import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Button } from 'semantic';
import Table from './Table';
import { API_URL } from 'utils/env';
import { request } from 'utils/api';
import { Breadcrumbs, Search, ListHeader } from 'components';

const onDataNeeded = (body) => {
  return request({
    method: 'POST',
    path: '/1/sync-log/search',
    body,
  });
};

export default function SyncLog() {
  const { t } = useTranslation();
  const searchRef = useRef();

  return (
    <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
      <>
        <Breadcrumbs active={t('syncLog.header', ' System Sync Log')} />

        <ListHeader title={t('syncLog.header', ' System Sync Log')}>
          <Button
            href={`${API_URL}/1/exact/authorize?admin=eflux2017`}
            rel="noopener"
            target="_blank"
            content={t('sync.reAuthorize', 'Re-authorize Exact Integration')}
            basic
          />
        </ListHeader>

        <Divider hidden />

        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table />
        <Divider hidden />
        <div style={{ textAlign: 'center' }}>
          <Search.Pagination />
        </div>
      </>
    </Search.Provider>
  );
}
