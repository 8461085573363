import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';

import { Table, Loader, Segment, Message, Divider } from 'semantic';
import { formatContractId } from 'utils/formatting';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const DataTable = (props) => {
  const { status, data: items, meta = {}, page } = props;

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>No generated Tokens returned</Message>
      )}

      {status.success && items.length > 0 && (
        <div>
          <p>Total number of Filtered Tokens: {meta.total}</p>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Contract Id</Table.HeaderCell>
                <Table.HeaderCell width={4}>Uid</Table.HeaderCell>
                <Table.HeaderCell width={4}>Active?</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <code>{formatContractId(item.emi3Contract)}</code>
                    </Table.Cell>
                    <Table.Cell>{item.uid}</Table.Cell>
                    <Table.Cell>
                      {`${item.isActive ? 'true' : 'false'}`}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
      <Divider hidden />
      {status.success && meta.total > props.limit && (
        <Center>
          <Pagination
            limit={props.limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              props.setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>Loading</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
};

export default DataTable;
