import React from 'react';
import ReactJson from 'react-json-view';

import { useTheme } from 'contexts/theme';

const light = {
  base00: '#F6F8FA',
  base01: '#EEEEEE',
  base02: '#DDDDDD',
  base03: '#AAAAAA',
  base04: '#888888',
  base05: '#666666',
  base06: '#444444',
  base07: '#222222',
  base08: '#E67800',
  base09: '#CC8E0B',
  base0A: '#B6947A',
  base0B: '#1C8300',
  base0C: '#258BB8',
  base0D: '#9D3F9D',
  base0E: '#9D3F9D',
  base0F: '#FF3D00',
};

const dark = {
  base00: '#000000',
  base01: '#333333',
  base02: '#444444',
  base03: '#666666',
  base04: '#888888',
  base05: '#AAAAAA',
  base06: '#CCCCCC',
  base07: '#EEEEEE',
  base08: '#F36026',
  base09: '#FF9726',
  base0A: '#D2B299',
  base0B: '#40A126',
  base0C: '#49A9D4',
  base0D: '#BA62BA',
  base0E: '#BA62BA',
  base0F: '#FF3D00',
};

export default function ReactJSONThemed(props) {
  const { renderedTheme } = useTheme();
  return (
    <ReactJson {...props} theme={renderedTheme === 'dark' ? dark : light} />
  );
}
