import React from 'react';
import { request } from 'utils/api';
import { Segment, Button, Header, Message, Divider } from 'semantic';

import PageCenter from 'components/PageCenter';
import Logo from 'components/LogoTitle';
import { Link } from 'react-router-dom';
import Code from 'components/form-fields/Code';

import { Layout } from 'components/Layout';
import { withTranslation } from 'react-i18next';
import { UserContext } from 'contexts/user';

class MfaVerification extends React.Component {
  static contextType = UserContext;

  state = {
    error: null,
    loading: false,
    code: '',
  };

  componentDidMount() {
    const data = this.getMfaSessionData();
    if (!data) {
      this.props.history.push('/login');
      return;
    }
    if (data.mfaMethod === 'sms') {
      this.triggerToken();
    }
  }

  getMfaSessionData() {
    const data = window.localStorage.getItem('mfa-auth');
    if (!data) return null;
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  triggerToken = async () => {
    const { mfaToken } = this.getMfaSessionData();
    this.setState({
      error: null,
      loading: true,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/mfa/send-code',
        token: mfaToken,
      });
      this.setState({
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  onSubmit = async () => {
    const params = new URLSearchParams(window.location.search);

    const { mfaToken } = this.getMfaSessionData();
    this.setState({
      error: null,
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/verify',
        token: mfaToken,
        body: {
          code: this.state.code,
        },
      });

      this.context.setToken(data.token);
      this.props.history.push(params.get('redirect') || '/');
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { error, loading } = this.state;
    const mfaSessionData = this.getMfaSessionData() || {};

    const { t } = this.props;

    return (
      <PageCenter>
        <Logo
          title={t('loginVerfication.logoTitle', 'Two-factor Verification')}
        />
        <Segment.Group>
          <Segment padded>
            <Header>
              {t('loginVerfication.header', 'Two-factor Verification')}
            </Header>
            {error && <Message error content={error.message} />}
            {mfaSessionData.mfaMethod === 'otp' && (
              <React.Fragment>
                <p>
                  {t(
                    'loginVerfication.enterCodeOtp',
                    'Enter the security code displayed by your authenticator app.'
                  )}
                </p>
                <Divider hidden />
                <Layout center>
                  <Code
                    className="verification-code"
                    type="number"
                    length={6}
                    loading={loading}
                    onChange={(value) => this.setState({ code: value })}
                    onComplete={(value) => {
                      this.setState({ code: value }, () => {
                        this.onSubmit();
                      });
                    }}
                  />
                </Layout>
                <Divider hidden />
              </React.Fragment>
            )}
            {mfaSessionData.mfaMethod === 'sms' && (
              <>
                <p>
                  {/* mfaSessionData bad translation here, attribute should have been called number, instead its called mfaSessionData */}
                  {t(
                    'loginVerfication.enterCodeSms',
                    'For added security, please enter the code that has been sent to your phone number ending in {{mfaSessionData}}',
                    {
                      mfaSessionData: mfaSessionData.mfaPhoneNumber,
                    }
                  )}
                </p>
                <Divider hidden />
                <Layout center>
                  <Code
                    className="verification-code"
                    type="number"
                    length={6}
                    loading={loading}
                    onChange={(value) => this.setState({ code: value })}
                    onComplete={(value) => {
                      this.setState({ code: value }, () => {
                        this.onSubmit();
                      });
                    }}
                  />
                </Layout>
                <Divider hidden />

                <p>
                  {t(
                    'loginVerfication.waitForMessage',
                    'It may take a minute to arrive.'
                  )}{' '}
                  <a onClick={this.triggerToken} style={{ cursor: 'pointer' }}>
                    {t('loginVerfication.sendAgain', 'Send again?')}
                  </a>
                </p>
              </>
            )}
            <Button
              fluid
              primary
              size="large"
              content={t('button.login', 'Login')}
              loading={loading}
              disabled={loading}
              onClick={this.onSubmit}
            />
          </Segment>
          <Segment secondary>
            {t(
              'loginVerfication.authMethodNotWorking',
              'Is this authentication method not working?'
            )}{' '}
            <Link to="/login/verification/backup">
              {t('loginVerfication.useBackupCodes', 'Use your backup codes')}
            </Link>
            .
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}

export default withTranslation()(MfaVerification);
