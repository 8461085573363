import React from 'react';
import { set, merge } from 'lodash-es';
import { request } from 'utils/api';

import { Form, Message, Modal, Button } from 'semantic';

import SearchDropDown from 'components/form-fields/SearchDropdown';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';

export default class EditEvseController extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleSubmit = () => {
    const { initialValues, locationId } = this.props;
    this.setState({
      submitted: true,
      loading: true,
      error: null,
    });

    request({
      method: 'POST',
      path: `/2/locations/cpo/${locationId}/access-groups`,
      body: {
        evseControllerId: initialValues.id,
        accessGroupIds: this.state.formValues.accessGroupIds,
      },
    })
      .then(() => {
        this.setState({ loading: false });
        if (this.props.onClose) this.props.onClose();
        this.setState({
          formValues: this.props.initialValues,
          open: false,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    this.setState({
      submitted: false,
      formValues: merge({}, this.state.formValues, set({}, name, value)),
    });
  }

  render() {
    const { trigger } = this.props;
    const { formValues = {}, submitted, open, loading, error } = this.state;
    const providerAccessGroupAccess = currentUserCanAccessProviderEndpoint(
      'accessGroups',
      'read',
      this.props.initialValues.providerId
    );
    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        trigger={trigger}
        onClose={() => {
          if (this.props.onClose) this.props.onClose();
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false,
          });
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Edit EVSE Controller Access Groups</Modal.Header>
        <Modal.Content>
          <Form
            id="EditEvseController-form"
            error={submitted && Boolean(error)}
            onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Field>
              <label>Access Groups</label>
              <SearchDropDown
                disabled={formValues.enablePublicFreeCharging}
                multiple
                value={formValues.accessGroupIds}
                objectMode={false}
                onDataNeeded={(body) => {
                  if (providerAccessGroupAccess) {
                    return request({
                      path: '/1/access-groups/search',
                      method: 'POST',
                      body: {
                        ...body,
                        accountId: this.props.initialValues.accountId,
                      },
                    });
                  } else {
                    return request({
                      path: '/1/access-groups/mine',
                      method: 'POST',
                      body,
                    });
                  }
                }}
                onChange={(e, { value }) => {
                  const { formValues } = this.state;
                  formValues.accessGroupIds = value;
                  this.setState({ formValues });
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={'Update'}
            form="EditEvseController-form"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
