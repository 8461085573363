import React from 'react';
import { Modal, Table } from 'semantic';
import JSONBlock from 'components/JSONBlock';
import { startCase } from 'lodash-es';
import ExpandJsonButton from '../ExpandJsonButton';
import modal from 'helpers/modal';
import { formatDateTime } from 'utils/date';

const FlexDiv = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
      }}>
      {children}
    </div>
  );
};

class ViewCommand extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    collapsedLevelParams: 1,
    collapsedLevelResponse: 1,
    collapsedLevelError: 1,
  };

  render() {
    const { item } = this.props;
    const {
      collapsedLevelParams,
      collapsedLevelResponse,
      collapsedLevelError,
    } = this.state;

    return (
      <>
        <Modal.Header>View Command {item.id}</Modal.Header>
        <Modal.Content>
          <FlexDiv>
            <span>
              <b>Params:</b>
            </span>
            <ExpandJsonButton
              collapsedLevel={this.state.collapsedLevelParams}
              onClick={() =>
                this.setState({
                  collapsedLevelParams: this.state.collapsedLevelParams
                    ? undefined
                    : 1,
                })
              }
            />
          </FlexDiv>
          <JSONBlock
            value={item.params}
            name="Params"
            collapsed={collapsedLevelParams}
          />
          {item.result && (
            <>
              <FlexDiv>
                <span>
                  <b>{startCase(item.destination)} Response:</b>
                </span>
                <ExpandJsonButton
                  collapsedLevel={this.state.collapsedLevelResponse}
                  onClick={() =>
                    this.setState({
                      collapsedLevelResponse: this.state.collapsedLevelResponse
                        ? undefined
                        : 1,
                    })
                  }
                />
              </FlexDiv>
              <JSONBlock
                value={item.result}
                name="Response"
                collapsed={collapsedLevelResponse}
              />
            </>
          )}
          {item.error && (
            <>
              <FlexDiv>
                <span>
                  <b>Error:</b>
                </span>
                <ExpandJsonButton
                  collapsedLevel={this.state.collapsedLevelError}
                  onClick={() =>
                    this.setState({
                      collapsedLevelError: this.state.collapsedLevelError
                        ? undefined
                        : 1,
                    })
                  }
                />
              </FlexDiv>
              <JSONBlock
                value={item.error}
                name="Error"
                collapsed={collapsedLevelError}
              />
            </>
          )}
          <span>
            <b>Details:</b>
          </span>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>ID</Table.Cell>
                <Table.Cell>{item.id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Method</Table.Cell>
                <Table.Cell>{item.method}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Status</Table.Cell>
                <Table.Cell>{item.status}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Destination</Table.Cell>
                <Table.Cell>{item.destination}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Message ID</Table.Cell>
                <Table.Cell>{item.messageId}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>OCPP Identity</Table.Cell>
                <Table.Cell>{item.ocppIdentity}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Created At</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Updated At</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions />
      </>
    );
  }
}

export default modal(ViewCommand);
