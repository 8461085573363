import React from 'react';
import { Button, Message, Modal } from 'semantic';
import ResetEvse from 'components/modals/ResetEvse';
import { useTranslation } from 'react-i18next';
import { EvseController } from 'components/modals/EditEvseController/types';

type RebootEvseProps = {
  rebootRequired: boolean;
  evseController: EvseController;
  onReboot?: () => void;
};

export default function RebootEvse({
  rebootRequired,
  evseController,
  onReboot,
}: RebootEvseProps) {
  const { t } = useTranslation();

  if (!rebootRequired) {
    return <></>;
  }
  return (
    <Modal.Content>
      <Message negative>
        <Message.Header>
          {t('evseController.rebootRequired', 'Reboot required')}
        </Message.Header>
        <p>
          {t(
            'evseControllerAlpitronic.rebootRequiredDetails',
            "A reboot is required to apply the newly configured prices. Please click 'Reboot this station' to reboot the station."
          )}
        </p>
        <ResetEvse
          locationId={evseController.locationId}
          initialValues={{
            evseControllerId: evseController.id,
          }}
          trigger={
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              basic
              icon="arrow-rotate-right"
              content={t('evseController.reset', 'Reboot this station')}
            />
          }
          onDone={() => onReboot?.()}
        />
      </Message>
    </Modal.Content>
  );
}
