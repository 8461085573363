import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppWrapper } from 'components';
import { useUser } from 'contexts/user';
import { Container } from 'semantic';
import Pairing from './Pairing';
import Overview from './Overview';

export default function SmartCharging() {
  const { hasPlatformFeatureInModule } = useUser();

  const supportsSmartCharging = hasPlatformFeatureInModule('smart-charging');

  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path="/smart-charging" component={Overview} exact />
          <Route
            path="/smart-charging/pairing"
            component={supportsSmartCharging && Pairing}
            render={() =>
              !supportsSmartCharging && <Redirect to="/smart-charging" />
            }
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
