import React from 'react';
import CpoSessionsProviderBreakdown from './CpoSessionsProviderBreakdown';
import { Container, Divider } from 'semantic';
import MspSessionsProviderBreakdown from './MspSessionsProviderBreakdown';
import AppWrapper from 'components/AppWrapper';
import { Breadcrumbs } from 'components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMenuRoutes from 'hooks/useMenuRoutes';

export default function FinanceRoaming({ routesContext = 'legacy' }) {
  const { t } = useTranslation();
  const { financeRoamingRoutes } = useMenuRoutes(routesContext);
  const { prefixUiRoute } = financeRoamingRoutes;

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link key="finance" to={prefixUiRoute}>
              {t('finance.title', 'Finance')}
            </Link>,
          ]}
          active={t('invoices.titleRoaming', 'Roaming')}
        />
        <CpoSessionsProviderBreakdown />
        <Divider hidden />
        <Divider hidden />
        <MspSessionsProviderBreakdown />
        <Divider hidden />
        <Divider hidden />
      </Container>
    </AppWrapper>
  );
}
