import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic';
import { Layout } from 'components/Layout';
import SearchContext from '../../old_search/Context';

function getOperatorAndValue(propsValue) {
  let operator = '$gte';
  let value = '';

  const keys = Object.keys(propsValue || {});
  if (keys[0]) {
    operator = keys[0];
    value = propsValue[keys[0]];
  }

  return {
    operator,
    value,
  };
}

export default function NumberRange({ label, name, onChange, ...props }) {
  const { getFilterValue, onFilterChange } = useContext(SearchContext);

  const value = getFilterValue(name);
  const start = getOperatorAndValue(value);

  const [operator, setOperator] = React.useState(start.operator);
  const [numberValue, setNumberValue] = React.useState(start.value);

  React.useEffect(() => {
    const updateValue = getOperatorAndValue(value);
    setOperator(updateValue.operator);
    setNumberValue(updateValue.value);
  }, [value]);

  function handleOnOperatorChange(evt, operatorValue) {
    if (numberValue !== '') {
      onFilterChange(evt, {
        name,
        value: {
          [operatorValue]: Number(numberValue),
        },
      });
    } else {
      setOperator(operatorValue);
    }
  }

  return (
    <Form.Field>
      <label>{label}</label>
      <Layout horizontal center spread>
        <Radio
          label="More Than"
          name={`${name}_operator`}
          value="$gte"
          checked={operator === '$gte'}
          onChange={(e, { value }) => handleOnOperatorChange(e, value)}
        />
        <Radio
          label="Less Than"
          name={`${name}_operator`}
          value="$lte"
          checked={operator === '$lte'}
          onChange={(e, { value }) => handleOnOperatorChange(e, value)}
        />
        <Radio
          label="Equal"
          name={`${name}_operator`}
          value="$eq"
          checked={operator === '$eq'}
          onChange={(e, { value }) => handleOnOperatorChange(e, value)}
        />
        <Form.Input
          value={numberValue}
          onChange={(evt, { value }) => {
            onFilterChange(evt, {
              name,
              value: {
                [operator]: Number(value),
              },
            });
          }}
          type="number"
          {...props}
        />
      </Layout>
    </Form.Field>
  );
}

NumberRange.propTypes = {
  ...Form.Input.propTypes,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
