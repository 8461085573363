import React from 'react';
import { Modal, Button, Message, Header } from 'semantic';
import modal from 'helpers/modal';
import { request } from 'utils/api';

class GenerateMFABackup extends React.Component {
  state = {
    data: null,
    loading: false,
    error: null,
  };

  generateCode = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/reset',
        body: {
          user: this.props.user.id,
        },
      });

      this.setState({ data, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  render() {
    const { loading, error, data } = this.state;

    return (
      <>
        <Modal.Header>Generate MFA code</Modal.Header>
        <Modal.Content>
          {error && <Message error>{error.message}</Message>}
          {!data && (
            <>
              <p>
                This will generate a new backup code for your account. Which the
                user can use to authenticate if they lose access to their
                device. Note that code only works once.
              </p>
              <Message warn>
                Before clicking "Generate Code" ask the {this.props.user.name}(
                {this.props.user.email}) to use one of their backup codes.
              </Message>
            </>
          )}
          {data && (
            <>
              <Header>One time backup code</Header>
              <p>
                <code>{data}</code>
              </p>
              <Message>
                Copy this code and give it to the user. <br />
                1. Once the user is prompted to enter a mfa code.
                <br />
                2. Click "Use your backup codes"
                <br />
                3. Enter the above code.
              </Message>
            </>
          )}
          <Button
            loading={loading}
            disabled={data || loading}
            primary
            content="Generate Code"
            onClick={() => this.generateCode()}
          />
        </Modal.Content>
      </>
    );
  }
}

export default modal(GenerateMFABackup);
