export const tasks = [
  {
    name: 'Number of Connectors Configuration',
    check: {
      description:
        'Checking if NumberOfConnectors configuration matches our EVSE Controller settings',
      execute: async ({ evseController, configuration }) => {
        let { NumberOfConnectors = '1' } = configuration;
        NumberOfConnectors = NumberOfConnectors.toString().replace(
          /[\[\]\(\)]+/g,
          ''
        );
        if (parseInt(NumberOfConnectors, 10) !== evseController.numConnectors) {
          throw new Error(
            `Expected EVSE Controller settings to have numConnectors = ${NumberOfConnectors} (was ${evseController.numConnectors})`
          );
        }
        return true;
      },
    },
    fix: {
      description:
        'Updating EVSE controller settings to reflect NumberOfConnectors configuration',
      execute: async ({ configuration, updateSettings }) => {
        let { NumberOfConnectors = '1' } = configuration;
        NumberOfConnectors = NumberOfConnectors.toString().replace(
          /[\[\]\(\)]+/g,
          ''
        );
        await updateSettings({
          numConnectors: NumberOfConnectors,
        });
      },
    },
  },
  {
    name: 'Websocket & Connection Configuration',
    type: 'keys',
    keys: {
      WebSocketPingInterval: {
        required: false,
        value: 60,
      },
      ConnectionTimeOut: {
        required: false,
        value: 120,
      },
    },
    keys201: {
      'OCPPCommCtrlr.WebSocketPingInterval': {
        required: false,
        value: 60,
      },
      'TxCtrlr.EVConnectionTimeOut': {
        required: false,
        value: 120,
      },
    },
  },
  {
    name: 'Heartbeat Configuration',
    check: {
      description: 'Checking if HeartbeatInterval needs to be adjusted',
      execute: async ({ evseController, configuration }) => {
        let { WebSocketPingInterval, HeartbeatInterval } = configuration;

        if (evseController.ocppProtocolVersion === 'ocpp2.0.1') {
          WebSocketPingInterval =
            configuration['OCPPCommCtrlr.WebSocketPingInterval'];
          HeartbeatInterval = configuration['OCPPCommCtrlr.HeartbeatInterval'];
        }

        if (WebSocketPingInterval && parseInt(HeartbeatInterval, 10) !== 900) {
          throw new Error(
            `Expected HeartbeatInterval to be 900 (WebSocketPingInterval supported)`
          );
        }
        if (!WebSocketPingInterval && parseInt(HeartbeatInterval, 10) !== 60) {
          throw new Error(
            `Expected HeartbeatInterval to be 60 (WebSocketPingInterval not supported)`
          );
        }
        return true;
      },
    },
    fix: {
      description: 'Updating HeartbeatInterval',
      execute: async ({
        evseController,
        configuration,
        changeConfiguration,
      }) => {
        let { WebSocketPingInterval, HeartbeatInterval } = configuration;
        let key = 'HeartbeatInterval';

        if (evseController.ocppProtocolVersion === 'ocpp2.0.1') {
          key = 'OCPPCommCtrlr.HeartbeatInterval';
          WebSocketPingInterval =
            configuration['OCPPCommCtrlr.WebSocketPingInterval'];
          HeartbeatInterval = configuration['OCPPCommCtrlr.HeartbeatInterval'];
        }

        if (WebSocketPingInterval && parseInt(HeartbeatInterval, 10) !== 900) {
          await changeConfiguration({
            [key]: '900',
          });
        }
        if (!WebSocketPingInterval && parseInt(HeartbeatInterval, 10) !== 60) {
          await changeConfiguration({
            [key]: '60',
          });
        }
      },
    },
  },
  {
    name: 'Transaction Configuration',
    type: 'keys',
    keys: {
      TransactionMessageAttempts: {
        required: false,
        value: 3,
      },
      TransactionMessageRetryInterval: {
        required: false,
        value: 60,
      },
      StopTransactionOnEVSideDisconnect: {
        required: false,
        value: 'True',
      },
      StopTransactionOnInvalidId: {
        required: false,
        value: 'True',
      },
      MaxEnergyOnInvalidId: {
        required: false,
        value: 0,
      },
    },
    keys201: {
      'OCPPCommCtrlr.MessageAttemptsTransactionEvent': {
        required: false,
        value: 3,
      },
      'OCPPCommCtrlr.MessageAttemptIntervalTransactionEvent': {
        required: false,
        value: 60,
      },
      'TxCtrlr.StopTxOnEVSideDisconnect': {
        required: false,
        value: 'True',
      },
      'TxCtrlr.StopTxOnInvalidId': {
        required: false,
        value: 'True',
      },
      'TxCtrlr.MaxEnergyOnInvalidId': {
        required: false,
        value: 0,
      },
      'TxCtrlr.TxStartPoint': {
        required: true,
        value: 'PowerPathClosed',
      },
    },
  } /*
  {
    name: 'ResetRetries Configuration',
    check: {
      description: 'Checking if ResetRetries matches global setting',
      execute: async ({ configuration }) => {
        const { ResetRetries } = configuration;
        const expectedResetRetries = 3;
        if (parseInt(ResetRetries, 10) !== expectedResetRetries) {
          throw new Error(
            `Expected ResetRetries to match ${expectedResetRetries} times (was ${ResetRetries})`
          );
        }
        return true;
      }
    },
    fix: {
      description:
        'Updating ResetRetries configuration to match global setting',
      execute: async ({ changeConfiguration }) => {
        await changeConfiguration({
          ResetRetries: '3'
        });
      }
    }
  },*/,
  {
    name: 'Meter Configuration',
    type: 'keys',
    keys: {
      MeterValueSampleInterval: {
        required: true,
        value: 500,
      },
      CentralMeterValueSampleInterval: {
        required: false,
        value: 500,
      },
      CentralMeterValueTransmissionMode: {
        required: false,
        value: 'during',
      },
    },
    keys201: {
      'SampledDataCtrlr.TxUpdatedInterval': {
        required: true,
        value: 500,
      },
    },
  },
  {
    name: 'Vendor Specific Configuration',
    type: 'keys',
    keys: {
      CpoName: {
        required: false,
        value: 'E-Flux',
      },
      'Chameleon-MinCurrent': {
        required: false,
        value: 14,
      },
      FreevendEnabled: {
        required: false,
        value: 'False',
      },
    },
    keys201: {},
  },
  {
    name: 'Local Auth Configuration',
    type: 'keys',
    keys: {
      LocalAuthorizeOffline: {
        required: true,
        value: 'True',
      },
      LocalAuthListEnabled: {
        required: false,
        value: 'True',
      },
      LocalPreAuthorize: {
        required: true,
        value: 'False',
      },
      AuthorizationCacheEnabled: {
        required: false,
        value: 'False',
      },
      AllowOfflineTxForUnknownId: {
        required: false,
        value: 'True',
      },
      AuthorizeRemoteTxRequests: {
        required: false,
        value: 'True',
      },
    },
    keys201: {
      'AuthCtrlr.LocalAuthorizeOffline': {
        required: true,
        value: 'True',
      },
      'LocalAuthListCtrlr.Enabled': {
        required: false,
        value: 'True',
      },
      'AuthCtrlr.LocalPreAuthorize': {
        required: true,
        value: 'False',
      },
      'AuthCacheCtrlr.Enabled': {
        required: false,
        value: 'False',
      },
      'AuthCtrlr.OfflineTxForUnknownIdEnabled': {
        required: false,
        value: 'True',
      },
      'AuthCtrlr.AuthorizeRemoteStart': {
        required: false,
        value: 'True',
      },
    },
  },
];
