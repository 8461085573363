import React from 'react';
import { ActionButton, Icon } from '../../semantic';
import { shortCircuitEvent } from '../../utils/events';

export function ActionButtonWithIcon({
  text,
  icon,
  disabled = false,
  onClick,
}: {
  text: string;
  icon: string;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <ActionButton
      style={{ paddingLeft: 0 }}
      disabled={disabled}
      primary
      compact
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        shortCircuitEvent(e);
        onClick?.();
      }}>
      <>
        <Icon name={icon} />
        {text}
      </>
    </ActionButton>
  );
}
