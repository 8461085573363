import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import {
  Table,
  Loader,
  Segment,
  Message,
  Modal,
  Button,
  Divider,
  Label,
} from 'semantic';
import { formatDateTime } from 'utils/date';
import EditExchangeRate from 'components/modals/EditExchangeRate';
import { capitalize } from 'lodash-es';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default function DataTable(props) {
  const {
    status,
    data: items,
    meta = {},
    page,
    formMeta,
    onDeleteItem,
    onRefresh,
    writeAccess,
  } = props;

  const color = (s) => {
    switch (s) {
      case 'active':
        return 'olive';
      case 'upcoming':
        return 'blue';
      default:
        return 'grey';
    }
  };
  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>Nothing to see here</Message>
      )}
      {status.success && items.length > 0 && (
        <div>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Source Currency</Table.HeaderCell>
                <Table.HeaderCell width={2}>Target Currency</Table.HeaderCell>
                <Table.HeaderCell width={2}>Rate</Table.HeaderCell>
                <Table.HeaderCell width={3}>Valid from</Table.HeaderCell>
                <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item._id}>
                    <Table.Cell>{item.sourceCurrency}</Table.Cell>
                    <Table.Cell>{item.targetCurrency}</Table.Cell>
                    <Table.Cell>{item.rate}</Table.Cell>
                    <Table.Cell>{formatDateTime(item.activeFrom)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <Label color={color(item.status)}>
                        {capitalize(item.status)}
                      </Label>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.status === 'upcoming' && (
                        <div>
                          <Modal
                            header={`Are you sure you want to delete?`}
                            content="All data will be permanently deleted"
                            trigger={
                              <Button
                                disabled={!writeAccess}
                                basic
                                icon="trash"
                                title="Delete"
                              />
                            }
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: () => onDeleteItem(item),
                              },
                            ]}
                          />
                          <EditExchangeRate
                            initialValues={item}
                            formMeta={formMeta}
                            trigger={
                              <Button
                                basic
                                icon="pen-to-square"
                                title="Edit"
                                disabled={!writeAccess}
                              />
                            }
                            onClose={() => onRefresh()}
                          />
                        </div>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
      <Divider hidden />
      {status.success && meta.total > props.limit && (
        <Center>
          <Pagination
            limit={props.limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              props.setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>Loading</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
}
