import React, { useContext } from 'react';

import { Table, Icon } from 'semantic';

import { Search } from 'components';

import { canAccess } from 'utils/roles';

import { useTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';

import styled from 'styled-components';

import Actions from '../Actions';
import { formatCardStatus } from 'utils/formatting';

const MediaObject = styled.div`
  &,
  & .bd {
    overflow: hidden;
    _overflow: visible;
    zoom: 1;
  }

  & .img {
    float: left;
    margin: 2px 0.25em 0 0;
    color: rgba(0, 0, 0, 0.4);

    svg {
      display: block;
    }

    .nocturnal-theme & {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export default function MyCardsTable() {
  const { user } = useContext(UserContext);
  const { items, reload, loading } = useContext(Search.Context);
  const { t } = useTranslation();

  const canEdit = canAccess(
    user,
    user.providerId,
    user.accountId,
    'cards',
    'write'
  );

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>
              {t('myCards.nameOnCardHeader', 'Name on Card')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('myCards.planHeader', 'Plan')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('myCards.statusHeader', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('myCards.identifierHeader', 'Identifier')}
            </Table.HeaderCell>
            {canEdit && (
              <Table.HeaderCell width={2}>
                {t('myCards.actionsHeader', 'Actions')}
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <MediaObject>
                    <span
                      title={
                        item.type === 'card'
                          ? t(`cardType.card`, 'Card')
                          : t(`cardType.tag`, 'Tag')
                      }
                      className="img">
                      <Icon
                        name={item.type === 'card' ? 'credit-card' : 'tag'}
                      />
                    </span>
                    <div className="bd">
                      <strong>{item.nameOnCard}</strong>
                      <br />
                      <small>({item.user.email})</small>
                    </div>
                  </MediaObject>
                </Table.Cell>
                <Table.Cell>{item.billingPlan?.name}</Table.Cell>
                <Table.Cell textAlign="center">
                  {formatCardStatus(item.status, t)}
                </Table.Cell>
                <Table.Cell>
                  {item.token?.visualNumber || item.token?.customId || '-'}
                </Table.Cell>
                {canEdit && (
                  <Table.Cell textAlign="center">
                    <Actions item={item} onReload={reload} />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
