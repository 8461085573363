import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic';

import { request } from 'utils/api';
import Table, { buildConnectors } from './Table';
import Modal from './Modal';
import ListHeader from 'components/ListHeader';
import useFetch from 'hooks/useFetch';
import { PaymentTerminal } from 'types/payment-terminal';
import { Location } from 'types/location';
import { useUser } from 'contexts/user';
import {
  hasPaymentTerminalPermissions,
  hasPaymentTerminalPlatformFeature,
} from './utils';

type Props = {
  location: Location;
};

type PaymentTerminalsResponse = PaymentTerminal[];

export default function PaymentTerminals({ location }: Props) {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const [deleteError, setDeleteError] = useState<Error | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const hasFeature = hasPaymentTerminalPlatformFeature();

  const canCreate =
    hasFeature && hasPaymentTerminalPermissions(user, 'write', provider);

  const {
    data: paymentTerminalsData,
    loading: paymentTerminalsLoading,
    error: paymentTerminalsError,
    refresh: refreshPaymentTerminals,
  } = useFetch<PaymentTerminalsResponse>({
    path: `/1/payment-terminals`,
    queryParams: { locationId: location.id },
  });

  const deletePaymentTerminal = async (id: string): Promise<Error | null> => {
    setDeleteError(null);
    setDeleteLoading(true);

    try {
      await request({
        method: 'DELETE',
        path: `/1/payment-terminals/${id}`,
      });

      setDeleteLoading(false);
      setDeleteError(null);
      refreshPaymentTerminals();
      return null;
    } catch (error) {
      setDeleteLoading(false);
      setDeleteError(error as Error);
      return error as Error;
    }
  };

  const updatePaymentTerminal = async (
    params: Partial<PaymentTerminal>
  ): Promise<Error | null> => {
    try {
      await request({
        method: 'PATCH',
        path: `/1/payment-terminals/${params.id}`,
        body: params,
      });

      refreshPaymentTerminals();
      return null;
    } catch (error) {
      return error as Error;
    }
  };

  const createPaymentTerminal = async (
    params: PaymentTerminal
  ): Promise<Error | null> => {
    try {
      await request({
        method: 'POST',
        path: `/1/payment-terminals`,
        body: params,
      });

      refreshPaymentTerminals();
      return null;
    } catch (error) {
      return error as Error;
    }
  };

  const connectors = buildConnectors(location, paymentTerminalsData || []);

  if (!paymentTerminalsLoading && !hasFeature) {
    return (
      <Message
        error
        content={t(
          'paymentTerminal.noFeature',
          'You do not have access to this feature.'
        )}
      />
    );
  }

  return (
    <div>
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <ListHeader title={t('paymentTerminal.title', 'Payment Terminals')}>
          {canCreate && (
            <Modal
              size="small"
              locations={[location]}
              connectors={connectors || []}
              submit={createPaymentTerminal}
              trigger={
                <Button
                  primary
                  icon="plus"
                  content={t(
                    'paymentTerminal.newPaymentTerminal',
                    'New Payment Terminal'
                  )}
                  as="button"
                />
              }
            />
          )}
        </ListHeader>
      </div>
      {paymentTerminalsError && (
        <Message error content={paymentTerminalsError.message} />
      )}
      {deleteError && <Message error content={deleteError.message} />}
      {!paymentTerminalsError && (
        <Table
          location={location}
          paymentTerminals={paymentTerminalsData || []}
          loading={paymentTerminalsLoading || deleteLoading}
          deletePaymentTerminal={deletePaymentTerminal}
          updatePaymentTerminal={updatePaymentTerminal}
        />
      )}
    </div>
  );
}
