import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Label } from 'semantic';
import { Link } from 'react-router-dom';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { Search } from 'components';
import SmartChargingModal from './Modal';

export default function EvseTable() {
  const { t } = useTranslation();
  const { items, reload, loading } = useContext(Search.Context);

  if (!items?.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('locationEvseControllers.columnSerialNo', 'Serial No')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('locationEvseControllers.columnOCPPId', 'OCPP Identity')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('locationEvseControllers.columnEVSEId', 'EVSE ID')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('locationEvseControllers.columnLocation', 'Location')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('smartCharging.method', 'Smart Charging Method')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('common.actions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                {currentUserCanAccessProviderEndpoint(
                  'evseControllers',
                  'read'
                ) ? (
                  <Link to={`/charging-stations/${item.id}`}>
                    {item.serialNumber ||
                      t(
                        'locationEvseControllers.noSerialNumber',
                        'No Serial Number'
                      )}
                  </Link>
                ) : (
                  item.serialNumber ||
                  t(
                    'locationEvseControllers.noSerialNumber',
                    'No Serial Number'
                  )
                )}
              </Table.Cell>
              <Table.Cell>{item.ocppIdentity}</Table.Cell>
              <Table.Cell>{item.normalizedEvseId || item.evseId}</Table.Cell>
              <Table.Cell>{item.location && item.location.name}</Table.Cell>
              <Table.Cell textAlign="center">
                <Label
                  style={{ textTransform: 'capitalize' }}
                  content={item.smartCharging.method}
                  color={item.smartCharging.paired ? 'olive' : undefined}
                />
              </Table.Cell>
              <Table.Cell>
                <SmartChargingModal
                  initialValues={item}
                  onSubmit={reload}
                  trigger={
                    <Button
                      basic
                      fluid
                      icon="handshake"
                      content={t('smartCharging.pair', 'Pair')}
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
