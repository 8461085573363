import React, { useEffect, useState } from 'react';
import { Box } from 'components';
import { Item, Icon, Header, Placeholder, Message } from 'semantic';
import { formatNumber } from 'utils/formatting';
import widgetstyles from '../widgetstyles.module.less';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { startOfDay, sub, endOfDay } from 'date-fns';

function getRange() {
  const from = sub(new Date(), { days: 30 });
  return {
    from: startOfDay(from).toISOString(),
    to: endOfDay(new Date()).toISOString(),
  };
}

type IData = {
  evses: number;
  issues: {
    open: number;
    closed: number;
  };
};

const Loader = () => (
  <Placeholder fluid>
    <Placeholder.Header image>
      <Placeholder.Line length="very long" />
      <Placeholder.Line length="medium" />
    </Placeholder.Header>
    {/* <Placeholder.Paragraph>
      <Placeholder.Line length="medium" />
      <Placeholder.Line length="short" />
      <Placeholder.Line length="long" />
    </Placeholder.Paragraph> */}
  </Placeholder>
);

export default function FieldServiceStats() {
  const { t } = useTranslation();

  const [data, setData] = useState<IData>({
    evses: 0,
    issues: {
      open: 0,
      closed: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  async function fetchAccountSummary() {
    setLoading(true);
    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/users/me/summary',
        params: getRange(),
      });

      setData({
        evses: data.fieldService?.evses || 0,
        issues: data.fieldService?.issues || {
          open: 0,
          closed: 0,
        },
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e as Error);
    }
  }

  const title = t(
    'maintenanceHome.FieldServiceStats',
    'Field Service Management'
  );

  useEffect(() => {
    fetchAccountSummary();
  }, []);

  return (
    <>
      <Box title={title}>
        <Box.Content>
          <div className={widgetstyles.itemGrid}>
            <div className={widgetstyles.itemGridColumn}>
              <Item.Group unstackable className={widgetstyles.itemGroup}>
                {loading ? (
                  <Loader />
                ) : (
                  <Item>
                    <Item.Image>
                      <Icon name="bug" className={widgetstyles.itemIcon} />
                    </Item.Image>
                    <Item.Content verticalAlign="middle">
                      <Header as="div">
                        {t(
                          'maintenanceHome.statsClosedIssues',
                          '{{closed}} Closed Issues',
                          {
                            closed: data.issues.closed || 0,
                          }
                        )}
                      </Header>
                      <div className={widgetstyles.itemSubheader}>
                        {t('common.last30Days', 'Last 30 days')}
                      </div>
                    </Item.Content>
                  </Item>
                )}
              </Item.Group>
            </div>
            <div className={widgetstyles.itemGridColumn}>
              <Item.Group unstackable className={widgetstyles.itemGroup}>
                {loading ? (
                  <Loader />
                ) : (
                  <Item>
                    <Item.Image>
                      <Icon
                        name="charging-station"
                        className={widgetstyles.itemIcon}
                      />
                    </Item.Image>
                    <Item.Content verticalAlign="middle">
                      <Header as="div">
                        {t('maintenanceHome.statsEvses', '{{evse}} EVSEs', {
                          evse: formatNumber(data.evses || 0),
                        })}
                      </Header>
                    </Item.Content>
                  </Item>
                )}
              </Item.Group>
            </div>
          </div>
        </Box.Content>

        <Box.Actions>
          <Box.ActionLink to="/maintenance/evse-controllers/issues">
            {t('maintenanceHome.viewIssues', 'View Issues')}
          </Box.ActionLink>
          <Box.ActionLink to="/maintenance/evse-controllers">
            {t('maintenanceHome.viewEvses', 'View EVSEs')}
          </Box.ActionLink>
        </Box.Actions>
      </Box>
      {error && <Message error content={error.message} />}
    </>
  );
}
