import React from 'react';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import InfoAndContactForm from './InfoAndContact.form';

export default function AccountInfoAndContact() {
  return (
    <SettingsShell>
      <InfoAndContactForm />
    </SettingsShell>
  );
}
