import React, { useState, useContext } from 'react';
import { Modal, Button, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useEnterpriseAccountMigration } from '../context';
import { Search } from 'components';

export default function EnterpriseAccountMigrationConfirmation({
  onBack,
  onClose,
  resetModal,
  intermediateData,
}) {
  const { t } = useTranslation();
  const {
    accountName,
    migrationType,
    accountId,
    enterpriseAccountName,
    enterpriseAccountId,
    requestMigration,
    acceptMigration,
    rejectMigration,
    setMigrationData,
  } = useEnterpriseAccountMigration();
  const { reload } = useContext(Search.Context);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const displayAccountName = intermediateData?.accountName || accountName;
  const displayEnterpriseAccountName =
    intermediateData?.enterpriseAccountName || enterpriseAccountName;

  async function acceptMigrationPlan(migrationPlanId) {
    try {
      const accepted = await acceptMigration(migrationPlanId);
      reload();
      resetModal();
      return accepted;
    } catch (error) {
      reload();
      throw error;
    }
  }

  async function rejectMigrationPlan(migrationPlanId) {
    try {
      const rejected = await rejectMigration(migrationPlanId);
      reload();
      resetModal();
      return rejected;
    } catch (error) {
      reload();
      throw error;
    }
  }

  async function handleAccept() {
    setLoading(true);

    if (intermediateData?.migrationPlanId) {
      await acceptMigrationPlan(intermediateData.migrationPlanId);
      return;
    }

    try {
      const migrationPlan = await requestMigration(
        migrationType,
        accountId,
        enterpriseAccountId
      );
      setMigrationData(migrationPlan?.data);

      const migrationPlanId = migrationPlan?.data?.id;
      await acceptMigrationPlan(migrationPlanId);

      setLoading(false);
      reload();
      onClose();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  async function handleReject() {
    setLoading(true);

    if (intermediateData?.migrationPlanId) {
      await rejectMigrationPlan(intermediateData.migrationPlanId);
      return;
    }

    try {
      const migrationPlan = await requestMigration(
        accountId,
        enterpriseAccountId
      );
      setMigrationData(migrationPlan?.data);

      const migrationPlanId = migrationPlan?.data?.id;
      const rejected = await rejectMigration(migrationPlanId);

      if (!rejected) {
        throw new Error('Failed to reject migration plan.');
      }

      setLoading(false);
      reload();
      onClose();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal.Header>Account Migration - Step 2/3: Confirmation</Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'enterpriseAccountMigration.confirmation.content',
            'Please confirm that you want to migrate the selected account.'
          )}
        </p>
        <Message
          icon="circle-exclamation"
          warning
          header="Warning"
          content={t(
            'enterpriseAccountMigration.confirmation.confirmationWarning',
            `Note that this migration will transfer all data from {{displayAccountName}} to {{displayEnterpriseAccountName}}! Proceed with caution.`,
            {
              displayAccountName,
              displayEnterpriseAccountName,
            }
          )}
        />
        {error && (
          <Message
            error
            header={t(
              'enterpriseAccountMigration.confirmation.errorHeader',
              'Error'
            )}
            content={error.message}
          />
        )}
      </Modal.Content>

      <Modal.Actions
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button
            disabled={loading}
            basic
            content={t('common.back', 'Back')}
            onClick={() => onBack()}
          />
        </div>

        <div>
          <Button
            disabled={loading}
            basic
            content={t('common.reject', 'Reject')}
            onClick={handleReject}
          />

          <Button
            loading={loading}
            primary
            content={t('common.accept', 'Accept')}
            onClick={handleAccept}
          />
        </div>
      </Modal.Actions>
    </>
  );
}
