import React, { useEffect, useMemo, useRef } from 'react';
import { ActionButton, Grid, Header, Icon, Segment } from 'semantic';
import { shortCircuitEvent } from 'utils/events';
import {
  useAdvancedTariffsEditState,
  useAdvancedTariffState,
} from 'components/tariffs/AdvancedTariff/state';
import CostSettingsForm from 'components/tariffs/AdvancedTariff/CostSettings/Form';
import RestrictionsForm from 'components/tariffs/AdvancedTariff/Restrictions/Form';
import { useCostSettingsSummaryRow } from 'components/tariffs/AdvancedTariff/CostSettings/Summary';
import { useRestrictionsSummaryRow } from 'components/tariffs/AdvancedTariff/Restrictions/Summary';
import { useTranslation } from 'react-i18next';
import { SummaryGridRow } from 'components/tariffs/AdvancedTariff/SummaryRow';
import {
  BaseCostSettingsProvider,
  useIsBaseCostSettings,
} from 'components/tariffs/AdvancedTariff/BaseCostSettings';
import SelectTariffSystem from 'components/tariffs/AdvancedTariff/SelectTariffSystem';
import AdvancedTariffsFeatureAlert from 'components/tariffs/AdvancedTariff/FeatureAlert';

const useAutoScrolling = (advancedTariff) => {
  const anchorMap = new Map();
  const collectAnchorRef = (component, anchor) => {
    anchorMap.set?.(component, anchor);
  };

  const lastCount = useRef(null);

  useEffect(() => {
    const nextLength = advancedTariff.components?.length || 0;
    if (lastCount.current < nextLength) {
      const lastIndex = nextLength - 1;
      const anchor = anchorMap.get(advancedTariff.components[lastIndex]);
      anchor?.scrollIntoView?.({ behavior: 'smooth' });
    }
    lastCount.current = nextLength;
  }, [advancedTariff?.components]);

  return collectAnchorRef;
};

export default function AdvancedTariffForm({
  value: inputValue,
  onChange,
  onEditStateChange,
  currency,
}) {
  const { t } = useTranslation();

  const {
    value,
    updateFallback,
    updateComponent,
    removeComponent,
    addComponent,
    tariffSystem,
    setTariffSystem,
  } = useAdvancedTariffState(inputValue);

  const collectAnchorRef = useAutoScrolling(value);

  const baseTariff = useMemo(
    () => ({
      costSettings: value,
      _uiKey: value._uiKey,
    }),
    [value]
  );

  const { getIsInEditMode, setIsInEditMode } = useAdvancedTariffsEditState(
    onEditStateChange,
    baseTariff
  );

  const onFallbackChange = (next) => {
    onChange(updateFallback(next));
  };

  const onComponentChange = (next, index) => {
    onChange(updateComponent(next, index));
  };

  const onAddNewComponent = (e) => {
    shortCircuitEvent(e);
    const nextValue = addComponent();
    const newComponent = nextValue.components[nextValue.components.length - 1];
    setIsInEditMode(newComponent, true);
    onChange(nextValue);
  };

  const onRemoveComponent = (e, index) => {
    shortCircuitEvent(e);
    const componentToRemove = value.components[index];
    setIsInEditMode(componentToRemove, false);
    onChange(removeComponent(index));
  };

  const onChangeTariffSystem = (next) => {
    onChange(setTariffSystem(next));
  };

  const baseTariffName =
    tariffSystem === 'dynamic'
      ? t('advancedTariff.dynamicTariff', 'Dynamic Tariff')
      : t('advancedTariff.baseTariff', 'Base Tariff');

  const editBaseTariffName =
    tariffSystem === 'dynamic'
      ? t('advancedTariff.editDynamicTariff', 'Dynamic Tariff Setup')
      : t('advancedTariff.editBaseTariff', 'Base Tariff Setup');

  const scheduledTariffName = t(
    'advancedTariff.scheduledTariff',
    'Scheduled Tariff'
  );
  const editScheduledTariffName = t(
    'advancedTariff.editScheduleTariff',
    'Scheduled Tariff Setup'
  );

  const baseTariffDescription =
    tariffSystem === 'dynamic'
      ? t(
          'advancedTariff.dynamicTariffDescription',
          'Price per kWh will be calculated automatically based on your energy market price (1 hour interval) together with an additional surcharge price you set manually.'
        )
      : t(
          'advancedTariff.baseTariffDescription',
          'Set your base tariff system that will be in use for your connector.'
        );

  return (
    <BaseCostSettingsProvider value={value}>
      <SelectTariffSystem
        tariffSystem={tariffSystem}
        setTariffSystem={onChangeTariffSystem}
      />
      <AdvancedTariffComponentCollapsableForm
        currency={currency}
        title={baseTariffName}
        editTitle={editBaseTariffName}
        info={
          <>
            <AdvancedTariffsFeatureAlert tariffSystem={tariffSystem} />
            <p>{baseTariffDescription}</p>
          </>
        }
        value={baseTariff}
        onChange={(next) => onFallbackChange(next)}
        isEditing={getIsInEditMode(baseTariff)}
        onIsEditingChange={(isEditing) => setIsInEditMode(value, isEditing)}
        tariffSystem={tariffSystem}
      />

      {value.components?.map?.((component, index) => (
        <AdvancedTariffComponentCollapsableForm
          key={component._uiKey}
          anchorRef={(ref) => collectAnchorRef(component, ref)}
          title={scheduledTariffName}
          editTitle={editScheduledTariffName}
          info={
            <p>
              {t(
                'advancedTariff.scheduledTariffInfo',
                'A schedule tariff will override your Base Tariff system for a specified time period.'
              )}
              <br />
              {t(
                'advancedTariff.scheduledTariffNote',
                'Note that your schedule tariffs can not overlap'
              )}
              .
            </p>
          }
          value={component}
          onChange={(next) => onComponentChange(next, index)}
          onRemove={(e) => onRemoveComponent(e, index)}
          isEditing={getIsInEditMode(component)}
          onIsEditingChange={(isEditing) =>
            setIsInEditMode(component, isEditing)
          }
          defaultExpanded={component._uiIsNew}
          currency={currency}
        />
      ))}

      {tariffSystem === 'scheduled' && (
        <ActionButton
          primary
          compact
          floated={'left'}
          onClick={onAddNewComponent}>
          <Icon name={'plus'} />{' '}
          {t(
            'advancedTariff.addScheduledTariff',
            'Add a scheduled tariff system'
          )}
        </ActionButton>
      )}

      <br />
    </BaseCostSettingsProvider>
  );
}

function AdvancedTariffComponentCollapsableForm({
  title,
  editTitle,
  info,
  value,
  onChange,
  onRemove,
  isEditing,
  onIsEditingChange,
  anchorRef,
  currency,
  tariffSystem,
}) {
  const { t } = useTranslation();
  const isBaseCostSetting = useIsBaseCostSettings(value.costSettings);

  const { costSettings, restrictions } = value;

  const handleCostSettingsChange = (costSettings) => {
    onChange({ ...value, costSettings, _uiIsNew: false });
  };

  const handleRestrictionsChange = (restrictions) => {
    onChange({ ...value, restrictions, _uiIsNew: false });
  };

  const summaryRows = useSummaryRows(value, currency, isBaseCostSetting);

  const handleClickEdit = (e) => {
    shortCircuitEvent(e);
    onIsEditingChange(!isEditing);
  };

  const handleClickRemove = (e) => {
    shortCircuitEvent(e);
    onRemove();
  };

  return (
    <Segment ref={anchorRef}>
      <Grid>
        <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Header as={'h4'} style={{ marginTop: '6px' }}>
              {isEditing ? editTitle : title}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign={'right'}>
            <>
              <ActionButton primary compact onClick={(e) => handleClickEdit(e)}>
                {!isEditing && (
                  <>
                    <Icon name={'edit'} />
                    {t('advancedTariff.edit', 'Edit')}
                  </>
                )}
                {isEditing && (
                  <>
                    <Icon name={'minimize'} />
                    {t('advancedTariff.minimize', 'Minimize')}
                  </>
                )}
              </ActionButton>
              {onRemove && (
                <ActionButton
                  primary
                  compact
                  onClick={(e) => handleClickRemove(e)}>
                  <Icon name={'trash'} />
                  {t('advancedTariff.remove', 'Remove')}
                </ActionButton>
              )}
            </>
          </Grid.Column>
        </Grid.Row>
        {isEditing && (
          <>
            <Grid.Row columns={1}>
              <Grid.Column>
                <>
                  {info}

                  <CostSettingsForm
                    currency={currency}
                    value={costSettings}
                    onChange={(next) => handleCostSettingsChange(next)}
                    tariffSystem={tariffSystem}
                  />
                  {restrictions && (
                    <>
                      <br />
                      <Header as={'h4'}>
                        {t('advancedTariff.scheduleSetup', 'Schedule Setup')}
                      </Header>

                      <p>
                        {t(
                          'advancedTariff.scheduleSetupNote',
                          'Set a custom time schedule when you want your tariff to be in effect.'
                        )}
                      </p>
                      <RestrictionsForm
                        currency={currency}
                        value={restrictions}
                        onChange={(next) => handleRestrictionsChange(next)}
                      />
                    </>
                  )}
                </>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {!isEditing && (
          <>
            {summaryRows.map((row) => (
              <SummaryGridRow
                key={row.uiKey}
                row={row.columns}
                currency={currency}
                isBaseTariff={isBaseCostSetting}
              />
            ))}
          </>
        )}
      </Grid>
    </Segment>
  );
}

export function useSummaryRows(value, currency, isBaseTariff) {
  const costSettingsRow = useCostSettingsSummaryRow(
    value.costSettings,
    currency,
    isBaseTariff
  );
  const restrictionsRow = useRestrictionsSummaryRow(value.restrictions);

  // If there are no restrictions, we can just return the cost settings row
  if (!value.restrictions) {
    return [costSettingsRow];
  }

  // If there are 3 or fewer columns, we can merge the two rows
  if (costSettingsRow.columns.length + restrictionsRow.columns.length <= 3) {
    return [
      {
        uiKey: costSettingsRow.uiKey,
        columns: [...costSettingsRow.columns, ...restrictionsRow.columns],
      },
    ];
  }

  // Otherwise we return both rows
  return [costSettingsRow, restrictionsRow];
}
