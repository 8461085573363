import React from 'react';
import { Dropdown } from 'semantic';
import InspectObject from 'components/modals/InspectObject';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { Confirm } from 'components';

export default function TariffNotesActions({ item, onReload } = {}) {
  const { t } = useTranslation();

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/tariff-notes/${item.id}`,
    });
  }

  return (
    <>
      <InspectObject
        name="EVSE"
        data={item}
        trigger={<Dropdown.Item icon="file-code" content="Inspect Object" />}
      />
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: `Tariff Note for operator: ${item.partyId}` }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item
            icon="trash"
            text={t('deleteDialog.button', 'Delete')}
          />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />
    </>
  );
}
