import { determineCoordinatorStatus } from '../utils/evse-controllers';
import { Label } from '../semantic';
import { fromNow } from 'utils/date';
import React from 'react';

export function ConnectivityStatus({
  item,
  oneLine = false,
  showUpdatedAt = true,
}) {
  const { connectivityStateUpdatedAt, content, title, color } =
    determineCoordinatorStatus(item);

  const style = {
    marginLeft: '5px',
    display: oneLine ? 'inline-block' : 'block',
  };

  return (
    <>
      <Label title={title} content={content} color={color} />
      {connectivityStateUpdatedAt && showUpdatedAt && (
        <small style={style}>
          Updated: {fromNow(connectivityStateUpdatedAt)}
        </small>
      )}
    </>
  );
}
