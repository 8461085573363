import React, { useState } from 'react';
import {
  Button,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Label,
  Modal,
  Table,
  Segment,
} from 'semantic';
import { useTranslation } from 'react-i18next';

import ErrorMessage from 'components/ErrorMessage';
import { request } from 'utils/api';
import AsyncModal from 'helpers/async-modal';
import Divider from 'components/Sidebar/Divider';
import Confirm from 'components/Confirm';
import useUserHasAccessToEvseControllersIntegrations from '../useUserHasAccessToEvseControllersIntegrations';
import useFetch from 'hooks/useFetch';
import { EvseControllerIntegrations } from 'types/evse-controller-integrations';
import { useUser } from 'contexts/user';

type CsmeClientsData = {
  clients: {
    id: string;
    name: string;
  }[];
};

type Props = {
  item: {
    id: string;
    evseId: string;
    location: {
      name: string;
      userId?: string;
    };
    integrations?: EvseControllerIntegrations;
  };
  onSave: () => void;
  close: () => void;
};

function Pair(props: Props): JSX.Element | null {
  const { item } = props;
  const { t } = useTranslation();
  const [pairingLoading, setPairingLoading] = useState(false);
  const [pairingError, setPairingError] = useState<Error | null>(null);
  const [unpairingError, setUnpairingError] = useState<Error | null>(null);
  const integrations = item?.integrations?.csmePairings || [];
  const hasIntegrations = integrations.length > 0;
  const [isFormEnabled, setIsFormEnabled] = useState(!hasIntegrations);
  const userHasAccessToEvseControllersIntegrations =
    useUserHasAccessToEvseControllersIntegrations('read-write');
  const { user } = useUser();
  const [newIntegration, setNewIntegration] = useState<{
    clientId: string;
    pairingCode: string;
  }>({
    clientId: '',
    pairingCode: '',
  });
  const {
    data: csmeClientsData,
    loading: csmeClientsLoading,
    error: csmeClientsError,
  } = useFetch<CsmeClientsData>({
    path: `/1/evse-controllers/${item.id}/csme-clients`,
    onCompleted: ({ data }) =>
      setNewIntegration({
        clientId: data?.clients[0]?.id || '',
        pairingCode: '',
      }),
  });

  const setField = (name: string, value: any) => {
    setNewIntegration((prevNewIntegration) => ({
      ...prevNewIntegration,
      [name]: value,
    }));
  };

  const unpair = async (pairingId: string) => {
    // `setPairingLoading` is ok to use be for unpairing
    // but `setUnpairingError` instead of `setPairingError` is required
    // because we should show errors in different places for those actions
    setPairingLoading(true);
    setUnpairingError(null);
    setPairingError(null);

    try {
      await request({
        method: 'DELETE',
        path: `/1/evse-controllers/${item.id}/csme-pairing/${pairingId}`,
      });

      props.onSave();
    } catch (error) {
      setUnpairingError(error as Error);
    } finally {
      setPairingLoading(false);
    }
  };

  const onSubmit = async () => {
    setPairingLoading(true);
    setPairingError(null);
    setUnpairingError(null);

    try {
      await request({
        method: 'POST',
        path: `/1/evse-controllers/${item.id}/csme-pairing`,
        body: {
          clientId: newIntegration.clientId,
          pairingCode: newIntegration.pairingCode,
        },
      });

      props.onSave();
    } catch (error) {
      setPairingError(error as Error);
    } finally {
      setPairingLoading(false);
    }
  };

  if (!item) return null;

  const csmeClients = csmeClientsData?.clients || [];
  const hasCsmeClients = csmeClients.length > 0;

  const canChange =
    item.location.userId === user.id ||
    userHasAccessToEvseControllersIntegrations;
  const canUserPair = canChange && hasCsmeClients && isFormEnabled;
  const canUserOpenPairingForm = canChange && hasCsmeClients && !isFormEnabled;

  const integrationOptions = hasCsmeClients
    ? csmeClients.map((csmeClient) => ({
        text: csmeClient.name,
        value: csmeClient.id,
      }))
    : [];

  return (
    <>
      <Modal.Header>{t('integrations.modalTitle', 'Pairing')}</Modal.Header>
      <Modal.Content>
        <Grid divided="vertically">
          <GridRow columns={2}>
            <GridColumn>
              <strong>{t('integrations.labelLocation', 'Location')}</strong>
              <p style={{ paddingTop: '1rem' }}>{item.location.name}</p>
            </GridColumn>
            <GridColumn>
              <strong>{t('integrations.labelEvseId', 'EVSE ID')}</strong>
              <p style={{ paddingTop: '1rem' }}>{item.evseId}</p>
            </GridColumn>
          </GridRow>
        </Grid>
        <Divider />
        <h3>{t('integrations.modalPairTitle', 'Pairing Setup')}</h3>
        <ErrorMessage error={csmeClientsError} />
        {unpairingError && <ErrorMessage error={unpairingError} />}

        {hasIntegrations && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>
                  {t('integrations.columnIntegration', 'Integration')}
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  {t('integrations.columnStatus', 'Status')}
                </Table.HeaderCell>
                {canChange && (
                  <Table.HeaderCell width={2}>
                    {t('integrations.columnActions', 'Actions')}
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {integrations.map((integration) => {
                const integrationStatus = integration.isConfirmed
                  ? 'Paired'
                  : 'Pending';
                const colorLabelStatus =
                  integrationStatus === 'Paired' ? 'olive' : 'yellow';

                return (
                  <Table.Row key={integration.id}>
                    <Table.Cell>
                      <Label color="black">{integration.csmeClient.name}</Label>
                    </Table.Cell>
                    <Table.Cell>
                      <Label color={colorLabelStatus}>
                        {integrationStatus}
                      </Label>
                    </Table.Cell>
                    {canChange && (
                      <Table.Cell textAlign="center">
                        <Confirm
                          header={t('integrations.buttonUnpair', 'Unpair')}
                          content={t(
                            'integrations.modalUnpairContent',
                            'Are you sure you want to unpair charging station with this integration?'
                          )}
                          trigger={
                            <Button
                              basic
                              title={t('integrations.buttonUnpair', 'Unpair')}
                              content={t('integrations.buttonUnpair', 'Unpair')}
                              as="button"
                            />
                          }
                          onConfirm={() => unpair(integration.id)}
                          // following props are required but not used
                          button={undefined}
                          onOpen={undefined}
                          onClose={undefined}
                        />
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {canUserOpenPairingForm && (
          <a
            onClick={() => setIsFormEnabled(true)}
            style={{ color: '#ff3d00', cursor: 'pointer' }}>
            <strong>
              {t(
                'integrations.buttonAddAnotherIntegration',
                '+ Add another integration'
              )}
            </strong>
          </a>
        )}
        {canUserPair && (
          <Segment>
            <Form
              autoComplete="disabled"
              error={Boolean(pairingError)}
              onSubmit={onSubmit}
              id="pairing-form">
              <ErrorMessage error={pairingError} />
              <Form.Select
                value={newIntegration.clientId}
                options={integrationOptions}
                name="clientId"
                label={t('integrations.formFieldIntegration', 'Integration')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Form.Input
                value={newIntegration.pairingCode}
                name="pairingCode"
                label={t('integrations.formFieldPairingCode', 'Pairing Code')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />
              <Button
                loading={pairingLoading}
                disabled={pairingLoading}
                primary
                content={t('integrations.buttonPair', 'Pair')}
                onClick={onSubmit}
                style={{ width: '110px' }}
                as="button"
              />
            </Form>
          </Segment>
        )}
        {csmeClientsLoading && (
          <a style={{ color: '#ff3d00', cursor: 'pointer' }}>
            <strong>{t('loading.loading', 'loading...')}</strong>
          </a>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={props.close}
          primary
          content={t('button.close', 'Close')}
          form="edit-account-form"
          style={{ width: '110px' }}
          as="button"
        />
      </Modal.Actions>
    </>
  );
}

export default AsyncModal(Pair);
