import legacyMenuRoutes from 'helpers/routes/LegacyMenuRoutes';
import systemMenuRoutes from 'helpers/routes/SystemMenuRoutes';
import { RoutesContext } from 'interfaces/interfaces';

export default function useMenuRoutes(routesContext: RoutesContext = 'legacy') {
  switch (routesContext) {
    case 'legacy':
      return legacyMenuRoutes;
    case 'system':
      return systemMenuRoutes;
    default:
      return legacyMenuRoutes;
  }
}
