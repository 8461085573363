import React from 'react';

import { Button } from 'semantic';

import EditCardName from 'components/modals/EditCardName';
import { useTranslation } from 'react-i18next';

import { Confirm } from 'components';

import { request } from 'utils/api';

export default function MyCardsActions({ item, onReload } = {}) {
  const { t } = useTranslation();

  function onToggleBlock(block) {
    return request({
      method: 'POST',
      path: `/1/cards/${item.id}/${block ? 'block' : 'unblock'}`,
    });
  }

  return (
    <>
      {item.tokenId && item.token && item.status === 'active' && (
        <Confirm
          header={t(
            'cardBlock.header',
            'Are you sure you want block {{name}}?',
            { name: item.nameOnCard }
          )}
          content={t('cardBlock.content', 'This card will be block')}
          trigger={
            <Button
              basic
              icon="ban"
              disabled={item.token && item.token.isBusy}
              content={t('cardBlock.button', 'Block Card')}
              style={{
                whiteSpace: 'nowrap',
              }}
            />
          }
          onConfirm={async () => {
            await onToggleBlock(true);
            await onReload();
          }}
          button={t('cardBlock.button', 'Block Card')}
        />
      )}
      {item.tokenId && item.token && item.status === 'disabled' && (
        <Confirm
          header={t(
            'cardUnblock.header',
            'Are you sure you want unblock {{name}}?',
            { name: item.nameOnCard }
          )}
          content={t('cardUnblock.content', 'This card will be unblock')}
          trigger={
            <Button
              basic
              icon="circle-play"
              disabled={item.token && item.token.isBusy}
              content={t('cardUnblock.button', 'Unblock card')}
              style={{
                width: '144px',
                textAlign: 'left',
              }}
            />
          }
          button={t('cardUnblock.button', 'Unblock card')}
          onConfirm={async () => {
            await onToggleBlock(false);
            await onReload();
          }}
        />
      )}

      {item.tokenId &&
        item.token &&
        (item.status === 'pending' || item.status === 'sent') && (
          <Button
            basic
            icon="ban"
            disabled
            content={t('cardBlock.button', 'Block Card')}
            style={{
              width: '144px',
              textAlign: 'left',
            }}
          />
        )}

      <EditCardName
        initialValues={item}
        onClose={() => onReload()}
        trigger={<Button basic icon="pen-to-square" title="Rename" />}
      />
    </>
  );
}
