import React, { useContext } from 'react';
import { Button, Confirm, Label, Table, Popup } from 'semantic';
import * as Search from 'components/search';
import { formatDateTime } from 'utils/date';
import { withTranslation } from 'react-i18next';
import { Text } from '../StyledComponents';

function SyncStatusTable({ t, handleOcpiSync }) {
  const { items, loading } = useContext(Search.Context);

  if (!items?.length || loading) return null;

  return (
    <>
      <Confirm
        header={t('syncStatusModal.warningSyncAll', 'Request sync for all?')}
        trigger={<Button content="Resync all" />}
        onConfirm={() => handleOcpiSync()}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('syncStatusModal.credentialName', 'Credential Name')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('syncStatusModal.pushedAt', 'Pushed at')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              {t('syncStatusModal.success', 'Success')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              {t('syncStatusModal.headerActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((row) => (
            <Table.Row key={row.credentialName}>
              <Table.Cell>
                <Text>{row.credentialName}</Text>
              </Table.Cell>
              <Table.Cell>
                <Text>{row.pushedAt ? formatDateTime(row.pushedAt) : '-'}</Text>
              </Table.Cell>
              <Table.Cell textAlign="center">
                {row.success === true ? (
                  <Label
                    content="Synced"
                    color="olive"
                    title="Successfully synced"
                  />
                ) : row.success === false ? (
                  <Popup
                    trigger={
                      <Label
                        style={{ cursor: 'pointer' }}
                        content="Failed"
                        color="red"
                        title="Error message"
                      />
                    }
                    content={row.error?.message}
                    hoverable
                  />
                ) : (
                  <Label content="Pending" color="grey" title="Error message" />
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {!row.credentialId ? (
                  <Confirm
                    header={t(
                      'syncStatusModal.warningSyncAll',
                      'Request sync for all?'
                    )}
                    content={t(
                      'syncStatusModal.warningSyncAllContent',
                      'CredentialId missing from sync status, this will request sync for all credentials!'
                    )}
                    trigger={
                      <Button
                        basic
                        size="small"
                        icon="arrows-rotate"
                        title="Request sync"
                      />
                    }
                    onConfirm={() => handleOcpiSync()}
                  />
                ) : (
                  <Button
                    basic
                    size="small"
                    icon="arrows-rotate"
                    title="Request sync"
                    onClick={() => handleOcpiSync(row.credentialId)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

export default withTranslation()(SyncStatusTable);
