import React, { useContext } from 'react';
import { Button, Label, Table } from 'semantic';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/date';
import * as Search from 'components/search';
import AuditTrailModal from 'screens/AuditTrail/AuditTrailModal';
import ExpandableJson from 'components/ExpandableJson';
import objectDiff from 'utils/objectDiff';
import { formatCardStatus, formatTokenStatus } from 'utils/formatting';
import { withTranslation } from 'react-i18next';
import { startCase } from 'lodash-es';
import { NarrowTable } from 'components/modals/SyncLogStatus/StyledComponents';

function TokenAuditTable({ t }) {
  const { items, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  return (
    <NarrowTable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User/Credential</Table.HeaderCell>
          <Table.HeaderCell>Account</Table.HeaderCell>
          <Table.HeaderCell>Provider</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Activity</Table.HeaderCell>
          <Table.HeaderCell>Object Type</Table.HeaderCell>
          <Table.HeaderCell>Object Differences</Table.HeaderCell>
          <Table.HeaderCell>Status After</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items.map((auditEntry) => (
          <Table.Row key={auditEntry.id}>
            <Table.Cell>
              {auditEntry?.user && (
                <Link to={`/accounts/users/${auditEntry?.user._id}`}>
                  {auditEntry?.user.contact.firstName}{' '}
                  {auditEntry?.user.contact.lastName}
                </Link>
              )}
              {auditEntry?.credential && (
                <Link to={`/credentials/${auditEntry?.credential?._id}`}>
                  {auditEntry?.credential?.name}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>
              {auditEntry?.account && (
                <Link to={`/accounts/${auditEntry.account._id}`}>
                  {auditEntry.account.name}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>
              {auditEntry?.provider && (
                <Link to={`/providers/${auditEntry.provider._id}`}>
                  {auditEntry.provider.name}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>{formatDateTime(auditEntry.createdAt)}</Table.Cell>
            <Table.Cell>
              <Label>{startCase(auditEntry.activity)}</Label>
            </Table.Cell>
            <Table.Cell>
              <Label>{auditEntry.objectType}</Label>
            </Table.Cell>
            <Table.Cell>
              {auditEntry.objectAfter && auditEntry.objectBefore ? (
                <ExpandableJson
                  object={objectDiff(
                    auditEntry.objectAfter,
                    auditEntry.objectBefore
                  )}
                  name="Differences"
                />
              ) : (
                <p>No object snapshots...</p>
              )}
            </Table.Cell>
            <Table.Cell>
              {auditEntry.objectType === 'Token' &&
                formatTokenStatus(
                  auditEntry.objectAfter?.isActive,
                  auditEntry.objectAfter?.isBlocked,
                  t
                )}
              {auditEntry.objectType === 'Card' &&
                formatCardStatus(
                  auditEntry.objectAfter?.status || 'unknown',
                  t
                )}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <AuditTrailModal
                auditEntry={auditEntry}
                trigger={<Button basic size="small" icon="list" />}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </NarrowTable>
  );
}

export default withTranslation()(TokenAuditTable);
