import React, { useEffect, useState } from 'react';
import { Form, Message } from 'semantic';
import PriceInput from 'components/form-fields/Price';
import { useTranslation } from 'react-i18next';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { useEnableEnergyMarketTrackingState } from 'components/tariffs/AdvancedTariff/BaseCostSettings';
import { CostSettingsFormProps } from 'components/tariffs/AdvancedTariff/CostSettings/Form';
import PricePerIdleMinute from 'components/tariffs/AdvancedTariff/CostSettings/PricePerIdleMinute';

export default function CostSettingsStaticForm({
  value: costSettings = {},
  onChange = (nextCostSettings) => undefined,
  currency,
}: CostSettingsFormProps) {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const [pricePerKwh, setPricePerKwh] = useState<number>(
    costSettings?.pricePerKwh
  );
  const [pricePerSession, setPricePerSession] = useState(
    costSettings?.pricePerSession
  );
  const [pricePerHour, setPricePerHour] = useState<number>(
    costSettings?.pricePerHour
  );
  const [pricePerIdleMinute, setPricePerIdleMinute] = useState<number>(
    costSettings?.pricePerIdleMinute
  );
  const [idleGracePeriodMinutes, setIdleGracePeriodMinutes] = useState<number>(
    costSettings?.idleGracePeriodMinutes || 30
  );

  const disablePricePerSession =
    !advancedTariffFeatures.sessionFee && !pricePerSession;
  const disablePricePerHour = !advancedTariffFeatures.timeFee && !pricePerHour;

  const [enableEnergyMarketTracking, setEnableEnergyMarketTracking] =
    useEnableEnergyMarketTrackingState(costSettings);

  const [surchargePerKwh, setSurchargePerKwh] = useState(
    costSettings?.surchargePerKwh || 0
  );

  const [enableIdleFee, setEnableIdleFee] = useState(
    !!costSettings?.pricePerIdleMinute
  );

  useEffect(() => {
    onChange?.({
      pricePerKwh,
      pricePerSession,
      pricePerHour,
      pricePerIdleMinute: enableIdleFee ? pricePerIdleMinute : 0,
      idleGracePeriodMinutes,
      enableEnergyMarketTracking,
      surchargePerKwh,
      _uiKey: costSettings._uiKey,
    });
  }, [
    pricePerKwh,
    pricePerSession,
    pricePerHour,
    pricePerIdleMinute,
    idleGracePeriodMinutes,
    enableIdleFee,
    enableEnergyMarketTracking,
    surchargePerKwh,
  ]);

  return (
    <>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <label>{t('advancedTariff.pricePerKwh', 'Price per kWh')}</label>
          <PriceInput
            value={pricePerKwh}
            onChange={setPricePerKwh}
            currency={currency}
          />
        </Form.Field>

        <Form.Field>
          <label>
            {t('advancedTariff.pricePerSession', 'Price per Session')}
          </label>
          <PriceInput
            value={pricePerSession}
            onChange={setPricePerSession}
            currency={currency}
            disabled={disablePricePerSession}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('advancedTariff.pricePerHour', 'Price per Hour')}</label>
          <PriceInput
            value={pricePerHour}
            onChange={setPricePerHour}
            currency={currency}
            disabled={disablePricePerHour}
          />
          <i style={{ fontSize: 12, float: 'left', marginTop: 6 }}>
            {t(
              'advancedTariff.timeCapMessage',
              '*Maximum cost may be applied per session according to roaming contracts'
            )}
          </i>
        </Form.Field>
      </Form.Group>

      {advancedTariffFeatures.idleFee && (
        <>
          <PricePerIdleMinute
            enableIdleFee={enableIdleFee}
            setEnableIdleFee={setEnableIdleFee}
            pricePerIdleMinute={pricePerIdleMinute}
            setPricePerIdleMinute={setPricePerIdleMinute}
            idleGracePeriodMinutes={idleGracePeriodMinutes}
            setIdleGracePeriodMinutes={setIdleGracePeriodMinutes}
            currency={currency}
          />
        </>
      )}

      <Message
        info
        content={t(
          'advancedTariff.accessPolicyMessage',
          'Note the hour tariff and session tariff can only be used for public locations, where the reimbursement is paid out to a business entity.'
        )}
      />
    </>
  );
}
