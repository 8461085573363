import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  Table,
  Loader,
  Segment,
  Message,
  Divider,
  Label,
  Button,
  Modal,
} from 'semantic';
import { formatDateTime } from 'utils/date';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import EvseIdLink from 'components/modals/EvseIdLink';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const writeAccess = currentUserCanAccessProviderEndpoint(
      'evseIds',
      'write'
    );

    const { status, data: items, meta = {}, page } = this.props;

    const itemsMapped = items.map((i) => {
      switch (i.source) {
        case 'in-life': {
          i.sourceLabel = 'In Life';
          i.sourceDescription = 'Created after EVSE was already registered';
          i.sourceColor = 'grey';
          break;
        }
        case 'registration': {
          i.sourceLabel = 'Registration';
          i.sourceDescription = 'Created during EVSE registration';
          i.sourceColor = 'grey';
          break;
        }
        default: {
          i.sourceLabel = 'Unknown';
          i.sourceDescription = 'Unknown';
          i.sourceColor = 'grey';
        }
      }
      return i;
    });

    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>There are no pending EVSE ID assignment requests.</Message>
        )}

        {status.success && items.length > 0 && (
          <div>
            <p>Total number of requests: {meta.total}</p>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>EVSE Controller</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell>Account</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="center">
                    Requested At
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    Source
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4} textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {itemsMapped.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        {item.evseController && (
                          <>
                            <Link
                              to={`/charging-stations/${item.evseController.id}`}
                              title={`Serial Number: ${item.evseController.serialNumber}`}>
                              {item.evseController.ocppIdentity}
                            </Link>
                            <p>
                              <small>
                                Evse ID: {item.evseController.evseId || '-'}
                              </small>{' '}
                              <br />
                              <small>
                                Serial number:{' '}
                                {item.evseController.serialNumber || '-'}
                              </small>
                            </p>
                          </>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {item.evseController?.location ? (
                          <>
                            <Link
                              to={`/charging-stations/locations/${item.evseController.location.id}`}>
                              {item.evseController.location.name}
                            </Link>{' '}
                          </>
                        ) : (
                          '-'
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {item.evseController?.account && (
                          <>
                            Account:{' '}
                            <Link
                              to={`/accounts/${item.evseController.account.id}`}>
                              {item.evseController.account.name}
                            </Link>
                            <br />
                          </>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatDateTime(item.createdAt)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {item.source && (
                          <Label
                            title={item.sourceLabel}
                            content={item.sourceLabel}
                            color={item.sourceColor}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {item.completed && (
                          <Label
                            title={
                              'Completed ' + formatDateTime(item.completedAt)
                            }
                            content={'Completed'}
                            color={'green'}
                          />
                        )}
                        {!item.completed && (
                          <Label
                            title={'Pending'}
                            content={'Pending'}
                            color={'yellow'}
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <EvseIdLink
                          evseIdAssignmentRequest={item}
                          onComplete={() => this.props.onRefresh()}
                          trigger={
                            <Button
                              disabled={!writeAccess}
                              basic
                              title={item.completed ? 'View' : 'Assign'}
                              content={item.completed ? 'View' : 'Assign'}
                            />
                          }
                        />
                        <Modal
                          header={`Are you sure you want to delete this request?`}
                          content="All data will be permanently deleted"
                          trigger={
                            <Button
                              disabled={!writeAccess}
                              icon="trash"
                              title="Delete"
                              color="red"
                            />
                          }
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.props.onDeleteItem(item),
                            },
                          ]}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
        <Divider hidden />
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Loader active>Loading</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
