import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, FormField } from 'semantic';
import { User } from 'types/user';
import * as Yup from 'yup';
import styles from './styles.module.less';
import { useUser } from 'contexts/user';
import { request } from 'utils/api';
import InputField from 'components/form-fields/formik/InputField';

type NewCardHolderInfoProps = {
  showErrorLabels?: boolean;
};

export function NewCardHolderInfo(props: NewCardHolderInfoProps) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.responsiveWidth}`}>
      <Form.Group widths={8} className={styles.rowResetPaddingBottom}>
        <FormField required width={8}>
          <label>{t('addNewUser.firstName', 'First Name')}</label>
          <InputField
            name="cardHolder.firstName"
            required
            validateImmediately
            hideErrorLabel={!props.showErrorLabels}
          />
        </FormField>
        <FormField required width={8} className={styles.newUserFieldLastName}>
          <label>{t('addNewUser.lastName', 'Last Name')}</label>
          <InputField
            name="cardHolder.lastName"
            required
            validateImmediately
            hideErrorLabel={!props.showErrorLabels}
          />
        </FormField>
      </Form.Group>
      <Form.Field
        required
        className={`${styles.rowResetPaddingTop} ${styles.newUserFieldEmail}`}>
        <label>{t('addNewUser.email', 'Email')}</label>
        <InputField
          name="cardHolder.email"
          required
          validateImmediately
          hideErrorLabel={!props.showErrorLabels}
        />
      </Form.Field>
    </div>
  );
}

type SelectCardHolderProps = {
  value: string;
  onChange: (value: string, user?: User) => void;
};

export function SelectCardHolder(props: SelectCardHolderProps) {
  const cardHolderOptions = useCardHolderOptions();
  return (
    <Dropdown
      clearable
      selection
      search
      error={cardHolderOptions.isError}
      loading={cardHolderOptions.isLoading}
      value={props.value}
      options={cardHolderOptions.data || []}
      onChange={(e, { value }) => {
        const user = cardHolderOptions.data?.find(
          (c) => c.value === value
        )?.user;
        props.onChange?.(value as string, user);
      }}
    />
  );
}

type CardHolderOption = {
  user: User;
  key: string;
  value: string;
  text: string;
};

export function useCardHolderOptions() {
  const { t } = useTranslation();
  const { user } = useUser();
  return useQuery<CardHolderOption[], unknown, CardHolderOption[]>({
    queryKey: ['card-holder-options', user?.id],
    queryFn: () =>
      request({
        path: '/1/users/search/fast',
        method: 'POST',
        body: {
          accountId: user?.accountId,
        },
      }).then(({ data }: { data: User[] }) =>
        data.map((obj) => {
          return {
            user: obj,
            key: obj.id,
            value: obj.id,
            text:
              obj.id === user?.id
                ? `${obj.name} (${t('labels.myself', 'MYSELF')})`
                : obj.name,
          };
        })
      ),
    staleTime: Infinity,
  });
}

export function useCardHolderInfoFormikValidationSchema() {
  const { t } = useTranslation();
  return {
    type: Yup.string().required().oneOf(['new', 'existing']),
    userId: Yup.string().when('type', {
      is: 'existing',
      then: (schema) =>
        schema.required(t('requestCardModel.userRequired', 'User is required')),
    }),
    firstName: Yup.string().when('type', {
      is: 'new',
      then: (schema) =>
        schema.required(
          t('requestCardModel.firstNameRequired', 'First name is required')
        ),
    }),
    lastName: Yup.string().when('type', {
      is: 'new',
      then: (schema) =>
        schema.required(
          t('requestCardModel.lastNameRequired', 'Last name is required')
        ),
    }),
    email: Yup.string()
      .email(t('requestCardModel.emailInvalid', 'Invalid email address'))
      .when('type', {
        is: 'new',
        then: (schema) =>
          schema.required(
            t('requestCardModel.emailRequired', 'Email is required')
          ),
      }),
  };
}
