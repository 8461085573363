import React, { Component } from 'react';
import { Icon, Modal, Header, Button } from 'semantic';

import CircularIcon from './CircularIcon';

export default class HelpTip extends Component {
  state = { open: false };

  close = (e) => {
    e?.stopPropagation();
    this.setState({ open: false });
  };
  open = (e) => {
    e.stopPropagation();
    this.setState({ open: true });
  };

  icon(icon, color, iconCircular) {
    if (!this.props.children) {
      if (iconCircular) {
        return <CircularIcon icon={icon} color={color} onClick={this.open} />;
      }

      return (
        <Icon
          name={icon}
          style={{ color, cursor: 'pointer' }}
          onClick={this.open}
        />
      );
    }
    return React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClick: this.open });
      }
      return child;
    });
  }

  render() {
    const {
      icon,
      title,
      text,
      style,
      float = 'right',
      color = '#ccc',
      marginLeft = '0.2em',
      marginTop = '3px',
      iconTooltip = 'circle-question',
      iconCircular = false,
      disabledIconTitle = false,
    } = this.props;
    return (
      <div
        style={{
          display: 'block',
          marginLeft,
          marginTop,
          float,
          ...(style || {}),
        }}>
        {this.icon(iconTooltip, color, iconCircular)}
        {this.state.open && (
          <Modal
            open={true}
            size="small"
            closeOnEscape={true}
            closeOnDimmerClick={true}
            onClose={this.close}>
            <Header
              icon={disabledIconTitle ? undefined : icon || 'circle-info'}
              content={title || 'Explanation'}
              style={{ display: 'flex', alignItems: 'center' }}
            />
            <Modal.Content>{text}</Modal.Content>
            <Modal.Actions>
              {this.props.actions?.map((action, index) =>
                React.cloneElement(action, {
                  key: index,
                  handleClose: () => {
                    this.close();
                  },
                })
              )}
              <Button basic onClick={this.close}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}
