import React from 'react';
import { Divider, Menu } from 'semantic';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';

import Overview from 'components/analytics-screens/msp/Overview';
import Transactions from 'components/analytics-screens/msp/Transactions';
import Financials from 'components/analytics-screens/msp/Financials';
import Locations from 'components/analytics-screens/msp/Locations';
import Equipment from 'components/analytics-screens/msp/Equipment';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { request } from 'utils/api';

class MspAnalytics extends React.Component {
  state = {};
  render() {
    const { t } = this.props;

    const screens = [
      {
        id: 'overview',
        name: t('common.overview', 'Overview'),
        component: Overview,
      },
      {
        id: 'transactions',
        name: t('common.transactions', 'Transactions'),
        component: Transactions,
      },
      {
        id: 'financials',
        name: t('common.financials', 'Financials'),
        component: Financials,
      },
      {
        id: 'locations',
        name: t('analyticsMsp.locationsAndProviders', 'Locations & Providers'),
        component: Locations,
      },
      {
        id: 'equipment',
        name: t('common.equipment', 'Equipment'),
        component: Equipment,
      },
    ];

    return (
      <div>
        <Breadcrumbs
          path={[
            <Link key="link" to="/analytics">
              {t('analytics.title', 'Analytics')}
            </Link>,
          ]}
          active={t('menu.analyticsMsp', 'MSP')}
        />
        <div style={{ float: 'right' }}>
          <SearchDropdown
            value={this.state.accountId}
            objectMode={false}
            placeholder="All Accounts"
            onDataNeeded={(body) => {
              return request({
                path: '/1/accounts/search',
                method: 'POST',
                body,
              });
            }}
            clearable
            onChange={(e, { value: accountId }) => this.setState({ accountId })}
          />
        </div>
        {this.renderMenu(screens)}
        <Divider hidden />
        <Switch>
          {screens.map((screen) => {
            return (
              <Route
                key={screen.id}
                exact
                path={
                  screen.id === 'overview'
                    ? `/analytics/msp`
                    : `/analytics/msp/${screen.id}`
                }
                render={() => (
                  <screen.component
                    baseFilter={
                      this.state.accountId
                        ? { terms: [{ accountId: this.state.accountId }] }
                        : {}
                    }
                  />
                )}
              />
            );
          })}
        </Switch>
      </div>
    );
  }

  renderMenu(screens) {
    return (
      <Menu fluid pointing secondary>
        {screens.map(({ id, name }) => {
          return (
            <Menu.Item
              key={id}
              exact
              content={name}
              to={id === 'overview' ? `/analytics/msp` : `/analytics/msp/${id}`}
              as={NavLink}
            />
          );
        })}
      </Menu>
    );
  }
}

export default withTranslation()(MspAnalytics);
