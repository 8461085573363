import React, { ReactNode } from 'react';
import { useFeatures } from 'contexts/features';
import { usePlatformModules } from 'contexts/platformModules';
import { useMemo } from 'react';
import {
  AdvancedTariffFeatures,
  AdvancedTariffFeaturesContext,
} from 'components/tariffs/features';
import { useAccountPlatformFeatures } from 'components/account-platform-features/context';

const ReleaseFeatureFlags = {
  DateRange: 'advanced_tariffs_date_range',
  EmployeeReimburseTariffLimits: 'employee_reimburse_tariff_limits',
  DynamicTariff: 'tariff_profiles_dynamic',
};

const PlatformFeatureFlags = {
  AdvancedTariffs: 'advanced-tariffs',
  Scheduled: 'advanced-tariffs:scheduled',
  MarketTracker: 'advanced-tariffs:market-tracker',
  IdleFee: 'advanced-tariffs:idle',
};

interface EvseTariffFeatureProviderProps {
  children: ReactNode;
}

export function TariffProfileFeatureProvider({
  children,
}: EvseTariffFeatureProviderProps) {
  const { hasFeature, isReady } = useFeatures();
  const { hasPlatformModule } = usePlatformModules();
  const { accountHasPlatformFeature } = useAccountPlatformFeatures();

  const features: AdvancedTariffFeatures = useMemo(() => {
    return {
      advancedTariffs: hasPlatformModule(PlatformFeatureFlags.AdvancedTariffs),
      scheduled: accountHasPlatformFeature(PlatformFeatureFlags.Scheduled),
      dateRange: isReady && hasFeature(ReleaseFeatureFlags.DateRange),
      daysOfWeek: true,
      dynamicPricing:
        accountHasPlatformFeature(PlatformFeatureFlags.MarketTracker) &&
        hasFeature(ReleaseFeatureFlags.DynamicTariff),
      idleFee: accountHasPlatformFeature(PlatformFeatureFlags.IdleFee),
      sessionFee: true,
      timeFee: true,
    };
  }, [hasFeature, hasPlatformModule, accountHasPlatformFeature, isReady]);

  return (
    <AdvancedTariffFeaturesContext.Provider value={features}>
      {children}
    </AdvancedTariffFeaturesContext.Provider>
  );
}
