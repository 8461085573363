import React from 'react';
import { set } from 'lodash-es';
import { request } from 'utils/api';

import { Form, Message, Modal, Button } from 'semantic';

import { withTranslation } from 'react-i18next';

class EditLocation extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleSubmit = () => {
    const { initialValues } = this.props;
    this.setState({
      submitted: true,
      loading: true,
      error: null,
    });

    const { nameOnCard } = this.state.formValues;

    request({
      method: 'PATCH',
      path: `/1/cards/mine/${initialValues.id}`,
      body: {
        nameOnCard,
      },
    })
      .then(() => {
        this.setState({ loading: false });
        if (this.props.onClose) this.props.onClose();
        this.setState({
          formValues: this.props.initialValues,
          open: false,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    const { formValues } = this.state;
    set(formValues, name, value);
    this.setState({
      submitted: false,
      formValues,
    });
  }

  render() {
    const { initialValues, trigger, t } = this.props;
    const { formValues = {}, submitted, open, loading, error } = this.state;
    const isUpdate = !!initialValues.id;

    const title = t('editCard.nameOnCard');

    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        trigger={trigger}
        onClose={() => {
          if (this.props.onClose) this.props.onClose();
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false,
          });
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          <Form
            form="EditNameOnCard-form"
            error={submitted && Boolean(error)}
            onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Input
              value={formValues.nameOnCard || ''}
              name="nameOnCard"
              required
              label={t('editCard.nameOnCard')}
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={isUpdate ? t('common.update') : t('common.create')}
            form="EditNameOnCard-form"
            onClick={() => this.handleSubmit()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(EditLocation);
