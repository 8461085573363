import React, { useState } from 'react';

import { Message, Modal, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { EvseController } from 'types/evse-controller';
import {
  anyEvseControllersPendingFirstConnection,
  getEvseProtocols,
} from 'utils/evse-controllers';

type Props = {
  evseControllers: EvseController[];
  trigger: React.ReactNode;
};

export default function ConfirmWithWarningExecuteCommandBulk({
  evseControllers,
  trigger,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const sameProtocol = getEvseProtocols(evseControllers).length === 1;
  const hasEvseControllersPendingFirstConnection =
    anyEvseControllersPendingFirstConnection(evseControllers);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('executeCommandBulkListModal.title', 'Execute Commands')}
      </Modal.Header>
      <Modal.Content>
        {hasEvseControllersPendingFirstConnection && (
          <Message
            error
            icon="triangle-exclamation"
            header={t(
              'executeCommandBulkPendingFirstConnection.errorTitle',
              'Charging Stations pending first connection detected'
            )}
            content={t(
              'executeCommandBulkPendingFirstConnection.errorContent',
              'Some of the selected charging stations have not yet been connected to the backend. Please unselect them.'
            )}
          />
        )}
        {!sameProtocol && (
          <>
            <Message
              error
              icon="triangle-exclamation"
              header={t(
                'executeCommandBulkDifferentOCPPVersions.errorTitle',
                'Different OCPP versions for charging stations detected'
              )}
              content={t(
                'executeCommandBulkDifferentOCPPVersions.errorContent',
                'Some of the selected charging stations have different OCPP versions. You can only send commands to charging stations with the same protocol version.\nPlease review your selection and retry.'
              )}
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => setOpen(false)}
          content={t('executeCommandBulkListModal.back', 'Back')}
        />
      </Modal.Actions>
    </Modal>
  );
}
