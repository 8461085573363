import { BillingPlan } from '../../types/billingplan';

export const resolveBillingPlanType = (plan: BillingPlan) => {
  if (plan.providerContext === 'msp') {
    return 'Consumer Card';
  }

  if (plan.type === 'account-tier') {
    return 'Account Tier';
  }

  return 'Charging Station';
};
