import React from 'react';
import { AggregateTerms, DonutChart } from 'react-tectonic';
import { startCase } from 'lodash-es';

import { defaultColors } from 'utils/visualizations';

export default function TermsByTermsTable({
  collection,
  title,
  aggField,
  termsSize = 8,
  referenceFetch,
  operation,
  field,
  valueField,
  labelFormatter,
  filter,
  colors = defaultColors,
  timeRangeDateField = 'createdAt',
}) {
  return (
    <AggregateTerms
      collection={collection}
      aggField={aggField}
      termsSize={termsSize}
      referenceFetch={referenceFetch}
      operation={operation}
      field={field}
      timeRangeDateField={timeRangeDateField}
      filter={filter}>
      <DonutChart
        colors={colors}
        title={title}
        titleAlign="center"
        valueField={valueField}
        labelFormatter={
          labelFormatter || ((key) => startCase((key || '').toLowerCase()))
        }
        percent
      />
    </AggregateTerms>
  );
}
