import React, { useState } from 'react';
import { Label, Table, Modal } from 'semantic';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/date';
import { useTranslation } from 'react-i18next';
import ExpandableJson from 'components/ExpandableJson';
import objectDiff from 'utils/objectDiff';

export default function AuditTrailModal({ trigger, auditEntry }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Modal
      closeIcon
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        <span>{t('auditTrail.entry', 'Audit Trail entry')}</span>
      </Modal.Header>
      <Modal.Content>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Created</Table.Cell>
              <Table.Cell>{formatDateTime(auditEntry.createdAt)}</Table.Cell>
            </Table.Row>
            {auditEntry?.user && (
              <Table.Row>
                <Table.Cell>User/Credential</Table.Cell>
                <Table.Cell>
                  <Link to={`/accounts/users/${auditEntry.user._id}`}>
                    {auditEntry.user.contact.firstName}{' '}
                    {auditEntry.user.contact.lastName}
                  </Link>
                </Table.Cell>
              </Table.Row>
            )}
            {auditEntry?.credential && (
              <Table.Row>
                <Table.Cell>Credential</Table.Cell>
                <Table.Cell>
                  <Link to={`/credentials/${auditEntry.credential._id}`}>
                    {auditEntry.credential.name}
                  </Link>
                </Table.Cell>
              </Table.Row>
            )}
            {auditEntry?.account && (
              <Table.Row>
                <Table.Cell>Account</Table.Cell>
                <Table.Cell>
                  <Link to={`/accounts/${auditEntry.account._id}`}>
                    {auditEntry.account.name}
                  </Link>
                </Table.Cell>
              </Table.Row>
            )}
            {auditEntry?.provider && (
              <Table.Row>
                <Table.Cell>Provider</Table.Cell>
                <Table.Cell>
                  <Link to={`/providers/${auditEntry.provider._id}`}>
                    {auditEntry.provider.name}
                  </Link>
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>Activity</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.activity}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.type}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Object Type</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.objectType}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>ObjectId</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.objectId}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Request Method</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.requestMethod}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Normalized Path</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.routeNormalizedPath}</Label>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Request URL</Table.Cell>
              <Table.Cell>
                <Label>{auditEntry.requestUrl}</Label>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        {auditEntry.objectBefore && auditEntry.objectAfter && (
          <>
            <h3>Object changes:</h3>
            {auditEntry.objectBefore && (
              <>
                <h5>Object before:</h5>
                <ExpandableJson
                  object={auditEntry.objectBefore}
                  name="Before"
                />
              </>
            )}
            {auditEntry.objectAfter && (
              <>
                <h5>Object after:</h5>
                <ExpandableJson object={auditEntry.objectAfter} name="After" />
              </>
            )}
            <h5>Differences:</h5>
            <ExpandableJson
              object={objectDiff(
                auditEntry.objectAfter,
                auditEntry.objectBefore
              )}
              name="Differences"
            />
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}
