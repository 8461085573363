import React from 'react';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';

import { useTranslation } from 'react-i18next';

import EditToken from 'components/modals/EditToken';

import { request } from 'utils/api';
import { Confirm } from 'components';
import { useHistory } from 'react-router-dom';

import { currentUserCanAccess } from 'utils/roles';

export default function TokensActions({ item, onReload } = {}) {
  const { t } = useTranslation();
  const history = useHistory();

  const writeAccess = currentUserCanAccess('tokens', 'write');

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/tokens/${item.id}`,
    });
  }

  const fetchToken = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/tokens/${item.id}`,
    });

    return data;
  };

  return (
    <>
      {writeAccess && (
        <EditToken
          onSave={onReload}
          getData={fetchToken}
          trigger={
            <Dropdown.Item
              text={t('tokens.editToken', 'Edit token')}
              icon="pen-to-square"
              disabled={!(writeAccess && item.customId)}
            />
          }
        />
      )}
      <Dropdown.Item
        text={t('tokens.blockUnblockToken', 'Block or unblock token')}
        icon="eye"
        onClick={() =>
          history.push(`/cards/tokens/${item?.id}`)
        }></Dropdown.Item>

      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.customId }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" text="Delete" disabled={!writeAccess} />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />

      <InspectObject
        name="Token"
        getData={fetchToken}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />
    </>
  );
}
