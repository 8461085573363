import React, { useState, useEffect } from 'react';
import { set, merge } from 'lodash-es';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';

import { Form, Message, Modal, Button } from 'semantic';
import { useAccessGroupOwnershipOptions } from 'utils/constants';

const accessGroupTypes = {
  rfid: {
    text: 'RFID Tags',
    icon: 'tags',
  },
  users: {
    text: 'Users',
    icon: 'users',
  },
};

const accessGroupTypeOptions = Object.keys(accessGroupTypes).map((key) => ({
  key,
  value: key,
  ...accessGroupTypes[key],
}));

function EditAccessGroup(props) {
  const { initialValues, trigger } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const accessGroupOwnershipOptions = useAccessGroupOwnershipOptions();

  useEffect(() => {
    if (initialValues !== formValues) {
      setSubmitted(false);
      setFormValues({ ...initialValues });
    }
  }, [initialValues]);

  const handleSubmit = () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    const { id } = initialValues;
    const isUpdate = !!id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate ? `/1/access-groups/${id}` : '/1/access-groups';

    request({
      method,
      path,
      body: formValues,
    })
      .then(() => {
        setLoading(false);
        if (props.onClose) props.onClose();
        setFormValues(props.initialValues);
        setOpen(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues((prevValues) => merge({}, prevValues, set({}, name, value)));
  };

  const isUpdate = !!initialValues.id;

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        if (props.onClose) props.onClose();
        setFormValues(props.initialValues);
        setSubmitted(false);
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {isUpdate
          ? t('editAccessGroup.editAccessGroup', 'Edit Access Group')
          : t('editAccessGroup.newAccessGroup', 'New Access Group')}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="EditAccessGroup-form"
          error={submitted && Boolean(error)}
          onSubmit={handleSubmit}>
          {error && <Message error content={error.message} />}

          <Form.Select
            value={formValues.type}
            options={accessGroupTypeOptions}
            name="type"
            label={t('editAccessGroup.typeLabel', 'Type')}
            type="text"
            required
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Input
            value={formValues.name || ''}
            name="name"
            label={t('editAccessGroup.nameLabel', 'Name')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Select
            value={formValues.ownershipContext}
            options={accessGroupOwnershipOptions}
            name="ownershipContext"
            label={t(
              'editAccessGroup.ownershipContextVisbility',
              'Access Group Visibility'
            )}
            type="text"
            required
            onChange={(e, { name, value }) => setField(name, value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={
            isUpdate
              ? t('editAccessGroup.update', 'Update')
              : t('editAccessGroup.create', 'Create')
          }
          form="EditAccessGroup-form"
        />
      </Modal.Actions>
    </Modal>
  );
}

EditAccessGroup.defaultProps = {
  initialValues: {
    type: 'rfid',
    ownershipContext: 'user',
  },
};

export default EditAccessGroup;
