import React from 'react';
import { request } from 'utils/api';

import { Message, Modal, Button, Checkbox, Divider } from 'semantic';
import { withTranslation } from 'react-i18next';
import { JWT_KEY } from 'utils/api';
import modal from 'helpers/modal';

class LoginAsUser extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    keepFeatureFlags: false,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  confirm = () => {
    const { user } = this.props;
    this.setState({
      loading: true,
      error: null,
    });
    request({
      method: 'GET',
      path: `/1/users/${user.id}/token`,
      params: {
        keepFeatureFlags: this.state.keepFeatureFlags,
      },
    })
      .then(({ data }) => {
        window.sessionStorage.setItem(JWT_KEY, data.token);
        document.location = '/';
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    const { user } = this.props;
    const { loading, error } = this.state;
    return (
      <>
        <Modal.Header>Login As User</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          Are you sure you want to log in as {user.name}? The current admin
          login session will be terminated (incognito session recommended). The
          session will be valid for 2 hours only.
          <Divider horizontal />
          <Checkbox
            checked={this.state.keepFeatureFlags}
            label={'Keep feature flags'}
            onChange={(e, { checked }) => {
              this.setState({ keepFeatureFlags: checked });
            }}
          />
          {/*
          <p>
            <Checkbox
              label={'Keep platform feature flags - Coming soon'}
              disabled={true}
            />
          </p>
          */}
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content="Cancel"
            onClick={() =>
              this.setState({
                open: false,
              })
            }
          />
          <Button
            loading={loading}
            primary
            content="Start Auth Session"
            onClick={() => this.confirm()}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default withTranslation()(modal(LoginAsUser));
