import { UserContext } from 'contexts/user';
import { useContext, useMemo } from 'react';
import { canAccess } from 'utils/roles';

type UseCanAccessOptions = {
  endpoint: string;
  requiredLevel: 'read' | 'write';
  allowAccountContext?: boolean;
  providerId?: string;
  accountId?: string;
};

export function useCanAccess({
  endpoint,
  requiredLevel,
  allowAccountContext = true,
  providerId,
  accountId,
}: UseCanAccessOptions) {
  const { user } = useContext(UserContext);
  providerId ||= user?.providerId;
  accountId ||= user?.account?.id;

  return useMemo(() => {
    if (!user) {
      return false;
    }
    if (!providerId) {
      return false;
    }

    const currentAccountId = allowAccountContext ? accountId : undefined;
    if (!currentAccountId) {
      return false;
    }

    return canAccess(
      user,
      providerId,
      currentAccountId,
      endpoint,
      requiredLevel
    );
  }, [user, endpoint, requiredLevel, allowAccountContext]);
}
