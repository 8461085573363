import { Formik } from 'formik';
import modal from 'helpers/modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'semantic';
import { joiErrorDetailsToObject, request } from 'utils/api';
import * as Yup from 'yup';
import InputField from '../form-fields/formik/InputField';
import SelectField from '../form-fields/formik/SelectField';

interface CostLimit {
  amount?: {
    currency: string;
    value: number;
  };
}

interface PowerTypeLimit {
  maxTimeCosts?: CostLimit;
  maxIdleCosts?: CostLimit;
}

interface RoamingLimit {
  id?: string;
  match: {
    infraProviderId: string;
  };
  priority: number;
  powerTypeAC?: PowerTypeLimit;
  powerTypeDC?: PowerTypeLimit;
}

interface Props {
  roamingLimit: RoamingLimit;

  close(): void;

  onSave(): void;
}

const CostLimitSchema = Yup.object({
  amount: Yup.object({
    currency: Yup.string().length(3),
    value: Yup.number().positive(),
  }),
});

const PowerTypeLimitSchema = Yup.object({
  maxTimeCosts: CostLimitSchema,
  maxIdleCosts: CostLimitSchema,
});

const VALIDATION_SCHEMA = Yup.object({
  match: Yup.object({
    infraProviderId: Yup.string().required(),
  }).required(),
  priority: Yup.number().min(0).required(),
  powerTypeAC: PowerTypeLimitSchema,
  powerTypeDC: PowerTypeLimitSchema,
});

interface State {
  currencies?: string[];
  error?: string;
}

type PowerTypeKeys = keyof Pick<RoamingLimit, 'powerTypeAC' | 'powerTypeDC'>;
type CostTypeKeys = keyof Pick<PowerTypeLimit, 'maxTimeCosts' | 'maxIdleCosts'>;

const powerTypes: PowerTypeKeys[] = ['powerTypeAC', 'powerTypeDC'];
const costTypes: CostTypeKeys[] = ['maxTimeCosts', 'maxIdleCosts'];

const EditRoamingLimitForm: React.FC<Props> = ({
  roamingLimit,
  close,
  onSave,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    currencies: [],
  });

  const isUpdate = !!roamingLimit;

  const submitHandler = async (formValues: any, formikBag: any) => {
    try {
      if (isUpdate) {
        await request({
          method: 'PATCH',
          path: `/1/system/roaming-limits/${roamingLimit.id}`,
          body: formValues,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/system/roaming-limits',
          body: formValues,
        });
      }
      close();
      onSave();
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  const getSystemCurrencies = async () => {
    try {
      const { data: currencies } = await request({
        method: 'GET',
        path: '/1/system/currencies',
      });
      setState({
        currencies: currencies.map((c: { currency: string }) => c.currency),
      });
    } catch (error: any) {
      setState({
        currencies: [],
        error: error?.message,
      });
    }
  };

  useEffect(() => {
    getSystemCurrencies();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={submitHandler}
        validationSchema={VALIDATION_SCHEMA}
        initialValues={roamingLimit || {}}>
        {({ values, dirty, isSubmitting, handleSubmit, isValid }) => {
          for (const pt of powerTypes) {
            const powerTypeLimits = values[pt] || {};
            for (const ct of costTypes) {
              if (
                !powerTypeLimits[ct]?.amount?.currency &&
                !powerTypeLimits[ct]?.amount?.value
              ) {
                delete powerTypeLimits[ct]; // clear empty limits
              }
            }
          }

          return (
            <>
              <Modal.Header>
                {isUpdate
                  ? t('roamingLimits.edit', 'Update Roaming Limit')
                  : t('roamingLimits.new', 'New Roaming Limit')}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <InputField
                    name={`match.infraProviderId`}
                    label={t('roamingLimits.provider', 'Provider')}
                    type="text"
                    min={0}
                  />
                  <InputField
                    name={`priority`}
                    label={t('roamingLimits.priority', 'Priority')}
                    type="number"
                    min={0}
                  />
                  {powerTypes.map((powerType) => (
                    <React.Fragment key={powerType}>
                      <hr />
                      <h3>
                        {powerType === 'powerTypeAC'
                          ? 'AC power limits'
                          : 'DC power limits'}
                      </h3>
                      {costTypes.map((limitType) => (
                        <React.Fragment key={`${powerType}-${limitType}`}>
                          <h5>
                            {limitType === 'maxTimeCosts'
                              ? 'Max Time Costs'
                              : 'Max Idle Costs'}
                          </h5>
                          <Form.Group widths="equal">
                            <SelectField
                              options={(state.currencies || []).map(
                                (currency) => ({
                                  key: `${powerType}-${limitType}-${currency}`,
                                  value: currency,
                                  text: currency,
                                })
                              )}
                              name={`${powerType}.${limitType}.amount.currency`}
                              label={t('roamingLimits.currency', 'Currency')}
                              clearable={true}
                            />
                            <InputField
                              name={`${powerType}.${limitType}.amount.value`}
                              label={t('roamingLimits.amount', 'Amount')}
                              type="number"
                              min={0}
                            />
                          </Form.Group>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="button"
                  loading={isSubmitting}
                  role="button"
                  disabled={isSubmitting || !isValid || !dirty}
                  primary
                  content={
                    isUpdate
                      ? t('roamingLimits.updateLimit', 'Update Limit')
                      : t('roamingLimits.createLimit', 'Create Limit')
                  }
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export const EditRoamingLimit = modal(EditRoamingLimitForm);
