import React, { useCallback } from 'react';

import { Menu, Segment } from 'semantic-ui-react';
import { Button, Divider, Form, Icon } from 'semantic';
import { useTranslation } from 'react-i18next';
import { isEmpty, get } from 'lodash';

import { shortCircuitEvent } from 'utils/events';
import { StandardConfigurationKeys } from './protocol';
import { Help, InputField } from 'components/config/ConfigField';
import { DropdownItemProps } from 'semantic-ui-react';
import { OCPPProtocolVersion } from 'types/evse-controller';

export enum ConfigurationInputType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

const commonInputStyle = { width: '100%', maxWidth: 450 };

export type Value = {
  input: ConfigurationInputType;
  name: string;
  value: string;
  component?: string;
  ocppProtocol?: OCPPProtocolVersion;
  standardKeyId?: string;
};

type Props = {
  standardConfigurationKeys: StandardConfigurationKeys;
  ocppProtocols: string[];
  value: Value;
  onChange: (entry: Value) => void;
  withBox?: boolean;
};

const SelectConfigurationInputTypeButton = ({
  text,
  selected,
  onClick,
}: {
  text: string;
  selected: boolean;
  onClick: (selected: boolean) => void;
}) => (
  <Menu.Item
    active={selected}
    onClick={(e: React.SyntheticEvent<HTMLElement>) => {
      shortCircuitEvent(e);
      onClick?.(true);
    }}>
    {selected && <Icon name={'circle-check'} />}
    {!selected && <Icon name={'circle regular'} />}
    {text}
  </Menu.Item>
);

export default function ConfigurationInput({
  standardConfigurationKeys,
  ocppProtocols,
  onChange,
  value,
  withBox = true,
}: Props) {
  const { t } = useTranslation();

  const standardConfiguration = value.standardKeyId
    ? standardConfigurationKeys[value.standardKeyId]
    : null;

  const handleChange = useCallback(
    (v: Value) => {
      const standardConfiguration = get(
        standardConfigurationKeys,
        v.standardKeyId || ''
      );
      // if it is a standard configuration, we get the protocol from the standard configuration object
      if (standardConfiguration) {
        const ocppProtocol =
          standardConfiguration.ocppProtocol as OCPPProtocolVersion;
        const name = standardConfiguration.name;
        const component =
          'component' in standardConfiguration.info
            ? standardConfiguration.info.component
            : undefined;

        return onChange({ ...v, ocppProtocol, name, component });
      }

      // if it is not a standard configuration, we infer the protocol from the the component value (only ocpp2.0.1 supports component)
      const ocppProtocol = isEmpty(v.component)
        ? OCPPProtocolVersion.OCPP16
        : OCPPProtocolVersion.OCPP201;

      onChange({ ...v, ocppProtocol });
    },
    [onChange]
  );

  const component = (
    <>
      <Menu compact style={{ marginBottom: '1em' }}>
        <SelectConfigurationInputTypeButton
          selected={value.input === ConfigurationInputType.STANDARD}
          text={t(
            'evseControllersBackgroundJobsChangeConfiguration.standardConfigurationSelectorLabel',
            'Standard Configuration'
          )}
          onClick={() => {
            if (value.input === ConfigurationInputType.CUSTOM) {
              // using onChange instead of handleChange to reset the value
              onChange({
                input: ConfigurationInputType.STANDARD,
                name: '',
                value: '',
              });
            }
          }}
        />
        <SelectConfigurationInputTypeButton
          selected={value.input === ConfigurationInputType.CUSTOM}
          text={t(
            'evseControllersBackgroundJobsChangeConfiguration.customConfigurationSelectorLabel',
            'Custom Configuration'
          )}
          onClick={() => {
            if (value.input === ConfigurationInputType.STANDARD) {
              // using onChange instead of handleChange to reset the value
              onChange({
                input: ConfigurationInputType.CUSTOM,
                name: '',
                value: '',
              });
            }
          }}
        />
      </Menu>

      {value.input === ConfigurationInputType.STANDARD && (
        <>
          <Form.Dropdown
            style={commonInputStyle}
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.standardVariableLabel',
              'Standard Variable'
            )}
            selection
            search={(
              options: DropdownItemProps[],
              value: string
            ): DropdownItemProps[] => {
              const searchTerm = value.toLowerCase();
              return options.filter((option) =>
                option.value?.toString()?.toLowerCase()?.includes(searchTerm)
              );
            }}
            value={standardConfiguration?.id || ''}
            options={Object.values(standardConfigurationKeys || {}).map(
              (value) => ({
                key: value.id,
                value: value.id,
                text: (
                  <>
                    {value.label}
                    {ocppProtocols.length > 1 && (
                      <span style={{ color: 'grey' }}>
                        {' '}
                        OCPP{' '}
                        {value.ocppProtocol === 'ocpp1.6' ? '1.6' : '2.0.1'}
                      </span>
                    )}
                  </>
                ),
              })
            )}
            id="variable-name"
            name="variable-name"
            onChange={(e, { value: v }) => {
              handleChange({
                ...value,
                standardKeyId: v as string,
                value: '',
              });
            }}
          />

          {standardConfiguration && (
            <Form.Field
              style={{
                ...commonInputStyle,
                display: 'grid',
                gridTemplateColumns: '1fr max-content',
                gridTemplateRows: '1fr max-content',
                justifyItems: 'stretch',
                alignItems: 'center',
                columnGap: '1em',
              }}>
              <label style={{ gridArea: '1 / 1 / 2 / 3' }}>
                {t(
                  'evseControllersBackgroundJobsChangeConfiguration.standardVariableValueLabel',
                  'Value'
                )}
              </label>
              <div style={{ gridArea: '2 / 1 / 3 / 2' }}>
                <InputField
                  style={{ width: '100%' }}
                  configKey={standardConfiguration.id}
                  value={value.value || ''}
                  handleChange={(
                    e: React.SyntheticEvent<HTMLElement>,
                    { value: v }: { value: string }
                  ) => {
                    handleChange({
                      ...value,
                      value: v,
                    });
                  }}
                  configInfo={standardConfiguration.info}
                  setFieldValue={(key: string, v: string) => {
                    handleChange({
                      ...value,
                      value: v,
                    });
                  }}
                />
              </div>
              <Help
                style={{ gridArea: '2 / 2 / 3 / 3' }}
                marginLeft="0"
                marginTop="0"
                title={standardConfiguration.label}
                configInfo={standardConfiguration.info}
              />
            </Form.Field>
          )}
        </>
      )}

      {value.input === ConfigurationInputType.CUSTOM && (
        <>
          <Form.Input
            style={commonInputStyle}
            name="custom-variable"
            placeholder={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariablePlaceholder',
              'Type here...'
            )}
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariableLabel',
              'Custom Variable Name'
            )}
            type="text"
            value={value.name || ''}
            onChange={(e, { value: v }) => {
              handleChange({
                ...value,
                name: v,
              });
            }}
          />
          {ocppProtocols.includes('ocpp2.0.1') && (
            <Form.Input
              style={commonInputStyle}
              name="custom-variable-component"
              placeholder={t(
                'evseControllersBackgroundJobsChangeConfiguration.customVariableComponentNamePlaceholder',
                'Type here...'
              )}
              label={
                ocppProtocols.length > 1
                  ? t(
                      'evseControllersBackgroundJobsChangeConfiguration.customVariableComponentNameMixedProtocolsLabel',
                      'Component Name (only OCPP 2.0.1)'
                    )
                  : t(
                      'evseControllersBackgroundJobsChangeConfiguration.customVariableComponentNameLabel',
                      'Component Name'
                    )
              }
              type="text"
              value={value.component || ''}
              onChange={(e, { value: v }) => {
                handleChange({
                  ...value,
                  component: v,
                });
              }}
            />
          )}
          <Form.Input
            style={commonInputStyle}
            name="custom-variable-value"
            placeholder={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariableValuePlaceholder',
              'Type here...'
            )}
            label={t(
              'evseControllersBackgroundJobsChangeConfiguration.customVariableValueLabel',
              'Value'
            )}
            type="text"
            value={value.value || ''}
            onChange={(e, { value: v }) => {
              handleChange({
                ...value,
                value: v,
              });
            }}
          />
        </>
      )}
    </>
  );

  if (withBox) {
    return <Segment>{component}</Segment>;
  }

  return component;
}
