import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Account } from 'types/account';
import request from 'utils/api/request';

type AccountContextProps = {
  account?: Account | null;
  setAccount?: (account: Account) => void;
  getAccount?: () => Promise<void>;
  isBillingUpdateRequired?: boolean;
  isAccountManagedBySso?: boolean;
  loading?: boolean;
  error?: string | null;
};

const AccountContext = createContext<AccountContextProps>({});

export const AccountProvider = ({ children }: { children: ReactNode }) => {
  const [account, setAccount] = useState<Account | null>(null);
  const [isBillingUpdateRequired, setIsBillingUpdateRequired] = useState(false);
  const [isAccountManagedBySso, setIsAccountManagedBySso] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getAccount = async () => {
    setLoading(true);
    try {
      const { data, meta } = await request({
        method: 'GET',
        path: '/1/accounts/self',
      });
      setAccount(data);
      setIsBillingUpdateRequired(meta?.isBillingUpdateRequired);
      setIsAccountManagedBySso(meta?.isAccountManagedBySso);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  const contextValue = useMemo(
    () => ({
      account,
      setAccount,
      getAccount,
      isBillingUpdateRequired,
      isAccountManagedBySso,
      loading,
      error,
    }),
    [account, setAccount, getAccount]
  );

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => useContext(AccountContext);
