import React from 'react';
import { Breadcrumbs, ListHeader } from 'components';
import { useTranslation } from 'react-i18next';
import { Button, Message } from 'semantic';
import { Link } from 'react-router-dom';
import { useUser } from 'contexts/user';

export default function Overview() {
  const { t } = useTranslation();
  const { provider, hasPlatformFeatureInModule } = useUser();

  const supportsSmartChargingModule =
    hasPlatformFeatureInModule('smart-charging');

  const supportsSmartCharging =
    !!provider.supportedSmartChargingProviders.length &&
    supportsSmartChargingModule;

  return (
    <>
      <Breadcrumbs active={t('smartCharging.title', 'Smart Charging')} />

      <ListHeader title={t('smartCharging.title', 'Smart Charging')} />

      <Message
        header={t('smartCharging.title', 'Smart Charging')}
        icon="info"
        info
        content={t(
          'smartCharging.overviewMessage',
          `Smart charging is a charging solution that allows you to charge your electric vehicle with the help of a smart charging provider (E.g. Stekker.app).
          This allows the charging session to be intelligently optimized according to current energy price, user preferences and more.`
        )}
      />

      {!supportsSmartCharging && (
        <Message
          header={t('smartCharging.notSupportedMessageTitle', 'Unsupported!')}
          icon="exclamation"
          warning
          content={t(
            'smartCharging.notSupportedMessage',
            `Unfortunately, your current provider does not support smart charging. Please contact your provider to find out more.`
          )}
        />
      )}

      <Link to={supportsSmartCharging ? '/smart-charging/pairing' : '#'}>
        <Button
          content={t('smartCharging.link', 'Go to smart charging pairing')}
          disabled={!supportsSmartCharging}
        />
      </Link>
    </>
  );
}
