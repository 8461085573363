import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Divider, Header, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { Container } from 'semantic';

import Menu from './Menu';
import ExportJobs from './ExportJobs';
import Statistics from './Statistics';

import { withTranslation } from 'react-i18next';

class FinanceExports extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs active={t('financeExports.title', 'Exports')} />
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('financeExports.title', 'Exports')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Menu />
          <Divider hidden />
          <Switch>
            <Route
              exact
              path="/finance/exports/statistics"
              component={(props) => <Statistics {...props} />}
            />
            <Route
              exact
              path="/finance/exports/export-jobs"
              component={(props) => <ExportJobs {...props} />}
            />
          </Switch>{' '}
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(FinanceExports);
