import React from 'react';

const WizardContext = React.createContext({});

import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';

const WizardProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [stickerCode, setStickerCode] = React.useState();
  const [wantSticker, setWantSticker] = React.useState(false);
  const [station, setStation] = React.useState();
  const [billingPlan, setBillingPlan] = React.useState(null);
  const [location, setLocation] = React.useState({
    country: 'NLD',
    name: t(
      'newChargingStationLocation.fieldNamePlaceholder',
      `{{name}}'s station`,
      { name: user.name }
    ),
  });
  const [details, setDetails] = React.useState({ costs: 0.25 });
  const [existingLocation, setExistingLocation] = React.useState();
  const [addToExisting, setAddToExisting] = React.useState(false);

  const values = React.useMemo(() => {
    return {
      stickerCode,
      setStickerCode,
      wantSticker,
      setWantSticker,
      station,
      setStation,
      billingPlan,
      setBillingPlan,
      location,
      setLocation,
      existingLocation,
      setExistingLocation,
      details,
      setDetails,
      addToExisting,
      setAddToExisting,
    };
  }, [
    stickerCode,
    setStickerCode,
    wantSticker,
    setWantSticker,
    station,
    setStation,
    billingPlan,
    setBillingPlan,
    location,
    setLocation,
    existingLocation,
    setExistingLocation,
    details,
    setDetails,
    addToExisting,
    setAddToExisting,
  ]);

  return (
    <WizardContext.Provider value={values}>{children}</WizardContext.Provider>
  );
};

const useWizardContext = () => {
  const context = React.useContext(WizardContext);
  if (context === undefined) {
    throw new Error(
      'useWizardContext must be used within a WizardProvider Context'
    );
  }
  return context;
};

export { WizardProvider, useWizardContext };
