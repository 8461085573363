import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Header,
  Message,
  Modal,
  Table,
  Popup,
  TableHeader,
  TableBody,
  TableCell,
} from 'semantic';
import { Checkbox } from 'semantic-ui-react';
import * as Yup from 'yup';
import { get } from 'lodash-es';

import { useTranslation } from 'react-i18next';
import { request, joiErrorDetailsToObject } from 'utils/api';
import { Formik, FieldArray } from 'formik';
import SelectField from 'components/form-fields/formik/SelectField';
import InputField from 'components/form-fields/formik/InputField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import TagsField from 'components/form-fields/formik/TagsField';
import { SUPPORTED_CURRENCIES } from 'components/Layout/utils';
import HelpTip from 'components/HelpTip';
import FeatureFlag from 'components/FeatureFlag';
import PlatformModule from 'components/PlatformModule';
import { useUser } from 'contexts/user';
import { BillingPlan, BillingPlanPrice } from 'types/billingplan';
import { useFeatures } from 'contexts/features';
import { Group, Space } from 'components/Layout/helpers';
import useAccountTierBillingPlanDefaults from './useAccountTierBillingPlanDefaults';
import { usePlatformModules } from 'contexts/platformModules';
import SearchDropdown from 'components/form-fields/formik/SearchDropdown';
import { AccountTier } from '../../../types/account-tier';

interface ProviderCurrency {
  currency: string;
  active: boolean;
}

const maxFixedFeesPerCurrency: {
  [key: string]: number;
} = {
  EUR: 1,
  GBP: 1,
  CHF: 1,
  DKK: 10,
} as const;

const MAX_PER_KWH_FEE_PERCENTAGE = 50;

interface PricesFieldProps {
  name: string;
  currencies: ProviderCurrency[];
  billingPlanId?: string;
}

const FeesField = ({ name }: PricesFieldProps) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  return (
    <FieldArray
      name={name}
      render={(helpers) => {
        const values: BillingPlanPrice[] = get(helpers.form.values, name);

        return (
          <>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHeader>
                {hasFeature('multi_currency_support') && (
                  <Table.HeaderCell width={6}>
                    {t('editBillingPlan.currency', 'Currency')}
                  </Table.HeaderCell>
                )}
                {helpers.form.values.usingBillingPlanFeesPercentage && (
                  <Table.HeaderCell width={6}>
                    {t(
                      'editBillingPlan.perKwhPercentage',
                      'Fee Per Kwh Percentage'
                    )}
                  </Table.HeaderCell>
                )}
                {!helpers.form.values.usingBillingPlanFeesPercentage && (
                  <Table.HeaderCell width={6}>
                    {t('editBillingPlan.perKwhFixed', 'Fee Per Kwh')}
                  </Table.HeaderCell>
                )}
              </TableHeader>
              <TableBody>
                {values?.map((price: BillingPlanPrice, i: number) => {
                  const currency = price.currency;
                  const maxFixedFee = maxFixedFeesPerCurrency[currency] ?? 0;

                  return (
                    <Table.Row key={i}>
                      {hasFeature('multi_currency_support') && (
                        <TableCell>
                          <PlatformModule moduleName="multi-currency">
                            {price.currency}
                          </PlatformModule>
                        </TableCell>
                      )}
                      {helpers.form.values.usingBillingPlanFeesPercentage && (
                        <TableCell>
                          <InputField
                            name={`${name}.${i}.perKwhPercentage`}
                            type="number"
                            min={0}
                            step={0.1}
                            onChange={(e) => {
                              const percentage = +e.target.value;
                              if (percentage && !isNaN(percentage)) {
                                helpers.form.setFieldValue(
                                  `${name}.${i}.perKwhPercentage`,
                                  Math.min(
                                    percentage,
                                    MAX_PER_KWH_FEE_PERCENTAGE
                                  )
                                );
                              }
                            }}
                          />
                        </TableCell>
                      )}
                      {!helpers.form.values.usingBillingPlanFeesPercentage && (
                        <TableCell>
                          <InputField
                            name={`${name}.${i}.perKwhFixed`}
                            type="number"
                            min={0}
                            step={0.1}
                            onChange={(e) => {
                              const fixed = +e.target.value;
                              if (fixed && !isNaN(fixed)) {
                                helpers.form.setFieldValue(
                                  `${name}.${i}.perKwhFixed`,
                                  Math.min(fixed, maxFixedFee)
                                );
                              }
                            }}
                          />
                        </TableCell>
                      )}
                    </Table.Row>
                  );
                })}
              </TableBody>
            </Table>

            <div>
              <Checkbox
                as=""
                label={t(
                  'editBillingPlan.usePercentagesForFees',
                  'Use Percentages'
                )}
                checked={!!helpers.form.values.usingBillingPlanFeesPercentage}
                onChange={(_: any, data: any) => {
                  helpers.form.setFieldValue(
                    `usingBillingPlanFeesPercentage`,
                    data.checked
                  );
                }}
              />
            </div>
            <br />
          </>
        );
      }}
    />
  );
};

const PricesField: React.FC<PricesFieldProps> = ({
  name,
  currencies,
  billingPlanId,
}) => {
  const [currenciesInUse, setCurrenciesInUse] = useState<string[]>([]);

  const fetchCurrenciesInUse = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/billing-plans/${billingPlanId}/currencies-in-use`,
    });
    if (Array.isArray(data)) {
      setCurrenciesInUse(data);
    }
  };

  useEffect(() => {
    if (billingPlanId) fetchCurrenciesInUse();
  }, []);

  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  return (
    <FieldArray
      name={name}
      render={(helpers) => {
        const values = get(helpers.form.values, name);
        const formType = helpers.form.values.providerContext;
        return (
          <>
            <Table style={{ tableLayout: 'fixed' }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{t('common.currency', 'Currency')}</span>
                    </div>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <span>{t('editBillingPlan.perPeriod', 'per Period')}</span>{' '}
                  </Table.HeaderCell>
                  {formType === 'cpo' && (
                    <Table.HeaderCell width={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {t('editBillingPlan.perDevice', 'Per Device')}
                        </span>{' '}
                        <HelpTip
                          title={t('editBillingPlan.perDeviceTitle', 'Device')}
                          text={t(
                            'editBillingPlan.perDeviceText',
                            'The price "per device" refers to a one-time fee that is charged when a new charge station is added by an account. This is only applicable when configuring a Charge Station billing plan.'
                          )}
                        />
                      </div>
                    </Table.HeaderCell>
                  )}
                  {formType === 'msp' && (
                    <Table.HeaderCell width={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{t('editBillingPlan.perCard', 'Per Card')}</span>{' '}
                        <HelpTip
                          title={t('editBillingPlan.perCardTitle', 'Card')}
                          text={t(
                            'editBillingPlan.perCardText',
                            'The price "per card" refers to a one-time fee that is charged when a new card is issued. This is only applicable when configuring a Consumer Card billing plan.'
                          )}
                        />
                      </div>
                    </Table.HeaderCell>
                  )}
                  {formType === 'msp' && (
                    <Table.HeaderCell width={2}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          {t('editBillingPlan.perSession', 'Per Session')}
                        </span>{' '}
                      </div>
                    </Table.HeaderCell>
                  )}
                  <FeatureFlag feature="multi_currency_support">
                    <PlatformModule moduleName="multi-currency">
                      <Table.HeaderCell width={1}></Table.HeaderCell>
                    </PlatformModule>
                  </FeatureFlag>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {values?.map((price: BillingPlanPrice, i: number) => {
                  const currencyLabel = SUPPORTED_CURRENCIES.find(
                    (x) => x.value === price.currency
                  );
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {hasFeature('multi_currency_support') ? (
                          <PlatformModule moduleName="multi-currency">
                            <SelectField
                              name={`${name}.${i}.currency`}
                              options={currencies?.map((x) => ({
                                key: x.currency,
                                text: `${x.currency} (${
                                  x.active
                                    ? t('common.active', 'active')
                                    : t('common.inactive', 'inactive')
                                })`,
                                value: x.currency,
                              }))}
                              hideErrorLabel
                            />
                          </PlatformModule>
                        ) : (
                          <p>{currencyLabel?.text}</p>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          name={`${name}.${i}.perPeriod`}
                          type="number"
                          min={0}
                          step={0.01}
                          hideErrorLabel
                        />
                      </Table.Cell>
                      {formType === 'cpo' && (
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.perDevice`}
                            type="number"
                            min={0}
                            disabled={formType === 'msp'}
                            step={0.01}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      )}
                      {formType === 'msp' && (
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.perCard`}
                            type="number"
                            min={0}
                            disabled={formType === 'cpo'}
                            step={0.01}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      )}
                      {formType === 'msp' && (
                        <Table.Cell>
                          <InputField
                            name={`${name}.${i}.perSession`}
                            disabled={formType === 'cpo'}
                            type="number"
                            min={0}
                            step={0.01}
                            hideErrorLabel
                          />
                        </Table.Cell>
                      )}
                      <PlatformModule moduleName="multi-currency">
                        <FeatureFlag feature="multi_currency_support">
                          <Table.Cell>
                            <Popup
                              disabled={
                                values.length !== 1 &&
                                !currenciesInUse.includes(price.currency)
                              }
                              content={
                                values.length === 1
                                  ? t(
                                      'editBillingPlan.lastCurrencyCannotBeDeleted',
                                      'Billing plan should contain at least one currency.'
                                    )
                                  : t(
                                      'editBillingPlan.currencyCannotBeDeletedAccountRef',
                                      'Currency cannot be deleted as it is in use by at least one account.'
                                    )
                              }
                              trigger={
                                <span>
                                  <Button
                                    as="button"
                                    icon="trash"
                                    disabled={
                                      values.length === 1 ||
                                      currenciesInUse.includes(price.currency)
                                    }
                                    onClick={async () => {
                                      helpers.remove(i);
                                    }}
                                  />
                                </span>
                              }
                            />
                          </Table.Cell>
                        </FeatureFlag>
                      </PlatformModule>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </>
        );
      }}
    />
  );
};

interface Props {
  close(): void;
  onSave(): void;
  onSubmit(billingPlan: BillingPlan): Promise<void>;
  billingPlan: Partial<BillingPlan>;
}

// TODO: consider having separate tabs and forms for CPO/MSP billing plans.
const BillingPlanForm: React.FC<Props> = ({
  billingPlan = {
    billingPeriod: 'month',
    type: 'account-tier',
    isPublic: false,
    priority: 0,
    providerContext: 'cpo',
    id: '',
    name: '',
    prices: [
      {
        currency: 'EUR',
        perPeriod: 0,
        perDevice: 0,
        perSession: 0,
        perCard: 0,
        perKwhPercentage: 0,
        perKwhFixed: 0,
      },
    ],
  },
  close,
  onSave,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { provider } = useUser();

  const isUpdate = !!billingPlan?.id;

  if (billingPlan.prices?.length) {
    // @ts-ignore This is not part of the spec but I'm using it to internalize inital values for the toggle here
    billingPlan.usingBillingPlanFeesPercentage =
      billingPlan.prices[0]?.perKwhPercentage > 0;
  }

  const { hasPlatformModule } = usePlatformModules();
  const isAccountTiersEnabled = hasPlatformModule('account-tiers');

  // Flag to determine if the billing plan should use account tier billing plan defaults
  const [showATBPToggle, setShowATBPToggle] = useState(false);
  const [useATBPDefaults, setUseATBPDefaults] = useState(false);
  const defaultATBPValues = useAccountTierBillingPlanDefaults();

  const submitHandler = async (formValues: any, formikBag: any) => {
    // depending on the type of billing plan, we want to reset the values of the other fields
    const prices = formValues.prices.map((price: any) => {
      if (formValues.providerContext === 'cpo') {
        price.perCard = 0;
        price.perSession = 0;
      } else {
        price.perDevice = 0;
      }

      if (formValues.usingBillingPlanFeesPercentage) {
        price.perKwhFixed = 0;
      } else {
        price.perKwhPercentage = 0;
      }

      formValues.usingBillingPlanFeesPercentage = undefined;

      return price;
    });

    const body = {
      ...formValues,
      prices,
    };

    try {
      await onSubmit(body);
      close();
      onSave();
    } catch (error: any) {
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };

  const periodOptions = [
    {
      key: 'month',
      value: 'month',
      text: t('editBillingPlan.billingPeriodMonth', 'month'),
    },
    {
      key: 'year',
      value: 'year',
      text: t('editBillingPlan.billingPeriodYear', 'year'),
    },
  ];

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        initialTouched={{
          type: true,
          billingPeriod: true,
          priority: true,
          prices: true,
          details: {
            en: {
              name: true,
            },
          },
        }}
        onSubmit={(formValues, formikBag) =>
          submitHandler(formValues, formikBag)
        }
        validationSchema={Yup.object({
          providerContext: Yup.string().oneOf(['cpo', 'msp']).required(),
          billingPeriod: Yup.string().oneOf(['month', 'year']).required(),
          priority: Yup.number().min(0).required(),
          type: Yup.string().oneOf(['consumer', 'station', 'account-tier']),
          accountTierId: Yup.string().when('type', {
            is: 'account-tier',
            then: (schema) =>
              schema.required(
                t(
                  'editBillingPlan.accountTierRequired',
                  'Account Tier is required'
                )
              ),
          }),
          prices: Yup.array().of(
            Yup.object({
              currency: Yup.string().required(),
              perPeriod: Yup.number().min(0).required(),
              perDevice: Yup.number().min(0).default(0),
              perCard: Yup.number().min(0).default(0),
              perSession: Yup.number().min(0).default(0),
              perKwhPercentage: Yup.number().min(0).max(100),
              perKwhFixed: Yup.number().min(0),
            })
          ),
          details: Yup.object({
            en: Yup.object({
              name: Yup.string().required(),
            }),
          }),
        })}
        initialValues={{
          details: {
            en: {
              name: 'Untitled',
            },
          },
          ...billingPlan,
          // TODO: below is a nasty fix that hides a data problem.
          // Currently, CPO billing plans all have an incorrect type of 'consumer'.
          // This will read provider context and overwrite the value in the UI.
          // However, a migration script is needed and this fix should be removed.
          ...(billingPlan.providerContext === 'cpo' &&
          billingPlan.type !== 'account-tier'
            ? { type: 'station' }
            : {}),
          ...(useATBPDefaults ? defaultATBPValues : {}),
        }}>
        {({
          status,
          values,
          dirty,
          isSubmitting,
          handleSubmit,
          isValid,
          setFieldValue,
        }) => {
          const url = `${document.location.protocol}//${document.location.host}/join?journey=${values.providerContext || 'generic'}&billingPlanId=${billingPlan.id}`;
          const [currencies, setCurrencies] = useState<ProviderCurrency[]>([]);

          const isTypeAccountTier = values.type === 'account-tier';

          const fetchCurrencies = async () => {
            const { data } = await request({
              method: 'GET',
              path: `/1/providers/${provider!.id}/currencies`,
            });
            if (Array.isArray(data)) {
              setCurrencies(data);
            }
          };

          useEffect(() => {
            fetchCurrencies();
          }, []);

          // Derive providerContext from the billingPlan.type
          useEffect(() => {
            switch (values.type) {
              case 'consumer':
                setFieldValue('providerContext', 'msp');
                setShowATBPToggle(false);
                break;
              case 'station':
                setFieldValue('providerContext', 'cpo');
                setShowATBPToggle(false);
                break;
              case 'account-tier':
                setFieldValue('providerContext', 'cpo');
                setShowATBPToggle(isAccountTiersEnabled && !isUpdate);
                break;
            }
          }, [values.type]);

          // For Account Tier billing plans
          // we want to reuse the English name across all languages
          useEffect(() => {
            if (useATBPDefaults && values.details.en.name) {
              const billingPlanName = values.details.en.name;

              setFieldValue('details.nl.name', billingPlanName);
              setFieldValue('details.de.name', billingPlanName);
              setFieldValue('details.fr.name', billingPlanName);
              setFieldValue('details.it.name', billingPlanName);
              setFieldValue('details.es.name', billingPlanName);
            }
          }, [useATBPDefaults, values.details.en.name]);

          return (
            <>
              <Modal.Header role={'heading'}>
                {isUpdate
                  ? t(
                      'editBillingPlan.titleEdit',
                      'Edit Billing Plan {{name}}',
                      {
                        name: billingPlan.name,
                      }
                    )
                  : t('editBillingPlan.titleNew', 'New Billing Plan')}
              </Modal.Header>
              <Modal.Content>
                <Form>
                  <Group align="flex-start" grow spacing={'xs'}>
                    <SelectField
                      required
                      style={{
                        display: 'flex',
                      }}
                      label="Type"
                      name="type"
                      options={[
                        ...(isAccountTiersEnabled
                          ? [
                              {
                                text: t(
                                  'billingPlan.accountTierType',
                                  'Account Tier'
                                ),
                                value: 'account-tier',
                                key: 'account-tier',
                              },
                            ]
                          : []),
                        {
                          text: t('billingPlan.consumerType', 'Consumer Card'),
                          value: 'consumer',
                          key: 'consumer',
                        },
                        {
                          text: t(
                            'billingPlan.chargingStationType',
                            'Charging Station'
                          ),
                          value: 'station',
                          key: 'station',
                        },
                      ]}
                    />

                    <SelectField
                      label={t(
                        'editBillingPlan.billingPeriod',
                        'Billing Period'
                      )}
                      required
                      name="billingPeriod"
                      options={periodOptions}
                    />
                  </Group>

                  {showATBPToggle && (
                    <>
                      <Space size={10} direction="vertical" />
                      <Form.Field>
                        <Checkbox
                          toggle
                          label={t(
                            'editBillingPlan.useAccountTierBillingPlanDefaults',
                            'Use Account Tier billing plan defaults'
                          )}
                          checked={useATBPDefaults}
                          onClick={() => setUseATBPDefaults(!useATBPDefaults)}
                        />
                      </Form.Field>
                    </>
                  )}

                  <Header>{t('editBillingPlan.prices', 'Prices')}</Header>
                  <PricesField name="prices" currencies={currencies} />
                  {values.providerContext === 'cpo' && (
                    <PlatformModule moduleName="white-label-fees">
                      <Header>
                        {t('editBillingPlan.usageFees', 'Usage Fees')}
                      </Header>
                      <FeesField
                        name="prices"
                        billingPlanId={billingPlan?.id}
                        currencies={currencies}
                      />
                    </PlatformModule>
                  )}
                  <PlatformModule moduleName="multi-currency">
                    <FeatureFlag feature="multi_currency_support">
                      {currencies.length > (values?.prices?.length ?? 0) && (
                        <Button
                          as="button"
                          icon="plus"
                          role="button"
                          label={t('editBillingPlan.addPrice', 'Add currency')}
                          onClick={() => {
                            const newPrices = values?.prices ?? [];
                            newPrices.push({
                              currency: '',
                              perPeriod: 0,
                              perCard: 0,
                              perDevice: 0,
                              perSession: 0,
                              perKwhFixed: 0,
                              perKwhPercentage: 0,
                            });
                            setFieldValue('prices', newPrices);
                          }}
                        />
                      )}
                    </FeatureFlag>
                  </PlatformModule>
                  <Header>{t('editBillingPlan.options', 'Options')}</Header>
                  <Form.Group>
                    <CheckboxField
                      label={t(
                        'editBillingPlan.visibleToAll',
                        'Is visible to all users?'
                      )}
                      name="isPublic"
                    />
                    {values.providerContext === 'msp' && (
                      <CheckboxField
                        label={t(
                          'editBillingPlan.isPrimary',
                          'Make this billing plan appear on signup'
                        )}
                        name="isPrimary"
                      />
                    )}
                  </Form.Group>

                  <Form.Field>
                    <InputField
                      name="priority"
                      type="number"
                      label="Display priority"
                    />
                  </Form.Field>

                  <Form.Field>
                    <TagsField
                      label={t('editBillingPlan.tags', 'Tags')}
                      name="tags"
                    />
                  </Form.Field>

                  {isTypeAccountTier && (
                    <SearchDropdown
                      name="accountTierId"
                      key={values.accountTierId}
                      objectMode={false}
                      label={t('editBillingPlan.accountTier', 'Account Tier')}
                      onDataNeeded={() =>
                        request({
                          method: 'GET',
                          path: '/1/account-tiers',
                        }).then((res) => {
                          return {
                            data: res.data?.map((accountTier: AccountTier) => ({
                              id: accountTier?.id,
                              name: accountTier?.name,
                            })),
                          };
                        })
                      }
                      getOptionLabel={(item) => item.name}
                      required
                    />
                  )}

                  <Header>
                    {t('editBillingPlan.detailsEnglish', 'Details English')}
                  </Header>

                  <Form.Field>
                    <InputField
                      label={t('editBillingPlan.name', 'Name')}
                      name="details.en.name"
                      required
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField
                      name="details.en.descriptionLong"
                      label={t(
                        'editBillingPlan.descriptionLong',
                        'Description Long'
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <TagsField
                      label={t('editBillingPlan.features', 'Features')}
                      name="details.en.features"
                    />
                  </Form.Field>

                  <Header>
                    {t('editBillingPlan.detailsFrench', 'Details French')}
                  </Header>
                  <Form.Field>
                    <InputField
                      label={t('editBillingPlan.name', 'Name')}
                      name="details.fr.name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField
                      name="details.fr.descriptionLong"
                      label={t(
                        'editBillingPlan.descriptionLong',
                        'Description Long'
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <TagsField
                      label={t('editBillingPlan.features', 'Features')}
                      name="details.fr.features"
                    />
                  </Form.Field>

                  <Header>
                    {t('editBillingPlan.detailsDutch', 'Details Dutch')}
                  </Header>
                  <Form.Field>
                    <InputField
                      label={t('editBillingPlan.name', 'Name')}
                      name="details.nl.name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField
                      name="details.nl.descriptionLong"
                      label={t(
                        'editBillingPlan.descriptionLong',
                        'Description Long'
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <TagsField
                      label={t('editBillingPlan.features', 'Features')}
                      name="details.nl.features"
                    />
                  </Form.Field>

                  <Header>
                    {t('editBillingPlan.detailsGerman', 'Details German')}
                  </Header>
                  <Form.Field>
                    <InputField
                      label={t('editBillingPlan.name', 'Name')}
                      name="details.de.name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField
                      name="details.de.descriptionLong"
                      label={t(
                        'editBillingPlan.descriptionLong',
                        'Description Long'
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <TagsField
                      label={t('editBillingPlan.features', 'Features')}
                      name="details.de.features"
                    />
                  </Form.Field>

                  <Header>
                    {t('editBillingPlan.detailsItalian', 'Details Italian')}
                  </Header>
                  <Form.Field>
                    <InputField
                      label={t('editBillingPlan.name', 'Name')}
                      name="details.it.name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField
                      name="details.it.descriptionLong"
                      label={t(
                        'editBillingPlan.descriptionLong',
                        'Description Long'
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <TagsField
                      label={t('editBillingPlan.features', 'Features')}
                      name="details.it.features"
                    />
                  </Form.Field>

                  <Header>
                    {t('editBillingPlan.detailsSpanish', 'Details Spanish')}
                  </Header>
                  <Form.Field>
                    <InputField
                      label={t('editBillingPlan.name', 'Name')}
                      name="details.es.name"
                    />
                  </Form.Field>
                  <Form.Field>
                    <InputField
                      name="details.es.descriptionLong"
                      label={t(
                        'editBillingPlan.descriptionLong',
                        'Description Long'
                      )}
                    />
                  </Form.Field>
                  <TagsField
                    label={t('editBillingPlan.features', 'Features')}
                    name="details.es.features"
                  />

                  {isUpdate && values?.providerContext === 'msp' && (
                    <Message info>
                      <Message.Header>
                        {t(
                          'editBillingPlan.billingPlanUrl',
                          'Billing Plan URL'
                        )}
                      </Message.Header>
                      <p>
                        {t(
                          'editBillingPlan.billingPlanDescription',
                          'In order to restrict any new signups to this Billing Plan, use the following link:'
                        )}{' '}
                        <a target="_blank" href={url} rel="noopener noreferrer">
                          {url}
                        </a>
                      </p>
                    </Message>
                  )}
                  {status && (
                    <Message error>
                      <p>{status}</p>
                    </Message>
                  )}
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  as="button"
                  loading={isSubmitting}
                  role="button"
                  aria-label={
                    isUpdate ? t('button.update') : t('button.create')
                  }
                  primary
                  disabled={isSubmitting || !isValid || !dirty}
                  content={isUpdate ? t('button.update') : t('button.create')}
                  onClick={handleSubmit}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default BillingPlanForm;
