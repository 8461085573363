import React, { useMemo } from 'react';

import { startOfDay, endOfDay } from 'date-fns';

import { request } from 'utils/api';

import { Form, Segment } from 'semantic';

import Table from './Table';
import { useTranslation } from 'react-i18next';
import {
  Search,
  Breadcrumbs,
  Layout,
  ListHeader,
  SearchFilters,
} from 'components';

type Sort = {
  column: string;
  direction: 'asc' | 'desc';
};
type Body = {
  from?: Date;
  to?: Date;
  paymentMethod?: string;
  keywords?: string;
  skip?: number;
  limit?: number;
  sort?: Sort;
};

const onDataNeeded = (filters: Body) => {
  const path = '/1/cpo-payments/fees';

  const from = filters.from ? startOfDay(new Date(filters.from)) : undefined;
  const to = filters.to ? endOfDay(new Date(filters.to)) : undefined;

  const body: Body = {
    from,
    to,
    paymentMethod: filters.paymentMethod,
    keywords: filters.keywords,
    skip: filters.skip,
    limit: filters.limit,
    sort: filters.sort,
  };

  return request({
    method: 'POST',
    path,
    body,
  });
};

export default function MyPaymentFeesList() {
  const { t, i18n } = useTranslation();

  const searchRef = React.useRef(null);

  const { labels, filterMapping } = useMemo(() => {
    const labels = {
      from: t('my-payment-fees.filterByFromDate', 'From'),
      to: t('my-payment-fees.filterByToDate', 'To'),
      paymentMethod: t(
        'my-payment-fees.filters.paymentMethod',
        'My Payment Method'
      ),
      scanToPay: t('my-payment-fees.filters.scanToPay', 'Scan to Pay'),
      tapToPay: t('my-payment-fees.filters.tapToPay', 'Tap to Pay'),
    };

    const filterMapping = {
      keywords: {
        type: 'search',
      },
      from: {
        label: labels.from,
        type: 'string',
      },
      to: {
        label: labels.to,
        type: 'string',
      },
      paymentMethod: {
        label: labels.paymentMethod,
        type: 'string',
      },
    };
    return {
      labels,
      filterMapping,
    };
  }, [i18n.language]);

  return (
    <Search.Provider
      ref={searchRef}
      limit={20}
      filterMapping={filterMapping}
      onDataNeeded={onDataNeeded}>
      <Breadcrumbs
        active={t('my-payment-fees.list.breadcrumb', 'My Payment Fees')}
      />

      <ListHeader title={t('my-payment-fees.list.title', 'My Payment Fees')} />

      <Segment>
        <Layout horizontal spread stackable>
          <SearchFilters.Modal size="tiny">
            <label></label>
            <Form.Group widths="equal">
              <SearchFilters.Input
                name="from"
                fluid
                type="date"
                label={t('my-payment-fees.list.filters.from', 'From')}
              />
              <SearchFilters.Input
                fluid
                name="to"
                type="date"
                label={t('my-payment-fees.filters.to', 'To')}
              />
            </Form.Group>

            <SearchFilters.Dropdown
              clearable
              objectMode={false}
              label={labels.paymentMethod}
              name="paymentMethod"
              search
              options={[
                {
                  key: 'scan-to-pay',
                  value: 'scan-to-pay',
                  text: labels.scanToPay,
                },
                {
                  key: 'tap-to-pay',
                  value: 'tap-to-pay',
                  text: labels.tapToPay,
                },
              ]}
            />
          </SearchFilters.Modal>

          <Layout horizontal stackable center right>
            <Search.Total />
            <SearchFilters.Search
              name="keywords"
              placeholder={t(
                'my-payment-fees.list.filter.search.placeholder',
                'ID'
              )}
            />
          </Layout>
        </Layout>
      </Segment>
      <Search.Status noResults={t('common.noResults', 'No Results')} />
      <Table />
      <div
        style={{
          textAlign: 'center',
        }}>
        <Search.Pagination />
      </div>
    </Search.Provider>
  );
}
