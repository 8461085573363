import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormField, Header } from 'semantic';
import { useFormikContext } from 'formik';
import styles from './BillingInfo.module.less';
import Countries from 'components/form-fields/formik/Countries';
import InputField from 'components/form-fields/formik/InputField';
import { BillingAndRequestCardFormValues } from 'components/BillingInfo/types';

export function BillingAddressFormPartHeader() {
  const { t } = useTranslation();
  return (
    <Header as={'h3'} style={{ paddingLeft: 0, fontWeight: 600 }}>
      {t('billingInfo.billingAddressHeader', 'Billing Address')}
    </Header>
  );
}

export type BillingAddress = {
  countryCode: string;
  street: string;
  number: string;
  postalCode: string;
  city: string;
};

type BillingAddressFormPartProps = {
  value: BillingAddress;
  objectPath?: string;
  showErrorLabels?: boolean;
  enableCountryCodeInput?: boolean;
};

export function BillingAddressFormPart(props: BillingAddressFormPartProps) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext<BillingAndRequestCardFormValues>();
  const prefix = props.objectPath ? `${props.objectPath}.` : '';
  const formatFieldName = (fieldName: string) => prefix + fieldName;

  return (
    <>
      <FormField>
        <Countries
          disabled={!props.enableCountryCodeInput}
          name={formatFieldName('countryCode')}
          label={t('formLabel.country', 'Country')}
          hideErrorLabel={!props.showErrorLabels}
        />
      </FormField>
      <Form.Group>
        <FormField width={12}>
          <InputField
            required
            validateImmediately
            name={formatFieldName('street')}
            label={t('billingInfo.street', 'Street')}
            hideErrorLabel={!props.showErrorLabels}
            onChange={(e) => {
              setFieldValue(formatFieldName('street'), e.target.value);
            }}
          />
        </FormField>
        <FormField width={4} className={styles.formField}>
          <InputField
            required
            validateImmediately
            name={formatFieldName('number')}
            label={t('billingInfo.number', 'No.')}
            hideErrorLabel={!props.showErrorLabels}
            onChange={(e) => {
              setFieldValue(formatFieldName('number'), e.target.value);
            }}
          />
        </FormField>
      </Form.Group>
      <Form.Group>
        <FormField width={6} className={styles.formField}>
          <InputField
            required
            validateImmediately
            name={formatFieldName('postalCode')}
            label={t('billingInfo.postalCode', 'Postal Code')}
            hideErrorLabel={!props.showErrorLabels}
            onChange={(e) => {
              setFieldValue(formatFieldName('postalCode'), e.target.value);
            }}
          />
        </FormField>
        <FormField width={10} className={styles.formField}>
          <InputField
            required
            validateImmediately
            name={formatFieldName('city')}
            label={t('billingInfo.city', 'City/Town')}
            hideErrorLabel={!props.showErrorLabels}
            onChange={(e) => {
              setFieldValue(formatFieldName('city'), e.target.value);
            }}
          />
        </FormField>
      </Form.Group>
    </>
  );
}
