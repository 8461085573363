import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export function useBillingInfoFormikValidationSchema(
  accountType: 'individual' | 'organization',
  enableBicCollection: boolean
) {
  const billingAddressValidationSchema =
    useBillingAddressFormikValidationSchema();
  const paymentInfoValidationSchema =
    usePaymentInfoFormikValidationSchema(enableBicCollection);
  const organizationInfoValidationSchema =
    useBillingOrganizationInfoFormikValidationSchema();

  return {
    ...billingAddressValidationSchema,
    ...paymentInfoValidationSchema,
    ...(accountType === 'organization' ? organizationInfoValidationSchema : {}),
  };
}

export function useBillingAddressFormikValidationSchema() {
  const { t } = useTranslation();
  return {
    countryCode: Yup.string().required(
      t('signup.countryCodeRequired', 'Country code is required')
    ),
    street: Yup.string().required(
      t('signup.streetRequired', 'Street is required')
    ),
    number: Yup.string().required(
      t('signup.numberRequired', 'Number is required')
    ),
    postalCode: Yup.string().required(
      t('signup.postalCodeRequired', 'Postal code is required')
    ),
    city: Yup.string().required(t('signup.cityRequired', 'City is required')),
  };
}

export function usePaymentInfoFormikValidationSchema(
  enableBicCollection: boolean
) {
  const { t } = useTranslation();
  return {
    paymentMethod: Yup.string().required(
      t('signup.paymentMethodRequired', 'Payment method is required')
    ),
    accountHolderName: Yup.string().when(
      ['paymentMethod'],
      ([paymentMethod], schema) => {
        if (paymentMethod === 'manual') {
          return schema;
        }
        return schema.required(
          t(
            'signup.accountHolderNameRequired',
            'Account holder name is required'
          )
        );
      }
    ),
    ibanNumber: Yup.string().when(
      ['paymentMethod'],
      ([paymentMethod], schema) => {
        if (paymentMethod === 'autopay') {
          return schema.required(
            t('signup.ibanNumberRequired', 'IBAN number is required')
          );
        }
        return schema;
      }
    ),
    bicNumber: Yup.string().when(
      ['paymentMethod'],
      ([paymentMethod], schema) => {
        if (paymentMethod === 'autopay' && enableBicCollection) {
          return schema.required(
            t('signup.bicNumberRequired', 'BIC number is required')
          );
        }
        return schema;
      }
    ),
    sepaAccepted: Yup.boolean().when(
      ['paymentMethod'],
      ([paymentMethod], schema) => {
        if (paymentMethod === 'manual') {
          return schema;
        }
        return schema.oneOf(
          [true],
          t('signup.sepaAcceptedRequired', 'Please accept the SEPA terms')
        );
      }
    ),
  };
}

export function useBillingOrganizationInfoFormikValidationSchema() {
  const { t } = useTranslation();
  const vatFieldName = 'vatNumber';
  const vatNotApplicableFieldName = 'vatNotApplicable';
  const chamberOfCommerceNumberFieldName = 'chamberOfCommerceNumber';
  return {
    [vatNotApplicableFieldName]: Yup.boolean(),
    [vatFieldName]: Yup.string().when(
      ['vatNotApplicable', 'countryCode'],
      ([vatNotApplicable], schema) => {
        return vatNotApplicable
          ? schema
          : schema.required(
              t('signup.vatNumberRequired', 'VAT number is required')
            );
      }
    ),
    [chamberOfCommerceNumberFieldName]: Yup.string().when(
      ['countryCode'],
      ([countryCode], schema) => {
        switch (countryCode?.toUpperCase()) {
          case 'NL': // Netherlands
            return schema.required(
              t(
                'signup.chamberOfCommerceNumberRequired',
                'Chamber of Commerce number is required'
              )
            );
          case 'FR': // France
            return schema.required(
              t('signup.siretNumberRequired', 'SIRET number is required')
            );
          case 'GB': // United Kingdom
            return schema.required(
              t(
                'signup.companyRegistrationNumberRequired',
                'Company registrations number is required'
              )
            );
          default:
            return schema;
        }
      }
    ),
  };
}
