import React from 'react';
import { Segment, Item, Loader, Message } from 'semantic';
import EvseConnector from 'components/EvseConnector';
import { request } from 'utils/api';
import { Divider } from 'semantic';

export default class ConnectorsCpo extends React.Component {
  state = { loading: true, evseControllers: [] };

  componentDidMount() {
    this.fetchActiveSessions();
    this.fetchEvseControllers();
  }

  async fetchActiveSessions() {
    try {
      const { data: activeSessions } = await request({
        method: 'POST',
        path: `/2/locations/cpo/${this.props.location.id}/sessions/search`,
        body: {
          limit: 10,
          status: 'ACTIVE',
        },
      });
      this.setState({ activeSessions, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  async fetchEvseControllers() {
    try {
      const { data: evseControllers } = await request({
        method: 'POST',
        body: {
          limit: 1000,
          skip: 0,
        },
        path: `/2/locations/cpo/${this.props.location.id}/evse-controllers/search`,
      });
      this.setState({ evseControllers, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  getActiveSession(activeSessions = [], evseControllerId, connectorId) {
    return activeSessions.filter(
      (session) =>
        session.evseControllerId == evseControllerId &&
        session.connectorId == connectorId
    )[0];
  }

  render() {
    const { activeSessions, loading, error } = this.state;

    if (loading) {
      return <Loader />;
    }
    if (error) {
      return <Message error content={error.message} />;
    }

    return (
      <div>
        {this.state.evseControllers.map((evseController) => {
          return (
            <div key={evseController.id}>
              <Segment basic>
                <Item.Group>
                  {evseController.connectors.map((connector) => {
                    const activeSession = this.getActiveSession(
                      activeSessions,
                      evseController.evseControllerId,
                      connector.connectorId
                    );

                    const connectorStatus =
                      evseController.connectorStatus?.[connector.connectorId]
                        ?.status;

                    return (
                      <EvseConnector
                        key={connector._id}
                        connectorStandard={connector.standard}
                        evseId={connector.evseId || evseController.evseId}
                        powerType={connector.powerType}
                        connectorFormat={connector.format}
                        activeSession={activeSession}
                        status={connectorStatus}
                      />
                    );
                  })}
                </Item.Group>
              </Segment>
              <Divider />
            </div>
          );
        })}
      </div>
    );
  }
}
