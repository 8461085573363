import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Menu, Divider } from 'semantic';
import logoInverted from 'assets/logo.svg';
import Footer from './Footer';

export default class PublicAppWrapper extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
        }}>
        <Menu inverted stackable style={{ borderRadius: 0 }}>
          <Container>
            <Menu.Item as={Link} to="/">
              <img
                style={{ width: '30px' }}
                className="logo"
                src={`${logoInverted}`}
              />
            </Menu.Item>
          </Container>
        </Menu>
        <Container style={{ marginTop: '20px', flex: 1 }}>
          {this.props.children}
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />
        </Container>
        {!window.navigator.userAgent.includes('Trident') && <Footer />}
      </div>
    );
  }
}
