import React from 'react';
import { Container, Header, Divider } from 'semantic';
import { isAccountAdmin, isSuperAdmin } from '../../../utils/roles';
import { request } from 'utils/api';

import Table from 'components/tables/Sessions';

import { Search, Layout } from 'components';
import { safeFileName } from 'utils/formatting';

const itemLimit = 20;

import { useUser } from 'contexts/user';
import { hasProviderRoles } from 'utils/roles';
import { useTranslation } from 'react-i18next';

export default function LocationSession({ location }) {
  const { t } = useTranslation();
  const { user } = useUser();

  async function onDataNeeded(filters = {}) {
    const response = await request({
      method: 'POST',
      path: `/2/locations/cpo/${location.id}/sessions/search`,
      body: {
        ...filters,
      },
    });

    return response;
  }

  let props = {};

  if (hasProviderRoles(user)) {
    props.includeResyncAction = true;
  }

  return (
    <Container>
      <Search.Provider onDataNeeded={onDataNeeded} limit={itemLimit}>
        <Header as="h2">
          <Layout horizontal spread>
            {t('accountCpoSessions.title', 'CPO Sessions')}
            <Search.ExportRange
              onRangeSubmit={async (from, to, filters) => {
                await onDataNeeded({
                  format: 'csv',
                  limit: 100000,
                  endedAt: {
                    $gte: from,
                    $lte: to,
                  },
                  filename: safeFileName(`cpo-sessions-${location.name}`),
                  ...filters,
                });
              }}
            />
          </Layout>
        </Header>
        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table
          linkToSessionDetail={isSuperAdmin(user) && !isAccountAdmin(user)}
          internalCalculatedPrice={user.roles.includes('consumer')}
          hasActions
          includeStopAction
          stopSessionForLocation
          hideInspectAction={user.roles.includes('consumer')}
          // Note: This a temporary fix, as we're allowing Account Admins access to
          // locations (which is a Provider Admin endpoint).
          // By setting the prop below to true, the Session Table will fetch sessions from /2/sessions/cpo/mine/:sessionId
          // instead of /2/sessions/cpo/:sessionId
          sessionDetailFromMineEndpoint={
            !isSuperAdmin(user) && isAccountAdmin(user)
          }
        />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </Search.Provider>
    </Container>
  );
}
