import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Button } from 'semantic';
import AnalyticsToken from 'components/AnalyticsToken';
import { TectonicProvider, TimeRangePicker } from 'react-tectonic';
import { request } from 'utils/api';

import Accounts from './Accounts';
import Msp from './Msp';
import Cpo from './Cpo';

import { withTranslation } from 'react-i18next';
import { sessionsExclusionFilter } from 'utils/visualizations';

import { TECTONIC_URL } from 'utils/env';
import { getTimeRangeFromLocations } from '../../../utils/analytics';
import { timeOptions } from '../timeOptions';

class Analytics extends React.Component {
  state = {
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.fetchMyLocations();
  }
  async fetchMyLocations() {
    try {
      const { data } = await request({
        method: 'POST',
        path: '/2/locations/cpo/search',
        body: {
          limit: 10000,
          shallow: true,
          sort: {
            order: 'desc',
            field: 'createdAt',
          },
        },
      });
      this.setState({ locations: data, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const { t, location } = this.props;
    const { locations, loading, error } = this.state;
    let dateField = 'createdAt';
    const tokenPath = location.pathname.match(/cpo/)
      ? '/1/users/me/analytics-tokens/provider-cpo-analytics'
      : '/1/users/me/analytics-tokens/provider-msp-analytics';
    if (location.pathname.match(/cpo/)) {
      dateField = 'endedAt';
    }

    const timeRange = getTimeRangeFromLocations(locations);

    return (
      <AppWrapper>
        <AnalyticsToken path={tokenPath}>
          <TectonicProvider
            baseUrl={TECTONIC_URL}
            collection="sessions"
            dateField={dateField}
            statsFilter={sessionsExclusionFilter}
            timeRange={timeRange}>
            <React.Fragment>
              <div style={{ float: 'right', marginTop: '-10px' }}>
                <TimeRangePicker
                  align="right"
                  timeOptions={[
                    timeOptions.today,
                    timeOptions.yesterday,
                    timeOptions.ThisWeek,
                    timeOptions.ThisMonth,
                    timeOptions.ThisYear,
                    timeOptions.hoursInput,
                    timeOptions.daysInput,
                    timeOptions.monthsInput,
                    timeOptions.selectYear,
                    timeOptions.selectMonths,
                  ]}
                  renderButton={(label, handleOnClick) => (
                    <Button
                      primary
                      icon="clock"
                      content={label}
                      onClick={handleOnClick}
                    />
                  )}
                />
              </div>
              <Switch>
                <Route
                  path="/analytics/msp"
                  render={(props) => <Msp {...props} />}
                />
                <Route
                  path="/analytics/cpo"
                  render={(props) => <Cpo {...props} />}
                />
                <Route
                  exact
                  path="/analytics"
                  render={(props) => <Accounts {...props} />}
                />
              </Switch>
            </React.Fragment>
          </TectonicProvider>
        </AnalyticsToken>
      </AppWrapper>
    );
  }
}

export default withTranslation()(Analytics);
