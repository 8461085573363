import {
  EvseController,
  EvseControllerAuthenticationMethod,
  EvseControllerConnectionProtocol,
  RenewBasicAuthPasswordSyncStatus,
} from 'types/evse-controller';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Button, Message, Modal } from 'semantic';
import { request } from 'utils/api';
import { Option } from 'screens/EvseControllers/Detail/SecurityProfileModal';
import Divider from 'components/Sidebar/Divider';

type Props = {
  evseController: EvseController;
  onRenew?: () => void;
};

export default function RenewBasicAuthModal({
  evseController,
  onRenew,
}: Props) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [renewLoading, setRenewLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  let selectedProfile = 0;

  if (
    evseController.ocppAuthorizationMethod ===
    EvseControllerAuthenticationMethod.Basic
  ) {
    switch (evseController.connectionProtocol) {
      case EvseControllerConnectionProtocol.Ws:
        selectedProfile = 1;
        break;
      case EvseControllerConnectionProtocol.Wss:
        selectedProfile = 2;
        break;
    }
  }

  const renewBasicAuth = async () => {
    setRenewLoading(true);
    setError(null);
    try {
      await request({
        method: 'POST',
        path: `/1/evse-controllers/${evseController.id}/renew-basic-auth-password`,
      });
      setModalOpen(false);
    } catch (error) {
      setError(error as Error);
    } finally {
      setRenewLoading(false);
      onRenew && onRenew();
    }
  };

  return (
    <Modal
      closeIcon
      open={modalOpen}
      onOpen={() => setModalOpen(true)}
      onClose={() => setModalOpen(false)}
      size="tiny"
      trigger={
        <Button
          primary
          disabled={
            evseController.renewBasicAuthPasswordSync?.status ===
            RenewBasicAuthPasswordSyncStatus.InProgress
          }
          icon="lock"
          content={t('renewBasicAuthModal.openModalButton', 'Renew Basic Auth')}
        />
      }>
      <Modal.Header>
        {t('renewBasicAuthModal.title', 'Renew Basic Authentication')}
      </Modal.Header>
      <Modal.Content>
        {error && <Message error content={error.message} />}
        <p>
          {t(
            'renewBasicAuthModal.description',
            'This will update the charging station with a new password to connect to the OCPP server.'
          )}
        </p>
        <Divider />
        <h4>
          {t(
            'renewBasicAuthModal.selectedSecurityProfile',
            'Enabled security profile on charging station'
          )}
        </h4>
        <div>
          {selectedProfile === 1 && (
            <Option
              inactive
              buttonStyle={{ cursor: 'default', pointerEvents: 'none' }}
              title={t(
                'securityProfileModal.optionSecurityProfile1Title',
                'Security Profile 1'
              )}
              description={t(
                'securityProfileModal.optionSecurityProfile1Description',
                'Security profile 1 with Basic Authentication'
              )}
              note={t(
                'securityProfileModal.optionSecurityProfile1Note',
                'Use for VPN connected stations - connected to ws.'
              )}
              onClick={() => {}}
              selected={false}
            />
          )}
          {selectedProfile === 2 && (
            <Option
              inactive
              buttonStyle={{ cursor: 'default', pointerEvents: 'none' }}
              title={t(
                'securityProfileModal.optionSecurityProfile2Title',
                'Security Profile 2'
              )}
              description={t(
                'securityProfileModal.optionSecurityProfile2Description',
                'Security profile 2 with Basic Authentication'
              )}
              note={t(
                'securityProfileModal.optionSecurityProfile2Note',
                'Use for stations connected using SSL/TLS - connected to wss.'
              )}
              onClick={() => {}}
              selected={false}
            />
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => setModalOpen(false)}
          content={t('renewBasicAuthModal.cancel', 'Cancel')}
        />
        <Button
          primary
          loading={renewLoading}
          onClick={() => renewBasicAuth()}
          content={t(
            'renewBasicAuthModal.renewBasicAuthButton',
            'Renew Basic Authentication'
          )}
        />
      </Modal.Actions>
    </Modal>
  );
}
