import React, { useState } from 'react';

import { Form, Message } from 'semantic';
import PriceInput from 'components/form-fields/Price';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

interface CurrencyLimits {
  // Holds the minimum amount that can be preauthorized
  min: number;
  // Holds the maximum amount that can be preauthorized
  max: number;
  // Holds the default amount that will be preauthorized in case there's no amount specified
  default: number;
}

interface Props {
  value?: number;
  currency: string;
  locationId: string;
  evseUid: string;
  connectorId: string;
  onChange: (value: number) => void;
}

export default function PreauthLimitField({
  value,
  currency,
  locationId,
  evseUid,
  connectorId,
  onChange,
}: Props) {
  const { t } = useTranslation();

  const [defaultValue, setDefaultValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const [error, setError] = useState(null);

  function getPreauthLimits() {
    return request({
      method: 'GET',
      path: `/1/remote-sessions/msp/preauth-limits/${locationId}/${evseUid}/${connectorId}`,
    })
      .then((body: { data: CurrencyLimits }) => {
        setDefaultValue(body.data.default);
        setMinValue(body.data.min);
        setMaxValue(body.data.max);

        onChange(body.data.default);
      })
      .catch((error) => {});
  }

  React.useEffect(() => {
    getPreauthLimits();
  }, []);

  function handleChange(value: number) {
    setError(null);

    if (value < minValue || value > maxValue) {
      setError(
        t(
          'startRemoteSessionModal.preauthLimitError',
          'The amount must be between {{min}} and {{max}}',
          {
            min: minValue,
            max: maxValue,
          }
        )
      );
    }
    onChange(value);
  }

  return (
    <Form.Field>
      <label>
        {t('startRemoteSessionModal.preauthLimit', 'Maximum amount charged')}
      </label>
      <Message color="blue">
        <p>
          {t(
            'startRemoteSessionModal.preauthLimitDescription',
            'The value below represents the maximum amount that will be charged during this session. This amount will be reserved on your card. If the session ends before the maximum amount is reached, only the actual amount used will be charged.'
          )}
        </p>
      </Message>
      <PriceInput
        readOnly={false}
        value={value || defaultValue}
        currency={currency}
        onChange={handleChange}
      />
      {error && (
        <Message negative>
          <p>{error}</p>
        </Message>
      )}
    </Form.Field>
  );
}
