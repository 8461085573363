import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'semantic';

export default function DateObjectInput({
  label,
  value,
  onChange,
  validate,
  disabled,
  clearable,
}) {
  const [validationError, setValidationError] = useState(null);

  const dateString = useMemo(() => dateObjToString(value), [value]);

  const doValidation = (nextDate) => {
    try {
      validate?.(nextDate);
      setValidationError(null);
      return true;
    } catch (e) {
      setValidationError({ content: e.message });
      return false;
    }
  };

  useEffect(() => {
    doValidation(value);
  }, [validate]);

  const onDateChange = useCallback(
    (nextDateString) => {
      const nextDate = new Date(nextDateString);
      if (!nextDateString || isNaN(nextDate.valueOf())) {
        setValidationError(null);
        return;
      }
      const nextValue = dateToDateObject(nextDate);
      doValidation(nextValue);
      onChange?.(nextValue);
    },
    [validate]
  );

  return (
    <Form.Field
      label={label}
      disabled={disabled}
      clearable={(!!clearable).toString()}
      type={'date'}
      control={'input'}
      value={dateString}
      onChange={(e) => onDateChange(e.target.value)}
      error={validationError}
    />
  );
}

function dateToDateObject(date) {
  return {
    day: date.getUTCDate(),
    month: date.getUTCMonth() + 1,
    year: date.getUTCFullYear(),
  };
}

export function dateObjToString(dateObj) {
  const day = (dateObj?.day || '').toString().padStart(2, '0');
  const month = (dateObj?.month || '').toString().padStart(2, '0');
  const year = (dateObj?.year || '').toString().padStart(4, '0');
  const dateString = `${year}-${month}-${day}`;
  if (dateString === '0000-00-00') {
    return '';
  }
  return dateString;
}
