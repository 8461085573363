import SearchDropdown from 'components/SearchDropdown';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Segment, Header } from 'semantic-ui-react';
import { request } from 'utils/api';
import {
  BillingPolicyMembers,
  LocationBillingPolicySubscriptionBilling,
} from './types';

type HOAConfigurationProps = {
  subscriptionBillingItems: LocationBillingPolicySubscriptionBilling[];
  onChange: (
    key: string,
    value: LocationBillingPolicySubscriptionBilling[]
  ) => void;
};

export const HOAConfiguration = ({
  subscriptionBillingItems = [],
  onChange,
}: HOAConfigurationProps) => {
  const { t } = useTranslation();

  const activeAccountId =
    subscriptionBillingItems.length > 0 &&
    subscriptionBillingItems[0]?.entity?.type === 'account'
      ? subscriptionBillingItems[0]?.entity?.accountId
      : '';

  return (
    <Segment>
      <Header as="h3">{t('accessPolicies.hoaPolicy', 'HOA Policy')}</Header>

      <p>
        {t(
          'accessPolicies.hoaPolicyNote',
          'Specify the account that will be charged for subscription billing. Your own account will be used for reimbursement.'
        )}
      </p>

      <Form.Field required={true}>
        <label>
          {t('editLocation.HOAAccountToChange', 'Account To Charge')}
        </label>
        <SearchDropdown
          objectMode={false}
          valueGetter={({
            options,
            value: id,
          }: {
            options: any[];
            value: string;
          }) => {
            return options.find(
              (option: any) => option.id === id && option.type === 'account'
            )?.id;
          }}
          value={activeAccountId}
          populateOnLoad
          onDataNeeded={async (body: { name: string } | undefined) => {
            const { data } = await request<BillingPolicyMembers>({
              path: `/2/locations/cpo/billing-policy-members/search`,
              method: 'POST',
              body: {
                accessPolicy: 'comunityReimburse',
                name: body?.name,
              },
            });
            return {
              data: data.filter((item) => item.type === 'account'),
            };
          }}
          onChange={(_: ChangeEvent, { value }: { value: string }) => {
            onChange('billingPolicy.subscriptionBilling', [
              {
                percentage: 100,
                entity: {
                  type: 'account',
                  accountId: value,
                },
              },
            ]);
          }}
        />
      </Form.Field>
    </Segment>
  );
};
