import React from 'react';
import { FormikProps } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic';
import { ValidationError, ValidationErrorResponse } from 'types/api';
import { RequestCardFormValues } from 'components/modals/RequestCard/v2/formData';

export class CardRequestError extends Error {
  internalCodes: string[];

  constructor(
    public type: 'billing' | 'invite' | 'card',
    public innerError: ValidationErrorResponse
  ) {
    super('Card request failed');
    this.internalCodes = innerError.details?.map((c) => c.code);
  }

  static Billing(innerError: ValidationErrorResponse) {
    return new CardRequestError('billing', innerError);
  }

  static Invite(innerError: ValidationErrorResponse) {
    return new CardRequestError('invite', innerError);
  }

  static Card(innerError: ValidationErrorResponse) {
    return new CardRequestError('card', innerError);
  }
}

type CardRequestErrorMessageProps = {
  error: CardRequestError | null;
  onDismiss?: () => void;
};

export function CardRequestErrorMessage(props: CardRequestErrorMessageProps) {
  const { t } = useTranslation();
  if (!props.error) {
    return null;
  }

  return (
    <Message error onDismiss={props.onDismiss}>
      {props.error.type === 'billing' &&
        t(
          'addCardModal.billingError',
          'There was a problem updating your billing info'
        )}
      {props.error.type === 'invite' &&
        t(
          'addCardModal.inviteError',
          'There was a problem inviting the new card holder'
        )}
      {props.error.type === 'card' &&
        t(
          'addCardModal.billingError',
          'There was a problem submitting your card request'
        )}

      {props.error.internalCodes?.length > 0 && (
        <code
          style={{
            fontSize: '11px',
            display: 'block',
            textTransform: 'uppercase',
            marginTop: '0.2em',
          }}>
          {t('addCardModal.errorCode', 'ERROR Code:')}{' '}
          {props.error.internalCodes.toString()}
        </code>
      )}
    </Message>
  );
}

export function useRequestCardErrorFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (
      error: ValidationError,
      formikProps: FormikProps<RequestCardFormValues> | null
    ) => {
      if (!formikProps) {
        return;
      }
      switch (error.code) {
        case 'INVITE_ALREADY_EXISTS':
          formikProps.setFieldError(
            'cardHolder.email',
            t(
              'inviteUser.errors.inviteAlreadyExists',
              'There is already a pending invite for this email address'
            )
          );
          break;
        case 'USER_ALREADY_EXISTS':
          formikProps.setFieldError(
            'cardHolder.email',
            t(
              'inviteUser.errors.userAlreadyExists',
              'This email address is already in use by another user'
            )
          );
          break;
        case 'EMAIL_NOT_SENT':
          formikProps.setFieldError(
            'cardHolder.email',
            t(
              'inviteUser.errors.failedToNotifyInvitee',
              'Failed to send an invitation to this email address'
            )
          );
          break;
        case 'SIGNUP_INVALID_IBAN':
          formikProps.setFieldError(
            'billing.ibanNumber',
            t('updateBillingInfo.errors.invalidIban', 'Invalid IBAN number')
          );
          break;
        case 'SIGNUP_INVALID_BIC':
          formikProps.setFieldError(
            'billing.bicNumber',
            t('updateBillingInfo.errors.invalidBic', 'Invalid BIC number')
          );
          break;
        case 'SIGNUP_INVALID_POSTAL_CODE_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.postalCode',
            t(
              'updateBillingInfo.errors.invalidPostalCode',
              'Invalid postal code'
            )
          );
          break;
        case 'SIGNUP_INVALID_COUNTRY':
          formikProps.setFieldError(
            'billing.countryCode',
            t(
              'updateBillingInfo.errors.invalidCountryCode',
              'Please select a supported country'
            )
          );
          break;
        case 'SIGNUP_VAT_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.vatNumber',
            t(
              'updateBillingInfo.errors.invalidVatNumber',
              'Invalid for the selected country'
            )
          );
          break;
        case 'SIGNUP_COC_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.chamberOfCommerceNumber',
            t(
              'updateBillingInfo.errors.invalidVatNumber',
              'Invalid for the selected country'
            )
          );
          break;
        default:
          console.error('Unhandled request card error', error);
      }
    },
    [t]
  );
}
