import React from 'react';
import SettingsShell from '../../../components/wrappers/SettingsShell';
import ReimbursementForm from './Reimbursement.form';

export default function OrganizationReimbursementLayout() {
  return (
    <SettingsShell>
      <ReimbursementForm />
    </SettingsShell>
  );
}
