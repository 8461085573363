import React from 'react';
import { Button } from 'semantic';
import { Layout } from 'components/Layout';
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'semantic';
import PortalNav from './PortalNav';
import { useUser } from 'contexts/user';
import { useTheme } from 'contexts/theme';
import './PortalWrapper.less';
import ThemedImage from './ThemeImage';
import { getThemedField } from 'utils/theming';

export default function PortalWrapper({ children }) {
  const { provider } = useUser();
  const { renderedTheme } = useTheme();

  let headStyle = {};
  const isDark = renderedTheme === 'dark';

  const secondaryBackgroundColorHex = getThemedField(
    provider,
    'secondaryBackgroundColorHex',
    isDark
  );
  if (secondaryBackgroundColorHex) {
    headStyle = { background: secondaryBackgroundColorHex };
  }

  return (
    <PortalNav className={`provider-${provider.slug}`}>
      <PortalNav.PageHead style={headStyle}>
        <div className="portal-nav__head-brand">
          <Layout horizontal center spread>
            <NavLink className="portal__logo" to="/docs">
              <ThemedImage
                darkSrc={provider?.invertedLogoUrl}
                ligthSrc={provider?.logoUrl}
                height="30"
              />
            </NavLink>
            <div>
              {' '}
              {/* I'm sure there's a better way to use link buttons */}
              {provider.slug === 'road' ? (
                <Button
                  primary
                  compact
                  content="Sign Up"
                  as="a"
                  href="/api/signup"
                  rel="noopener"
                  target="_blank"
                />
              ) : (
                <NavLink to="/">
                  <Button primary compact content="Go to dashboard" />
                </NavLink>
              )}
            </div>
          </Layout>
        </div>
        <div className="portal-nav__head-menu">
          <Menu secondary pointing horizontal>
            <Layout horizontal center spread>
              <Layout.Group>
                <Menu.Item as={NavLink} exact to="/docs">
                  <Icon name="house" /> Home
                </Menu.Item>
                <Menu.Item as={NavLink} to="/docs/charge-stations">
                  <Icon name="charging-station" /> Charge Stations
                </Menu.Item>
                <Menu.Item as={NavLink} to="/docs/cards">
                  <Icon name="credit-card" /> Charge Cards
                </Menu.Item>
                <Menu.Item as={NavLink} to="/docs/roaming">
                  <Icon name="globe" /> Roaming
                </Menu.Item>
                <Menu.Item as={NavLink} to="/docs/location-data">
                  <Icon name="location-arrow" /> Location data
                </Menu.Item>
                <Menu.Item as={NavLink} to="/docs/location-map">
                  <Icon name="map" /> Locations Map
                </Menu.Item>
              </Layout.Group>
              <Layout.Group>
                <Menu.Item as={NavLink} to="/docs/reference">
                  <Icon name="book" /> API reference
                </Menu.Item>
                <Menu.Item
                  as={'a'}
                  href="https://www.e-flux.io/support/"
                  target="blank">
                  <Icon name="circle-question" /> Support
                </Menu.Item>
              </Layout.Group>
            </Layout>
          </Menu>
        </div>
      </PortalNav.PageHead>

      <PortalNav.MobileSidebar style={headStyle}>
        <Layout vertical spread style={{ height: '100%' }}>
          <Layout.Group grow overflow>
            <PortalNav.Link exact to="/docs">
              <Icon name="house" /> Home
            </PortalNav.Link>

            <PortalNav.Link to="/docs/charge-stations">
              <Icon name="charging-station" /> Charge Stations
            </PortalNav.Link>
            <PortalNav.Link to="/docs/cards">
              <Icon name="credit-card" /> Charge Cards
            </PortalNav.Link>
            <PortalNav.Link to="/docs/roaming">
              <Icon name="globe" /> Roaming
            </PortalNav.Link>
            <PortalNav.Link to="/docs/location-data">
              <Icon name="location-arrow" /> Location data
            </PortalNav.Link>
            <PortalNav.Link to="/docs/location-map">
              <Icon name="map" />
              Locations map
            </PortalNav.Link>
          </Layout.Group>
          <Layout.Group>
            <PortalNav.Link to="/docs/reference">
              <Icon name="book" /> API reference
            </PortalNav.Link>
            <a
              href="https://www.e-flux.io/support/"
              className="portal-nav-link"
              target="_blank"
              rel="noopener noreferrer">
              <Icon name="circle-question" /> Support
            </a>
          </Layout.Group>
        </Layout>
      </PortalNav.MobileSidebar>

      <PortalNav.Container>{children}</PortalNav.Container>
    </PortalNav>
  );
}
