import React, { ReactNode } from 'react';
import AccountSettingsSidebar from '../organisms/SettingsSidebar';
import { Flex } from '../../../../components/Layout/helpers';
import { AppWrapper } from '../../../../components';
import { AccountProvider } from 'contexts/account';

export default function SettingsShell({ children }: { children: ReactNode }) {
  return (
    <AccountProvider>
      <AppWrapper>
        <Flex direction="row" wrap="wrap" justify="flex-start" columnGap="sm">
          <AccountSettingsSidebar />
          <div style={{ marginTop: 28, minWidth: 400, maxWidth: 800 }}>
            {children}
          </div>
        </Flex>
      </AppWrapper>
    </AccountProvider>
  );
}
