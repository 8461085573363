import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-tectonic';

import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import MultiCardinalityDonutChart from 'components/analytics/MultiCardinalityDonutChart';
import { getColors, mergeFilters } from 'utils/visualizations';
import { useUser } from 'contexts/user';

export default function MaintainceIssues({ baseFilter = {} }) {
  const { t } = useTranslation();
  const { provider } = useUser();
  const colorHex = `${provider.colorHex}`;

  const colors = getColors(colorHex);

  return (
    <ErrorBoundary>
      <Divider hidden />
      <Divider hidden />
      <Statistic.Group widths={3} style={{ justifyContent: 'space-between' }}>
        <CardinalityStatistic
          collection="sessions"
          field="evseControllerId"
          label={t('common.evses', 'EVSEs')}
          filter={baseFilter}
        />
        <CardinalityStatistic
          collection="evseissues"
          label={t('common.openIssues', 'Open Issues')}
          filter={mergeFilters(
            {
              terms: [{ isResolved: false }],
            },
            baseFilter
          )}
        />
        <CardinalityStatistic
          collection="evseissues"
          label={t('common.resolvedIssues', 'Resolved Issues')}
          filter={mergeFilters(
            {
              terms: [{ isResolved: true }],
            },
            baseFilter
          )}
        />
      </Statistic.Group>
      <Divider hidden />
      <Divider hidden />
      <React.Fragment>
        <Divider hidden />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <TermsDonutChart
                  filter={baseFilter}
                  collection="evseissues"
                  aggField="severity"
                  operation="count"
                  title={t('common.issueSeverities', 'Issue Severities')}
                  termsSize={15}
                  colors={colors}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <Segment basic>
                <MultiCardinalityDonutChart
                  title={t(
                    'analyticsMaintenance.issueResolutionStatus',
                    'Issue Resolution Status'
                  )}
                  collection="evseissues"
                  colors={colors}
                  items={[
                    {
                      label: t('common.resolved', 'Resolved'),
                      request: {
                        collection: 'evseissues',
                        fields: ['id'],
                        filter: mergeFilters(
                          {
                            terms: [{ isResolved: true }],
                          },
                          baseFilter
                        ),
                      },
                    },
                    {
                      label: t('common.open', 'Open'),
                      request: {
                        collection: 'evseissues',
                        fields: ['id'],
                        filter: mergeFilters(
                          {
                            terms: [{ isResolved: false }],
                          },
                          baseFilter
                        ),
                      },
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
      <Divider hidden />
      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                filter={baseFilter}
                collection="evseissues"
                aggField="resolvedByUserName"
                operation="count"
                valueField="count"
                valueFieldName={t('common.issues', 'Issues')}
                title={t(
                  'analyticsMaintenance.topIssueResolvers',
                  'Top Issue Resolvers'
                )}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                filter={baseFilter}
                title={t('analyticsMaintenance.issueTypes', 'Issue Types')}
                collection="evseissues"
                aggField="type"
                operation="count"
                valueField="count"
                valueFieldName={t('common.issues', 'Issues')}
                labelFormatter={(item) => `${item}`}
                termsSize={15}
                style={{ height: '100%' }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      <Divider hidden />
    </ErrorBoundary>
  );
}
