import React, { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import DataList from 'components/DataList';
import { request } from 'utils/api';
import Table from './Table';
import { Container, Header, Grid, Divider, Button } from 'semantic';
import {
  currentUserCanAccessProviderEndpoint,
  currentUserCanAccessSystemEndpoint,
} from 'utils/roles';
import EditExchangeRate from 'components/modals/EditExchangeRate';
import { useTranslation } from 'react-i18next';
import useMenuRoutes from 'hooks/useMenuRoutes';
import { Breadcrumbs, ErrorMessage } from 'components';

const itemLimit = 100;

export default function ExchangeRates({ routesContext = 'legacy' }) {
  const { t } = useTranslation();
  const { financeExchangeRatesRoutes } = useMenuRoutes(routesContext);
  const { prefixUiRoute, apiRoute } = financeExchangeRatesRoutes;

  // TODO: design a better way to handle permission endpoints
  // Note: the legacy writeAccess check will dissapear after the system menu is fully released
  const writeAccess =
    routesContext === 'legacy'
      ? currentUserCanAccessProviderEndpoint('exchangeRates', 'write')
      : currentUserCanAccessSystemEndpoint(
          'system/finance/exchangeRates',
          'write'
        );

  const [dataKey, setDataKey] = useState(Date.now());
  const [error, setError] = useState(null);
  const [formMeta, setFormMeta] = useState({
    countryCodes: [],
    currencies: [],
    countryCodeMap: {},
  });

  const refresh = useCallback(() => {
    setError(null);
    setDataKey(Date.now());
  });

  const handleDeleteItem = useCallback(async (item) => {
    try {
      await request({
        method: 'DELETE',
        path: `${apiRoute}/${item._id}`,
      });
      refresh();
    } catch (err) {
      setError(err);
    }
  });

  const handleRequest = useCallback(async () => {
    const resp = await request({
      method: 'GET',
      path: `${apiRoute}`,
    });
    return {
      // sort in order to display the active exchange rates first
      data: resp.data.sort((i, j) => {
        if (i.status === 'active') return -1;
        if (i.status === 'upcoming' && j.status === 'past') return -1;

        return 1;
      }),
    };
  });

  useEffect(() => {
    setError(null);
    const getMetadata = async () => {
      const resp = await request({
        method: 'GET',
        path: `${apiRoute}/countries-currencies`,
      });
      const { countryCodes, currencies, countryCodeMap } = resp.data;
      setFormMeta({ countryCodes, currencies, countryCodeMap });
    };
    getMetadata();
  }, []);

  return (
    <AppWrapper>
      <Container style={{ position: 'relative' }}>
        <Breadcrumbs
          path={[
            <Link key="finance" to={prefixUiRoute}>
              {t('finance.title', 'Finance')}
            </Link>,
          ]}
          active={t('exchangeRates.title', 'Exchange Rates')}
        />
        <Grid reversed="computer">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={10}
            textAlign="right"
            verticalAlign="middle">
            <EditExchangeRate
              formMeta={formMeta}
              apiRoute={apiRoute}
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '1px' }}
                  content="Add exchange rate"
                  icon="plus"
                  disabled={!writeAccess}
                />
              }
              onClose={refresh}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('exchangeRates.title', 'Exchange Rates')}
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        {error && <ErrorMessage error={error} />}
        <DataList key={dataKey} limit={itemLimit} onRequest={handleRequest}>
          <Table
            formMeta={formMeta}
            onDeleteItem={handleDeleteItem}
            onRefresh={refresh}
            writeAccess={writeAccess}
          />
        </DataList>
        <Divider hidden />
      </Container>
    </AppWrapper>
  );
}
