import Modal from './Modal';
import Number from './Number';
//import Search from './Search';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import DateRange from './DateRange';
import NumberRange from '../../search_custom/Filters/NumberRange';
import Overview from '../../search_custom/Filters/Overview';
import Input from '../../search_custom/Filters/Input';
import Search from '../../search_custom/Filters/Search';

export default {
  Modal,
  Number,
  Search,
  Dropdown,
  Checkbox,
  DateRange,
  NumberRange,
  Overview,
  Input,
};
