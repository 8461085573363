import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';

import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import { request } from 'utils/api';

class EditExternalProvider extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    const { formValues } = this.state;

    try {
      if (this.props.initialValues?.id) {
        await request({
          method: 'PATCH',
          path: `/1/external-providers/${formValues.id}`,
          body: formValues,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/external-providers',
          body: this.state.formValues,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      submitted: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { initialValues, t } = this.props;
    const { formValues = {}, error } = this.state;

    const isUpdate = !!initialValues.id;

    return (
      <>
        <Modal.Header>
          {isUpdate
            ? t(
                'editExternalProvider.titleEdit',
                'Edit ExternalProvider {{name}}',
                {
                  name: `${initialValues.name} ${initialValues.customId}`,
                }
              )
            : t('editExternalProvider.titleNew', 'New ExternalProvider')}
        </Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)} onSubmit={() => this.onSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Input
              value={formValues.customId}
              name="customId"
              label={t('editExternalProvider.customID', 'Custom ID')}
              type="text"
              required
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
            <Form.Input
              value={formValues.name}
              name="name"
              label={t('editExternalProvider.name', 'Name')}
              type="text"
              required
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={this.state.loading}
            primary
            content={isUpdate ? t('button.update') : t('button.create')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditExternalProvider));
