import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';

const NavLine = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  .line {
    height: 3px;
    width: 100%;
    background: ${(props) => props.theme.primaryColor};
    position: absolute;
    bottom: 9px;
    z-index: -1;
  }

  .items {
    display: flex;
    justify-content: space-between;
  }

  .item {
    font-weight: bold;
    text-align: center;
    &.active {
      color: ${(props) => props.theme.primaryColor};
      cursor: pointer;
      .dot {
        background: ${(props) => props.theme.primaryColor};
      }
      .text {
        color: ${(props) => props.theme.primaryColor};
      }
    }
  }

  .dot {
    border-radius: 20px;
    border: 3px ${(props) => props.theme.primaryColor} solid;
    background: #fff;
    height: 20px;
    width: 20px;
  }

  .text {
    position: absolute;
    top: -25px;
    width: 130px;
    margin-left: -50px;
    text-align: center;
  }
`;

export default ({ base }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const step = React.useMemo(() => {
    if (location.pathname === `/${base}/signup`) return 1;
    if (location.pathname === `/${base}/signup/billing`) return 2;
    if (location.pathname === `/${base}/signup/finalize`) return 3;
  }, [location]);

  return (
    <NavLine>
      <div className="line"></div>
      <div className="items">
        <div
          onClick={() => step > 1 && history.replace(`/${base}/signup`)}
          className={`item ${step >= 1 ? 'active' : ''}`}>
          <div className="dot"></div>
          <div className="text">{t('navLine.step2', 'Create Account')}</div>
        </div>
        <div
          onClick={() => step > 2 && history.replace(`/${base}/signup/billing`)}
          className={`item ${step >= 2 ? 'active' : ''}`}>
          <div className="dot"></div>
          <div className="text">{t('navLine.step3', 'Billing Address')}</div>
        </div>
        <div className={`item ${step >= 3 ? 'active' : ''}`}>
          <div className="dot"></div>
          <div className="text last">{t('navLine.step4', 'Finalize')}</div>
        </div>
      </div>
    </NavLine>
  );
};
