import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';
import Summary from './Summary';
import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function Invoices({
  prefixPath = '/finance',
  invoicesPath = '/finance/invoices',
  detailPath = '/finance/invoices/:id',
  summaryPath = '/finance/invoices/:id/summary',
}) {
  const analysePath = `${detailPath}/analyze-sessions`;

  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path={summaryPath} exact component={Summary} />
          <Route
            path={detailPath}
            component={() => (
              <Detail
                prefixPath={prefixPath}
                analysePath={analysePath}
                overviewPath={detailPath}
              />
            )}
          />
          <Route
            path={invoicesPath}
            render={(props) => {
              return <List {...props} prefixPath={prefixPath} />;
            }}
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
