import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';

import { Loader, Segment, Dimmer, Message, Button } from 'semantic';

import { useTranslation } from 'react-i18next';
import CreditSessionsTable from './CreditSessionsTable';
import CreditSessionsModal from './CreditSessionsModal';
import ExportButton from 'components/ExportButton';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default function SessionDataTable({
  status,
  limit,
  data: items,
  meta = {},
  page,
  setPage,
  onCreditSessionDelete,
  session,
}) {
  const { t } = useTranslation();

  return (
    <div className="list">
      {status.success && !items.length && (
        <>
          <Message>
            {t('creditSessions.noSessionsYet', 'No credit sessions yet')}
          </Message>
          {session && (
            <CreditSessionsModal
              session={session}
              onClose={() => setPage(1)}
              trigger={
                <Button
                  basic
                  content="Create Credit Sessions"
                  icon="credit-card"
                />
              }
            />
          )}
        </>
      )}

      {status.success && items.length > 0 && (
        <CreditSessionsTable
          creditSessions={items}
          onCreditSessionDelete={onCreditSessionDelete}
        />
      )}
      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}

      {status.success && meta.total > 0 && (
        <ExportButton
          content="Export All"
          path={'/1/credit-sessions/search'}
          filename="credit-sessions"
        />
      )}
    </div>
  );
}
