import { useMemo } from 'react';

/**
 * @param {Object} params
 * @param {number} params.numConnectors
 * @param {{
 *   [_id]: string,
 *   [connectorId]: number,
 *   [evseId]: string,
 *   [id]: string
 * }[]} params.connectors
 * @param {{[enableEnergyMarketTracking]: boolean,
 *   [pricePerSession]: number,
 *   [pricePerHour]: number,
 *   [pricePerKwh]: number,
 *   [_id]: string,
 *   [connectorId]: number,
 *   [surchargePerKwh]: number,
 *   [currency]: string,
 *   [id]: string}[]} params.value
 * @returns {{[enableEnergyMarketTracking]: boolean,
 *   [pricePerSession]: number,
 *   [pricePerHour]: number,
 *   [pricePerKwh]: number,
 *   [_id]: string,
 *   [connectorId]: number,
 *   [surchargePerKwh]: number,
 *   [currency]: string,
 *   [connectorId]: number,
 *   [id]: string}[]}
 */
export function useValueCostSettings({ connectors, numConnectors, value }) {
  return useMemo(() => {
    const valueCostSettings = value || [];
    const costSettings = [];

    for (let i = 0; (numConnectors || 1) > i; i++) {
      let item = valueCostSettings[i];
      if (!item) {
        item = {
          connectorId: (i + 1).toString(),
          pricePerKwh: 0,
          pricePerSession: 0,
          pricePerHour: 0,
        };
      }
      if (connectors && connectors[i]) {
        item.connectorId = connectors[i].connectorId;
      }
      costSettings.push(item);
    }
    return costSettings;
  }, [connectors, numConnectors, value]);
}
