import React from 'react';

import { Button, Icon } from 'semantic';

type Props = {
  buttonStyle?: React.CSSProperties;
  selected: boolean;
  onClick?: (selected: boolean) => void;
  children: React.ReactNode;
  disabled?: boolean;
  inactive?: boolean;
};

export function SelectableButton({
  buttonStyle,
  children,
  selected,
  onClick,
  disabled,
  inactive,
}: Props) {
  const selectionIcon = selected ? 'circle-check regular' : 'circle regular';

  return (
    <Button
      disabled={disabled}
      basic
      color={selected && !inactive ? 'blue' : null}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'left',
        paddingTop: 20,
        paddingBottom: 20,
        ...buttonStyle,
      }}
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        onClick && onClick(selected);
      }}>
      <div>{children}</div>
      {!inactive && (
        <div>
          <Icon size="large" name={selectionIcon} />
        </div>
      )}
    </Button>
  );
}
