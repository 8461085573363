import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic';
import { accessGroupRFIDImportMapping } from 'utils/constants';
import { useUser } from 'contexts/user';

const accessGroupExportMapping = Object.keys(accessGroupRFIDImportMapping).map(
  (key) => ({
    key,
    header: accessGroupRFIDImportMapping[key].exactMatches[0],
  })
);

export function ExportAccessGroupMembers({
  floated = undefined,
  style = {},
  accessGroup = { members: [] },
}) {
  const { t } = useTranslation();
  const members = accessGroup?.members || [];
  const { hasPlatformFeature } = useUser();

  const exportCsv = () => {
    const csv = [
      accessGroupExportMapping.map((mapping) => mapping.header).join(','),
      ...members.map((member) => {
        const row = accessGroupExportMapping.map(
          (mapping) => member[mapping.key] || ''
        );
        return row.join(',');
      }),
    ].join('\n');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `access-group-members.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      floated={floated}
      style={style}
      onClick={() => exportCsv()}
      primary
      content={t('myAccessGroup.exportMembers', 'Export Members')}
      icon="download"
    />
  );
}
