import React from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Switch, Route } from 'react-router-dom';
import { Header, Divider, Grid } from 'semantic';
import Summary from './Summary';
import Search from './Search';
import Menu from './Menu';
import Breadcrumbs from 'components/Breadcrumbs';
import Analytics from './Analytics/Analytics';
import RemoteTokenSearchPanel from './RemoteTokenSearchPanel';
import { currentUserIsSuperAdmin } from 'utils/roles';
import { OCPI } from 'components/RemoteTokenSearch';

export default () => {
  return (
    <AppWrapper>
      <Breadcrumbs
        path={[
          <Link key="charging-stations" to="/charging-stations">
            Charging Stations
          </Link>,
        ]}
        active="External Tokens"
      />
      <Grid reversed="computer">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            External tokens
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <Menu />
      <Divider hidden />
      <Switch>
        <Route
          exact
          path="/charging-stations/tokens/search"
          component={(props) => <Search {...props} />}
        />
        <Route
          exact
          path="/charging-stations/tokens"
          component={(props) => <Summary {...props} />}
        />
        <Route
          exact
          path="/charging-stations/tokens/analytics"
          component={(props) => <Analytics {...props} />}
        />
        {currentUserIsSuperAdmin() && (
          <Route
            exact
            path="/charging-stations/tokens/remote-search"
            component={(props) => (
              <RemoteTokenSearchPanel {...props} allowedProtocols={[OCPI]} />
            )}
          />
        )}
      </Switch>
    </AppWrapper>
  );
};
