import { InviteEmployeeFormValues } from './formData';
import { useAccount } from 'contexts/account';
import { submitBillingInfo } from 'components/BillingInfo/api';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { request } from 'utils/api';
import { InviteEmployeeError } from './errors';

export function useSubmitInviteEmployee(
  showAccountBilling: boolean,
  options: Omit<
    UseMutationOptions<unknown, InviteEmployeeError, InviteEmployeeFormValues>,
    'mutationFn'
  >
) {
  const { account, isBillingUpdateRequired } = useAccount();
  return useMutation({
    mutationFn: async (values: InviteEmployeeFormValues) => {
      if (!account) {
        throw new Error('No account in context!');
      }

      if (isBillingUpdateRequired && showAccountBilling) {
        try {
          await submitBillingInfo(account, values.billing);
        } catch (e: any) {
          throw InviteEmployeeError.Billing(e);
        }
      }

      await submitInviteEmployee(values);
    },
    ...options,
  });
}

async function submitInviteEmployee(values: InviteEmployeeFormValues) {
  try {
    await request({
      method: 'POST',
      path: '/1/evse-invites',
      body: {
        ...values,
        ...(values.requestUserId === 'new'
          ? { requestUser: true, requestUserId: undefined }
          : {}),
      },
    });
  } catch (error: any) {
    throw InviteEmployeeError.Inviting(error);
  }
}
