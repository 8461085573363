import React, { useState } from 'react';
import { request } from 'utils/api';

import { Form, Message, Modal, Label, Input, Button } from 'semantic';
import { simpleOptions } from 'utils/form';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import { useTranslation } from 'react-i18next';
import { PlatformFeature } from '..';

import { useUser } from 'contexts/user';

const MAX_PRICE_PER_KWH = 2;

function getFormValues(member, accessGroup) {
  const type = accessGroup.type === 'rfid' ? 'rfid' : 'user';

  const formValues = {
    userId: member?.user?.id,
    priceType: 'free',
    type,
    ...member,
  };

  if (member.pricePerKwh) {
    formValues.pricePerKwh = member.pricePerKwh.toString();
  }

  return formValues;
}

export default function EditAccessGroupMember({
  member = {},
  accessGroup,
  trigger,
  refresh,
}) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(
    getFormValues(member, accessGroup)
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fromOwnAccount, setFromOwnAccount] = useState(true);
  const [error, setError] = useState(null);

  const { hasPlatformFeature } = useUser();

  const isUpdate = !!formValues.id;
  const priceTypeOptions = ['free'];

  if (hasPlatformFeature('access-groups:custom-pricing-users')) {
    priceTypeOptions.push('custom');
  }

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    const memberBody = {
      ...formValues,
      id: formValues.id,
      pricePerKwh: formValues.pricePerKwh,
      priceType: formValues.priceType,
      type: formValues.type,
      user: formValues.user,
      userId: formValues.userId?._id || formValues.userId,
    };

    if (memberBody.type === 'user' && !memberBody.userId && fromOwnAccount) {
      setError(new Error('Please select a user'));
      setLoading(false);
      return;
    }

    if (memberBody.priceType === 'free') {
      delete memberBody.pricePerKwh;
    }

    if (memberBody.priceType === 'custom') {
      if (
        memberBody.pricePerKwh &&
        memberBody.pricePerKwh?.match(/,/) &&
        memberBody.pricePerKwh?.match(/\./)
      ) {
        setError(
          new Error('Price decimal needs to be denominated by comma or dot')
        );
        setLoading(false);
        return;
      }
      if (memberBody.pricePerKwh?.match(/,/)) {
        memberBody.pricePerKwh = memberBody.pricePerKwh.replace(/,/g, '.');
      }
      if (memberBody.pricePerKwh > MAX_PRICE_PER_KWH) {
        setError(
          new Error(`The maximum tariff per kWh is €${MAX_PRICE_PER_KWH}`)
        );
        setLoading(false);
        return;
      }
      if (memberBody.pricePerKwh < 0 || !memberBody.pricePerKwh) {
        setError(new Error('Please enter a valid tariff per kWh'));
        setLoading(false);
        return;
      }
    }
    try {
      if (formValues.type === 'visualNumber') {
        const { data: visualNumberCheck } = await request({
          method: 'POST',
          path: `/1/external-tokens/check-exists`,
          body: { visualNumber: formValues.visualNumber },
        });

        if (!visualNumberCheck.exists) {
          throw new Error(
            `No roaming charge card found with visual number (printed number) ${formValues.visualNumber}`
          );
        }
      }

      await request({
        method: 'POST',
        path: `/1/access-groups/${accessGroup.id}/member`,
        body: memberBody,
      });
      setLoading(false);
      setOpen(false);
      refresh();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const setField = (name, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  return (
    <Modal
      closeIcon
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      centered
      closeOnDimmerClick={false}
      trigger={trigger}>
      <Modal.Header>
        {isUpdate
          ? t('myAccessGroup.editMember', 'Edit Member')
          : t('myAccessGroup.newMember', 'New Member')}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="EditAccessGroupMember-form"
          error={Boolean(error)}
          onSubmit={onSubmit}>
          {error && <Message error content={error.message} />}
          {accessGroup.type === 'rfid' && (
            <>
              {!isUpdate && (
                <Form.Select
                  value={formValues.type}
                  options={[
                    {
                      key: 'rfid',
                      value: 'rfid',
                      text: 'RFID (UID)',
                    },
                    {
                      key: 'visualNumber',
                      value: 'visualNumber',
                      text: 'Visual Number',
                    },
                  ]}
                  name="type"
                  label={t('myAccessGroup.tokenType', 'Token Type')}
                  type="text"
                  required
                  onChange={(e, { name, value }) => {
                    // reset form values to avoid type specific fields being created
                    setFormValues({
                      ...getFormValues(member, accessGroup),
                      [name]: value,
                    });
                  }}
                />
              )}

              {formValues.type === 'rfid' && (
                <Form.Input
                  value={formValues.uid || ''}
                  name="uid"
                  label={t('myAccessGroup.tokenUid', 'Token UID')}
                  type="text"
                  disabled={isUpdate}
                  required
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              )}
              <Form.Input
                value={formValues.name || ''}
                name="name"
                label={t('myAccessGroup.name', 'Name')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
              />

              {formValues.type === 'visualNumber' && (
                <Form.Input
                  value={formValues.visualNumber || ''}
                  name="visualNumber"
                  label={t('myAccessGroup.visualNumber', 'Visual Number')}
                  type="text"
                  required
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              )}
            </>
          )}

          {accessGroup.type === 'users' && (
            <>
              {!isUpdate && (
                <PlatformFeature feature="access-groups:external-members">
                  <Form.Group style={{ marginTop: '5px' }}>
                    <Form.Radio
                      label={t('myAccessGroup.user', 'User')}
                      value=""
                      checked={fromOwnAccount}
                      onChange={() => setFromOwnAccount(true)}
                    />

                    <Form.Radio
                      label={t('myAccessGroup.externalUser', 'External User')}
                      value=""
                      checked={!fromOwnAccount}
                      onChange={() => setFromOwnAccount(false)}
                    />
                  </Form.Group>
                </PlatformFeature>
              )}

              {fromOwnAccount ? (
                <Form.Field>
                  {isUpdate ? (
                    <Form.Input
                      label={t('myAccessGroup.user', 'User')}
                      value={member.user?.name}
                      disabled={true}
                      readOnly
                    />
                  ) : (
                    <SearchDropDown
                      label={t('myAccessGroup.user', 'User')}
                      keywordField="searchPhrase"
                      required
                      placeholder="Search for a user"
                      value={formValues?.userId}
                      search
                      getOption={(user) => {
                        const isMember = accessGroup.members.some(
                          (c) => c.user.id == user.id
                        );
                        return {
                          key: user.id,
                          text: [
                            user.name,
                            isMember &&
                              t(
                                'myAccessGroup.alreadyAMember',
                                'Already a member'
                              ),
                          ]
                            .filter(Boolean)
                            .join(' - '),
                          value: user.id,
                          disabled: accessGroup.members.some(
                            (c) => c.user.id == user.id
                          ),
                        };
                      }}
                      objectMode={false}
                      onDataNeeded={(body) => {
                        return request({
                          path: '/1/users/search',
                          method: 'POST',
                          body: {
                            ...body,
                            accountId: accessGroup.accountId,
                          },
                        });
                      }}
                      getOptionLabel={(user) => user.name}
                      onChange={(e, { value }) => setField('userId', value)}
                    />
                  )}
                </Form.Field>
              ) : (
                <Form.Input
                  value={formValues.email || ''}
                  name="email"
                  label={'User by email'}
                  type="text"
                  required
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              )}

              <Form.Select
                value={formValues.priceType || 'free'}
                options={simpleOptions(priceTypeOptions)}
                name="priceType"
                label={t('myAccessGroup.priceType', 'Price Type')}
                type="text"
                required
                onChange={(e, { name, value }) => setField(name, value)}
              />
              {formValues.priceType === 'custom' && (
                <Form.Field>
                  <label>
                    {t('myAccessGroup.pricePerKwh', 'Price per kWh')}
                  </label>
                  <Input
                    style={{ marginBottom: '4px' }}
                    labelPosition="right"
                    fluid
                    name="pricePerKwh"
                    type="text"
                    value={formValues.pricePerKwh || ''}
                    onChange={(e, { name, value }) => setField(name, value)}>
                    <Label basic>&euro;</Label>
                    <input />
                  </Input>
                </Form.Field>
              )}
            </>
          )}
          {error && <Message error content={error.message} />}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={t('myAccessGroup.save', 'Save')}
          form="EditAccessGroupMember-form"
        />
      </Modal.Actions>
    </Modal>
  );
}
