import { useBillingInfoFormikValidationSchema } from 'components/BillingInfo/formik-validation';
import { BillingInfo } from 'components/BillingInfo/types';
import { useAccount } from 'contexts/account';
import { useMemo } from 'react';
import * as Yup from 'yup';

export type AccountBillingValues = {
  billing: BillingInfo;
};

export type AccountBillingInitialState = {
  formData: AccountBillingValues;
  showBillingInfo: boolean;
  accountType: 'individual' | 'organization';
};

export function useAccountBillingInitialState(): AccountBillingInitialState {
  const { account, isBillingUpdateRequired } = useAccount();
  const formData = useMemo<AccountBillingValues>(() => {
    return {
      billing: {
        accountHolderName: account?.billing?.accountHolderName || '',
        city: account?.billing?.city || '',
        countryCode: account?.billing?.countryCode?.toLowerCase() || '',
        ibanNumber: account?.billing?.ibanNo || '',
        bicNumber: account?.billing?.bicNo || '',
        number: account?.billing?.addressLine2 || '',
        paymentMethod: account?.billing?.paymentMethod || 'manual',
        postalCode: account?.billing?.postalCode || '',
        sepaAccepted: account?.billing?.sepaAccepted || false,
        street: account?.billing?.address || '',
        vatNumber: account?.vatNo || '',
        vatNotApplicable: account?.hasNoVatNo || false,
        chamberOfCommerceNumber: account?.chamberOfCommerceNo || '',
      },
    };
  }, [account]);

  return {
    formData,
    accountType: account?.type || 'individual',
    showBillingInfo: !!isBillingUpdateRequired,
  };
}

export function useAccountBillingValidationSchema(
  enableBicCollection: boolean
) {
  const { account } = useAccount();

  const billingInfoValidationSchema = useBillingInfoFormikValidationSchema(
    account?.type || 'individual',
    enableBicCollection
  );

  return Yup.object({
    billing: Yup.object(billingInfoValidationSchema),
  });
}
