import React, { useState } from 'react';
import DataList from 'components/DataList';
import Search from 'components/SearchInput';
import { request } from 'utils/api';
import Filters from './Filters';
import Table from './Table';
import { Segment, Grid, Divider } from 'semantic';

const itemLimit = 100;

export default function Locations({ locationsPath = '/cards/locations' }) {
  const [dateKey, setDateKey] = useState(Date.now());
  const [searchId, setSearchId] = useState('');
  const [filters, setFilters] = useState({});

  const handleDeleteItem = async (item) => {
    await request({
      method: 'DELETE',
      path: `/2/locations/msp/${item.id}`,
    });
    return refresh();
  };

  const handleRequest = (paramFilters = {}) => {
    return request({
      method: 'POST',
      path: `/2/locations/msp/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        ...paramFilters,
        ...(searchId ? { searchId, searchPhrase: searchId } : {}),
        ...filters,
        limit: itemLimit,
      },
    });
  };

  const handleFilterChange = (filters) => {
    setFilters(
      filters.reduce((result, item) => {
        return {
          ...result,
          ...(item.apiFilter || {}),
        };
      }, {})
    );
  };

  const handleOnSearch = (search) => {
    setSearchId(search);
  };

  const refresh = () => {
    setDateKey(Date.now());
  };

  return (
    <div>
      <Segment>
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column width={10}>
              <Filters onChange={handleFilterChange}></Filters>
            </Grid.Column>
            <Grid.Column width={6}>
              <Search
                placeholder="Search by ID, Name, etc."
                onChange={handleOnSearch}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <DataList key={dateKey} limit={itemLimit} onRequest={handleRequest}>
        <Table
          locationsPath={locationsPath}
          onDeleteItem={handleDeleteItem}
          onRefresh={() => refresh()}
        />
      </DataList>
      <Divider hidden />
    </div>
  );
}
