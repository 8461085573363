import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AccountProvider } from 'contexts/account';
import PasswordAndAuthenticationLayout from './PasswordAndAuthentication.layout';

export default function PasswordAndAuthentication() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);
  //TODO: Add error boundary layer

  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider>
        <PasswordAndAuthenticationLayout />
      </AccountProvider>
    </QueryClientProvider>
  );
}
