export const CPO_SESSION_EXCLUDED_REASON = {
  HISTORICAL_END: 'HISTORICAL_END',
  MAINTENANCE_TOKEN: 'MAINTENANCE_TOKEN',
  HIGH_ENERGY_USAGE: 'HIGH_ENERGY_USAGE',
  LOW_ENERGY_USAGE: 'LOW_ENERGY_USAGE',
  LOW_DURATION: 'LOW_DURATION',
  IMPROBABLE_ENERGY_USAGE: 'IMPROBABLE_ENERGY_USAGE',
  NO_COST: 'NO_COST',
  EXTERNAL_PAYMENT_SOLUTION: 'EXTERNAL_PAYMENT_SOLUTION',
  NON_ACCEPTED_AUTH: 'NON_ACCEPTED_AUTH',
  MANUAL_EXCLUSION: 'MANUAL_EXCLUSION',
  MANUAL_CANCELLATION: 'MANUAL_CANCELLATION',
  INVALID_START_TIME: 'INVALID_START_TIME',
  INVALID_END_TIME: 'INVALID_END_TIME',
  UNKNOWN_METER_VALUES: 'UNKNOWN_METER_VALUES',
  TEMPORARILY_EXCLUDED_WAITING_FOR_MISSING_DATA:
    'TEMPORARILY_EXCLUDED_WAITING_FOR_MISSING_DATA',
  NO_AUTO_REIMBURSEMENT: 'NO_AUTO_REIMBURSEMENT',
} as const;
export type CPO_SESSION_EXCLUDED_REASON =
  (typeof CPO_SESSION_EXCLUDED_REASON)[keyof typeof CPO_SESSION_EXCLUDED_REASON];

export const MSP_SESSION_EXCLUDED_REASON = {
  HIGH_ENERGY_USAGE: 'HIGH_ENERGY_USAGE', // Too much Kwh for the duration
  LOW_ENERGY_USAGE: 'LOW_ENERGY_USAGE', // Too little Kwh for the duration
  LOW_DURATION: 'LOW_DURATION', // Too little duration for the Kwh
  IMPROBABLE_ENERGY_USAGE: 'IMPROBABLE_ENERGY_USAGE', // Too much Kwh per minute
  NO_COST: 'NO_COST', // Too little cost
  HIGH_COST: 'HIGH_COST', // Too much cost
  TOO_OLD: 'TOO_OLD', // Too old
  PSP_PAYMENT: 'PSP_PAYMENT', // Paid with PSP
  NON_EXISTENT_TOKEN: 'NON_EXISTENT_TOKEN', // The session was started with a non-existent token and cannot be billed
  NON_ACTIVE_TOKEN: 'NON_ACTIVE_TOKEN', // The session was started with a non-active token and cannot be billed
  NO_SESSION: 'NO_SESSION', // The session has no cost and no energy usage
  MANUAL_EXCLUSION: 'MANUAL_EXCLUSION', // The session has been explicitly manually excluded.
  DUPLICATE: 'DUPLICATE', // The session is a duplicate
} as const;
export type MSP_SESSION_EXCLUDED_REASON =
  (typeof MSP_SESSION_EXCLUDED_REASON)[keyof typeof MSP_SESSION_EXCLUDED_REASON];

export const MANUAL_EXCLUSION_REASON = {
  DUPLICATE: 'DUPLICATE',
  OTHER: 'OTHER',
};
export type MANUAL_EXCLUSION_REASON =
  (typeof MANUAL_EXCLUSION_REASON)[keyof typeof MANUAL_EXCLUSION_REASON];
