import React, { useContext } from 'react';
import Pagination from 'components/Pagination';

import SearchContext from './Context';

export default function SearchPagination() {
  const searchContext = useContext(SearchContext);

  const onPageChange = (evt, props) => {
    window.scrollTo(0, 0);
    searchContext.onPageChange(evt, props);
  };

  const { loading, error, page, meta } = searchContext;
  if (loading || error || !meta || meta.total <= meta.limit) {
    return null;
  }

  return (
    <div
      style={{
        textAlign: 'center',
      }}>
      <Pagination
        page={page}
        limit={meta.limit}
        total={meta.total}
        boundaryRange={1}
        onPageChange={onPageChange}
      />
    </div>
  );
}
