import React, { useContext } from 'react';
import { Modal } from 'semantic';
import { UserContext } from 'contexts/user';
import SessionDetails from '../SessionDetails';
import AsyncModal from 'helpers/async-modal';

function ViewSession({ data }) {
  const context = useContext(UserContext);

  return (
    <>
      <Modal.Header>View Session {data.id}</Modal.Header>
      <Modal.Content>
        <SessionDetails
          context={data?.providerContext}
          session={data}
          user={context.user}
        />
      </Modal.Content>
      <Modal.Actions />
    </>
  );
}

export default AsyncModal(ViewSession);
