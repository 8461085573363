import React from 'react';
import { request } from 'utils/api';
import { SearchProvider, AppWrapper, Confirm } from 'components';
import { Container, Header, Table, Divider, Message, Button } from 'semantic';
import StartRemoteSessionMsp from 'components/modals/StartRemoteSessionMsp';
import StartRemoteMspSessionPreauth from 'components/modals/StartRemoteSessionMspPreauth';
import { HelpTip } from 'components';
import { formatDateTime } from 'utils/date';
import { withTranslation } from 'react-i18next';
import { useFeatures } from 'contexts/features';

const MyRemoteSessions = ({ t }) => {
  const { hasFeature } = useFeatures();

  const pspIntegrationEnabled = hasFeature('psp_integrations');

  const fetchData = async (body) => {
    let result;
    if (pspIntegrationEnabled) {
      result = await request({
        method: 'GET',
        path: '/1/remote-sessions/msp/mine',
      });
    } else {
      result = await request({
        method: 'POST',
        path: '/1/remote-sessions/msp/search',
        body,
      });
    }

    request({
      method: 'POST',
      path: `/1/remote-sessions/msp/status`,
      body: {},
    }); // fire and forget (For local dev to call the remote session status manager job)

    return result;
  };

  return (
    <AppWrapper>
      <Container style={{ position: 'relative' }}>
        <SearchProvider limit={100} onDataNeeded={fetchData} live>
          {({ items, reload }) => (
            <>
              <Header as="h2">
                {t('myRemoteSessions.title', 'My Remote Sessions')}ᵇᵉᵗᵃ
                {pspIntegrationEnabled ? (
                  <StartRemoteMspSessionPreauth
                    onClose={() => reload()}
                    onSubmit={() => reload()}
                    trigger={
                      <Button
                        primary
                        floated="right"
                        style={{ marginTop: '1px' }}
                        content={t(
                          'startRemoteSessionModal.header',
                          'Start Remote Session'
                        )}
                        icon="charging-station"
                      />
                    }
                  />
                ) : (
                  <StartRemoteSessionMsp
                    onClose={() => reload()}
                    onSubmit={() => reload()}
                    trigger={
                      <Button
                        primary
                        floated="right"
                        style={{ marginTop: '1px' }}
                        content={t(
                          'startRemoteSessionModal.header',
                          'Start Remote Session'
                        )}
                        icon="charging-station"
                      />
                    }
                  />
                )}
              </Header>
              <Divider hidden />
              {items.length === 0 ? (
                <Message content="No remote sessions created yet" />
              ) : (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>
                        {t('activeSessionsTable.columnStatus', 'Status')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('myUsage.colummStartTime', 'Start Time')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('activeSessionsTable.columnLocation', 'Location')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('myUsage.columnActions', 'Actions')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => (
                      <Table.Row key={item.id}>
                        <Table.Cell>
                          {item.status}{' '}
                          {item.error && (
                            <HelpTip
                              text={item.error.message}
                              title="Remote Session Error"
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {formatDateTime(item.createdAt)}
                        </Table.Cell>
                        <Table.Cell>{item?.location?.name}</Table.Cell>
                        <Table.Cell textAlign="center">
                          {item.status === 'ACTIVE' && (
                            <Confirm
                              header={t(
                                'myRemoteSessions.confirmStopSession',
                                'Are you sure you want to stop this session?'
                              )}
                              trigger={
                                <Button
                                  primary
                                  content="End Session"
                                  icon="stop"
                                />
                              }
                              button={t(
                                'myRemoteSessions.stopSessionButton',
                                'End Session'
                              )}
                              onConfirm={async () => {
                                await request({
                                  method: 'POST',
                                  path: `/1/remote-sessions/msp/${item.id}/stop`,
                                  body: {},
                                });
                                reload();
                              }}
                            />
                          )}
                          {['PENDING', 'STARTING'].includes(item.status) && (
                            <Confirm
                              header={t(
                                'myRemoteSessions.confirmCancelSession',
                                'Are you sure you want to cancel this session?'
                              )}
                              content={t(
                                'myRemoteSessions.confirmItCanTakeTime',
                                'It can take up to a minute for a session to start.'
                              )}
                              trigger={
                                <Button
                                  basic
                                  content="Cancel Session"
                                  icon="ban"
                                />
                              }
                              button={t(
                                'myRemoteSessions.cancelSessionButton',
                                'Cancel Session'
                              )}
                              onConfirm={async () => {
                                await request({
                                  method: 'POST',
                                  path: `/1/remote-sessions/msp/${item.id}/cancel`,
                                  body: {},
                                });
                                reload();
                              }}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </>
          )}
        </SearchProvider>
      </Container>
    </AppWrapper>
  );
};

export default withTranslation()(MyRemoteSessions);
