import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Message, Loader, Dropdown } from 'semantic';
import Menu from './Menu';
import PageLoader from 'components/PageLoader';

import Overview from './Overview';
import Logs from 'screens/OcpiCredentials/Detail/Logs';
import Examples from 'screens/OcpiCredentials/Detail/Examples';
import { request } from 'utils/api';
import Breadcrumbs from 'components/Breadcrumbs';

import InspectObject from 'components/modals/InspectObject';
import { withTranslation } from 'react-i18next';

class OcpiCredential extends React.Component {
  state = {
    itemId: this.props.match.params.id,
    item: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.fetch(this.state.itemId);
  }

  fetch = (itemId) => {
    return request({
      method: 'GET',
      path: `/1/ocpi-credentials/mine/${itemId}`,
    })
      .then(({ data }) => {
        this.setState({ item: data, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  refresh() {
    this.fetch(this.state.itemId);
  }

  render() {
    const { t } = this.props;
    const { item } = this.state;

    if (!item) return <Loader actcive />;
    return (
      <AppWrapper>
        <Container>
          {
            <Dropdown
              button
              primary
              text={t('location.actionsBtn', 'Actions')}
              style={{ float: 'right' }}>
              <Dropdown.Menu>
                <InspectObject
                  name="OCPI Credential"
                  data={item}
                  trigger={
                    <Dropdown.Item icon="file-code" content="Inspect Object" />
                  }
                />
              </Dropdown.Menu>
            </Dropdown>
          }

          <Breadcrumbs
            path={[<Link to="/my-ocpi">OCPI Connections</Link>]}
            active={
              item
                ? `${item.name} (as a ${
                    item.providerContext === 'cpo' ? 'MSP' : 'CPO'
                  })`
                : 'Loading...'
            }
          />
        </Container>

        <Divider hidden />

        {item && item.deletedAt && (
          <Message error content="This OCPI credential has been deleted" />
        )}

        <Menu itemId={this.state.itemId} />

        <Divider hidden />
        {!item && <PageLoader />}
        {item && (
          <Switch>
            <Route
              exact
              path="/my-ocpi/:id"
              item={item}
              component={(props) => (
                <Overview {...props} ocpiCredential={item} />
              )}
            />
            <Route
              exact
              path="/my-ocpi/:id/logs"
              component={(props) => (
                <Logs lockOcpiCredential {...props} ocpiCredential={item} />
              )}
            />
            <Route
              exact
              path="/my-ocpi/:id/examples"
              component={(props) => (
                <Examples {...props} ocpiCredential={item} />
              )}
            />
          </Switch>
        )}
      </AppWrapper>
    );
  }
}

export default withTranslation()(OcpiCredential);
