import React, { useState } from 'react';
import { request } from 'utils/api';
import * as Search from 'components/search';
import { Message } from 'semantic';
import Table from './Table';
import { withTranslation } from 'react-i18next';

const SyncStatus = ({ modelName, t, doc: document, ...options }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loadMap, setLoadMap] = useState({});
  const [doc, setDoc] = useState(document);
  const [key, setKey] = useState(Date.now());

  const onDataNeeded = () => {
    if (!doc) return [];
    if (!doc.externalSync) return [];

    const rows = [];
    for (const { externalRefs, name, states } of doc.externalSync) {
      if (options.externalSyncJobName && name !== options.externalSyncJobName) {
        continue; // Filter by externalSyncJobName if provided
      }
      for (const endpointState of states) {
        rows.push({
          name,
          endpoint: endpointState.endpoint,
          ...(options.showExternalRefs &&
            externalRefs && {
              externalRefs: options.externalRefFields.reduce((acc, field) => {
                return { ...acc, [field]: externalRefs?.[field] };
              }, {}),
            }),
          status: endpointState.status,
          lastSynced: endpointState.lastSynced,
          nextTryAfter: endpointState.nextTryAfter,
          lastMessage: endpointState.lastSyncLog?.message,
          lastMessageType: endpointState.lastSyncLog?.type || '',
        });
      }
    }

    return { meta: { count: rows?.length }, data: rows };
  };

  const handleSync = async (externalSyncName, endpoint = 'default') => {
    const key = externalSyncName + endpoint;
    const { id } = doc;
    setLoadMap((prevState) => ({ ...prevState, [key]: true }));

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/external-sync/request-sync',
        body: { id, modelName, externalSyncName, endpoint },
      });

      const externalSync = data[0]?.externalSync;
      if (externalSync) {
        setDoc((prevState) => ({ ...prevState, externalSync }));
        setKey(Date.now()); // force reload
      }
    } catch ({ message }) {
      setErrorMessage(message);
    } finally {
      setLoadMap((prevState) => ({ ...prevState, [key]: false }));
    }
  };

  return (
    <Search.Provider key={key} onDataNeeded={onDataNeeded}>
      {errorMessage && <Message error content={errorMessage} />}
      <Search.Status noResults={t('common.noResults', 'No Results')} />
      <Table
        showExternalRefs={options.showExternalRefs}
        externalRefFields={options.externalRefFields}
        handleSync={handleSync}
        loadMap={loadMap}
      />
    </Search.Provider>
  );
};

export default withTranslation()(SyncStatus);
