import React from 'react';

import { Segment, Header, Message, Icon, Divider, List, Form } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useSignup } from './Context';
import { useUser } from 'contexts/user';

import { request } from 'utils/api';
import { calculateVat } from 'utils/formatting';
import styled from 'styled-components';
import { INTERCOM_APP_ID } from 'utils/env';
import Currency from 'components/Currency';
import { FeatureFlag, PlatformModule } from 'components';

const SideBar = styled.div`
  .lines {
    padding: 0;
    margin-top: 20px;
    color: #52bf9b;
    font-weight: bold;
    list-style-type: none;
    font-size: 12px;
  }
  .order {
    padding: 0;
    list-style-type: none;
    font-weight: bold;
    font-size: 12px;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .big {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
`;

export default () => {
  const { t } = useTranslation();
  const { step, billingPlan, setBillingPlan, cardType, currency, setCurrency } =
    useSignup();
  //@ts-ignore
  const { provider } = useUser();
  const [error, setError] = React.useState<Error>();

  React.useEffect(() => {
    if (provider.slug === 'e-flux') {
      //@ts-ignore
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
    }
  }, [provider]);

  React.useEffect(() => {
    if (billingPlan?.id) {
      request({
        method: 'GET',
        path: `/1/billing-plans/${billingPlan?.id}`,
      })
        .then(({ data }) => setBillingPlan(data))
        .catch((e) => setError(e));
    }
  }, [billingPlan?.id]);

  const billingPlanPricesInCurrency = billingPlan?.prices?.find(
    (priceInCurrency) => priceInCurrency.currency === (currency || 'EUR')
  );

  const items = billingPlan
    ? [
        ...[
          {
            text: t('editBillingPlan.pricePerPeriod'),
            value: calculateVat(
              billingPlanPricesInCurrency?.perPeriod,
              provider.individualFees / 100
            ),
          },
          {
            text: t('editBillingPlan.pricePerSession'),
            value: calculateVat(
              billingPlanPricesInCurrency?.perSession,
              provider.individualFees / 100
            ),
          },
          {
            text: t('editBillingPlan.pricePerCard'),
            value: calculateVat(
              billingPlanPricesInCurrency?.perCard,
              provider.individualFees / 100
            ),
          },
        ]
          .filter((a) => a.value)
          .sort((b, a) => a.value - b.value),
      ]
    : [];

  return (
    <SideBar>
      {billingPlan?.id && (
        <Segment loading={!billingPlan}>
          <Header>{t('signupSidebar.myOrder', 'My Order')}</Header>
          <Divider />
          {error && <Message error content={error?.message} />}
          {billingPlan && (
            <ul className="order">
              <li className="big">
                <span className="text">{billingPlan.name}</span>
              </li>

              <li style={{ marginBottom: '7px' }}>
                <span className="text">
                  {t('signupSidebar.cardType', 'Type')}
                </span>

                <span className="amount">
                  {cardType === 'card'
                    ? t('signupSidebar.cardTypeCard', 'Card')
                    : t('signupSidebar.cardTypeTag', 'Tag')}
                </span>
              </li>

              {items.map((item) => {
                if (!item.text) {
                  return <li style={{ marginBottom: '7px' }}></li>;
                }

                return (
                  <li key={item.text}>
                    <span className="text">{item.text}</span>
                    <span className="amount">
                      <Currency currency={currency} value={item.value} />{' '}
                      {t('signupSidebar.inclVat', 'incl. {{percentage}}% VAT', {
                        percentage: provider.individualFees,
                      })}
                    </span>
                  </li>
                );
              })}
            </ul>
          )}

          <ul className="lines">
            <li>
              <Icon name="bolt" />
              {t(
                'signupSidebar.promoLine1',
                'Charge at 200.000+ charging stations'
              )}
            </li>
            <li>
              <Icon name="bolt" />
              {t(
                'signupSidebar.promoLine2',
                'Fair rates without additional costs'
              )}
            </li>
            <li>
              <Icon name="bolt" />
              {t('signupSidebar.promoLine3', '24/7 customer support')}
            </li>
            <li>
              <Icon name="bolt" />
              {t(
                'signupSidebar.promoLine4',
                'Easily add cards to your account'
              )}
            </li>
          </ul>
        </Segment>
      )}
      {step === 'plans' && (
        <FeatureFlag feature="multi_currency_self_serve">
          <PlatformModule moduleName={'multi-currency'}>
            <Segment>
              <p>{t('signupSidebar.currencyTitle', 'Billing currency:')}</p>
              <Form.Select
                value={currency}
                options={provider?.billingCurrencies.map((currency) => ({
                  key: currency,
                  value: currency,
                  text: currency,
                }))}
                name="currency"
                onChange={(e, { value }) => setCurrency(value as string)}
              />
            </Segment>
          </PlatformModule>
        </FeatureFlag>
      )}
      {provider.slug !== 'e-flux' && (
        <Segment>
          <Header>{t('signupSidebar.anyProblems', 'Need Help')}</Header>

          <List>
            <List.Item>{t('signupSidebar.supportPhoneNo')}</List.Item>
            <List.Item as="a" href={`tel:${provider.supportPhoneNo}`}>
              {provider.supportPhoneNo}
            </List.Item>
            <List.Item>{t('signupSidebar.supportEmail')}</List.Item>
            <List.Item as="a" href={`mailto:${provider.supportEmail}`}>
              {provider.supportEmail}
            </List.Item>
          </List>
        </Segment>
      )}
    </SideBar>
  );
};
