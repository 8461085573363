import React from 'react';
import { Form, Dropdown } from 'semantic';
import isoCountries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

const countryTranslations = {
  en: require('i18n-iso-countries/langs/en.json'),
  nl: require('i18n-iso-countries/langs/nl.json'),
  de: require('i18n-iso-countries/langs/de.json'),
  fr: require('i18n-iso-countries/langs/fr.json'),
  it: require('i18n-iso-countries/langs/it.json'),
  es: require('i18n-iso-countries/langs/es.json'),
};

for (const country of Object.values(countryTranslations)) {
  isoCountries.registerLocale(country);
}

function getCountryOptions(
  standard,
  language,
  lowerCase,
  countryNameLabels = true
) {
  let codes;
  if (standard === 'alpha-3') {
    codes = isoCountries.getAlpha3Codes();
  } else {
    codes = isoCountries.getAlpha2Codes();
  }
  return Object.keys(codes).map((code) => {
    const value = lowerCase ? code.toLowerCase() : code;
    const text = countryNameLabels
      ? isoCountries.getName(code, language)
      : value;
    return {
      value,
      key: value,
      text,
    };
  });
}

export default function Countries({
  required,
  label,
  placeholder = 'Country',
  name,
  disabled = false,
  onChange,
  whitelist = undefined,
  standard = 'alpha-2',
  language,
  lowerCase = true,
  countryNameLabels = true,
  readOnly = false,
  multiple = false,
  ...props
}) {
  if (!language) {
    const { i18n } = useTranslation();

    language = countryTranslations.hasOwnProperty(i18n.language)
      ? i18n.language
      : 'en';
  }

  const options = getCountryOptions(
    standard,
    language,
    lowerCase,
    countryNameLabels
  ).filter((x) => {
    if (!Array.isArray(whitelist)) {
      return true;
    }
    return whitelist.includes(x?.key);
  });

  if (readOnly) {
    const currentValue = options.find((c) => c.key == props.value)?.text;

    return (
      <Form.Input
        {...props}
        value={currentValue}
        name={name}
        label={label}
        type="text"
        readOnly={readOnly}
      />
    );
  }

  return (
    <Form.Field required={required} disabled={disabled}>
      {label && <label>{label}</label>}
      <Dropdown
        name={name}
        multiple={multiple}
        selection
        search
        placeholder={placeholder}
        options={options}
        {...props}
        onChange={(e, { value }) => onChange(value)}
      />
    </Form.Field>
  );
}
