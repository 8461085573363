import React, { useEffect } from 'react';
import beautify from 'xml-beautifier';
import { Modal, Segment, Header } from 'semantic';
import { useTranslation } from 'react-i18next';
import JSONBlock from '../JSONBlock';

export default function EditToken({ initialValues = {}, trigger }) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({ ...initialValues });
  const [submitted, setSubmitted] = React.useState(false);

  useEffect(() => {
    setSubmitted(false);
    setFormValues({ ...initialValues });
  });

  return (
    <Modal
      closeOnDimmerClick={false}
      closeIcon
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('viewSyncLog.header', 'View Log Entry {{name}}', {
          name: initialValues.id,
        })}
      </Modal.Header>
      <Modal.Content>
        <JSONBlock value={initialValues} />
        {initialValues.requestRawXml && (
          <>
            <Header as="h3">
              {t('viewSyncLog.requestRawXML', 'Request Raw XML')}
            </Header>
            <Segment inverted>
              <pre>{beautify(initialValues.requestRawXml)}</pre>
            </Segment>
          </>
        )}
        {initialValues.responseRawXml && (
          <>
            <Header as="h3">
              {t('viewSyncLog.responseRawXML', 'Response Raw XML')}
            </Header>
            <Segment inverted>
              <pre>{beautify(initialValues.responseRawXml)}</pre>
            </Segment>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}
