import React, { useContext } from 'react';
import { Search } from 'components';
import { Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormattedIssueSeverity } from 'utils/evse-issues';

export default function EvseIssuesTable() {
  const { items, loading } = useContext(Search.Context);
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>
            {t('maintenanceHome.columnEVSE', 'EVSE')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('maintenanceHome.columnSeverity', 'Severity')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('maintenanceHome.columnDescription', 'Description')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>
              {item.evseController && (
                <Link to={`/charging-stations/${item.evseController.id}`}>
                  {item.evseController.ocppIdentity}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <FormattedIssueSeverity evseIssue={item} />
            </Table.Cell>
            <Table.Cell>{item.description}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
