import React from 'react';

import {
  Container,
  Segment,
  Input,
  Button,
  Header,
  Message,
  Divider,
  Form,
} from 'semantic';

import AppWrapper from 'components/AppWrapper';
import Breadcrumbs from 'components/Breadcrumbs';

import { withTranslation } from 'react-i18next';

import { request } from 'utils/api';

import {
  labelPrintUrlForCards,
  labelPrintUrlForCard,
  getDeliveryCountryCode,
  sortCardsByCountry,
} from 'utils/cards';
import { Link } from 'react-router-dom';
import { uniqBy, debounce } from 'lodash-es';
import { captureError } from 'utils/sentry';
import TimeObjectInput from 'components/form-fields/TimeObject';
import DateObjectInput from 'components/form-fields/DateObject';

const CardAddress = ({ card }) => {
  return (
    <div>
      {labelPrintUrlForCard(card, true).map((line) => (
        <p style={{ margin: 0, fontSize: '20px' }} key={line}>
          {line}
        </p>
      ))}
    </div>
  );
};

class BatchAssignTokens extends React.Component {
  static defaultProps = {
    cards: [],
    started: false,
    currentCard: null,
    prevCard: null,
    inputValue: '',
  };

  state = {
    formValues: { ...this.props.initialValues },
    dateTo: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    },
    dateToTime: {
      hour: 15,
      minute: 30,
    },
    batchType: 'card',
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.debouncedConfirmTokenForCard = debounce(this.confirmTokenForCard, 400);
  }

  onCancel = () => {
    this.setState({
      cards: [],
      currentCard: null,
      started: false,
      batchCompleted: false,
    });
  };

  onStartBatch = async () => {
    this.setState({
      loading: true,
      error: null,
      batchCompleted: false,
    });

    let data;
    try {
      const response = await request({
        method: 'POST',
        path: '/1/cards/search',
        body: {
          status: 'pending',
          to: new Date(
            this.state.dateTo.year,
            this.state.dateTo.month - 1,
            this.state.dateTo.day,
            this.state.dateToTime.hour,
            this.state.dateToTime.minute
          ),
          type: this.state.batchType,
          limit: 50,
          sort: {
            field: 'createdAt',
            order: 'asc',
          },
        },
      });
      data = response.data;
      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
      return;
    }

    const cards = sortCardsByCountry(data);

    this.setState({
      cards,
      currentCard: cards[0],
      cardIndex: 0,
      started: true,
      batchCompleted: cards.length === 0,
    });
    setTimeout(() => {
      this.inputRef.current?.focus();
    }, 100);
  };

  confirmTokenForCard = async (token) => {
    if (token.length < 8) return;

    this.setState({
      loading: true,
      error: null,
    });

    try {
      const { data } = await request({
        path: '/1/tokens/lookup',
        method: 'POST',
        body: {
          uid: token,
        },
      });

      await request({
        method: 'POST',
        path: `/1/cards/assign-token`,
        body: {
          cardId: this.state.currentCard.id,
          tokenId: data.id,
        },
      });

      const { data: prevCard } = await request({
        method: 'GET',
        path: `/1/cards/${this.state.currentCard.id}`,
      });

      this.setState({
        prevCard,
      });
    } catch (e) {
      console.log("Couldn't assign token", token);
      console.error(e);

      this.setState({
        error: e,
        loading: false,
      });

      captureError(e, {
        uid7: token.slice(0, 7),
        uidLength: token.length,
        cardId: this.state.currentCard.id,
      });
      return;
    }

    this.nextCard();
  };

  nextCard = () => {
    const cardIndex = this.state.cardIndex + 1;
    if (!this.state.cards[cardIndex]) {
      this.setState({
        batchCompleted: true,
        cards: [],
      });
      setTimeout(() => {
        window.location.reload();
      }, 100);
      return;
    }

    this.setState(
      {
        inputValue: '',
        cardIndex: cardIndex,
        loading: true,
        error: null,
      },
      () => this.fetchCard(this.state.cards[cardIndex].id)
    );
  };

  fetchCard = async (id) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/cards/${id}`,
      });

      this.setState({
        error: data.tokenId
          ? new Error('Card already got a token attached')
          : null,
        currentCard: data,
        loading: false,
      });

      setTimeout(() => {
        this.inputRef.current?.focus();
      }, 100);
    } catch (e) {
      this.setState({ error: e });
    }
  };

  render() {
    const { t } = this.props;
    const { cards = [] } = this.state;

    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="cards" to="/cards">
                {t('cards.title', 'Cards')}
              </Link>,
            ]}
            active="Batch Assign tokens"
          />

          {!this.state.started && (
            <Form>
              <Form.Group widths="equal">
                <Form.Select
                  required
                  value={this.state.batchType}
                  options={[
                    {
                      text: 'Tag',
                      value: 'tag',
                    },
                    {
                      text: 'Card',
                      value: 'card',
                    },
                  ]}
                  name="type"
                  label={'Type'}
                  type="text"
                  onChange={(e, { value }) => {
                    this.setState({ batchType: value });
                  }}
                />
                <Form.Field />
                <Form.Field />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <DateObjectInput
                    label="Date to"
                    value={this.state.dateTo}
                    onChange={(dateTo) => {
                      this.setState({ dateTo });
                    }}
                  />
                  <TimeObjectInput
                    disabled={!!this.state.started}
                    value={this.state.dateToTime}
                    onChange={(dateToTime) => {
                      this.setState({ dateToTime });
                    }}
                  />
                </Form.Field>
                <Form.Field />
                <Form.Field />
              </Form.Group>
            </Form>
          )}

          {cards.length === 0 && (
            <Button style={{ width: '120px' }} onClick={this.onStartBatch}>
              Start batch
            </Button>
          )}

          {cards.length !== 0 && (
            <Button style={{ width: '120px' }} onClick={this.onCancel}>
              Cancel
            </Button>
          )}

          <Button
            basic
            as="a"
            disabled={cards.length === 0}
            rel="noopener"
            target="_blank"
            onClick={() => {
              this.setState({ printed: true });
            }}
            href={labelPrintUrlForCards(cards)}>
            Print labels
          </Button>

          {this.state.batchCompleted && <Message>Batch Done</Message>}
          {!this.state.batchCompleted && this.state.currentCard && (
            <>
              <Divider horizontal>
                <Header as="h4" style={{ marginTop: '0em' }}>
                  {this.state.currentCard.type} {this.state.cardIndex + 1} /{' '}
                  {this.state.cards?.length}
                </Header>
              </Divider>

              <Segment
                loading={this.state.loading}
                style={{ minHeight: '276px' }}>
                <div style={{ float: 'right' }}>
                  <Button onClick={this.nextCard}>Skip</Button>
                </div>
                <Header>Scan {this.state.currentCard.type} for</Header>

                <p>
                  <code>
                    Card Id:{' '}
                    <a
                      target="_blank"
                      href={`/accounts/${this.state.currentCard.accountId}/cards`}
                      rel="noopener noreferrer">
                      {this.state.currentCard.id}
                    </a>
                  </code>
                </p>

                <CardAddress
                  key={this.state.currentCard.id}
                  card={this.state.currentCard}
                />

                <br />
                <br />
                <br />
                {this.state.error && (
                  <Message error content={this.state.error.message} />
                )}

                <Input
                  ref={this.inputRef}
                  size="massive"
                  value={this.state.inputValue || ''}
                  fluid
                  disabled={this.state.loading}
                  onBlur={(e) => {
                    setTimeout(() => e.target.focus());
                  }}
                  onChange={(e, { value }) => {
                    this.setState({ inputValue: value }, () =>
                      this.debouncedConfirmTokenForCard(value)
                    );
                  }}
                  placeholder="Waiting for input from card scanner"
                />
              </Segment>

              {this.state.prevCard && (
                <Segment
                  loading={this.state.loading}
                  style={{ marginTop: '1em' }}>
                  <p>Previous scanned card</p>
                  <p>
                    <code>
                      Card Id:{' '}
                      <a
                        target="_blank"
                        href={`/accounts/${this.state.prevCard.accountId}/cards`}
                        rel="noopener noreferrer">
                        {this.state.prevCard.id}
                      </a>
                    </code>
                  </p>

                  <CardAddress
                    key={this.state.prevCard.id}
                    card={this.state.prevCard}
                  />
                </Segment>
              )}
            </>
          )}
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(BatchAssignTokens);
