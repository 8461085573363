import React, { useRef } from 'react';
import { Modal, Form, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { BillingInfoFormikForm } from '../../../../BillingInfo/formik-form';
import { Formik, FormikProps } from 'formik';
import { useAccount } from 'contexts/account';
import {
  AccountBillingValues,
  useAccountBillingInitialState,
  useAccountBillingValidationSchema,
} from './formData';
import {
  AccountBillingErrorMessage,
  useAccountBillingErrorFormatter,
} from './errors';
import { useSubmitAccountBilling } from './api';
import { useFeatures } from 'contexts/features';

export default (props: any) => {
  const { onDone, onBack, stepHeader } = props;
  const { t } = useTranslation();
  const { getAccount } = useAccount();

  const { hasFeature, isReady: isFeatureReady } = useFeatures();
  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const formRef = useRef<FormikProps<AccountBillingValues> | null>(null);
  const formInitialState = useAccountBillingInitialState();
  const formValidationSchema = useAccountBillingValidationSchema(
    isBicCollectionEnabled
  );
  const errorFormatter = useAccountBillingErrorFormatter();

  const submitAccountBillingRequest = useSubmitAccountBilling({
    onSuccess: async () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
      if (getAccount) {
        await getAccount();
      }
      onDone();
    },
    onError: (error) => {
      error.innerError.details?.forEach((detail) =>
        errorFormatter(detail, formRef.current)
      );
    },
  });

  const onSubmit = (values: AccountBillingValues) => {
    submitAccountBillingRequest.mutate(values);
  };

  return (
    <>
      <Modal.Header>
        {stepHeader}
        {t('newChargingStationBilling.header', 'Configure Account Billing')}
      </Modal.Header>
      <Modal.Content>
        <Formik
          innerRef={formRef}
          initialValues={formInitialState.formData}
          onSubmit={onSubmit}
          validationSchema={formValidationSchema}>
          {({ handleSubmit }) => {
            return (
              <Form onSubmit={handleSubmit} id="account-billing-form">
                {submitAccountBillingRequest.isError && (
                  <AccountBillingErrorMessage
                    error={submitAccountBillingRequest.error}
                    onDismiss={() => submitAccountBillingRequest.reset()}
                  />
                )}
                {isFeatureReady && (
                  <BillingInfoFormikForm
                    accountType={formInitialState.accountType}
                    enableCountryCodeInput={
                      !formInitialState.formData?.billing?.countryCode
                    }
                    enableBicCollection={isBicCollectionEnabled}
                    objectPath="billing"
                  />
                )}
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <Button content={t('common.back', 'Back')} onClick={() => onBack()} />

        <Button
          form="account-billing-form"
          primary
          content={t('common.next', 'Next')}
        />
      </Modal.Actions>
    </>
  );
};
