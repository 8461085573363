import React, { useEffect, useState } from 'react';
import { Loader, Message, Statistic, Header } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

export default function Stats({ cpoStatsApiRoute }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchStats(cpoStatsApiRoute);
  }, [cpoStatsApiRoute]);

  const fetchStats = async (cpoStatsApiRoute) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: cpoStatsApiRoute,
      });
      setData(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Header as="h3">
        {t('cpoFinanceStats.totalActivatedEvses', 'Activated EVSE Controllers')}
      </Header>
      {loading && <Loader active inline="centered" />}

      {error && <Message error content={error.message} />}
      {data && (
        <React.Fragment>
          <Statistic.Group widths={4}>
            <Statistic
              value={data.ac}
              label={t('cpoStatLabels.acSockets', 'ac sockets')}
            />
            <Statistic
              value={data.dc}
              label={t('cpoStatLabels.dcSockets', 'dc sockets')}
            />
            <Statistic
              value={data.connectors}
              label={t('cpoStatLabels.totalSockets', 'total sockets')}
            />
            <Statistic
              value={data.total}
              label={t(
                'cpoStatLabels.totalChargingStations',
                'total charging stations'
              )}
            />
          </Statistic.Group>
        </React.Fragment>
      )}
    </div>
  );
}
