import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Table, Button, Label } from 'semantic';

import { Search } from 'components';

import ViewSyncLog from 'components/modals/ViewSyncLog';

import { formatDateTime } from 'utils/date';

export default function SyncLogTable() {
  const { t } = useTranslation();
  const { items, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              {t('syncLog.columnType', 'Type')}
            </Table.HeaderCell>
            <Table.HeaderCell width={11}>
              {t('syncLog.columnMessage', 'Message')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('syncLog.columnTime', 'Time')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
              {t('syncLog.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Label
                    content={item.type}
                    color={item.type === 'error' ? 'red' : undefined}
                  />
                </Table.Cell>
                <Table.Cell>{item.message}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <ViewSyncLog
                    initialValues={item}
                    trigger={<Button basic icon="magnifying-glass" />}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
