import React from 'react';
import { Divider, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import { AggregateTimeSeries, SeriesChart } from 'react-tectonic';

import { mergeFilters, sessionsExclusionFilter } from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import { useUser } from 'contexts/user';

export default function MspOverview({ baseFilter = {} }) {
  const { t } = useTranslation();

  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;

  return (
    <div>
      <Divider hidden />
      <Divider hidden />

      <Statistic.Group
        widths="three"
        style={{ justifyContent: 'space-between' }}>
        <CardinalityStatistic
          collection="cards"
          label={t('common.cards', 'Cards')}
          filter={baseFilter}
        />
        <CardinalityStatistic
          collection="tokens"
          label={t('common.tokens', 'Tokens')}
          filter={baseFilter}
        />
        <CardinalityStatistic
          collection="sessions"
          label={t('common.sessions', 'Sessions')}
          filter={baseFilter}
        />
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="count"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('common.sessions', 'Sessions')}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueField="count"
          valueFieldLabel={t('common.sessions', 'Sessions')}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />
    </div>
  );
}
