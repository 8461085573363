import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { mergeWith, set, omit } from 'lodash-es';

import SearchDropdown from 'components/form-fields/SearchDropdown';
import { request } from 'utils/api';
import { getRoleIds, setRolesInBody } from 'utils/roles';
import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';

class EditMyUser extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: this.getDefaults(),
  };

  getDefaults() {
    if (this.props.initialValues?.id) {
      return {
        roles: [],
        ...this.props.initialValues,
        accountRoleIds: getRoleIds(this.props.initialValues.accountRoles || []),
        providerRoleIds: getRoleIds(
          this.props.initialValues.providerRoles || []
        ),
      };
    }
    return {
      defaultLangCode: 'nl',
      roles: ['consumer'],
      ...this.props.initialValues,
      accountRoleIds: getRoleIds(this.props.initialValues.accountRoles || []),
      providerRoleIds: getRoleIds(this.props.initialValues.providerRoles || []),
    };
  }

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    const { formValues } = this.state;

    const body = setRolesInBody(this.state.formValues);

    try {
      if (formValues?.id) {
        await request({
          method: 'PATCH',
          path: `/1/users/${formValues.id}`,
          // excluding the contact information from the body to avoid validation errors
          body: omit(body, ['contact']),
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/users',
          body: {
            ...formValues,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value, convertEmpty = true) {
    const parsedValue =
      convertEmpty && typeof value === 'string' && value.length === 0
        ? null
        : value;

    this.setState({
      submitted: false,
      formValues: mergeWith(
        {},
        this.state.formValues,
        set({}, name, parsedValue),
        (objValue, srcValue) => {
          if (Array.isArray(objValue)) return srcValue;
        }
      ),
    });
  }

  render() {
    const { initialValues, t } = this.props;
    const { formValues = {}, error, loading } = this.state;

    const isUpdate = !!initialValues.id;

    const name =
      (initialValues.contact &&
        [initialValues.contact.firstName, initialValues.contact.lastName]
          .filter(Boolean)
          .join(' ')) ||
      'Unknown Name';

    return (
      <>
        <Modal.Header>
          {isUpdate
            ? t('editMyUser.titleEdit', 'Edit {{name}}', {
                name: name,
              })
            : t('editMyUser.titleNew', 'New User')}
        </Modal.Header>
        <Modal.Content>
          <Form
            id="edit-user-form"
            autoComplete="disabled"
            error={Boolean(error)}
            onSubmit={() => this.onSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Field>
              <label>{t('editMyUser.accountRoles', 'Account Roles')}</label>
              <p style={{ marginBottom: '0.2em' }}>
                {t(
                  'editMyUser.accountRolesDescription',
                  'These roles give the user access within the scope of the account.'
                )}
              </p>
              <SearchDropdown
                multiple
                value={formValues.accountRoleIds}
                objectMode={false}
                disabled={!formValues.accountId}
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/roles/search',
                    method: 'POST',
                    body: {
                      ...body,
                      accountId: formValues.accountId,
                      context: 'account',
                    },
                  });
                }}
                onChange={(e, { value }) =>
                  this.setField('accountRoleIds', value)
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            disabled={loading}
            primary
            content={isUpdate ? t('button.update') : t('button.create')}
            form="edit-user-form"
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditMyUser));
