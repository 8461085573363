import React, { useEffect } from 'react';
import { request } from 'utils/api';
import { Segment, Grid, Form, Message, Button } from 'semantic';

import PageCenter from 'components/PageCenter';
import Logo from 'components/LogoTitle';

import { Link, useHistory } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { providerIsCpoOnly } from 'utils/providers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'components/Sidebar/Divider';
import SocialLoginButton from 'screens/Auth/Login/SocialLoginButton/SocialLoginButton';

function determineSignupRouteForProvider(provider) {
  if (['vattenfall', 'e-flux'].includes(provider?.slug)) {
    return '/join';
  }

  if (provider.slug === 'road') {
    return '/api/signup';
  }
  if (providerIsCpoOnly(provider)) {
    return '/cpo/signup';
  }
  return '/signup';
}

export default function Login(props) {
  const params = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    error: null,
    loading: false,
    email: '',
    password: '',
  });

  const history = useHistory();

  const { t } = useTranslation();

  const { provider, setToken } = useUser();

  useEffect(() => {
    const openIdError = params.get('openid-error');
    switch (openIdError) {
      default:
        console.warn(
          `Unknown OpenID error: ${openIdError}. Please check the OpenID provider for more information.`
        );
        break;
      case 'CONFLICT':
        setState({
          ...state,
          loading: false,
          error: {
            message: t(
              'login.emailExists',
              'An account with this email address already exists. Please login with your email and password.'
            ),
          },
        });
        break;
    }
  }, []);

  // If the user is arriving to this page with a token already in the URL, we should attempt to log them in
  // immediately as they are likely using social login. We first verify the validy of this token, and then
  // log the user in.
  useEffect(() => {
    if (!params.get('token')) {
      return;
    }
    const fetchUser = async () => {
      try {
        await request({
          method: 'GET',
          path: `/1/users/me`,
          token: params.get('token'),
        });
        setToken(params.get('token'));
        history.push(params.get('redirect') || '/');
      } catch (e) {
        setState({
          ...state,
          error: {
            message: t(
              'login.invalidToken',
              'We were unable to sign in using your selected authentication provider. Please try again using your email and password.'
            ),
          },
          loading: false,
        });
      }
    };
    fetchUser();
  }, []);

  async function handleOnSubmit() {
    setState({
      ...state,
      error: null,
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/login',
        body: {
          email: state.email,
          password: state.password,
        },
      });

      if (data.mfaRequired) {
        window.localStorage.setItem('mfa-auth', JSON.stringify(data));
        history.push(`/login/verification?${params.toString()}`);
        return;
      }

      if (data.ssoEnabled && data.redirectRequired) {
        window.location = data.redirectUrl;
        return;
      }

      setToken(data.token);

      history.push(params.get('redirect') || '/');
    } catch (error) {
      setState({
        ...state,
        error,
        loading: false,
      });
    }
  }

  const ssoEnabled = provider.enableSingleSignOn;

  return (
    <PageCenter>
      <Logo title="Login" />
      <Segment.Group>
        <Segment padded>
          <Form error={!!state.error} size="large" onSubmit={handleOnSubmit}>
            {state.error && <Message error content={state.error.message} />}
            <Form.Field error={state.error?.hasField?.('email')}>
              <Form.Input
                value={state.email}
                onChange={(e, { value }) => {
                  setState({ ...state, email: value });
                }}
                name="email"
                icon="envelope"
                iconPosition="left"
                placeholder={t('formPlaceHolder.email', 'E-mail Address')}
                type="email"
                autoComplete="email"
              />
            </Form.Field>
            <Form.Field error={state.error?.hasField?.('password')}>
              <Form.Input
                value={state.password}
                onChange={(e, { value }) =>
                  setState({ ...state, password: value })
                }
                name="password"
                icon="lock"
                iconPosition="left"
                placeholder={t('formPlaceHolder.password', 'Password')}
                autoComplete="current-password"
                type="password"
              />
            </Form.Field>
            <div>
              <Button
                fluid
                primary
                size="large"
                content="Login"
                loading={state.loading}
                disabled={state.loading}
              />
            </div>
            {provider.enableSocialLogin &&
              provider.socialLoginIssuers?.length > 0 && (
                <>
                  <Divider text="or" />
                  {provider.socialLoginIssuers.map((issuer) => (
                    <SocialLoginButton
                      slug={provider?.slug}
                      issuer={issuer}
                      key={issuer}
                    />
                  ))}
                </>
              )}
          </Form>
        </Segment>
        <Segment secondary>
          <Grid style={{ marginLeft: 0, marginRight: 0 }}>
            {provider.enableSelfSignup && (
              <Grid.Column floated="left" width={8}>
                <Link
                  to={{
                    pathname: determineSignupRouteForProvider(provider),
                    search: props.location.search,
                  }}>
                  {t('button.signup', 'Signup')}
                </Link>
              </Grid.Column>
            )}
            <Grid.Column floated="right" width={8} textAlign="right">
              <Link
                to={{
                  pathname: '/password/forgot',
                  search: props.location.search,
                }}>
                {t('link.forgotPassword', 'Forgot Password')}
              </Link>
            </Grid.Column>
          </Grid>
        </Segment>
        {ssoEnabled && (
          <Segment padded>
            <Link
              to={{
                pathname: '/login/sso',
                search: props.location.search,
              }}>
              {t('link.loginWithSSO', 'Log in with your company SSO')}
            </Link>
          </Segment>
        )}
      </Segment.Group>
    </PageCenter>
  );
}
