import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';

export default function Cards() {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path="/cards" component={List} exact />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
