import React from 'react';
import { Header } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Message, Segment } from '../../semantic';
import Pagination from '../../components/Pagination';
import styled from 'styled-components';
import AuditTrailTable from './AuditTrailTable';

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default function AuditTrailEntries({
  status,
  limit,
  data: items,
  meta = {},
  page,
  setPage,
}) {
  const { t } = useTranslation();

  return (
    <div className="list">
      <Header as="h3">{t('auditTrail.entries', 'Entries')}</Header>
      {status.success && !items.length && (
        <Message>
          {t('auditTrail.noEntries', 'No audit trail entries found')}
        </Message>
      )}

      {status.success && items.length > 0 && (
        <AuditTrailTable auditEntries={items} />
      )}
      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) => {
              setPage(activePage).then(() => {
                window.scrollTo(0, 0);
              });
            }}
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
}
