import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';

import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function BillingPlans({
  prefixPath = '/finance',
  billingPlanPath = '/finance/billing-plans',
}) {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route
            path={billingPlanPath}
            component={() => <List prefixPath={prefixPath} />}
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
