import { useMemo, useState } from 'react';
import { request } from '../../../../utils/api';

export function useBillingPlanOptions(accountId: string) {
  const [billingPlanOptions, setBillingPlanOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useMemo(() => {
    const requestData = async () => {
      const { data: account } = await request({
        method: 'GET',
        path: `/1/accounts/${accountId}`,
      });

      const payload = {
        currency: account?.billingCurrency,
        providerContext: 'cpo',
        ...(account?.allowedBillingPlanIds?.length && {
          ids: account.allowedBillingPlanIds,
        }),
      };

      const billingPlans = await request({
        path: '/1/billing-plans/search',
        method: 'POST',
        body: payload,
      });

      setBillingPlanOptions(
        billingPlans?.data?.map((plan: any) => ({
          value: plan.id,
          label: plan.name,
        }))
      );
    };

    if (!!accountId) {
      requestData();
    }
  }, [accountId]);

  return billingPlanOptions;
}
