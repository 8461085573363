import React from 'react';
import { Table } from 'semantic';
import { Link } from 'react-router-dom';
import { formatCardStatus } from 'utils/formatting';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { StyledTable } from 'components/modals/SyncLogStatus/StyledComponents';

function CardStatusTable({ cards, t }) {
  return (
    <Table definition>
      <Table.Body>
        {cards.map((card, index) => (
          <Table.Row key={card.id}>
            <Table.Cell width={2}>
              {t('cardType.card', 'Card')} {index + 1}
            </Table.Cell>
            <StyledTable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ display: 'none' }} />
                  <Table.HeaderCell width={8}>
                    {t('myCards.nameOnCardHeader', 'Name on Card')}
                  </Table.HeaderCell>
                  <Table.HeaderCell width={8}>
                    {t('cards.columnStatus', 'Status')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Link to={`/accounts/${card.accountId}/cards`}>
                      {card.nameOnCard || card.id}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{formatCardStatus(card.status, t)}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </StyledTable>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

CardStatusTable.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      accountId: PropTypes.string.isRequired,
      nameOnCard: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default withTranslation()(CardStatusTable);
