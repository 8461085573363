import React from 'react';
import { Link } from 'react-router-dom';
import { Message, Header, Divider, Grid } from 'semantic';
import Sessions from './Sessions';
import Stats from './Stats';
import AppWrapper from 'components/AppWrapper';
import MyActiveSessions from './MyActiveSessions';

import Breadcrumbs from 'components/Breadcrumbs';

import { useTranslation } from 'react-i18next';

export default function MyUsage() {
  const { t } = useTranslation();

  return (
    <AppWrapper>
      <Breadcrumbs
        path={[
          <Link key="my-cards" to="/my-cards">
            {t('myCards.title', 'My Cards')}
          </Link>,
        ]}
        active={t('myUsage.sessionTitle', 'Charge Sessions')}
      />
      <MyActiveSessions />
      <Grid reversed="computer">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            {t('myUsage.sessionTitle', 'Charge Sessions')}
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <Stats />
      <Sessions />
    </AppWrapper>
  );
}
