import React from 'react';
import { Modal, Button } from 'semantic';

import PlansCPO from 'components/PlansCPO';

import { useTranslation } from 'react-i18next';
import { useWizardContext } from '../context';

export default ({ onDone, onBack, stepHeader }) => {
  const { setBillingPlan, billingPlan, station = {} } = useWizardContext();
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>
        {stepHeader}
        {t('newChargingStationPlans.header', 'Select A Plan')}
      </Modal.Header>
      <Modal.Content>
        <PlansCPO
          disablePlan={(plan) => {
            if (station.powerType && station.powerType !== 'unknown') {
              return !(plan.tags || [])
                .map((tag) => tag.toLowerCase())
                .includes(station.powerType);
            }
            return false;
          }}
          billingPlanId={billingPlan && billingPlan.id}
          onSelect={(plan) => {
            setBillingPlan(plan);
            onDone();
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onBack()}>{t('common.back', 'Back')}</Button>
        <Button
          disabled={!billingPlan}
          primary
          content={t('common.next', 'Next')}
          onClick={() => onDone()}
        />
      </Modal.Actions>
    </>
  );
};
