import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import isoCountries from 'i18n-iso-countries';

import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';

import modal from 'helpers/modal';
import { Divider, Grid, Segment } from '../../semantic';
import { Link } from 'react-router-dom';

function validateIdentifierFormat(evseId) {
  if (!evseId) return false;
  evseId = normalizeIdentifier(evseId);
  return !!evseId.match(/^(?:..EFLE)?V?[0-9]+$/);
}

function normalizeIdentifier(evseId) {
  // let's check if scanned URL

  try {
    const possibleURL = new URL(evseId);
    evseId = possibleURL.pathname.split('/').at(-1);
    // eslint-disable-next-line no-empty
  } catch {}

  return (evseId || '').replace(/\*/g, '');
}

class EvseIdLink extends React.Component {
  static defaultProps = {
    initialValues: { identifier: '' },
  };

  state = {
    open: false,
    warning: '',
    formValues: { ...this.props.initialValues },
    loading: false,
    validated: false,
    evseIdAssignmentRequest: this.props.evseIdAssignmentRequest,
  };

  loadEvseControllerInfo = async (evseControllerId) => {
    this.setState({ loading: true });

    try {
      const { data: evseController } = await request({
        method: 'GET',
        path: '/1/evse-controllers/' + evseControllerId,
      });

      const newState = { evseController };

      if (evseController?.accountId) {
        const { data: account } = await request({
          method: 'GET',
          path: '/1/accounts/' + evseController?.accountId,
        });

        newState.account = account;
      }

      if (evseController?.locationId) {
        const { data: location } = await request({
          method: 'GET',
          path: '/1/locations/' + evseController?.locationId,
        });

        newState.location = location;

        if (location?.userId) {
          const { data: user } = await request({
            method: 'GET',
            path: '/1/users/' + location.userId,
          });

          newState.user = user;
        }
      }

      this.setState({ loading: false, ...newState });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false,
      });
    }
  };

  componentDidMount = async () => {
    if (this.state.evseIdAssignmentRequest?.completed) {
      return await this.loadEvseControllerInfo(
        this.state.evseIdAssignmentRequest.evseControllerId
      );
    }
  };

  onSubmit = async () => {
    const { t } = this.props;

    this.setState({
      loading: true,
      error: null,
      inputDisabled: true,
    });

    const { formValues } = this.state;

    if (!validateIdentifierFormat(formValues.identifier)) {
      this.setState({
        loading: false,
        error: t(
          'evseIdAssignmentRequestLink.invalidIdentifier',
          'Sorry but this identifier does not seem to be of a valid format.'
        ),
        inputDisabled: false,
      });
    }

    try {
      const response = await request({
        method: 'POST',
        path: '/1/evse-ids/link',
        body: {
          identifier: normalizeIdentifier(formValues.identifier),
          evseControllerId: this.state.evseIdAssignmentRequest.evseControllerId,
        },
      });

      const evseIdAssignmentRequest = response.data?.evseIdAssignmentRequest;

      if (!evseIdAssignmentRequest) {
        return this.onComplete();
      }

      await this.loadEvseControllerInfo(
        this.state.evseIdAssignmentRequest.evseControllerId
      );

      this.setState({
        error: undefined,
        loading: false,
        inputDisabled: false,
        evseIdAssignmentRequest,
        completed: true,
      });
    } catch (err) {
      let error = err.message;

      switch (err.message) {
        case 'unknown identifier':
          error = t(
            'evseIdAssignmentRequestLink.unknownIdentifier',
            `We couldn't find the provided identifier in the list of reserved ones.`
          );
          break;
        case 'duplicate identifier':
          error = t(
            'evseIdAssignmentRequestLink.duplicateIdentifier',
            `We've detected that this identifier is a duplicate. It's likely it has been used before with a different country code. Please bin this sticker and try with a different one.`
          );
          break;
        case 'evseId already in use':
          error = t(
            'evseIdAssignmentRequestLink.identifierAlreadyInUse',
            `This identifier is already assigned to a different EVSE Controller and cannot be reassigned.`
          );
          break;
      }

      this.setState({
        error,
        loading: false,
        inputDisabled: false,
      });
    }
  };

  setIdentifier(value) {
    this.setState({
      submitted: false,
      error: undefined,
      formValues: {
        identifier: value,
      },
    });
  }

  onComplete() {
    this.props.close();
    this.props.onComplete();
  }

  pluckAddressLines(name, address) {
    let country;
    try {
      const isoCountry = isoCountries.getName(
        address.country || address.countryCode,
        'en'
      );
      country = isoCountry || '';
    } catch (e) {
      country = '';
    }
    return [
      name,
      [address.address, address.addressLine2].filter((s) => !!s).join(' '),
      [address.postalCode || address.postal_code, address.city]
        .filter((s) => !!s)
        .join(' '),
      [country].filter((s) => !!s),
    ].filter((l) => !!l);
  }

  getAddressesForPrint() {
    const { evseController, location, account, user } = this.state;

    if (!account) return [];

    const addresses = {};

    const accountName = account.contact
      ? `${account.contact?.firstName} ${account.contact?.lastName}`
      : undefined;

    const {
      billing: accountBillingAddress,
      creditBilling: accountCreditAddress,
    } = account;

    if (accountBillingAddress?.address) {
      addresses.accountBilling = {
        label: 'Account Address',
        lines: this.pluckAddressLines(accountName, accountBillingAddress),
      };
    }

    if (accountCreditAddress?.address) {
      addresses.accountCreditBilling = {
        label: 'Account Address (credit)',
        lines: this.pluckAddressLines(accountName, accountCreditAddress),
      };
    }

    if (user && location?.accessPolicy == 'employeeReimburse') {
      const { creditBilling: userCreditBilling } = user;
      const userName = user.contact
        ? `${user.contact?.firstName} ${user.contact.lastName}`
        : null;
      if (userCreditBilling?.address) {
        addresses.location = {
          label: 'Location Address',
          lines: this.pluckAddressLines(userName || accountName, location),
        };
      }
    }

    if (addresses.location && location.accessPolicy == 'employeeReimburse') {
      addresses.location.preferred = true;
      delete addresses.accountCreditBilling;
    } else if (
      addresses.accountCreditBilling &&
      location.accessPolicy == 'businessReimburse'
    ) {
      addresses.accountCreditBilling.preferred = true;
    } else if (addresses.accountBilling) {
      addresses.accountBilling.preferred = true;
    }

    return Object.values(addresses).sort((a) => -1 * a.preferred);
  }

  getPrintLink(lines) {
    return `${document.location.protocol}//${
      document.location.host
    }/cards/print-label?data=${encodeURIComponent(JSON.stringify([lines]))}`;
  }

  render() {
    const { t } = this.props;
    const {
      evseIdAssignmentRequest,
      formValues = {},
      warning,
      loading,
      error,
      location,
      completed,
      evseController,
    } = this.state;

    if (!loading && (completed || evseIdAssignmentRequest.completed)) {
      if (error) {
        return <Message error content={error} />;
      }

      const addresses = this.getAddressesForPrint();

      const rows = [],
        size = 3;

      while (addresses.length > 0) rows.push(addresses.splice(0, size));

      const ev = evseIdAssignmentRequest.evseController || evseController;

      return (
        <>
          <Modal.Header>
            {t(
              'evseIdAssignmentRequestLink.headerComplete',
              'Identifier assigned'
            )}
          </Modal.Header>
          <Modal.Content>
            <div>
              The Identifier {ev.evseId} has been assigned to{' '}
              <Link
                to={`/charging-stations/${ev.id}`}
                title={`Serial Number: ${ev.serialNumber}`}>
                {ev.ocppIdentity}
              </Link>
              .{' '}
              {location && (
                <span>
                  The location accessPolicy is <b>{location.accessPolicy}</b>
                </span>
              )}
              .
            </div>
            <Divider hidden />
            <Grid columns={3}>
              {rows.map((addresses, i) => (
                <Grid.Row stretched key={`address_row_${i}`}>
                  {addresses.map((a, j) => (
                    <Grid.Column key={`address_column_${i}_${j}`}>
                      <Segment raised={a.preferred}>
                        <div>
                          <b>{a.label}</b>
                        </div>
                        {a.lines.map((l, k) => (
                          <div key={`address_line_${i}_${j}_${k}`}>{l}</div>
                        ))}
                      </Segment>
                      <Button
                        content="Print"
                        rel="noopener"
                        target="_blank"
                        href={this.getPrintLink(a.lines)}></Button>
                    </Grid.Column>
                  ))}
                </Grid.Row>
              ))}
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              icon="check"
              content="Complete"
              onClick={() => this.onComplete()}
            />
          </Modal.Actions>
        </>
      );
    }

    return (
      <>
        <Modal.Header>
          {t(
            'evseIdAssignmentRequestLink.header',
            'Assign EVSE ID to a Controller.'
          )}
        </Modal.Header>
        <Modal.Content>
          {warning && (
            <Message warning icon="exclamation-triangle" content={warning} />
          )}

          <Grid columns={3} divided>
            <Grid.Row>
              <Grid.Column>
                <>
                  <Link
                    to={`/charging-stations/${evseIdAssignmentRequest.evseController.id}`}
                    title={`Serial Number: ${evseIdAssignmentRequest.evseController.serialNumber}`}>
                    {evseIdAssignmentRequest.evseController.ocppIdentity}
                  </Link>
                  <p>
                    <small>
                      Evse ID:{' '}
                      {evseIdAssignmentRequest.evseController.evseId || '-'}
                    </small>{' '}
                    <br />
                    <small>
                      Serial number:{' '}
                      {evseIdAssignmentRequest.evseController.serialNumber ||
                        '-'}
                    </small>
                  </p>
                </>
              </Grid.Column>
              <Grid.Column>
                {evseIdAssignmentRequest.evseController.location && (
                  <>
                    Location:{' '}
                    <Link
                      to={`/charging-stations/locations/${evseIdAssignmentRequest.evseController.location.id}`}>
                      {evseIdAssignmentRequest.evseController.location.name}
                    </Link>
                  </>
                )}
              </Grid.Column>
              <Grid.Column>
                {evseIdAssignmentRequest.evseController.account && (
                  <>
                    Account:{' '}
                    <Link
                      to={`/accounts/${evseIdAssignmentRequest.evseController.account.id}`}>
                      {evseIdAssignmentRequest.evseController.account.name}
                    </Link>
                    <br />
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <>
            <Divider hidden />
            <p>
              {t(
                'evseIdAssignmentRequestLink.enterIdentifier',
                `Please enter the identifier from the sticker you would like to
            assign to this EVSE. You can also use a USB scanner to scan the QR
            code in.`
              )}
            </p>
          </>
          <Form error={Boolean(error)} onSubmit={() => this.onSubmit()}>
            {error && <Message error content={error} />}
            <Form.Input
              value={formValues.identifier}
              required
              disabled={this.state.inputDisabled}
              autoComplete="disabled"
              name="identifier"
              label={t('evseIdAssignmentRequestLink.identifier', 'Identifier')}
              type="text"
              onChange={(e, { name, value }) => {
                this.setIdentifier(value);
              }}
            />
          </Form>
          {evseIdAssignmentRequest?.evseController?.connectors?.length > 3 && (
            <Message
              info
              content={t(
                'evseIdAssignmentRequestLink.possibleMasterSlave',
                `The EVSE you are trying to associate has {{numConnectors}} connectors and is likely deployed in a master-slave configuration. Consider selecting a sticker that includes sub-connectors.`,
                {
                  numConnectors:
                    evseIdAssignmentRequest?.evseController?.connectors?.length,
                }
              )}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={this.state.loading}
            disabled={loading || !formValues.identifier}
            primary
            content={t('evseIdAssignmentRequestLink.assign', 'Assign')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EvseIdLink));
