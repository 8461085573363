import React from 'react';
import { Button } from 'semantic';
import { safeFileName } from 'utils/formatting';

import SearchContext from './Context';

export default class ExportButton extends React.Component {
  static contextType = SearchContext;

  state = {
    loading: false,
    error: null,
  };

  handleSubmit = async () => {
    this.setState({ loading: true, error: null });
    const body = this.props.body || {};
    try {
      await this.context.onDataNeeded({
        format: 'csv',
        limit: this.props.limit || 100000,
        filename: this.props.filename
          ? `${safeFileName(this.props.filename.replace('.csv', ''))}.csv`
          : 'export.csv',
        ...this.context.filters,
        ...body,
      });
      this.setState({
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { meta, loading: contextloading } = this.context;

    const As = this.props.as || Button;

    return (
      <As
        loading={loading}
        disabled={contextloading || loading || meta?.total === 0}
        negative={error}
        title={error?.message}
        basic
        icon={error || 'download'}
        content={
          this.props.content === '' ? undefined : this.props.content || 'Export'
        }
        onClick={this.handleSubmit}
      />
    );
  }
}
