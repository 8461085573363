import React, { useState } from 'react';
import { Modal } from 'semantic';
import JSONBlock from '../JSONBlock';
import ExpandJsonButton from '../ExpandJsonButton';
import AsyncModal from 'helpers/async-modal';

const regEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
// test if a string is a valid ISO 8601 date
function isISOString(dateString) {
  if (typeof dateString !== 'string') return false;
  if (dateString instanceof Date) return true;

  if (!dateString) return false;

  return regEx.test(dateString);
}

const objectIdRegex = /^[a-f\d]{24}$/i;

function isObjectIdLike(objectIdString) {
  if (typeof objectIdString !== 'string') return false;
  return objectIdRegex.test(objectIdString);
}

function sortOrder(obj) {
  return Object.keys(obj)
    .sort()
    .reduce(function (acc, key) {
      acc[key] = obj[key];
      return acc;
    }, {});
}

function findMatchObjectId(key, object) {
  const idKey = `${key}Id`;
  const value = object[key];

  return (
    isObjectIdLike(object[idKey]) &&
    value &&
    [value.id, value._id].includes(object[idKey])
  );
}

function sortObject(object) {
  const idsField = {};
  const datesField = {};
  const objectIdsField = {};
  const restFields = {};
  const objectAndKeys = {};

  if (typeof object !== 'object' || !object) {
    return object;
  }

  Object.keys(object).forEach((key) => {
    const value = object[key];

    if (Array.isArray(value)) {
      Object.assign(restFields, { [key]: value.map(sortObject) });
      return;
    }
    if (typeof value === 'object') {
      Object.assign(
        findMatchObjectId(key, object) ? objectAndKeys : restFields,
        { [key]: sortObject(value) }
      );
      return;
    }
    if (['id', '_id'].includes(key)) {
      Object.assign(idsField, { [key]: value });
      return;
    }
    if (isISOString(value)) {
      Object.assign(datesField, { [key]: value });
      return;
    }
    if (isObjectIdLike(value)) {
      Object.assign(objectIdsField, { [key]: value });
      return;
    }
    Object.assign(restFields, { [key]: value });
  });

  return {
    ...sortOrder(idsField),
    ...sortOrder({ ...objectIdsField, ...objectAndKeys }),
    ...sortOrder(datesField),
    ...sortOrder(restFields),
  };
}

function getFormattedObject(values) {
  if (Array.isArray(values)) {
    return values.map(sortObject);
  }
  if (typeof values === 'object') {
    return sortObject(values);
  }

  return values;
}

function InspectObject(props) {
  const [collapsedLevel, setCollapsedLevel] = useState(1);
  const [item, setItem] = React.useState(getFormattedObject(props.data) || {});

  return (
    <>
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <span>Inspect {props.name}</span>
        <ExpandJsonButton
          collapsedLevel={collapsedLevel}
          onClick={() => setCollapsedLevel(collapsedLevel ? undefined : 1)}
        />
      </Modal.Header>
      <Modal.Content>
        <JSONBlock value={item} collapsed={collapsedLevel} />
      </Modal.Content>
    </>
  );
}

export default AsyncModal(InspectObject);
