import { useUser } from 'contexts/user';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Grid, Icon, ActionButton } from 'semantic';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import styles from './WelcomeModal.module.less';

type User = {
  numCards: number;
  numEvseControllers: number;
  accountType: string;
};

type Provider = {
  platformName: string;
  enabledChargeProducts?: string[];
};

type WelcomeModalProps = {
  isOpen?: boolean;
  isHidden?: boolean;
  onClose: () => void;
  onNewChargingStation: () => void;
  onNewChargeCard: () => void;
  onNewUser: () => void;
  onNewEmployee: () => void;
};

export default function WelcomeModal(props: WelcomeModalProps) {
  const { t } = useTranslation();
  const { user, provider }: { user: User; provider: Provider } = useUser();
  const enabledChargeProducts = provider?.enabledChargeProducts;

  const { productInstrumentation } = useProductInstrumentation();

  useEffect(() => {
    productInstrumentation?.trackJourneyStep({
      journey: 'sign-up',
      stage: 'started',
      step: 'product-selection',
      version: '1.1.0',
    });
  }, []);

  const { chargeCardEnabled, chargingStationsEnabled, addUsersEnabled } =
    useMemo(
      () => ({
        chargeCardEnabled:
          enabledChargeProducts?.includes('card') ||
          enabledChargeProducts?.includes('tag'),
        chargingStationsEnabled:
          enabledChargeProducts?.includes('charge-station'),
        addUsersEnabled: user.accountType === 'organization',
      }),
      [enabledChargeProducts, user.accountType]
    );

  const closeModal = () => {
    props.onClose();
  };

  return (
    <>
      <Modal
        closeOnDimmerClick={false}
        open={props.isOpen}
        closeIcon
        onClose={() => closeModal()}
        style={{ display: props.isHidden ? 'none' : undefined }}>
        <Modal.Header style={{ height: '118px', textAlign: 'center' }}>
          <h2 style={{ fontWeight: 600, marginTop: '0.5rem' }}>
            {t('welcome.header', 'Welcome to {{platformName}}', {
              platformName: provider.platformName,
            })}
          </h2>
          <p style={{ fontWeight: 500, fontSize: '14px' }}>
            {t('welcome.subHeader', 'Start your EV charging journey')}
          </p>
        </Modal.Header>
        <Modal.Content>
          <Grid style={{ paddingTop: '15px', paddingBottom: '30px' }}>
            {chargingStationsEnabled && (
              <WelcomeSection
                header={t(
                  'welcome.setupEvStation.header',
                  'Setup your EV Station'
                )}
                icon={'charging-station'}
                text={t(
                  'welcome.setupEvStation.text',
                  'Configure your EV stations for public use or private charging'
                )}
                actions={[
                  {
                    key: 'new-cs',
                    text: t(
                      'welcome.setupEvStation.button',
                      'New Charging Station'
                    ),
                    action: () => {
                      productInstrumentation?.trackJourneyStep({
                        journey: 'sign-up',
                        stage: 'completed',
                        step: 'product-selection',
                        version: '1.1.0',
                      });
                      props.onNewChargingStation();
                    },
                  },
                  {
                    key: 'inv-emps',
                    text: t('welcome.addEmployees.button', 'Invite Employees'),
                    action: () => props.onNewEmployee(),
                    hidden: !addUsersEnabled,
                  },
                ]}
              />
            )}
            {chargeCardEnabled && (
              <WelcomeSection
                header={t(
                  'welcome.orderChargeCard.header',
                  'Order Charge Card'
                )}
                icon={'credit-card regular'}
                text={t(
                  'welcome.orderChargeCard.text',
                  'Once you receive your charge card you can immediately start using it at over 200.000 stations.'
                )}
                actions={[
                  {
                    key: 'order-cc',
                    text: t(
                      'welcome.orderChargeCard.button',
                      'Order Charge Card'
                    ),
                    action: () => {
                      productInstrumentation?.trackJourneyStep({
                        journey: 'sign-up',
                        stage: 'completed',
                        step: 'product-selection',
                        version: '1.1.0',
                      });
                      props.onNewChargeCard();
                    },
                  },
                ]}
              />
            )}
            {addUsersEnabled && (
              <WelcomeSection
                header={t('welcome.addUsers.header', 'Add Users')}
                icon={'users'}
                text={t(
                  'welcome.addUsers.text',
                  'Create accounts for your employees so you can start reimbursements.'
                )}
                actions={[
                  {
                    key: 'inv-users',
                    text: t('welcome.addUsers.button', 'Invite Users'),
                    action: () => {
                      productInstrumentation?.trackJourneyStep({
                        journey: 'sign-up',
                        stage: 'completed',
                        step: 'product-selection',
                        version: '1.1.0',
                      });
                      props.onNewUser();
                    },
                  },
                ]}
              />
            )}
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  );
}

type WelcomeSectionProps = {
  header: string;
  icon: string;
  text: string;
  actions?: Action[];
};

type Action = {
  key: string;
  text: string;
  action: () => void;
  hidden?: boolean;
};

function WelcomeSection({ header, icon, text, actions }: WelcomeSectionProps) {
  return (
    <Grid.Row className={styles.productCard}>
      <Grid.Column width={2} className={styles.textAlignCenter}>
        <Icon name={icon} size={'huge'} />
      </Grid.Column>
      <Grid.Column width={12} className={styles.productDescription}>
        <h3 className={styles.productHeader}>{header}</h3>
        <p>{text}</p>
        {actions
          ?.filter(({ hidden }) => !hidden)
          ?.map(({ key, action, text }) => (
            <ActionButton
              primary
              key={key}
              onClick={action}
              className={styles.actionButton}>
              {text}
              <Icon style={{ marginLeft: '0.25rem' }} name={'arrow-right'} />
            </ActionButton>
          ))}
      </Grid.Column>
    </Grid.Row>
  );
}
