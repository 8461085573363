import React, { useRef } from 'react';
import EditCard from 'components/modals/EditCard';
import { useTranslation } from 'react-i18next';
import { currentUserCanAccess } from 'utils/roles';
import {
  SearchFilters,
  ListHeader,
  Breadcrumbs,
  Search,
  Layout,
} from 'components';
import { Segment, Divider, Button } from 'semantic';
import Table from './Table';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';

export default function Cards() {
  const { t } = useTranslation();

  const onDataNeeded = async (body) => {
    return request({
      method: 'POST',
      path: `/1/cards/search${body.format !== 'csv' ? '/fast' : ''}`,
      body,
    });
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
      status: {
        label: t('cards.filterByStatus', 'Filter By Status'),
        type: 'string',
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
      billingPlan: {
        label: t('cards.billingPlan', 'Billing Plan'),
        type: 'string',
        multiple: true,
      },
    };
  };

  const writeAccess = currentUserCanAccess('cards', 'write');

  const labels = {
    status: t('cards.filterByStatus', 'Filter By Status'),
    createdAt: t('common.createdAt', 'Created At'),
    billingPlan: t('cards.billingPlan', 'Billing Plan'),
  };

  const searchRef = useRef(null);

  return (
    <Search.Provider
      onDataNeeded={onDataNeeded}
      ref={searchRef}
      filterMapping={getFilterMapping()}>
      <Breadcrumbs active={t('cards.title', 'Cards')} />

      <ListHeader title={t('cards.title', 'Cards')}>
        <Search.Export filename="Cards" />
        <Button basic as={Link} to="/cards/batch-assign">
          Batch Assign Tokens
        </Button>

        <EditCard
          onSave={() => searchRef.current.reload()}
          trigger={
            <Button
              primary
              content={t('cards.newCard', 'New Card')}
              icon="plus"
              disabled={!writeAccess}
            />
          }
        />
      </ListHeader>

      <Divider hidden />
      <Segment>
        <Layout horizontal spread stackable>
          <SearchFilters.ModalFilterV2>
            <SearchFilters.DropdownFilterV2
              name="status"
              label={labels.status}
              options={[
                {
                  text: t('cards.filterByStatusPending', 'Pending'),
                  value: 'pending',
                },
                {
                  text: t('cards.filterByStatusSent', 'Sent'),
                  value: 'sent',
                },
                {
                  text: t('cards.filterByStatusActive', 'Active'),
                  value: 'active',
                },
                {
                  text: t('cards.filterByStatusDisabled', 'Disabled'),
                  value: 'disabled',
                },
              ]}
            />
            <SearchFilters.DateRange
              label={labels.createdAt}
              name="createdAt"
            />
            <SearchFilters.DropdownSearchFilterV2
              label={t('cards.billingPlan', 'Billing Plan')}
              name="billingPlan"
              multiple={true}
              populateOnLoad={true}
              onDataNeeded={() => {
                return request({
                  method: 'POST',
                  path: '/1/billing-plans/search',
                });
              }}
            />
          </SearchFilters.ModalFilterV2>
          <Layout horizontal stackable center right>
            <Search.Total />
            <SearchFilters.Search
              name="searchPhrase"
              placeholder={t('cards.filterPlaceHolder', 'Search by Name or ID')}
            />
          </Layout>
        </Layout>
      </Segment>
      <Search.Status noResults={t('common.noResults', 'No Results')} />
      <Table />
      <div
        style={{
          textAlign: 'center',
        }}>
        <Search.Pagination />
      </div>
    </Search.Provider>
  );
}
