import { useEffect, useRef } from 'react';

const useInterval = (
  callback: (clearInterval: Function) => void,
  interval: number
): void => {
  const savedCallback = useRef<((clearInterval: Function) => void) | null>(
    null
  );

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    let id = setInterval(tick, interval);

    function tick() {
      if (savedCallback.current) {
        savedCallback.current(() => clearInterval(id));
      }
    }

    return () => clearInterval(id);
  }, [interval]);
};

export default useInterval;
