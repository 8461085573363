import { APP_NAME } from 'utils/env';
import { snakeCase } from 'lodash-es';

const KEY_PREFIX = snakeCase(APP_NAME || '');

function isStorageAvailable(type) {
  try {
    const storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

class InMemoryStorage {
  constructor() {
    this.store = new Map();
  }
  getItem(key) {
    return this.store.get(key);
  }
  setItem(key, value) {
    this.store.set(key, value);
  }
  removeItem(key) {
    this.store.delete(key);
  }
}

function createStorageWrapper(store) {
  class Storage {
    constructor(store) {
      this.store = store;
    }
    getItem(key) {
      const value = this.store.getItem(`${KEY_PREFIX}.${key}`);

      if (value) {
        let parsed;

        try {
          parsed = JSON.parse(value);
        } catch (e) {
          return null;
        }

        if (Array.isArray(parsed)) {
          const [value, ttl] = parsed;
          if (ttl < Date.now()) {
            this.store.removeItem(key);
            return null;
          }
          return value;
        }
        return value;
      }
      return null;
    }

    setItem(key, value, ttl) {
      this.store.setItem(
        `${KEY_PREFIX}.${key}`,
        ttl
          ? JSON.stringify([value, Date.now() + ttl])
          : JSON.stringify([value])
      );
    }

    removeItem(key) {
      this.store.removeItem(key);
    }
  }

  return new Storage(store);
}

export function createStorage(type) {
  return createStorageWrapper(
    isStorageAvailable(type) ? window[type] : new InMemoryStorage()
  );
}

export const storage = createStorage('localStorage');
