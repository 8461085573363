import React from 'react';

import { Dropdown } from 'semantic';

import EditProviderCertificate from 'components/modals/EditProviderCertificate';

import { useTranslation } from 'react-i18next';

import { Confirm } from 'components';
import { request } from 'utils/api';

import { currentUserCanAccess, currentUserIsSuperAdmin } from 'utils/roles';
import InspectObject from 'components/modals/InspectObject';

export default function ProviderActions({ item, onReload } = {}) {
  const { t } = useTranslation();

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/providers/${item.id}`,
    });
  }

  const writeAccess = currentUserCanAccess('providers', 'write');

  return (
    <>
      <EditProviderCertificate
        providerId={item.id}
        trigger={
          <Dropdown.Item
            text={t('editProviderCertificate.header', 'Configure Certificate')}
            icon="lock"
            disabled={!currentUserIsSuperAdmin()}
          />
        }
        onSave={onReload}
      />
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.name }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" text="Delete" disabled={!writeAccess} />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />
      <InspectObject
        name="Provider"
        data={item}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />
    </>
  );
}
