import React from 'react';

import { AppWrapper } from 'components';
import { Container } from 'semantic';
import { Route, Switch } from 'react-router-dom';
import List from './List';

export default function AccountTiers() {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path="/finance/account-tiers" component={List} exact />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
