import { useMemo, useState } from 'react';
import { request } from '../../../../utils/api';

export function useAccountOptions() {
  const [accountOptions, setAccountOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useMemo(() => {
    const requestAccountData = async () => {
      const response = await request({
        path: '/1/accounts/search',
        method: 'POST',
        body: {},
      });

      setAccountOptions(
        response.data.map((account: any) => ({
          value: account.id,
          label: account.name,
        }))
      );
    };

    requestAccountData();
  }, []);

  return accountOptions;
}
