import { useQuery } from '@tanstack/react-query';
import request from 'utils/api/request';

export default function useInvoiceEventLogs(id: string) {
  const { isLoading, isError, data, error } = useQuery({
    enabled: !!id,
    queryKey: ['invoiceLog', id],
    queryFn: () =>
      request({
        path: `/1/invoices-event-logs/${id}/events`,
        method: 'GET',
      }),
  });

  return {
    data: data?.data,
    isLoading,
    isError,
    error,
  };
}
