import React from 'react';
import UnlockConnector from 'components/modals/UnlockConnector';
import ResetEvse from 'components/modals/ResetEvse';
import { Button } from 'semantic';
import { formatDateTime } from 'utils/date';

export default class Actions extends React.Component {
  state = {
    loading: false,
    error: null,
    unlockCommand: null,
    resetCommand: null,
  };
  render() {
    const { location } = this.props;
    const { unlockCommand, resetCommand } = this.state;
    return (
      <div>
        {unlockCommand && (
          <p>
            Unlock command sent to controller at{' '}
            {formatDateTime(unlockCommand.createdAt)}
          </p>
        )}
        {resetCommand && (
          <p>
            Reset command sent to controller at{' '}
            {formatDateTime(resetCommand.createdAt)}
          </p>
        )}
        <UnlockConnector
          locationId={location.id}
          trigger={<Button basic icon="plug" content="Unlock Connector" />}
          onDone={(unlockCommand) => this.setState({ unlockCommand })}
        />
        <ResetEvse
          locationId={location.id}
          trigger={
            <Button
              basic
              icon="arrow-rotate-right"
              content="Reboot this station"
            />
          }
          onDone={(resetCommand) => this.setState({ resetCommand })}
        />
      </div>
    );
  }
}
