import { capitalize } from 'lodash-es';

export const simpleOptions = (keys, options = {}) =>
  keys.map((key) => ({
    key,
    value: key,
    text: options.upperCase
      ? key.toUpperCase()
      : capitalize(key.replaceAll('_', ' ')),
  }));

export const simpleOptionsWithIcons = (keysAndIcons) =>
  Object.keys(keysAndIcons).map((key) => ({
    key,
    value: key,
    text: capitalize(key),
    icon: keysAndIcons[key],
  }));

export function autoConvertFloat(value) {
  if (typeof value !== 'string') return value;
  if (value.match(/^[0-9]+$/)) {
    return parseFloat(value, 10);
  }
  if (value.match(/^[0-9\.]+$/)) {
    return parseFloat(value, 10);
  }
  if (value.match(/^[0-9\,]+$/)) {
    return parseFloat(value.replace(/\,/g, '.'), 10);
  }
  throw new Error(`Invalid number (use comma or dot): ${value}`);
}

export function normalizeCostSettings(costSettings) {
  return costSettings.map((setting) => {
    let {
      pricePerKwh = 0,
      pricePerSession = 0,
      pricePerHour = 0,
      pricePerIdleMinute,
      energyDeliveryArea,
    } = setting;
    pricePerKwh = autoConvertFloat(pricePerKwh);
    pricePerSession = autoConvertFloat(pricePerSession);
    pricePerHour = autoConvertFloat(pricePerHour);
    pricePerIdleMinute = autoConvertFloat(pricePerIdleMinute);
    return {
      ...setting,
      energyDeliveryArea,
      pricePerKwh,
      pricePerSession,
      pricePerHour,
      pricePerIdleMinute,
    };
  });
}
