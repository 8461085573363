import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

interface Props {
  data: Array<Record<string, any>>;
  fileName: string;
  headers?: Record<string, string>; // Maps keys to custom headers
}

const ClientCSVExporter: React.FC<Props> = ({
  data,
  fileName = 'export',
  headers,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  // Convert array of objects to CSV format
  const convertToCSV = (
    data: Array<Record<string, any>>,
    headers?: Record<string, string>
  ): string => {
    const keys = headers ? Object.keys(headers) : Object.keys(data[0] ?? {});
    const headerRow = headers
      ? Object.values(headers).join(',')
      : keys.join(',');

    const rows = data.map(
      (row) => keys.map((key) => escapeCSVString(row[key] ?? '')).join(',') // Use keys to map values
    );

    return [headerRow, ...rows].join('\n');
  };

  // Function to download the generated CSV file
  const downloadCSV = () => {
    setIsProcessing(true);

    const csv = convertToCSV(data, headers);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    document.body.appendChild(a);
    a.click(); // User interaction to trigger download
    document.body.removeChild(a);

    setIsProcessing(false);
  };

  const escapeCSVString = (str: string): string => {
    let res = str.replace(/"/g, '""');

    if (res.includes(',') || res.includes('"') || res.includes('\n')) {
      // Enclose the value in double quotes
      res = `"${res}"`;
    }

    return res;
  };

  return (
    <Button
      basic
      icon="download"
      onClick={downloadCSV}
      disabled={isProcessing}
      loading={isProcessing}
      content="Export"
    />
  );
};

export default ClientCSVExporter;
