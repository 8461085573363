import React, { useState, ReactNode } from 'react';
import { Modal } from 'semantic';
import { EnterpriseAccountMigrationProvider } from './context';
import EnterpriseAccountMigrationInputs from './Steps/Inputs';
import EnterpriseAccountMigrationConfirmation from './Steps/Confirmation';
import EnterpriseAccountMigrationCompletion from './Steps/Complete';

export default function EnterpriseAccountMigrationModal({
  trigger,
  startingStep = 'details',
  intermediateData = {},
  forceReset = false,
}: {
  trigger: ReactNode;
  startingStep?: string;
  intermediateData?: any;
  forceReset?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState((startingStep ??= 'details'));

  function resetModal() {
    setOpen(false);
    setStep('details');
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        forceReset ? resetModal() : onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <EnterpriseAccountMigrationProvider>
        {step === 'details' && (
          <EnterpriseAccountMigrationInputs
            onDone={() => setStep('confirmation')}
          />
        )}
        {step === 'confirmation' && (
          <EnterpriseAccountMigrationConfirmation
            onBack={() => setStep('details')}
            onClose={onClose}
            resetModal={resetModal}
            intermediateData={intermediateData}
          />
        )}
        {step === 'completion' && (
          <EnterpriseAccountMigrationCompletion
            onClose={onClose}
            intermediateData={intermediateData}
          />
        )}
      </EnterpriseAccountMigrationProvider>
    </Modal>
  );
}
