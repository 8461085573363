import React, { useContext, useState } from 'react';

import { getToken } from 'utils/api';
import { parseToken } from 'utils/token';

const SandboxContext = React.createContext({});

const SandboxProvider = ({ children }) => {
  let initialSandboxMode = false;

  const token = getToken();
  if (token) {
    const parsed = parseToken(token);
    if (parsed?.sandboxMode) initialSandboxMode = true;
  }

  const [sandboxMode, setSandboxMode] = useState(initialSandboxMode);

  return (
    <SandboxContext.Provider
      value={{
        sandboxMode,
        setSandboxMode,
      }}>
      {children}
    </SandboxContext.Provider>
  );
};

const useSandbox = () => {
  return useContext(SandboxContext);
};

export { SandboxProvider, useSandbox };
