import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.less';
import { Header } from 'semantic';

export default function Box({
  title,
  children,
  condensed = false,
}: {
  title?: string;
  children?: React.ReactNode;
  condensed?: boolean;
}) {
  return (
    <div className={styles.wrapper + ' ' + (condensed ? styles.condensed : '')}>
      <div className={styles.header}>
        {/*  Probably header.container? */}
        {title && <Header as="h3">{title}</Header>}
      </div>
      <div className={styles.container}>{children}</div>
    </div>
  );
}

Box.Header = function BoxHeader({ children }: { children?: React.ReactNode }) {
  return <div className={styles.headerContainer}>{children}</div>;
};

Box.Content = function BoxContent({
  children,
}: {
  children?: React.ReactNode;
}) {
  return <div className={styles.contentContainer}>{children}</div>;
};

Box.Actions = function BoxActions({
  children,
}: {
  children?: React.ReactNode;
}) {
  return <div className={styles.actionContainer}>{children}</div>;
};

Box.ExternalActionLink = function BoxActionLink({
  href,
  children,
}: {
  href: string;
  to?: string;
  children?: React.ReactNode;
}) {
  return (
    <a href={href} rel="noopener" target="_blank" className={styles.actionLink}>
      {children}
    </a>
  );
};

Box.ActionLink = function BoxActionLink({
  children,
  to = '',
}: {
  to: string;
  children?: React.ReactNode;
}) {
  return (
    <Link to={to} className={styles.actionLink}>
      {children}
    </Link>
  );
};
