import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const usePersonalReimbursementValidation = (
  isBicCollectionEnabled: boolean
) => {
  const { t } = useTranslation();
  const shape = {
    accountHolderName: string().required(
      t(
        'settings.personal.billing.validations.accountHolderName',
        'Please enter your account holder name'
      )
    ),
    ibanNo: string().required(
      t(
        'settings.personal.billing.validations.ibanNo',
        'Please enter your IBAN number'
      )
    ),
    bicNo: string().nullable(),
    countryCode: string().required(
      t(
        'settings.personal.billing.validations.countryCode',
        'Please enter your country code'
      )
    ),
    address: string().required(
      t(
        'settings.personal.billing.validations.address',
        'Please enter your address'
      )
    ),
    addressLine2: string().required(
      t(
        'settings.personal.billing.validations.addressLine2',
        'Please enter your street number'
      )
    ),
    postalCode: string().required(
      t(
        'settings.personal.billing.validations.postalCode',
        'Please enter your postal code'
      )
    ),
    city: string().required(
      t('settings.personal.billing.validations.city', 'Please enter your city')
    ),
  };

  if (isBicCollectionEnabled) {
    shape.bicNo = string().required(
      t(
        'settings.personal.billing.validations.bicNo',
        'Please enter your BIC number'
      )
    );
  }

  return object().shape(shape);
};
