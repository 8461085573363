import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message, Grid } from 'semantic';
import { useUser } from '../../../../../contexts/user';

export default function PersonalBillingPreview() {
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <Message color="grey">
      <h4>
        {t(
          'settings.personal.billing.preview.header',
          'Preview your personal billing information'
        )}
      </h4>
      <Grid>
        <Grid.Column width={8}>
          <h5>
            {t(
              'settings.personal.billing.headers.paymentInfo',
              'Payment Information'
            )}
          </h5>
          <p>
            <strong>
              {t(
                'settings.personal.billing.preview.paymentMethod',
                'Payment Method'
              )}
              :{' '}
            </strong>
            {user.billing.paymentMethod}
          </p>
          <p>
            <strong>
              {t(
                'settings.personal.billing.preview.accountHolderName',
                'Account Holder Name'
              )}
              :{' '}
            </strong>
            {user.billing.accountHolderName}
          </p>
          <p>
            <strong>
              {t('settings.personal.billing.preview.ibanNo', 'IBAN')}:{' '}
            </strong>
            {user.billing.ibanNo}
          </p>
        </Grid.Column>
        <Grid.Column width={8}>
          <h5>
            {t(
              'settings.personal.billing.headers.addressInfo',
              'Billing Address'
            )}
          </h5>
          <p>
            <strong>
              {t('settings.personal.billing.preview.country', 'Country')}:{' '}
            </strong>
            {user.billing.countryCode}
          </p>
          <p>
            <strong>
              {t('settings.personal.billing.preview.address', 'Address')}:{' '}
            </strong>
            {user.billing.address}
          </p>
          <p>
            <strong>
              {t('settings.personal.billing.preview.addressLine2', 'Address')}:{' '}
            </strong>
            {user.billing.addressLine2}
          </p>
          <p>
            <strong>
              {t('settings.personal.billing.preview.postalCode', 'Postal Code')}
              :{' '}
            </strong>
            {user.billing.postalCode}
          </p>
          <p>
            <strong>
              {t('settings.personal.billing.preview.city', 'City')}:{' '}
            </strong>
            {user.billing.city}
          </p>
        </Grid.Column>
      </Grid>
    </Message>
  );
}
