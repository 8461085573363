import { Table, Label, Message, Loader } from 'semantic';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { request } from 'utils/api';
import { numberWithDots } from 'utils/formatting';
import { fromNow } from 'utils/date';

export default function LocationsSources() {
  const [aggregatedItems, setAggregatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();

  const onDataNeeded = async () => {
    const { data: sources } = await request({
      method: 'GET',
      path: '/2/locations/msp/sources',
    });

    const operations = sources.map(async (source) => {
      const { data: stats } = await request({
        method: 'POST',
        path: `/2/locations/msp/stats`,
        body: { source },
      }).catch(() =>
        setErrors((prev) => [
          ...prev,
          `Failed to fetch stats for: ${new URL(source).hostname}`,
        ])
      );

      setAggregatedItems((prev) => [...prev, { id: source, ...stats }]);
    });

    await Promise.allSettled(operations);
    setLoading(false);
  };

  useEffect(() => {
    (async () => await onDataNeeded())();
  }, []);

  return (
    <div>
      <Loader inline="centered" active={loading} />
      {!!errors.length && <Message error content={errors.join('\n')} />}

      {aggregatedItems.length !== 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t('locationSources.columnSources', 'Source')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnName', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnPrimary', 'Primary')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnTariffs', 'Tariffs')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnAvailable', 'Available')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnNotAvailable', 'Not Available')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnUnknown', 'Unknown')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnTotal', 'Total')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnEvses', 'Evses')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnLastUpdate', 'Last Update')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('locationSources.columnLastNew', 'Last New')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aggregatedItems.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <Label content={item.source} />
                </Table.Cell>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>
                  {item.source === 'eclearing'
                    ? '-'
                    : item.isPrimarySource
                      ? 'Yes'
                      : 'No'}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numberWithDots(item.hasTariffInfo)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numberWithDots(item.majorStatusAvailable)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numberWithDots(item.majorStatusInoperative)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numberWithDots(item.majorStatusUnknown)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numberWithDots(item.total)}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {numberWithDots(item.totalEvses)}
                </Table.Cell>
                <Table.Cell>
                  {fromNow(Date.parse(new Date(item.lastUpdatedTs).toString()))}
                </Table.Cell>
                <Table.Cell>
                  {fromNow(Date.parse(new Date(item.lastCreatedTs).toString()))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}
