import React from 'react';
import PropTypes from 'prop-types';
import DateInput from '../DateInput';
import { Form, Input, Icon } from 'semantic';
import { format } from 'date-fns';
import { formatDate } from 'utils/date';
import { Layout } from '../Layout';

export default class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeSelectedAtLeastOnce: false,
    };
  }

  setDate(evt, date) {
    this.props.onChange(evt, {
      ...this.props,
      value: date,
    });
  }

  onDayChange = (date) => {
    if (!this.props.time || !this.state.timeSelectedAtLeastOnce) {
      if (this.props.end) {
        date.setHours(23);
        date.setMinutes(59, 59, 999);
      } else if (this.props.start) {
        date.setHours(0);
        date.setMinutes(0, 0, 0);
      }
    }
    this.setDate(null, date);
  };

  onTimeChanged = (evt, { value }) => {
    let { value: date } = this.props;
    this.setState({ timeSelectedAtLeastOnce: true });

    if (date) {
      date = new Date(date);
      const [hours, minutes] = value.split(':');
      date.setHours(hours);
      date.setMinutes(minutes);
      this.setDate(evt, date);
    }
  };

  formatDate(date) {
    return formatDate(date);
  }

  render() {
    const {
      fromDate,
      time,
      required,
      label,
      placeholder,
      clearable,
      id,
      fullWidth,
      description,
    } = this.props;
    let { value: date } = this.props;
    if (date && typeof date === 'string') {
      date = new Date(date);
    }
    const timeValue = date ? format(date, 'HH:mm') : '';

    return (
      <Form.Field required={required}>
        {label && <label htmlFor={id}>{label}</label>}
        {description && (
          <div style={{ paddingBottom: 10, fontSize: 12, color: '#696969' }}>
            <em>{description}</em>
          </div>
        )}
        <Layout horizontal center>
          <Layout.Group style={{ position: 'relative' }} grow={fullWidth}>
            <DateInput
              id={id}
              value={date}
              placeholder={placeholder}
              fromDate={fromDate}
              formatDate={this.formatDate}
              style={{
                width: '140px',
              }}
              onChange={this.onDayChange}
            />
            {clearable && (
              <Icon
                name="close"
                color="grey"
                style={{
                  position: 'absolute',
                  top: '12px',
                  right: '5px',
                  cursor: 'pointer',
                  visibility: date ? 'visible' : 'hidden',
                }}
                onClick={() => this.setDate(null)}
              />
            )}
          </Layout.Group>
          {time && (
            <Input
              type="time"
              style={{ height: 38, paddingLeft: 10 }}
              value={timeValue}
              onChange={this.onTimeChanged}
            />
          )}
        </Layout>
      </Form.Field>
    );
  }
}

DateField.propTypes = {
  time: PropTypes.bool,
  label: PropTypes.node,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

DateField.defaultProps = {
  time: false,
  required: false,
  clearable: false,
};
