import React, { ReactNode, CSSProperties, Children } from 'react';

interface CenterProps {
  /**
   * Determines whether the children should be centered using inline-flex or flex display.
   * inline-flex centers content within a row while behaving like an inline-level element,
   * whereas flex centers content within a column and creates a block-level container.
   */
  inline?: boolean;

  /**
   * The content to be centered.
   */
  children: ReactNode;
  /**
   * The style to be applied to the center container.
   */
  style?: CSSProperties;
}

/**
 * A component that horizontally and vertically centers its children.
 *
 * @component
 * @param {CenterProps} props - The props for the Center component.
 * @param {boolean} props.inline - Determines whether to use inline-flex or flex display for centering.
 * @param {ReactNode} props.children - The content to be centered.
 * @returns {JSX.Element} The centered content.
 * @example
 * // Basic usage
 * <Center>
 *   <div>Centered Content</div>
 * </Center>
 *
 * // Usage with inline display
 * <Center inline>
 *   <button>Centered Button</button>
 * </Center>
 */
export default function Center({ inline, style, children }: CenterProps) {
  const centerStyle: CSSProperties = {
    display: inline ? 'inline-flex' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...style,
  };

  return (
    <div style={centerStyle}>
      {Children.map(children, (child) => (
        <div>{child}</div>
      ))}
    </div>
  );
}
