import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Search } from 'components';
import { Table, Label, Button } from 'semantic';

import ResourceLink from 'components/Link/ResourceLink';
import Pair from './Pair';
import { EvseControllerIntegrations } from 'types/evse-controller-integrations';
import useUserHasAccessToEvseControllersIntegrations from '../useUserHasAccessToEvseControllersIntegrations';
import { useUser } from 'contexts/user';

type Item = {
  id: string;
  ocppIdentity: string;
  evseId: string;
  location: {
    id: string;
    name: string;
    userId: string;
  };
  integrations?: EvseControllerIntegrations;
};

export default function IntegrationsTable() {
  const { t } = useTranslation();
  const { items = [], loading, reload } = useContext(Search.Context);
  const { user } = useUser();
  const userHasAccessToEvseControllersIntegrations =
    useUserHasAccessToEvseControllersIntegrations('read-write');

  const onSave = () => {
    reload();
  };

  if (!items.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            {t('integrations.columnLocation', 'Location')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('integrations.columnEvseId', 'EVSE ID')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('integrations.columnOcppIdentity', 'OCPP Identity')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('integrations.columnIntegration', 'Integration')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('integrations.columnActions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item: Item) => {
          const integrations = item?.integrations?.csmePairings || [];
          const hasIntegrations = integrations.length > 0;

          const externalLinkLocationsRoute = `/charging-stations/locations/${item?.location.id}`;
          const externalLinkLocationName =
            item?.location.name || t('integrations.locationNoName', 'No Name');

          return (
            <Table.Row key={item.id}>
              <Table.Cell>
                <ResourceLink
                  route={externalLinkLocationsRoute}
                  linkName={externalLinkLocationName}
                />
              </Table.Cell>
              <Table.Cell>{item.evseId}</Table.Cell>
              <Table.Cell>{item.ocppIdentity}</Table.Cell>
              <Table.Cell>
                {!hasIntegrations && (
                  <Label>{t('integrations.noIntegration', 'None')}</Label>
                )}
                {hasIntegrations &&
                  integrations.map((integration) => (
                    <Label key={integration.id} color="black">
                      {integration.csmeClient.name}
                    </Label>
                  ))}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {(userHasAccessToEvseControllersIntegrations ||
                  item?.location?.userId === user.id) && (
                  <Pair
                    size="tiny"
                    item={item}
                    onSave={onSave}
                    trigger={
                      <Button
                        basic
                        title={
                          hasIntegrations
                            ? t('integrations.buttonEdit', 'Edit')
                            : t('integrations.buttonPair', 'Pair')
                        }
                        icon={hasIntegrations ? null : 'handshake'}
                        content={
                          hasIntegrations
                            ? t('integrations.buttonEdit', 'Edit')
                            : t('integrations.buttonPair', 'Pair')
                        }
                        as="button"
                      />
                    }
                  />
                )}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
