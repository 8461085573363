import React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'semantic';

export default function Search({ value = '', onChange, placeholder }) {
  const [search, setSearch] = useState(value);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  return (
    <Form onSubmit={() => onChange(search)}>
      <Form.Input
        value={search}
        name="search"
        placeholder={placeholder}
        icon={{
          name: search ? 'close' : 'magnifying-glass',
          link: true,
          onClick: (evt) => {
            if (search) {
              onChange(undefined);
              setSearch('');
              evt.currentTarget.parentNode.querySelector('input').focus();
            }
          },
        }}
        type="text"
        onChange={(e, { value }) => setSearch(value.length ? value : undefined)}
      />
    </Form>
  );
}
