import React from 'react';
import { Route } from 'react-router-dom';
import BootPublic from 'components/BootPublic';

export default class Public extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <BootPublic>
              <Component {...props} />
            </BootPublic>
          );
        }}
      />
    );
  }
}
