import React, { useEffect, useState } from 'react';
import { Input, Popup } from 'semantic';
import { currencyToSymbol } from 'utils/formatting';
import { useTranslation } from 'react-i18next';

interface PriceInputProps {
  value?: number;
  onChange?: (value: number) => void;
  readOnly?: boolean | undefined;
  currency?: string;
  scalar?: number;
  disabled?: boolean;
}

export default function PriceInput({
  value = 0,
  onChange,
  readOnly,
  currency = 'EUR',
  scalar = 1,
  disabled = false,
}: PriceInputProps) {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>(
    priceToString(value, currency, scalar)
  );
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    setAmount(priceToString(value, currency, scalar));
  }, [value]);

  const onBlur = () => {
    const nextAmount = parsePriceString(amount, scalar);
    if (isNaN(nextAmount) || amount.includes('e')) {
      setError('invalid price');
      return;
    }
    if (nextAmount < 0) {
      setError('price cannot be negative');
      return;
    }
    setError(null);
    onChange?.(parsePriceString(amount, scalar));
  };

  if (disabled) {
    return (
      <Popup
        position={'bottom right'}
        trigger={
          <div>
            <Input
              label={currencyToSymbol(currency)}
              fluid
              disabled={disabled}
              readOnly={readOnly}
              value={amount}
              error={!!error}
              onChange={(e) => setAmount(e.target.value)}
              onBlur={() => onBlur()}
            />
          </div>
        }
        disabled={false}
        content={t(
          'tariffs.advancedTariffs.disabled',
          'This tariff is unavailable'
        )}
      />
    );
  }

  return (
    <Input
      label={currencyToSymbol(currency)}
      fluid
      disabled={disabled}
      readOnly={readOnly}
      value={amount}
      error={!!error}
      onChange={(e) => setAmount(e.target.value)}
      onBlur={() => onBlur()}
    />
  );
}

function parsePriceString(value = '', scalar = 1) {
  return Number(value.replace(',', '.')) / scalar;
}

function priceToString(value = 0, currency = 'EUR', scalar = 1) {
  // TODO currency formatters
  return Number(value * scalar)
    .toString()
    .replace('.', ',');
}
