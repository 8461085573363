import React, { useState, useEffect } from 'react';
import { Header, Table, Loader, Message, Divider } from 'semantic';
import { request } from 'utils/api';
import OcpiStats from 'components/OcpiStats';
import CopyValue from 'components/CopyValue';
import { formatDateTime } from 'utils/date';

export default function Overview({ ocpiCredential }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const loadStats = async () => {
      setLoading(true);
      setError(null);

      try {
        const { data } = await request({
          method: 'GET',
          path: `/1/ocpi-credentials/${ocpiCredential.id}/stats`,
        });
        setStats(data);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    loadStats();
  }, [ocpiCredential]);

  const renderStatistics = () => {
    if (error) return <Message error content={error.message} />;
    if (loading || !stats) return <Loader active inline />;
    return <OcpiStats stats={stats} ocpiCredential={ocpiCredential} />;
  };

  return (
    <div>
      <Header as="h3">Statistics</Header>
      {renderStatistics()}
      <Divider hidden />
      <Header as="h3">Credentials</Header>
      <div>
        <CopyValue label="Token A" value={ocpiCredential.tokenA} />
        <CopyValue label="Token B" value={ocpiCredential.tokenB} />
        <CopyValue label="Token C" value={ocpiCredential.tokenC} />
      </div>
      <Divider hidden />
      <Header as="h3">Details</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>ID</Table.Cell>
            <Table.Cell>{ocpiCredential.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell>{ocpiCredential.name}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Context</Table.Cell>
            <Table.Cell>{ocpiCredential.providerContext}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>{ocpiCredential.type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Account</Table.Cell>
            <Table.Cell>
              {ocpiCredential.account ? ocpiCredential.account.name : '-'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Versions URL</Table.Cell>
            <Table.Cell>{ocpiCredential.versionsUrl}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Created At</Table.Cell>
            <Table.Cell>{formatDateTime(ocpiCredential.createdAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Updated At</Table.Cell>
            <Table.Cell>{formatDateTime(ocpiCredential.updatedAt)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}
