import React from 'react';
import { request } from 'utils/api';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { Button, Container, Divider, Grid, Header, Segment } from 'semantic';
import Filters from './Filters';
import DataList from 'components/DataList';
import Table from './Table';

const itemLimit = 100;

export default class EvseIds extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  handleDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/evse-ids/assignment-request/${item.id}`,
    }).then(() => this.refresh());
  };

  handleRequest = (appliedFilters = {}) => {
    const { searchId, filters } = this.state;
    return request({
      method: 'POST',
      path: '/1/evse-ids/assignment-request/search',
      body: {
        ...appliedFilters,
        ...filters,
        limit: itemLimit,
      },
    });
  };

  handleFilterChange = (filters) => {
    this.setState(
      {
        filters: filters.reduce(
          (result, item) => ({
            ...result,
            ...(item.apiFilter || {}),
          }),
          {}
        ),
      },
      () => this.refresh()
    );
  };

  handleOnSearch = (searchId) => {
    this.setState({ searchId }, () => this.refresh());
  };

  refresh() {
    this.setState({ dataKey: Date.now() });
  }

  render() {
    const writeAccess = currentUserCanAccessProviderEndpoint(
      'evseIds',
      'write'
    );
    return (
      <Container>
        <Grid reversed="computer">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            textAlign="right"
            verticalAlign="middle"></Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Header as="h2" style={{ margin: '0' }}>
              EVSE IDs Assignment Requests
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />

        <Segment>
          <Grid>
            <Grid.Row width={16}>
              <Grid.Column width={10}>
                <Filters onChange={this.handleFilterChange} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <p>
          These are requests made by customers either during EVSE activation or
          later - for EVSEs listed below the customer has requested a sticker to
          be sent to them.
        </p>

        <DataList
          key={this.state.dataKey}
          limit={itemLimit}
          onRequest={this.handleRequest}>
          <Table
            onRefresh={() => this.refresh()}
            onDeleteItem={this.handleDeleteItem}
          />
        </DataList>
        <Divider hidden />
      </Container>
    );
  }
}
