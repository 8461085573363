import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Button, Form, Icon } from 'semantic';
import SearchContext, { useFilterContext } from 'components/search/Context';
import AutoFocus from 'components/AutoFocus';
import './modal.less';
import WrappedModal from 'components/modal/WrappedModal';
import { TFunction, useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactNode;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  useAutoFocus?: boolean;
};

const getFiltersMessage = (filters: { [key: string]: any }, t: TFunction) => {
  let filtersCount = 0;
  for (const filterKey in filters) {
    const filterItem = filters[filterKey];
    if (Array.isArray(filterItem)) {
      filtersCount += filterItem.length;
    } else {
      filtersCount += 1;
    }
  }

  if (filtersCount > 0) {
    return t(`common.filtersSelectedCount`, {
      defaultValue: `{{filtersCount}} Filters Selected`,
      filtersCount,
    });
  } else {
    return undefined;
  }
};

export const ModalFilterV2 = ({
  size = 'small',
  children,
  useAutoFocus = true,
}: Props) => {
  const filterContext = useFilterContext();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(filterContext.filters);

  // Just to rerender when the filters change
  useEffect(() => {
    setFilters(filterContext.filters);
  }, [filterContext.filters]);

  const onSubmit = () => {
    filterContext.setFilters(filters);
    setOpen(false);
  };

  const onFilterChange = ({ name, value }: { name: string; value: any }) => {
    setFilters({ ...filters, [name]: value });
  };

  const onReset = () => {
    setFilters({});
  };

  const filterMessage = getFiltersMessage(filters, t);

  return (
    <div className="search-filters-modal">
      <Button
        basic
        primary
        onClick={() => {
          setOpen(true);
        }}>
        <Icon name="filter" />
        {t('common.filter', 'Filter')}
      </Button>
      {filterMessage && (
        <span style={{ padding: '1rem', color: '#5D5D5D' }}>
          {filterMessage}
        </span>
      )}
      {Object.keys(filters).length > 0 && (
        <button
          className="borderless-blue-button"
          onClick={() => {
            filterContext.setFilters({});
            filterContext.reload();
          }}>
          {t('common.clearFilters', 'Clear All Filters')}
        </button>
      )}
      <WrappedModal isOpen={open} setIsOpen={setOpen} size={size}>
        <Modal.Header>Filter</Modal.Header>
        <Modal.Content>
          <Form id="filters" onSubmit={onSubmit}>
            <SearchContext.Provider
              value={{
                ...filterContext,
                filters: filters,
                onFilterChange: onFilterChange,
              }}>
              {useAutoFocus ? <AutoFocus>{children}</AutoFocus> : children}
            </SearchContext.Provider>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content={t('common.reset', 'Reset')}
            onClick={onReset}
          />
          <Button primary form="filters" content={t('common.apply', 'Apply')} />
        </Modal.Actions>
      </WrappedModal>
    </div>
  );
};
