import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default class TariffNotes extends React.Component {
  render() {
    return (
      <AppWrapper>
        <Container>
          <Switch>
            <Route path="/credentials/tariff-notes" component={List} exact />
          </Switch>
        </Container>
      </AppWrapper>
    );
  }
}
