import React from 'react';
import { Table, Button, Label } from 'semantic';
import * as Search from 'components/search';
import { formatDateTime } from '../../../../utils/date';
import { withTranslation } from 'react-i18next';
import { Text } from '../StyledComponents';
import ViewSyncLog from 'components/modals/ViewSyncLog';

class SyncLogsTable extends React.Component {
  static contextType = Search.Context;

  render() {
    const { t } = this.props;
    const { items, loading } = this.context;

    if (!items.length || loading) return null;

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>
                {t('syncStatusModal.logs.type', 'Type')}
              </Table.HeaderCell>
              <Table.HeaderCell width={11}>
                {t('syncStatusModal.logs.message', 'Message')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('syncStatusModal.logs.time', 'Time')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('syncStatusModal.logs.actions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Label
                      content={item.type}
                      color={item.type === 'error' ? 'red' : undefined}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{item.message}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <Text>{formatDateTime(item.createdAt)}</Text>
                  </Table.Cell>
                  <Table.Cell>
                    <ViewSyncLog
                      initialValues={item}
                      trigger={<Button basic icon="search" />}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(SyncLogsTable);
