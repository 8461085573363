import React from 'react';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { Checkbox, Form, Input } from 'semantic';
import PriceInput from 'components/form-fields/Price';
import { useTranslation } from 'react-i18next';

type PricePerIdleMinuteProps = {
  enableIdleFee: boolean;
  setEnableIdleFee: (enableIdleFee: boolean) => void;
  pricePerIdleMinute: number;
  setPricePerIdleMinute: (pricePerIdleMinute: number) => void;
  idleGracePeriodMinutes: number;
  setIdleGracePeriodMinutes: (idleGracePeriodMinutes: number) => void;
  currency: string;
};

export default function PricePerIdleMinute({
  enableIdleFee,
  setEnableIdleFee,
  pricePerIdleMinute,
  setPricePerIdleMinute,
  idleGracePeriodMinutes,
  setIdleGracePeriodMinutes,
  currency,
}: PricePerIdleMinuteProps) {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  if (!advancedTariffFeatures.idleFee) {
    return <></>;
  }

  return (
    <>
      <Form.Field>
        <Checkbox
          toggle
          label={
            <label style={{ fontWeight: 'bold' }}>
              {t(
                'advancedTariff.enableIdleFee',
                'Charge an idle fee after a vehicle has finished charging'
              )}
            </label>
          }
          checked={enableIdleFee}
          onChange={(e, { checked }) => setEnableIdleFee(checked)}
        />
      </Form.Field>

      <Form.Group widths={'equal'}>
        <Form.Field disabled={!enableIdleFee}>
          <label>
            {t('advancedTariff.idleFeePerMinute', 'Idle Fee per minute')}
          </label>
          <PriceInput
            value={pricePerIdleMinute}
            onChange={setPricePerIdleMinute}
            currency={currency}
          />
          <i style={{ fontSize: 12, float: 'left', marginTop: 6 }}>
            {t(
              'advancedTariff.timeCapMessage',
              '*Maximum cost may be applied per session according to roaming contracts'
            )}
          </i>
        </Form.Field>
        <Form.Field disabled={!enableIdleFee}>
          <label>
            {t('advancedTariff.idleFeeWaitTime', 'Wait time before idle fee')}
          </label>
          <Input
            label={t('advancedTariff.minutes', 'minutes')}
            fluid
            value={idleGracePeriodMinutes}
            onChange={(e, { value }) => {
              if (!isNaN(+value)) {
                setIdleGracePeriodMinutes(+value);
              }
            }}
          />
        </Form.Field>
        <Form.Field />
      </Form.Group>
    </>
  );
}
