import React, { createContext, useEffect, useReducer, useContext } from 'react';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import { startOfDay, sub, endOfDay } from 'date-fns';

import { canAccess } from 'utils/roles';

function useLocalState(initialState) {
  return useReducer((currentState, updatedState) => {
    return { ...currentState, ...updatedState };
  }, initialState);
}

export const DashboardContext = createContext({});

function getRange() {
  const from = sub(new Date(), { days: 30 });
  return {
    from: startOfDay(from).toISOString(),
    to: endOfDay(new Date()).toISOString(),
  };
}

async function fetchAccountSummary() {
  const { data } = await request({
    method: 'GET',
    path: '/1/accounts/self/summary',
    params: getRange(),
  });

  return {
    hasCards: data.msp.cards > 0,
    hasLocations: data.cpo.locations > 0,
    ...data,
  };
}

async function fetchUserSummary() {
  const { data } = await request({
    method: 'GET',
    path: '/1/users/me/summary',
    params: getRange(),
  });
  return {
    hasCards: data.msp.cards > 0,
    hasLocations: data.cpo.locations > 0,
    ...data,
  };
}

export const DashboardProvider = ({ children }) => {
  const { user } = useUser();

  const canManagedUser = canAccess(
    user,
    user.providerId,
    user.accountId,
    'users',
    'write'
  );

  const [state, setState] = useLocalState({
    canManagedUser: canManagedUser,
    isAccount: canManagedUser,
    loading: true,
    hasCards: false,
    hasLocations: false,
    cpo: {},
    msp: {},
  });

  useEffect(() => {
    if (user) {
      fetchData(state.isAccount ? 'account' : 'user');
    }
  }, [user]);

  async function fetchData(type) {
    setState({ loading: true });
    try {
      const data =
        type === 'account'
          ? await fetchAccountSummary()
          : await fetchUserSummary();

      setState({ loading: false, ...data });
    } catch (error) {
      setState({ loading: false, error });
    }
  }

  function toggleAccount() {
    const isAccount = !state.isAccount;
    setState({ isAccount });
    fetchData(isAccount ? 'account' : 'user');
  }

  return (
    <DashboardContext.Provider
      value={{
        ...state,
        toggleAccount,
      }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  return context;
};
