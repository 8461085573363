import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Divider } from 'semantic';

import Table from './Table';
import { ListHeader, Breadcrumbs, Search } from 'components';
import { request } from 'utils/api';

export default function MyInvoices() {
  const { t } = useTranslation();

  function onDataNeeded(filters) {
    return request({
      method: 'POST',
      path: '/1/invoices/mine/credit',
      body: filters,
    });
  }

  return (
    <Search.Provider onDataNeeded={onDataNeeded}>
      <Breadcrumbs
        path={[
          <Link key="mylocations" to="/my-locations">
            {t('myLocations.header', 'My Locations')}
          </Link>,
        ]}
        active={t('myCreditInvoices.title', 'My Credit Invoices')}
      />
      <ListHeader title={t('myCreditInvoices.title', 'My Credit Invoices')} />

      <Search.Status
        noItems={t('invoices.messageNoInvoices', 'No invoices generated yet')}
      />
      <Table />
      <Divider hidden />
      <div
        style={{
          textAlign: 'center',
        }}>
        <Search.Pagination />
      </div>
    </Search.Provider>
  );
}
