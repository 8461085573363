import React from 'react';

import { Segment, Header, List, Icon, Divider } from 'semantic';
import { useTranslation } from 'react-i18next';

import { useUser } from 'contexts/user';
import { INTERCOM_APP_ID } from 'utils/env';

import styled from 'styled-components';

const SideBar = styled.div`
  .lines {
    padding: 0;
    margin-top: 0px;
    color: #52bf9b;
    font-weight: bold;
    list-style-type: none;
    font-size: 12px;
  }
  .order {
    padding: 0;
    list-style-type: none;
    font-weight: bold;
    font-size: 12px;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .big {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
`;

export default () => {
  const { t } = useTranslation();

  const { provider } = useUser();

  React.useEffect(() => {
    if (provider.slug === 'e-flux') {
      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
    }
  }, [provider]);

  return (
    <SideBar>
      <Segment>
        <Header>{t('signupSidebar.features', 'Features')}</Header>
        <Divider />
        <ul className="lines">
          <li>
            <Icon name="check" />
            {t('signupSidebarWithoutPlan.promoLine1', 'Location management')}
          </li>
          <li>
            <Icon name="check" />
            {t('signupSidebarWithoutPlan.promoLine2', 'Custom price settings')}
          </li>

          <li>
            <Icon name="check" />
            {t('signupSidebarWithoutPlan.promoLine3', 'Access Control')}
          </li>
          <li>
            {' '}
            <Icon name="check" />
            {t('signupSidebarWithoutPlan.promoLine4', '24/7 helpdesk')}
          </li>
        </ul>
      </Segment>

      {provider.slug !== 'e-flux' && (
        <Segment>
          <Header>{t('signupSidebar.anyProblems', 'Need Help')}</Header>

          <List>
            <List.Item>{t('signupSidebar.supportPhoneNo')}</List.Item>
            <List.Item as="a" href={`tel:${provider.supportPhoneNo}`}>
              {provider.supportPhoneNo}
            </List.Item>
            <List.Item>{t('signupSidebar.supportEmail')}</List.Item>
            <List.Item as="a" href={`mailto:${provider.supportEmail}`}>
              {provider.supportEmail}
            </List.Item>
          </List>
        </Segment>
      )}
    </SideBar>
  );
};
