import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Modal, Form, Label, Icon, Grid, Checkbox, Button } from 'semantic';
import 'react-datepicker/dist/react-datepicker.css';

import { startOfMonth, endOfMonth } from 'date-fns';

export default function Filters(props) {
  const [useAllTime, setUseAllTime] = useState(true);
  const [ocpiCredentialsName, setOcpiCredentialsName] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(startOfMonth(new Date()));
  const [to, setTo] = useState(endOfMonth(new Date()));
  const [appliedFilters, setAppliedFilters] = useState([]);

  const computeFilters = () => {
    const filters = [];

    if (!useAllTime) {
      filters.push({
        field: 'dateRange',
        value: {
          from,
          to,
        },
        apiFilter: {
          from,
          to,
        },
        label: `Date Range ${from.toLocaleDateString()}-${to.toLocaleDateString()}`,
      });
    }

    if (ocpiCredentialsName) {
      filters.push({
        field: 'ocpiCredentialsName',
        value: ocpiCredentialsName,
        apiFilter: {
          ocpiCredentialsName,
        },
        label: `OCPI Provider: ${ocpiCredentialsName}`,
      });
    }

    return filters;
  };

  const handleApply = () => {
    const appliedFilters = computeFilters();
    props.onChange(appliedFilters);
    setAppliedFilters(appliedFilters);
    close();
  };

  const handleReset = () => {
    props.onChange([]);
    setAppliedFilters([]);
    close();
  };

  const handleRemoveFilter = (filter) => {
    const updatedFilters = appliedFilters.filter(
      (c) => c.field !== filter.field
    );
    props.onChange(updatedFilters);
    setAppliedFilters(updatedFilters);
    close();
  };

  const close = () => {
    setUseAllTime(true);
    setOcpiCredentialsName(undefined);
    setOpen(false);
    setFrom(startOfMonth(new Date()));
    setTo(endOfMonth(new Date()));
  };

  const handleAllTimeChange = (e, { checked }) => {
    setUseAllTime(checked);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Modal
        size="tiny"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <Button basic primary={true} floated="left">
            <Icon name="filter" /> Filters
          </Button>
        }
        closeIcon>
        <Modal.Header>Filters</Modal.Header>
        <Modal.Content>
          <Form>
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <Checkbox
                      onChange={handleAllTimeChange}
                      checked={useAllTime}
                      label="All Time"
                      toggle
                    />
                  </Form.Field>
                  <div onClick={() => setUseAllTime(false)}>
                    <Form.Field disabled={useAllTime}>
                      <label>From</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={from}
                        onChange={(date) => setFrom(date)}
                        selectsStart
                        startDate={from}
                        endDate={to}
                      />
                    </Form.Field>
                    <Form.Field disabled={useAllTime}>
                      <label>To</label>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={to || new Date()}
                        onChange={(date) => setTo(date)}
                        selectsEnd
                        startDate={from}
                        endDate={to}
                        minDate={from}
                      />
                    </Form.Field>
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field>
                    <Form.Input
                      onChange={(e, { value }) => {
                        setOcpiCredentialsName(value);
                      }}
                      toggle
                      checked={ocpiCredentialsName}
                      label="OCPI Credential Name"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic content="Reset" onClick={handleReset} />
          <Button primary icon="check" content="Apply" onClick={handleApply} />
        </Modal.Actions>
      </Modal>

      <div style={{ marginLeft: '10px' }}>
        {appliedFilters.map((filter) => (
          <Label
            key={filter.field}
            size="large"
            style={{ marginLeft: '10px', lineHeight: '20px' }}>
            {filter.label}
            <Icon name="xmark" onClick={() => handleRemoveFilter(filter)} />
          </Label>
        ))}
      </div>
    </div>
  );
}
