import { useEffect, useState } from 'react';
import { request } from 'utils/api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { countries } from 'eflux-pkg-js';
import { EvseController } from 'components/modals/EditEvseController/types';

/**
 * We identify the currency in which the prices on this charge station are applied in based on the country of the
 * location.
 */
export default function useCurrency(
  evseController: EvseController,
  onError?: (e) => void
) {
  const [currency, setCurrency] = useState<string>(null);

  useEffect(() => {
    if (!evseController.locationId) {
      if (currency) {
        setCurrency(null);
      }
      return;
    }

    (async () => {
      const { data } = await request({
        path: `/2/locations/cpo/${evseController.locationId}`,
        method: 'GET',
      });
      setCurrency(countries.getCurrencyByAlpha3(data?.country));
    })().catch((error) => onError?.(error));
  }, [evseController.locationId]);

  return currency;
}
