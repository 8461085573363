import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useBulkEditEvseControllersErrorFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (failedCondition: { code: string; description: string }) => {
      switch (failedCondition.code) {
        case 'EVSE_CONTROLLER_ACCOUNTS_HAVE_SAME_CURRENCY':
          return {
            header: t(
              'evseControllers.errors.sameCurrency.header',
              'Different billing currencies'
            ),
            message: t(
              'evseControllers.errors.sameCurrency.message',
              'Accounts on the selected stations should have the same billing currency'
            ),
          };
        case 'EVSE_CONTROLLERS_HAVE_SAME_POWER_TYPE':
          return {
            header: t(
              'evseControllers.errors.samePowerType.header',
              'Same power type'
            ),
            message: t(
              'evseControllers.errors.samePowerType.message',
              'Station connectors should have the same power type setup (AC, DC or AC/DC)'
            ),
          };
        case 'EVSE_CONTROLLERS_HAVE_NO_ACCOUNTS':
          return {
            header: t(
              'evseControllers.errors.noAccounts.header',
              'Evse controllers should not have accounts'
            ),
            message: t(
              'evseControllers.errors.noAccounts.message',
              'One or more stations already have accounts assigned.'
            ),
          };
        case 'EVSE_CONTROLLERS_HAVE_SAME_ACCOUNTS':
          return {
            header: t(
              'evseControllers.errors.sameAccount.header',
              'Same account'
            ),
            message: t(
              'evseControllers.errors.sameAccount.message',
              'All EVSE controllers must be assigned to the same account'
            ),
          };
        default:
          console.error('Unhandled failed condition error');
      }

      return {
        header: t('evseControllers.errors.unknown.header', 'Unknown error'),
        message: t(
          'evseControllers.errors.unknown.message',
          'An unknown error occurred'
        ),
      };
    },
    [t]
  );
}
