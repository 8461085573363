import { Container } from 'semantic';
import ThemedImage from 'components/ThemeImage';
import React from 'react';
import LanguageSelect from 'components/form-fields/Languages';
import { useTranslation } from 'react-i18next';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';

export default function PublicNav() {
  const provider = useProvider();
  const { i18n } = useTranslation();
  const isMobile = window.innerWidth <= 768;

  function setLanguage(e: unknown, { value }: { value: string }) {
    i18n.changeLanguage(value);
  }

  return (
    <nav>
      <Container>
        <div
          style={{
            margin: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <ThemedImage
            className="logo"
            darkSrc={provider?.invertedLogoUrl || ''}
            ligthSrc={provider?.logoUrl || ''}
            style={{ maxWidth: '100%', maxHeight: isMobile ? 25 : 40 }}
          />
          <div style={{ display: 'flex' }}>
            <LanguageSelect
              onChange={setLanguage}
              label=""
              value={i18n.language}
              style={{ maxWidth: '5rem' }}
            />
          </div>
        </div>
      </Container>
    </nav>
  );
}
