import React, {
  CSSProperties,
  HTMLAttributeAnchorTarget,
  ReactNode,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';

interface ResourceLinkProps {
  route: string;
  linkName: string;
  style?: CSSProperties;
  children?: ReactNode;
  target?: HTMLAttributeAnchorTarget;
}

export default function ResourceLink({
  route,
  linkName,
  style,
  children,
  target,
}: ResourceLinkProps) {
  return (
    <Link to={route} style={style} target={target}>
      <>
        {linkName}
        {children}
      </>
    </Link>
  );
}
