import React from 'react';
import { Header, Button, Divider, Message } from 'semantic';
import EditAccessGroupMember from 'components/modals/EditAccessGroupMember';
import { request } from 'utils/api';
import ImportAccessGroupMembers from 'components/modals/ImportAccessGroupMembers';
import RfidTable from './RfidTable';
import UsersTable from './UsersTable';

import { useTranslation } from 'react-i18next';
import { ExportAccessGroupMembers } from 'components/ExportAccessGroupMembers';

export default function Overview({ onRefresh, accessGroup }) {
  const { t } = useTranslation();

  function onDelete(item) {
    return request({
      method: 'DELETE',
      path: `/1/access-groups/${accessGroup.id}/members/${item.id}`,
    }).then(() => onRefresh());
  }

  const { members } = accessGroup;
  return (
    <div>
      <Header as="h2">
        {t('myAccessGroup.members', 'Members')}
        <EditAccessGroupMember
          accessGroup={accessGroup}
          onClose={onRefresh}
          refresh={onRefresh}
          trigger={
            <Button
              primary
              floated="right"
              style={{ marginTop: '-5px' }}
              content={t('myAccessGroup.addMember', 'Add Member')}
              icon="plus"
            />
          }
        />
        {accessGroup.type === 'rfid' && (
          <>
            <ImportAccessGroupMembers
              accessGroup={accessGroup}
              onClose={() => onRefresh()}
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '-5px' }}
                  content={t('myAccessGroup.importMembers', 'Import Members')}
                  icon="upload"
                />
              }
            />
            <ExportAccessGroupMembers
              floated="right"
              style={{ marginTop: '-5px' }}
              accessGroup={accessGroup}
            />
          </>
        )}
        <div style={{ clear: 'both' }} />
      </Header>
      <Divider hidden />
      <div className="list">
        {!members.length && (
          <Message>
            {t('myAccessGroup.emptyMessage', 'No members added yet')}
          </Message>
        )}
        {members.length > 0 &&
          (accessGroup.type === 'rfid' ? (
            <RfidTable
              members={members}
              accessGroup={accessGroup}
              onRefresh={onRefresh}
              onDelete={onDelete}
            />
          ) : (
            <UsersTable
              members={members}
              accessGroup={accessGroup}
              onRefresh={onRefresh}
              onDelete={onDelete}
            />
          ))}
      </div>
    </div>
  );
}
