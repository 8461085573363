import React from 'react';
import { Form } from 'semantic';
import { useTranslation } from 'react-i18next';
import { supportedLanguages } from 'i18n';

export default function LanguageSelect(props) {
  const { t } = useTranslation();

  return (
    <Form.Select
      label={props.label || t('formLabel.language', 'Language')}
      options={supportedLanguages}
      {...props}
    />
  );
}
