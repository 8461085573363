import React, { useState } from 'react';
import { formatDateTime } from 'utils/date';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalHeader,
  Table,
} from 'semantic-ui-react';
import ExpandableJson from 'components/ExpandableJson';
import styles from './RoamingActivity.module.css';

export interface Activity {
  label: string;
  correlationId: string;
  traceId: string;
  owner: {
    connectionId: string;
    connectionName?: string;
  };
  occurredAt: Date;
  entityId: string;
  entityType: string;
  childActivities: Activity[];
  body: {
    connectorPush?: {
      toConnection: {
        connectionId: string;
        connectionName: string;
      };
      connectionName: string;
      statusCode: number;
      error: any;
      requestUrl: string;
      requestBody: any;
      responseBody: any;
      occurredAt: Date;
    };
  };
}

const BroadcastRequestModal: React.FC<{
  item?: Activity;
  onClose(): void;
}> = ({ item, onClose }) => {
  const { t } = useTranslation();
  if (!item?.body?.connectorPush) {
    return null;
  }

  const connectorPush = item?.body?.connectorPush;

  let requestJSON = {};
  let responseJSON = {};
  try {
    requestJSON = JSON.parse(connectorPush?.requestBody);
  } catch (e) {
    console.warn(`Error parsing request JSON: ${e}`);
  }

  try {
    responseJSON = JSON.parse(connectorPush?.responseBody);
  } catch (e) {
    console.warn(`Error parsing response JSON: ${e}`);
  }

  return (
    <Modal closeIcon onClose={() => onClose()} open={!!open}>
      <ModalHeader>
        {t('roamingActivity.requestDetails', 'Request details')}
      </ModalHeader>
      <ModalContent>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {t('roamingActivity.requestDetailsURL', 'Request URL')}
              </Table.Cell>
              <Table.Cell>{connectorPush?.requestUrl}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t('roamingActivity.status', 'Status')}</Table.Cell>
              <Table.Cell>
                <StatusLabel statusCode={connectorPush?.statusCode} />
              </Table.Cell>
            </Table.Row>
            {connectorPush?.error && (
              <Table.Row>
                <Table.Cell>
                  {t('roamingActivity.requestDetailsError', 'Error')}
                </Table.Cell>
                <Table.Cell>{connectorPush?.error}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>
                {t('roamingActivity.requestDetailsoccurredAt', 'Occurred at')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(item?.occurredAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <h2>{t('roamingActivity.request', 'Request')}</h2>
        <ExpandableJson object={requestJSON || {}} />
        <h2>{t('roamingActivity.response', 'Response')}</h2>
        <ExpandableJson object={responseJSON || {}} />
      </ModalContent>
    </Modal>
  );
};

const StatusLabel: React.FC<{ statusCode: number }> = ({ statusCode }) => {
  const { t } = useTranslation();
  const success = [200, 201].includes(statusCode);
  return (
    <Label color={success ? 'olive' : 'red'}>
      {success
        ? t('roamingActivity.success', 'Success')
        : t('roamingActivity.error', 'Error')}
    </Label>
  );
};

const RoamingActivityBroadcast: React.FC<{
  item: Activity;
}> = ({ item }) => {
  const [selectedItem, toggleModal] = useState<Activity | undefined>(undefined);
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  const itemLabel = (key: string, connectionName?: string): string => {
    const keys: Record<string, string> = {
      CHANGE_BROADCAST: t(
        'roamingActivity.changeBroadcast',
        'A change to this entity has been broadcast'
      ),
      DOCUMENT_PUSH: t(
        'roamingActivity.documentPush',
        `The entity has been pushed to {{connectionName}}`,
        { connectionName }
      ),
    };
    const value = keys[key];
    if (value) {
      return value;
    }
    return key;
  };

  const entityTypeLabel = (entityType: string): string => {
    const keys: Record<string, string> = {
      ENTITY_TYPE_LOCATION: t('roamingActivity.entityTypeLocation', 'Location'),
      ENTITY_TYPE_TARIFF: t('roamingActivity.entityTypeTariff', 'Tariff'),
      ENTITY_TYPE_TOKEN: t('roamingActivity.entityTypeToken', 'Token'),
      ENTITY_TYPE_CDR: t('roamingActivity.entityTypeCDR', 'CDR'),
      ENTITY_TYPE_SESSION: t('roamingActivity.entityTypeSession', 'Session'),
    };
    const value = keys[entityType];
    if (value) {
      return value;
    }
    return entityType;
  };

  return (
    <div className={styles.activityItem}>
      <BroadcastRequestModal
        item={selectedItem}
        onClose={() => toggleModal(undefined)}
      />
      <div className={styles.activityItemHeader}>
        <Label size="tiny" className={styles.entityTypeLabel}>
          {entityTypeLabel(item?.entityType)}
        </Label>
        <div className={styles.activityHeaderName}>{itemLabel(item.label)}</div>
        <div className={styles.activityTime}>
          {formatDateTime(item.occurredAt)}
        </div>
        {item?.childActivities?.length > 0 && (
          <Button
            onClick={() => setShowDetails(!showDetails)}
            basic
            className={styles.button}
            size="mini">
            {showDetails
              ? t('roamingActivity.hide', 'Hide details')
              : t('roamingActivity.show', 'Show details')}
          </Button>
        )}
      </div>
      {showDetails && (
        <div className={styles.childActivityList}>
          {item?.childActivities?.map((childActivity, index) => {
            return (
              <div key={index} className={styles.activityRaw}>
                <p className={styles.activityRawName}>
                  {itemLabel(
                    childActivity?.label,
                    childActivity?.body?.connectorPush?.toConnection
                      ?.connectionName
                  )}
                </p>
                {childActivity.body?.connectorPush?.statusCode && (
                  <StatusLabel
                    statusCode={childActivity.body?.connectorPush?.statusCode}
                  />
                )}
                <Button
                  basic
                  className={styles.button}
                  size="mini"
                  onClick={() => toggleModal(childActivity)}>
                  {t('roamingActivity.request', 'View request data')}
                </Button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default RoamingActivityBroadcast;
