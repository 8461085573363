import React from 'react';
import { Label } from 'semantic';

export function formatPermissions(permissions, context) {
  const endpoints = Object.keys(permissions).filter(
    (endpoint) => permissions[endpoint] !== 'none'
  );
  if (!endpoints.length) return 'None';
  return endpoints.map((endpoint) => {
    return (
      <Label
        key={endpoint}
        color={context === 'provider' ? 'teal' : undefined}
        detail={permissions[endpoint]}
        content={endpoint}
      />
    );
  });
}
