import React from 'react';
import { Message, Table, Loader } from 'semantic';

export default class CreateJobResultPage extends React.Component {
  state = {
    loading: false,
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.props.getExportPreviewData();
    this.setState({
      loading: false,
    });
  }

  exportPreviewTable() {
    const { selectedCollectionFields, exportPreviewData } = this.props;

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            {selectedCollectionFields.map((collectionField) => (
              <Table.HeaderCell key={collectionField}>
                {collectionField}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {exportPreviewData.map((item, index) => (
            <Table.Row key={item + index}>
              {Object.values(item).map((field) => (
                <Table.Cell key={field + index}>{field}</Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { t, collectionCount, collectionCountLimit, exportPreviewData } =
      this.props;
    return (
      <div style={{ minHeight: '20rem', overflowX: 'scroll' }}>
        <Loader size="small" active={this.state.loading}>
          Loading
        </Loader>

        {collectionCount > 0 && (
          <p>
            {t('financeExports.createJob.recordsExpected', 'Records expected') +
              ': ' +
              collectionCount}
          </p>
        )}

        {collectionCount > collectionCountLimit ? (
          <Message negative>
            The expected amount of exported documents ({collectionCount})
            exceeds the maximum allowed amount ({collectionCountLimit})
          </Message>
        ) : undefined}

        {exportPreviewData?.length && this.exportPreviewTable()}
      </div>
    );
  }
}
