import { Header, Message } from 'semantic';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { isAccountAdmin } from '../../../utils/roles';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import TokenizeCardForm from 'components/TokenizeCardForm';
import PaymentInstrument from 'components/PaymentInstrument';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';

const PaymentMethods: React.FC = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = useUser();
  const [status, setStatus] = React.useState<{
    loading: boolean;
    success?: boolean;
    error?: any;
  }>({
    loading: false,
  });
  const [paymentMethods, setPaymentMethods] = React.useState<any[]>([]);
  const accountAdmin = isAccountAdmin(user);

  async function listPaymentMethods() {
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/users/me/payment-instruments',
      });

      setPaymentMethods(data);

      setStatus({ loading: false, error: null });
    } catch (error) {
      setStatus({ error, loading: false });
    }
  }

  React.useEffect(() => {
    listPaymentMethods();
  }, []);

  return (
    <SettingsShell>
      <Header>{t('settingsProfile.paymentMethods', 'Payment Methods')}</Header>
      {status.error && <Message error content={status.error.message} />}
      {status.success && (
        <Message content={t('messages.updated', 'Successfully updated!')} />
      )}
      {paymentMethods.map((p) => (
        <PaymentInstrument
          key={p.id}
          method={p}
          onDelete={() => listPaymentMethods()}
          deleteable={accountAdmin}
        />
      ))}
      {accountAdmin && (
        <TokenizeCardForm
          onSuccess={() => listPaymentMethods()}
          redirectURL={`${window.location.origin}/settings/account/payment-methods`}
        />
      )}
    </SettingsShell>
  );
};

export default PaymentMethods;
