import React, { useMemo } from 'react';
import { Form } from 'semantic';
import isoCountries from 'i18n-iso-countries';

import en from 'i18n-iso-countries/langs/en.json';
import nl from 'i18n-iso-countries/langs/nl.json';
import de from 'i18n-iso-countries/langs/de.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import it from 'i18n-iso-countries/langs/it.json';
import es from 'i18n-iso-countries/langs/es.json';
import { FieldValidator, useField } from 'formik';
import { useTranslation } from 'react-i18next';

const countryTranslations = {
  en: en,
  nl: nl,
  de: de,
  fr: fr,
  it: it,
  es: es,
};

for (const country of Object.values(countryTranslations)) {
  isoCountries.registerLocale(country);
}

export type CountryOption = {
  value: string;
  key: string;
  text: string;
};

function getCountryOptions(
  standard,
  language,
  lowerCase,
  countryNameLabels = true
): CountryOption[] {
  let codes;
  if (standard === 'alpha-3') {
    codes = isoCountries.getAlpha3Codes();
  } else {
    codes = isoCountries.getAlpha2Codes();
  }
  return Object.keys(codes).map((code) => {
    const value = lowerCase ? code.toLowerCase() : code;
    const text = countryNameLabels
      ? isoCountries.getName(code, language)
      : value;
    return {
      value,
      key: value,
      text,
    };
  });
}

export interface CountriesProps {
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  whitelist?: string[];
  standard?: 'alpha-2' | 'alpha-3';
  language?: string;
  lowerCase?: boolean;
  countryNameLabels?: boolean;
  readOnly?: boolean;
  multiple?: boolean;
  hideErrorLabel?: boolean;
  validate?: FieldValidator;
  validateImmediately?: boolean;
  comparator?: (a: CountryOption, b: CountryOption) => number;
}

export default function Countries({
  required,
  label,
  placeholder = 'Country',
  name,
  disabled = false,
  whitelist = undefined,
  standard = 'alpha-2',
  language,
  lowerCase = true,
  countryNameLabels = true,
  readOnly = false,
  multiple = false,
  hideErrorLabel,
  validate,
  validateImmediately,
  comparator = undefined,
  ...props
}: CountriesProps) {
  const [field, meta, helpers] = useField({ name, validate });
  if (!language) {
    const { i18n } = useTranslation();

    language = countryTranslations.hasOwnProperty(i18n.language)
      ? i18n.language
      : 'en';
  }

  const hasTouched = validateImmediately ? true : meta.touched;

  const options = useMemo(
    () =>
      getCountryOptions(standard, language, lowerCase, countryNameLabels)
        .filter((x) => {
          if (!Array.isArray(whitelist)) {
            return true;
          }
          return whitelist.includes(x.key);
        })
        .sort(comparator || ((a, b) => 0)),
    [whitelist, standard, language, lowerCase, countryNameLabels]
  );

  if (readOnly) {
    const currentValue = options.find((c) => c.key == field.value)?.text;

    return (
      <Form.Input
        value={currentValue}
        name={name}
        label={label}
        type="text"
        readOnly={readOnly}
        onChange={(e, { value }) => {
          helpers.setValue(value, true);
        }}
        error={
          hasTouched && meta.error
            ? hideErrorLabel
              ? true
              : meta.error
            : undefined
        }
        {...props}
      />
    );
  }

  return (
    <Form.Field required={required} disabled={disabled}>
      {label && <label>{label}</label>}
      <Form.Dropdown
        style={{ minWidth: '2em' }}
        value={field.value}
        name={name}
        multiple={multiple}
        selection
        search
        placeholder={placeholder}
        options={options}
        onChange={(e, { value }) => {
          helpers.setValue(value, true);
        }}
        error={
          hasTouched && meta.error
            ? hideErrorLabel
              ? true
              : meta.error
            : undefined
        }
        {...props}
      />
    </Form.Field>
  );
}
