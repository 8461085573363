import React from 'react';
import { request } from 'utils/api';
import { SearchDropdown } from 'components';

export default function PublicLocations({
  excludeConnectorsWithoutCurrency,
  onChange,
  value,
}) {
  const [error, setError] = React.useState(null);

  async function findLocations(searchParams) {
    try {
      const response = await request({
        path: `/2/locations/msp/connectors/search`,
        method: 'POST',
        body: {
          searchPhrase: searchParams.name,
          excludeConnectorsWithoutCurrency,
        },
      });
      const options = [];

      for (const connector of response.data) {
        if (!connector.evseUid || !connector.id) {
          continue;
        }

        options.push({
          name: `${connector.evseId} - Connector ${connector.id} - ${connector.locationName}`,
          currency: connector.tariff?.currency,
          locationId: connector.locationId,
          evseUid: connector.evseUid,
          connectorId: connector.id,
          // A unique id is required by the SearchDropdown component
          id: `${connector.evseUid}-${connector.id}`,
        });
      }

      return { data: options };
    } catch (error) {
      setError(true);
    }
  }

  return (
    <SearchDropdown
      value={value}
      getOptionLabel={(option) => option.name}
      objectMode={true}
      keywordField="name"
      onChange={onChange}
      error={error}
      onDataNeeded={findLocations}
    />
  );
}
