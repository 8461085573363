import { getToken } from 'utils/api';
import { parseToken } from 'utils/token';
import { emitAuthenticationBusMessage } from 'components/authentication/authentication-bus';

let initialized = false;

export function setupSSOTokenExpirationNotification() {
  if (initialized) {
    return;
  }

  const token = getToken();
  if (!token) {
    return;
  }

  const parsedToken = parseToken(token);
  if (parsedToken.method !== 'sso') {
    return;
  }

  // We can't immediately set the timeout for the unauthorized message as we'd also be doing that on the page when we
  // do the SSO token exchange.
  // So the user would click 'login with sso', get redirect to SSO and back, and then immediately we'd show the
  // unauthorized screen here, while the user would get a valid token set just after that. We could then hide the popup
  // again but that would result in flickering. So just wait 1 second before setting this timeout. In case the user
  // comes to the page without a valid token any 401 on the API will result in the loggedout popup immediately, so
  // doing a 1 second timeout is not a problem.
  const safeTimeoutInMs = 1000;
  setTimeout(() => {
    const token = getToken();
    if (!token) {
      emitAuthenticationBusMessage('sso-token-expired');
      return;
    }

    const tokenExpirationTime = parseToken(token).exp * 1000 - Date.now();
    setTimeout(() => {
      emitAuthenticationBusMessage('sso-token-expired');
    }, tokenExpirationTime);
  }, safeTimeoutInMs);

  initialized = true;
}
