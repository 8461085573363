import React from 'react';
import { Loader, Icon, Header, Segment, Grid, Button } from 'semantic';
import { request } from 'utils/api';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { calculateVat, formatCurrency } from 'utils/formatting';
import { useUser } from 'contexts/user';

const PlansContainer = styled.div`
  .plan {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    font-size: 14px;
  }
  .lines {
    margin-top: 10px;
    font-weight: bold;
    list-style-type: none;
    line-height: 30px;
    padding: 0;
    .icon {
      padding-right: 10px;
      color: ${(props) => props.theme.primaryColor} !important;
    }
  }
  p {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
  }
`;

export default function PlansCPO({
  billingPlanId,
  onSelect,
  disablePlan = () => false,
}) {
  const { i18n, t } = useTranslation();
  const { user } = useUser();
  const [plans, setPlans] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const accountBillingCurrency = user?.account?.billingCurrency;

  function fetchBillingPlans() {
    return request({
      method: 'POST',
      path: '/1/billing-plans/search',
      body: {
        providerContext: 'cpo',
        accountId: user.accountId,
        ...(accountBillingCurrency ? { currency: accountBillingCurrency } : {}),
      },
    }).then(({ data }) => {
      const availablePlans = data.sort((a, b) => b.priority - a.priority);

      let visiblePlans = [];
      if (availablePlans.length > 0) {
        // TODO: Fix this once https://linear.app/road-io/issue/SAAS-1852/filter-billing-plans-returned-from-the-api-on-1billing-planssearch is complete
        if (availablePlans[0].restrictedAccount) {
          visiblePlans = availablePlans;
        } else {
          visiblePlans = availablePlans.filter((e) => e.isPublic);
        }
      }

      setPlans(visiblePlans);
      setLoading(false);
    });
  }

  React.useEffect(() => {
    fetchBillingPlans();
  }, []);

  return (
    <PlansContainer>
      {loading && (
        <>
          <Loader active />
        </>
      )}
      <p>
        {t(
          'planCPO.allPlans',
          'All plans have 24/7 support, free firmware updates.'
        )}
      </p>
      <Grid textAlign="center" divided columns={plans.length || 2}>
        <Grid.Row>
          {plans.map((plan) => {
            const features = plan.details[i18n.language].features || [];
            const description = plan.details[i18n.language].descriptionLong;
            const pricesInCurrency = plan.prices.find(
              (x) => x.currency === accountBillingCurrency
            );

            return (
              <Grid.Column key={plan.id}>
                <Segment
                  className="plan"
                  disabled={billingPlanId ? billingPlanId !== plan.id : false}>
                  <Header as="h3" textAlign="center">
                    {plan.details[i18n.language].name}
                  </Header>
                  <div style={{ flex: 1, marginBottom: '10px' }}>
                    {!features.length && description && <p>{description}</p>}

                    {features.length > 0 && (
                      <ul className="lines">
                        {features.map((feature) => {
                          return (
                            <li key={feature}>
                              <Icon size="large" name="check" />
                              {feature}
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {pricesInCurrency.perDevice > 0 && (
                      <p>
                        {t(
                          'planCPO.perDeviceFee',
                          'One-time connection fee {{price}} incl. btw',
                          {
                            price: formatCurrency(
                              calculateVat(pricesInCurrency.perDevice),
                              { currency: accountBillingCurrency }
                            ),
                          }
                        )}
                      </p>
                    )}

                    <p>
                      {t(
                        'planCPO.costsPerMonth',
                        'Costs per month for the station {{price}} incl. btw',
                        {
                          price: formatCurrency(
                            calculateVat(pricesInCurrency.perPeriod),
                            { currency: accountBillingCurrency }
                          ),
                        }
                      )}
                    </p>

                    {pricesInCurrency.perKwhFixed > 0 && (
                      <p>
                        {t(
                          'planCpo.feesPerKwhFixed',
                          'Fees per kwh for the station {{price}} per KwH',
                          {
                            price: formatCurrency(
                              calculateVat(pricesInCurrency.perKwhFixed),
                              { currency: accountBillingCurrency }
                            ),
                          }
                        )}
                      </p>
                    )}
                    {pricesInCurrency.perKwhPercentage > 0 && (
                      <p>
                        {t(
                          'planCpo.feesPerKwhPercentage',
                          'Fees per kwh for the station {{price}}% per KwH',
                          {
                            price: pricesInCurrency.perKwhPercentage,
                          }
                        )}
                      </p>
                    )}
                  </div>

                  <Button
                    disabled={disablePlan(plan)}
                    primary
                    onClick={() => onSelect(plan)}>
                    {t('common.select', 'Select')}
                  </Button>
                </Segment>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </PlansContainer>
  );
}
