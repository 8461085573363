import React from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'semantic';
import { Invoice } from 'types/invoice';

export default function InvoiceStatusLabel({ invoice }: { invoice: Invoice }) {
  const { t } = useTranslation();

  if (invoice.hasError) {
    return (
      <Label
        color="red"
        content={t('invoiceStatusLabel.error', 'Error')}
        title={
          invoice.error
            ? invoice.error.message
            : t('invoiceStatusLabel.errorNotProvided', 'Error not provided')
        }
      />
    );
  }

  if (invoice.isPaid) {
    return (
      <Label color="olive" content={t('invoiceStatusLabel.paid', 'Paid')} />
    );
  }
  if (invoice.isReady) {
    return (
      <Label color="yellow" content={t('invoiceStatusLabel.ready', 'Ready')} />
    );
  }

  return (
    <Label color="grey" content={t('invoiceStatusLabel.pending', 'Pending')} />
  );
}
