import React from 'react';
import { useTranslation } from 'react-i18next';
import { RedirectPanel } from 'screens/Auth/SignupV2/steps/Completed/RedirectPanel';

interface Props {
  isLoading: boolean;
  error?: boolean;
  errorMessage?: string;
  hideBelowContent?: boolean;
  onSubmit: () => void;
  postSignupRedirect: string;
  loginPanelText: string;
  navigationDirection: 'none' | 'forward' | 'backward';
  platformName: string;
}

export default function AccountVerified(props: Props) {
  const { t } = useTranslation();

  const platformName = props.platformName;

  return (
    <RedirectPanel
      {...props}
      headerText={t(
        'signup.accountVerifiedHeader',
        'Start using {{platformName}}',
        { platformName }
      )}
      subHeaderText={t(
        'signup.accountVerifiedSubheader',
        `You're all set to start your first EV charge`
      )}
      content={
        <>
          <p>
            {t(
              'signup.accountVerifiedText',
              'We will now log you in to the {{platformName}} Dashboard automatically to setup your first EV charging station or order your charge card.', // fixme update text based on flow
              { platformName }
            )}
          </p>
        </>
      }
    />
  );
}
