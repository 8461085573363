import { FieldValidator } from 'formik';
import React, { CSSProperties } from 'react';
import SelectField from './SelectField';
import { supportedLanguages } from 'i18n';

interface SelectLanguageProps {
  name: string;
  label?: string;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  validate?: FieldValidator;
  required?: boolean;
}

export default function SelectLanguage({
  name,
  required = false,
  label,
  validate,
  wrapperStyle,
}: SelectLanguageProps) {
  return (
    <SelectField
      name={name}
      label={label}
      validate={validate}
      required={required}
      options={supportedLanguages}
      wrapperStyle={wrapperStyle}
    />
  );
}
