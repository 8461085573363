import React, { useState } from 'react';
import { first, isNil } from 'lodash';
import {
  Accordion,
  AccordionTitle,
  Button,
  Checkbox,
  Form,
  Icon,
  Label,
  Message,
  Modal,
  Segment,
} from 'semantic';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import InputField from 'components/form-fields/formik/InputField';
import SelectField from 'components/form-fields/formik/SelectField';
import ErrorMessage from 'components/ErrorMessage';
import AsyncModal from 'helpers/async-modal';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import useFetch from 'hooks/useFetch';
import {
  MerchantContractType,
  PaymentTerminal,
  PaymentTerminalType,
  PayterAuthorizationFlow,
  PayterPaymentMethod,
  PaymentTerminalAcquirer,
} from 'types/payment-terminal';
import { Location } from 'types/location';
import {
  translationMerchantContractType,
  translationPaymentTerminalType,
  translationPayterAuthorizationFlow,
  translationPayterPaymentMethod,
  translationPaymentTerminalAcquirer,
} from './translations';
import { countries } from 'eflux-pkg-js';
import PriceField from 'components/form-fields/formik/PriceField';

enum TerminalType {
  Payter = 'payter',
  Alpitronic = 'alpitronic',
  Sicharge = 'sicharge',
  AdsTec = 'ads-tec',
  ETotem = 'e-totem',
}

const StyledLink = styled('a')`
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin: 0 15px;
  color: ${(props) => props.theme.primaryColor};

  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

type Connector = {
  id: string;
  evseId: string;
  parentEvseId: string;
  paymentTerminal?: PaymentTerminal;
};

type Option = {
  key: string;
  value: string;
  text: string;
};

type OptionWithMetadata = Option & {
  checked: boolean;
  enabled: boolean;
  paymentTerminal?: PaymentTerminal;
};

type Props = {
  submit: (values: PaymentTerminal) => Promise<Error | null>;
  locations: Location[];
  connectors: Connector[];
  paymentTerminal?: PaymentTerminal;
  close: () => void;
};

type Form = {
  name: string;
  type?: string; // undefined while metadata is loading
  location: string;
  connector: { [key: string]: boolean };
  enabled: boolean;
  contract?: string; // undefined while metadata is loading
  acquirer?: string | null; // undefined while metadata is loading
  payterSerialNumber?: string;
  payterPaymentMethod?: string;
  payterAuthorizationFlow?: string;
  payterLangCode?: string;
  payterPreAuthAmount?: number;
  sichargePreAuthAmount?: number;
  adsTecKwhPrice?: number;
};

type Metadata = {
  types: string[];
  contractTypes: string[];
  acquirers: string[];
  payter?: {
    paymentMethods: string[];
    authorizationFlows: string[];
    langCodes: string[];
  };
};

enum FormConfigAssociationLevel {
  EVSEController = 'evse-controller',
  Connector = 'connector',
}

type FormConfig = {
  isSingleChargingStation?: boolean;
  showEnabledField?: boolean;
  associationLevel?: FormConfigAssociationLevel;
  customFields?: JSX.Element;
  alert?: JSX.Element;
};

const defaultPreAuthAmount = 40;

// build initial values for the formik form
const useInitialValues = ({
  paymentTerminal,
  metadata,
  locations,
  connectorValues,
}: {
  paymentTerminal?: PaymentTerminal;
  metadata?: Metadata | null;
  locations: Location[];
  connectorValues: { [key: string]: boolean };
}): Form => {
  const name = paymentTerminal?.name || '';
  const type = paymentTerminal?.type || first(metadata?.types) || '';
  const isAlpitronic = type === TerminalType.Alpitronic;
  const location = paymentTerminal?.locationId || first(locations)?.id || '';
  const connector = connectorValues;
  const contract =
    paymentTerminal?.contract || first(metadata?.contractTypes) || '';
  const acquirer = paymentTerminal?.acquirer;

  const enabled = !isNil(paymentTerminal?.enabled)
    ? paymentTerminal?.enabled
    : isAlpitronic;

  let payterSerialNumber;
  let payterPaymentMethod;
  let payterAuthorizationFlow;
  let payterLangCode;
  let payterPreAuthAmount;
  let sichargePreAuthAmount = defaultPreAuthAmount;
  let adsTecKwhPrice;

  switch (type) {
    case TerminalType.Payter:
      payterSerialNumber = paymentTerminal?.payterDetails?.serialNumber || '';
      payterPaymentMethod =
        paymentTerminal?.payterDetails?.paymentMethod ||
        first(metadata?.payter?.paymentMethods) ||
        '';
      payterAuthorizationFlow =
        paymentTerminal?.payterDetails?.authorizationFlow ||
        first(metadata?.payter?.authorizationFlows) ||
        '';
      payterLangCode =
        paymentTerminal?.payterDetails?.langCode ||
        first(metadata?.payter?.langCodes) ||
        '';
      payterPreAuthAmount = paymentTerminal?.payterDetails?.preAuthAmount || 40;
      break;
    case TerminalType.Sicharge:
      sichargePreAuthAmount =
        paymentTerminal?.sichargeDetails?.preAuthAmount || 0;
      break;
    case TerminalType.Alpitronic:
      break;
    case TerminalType.AdsTec:
      adsTecKwhPrice = paymentTerminal?.adsTecDetails?.kwhPrice || 0;
      break;
    case TerminalType.ETotem:
      break;
    default:
      payterSerialNumber = '';
  }

  return {
    name,
    type,
    location,
    enabled,
    connector,
    contract,
    acquirer,
    payterSerialNumber,
    payterPaymentMethod,
    payterAuthorizationFlow,
    payterLangCode,
    payterPreAuthAmount,
    sichargePreAuthAmount,
    adsTecKwhPrice,
  };
};

function ModalForm({
  paymentTerminal,
  locations,
  connectors,
  submit,
  close,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({});

  const {
    data: paymentTerminalMetadata,
    loading: paymentTerminalMetadataLoading,
    error: paymentTerminalError,
  } = useFetch<Metadata>({
    path: '/1/payment-terminals/metadata',
    queryParams: {
      locationId: paymentTerminal?.locationId || first(locations)?.id || '',
    },
  });

  const allErrors = paymentTerminalError || error;

  // build available type options for payment terminals
  const availableTypes: Option[] =
    paymentTerminalMetadata?.types.map((type) => {
      const translation = translationPaymentTerminalType(
        type as PaymentTerminalType
      );

      return {
        key: type,
        value: type,
        text: /* i18next-extract-disable-line */ t(
          translation.key,
          translation.default
        ),
      };
    }) || [];

  // build available contract options for payment terminals
  const contracts: Option[] =
    paymentTerminalMetadata?.contractTypes.map((type) => {
      const translation = translationMerchantContractType(
        type as MerchantContractType
      );

      return {
        key: type,
        value: type,
        text: /* i18next-extract-disable-line */ t(
          translation.key,
          translation.default
        ),
      };
    }) || [];

  // build available acquirer options for payment terminals
  const acquirers: Option[] =
    paymentTerminalMetadata?.acquirers.map((type) => {
      const translation = translationPaymentTerminalAcquirer(
        type as PaymentTerminalAcquirer
      );

      return {
        key: type,
        value: type,
        text: /* i18next-extract-disable-line */ t(
          translation.key,
          translation.default
        ),
      };
    }) || [];

  // build available payment method options for Payter payment terminals
  const payterPaymentMethods: Option[] =
    paymentTerminalMetadata?.payter?.paymentMethods?.map((method) => {
      const translation = translationPayterPaymentMethod(
        method as PayterPaymentMethod
      );

      return {
        key: method,
        value: method,
        text: /* i18next-extract-disable-line */ t(
          translation.key,
          translation.default
        ),
      };
    }) || [];

  // build available authorization flow options for Payter payment terminals
  const payterAuthorizationFlows: Option[] =
    paymentTerminalMetadata?.payter?.authorizationFlows?.map((flow) => {
      const translation = translationPayterAuthorizationFlow(
        flow as PayterAuthorizationFlow
      );

      return {
        key: flow,
        value: flow,
        text: /* i18next-extract-disable-line */ t(
          translation.key,
          translation.default
        ),
      };
    }) || [];

  const payterLangCodes: Option[] =
    paymentTerminalMetadata?.payter?.langCodes?.map((code) => {
      return {
        key: code,
        value: code,
        text: code.toUpperCase(),
      };
    }) || [];

  // build locations options for payment terminals
  const locationOptions: Option[] = locations.map((location) => ({
    key: location.id,
    value: location.id,
    text: location.name,
  }));

  // build available connector options for payment terminals grouped by parent evse id
  // used only for rendering the options on the html form
  const connectorOptionsGroupedByParentEvseId = connectors.reduce<{
    [parentEvseId: string]: OptionWithMetadata[];
  }>((acc, connector) => {
    const connectorOption: OptionWithMetadata = {
      key: connector.id,
      value: connector.id,
      text: connector.evseId,
      checked: !!paymentTerminal?.connectorIds?.includes(connector.id),
      enabled:
        !connector.paymentTerminal ||
        connector.paymentTerminal?.id === paymentTerminal?.id,
      paymentTerminal: connector.paymentTerminal,
    };

    if (acc[connector.parentEvseId]) {
      return {
        ...acc,
        [connector.parentEvseId]: [
          ...acc[connector.parentEvseId],
          connectorOption,
        ],
      };
    }

    return {
      ...acc,
      [connector.parentEvseId]: [connectorOption],
    };
  }, {});
  const parentEvseIds = Object.keys(connectorOptionsGroupedByParentEvseId);

  // build initial values for connectors for payment terminals
  // used on the formik form
  const initialConnectorsValues = Object.values(
    connectorOptionsGroupedByParentEvseId
  ).reduce<{
    [key: string]: boolean;
  }>((acc, options) => {
    const optionsWithValue: { [key: string]: boolean } = options.reduce(
      (acc, option) => {
        return {
          ...acc,
          [option.key]: option.checked,
        };
      },
      {}
    );

    return {
      ...acc,
      ...optionsWithValue,
    };
  }, {});

  const initialValues = useInitialValues({
    paymentTerminal,
    metadata: paymentTerminalMetadata,
    locations,
    connectorValues: initialConnectorsValues,
  });

  // handle form submit
  const onSubmit = async (values: Form) => {
    const connectorIds = Object.keys(values.connector).filter(
      (key) => values.connector[key]
    );

    const result = await submit({
      id: paymentTerminal?.id || '',
      name: values.name,
      type: values.type as PaymentTerminalType,
      locationId: values.location,
      enabled: values.enabled,
      contract: values.contract as MerchantContractType,
      acquirer: (values.acquirer as PaymentTerminalAcquirer) || null,
      connectorIds,
      payterDetails:
        values.type === TerminalType.Payter
          ? ({
              serialNumber: values.payterSerialNumber || '',
              paymentMethod: values.payterPaymentMethod || '',
              authorizationFlow: values.payterAuthorizationFlow || '',
              langCode: values.payterLangCode || '',
              preAuthAmount: values.payterPreAuthAmount || 0,
            } as PaymentTerminal['payterDetails'])
          : undefined,
      sichargeDetails:
        values.type === TerminalType.Sicharge
          ? {
              preAuthAmount: values.sichargePreAuthAmount || 0,
            }
          : undefined,
      adsTecDetails:
        values.type === TerminalType.AdsTec
          ? {
              kwhPrice: values.adsTecKwhPrice || 0,
            }
          : undefined,
    });

    if (!result) {
      close();
    } else {
      setError(result);
    }
  };

  // get currency for sicharge terminals
  const currency = countries.getCurrencyByAlpha3(
    first(locations)?.country || ''
  );

  const getFormConfigByType = (type: TerminalType): FormConfig => {
    switch (type) {
      case TerminalType.Payter:
        return {
          isSingleChargingStation: false,
          showEnabledField: true,
          associationLevel: FormConfigAssociationLevel.Connector,
          customFields: (
            <>
              <Form.Field>
                <InputField
                  name="payterSerialNumber"
                  label={t(
                    'paymentTerminalModal.inputFieldSerialNumber',
                    'Serial Number'
                  )}
                  type="text"
                  required
                />
              </Form.Field>
              <Form.Field>
                <SelectField
                  name="payterPaymentMethod"
                  label={t(
                    'paymentTerminalModal.inputFieldPaymentMethod',
                    'Payment Method'
                  )}
                  type="text"
                  required
                  options={payterPaymentMethods}
                />
              </Form.Field>
              <Form.Field>
                <SelectField
                  name="payterAuthorizationFlow"
                  label={t(
                    'paymentTerminalModal.inputFieldAuthorizationFlow',
                    'Authorization Flow'
                  )}
                  type="text"
                  required
                  options={payterAuthorizationFlows}
                />
              </Form.Field>
              <Form.Field>
                <SelectField
                  name="payterLangCode"
                  label={t(
                    'paymentTerminalModal.inputFieldLangCode',
                    'Display Language'
                  )}
                  type="text"
                  required
                  options={payterLangCodes}
                />
              </Form.Field>
              <Form.Field>
                <InputField
                  name="payterPreAuthAmount"
                  label={t(
                    'paymentTerminalModal.inputFieldPreAuthAmount',
                    'Authorization Amount'
                  )}
                  type="number"
                  required
                />
              </Form.Field>
            </>
          ),
        };
      case TerminalType.Alpitronic:
        return {
          isSingleChargingStation: true,
          associationLevel: FormConfigAssociationLevel.EVSEController,
        };
      case TerminalType.Sicharge:
        return {
          isSingleChargingStation: true,
          showEnabledField: true,
          associationLevel: FormConfigAssociationLevel.EVSEController,
          customFields: (
            <PriceField
              currency={currency}
              name="sichargePreAuthAmount"
              min={0}
              label={t(
                'paymentTerminalModal.inputFieldPreAuthAmount',
                'Pre-authorization Amount'
              )}
              required
            />
          ),
          alert: paymentTerminal ? (
            paymentTerminal.sichargeDetails?.isSynced ? (
              <Message info size="small">
                <p>
                  {t(
                    'paymentTerminalModal.sichargeSyncSuccess',
                    'Payment Terminal is in sync with EVSE controller!'
                  )}
                </p>
              </Message>
            ) : (
              <Message error size="small">
                <p>
                  {t(
                    'paymentTerminalModal.sichargeSyncWarning',
                    "Payment Terminal is not in sync with EVSE controller! Press 'Update Payment Terminal' to start sync."
                  )}
                </p>
              </Message>
            )
          ) : undefined,
        };
      case TerminalType.AdsTec:
        return {
          isSingleChargingStation: true,
          associationLevel: FormConfigAssociationLevel.EVSEController,
          customFields: (
            <PriceField
              currency={currency}
              name="adsTecKwhPrice"
              min={0}
              label={t(
                'paymentTerminalModal.inputFieldKwhPrice',
                'Price per kWh'
              )}
              required
            />
          ),
          alert: paymentTerminal ? (
            paymentTerminal.adsTecDetails?.isSynced ? (
              <Message info size="small">
                <p>
                  {t(
                    'paymentTerminalModal.adsTecSyncSuccess',
                    'Payment Terminal is in sync with EVSE controller!'
                  )}
                </p>
              </Message>
            ) : (
              <Message error size="small">
                <p>
                  {t(
                    'paymentTerminalModal.adsTecSyncWarning',
                    "Payment Terminal is not in sync with EVSE controller! Press 'Update Payment Terminal' to start sync."
                  )}
                </p>
              </Message>
            )
          ) : undefined,
        };
      case TerminalType.ETotem:
        return {
          isSingleChargingStation: true,
          showEnabledField: false,
          associationLevel: FormConfigAssociationLevel.EVSEController,
        };
      default:
        return {};
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        type: Yup.string().required(),
        location: Yup.string().required(),
        enabled: Yup.boolean().required(),
        contract: Yup.string().required(),
        acquirer: Yup.string().nullable(),
        connector: Yup.object().required(),
        payterSerialNumber: Yup.string().when('type', {
          is: TerminalType.Payter,
          then: (schema) =>
            schema.required(
              t(
                'paymentTerminalModal.payterSerialNumberRequired',
                'Serial Number is required'
              )
            ),
        }),
        payterPaymentMethod: Yup.string().when('type', {
          is: TerminalType.Payter,
          then: (schema) =>
            schema.required(
              t(
                'paymentTerminalModal.payterPaymentMethodRequired',
                'Payment Method is required'
              )
            ),
        }),
        payterAuthorizationFlow: Yup.string().when('type', {
          is: 'payter',
          then: (schema) =>
            schema.required(
              t(
                'paymentTerminalModal.payterAuthorizationFlowRequired',
                'Authorization Flow is required'
              )
            ),
        }),
        payterLangCode: Yup.string(),
        payterPreAuthAmount: Yup.number().positive(),
        sichargePreAuthAmount: Yup.number().when('type', {
          is: TerminalType.Sicharge,
          then: (schema) =>
            schema
              .required(
                t(
                  'paymentTerminalModal.sichargePreAuthAmountRequired',
                  'Pre-Authorization Amount is required'
                )
              )
              .positive(
                t(
                  'paymentTerminalModal.sichargePreAuthAmountMin',
                  'Pre-Authorization Amount cannot be negative'
                )
              ),
        }),
        adsTecKwhPrice: Yup.number().when('type', {
          is: TerminalType.AdsTec,
          then: (schema) =>
            schema
              .required(
                t(
                  'paymentTerminalModal.adsTecKwhPriceRequired',
                  'Price per kWh is required'
                )
              )
              .positive(
                t(
                  'paymentTerminalModal.adsTecKwhPriceMin',
                  'Price per kWh cannot be negative'
                )
              ),
        }),
      })}
      onSubmit={(values) => onSubmit(values)}>
      {({ isSubmitting, handleSubmit, values, setFieldValue }) => {
        const formConfig: FormConfig = getFormConfigByType(
          values.type as TerminalType
        );
        const hasAnyConnectorsChecked = Object.values(values.connector).some(
          (value) => value
        );
        const setConnectorsByParentEvseId = (
          parentEvseId: string,
          value: boolean
        ) => {
          connectorOptionsGroupedByParentEvseId[parentEvseId].forEach(
            (connector) => {
              if (!connector.enabled) return;

              setFieldValue(`connector.${connector.key}`, value);
            }
          );
        };
        const contract = values.contract as MerchantContractType;
        const showAcquirerField = contract === 'road';

        return (
          <>
            <Modal.Header>
              {paymentTerminal
                ? t(
                    'paymentTerminalModal.modalTitleEdit',
                    'Update Payment Terminal'
                  )
                : t(
                    'paymentTerminalModal.modalTitleNew',
                    'New Payment Terminal'
                  )}
            </Modal.Header>
            <Modal.Content>
              <Form name="payment-terminal-form" onSubmit={handleSubmit}>
                {allErrors && <ErrorMessage error={allErrors} />}

                {formConfig.alert}
                <Form.Field>
                  <InputField
                    name="name"
                    label={t('paymentTerminalModal.inputFieldName', 'Name')}
                    type="text"
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <SelectField
                    name="location"
                    label={t(
                      'paymentTerminalModal.inputFieldLocation',
                      'Location'
                    )}
                    type="text"
                    required
                    disabled
                    options={locationOptions}
                  />
                </Form.Field>
                <Form.Field>
                  <SelectField
                    name="type"
                    label={t('paymentTerminalModal.inputFieldType', 'Type')}
                    type="text"
                    required
                    options={availableTypes}
                    disabled={!!paymentTerminal}
                    onChange={(e, { value }) => {
                      setFieldValue('type', value);

                      // clear all connectors when changing the type
                      // different terminals have different association rules
                      // Eg: alpitronic terminals can only be associate with all terminals or none
                      parentEvseIds.forEach((parentEvseId) =>
                        setConnectorsByParentEvseId(parentEvseId, false)
                      );

                      // alpitronic/adstec payment terminals are always enabled
                      if (
                        value === TerminalType.Alpitronic ||
                        value === TerminalType.AdsTec ||
                        value === TerminalType.ETotem
                      ) {
                        setFieldValue('enabled', true);
                      }
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <SelectField
                    name="contract"
                    label={t(
                      'paymentTerminalModal.inputFieldMerchantContracts',
                      'Merchant Contract'
                    )}
                    type="text"
                    required
                    options={contracts}
                    onChange={(e, { value }) => {
                      setFieldValue('contract', value);

                      // customer contract cannot have acquirer
                      const contract = value as MerchantContractType;
                      if (contract === 'customer') {
                        setFieldValue('acquirer', null);
                      }
                    }}
                  />
                </Form.Field>
                {showAcquirerField && (
                  <Form.Field>
                    <SelectField
                      name="acquirer"
                      required
                      label={t(
                        'paymentTerminalModal.inputFieldAcquirer',
                        'Acquirer'
                      )}
                      type="text"
                      options={acquirers}
                      clearable
                    />
                  </Form.Field>
                )}
                {formConfig.customFields}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingTop: 10,
                  }}>
                  <Form.Field required>
                    <label>
                      {t(
                        'paymentTerminalModal.inputFieldConnectedEVSEs',
                        'Connected EVSEs'
                      )}
                    </label>
                  </Form.Field>
                  <div>
                    {formConfig.isSingleChargingStation && (
                      <>
                        <StyledLink
                          onClick={(e: any) => {
                            e.preventDefault();

                            // select all connectors
                            parentEvseIds.forEach((parentEvseId) =>
                              setConnectorsByParentEvseId(parentEvseId, true)
                            );
                          }}>
                          {t('paymentTerminalModal.selectAll', 'Select all')}
                        </StyledLink>
                        <span style={{ color: '#DDDDDD' }}>|</span>
                      </>
                    )}
                    <StyledLink
                      onClick={(e: any) => {
                        e.preventDefault();

                        // clear all connectors
                        parentEvseIds.forEach((parentEvseId) =>
                          setConnectorsByParentEvseId(parentEvseId, false)
                        );
                      }}>
                      {t('paymentTerminalModal.clearAll', 'Clear all')}
                    </StyledLink>
                  </div>
                </div>
                <Form.Field required>
                  <div>
                    <Message
                      attached
                      content={t(
                        'paymentTerminalModal.inputFieldConnectedEVSEsHeader',
                        'EVSE ID'
                      )}
                    />
                    <Segment attached>
                      <Accordion>
                        {parentEvseIds.map((parentEvseId) => {
                          const parentConnectors =
                            connectorOptionsGroupedByParentEvseId[
                              parentEvseId
                            ].map((c) => c.key);
                          const allParentConnectorsChecked =
                            parentConnectors.every(
                              (connector) => values.connector[connector]
                            );
                          const allParentConnectorsUnchecked =
                            parentConnectors.every(
                              (connector) => !values.connector[connector]
                            );
                          const allParentConnectorsDisabled =
                            parentConnectors.every(
                              (connector) =>
                                !connectorOptionsGroupedByParentEvseId[
                                  parentEvseId
                                ].find((c) => c.key === connector)?.enabled
                            );
                          const anyParentConnectorCheckedByAnotherTerminal =
                            connectorOptionsGroupedByParentEvseId[
                              parentEvseId
                            ].some(
                              (c) =>
                                c.paymentTerminal &&
                                c.paymentTerminal?.id !== paymentTerminal?.id
                            );

                          const isDisabledParentCheckbox =
                            // mark it disable if metadata is loading
                            paymentTerminalMetadataLoading ||
                            // mark it disable if all connectors are disabled
                            allParentConnectorsDisabled ||
                            // mark it disable if any connector is checked by another terminal
                            (formConfig.isSingleChargingStation &&
                              anyParentConnectorCheckedByAnotherTerminal) ||
                            // mark it disable if terminal is already associated with another evse controller
                            (formConfig.isSingleChargingStation &&
                              hasAnyConnectorsChecked &&
                              allParentConnectorsUnchecked);

                          return (
                            <div key={parentEvseId} style={{ fontWeight: 600 }}>
                              <AccordionTitle
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                <Icon
                                  style={{ marginRight: 10 }}
                                  name={
                                    collapsed[parentEvseId]
                                      ? 'angle-right'
                                      : 'angle-down'
                                  }
                                  onClick={() =>
                                    setCollapsed({
                                      ...collapsed,
                                      [parentEvseId]: !collapsed[parentEvseId],
                                    })
                                  }
                                />
                                <Form.Field>
                                  <Checkbox
                                    as="checkbox"
                                    label={parentEvseId}
                                    disabled={isDisabledParentCheckbox}
                                    onChange={(e: any) => {
                                      e.preventDefault();

                                      if (allParentConnectorsUnchecked) {
                                        setConnectorsByParentEvseId(
                                          parentEvseId,
                                          true
                                        );
                                      } else {
                                        setConnectorsByParentEvseId(
                                          parentEvseId,
                                          false
                                        );
                                      }
                                    }}
                                    checked={allParentConnectorsChecked}
                                    indeterminate={
                                      !allParentConnectorsChecked &&
                                      !allParentConnectorsUnchecked
                                    }
                                  />
                                </Form.Field>
                              </AccordionTitle>

                              <Accordion.Content
                                style={{ paddingLeft: 50, fontWeight: 500 }}
                                active={!collapsed[parentEvseId]}>
                                {connectorOptionsGroupedByParentEvseId[
                                  parentEvseId
                                ].map((connector) => (
                                  <div
                                    key={`connector.${connector.key}`}
                                    style={{
                                      display: 'flex',
                                      paddingBottom: connector.enabled ? 14 : 0,
                                    }}>
                                    <Form.Field>
                                      <CheckboxField
                                        name={`connector.${connector.key}`}
                                        label={connector.text}
                                        disabled={
                                          paymentTerminalMetadataLoading ||
                                          formConfig.associationLevel ===
                                            FormConfigAssociationLevel.EVSEController ||
                                          !connector.enabled
                                        }
                                      />
                                    </Form.Field>
                                    {!connector.enabled && (
                                      <Label
                                        size="tiny"
                                        style={{
                                          marginTop: 0,
                                          marginLeft: 20,
                                          marginBottom: 14,
                                          backgroundColor:
                                            'rgba(0, 140, 217, 0.2)',
                                        }}>
                                        <Icon name="money-check" />{' '}
                                        {connector.paymentTerminal?.name}
                                      </Label>
                                    )}
                                  </div>
                                ))}
                              </Accordion.Content>
                            </div>
                          );
                        })}
                      </Accordion>
                    </Segment>
                  </div>
                </Form.Field>

                <Form.Field
                  style={
                    !formConfig.showEnabledField ? { display: 'none' } : {}
                  }>
                  <label style={{ paddingBottom: 10 }}>
                    {t(
                      'paymentTerminalModal.inputFieldActivation',
                      'Activation'
                    )}
                  </label>
                  <CheckboxField
                    name="enabled"
                    toggle
                    disabled={!formConfig.showEnabledField}
                    label={t(
                      'paymentTerminalModal.inputFieldEnabled',
                      'Enable payment terminal'
                    )}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                basic
                style={{ boxShadow: 'none', fontWeight: 600 }}
                onClick={close}>
                {t('button.close', 'Close')}
              </Button>
              <Button
                type="submit"
                loading={isSubmitting || paymentTerminalMetadataLoading}
                primary
                content={
                  paymentTerminal
                    ? t(
                        'paymentTerminalModal.buttonUpdatePaymentTerminal',
                        'Update Payment Terminal'
                      )
                    : t(
                        'paymentTerminalModal.buttonCreatePaymentTerminal',
                        'Create Payment Terminal'
                      )
                }
                form="payment-terminal-form"
                style={{ width: '220px' }}
                as="button"
                onClick={handleSubmit}
              />
            </Modal.Actions>
          </>
        );
      }}
    </Formik>
  );
}

export default AsyncModal(ModalForm);
