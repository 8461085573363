import React from 'react';
import { Table, Header } from 'semantic';
import {
  formatCdrSourceCell,
  formatCdrStatusCell,
  formatCurrency,
  formatKwh,
} from 'utils/formatting';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatDuration } from 'utils/date';
import { CdrChargingPeriodDimensions } from 'utils/constants';
import { Link } from 'react-router-dom';

export default function CdrTable({ cdr, user }) {
  const { t } = useTranslation();

  const startDate = new Date(cdr?.startTime);
  const endDate = new Date(cdr?.endTime);
  const durationSeconds = (endDate - startDate) / 1000;

  return (
    <div>
      <Header as="h3">{t('cdrsTable.detailsTitle', 'Basic Details')}</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {t('cdrsTable.externalCdrId', 'External CDR ID')}
            </Table.Cell>
            <Table.Cell>{cdr.externalCdrId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.contractId', 'Contract ID')}</Table.Cell>
            <Table.Cell>{cdr.contractId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.source', 'Source')}</Table.Cell>
            <Table.Cell>
              {formatCdrSourceCell(cdr.source)} (View raw data)
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.status', 'Status')}</Table.Cell>
            <Table.Cell>{formatCdrStatusCell(cdr.status)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('cdrsTable.totalKwh', 'Total Energy (kWh)')}
            </Table.Cell>
            <Table.Cell>{formatKwh(parseFloat(cdr.totalKwh))}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell> {t('cdrsTable.duration', 'Duration')}</Table.Cell>
            <Table.Cell>
              {formatDuration(durationSeconds, t)}
              <p>
                <small>
                  <b>{t('common.start', 'Start')}</b>:{' '}
                  {formatDateTime(cdr.startTime)}
                </small>
                <br />
                <small>
                  <b>{t('common.end', 'End')}</b>: {formatDateTime(cdr.endTime)}
                </small>
              </p>
            </Table.Cell>
          </Table.Row>

          {cdr.providerId && (
            <Table.Row>
              <Table.Cell>{t('cdrsTable.provider', 'Provider')}</Table.Cell>
              <Table.Cell>
                <Link to={`/providers`}> {cdr.providerId}</Link>
              </Table.Cell>
            </Table.Row>
          )}
          {cdr.tokenId && (
            <Table.Row>
              <Table.Cell>{t('cdrsTable.tokenId', 'Token ID')}</Table.Cell>
              <Table.Cell>
                <Link to={`/cards/tokens`}>{cdr.tokenId}</Link>
              </Table.Cell>
            </Table.Row>
          )}
          {cdr.accountId && (
            <Table.Row>
              <Table.Cell>{t('cdrsTable.accountId', 'Account ID')}</Table.Cell>
              <Table.Cell>
                <Link to={`/accounts/${cdr.accountId}`}>{cdr.accountId}</Link>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Header style={{ marginTop: '1em' }} as="h3">
        {t('cdrsTable.costsTitle', 'Costs')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.totalCosts', 'Total Costs')}</Table.Cell>
            <Table.Cell>
              {formatCurrency(parseFloat(cdr.cost.totalCost), {
                currency: cdr.cost.currency,
              })}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.currency', 'Currency')}</Table.Cell>
            <Table.Cell>{cdr.cost.currency.toUpperCase()}</Table.Cell>
          </Table.Row>
          {cdr.cost?.totalEnergyCost && (
            <Table.Row>
              <Table.Cell>
                {t('cdrsTable.totalEnergy', 'Total Energy Costs')}
              </Table.Cell>
              <Table.Cell>
                {formatCurrency(parseFloat(cdr.cost.totalEnergyCost), {
                  currency: cdr.cost.currency,
                })}
              </Table.Cell>
            </Table.Row>
          )}
          {cdr.cost?.totalFixedCost && (
            <Table.Row>
              <Table.Cell>
                {t('cdrsTable.totalFixed', 'Total Fixed Costs')}
              </Table.Cell>
              <Table.Cell>
                {formatCurrency(parseFloat(cdr.cost.totalFixedCost), {
                  currency: cdr.cost.currency,
                })}
              </Table.Cell>
            </Table.Row>
          )}
          {cdr.cost?.totalParkingCost && (
            <Table.Row>
              <Table.Cell>
                {t('cdrsTable.totalParking', 'Total Parking Costs')}
              </Table.Cell>
              <Table.Cell>
                {formatCurrency(parseFloat(cdr.cost.totalParkingCost), {
                  currency: cdr.cost.currency,
                })}
              </Table.Cell>
            </Table.Row>
          )}
          {cdr.cost?.totalReservationCost && (
            <Table.Row>
              <Table.Cell>
                {t('cdrsTable.totalReservation', 'Total Reservation Costs')}
              </Table.Cell>
              <Table.Cell>
                {formatCurrency(parseFloat(cdr.cost.totalReservationCost), {
                  currency: cdr.cost.currency,
                })}
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      <Header style={{ marginTop: '1em' }} as="h3">
        {t('cdrsTable.periodsTitle', 'Charging Periods')}
      </Header>
      <Table definition>
        <Table.Body>
          {cdr.chargingPeriods.map((period, index) => (
            <Table.Row key={period._id}>
              <Table.Cell textAlign="center" width={1}>
                {index + 1}
              </Table.Cell>
              <Table.Cell style={{ padding: '0px' }}>
                <Table
                  striped
                  definition
                  style={{ borderRadius: '0px', border: 'none' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        {t('cdrsTable.start', 'Start Time')}
                      </Table.Cell>
                      <Table.Cell>
                        {formatDateTime(period.startTime)}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{t('cdrsTable.type', 'Type')}</Table.Cell>
                      <Table.Cell>
                        {CdrChargingPeriodDimensions[period.dimension.type]}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>{t('cdrsTable.value', 'Value')}</Table.Cell>
                      <Table.Cell>{period.dimension.value}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Header style={{ marginTop: '1em' }} as="h3">
        {t('cdrsTable.locationTitle', 'Location')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.locationName', 'Name')}</Table.Cell>
            <Table.Cell>
              {cdr.location.locationId ? (
                <Link to={`locations/${cdr.location.locationId}`}>
                  {cdr.location.name}
                </Link>
              ) : (
                cdr.location.name
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.locationAddress', 'Address')}</Table.Cell>
            <Table.Cell>{cdr.location.address}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.locationCity', 'City')}</Table.Cell>
            <Table.Cell>{cdr.location.city}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('cdrsTable.locationPostalCode', 'Postal Code')}
            </Table.Cell>
            <Table.Cell>{cdr.location.postalCode}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('cdrsTable.locationCountry', 'Country')}</Table.Cell>
            <Table.Cell>{cdr.location.country}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}
