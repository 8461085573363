import { isSuperAdmin } from './roles';

export function providerIsCpoOnly(provider) {
  return (
    provider &&
    provider.enabledChargeProducts &&
    !provider.enabledChargeProducts.includes('card') &&
    !provider.enabledChargeProducts.includes('tag') &&
    provider.enabledChargeProducts.includes('charge-station')
  );
}

export const currentProviderIsEflux = () => {
  const { user, provider } = window;
  return isSuperAdmin(user) || provider.slug === 'e-flux';
};

export function setResourceProviderIdInSessionStorage(resourceProviderId) {
  const sessionStorageProviderId = sessionStorage.getItem('resourceProviderId');
  if (
    !sessionStorageProviderId ||
    sessionStorageProviderId !== resourceProviderId
  ) {
    sessionStorage.setItem('resourceProviderId', resourceProviderId);
  }
}

export function removeResourceProviderIdInSessionStorage() {
  sessionStorage.removeItem('resourceProviderId');
}
