export enum EvseOperationalStatusRoaming {
  None = 'none',
  Blocked = 'blocked',
  Inoperative = 'inoperative',
  Outoforder = 'outoforder',
  Planned = 'planned',
  Removed = 'removed',
  Reserved = 'reserved',
}

export enum EvseOperationalStatusChargingStation {
  None = 'none',
  Operative = 'operative',
  Inoperative = 'inoperative',
}

export enum EvseOperationalStatusBilling {
  None = 'none',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum EvseOperationalStatusColor {
  Red = 'red',
  Orange = 'orange',
  Yellow = 'yellow',
  Olive = 'olive',
  Green = 'green',
  Teal = 'teal',
  Blue = 'blue',
  Violet = 'violet',
  Purple = 'purple',
  Pink = 'pink',
  Brown = 'brown',
  Grey = 'grey',
  Black = 'black',
}

export enum EvseOperationalStatusType {
  Custom = 'custom',
  System = 'system',
}

export enum EvseOperationalCanonicalStatus {
  Onboarding = 'onboarding',
  Activation = 'activation',
  Live = 'live',
  Disabled = 'disabled',
}

export interface EvseOperationalStatus {
  id: string;
  name: string;
  type: EvseOperationalStatusType;
  isInitial: boolean;
  description: string;
  badgeColor: EvseOperationalStatusColor;
  roamingStatus: EvseOperationalStatusRoaming;
  chargingStationStatus: EvseOperationalStatusChargingStation;
  billingStatus: EvseOperationalStatusBilling;
  /**
   * Only present for system status
   */
  canonicalStatus?: EvseOperationalCanonicalStatus;

  /**
   * Only present for custom status
   */
  providerId?: string;
  /**
   * Only present for custom status with parent
   */
  parentEvseOperationalStatusId?: string | null;

  // Metadata
  createdAt: Date;
  updatedAt: Date;
}
