import React from 'react';
import { FieldValidator, useField } from 'formik';
import DateFieldField from 'components/form-fields/Date';
import { Label } from 'semantic';

interface Props {
  name: string;
  validate?: FieldValidator;
  label?: string;
  clearable?: boolean;
  objectMode?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  hideErrorLabel?: boolean;
  description?: string;
}

const DateField: React.FC<Props> = ({
  name,
  validate,
  label,
  required = false,
  fullWidth = true,
  hideErrorLabel = true,
  description,
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  return (
    <>
      <DateFieldField
        label={label}
        description={description}
        value={field.value}
        required={required}
        fullWidth={fullWidth}
        onChange={(e: Event, { value }: { value: unknown }) => {
          helpers.setValue(value, true);
        }}></DateFieldField>

      {/* We had to add this label to show error message box below the field */}
      {meta.error && meta.touched && !hideErrorLabel && (
        <Label
          basic
          pointing={'above'}
          style={{
            color: '#9F3A38',
            marginTop: -15,
            border: '1px solid #E0B4B4',
          }}>
          {meta.error}
        </Label>
      )}
    </>
  );
};

export default DateField;
