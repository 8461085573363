import { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { EvseController } from 'components/modals/EditEvseController/types';

export default function useTariffProfiles(
  evseController: Partial<EvseController>,
  currency: string,
  setField: (name: string, value: string) => void,
  onError?: (error) => void
) {
  const [tariffProfile, setTariffProfile] = useState(null);
  const [tariffProfiles, setTariffProfiles] = useState([]);

  useEffect(() => {
    if (!currency) {
      return;
    }
    (async () => {
      const { data: tariffProfiles } = await request({
        path: '/1/tariff-profiles/search',
        method: 'POST',
        body: { currency, limit: 200 },
      });
      setTariffProfiles(tariffProfiles);

      // check if chosen tariff profile is in the list
      if (
        !tariffProfiles.find((i) => i.id === evseController.tariffProfileId)
      ) {
        setField('tariffProfileId', '');
      }
    })().catch((error) => onError?.(error));
  }, [currency]);

  useEffect(() => {
    if (!evseController.tariffProfileId) {
      setTariffProfile(null);
      return;
    }
    (async () => {
      const { data: tariffProfile } = await request({
        method: 'GET',
        path: `/1/tariff-profiles/${evseController.tariffProfileId}`,
      });
      setTariffProfile(tariffProfile);
    })().catch((error) => onError?.(error));
  }, [evseController.tariffProfileId]);

  return { tariffProfile, tariffProfiles };
}
