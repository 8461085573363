import { useTranslation } from 'react-i18next';

type ValidationError = string | undefined;

export const useSEPAValidator = () => {
  const { t } = useTranslation();
  const validateSEPA = (value) => {
    let error: ValidationError;
    if (!value) {
      error = t(
        'settings.organization.billing.validation.sepaTerms',
        'You must accept the SEPA terms to enable the Autopay payment method.'
      );
      return error;
    }
  };

  return validateSEPA;
};

export const useIBANValidator = () => {
  const { t } = useTranslation();
  const validateIBAN = (value: string) => {
    let error: ValidationError;
    if (!value) {
      error = t(
        'settings.organization.billing.validation.iban',
        'Please enter your IBAN number'
      );
    }
    return error;
  };

  return validateIBAN;
};

export const useBICValidator = () => {
  const { t } = useTranslation();
  const validateBIC = (value: string) => {
    let error: ValidationError;
    if (!value) {
      error = t(
        'settings.organization.billing.validation.bic',
        'Please enter your BIC number'
      );
    }
    return error;
  };

  return validateBIC;
};

export const useAccountHolderNameValidator = () => {
  const { t } = useTranslation();
  const validateAccountHolderName = (value: string) => {
    let error: ValidationError;
    if (!value) {
      error = t(
        'settings.organization.billing.validation.accountHolderName',
        'Please enter your account holder name'
      );
    }
    return error;
  };

  return validateAccountHolderName;
};
