import React from 'react';
import { request } from 'utils/api';
import { SearchProvider, AppWrapper } from 'components';

import { Link } from 'react-router-dom';
import {
  Container,
  Header,
  Table,
  Divider,
  Message,
  Grid,
  Label,
  Button,
} from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { withTranslation } from 'react-i18next';
import InspectObject from 'components/modals/InspectObject';
import HelpTip from 'components/HelpTip';
import { formatDateTime } from 'utils/date';

class MyOcpiCredentials extends React.Component {
  state = {};
  constructor(props) {
    super(props);
  }

  async fetchData(body) {
    const result = await request({
      method: 'POST',
      path: '/1/ocpi-credentials/mine/search',
      body,
    });
    return result;
  }

  render() {
    const { t } = this.props;

    return (
      <AppWrapper>
        <Breadcrumbs active="OCPI Connections" />
        <Grid reversed="computer">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              OCPI Connections
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Container style={{ position: 'relative' }}>
          <SearchProvider limit={100} onDataNeeded={this.fetchData} live>
            {({ items }) => (
              <>
                <Divider hidden />
                {items.length === 0 ? (
                  <Message>
                    No OCPI connections active yet. See{' '}
                    <a href="/developer/ocpi" rel="noopener" target="_blank">
                      OCPI documentation
                    </a>{' '}
                    for onboarding procedures.
                  </Message>
                ) : (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={2}>
                          {t('ocpiCredentials.columnName', 'Name')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t('ocpiCredentials.columnRole', 'Role')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          {t('ocpiCredentials.columnStatus', 'Status')}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          {t(
                            'ocpiCredentials.columnActiveVersion',
                            'Active Version'
                          )}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('ocpiCredentials.columnCreated', 'Created')}
                          <HelpTip
                            title={t(
                              'ocpiCredentials.columnCreated',
                              'Created'
                            )}
                            text={t(
                              'ocpiCredentials.columnCreatedToolTip',
                              'This is the date and time the credential was created.'
                            )}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('ocpiCredentials.columnActions', 'Actions')}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        const isActive = !!item.activeVersion;
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <Link to={`/my-ocpi/${item.id}`}>
                                {item.name}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>
                              As a{' '}
                              {item.providerContext === 'cpo' ? 'MSP' : 'CPO'}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {isActive ? (
                                <Label color="olive" content="Active" />
                              ) : (
                                <Label content="Inactive" />
                              )}
                            </Table.Cell>
                            <Table.Cell>{item.activeVersion || '-'}</Table.Cell>
                            <Table.Cell>
                              {formatDateTime(item.createdAt)}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <InspectObject
                                name="OCPI Credential"
                                data={item}
                                trigger={
                                  <Button
                                    basic
                                    title="Inspect"
                                    icon="file-code"
                                  />
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </>
            )}
          </SearchProvider>
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(MyOcpiCredentials);
