import React from 'react';
import {
  Table,
  Container,
  Header,
  Divider,
  Segment,
  Loader,
  Label,
  Button,
} from 'semantic';
import Search from 'components/SearchInput';
import InspectObject from 'components/modals/InspectObject';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { request } from 'utils/api';

export default () => {
  const { t } = useTranslation();
  const [searchPhrase, setSearchPhrase] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    async function searchRequest() {
      if (!searchPhrase) return;
      setLoading(true);
      const res = await request({
        method: 'POST',
        path: '/1/external-tokens/search/fast',
        body: { searchPhrase: searchPhrase.trim() },
      });
      setLoading(false);
      setResults(res?.data || []);
    }
    searchRequest();
  }, [searchPhrase]);

  return (
    <Container>
      <Header as="h2">External Tokens</Header>
      <Divider hidden />
      <p>Search for external tokens</p>
      <Search
        placeholder={t(
          'externalTokens.searchPlaceholder',
          'Search by UID, Visual number, Custom ID or Contract ID'
        )}
        onChange={(searchStr) => setSearchPhrase(searchStr)}
      />
      <Divider hidden />
      {isLoading && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
      {results && !results.length && !isLoading ? (
        <p>
          <em>Nothing found</em>
        </p>
      ) : null}
      {results && results.length && !isLoading ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t('externalTokens.columnUid', 'UID')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('externalTokens.columnVisualNumber', 'Visual number')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('externalTokens.columnCustomId', 'Custom ID')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('externalTokens.columnContractId', 'Contract ID')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('externalTokens.columnValid', 'Valid')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('externalTokens.columnImportSource', 'Import source')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('externalTokens.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results.map((item) => (
              <Table.Row key={item._id}>
                <Table.Cell>{item.uid}</Table.Cell>
                <Table.Cell>{item.visualNumber}</Table.Cell>
                <Table.Cell>{item.customId}</Table.Cell>
                <Table.Cell>{item.contractId}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Label color={item.valid ? 'olive' : 'red'}>
                    {item.valid ? 'Valid' : 'Invalid'}
                  </Label>
                </Table.Cell>
                <Table.Cell>{item.importSource}</Table.Cell>
                <Table.Cell textAlign="center">
                  <InspectObject
                    name="External token"
                    data={item}
                    trigger={<Button basic icon="file-code" />}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : null}
    </Container>
  );
};
