import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic';
import DaysOfWeekInput from 'components/form-fields/DayOfWeek';
import DateObjectInput, {
  dateObjToString,
} from 'components/form-fields/DateObject';
import TimeObjectInput from 'components/form-fields/TimeObject';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';

export default function RestrictionsForm({
  value: restrictions = {},
  disabled,
  onChange,
  currency,
}) {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const [enableTimeOfDay, setEnableTimeOfDay] = useState(
    !!(restrictions.startTime || restrictions.endTime)
  );
  const [startTime, setStartTime] = useState(restrictions.startTime);
  const [endTime, setEndTime] = useState(restrictions.endTime);

  const [enableDateRange, setEnableDateRange] = useState(
    !!(restrictions.startDate || restrictions.endDate)
  );
  const [startDate, setStartDate] = useState(
    restrictions.startDate || { hour: 0, minute: 0 }
  );
  const [endDate, setEndDate] = useState(
    restrictions.endDate || { hour: 0, minute: 0 }
  );

  const [enableDaysOfWeek, setEnableDaysOfWeek] = useState(
    !!restrictions?.daysOfWeek?.length
  );
  const [daysOfWeek, setDaysOfWeek] = useState(restrictions.daysOfWeek);

  useEffect(() => {
    const nextState = {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      daysOfWeek: null,
    };
    if (enableTimeOfDay) {
      nextState.startTime = startTime;
      nextState.endTime = endTime;
    }
    if (enableDaysOfWeek) {
      nextState.daysOfWeek = daysOfWeek;
    }
    if (enableDateRange) {
      nextState.startDate = startDate;
      nextState.endDate = endDate;
    }
    onChange?.(nextState);
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    enableTimeOfDay,
    enableDaysOfWeek,
    enableDateRange,
  ]);

  return (
    <>
      <Form.Checkbox
        toggle
        label={
          <label style={{ fontWeight: 'bold' }}>
            {t('advancedTariff.timeOfDay', 'Time of Day')}
          </label>
        }
        checked={enableTimeOfDay}
        onChange={(e, { checked }) => setEnableTimeOfDay(checked)}
        disabled={disabled}
      />
      <TimeOfDayForm
        restrictions={restrictions}
        disabled={disabled || !enableTimeOfDay}
        startTime={startTime}
        onStartTimeChange={setStartTime}
        endTime={endTime}
        onEndTimeChange={setEndTime}
      />

      {advancedTariffFeatures.daysOfWeek && (
        <>
          <br />

          <Form.Checkbox
            toggle
            label={
              <label style={{ fontWeight: 'bold' }}>
                {t('advancedTariff.daysOfWeek', 'Days of Week')}
              </label>
            }
            checked={enableDaysOfWeek}
            onChange={(e, { checked }) => setEnableDaysOfWeek(checked)}
            disabled={disabled}
          />
          <DaysOfWeekInput
            allIfUnset
            disabled={disabled || !enableDaysOfWeek}
            value={daysOfWeek}
            onChange={(next) => setDaysOfWeek(next)}
          />
        </>
      )}

      {advancedTariffFeatures.dateRange && (
        <>
          <br />

          <Form.Checkbox
            toggle
            label={
              <label style={{ fontWeight: 'bold' }}>
                {t('advancedTariff.dateRange', 'Date Range')}
              </label>
            }
            checked={enableDateRange}
            onChange={(e, { checked }) => setEnableDateRange(checked)}
            disabled={disabled}
          />
          <DateRange
            restrictions={restrictions}
            disabled={disabled || !enableDateRange}
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
          />
        </>
      )}
    </>
  );
}

function TimeOfDayForm({
  disabled,
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
}) {
  const { t } = useTranslation();
  const validateEndTime = useCallback(
    (nextEndTime) => {
      if (!startTime) {
        return;
      }
      if (startTime && !nextEndTime) {
        throw new Error(
          t(
            'advancedTariff.errorEndTimeRequired',
            'end time must be set if start time is set'
          )
        );
      }
      if (nextEndTime.hour === 0 && nextEndTime.minute === 0) {
        return;
      }
      if (startTime.hour === 0 && startTime.minute === 0) {
        return;
      }
      if (
        nextEndTime.hour < startTime?.hour ||
        (nextEndTime.hour === startTime?.hour &&
          nextEndTime.minute <= startTime.minute)
      ) {
        throw new Error('end time must be after the start time');
      }
    },
    [startTime, t]
  );

  const handleStartTimeChange = (nextStartTime) => {
    onStartTimeChange(nextStartTime);
    if (nextStartTime && !endTime) {
      onEndTimeChange({ hour: 0, minute: 0 });
    }
  };
  const handleEndTimeChange = (nextEndTime) => {
    onEndTimeChange(nextEndTime);
    if (nextEndTime && !startTime) {
      onStartTimeChange({ hour: 0, minute: 0 });
    }
  };

  return (
    <>
      <Form.Group widths={'equal'}>
        <TimeObjectInput
          disabled={disabled}
          label={t('advancedTariff.startTime', 'Start Time')}
          value={startTime}
          onChange={handleStartTimeChange}
        />
        <TimeObjectInput
          disabled={disabled}
          label={t('advancedTariff.endTime', 'End Time')}
          value={endTime}
          onChange={handleEndTimeChange}
          validate={validateEndTime}
        />
        <Form.Field />
      </Form.Group>
    </>
  );
}

const isDateUnset = (dateObj) =>
  !dateObj?.year && !dateObj?.month && !dateObj?.day;

function DateRange({
  disabled,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}) {
  const { t } = useTranslation();
  const validateEndDate = useCallback(
    (nextEndDate) => {
      if (isDateUnset(startDate) || isDateUnset(nextEndDate)) {
        return;
      }
      if (dateObjToString(startDate) >= dateObjToString(nextEndDate)) {
        throw new Error(
          t(
            'advancedTariff.errorEndDateBeforeStart',
            'end date must be after the start date'
          )
        );
      }
    },
    [startDate]
  );

  return (
    <>
      <Form.Group widths={'equal'}>
        <DateObjectInput
          label={t('advancedTariff.startDate', 'Start Date')}
          disabled={disabled}
          clearable
          type={'date'}
          control={'input'}
          value={startDate}
          onChange={(next) => onStartDateChange(next)}
        />
        <DateObjectInput
          label={t('advancedTariff.endDate', 'End Date')}
          disabled={disabled}
          clearable
          type={'date'}
          control={'input'}
          value={endDate}
          onChange={(next) => onEndDateChange(next)}
          validate={validateEndDate}
        />
        <Form.Field />
      </Form.Group>
    </>
  );
}
