import { AccountProvider } from 'contexts/account';
import React from 'react';
import AccountInfoAndContact from './InfoAndContact.layout';

export default function AccountInfoAndContactContainer() {
  //TODO: Add error boundary layer
  return (
    <AccountProvider>
      <AccountInfoAndContact />
    </AccountProvider>
  );
}
