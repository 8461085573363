import {
  SettingsActiveItem,
  SettingsMenuItemProps,
} from 'types/account-settings';
import AccountSettingsMenu from '../molecules/SettingsMenu';
import { useTranslation } from 'react-i18next';
import { useFeatures } from 'contexts/features';
import { useAccount } from 'contexts/account';

interface OrganizationAccountSettingsMenuProps extends SettingsActiveItem {
  canViewBillingInformation: boolean;
  subOperatorEnabled: boolean;
  ssoModuleEnabled: boolean;
}

export default function OrganizationAccountSettingsMenu({
  activeItem,
  canViewBillingInformation,
  subOperatorEnabled,
  ssoModuleEnabled,
}: OrganizationAccountSettingsMenuProps) {
  const { t } = useTranslation();
  const { account } = useAccount();

  const showCurrentPlanUnderBilling = Boolean(
    account?.accountTierBillingPlanId
  );

  const organizationSettings: SettingsMenuItemProps[] = [
    {
      title: t(
        'settings.sidebar.organization.infoAndContact',
        'Account Info & Contact'
      ),
      route: '/settings/account/organization/info-and-contact',
      iconName: 'regular building',
    },
  ];

  if (canViewBillingInformation) {
    organizationSettings.push(
      {
        title: showCurrentPlanUnderBilling
          ? t(
              'settings.sidebar.organization.billingAndPlans',
              'Billing & Plans'
            )
          : t('settings.sidebar.organization.billing', 'Billing'),
        route: '/settings/account/organization/billing',
        iconName: 'regular credit-card ',
      },
      {
        title: t(
          'settings.sidebar.organization.reimbursement',
          'Reimbursement'
        ),
        route: '/settings/account/organization/reimbursement',
        iconName: 'regular file-lines',
      }
    );
  }

  if (subOperatorEnabled) {
    organizationSettings.push({
      title: t('settings.sidebar.organization.suboperator', 'Suboperator'),
      route: '/settings/account/organization/suboperator',
      iconName: 'regular user',
    });
  }

  if (ssoModuleEnabled) {
    organizationSettings.push({
      title: t('settings.sidebar.organization.sso', 'Single Sign On'),
      route: '/settings/account/organization/sso/saml',
      iconName: 'key',
    });
  }

  return AccountSettingsMenu({
    header: t('settings.sidebar.organization.title', 'Organization'),
    menuItems: organizationSettings,
    activeItem,
  });
}
