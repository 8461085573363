import React from 'react';
import Pagination from 'components/Pagination';

import SearchContext from './Context';

export default class SearchPagination extends React.Component {
  static contextType = SearchContext;

  render() {
    const { items, page, meta } = this.context;
    if (!items.length || meta.total <= meta.limit) {
      return null;
    }

    return (
      <div style={{ margin: 'auto', width: '100%', textAlign: 'center' }}>
        <Pagination
          page={page}
          limit={meta.limit}
          total={meta.total}
          boundaryRange={1}
          onPageChange={this.context.onPageChange}
        />
      </div>
    );
  }
}
