import React from 'react';
import AppWrapper from 'components/AppWrapper';

import DataList from 'components/DataList';

import { request } from 'utils/api';

import Table from 'components/tables/EvseIssues';

import { Link } from 'react-router-dom';
import { Container, Header, Divider, Menu, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import { withTranslation } from 'react-i18next';

const itemLimit = 100;

class EvseControllersMaintenanceIssues extends React.Component {
  state = {
    toggledItems: {},
    dateKey: Date.now(),
    isResolved: false,
  };

  handleResolveItem = (item) => {
    return request({
      method: 'POST',
      path: `/1/evse-issues/maintenance/${item.id}/resolve`,
    }).then(() => this.refresh());
  };

  handleRequest = (filters = {}) => {
    const { isResolved } = this.state;
    return request({
      method: 'POST',
      path: '/1/evse-issues/maintenance/search',
      body: {
        ...filters,
        ...(isResolved ? { isResolved } : {}),
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { isResolved } = this.state;
    const { t } = this.props;
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="home" to="/maintenance/evse-controllers">
                {t('menu.maintenanceHome', 'Maintenance')}
              </Link>,
              <Link
                key="evseControllerMaintenance"
                to="/maintenance/evse-controllers">
                {t('evseControllerMaintenance.title', 'EVSE Maintenance')}
              </Link>,
            ]}
            active={t('menu.maintenanceIssues', 'Detected Issues')}
          />
          <Grid reversed="computer">
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('menu.maintenanceIssues', 'Detected Issues')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Menu pointing secondary stackable>
            <Menu.Item
              content={t('evseControllerMaintenance.tabOpen', 'Open')}
              active={isResolved === false}
              onClick={() => {
                this.setState({ isResolved: false });
                this.refresh();
              }}
            />
            <Menu.Item
              content={
                ('evseControllerMaintenance.tabResolvedIssues', 'Resolve')
              }
              active={isResolved === true}
              onClick={() => {
                this.setState({ isResolved: true });
                this.refresh();
              }}
            />
          </Menu>

          <Divider hidden />

          <DataList
            key={this.state.dataKey}
            limit={itemLimit}
            onRequest={this.handleRequest}>
            <Table
              filters={this.state.filters}
              onResolveItem={(item) => this.handleResolveItem(item)}
              isResolved={isResolved}
            />
          </DataList>
          <Divider hidden />
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(EvseControllersMaintenanceIssues);
