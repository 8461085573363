import React, { useEffect, useState } from 'react';
import AppWrapper from 'components/AppWrapper';
import { Link } from 'react-router-dom';
import { Container, Divider, Header } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'components';
import { request } from 'utils/api';
import DataList from 'components/DataList';
import Table from './Table';

const itemLimit = 100;

export default function CreditSessions() {
  const [dataKey, setDataKey] = useState(Date.now());
  const { t } = useTranslation();

  const handleRequest = (filters = {}) => {
    return request({
      method: 'POST',
      path: `/1/credit-sessions/search`,
      body: {
        ...filters,
        limit: itemLimit,
      },
    });
  };

  const deleteCreditSession = (creditSessionId) => {
    return request({
      method: 'DELETE',
      path: `/1/credit-sessions/${creditSessionId}`,
    }).then(() => setDataKey(Date.now()));
  };

  useEffect(() => {
    handleRequest();
  }, []);

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link key="cards" to="/cards">
              {t('cards.title', 'Cards')}
            </Link>,
          ]}
          active={t('creditSessions.title', 'Credit Sessions')}
        />
        <Header as="h2" style={{ margin: '0' }}>
          {t('creditSessions.title', 'Sessions')}
        </Header>
        <Divider hidden />

        <DataList key={dataKey} limit={itemLimit} onRequest={handleRequest}>
          <Table onCreditSessionDelete={deleteCreditSession} />
        </DataList>

        <Divider hidden />
      </Container>
    </AppWrapper>
  );
}
