import React, { useEffect, useState } from 'react';
import ProviderForm from 'components/forms/ProviderForm/ProviderForm';
import { useParams } from 'react-router-dom';
import { Provider } from 'types/provider';
import { request } from 'utils/api';

const ProviderDetail: React.FC<never> = () => {
  const [provider, setProvider] = useState<Partial<Provider>>();
  const { id } = useParams();

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { data } = await request({
          path: `/1/providers/${id}`,
          method: 'GET',
        });
        setProvider(data);
      } catch (e) {
        console.error('provider fetch console error', id, e);
      }
    };
    fetchProvider();
  }, [id]);

  return (
    <ProviderForm
      provider={provider}
      onSubmit={(formValues) =>
        request({
          method: 'PATCH',
          path: `/1/providers/${id}`,
          body: formValues,
        })
      }
    />
  );
};

export default ProviderDetail;
