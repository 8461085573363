import { BillingAndRequestCardFormValues } from 'components/BillingInfo/types';
import { FormikProps } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalAndAccountDetails } from 'screens/Auth/SignupV2/steps/PersonalDetails';
import { ValidationError } from 'types/api';

export function useSignupErrorFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (
      error: ValidationError,
      formikProps:
        | FormikProps<PersonalAndAccountDetails>
        | FormikProps<BillingAndRequestCardFormValues>
        | null
    ) => {
      if (!formikProps) {
        return;
      }
      switch (error.code) {
        case 'SIGNUP_EMAIL_ALREADY_REGISTERED':
          formikProps.setFieldError(
            'email',
            t('signup.errors.emailAlreadyRegistered', 'Email is already in use')
          );
          break;
        case 'SIGNUP_INVALID_EMAIL':
          formikProps.setFieldError(
            'email',
            t(
              'signup.errors.invalidEmail',
              'Please enter a valid email address'
            )
          );
          break;
        case 'SIGNUP_TERMS_AND_CONDITIONS_NOT_ACCEPTED':
          formikProps.setFieldError(
            'acceptedTerms',
            t(
              'signup.errors.termsAndConditionsNotAccepted',
              'Accepting the terms and conditions is required for creating a new account'
            )
          );
          break;
        case 'SIGNUP_INVALID_PASSWORD':
          formikProps.setFieldError(
            'password',
            t(
              'signup.errors.invalidPassword',
              'Password must contain at least 8 characters, with one letter, one number and one special character'
            )
          );
          break;
        case 'SIGNUP_PASSWORD_TOO_LONG':
          formikProps.setFieldError(
            'password',
            t(
              'signup.errors.passwordTooLong',
              'Password must be less than 100 characters'
            )
          );
          break;
        case 'SIGNUP_INVALID_COUNTRY':
          formikProps.setFieldError(
            'countryCode',
            t('signup.errors.invalidCountry', 'Please select a valid country')
          );
          break;
        case 'SIGNUP_INVALID_LANGUAGE_CODE':
          formikProps.setFieldError(
            'languageCode',
            t(
              'signup.errors.invalidLanguageCode',
              'Please select a valid language'
            )
          );
          break;
        case 'SIGNUP_INVALID_PHONE_NUMBER':
          formikProps.setFieldError(
            'phoneNo',
            t(
              'signup.errors.invalidPhoneNumber',
              'Please enter a valid phone number'
            )
          );
          break;
        case 'SIGNUP_VAT_REQUIRED_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.vatNumber',
            t(
              'signup.errors.vatRequiredForCountry',
              'VAT number is required for the selected country'
            )
          );
          break;
        case 'SIGNUP_VAT_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.vatNumber',
            t(
              'signup.errors.vatInvalidForCountry',
              'VAT number is invalid for the selected country'
            )
          );
          break;
        case 'SIGNUP_COC_REQUIRED_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.chamberOfCommerceNumber',
            t(
              'signup.errors.cocNumberRequiredForCountry',
              'Chamber of Commerce number is required for the selected country'
            )
          );
          break;
        case 'SIGNUP_COC_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.chamberOfCommerceNumber',
            t(
              'signup.errors.cocNumberInvalidForCountry',
              'Chamber of Commerce number is invalid for the selected country'
            )
          );
          break;
        case 'SIGNUP_PAYMENT_METHOD_NOT_ALLOWED':
          formikProps.setFieldError(
            'billing.paymentMethod',
            t(
              'signup.errors.paymentMethodNotAllowed',
              'This payment method is not available for the selected country'
            )
          );
          break;
        case 'SIGNUP_INVALID_PAYMENT_METHOD':
          formikProps.setFieldError(
            'billing.paymentMethod',
            t(
              'signup.errors.invalidPaymentMethod',
              'Please select a valid payment method'
            )
          );
          break;
        case 'SIGNUP_SEPA_TERMS_NOT_ACCEPTED':
          formikProps.setFieldError(
            'billing.sepaAccepted',
            t(
              'signup.errors.sepaTermsNotAccepted',
              'Please accept the SEPA terms and conditions'
            )
          );
          break;
        case 'SIGNUP_AUTOPAY_MISSING_FIELDS':
          formikProps.setFieldError(
            'billing.paymentMethod',
            t(
              'signup.errors.autopayMissingFields',
              'Account Holder Name and IBAN are required for automatic payment'
            )
          );
          break;
        case 'SIGNUP_INVALID_IBAN':
          formikProps.setFieldError(
            'billing.ibanNumber',
            t('signup.errors.invalidIban', 'Please enter a valid IBAN')
          );
          break;
        case 'SIGNUP_INVALID_BIC':
          formikProps.setFieldError(
            'billing.bicNumber',
            t('signup.errors.invalidBic', 'Please enter a valid BIC')
          );
          break;
        case 'SIGNUP_INVALID_POSTAL_CODE_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.postalCode',
            t(
              'signup.errors.invalidPostalCodeForCountry',
              'Please enter a valid postal code'
            )
          );
          break;
        default:
          console.error('Unhandled signup error', error);
      }
    },
    [t]
  );
}
