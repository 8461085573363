import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { Container, Message } from 'semantic';
import { useTranslation } from 'react-i18next';

import { AppWrapper } from 'components';
import List from './List';
import Detail from './Detail';
import FirmwareUpdate from './FirmwareUpdate';
import ChangeConfiguration from './ChangeConfiguration';
import ExecuteCommand from './ExecuteCommand';
import {
  EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH,
  hasEvseControllersBackgroundJobsPermissions,
} from './utils';

export default function EvseControllerBackgroundJobsIndex() {
  const { t } = useTranslation();
  const hasAccess = hasEvseControllersBackgroundJobsPermissions('read');

  return (
    <AppWrapper>
      {!hasAccess && (
        <Message
          error
          content={t(
            'evseControllersBackgroundJobs.noFeature',
            'You do not have access to this feature.'
          )}
        />
      )}

      {hasAccess && (
        <Container>
          <Switch>
            <Route
              path={EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}
              component={List}
              exact
            />
            <Route
              path={EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH}
              component={FirmwareUpdate}
              exact
            />
            <Route
              path={
                EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH
              }
              component={ChangeConfiguration}
              exact
            />
            <Route
              path={EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH}
              component={ExecuteCommand}
              exact
            />
            <Route
              path={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/:id`}
              component={Detail}
              exact
            />
          </Switch>
        </Container>
      )}
    </AppWrapper>
  );
}
