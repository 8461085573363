import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Divider } from 'semantic';

import { PersonalAndAccountDetails } from 'screens/Auth/SignupV2/steps/PersonalDetails';

type VerifyEmailProps = {
  personalAndAccountDetails: PersonalAndAccountDetails;
};

export default function VerifyEmail({
  personalAndAccountDetails,
}: VerifyEmailProps) {
  const { t } = useTranslation();
  const email = personalAndAccountDetails.email;
  return (
    <>
      <Divider hidden />
      <p>
        <Trans i18nKey={'signup.verifyEmailMessage1'} email={email}>
          We just sent you an e-mail to <strong>{{ email }}</strong>.
        </Trans>
      </p>
      <p>
        {t(
          'signup.verifyEmailMessage2',
          'Please confirm your account creation by clicking the button within the e-mail we sent you.'
        )}
      </p>
    </>
  );
}
