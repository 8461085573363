import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Modal, Form, Message, Button, Divider, Header } from 'semantic';
import modal from 'helpers/modal';
import { request } from 'utils/api';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';

type EditFacilitatorAccountPermissionsProps = {
  accountId: string;
  onClose?: () => void;
};

function EditFacilitatorAccountPermissions({
  accountId,
  close,
  onClose,
}: EditFacilitatorAccountPermissionsProps & { close: () => void }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState<any>({});

  useEffect(() => {
    if (!accountId) {
      return;
    }
    setLoading(true);
    request({
      method: 'GET',
      path: `/1/facilitator-permissions/${accountId}`,
    })
      .then((response) => {
        setFormValues({ ...response.data });
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accountId]);

  const onSave = () => {
    setLoading(true);
    request({
      method: 'POST',
      path: `/1/facilitator-permissions/${accountId}`,
      body: formValues,
    })
      .then(() => {
        close();
        onClose?.();
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal.Header>
        {t('editFacilitatorPermissions.title', 'Facilitator Permissions')}
      </Modal.Header>
      <Modal.Content>
        {error && <Message error content={error.message} />}
        <p>
          {t(
            'editFacilitatorPermissions.description',
            'Define access permissions for locations facilitators.'
          )}
        </p>
        <Divider hidden />
        <Form
          id="EditFacilitatorPermissions-form"
          error={Boolean(error)}
          onSubmit={onSave}>
          <SelectPermission
            disabled={loading || error}
            formValues={formValues}
            setFormValues={setFormValues}
            header={t(
              'editFacilitatorPermissions.cpoLocationsHeader',
              'Location Permissions'
            )}
            readWriteLabel={t(
              'editFacilitatorPermissions.cpoLocationsReadWrite',
              'Allow editing of locations'
            )}
            endpoint="cpoLocations"
            minLevel={'read'}
          />
          <SelectPermission
            disabled={loading || error}
            formValues={formValues}
            setFormValues={setFormValues}
            header={t(
              'editFacilitatorPermissions.costSettingsHeader',
              'Cost Settings'
            )}
            readLabel={t(
              'editFacilitatorPermissions.costSettingsRead',
              'Allow viewing of cost settings on EVSE'
            )}
            readWriteLabel={t(
              'editFacilitatorPermissions.costSettingsReadWrite',
              'Allow viewing & editing of cost settings on EVSE'
            )}
            endpoint="evseTariffs"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={loading || error}
          primary
          content={t('editFacilitatorPermissions.save', 'Save')}
          form="EditFacilitatorPermissions-form"
        />
      </Modal.Actions>
    </>
  );
}

const EditFacilitatorAccountPermissionsModal = modal(
  EditFacilitatorAccountPermissions
);

const FeatureFlaggedEditFacilitatorAccountPermissions = (
  props: EditFacilitatorAccountPermissionsProps & { trigger: ReactElement }
) => {
  const { canAccess, hasPlatformFeature } = useUser();

  const showFacilitatorPermissions =
    hasPlatformFeature('location-facilitator:custom-permissions') &&
    canAccess('users', 'read');

  if (!showFacilitatorPermissions) {
    return null;
  }
  return <EditFacilitatorAccountPermissionsModal {...props} />;
};

export default FeatureFlaggedEditFacilitatorAccountPermissions;

function SelectPermission({
  disabled,
  header,
  endpoint,
  formValues,
  setFormValues,
  minLevel = 'none',
  maxLevel = 'read-write',
  readLabel,
  readWriteLabel,
}) {
  const { t } = useTranslation();
  const options = useMemo(() => {
    const permissionOptions = ['none', 'read', 'read-write'];
    const minIndex = permissionOptions.findIndex((p) => p === minLevel);
    const maxIndex = permissionOptions.findIndex((p) => p === maxLevel);
    return permissionOptions.slice(minIndex + 1, maxIndex + 1);
  }, [t, minLevel, maxLevel]);

  const permission = formValues.permissions?.[endpoint];

  return (
    <>
      <Header as={'h5'}>{header}</Header>

      {options.map((option) => {
        if (option === 'read') {
          return (
            <Form.Group inline>
              <Form.Checkbox
                disabled={disabled}
                toggle
                checked={permission === 'read'}
                onChange={(e, { checked }) => {
                  setFormValues({
                    ...formValues,
                    permissions: {
                      ...formValues.permissions,
                      [endpoint]: checked ? 'read' : minLevel,
                    },
                  });
                }}
              />
              <label>{readLabel}</label>
            </Form.Group>
          );
        }
        if (option === 'read-write') {
          return (
            <Form.Group inline>
              <Form.Checkbox
                disabled={disabled}
                toggle
                checked={permission === 'read-write'}
                onChange={(e, { checked }) => {
                  setFormValues({
                    ...formValues,
                    permissions: {
                      ...formValues.permissions,
                      [endpoint]: checked ? 'read-write' : minLevel,
                    },
                  });
                }}
              />
              <label>{readWriteLabel}</label>
            </Form.Group>
          );
        }
      })}
    </>
  );
}
