import React, { useState } from 'react';
import { Modal } from 'semantic';
import { useTranslation } from 'react-i18next';

import { BatchItem, Mapping } from 'components/modals/ImportTokenBatch/types';
import { CommitScreen } from 'components/modals/ImportTokenBatch/CommitScreen';
import { PreviewScreen } from 'components/modals/ImportTokenBatch/PreviewScreen';
import { UploadScreen } from 'components/modals/ImportTokenBatch/UploadScreen';
import modal from 'helpers/modal';
import {
  BatchDetailsScreen,
  BatchDetails,
} from 'components/modals/ImportTokenBatch/BatchDetailsScreen';

type State = {
  step: number;
  items: BatchItem[];
  mapping: Mapping;
  numColumnsMatched: number;
  fileUploadError: Error | null;
  open: boolean;
  batchDetails: BatchDetails;
};

const defaultState: State = {
  step: 1,
  items: [],
  mapping: {},
  numColumnsMatched: 0,
  fileUploadError: null,
  open: false,
  batchDetails: { note: '', tokenType: '' },
};

type ImportTokenBatchProps = {
  close(): void;
};

function ImportTokenBatch({ close }: ImportTokenBatchProps) {
  const [state, setState] = useState<State>({ ...defaultState });
  const { t } = useTranslation();

  const onGoToPreviousStep = () => {
    setState({
      ...state,
      step: state.step - 1,
    });
  };

  const onSubmitBatchDetails = (values: BatchDetails) => {
    setState({
      ...state,
      batchDetails: values,
      step: 2,
    });
  };

  const onReset = () => {
    setState({ ...defaultState, step: 2 });
  };

  const onUploadSuccess = (
    items: BatchItem[],
    mapping: Mapping,
    numColumnsMatched: number
  ) => {
    setState({
      ...state,
      items,
      mapping,
      numColumnsMatched,
      step: 3,
    });
  };

  const onUploadFailed = (fileUploadError: Error) => {
    setState({
      ...state,
      fileUploadError,
      step: 3,
    });
  };

  const onStartImport = () => {
    setState({
      ...state,
      step: 4,
    });
  };

  const onDone = () => {
    setState(defaultState);
    close();
  };

  const {
    step,
    fileUploadError,
    items,
    mapping,
    numColumnsMatched,
    batchDetails,
  } = state;
  return (
    <>
      <Modal.Header>
        {t('tokenBatches.import.header', 'Import Token Batch')}
      </Modal.Header>
      {step === 1 && (
        <BatchDetailsScreen
          initialValues={batchDetails}
          onSubmit={onSubmitBatchDetails}
        />
      )}
      {step === 2 && (
        <UploadScreen
          onUploadSuccess={onUploadSuccess}
          onUploadFailed={onUploadFailed}
          onGoToPreviousStep={onGoToPreviousStep}
        />
      )}
      {step === 3 && (
        <PreviewScreen
          fileUploadError={fileUploadError}
          items={items}
          mapping={mapping}
          numColumnsMatched={numColumnsMatched}
          onReset={onReset}
          onStartImport={onStartImport}
        />
      )}
      {step === 4 && (
        <CommitScreen
          items={items}
          batchDetails={batchDetails}
          onDone={onDone}
        />
      )}
    </>
  );
}

export default modal(ImportTokenBatch);
