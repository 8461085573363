import React from 'react';
import { Form, Message, Button, Modal } from 'semantic';
import { request } from 'utils/api';
import { withTranslation } from 'react-i18next';
import CreateJobSelectionPage from './CreateJobSelectionPage';
import CreateJobResultPage from './CreateJobResultPage';
import modal from 'helpers/modal';
import { startOfMonth, endOfMonth } from 'date-fns';

function arrayToSelectValues(array) {
  return array.map((item) => {
    return { key: item, text: item, value: item };
  });
}

class CreateJob extends React.Component {
  state = {
    currentPage: 1,
    amountOfPages: 2,
    loading: false,
    popupIsOpen: false,
    formValues: this.getDefaultFormValues(),
    collections: {},
    dateFilterTypes: ['createdAt', 'endedAt', 'updatedAt'],
    collectionCount: undefined,
    collectionCountLimit: 500000,
  };

  constructor(props) {
    super(props);
    this.onOpenPopup = this.onOpenPopup.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.setField = this.setField.bind(this);
    this.getExportPreviewData = this.getExportPreviewData.bind(this);
  }

  getCollectionFields() {
    return this.state.collections[this.state.formValues.collectionName]?.fields;
  }

  maySubmit() {
    return (
      this.state.collectionCount &&
      this.state.collectionCount < this.state.collectionCountLimit &&
      !this.state.loading
    );
  }

  getMappedDropdownCollectionFields() {
    return (this.getCollectionFields() || []).map((item) => {
      return { key: item, text: item, value: item };
    });
  }

  getCollectionNames() {
    return arrayToSelectValues(Object.keys(this.state.collections));
  }

  getDateFilterTypes() {
    return arrayToSelectValues(this.state.dateFilterTypes);
  }

  getDefaultFormValues() {
    return {
      collectionName: undefined,
      collectionFields: [],
      dateFilterTypes: ['createdAt'],
      dateFrom: startOfMonth(new Date()),
      dateTo: endOfMonth(new Date()),
    };
  }

  async getExportPreviewData() {
    const response = await request({
      path: '/1/finance/exports/jobs/collectionTypes/preview',
      method: 'POST',
      body: { ...this.state.formValues },
    });

    this.setState({
      // Don't show data preview until we know how we want to handle it.
      // exportPreviewData: response.data,
      collectionCount: response.meta.count,
    });
  }

  async getCollections() {
    const collections = await request({
      path: '/1/finance/exports/jobs/collectionTypes',
      method: 'GET',
    });

    const defaultCollection = Object.keys(collections)[0];

    this.setState({
      collections,
      formValues: {
        ...this.state.formValues,
        collectionName: defaultCollection,
        collectionFields: collections[defaultCollection]?.fields,
      },
    });
  }

  async setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        // If the collection is changed, remove all previously selected collection fields and add all fields from the newly selected collection.
        ...(name === 'collectionName' && {
          collectionFields: this.state.collections[value]?.fields,
        }),
        [name]: value,
      },
    });
  }

  handleSubmit = async () => {
    this.setState({ loading: true, error: null });

    try {
      await request({
        path: '/1/finance/exports/jobs/create',
        method: 'POST',
        body: this.state.formValues,
      });
      this.props.onSave();

      this.clearState();
    } catch (error) {
      this.setState({
        error: error,
        loading: false,
      });
    }
  };

  clearState() {
    this.setState({
      currentPage: 1,
      formValues: this.getDefaultFormValues(),
      collectionCount: undefined,
      collections: {},
      loading: false,
      popupIsOpen: false,
    });
  }

  onOpenPopup() {
    this.clearState();
    this.setState({ popupIsOpen: true });

    this.getCollections();
  }

  nextPage() {
    this.setState({
      currentPage: this.state.currentPage + 1,
    });
  }

  previousPage() {
    this.setState({
      currentPage: this.state.currentPage - 1,
      exportPreviewData: undefined,
      collectionCount: undefined,
    });
  }

  render() {
    const { loading, error, formValues } = this.state;
    const { t } = this.props;

    return (
      <>
        <Modal.Header>
          {t('financeExports.createExportJob', 'Create export job')}
        </Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message}></Message>}
          <Form onSubmit={() => this.handleSubmit} error={true}>
            {this.state.currentPage === 1 && (
              <CreateJobSelectionPage
                error={error}
                formValues={formValues}
                mappedDropdownCollectionFields={this.getMappedDropdownCollectionFields()}
                collectionNames={this.getCollectionNames()}
                dateFilterTypes={this.getDateFilterTypes()}
                loading={loading}
                t={t}
                setField={this.setField}
                onHandleSearchChange={this.onHandleSearchChange}
                nextPage={this.nextPage}
              />
            )}
            {this.state.currentPage === 2 && (
              <CreateJobResultPage
                formValues={this.state.formValues}
                collectionCount={this.state.collectionCount}
                collectionCountLimit={this.state.collectionCountLimit}
                selectedCollectionFields={
                  this.state.formValues.collectionFields
                }
                exportPreviewData={this.state.exportPreviewData}
                getExportPreviewData={this.getExportPreviewData}
                t={t}
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {this.state.currentPage === this.state.amountOfPages ? (
            <>
              <Button
                onClick={this.previousPage}
                secondary
                content={t('financeExports.createJob.previousPage', 'Previous')}
              />
              <Button
                type="submit"
                loading={loading}
                disabled={!this.maySubmit()}
                primary
                onClick={this.handleSubmit}
                content={t(
                  'financeExports.createExportJob',
                  'Create export job'
                )}
              />
            </>
          ) : (
            <Button
              onClick={this.nextPage}
              primary
              content={t('financeExports.createJob.nextPage', 'Next')}
            />
          )}
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(CreateJob));
