import React, { Component, ErrorInfo, isValidElement } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from './types';

const initialState = { error: null, info: null };

interface ErrorBoundaryState {
  error: Error | null;
  info: ErrorInfo | null;
}

/**
 * React error boundaries are written as class components
 * because error boundaries rely on the componentDidCatch lifecycle method
 * to catch JavaScript errors anywhere in a component tree below them.
 */
export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = initialState;
  }

  resetErrorBoundary = () => {
    this.props.onReset?.();
    this.setState(initialState);
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // TODO: check which errors need to be captured
    Sentry.captureException(error);
    this.setState({ error, info });
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps.children !== this.props.children) {
      this.resetErrorBoundary();
    }
  }

  render() {
    const { error, info } = this.state;
    const { children, fallback, fallbackRender, FallbackComponent } =
      this.props;

    if (error !== null) {
      const props = {
        componentStack: info?.componentStack,
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };
      if (isValidElement(fallback)) {
        return fallback;
      } else if (typeof fallbackRender === 'function') {
        return fallbackRender(props);
      } else if (typeof FallbackComponent === 'function') {
        return <FallbackComponent {...props} />;
      } else {
        throw new Error(
          'the ErrorBoundary requires either a fallback, fallbackRender, or FallbackComponent prop'
        );
      }
    }
    return children;
  }
}
