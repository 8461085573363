import { useState, useEffect } from 'react';
import { request } from 'utils/api';

export default function useAccountHasFeature(
  featureName: string,
  accountId: string
) {
  const [hasFeature, setHasFeature] = useState(false);

  useEffect(() => {
    if (!accountId) {
      setHasFeature(false);
    } else {
      request({
        method: 'GET',
        path: `/1/platform-modules/account/${accountId}`,
      })
        .then(({ data }) => {
          setHasFeature(
            !!data.find(
              (feature: { key: string }) => feature.key === featureName
            )
          );
        })
        .catch((err) => {
          console.error(err);
          setHasFeature(false);
        });
    }
  }, [accountId]);
  return hasFeature;
}
