import React from 'react';
import { BarChart, Bar } from 'recharts';
import { useTheme } from 'contexts/theme';

const WIDTH = 100;
const HEIGHT = 50;

export default function SparkChart({ timeSeries }) {
  const { renderedTheme } = useTheme();
  // minHeight ~= 1px
  const max = Math.max(...timeSeries.map((point) => point.count));
  const minHeight = max / HEIGHT;

  return (
    <BarChart
      width={WIDTH}
      height={HEIGHT}
      data={timeSeries.map((point) => ({
        count: point.count === 0 ? minHeight : point.count,
        day: point.day,
      }))}>
      {/*<YAxis hide={true} domain={[0, maxCount]} />*/}
      <Bar
        dataKey="count"
        fill={
          renderedTheme == 'dark'
            ? 'rgba(255,255,255,.5)'
            : 'rgba(0, 0, 0, 0.5)'
        }
      />
    </BarChart>
  );
}
