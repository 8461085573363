import { Message } from 'semantic-ui-react';
import { Button, Table, Modal } from 'semantic';
import { get } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BatchItem, Mapping } from 'components/modals/ImportTokenBatch/types';

const UPLOAD_LIMIT = 10_000;

type Props = {
  items: BatchItem[];
  mapping: Mapping;
  numColumnsMatched: number;
  fileUploadError: Error | null;
  onReset: () => void;
  onStartImport: () => void;
};

export function PreviewScreen({
  fileUploadError,
  items,
  mapping,
  numColumnsMatched,
  onReset,
  onStartImport,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Content>
        {((): React.ReactNode => {
          if (items.length > UPLOAD_LIMIT) {
            return (
              <Message
                error
                content={t(
                  'tokenBatch.uploadLimitExceeded',
                  'You cannot upload more than {{limit}} tokens at a time',
                  { limit: UPLOAD_LIMIT.toString() }
                )}
              />
            );
          }

          if (fileUploadError) {
            return <Message error content={fileUploadError.message} />;
          }

          return (
            <div>
              <p>
                {t(
                  'tokenBatch.analyzingDataSuccess',
                  'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
                  {
                    numColumnsMatched,
                    itemsCount: items.length,
                  }
                )}
              </p>
              <div style={{ overflowX: 'auto', width: '100%' }}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      {Object.keys(mapping).map((key) => (
                        <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.slice(0, 5).map((item, i) => (
                      <Table.Row key={i}>
                        {Object.keys(mapping).map((key) => (
                          <Table.Cell key={key}>{get(item, key)}</Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </div>
          );
        })()}
      </Modal.Content>
      <Modal.Actions>
        <Button
          // @ts-ignore
          content={t('tokenBatches.import.reset', 'Reset')}
          icon="arrow-rotate-right"
          onClick={onReset}
        />
        <Button
          // @ts-ignore
          content={t('importSessions.import', 'Import')}
          icon="check"
          primary
          disabled={!!fileUploadError}
          onClick={onStartImport}
        />
      </Modal.Actions>
    </>
  );
}
