import { Button } from 'semantic';
import * as PropTypes from 'prop-types';
import React from 'react';

function ExpandJsonButton({ onClick, collapsedLevel }) {
  return (
    <Button
      basic
      content={collapsedLevel ? 'Expand' : 'Collapse'}
      icon={collapsedLevel ? 'expand' : 'compress'}
      onClick={onClick}
    />
  );
}

ExpandJsonButton.propTypes = {
  collapsedLevel: PropTypes.number,
  onClick: PropTypes.func,
};

export default ExpandJsonButton;
