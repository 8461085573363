import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ActionButton from 'components/semantic/ActionButton';
import { Icon } from 'semantic';
import React from 'react';

type LoginPanelProps = {
  text: string;
  textAlign?: 'center' | 'right';
  redirectTo: string;
};

export default function LoginPanel({
  textAlign = 'center',
  text,
  redirectTo,
}: LoginPanelProps) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        textAlign,
      }}>
      <strong>{text}</strong>
      <br />
      <NavLink to={`/login?redirect=${redirectTo}`}>
        <ActionButton primary style={{ paddingLeft: 0, paddingRight: 0 }}>
          {t('signup.login', 'Login')}
          <Icon
            name={'arrow-right'}
            style={{ marginLeft: '0.5em', marginRight: 0 }}
          />
        </ActionButton>
      </NavLink>
    </div>
  );
}
