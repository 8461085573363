import { useFilterContext } from 'components/search/Context';
import React from 'react';
import { Form } from 'semantic';

type InputProps = {
  name: string;
  label?: string;
  onChange?: (value: any) => void;
  reloadOnChange?: boolean;
};

export function InputFilterV2(props: InputProps) {
  const filterContext = useFilterContext();

  const { name, ...rest } = props;

  const value: string = filterContext.filters[props.name];

  const clearIcon = {
    name: value ? 'close' : '',
    link: true,
    onClick: (evt: React.ChangeEvent) => {
      if (value) {
        filterContext.onFilterChange({ name, value: '' });
      }
      evt.target.closest('.input')?.querySelector('input')?.focus();
    },
  };

  return (
    <Form.Input
      name={props.name}
      icon={clearIcon}
      value={value ?? ''}
      onChange={(_: React.ChangeEvent<HTMLInputElement>, { name, value }) => {
        filterContext.onFilterChange({ name, value });
        props.onChange?.(value);
        if (props.reloadOnChange) {
          filterContext.reload();
        }
      }}
    />
  );
}
