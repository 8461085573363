import { getNewAdvancedTariffComponent } from 'components/tariffs/AdvancedTariff/factory';
import { useCallback, useRef, useState } from 'react';

export function ensureUiKeys(advancedTariff) {
  advancedTariff._uiKey ||= Math.random();
  advancedTariff?.components?.forEach?.((c) => (c._uiKey ||= Math.random()));
  return advancedTariff;
}

export type TariffSystem = 'basic' | 'scheduled' | 'dynamic';
const resolveCurrentTariffSystem = (advancedTariff): TariffSystem => {
  if (advancedTariff?.components?.length) {
    return 'scheduled';
  }
  if (advancedTariff?.enableEnergyMarketTracking) {
    return 'dynamic';
  }
  return 'basic';
};

export function useAdvancedTariffState(advancedTariff) {
  const initialState = useRef(advancedTariff);
  // Hold on to the scheduled tariffs, so we can restore them when switching back from static/dynamic
  const scheduledTariffs = useRef(advancedTariff.components || []);

  const [tariffSystem, setTariffSystem] = useState<TariffSystem>(
    resolveCurrentTariffSystem(advancedTariff)
  );

  const addComponent = useCallback(
    (component = getNewAdvancedTariffComponent({ isNew: true })) => {
      return ensureUiKeys({
        ...advancedTariff,
        components: [...(advancedTariff.components || []), component],
      });
    },
    [advancedTariff]
  );

  const handleTariffSystemChange = useCallback(
    (next: TariffSystem) => {
      setTariffSystem(next);
      switch (next) {
        case 'basic':
          scheduledTariffs.current = advancedTariff.components;
          return ensureUiKeys({
            ...advancedTariff,
            enableEnergyMarketTracking: false,
            surchargePerKwh: 0,
            components: [],
          });
        case 'scheduled':
          return ensureUiKeys({
            ...advancedTariff,
            components: scheduledTariffs.current,
            enableEnergyMarketTracking: false,
            surchargePerKwh: 0,
          });
        case 'dynamic':
          scheduledTariffs.current = advancedTariff.components;
          return ensureUiKeys({
            ...advancedTariff,
            enableEnergyMarketTracking: true,
            components: [],
          });
      }
    },
    [advancedTariff]
  );

  const removeComponent = useCallback(
    (index) => {
      const nextComponents = [...advancedTariff.components];
      nextComponents.splice(index, 1);
      return ensureUiKeys({
        ...advancedTariff,
        components: nextComponents,
      });
    },
    [advancedTariff]
  );

  const updateFallback = useCallback(
    (nextFallback) => {
      return ensureUiKeys({
        ...advancedTariff,
        ...nextFallback.costSettings,
      });
    },
    [advancedTariff]
  );

  const updateComponent = useCallback(
    (nextComponent, index) => {
      const nextComponents = [...advancedTariff.components];
      nextComponents[index] = {
        ...advancedTariff.components[index],
        ...nextComponent,
      };
      return ensureUiKeys({
        ...advancedTariff,
        components: nextComponents,
      });
    },
    [advancedTariff]
  );

  const revertChanges = useCallback(() => {
    return ensureUiKeys(initialState.current);
  }, []);

  return {
    value: ensureUiKeys(advancedTariff),
    addComponent,
    removeComponent,
    updateFallback,
    updateComponent,
    tariffSystem,
    setTariffSystem: handleTariffSystemChange,
    revertChanges,
  };
}

export function useAdvancedTariffsEditState(
  onEditStateChange,
  ...initialTariffs
) {
  // Keep track of which sub-forms are in edit mode
  const [editState, setEditState] = useState(
    new Set(initialTariffs.map((t) => t._uiKey))
  );

  return {
    getIsInEditMode: ({ _uiKey }) => editState.has(_uiKey),
    setIsInEditMode: ({ _uiKey }, value) => {
      const nextEditState = new Set([...editState]);
      if (value) {
        nextEditState.add(_uiKey);
      } else {
        nextEditState.delete(_uiKey);
      }
      onEditStateChange?.(nextEditState.size > 0);
      setEditState(nextEditState);
    },
  };
}
