import React, { useState, useContext, useEffect } from 'react';
import { set, merge } from 'lodash-es';
import { request } from 'utils/api';
import { Form, Message, Modal, Button, Header } from 'semantic';
import CostSettings from 'components/tariffs/EvseTariff';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import {
  canAccess,
  currentUserCanAccessProviderEndpoint,
  hasFacilitatorPermissionsAccess,
} from 'utils/roles';
import { normalizeCostSettings } from 'utils/form';
import { formatTariffProfile } from 'utils/formatting';
import { UserContext } from 'contexts/user';
import ResetEvse from 'components/modals/ResetEvse';
import { useSanitizeCostSettingsFeatures } from 'components/tariffs/features';
import { useTranslation } from 'react-i18next';
import { countries } from 'eflux-pkg-js';

export default function EditEvseCostSettingsController(props) {
  const { t } = useTranslation();
  const { trigger, initialValues } = props;
  const { user, facilitatorPermissions } = useContext(UserContext);
  const sanitizeCostSettingsFeatures = useSanitizeCostSettingsFeatures();

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [evseConfiguration, setEvseConfiguration] = useState(
    initialValues?.configuration || []
  );
  const [isReady, setIsReady] = useState(true);
  const [rebootRequired, setRebootRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTariffProfile, setLoadingTariffProfile] = useState(false);
  const [error, setError] = useState(null);
  const [tariffProfile, setTariffProfile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [currency, setCurrency] = useState(null);

  const providerAccessGroupAccess = currentUserCanAccessProviderEndpoint(
    'accessGroups',
    'read',
    initialValues.providerId
  );

  const evseCostSettingsReadAccess =
    canAccess(
      user,
      initialValues.providerId,
      props.accountId,
      'evseTariffs',
      'read'
    ) ||
    hasFacilitatorPermissionsAccess(
      facilitatorPermissions,
      'evseTariffs',
      'read'
    );
  const evseCostSettingsWriteAccess =
    canAccess(
      user,
      initialValues.providerId,
      props.accountId,
      'evseTariffs',
      'write'
    ) ||
    hasFacilitatorPermissionsAccess(
      facilitatorPermissions,
      'evseTariffs',
      'write'
    );

  const disableCostSettingsEditing =
    !evseCostSettingsWriteAccess ||
    !formValues.enablePublicCharging ||
    formValues.enablePublicFreeCharging;

  const showEditTariff =
    evseCostSettingsReadAccess &&
    (!formValues.tariffProfileId ||
      (formValues.enableTariffProfileOverride &&
        tariffProfile &&
        tariffProfile.allowEvseControllerOverride));

  const handleSubmit = () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    const costSettings = normalizeCostSettings(formValues.costSettings);
    sanitizeCostSettingsFeatures?.(costSettings, currency);

    request({
      method: 'POST',
      path: `/2/locations/cpo/${props.locationId}/cost-settings`,
      body: {
        evseControllerId: initialValues.id,
        costSettings,
        accessGroupIds: formValues.accessGroupIds,
        enablePublicFreeCharging: formValues.enablePublicFreeCharging,
        enablePublicCharging: formValues.enablePublicCharging,
        enableTariffProfileOverride: formValues.enableTariffProfileOverride,
        optOutOfScanToPay: formValues.optOutOfScanToPay,
      },
    })
      .then(() => {
        setLoading(false);
        if (props.onClose) props.onClose();
        setFormValues(initialValues);
        setOpen(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues(merge({}, formValues, set({}, name, value)));
  };

  const fetchTariffProfile = async (tariffProfileId) => {
    setLoadingTariffProfile(true);
    try {
      const { data: tariffProfile } = await request({
        method: 'GET',
        path: `/1/tariff-profiles/${tariffProfileId}`,
      });
      setTariffProfile(tariffProfile);
      setLoadingTariffProfile(false);
    } catch (error) {
      setLoadingTariffProfile(false);
      setError(error);
    }
  };

  useEffect(() => {
    if (initialValues.tariffProfileId) {
      fetchTariffProfile(initialValues.tariffProfileId);
    }
  }, [initialValues.tariffProfileId]);

  const refetchEvseConfiguration = async () => {
    const configuration = await request({
      method: 'GET',
      path: `/1/evse-controllers/${initialValues.id}/configuration`,
    });
    setEvseConfiguration(configuration.data || []);
  };

  /**
   * We identify the currency in which the prices on this charge station are applied in based on the country of the
   * location.
   * @param locationId
   * @returns {Promise<void>}
   */
  const fetchCurrency = async (locationId) => {
    if (!locationId) {
      if (currency) {
        setCurrency(null);
      }
      return;
    }
    try {
      const { data } = await request({
        path: `/2/locations/cpo/${locationId}`,
        method: 'GET',
      });
      setCurrency(countries.getCurrencyByAlpha3(data?.country));
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchCurrency(formValues.locationId);
  }, [formValues.locationId]);

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        if (props.onClose) props.onClose();
        setOpen(false);
        setFormValues(initialValues);
        setSubmitted(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t(
          'editEvseCostSettingsDialog.header',
          'Edit EVSE Controller Cost Settings'
        )}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="EditEvseController-form"
          error={submitted && Boolean(error)}
          onSubmit={handleSubmit}>
          {error && <Message error content={error.message} />}

          <Header
            as="h5"
            style={{ marginTop: 0 }}
            content={<>{t('editEvseControllers.roamingLabel', 'Roaming')}</>}
          />

          <Form.Checkbox
            label={t(
              'editEvseCostSettingsDialog.enablePublicCharging',
              'Allow charging from other networks (roaming)'
            )}
            name="enablePublicCharging"
            checked={formValues.enablePublicCharging}
            disabled={formValues.enablePublicFreeCharging}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />

          <Form.Checkbox
            label={t(
              'editEvseCostSettingsDialog.enablePublicFreeCharging',
              'Allow any card to charge here for free (ignores all cost settings)'
            )}
            name="enablePublicFreeCharging"
            checked={formValues.enablePublicFreeCharging}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />

          <Header
            as="h5"
            style={{ marginTop: 0 }}
            content={
              <>{t('editEvseControllers.scanToPayHeader', 'Scan to pay')}</>
            }
          />

          <Form.Checkbox
            label={t(
              'newChargingStationDetails.optOutOfScanToPay',
              'Opt-out of "Scan to Pay" functionality'
            )}
            name="optOutOfScanToPay"
            checked={formValues.optOutOfScanToPay}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />

          {tariffProfile && (
            <Message info content={formatTariffProfile(tariffProfile, t)} />
          )}

          {tariffProfile && tariffProfile.allowEvseControllerOverride && (
            <Form.Checkbox
              label={
                'Override tariff profile base tariff for this charge station'
              }
              name="enableTariffProfileOverride"
              checked={formValues.enableTariffProfileOverride}
              onChange={(e, { name, checked }) => setField(name, checked)}
            />
          )}

          {showEditTariff && (
            <>
              <Header
                as="h5"
                content={<>{t('costSettings.title', 'Energy Tariffs')}</>}
                subheader={t(
                  'costSettings.reimbursed',
                  'This tariff will be reimbursed'
                )}
              />
              <CostSettings
                currency={currency}
                disabled={disableCostSettingsEditing}
                value={formValues.costSettings}
                energyDeliveryArea={initialValues.energyDeliveryArea}
                numConnectors={formValues.numConnectors}
                connectors={initialValues.connectors}
                evseConfiguration={evseConfiguration}
                evseConnectivityState={initialValues.connectivityState}
                evseControllerId={initialValues.id}
                paymentTerminalType={formValues.paymentTerminalType}
                label="Energy Price per kWh"
                refetchEvseConfiguration={refetchEvseConfiguration}
                setIsReady={setIsReady}
                setRebootRequired={setRebootRequired}
                onChange={(costSettings) => {
                  setSubmitted(false);
                  setFormValues({
                    ...formValues,
                    costSettings,
                  });
                }}
              />
              <br />
            </>
          )}

          <Form.Field>
            <label>
              {t('editEvseCostSettingsDialog.accessGroups', 'Access Groups')}
            </label>
            <SearchDropDown
              disabled={formValues.enablePublicFreeCharging}
              multiple
              value={formValues.accessGroupIds}
              objectMode={false}
              onDataNeeded={(body) => {
                if (providerAccessGroupAccess) {
                  return request({
                    path: '/1/access-groups/search',
                    method: 'POST',
                    body: {
                      ...body,
                      accountId: initialValues.accountId,
                    },
                  });
                } else {
                  return request({
                    path: '/1/access-groups/mine',
                    method: 'POST',
                    body,
                  });
                }
              }}
              onChange={(e, { value }) => {
                setFormValues({
                  ...formValues,
                  accessGroupIds: value,
                });
              }}
            />
          </Form.Field>
          {rebootRequired && (
            <Message warning>
              <Message.Header>
                {t('evseController.rebootRequired', 'Reboot required')}
              </Message.Header>
              <p>
                {t(
                  'evseController.rebootRequiredDetails',
                  'Changes have been made to this charge station that require a reboot. The changes have been applied, but a reboot was not yet performed.'
                )}
              </p>
              <ResetEvse
                locationId={initialValues?.locationId}
                initialValues={{ evseControllerId: initialValues?.id }}
                trigger={
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    basic
                    icon="arrow-rotate-right"
                    content={t('evseController.reset', 'Reboot this station')}
                  />
                }
                onDone={() => {
                  setRebootRequired(false);
                }}
              />
            </Message>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={t('common.update', 'Update')}
          form="EditEvseController-form"
        />
      </Modal.Actions>
    </Modal>
  );
}

// Set the default props
EditEvseCostSettingsController.defaultProps = {
  initialValues: {
    enablePublicFreeCharging: false,
  },
};
