import React, { useEffect, useState } from 'react';
import AppWrapper from 'components/AppWrapper';

import { Link } from 'react-router-dom';
import DataList from 'components/DataList';
import Search from 'components/SearchInput';

import { request } from 'utils/api';

import Filters from './Filters';
import Table from './Table';

import { Container, Header, Segment, Grid, Divider, Button } from 'semantic';

import Breadcrumbs from 'components/Breadcrumbs';

import { useTranslation } from 'react-i18next';

const itemLimit = 100;

export default function EvseControllers() {
  const [dateKey, setDateKey] = useState(Date.now());
  const [searchPhrase, setsearchPhrase] = useState();
  const [filters, setFilters] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    refresh();
  }, [searchPhrase, filters]);

  const handleRequest = (paramFilters = {}) => {
    return request({
      method: 'POST',
      path: '/1/evse-controllers/maintenance/search/fast',
      body: {
        ...paramFilters,
        ...(searchPhrase ? { searchPhrase } : {}),
        ...filters,
        limit: itemLimit,
      },
    });
  };

  const handleFilterChange = (filters) => {
    setFilters(
      filters.reduce((result, item) => {
        return {
          ...result,
          ...(item.apiFilter || {}),
        };
      }, {})
    );
  };

  const handleOnSearch = (search) => {
    setsearchPhrase(search);
  };

  const refresh = () => {
    setDateKey(Date.now());
  };

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link key="maintaince" to="/maintenance/evse-controllers">
              Maintenance
            </Link>,
          ]}
          active={t('evseControllerMaintenance.title', 'EVSE Maintenance')}
        />
        <Grid reversed="computer">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={10}
            textAlign="right"
            verticalAlign="middle">
            <Button
              basic
              as={Link}
              to="/maintenance/evse-controllers/issues"
              content="Detected Issues"
              icon="crosshairs"
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={6}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('evseControllerMaintenance.title', 'EVSE Maintenance')}
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Segment>
          <Grid>
            <Grid.Row width={16}>
              <Grid.Column width={10}>
                <Filters onChange={handleFilterChange}></Filters>
              </Grid.Column>
              <Grid.Column width={6}>
                <Search
                  placeholder={t(
                    'evseControllerMaintenance.searchBy',
                    'Search by ID, Serial Number, Model Number, IMSI, etc'
                  )}
                  onChange={handleOnSearch}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <DataList key={dateKey} limit={itemLimit} onRequest={handleRequest}>
          <Table onRefresh={() => refresh()} />
        </DataList>
        <Divider hidden />
      </Container>
    </AppWrapper>
  );
}
