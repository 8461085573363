import React from 'react';

import { SignupProvider } from './Context';
import { Switch, Route } from 'react-router-dom';
import { Container, Menu, Grid, Button } from 'semantic';

import PageCenter from 'components/PageCenter';
import Protected from 'components/routes/Protected';
import Billing from './Steps/Billing';
import Account from './Steps/Account';
import Payment from './Steps/Payment';
import logoInverted from 'assets/logo-text.svg';
import NavLine from './NavLineWithoutPlan';
import { useTranslation } from 'react-i18next';
import SideBar from './SideBarWithoutPlan';
import { useHistory } from 'react-router-dom';
import { useUser } from 'contexts/user';
import styled from 'styled-components';
import LanguageDropdown from 'components/LanguageDropdown';
import {
  environmentBannerHeight,
  isNotProd,
} from '../../../components/environmentBanner';

const Responsive = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

export default () => {
  const { t } = useTranslation();
  const { provider } = useUser();
  const history = useHistory();

  if (!provider.enableSelfSignup) {
    history.push('/login');
    return null;
  }

  const logoPath = (provider && provider.invertedLogoUrl) || logoInverted;
  React.useEffect(() => {
    window.sessionStorage.setItem('signupWithPlan', false);
  }, []);

  return (
    <SignupProvider>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...(isNotProd()
            ? { minHeight: `calc(100vh - ${environmentBannerHeight})` }
            : { minHeight: '100vh' }),
        }}>
        <Menu inverted stackable style={{ borderRadius: 0 }}>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              width: '100%',
              justifyContent: 'center',
            }}>
            <img
              style={{
                height: '25px',
                marginTop: '7px',
              }}
              src={logoPath}
            />
          </div>

          <Menu.Menu position="right">
            <Menu.Item>
              <Button primary onClick={() => history.push('/login')}>
                {t('button.login', 'Login')}
              </Button>
            </Menu.Item>
            <LanguageDropdown />
          </Menu.Menu>
        </Menu>
        <Container>
          <PageCenter maxWidth="800px">
            <Responsive>
              <NavLine base="cpo" style={{ marginBottom: '20px' }} />
            </Responsive>
            {provider && (
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Switch>
                      <Protected
                        exact
                        path="/cpo/signup/billing"
                        endpoint="me"
                        component={(props) => (
                          <Billing {...props} withoutPlan journey="cpo" />
                        )}
                      />
                      <Protected
                        exact
                        path="/cpo/signup/finalize"
                        endpoint="me"
                        component={(props) => (
                          <Payment {...props} withoutPlan journey="cpo" />
                        )}
                      />
                      <Route
                        exact
                        path="/cpo/signup"
                        component={(props) => (
                          <Account {...props} withoutPlan journey="cpo" />
                        )}
                      />
                    </Switch>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <SideBar />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </PageCenter>
        </Container>
      </div>
    </SignupProvider>
  );
};
