import React from 'react';
import CostSettingsStaticForm from 'components/tariffs/AdvancedTariff/CostSettings/FormStatic';
import { TariffSystem } from 'components/tariffs/AdvancedTariff/state';
import CostSettingsDynamicForm from 'components/tariffs/AdvancedTariff/CostSettings/FormDynamic';

export type CostSettingsFormProps = {
  value: any;
  onChange: (nextCostSettings: any) => void;
  currency: string;
  tariffSystem?: TariffSystem;
};

export default function CostSettingsForm(props: CostSettingsFormProps) {
  if (props.tariffSystem === 'dynamic') {
    return <CostSettingsDynamicForm {...props} />;
  }
  return <CostSettingsStaticForm {...props} />;
}
