export function getLanguage() {
  const tmp = window.location.search.match(/lng=([^&]+)$/);
  if (tmp && tmp[1] && ['nl', 'en', 'de', 'fr'].includes(tmp[1])) {
    window.sessionStorage.setItem('language', tmp[1]);
    return tmp[1];
  }

  if (window.sessionStorage.getItem('language')) {
    return window.sessionStorage.getItem('language');
  }

  const language = window.navigator.language.toLowerCase();

  const englishLangCodes = [
    'en',
    'en-us',
    'en-eg',
    'en-au',
    'en-gb',
    'en-ca',
    'en-nz',
    'en-ie',
    'en-za',
    'en-jm',
    'en-bz',
    'en-tt',
  ];

  if (englishLangCodes.includes(language)) {
    return 'en';
  }

  const germanLangCodes = ['de', 'de-ch', 'de-at', 'de-lu', 'de-li', 'de-de'];

  if (germanLangCodes.includes(language)) {
    return 'de';
  }

  const frenchLangCodes = ['fr', 'fr-be', 'fr-ca', 'fr-fr', 'fr-lu', 'fr-ch'];

  if (frenchLangCodes.includes(language)) {
    return 'fr';
  }

  const italianLangCodes = ['it', 'it-ch', 'it-it'];
  if (italianLangCodes.includes(language)) {
    return 'it';
  }

  /*
  disabled until ready
  const spanishLangCodes = [
    'es',
    'es-ar',
    'es-bo',
    'es-cl',
    'es-co',
    'es-cr',
    'es-do',
    'es-ec',
    'es-sv',
    'es-gt',
    'es-hn',
    'es-mx',
    'es-ni',
    'es-pa',
    'es-py',
    'es-pe',
    'es-pr',
    'es-es',
    'es-uy',
    'es-ve',
  ];

  if (spanishLangCodes.includes(language)) {
    return 'es';
  }
  */

  return 'nl';
}
