import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

export default ({ itemId }) => {
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content="Overview"
          to={`/charging-stations/access-groups/${itemId}`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
};
