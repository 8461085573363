import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from 'semantic';

type Props = {
  children: React.ReactNode;
  buttonTo: string;
  buttonTitle: string;
};

export const ToastButtonLayout = ({
  buttonTo,
  buttonTitle,
  children,
}: Props) => {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={12} style={{ paddingRight: 0 }}>
          {children}
        </Grid.Column>
        <Grid.Column width={4}>
          <Link to={buttonTo}>
            <Button basic content={buttonTitle} type="button" />
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
