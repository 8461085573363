import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import {
  AggregateStats,
  AggregateTimeMap,
  AggregateTimeSeries,
  ErrorBoundary,
  PunchChart,
  SeriesChart,
} from 'react-tectonic';

import {
  getColors,
  mergeFilters,
  sessionsExclusionFilter,
} from 'utils/visualizations';

import MultiCardinalityDonutChart from 'components/analytics/MultiCardinalityDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import {
  calculateKwh,
  formatDurationWithUnit,
  numberWithCommas,
} from 'utils/formatting';
import { startCase } from 'lodash-es';
import { Link } from 'react-router-dom';
import { useUser } from 'contexts/user';

export default function CpoTransactions({
  baseFilter = {},
  includeAccounts = true,
}) {
  const { t, i18n } = useTranslation();

  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;

  const colors = getColors(colorHex);

  return (
    <ErrorBoundary>
      <Divider hidden />
      <Statistic.Group
        widths="five"
        style={{ justifyContent: 'space-between' }}>
        <AggregateStats
          collection="sessions"
          fields={['durationSeconds']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.durationSeconds) return '...';
            const { value, unit } = formatDurationWithUnit(
              data['durationSeconds'].sum,
              t
            );
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(value) : '...'}
                </Statistic.Value>
                <Statistic.Label>
                  {t(
                    'analyticsCpoTransactions.unitsOfCharging',
                    '{{unit}} of charging',
                    {
                      unit: startCase(unit),
                    }
                  )}
                </Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['durationSeconds']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.durationSeconds) return '...';
            const { value, unit } = formatDurationWithUnit(
              data['durationSeconds'].avg,
              t
            );
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(value) : '...'}
                </Statistic.Value>
                <Statistic.Label>
                  {t(
                    'analyticsCpoTransactions.unitSessionAvg',
                    '{{unit}} session avg',
                    {
                      unit: startCase(unit),
                    }
                  )}
                </Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['kwh']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.kwh) return '...';
            const { kwhValue, kwhUnit } = calculateKwh(data['kwh'].sum);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? kwhValue || 0 : '...'}
                </Statistic.Value>
                <Statistic.Label>
                  {kwhUnit} {t('common.total', 'total')}
                </Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['kwh']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.kwh) return '...';
            const { kwhValue, kwhUnit } = calculateKwh(data['kwh'].avg);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success ? numberWithCommas(kwhValue) : '...'}
                </Statistic.Value>
                <Statistic.Label>
                  {t(
                    'analyticsCpoTransactions.unitSessionAvg',
                    '{{unit}} session avg',
                    {
                      unit: kwhUnit,
                    }
                  )}
                </Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
        <AggregateStats
          collection="sessions"
          fields={['kwh', 'durationSeconds']}
          filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
          {({ status, data }) => {
            if (!data) return '...';
            if (!data.kwh) return '...';
            const kwPerHour = data.kwh.sum / (data.durationSeconds.sum / 3600);
            return (
              <Statistic>
                <Statistic.Value>
                  {status.success
                    ? kwPerHour
                      ? kwPerHour.toFixed(2)
                      : 0
                    : '...'}
                </Statistic.Value>
                <Statistic.Label>
                  {t('analyticsCpoTransactions.avgKwhPerhour', 'avg kwh/hour')}
                </Statistic.Label>
              </Statistic>
            );
          }}
        </AggregateStats>
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="kwh"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.totalkWh', 'Total kWh')}
          titleAlign="center"
          chartType="area"
          height={350}
          valueFieldLabel="kWh"
          valueFormatter={(value) => numberWithCommas(Math.round(value))}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="avg"
        field="kwh"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.averagekWh', 'Average kWh')}
          titleAlign="center"
          chartType="area"
          height={350}
          valueFieldLabel="kWh"
          valueFormatter={(value) => (value ? value.toFixed(2) : 0)}
          color={colorHex}
        />
      </AggregateTimeSeries>

      {includeAccounts && (
        <React.Fragment>
          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    limit={20}
                    filter={mergeFilters(baseFilter, sessionsExclusionFilter)}
                    collection="sessions"
                    aggField="accountId"
                    field="kwh"
                    operation="sum"
                    title={t(
                      'analyticsCpoTransactions.topAccountsBykWh',
                      'Top Accounts by kWh'
                    )}
                    valueField="value"
                    valueFieldName="kWh"
                    valueFormatter={(value) =>
                      numberWithCommas(Math.round(value))
                    }
                    fetchReference="accounts"
                    referenceLabelFormatter={(item) => {
                      return (
                        <Link to={`/accounts/${item.id}`}>{item.name}</Link>
                      );
                    }}
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    collection="sessions"
                    filter={mergeFilters(baseFilter, sessionsExclusionFilter)}
                    labelFormatter={(v) => v.toString().toUpperCase()}
                    aggField="tokenInfraProviderId"
                    operation="sum"
                    title={t(
                      'analyticsCpoTransactions.topMobilityServiceProvidersByKWh',
                      'Top Mobility Service Providers by kWh'
                    )}
                    field="kwh"
                    valueField="value"
                    valueFieldName="kWh"
                    valueFormatter={(value) =>
                      numberWithCommas(Math.round(value))
                    }
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="durationSeconds"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t(
            'analyticsCpoTransactions.cumulativeSessionDuration',
            'Cumulative Session Duration'
          )}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueFieldLabel={t('common.duration', 'Duration')}
          valueFormatter={(item) =>
            `${numberWithCommas(Math.round(item / 3600))} ${t('formatDuration.hours', 'hours')}`
          }
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeMap
        collection="sessions"
        dateField="startedAt"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <PunchChart
          titleAlign="center"
          title={t('analyticsSessions.startTimes', 'Session Start Times')}
          height={490}
          color={colorHex}
          localeName={i18n.language}
        />
      </AggregateTimeMap>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeMap
        collection="sessions"
        dateField="endedAt"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <PunchChart
          titleAlign="center"
          title={t('analyticsSessions.endTimes', 'Session End Times')}
          height={490}
          color={colorHex}
          localeName={i18n.language}
        />
      </AggregateTimeMap>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <MultiCardinalityDonutChart
                title={t(
                  'analyticsCpoTransactions.sessionDurations',
                  'Session Durations'
                )}
                colors={colors}
                collection="sessions"
                items={[
                  {
                    label: t(
                      'analyticsCpoTransactions.overNineHours',
                      'Over 9 Hours'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              durationSeconds: { gt: 9 * 3600 },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: t(
                      'analyticsCpoTransactions.fourToNineHours',
                      '4 to 9 Hours'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              durationSeconds: {
                                gte: 4 * 3600,
                                lte: 9 * 3600,
                              },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: t(
                      'analyticsCpoTransactions.oneToFourHours',
                      '1 to 4 Hours'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              durationSeconds: {
                                gte: 1 * 3600,
                                lte: 4 * 3600,
                              },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: t(
                      'analyticsCpoTransactions.under1Hour',
                      'Under 1 Hour'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              durationSeconds: { lt: 1 * 3600 },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <MultiCardinalityDonutChart
                colors={colors}
                title={t(
                  'analyticsCpoTransactions.sessionEnergyUsage',
                  'Session Energy Usage'
                )}
                collection="sessions"
                items={[
                  {
                    label: t(
                      'analyticsCpoTransactions.over50KWh',
                      'Over 50 kWh'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              kwh: { gt: 50 },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: t(
                      'analyticsCpoTransactions.between25to50KWh',
                      '25 to 50 kWh'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              kwh: { gte: 25, lte: 50 },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: t(
                      'analyticsCpoTransactions.between10to25KWh',
                      '10 to 25 kWh'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              kwh: { gte: 10, lte: 25 },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                  {
                    label: t(
                      'analyticsCpoTransactions.under10kWh',
                      'Under 10 kWh'
                    ),
                    request: {
                      collection: 'sessions',
                      fields: ['id'],
                      filter: {
                        ...mergeFilters(
                          {
                            ranges: sessionsExclusionFilter.ranges.concat({
                              kwh: { lte: 10 },
                            }),
                          },
                          baseFilter
                        ),
                      },
                    },
                  },
                ]}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Divider hidden />
      <Divider hidden />
    </ErrorBoundary>
  );
}
