/*
.connection-error {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: #bf434d;
  text-align: center;
  padding: 0.3em 0;
  transform: translateY(-100%);
  transition: transform 200ms ease-in-out;

  &--active {
    transform: translateY(0);
  }
}
*/

import React from 'react';

export default class DashboardNotice extends React.Component {
  render() {
    return (
      <div
        style={{
          margin: '-20px -25px 20px -25px',
          padding: '10px 25px',
          color: '#fff',
          background: '#bf434d',
          zIndex: 9,
          position: 'relative',
        }}>
        {this.props.children}
      </div>
    );
  }
}
