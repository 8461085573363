import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const ButtonWithRef = React.forwardRef((props, ref) => (
  <Button ref={ref} {...props} />
));

export default styled(ButtonWithRef)`
  &:hover {
    filter: brightness(1.15);
  }
  background-color: transparent !important;
  border: 0;
  &.ui.primary.button {
    color: ${(props) => props.theme.primaryColor};
  }
  &.ui.basic.primary.button,
  &.ui.basic.primary.button:hover {
    filter: brightness(1.15);
  }
`;
