import React from 'react';
import { Pagination, Message } from 'semantic';
import { useTranslation } from 'react-i18next';

// Due to an Elasticsearch config limitation, we can't go paginate past 10000 documents
// See max_result_window in https://www.elastic.co/guide/en/elasticsearch/reference/current/index-modules.html
const MaxResultWindow = 10000;

export default ({
  page,
  limit,
  total,
  boundaryRange = 0,
  onPageChange,
  className,
  estimated,
}) => {
  const { t } = useTranslation();

  const totalPages = Math.ceil(total / limit);
  const maxPage = Math.ceil(MaxResultWindow / limit);
  const displayedTotalPages = Math.min(totalPages, maxPage);

  return (
    <>
      {totalPages > 1 && (
        <Pagination
          title={
            estimated
              ? t(
                  'pagination.estimated',
                  'Due to performance reasons these pages are an (over)estimate'
                )
              : undefined
          }
          className={className}
          nextItem={
            page * limit - total < 0
              ? {
                  'aria-label': 'Next Page',
                  content: t('Pagination.next', 'Next'),
                }
              : null
          }
          prevItem={
            page !== 1
              ? {
                  'aria-label': 'Previous item',
                  content: 'Previous',
                }
              : null
          }
          onPageChange={onPageChange}
          activePage={page}
          firstItem={null}
          siblingRange={3}
          boundaryRange={boundaryRange}
          lastItem={null}
          totalPages={displayedTotalPages}
        />
      )}
      {page === displayedTotalPages && totalPages > displayedTotalPages && (
        <Message
          content={t(
            'pagination.maxPaginationMessage',
            `Can't find what you're looking for? Try refining your search.`
          )}
        />
      )}
    </>
  );
};
