import React, { useState } from 'react';
import * as Search from 'components/search';
import Table from './Table';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { Message } from 'semantic';

const OcpiSyncStatus = ({ t, doc: document, modelName }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [doc, setDoc] = useState(document);
  const [key, setKey] = useState(Date.now());

  const onDataNeeded = () => {
    if (!doc) return [];
    if (!doc.syncOcpiStatus) return [];

    const rows = [];
    Object.entries(doc.syncOcpiStatus).forEach(
      ([credentialName, { pushedAt, success, error, credentialId }]) => {
        rows.push({ credentialName, credentialId, pushedAt, success, error });
      }
    );

    return { data: rows, meta: { count: rows.length } };
  };

  const handleOcpiSync = async (credentialId) => {
    const { id } = doc;
    setErrorMessage(null);

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/external-sync/request-ocpi-sync',
        body: { id, modelName, credentialId },
      });

      setDoc(data);
      setKey(Date.now()); // force reload
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Search.Provider key={key} onDataNeeded={onDataNeeded}>
      {errorMessage && <Message error content={errorMessage} />}
      <Search.Status noResults={t('common.noResults', 'No Results')} />
      <Table handleOcpiSync={handleOcpiSync} />
    </Search.Provider>
  );
};

export default withTranslation()(OcpiSyncStatus);
