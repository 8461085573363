import React, { useMemo } from 'react';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SuboperatorSettingsForm } from './SuboperatorSettings.form';

export default function SuboperatorSettings() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);
  //TODO: Add error boundary layer

  return (
    <SettingsShell>
      <QueryClientProvider client={queryClient}>
        <SuboperatorSettingsForm />
      </QueryClientProvider>
    </SettingsShell>
  );
}
