import React from 'react';
import { useEnergyMarketPriceContext } from 'components/MarketEnergyTariffs/Context';
import { Grid, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import MarketEnergyTariffsModal from 'components/MarketEnergyTariffs/Modal';

export default function CurrentMarketPrices() {
  const { t } = useTranslation();
  const context = useEnergyMarketPriceContext();

  if (!context.activeTariff) {
    return (
      <Message
        error
        content={
          <>
            {t(
              'advancedTariff.noMarketPrices',
              'Current market prices are not available.'
            )}
          </>
        }
      />
    );
  }

  return (
    <Message
      info
      content={
        <Grid container>
          <Grid.Row style={{ paddingBottom: '0.5rem' }}>
            <Grid.Column>
              <strong>
                {t(
                  'advancedTariff.energyMarketPrice',
                  'Energy Market Price on {{date}}, {{timeFrom}} - {{timeTo}}',
                  {
                    date: context.currentTariffFormatted?.date,
                    timeFrom: context.currentTariffFormatted?.startTime,
                    timeTo: context.currentTariffFormatted?.endTime,
                  }
                )}
              </strong>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} style={{ paddingTop: 0 }}>
            <Grid.Column className={'middle aligned'}>
              <strong>
                {context.currentTariffFormatted?.price}{' '}
                {t('advancedTariff.perKwh', 'per kWh')}
              </strong>
            </Grid.Column>
            <Grid.Column>
              <MarketEnergyTariffsModal />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    />
  );
}
