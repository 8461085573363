import React from 'react';
import { Dropdown } from 'semantic';
import { useTranslation } from 'react-i18next';
import { UKFlag, NLFlag, DEFlag, FRFlag, ITFlag, ESFlag } from './Flags';

const flags = {
  en: UKFlag,
  nl: NLFlag,
  de: DEFlag,
  fr: FRFlag,
  it: ITFlag,
  es: ESFlag,
};

export default function MenuLanguage() {
  const { t, i18n } = useTranslation();

  function changeLang(language) {
    i18n.changeLanguage(language);
    window.sessionStorage.setItem('language', language);
  }

  const Icon = flags[i18n.language];

  const supportedLanguages = Object.keys(flags);

  return (
    <Dropdown icon={<Icon />} item>
      <Dropdown.Menu>
        {supportedLanguages.includes('nl') && (
          <Dropdown.Item
            selected={i18n.language === 'nl'}
            onClick={() => changeLang('nl')}>
            <NLFlag />
            {t('language.dutch', 'Dutch')}
          </Dropdown.Item>
        )}
        {supportedLanguages.includes('en') && (
          <Dropdown.Item
            selected={i18n.language === 'en'}
            onClick={() => changeLang('en')}>
            <UKFlag />
            {t('language.english', 'English')}
          </Dropdown.Item>
        )}
        {supportedLanguages.includes('de') && (
          <Dropdown.Item
            selected={i18n.language === 'de'}
            onClick={() => changeLang('de')}>
            <DEFlag />
            {t('language.german', 'German')}
          </Dropdown.Item>
        )}
        {supportedLanguages.includes('fr') && (
          <Dropdown.Item
            selected={i18n.language === 'fr'}
            onClick={() => changeLang('fr')}>
            <FRFlag />
            {t('language.french', 'French')}
          </Dropdown.Item>
        )}
        {supportedLanguages.includes('it') && (
          <Dropdown.Item
            selected={i18n.language === 'it'}
            onClick={() => changeLang('it')}>
            <ITFlag />
            {t('language.italian', 'Italian')}
          </Dropdown.Item>
        )}
        {supportedLanguages.includes('es') && (
          <Dropdown.Item
            selected={i18n.language === 'es'}
            onClick={() => changeLang('es')}>
            <ESFlag />
            {t('language.spanish', 'Spanish')}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
