import React, { useState } from 'react';
import { Form, StrictFormFieldProps, TextAreaProps } from 'semantic';
import styled from 'styled-components';

interface TextareaFileDropProps extends StrictFormFieldProps, TextAreaProps {
  allowTypes?: string[];
  maximumSizeBytes?: number;
  onFileDrop?: (content: string) => void;
  validationError?: Error | null;
}

const defaultPlaceholder = 'Enter text or drag and drop a file here';

const ErrorParagraph = styled.p`
  color: red;
  font-size: 0.9em;
  line-height: 0em;
  margin-bottom: 2em;
`;

// Helper function to generate a hash using the Web Crypto API
const hashContent = async (content: string): Promise<string> => {
  content = content.replaceAll(/\r\n/g, '\n');
  const encoder = new TextEncoder();
  const data = encoder.encode(content);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

export default function TextareaFileDrop({
  placeholder = defaultPlaceholder,
  allowTypes,
  maximumSizeBytes,
  onFileDrop,
  validationError,
  ...props
}: TextareaFileDropProps) {
  const [value, setValue] = useState('');
  const [fileDropped, setFileDropped] = useState(false);
  const [fileInitialState, setFileInitialState] = useState('');
  const [modified, setModified] = useState(false);
  const [isDragging, setIsDragging] = useState(false); // Track drag state

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    setIsDragging(true); // Set drag state to true when file is dragged over
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    setIsDragging(false); // Reset drag state when file leaves the area
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    if (!e.dataTransfer || !e.dataTransfer.files) {
      return;
    }

    const files = Array.from(e.dataTransfer?.files || []);
    const textFiles = files.filter((file) => {
      if (allowTypes && !allowTypes.includes(file.type)) {
        return false;
      }
      if (maximumSizeBytes && file.size > maximumSizeBytes) {
        return false;
      }
      return true;
    });

    const reader = new FileReader();
    reader.readAsText(textFiles[0]);
    reader.addEventListener('load', async () => {
      const content = reader?.result?.toString() || '';
      setValue(content);
      setFileInitialState(await hashContent(content));
      setModified(false);
      setFileDropped(true);

      if (onFileDrop) {
        onFileDrop(content);
      }
    });
  };

  const onChange = async (value: string) => {
    setValue(value);
    if (value === '') {
      setModified(false);
      setFileDropped(false);
    } else if (fileDropped && fileInitialState !== '') {
      const hashedValue = await hashContent(value);
      if (hashedValue !== fileInitialState) {
        setModified(true);
      } else {
        setModified(false);
      }
    }
  };

  return (
    <>
      <Form.TextArea
        {...props}
        placeholder={placeholder}
        value={value}
        onChange={(_, { value }) => {
          onChange(value as string);
          if (props.onChange) props.onChange(_, { value });
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        style={{
          ...(isDragging
            ? { backgroundColor: '#DFF0FF', border: '1px #2185D0 solid' }
            : {}),
        }}
        error={validationError ? true : false}
      />
      {modified && (
        <ErrorParagraph>Text has been modified after file drop.</ErrorParagraph>
      )}
      {fileInitialState === '' && validationError && (
        <p style={{ color: 'red', fontSize: '0.9em', lineHeight: '2em' }}>
          This field is required
        </p>
      )}
    </>
  );
}
