import React, { useCallback } from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import PublicLocationsMsp from 'components/form-fields/PublicLocationsMsp';
import SearchDropdown from 'components/form-fields/SearchDropdown';

import { useTranslation } from 'react-i18next';
import PreauthLimitField from 'components/MspRemoteSessions/PreauthLimitField';

export default function StartRemoteMspSessionPreauth({
  initialValues = {},
  onSubmit,
  trigger,
}) {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [formValues, setFormValues] = React.useState({ ...initialValues });
  const [open, setOpen] = React.useState(false);

  const getAuthMethodTypeSpecificValues = () => {
    switch (formValues.authMethod?.type) {
      case 'PSP_STORED_CARD':
        return {
          preauthAmount: formValues.preauthAmount,
          currency: formValues.location?.currency,
          paymentInstrumentId: formValues.authMethod.id,
          paymentType: 'PSP_PREAUTH',
        };
      case 'TOKEN':
        return {
          tokenId: formValues.authMethod.id,
          paymentType: 'TOKEN',
        };
    }
  };

  const excludeConnectorsWithoutCurrency = !!(
    formValues.authMethod?.type === 'PSP_STORED_CARD'
  );

  const handleSubmit = () => {
    setSubmitted(true);
    setError(null);

    const { location } = formValues;
    if (!location) {
      setError(new Error('No location selected'));
      return;
    }
    request({
      method: 'POST',
      path: '/1/remote-sessions/msp',
      body: {
        locationId: location.locationId,
        evseUid: location.evseUid,
        connectorId: location.connectorId,
        ...getAuthMethodTypeSpecificValues(),
      },
    })
      .then(() => {
        setFormValues(initialValues);
        setSubmitted(false);
        setError(null);
        setOpen(false);
        onSubmit();
      })
      .catch((error) => {
        setSubmitted(false);
        setError(error);
      });
  };

  const setField = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setSubmitted(false);
  };

  const handleOnDataNeeded = useCallback(async () => {
    const { data: cardsResponseData = [] } = await request({
      path: '/1/cards/mine',
      method: 'GET',
    });

    const { data: paymentInstrumentsData = [] } = await request({
      path: '/1/users/me/payment-instruments',
      method: 'POST',
      body: { filters: { intent: 'msp_remote_session' } },
    });

    const tokens = cardsResponseData
      .map((card) => {
        if (!card?.token) {
          return null;
        }

        return {
          name: `Token: ${card?.token.visualNumber || card?.token.customId}`,
          id: card?.token.id,
          type: 'TOKEN',
        };
      })
      .filter((token) => !!token);

    const paymentInstruments = paymentInstrumentsData.map((p) => ({
      name: `Credit Card: ${p.card.scheme} - ${p.card.last4Digits}`,
      id: p.id,
      type: 'PSP_STORED_CARD',
    }));

    return { data: [...tokens, ...paymentInstruments] };
  }, []);

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        setFormValues(initialValues);
        setSubmitted(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('startRemoteSessionModal.header', 'Start Remote Session')}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'startRemoteSessionModal.instruction1',
            'Step 1: Make sure your cable is plugged in to both your vehicle and the charge startion connector.'
          )}
        </p>
        <p>
          {t(
            'startRemoteSessionModal.instruction2',
            'Step 2: Select your payment method'
          )}
        </p>
        <p>
          {t(
            'startRemoteSessionModal.instruction3',
            'Step 3: Find and select the charge station you want to use'
          )}{' '}
        </p>
        <Form onSubmit={() => handleSubmit()}>
          <Form.Field>
            <label>
              {t(
                'startRemoteSessionModal.findPaymentMethod',
                'Find Payment Method'
              )}
            </label>
            <SearchDropdown
              value={formValues.authMethod}
              getOptionLabel={(option) => option.name}
              objectMode={true}
              keywordField="id"
              onDataNeeded={() => handleOnDataNeeded()}
              onChange={(e, { value }) => {
                setField('authMethod', value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>
              {t(
                'startRemoteSessionModal.findLocation',
                'Find Public Location'
              )}
            </label>
            <PublicLocationsMsp
              value={formValues.location}
              onChange={(e, { value }) => setField('location', value)}
              excludeConnectorsWithoutCurrency={
                excludeConnectorsWithoutCurrency
              }
            />
          </Form.Field>
          {formValues.authMethod?.type === 'PSP_STORED_CARD' &&
            formValues.location && (
              <PreauthLimitField
                value={formValues.preauthAmount}
                currency={formValues.location.currency}
                locationId={formValues.location.locationId}
                evseUid={formValues.location.evseUid}
                connectorId={formValues.location.connectorId}
                onChange={(value) => {
                  setField('preauthAmount', value);
                }}
              />
            )}
        </Form>
        {error && <Message error content={error.message} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={submitted}
          primary
          content={t('startRemoteSessionModal.start', 'Start Session')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}
