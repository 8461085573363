import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const tokensImportMapping = {
  uid: {
    required: true,
    matches: ['UID'],
    exactMatches: ['uid', 'UID'],
  },
  customId: {
    required: true,
    exactMatches: ['Custom ID', 'custom_id', 'custom id', 'laadpasnummer'],
  },
  type: {
    exactMatches: ['Type', 'type'],
    defaultValue: 'mifare',
  },
  visualNumber: {
    exactMatches: [
      'Visual Number',
      'visual_number',
      'visual number',
      'visualNumber',
    ],
  },
};

export const tokenBatchImportMapping = {
  visibleId: {
    required: true,
    exactMatches: [
      'visible id',
      'visible Id',
      'visibleId',
      'Visible Id',
      'Visible ID',
    ],
  },
  rfid: {
    required: true,
    exactMatches: ['RFID', 'rfid'],
  },
};

export const sessionsImportMapping = {
  externalId: {
    required: true,
    exactMatches: ['CDR_ID', 'CDR ID'],
  },
  startedAt: {
    required: true,
    exactMatches: ['Start_datetime', 'Start'],
    type: 'datetime',
  },
  endedAt: {
    required: true,
    exactMatches: ['End_datetime', 'End'],
    type: 'datetime',
  },
  durationSeconds: {
    required: false,
    exactMatches: ['Duration'],
    type: 'duration',
  },
  kwh: {
    required: true,
    exactMatches: ['Volume', 'KWh'],
    type: 'europeanOrAmericanNumber',
  },
  'location.address': {
    required: true,
    exactMatches: ['Charge_Point_Address', 'Address'],
  },
  'location.postalCode': {
    required: true,
    exactMatches: ['Charge_Point_ZIP', 'Postcode'],
  },
  'location.city': {
    required: true,
    exactMatches: ['Charge_Point_City', 'City'],
  },
  'location.countryCode': {
    required: true,
    exactMatches: ['Charge_Point_Country', 'Country'],
    parseFn: (value) => (value === 'NLD' ? 'NL' : value),
  },
  tokenUid: {
    required: true,
    exactMatches: ['Authentication_ID', 'Authentication ID'],
    parseFn(value) {
      // 4,13128E+12 reject scientific notation
      if (value.match(/^\d+[.,]+.*E\+\d+$/)) {
        throw new Error(`Invalid token UID: ${value}`);
      }
      return value;
    },
  },
  tokenContractId: {
    required: false,
    exactMatches: ['Contract_ID', 'Contract ID'],
  },
  chargePointId: {
    required: true,
    exactMatches: ['Charge_Point_ID', 'Charge Point ID'],
  },
  serviceExternalProviderId: {
    required: false,
    exactMatches: ['Service_Provider_ID', 'Service Provider ID'],
  },
  infraProviderId: {
    required: true,
    exactMatches: ['Infra_Provider_ID', 'Infra Provider ID'],
  },
  connectorType: {
    required: false,
    exactMatches: ['Connector type'],
  },
  status: {
    required: false,
    exactMatches: ['Status'],
  },
  externalCalculatedPrice: {
    required: true,
    exactMatches: ['Calculated_Cost', 'Price (EUR)'],
    type: 'europeanOrAmericanNumber',
  },
  importSource: {
    required: false,
    defaultValue: 'csv',
    exactMatches: ['Import_Source'],
  },
  currency: {
    required: false,
    defaultValue: 'EUR',
    exactMatches: ['Currency', 'currency'],
  },
};

export const pricingToolImportMapping = {
  cdrId: {
    required: true,
    exactMatches: ['CDR_ID', 'CDR ID'],
  },
  totalPrice: {
    required: true,
    exactMatches: ['Total_Price', 'Total Price'],
    // type: 'europeanOrAmericanNumber',
  },
};

export const accessGroupRFIDImportMapping = {
  uid: {
    required: true,
    matches: ['UID'],
    exactMatches: ['uid', 'UID', 'rfid', 'token_uid', 'token uid'],
  },
  name: {
    required: false,
    exactMatches: ['name', 'NAME', 'Name'],
  },
  pricePerKwh: {
    required: false,
    exactMatches: [
      'pricePerKwh',
      'PricePerKwh',
      'price',
      'kwhprice',
      'cost',
      'costPerKwh',
      'energy costs',
    ],
    type: 'europeanOrAmericanNumber',
  },
};

export const accessGroupVisualNumberImportMapping = {
  name: {
    required: false,
    exactMatches: ['name', 'NAME', 'Name'],
  },
  visualNumber: {
    required: true,
    exactMatches: [
      'visualNumber',
      'visualnumber',
      'visual_number',
      'visual number',
      'Visual number',
    ],
  },
};

export const accountsImportMapping = {
  'contact.firstName': {
    required: true,
    exactMatches: ['contact.firstName'],
  },
  'contact.lastName': {
    required: true,
    exactMatches: ['contact.lastName'],
  },
  'contact.phoneNo': {
    required: true,
    exactMatches: ['contact.phoneNo'],
  },
  'contact.email': {
    required: true,
    exactMatches: ['contact.email'],
  },
  'billing.countryCode': {
    required: true,
    exactMatches: ['billing.countryCode'],
    parseFn: (value) => value.toLowerCase(),
  },
  'billing.address': {
    required: true,
    exactMatches: ['billing.address'],
  },
  'billing.addressLine2': {
    required: false,
    exactMatches: ['billing.addressLine2'],
  },
  'billing.postalCode': {
    required: true,
    exactMatches: ['billing.postalCode'],
  },
  'billing.city': {
    required: true,
    exactMatches: ['billing.city'],
  },
  'billing.email': {
    required: true,
    exactMatches: ['billing.email'],
  },
  'billing.paymentMethod': {
    required: true,
    exactMatches: ['billing.paymentMethod'],
  },
  'billing.accountHolderName': {
    required: true,
    exactMatches: ['billing.accountHolderName'],
  },
  // TODO(rewop): BIll-139. Consider adding bicNo for importing
  'billing.ibanNo': {
    required: true,
    exactMatches: ['billing.ibanNo'],
  },
  'creditBilling.accountHolderName': {
    required: false,
    exactMatches: ['creditBilling.accountHolderName'],
  },
  // TODO(rewop): BIll-139. Consider adding bicNo for importing
  'creditBilling.ibanNo': {
    required: false,
    exactMatches: ['creditBilling.ibanNo'],
  },
  paymentTerm: {
    required: true,
    exactMatches: ['paymentTerm'],
  },
  featureFlags: {
    required: true,
    exactMatches: ['featureFlags'],
    type: 'list',
  },
  type: {
    required: true,
    exactMatches: ['type'],
  },
  billingCurrency: {
    required: true,
    exactMatches: ['billingCurrency'],
  },
  name: {
    required: true,
    exactMatches: ['name'],
  },
  vatNo: {
    required: true,
    exactMatches: ['vatNo'],
  },
  chamberOfCommerceNo: {
    required: true,
    exactMatches: ['chamberOfCommerceNo'],
  },
  legacyId: {
    required: true,
    exactMatches: ['legacyId'],
  },
};

export const usersImportMapping = {
  accountLegacyId: {
    required: true,
    exactMatches: ['accountLegacyId'],
  },
  'contact.firstName': {
    required: true,
    exactMatches: ['contact.firstName'],
  },
  'contact.lastName': {
    required: true,
    exactMatches: ['contact.lastName'],
  },
  'contact.phoneNo': {
    required: true,
    exactMatches: ['contact.phoneNo'],
  },
  defaultLangCode: {
    required: true,
    exactMatches: ['defaultLangCode'],
    parseFn: (value) => (value && value.match(/nl/i) ? 'nl' : 'en'),
  },
  email: {
    required: true,
    exactMatches: ['email'],
  },
  migrated: {
    required: false,
    exactMatches: ['migrated'],
    defaultValue: 'false',
  },
  'creditBilling.accountHolderName': {
    required: false,
    exactMatches: ['creditBilling.accountHolderName'],
  },
  // TODO(rewop): BIll-139. Consider adding bicNo for importing
  'creditBilling.ibanNo': {
    required: false,
    exactMatches: ['creditBilling.ibanNo'],
  },
  accountRole: {
    required: false,
    exactMatches: ['accountRole', 'Account Role'],
  },
};

export const locationsImportMapping = {
  accountLegacyId: {
    required: true,
    matches: ['Legacy account ID'],
    exactMatches: ['accountLegacyId'],
  },
  name: {
    required: true,
    matches: ['Name'],
  },
  accessPolicy: {
    required: true,
    matches: ['Access & billing policy', 'Access Policy', 'access policy'],
    exactMatches: ['accessPolicy'],
  },
  address: {
    required: true,
    matches: ['street', 'Street', 'address', 'Address'],
  },
  postal_code: {
    required: true,
    matches: ['postal code', 'postal_code', 'Postal Code', 'Postal code'],
    exactMatches: ['postalCode'],
  },
  city: {
    required: true,
    matches: ['city', 'City'],
  },
  country: {
    required: true,
    matches: ['country', 'Country', 'Country Code'],
    parseFn: (value) => (value === 'NLD' ? 'NL' : value),
    exactMatches: ['countryCode'],
  },
  publishingMode: {
    required: true,
    matches: ['visibility', 'Visibility', 'Publishing Mode', 'Publishing mode'],
    exactMatches: ['publishingMode'],
    parseFn: (value) => value.toLowerCase(),
  },
  userEmail: {
    required: false,
    matches: ['userEmail', 'User Email', 'User email'],
    exactMatches: ['userEmail'],
  },
  accountId: {
    required: true,
    exactMatches: ['accountId'],
  },
};

export const accessGroupsImportMapping = {
  accountLegacyId: {
    required: false,
    matches: ['Legacy account ID'],
    exactMatches: ['accountLegacyId'],
  },
  name: {
    required: true,
    matches: ['Name', 'name'],
  },
  type: {
    required: false,
    matches: ['Type', 'type'],
    defaultValue: 'rfid',
  },
  ownershipContext: {
    matches: ['visibility', 'Visibility'],
    required: false,
    defaultValue: 'account',
    parseFn: (value) => (value === 'private' ? 'user' : 'account'),
  },
  userEmail: {
    required: false,
    matches: ['userEmail', 'User Email', 'User email'],
    exactMatches: ['userEmail'],
  },
  accountId: {
    required: false,
    exactMatches: ['accountId'],
  },
  members: {
    required: false,
    exactMatches: ['UIDs', 'uids', 'members'],
    parseFn(value) {
      const uids = value?.split(/,\s*/) || [];
      return uids.map((uid, i) => ({
        type: 'rfid',
        priceType: 'free',
        pricePerKwh: 0,
        name: `Imported UID ${i}`,
        uid,
      }));
    },
  },
};

export const evseCommandsMapping = {
  evseControllerId: {
    required: true,
    matches: ['EVSE Controller ID'],
    exactMatches: ['evseControllerId'],
  },
  commandMethod: {
    required: true,
    exactMatches: ['commandMethod'],
  },
  commandParams: {
    required: true,
    exactMatches: ['commandParams'],
    parseFn(value) {
      try {
        const normalizedValue = value.replace(/“/g, '"').replace(/”/g, '"');
        return JSON.parse(normalizedValue);
      } catch (e) {
        throw new Error(`Invalid JSON: ${value} (${e.message})`);
      }
    },
  },
};

// XXX should be using the translation api
export const paymentMethodOptions = [
  { language: 'en', value: 'autopay', text: 'Auto pay (Free)' },
  { language: 'en', value: 'manual', text: 'Manual (EUR 1,50 per month)' },
  { language: 'nl', value: 'autopay', text: 'Automatische incasso (gratis)' },
  { language: 'nl', value: 'manual', text: 'Per factuur (€1.50 per factuur)' },
  { language: 'de', value: 'autopay', text: 'Lastschrift (kostenlos)' },
  {
    language: 'de',
    value: 'manual',
    text: 'Pro Rechnung (1,50 € pro Rechnung)',
  },
  { language: 'fr', value: 'autopay', text: 'Payer automatique (gratuit)' },
  { language: 'fr', value: 'manual', text: 'Par factuur (1,50 € par factuur)' },
];

export const defaultSearchFilter = {
  notExists: 'deletedAt',
};

export const accessGroupTypes = {
  rfid: {
    text: 'RFID Tags',
    icon: 'tags',
  },
  users: {
    text: 'Users',
    icon: 'users',
  },
};

export function useAccessGroupTypeOptions() {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        key: 'rfid',
        value: 'rfid',
        text: t('accessGroup.rfid', 'RFID Tags'),
        icon: 'tags',
      },
      {
        key: 'users',
        value: 'users',
        text: t('accessGroup.users', 'Users'),
        icon: 'users',
      },
    ];
  }, [t]);
}

export function useAccessGroupOwnershipOptions() {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        key: 'user',
        value: 'user',
        text: t(
          'editAccessGroup.userAccessGroupOwnershipType',
          'Private - Only visible by me'
        ),
        icon: 'lock',
      },
      {
        key: 'account',
        value: 'account',
        text: t(
          'editAccessGroup.accountAccessGroupOwnershipType',
          'Shared - Visible by all users in this account'
        ),
        icon: 'users',
      },
    ];
  }, [t]);
}

export const CdrStatuses = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  BILLED: 'billed',
};

export const CdrSources = {
  UNSPECIFIED: 'CDR_SOURCE_UNSPECIFIED',
  OCHP_14: 'CDR_SOURCE_OCHP_14',
  OCPI_211_PULL: 'CDR_SOURCE_OCPI_211_PULL',
  OCPI_211_PUSH: 'CDR_SOURCE_OCPI_211_PUSH',
  GIREVE_211_PULL: 'CDR_SOURCE_GIREVE_211_PULL',
  GIREVE_211_PUSH: 'CDR_SOURCE_GIREVE_211_PUSH',
  EVIOLIN_161_CSV: 'CDR_SOURCE_EVIOLIN_161_CSV',
};

export const CdrChargingPeriodDimensions = {
  ENERGY_KWH: 'Energy (kWh)',
  FLAT_FEE: 'Flat Fee',
  MAX_CURRENT_AMP: 'Max Current (Amp)',
  MIN_CURRENT_AMP: 'Min Current (Amp)',
  PARKING_TIME_HOURS: 'Parking Time (Hours)',
  CHARGING_TIME_HOURS: 'Charging Time (Hours)',
  RESERVATION_FEE: 'Reservation Fee',
  RESERVATION_TIME_HOURS: 'Reservation Time (Hours)',
  STATE_OF_CHARGE_PERCENTAGE: 'State of Charge (%)',
  ENERGY_EXPORT_KWH: 'Energy Export (kWh)',
  ENERGY_IMPORT_KWH: 'Energy Import (kWh)',
};
