import React, { ReactNode, CSSProperties, Children } from 'react';
import {
  AlignItems,
  SpacingValue,
  FlexDirection,
  JustifyContent,
  FlexWrap,
} from 'types/layout';
import { resolveSpacingValue } from './utils';

interface FlexProps {
  /**
   * Controls the alignment of the children along the cross axis.
   */
  align?: AlignItems;
  /**
   * Controls the gap between columns.
   */
  columnGap?: SpacingValue;
  /**
   * Controls the direction of the children.
   */
  direction?: FlexDirection;
  /**
   * Controls the gap between children.
   */
  gap?: SpacingValue;
  /**
   * Controls the alignment of the children along the main axis.
   */
  justify?: JustifyContent;
  /**
   * Controls the gap between rows.
   */
  rowGap?: SpacingValue;
  /**
   * Controls whether the children wrap to the next line.
   */
  wrap?: FlexWrap;
  /**
   * The style to be applied to the center container.
   */
  style?: CSSProperties;
  /**
   * The children to be rendered.
   */
  children: ReactNode;
}

const DEFAULT_GAP_VALUE_IN_PX = 5;
const DEAFULT_ROW_GAP_VALUE_IN_PX = 5;
const DEFAULT_COLUMN_GAP_VALUE_IN_PX = 5;

/**
 * A flexible container that arranges its children in a flex layout.
 *
 * @component
 * @param {FlexProps} props - The props for the Flex component.
 * @param {AlignItems} props.align - Controls the alignment of the children along the cross axis.
 * @param {SpacingValue} props.columnGap - Controls the gap between columns.
 * @param {FlexDirection} props.direction - Controls the direction of the children.
 * @param {SpacingValue} props.gap - Controls the gap between children.
 * @param {JustifyContent} props.justify - Controls the alignment of the children along the main axis.
 * @param {SpacingValue} props.rowGap - Controls the gap between rows.
 * @param {FlexWrap} props.wrap - Controls whether the children wrap to the next line.
 * @returns {JSX.Element} The flex container with centered content.
 * @example
 * // Basic usage
 * <Flex>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Flex>
 *
 * // Centered column with gaps and wrapping
 * <Flex direction="column" justify="center" gap={10} rowGap={20} wrap="wrap">
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 *   <div>Item 4</div>
 *   <div>Item 5</div>
 * </Flex>
 */
export default function Flex({
  align = 'stretch',
  direction = 'row',
  justify = 'flex-start',
  wrap = 'nowrap',
  gap = DEFAULT_GAP_VALUE_IN_PX,
  columnGap = DEFAULT_COLUMN_GAP_VALUE_IN_PX,
  rowGap = DEAFULT_ROW_GAP_VALUE_IN_PX,
  children,
  style,
}: FlexProps) {
  const flexStyle: CSSProperties = {
    display: 'flex',
    alignItems: align,
    flexDirection: direction,
    justifyContent: justify,
    flexWrap: wrap,
    gap: resolveSpacingValue(gap, DEFAULT_GAP_VALUE_IN_PX),
    columnGap: resolveSpacingValue(columnGap, DEFAULT_COLUMN_GAP_VALUE_IN_PX),
    rowGap: resolveSpacingValue(rowGap, DEAFULT_ROW_GAP_VALUE_IN_PX),
    ...style,
  };

  return (
    <div style={flexStyle}>
      {Children.map(children, (child) => (
        <div>{child}</div>
      ))}
    </div>
  );
}
