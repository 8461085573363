import React, { createRef } from 'react';
import PublicAppWrapper from 'components/PublicAppWrapper';
import { Container, Menu, Message, Divider, Icon, Dropdown } from 'semantic';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import StandardPage from './StandardPage';
import PageLoader from 'components/PageLoader';
import { Layout } from 'components/Layout';
import { getThemedField } from 'utils/theming';
import Breadcrumbs from 'components/Breadcrumbs';
import { request } from 'utils/api';
import PortalNav from 'components/PortalNav';

import GETTING_STARTED_MD from 'docs/reference/GETTING_STARTED.md';
import TERMINOLOGY_MD from 'docs/reference/TERMINOLOGY.md';
import AUTHENTICATION_MD from 'docs/reference/AUTHENTICATION.md';
import USERS_MD from 'docs/reference/USERS.md';
import USER_NOTIFICATION_SUBSCRIPTIONS_MD from 'docs/reference/USER_NOTIFICATION_SUBSCRIPTIONS.md';
import ACCOUNTS_MD from 'docs/reference/ACCOUNTS.md';
import BILLING_PLANS_MD from 'docs/reference/BILLING_PLANS.md';
import TOKENS_MD from 'docs/reference/TOKENS.md';
import CARDS_MD from 'docs/reference/CARDS.md';
import MSP_SESSIONS_MD from 'docs/reference/MSP_SESSIONS.md';
import CPO_SESSIONS_MD from 'docs/reference/CPO_SESSIONS.md';
import MSP_REMOTE_SESSIONS from 'docs/reference/MSP_REMOTE_SESSIONS.md';
import CREDIT_SESSIONS_MD from 'docs/reference/CREDIT_SESSIONS.md';
import LOCATIONS_MSP_MD from 'docs/reference/LOCATIONS_MSP.md';
import LOCATIONS_CPO_MD from 'docs/reference/LOCATIONS_CPO.md';
import EVSE_CONTROLLERS_MD from 'docs/reference/EVSE_CONTROLLERS.md';
import TARIFF_PROFILES_MD from 'docs/reference/TARIFF_PROFILES.md';
import EVSE_ISSUES_MD from 'docs/reference/EVSE_ISSUES.md';
import UPLOADS_MD from 'docs/reference/UPLOADS.md';
import WEBHOOKS_MD from 'docs/reference/WEBHOOKS.md';
import ACCESS_GROUPS_MD from 'docs/reference/ACCESS_GROUPS.md';
import FACILITATOR_MD from 'docs/reference/FACILITATOR.md';
import ENTITIES_MD from 'docs/reference/ENTITIES.md';
import MSP_LOCATIONS_MAP from 'docs/reference/MSP_LOCATIONS_MAP.md';
import PAYMENT_INSTRUMENTS from 'docs/reference/PAYMENT_INSTRUMENTS.md';
import { withTheme } from 'contexts/theme';

const pages = [
  {
    id: 'getting-started',
    name: 'Getting Started',
    markdown: GETTING_STARTED_MD,
  },
  {
    id: 'terminology',
    name: 'Terminology',
    markdown: TERMINOLOGY_MD,
  },
  {
    id: 'authentication',
    name: 'Authentication',
    markdown: AUTHENTICATION_MD,
  },
  {
    id: 'users',
    name: 'Users',
    markdown: USERS_MD,
  },
  {
    id: 'user-notification-subscriptions',
    name: 'User Notification Subscriptions',
    markdown: USER_NOTIFICATION_SUBSCRIPTIONS_MD,
    private: true,
  },
  {
    id: 'accounts',
    name: 'Accounts',
    markdown: ACCOUNTS_MD,
  },
  {
    id: 'billing-plans',
    name: 'Billing Plans',
    markdown: BILLING_PLANS_MD,
  },
  {
    id: 'cards',
    name: 'MSP / Cards',
    markdown: CARDS_MD,
  },
  {
    id: 'tokens',
    name: 'MSP / Tokens',
    markdown: TOKENS_MD,
  },
  {
    id: 'sessions',
    name: 'MSP / Sessions',
    markdown: MSP_SESSIONS_MD,
  },
  {
    id: 'credit-sessions',
    name: 'MSP / Credit Sessions',
    markdown: CREDIT_SESSIONS_MD,
  },
  {
    id: 'msp-remote-sessions',
    name: 'MSP / Remote Sessions',
    markdown: MSP_REMOTE_SESSIONS,
    private: true,
  },
  {
    id: 'locations-msp',
    name: 'MSP / Locations',
    markdown: LOCATIONS_MSP_MD,
  },
  {
    id: 'evse-controllers',
    name: 'CPO / EVSEs',
    markdown: EVSE_CONTROLLERS_MD,
  },
  {
    id: 'tariff-profiles',
    name: 'CPO / Tariff Profiles',
    markdown: TARIFF_PROFILES_MD,
  },
  {
    id: 'evse-issues',
    name: 'CPO / EVSE Issues',
    markdown: EVSE_ISSUES_MD,
  },
  {
    id: 'access-groups',
    name: 'CPO / Access Groups',
    markdown: ACCESS_GROUPS_MD,
  },
  {
    id: 'locations-cpo',
    name: 'CPO / Locations',
    markdown: LOCATIONS_CPO_MD,
  },
  {
    id: 'sessions-cpo',
    name: 'CPO / Sessions',
    markdown: CPO_SESSIONS_MD,
  },
  {
    id: 'payment-instruments',
    name: 'Payment Instruments',
    markdown: PAYMENT_INSTRUMENTS,
    private: true,
  },
  {
    id: 'webhooks',
    name: 'Webhooks',
    markdown: WEBHOOKS_MD,
  },
  {
    id: 'uploads',
    name: 'Uploads',
    markdown: UPLOADS_MD,
  },
  {
    id: 'facilitator',
    name: 'Facilitator Access',
    markdown: FACILITATOR_MD,
  },
  {
    id: 'entities',
    name: 'Entity Reference',
    markdown: ENTITIES_MD,
  },
  {
    id: 'msp-locations-map',
    name: 'MSP Locations Map',
    markdown: MSP_LOCATIONS_MAP,
    private: true,
  },
];

function stateForParams(params) {
  const { id } = params;
  return {
    pageId: id,
    page: id ? pages.find((p) => p.id === id) : pages[0],
  };
}

class Docs extends React.Component {
  contextRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      openApi: null,
      loading: true,
      error: null,
      ...stateForParams(this.props.match.params),
    };
  }

  state = {
    loading: true,
    error: null,
  };

  async componentDidMount() {
    try {
      const openApi = await request({
        method: 'GET',
        path: '/openapi.lite.json',
      });
      this.setState({
        loading: false,
        openApi,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        ...stateForParams(this.props.match.params),
      });
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { page, loading, openApi } = this.state;

    const isDark = this.context.renderedTheme === 'dark';

    const backgroundStyle = {};

    const secondaryBackgroundColorHex = getThemedField(
      window.provider,
      'secondaryBackgroundColorHex',
      isDark
    );

    if (secondaryBackgroundColorHex) {
      backgroundStyle.background = secondaryBackgroundColorHex;
    }

    if (loading) {
      return <PageLoader />;
    }

    if (!page) {
      return (
        <PublicAppWrapper>
          <Container>
            <Message error content="Page not found" />
          </Container>
        </PublicAppWrapper>
      );
    }

    return (
      <>
        <PortalNav.Sidebar style={backgroundStyle}>
          <Layout vertical style={{ height: '100%' }}>
            <Layout.Group grow overflow>
              <Layout vertical style={{ minHeight: '100%' }}>
                <Layout.Group grow>{this.renderMenu()}</Layout.Group>
              </Layout>
            </Layout.Group>
          </Layout>
        </PortalNav.Sidebar>
        <PortalNav.Content>
          <PortalNav.MobileHead style={backgroundStyle}>
            <Layout horizontal center spread style={{ overflow: 'visible' }}>
              <Menu fluid>{this.renderMobileMenu()}</Menu>
              <PortalNav.Trigger>
                <Icon name="bars" fitted />
              </PortalNav.Trigger>
            </Layout>
          </PortalNav.MobileHead>

          <Layout vertical>
            <Container>
              <Breadcrumbs
                homePath="/docs"
                homeName="Docs"
                path={[
                  <Link key="developer" to="/docs/reference">
                    Reference
                  </Link>,
                ]}
                active={page.name}
              />
              <Divider hidden />

              <Switch>
                {pages
                  .map((page) => {
                    return (
                      <Route
                        key={page.id}
                        exact
                        path={`/docs/reference/${page.id}`}
                        component={(props) => (
                          <StandardPage
                            {...props}
                            openApi={openApi}
                            page={page}
                          />
                        )}
                      />
                    );
                  })
                  .concat([
                    <Route
                      key="index"
                      exact
                      path={`/docs/reference`}
                      component={(props) => (
                        <StandardPage
                          {...props}
                          openApi={openApi}
                          page={pages[0]}
                        />
                      )}
                    />,
                  ])}
              </Switch>
            </Container>
          </Layout>
        </PortalNav.Content>
      </>
    );
  }

  renderMenu() {
    const { pageId } = this.state;
    return (
      <>
        {pages
          .filter((menuItem) => !menuItem.private)
          .map(({ id, name }) => {
            return (
              <PortalNav.Link
                key={id}
                exact
                active={pageId === id}
                to={`/docs/reference/${id}`}
                as={NavLink}>
                {name}
              </PortalNav.Link>
            );
          })}
      </>
    );
  }

  renderMobileMenu() {
    const { pageId } = this.state;
    return (
      <Menu fluid>
        <Dropdown text="Jump to chapter" className="link item" fluid>
          <Dropdown.Menu>
            {pages
              .filter((menuItem) => !menuItem.private)
              .map(({ id, name }) => {
                return (
                  <Menu.Item
                    key={id}
                    exact
                    active={pageId === id}
                    to={`/docs/reference/${id}`}
                    as={NavLink}>
                    {name}
                  </Menu.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );
  }
}

export default withTheme(Docs);
