import React from 'react';
import { useAccountPlatformFeatures } from 'components/account-platform-features/context';

type AccountPlatformModuleProps = {
  children: React.ReactNode;
  moduleName: string;
};

// This component requires the AccountPlatformFeatureContext to be provided via AccountPlatformFeatureProvider
export default function AccountPlatformModule({
  children,
  moduleName,
}: AccountPlatformModuleProps) {
  const { accountHasPlatformModule } = useAccountPlatformFeatures();
  return accountHasPlatformModule(moduleName) ? children : null;
}
