import React from 'react';
import { Button, Label, Table } from 'semantic';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/date';
import AuditTrailModal from './AuditTrailModal';
import styled from 'styled-components';
import { startCase } from 'lodash-es';

const StyledTable = styled(Table)`
  &&& td {
    padding: 0.5rem;
  }
`;

export default function AuditTrailTable({ auditEntries }) {
  return (
    <StyledTable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User/Credential</Table.HeaderCell>
          <Table.HeaderCell>Account</Table.HeaderCell>
          <Table.HeaderCell>Provider</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Activity</Table.HeaderCell>
          <Table.HeaderCell>Object Type</Table.HeaderCell>
          <Table.HeaderCell>Normalized Path</Table.HeaderCell>
          <Table.HeaderCell>Request Method</Table.HeaderCell>
          <Table.HeaderCell>Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {auditEntries.map((auditEntry) => (
          <Table.Row key={auditEntry.id}>
            <Table.Cell>
              {auditEntry?.user && (
                <Link to={`/accounts/users/${auditEntry?.user._id}`}>
                  {auditEntry?.user?.contact?.firstName}{' '}
                  {auditEntry?.user?.contact?.lastName}
                </Link>
              )}
              {auditEntry?.credential && (
                <Link to={`/credentials/${auditEntry?.credential?._id}`}>
                  {auditEntry?.credential?.name}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>
              {auditEntry?.account && (
                <Link to={`/accounts/${auditEntry?.account._id}`}>
                  {auditEntry?.account?.name}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>
              {auditEntry?.provider && (
                <Link to={`/providers/${auditEntry?.provider._id}`}>
                  {auditEntry?.provider?.name}
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>{formatDateTime(auditEntry?.createdAt)}</Table.Cell>
            <Table.Cell>
              <Label>{startCase(auditEntry?.activity)}</Label>
            </Table.Cell>
            <Table.Cell>
              <Label>{auditEntry?.objectType}</Label>
            </Table.Cell>
            <Table.Cell style={{ overflowWrap: 'anywhere' }}>
              {auditEntry?.routeNormalizedPath}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Label>{auditEntry?.requestMethod}</Label>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <AuditTrailModal
                auditEntry={auditEntry}
                trigger={<Button basic size="small" icon="list" />}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </StyledTable>
  );
}
