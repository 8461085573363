import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

export default ({ itemId }) => {
  return (
    <Container fluid>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          name="Overview"
          to={`/my-credentials/${itemId}`}
          as={NavLink}
        />
        <Menu.Item
          exact
          name="API Logs"
          to={`/my-credentials/${itemId}/logs`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
};
