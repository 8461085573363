import React from 'react';
import { default as ReactConfetti } from 'react-dom-confetti';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class Confetti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  async componentDidMount() {
    await sleep(500);
    this.setState({
      active: true,
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ position: 'fixed' }}>
          <ReactConfetti
            active={this.state.active}
            config={{
              angle: '200',
              spread: 360,
              startVelocity: 40,
              elementCount: 70,
              dragFriction: 0.12,
              duration: 3000,
              stagger: 3,
              width: '10px',
              height: '10px',
              perspective: '500px',
              colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
            }}
          />
        </div>
      </div>
    );
  }
}
