import React, { useState, useEffect } from 'react';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';

import { Form, Message, Modal, Button } from 'semantic';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import {
  useAccessGroupOwnershipOptions,
  useAccessGroupTypeOptions,
} from 'utils/constants';

function EditAccessGroup(props) {
  const { initialValues, trigger } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const accessGroupTypeOptions = useAccessGroupTypeOptions();
  const accessGroupOwnershipOptions = useAccessGroupOwnershipOptions();

  useEffect(() => {
    if (initialValues !== formValues) {
      setSubmitted(false);
      setFormValues({ ...initialValues });
    }
  }, [initialValues]);

  const handleSubmit = () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    const body = formValues;
    const isUpdate = !!initialValues.id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate
      ? `/1/access-groups/${initialValues.id}`
      : '/1/access-groups';

    request({
      method,
      path,
      body: { ...body, members: undefined },
    })
      .then(() => {
        setLoading(false);
        if (props.onClose) props.onClose();
        setFormValues(props.initialValues);
        setOpen(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const isUpdate = !!initialValues.id;

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        if (props.onClose) props.onClose();
        setFormValues(props.initialValues);
        setSubmitted(false);
        setOpen(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {isUpdate
          ? t('editAccessGroup.editAccessGroup', 'Edit Access Group')
          : t('editAccessGroup.newAccessGroup', 'New Access Group')}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="EditAccessGroup-form"
          error={submitted && Boolean(error)}
          onSubmit={handleSubmit}>
          {error && <Message error content={error.message} />}

          <Form.Field required>
            <label>{t('editAccessGroup.accountLabel', 'Account')}</label>
            <SearchDropDown
              clearable={false}
              disabled={isUpdate}
              value={formValues.accountId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                });
              }}
              onChange={(e, { value }) => setField('accountId', value)}
            />
          </Form.Field>

          <Form.Select
            value={formValues.type}
            disabled={isUpdate}
            options={accessGroupTypeOptions}
            name="type"
            label={t('editAccessGroup.typeLabel', 'Type')}
            type="text"
            required
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Input
            value={formValues.name || ''}
            name="name"
            label={t('editAccessGroup.nameLabel', 'Name')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Select
            value={formValues.ownershipContext}
            options={accessGroupOwnershipOptions}
            name="ownershipContext"
            label={t(
              'editAccessGroup.ownershipContextVisbility',
              'Access Group Visibility'
            )}
            type="text"
            required
            onChange={(e, { name, value }) => setField(name, value)}
          />

          {formValues.ownershipContext === 'user' && (
            <Form.Field required>
              <label>{t('editAccessGroup.userLabel', 'User')}</label>
              <SearchDropDown
                keywordField="searchPhrase"
                value={formValues.userId}
                objectMode={false}
                getOptionLabel={(item) => `${item.name} (${item.email})`}
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/users/search',
                    method: 'POST',
                    body: {
                      accountId: formValues.accountId,
                      ...body,
                    },
                  });
                }}
                onChange={(e, { value }) => setField('userId', value)}
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={
            isUpdate
              ? t('editAccessGroup.updateAccessGroupButton', 'Update')
              : t('editAccessGroup.updateAccessGroupButton', 'Create')
          }
          form="EditAccessGroup-form"
        />
      </Modal.Actions>
    </Modal>
  );
}

EditAccessGroup.defaultProps = {
  initialValues: {
    type: 'users',
    ownershipContext: 'account',
  },
};

export default EditAccessGroup;
