import React from 'react';
import { Box } from 'components';
import { Item, Icon, Header } from 'semantic';
import { useDashboard } from '../context';
import { formatNumber } from 'utils/formatting';
import widgetstyles from '../widgetstyles.module.less';
import { useTranslation } from 'react-i18next';

export default function MyChargePoints() {
  const { cpo, hasLocations, isAccount } = useDashboard();
  const { t } = useTranslation();

  const title = isAccount
    ? t(
        'dashboardHome.myChargePointsTitleAccount',
        "My Account's Charge Points"
      )
    : t('dashboardHome.myChargePointsTitle', 'My Charge Points');

  if (!hasLocations) {
    return (
      <Box title={title}>
        <Box.Content>
          <div className={widgetstyles.itemGrid}>
            <div className={widgetstyles.itemGridColumn}>
              <Item.Group unstackable className={widgetstyles.itemGroup}>
                <Item>
                  <Item.Image>
                    <Icon name="bolt" className={widgetstyles.itemIcon} />
                  </Item.Image>
                  <Item.Content verticalAlign="middle">
                    <Header as="div">
                      {t(
                        'dashboard.myChargePointsPlaceHolder',
                        'Setup your Charging Station'
                      )}
                    </Header>
                    <div className={widgetstyles.itemSubheader}>
                      {t(
                        'dashboard.myChargePointsPlaceHolderSubheader',
                        'Begin by adding your Locations, Charging stations & Users'
                      )}
                    </div>
                  </Item.Content>
                </Item>
              </Item.Group>
            </div>
          </div>
        </Box.Content>

        <Box.Actions>
          <Box.ActionLink to="/my-locations">
            {t(
              'dashboardHome.myChargePointsAddLocation',
              '+ New Charging Station'
            )}
          </Box.ActionLink>
        </Box.Actions>
      </Box>
    );
  }

  return (
    <Box title={title}>
      <Box.Content>
        <div className={widgetstyles.itemGrid}>
          <div className={widgetstyles.itemGridColumn}>
            <Item.Group unstackable className={widgetstyles.itemGroup}>
              <Item>
                <Item.Image>
                  <Icon name="location-dot" className={widgetstyles.itemIcon} />
                </Item.Image>
                <Item.Content verticalAlign="middle">
                  <Header as="div">
                    {t(
                      'dashboardHome.myChargePointsLocationCount',
                      '{{count}} Locations',
                      {
                        count: formatNumber(cpo.locations),
                      }
                    )}
                  </Header>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
          <div className={widgetstyles.itemGridColumn}>
            <Item.Group unstackable className={widgetstyles.itemGroup}>
              <Item>
                <Item.Image>
                  <Icon
                    name="charging-station"
                    className={widgetstyles.itemIcon}
                  />
                </Item.Image>
                <Item.Content verticalAlign="middle">
                  <Header as="div">
                    {t(
                      'dashboardHome.myChargePointsEvseCount',
                      '{{count}} Charge Stations',
                      {
                        count: formatNumber(cpo.evses),
                      }
                    )}
                  </Header>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
          <div className={widgetstyles.itemGridColumn}>
            <Item.Group unstackable className={widgetstyles.itemGroup}>
              <Item>
                <Item.Image>
                  <Icon name="car-side" className={widgetstyles.itemIcon} />
                </Item.Image>
                <Item.Content verticalAlign="middle">
                  <Header as="div">
                    {t(
                      'dashboardHome.myChargePointsActiveSessionCount',
                      '{{count}} Active Sessions',
                      {
                        count: formatNumber(cpo.activeSessions),
                      }
                    )}
                  </Header>
                </Item.Content>
              </Item>
            </Item.Group>
          </div>
        </div>
      </Box.Content>

      <Box.Actions>
        <Box.ActionLink to="/my-locations">
          {t('dashboardHome.myChargePointsViewLocations', 'View Locations')}
        </Box.ActionLink>
        <Box.ActionLink to="/my-locations/usage">
          {t('dashboardHome.myChargePointsViewSessions', 'View Sessions')}
        </Box.ActionLink>
      </Box.Actions>
    </Box>
  );
}
