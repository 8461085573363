import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Divider, Button, Message } from 'semantic';
import InputField from '../../../../../components/form-fields/formik/InputField';
import { request } from '../../../../../utils/api';
import FormVerticalSpace from '../../../components/atoms/FormVerticalSpace';
import { usePasswordAndAuthValidaton } from './password-and-auth.validation';
import { useMutation } from '@tanstack/react-query';
import { useUser } from 'contexts/user';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';

interface PasswordFormValues {
  currentPassword: string;
  newPassword: string;
}

export default function PasswordAndAuthenticationForm() {
  // @ts-ignore until the user context is typed
  const { user } = useUser();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const validationSchema = usePasswordAndAuthValidaton();

  useEffect(() => {
    const TwentyMinutesAgo = Date.now() - 20 * 60 * 1000;

    if (Date.parse(user.accessConfirmedAt) < TwentyMinutesAgo) {
      history.push(`/confirm-access?to=${location.pathname}`);
    }
  }, []);

  const handleSubmitMutation = useMutation(
    async (values: PasswordFormValues) => {
      await request({
        method: 'POST',
        path: '/1/users/me/set-credentials',
        body: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
      });
    }
  );

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmitMutation.mutate}
      enableReinitialize>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} loading={handleSubmitMutation.isLoading}>
          <FormHeader
            size="large"
            pb={5}
            pt={5}
            text={t(
              'settings.personal.passwordAndAuthentication.title',
              'Password & Authentication'
            )}
          />
          <Divider />
          {handleSubmitMutation.isError && (
            <Message
              error
              content={(handleSubmitMutation.error as any)?.message}
            />
          )}
          {handleSubmitMutation.isSuccess && (
            <Message
              success
              content={t('messages.updated', 'Successfully updated!')}
            />
          )}
          <p>
            {t(
              'settings.personal.passwordAndAuthentication.description',
              'Update your password or add additional security measures to your account.'
            )}
          </p>

          <FormHeader
            size="medium"
            mt={20}
            mb={15}
            text={t(
              'settings.personal.passwordAndAuthentication.passwordSubheader',
              'Password'
            )}
          />
          <InputField
            name="currentPassword"
            label={t(
              'settings.personal.passwordAndAuthentication.currentPassword',
              'Current Password'
            )}
            type="password"
            required
            wrapperStyle={{ maxWidth: 285 }}
          />
          <FormVerticalSpace />
          <InputField
            name="newPassword"
            label={t(
              'settings.personal.passwordAndAuthentication.newPassword',
              'New Password'
            )}
            type="password"
            required
            wrapperStyle={{ maxWidth: 285 }}
          />
          <FormVerticalSpace />
          <InputField
            name="confirmPassword"
            label={t(
              'settings.personal.passwordAndAuthentication.confirmPassword',
              'Confirm New Password'
            )}
            type="password"
            required
            wrapperStyle={{ maxWidth: 285 }}
          />
          <FormVerticalSpace size={50} />
          <Button type="submit" style={{ marginLeft: 0 }}>
            {t(
              'settings.personal.passwordAndAuthentication.updatePassword',
              'Update Password'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
