import React, { useState, useEffect } from 'react';
import { request, getFilePath } from 'utils/api';
import InspectObject from 'components/modals/InspectObject';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumbs, ListHeader, ErrorMessage } from 'components';
import { Dropdown, Button, Divider, Loader, Message } from 'semantic';
import { Switch, Route } from 'react-router-dom';
import { currentUserCanAccess } from 'utils/roles';
import Actions from '../Actions';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';

export default function InvoiceDetail({
  prefixPath = '/finance',
  overviewPath = '/finance/invoices/:id',
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const writeAccess = currentUserCanAccess('invoices', 'write');

  const financeBreadcrumbPath = prefixPath;
  const invoicesBreadcrumbPath = `${prefixPath}/invoices`;

  useEffect(() => {
    fetchItem();
  }, [id]);

  const fetchItem = async () => {
    setError(null);
    setLoading(true);
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/invoices/${id}`,
      });

      setItem(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="finance" to={financeBreadcrumbPath}>
            {t('finance.title', 'Finance')}
          </Link>,
          <Link key="invoices" to={invoicesBreadcrumbPath}>
            {t('invoices.title', 'Invoices')}
          </Link>,
        ]}
        active={item?.identifier || '...'}
      />
      <ListHeader
        title={t('invoiceDetails.header', 'Invoice #{{name}} for {{date}}', {
          name: item?.identifier,
          date: item ? `${item.year} / ${item.month}` : '',
        })}>
        {item && (
          <>
            <Button
              basic
              icon="download"
              content="Download"
              href={getFilePath(
                `/1/invoices/${item.id}/pdf?accessToken=${item.accessToken}`
              )}
              disabled={!item.isReady}
            />
            <InspectObject
              name="Invoice"
              data={item}
              trigger={<Button basic content={'Inspect'} icon="code" />}
            />

            <Dropdown button basic text={t('common.more', 'More')}>
              <Dropdown.Menu direction="left">
                <Actions
                  writeAccess={writeAccess}
                  item={item}
                  onReload={fetchItem}
                  prefixPath={prefixPath}
                />
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </ListHeader>
      <Divider hidden />
      {loading && <Loader active />}
      {error && <ErrorMessage error={error} />}
      {item && item.deletedAt && (
        <Message
          error
          content={t('invoice.hasBeenDeleted', 'This invoice has been deleted')}
        />
      )}

      {item && (
        <Switch>
          <Route
            exact
            path={overviewPath}
            item={item}
            component={(props) => <Overview {...props} invoice={item} />}
          />
        </Switch>
      )}
    </>
  );
}
