import React from 'react';
import { FieldValidator, useField } from 'formik';
import { Form } from 'semantic';

interface Props {
  name: string;
  label?: string | React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  validate?: FieldValidator;
  // set this to true if you do not want to show the error label below the input if the field is not valid. Note that
  // the field will still be styled as invalid
  hideErrorLabel?: boolean;
  onChangeFn?: () => void;
  toggle?: boolean;
}

const CheckboxField: React.FC<Props> = ({
  name,
  label,
  disabled,
  required,
  validate,
  hideErrorLabel,
  onChangeFn,
  toggle,
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  return (
    <Form.Checkbox
      checked={field.value}
      required={required}
      disabled={disabled}
      aria-label={name}
      label={label}
      name={name}
      toggle={toggle}
      error={
        meta.touched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
      onChange={(e, { checked }) => {
        helpers.setValue(checked);
        toggle = { toggle };
        onChangeFn?.();
      }}
    />
  );
};

export default CheckboxField;
