import React, { useState } from 'react';

import { camelCase, isArray, startCase, upperFirst } from 'lodash-es';
import {
  Button,
  Divider,
  Header,
  Label,
  Loader,
  Popup,
  Segment,
  SemanticCOLORS,
  Table,
} from 'semantic';
import { TFunction, useTranslation } from 'react-i18next';

import { request } from 'utils/api';
import { determineLastConnectivityMode } from 'utils/evse-controllers';
import UpdateFirmware from 'components/modals/UpdateFirmware';
import AutoConfigureEvseController from 'components/modals/AutoConfigureEvseController';
import EditEvseControllerConnectors from 'components/modals/EditEvseControllerConnectors';
import {
  currentUserCanAccess,
  isSuperAdmin,
  userCanAccessProviderEndpoint,
} from 'utils/roles';
import { FeatureFlag } from 'components';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import { fromNow } from 'utils/date';
import ErrorMessage from 'components/ErrorMessage';
import useInterval from 'hooks/useInternal';
import {
  ConfigureSecureConnectionSyncStatus,
  EvseController,
  EvseControllerAuthenticationMethod,
  EvseControllerConnectionProtocol,
  EvseControllerConnectionType,
  EvseControllerConnectivityState,
  RenewBasicAuthPasswordSyncStatus,
} from 'types/evse-controller';
import useFetch from 'hooks/useFetch';
import { useUser } from 'contexts/user';
import SecurityProfileModal from './SecurityProfileModal';
import CircularIcon from 'components/CircularIcon';
import RenewBasicAuthModal from 'screens/EvseControllers/Detail/RenewBasicAuthModal';

type Props = {
  evseController: EvseController;
  maintainerMode: boolean;
};

export default function Diagnostics({
  maintainerMode,
  evseController: evseCtrl,
}: Props) {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const [getDiagnosticsLoading, setGetDiagnosticsLoading] =
    useState<boolean>(false);
  const [getDiagnosticsError, setGetDiagnosticsError] = useState<Error | null>(
    null
  );
  const [resetSerialNumbersLoading, setResetSerialNumbersLoading] =
    useState(false);
  const [resetSerialNumbersError, setResetSerialNumbersError] =
    useState<Error | null>(null);
  const { data: evseController, refresh: evseControllerRefresh } =
    useFetch<EvseController>({
      method: 'GET',
      path: `/1/evse-controllers/${evseCtrl.id}`,
    });

  useInterval(async () => {
    evseControllerRefresh();
  }, 4000);

  if (!evseController || !user) return <Loader active />;

  const getDiagnostics = async () => {
    setGetDiagnosticsLoading(true);
    setGetDiagnosticsError(null);
    try {
      await request({
        method: 'POST',
        path: `/1/evse-controllers/${evseController.id}/diagnostics`,
        body: {},
      });
      evseControllerRefresh();
    } catch (error) {
      setGetDiagnosticsError(error as Error);
    } finally {
      setGetDiagnosticsLoading(false);
    }
  };

  const resetSerialNumbers = async () => {
    setResetSerialNumbersLoading(true);
    setResetSerialNumbersError(null);
    try {
      await request({
        method: 'POST',
        path: `/1/evse-controllers/${evseController.id}/reset-serial-numbers`,
        body: {},
      });
      evseControllerRefresh();
    } catch (error) {
      setResetSerialNumbersError(error as Error);
    } finally {
      setResetSerialNumbersLoading(false);
    }
  };

  const canUserWriteEvseControllers = currentUserCanAccess(
    'evseControllers',
    'write'
  );

  const canUserReadWriteEvseControllersMaintenance =
    userCanAccessProviderEndpoint(
      user,
      provider,
      'evseControllersMaintenance',
      'read-write'
    );

  const canUserChangeEvseConnectors =
    maintainerMode ||
    canUserReadWriteEvseControllersMaintenance ||
    isSuperAdmin(user);

  const canUserWriteEvseCommands =
    isSuperAdmin(user) || currentUserCanAccess('evseCommands', 'write');

  const connectivityMode = determineLastConnectivityMode(evseController);
  const googleCloudLogsUrl = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.container_name%3D%22ocpp-server%22%0AtextPayload%3D~%22${evseController.ocppIdentity}%22?project=eflux-production`;
  const coordinatorStatus = evseController.coordinatorStatus || {
    state: 'unassigned',
  };
  const isEvseControllerConnected =
    evseController.connectivityState ===
    EvseControllerConnectivityState.Connected;

  return (
    <div>
      <Header as="h3">
        {t('evseControllerDiagnostics.coordinatorStatus', 'Coordinator Status')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {t('evseControllerDiagnostics.ocppIdentity', 'OCPP Identity')}
            </Table.Cell>
            <Table.Cell>{evseController.ocppIdentity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.ocppFtpPassword',
                'OCPP FTP Password'
              )}
            </Table.Cell>

            <Table.Cell>{evseController.ocppFtpPassword || '-'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.connectivityStatus',
                'Connectivity Status'
              )}
            </Table.Cell>
            <Table.Cell>
              <ConnectivityStatus item={evseController} oneLine />
            </Table.Cell>
          </Table.Row>
          {canUserWriteEvseControllers && (
            <Table.Row>
              <Table.Cell>
                {t(
                  'evseControllerDiagnostics.connectivityMode',
                  'Connectivity Mode'
                )}
              </Table.Cell>
              <Table.Cell>
                <Label
                  color={(connectivityMode.color || 'grey') as SemanticCOLORS}
                  content={connectivityMode.content}
                />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>
              {t('evseControllerDiagnostics.assignedTo', 'Assigned To')}
            </Table.Cell>
            <Table.Cell>
              {coordinatorStatus?.identifier || '-'} (
              <a
                href={googleCloudLogsUrl}
                rel="noopener external noreferrer"
                target="_blank">
                {t('evseControllerDiagnostics.openLogs', 'Open Logs')}
              </a>
              )
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('evseControllerDiagnostics.lastConnected', 'Last Connected')}
            </Table.Cell>
            <Table.Cell>
              {evseController.coordinatorConnectedAt
                ? fromNow(evseController.coordinatorConnectedAt)
                : '-'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('evseControllerDiagnostics.lastHeartbeat', 'Last Heartbeat')}
            </Table.Cell>
            <Table.Cell>
              {evseController.heartbeatReceivedAt
                ? fromNow(evseController.heartbeatReceivedAt)
                : '-'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.lastTokenAuthorize',
                'Last Token Authorize'
              )}
            </Table.Cell>
            <Table.Cell>
              {evseController.tokenAuthorizeReceivedAt
                ? fromNow(evseController.tokenAuthorizeReceivedAt)
                : '-'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.lastDisconnected',
                'Last Disconnected'
              )}
            </Table.Cell>
            <Table.Cell>
              {evseController.coordinatorDisconnectedAt
                ? fromNow(evseController.coordinatorDisconnectedAt)
                : '-'}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('evseControllerDiagnostics.connectionInfo', 'Connection Info')}
            </Table.Cell>
            <Table.Cell>
              {evseController.coordinatorStatus?.connectionInfo ? (
                <>
                  <p>
                    {t(
                      'evseControllerDiagnostics.connectionType',
                      'Connection Type'
                    )}
                    :{' '}
                    <strong>
                      {evseController.connectionType ===
                      EvseControllerConnectionType.Private
                        ? 'VPN'
                        : 'Public'}
                    </strong>
                  </p>
                  <p>
                    {t(
                      'evseControllerDiagnostics.remoteAddress',
                      'Remote Address'
                    )}
                    :{' '}
                    <strong>
                      {evseController.coordinatorStatus.connectionInfo
                        .remoteAddress || ''}
                    </strong>
                  </p>
                  <p>
                    {t(
                      'evseControllerDiagnostics.websocketProtocol',
                      'Websocket Protocol'
                    )}
                    : <strong>{evseController.connectionProtocol}</strong>
                  </p>
                  <p>
                    {t(
                      'evseControllerDiagnostics.ocppProtocol',
                      'OCPP Protocol'
                    )}
                    :{' '}
                    <strong>
                      {evseController.coordinatorStatus.connectionInfo.protocol}
                    </strong>
                  </p>
                  <p>
                    {t('evseControllerDiagnostics.host', 'Host')}:{' '}
                    <strong>
                      {evseController.coordinatorStatus.connectionInfo.host}
                    </strong>
                  </p>
                </>
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.securityProfile',
                'Security Profile'
              )}
            </Table.Cell>
            <Table.Cell>
              {' '}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 5,
                  paddingBottom: 14,
                }}>
                {t('evseControllerDiagnostics.securityProfileStatus', 'Status')}
                :<strong>{securityProfileStatus(t, evseController)}</strong>{' '}
                <SecurityProfileExtraStatus evseController={evseController} />
              </div>
              <p>
                {t('evseControllerDiagnostics.securityProfileType', 'Type')}:{' '}
                <strong>{securityProfileType(evseController)}</strong>
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.authenticationInfo',
                'Authentication Info'
              )}
            </Table.Cell>
            <Table.Cell>
              {evseController.authorizationInfo ? (
                <>
                  <div style={{ paddingBottom: 14 }}>
                    {t(
                      'evseControllerDiagnostics.authenticationType',
                      'Authentication Type'
                    )}
                    :{' '}
                    <strong>
                      {upperFirst(
                        evseController.ocppAuthorizationMethod || 'None'
                      )}
                    </strong>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '3px' }}>
                      {t(
                        'evseControllerDiagnostics.authenticationStatus',
                        'Authentication Status'
                      )}
                      :{' '}
                    </div>
                    <AuthenticationStatus evseController={evseController} />
                  </div>
                </>
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t(
                'evseControllerDiagnostics.serialNumber',
                'Serial Number History'
              )}
            </Table.Cell>
            <Table.Cell>
              {isArray(evseController.bootInfoSerialNumbers)
                ? evseController.bootInfoSerialNumbers.map((item) => (
                    <p key={item.serialNumber}>
                      {t(
                        'evseControllerDiagnostics.lastBootNotification',
                        'Last boot notification with serial number'
                      )}{' '}
                      <strong>{item.serialNumber}</strong>{' '}
                      {t(
                        'evseControllerDiagnostics.lastBootedAt',
                        'was {{lastBootedAt}}',
                        { lastBootedAt: fromNow(item.lastBootedAt) }
                      )}
                    </p>
                  ))
                : '-'}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Divider hidden />
      <Header as="h3">{t('evseControllerDiagnostics.setup', 'Setup')}</Header>
      <Segment>
        <AutoConfigureEvseController
          onClose={() => evseControllerRefresh()}
          onDone={() => evseControllerRefresh()}
          evseController={evseController}
          trigger={
            <Button
              icon="wand-magic-sparkles"
              content={t(
                'evseControllerDiagnostics.autoConfiguration',
                'Auto Configuration'
              )}
              disabled={!isEvseControllerConnected}
              primary
            />
          }
        />
        {canUserChangeEvseConnectors && (
          <EditEvseControllerConnectors
            onClose={() => evseControllerRefresh()}
            evseController={evseController}
            trigger={
              <Button
                icon="plug"
                content={t(
                  'evseControllerDiagnostics.configureConnectors',
                  'Configure Connectors'
                )}
                primary
              />
            }
          />
        )}
        {canUserWriteEvseCommands && (
          <FeatureFlag feature="hardware_evsecontroller_basicauth">
            <>
              <SecurityProfileModal evseController={evseController} />
              {evseController.ocppAuthorizationMethod ===
                EvseControllerAuthenticationMethod.Basic && (
                <RenewBasicAuthModal
                  evseController={evseController}
                  onRenew={() => evseControllerRefresh()}
                />
              )}
            </>
          </FeatureFlag>
        )}
      </Segment>
      <Divider hidden />
      <Header as="h3">
        {t('evseControllerDiagnostics.deviceDiagnostics', 'Device Diagnostics')}
      </Header>
      {getDiagnosticsError && <ErrorMessage error={getDiagnosticsError} />}
      {resetSerialNumbersError && (
        <ErrorMessage error={resetSerialNumbersError} />
      )}
      <Segment>
        {evseController.diagnosticsRequestedAt && (
          <p>
            {t(
              'evseControllerDiagnostics.diagnosticsUploadRequested',
              'Diagnostics upload requested'
            )}
            : {fromNow(evseController.diagnosticsRequestedAt)}
          </p>
        )}
        {evseController.diagnosticsRequestedAt &&
          !evseController.diagnosticsUpload && (
            <p>
              <i>
                {t(
                  'evseControllerDiagnostics.waiting',
                  'Waiting for diagnostics upload from device.'
                )}
              </i>
            </p>
          )}

        {evseController.diagnosticsUpload && (
          <p>
            {t(
              'evseControllerDiagnostics.diagnosticsUploaded',
              'Diagnostics uploaded'
            )}{' '}
            <Button
              basic
              content={`Download ${evseController.diagnosticsUpload.filename}`}
              as={'a'}
              rel="noopener"
              target="_blank"
              href={evseController.diagnosticsUpload.rawUrl}
            />
          </p>
        )}
        <Button
          onClick={() => getDiagnostics()}
          disabled={!isEvseControllerConnected}
          loading={getDiagnosticsLoading}
          icon="cloud-arrow-up"
          content="Request Diagnostics"
          primary
        />
        <Button
          onClick={() => resetSerialNumbers()}
          loading={resetSerialNumbersLoading}
          icon="arrow-rotate-left"
          content="Reset Serial Numbers"
          primary
        />
      </Segment>
      <Divider hidden />
      <Header as="h3">
        {t('evseControllerDiagnostics.firmwareUpdate', 'Firmware Update')}
      </Header>
      <Segment>
        {evseController.firmwareUpload && (
          <p>
            {t(
              'evseControllerDiagnostics.lastFirmwhareUpdate',
              'Last firmware binary uploaded'
            )}{' '}
            {fromNow(evseController.firmwareUpload.createdAt)}
            {' (filename: '}
            {evseController.firmwareUpload.filename}
            {', mimeType: '}
            {evseController.firmwareUpload.mimeType})
          </p>
        )}
        <UpdateFirmware
          onDone={() => evseControllerRefresh()}
          initialValues={{}}
          evseControllerId={evseController.id}
          trigger={
            <Button
              disabled={!isEvseControllerConnected}
              title="Download"
              icon="download"
              content="Update Firmware"
              primary
            />
          }
        />
      </Segment>
    </div>
  );
}

function AuthenticationStatus({
  evseController,
}: {
  evseController: EvseController;
}) {
  const { state, error } = evseController.authorizationInfo || {};

  if (
    evseController.ocppAuthorizationMethod ===
    EvseControllerAuthenticationMethod.None
  ) {
    return <strong>-</strong>;
  }

  if (state === 'error') {
    return (
      <>
        <Label content={startCase(state)} color="red" />
        <Popup
          style={{ marginInlineStart: '-10px' }}
          trigger={
            <div style={{ marginInlineStart: '3px' }}>
              <CircularIcon color="grey" icon="info" />
            </div>
          }
          content={error?.message}
        />
      </>
    );
  }

  // if renewal of the basic auth password is in progress or failed
  if (
    evseController.renewBasicAuthPasswordSync?.status ===
    RenewBasicAuthPasswordSyncStatus.InProgress
  ) {
    return <Label content="In Progress" color="blue" />;
  }

  if (
    evseController.renewBasicAuthPasswordSync?.status ===
    RenewBasicAuthPasswordSyncStatus.Failed
  ) {
    return (
      <>
        <Label content="Failed" color="red" />
        <Popup
          style={{ marginInlineStart: '-10px' }}
          trigger={
            <div style={{ marginInlineStart: '3px' }}>
              <CircularIcon color="grey" icon="info" />
            </div>
          }
          content={evseController.renewBasicAuthPasswordSync.statusDetails}
        />
      </>
    );
  }

  if (state === 'success') {
    return <Label content={startCase(state)} color="olive" />;
  }

  return <Label content="Unknown" />;
}

function SecurityProfileExtraStatus({
  evseController,
}: {
  evseController: EvseController;
}) {
  const { t } = useTranslation();
  const { status, statusDetails } =
    evseController?.configureSecureConnectionSync || {};

  const showExtraSecurityProfileStatus =
    status === ConfigureSecureConnectionSyncStatus.InProgress ||
    status === ConfigureSecureConnectionSyncStatus.Failed;

  if (!showExtraSecurityProfileStatus) {
    return null;
  }

  let color: SemanticCOLORS = 'blue';
  if (status === ConfigureSecureConnectionSyncStatus.Failed) {
    color = 'red';
  }

  return (
    <>
      <Label
        content={t(
          `evseControllerDiagnostics.${status}`,
          startCase(camelCase(status))
        )}
        color={color}
      />
      {statusDetails && (
        <Popup
          content={statusDetails}
          trigger={
            <div>
              <CircularIcon icon="info" color="#ccc" />
            </div>
          }
        />
      )}
    </>
  );
}

function securityProfileType(evseController: EvseController) {
  if (
    evseController.ocppAuthorizationMethod ===
      EvseControllerAuthenticationMethod.Basic &&
    evseController.connectionProtocol === EvseControllerConnectionProtocol.Ws
  ) {
    return '1';
  }

  if (
    evseController.ocppAuthorizationMethod ===
      EvseControllerAuthenticationMethod.Basic &&
    evseController.connectionProtocol === EvseControllerConnectionProtocol.Wss
  ) {
    return '2';
  }

  return '-';
}

function securityProfileStatus(t: TFunction, evseController: EvseController) {
  const basicAuthEnabled =
    evseController.ocppAuthorizationMethod ===
    EvseControllerAuthenticationMethod.Basic;

  if (basicAuthEnabled) {
    return t(
      'evseControllerDiagnostics.securityProfileStatusEnabled',
      'Enabled'
    );
  }

  if (
    evseController?.configureSecureConnectionSync?.status ===
    ConfigureSecureConnectionSyncStatus.InProgress
  ) {
    return t(
      'evseControllerDiagnostics.securityProfileStatusEnablingSecurity',
      'Enabling Security'
    );
  }

  return t(
    'evseControllerDiagnostics.securityProfileStatusDisabled',
    'Disabled'
  );
}
