import React from 'react';
import { Header, Table, Label, Icon, Popup } from 'semantic';
import { Link } from 'react-router-dom';
import { labelForStatus, colorForStatus } from 'utils/locations';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';

import { hasProviderRoles } from 'utils/roles';
import { formatDateTime } from 'utils/date';
import { HelpTip, PlatformFeature } from 'components';

export default function LocationOverview({ location }) {
  const { t } = useTranslation();
  const { user } = useUser();

  const isProvider = hasProviderRoles(user);

  return (
    <div>
      {/*<Header as="h1">{location.name}</Header> */}
      <Header as="h3">{t('common.status', 'Status')}</Header>
      <Label
        color={colorForStatus(location.status)}
        content={labelForStatus(location.status)}
      />
      <Header as="h3">{t('common.details', 'Details')}</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('common.address', 'Address')}</Table.Cell>
            <Table.Cell>
              {[
                location.address,
                location.postal_code,
                location.city,
                location.country,
              ]
                .filter(Boolean)
                .join(', ')}
            </Table.Cell>
          </Table.Row>
          {isProvider && location.account && (
            <Table.Row>
              <Table.Cell>{t('common.account', 'Account')}</Table.Cell>
              <Table.Cell>
                {location.account ? (
                  <Link to={`/accounts/${location.account.id}`}>
                    {location.account.name || t('labels.noName', 'No Name')}
                  </Link>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>{t('common.createdAt', 'Created At')}</Table.Cell>
            <Table.Cell>{formatDateTime(location.createdAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.updatedAt', 'Updated At')}</Table.Cell>
            <Table.Cell>{formatDateTime(location.updatedAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.provider', 'Provider')}</Table.Cell>
            <Table.Cell>{location.provider.name}</Table.Cell>
          </Table.Row>
          {location.suboperator && (
            <PlatformFeature feature="roaming:suboperator-locations">
              <Table.Row>
                <Table.Cell>
                  {t('common.suboperator', 'Suboperator')}
                </Table.Cell>
                <Table.Cell>
                  {(location.suboperator?.source != 'location' && (
                    <Popup
                      content={t(
                        'suboperator.popup',
                        'This value has been inherited from account settings.'
                      )}
                      trigger={<Label content={location.suboperator?.name} />}
                    />
                  )) || <Label content={location.suboperator?.name} />}
                </Table.Cell>
              </Table.Row>
            </PlatformFeature>
          )}
        </Table.Body>
      </Table>
    </div>
  );
}
