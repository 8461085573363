import * as Sentry from '@sentry/react';

export function captureError(err, extras) {
  if (extras) {
    for (let [name, value] of Object.entries(extras)) {
      Sentry.setExtra(name, value);
    }
  }
  Sentry.captureException(err);
}
