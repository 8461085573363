import React from 'react';
import { Dropdown } from 'semantic';

import countries from 'utils/countries';

const options = countries
  .filter((c) => c.callingCode)
  .map(({ flag, callingCode, countryCode }) => ({
    key: countryCode,
    text: `${flag} ${countryCode} +${callingCode}`,
    value: countryCode,
  }));

export default function phoneCountryCode({
  value,
  onChange,
  styles = {},
  ...rest
}) {
  return (
    <Dropdown
      style={{
        height: '36px',
        marginRight: '1em',
        minWidth: '120px',
        ...styles,
      }}
      selection
      search
      value={value}
      options={options}
      onChange={onChange}
      {...rest}
    />
  );
}
