import React from 'react';
import { request } from 'utils/api';
import { AppWrapper, Confirm, ListHeader, SearchProvider } from 'components';

import { Button, Container, Divider, Message, Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import StartRemoteSession from 'components/modals/StartRemoteSession';
import { formatDateTime } from 'utils/date';

import styles from './styles.module.less';
import { getParameterByName } from 'utils/url';

export default function MyRemoteSessions() {
  const { t } = useTranslation();

  const evseId = getParameterByName('evseId');
  const connectorId = getParameterByName('connectorId');

  const fetchData = async (body) => {
    return await request({
      method: 'POST',
      path: '/1/remote-sessions/mine/search',
      body,
    });
  };

  return (
    <AppWrapper>
      <Container style={{ position: 'relative' }}>
        <SearchProvider limit={100} onDataNeeded={fetchData} live>
          {({ items, reload }) => (
            <>
              <ListHeader
                topPadded
                mobileChildAlignment="bottom"
                title={t('myRemoteSessions.title', 'My Remote Sessions')}>
                <StartRemoteSession
                  evseId={evseId}
                  connectorId={connectorId}
                  openOnInit={!!evseId}
                  onClose={() => reload()}
                  onSubmit={() => reload()}
                  trigger={
                    <Button
                      primary
                      className={styles.startRemoteSessionButton}
                      content={t(
                        'myRemoteSessions.startRemoveSession',
                        'Start Remote Session'
                      )}
                      icon="charging-station"
                    />
                  }
                />
              </ListHeader>
              <Divider hidden />
              {items.length === 0 ? (
                <Message
                  content={t(
                    'myRemoveSessions.noSessionsYet',
                    'No remote sessions created yet'
                  )}
                />
              ) : (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>
                        {t('activeSessionsTable.columnStatus', 'Status')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('myUsage.colummStartTime', 'Start Time')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('activeSessionsTable.columnLocation', 'Location')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('myUsage.columnActions', 'Actions')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => {
                      return (
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.status}</Table.Cell>
                          <Table.Cell>
                            {formatDateTime(item.createdAt)}
                          </Table.Cell>
                          <Table.Cell>{item.location.name}</Table.Cell>
                          <Table.Cell textAlign="center">
                            {item.status === 'ACTIVE' && (
                              <Confirm
                                header={t(
                                  'myRemoteSessions.confirmStopSession',
                                  'Are you sure you want to stop this session?'
                                )}
                                trigger={
                                  <Button
                                    primary
                                    content="End Session"
                                    icon="stop"
                                  />
                                }
                                button={t(
                                  'myRemoteSessions.stopSessionButton',
                                  'End Session'
                                )}
                                onConfirm={async () => {
                                  await request({
                                    method: 'POST',
                                    path: `/1/remote-sessions/mine/${item.id}/stop`,
                                    body: {},
                                  });
                                  reload();
                                }}
                              />
                            )}
                            {item.status === 'PENDING' && (
                              <Confirm
                                header={t(
                                  'myRemoteSessions.confirmCancelSession',
                                  'Are you sure you want to cancel this session?'
                                )}
                                content={t(
                                  'myRemoteSessions.confirmItCanTakeTime',
                                  'It can take up to a minute for a session to start.'
                                )}
                                trigger={
                                  <Button
                                    basic
                                    content="Cancel Session"
                                    icon="ban"
                                  />
                                }
                                button={t(
                                  'myRemoteSessions.cancelSessionButton',
                                  'Cancel Session'
                                )}
                                onConfirm={async () => {
                                  await request({
                                    method: 'POST',
                                    path: `/1/remote-sessions/mine/${item.id}/cancel`,
                                    body: {},
                                  });
                                  reload();
                                }}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </>
          )}
        </SearchProvider>
      </Container>
    </AppWrapper>
  );
}
