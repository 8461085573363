import React from 'react';
import { Card, Grid, GridColumn, GridRow, Header } from 'semantic';

export default class ResultCard extends React.Component {
  render() {
    const result = this.props.result;
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>NODE: {result.hostname}</Card.Header>
          <Card.Description>
            <Grid columns={3}>
              <GridRow>
                <GridColumn>
                  <Header sub>Duration</Header>
                  {(new Date(result.endTime) - new Date(result.startTime)) /
                    1000}
                  s
                </GridColumn>
                <GridColumn>
                  <Header sub>Start</Header>{' '}
                  {new Date(result.startTime).toString()}
                </GridColumn>
                <GridColumn>
                  <Header sub>End</Header> {new Date(result.endTime).toString()}
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <Header sub>Messages received</Header>
                  {result.messagesSent}/{result.messagesReceived}
                </GridColumn>
                <GridColumn>
                  <Header sub>Client errors</Header>
                  {result.clientErrorsReceived}
                </GridColumn>
                <GridColumn>
                  <Header sub>Early disconnects</Header>
                  {result.earlyDisconnect}
                </GridColumn>
              </GridRow>
              <GridRow columns={7}>
                <GridColumn>
                  <Header sub>Under 100ms</Header>
                  {result.responseTimes['Under 100ms']}
                </GridColumn>
                <GridColumn>
                  <Header sub>Under 500ms</Header>
                  {result.responseTimes['Under 500ms']}
                </GridColumn>
                <GridColumn>
                  <Header sub>Under 1000ms</Header>
                  {result.responseTimes['Under 1000ms']}
                </GridColumn>
                <GridColumn>
                  <Header sub>Under 3000ms</Header>
                  {result.responseTimes['Under 3000ms']}
                </GridColumn>
                <GridColumn>
                  <Header sub>Under 5000ms</Header>
                  {result.responseTimes['Under 5000ms']}
                </GridColumn>
                <GridColumn>
                  <Header sub>Under 10 000ms</Header>
                  {result.responseTimes['Under 10000ms']}
                </GridColumn>
                <GridColumn>
                  <Header sub>over 10 000ms</Header>
                  {result.responseTimes['Over 10000ms']}
                </GridColumn>
              </GridRow>
            </Grid>
          </Card.Description>
        </Card.Content>
      </Card>
    );
  }
}
