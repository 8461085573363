import React from 'react';
import { Input, Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useValueCostSettings } from 'components/tariffs/EvseTariff/useValueCostSettings';
import { currencyToSymbol } from 'utils/formatting';

export default function SimpleCostSettings({
  currency,
  value,
  connectors = undefined,
  numConnectors = 1,
  onChange,
  disabled,
}) {
  const { t } = useTranslation();
  const costSettings = useValueCostSettings({
    value,
    connectors,
    numConnectors,
  });

  function setConnectorValue(connectorId, field, value) {
    costSettings.forEach((item) => {
      if (item.connectorId.toString() === connectorId.toString()) {
        item.connectorId = connectorId;
        item[field] = value;
      }
    });
    onChange(costSettings);
  }

  return (
    <>
      <Table definition celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>
              {t('common.pricePerKwh', 'Price per kWh')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('common.pricePerSession', 'Price per Session')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('common.pricePerHour', 'Price per Hour')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {costSettings.map(
            ({ connectorId, pricePerKwh, pricePerSession, pricePerHour }) => {
              return (
                <Table.Row key={connectorId}>
                  <Table.Cell>
                    {t(
                      'editEvseControllers.connectorLabel',
                      'Connector {{connectorId}}',
                      { connectorId }
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      disabled={disabled}
                      key={connectorId}
                      style={{ marginBottom: '4px' }}
                      label={currencyToSymbol(currency)}
                      fluid
                      value={pricePerKwh}
                      onChange={(e, { value }) =>
                        setConnectorValue(connectorId, 'pricePerKwh', value)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      disabled={disabled}
                      key={connectorId}
                      style={{ marginBottom: '4px' }}
                      label={currencyToSymbol(currency)}
                      fluid
                      value={pricePerSession}
                      onChange={(e, { value }) =>
                        setConnectorValue(connectorId, 'pricePerSession', value)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      disabled={disabled}
                      key={connectorId}
                      style={{ marginBottom: '4px' }}
                      label={currencyToSymbol(currency)}
                      fluid
                      value={pricePerHour}
                      onChange={(e, { value }) =>
                        setConnectorValue(connectorId, 'pricePerHour', value)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              );
            }
          )}
        </Table.Body>
      </Table>
      <i style={{ float: 'left', marginTop: 6 }}>
        {t(
          'advancedTariff.pricePerHourCapMessage',
          '*Maximum time cost may be applied per session according to roaming contracts'
        )}
      </i>
      <br />
    </>
  );
}
