import { AccountProvider } from 'contexts/account';
import React, { useMemo } from 'react';
import OrganizationReimbursementLayout from './Reimbursement.layout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export default function OrganizationReimbursementContainer() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);

  //TODO: Add error boundary layer
  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider>
        <OrganizationReimbursementLayout />
      </AccountProvider>
    </QueryClientProvider>
  );
}
