import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('financeExports.statistics', 'Statistics')}
          to={`/finance/exports/statistics`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('financeExports.exportJobs', 'Export Jobs')}
          to={`/finance/exports/export-jobs`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
};
