import React from 'react';
import { Message, Icon } from 'semantic';
import { TFunction } from 'react-i18next';

interface PersonalBillingInfoBoxParams {
  isBillingUpdateRequired: boolean;
  sideBarMenuName: string;
  t: TFunction<'translation', undefined>;
}

export default function PersonalBillingInfoBox({
  isBillingUpdateRequired,
  sideBarMenuName,
  t,
}: PersonalBillingInfoBoxParams) {
  return (
    <>
      {isBillingUpdateRequired && (
        <Message info>
          <h4>
            <Icon name="circle-exclamation" />{' '}
            {t(
              'settings.personal.billing.obsoleted.header.withoutAccountBilling',
              'Action required: Set your {{accountType}} Billing information',
              {
                accountType: sideBarMenuName,
              }
            )}
          </h4>
          <p>
            {t(
              'settings.personal.billing.obsoleted.description.part1.base',
              'You have provided personal billing information in the past. Moving forward we will only use the billing information provided in the'
            )}
            <strong>
              {' '}
              {sideBarMenuName}{' '}
              {t(
                'settings.personal.billing.obsoleted.description.setting',
                'settings'
              )}
              .{' '}
            </strong>
            {t(
              'settings.personal.billing.obsoleted.description.part2.withoutAccountBilling',
              'Please set your'
            )}
            <strong> {sideBarMenuName} </strong>
            {t(
              'settings.personal.billing.obsoleted.description.part3.withoutAccountBilling',
              'billing information, to be able to remove your personal billing information below.'
            )}
          </p>
        </Message>
      )}

      <Message info>
        <h4>
          <Icon name="circle-exclamation" />{' '}
          {t(
            'settings.personal.billing.obsoleted.header.base',
            'Action required: Remove your personal billing information'
          )}
        </h4>
        {isBillingUpdateRequired ? (
          <p>
            {t(
              'settings.personal.billing.obsoleted.description.part1.withoutAccountBilling',
              'You need to set your {{accountType}} Billing information to be able to remove your personal billing information below.',
              {
                accountType: sideBarMenuName,
              }
            )}
          </p>
        ) : (
          <p>
            {t(
              'settings.personal.billing.obsoleted.description.part1.base',
              'You have provided personal billing information in the past. Moving forward we will only use the billing information provided in the'
            )}
            <strong>
              {' '}
              {sideBarMenuName}{' '}
              {t(
                'settings.personal.billing.obsoleted.description.setting',
                'settings'
              )}
              .{' '}
            </strong>
            {t(
              'settings.personal.billing.obsoleted.description.part2.withAccountBilling',
              'Please keep your'
            )}
            <strong> {sideBarMenuName} </strong>
            {t(
              'settings.personal.billing.obsoleted.description.part3.withAccountBilling',
              'billing information up to date, and remove your personal billing information below.'
            )}
          </p>
        )}
      </Message>
    </>
  );
}
