import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Table, Loader, Segment, Message } from 'semantic';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { useTranslation } from 'react-i18next';

import { ConnectivityStatus } from 'components/ConnectivityStatus';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default function TableView({
  status,
  data: items,
  meta = {},
  page,
  limit,
  setPage,
}) {
  const { t } = useTranslation();

  return (
    <div className="list">
      {status.success && !items.length && (
        <Message>
          {t(
            'locationEvseControllers.noControllersYet',
            'No EVSE Controllers created yet'
          )}
        </Message>
      )}

      {status.success && items.length > 0 && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('locationEvseControllers.columnSerialNo', 'Serial No')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationEvseControllers.columnOCPPId', 'OCPP Identity')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationEvseControllers.columnEVSEId', 'EVSE ID')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationEvseControllers.columnLocation', 'Location')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('myLocations.connectivityStatus', 'Connectivity Status')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('locationEvseControllers.columnPlan', 'Plan')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell>
                  {currentUserCanAccessProviderEndpoint(
                    'evseControllers',
                    'read'
                  ) ? (
                    <Link to={`/charging-stations/${item.id}`}>
                      {item.serialNumber ||
                        t(
                          'locationEvseControllers.noSerialNumber',
                          'No Serial Number'
                        )}
                    </Link>
                  ) : (
                    item.serialNumber ||
                    t(
                      'locationEvseControllers.noSerialNumber',
                      'No Serial Number'
                    )
                  )}
                </Table.Cell>
                <Table.Cell>{item.ocppIdentity}</Table.Cell>
                <Table.Cell>{item.normalizedEvseId || item.evseId}</Table.Cell>
                <Table.Cell>{item.location && item.location.name}</Table.Cell>
                <Table.Cell textAlign="center">
                  <ConnectivityStatus item={item} />
                </Table.Cell>
                <Table.Cell>
                  {item.billingPlan && item.billingPlan.details.en.name}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {status.success && meta.total > limit && (
        <Center>
          <Pagination
            limit={limit}
            page={page}
            total={meta.total}
            onPageChange={(e, { activePage }) =>
              setPage(activePage).then(() => window.scrollTo(0, 0))
            }
          />
        </Center>
      )}
      {status.request && (
        <Segment style={{ height: '100px' }}>
          <Loader active>{t('loading.loading')}</Loader>
        </Segment>
      )}
      {status.error && <Message error content={status.error.message} />}
    </div>
  );
}
