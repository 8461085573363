import React from 'react';

import { useUser } from 'contexts/user';

export default () => {
  //@ts-ignore
  const { provider } = useUser();

  React.useEffect(() => {
    document.location = 'https://forms.gle/pkHFccMPCGqUFxtGA';
  }, []);

  return <div />;
};
