import { Button, Dropdown } from '../../../semantic';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { sub, format } from 'date-fns';

export const intervalOptions = [
  {
    key: 1,
    text: 'Last week',
    value: format(
      sub(new Date(), {
        weeks: 1,
      }),
      'yyyy-MM-dd'
    ),
  },
  {
    key: 2,
    text: 'Last 30 days',
    value: format(
      sub(new Date(), {
        days: 30,
      }),
      'yyyy-MM-dd'
    ),
  },
  {
    key: 2,
    text: 'Last 6 months',
    value: format(
      sub(new Date(), {
        months: 6,
      }),
      'yyyy-MM-dd'
    ),
  },
  {
    key: 3,
    text: 'Last year',
    value: format(
      sub(new Date(), {
        years: 1,
      }),
      'yyyy-MM-dd'
    ),
  },
  {
    key: 4,
    text: 'All time',
    value: 'All',
  },
];

export default function Filters({
  loading,
  onFilterChange,
  onRefresh,
  currentFilter,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Dropdown
        selection
        value={currentFilter}
        options={intervalOptions}
        label={t('evseIssuesAggregate.from', 'From')}
        onChange={(_, { value }) => onFilterChange(value)}
      />
      <Button
        basic
        icon="arrow-rotate-right"
        loading={loading}
        content={t('common.refreshData', 'Refresh data')}
        onClick={() => onRefresh()}
      />
    </div>
  );
}
