import React from 'react';
import { Input } from 'semantic';

export default function PhoneNumber({ name, value, onChange, ...rest }) {
  return (
    <Input
      style={{ flex: 1 }}
      value={value}
      name={name}
      type="text"
      fluid
      onChange={onChange}
      {...rest}
    />
  );
}
