import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash-es';

export const useDebounce = (fnToDebounce, durationInMs = 0) => {
  if (isNaN(durationInMs)) {
    throw new TypeError('durationInMs for debounce should be a number');
  }

  if (fnToDebounce == null) {
    throw new TypeError('fnToDebounce cannot be null');
  }

  if (typeof fnToDebounce !== 'function') {
    throw new TypeError('fnToDebounce should be a function');
  }

  return useCallback(debounce(fnToDebounce, durationInMs), [
    fnToDebounce,
    durationInMs,
  ]);
};

export function useDebounceValue(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export default useDebounce;
