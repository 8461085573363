import React, { useEffect, useState } from 'react';

import { request } from 'utils/api';

import PageLoader from 'components/PageLoader';
import InvoiceDetailTable from './Table';

export default function Invoices({ match }) {
  const invoiceId = match.params.id;
  const [invoice, setInvoice] = React.useState();
  const [loading, setLoading] = useState(true);

  function fetchInvoice() {
    return request({
      method: 'GET',
      path: `/1/invoices/${invoiceId}`,
    })
      .then(({ data }) => setInvoice(data))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchInvoice();
  }, [invoiceId]);

  return (
    <>
      {invoice && <InvoiceDetailTable invoice={invoice} />}
      {!invoice && loading && <PageLoader />}
      {!invoice && !loading && <div>Invoice not found.</div>}
    </>
  );
}
