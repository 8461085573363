import React, { useEffect, useState } from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import PublicLocations from 'components/form-fields/PublicLocations';

import { useTranslation } from 'react-i18next';

const defaultInitialValues = {};

export default function StartRemoteSession({
  evseId,
  connectorId,
  initialValues = defaultInitialValues,
  onSubmit,
  trigger,
  openOnInit = false,
}) {
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(openOnInit);
  const [formValues, setFormValues] = useState({ ...initialValues });

  useEffect(() => {
    setSubmitted(false);
    setFormValues({ ...initialValues });
  }, [initialValues]);

  const handleSubmit = () => {
    setSubmitted(true);
    setError(null);
    const { location } = formValues;
    if (!location) {
      setError(new Error('No location selected'));
      return;
    }
    const [locationId, evseId, evseControllerId, connectorId] =
      location.split(':');
    request({
      method: 'POST',
      path: '/1/remote-sessions/mine',
      body: {
        locationId,
        evseId,
        evseControllerId,
        connectorId,
      },
    })
      .then(() => {
        setFormValues(initialValues);
        setSubmitted(false);
        setError(null);
        setOpen(false);
        onSubmit?.();
      })
      .catch((error) => {
        setSubmitted(false);
        setError(error);
      });
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        setFormValues(initialValues);
        setSubmitted(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      pe>
      <Modal.Header>
        {t('startRemoteSessionModal.header', 'Start Remote Session')}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'startRemoteSessionModal.introText',
            'A remote session allows you to initiate a charge session without needing an RFID card.'
          )}
        </p>
        <p>
          {t(
            'startRemoteSessionModal.step1',
            'Step 1: Make sure your cable is plugged in to both your vehicle and the charge station connector.'
          )}
        </p>
        <p>
          {t(
            'startRemoteSessionModal.step2',
            'Step 2: Find the correct ID number and connector before starting a charge session.'
          )}
        </p>
        <Form onSubmit={() => handleSubmit()}>
          {error && <Message error content={error.message} />}
          <Form.Field>
            <label>
              {t(
                'startRemoteSessionModal.findLocation',
                'Find Public Location'
              )}
            </label>
            <PublicLocations
              evseId={evseId}
              connectorId={connectorId}
              value={formValues.location}
              onChange={(e, { value }) => setField('location', value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={submitted}
          primary
          content={t('startRemoteSessionModal.start', 'Start Session')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}
