import React from 'react';
import ConfigureConnectors from 'components/ConfigureConnectors';
import { Button, Divider, Modal } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

interface EvseController {
  id: string;
}

interface Props {
  evseController: EvseController;
  nextStep: () => void;
  totalSteps: number;
}

function sendResetCommand(evseControllerId: string) {
  request({
    method: 'POST',
    path: `/1/evse-controllers/${evseControllerId}/action`,
    body: {
      method: 'Reset',
      evseControllerId,
    },
  }).catch((error) => {
    console.error(error);
  });
}

export default function ConfigureConnectorsStep(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  function save() {
    sendResetCommand(props.evseController.id);
    props.nextStep();
  }

  return (
    <React.Fragment>
      <Modal.Header>
        {`${t('setupEvse.inviteCustomer', 'Setup Charge Station')} (2/${
          props.totalSteps
        })`}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'setupEvse.connectorConfigurationDescription',
            `Step 2: Please configure the correct connector information for this
            charge station's connectors.`
          )}
        </p>
        <ConfigureConnectors
          hideEvseId
          formId="setup-evse-connectors"
          evseController={props.evseController}
          onLoading={(loading: boolean) => setLoading(loading)}
          onSave={save}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          loading={loading}
          as="button"
          content={t(
            'setupEvse.saveConnectorConfiguration',
            'Save Connector Configuration'
          )}
          form="setup-evse-connectors"
        />
      </Modal.Actions>
    </React.Fragment>
  );
}
