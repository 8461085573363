import React, { createContext, useEffect, useMemo, useState } from 'react';
import { request } from 'utils/api';
import { addDays, format } from 'date-fns';
import {
  EnergyTariff,
  MarketEnergyTariffDay,
  formatEnergyTariff,
  FormattedEnergyTariff,
} from 'components/MarketEnergyTariffs/model';

type EnergyMarketPriceApiResponse = {
  tariffs: MarketEnergyTariffDay[];
  activeTariff?: EnergyTariff;
  averagePricePerKwh?: number;
  minPricePerKwh?: number;
  maxPricePerKwh?: number;
  from?: string;
  to?: string;
};

export type EnergyMarketPriceContextType = EnergyMarketPriceApiResponse & {
  energyDeliveryArea: string;
  currentTariffFormatted: FormattedEnergyTariff | null;
  loading: boolean;
};

const EnergyMarketPriceContext = createContext<EnergyMarketPriceContextType>({
  energyDeliveryArea: '',
  tariffs: [],
  currentTariffFormatted: null,
  loading: false,
});

export type EnergyMarketPriceProviderProps = {
  energyDeliveryArea: string;
  children: any;
};

export function EnergyMarketTariffProvider({
  energyDeliveryArea,
  children,
}: EnergyMarketPriceProviderProps) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<EnergyMarketPriceApiResponse>({
    tariffs: [],
  });

  useEffect(() => {
    if (!energyDeliveryArea) {
      setLoading(false);
      return;
    }
    (async () => {
      setLoading(true);
      const today = format(Date.now(), 'yyyy-MM-dd');
      const tomorrow = format(addDays(Date.now(), 1), 'yyyy-MM-dd');
      const { data } = await request({
        method: 'GET',
        path: `/1/epex-energy-tariffs/${energyDeliveryArea}`,
        params: {
          from: today,
          to: tomorrow,
        },
      });
      setData(data);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  }, [energyDeliveryArea]);

  const context = useMemo(
    () => ({
      ...data,
      energyDeliveryArea,
      currentTariffFormatted: data?.activeTariff
        ? formatEnergyTariff(data.activeTariff)
        : null,
      data,
      loading,
    }),
    [energyDeliveryArea, data, loading]
  );

  return (
    <EnergyMarketPriceContext.Provider value={context}>
      {children}
    </EnergyMarketPriceContext.Provider>
  );
}

export function useEnergyMarketPriceContext(): EnergyMarketPriceContextType {
  return React.useContext(EnergyMarketPriceContext);
}
