import { normalizeCostSettings } from 'utils/form';
import { request } from 'utils/api';
import { EvseController } from 'components/modals/EditEvseController/types';
import { useSanitizeCostSettingsFeatures } from 'components/tariffs/features';
import { useState } from 'react';

export default function useSaveEvseController(
  original: EvseController,
  evseController: Partial<EvseController>,
  currency: string,
  onError?: (error) => void,
  close?: () => void,
  onClose?: () => void
) {
  const [loading, setLoading] = useState(false);
  const sanitizeCostSettingsFeatures = useSanitizeCostSettingsFeatures();
  const handleSubmit = async () => {
    setLoading(true);

    try {
      const costSettings = normalizeCostSettings(evseController.costSettings);
      sanitizeCostSettingsFeatures(costSettings, currency);
      const body = {
        ...evseController,
        costSettings,
        pairedChargeCard: undefined,
      };
      const isUpdate = !!original.id;
      const method = isUpdate ? 'PATCH' : 'POST';
      const path = isUpdate
        ? `/1/evse-controllers/${original.id}`
        : '/1/evse-controllers';

      await request({ method, path, body });
      close?.();
      onClose?.();
    } catch (error) {
      onError?.(error);
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
}
