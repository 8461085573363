import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import { Divider, Message } from 'semantic';
import { currentUserCanAccess } from 'utils/roles';
import AccountBillingForm from './BillingAndPlans.form';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';

export default function AccountBilling() {
  const { t } = useTranslation();

  const cannotReadBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'read'
  );

  const cannotEditBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'write'
  );

  if (cannotReadBillingInformation) {
    return (
      <SettingsShell>
        <FormHeader
          size="large"
          disabled={cannotEditBillingInformation}
          pb={5}
          pt={5}
          text={t(
            'settings.organization.billing.title',
            'Organization Billing'
          )}
        />
        <Divider />
        <Message warning>
          {t(
            'access.cannotViewBillingInformation',
            'You do not have sufficient permissions to view billing information.'
          )}
        </Message>
      </SettingsShell>
    );
  }

  return (
    <SettingsShell>
      <AccountBillingForm />
    </SettingsShell>
  );
}
