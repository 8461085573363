import React, { useContext } from 'react';
const Context = React.createContext();

export default Context;

export function withSearchProvider(Component) {
  return function WrapperComponent(props) {
    return (
      <Context.Consumer>
        {(state) => <Component {...props} context={state} />}
      </Context.Consumer>
    );
  };
}

/**
 * A hook that provides access to the search context.
 *
 * @template T The type of items in the search context.
 * @returns {SearchContextProps<T>} The search context.
 */
export function useSearchContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }

  return context;
}

/**
 * A hook that provides access to the filter context.
 * I added this one (same as code in useSearchContext),
 * so we can have a type attached to the filter different from Search.
 *
 * And we get to decouple this from SearchContext later on in case we need/want to.
 *
 * @returns {FilterContextProps} The search context.
 */
export function useFilterContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useFilterContext must be used within a SearchProvider');
  }

  return context;
}
