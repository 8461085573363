import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from '@tanstack/react-query';
import { BillingInfo } from 'components/BillingInfo/types';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { UserJourney } from 'screens/Auth/SignupV2/journey';
import { ValidationErrorResponse } from 'types/api';
import { request } from 'utils/api';
import { PersonalDetails } from 'screens/Auth/SignupV2/steps/PersonalDetails';
import { AccountDetails } from 'screens/Auth/SignupV2/steps/AccountDetails';
import { RequestCardFormValues } from 'components/modals/RequestCard/v2/formData';
import { CardRequestError } from 'components/modals/RequestCard/v2/errors';
import { Account } from 'types/account';
import { submitCardRequest } from 'components/modals/RequestCard/v2/api';
import { trackCustomBehavioralEvent } from 'utils/hubspot';

export type SignupFormData = {
  personalDetails: PersonalDetails;
  accountDetails: AccountDetails;
  billingInfo?: BillingInfo;
  userId?: string;
  accountId?: string;
  token?: string;
};

type CreateAccountBillingPaymentInfoPayload = {
  paymentMethod?: 'autopay' | 'manual';
  accountHolderName?: string;
  ibanNumber?: string;
  bicNumber?: string;
  sepaAccepted?: boolean;
};
type CreateAccountBillingInfoPayload =
  CreateAccountBillingPaymentInfoPayload & {
    email?: string;
    address?: string;
    addressLine2?: string;
    postalCode?: string;
    city?: string;
    vatNo?: string;
    hasNoVatNo?: boolean;
    chamberOfCommerceNo?: string;
  };
type CreateAccountPayload = {
  email: string;
  password: string;
  contact: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    phoneCountryCode: string;
  };
  newsletter: boolean;
  acceptedTerms: boolean;
  acceptedPrivacy: boolean;
  countryCode: string;
  defaultLangCode: string;
  timeZone: string;
  accountType: 'individual' | 'organization';
  organization?: {
    organizationName: string;
    jobTitle?: string;
    websiteUrl?: string;
    organizationSize?: string;
  };
  referral?: string;
  journey?: 'cpo' | 'msp' | 'remote-sessions' | 'fst-invite-customer';
  platform?: 'web';
  billingPlanId?: string;
  evseId?: string;
  billing?: CreateAccountBillingInfoPayload;
};

const omitEmptyString = (val: string) => {
  if (val === '') {
    return undefined;
  }
  return val;
};

const transformPersonalDetails = (personalDetails: PersonalDetails, i18n) => ({
  email: personalDetails.email,
  password: personalDetails.password,
  contact: {
    firstName: personalDetails.firstName,
    lastName: personalDetails.lastName,
    email: personalDetails.email,
    phoneNo: personalDetails.phoneNo,
    phoneCountryCode: personalDetails.phoneCountryCode,
  },
  newsletter: personalDetails.newsletter,
  acceptedTerms: personalDetails.acceptedTerms,
  acceptedPrivacy: personalDetails.acceptedPrivacy,
  countryCode: personalDetails.countryCode,
  defaultLangCode: i18n.language,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

const transformBillingInfo = (
  billingInfo: Partial<BillingInfo>
): CreateAccountBillingInfoPayload => ({
  address: billingInfo.street,
  addressLine2: billingInfo.number,
  postalCode: billingInfo.postalCode,
  city: billingInfo.city,
  vatNo: billingInfo.vatNotApplicable ? '' : billingInfo.vatNumber,
  hasNoVatNo: billingInfo.vatNotApplicable,
  chamberOfCommerceNo: omitEmptyString(billingInfo.chamberOfCommerceNumber),
  paymentMethod: billingInfo.paymentMethod,
  ...(billingInfo.paymentMethod === 'autopay' && {
    accountHolderName: billingInfo.accountHolderName,
    ibanNo: billingInfo.ibanNumber,
    bicNo: billingInfo.bicNumber,
    sepaAccepted: billingInfo.sepaAccepted,
  }),
});

export function useInitialSignupState() {
  return useQuery(
    ['/1/signup/form'],
    async () => {
      const { data } = await request({
        method: 'GET',
        path: '/1/signup/form',
      }).catch(() => ({ data: {} as Partial<CreateAccountPayload> }));
      return { formState: data };
    },
    {
      staleTime: Infinity,
      networkMode: 'always',
      retry: false,
    }
  );
}

export function useCreateAccount<T extends SignupFormData = SignupFormData>(
  options: UseMutationOptions<T, ValidationErrorResponse, T> & {
    userJourney: UserJourney;
  }
) {
  const { i18n } = useTranslation();

  return useMutation<T, ValidationErrorResponse, T>(
    ['/1/signup'],
    async (values) => {
      let organization;
      if (values.accountDetails.accountType === 'organization') {
        organization = {
          organizationName: values.accountDetails.organizationName,
          jobTitle: omitEmptyString(values.accountDetails.jobTitle),
          websiteUrl: omitEmptyString(values.accountDetails.websiteUrl),
          organizationSize: omitEmptyString(
            values.accountDetails.organizationSize
          ),
        };
      }
      const payload: CreateAccountPayload = {
        ...transformPersonalDetails(values.personalDetails, i18n),
        organization,
        accountType: values.accountDetails.accountType,
        billing: values.billingInfo
          ? {
              ...transformBillingInfo(values.billingInfo),
              email: values.personalDetails.email,
            }
          : undefined,
        ...options.userJourney,
      };
      const response = await request({
        path: '/1/signup',
        method: 'POST',
        body: payload,
      });

      const dataLayer = (window as any).dataLayer;
      if (dataLayer) {
        dataLayer.push({
          event: 'account_created',
          referral: options.userJourney?.referral,
          billingPlanId: options.userJourney?.billingPlanId,
          journey: options.userJourney?.journey,
          accountBillingCountry: values.billingInfo?.countryCode?.toUpperCase(),
        });
      }

      trackCustomBehavioralEvent(
        `${options.userJourney?.journey}_account_created`,
        {
          // give each property a value so we know what they mean
          referral: options.userJourney?.referral,
        }
      );

      return {
        ...values,
        userId: response?.data?.userId,
        accountId: response?.data?.accountId,
        token: response?.data?.token,
      };
    },
    { ...options, networkMode: 'always' }
  );
}

export function useValidatePersonalDetails(
  options: UseMutationOptions<
    PersonalDetails,
    ValidationErrorResponse,
    PersonalDetails
  >
) {
  const { i18n } = useTranslation();
  return useMutation<PersonalDetails, ValidationErrorResponse, PersonalDetails>(
    ['/1/signup/validate', 'personal-details'],
    async (personalDetails) => {
      await request({
        path: '/1/signup/validate',
        method: 'POST',
        body: transformPersonalDetails(personalDetails, i18n),
      });
      return personalDetails;
    },
    { ...options, networkMode: 'always' }
  );
}

export function useValidateBillingInfo(
  options: UseMutationOptions<BillingInfo, ValidationErrorResponse, BillingInfo>
) {
  return useMutation<BillingInfo, ValidationErrorResponse, BillingInfo>(
    ['/1/signup/validate', 'billing-info'],
    async (billingInfo) => {
      await request({
        path: '/1/signup/validate',
        method: 'POST',
        body: {
          countryCode: billingInfo.countryCode,
          billing: transformBillingInfo(billingInfo),
        },
      });
      return billingInfo;
    },
    { ...options, networkMode: 'always' }
  );
}

type UseSubmitCardRequestMutationOptions = Omit<
  UseMutationOptions<unknown, CardRequestError, RequestCardFormValues>,
  'mutationFn'
> & {
  account: Account;
};

export function useSubmitCardRequest(
  options: UseSubmitCardRequestMutationOptions
) {
  const { account, ...mutationOptions } = options;
  return useMutation({
    mutationFn: async (values: RequestCardFormValues) => {
      if (!account) {
        throw new Error('No account in context!');
      }

      await submitCardRequest(account, values);
    },
    ...mutationOptions,
  });
}

export type LoginDetails = {
  email: string;
  password: string;
};

type LoginResponse = {
  token: string;
  mfaRequired: boolean;
};

type UseLoginOptions = {
  redirectTo?: string;
  mutationOptions?: Omit<
    UseMutationOptions<LoginResponse, unknown, LoginDetails>,
    'onSuccess'
  >;
  delay?: number;
};

export function useLogin(options: UseLoginOptions = {}) {
  const params = new URLSearchParams(window.location.search);
  const { setToken } = useUser() as { setToken: (token: string) => void };
  const history = useHistory();

  return useMutation<LoginResponse, unknown, LoginDetails>(
    async (values: { email: string; password: string }) => {
      if (options.delay > 0) {
        await new Promise((resolve) =>
          setTimeout(resolve, options.delay as number)
        );
      }
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/login',
        body: {
          email: values.email,
          password: values.password,
        },
      });
      return data;
    },
    {
      ...options.mutationOptions,
      onSuccess: (data) => {
        if (data.mfaRequired) {
          window.localStorage.setItem('mfa-auth', JSON.stringify(data));
          history.push(`/login/verification?${params.toString()}`);
          return;
        }
        setToken(data.token);
        history.push(options.redirectTo || '/');
      },
    }
  );
}
