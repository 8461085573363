import React from 'react';
import { Icon, Table, Loader, Message, Label, Form } from 'semantic';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import SparkChart from 'components/SparkChart';
import { formatDateTime, fromNow } from 'utils/date';
import { sub, format } from 'date-fns';

class EvseIssues extends React.Component {
  state = {
    issues: null,
    loading: true,
    from: null,
    maxCount: null,
    level: 'all',
    sortBy: 'count',
    intervalOptions: [],
    selectedProvider: 'all',
    providers: [],
    selectedAccount: 'all',
    accounts: [],
    selectedVendor: 'all',
    vendors: [],
  };

  componentDidMount() {
    const intervalOptions = this.getIntervalOptions();
    this.setState({
      intervalOptions,
    });
    this.setState(
      {
        // default to week
        from: intervalOptions[1].value,
      },
      () => {
        this.fetch();
      }
    );
  }

  async fetch() {
    const {
      from,
      level,
      sortBy,
      selectedProvider,
      selectedAccount,
      selectedVendor,
    } = this.state;
    this.setState({
      loading: true,
      data: null,
    });
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/evse-issues/aggregated',
        body: {
          from,
          groupSort: sortBy,
          ...(level !== 'all' ? { level } : {}),
          ...(selectedProvider !== 'all'
            ? { providerId: selectedProvider }
            : {}),
          ...(selectedAccount !== 'all' ? { accountId: selectedAccount } : {}),
          ...(selectedVendor !== 'all' ? { vendor: selectedVendor } : {}),
        },
      });

      this.setState({
        issues: data.issues,
        providers: data.providers,
        accounts: data.accounts,
        vendors: data.vendors,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  getIntervalOptions() {
    const now = new Date();

    return [
      {
        key: 0,
        text: 'Last day',
        value: format(
          sub(now, {
            days: 1,
          }),
          'yyyy-MM-dd'
        ),
      },
      {
        key: 1,
        text: 'Last 7 days',
        value: format(
          sub(now, {
            days: 7,
          }),
          'yyyy-MM-dd'
        ),
      },
      {
        key: 2,
        text: 'Last 30 days',
        value: format(
          sub(now, {
            days: 30,
          }),
          'yyyy-MM-dd'
        ),
      },
    ];
  }

  getLevelOptions() {
    return [
      {
        key: 0,
        text: 'All levels',
        value: 'all',
      },
      {
        key: 1,
        text: 'High',
        value: 'high',
      },
      {
        key: 2,
        text: 'Medium',
        value: 'medium',
      },
      {
        key: 3,
        text: 'Low',
        value: 'low',
      },
    ];
  }

  getSortOptions() {
    return [
      {
        key: 0,
        text: 'Occurrences',
        value: 'count',
      },
      {
        key: 1,
        text: 'Affected documents',
        value: 'docCount',
      },
    ];
  }

  getProviderOptions() {
    const { providers } = this.state;
    return [
      {
        key: 0,
        text: 'All providers',
        value: 'all',
      },
      ...providers.map(({ name, _id }, i) => ({
        key: i + 1,
        text: name,
        value: _id,
      })),
    ];
  }

  getAccountOptions() {
    const { accounts } = this.state;
    return [
      {
        key: 0,
        text: 'All accounts',
        value: 'all',
      },
      ...accounts.map(({ name, _id }, i) => ({
        key: i + 1,
        text: name,
        value: _id,
      })),
    ];
  }

  getVendorOptions() {
    const { vendors } = this.state;
    return [
      {
        key: 0,
        text: 'All vendors',
        value: 'all',
      },
      ...vendors.map((vendor, i) => ({
        key: i + 1,
        text: vendor,
        value: vendor,
      })),
    ];
  }

  render() {
    const {
      loading,
      error,
      issues,
      from,
      intervalOptions,
      level,
      selectedProvider,
      selectedAccount,
      selectedVendor,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>
          <Form
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '1em',
            }}>
            <Form.Select
              value={from}
              options={intervalOptions}
              label={t('evseIssuesAggregate.from', 'From')}
              onChange={(_, { value }) =>
                this.setState({ from: value }, () => this.fetch())
              }
            />
            <Form.Select
              value={level}
              options={this.getLevelOptions()}
              label={t('evseIssuesAggregate.level', 'Level')}
              onChange={(_, { value }) =>
                this.setState({ level: value }, () => this.fetch())
              }
            />
            <Form.Select
              value={selectedProvider}
              options={this.getProviderOptions()}
              label={t('evseIssuesAggregate.provider', 'Provider')}
              type="text"
              search
              onChange={(_, { value }) =>
                this.setState({ selectedProvider: value }, () => this.fetch())
              }
            />
            <Form.Select
              value={selectedAccount}
              options={this.getAccountOptions()}
              label={t('evseIssuesAggregate.account', 'Account')}
              type="text"
              search
              onChange={(_, { value }) =>
                this.setState({ selectedAccount: value }, () => this.fetch())
              }
            />
            <Form.Select
              value={selectedVendor}
              options={this.getVendorOptions()}
              label={t('evseIssuesAggregate.vendor', 'Vendor')}
              type="text"
              search
              onChange={(_, { value }) =>
                this.setState({ selectedVendor: value }, () => this.fetch())
              }
            />
          </Form>
        </div>
        {loading && <Loader active inline style={{ marginTop: '1em' }} />}
        {error && <Message error content={error.message} />}
        {issues && (
          <React.Fragment>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('evseIssuesAggregate.columnMessage', 'Message')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('evseIssuesAggregate.columnGraph', 'Graph')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('evseIssuesAggregate.columnTotal', 'Total')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('evseIssuesAggregate.columnProviders', 'Providers')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnLastSeen', 'Last seen')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnFirstSeen', 'First seen')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {issues.length ? (
                  issues.map((item) => {
                    return (
                      <Table.Row key={item._id}>
                        <Table.Cell>
                          <div>
                            <p
                              style={{
                                fontSize: '0.9em',
                                marginBottom: '.5em',
                              }}>
                              {item.message.replaceAll('","', '", "')}
                            </p>
                          </div>

                          <div>
                            {item.levels.map((level) => (
                              <Label size="small" key={level}>
                                {level}
                              </Label>
                            ))}
                            <Label size="small" key="lastSeen">
                              <Icon name="clock" />
                              {fromNow(item.lastOccurence)}
                              {` | `}
                              {fromNow(item.firstOccurence).replace(
                                'ago',
                                ''
                              )}{' '}
                              old
                            </Label>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <SparkChart timeSeries={item.timeSeries} />
                        </Table.Cell>
                        <Table.Cell text-align="center">
                          <Label circular>{item.count}</Label>
                        </Table.Cell>
                        <Table.Cell>
                          {item.providerNames.map((name) => (
                            <Label size="small" key={name}>
                              {name}
                            </Label>
                          ))}
                        </Table.Cell>
                        <Table.Cell>
                          <div style={{ fontSize: '0.9em' }}>
                            {formatDateTime(item.lastOccurence)}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <div style={{ fontSize: '0.9em' }}>
                            {formatDateTime(item.firstOccurence)}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={100}>
                      <span
                        style={{
                          width: '100%',
                          display: 'block',
                          textAlign: 'center',
                        }}>
                        {t('common.noItemsFound', 'No results found')}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(EvseIssues);
