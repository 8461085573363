import React, { CSSProperties } from 'react';
import { Form } from 'semantic';

import countries from 'utils/countries';

import { useField, FieldValidator } from 'formik';
import styles from './PhoneCountry.module.less';
import { FormDropdownProps } from 'semantic';

const options = countries
  .filter((c) => c.callingCode)
  .map(({ flag, callingCode, countryCode }) => ({
    key: countryCode,
    text: `${flag} ${countryCode} +${callingCode}`,
    value: countryCode,
  }));

interface Props extends FormDropdownProps {
  name: string;
  label?: string;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  required?: boolean;
  validate?: FieldValidator;
  disabled?: boolean;
  // Set this to true if you want the validation occur immediately, instead of after touched;
  // i.e touched = input has been focused, or form submitted
  validateImmediately?: boolean;
  // set this to true if you do not want to show the error label below the input if the field is not valid. Note that
  // the field will still be styled as invalid
  hideErrorLabel?: boolean;
  className?: string;
  placeholder?: string;
}

const PhoneCountry: React.FC<Props> = ({
  required,
  name,
  disabled,
  validate,
  validateImmediately,
  label,
  className,
  style,
  wrapperStyle,
  hideErrorLabel,
  placeholder,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  const hasTouched = validateImmediately ? true : meta.touched;

  const content = (
    <Form.Dropdown
      className={[styles.container, className].filter(Boolean).join(' ')}
      error={
        hasTouched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
      value={field.value}
      required={required}
      name={name}
      role="input"
      aria-label={name}
      label={label}
      disabled={disabled}
      selection
      style={style}
      search
      options={options}
      placeholder={placeholder}
      onChange={(e, { value }) => {
        helpers.setValue(value, true);
      }}
      {...rest}
    />
  );

  if (!wrapperStyle) {
    return content;
  }

  return <div style={wrapperStyle}>{content}</div>;
};

export default PhoneCountry;
