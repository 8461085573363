import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { submitBillingInfo } from 'components/BillingInfo/api';
import { useAccount } from 'contexts/account';
import { AccountBillingError } from './errors';
import { AccountBillingValues } from './formData';
import { ValidationErrorResponse } from '../../../../../types/api';

export function useSubmitAccountBilling(
  options: Omit<
    UseMutationOptions<unknown, AccountBillingError, AccountBillingValues>,
    'mutationFn'
  >
) {
  const { account, isBillingUpdateRequired } = useAccount();
  return useMutation({
    mutationFn: async (values: AccountBillingValues) => {
      if (!account) {
        throw new Error('No account in context!');
      }

      if (isBillingUpdateRequired) {
        try {
          await submitBillingInfo(account, values.billing);
        } catch (e) {
          throw AccountBillingError.Billing(e as ValidationErrorResponse);
        }
      }
    },
    ...options,
  });
}
