import React, { useState } from 'react';
import { Form, Message, Modal, Button } from 'semantic';
import PermissionsSelector from 'components/form-fields/PermissionsSelector';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import modal from 'helpers/modal';
import { useUser } from 'contexts/user';
import { isSuperAdmin } from 'utils/roles';
import SearchDropdown from 'components/form-fields/SearchDropdown';

const EditRole = ({
  role = { context: 'account', disabled: false },
  close,
  onSave,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [editedRole, setEditedRole] = useState(role);
  const { t } = useTranslation();
  const { user } = useUser();

  const canSeeGlobalRoles = isSuperAdmin(user);
  const canSeeSystemRoles = isSuperAdmin(user);

  const onSubmit = async () => {
    setLoading(true);

    try {
      if (role?.id) {
        await request({
          method: 'PATCH',
          path: `/1/roles/${editedRole.id}`,
          body: editedRole,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/roles',
          body: { ...editedRole },
        });
      }

      close();
      onSave();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setEditedRole({
      ...editedRole,
      [name]: value,
    });
  };

  const getContentOptions = () => {
    const defaultOptions = [
      {
        text: t('editRole.optionAccount', 'Account'),
        value: 'account',
      },
      {
        text: t('editRole.optionProvider', 'Provider'),
        value: 'provider',
      },
    ];

    let contentOptions = [...defaultOptions];

    if (canSeeGlobalRoles) {
      const globalOption = {
        text: t('editRole.optionGlobal', 'Global'),
        value: 'global',
      };
      contentOptions = [...contentOptions, globalOption];
    }

    if (canSeeSystemRoles) {
      const systemOptions = {
        text: t('editRole.optionSystem', 'System'),
        value: 'system',
      };
      contentOptions = [...contentOptions, systemOptions];
    }

    return contentOptions;
  };

  const isUpdate = !!role.id;
  const canBeScopedToProvider =
    editedRole.context === 'provider' || editedRole.context === 'account';

  return (
    <>
      <Modal.Header>
        {isUpdate
          ? t('editRole.titleEdit', 'Edit Role {{name}}', { name: role.name })
          : t('editRole.titleNew', 'New Role')}
      </Modal.Header>
      <Modal.Content>
        <Form id="edit-provider-form" error={Boolean(error)}>
          {error && <Message error content={error.message} />}
          <Form.Input
            value={editedRole.name}
            name="name"
            label={t('editRole.name', 'Name')}
            type="text"
            required
            onChange={(e, { name, value }) => {
              setField(name, value);
            }}
          />
          {!isUpdate && (
            <Form.Select
              value={editedRole.context}
              options={getContentOptions()}
              name="context"
              label={t('editRole.context', 'Context')}
              required
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          )}

          {canBeScopedToProvider && (
            <Form.Field>
              <label>{t('editRole.providerId', 'Provider')}</label>
              <SearchDropdown
                value={editedRole.providerId}
                objectMode={false}
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/providers/search',
                    method: 'POST',
                    body,
                  });
                }}
                onChange={(e, { name, value }) => {
                  setField('providerId', value);
                }}
              />
            </Form.Field>
          )}

          <h4>{t('editRole.permissions', 'Permissions')}</h4>
          <PermissionsSelector
            context={editedRole.context}
            value={editedRole.permissions || {}}
            onChange={(permissions) => setField('permissions', permissions)}
          />

          <Form.Checkbox
            label={t('editRole.isDisabled', 'Is Disabled?')}
            name="disabled"
            checked={editedRole.disabled || false}
            onChange={(e, { name, checked }) => setField(name, checked)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          form="edit-role-form"
          loading={loading}
          primary
          content={isUpdate ? t('button.update') : t('button.create')}
          onClick={() => {
            onSubmit();
          }}
        />
      </Modal.Actions>
    </>
  );
};

export default modal(EditRole);
