import React, { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { Loader, Message, Modal } from 'semantic';
import GrowthChart from './GrowthChart';
import styled from 'styled-components';
import Filters, { intervalOptions } from './Filters';

export const StyledLoader = styled(Loader)`
  &::before {
    border-color: rgba(0, 0, 0, 0.1);
  }
  &::after {
    border-color: #767676 transparent transparent !important;
  }
`;

export default function PartyModal(props) {
  const { trigger, partyId } = props;
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(intervalOptions[2].value);

  const fetch = () => {
    setLoading(true);
    request({
      method: 'POST',
      path: `/1/external-tokens/party-breakdown/${partyId}/time-series`,
      params: {
        ...(from !== 'All' ? { from } : {}),
      },
    })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetch();
    }
  }, [from, open]);

  return (
    <Modal
      closeIcon
      closeOnDimmerClick
      trigger={trigger}
      onOpen={() => setOpen(true)}
      size="fullscreen">
      <Modal.Header>Valid tokens - {partyId}</Modal.Header>
      <Modal.Content>
        {loading && !data && <StyledLoader inline="centered" active />}
        {!!error && <Message error content={error.message} />}
        {!!data && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}>
            <Filters
              loading={loading}
              onRefresh={() => !loading && fetch()}
              onFilterChange={(from) => setFrom(from)}
              currentFilter={from}
            />
            <GrowthChart timeSeries={data.timeSeries} />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}
