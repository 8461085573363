import React from 'react';
import { Header, Image, Divider } from 'semantic';
import { Link } from 'react-router-dom';
import DefaultLogo from 'assets/logo.svg';

import { useUser } from 'contexts/user';
import ThemedImage from './ThemeImage';

export default ({ title }) => {
  const { provider } = useUser();

  let imageSrc = DefaultLogo;
  if (provider.logoUrl) {
    imageSrc = provider.logoUrl;
  }

  return (
    <React.Fragment>
      <Divider hidden />
      <Link to="/">
        {provider?.logoUrl ? (
          <ThemedImage
            className="ui image"
            darkSrc={provider?.invertedLogoUrl}
            ligthSrc={provider?.logoUrl}
            alt="Logo"
            style={{ height: '80px', margin: '0 auto' }}
          />
        ) : (
          <Image
            src={DefaultLogo}
            alt="Logo"
            style={{ height: '80px', margin: '0 auto' }}
          />
        )}
      </Link>
      <Divider hidden />
      <Header as="h3" textAlign="center" style={{ textTransform: 'uppercase' }}>
        {title}
      </Header>
    </React.Fragment>
  );
};
