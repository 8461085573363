import React from 'react';

import { Message, Loader } from 'semantic';

import { request } from 'utils/api';
import modal from '../../helpers/modal';
import ConfigureChargingStationStep from 'screens/SetupEvse/WizardSteps/ConfigureChargingStation';
import ConfigureConnectorsStep from 'screens/SetupEvse/WizardSteps/ConfigureConnectors';
import InviteCustomerStep from 'screens/SetupEvse/WizardSteps/InviteCustomer';
import { useFeatures } from 'contexts/features';

interface EvseController {
  id: string;
  createdByMethod: string;
  accountId: string;
}

interface Props {
  evseController: EvseController;
  nextStep: (step: number) => void;
  onDone: () => void;
  onStart: () => void;
  close: () => void;
}

function SetupEvseWizard(props: Props) {
  const [loading, setLoading] = React.useState(false);

  const [step, setStep] = React.useState(1);
  const [error, setError] = React.useState<Error | null>(null);
  const [evseController, setEvseController] =
    React.useState<EvseController | null>(null);

  const { hasFeature } = useFeatures();
  const showInviteStep = hasFeature('installer_invite_flow');
  const totalSteps = showInviteStep && !props.evseController.accountId ? 3 : 2;

  React.useEffect(() => {
    linkEvseController();
  }, []);

  async function linkEvseController() {
    try {
      setLoading(true);
      setError(null);
      const { data } = await request({
        method: 'POST',
        path: '/1/evse-controllers/maintenance/link',
        body: {
          evseControllerId: props.evseController.id,
        },
      });
      setEvseController(data);
      setLoading(false);
    } catch (error) {
      setError(error as Error);
      setLoading(false);
    }
  }

  async function fetchEvseController() {
    try {
      setLoading(true);
      const { data } = await request({
        method: 'GET',
        path: `/1/evse-controllers/${evseController?.id}`,
      });
      setEvseController(data);
    } catch (error) {
      setError(error as Error);
    }
    setLoading(false);
  }

  async function nextStep() {
    if (step === 1) {
      fetchEvseController();
    }
    if (step === totalSteps) {
      props.onDone && props.onDone();
      props.close && props.close();
    }
    props.nextStep && props.nextStep(step + 1);
    setStep(step + 1);
  }

  function renderStep(step: number) {
    if (loading) return <Loader active />;
    if (error) {
      return <Message error content={error.message} />;
    }
    if (!evseController) {
      return (
        <Message
          error
          content="Could not link this EVSE to maintenance account, please contact support"
        />
      );
    }
    if (step === 1) {
      return (
        <ConfigureChargingStationStep
          onStart={() => {
            props.onStart();
          }}
          evseController={evseController}
          loading={loading}
          nextStep={nextStep}
          totalSteps={totalSteps}
        />
      );
    }
    if (step === 2) {
      return (
        <ConfigureConnectorsStep
          evseController={evseController}
          nextStep={nextStep}
          totalSteps={totalSteps}
        />
      );
    }
    if (step === 3 && showInviteStep) {
      return (
        <InviteCustomerStep
          evseControllerId={evseController.id}
          nextStep={nextStep}
          totalSteps={totalSteps}
        />
      );
    }
  }

  return <>{renderStep(step)}</>;
}

export default modal(SetupEvseWizard);
