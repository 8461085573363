import React, { useContext } from 'react';
import { TFunction } from 'react-i18next';
import { Search } from 'components';
import { Table } from 'semantic';

import ResourceLink from 'components/Link/ResourceLink';
import { LabelItem } from '.';
import EvseControllerLabelActions from './Actions';
import { utf8ToBase64 } from '../../../utils/url';

interface EvseControllerLabelTableProps {
  writeAccess: boolean;
  t: TFunction;
}

export default function EvseControllerLabelTable({
  writeAccess,
  t,
}: EvseControllerLabelTableProps) {
  const { items = [], loading, reload } = useContext(Search.Context);

  if (!items.length || loading) return null;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>
            {t('manageLabels.tabel.label', 'Label')}
          </Table.HeaderCell>
          <Table.HeaderCell width={1}>
            {t('manageLabels.tabel.usage', 'NO. OF EVSEs')}
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>
            {t('manageLabels.tabel.actions', 'Actions')}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item: LabelItem) => {
          const externalLinkRoute = `/charging-stations?labels=${utf8ToBase64(item?.label)}`;
          return (
            <Table.Row key={item.label}>
              <Table.Cell width={4}>{item.label}</Table.Cell>
              <Table.Cell width={4}>
                <ResourceLink
                  route={externalLinkRoute}
                  linkName={item.usage.toString()}
                  target="_blank"
                />
              </Table.Cell>
              <Table.Cell width={2} textAlign="center">
                <EvseControllerLabelActions
                  item={item}
                  writeAccess={writeAccess}
                  t={t}
                  onSave={() => {
                    reload();
                  }}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
