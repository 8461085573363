import { useQuery } from '@tanstack/react-query';
import { BillingPlan } from 'types/billingplan';
import { request } from 'utils/api';

type UseChargeCardBillingPlansProps = {
  currency?: string;
  billingPlanId?: string;
  onlyPrimary?: boolean;
  restrictBillingPlanId?: string;
};

export function useChargeCardBillingPlans({
  currency,
  billingPlanId,
  onlyPrimary,
  restrictBillingPlanId,
}: UseChargeCardBillingPlansProps = {}) {
  return useQuery<BillingPlan[], unknown, BillingPlan[]>({
    queryKey: [
      'charge-card-billing-plans',
      currency,
      billingPlanId,
      onlyPrimary,
      restrictBillingPlanId,
    ],
    queryFn: async () => {
      return request({
        method: 'POST',
        path: '/1/billing-plans/search',
        body: {
          providerContext: 'msp',
          currency: currency || undefined,
        },
      }).then(({ data }: { data: BillingPlan[] }) => {
        return data
          .filter((c) => {
            if (!c.isPublic) {
              return false;
            }
            if (onlyPrimary && !restrictBillingPlanId && !c.isPrimary) {
              return false;
            }
            if (restrictBillingPlanId) {
              return c.id === restrictBillingPlanId;
            }
            return true;
          })
          .sort((a, b) => b.priority - a.priority);
      });
    },
    staleTime: Infinity,
    networkMode: 'always',
  });
}
