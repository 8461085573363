import React from 'react';

import EditCredential from 'components/modals/EditCredential';
import { currentUserCanAccess } from 'utils/roles';
import { Divider, Button } from 'semantic';
import { Breadcrumbs, Search, ListHeader } from 'components';
import { withTranslation } from 'react-i18next';

import Table from './Table';
import { request } from 'utils/api';

class Credentials extends React.Component {
  onDataNeeded = (filters) => {
    return request({
      method: 'POST',
      path: '/1/credentials/search',
      body: filters,
    });
  };

  render() {
    const { t } = this.props;

    const writeAccess = currentUserCanAccess('credentials', 'write');

    return (
      <Search.Provider ref={this.searchRef} onDataNeeded={this.onDataNeeded}>
        {({ reload }) => {
          return (
            <>
              <Breadcrumbs active={t('credentials.title', 'API Access')} />

              <ListHeader title={t('credentials.title', 'API Access')}>
                <EditCredential
                  onSave={reload}
                  trigger={
                    <Button
                      primary
                      floated="right"
                      style={{ marginTop: '-5px' }}
                      content={t('credentials.newCredential', 'New Credential')}
                      icon="plus"
                      disabled={!writeAccess}
                    />
                  }
                />
              </ListHeader>

              <Divider hidden />

              <Search.Status
                noItems={t(
                  'credentials.noCredentialsYet',
                  'No credentials created yet'
                )}
              />
              <Table />
              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Search.Pagination />
              </div>
            </>
          );
        }}
      </Search.Provider>
    );
  }
}

export default withTranslation()(Credentials);
