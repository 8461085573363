import React, { useEffect, useMemo } from 'react';

import { NavLink } from 'react-router-dom';
import { Button, Container, Icon } from 'semantic';

import { useTranslation } from 'react-i18next';
import { Layout } from 'components/Layout';
import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import ProviderSelector from 'components/modals/ProviderSelector';
import {
  canAccess,
  canAccessProviderEndpoint,
  currentUserHasGlobalRoles,
  isSuperAdmin,
} from 'utils/roles';
import { mfaIsRequiredForUser } from 'utils/security';

import { buildMenu, menuDefinitions } from 'utils/navigation';

import DashboardNotice from './DashboardNotice';

import { useUser } from 'contexts/user';
import { useTheme } from 'contexts/theme';
import { useSandbox } from 'contexts/sandbox';
import { truncate } from '../utils/formatting';
import { getThemedField } from '../utils/theming';
import ThemedImage from './ThemeImage';

import SandboxToggle from './SandboxToggle';
import { useFeatures } from 'contexts/features';
import { usePlatformModules } from 'contexts/platformModules';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PlatformModule from 'components/PlatformModule';

// This is a global variable to keep track of the scroll position of the sidebar
// so that it can be restored when the sidebar is re-mounted.
const sidebarPosition = {
  top: 0,
  height: 0,
};

// Keep track of the scroll position of the sidebar
const handleSidebarScroll = (e) => {
  sidebarPosition.top = e.target.scrollTop;
  sidebarPosition.height = e.target.clientHeight;
};

// When the sidebar is mounted, restore the scroll position and capture the new height
const onSidebarRender = (sidebarDivElement) => {
  if (!sidebarDivElement) {
    return;
  }
  // restore the scroll position of the sidebar
  let scrollTop = sidebarPosition.top;
  // if the height of the sidebar has changed, adjust the scroll position
  if (
    sidebarPosition.height &&
    sidebarPosition.height !== sidebarDivElement.clientHeight
  ) {
    scrollTop += sidebarDivElement.clientHeight - sidebarPosition.height;
  }
  sidebarDivElement.scrollTop = scrollTop;
  sidebarPosition.height = sidebarDivElement.clientHeight;
};

export default function AppWrapper({
  fluid = false,
  children,
  reactQueryConfig = undefined,
}) {
  const { t } = useTranslation();

  const { user, provider, setProviderId, hasPlatformFeature } = useUser();
  const { hasPlatformModule } = usePlatformModules();
  const { hasFeature } = useFeatures();
  const { renderedTheme } = useTheme();
  const { sandboxMode } = useSandbox();

  const queryClient = useMemo(() => {
    return new QueryClient(reactQueryConfig);
  }, []);

  useEffect(() => {
    return () => queryClient.clear();
  }, []);

  const backgroundStyle = {};

  const isDark = renderedTheme === 'dark';

  const activeColor = getThemedField(provider, 'primaryColorHex', isDark);

  const backgroundColor = getThemedField(
    provider,
    'secondaryBackgroundColorHex',
    isDark
  );

  // we should always show the sandbox toggle within the sandbox
  // environment, otherwise we conditionally present the toggle in
  // prod based on feature flags and proper provider access
  const canSeeSandboxToggle =
    sandboxMode ||
    canAccessProviderEndpoint(user, provider.id, 'sandbox', 'write');

  const userHasProviderRolesForOtherProviders = user?.providerRoles?.some(
    (role) => role.providerId !== user.providerId
  );

  const canListProviders =
    canAccess(user, provider.id, user.accountId, 'providers', 'read') ||
    currentUserHasGlobalRoles() ||
    userHasProviderRolesForOtherProviders;

  const canSeeReorganizedSettings = hasFeature(
    'account_settings_reorganization'
  );

  if (backgroundColor) {
    backgroundStyle.background = backgroundColor;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Sidebar>
        <Sidebar.Menu style={backgroundStyle}>
          <Layout vertical style={{ height: '100%' }}>
            <Layout.Group>
              <NavLink
                to="/"
                style={{
                  display: 'block',
                  margin: '-5px 25px 10px 25px',
                  textAlign: 'center',
                }}>
                {provider?.logoUrl && (
                  <ThemedImage
                    className="logo"
                    darkSrc={provider?.invertedLogoUrl}
                    ligthSrc={provider?.logoUrl}
                    style={{ maxWidth: '100%', maxHeight: '46px' }}
                  />
                )}
              </NavLink>
            </Layout.Group>
            <Layout.Group
              grow
              overflow
              divRef={onSidebarRender}
              onScroll={handleSidebarScroll}>
              <Layout vertical style={{ minHeight: '100%', padding: '15px 0' }}>
                <Layout.Group grow>
                  {canListProviders && (
                    <Sidebar.Item>
                      <ProviderSelector
                        defaultValue={provider.id}
                        onSelect={(provider) => setProviderId(provider.id)}
                        trigger={
                          <div>
                            <Icon name="building" />
                            {provider
                              ? truncate(provider.name, 21)
                              : 'Select Provider'}
                            <Icon name="caret-down" className="right" />
                          </div>
                        }
                        size="tiny"
                      />
                    </Sidebar.Item>
                  )}
                  {menuDefinitions
                    .map((definition) => {
                      const title = definition.title(t);
                      const menu = buildMenu(
                        provider,
                        user,
                        definition.items.map((item) => {
                          if (typeof item.to === 'function') {
                            item.to = item.to(provider);
                          }
                          return item;
                        }),
                        t,
                        hasFeature,
                        hasPlatformFeature,
                        hasPlatformModule
                      );
                      return {
                        definition,
                        title,
                        menu,
                      };
                    })
                    .filter(({ definition, menu }) => {
                      if (definition.hideForSuperAdmin && isSuperAdmin(user)) {
                        return false;
                      }
                      return menu.length > 0;
                    })
                    .map(({ title, menu }) => {
                      return (
                        <React.Fragment key={title}>
                          <Sidebar.Header>{title}</Sidebar.Header>
                          <Layout.Group>
                            {menu.map((item) => (
                              <React.Fragment key={item.to}>
                                <Sidebar.Link
                                  activeColor={activeColor}
                                  key={item.to}
                                  to={item.to}
                                  content={item.content}
                                  id={item.id}
                                  isActive={item.isActive}
                                  exact>
                                  {item.icon && <Icon name={item.icon} />}
                                  {item.content}
                                </Sidebar.Link>
                                {item.subItems.length > 0 && (
                                  <Sidebar.Accordion active={item.to}>
                                    {item.subItems.map((subItem) => {
                                      return (
                                        <Sidebar.Link
                                          activeColor={activeColor}
                                          key={subItem.to}
                                          to={subItem.to}
                                          id={subItem.id}
                                          content={subItem.content}
                                          target={subItem.target}
                                          exact={subItem.exact}>
                                          {subItem.icon && (
                                            <Icon name={subItem.icon} />
                                          )}
                                          {subItem.content}
                                        </Sidebar.Link>
                                      );
                                    })}
                                  </Sidebar.Accordion>
                                )}
                              </React.Fragment>
                            ))}
                          </Layout.Group>
                        </React.Fragment>
                      );
                    })}
                </Layout.Group>
                <Layout.Group>
                  {canSeeSandboxToggle && (
                    <PlatformModule moduleName="sandbox-mode">
                      <>
                        <Sidebar.Divider />
                        <div style={{ paddingLeft: '1.5rem' }}>
                          <SandboxToggle />
                        </div>
                      </>
                    </PlatformModule>
                  )}
                  <Sidebar.Divider />
                  <Sidebar.Header>{user && user.name}</Sidebar.Header>
                  <Sidebar.Link
                    id="menu-settings"
                    to={
                      canSeeReorganizedSettings
                        ? '/settings/personal/profile'
                        : '/settings'
                    }
                    activeColor={activeColor}>
                    <Icon name="gear" />
                    {t('menu.settings', 'Settings')}
                  </Sidebar.Link>
                  <Sidebar.Link to="/logout" activeColor={activeColor}>
                    <Icon name="log-out" />
                    {t('menu.signOut', 'Sign Out')}
                  </Sidebar.Link>
                </Layout.Group>
              </Layout>
            </Layout.Group>
          </Layout>
        </Sidebar.Menu>
        <Sidebar.Content>
          <Layout vertical className="layout app-wrapper vertical">
            {mfaIsRequiredForUser(user) && !user.mfaMethod && (
              <DashboardNotice>
                <Container>
                  {t(
                    'mfaBanner.content',
                    'Please enable Multi-Factor Authentication to improve the security of your administrative account.'
                  )}
                  <Button
                    as={'a'}
                    style={{ display: 'inline-block', marginLeft: '1em' }}
                    size="tiny"
                    inverted
                    href="/settings/security"
                    basic
                    content={t(
                      'mfaBanner.openSecuritySettings',
                      'Open Security Settings'
                    )}
                  />
                </Container>
              </DashboardNotice>
            )}
            <Layout.Group>
              <Sidebar.Mobile style={backgroundStyle}>
                <Layout horizontal spread center>
                  <Layout.Group>
                    <NavLink to="/">
                      {provider?.logoUrl && (
                        <ThemedImage
                          className="logo"
                          darkSrc={provider?.invertedLogoUrl}
                          ligthSrc={provider?.logoUrl}
                          style={{ height: '16px' }}
                        />
                      )}
                    </NavLink>
                  </Layout.Group>
                  <Layout.Group>
                    <Sidebar.Trigger>
                      <Icon name="bars" fitted />
                    </Sidebar.Trigger>
                  </Layout.Group>
                </Layout>
              </Sidebar.Mobile>
            </Layout.Group>
            <Layout.Group grow>
              <Container fluid={fluid}>
                <main>{children}</main>
              </Container>
            </Layout.Group>
            <Layout.Group>
              <Footer />
            </Layout.Group>
          </Layout>
        </Sidebar.Content>
      </Sidebar>
    </QueryClientProvider>
  );
}
