export function paymentMethodText(paymentMethod: string) {
  switch (paymentMethod) {
    case 'scan-to-pay':
      return 'Scan to Pay';
    case 'tap-to-pay':
      return 'Tap to Pay';
    default:
      return paymentMethod;
  }
}

export type DetailItemSession = {
  id: string;
};

export type DetailItemLocation = {
  id: string;
  name: string;
};

export type DetailItemInvoice = {
  id: string;
  identifier: string;
  year: number;
  month: number;
};

export type DetailItemPayment = {
  paymentId: string;
  paymentMethod: 'scan-to-pay' | 'tap-to-pay';
  currency: string;
  capturedAmount?: number;
};

export type FeeStatus = 'invoiced' | 'uninvoiced';

export interface DetailItem {
  createdAt: Date;
  payment: DetailItemPayment;
  invoice?: DetailItemInvoice;
  session?: DetailItemSession;
  location: DetailItemLocation;
  variableFee?: {
    amountExclVat?: number;
    amountInclVat?: number;
    currency: string;
    rate: {
      percentage: number;
    };
  };
  fixedFee?: {
    amountExclVat: number;
    amountInclVat: number;
    currency: string;
    rate: {
      amount: number;
      currency: string;
    };
  };
  totalFee: {
    amountExclVat: number;
    amountInclVat: number;
    currency: string;
  };
}
