import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import {
  AggregateTimeSeries,
  ErrorBoundary,
  SeriesChart,
} from 'react-tectonic';

import {
  changeFilterId,
  mergeFilters,
  sessionsExclusionFilter,
  getColors,
} from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import { numberWithCommas } from 'utils/formatting';
import { useUser } from 'contexts/user';

export default function CpoOverview({
  includeLocations = true,
  baseFilter = {},
}) {
  const { t } = useTranslation();
  const { provider } = useUser();
  const locationFilter = changeFilterId(baseFilter, 'locationId');

  const colorHex = `#${provider.primaryColorHex}`;

  const colors = getColors(colorHex);

  return (
    <ErrorBoundary>
      <Divider hidden />
      <Divider hidden />

      <Statistic.Group
        widths={includeLocations ? 3 : 2}
        style={{ justifyContent: 'space-between' }}>
        {includeLocations && (
          <CardinalityStatistic
            collection="locations"
            field="id"
            label={t('common.locations', 'Locations')}
            filter={locationFilter}
          />
        )}
        <CardinalityStatistic
          collection="sessions"
          field="evseControllerId"
          label={t('common.evses', 'EVSEs')}
          filter={baseFilter}
        />
        <CardinalityStatistic
          collection="sessions"
          label={t('common.sessions', 'sessions')}
          filter={baseFilter}
        />
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="count"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.sessions', 'Sessions')}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueField="count"
          valueFieldLabel={t('analyticsSessions.sessions', 'Sessions')}
          color={colorHex}
        />
      </AggregateTimeSeries>

      {includeLocations && (
        <React.Fragment>
          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Segment basic>
                  <TermsDonutChart
                    collection="locations"
                    aggField="status"
                    title={t(
                      'analyticsOverviewCPO.currentLocationStatus',
                      'Current Location Status'
                    )}
                    filter={locationFilter}
                    colors={colors}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="sessions"
        operation="sum"
        field="kwh"
        filter={mergeFilters(baseFilter, sessionsExclusionFilter)}>
        <SeriesChart
          title={t('analyticsSessions.totalkWh', 'Total kWh')}
          titleAlign="center"
          chartType="area"
          height={350}
          valueFieldLabel="kWh"
          valueFormatter={(value) => numberWithCommas(Math.round(value))}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />
    </ErrorBoundary>
  );
}
