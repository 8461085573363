import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Message, Modal } from 'semantic';
import { request } from 'utils/api';
import { MANUAL_EXCLUSION_REASON } from 'types/sessions';
import { translateExcludedReason } from 'utils/formatting';

export default function ExcludeSessionMenuItem({
  onChange,
  session,
}: {
  onChange: () => void;
  session: any;
}) {
  async function excludeSession({
    session,
    exclusionReason,
    exclusionReasonDescription,
  }: {
    session: any;
    exclusionReason: string;
    exclusionReasonDescription: string;
  }) {
    if (!exclusionReason) {
      setError(new Error('Exclusion reason is required'));
      return;
    }
    if (
      exclusionReason === MANUAL_EXCLUSION_REASON.OTHER &&
      !exclusionReasonDescription
    ) {
      setError(new Error('Exclusion reason description is required'));
      return;
    }

    setLoading(true);
    try {
      const body = {
        reason: exclusionReason,
        reasonDescription:
          exclusionReason === MANUAL_EXCLUSION_REASON.OTHER &&
          exclusionReasonDescription,
      };

      await request({
        method: 'POST',
        path: `/1/sessions/${session.id}/exclude`,
        body: body,
      });
      setOpen(false);
      setError(undefined);
    } catch (error) {
      setError(new Error(`${error}`));
    } finally {
      setLoading(false);
    }
  }

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>();

  const [exclusionReason, setExclusionReason] = React.useState<string>('');
  const [exclusionReasonDescription, setExclusionReasonDescription] =
    React.useState<string>('');

  const exclusionReasons: string[] = [];
  for (const reason in MANUAL_EXCLUSION_REASON) {
    exclusionReasons.push(reason);
  }

  return (
    <Modal
      trigger={
        <Dropdown.Item icon="not-equal" text={t('common.exclude', 'Exclude')} />
      }
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>Exclude Session</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to exclude this session?</p>
        <p>Reason:</p>
        <Form.Dropdown
          button
          basic
          placeholder={t('common.select', 'Select')}
          onChange={(e, { value }) => setExclusionReason(value as string)}
          options={exclusionReasons.map((reason) => ({
            key: reason,
            text: `${reason}: ${translateExcludedReason(reason, t)}`,
            value: reason,
          }))}></Form.Dropdown>
        {exclusionReason === MANUAL_EXCLUSION_REASON.OTHER && (
          <>
            <Form.Input
              // type="text"
              placeholder="Exclusion Reason..."
              onChange={(e) => setExclusionReasonDescription(e.target.value)}
            />
          </>
        )}
        {error && (
          <Message
            style={{ borderRadius: 0, marginTop: '1em' }}
            icon="triangle-exclamation"
            header={t('common.somethingWentWrong', 'Something went wrong')}
            error
            content={error.message}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => {
            setOpen(false);
            setError(undefined);
          }}>
          {t('common.cancel', 'Cancel')}
        </Button>
        <Button
          content={t('common.confirm', 'Confirm')}
          primary
          loading={loading}
          onClick={async () =>
            await excludeSession({
              session,
              exclusionReason,
              exclusionReasonDescription: exclusionReasonDescription,
            })
          }
        />
      </Modal.Actions>
    </Modal>
  );
}
