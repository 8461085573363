import React from 'react';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import PersonalReimbursementForm from './PersonalReimbursement.form';

export default function PersonalReimbursementLayout() {
  return (
    <SettingsShell>
      <PersonalReimbursementForm />
    </SettingsShell>
  );
}
