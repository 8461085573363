import React from 'react';
import { Icon } from 'semantic';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import styles from './style.module.css';

type Props = {
  color: string;
  icon: string;
  onClick?: () => void;
};

const CircularStyledDiv = styled('div')<{ boxShadowColor: string }>`
  border-radius: 500em !important;
  box-shadow: 0 0 0 0.1em ${(props) => props.boxShadowColor} inset;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ({ color, icon, onClick }: Props) => {
  const boxShadowColor = color ? color : 'rgba(0, 0, 0, 0.1)';

  // not using icon circular prop as it makes the tooltip too big
  return (
    <CircularStyledDiv
      boxShadowColor={boxShadowColor}
      style={{ cursor: 'pointer' }}
      onClick={onClick}>
      <Icon
        name={icon}
        style={{ color }}
        size="small"
        className={styles.circularIcon}
      />
    </CircularStyledDiv>
  );
};
