import React from 'react';
import { Header, Table, Label } from 'semantic';
import { labelForStatus, colorForStatus } from 'utils/locations';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';

export default function LocationOverview({ location }) {
  const { t } = useTranslation();

  return (
    <div>
      <Header as="h3">{t('common.status', 'Status')}</Header>
      <Label
        color={colorForStatus(location.status)}
        content={labelForStatus(location.status)}
      />
      <Header as="h3">{t('common.details', 'Details')}</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t('common.address', 'Address')}</Table.Cell>
            <Table.Cell>
              {[
                location.address,
                location.postal_code,
                location.city,
                location.country,
              ]
                .filter(Boolean)
                .join(', ')}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.createdAt', 'Created At')}</Table.Cell>
            <Table.Cell>{formatDate(location.createdAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.updatedAt', 'Updated At')}</Table.Cell>
            <Table.Cell>{formatDate(location.updatedAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('common.provider', 'Provider')}</Table.Cell>
            <Table.Cell>{location.provider.name}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}
