import React, { PropsWithChildren } from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import styles from './styles.module.less';
import { Message } from 'semantic';

interface Props {
  success?: boolean;
  info?: boolean;
  error?: boolean;
  close: () => unknown;
}

export const Toast = (props: PropsWithChildren<Props>) => {
  useTimeout(props.close, 15000);

  return (
    <div className={styles.toast}>
      <Message
        floating
        success={props.success}
        info={props.info}
        error={props.error}
        onDismiss={props.close}>
        <div style={{ paddingRight: '1px' }}>{props.children}</div>
      </Message>
    </div>
  );
};
