import React from 'react';
import { Message, Loader } from 'semantic';

import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';

const LoaderWrapper = () => {
  const { t } = useTranslation();
  return (
    <div style={{ height: '50vh', display: 'flex' }}>
      <Loader active>{t('loading.loading')}</Loader>
    </div>
  );
};

export default class AnalyticsToken extends React.Component {
  state = {
    data: null,
    loading: true,
    error: null,
    path: this.props.path,
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      this.setState({ path: this.props.path, loading: true }, () => {
        this.fetch();
      });
    }
  }

  async fetch() {
    const { path } = this.state;
    try {
      const { data } = await request({
        method: 'POST',
        path,
      });
      this.setState({ data, error: null, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const { loading, error, data } = this.state;
    if (this.props.path !== this.state.path) return <LoaderWrapper />;
    if (loading) return <LoaderWrapper />;
    if (error) return <Message error content={error.message} />;
    return React.cloneElement(this.props.children, data);
  }
}
