import React from 'react';

import { Switch, Route } from 'react-router-dom';
import { Container, Message } from 'semantic';
import { useTranslation } from 'react-i18next';

import { AppWrapper } from 'components';
import List from './List';
import {
  EVSE_OPERATIONAL_STATUSES_FE_PATH,
  hasEvseOperationalStatusesAccess,
} from './utils';

export default function OperationalStatuses() {
  const { t } = useTranslation();
  const hasAccess = hasEvseOperationalStatusesAccess();

  return (
    <AppWrapper>
      {!hasAccess && (
        <Message
          error
          content={t(
            'evseOperationalStatuses.noFeature',
            'You do not have access to this feature.'
          )}
        />
      )}

      {hasAccess && (
        <Container>
          <Switch>
            <Route
              path={EVSE_OPERATIONAL_STATUSES_FE_PATH}
              component={List}
              exact
            />
          </Switch>
        </Container>
      )}
    </AppWrapper>
  );
}
