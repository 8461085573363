import React from 'react';
import { Table, Header, Loader, Message, Button } from 'semantic';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { downloadCsv } from 'utils/csv';
import { omit } from 'lodash-es';
import { format } from 'date-fns';

class BillingPlansBreakdown extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/evse-controllers/breakdown/billing-plans',
      });
      this.setState({
        data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error, data } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Header as="h3">
          {t('cpoBillingPlansBreakdown.title', 'CPO Billing Plans Breakdown')}
        </Header>
        {loading && <Loader active inline="centered" />}
        {error && <Message error content={error.message} />}
        {data && data.length && (
          <React.Fragment>
            <p>
              Changes in billing plans month-to-month are not reflected in these
              figures
            </p>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('cpoBillingPlansBreakdown.columnMonth', 'Month')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('cpoBillingPlansBreakdown.columnPlans', 'Plans')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      'cpoBillingPlansBreakdown.columnTotalConnectors',
                      'Total Connectors'
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map((item) => {
                  const { year, month } = item._id;
                  return (
                    <Table.Row key={`${year}-${month}`}>
                      <Table.Cell>
                        {year} / {month}
                      </Table.Cell>
                      <Table.Cell>
                        <Table basic="very">
                          <Table.Body>
                            {Object.keys(omit(item, ['total', '_id'])).map(
                              (key) => {
                                return (
                                  <Table.Row key={key}>
                                    <Table.Cell width={10}>{key}</Table.Cell>
                                    <Table.Cell textAlign="right">
                                      {item[key] || 0}
                                    </Table.Cell>
                                  </Table.Row>
                                );
                              }
                            )}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                      <Table.Cell textAlign="right">{item.total}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            <div>
              <Button
                onClick={() => {
                  downloadCsv(
                    data.map((item) => {
                      const { _id, ...rest } = item;
                      const { year, month } = _id;
                      return {
                        month: `${year} / ${month}`,
                        ...rest,
                      };
                    }),
                    null,
                    `cpo-billing-plans-breakdown-${format(
                      new Date(),
                      'yyyy-MM-dd'
                    )}.csv`
                  );
                }}
                icon="download"
                content="Export CSV"
                basic
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(BillingPlansBreakdown);
