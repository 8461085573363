import React from 'react';
import InputField from 'components/form-fields/formik/InputField';
import { useAccount } from 'contexts/account';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import { Form, Message, Button, Divider } from 'semantic';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import { useSuboperatorValidation } from './SuboperatorSettings.validation';
import { useMutation } from '@tanstack/react-query';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';

type SuboperatorSettingsFormValues = {
  suboperatorSettings: {
    name: string;
  };
};

export function SuboperatorSettingsForm() {
  // @ts-ignore until the user context is typed
  const { hasPlatformFeature } = useUser();
  const { account: accountData } = useAccount();
  const { t } = useTranslation();

  const validationSchema = useSuboperatorValidation();

  if (!hasPlatformFeature('roaming:suboperator')) {
    return (
      <>
        <Message warning>
          {t(
            'access.cannotViewSuboperator',
            'Suboperator settings is not enable for your account.'
          )}
        </Message>
      </>
    );
  }

  const handleSubmitMutation = useMutation(
    async (values: SuboperatorSettingsFormValues) => {
      return await request({
        method: 'PATCH',
        path: '/1/accounts/self',
        body: values,
      });
    }
  );

  return (
    <>
      <Formik
        initialValues={{
          suboperatorSettings: {
            name: accountData?.suboperatorSettings?.name || '',
          },
        }}
        onSubmit={handleSubmitMutation.mutate}
        validationSchema={validationSchema}
        enableReinitialize>
        {({ handleSubmit, dirty }) => (
          <Form
            onSubmit={handleSubmit}
            loading={handleSubmitMutation.isLoading}>
            <FormHeader
              size="large"
              pb={5}
              pt={5}
              text={t('suboperator.headerSettings', 'Suboperator')}
            />
            <Divider />
            {handleSubmitMutation.isError && (
              <Message
                error
                content={(handleSubmitMutation.error as any)?.message}
              />
            )}
            {handleSubmitMutation.isSuccess && (
              <Message
                success
                content={t('messages.updated', 'Successfully updated!')}
              />
            )}
            <p style={{ maxWidth: 700 }}>
              {t(
                'suboperator.description',
                `As a suboperator, your name will be displayed in MSP providers that
        support Suboperator information. Please note that support for this
        information is not mandatory as per the OCPI roaming specification.
        Therefore we can't guarantee that the name will be displayed in other
        maps. It may take some hours for changes to take effect as well.`
              )}
            </p>
            <FormVerticalSpace />
            <InputField
              name={'suboperatorSettings.name'}
              label={t('suboperatorSettings.name', 'Suboperator Name')}
              wrapperStyle={{ maxWidth: 285 }}
            />

            <FormVerticalSpace size={50} />

            <Button
              primary
              disabled={!dirty}
              content={t(
                'settings.organization.suboperator.updateButton',
                'Update Suboperator Name'
              )}
              loading={handleSubmitMutation.isLoading}
              style={{ marginLeft: 0 }}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
