import React, { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { Message, Modal } from 'semantic';
import GrowthChart from './GrowthChart';
import Filters, { intervalOptions } from './Filters';
import { StyledLoader } from './PartyModal';

export default function IssuerModal(props) {
  const { trigger, issuer } = props;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(intervalOptions[2].value);

  const fetch = () => {
    setLoading(true);
    request({
      method: 'POST',
      path: `/1/external-tokens/party-breakdown/issuer/${issuer}/time-series`,
      params: {
        ...(from !== 'All' ? { from } : {}),
      },
    })
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      fetch();
    }
  }, [from, open]);

  return (
    <Modal
      closeIcon
      closeOnDimmerClick
      trigger={trigger}
      onOpen={() => setOpen(true)}
      size="fullscreen">
      <Modal.Header>Valid tokens - {issuer}</Modal.Header>
      <Modal.Content>
        {loading && !data && <StyledLoader inline="centered" active />}
        {!!error && <Message error content={error.message} />}
        {!!data && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}>
            <Filters
              loading={loading}
              onRefresh={() => !loading && fetch()}
              onFilterChange={(from) => setFrom(from)}
              currentFilter={from}
            />
            <GrowthChart timeSeries={data.timeSeries} />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}
