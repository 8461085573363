import React, { useEffect } from 'react';
import { useState } from 'react';
import { Modal, Menu, Loader } from 'semantic';
import SyncLogs from './Logs';
import SyncStatus from './Status';
import Audit from './Audit';
import { request } from 'utils/api';
import CurrentStatus from 'components/modals/SyncLogStatus/Audit/CurrentStatus';
import AsyncModal from 'helpers/async-modal';
import OcpiSyncStatus from 'components/modals/SyncLogStatus/ocpi';

function SyncLogStatus({ modelName, data, ...options }) {
  const [openTab, setOpenTab] = useState('status');
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const isToken = modelName === 'token';
  const hasOcpiSyncState = !!data.syncOcpiStatus;

  const getTokenCards = async () => {
    const resp = data.customId
      ? await request({
          method: 'POST',
          path: '/1/cards/search/fast',
          body: { searchPhrase: data.customId },
        })
      : { data: [] };
    setCards(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    isToken && getTokenCards();
  }, []);

  return (
    <>
      <Modal.Header>
        Sync status
        {options.syncStatusName && `: ${options.syncStatusName}`}
      </Modal.Header>
      <Modal.Content>
        {isToken &&
          (loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <CurrentStatus token={data} cards={cards} />
          ))}

        <Menu pointing secondary stackable>
          <Menu.Item
            content="Status"
            active={openTab === 'status'}
            onClick={() => setOpenTab('status')}
          />
          <Menu.Item
            content="Logs"
            active={openTab === 'logs'}
            onClick={() => setOpenTab('logs')}
          />
          {hasOcpiSyncState && (
            <Menu.Item
              content="Ocpi Sync Status"
              active={openTab === 'ocpiSyncStatus'}
              onClick={() => setOpenTab('ocpiSyncStatus')}
            />
          )}
          {isToken && (
            <Menu.Item
              content={'Audit'}
              active={openTab === 'audit'}
              onClick={() => setOpenTab('audit')}
            />
          )}
        </Menu>
        {openTab === 'logs' && <SyncLogs doc={data} />}
        {openTab === 'status' && (
          <SyncStatus
            showExternalRefs={options.showExternalRefs}
            externalRefFields={options.externalRefFields || []}
            externalSyncJobName={options.externalSyncJobName}
            doc={data}
            modelName={modelName}
          />
        )}
        {openTab === 'ocpiSyncStatus' && (
          <OcpiSyncStatus doc={data} modelName={modelName} />
        )}
        {isToken && openTab === 'audit' && <Audit doc={data} cards={cards} />}
      </Modal.Content>
    </>
  );
}

export default AsyncModal(SyncLogStatus);
