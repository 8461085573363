import React from 'react';

import { Grid, Header, Divider, Button } from 'semantic';

import { useTranslation } from 'react-i18next';
import FieldServiceStats from './FieldServiceStats';
import AppWrapper from 'components/AppWrapper';
import EvseIssues from './EvseIssues';
import { Link } from 'react-router-dom';
import ListHeader from 'components/ListHeader';

export default function Home() {
  const { t } = useTranslation();

  return (
    <AppWrapper>
      <Divider hidden />
      {/* A bit of space on top, not required if there are breadcrumbs */}
      <ListHeader title={t('maintenanceHome.title', 'Dashboard')}>
        <Button as={Link} to={`/maintenance/setup-evse`}>
          {t('maintenanceHome.setupNewEVSE', 'Setup New EVSE')}
        </Button>
      </ListHeader>

      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <FieldServiceStats />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={16}>
            <EvseIssues />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppWrapper>
  );
}
