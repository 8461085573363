import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import modal from 'helpers/modal';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

class AddSessionToAccessGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      name: '',
      accessGroup: null,
    };
  }

  onSubmit = async () => {
    this.setState({
      loading: true,
    });

    try {
      await request({
        method: 'POST',
        path: `/1/access-groups/${this.state.accessGroup}/member`,
        body: {
          type: 'visualNumber',
          name: this.state.name,
          visualNumber: this.props.visualNumber,
          priceType: 'free',
        },
      });
      this.props.history.push(
        `/my-locations/access-groups/${this.state.accessGroup}`
      );
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { name, loading, error, accessGroup } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <Modal.Header>
          {t(
            'addSessionToAccessGroup.title',
            'Add Session Token To Access Group'
          )}
        </Modal.Header>
        <Modal.Content>
          <Form id="add-to-access-group" onSubmit={this.onSubmit}>
            {error && <Message error content={error.message} />}
            <Form.Input
              required
              type="text"
              name="name"
              label={t('addSessionToAccessGroup.name', 'Name')}
              value={name}
              onChange={(e, { value }) => this.setState({ name: value })}
            />

            <SearchDropdown
              required
              fluid
              label={t(
                'addSessionToAccessGroup.labelAccessGroup',
                'Access Groups'
              )}
              onDataNeeded={async () => {
                return await request({
                  path: '/1/access-groups/mine',
                  method: 'POST',
                  body: {
                    type: 'rfid',
                  },
                });
              }}
              objectMode={false}
              value={this.state.accessGroup}
              clearable
              onChange={(e, { value }) => this.setState({ accessGroup: value })}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="add-to-access-group"
            loading={loading}
            disabled={!accessGroup || !name || loading}
            content="Add Token to Access Group"
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(modal(AddSessionToAccessGroup)));
