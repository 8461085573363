import React from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { request } from 'utils/api';

import { Search, ListHeader, SearchFilters, Layout } from 'components';

import { Container, Segment, Divider } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import { useTranslation } from 'react-i18next';
import FacilitatorLocationsTable from 'screens/FacilitatorLocations/FacilitatorLocationsTable';

export default function FacilitatorLocations() {
  const { t } = useTranslation();

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/2/locations/cpo/facilitator/search',
      body: filters,
    });
  };

  const labels = {
    hasErrors: t('common.hasErrors', 'Has Errors'),
    createdAt: t('common.createdAt', 'Created At'),
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
    };
  };

  return (
    <AppWrapper>
      <Search.Provider
        limit={100}
        onDataNeeded={onDataNeeded}
        filterMapping={getFilterMapping()}>
        {() => {
          return (
            <Container>
              <Breadcrumbs
                path={[<Link to="/facilitator/locations">Facilitator</Link>]}
                active={t('locations.title', 'Locations')}
              />
              <ListHeader title={t('locations.title', 'Locations')}>
                <Search.Export filename="locations" />
              </ListHeader>

              <Segment>
                <Layout horizontal spread stackable>
                  <SearchFilters.Modal>
                    <SearchFilters.DateRange
                      label={labels.createdAt}
                      name="createdAt"
                    />
                  </SearchFilters.Modal>
                  <Layout horizontal stackable center right>
                    <Search.Total />
                    <SearchFilters.Search
                      name="searchId"
                      placeholder={t(
                        'locations.searchPlaceholder',
                        'Search by ID, EVSE ID, etc.'
                      )}
                    />
                  </Layout>
                </Layout>
              </Segment>

              <Search.Status noResults={t('common.noResults', 'No Results')} />

              <FacilitatorLocationsTable />

              <Divider hidden />
              <div style={{ textAlign: 'center' }}>
                <Search.Pagination />
              </div>

              <Divider hidden />
            </Container>
          );
        }}
      </Search.Provider>
    </AppWrapper>
  );
}
