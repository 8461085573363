import React, { useContext, useMemo, useState } from 'react';

import { withTranslation } from 'react-i18next';

import { Table, Checkbox } from 'semantic';

import { Link } from 'react-router-dom';
import { Search } from 'components';

import { formatDateTime } from 'utils/date';

function BatchesTable({ t, isBatchSelected, toggleBatchForBulkActions }) {
  const { items, loading } = useContext(Search.Context);

  if (!items.length || loading) {
    return null;
  }

  return (
    <Table celled sortable>
      <Table.Header>
        <Header t={t} />
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Row
            item={item}
            isBatchSelected={isBatchSelected}
            toggleBatchForBulkActions={toggleBatchForBulkActions}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

function Header({ t }) {
  const translations = useMemo(
    () => ({
      note: t('evseIdPrintBatches.note', 'Note'),
      size: t('evseIdPrintBatches.size', 'Size'),
      numSubConnectors: t(
        'evseIdPrintBatches.numSubConnectors',
        'Number of connectors'
      ),
      createdAt: t('evseIdPrintBatches.createdAt', 'Created at'),
      createdBy: t('evseIdPrintBatches.createdBy', 'Created by'),
    }),
    [t]
  );

  return (
    <Table.Row>
      <Table.HeaderCell></Table.HeaderCell>
      <Table.HeaderCell>{translations.note}</Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.size}</Table.HeaderCell>
      <Table.HeaderCell width={3}>
        {translations.numSubConnectors}
      </Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.createdAt}</Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.createdBy}</Table.HeaderCell>
    </Table.Row>
  );
}

function Row({ item, isBatchSelected, toggleBatchForBulkActions }) {
  const [checked, setChecked] = useState(() =>
    isBatchSelected(item._id.toString())
  );
  const toggle = () => {
    const batchId = item._id.toString();
    toggleBatchForBulkActions(batchId);
    setChecked(isBatchSelected(batchId));
  };

  return (
    <Table.Row key={item._id}>
      <Table.Cell>
        <Checkbox checked={checked} onChange={toggle} />
      </Table.Cell>
      <Table.Cell>
        <Link to={`batches/${item._id.toString()}`}>{item.note}</Link>
      </Table.Cell>
      <Table.Cell>{item.size}</Table.Cell>
      <Table.Cell>{item.numSubConnectors}</Table.Cell>
      <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
      <Table.Cell>{item.createdBy?.name}</Table.Cell>
    </Table.Row>
  );
}

export default React.memo(withTranslation()(BatchesTable));
