import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import { AppWrapper } from 'components';
import { Container } from 'semantic';
import useMenuRoutes from 'hooks/useMenuRoutes';

export default function Providers({ routesContext = 'legacy' }) {
  const { providerRoutes } = useMenuRoutes(routesContext);
  const { uiRoute, apiRoute } = providerRoutes;

  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route
            path={uiRoute}
            component={(props) => <List {...props} apiRoute={apiRoute} />}
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
