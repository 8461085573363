import React from 'react';
import RoamingActivityBroadcast from './RoamingActivityBroadcast';
import RoamingActivityTokenAuthorize from './RoamingActivityTokenAuthorize';
import RoamingActivityCPOCommand from './RoamingActivityCPOCommand';

export interface Activity {
  label: string;
  correlationId: string;
  traceId: string;
  owner: {
    connectionId: string;
    connectionName?: string;
  };
  occurredAt: Date;
  entityId: string;
  entityType: string;
  childActivities: Activity[];
  body: {
    connectorPush?: {};
    tokenAuthorize?: {};
    cpoCommand?: {};
  };
}

interface Props {
  item: Activity;
}

const RoamingActivityItem: React.FC<Props> = ({ item }) => {
  const activityType = (() => {
    if (item.body?.connectorPush) {
      return 'ignore';
    }
    if (item?.body?.tokenAuthorize) {
      return 'tokenAuthorize';
    }
    if (!item?.body) {
      return 'broadcast';
    }
    if (item?.body?.cpoCommand) {
      return 'cpocommand';
    }
  })();
  switch (activityType) {
    case 'tokenAuthorize':
      return <RoamingActivityTokenAuthorize item={item} />;
    case 'broadcast':
      return <RoamingActivityBroadcast item={item} />;
    case 'cpocommand':
      return <RoamingActivityCPOCommand item={item} />;
    default:
      return null;
  }
};

export default RoamingActivityItem;
