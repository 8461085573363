import React, { useState } from 'react';
import { Formik, FormikValues } from 'formik';
import { BillingEntity, TaxResidencyCountryCode } from '../types';
import { request } from '../../../utils/api';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Modal } from 'semantic';
import InputField from '../../../components/form-fields/formik/InputField';
import { Group, Space } from '../../../components/Layout/helpers';
import DropdownField from '../../../components/form-fields/formik/DropdownField';
import FormHeader from '../../Settings/components/atoms/FormHeader';
import { mapEnumToOptions } from '../utils';
import AsyncModal from '../../../helpers/async-modal';

interface BillingEntityFormProps {
  billingEntity?: BillingEntity;
  onSave: () => void;
  close: () => void;
  onClose?: () => void;
  isUpdate?: boolean;
}

function BillingEntityForm({
  billingEntity,
  onSave,
  close,
  isUpdate,
}: BillingEntityFormProps) {
  const { t } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values: FormikValues) => {
    setErrorMessage('');
    setSuccess(false);
    try {
      await request({
        path: isUpdate
          ? `/1/billing-entities/${billingEntity?.id}`
          : '/1/billing-entities',
        method: isUpdate ? 'PATCH' : 'POST',
        body: values,
      });

      setSuccess(true);

      setTimeout(() => {
        onSave();
        close();
      }, 1000);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().required(
      t('billingEntities.nameRequired', 'Name is required')
    ),
    slug: Yup.string().required(
      t('billingEntities.slugRequired', 'Slug is required')
    ),
    taxResidencyCountryCode: Yup.string()
      .oneOf(Object.values(TaxResidencyCountryCode))
      .required(
        t(
          'billingEntities.taxResidencyCountryCodeRequired',
          'Tax Residency Country Code is required'
        )
      ),
    address: Yup.object().shape({
      streetAddress: Yup.string().required(
        t('billingEntities.streetAddressRequired', 'Street Address is required')
      ),
      locality: Yup.string().required(
        t('billingEntities.localityRequired', 'Town/City is required')
      ),
      postalCode: Yup.string().required(
        t('billingEntities.postalCodeRequired', 'Postal Code is required')
      ),
      countryCode: Yup.string().required(
        t('billingEntities.countryCodeRequired', 'Country Code is required')
      ),
      additionalDetails: Yup.string().optional(),
      region: Yup.string().optional(),
    }),
    billingDetails: Yup.object().shape({
      vatNumber: Yup.string().required(
        t('billingEntities.vatNumberRequired', 'VAT Number is required')
      ),
      chamberOfCommerceNumber: Yup.string().required(
        t(
          'billingEntities.chamberOfCommerceNumberRequired',
          'Chamber of Commerce Number is required'
        )
      ),
      iban: Yup.string().optional(),
      bic: Yup.string().optional(),
    }),
  });

  return (
    <>
      <Formik
        initialValues={billingEntity || {}}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {({ values, isValid, dirty, handleSubmit }) => {
          return (
            <>
              <Modal.Header>
                {isUpdate
                  ? t('billingEntities.edit', 'Edit Billing Entity')
                  : t('billingEntities.create', 'Create Billing Entity')}
              </Modal.Header>
              <Modal.Content>
                {success && (
                  <Message success>
                    <Message.Header>
                      {t('common.success', 'Success')}
                    </Message.Header>
                    <p>
                      {t(
                        'billingEntities.successMessage',
                        'Billing Entity saved successfully!'
                      )}
                    </p>
                  </Message>
                )}

                {errorMessage && (
                  <Message negative>
                    <Message.Header>
                      {t('common.error', 'Error')}
                    </Message.Header>
                    <p>{errorMessage}</p>
                  </Message>
                )}

                <Form noValidate onSubmit={handleSubmit}>
                  <InputField
                    name="name"
                    label={t('billingEntities.name', 'Name')}
                  />

                  <Space direction="vertical" size={10} />

                  <InputField
                    name="slug"
                    label={t('billingEntities.slug', 'Slug')}
                    disabled={isUpdate}
                  />

                  <Space direction="vertical" size={10} />

                  <DropdownField
                    name="taxResidencyCountryCode"
                    label={t(
                      'billingEntities.taxResidencyCountryCode',
                      'Tax Residency Country Code'
                    )}
                    fluid
                    selection
                    clearable
                    options={mapEnumToOptions(TaxResidencyCountryCode) || []}
                    required={true}
                    disabled={isUpdate}
                  />

                  <Space direction="vertical" size={20} />

                  <FormHeader
                    text="Address Information"
                    size="medium"
                    mt={12}
                    mb={12}
                  />

                  <Group align="flex-start" spacing="xs" grow>
                    <InputField
                      name="address.streetAddress"
                      label={t(
                        'billingEntities.streetAddress',
                        'Street Address'
                      )}
                      wrapperStyle={{ width: 275 }}
                      required
                    />
                    <InputField
                      name="address.locality"
                      label={t('billingEntities.locality', 'Town/City')}
                      wrapperStyle={{ width: 275 }}
                      required
                    />

                    <InputField
                      name="address.region"
                      label={t('billingEntities.region', 'Region')}
                      wrapperStyle={{ width: 275 }}
                    />
                  </Group>

                  <Space direction="vertical" size={20} />

                  <Group align="flex-start" spacing="xs" grow>
                    <InputField
                      name="address.postalCode"
                      label={t('billingEntities.postalCode', 'Postal Code')}
                      wrapperStyle={{ width: 275 }}
                      required
                    />

                    <DropdownField
                      name="address.countryCode"
                      label={t('billingEntities.countryCode', 'Country Code')}
                      fluid
                      selection
                      clearable
                      options={mapEnumToOptions(TaxResidencyCountryCode) || []}
                      wrapperStyle={{ width: 275 }}
                      required={true}
                    />

                    <InputField
                      name="address.additionalDetails"
                      label={t(
                        'billingEntities.additionalDetails',
                        'Additional Details'
                      )}
                      wrapperStyle={{ width: 275 }}
                    />
                  </Group>

                  <Space direction="vertical" size={20} />

                  <FormHeader
                    text="Billing Details"
                    size="medium"
                    mt={12}
                    mb={12}
                  />

                  <Group align="flex-start" spacing="xs" grow>
                    <InputField
                      name="billingDetails.vatNumber"
                      label={t('billingEntities.vatNumber', 'VAT Number')}
                      wrapperStyle={{ width: 421 }}
                      required
                    />

                    <InputField
                      name="billingDetails.chamberOfCommerceNumber"
                      label={t(
                        'billingEntities.chamberOfCommerceNumber',
                        'Chamber of Commerce Number'
                      )}
                      wrapperStyle={{ width: 421 }}
                      required
                    />
                  </Group>

                  <Space direction="vertical" size={20} />

                  <Group align="flex-start" spacing="xs" grow>
                    <InputField
                      name="billingDetails.iban"
                      label={t('billingEntities.iban', 'IBAN')}
                      wrapperStyle={{ width: 421 }}
                    />
                    <InputField
                      name="billingDetails.bic"
                      label={t('billingEntities.bic', 'BIC')}
                      wrapperStyle={{ width: 421 }}
                    />
                  </Group>

                  <Space direction="vertical" size={20} />

                  <Group align="flex-start" position="right">
                    <Button onClick={close} basic>
                      {t('common.cancel', 'Cancel')}
                    </Button>

                    <Button type="submit" primary disabled={!isValid || !dirty}>
                      {t('common.save', 'Save')}
                    </Button>
                  </Group>
                </Form>
              </Modal.Content>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default AsyncModal(BillingEntityForm);
