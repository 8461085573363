import React from 'react';
import FormVerticalSpace from '../../../components/atoms/FormVerticalSpace';
import { Layout, LoadButton } from '../../../../../components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Flex } from '../../../../../components/Layout/helpers';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Button, Label, Message, Segment } from 'semantic';
import { useUser } from '../../../../../contexts/user';
import { request } from '../../../../../utils/api';
import { useMutation } from '@tanstack/react-query';

export default function OrganizationTwoFactorAuthentication() {
  // @ts-ignore until the user context is typed
  const { user, fetchUser } = useUser();
  const { mfaMethod } = user;
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const handleDeleteMutation = useMutation(
    async () => {
      await request({
        method: 'DELETE',
        path: '/1/mfa/disable',
      });
    },
    {
      onSuccess: () => {
        fetchUser();
      },
      onError: (error: any) => {
        if (error.status === 403) {
          history.push(`/confirm-access?to=${location.pathname}`);
          return;
        }
      },
    }
  );

  return (
    <>
      <FormVerticalSpace />
      <h4>Two-factor authentication</h4>

      <Segment>
        <Layout vertical spread>
          <Flex>
            <Icon name="mobile-screen-button" />
            <Header size="tiny" style={{ marginTop: 0, marginBottom: '0.5em' }}>
              {t('settingSecurity.otpHeader', 'App authentication')}{' '}
              {mfaMethod === 'otp' && (
                <Label color="olive">{t('common.enabled', 'Enabled')}</Label>
              )}
            </Header>
          </Flex>
          <p>
            {t(
              'settingSecurity.otpDescription',
              'Security codes will be generated by your preferred authenticator app.'
            )}
          </p>
          <div style={{ maxWidth: 100 }}>
            <Button
              size="tiny"
              basic
              to="/settings/account/organization/mfa-authenticator"
              as={Link}>
              {mfaMethod === 'otp'
                ? t('common.config', 'Configure')
                : t('common.enable', 'Enable')}
            </Button>
          </div>
        </Layout>
      </Segment>
      <Segment>
        <Layout vertical spread>
          <Flex>
            <Icon name="regular regular-message" />
            <Header size="tiny" style={{ marginTop: 0, marginBottom: '0.5em' }}>
              {t('settingSecurity.smsHeader', 'SMS authentication')}
              {mfaMethod == 'sms' && (
                <Label color="olive">{t('common.enabled', 'Enabled')}</Label>
              )}
            </Header>
          </Flex>
          <p>
            {t(
              'settingSecurity.smsDescription',
              'Security codes will be sent via SMS to your mobile device.'
            )}
          </p>
          <div style={{ maxWidth: 100 }}>
            <Button
              basic
              size="small"
              to="/settings/account/organization/mfa-sms"
              as={Link}>
              {mfaMethod === 'sms'
                ? t('common.config', 'Configure')
                : t('common.enable', 'Enable')}
            </Button>
          </div>
        </Layout>
      </Segment>

      {mfaMethod && (
        <>
          {handleDeleteMutation.isError &&
            handleDeleteMutation.error.message && (
              <Message error content={handleDeleteMutation.error.message} />
            )}
          <Segment color="red">
            <Layout vertical spread>
              <Header
                size="tiny"
                style={{ marginTop: 0, marginBottom: '0.5em' }}>
                {t(
                  'settingSecurity.disableHeader',
                  'Turn off two-factor authentication'
                )}
              </Header>
              <p>
                {t(
                  'settingSecurity.disableDescription',
                  'Turning off two-factor authentication will remove an extra layer of security on your account.'
                )}
              </p>
              <div style={{ maxWidth: 100 }}>
                <LoadButton
                  onClick={handleDeleteMutation.mutate}
                  basic
                  size="small"
                  color="red">
                  {t('settingSecurity.disableBtn', 'Turn off')}
                </LoadButton>
              </div>
            </Layout>
          </Segment>
        </>
      )}
    </>
  );
}
