import { default as Modal } from './Modal';
import { default as Number } from './Number';
import { default as Search } from './Search';
import { default as Dropdown } from './Dropdown';
import { default as Checkbox } from './Checkbox';
import { default as DateRange } from './DateRange';
import { default as Input } from './Input';
import { DropdownFilterV2 } from 'components/search/Filters/DropdownV2';
import { InputFilterV2 } from 'components/search/Filters/InputV2';
import { DropdownSearchFilterV2 } from 'components/search/Filters/DropdownSearchFilterV2';
import { ModalFilterV2 } from 'components/search/Filters/ModalV2/ModalV2';

const SearchFilters = {
  /**
   * @deprecated
   * @see {@link ./Modal | Modal}
   */
  Modal,

  ModalFilterV2,
  Number,
  Search,

  /**
   * @deprecated
   * @see {@link ./Dropdown | Dropdown}
   *
   */
  Dropdown,

  DropdownFilterV2,
  Checkbox,
  DateRange,

  /**
   * @deprecated Use InputFilterV2 instead
   */
  Input,
  DropdownSearchFilterV2,
  InputFilterV2,
};

export default SearchFilters;
