import React from 'react';
import AppWrapper from 'components/AppWrapper';

import { Header, Container, Divider, Message, Button } from 'semantic';
import { request } from 'utils/api';
import NewSwarmTest from './modals/NewSwarmTest';
import Cards from './Cards';

export default class OcppSwarmSimulator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      items: [],
    };
  }

  componentDidMount() {
    this.fetchSwarmTests();
  }

  handleNewSwarmTest = async (parameters) => {
    const {
      startIn,
      numChargepoints,
      timeoutBetweenInit,
      messagesPerChargepoint,
      timeoutBetweenCommands,
      timeoutAfterConnection,
      ocppUrl,
      comment,
    } = parameters;

    const startAt = new Date(new Date().getTime() + parseInt(startIn, 10));

    await request({
      method: 'POST',
      path: '/1/ocpp/swarm-simulations',
      body: {
        startAt,
        ocppUrl,
        numChargepoints,
        timeoutBetweenInit,
        messagesPerChargepoint,
        timeoutBetweenCommands,
        timeoutAfterConnection,
        comment,
      },
    });

    this.fetchSwarmTests();
  };

  fetchSwarmTests = async () => {
    this.setState({ fetching: true });
    try {
      const response = await request({
        method: 'GET',
        path: '/1/ocpp/swarm-simulations',
      });
      const data = response;
      this.setState({ fetching: false, items: data.body });
    } catch (error) {
      this.setState({ fetching: false, error });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <AppWrapper>
        <Container>
          <Header as="h2">
            OCPP swarm simulations
            <NewSwarmTest
              onChange={this.handleNewSwarmTest.bind(this)}
              ocppUrl={this.state.ocppUrl}
              ocppIdentity={this.state.ocppIdentity}
              heartbeat={this.state.heartbeat}
              trigger={
                <Button
                  primary
                  floated="right"
                  content="New OCPP swarm test"
                  icon="plus"
                />
              }
            />
            <Button
              onClick={this.fetchSwarmTests}
              content="Refresh"
              floated="right"
              basic
              icon="arrow-rotate-right"
            />
          </Header>
          {error && <Message error content={error.message} />}
          <Divider />
          <Cards
            items={this.state.items}
            onDelete={async (item) => {
              await request({
                method: 'DELETE',
                path: '/1/ocpp/swarm-simulations/' + item.id,
              });
              this.fetchSwarmTests();
            }}
          />
        </Container>
      </AppWrapper>
    );
  }
}
