import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Message, Dropdown, Grid, Header } from 'semantic';

import MenuContainer from './Menu';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import Connectors from './ConnectorsMsp';
import { request } from 'utils/api';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import Breadcrumbs from 'components/Breadcrumbs';

import { useTranslation } from 'react-i18next';
import InspectObject from '../../components/modals/InspectObject';

export default function Location({ match }) {
  const { t } = useTranslation();

  const itemId = match.params.id;

  const [item, setItem] = React.useState();
  const [status, setStatus] = React.useState({ request: true });

  async function fetchData() {
    setStatus({ loading: true });
    try {
      const { data } = await request({
        method: 'GET',
        path: `/2/locations/msp/${itemId}`,
      });
      setStatus({ success: true });
      setItem(data);
    } catch (e) {
      setStatus({ error: e });
    }
  }

  React.useEffect(() => {
    fetchData();
  }, [itemId]);

  React.useEffect(() => {
    if (item?.providerContext && item.providerContext !== 'msp') {
      setStatus({ error: new Error(`Not a MSP location.`) });
    }
  }, [item]);

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={
            currentUserCanAccessProviderEndpoint('locations', 'read')
              ? [
                  <Link to="/cards">Cards</Link>,
                  <Link to="/cards/locations">
                    {t('location.breadcrumbHeader', 'Locations')}
                  </Link>,
                ]
              : [
                  <Link to="/my-locations">
                    {t('myLocations.header', 'My Locations')}
                  </Link>,
                ]
          }
          active={item ? item.name : t('loading.loading')}
        />
        <Grid reversed="computer">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={4}
            textAlign="right"
            verticalAlign="middle">
            <Dropdown
              button
              className="ui-button primary"
              text={t('location.actionsBtn', 'Actions')}>
              <Dropdown.Menu direction="left">
                <InspectObject
                  name="MSP Location"
                  data={item}
                  trigger={<Dropdown.Item content={'Inspect'} icon="code" />}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={12}>
            <Header as="h2" style={{ margin: '0' }}>
              {item ? item.name : t('loading.loading')}
            </Header>
          </Grid.Column>
        </Grid>
      </Container>
      <Divider hidden />
      {item && item.deletedAt && (
        <Message
          error
          content={t(
            'location.locationDeleted',
            'This location has been deleted'
          )}
        />
      )}
      <MenuContainer itemId={itemId} />
      <Divider hidden />

      {status.error && (
        <>
          <Message error content={status.error.message} />
          <Link to={`/charging-stations/locations/${itemId}`}>
            Use {item?.providerContext.toUpperCase()} location endpoint instead
          </Link>
        </>
      )}

      {status.request && <PageLoader />}
      {item && !status.error && (
        <Switch>
          <Route
            exact
            path="/cards/locations/:id/connectors"
            component={(props) => <Connectors {...props} location={item} />}
          />
          <Route
            exact
            path="/cards/locations/:id"
            item={item}
            component={(props) => <Overview {...props} location={item} />}
          />
        </Switch>
      )}
    </AppWrapper>
  );
}
