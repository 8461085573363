import React, { useState } from 'react';
import { Input, Table } from 'semantic';
import { currencyToSymbol } from 'utils/formatting';
import { useTranslation } from 'react-i18next';
import { TariffProfile } from 'types/tariffprofile';
import { useFeatures } from 'contexts/features';

type Props = {
  sameForAcDc: boolean;
  formValues: TariffProfile;
  setField: (field: string, value: any) => void;
};

export function SimpleTariffCostSettings({
  sameForAcDc,
  formValues,
  setField,
}: Props): React.JSX.Element {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  // TODO: Remove this feature flag once we have migrated all customers to the new tariff profiles
  const supportNewTariffProfile = hasFeature('tariff-profiles-v2');

  const numbersRows = [];

  const [costSettingsState, setCostSettingsState] = useState({
    ac: {
      pricePerKwh: formValues.costSettings?.ac.pricePerKwh.toString() ?? '',
      pricePerSession:
        formValues.costSettings?.ac.pricePerSession.toString() ?? '',
      pricePerHour: formValues.costSettings?.ac.pricePerHour.toString() ?? '',
    },
    dc: {
      pricePerKwh: formValues.costSettings?.dc.pricePerKwh.toString() ?? '',
      pricePerSession:
        formValues.costSettings?.dc.pricePerSession.toString() ?? '',
      pricePerHour: formValues.costSettings?.dc.pricePerHour.toString() ?? '',
    },
  });

  if (sameForAcDc) {
    numbersRows.push(
      <Table.Row key={'simple-cost-row' + numbersRows.length + 1}>
        <Table.Cell>Base Tariff</Table.Cell>
        {supportNewTariffProfile && <Table.Cell>AC/DC</Table.Cell>}
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.dc.pricePerKwh}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                dc: { ...costSettingsState.dc, pricePerKwh: value },
              });

              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.ac || !next?.dc) {
                return;
              }

              next.ac.pricePerKwh = +value;
              next.dc.pricePerKwh = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.dc.pricePerSession}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                dc: { ...costSettingsState.dc, pricePerSession: value },
              });

              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.ac || !next?.dc) {
                return;
              }

              next.ac.pricePerSession = +value;
              next.dc.pricePerSession = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.dc.pricePerHour}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                dc: { ...costSettingsState.dc, pricePerHour: value },
              });

              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.ac || !next?.dc) {
                return;
              }
              next.ac.pricePerHour = +value;
              next.dc.pricePerHour = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
      </Table.Row>
    );
  } else {
    // AC
    numbersRows.push(
      <Table.Row key={'simple-cost-row' + numbersRows.length + 1}>
        <Table.Cell rowSpan="2">Base Tariff</Table.Cell>
        {supportNewTariffProfile && <Table.Cell>AC</Table.Cell>}
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.ac.pricePerKwh}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                ac: { ...costSettingsState.ac, pricePerKwh: value },
              });
              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.ac) {
                return;
              }
              next.ac.pricePerKwh = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.ac.pricePerSession}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                ac: { ...costSettingsState.ac, pricePerSession: value },
              });
              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.ac) {
                return;
              }
              next.ac.pricePerSession = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.ac.pricePerHour}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                ac: { ...costSettingsState.ac, pricePerHour: value },
              });
              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.ac) {
                return;
              }
              next.ac.pricePerHour = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
      </Table.Row>
    );

    // DC
    numbersRows.push(
      <Table.Row key={'simple-cost-row' + numbersRows.length + 1}>
        {supportNewTariffProfile && (
          <>
            <Table.Cell style={{ display: 'none' }} />
            <Table.Cell>DC</Table.Cell>
          </>
        )}
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.dc.pricePerKwh}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                dc: { ...costSettingsState.dc, pricePerKwh: value },
              });
              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;

              if (!next?.dc) {
                return;
              }

              next.dc.pricePerKwh = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.dc.pricePerSession}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                dc: { ...costSettingsState.dc, pricePerSession: value },
              });
              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;
              if (!next?.dc) {
                return;
              }
              next.dc.pricePerSession = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Input
            style={{ marginBottom: '4px' }}
            label={currencyToSymbol(formValues.currency)}
            fluid
            value={costSettingsState.dc.pricePerHour}
            onChange={(e, { value }) => {
              setCostSettingsState({
                ...costSettingsState,
                dc: { ...costSettingsState.dc, pricePerHour: value },
              });
              if (isNaN(+value)) {
                return;
              }

              const next = formValues.costSettings;

              if (!next?.dc) {
                return;
              }

              next.dc.pricePerHour = +value;
              setField('costSettings', next);
            }}
          />
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <>
      <Table definition celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            {supportNewTariffProfile && (
              <Table.HeaderCell>
                {t('advancedTariffs.ConnectorType', 'Connector Type')}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell>
              {t('common.pricePerKwh', 'Price per kWh')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('common.pricePerSession', 'Price per Session')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('common.pricePerHour', 'Price per Hour')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{numbersRows}</Table.Body>
      </Table>
      <i style={{ float: 'left', marginTop: 6 }}>
        {t(
          'advancedTariff.pricePerHourCapMessage',
          '*Maximum time cost may be applied per session according to roaming contracts'
        )}
      </i>
    </>
  );
}
