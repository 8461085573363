import { Label } from 'semantic';
import React from 'react';
import { TFunction } from 'react-i18next';

interface PaymentStatusProps {
  status?: string;
  t: TFunction;
}

export function PaymentStatus({ status, t }: PaymentStatusProps) {
  switch (status?.toUpperCase()) {
    case 'PAID':
      return (
        <Label
          color="olive"
          content={t('payment.paymentStatus.paid', 'Paid')}
        />
      );
    case 'AUTHORIZED':
      return (
        <Label
          color="blue"
          content={t('payment.paymentStatus.authorized', 'Authorized')}
        />
      );
    case 'REFUNDED':
      return (
        <Label
          color="red"
          content={t('payment.paymentStatus.refunded', 'Refunded')}
        />
      );
    case 'CANCELED':
      return (
        <Label
          color="grey"
          content={t('payment.paymentStatus.canceled', 'Canceled')}
        />
      );
    case 'VOIDED':
      return (
        <Label
          color="grey"
          content={t('payment.paymentStatus.voided', 'Voided')}
        />
      );
    default:
      return <Label content="N/A"></Label>;
  }
}
