import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectTariffSystem from 'components/tariffs/AdvancedTariff/SelectTariffSystem';
import {
  TariffProfile,
  TariffProfileCostSettingsFields,
  TariffProfileCurrentCostSettingsComponentRestrictions,
} from 'types/tariffprofile';
import { TariffSystem } from 'components/tariffs/AdvancedTariff/state';

import './AdvancedTariffCostSettings.less';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  List,
  Message,
  Table,
  Divider,
} from 'semantic';
import { currencyToSymbol, formatCurrency } from 'utils/formatting';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import DaysOfWeekInput from 'components/form-fields/DayOfWeek';
import TimeObjectInput from 'components/form-fields/TimeObject';
import DateObjectInput, {
  dateObjToString,
} from 'components/form-fields/DateObject';
import { CalendarDateObj, ClockTimeObj, WeekDay } from 'types/date';
import SummaryLabel from 'components/tariffs/AdvancedTariff/SummaryLabel';
import { formatDate } from 'utils/date';
import { PriceText } from 'components/tariffs/AdvancedTariff/CostSettings/PriceText';
import { useEnergyMarketPriceContext } from 'components/MarketEnergyTariffs/Context';
import { useEnableEnergyMarketTrackingState } from 'components/tariffs/AdvancedTariff/BaseCostSettings';
import {
  AdvancedTariffCostSettingsProps,
  BasicCostSettingsFormProps,
  BasicCostSettingsPreviewProps,
  BasicCostSettingsProps,
  DateRangeProps,
  DateTextProps,
  DaysOfWeekTextProps,
  DynamicCostSettingsProps,
  RestrictionsFormProps,
  ScheduledCostCardProps,
  ScheduledCostSettingsProps,
  TimeOfDayFormProps,
} from 'components/modals/EditTariffProfile/types';
import CurrentMarketPrices from 'components/MarketEnergyTariffs/Current';
import PriceInput from 'components/form-fields/Price';
import PricePerIdleMinute from 'components/tariffs/AdvancedTariff/CostSettings/PricePerIdleMinute';
import { useFeatures } from 'contexts/features';

export function AdvancedTariffCostSettings({
  sameForAcDc,
  formValues,
  setField,
  setActiveTariffSystem,
  defaultIdleGracePeriodMinutes = 30,
  hideHeader = false,
}: AdvancedTariffCostSettingsProps) {
  const { t } = useTranslation();

  const { hasFeature } = useFeatures();

  const supportNewTariffProfile = hasFeature('tariff-profiles-v2');
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const tariffSystem = getTariffSystem(formValues);

  const [ACTariffSystem, setACTariffSystem] = useState(tariffSystem);
  const [DCTariffSystem, setDCTariffSystem] = useState(tariffSystem);

  const [acCostSettings, setAcCostSettings] = useState(
    formValues.costSettings?.ac!
  );

  const [dcCostSettings, setDcCostSettings] = useState(
    formValues.costSettings?.dc!
  );

  const [enableIdleFeeAC, setEnableIdleFeeAC] = useState(
    (acCostSettings?.pricePerIdleMinute ?? 0) > 0
  );

  const [enableIdleFeeDC, setEnableIdleFeeDC] = useState(
    (dcCostSettings?.pricePerIdleMinute ?? 0) > 0
  );

  // On each render this updates.
  setActiveTariffSystem({ ac: ACTariffSystem, dc: DCTariffSystem });

  useEffect(() => {
    setField('costSettings.ac', acCostSettings);
  }, [acCostSettings]);

  useEffect(() => {
    setField('costSettings.dc', dcCostSettings);
  }, [dcCostSettings]);

  if (sameForAcDc || !supportNewTariffProfile) {
    return (
      <>
        {supportNewTariffProfile && !hideHeader && <h4>AC/DC</h4>}
        <div className={'cost-settings-container'}>
          <SelectTariffSystem
            tariffSystem={DCTariffSystem}
            setTariffSystem={(e) => {
              setDCTariffSystem(e);
              setACTariffSystem(e);
            }}
          />
          {DCTariffSystem === 'basic' && (
            <BasicCostSettings
              currentCostSettings={dcCostSettings}
              currency={formValues.currency}
              setCurrentCostSettings={(e) => {
                const newSettings = {
                  ...acCostSettings,
                  ...e,
                };
                setAcCostSettings(newSettings);
                setDcCostSettings(newSettings);
              }}
            />
          )}

          {DCTariffSystem === 'dynamic' && (
            <DynamicCostSettings
              currentCostSettings={dcCostSettings}
              currency={formValues.currency}
              setCurrentCostSettings={(e) => {
                const newSettings = {
                  ...acCostSettings,
                  ...e,
                };
                setAcCostSettings(newSettings);
                setDcCostSettings(newSettings);
              }}
            />
          )}

          {DCTariffSystem === 'scheduled' && (
            <>
              <ScheduledCostSettings
                currency={formValues.currency}
                costSettings={dcCostSettings}
                onChange={(e) => {
                  const newSettings = {
                    ...acCostSettings,
                    ...e,
                  };
                  setAcCostSettings(newSettings);
                  setDcCostSettings(newSettings);
                }}
              />
              <ActionButton
                primary
                compact
                style={{ marginBottom: '10px' }}
                floated={'left'}
                onClick={() => {
                  const DCComponents = dcCostSettings?.components ?? [];
                  DCComponents.push({
                    costSettings: {
                      pricePerKwh: 0,
                      pricePerSession: 0,
                      pricePerHour: 0,
                      pricePerIdleMinute: 0,
                      idleGracePeriodMinutes: defaultIdleGracePeriodMinutes,
                    },
                    restrictions: {},
                  });

                  setDcCostSettings({
                    ...dcCostSettings,
                    components: DCComponents,
                  });
                  setAcCostSettings({
                    ...dcCostSettings,
                    components: DCComponents,
                  });
                }}>
                <Icon name={'plus'} />{' '}
                {t(
                  'advancedTariff.addScheduledTariff',
                  'Add a scheduled tariff system'
                )}
              </ActionButton>
            </>
          )}

          {advancedTariffFeatures.idleFee && (
            <div style={{ marginTop: '1rem' }}>
              <PricePerIdleMinute
                enableIdleFee={enableIdleFeeDC}
                setEnableIdleFee={(e) => {
                  setEnableIdleFeeDC(e);
                  setEnableIdleFeeAC(e);

                  if (!e) {
                    const newSettings = {
                      ...dcCostSettings,
                      pricePerIdleMinute: undefined,
                      idleGracePeriodMinutes: undefined,
                    };
                    setAcCostSettings(newSettings);
                    setDcCostSettings(newSettings);
                  }
                }}
                pricePerIdleMinute={dcCostSettings?.pricePerIdleMinute ?? 0}
                setPricePerIdleMinute={(newPrice) => {
                  const newSettings = {
                    ...dcCostSettings,
                    ...(newPrice > 0 && !dcCostSettings.idleGracePeriodMinutes
                      ? {
                          idleGracePeriodMinutes: defaultIdleGracePeriodMinutes,
                        }
                      : undefined),
                    pricePerIdleMinute: newPrice,
                  };

                  setDcCostSettings(newSettings);
                  setAcCostSettings(newSettings);
                }}
                idleGracePeriodMinutes={
                  dcCostSettings?.idleGracePeriodMinutes ?? 0
                }
                setIdleGracePeriodMinutes={(newIdleMinutes) => {
                  const newSettings = {
                    ...dcCostSettings,
                    idleGracePeriodMinutes: newIdleMinutes,
                  };
                  setDcCostSettings(newSettings);
                  setAcCostSettings(newSettings);
                }}
                currency={formValues.currency}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {!hideHeader && <h4>AC</h4>}
      <div className={'cost-settings-container'}>
        <SelectTariffSystem
          tariffSystem={ACTariffSystem}
          setTariffSystem={setACTariffSystem}
        />
        {ACTariffSystem === 'basic' && (
          <>
            <BasicCostSettings
              currentCostSettings={acCostSettings}
              currency={formValues.currency}
              setCurrentCostSettings={(e) => {
                // I know this is not great, sorry, I'll refactor it later
                const newSettings = {
                  ...acCostSettings,
                  ...e,
                };
                setAcCostSettings(newSettings);
              }}
            />
          </>
        )}

        {ACTariffSystem === 'scheduled' && (
          <>
            <ScheduledCostSettings
              currency={formValues.currency}
              costSettings={acCostSettings}
              onChange={(e) => {
                const newSettings = {
                  ...acCostSettings,
                  ...e,
                };
                setAcCostSettings(newSettings);
              }}
            />
            <ActionButton
              primary
              compact
              floated={'left'}
              onClick={() => {
                const ACComponents = acCostSettings.components ?? [];
                ACComponents.push({
                  costSettings: {
                    pricePerKwh: 0,
                    pricePerSession: 0,
                    pricePerHour: 0,
                    pricePerIdleMinute: 0,
                    idleGracePeriodMinutes: defaultIdleGracePeriodMinutes,
                  },
                  restrictions: {},
                });

                setAcCostSettings({
                  ...acCostSettings,
                  components: ACComponents,
                });
              }}>
              <Icon name={'plus'} />{' '}
              {t(
                'advancedTariff.addScheduledTariff',
                'Add a scheduled tariff system'
              )}
            </ActionButton>
          </>
        )}

        {DCTariffSystem === 'dynamic' && (
          <DynamicCostSettings
            currentCostSettings={dcCostSettings}
            currency={formValues.currency}
            setCurrentCostSettings={(e) => {
              const newSettings = {
                ...acCostSettings,
                ...e,
              };
              setAcCostSettings(newSettings);
            }}
          />
        )}

        {advancedTariffFeatures.idleFee && (
          <div style={{ marginTop: '1rem' }}>
            <PricePerIdleMinute
              enableIdleFee={enableIdleFeeAC}
              setEnableIdleFee={(e) => {
                setEnableIdleFeeAC(e);
                if (!e) {
                  const newSettings = {
                    ...acCostSettings,
                    pricePerIdleMinute: undefined,
                    idleGracePeriodMinutes: undefined,
                  };
                  setAcCostSettings(newSettings);
                }
              }}
              pricePerIdleMinute={acCostSettings?.pricePerIdleMinute ?? 0}
              setPricePerIdleMinute={(newPrice) => {
                const newSettings = {
                  ...acCostSettings,
                  ...(newPrice > 0 && !acCostSettings.idleGracePeriodMinutes
                    ? { idleGracePeriodMinutes: defaultIdleGracePeriodMinutes }
                    : undefined),
                  pricePerIdleMinute: newPrice,
                };
                setAcCostSettings(newSettings);
                setEnableIdleFeeAC(true);
              }}
              idleGracePeriodMinutes={
                acCostSettings?.idleGracePeriodMinutes ?? 0
              }
              setIdleGracePeriodMinutes={(newIdleMinutes) => {
                const newSettings = {
                  ...acCostSettings,
                  idleGracePeriodMinutes: newIdleMinutes,
                };
                setAcCostSettings(newSettings);
              }}
              currency={formValues.currency}
            />
          </div>
        )}
      </div>
      {!hideHeader && <h4>DC</h4>}
      <div className={'cost-settings-container'}>
        <SelectTariffSystem
          tariffSystem={DCTariffSystem}
          setTariffSystem={setDCTariffSystem}
        />
        {DCTariffSystem === 'basic' && (
          <>
            <BasicCostSettings
              currentCostSettings={dcCostSettings}
              currency={formValues.currency}
              setCurrentCostSettings={(e) => {
                // I know this is not great, sorry, I'll refactor it later
                const newSettings = {
                  ...acCostSettings,
                  ...e,
                };
                setDcCostSettings(newSettings);
              }}
            />
          </>
        )}

        {DCTariffSystem === 'scheduled' && (
          <>
            <ScheduledCostSettings
              currency={formValues.currency}
              costSettings={dcCostSettings}
              onChange={(next) => {
                const newSettings = {
                  ...acCostSettings,
                  ...next,
                };
                setDcCostSettings(newSettings);
              }}
            />
            <ActionButton
              primary
              compact
              floated={'left'}
              onClick={() => {
                const components = dcCostSettings.components ?? [];
                components.push({
                  costSettings: {
                    pricePerKwh: 0,
                    pricePerSession: 0,
                    pricePerHour: 0,
                    pricePerIdleMinute: 0,
                    idleGracePeriodMinutes: defaultIdleGracePeriodMinutes,
                  },
                  restrictions: {},
                });

                setDcCostSettings({
                  ...dcCostSettings,
                  components: components,
                });
              }}>
              <Icon name={'plus'} />{' '}
              {t(
                'advancedTariff.addScheduledTariff',
                'Add a scheduled tariff system'
              )}
            </ActionButton>
          </>
        )}

        {DCTariffSystem === 'dynamic' && (
          <DynamicCostSettings
            currentCostSettings={dcCostSettings}
            currency={formValues.currency}
            setCurrentCostSettings={(e) => {
              const newSettings = {
                ...acCostSettings,
                ...e,
              };
              setDcCostSettings(newSettings);
            }}
          />
        )}

        {advancedTariffFeatures.idleFee && (
          <div style={{ marginTop: '1rem' }}>
            <PricePerIdleMinute
              enableIdleFee={enableIdleFeeDC}
              setEnableIdleFee={(e) => {
                setEnableIdleFeeDC(e);
                if (!e) {
                  const newSettings = {
                    ...dcCostSettings,
                    pricePerIdleMinute: undefined,
                    idleGracePeriodMinutes: undefined,
                  };
                  setDcCostSettings(newSettings);
                }
              }}
              pricePerIdleMinute={dcCostSettings?.pricePerIdleMinute ?? 0}
              setPricePerIdleMinute={(newPrice) => {
                const newSettings = {
                  ...dcCostSettings,
                  ...(newPrice > 0 && !dcCostSettings.idleGracePeriodMinutes
                    ? { idleGracePeriodMinutes: defaultIdleGracePeriodMinutes }
                    : undefined),
                  pricePerIdleMinute: newPrice,
                };
                setDcCostSettings(newSettings);
                setEnableIdleFeeDC(true);
              }}
              idleGracePeriodMinutes={
                dcCostSettings?.idleGracePeriodMinutes ?? 0
              }
              setIdleGracePeriodMinutes={(newIdleMinutes) => {
                const newSettings = {
                  ...dcCostSettings,
                  idleGracePeriodMinutes: newIdleMinutes,
                };
                setDcCostSettings(newSettings);
              }}
              currency={formValues.currency}
            />
          </div>
        )}
      </div>
    </>
  );
}

function getTariffSystem(profile: TariffProfile): TariffSystem {
  if (
    profile.costSettings?.dc?.enableEnergyMarketTracking &&
    profile.costSettings?.ac?.enableEnergyMarketTracking
  ) {
    return 'dynamic';
  }

  if (
    profile.costSettings?.ac?.components?.length ||
    profile.costSettings?.dc?.components?.length
  ) {
    return 'scheduled';
  }

  return 'basic';
}

function BasicCostSettings({
  currentCostSettings,
  currency,
  setCurrentCostSettings,
  editMode = false,
}: BasicCostSettingsProps) {
  const [isEditing, setIsEditing] = useState(editMode);

  if (isEditing) {
    return (
      <BasicCostSettingsForm
        currentCostSettings={currentCostSettings}
        currency={currency}
        setCurrentCostSettings={setCurrentCostSettings}
      />
    );
  }

  return (
    <BasicCostSettingsPreview
      currentCostSettings={currentCostSettings}
      currency={currency}
      setIsEditing={() => {
        setIsEditing(true);
      }}
    />
  );
}

function BasicCostSettingsPreview({
  currentCostSettings,
  currency,
  setIsEditing,
}: BasicCostSettingsPreviewProps) {
  const { t } = useTranslation();

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            {t('advancedTariff.basicTariff', 'Basic Tariff')}
          </Grid.Column>
          <Grid.Column>
            <ActionButton primary compact onClick={() => setIsEditing()}>
              <Icon name={'edit'} />
              {t('advancedTariff.edit', 'Edit')}
            </ActionButton>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <List>
              <List.Item>
                <PriceText
                  amount={currentCostSettings.pricePerKwh}
                  currency={currency}
                  label={t('advancedTariff.perKwh', 'per kWh')}
                />
              </List.Item>
              <List.Item>
                <PriceText
                  amount={currentCostSettings.pricePerSession}
                  currency={currency}
                  label={t('advancedTariff.perSession', 'per session')}
                />
              </List.Item>
              <List.Item>
                <PriceText
                  amount={currentCostSettings.pricePerHour}
                  currency={currency}
                  label={t('advancedTariff.perHour', 'per hour')}
                />
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}

function BasicCostSettingsForm({
  currentCostSettings,
  currency,
  setCurrentCostSettings,
}: BasicCostSettingsFormProps) {
  const { t } = useTranslation();

  const [priceState, setPriceState] = useState({
    pricePerKwh: currentCostSettings.pricePerKwh.toString(),
    pricePerSession: currentCostSettings.pricePerSession.toString(),
    pricePerHour: currentCostSettings.pricePerHour.toString(),
  });

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.HeaderCell>
            {t('common.pricePerKwh', 'Price per kWh')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('common.pricePerSession', 'Price per Session')}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {t('common.pricePerHour', 'Price per Hour')}
          </Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Input
                style={{ marginBottom: '4px' }}
                label={currencyToSymbol(currency)}
                fluid
                value={priceState.pricePerKwh}
                onChange={(e, { value }) => {
                  setPriceState({ ...priceState, pricePerKwh: value });

                  if (value.endsWith('.') || value.endsWith(',')) {
                    return;
                  }

                  setCurrentCostSettings({
                    ...currentCostSettings,
                    pricePerKwh: +value,
                  });
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                style={{ marginBottom: '4px' }}
                label={currencyToSymbol(currency)}
                fluid
                value={priceState.pricePerSession}
                onChange={(e, { value }) => {
                  setPriceState({ ...priceState, pricePerSession: value });

                  if (value.endsWith('.') || value.endsWith(',')) {
                    return;
                  }

                  setCurrentCostSettings({
                    ...currentCostSettings,
                    pricePerSession: +value,
                  });
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Input
                style={{ marginBottom: '4px' }}
                label={currencyToSymbol(currency)}
                fluid
                value={priceState.pricePerHour}
                onChange={(e, { value }) => {
                  setPriceState({ ...priceState, pricePerHour: value });

                  if (value.endsWith('.') || value.endsWith(',')) {
                    return;
                  }
                  setCurrentCostSettings({
                    ...currentCostSettings,
                    pricePerHour: +value,
                  });
                }}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

function DynamicCostSettings({
  currentCostSettings,
  currency,
  setCurrentCostSettings,
}: DynamicCostSettingsProps) {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const disablePricePerSession = !advancedTariffFeatures.sessionFee;
  const disablePricePerHour = !advancedTariffFeatures.timeFee;

  const [pricePerKwh, setPricePerKwh] = useState(
    currentCostSettings?.pricePerKwh
  );
  const [pricePerSession, setPricePerSession] = useState(
    currentCostSettings?.pricePerSession
  );
  const [pricePerHour, setPricePerHour] = useState(
    currentCostSettings?.pricePerHour
  );

  const [surchargePerKwh, setSurchargePerKwh] = useState(
    currentCostSettings?.surchargePerKwh || 0
  );

  useEffect(() => {
    setCurrentCostSettings?.({
      ...currentCostSettings,
      pricePerKwh,
      pricePerSession,
      pricePerHour,
      surchargePerKwh,
      enableEnergyMarketTracking: true,
    });
  }, [pricePerKwh, pricePerSession, pricePerHour, surchargePerKwh]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <CurrentMarketPrices />

          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>
                {t(
                  'advancedTariff.dynamicPriceSurchargePerKwh',
                  'Surcharge per kWh'
                )}
              </label>
              <PriceInput
                value={surchargePerKwh}
                onChange={setSurchargePerKwh}
                currency={currency}
              />
            </Form.Field>

            <Form.Field>
              <label>
                {t('advancedTariff.pricePerSession', 'Price per Session')}
              </label>
              <PriceInput
                value={pricePerSession}
                onChange={setPricePerSession}
                currency={currency}
                disabled={disablePricePerSession}
              />
            </Form.Field>

            <Form.Field>
              <label>
                {t('advancedTariff.pricePerHour', 'Price per Hour')}
              </label>
              <PriceInput
                value={pricePerHour}
                onChange={setPricePerHour}
                currency={currency}
                disabled={disablePricePerHour}
              />
              <i style={{ fontSize: 12, float: 'left', marginTop: 6 }}>
                {t(
                  'advancedTariff.timeCapMessage',
                  '*Maximum cost may be applied per session according to roaming contracts'
                )}
              </i>
            </Form.Field>
          </Form.Group>

          <br />
          <Header as={'h4'} style={{ marginTop: 0 }}>
            {t('advancedTariff.backupTariff', 'Backup Tariff')}
          </Header>
          <p>
            {t(
              'advancedTariff.backupTariffDescription',
              'Set your backup price in case the current energy market price cannot be retrieved. Note that the surcharge price from the dynamic tariff will still be applied.'
            )}
          </p>
          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>{t('advancedTariff.pricePerKwh', 'Price per kWh')}</label>
              <PriceInput
                value={pricePerKwh}
                onChange={setPricePerKwh}
                currency={currency}
              />
              <i style={{ float: 'left', marginTop: 6 }}>
                {formatCurrency(pricePerKwh + surchargePerKwh, {
                  currency,
                  exact: true,
                })}{' '}
                {t(
                  'advancedTariff.pricePerKwhIncludingSurcharge',
                  'per kWh including surcharge'
                )}
              </i>
            </Form.Field>
            <Form.Field />
            <Form.Field />
          </Form.Group>

          <Message
            info
            content={t(
              'advancedTariff.accessPolicyMessage',
              'Note the hour tariff and session tariff can only be used for public locations, where the reimbursement is paid out to a business entity.'
            )}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function ScheduledCostSettings({
  costSettings,
  onChange,
  currency,
}: ScheduledCostSettingsProps) {
  const [isMarketTrackingEnabled] =
    useEnableEnergyMarketTrackingState(costSettings);

  costSettings.components = costSettings.components ?? [];

  const [currentCostSettings, setCurrentCostSettings] = useState(costSettings);

  return (
    <>
      <BasicCostSettings
        currentCostSettings={currentCostSettings}
        currency={currency}
        setCurrentCostSettings={(e) => {
          // I know this is not great, sorry, I'll refactor it later
          const newSettings = {
            ...currentCostSettings,
            ...e,
          };
          setCurrentCostSettings(newSettings);
          onChange(newSettings);
        }}
        editMode={true}
      />
      {currentCostSettings.components!.map((component, index) => (
        <>
          <ScheduledCostCard
            component={component}
            index={index}
            currency={currency}
            isMarketTrackingEnabled={isMarketTrackingEnabled}
            requestDelete={(index) => {
              currentCostSettings.components?.splice(index, 1);
              setCurrentCostSettings(currentCostSettings);
              onChange?.(currentCostSettings);
            }}
            onSave={(index, component) => {
              currentCostSettings.components![index] = component;
              setCurrentCostSettings(currentCostSettings);
              onChange?.(currentCostSettings);
            }}
          />
          <Divider />
        </>
      ))}
    </>
  );
}

function ScheduledCostCard({
  component,
  index,
  isMarketTrackingEnabled,
  currency,
  requestDelete,
  onSave,
}: ScheduledCostCardProps) {
  const advancedTariffFeatures = useAdvancedTariffFeatures();
  const { t } = useTranslation();
  const energyTariffContext = useEnergyMarketPriceContext();

  const [isEditing, setIsEditing] = useState(false);

  const scheduledTariffName = t(
    'advancedTariff.scheduledTariff',
    'Scheduled Tariff'
  );
  const editScheduledTariffName = t(
    'advancedTariff.editScheduleTariff',
    'Scheduled Tariff Setup'
  );

  const [restrictions, setRestrictions] =
    useState<TariffProfileCurrentCostSettingsComponentRestrictions>(
      component.restrictions
    );

  const [costSettings, setCostSettings] =
    useState<TariffProfileCostSettingsFields>(component.costSettings);

  if (isEditing) {
    return (
      <>
        <BasicCostSettings
          currentCostSettings={costSettings}
          currency={currency}
          setCurrentCostSettings={(e) => {
            // I know this is not great, sorry, I'll refactor it later
            const newSettings = {
              ...costSettings,
              ...e,
            };
            setCostSettings(newSettings);
          }}
          editMode={true}
        />
        <div></div>
        <TariffProfileRestrictionsForm
          value={restrictions}
          onChange={(next) => {
            setRestrictions(next);
          }}
          disabled={false}
        />

        <ActionButton
          onClick={() => {
            onSave(index, { costSettings, restrictions });
            setIsEditing(false);
          }}
          primary
          style={{ padding: 0, textTransform: 'uppercase' }}>
          {t('common.save', 'Save')}
        </ActionButton>

        <ActionButton
          onClick={() => {
            setRestrictions(component.restrictions);
            setIsEditing(false);
          }}
          primary
          style={{ padding: 0, textTransform: 'uppercase' }}>
          {t('common.cancel', 'Cancel')}
        </ActionButton>
      </>
    );
  }

  return (
    <>
      <Grid>
        <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Header as={'h4'} style={{ marginTop: '6px' }}>
              {isEditing ? editScheduledTariffName : scheduledTariffName}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign={'right'}>
            <ActionButton
              primary
              compact
              onClick={() => setIsEditing(!isEditing)}>
              {!isEditing && (
                <>
                  <Icon name={'edit'} />
                  {t('advancedTariff.edit', 'Edit')}
                </>
              )}
              {isEditing && (
                <>
                  <Icon name={'minimize'} />
                  {t('advancedTariff.minimize', 'Minimize')}
                </>
              )}
            </ActionButton>
            <ActionButton primary compact onClick={() => requestDelete(index)}>
              <Icon name={'trash'} />
              {t('advancedTariff.remove', 'Remove')}
            </ActionButton>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          {!isMarketTrackingEnabled && (
            <Grid.Column>
              <SummaryLabel>{t('advancedTariff.price', 'Price')}</SummaryLabel>
            </Grid.Column>
          )}
          {isMarketTrackingEnabled && (
            <Grid.Column>
              <SummaryLabel>{t('advancedTariff.price', 'Price')}</SummaryLabel>
            </Grid.Column>
          )}
          {isMarketTrackingEnabled && (
            <Grid.Column>
              <SummaryLabel>
                {t('advancedTariff.backupPrices', 'Backup Price')}
              </SummaryLabel>
            </Grid.Column>
          )}
          {advancedTariffFeatures.idleFee && (
            <Grid.Column>
              <SummaryLabel>
                {t('advancedTariff.idleFee', 'Idle Fee')}
              </SummaryLabel>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row columns={4}>
          {!isMarketTrackingEnabled && (
            <Grid.Column>
              <List>
                <List.Item>
                  <PriceText
                    amount={component.costSettings?.pricePerKwh}
                    currency={currency}
                    label={t('advancedTariff.perKwh', 'per kWh')}
                  />
                </List.Item>
                <List.Item>
                  <PriceText
                    amount={component.costSettings?.pricePerSession}
                    currency={currency}
                    label={t('advancedTariff.perSession', 'per session')}
                  />
                </List.Item>
                <List.Item>
                  <PriceText
                    amount={component.costSettings?.pricePerHour}
                    currency={currency}
                    label={t('advancedTariff.perHour', 'per hour')}
                  />
                </List.Item>
              </List>
            </Grid.Column>
          )}
          {isMarketTrackingEnabled && (
            <Grid.Column>
              <List>
                <List.Item>
                  <PriceText
                    amount={
                      energyTariffContext.activeTariff?.costSettings
                        .pricePerKwh ?? 0
                    }
                    currency={
                      energyTariffContext.activeTariff?.costSettings.currency ??
                      'EUR'
                    }
                    label={t('advancedTariff.perKwh', 'per kWh')}
                  />{' '}
                  <span>
                    <Icon name={'chart-line'} />
                  </span>
                </List.Item>
                <List.Item>
                  <PriceText
                    // amount={component.costSettings?.surchargePerKwh ?? 0}
                    amount={0}
                    currency={currency}
                    label={t(
                      'advancedTariff.surchargePerKwh',
                      'surcharge per kWh'
                    )}
                  />
                </List.Item>

                <List.Item>
                  <PriceText
                    amount={component.costSettings?.pricePerSession}
                    currency={currency}
                    label={t('advancedTariff.perSession', 'per session')}
                  />
                </List.Item>
                <List.Item>
                  <PriceText
                    amount={component.costSettings?.pricePerHour}
                    currency={currency}
                    label={t('advancedTariff.perHour', 'per hour')}
                  />
                </List.Item>
              </List>
            </Grid.Column>
          )}
          {isMarketTrackingEnabled && (
            <Grid.Column>
              <List>
                <List.Item>
                  <PriceText
                    amount={component.costSettings.pricePerKwh}
                    currency={currency}
                    label={t('advancedTariff.backupPricePerKwh', 'per kWh')}
                  />
                </List.Item>
              </List>
            </Grid.Column>
          )}
          {advancedTariffFeatures.idleFee && (
            <Grid.Column>
              <List>
                {component.costSettings.pricePerIdleMinute &&
                  component.costSettings.pricePerIdleMinute > 0 && (
                    <>
                      <List.Item>
                        <PriceText
                          amount={component.costSettings.pricePerIdleMinute}
                          currency={currency}
                          label={t('advancedTariff.perMinute', 'per minute')}
                        />
                      </List.Item>
                      <List.Item>
                        <span style={{ fontWeight: 'bold' }}>
                          {component.costSettings.idleGracePeriodMinutes ||
                            defaultIdleGracePeriodMinutes}{' '}
                          min
                        </span>{' '}
                        <span style={{ textTransform: 'lowercase' }}>
                          {t(
                            'advancedTariff.idleFeeWaitTime',
                            'wait time before idle fee'
                          )}
                        </span>
                      </List.Item>
                    </>
                  )}
                {!component.costSettings.pricePerIdleMinute && (
                  <List.Item>
                    <i>{t('advancedTariff.none', 'None')}</i>
                  </List.Item>
                )}
              </List>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SummaryLabel>
              {t('advancedTariff.timeOfDay', 'Time of Day')}
            </SummaryLabel>
          </Grid.Column>
          {advancedTariffFeatures.daysOfWeek && (
            <Grid.Column>
              <SummaryLabel>
                {t('advancedTariff.dayOfWeek', 'Day of Week')}
              </SummaryLabel>
            </Grid.Column>
          )}
          {advancedTariffFeatures.dateRange && (
            <Grid.Column>
              <SummaryLabel>
                {t('advancedTariff.dateRange', 'Date Range')}
              </SummaryLabel>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column key={'restricts-' + index}>
            <List>
              {component.restrictions?.startTime && (
                <List.Item>
                  <TimeObjectText
                    value={component.restrictions?.startTime}
                    label={t('advancedTariff.startTime', 'Start Time')}
                  />
                </List.Item>
              )}
              {component.restrictions?.endTime && (
                <List.Item>
                  <TimeObjectText
                    value={component.restrictions?.endTime}
                    label={t('advancedTariff.endTime', 'End Time')}
                  />
                </List.Item>
              )}
              {!component.restrictions?.startTime &&
                !component.restrictions?.endTime && (
                  <List.Item>
                    <strong>{t('advancedTariff.allDay', 'All Day')}</strong>
                  </List.Item>
                )}
            </List>
          </Grid.Column>
          {advancedTariffFeatures.daysOfWeek && (
            <Grid.Column>
              <List>
                <List.Item>
                  <DaysOfWeekText
                    value={component.restrictions?.daysOfWeek ?? []}
                  />
                </List.Item>
              </List>
            </Grid.Column>
          )}
          {advancedTariffFeatures.dateRange && (
            <Grid.Column>
              <List>
                {(component.restrictions?.startDate ||
                  component.restrictions?.endDate) && (
                  <>
                    <List.Item>
                      <DateText
                        value={component.restrictions?.startDate!}
                        label={t('advancedTariff.startDate', 'Start Date')}
                      />
                    </List.Item>
                    <List.Item>
                      <DateText
                        value={component.restrictions?.endDate!}
                        label={t('advancedTariff.endDate', 'End Date')}
                      />
                    </List.Item>
                  </>
                )}
                {!component.restrictions?.startDate &&
                  !component.restrictions?.endDate && (
                    <List.Item>
                      <strong>{t('advancedTariff.always', 'Always')}</strong>
                    </List.Item>
                  )}
              </List>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </>
  );
}

function DateText({ label, value }: DateTextProps) {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        {dateObjToStringOmitYearIfCurrent(value)}
      </span>
      &nbsp;
      {label}
    </>
  );
}

function dateObjToStringOmitYearIfCurrent(dateObj: CalendarDateObj) {
  const date = new Date(dateObj.year, dateObj.month, dateObj.day);
  const now = new Date();
  let formatted = formatDate(date);
  if (now.getFullYear() === date.getFullYear()) {
    formatted = formatted.replace(/[0-9]{4}$/, '');
  }
  return formatted;
}

function DaysOfWeekText({ value }: DaysOfWeekTextProps) {
  const { t } = useTranslation();
  const text = useMemo(() => {
    const daySet = new Set(value || []);
    if (!daySet?.size || daySet?.size === 7) {
      return t('advancedTariffs.everyDay', 'Every day');
    }
    if (daySet.size === 5 && !daySet.has('saturday') && !daySet.has('sunday')) {
      return t('advancedTariffs.weekdays', 'Weekdays');
    }
    if (daySet.size === 2 && daySet.has('saturday') && daySet.has('sunday')) {
      return t('advancedTariffs.weekends', 'Weekends');
    }
    const dayTranslator = (day: WeekDay) =>
      t(`advancedTariff.{{day}}`, day, { day });
    let dayFormatter = (day: string) =>
      `${day.charAt(0).toUpperCase()}${day.slice(1)}`;
    if (daySet.size > 2) {
      dayFormatter = (day: string) =>
        `${day.charAt(0).toUpperCase()}${day.slice(1, 3)}`;
    }
    return value
      .sort(dayOfWeekComparator)
      .map((day) => {
        return dayFormatter(dayTranslator(day));
      })
      .join(', ');
  }, [value, t]);
  return <strong>{text}</strong>;
}

const dayOrder = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

function dayOfWeekComparator(day1, day2) {
  day1 = day1.toLowerCase();
  day2 = day2.toLowerCase();
  if (dayOrder[day1] < dayOrder[day2]) {
    return -1;
  }
  if (dayOrder[day1] > dayOrder[day2]) {
    return 1;
  }
  return 0;
}

function TariffProfileRestrictionsForm({
  value: restrictions = {},
  disabled,
  onChange,
}: RestrictionsFormProps) {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const [enableTimeOfDay, setEnableTimeOfDay] = useState(
    !!(restrictions.startTime ?? restrictions.endTime)
  );
  const [startTime, setStartTime] = useState(
    restrictions.startTime ?? { hour: 0, minute: 0 }
  );
  const [endTime, setEndTime] = useState(
    restrictions.endTime ?? { hour: 11, minute: 59 }
  );

  const [enableDateRange, setEnableDateRange] = useState(
    !!(restrictions.startDate ?? restrictions.endDate)
  );
  const [startDate, setStartDate] = useState(
    restrictions.startDate ?? { day: 0, month: 0, year: 0 }
  );
  const [endDate, setEndDate] = useState(
    restrictions.endDate ?? { day: 0, month: 0, year: 0 }
  );

  const [enableDaysOfWeek, setEnableDaysOfWeek] = useState(
    !!restrictions.daysOfWeek?.length
  );
  const [daysOfWeek, setDaysOfWeek] = useState(restrictions.daysOfWeek);

  useEffect(() => {
    const nextState: {
      startTime?: ClockTimeObj;
      endTime?: ClockTimeObj;
      startDate?: CalendarDateObj;
      endDate?: CalendarDateObj;
      daysOfWeek?: WeekDay[];
    } = {};
    if (enableTimeOfDay) {
      nextState.startTime = startTime;
      nextState.endTime = endTime;
    }
    if (enableDaysOfWeek) {
      nextState.daysOfWeek = daysOfWeek;
    }
    if (enableDateRange) {
      nextState.startDate = startDate;
      nextState.endDate = endDate;
    }
    onChange?.(nextState);
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    daysOfWeek,
    enableTimeOfDay,
    enableDaysOfWeek,
    enableDateRange,
  ]);

  return (
    <>
      <Form.Checkbox
        toggle
        label={
          <label style={{ fontWeight: 'bold' }}>
            {t('advancedTariff.timeOfDay', 'Time of Day')}
          </label>
        }
        checked={enableTimeOfDay}
        onChange={(e, { checked }) => {
          setEnableTimeOfDay(checked ?? false);
        }}
        disabled={disabled}
      />
      <TimeOfDayForm
        restrictions={restrictions}
        disabled={disabled || !enableTimeOfDay}
        startTime={startTime}
        onStartTimeChange={setStartTime}
        endTime={endTime}
        onEndTimeChange={setEndTime}
      />

      {advancedTariffFeatures.daysOfWeek && (
        <>
          <br />
          <Form.Checkbox
            toggle
            label={
              <label style={{ fontWeight: 'bold' }}>
                {t('advancedTariff.daysOfWeek', 'Days of Week')}
              </label>
            }
            checked={enableDaysOfWeek}
            onChange={(e, { checked }) => {
              if (!checked && onChange) {
                onChange({ ...restrictions, daysOfWeek: [] });
              }
              setEnableDaysOfWeek(!!checked);
            }}
            disabled={disabled}
          />
          <DaysOfWeekInput
            allIfUnset
            disabled={disabled || !enableDaysOfWeek}
            value={daysOfWeek}
            onChange={(next: any) => setDaysOfWeek(next)}
          />
        </>
      )}

      {advancedTariffFeatures.dateRange && (
        <>
          <br />

          <Form.Checkbox
            toggle
            label={
              <label style={{ fontWeight: 'bold' }}>
                {t('advancedTariff.dateRange', 'Date Range')}
              </label>
            }
            checked={enableDateRange}
            onChange={(e, { checked }) => {
              setEnableDateRange(checked ?? false);
            }}
            disabled={disabled}
          />
          <DateRange
            restrictions={restrictions}
            disabled={disabled || !enableDateRange}
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
          />
        </>
      )}
    </>
  );
}

function TimeOfDayForm({
  disabled,
  startTime,
  onStartTimeChange,
  endTime,
  onEndTimeChange,
}: TimeOfDayFormProps) {
  const { t } = useTranslation();
  const validateEndTime = useCallback(
    (nextEndTime: ClockTimeObj) => {
      if (!startTime) {
        return;
      }
      if (startTime && !nextEndTime) {
        throw new Error(
          t(
            'advancedTariff.errorEndTimeRequired',
            'end time must be set if start time is set'
          )
        );
      }
      if (nextEndTime.hour === 0 && nextEndTime.minute === 0) {
        return;
      }
      if (startTime.hour === 0 && startTime.minute === 0) {
        return;
      }
      if (
        nextEndTime.hour < startTime?.hour ||
        (nextEndTime.hour === startTime?.hour &&
          nextEndTime.minute <= startTime.minute)
      ) {
        throw new Error('end time must be after the start time');
      }
    },
    [startTime, t]
  );

  const handleStartTimeChange = (nextStartTime: ClockTimeObj) => {
    onStartTimeChange(nextStartTime);
    if (nextStartTime && !endTime) {
      onEndTimeChange({ hour: 0, minute: 0 });
    }
  };
  const handleEndTimeChange = (nextEndTime: ClockTimeObj) => {
    onEndTimeChange(nextEndTime);
    if (nextEndTime && !startTime) {
      onStartTimeChange({ hour: 0, minute: 0 });
    }
  };

  return (
    <>
      <Form.Group widths={'equal'}>
        <TimeObjectInput
          disabled={disabled ?? false}
          label={t('advancedTariff.startTime', 'Start Time')}
          value={startTime}
          onChange={handleStartTimeChange}
        />
        <TimeObjectInput
          disabled={disabled ?? false}
          label={t('advancedTariff.endTime', 'End Time')}
          value={endTime}
          onChange={handleEndTimeChange}
          validate={validateEndTime}
        />
        <Form.Field />
      </Form.Group>
    </>
  );
}

function TimeObjectText({ label, value }: any) {
  const hour = value.hour || 0;
  const minute = value.minute || 0;
  const amPm = hour < 12 ? 'AM' : 'PM';
  let displayHour = hour % 12;
  if (displayHour === 0) displayHour = 12;
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        {displayHour.toString().padStart(2, '0')}:
        {minute.toString().padStart(2, '0')} {amPm}
      </span>
      &nbsp;
      {label}
    </>
  );
}

function isDateUnset(dateObj: CalendarDateObj | undefined | null) {
  return !dateObj?.year && !dateObj?.month && !dateObj?.day;
}

function DateRange({
  disabled,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}: DateRangeProps) {
  const { t } = useTranslation();
  const validateEndDate = useCallback(
    (nextEndDate) => {
      if (isDateUnset(startDate) || isDateUnset(nextEndDate)) {
        return;
      }
      if (dateObjToString(startDate) >= dateObjToString(nextEndDate)) {
        throw new Error(
          t(
            'advancedTariff.errorEndDateBeforeStart',
            'end date must be after the start date'
          )
        );
      }
    },
    [startDate]
  );

  return (
    <>
      <Form.Group widths={'equal'}>
        <DateObjectInput
          label={t('advancedTariff.startDate', 'Start Date')}
          disabled={disabled}
          clearable
          type={'date'}
          control={'input'}
          value={startDate}
          onChange={(next) => onStartDateChange(next)}
        />
        <DateObjectInput
          label={t('advancedTariff.endDate', 'End Date')}
          disabled={disabled}
          clearable
          type={'date'}
          control={'input'}
          value={endDate}
          onChange={(next) => onEndDateChange(next)}
          validate={validateEndDate}
        />
        <Form.Field />
      </Form.Group>
    </>
  );
}
