// This components builds on `Sidebar` component to fit purpose of
// portal layout navigation with secondary navigation.

import React from 'react';
import { throttle } from 'lodash-es';
import { PropTypes } from 'prop-types';

import bem from 'helpers/bem';

import PageHead from './PageHead';
import MobileSidebar from './MobileSidebar';
import Container from './Container';
import Sidebar from './Sidebar';
import Content from './Content';
import MobileHead from './MobileHead';
import Trigger from './Trigger';
import Link from './Link';
// import Menu from './Menu';
// import Link from './Link';
// import Item from './Item';
// import Header from './Header';
// import Mobile from './Mobile';
// import Content from './Content';
// import Trigger from './Trigger';
// import Divider from './Divider';
// import Accordion from './Accordion';

import './portal-nav.less';
import { isNotProd } from '../environmentBanner';

const BREAKPOINTS = {
  always: 0,
  tablet: 768,
  desktop: 1128,
};

const Context = React.createContext();

PageHead.contextType = Context;
MobileSidebar.contextType = Context;
Container.contextType = Context;
Sidebar.contextType = Context;
Content.contextType = Context;
MobileHead.contextType = Context;
Trigger.contextType = Context;
Link.contextType = Context;
// Menu.contextType = Context;
// Link.contextType = Context;
// Header.contextType = Context;
// Mobile.contextType = Context;
// Content.contextType = Context;
// Trigger.contextType = Context;
// Divider.contextType = Context;
// Accordion.contextType = Context;

class PortalNav extends React.Component {
  static PageHead = PageHead;
  static MobileSidebar = MobileSidebar;
  static Container = Container;
  static Sidebar = Sidebar;
  static Content = Content;
  static MobileHead = MobileHead;
  static Trigger = Trigger;
  static Link = Link;
  // static Menu = Menu;
  // static Link = Link;
  // static Item = Item;
  // static Header = Header;
  // static Mobile = Mobile;
  // static Content = Content;
  // static Trigger = Trigger;
  // static Divider = Divider;
  // static Accordion = Accordion;

  constructor(props) {
    super(props);
    this.state = {
      offscreen: this.isOffscreen(),
      open: false,
    };
  }

  // Lifecycle

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  // Events

  onResize = throttle(() => {
    const offscreen = this.isOffscreen();
    this.setState({
      offscreen,
      open: offscreen && this.state.open,
    });
  }, 200);

  toggle = (open = !this.state.open) => {
    this.setState({
      open,
    });
  };

  close = () => {
    this.toggle(false);
  };

  // Other

  isOffscreen() {
    return window.innerWidth <= this.resolveBreakpoint();
  }

  resolveBreakpoint() {
    const { open } = this.props;
    return BREAKPOINTS[open] || open;
  }

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, toggle: this.toggle, close: this.close }}>
        <div
          className={`${this.getBlockClass()} ${
            isNotProd() ? 'with-env-banner' : ''
          }`}>
          {this.props.children}
        </div>
        {this.renderDimmer()}
      </Context.Provider>
    );
  }

  renderDimmer() {
    const { dimmer } = this.props;
    const { offscreen, open } = this.state;
    if (dimmer && offscreen) {
      return (
        <div
          className={this.getElementClass('dimmer', open ? 'open' : null)}
          onClick={this.close}
        />
      );
    }
  }
}

PortalNav.propTypes = {
  open: PropTypes.oneOfType([
    PropTypes.oneOf(['desktop', 'tablet', 'always']),
    PropTypes.number,
  ]),
  dimmer: PropTypes.bool,
};

PortalNav.defaultProps = {
  open: 'tablet',
  dimmer: true,
};

export default bem(PortalNav);
