import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';

export function useSepaUrl() {
  const { provider } = useUser();
  const { i18n } = useTranslation();
  return (
    provider['sepaUrl'] ||
    (i18n.language === 'nl'
      ? 'https://www.e-flux.nl/automatische-incasso'
      : 'https://www.e-flux.nl/en/recurrent-sepa-mandate/')
  );
}
