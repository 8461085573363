import React from 'react';
import { Loader, Icon, Dropdown, Header } from 'semantic';
import { request } from 'utils/api';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BillingPlan } from 'screens/Auth/Signup/Context';

const PlansContainer = styled.div`
  .lines {
    width: 80%;
    margin-top: 10px;
    font-weight: bold;
    list-style-type: none;
    font-size: 15px;
    line-height: 30px;
    .icon {
      padding-right: 10px;
      color: ${(props) => props.theme.primaryColor} !important;
    }
  }
  p {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
  }
  p.warning {
    font-weight: normal;
  }
`;

interface Props {
  billingPlanId?: string;
  restrictBillingPlanId?: string;
  onSelect(plan?: BillingPlan): void;
  onlyPrimary?: boolean;
  currency?: string;
}

function getLanguageCode(language: string, plans: BillingPlan[]) {
  // confirms that we got a plan with given language otherwise default to english
  if (
    !plans.find((c) => {
      return c.details[language].name;
    })
  ) {
    language = 'en';
  }

  switch (language) {
    case 'nl':
    case 'fr':
    case 'de':
    case 'en':
    case 'it':
    case 'es':
      return language;
    default:
      return 'en';
  }
}

const PlanSelector: React.FC<Props> = ({
  billingPlanId,
  restrictBillingPlanId,
  onSelect,
  currency,
  onlyPrimary,
}) => {
  const { i18n, t } = useTranslation();
  const [current, setCurrent] = React.useState<BillingPlan>();
  const [plans, setPlans] = React.useState<BillingPlan[]>([]);
  const [loading, setLoading] = React.useState(true);

  function fetchBillingPlans() {
    return request({
      method: 'POST',
      path: '/1/billing-plans/search',
      body: {
        providerContext: 'msp',
        ...(currency ? { currency } : {}),
      },
    }).then(({ data }: { data: BillingPlan[] }) => {
      const publicPlans = data
        .filter((c) => c.isPublic)
        .sort((a, b) => b.priority - a.priority);

      let billingPlans = publicPlans;
      if (onlyPrimary && !restrictBillingPlanId) {
        billingPlans = publicPlans.filter((c) => c.isPrimary);
      }

      const defaultSelected =
        (billingPlanId && billingPlans.find((c) => c.id === billingPlanId)) ||
        billingPlans[0] ||
        publicPlans[0];

      if (restrictBillingPlanId) {
        const selected = data.find((b) => b.id === restrictBillingPlanId);
        if (selected) {
          setPlans([selected]);
          setCurrent(selected);
          onSelect(selected);
        }
      } else {
        setPlans(billingPlans);
        setCurrent(defaultSelected);
        onSelect(defaultSelected);
      }

      setLoading(false);
    });
  }

  React.useEffect(() => {
    fetchBillingPlans();
  }, [currency, restrictBillingPlanId, onlyPrimary]);

  React.useEffect(() => {
    if (billingPlanId) {
      setCurrent(plans.find((c) => c.id === billingPlanId));
    }
  }, [billingPlanId]);

  const languageCode = getLanguageCode(i18n.language, plans);

  const options = plans
    .sort((a, b) => b.priority - a.priority)
    .map((plan) => {
      return {
        selected: current && current.id === plan.id,
        key: plan.id,
        text: plan.details[languageCode].name,
        value: plan.id,
      };
    });

  const features = ((current && current.details[languageCode].features) ||
    []) as string[];
  const description = current && current.details[languageCode].descriptionLong;

  const currentName = current
    ? current?.details?.[languageCode]?.name || current.name
    : t('plans.otherPlans', 'Other Plans');

  return (
    <PlansContainer>
      {loading && (
        <>
          <Loader active />
        </>
      )}
      {current && (
        <>
          <Header as="h3" textAlign="center">
            {description}
          </Header>

          {features.length > 0 && (
            <ul className="lines">
              {features.map((feature) => {
                return (
                  <li key={feature}>
                    <Icon size="large" name="check" />
                    {feature}
                  </li>
                );
              })}
            </ul>
          )}

          {options.length > 0 && (
            <Dropdown
              text={currentName}
              fluid
              selection
              options={options}
              onChange={(e, { value }) =>
                onSelect(plans.find((c) => c.id === value))
              }
            />
          )}
        </>
      )}
      {options.length === 0 && (
        <p className="warning">
          {t(
            'plans.noneAvailable',
            'It looks like there are no plans available available in your selected currency.'
          )}
        </p>
      )}
    </PlansContainer>
  );
};

export default PlanSelector;
