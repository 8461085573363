import { FeatureFlag, Layout, Search, SearchFilters } from 'components';
import { Icon, Segment } from 'semantic';
import { request } from 'utils/api';
import { capitalize, isArray } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchFilter from 'components/search/Filters/Search';
import { useFilterContext } from 'components/search/Context';
import { Account } from 'types/account';
import { Location } from 'types/location';
import Divider from 'components/Sidebar/Divider';
import { FilterMapping } from 'components/search/Provider';
import { DropdownOption } from 'components/search/Filters/DropdownV2';
import { Label } from 'semantic';
import {
  EvseOperationalStatus,
  EvseOperationalStatusType,
} from 'types/evse-operational-status';
import {
  decodeStringForFilter,
  encodeStringForFilter,
  formatDateRangeChip,
  debounce,
} from 'utils/filters-header';

const debounceTimeout = 200;

/**
 * values are derived from the API in its style of naming.
 *
 * @note 2 fields exist here that are not in the spec
 *  1. IN_PROGRESS
 *  2. READY_FOR_ACTIVATION
 * Those were checkboxes in previous design and represent different fields in the API Endpoint
 * But design wanted it in the same dropdown for backward compatibility, so it's now part of this Dropdown.
 * In the network call component it should be remapped it to the proper API request field
 *
 * @see {@link services/api/src/interfaces/domain-models/evse-controller.ts}
 *
 */
const setupStateOptions: DropdownOption[] = [
  {
    text: 'Setup in Progress',
    key: 'IN_PROGRESS',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'IN-PROGRESS',
  },
  {
    text: 'Ready for Activation',
    key: 'READY_FOR_ACTIVATION',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'READY-FOR-ACTIVATION',
  },
  {
    text: 'Attach Entities',
    key: 'ATTACH_ENTITIES',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'ATTACH-ENTITIES',
  },
  {
    text: 'Configure Energy Costs',
    key: 'CONFIGURE_ENERGY_COSTS',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'CONFIGURE-ENERGY-COSTS',
  },
  {
    text: 'Configure Connector Info',
    key: 'CONFIGURE_CONNECTOR_INFO',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'CONFIGURE-CONNECTOR-INFO',
  },
  {
    text: 'Configure Number Of Connectors',
    key: 'CONFIGURE_NUMBER_OF_CONNECTORS',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'CONFIGURE-NUMBER-OF-CONNECTORS',
  },
  {
    text: 'Completed',
    key: 'COMPLETED',
    value: 'COMPLETED',
  },
];

const connectivityStateOptions: DropdownOption[] = [
  {
    text: 'Connected',
    key: 'CONNECTED',
    value: 'connected',
  },
  {
    text: 'Maybe Connected',
    key: 'MAYBE_CONNECTED',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'maybe-connected',
  },
  {
    text: 'Disconnected',
    key: 'DISCONNECTED',
    value: 'disconnected',
  },
  {
    text: 'Access Denied',
    key: 'ACCESS_DENIED',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'access-denied',
  },
  {
    text: 'Pending Connection',
    key: 'PENDING_FIRST_CONNECTION',
    // The dash here is used to avoid SearchProvider _ usage as a list separator
    value: 'pending-first-connection',
  },
];

const OCPPVersions: DropdownOption[] = [
  {
    text: 'OCPP 1.6',
    key: 'ocpp1.6',
    value: 'ocpp1.6',
  },
  {
    text: 'OCPP 2.0',
    key: 'ocpp2.0',
    value: 'ocpp2.0',
  },
  {
    text: 'OCPP 2.0.1',
    key: 'ocpp2.0.1',
    value: 'ocpp2.0.1',
  },
];

type EvseFiterFields =
  | 'createdAt'
  | 'setupStates'
  | 'connectivityStates'
  | 'accountIds'
  | 'locationIds'
  | 'maintenanceAccounts'
  | 'powerType'
  | 'tariffProfiles'
  | 'billingPlans'
  | 'searchPhrase'
  | 'vendors'
  | 'ocppVersions'
  | 'firmwareVersions'
  | 'hasNoAccount'
  | 'labels'
  | 'evseOperationalStatusIds';

export const evseFilterMapping: FilterMapping<EvseFiterFields> = {
  createdAt: {
    name: 'createdAt',
    type: 'date',
    range: true,
  },
  setupStates: {
    name: 'setupStates',
    type: 'string',
    multiple: true,
  },
  connectivityStates: {
    name: 'connectivityStates',
    type: 'string',
    multiple: true,
  },
  accountIds: {
    name: 'accountIds',
    type: 'string',
    multiple: true,
  },
  locationIds: {
    name: 'locationIds',
    type: 'string',
    multiple: true,
  },
  maintenanceAccounts: {
    name: 'maintenanceAccounts',
    type: 'string',
    multiple: true,
  },
  powerType: {
    name: 'powerType',
    type: 'string',
  },
  tariffProfiles: {
    name: 'tariffProfiles',
    type: 'string',
    multiple: true,
  },
  billingPlans: {
    name: 'billingPlans',
    type: 'string',
    multiple: true,
  },
  searchPhrase: {
    name: 'searchPhrase',
    type: 'string',
  },
  hasNoAccount: {
    name: 'hasNoAccount',
    type: 'boolean',
  },
  vendors: {
    name: 'vendors',
    type: 'string',
    multiple: true,
  },
  ocppVersions: {
    name: 'ocppVersions',
    type: 'string',
    multiple: true,
  },
  firmwareVersions: {
    name: 'firmwareVersions',
    type: 'string',
    multiple: true,
  },
  labels: {
    name: 'labels',
    type: 'base64',
    multiple: true,
    splitBy: '||', // using a url encodebale delimiter to ensure non-clashing delmiter with the label name
  },
  evseOperationalStatusIds: {
    name: 'evseOperationalStatusIds',
    type: 'string',
    multiple: true,
  },
} as const;

type EVSEAvailableFilters = {
  firmwareVersions: string[];
  vendors: string[];
};

export const EvseListFiltersHeader = () => {
  const { t } = useTranslation();
  const filterContext = useFilterContext<EvseFiterFields>();

  // I'm doing those in 3 render passes to avoid race condition on a single field from the network updates
  const [currentLabels, setCurrentLabels] = useState<any[]>(
    filterContext.filters.labels || []
  );
  const [currentFilterAccounts, setCurrentFilterAccounts] = useState<Account[]>(
    []
  );
  const [currentFilterLocations, setCurrentFilterLocations] = useState<
    Location[]
  >([]);
  const [currentMaintenanceAccounts, setCurrentMaintenanceAccounts] = useState<
    Account[]
  >([]);
  const [currentTariffProfiles, setCurrentTariffProfiles] = useState<any[]>([]);
  const [currentBillingPlans, setCurrentBillingPlans] = useState<any[]>([]);

  const [availableFilters, setAvailableFilters] =
    useState<EVSEAvailableFilters>();

  const [isFiltersEmpty, setIsFiltersEmpty] = useState(true);

  const evsePowerTypeOptions: DropdownOption[] = [
    {
      key: 'ac',
      value: 'AC',
      text: t('evseControllerFilter.powerType.ac', 'AC Only'),
    },
    {
      key: 'dc',
      value: 'DC',
      text: t('evseControllerFilter.powerType.dc', 'DC Only'),
    },
    {
      key: 'acdc',
      value: 'ACDC',
      text: t(
        'evseControllerFilter.powerType.acdc',
        'AC&DC Only (in same station)'
      ),
    },
  ];

  // Fast feedback to show location input per [SAAS-1954]
  const [accountsSelected, setAccountsSelected] = useState<any[]>(
    filterContext.filters.accountIds ?? []
  );

  // Fetch Already selected filters names
  useEffect(() => {
    if (filterContext.filters.accountIds) {
      request<{ data: Account[] }>({
        method: 'POST',
        path: '1/accounts/search',
        body: {
          // This includes current account ids in the list to show their names
          ids: filterContext.filters.accountIds,
        },
      }).then((response) => {
        setCurrentFilterAccounts(response.data);
      });
    } else {
      setCurrentFilterAccounts([]);
    }

    if (filterContext.filters.locationIds) {
      request<{ data: Location[] }>({
        method: 'POST',
        path: '/2/locations/cpo/search',
        body: {
          ids: filterContext.filters.locationIds,
        },
      }).then((response) => {
        setCurrentFilterLocations(response.data);
      });
    } else {
      setCurrentFilterLocations([]);
    }

    if (filterContext.filters.maintenanceAccounts) {
      request<{ data: Account[] }>({
        method: 'POST',
        path: '/1/accounts/search',
        body: {
          ids: filterContext.filters.maintenanceAccounts,
        },
      }).then((response) => {
        setCurrentMaintenanceAccounts(response.data);
      });
    } else {
      setCurrentMaintenanceAccounts([]);
    }

    if (!availableFilters) {
      request<{ data: EVSEAvailableFilters }>({
        method: 'GET',
        path: '/1/evse-controllers/available-filters',
      }).then((response) => {
        setAvailableFilters(response.data);
      });
    }

    if (filterContext.filters.tariffProfiles) {
      request<{ data: any[] }>({
        method: 'POST',
        path: '/1/tariff-profiles/search',
      }).then((response) => {
        setCurrentTariffProfiles(
          response.data.filter((e) =>
            filterContext.filters.tariffProfiles?.includes(e.id)
          )
        );
      });
    } else {
      setCurrentTariffProfiles([]);
    }

    if (filterContext.filters.billingPlans) {
      request<{ data: any[] }>({
        method: 'POST',
        path: '/1/billing-plans/search',
      }).then((response) => {
        setCurrentBillingPlans(
          response.data.filter((e) =>
            filterContext.filters.billingPlans?.includes(e.id)
          )
        );
      });
    } else {
      setCurrentBillingPlans([]);
    }

    setIsFiltersEmpty(Object.keys(filterContext.filters).length === 0);
  }, [filterContext.filters]);

  return (
    <Segment>
      <Layout horizontal spread stackable center>
        <SearchFilters.ModalFilterV2>
          <SearchFilters.DateRange
            label={t('common.createdAt')}
            name={evseFilterMapping.createdAt.name}
          />

          <FeatureFlag feature="operational_statuses_page">
            <SearchFilters.DropdownSearchFilterV2
              label={t(
                'evseControllerFilter.operationalStatus',
                'Operational Status'
              )}
              name={evseFilterMapping.evseOperationalStatusIds.name}
              multiple={true}
              populateOnLoad={true}
              onDataNeeded={async () => {
                const response = await request({
                  method: 'GET',
                  path: '/1/evse-operational-statuses',
                });
                if (!isArray(response?.data)) {
                  return response;
                }

                const data = response.data.map(
                  (status: EvseOperationalStatus) => {
                    if (status.type === EvseOperationalStatusType.System) {
                      return {
                        ...status,
                        name: t(
                          `evseOperationalStatuses.systemName.${status.name}`,
                          capitalize(status.name)
                        ),
                      };
                    }

                    return status;
                  }
                );

                return { ...response, data };
              }}
            />
          </FeatureFlag>

          <SearchFilters.Checkbox
            label={t('evseControllerFilter.hasNoAccount', 'Has No Account')}
            name={evseFilterMapping.hasNoAccount.name}
          />

          <FeatureFlag feature="evsecontroller-labels">
            <SearchFilters.DropdownSearchFilterV2
              name={evseFilterMapping.labels.name}
              label={t('evseControllerFilter.labels', 'Labels')}
              multiple={evseFilterMapping.labels.multiple}
              populateOnLoad={true}
              onDataNeeded={async () => {
                const debouncedFun = debounce(async () => {
                  try {
                    const labels = await request({
                      method: 'GET',
                      path: '/1/evse-controllers/available-labels',
                    }).then((response) => {
                      return {
                        data: response.data.map((label: string) => {
                          return {
                            name: label,
                            id: label,
                          };
                        }),
                      };
                    });

                    // This keeps already selected labels in the list so the name can be shown
                    if (currentFilterAccounts.length > 0) {
                      labels.data.push(...currentLabels);
                    }

                    return labels;
                  } catch (error) {
                    return { data: currentLabels ?? [] };
                  }
                }, debounceTimeout);

                const result = await debouncedFun();

                return result ?? { data: currentLabels };
              }}
            />
          </FeatureFlag>

          <SearchFilters.DropdownFilterV2
            name={evseFilterMapping.setupStates.name}
            label={t('evseControllerFilter.setupState', 'Setup State')}
            options={setupStateOptions}
            multiple={evseFilterMapping.setupStates.multiple ?? false}
          />

          <SearchFilters.DropdownFilterV2
            label={t(
              'evseControllerFilter.connectivityState',
              'Connectivity State'
            )}
            multiple={evseFilterMapping.connectivityStates.multiple ?? false}
            name={evseFilterMapping.connectivityStates.name}
            options={connectivityStateOptions}
          />

          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t('evseControllerFilter.account', 'Account')}
            name={evseFilterMapping.accountIds.name}
            populateOnLoad={true}
            multiple={evseFilterMapping.accountIds.multiple ?? false}
            onChange={(value) => {
              if (value && Array.isArray(value) && value.length > 0) {
                setAccountsSelected(value ?? []);
              } else {
                setAccountsSelected([]);
              }
            }}
            onDataNeeded={async (account) => {
              if (!account?.name) {
                return { data: currentFilterAccounts };
              }

              const debouncedFun = debounce(async () => {
                try {
                  const searchAccounts = await request<{ data: Account[] }>({
                    method: 'POST',
                    path: '1/accounts/search',
                    body: {
                      searchPhrase: account?.name,
                      sort: { field: 'name', order: 'asc' },
                    },
                  });

                  // This keeps already selected accounts in the list so the name can be shown
                  if (currentFilterAccounts.length > 0) {
                    searchAccounts.data.push(...currentFilterAccounts);
                  }

                  return searchAccounts;
                } catch (error) {
                  return { data: currentFilterAccounts ?? [] };
                }
              }, debounceTimeout);

              const result = await debouncedFun();

              return result ?? { data: currentFilterAccounts };
            }}
          />

          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            name={evseFilterMapping.locationIds.name}
            label={t('evseControllerFilter.location', 'Location')}
            multiple={evseFilterMapping.locationIds.multiple ?? false}
            disabled={accountsSelected.length === 0}
            onDataNeeded={async (locationQuery) => {
              if (!locationQuery?.name) {
                return { data: currentFilterLocations };
              }

              const debouncedFun = debounce(async () => {
                try {
                  const searchLocations = await request({
                    method: 'POST',
                    path: '/2/locations/cpo/search/fast',
                    body: {
                      searchPhrase: locationQuery?.name,
                    },
                  });

                  // This keeps already selected locations in the list so the name can be shown
                  if (currentFilterLocations.length > 0) {
                    searchLocations.data.push(...currentFilterLocations);
                  }

                  return searchLocations;
                } catch (error) {
                  return { data: currentFilterLocations ?? [] };
                }
              }, debounceTimeout);

              const result = await debouncedFun();

              return result ?? { data: currentFilterLocations };
            }}
          />

          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            name={evseFilterMapping.maintenanceAccounts.name}
            label={t(
              'evseControllerFilter.fieldServiceAccount',
              'Field Service Account'
            )}
            multiple={evseFilterMapping.maintenanceAccounts.multiple ?? false}
            onDataNeeded={async (accQuery) => {
              if (!accQuery?.name) {
                return { data: currentMaintenanceAccounts };
              }

              const debouncedFun = debounce(async () => {
                try {
                  const accounts = await request({
                    method: 'POST',
                    path: '/1/accounts/search',
                    body: {
                      searchPhrase: accQuery?.name,
                      sort: { field: 'name', order: 'asc' },
                    },
                  });
                  if (currentMaintenanceAccounts.length > 0) {
                    accounts.data.push(...currentMaintenanceAccounts);
                  }

                  return accounts;
                } catch (error) {
                  return { data: currentMaintenanceAccounts ?? [] };
                }
              }, debounceTimeout);

              const result = await debouncedFun();

              return result ?? { data: currentMaintenanceAccounts };
            }}
          />

          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            name={evseFilterMapping.vendors.name}
            label={t('evseControllerFilter.vendors', 'Vendor')}
            multiple={evseFilterMapping.vendors.multiple ?? false}
            onDataNeeded={async () => {
              return {
                data:
                  availableFilters?.vendors?.map((e) => {
                    return {
                      name: e,
                      id: encodeStringForFilter(e),
                    };
                  }) ?? [],
              };
            }}
          />

          <SearchFilters.DropdownFilterV2
            name={evseFilterMapping.ocppVersions.name}
            label={t('evseControllerFilter.ocppVersions', 'OCPP Version')}
            multiple={evseFilterMapping.ocppVersions.multiple ?? false}
            options={OCPPVersions}
          />

          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            name={evseFilterMapping.firmwareVersions.name}
            label={t(
              'evseControllerFilter.firmwareVersions',
              'Firmware Version'
            )}
            multiple={evseFilterMapping.firmwareVersions.multiple ?? false}
            onDataNeeded={async () => {
              return {
                data:
                  availableFilters?.firmwareVersions?.map((e) => {
                    return {
                      name: e,
                      id: encodeStringForFilter(e),
                    };
                  }) ?? [],
              };
            }}
          />

          <SearchFilters.DropdownFilterV2
            name={evseFilterMapping.powerType.name}
            options={evsePowerTypeOptions}
            label={t('evseControllerFilter.evsePowerType', 'Power type')}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('evseControllerFilter.tariffProfile')}
            name={evseFilterMapping.tariffProfiles.name}
            multiple={evseFilterMapping.tariffProfiles.multiple ?? false}
            populateOnLoad={true}
            onDataNeeded={() => {
              return request({
                method: 'POST',
                path: '/1/tariff-profiles/search',
              });
            }}
          />

          <SearchFilters.DropdownSearchFilterV2
            label={t('evseControllerFilter.billingPlan', 'Billing Plan')}
            name={evseFilterMapping.billingPlans.name}
            multiple={true}
            populateOnLoad={true}
            onDataNeeded={() => {
              return request({
                method: 'POST',
                path: '/1/billing-plans/search',
              });
            }}
          />
        </SearchFilters.ModalFilterV2>

        <Layout horizontal stackable center right>
          <Search.Total />
          <SearchFilter name={evseFilterMapping.searchPhrase.name} />
        </Layout>
      </Layout>
      {!isFiltersEmpty && (
        <>
          <Divider
            style={{ 'border-top': '1px solid rgba(34, 36, 38, .15)' }}
          />
          <Layout horizontal stackable center wrap>
            {filterContext.filters.createdAt && (
              <Label
                key={`${evseFilterMapping.createdAt.name}-${filterContext.filters.createdAt}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: evseFilterMapping.createdAt.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('common.createdAt', 'Created At')} :
                {formatDateRangeChip(
                  filterContext.filters.createdAt.$gte,
                  filterContext.filters.createdAt.$lte
                )}
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.hasNoAccount && (
              <Label
                key={`${evseFilterMapping.hasNoAccount.name}-${filterContext.filters.hasNoAccount}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: evseFilterMapping.hasNoAccount.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('evseControllerFilter.hasNoAccount', 'Has No Account')}
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.labels?.length &&
              filterContext.filters.labels?.map((label: string) => {
                return (
                  <Label
                    key={`${evseFilterMapping.labels.name}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: evseFilterMapping.labels.name,
                        value: filterContext.filters.labels?.filter(
                          (labelInContext: string) => labelInContext !== label
                        ),
                      });
                      filterContext.reload();
                    }}>
                    {t('evseControllerFilter.labels', 'Labels')} : {label}
                    <Icon name="delete" />
                  </Label>
                );
              })}

            {currentFilterAccounts.map((e) => {
              return (
                <Label
                  key={`${evseFilterMapping.accountIds.name}-${e.id}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.accountIds.name,
                      value: filterContext.filters.accountIds?.filter(
                        (id: string) => id !== e.id
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('evseControllerFilter.accountIds', 'Account')} :{' '}
                  {e.name ?? e.id}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {currentFilterLocations.map((e) => {
              return (
                <Label
                  key={`${evseFilterMapping.locationIds.name}-${e.id}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.locationIds.name,
                      value: filterContext.filters.locationIds?.filter(
                        (id: string) => id !== e.id
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('evseControllerFilter.location', 'Location')} :{' '}
                  {e.name ?? e.id}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {currentMaintenanceAccounts.map((e) => {
              return (
                <Label
                  key={`${evseFilterMapping.maintenanceAccounts.name}-${e.id}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.maintenanceAccounts.name,
                      value: filterContext.filters.maintenanceAccounts?.filter(
                        (id: string) => id !== e.id
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t(
                    'evseControllerFilter.fieldServiceAccount',
                    'Field Service Account'
                  )}{' '}
                  : {e.name ?? e.id}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {currentTariffProfiles?.map((e: any) => {
              return (
                <Label
                  key={`${evseFilterMapping.tariffProfiles.name}-${e.id}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.tariffProfiles.name,
                      value: filterContext.filters.tariffProfiles?.filter(
                        (id: string) => id !== e.id
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('evseControllerFilter.tariffProfile', 'Tariff Profile')} :{' '}
                  {e.name}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {currentBillingPlans?.map((e: any) => {
              return (
                <Label
                  key={`${evseFilterMapping.billingPlans.name}-${e.id}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.billingPlans.name,
                      value: filterContext.filters.billingPlans?.filter(
                        (id: string) => id !== e.id
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('evseControllerFilter.billingPlan', 'Billing Plan')} :{' '}
                  {e.name}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.setupStates?.map((e: string) => {
              return (
                <Label
                  key={`${evseFilterMapping.setupStates.name}-${e}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.setupStates.name,
                      value: filterContext.filters.setupStates?.filter(
                        (id: string) => id !== e
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t('evseControllerFilter.setupState', 'Setup State')} :{' '}
                  {setupStateOptions.find((o) => o.value === e)?.text}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.ocppVersions &&
              filterContext.filters.ocppVersions.map((e: string) => {
                return (
                  <Label
                    key={`${evseFilterMapping.ocppVersions.name}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: evseFilterMapping.ocppVersions.name,
                        value: filterContext.filters.ocppVersions?.filter(
                          (id: string) => id !== e
                        ),
                      });
                      filterContext.reload();
                    }}>
                    {t('evseControllerFilter.ocppVersions', 'OCPP Version')} :{' '}
                    {decodeStringForFilter(e)}
                    <Icon name="delete" />
                  </Label>
                );
              })}

            {filterContext.filters.vendors &&
              filterContext.filters.vendors.map((e: string) => {
                return (
                  <Label
                    key={`${evseFilterMapping.vendors.name}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: evseFilterMapping.vendors.name,
                        value: filterContext.filters.vendors?.filter(
                          (id: string) => id !== e
                        ),
                      });
                      filterContext.reload();
                    }}>
                    {t('evseControllerFilter.vendors', 'Vendor')} :{' '}
                    {decodeStringForFilter(e)}
                    <Icon name="delete" />
                  </Label>
                );
              })}

            {filterContext.filters.firmwareVersions &&
              filterContext.filters.firmwareVersions.map((e: string) => {
                return (
                  <Label
                    key={`${evseFilterMapping.firmwareVersions.name}`}
                    as="a"
                    onClick={() => {
                      filterContext.onFilterChange({
                        name: evseFilterMapping.firmwareVersions.name,
                        value: filterContext.filters.firmwareVersions?.filter(
                          (id: string) => id !== e
                        ),
                      });
                      filterContext.reload();
                    }}>
                    {t(
                      'evseControllerFilter.firmwareVersions',
                      'Firmware Version'
                    )}{' '}
                    : {decodeStringForFilter(e)}
                    <Icon name="delete" />
                  </Label>
                );
              })}

            {filterContext.filters.connectivityStates?.map((e: string) => {
              return (
                <Label
                  key={`${evseFilterMapping.connectivityStates.name}-${e}`}
                  as="a"
                  onClick={() => {
                    filterContext.onFilterChange({
                      name: evseFilterMapping.connectivityStates.name,
                      value: filterContext.filters.connectivityStates?.filter(
                        (id: string) => id !== e
                      ),
                    });
                    filterContext.reload();
                  }}>
                  {t(
                    'evseControllerFilter.connectivityState',
                    'Connectivity State'
                  )}{' '}
                  : {connectivityStateOptions.find((o) => o.value === e)?.text}
                  <Icon name="delete" />
                </Label>
              );
            })}

            {filterContext.filters.powerType && (
              <Label
                key={`${evseFilterMapping.powerType.name}-${filterContext.filters.powerType}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: evseFilterMapping.powerType.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('evseControllerFilter.evsePowerType', 'Power type')} :{' '}
                {
                  evsePowerTypeOptions.find(
                    (o) => o.value === filterContext.filters.powerType
                  )?.text
                }
                <Icon name="delete" />
              </Label>
            )}

            {filterContext.filters.searchPhrase && (
              <Label
                key={`${evseFilterMapping.searchPhrase.name}-${filterContext.filters.searchPhrase}`}
                as="a"
                onClick={() => {
                  filterContext.onFilterChange({
                    name: evseFilterMapping.searchPhrase.name,
                    value: undefined,
                  });
                  filterContext.reload();
                }}>
                {t('common.search', 'Search')} :{' '}
                {filterContext.filters.searchPhrase}
              </Label>
            )}
          </Layout>
        </>
      )}
    </Segment>
  );
};
