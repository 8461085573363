import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import { Modal, Form, Label, Icon, Grid, Button } from 'semantic';

const getDefaultState = () => ({
  open: false,
});

const getDefaultFilters = () => ({
  showAssigned: true,
  showUnassigned: true,
});

export default class Filters extends React.Component {
  state = {
    ...getDefaultState(),
    ...getDefaultFilters(),
    appliedFilters: [],
  };

  open = () => this.setState({ open: true });
  close = () => this.setState(getDefaultState());

  computeFilters() {
    const { showAssigned, showUnassigned } = this.state;

    const filters = [];

    if (showAssigned) {
      filters.push({
        field: 'showAssigned',
        value: true,
        apiFilter: {
          isAssigned: true,
        },
        label: 'Assigned Tokens',
      });
    }

    if (showUnassigned) {
      filters.push({
        field: 'showUnassigned',
        value: true,
        apiFilter: {
          isUnassigned: true,
        },
        label: 'Unassigned Tokens',
      });
    }

    return filters;
  }

  componentDidMount() {
    const appliedFilters = this.computeFilters();
    this.props.onChange(appliedFilters);
    this.setState({ appliedFilters });
  }

  handleApply = () => {
    const appliedFilters = this.computeFilters();
    this.props.onChange(appliedFilters);
    this.setState({ appliedFilters });
    this.close();
  };

  handleReset = () => {
    this.setState({ ...getDefaultState(), ...getDefaultFilters() }, () => {
      const appliedFilters = this.computeFilters();
      this.props.onChange(appliedFilters);
      this.setState({ appliedFilters });
      this.close();
    });
  };

  handleRemoveFilter = (filter) => {
    const { appliedFilters } = this.state;
    const newFilters = appliedFilters.filter((c) => c.field !== filter.field);

    this.setState({ [filter.field]: !filter.value });

    this.setState({ appliedFilters: newFilters }, () =>
      this.props.onChange(this.state.appliedFilters)
    );
    this.close();
  };

  render() {
    const { open, showAssigned, showUnassigned } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        <Modal
          size="tiny"
          open={open}
          onOpen={this.open}
          onClose={this.close}
          trigger={
            <Button basic primary={true} floated="left">
              <Icon name="filter" /> Filters
            </Button>
          }
          closeIcon>
          <Modal.Header>Filters</Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Checkbox
                      label="Show Assigned"
                      checked={showAssigned}
                      onChange={(e, { checked }) => {
                        this.setState({
                          showAssigned: checked,
                        });
                      }}
                      style={{ marginRight: '10px' }}
                      toggle
                    />
                    <Form.Checkbox
                      label="Show Unassigned"
                      checked={showUnassigned}
                      onChange={(e, { checked }) => {
                        this.setState({
                          showUnassigned: checked,
                        });
                      }}
                      style={{ marginRight: '10px' }}
                      toggle
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button basic content="Reset" onClick={this.handleReset} />
            <Button
              primary
              icon="check"
              content="Apply"
              onClick={this.handleApply}
            />
          </Modal.Actions>
        </Modal>

        <div style={{ marginLeft: '10px' }}>
          {this.state.appliedFilters.map((filter) => (
            <Label
              key={filter.field}
              size="large"
              style={{ marginLeft: '10px', lineHeight: '20px' }}>
              {filter.label}
              <Icon
                name="delete"
                onClick={() => this.handleRemoveFilter(filter)}
              />
            </Label>
          ))}
        </div>
      </div>
    );
  }
}
