import { Group } from 'components/Layout/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Form } from 'semantic';
import { Divider } from 'semantic';

interface ThemeSwitchCardProps {
  theme: any;
  targetTheme: string;
  label: 'Light Mode' | 'Dark Mode';
  staticThemeClassName: 'diurnal-theme' | 'nocturnal-theme';
  iconName: string;
  updateTheme: (theme: string) => void;
}

// TODO: Investigate how to extract these from a global theme
const primaryColor = '#ff3d00';
const darkBgColor = '#3c3c3c';
const lightBgColor = '#fff';
const iconColor = '#D3D3D3';
const activeBorderColor = '#008CD9';

const ThemeCardPlaceHolder = ({
  color,
  width,
}: {
  color: string;
  width: number;
}) => (
  <div>
    <div
      style={{
        paddingBottom: '1em',
        height: 5,
        backgroundColor: color,
        width: width,
        marginTop: 5,
        borderRadius: 5,
      }}
    />
  </div>
);

export default function ThemeSwitchCard({
  theme,
  staticThemeClassName,
  label,
  targetTheme,
  iconName,
  updateTheme,
}: ThemeSwitchCardProps) {
  const { t } = useTranslation();
  const styles = {
    backgroundColor:
      staticThemeClassName === 'diurnal-theme' ? lightBgColor : darkBgColor,
  };

  return (
    <div
      style={{
        width: 250,
        borderRadius: 10,
        padding: 15,
        marginTop: 10,
        marginBottom: 20,
        border: `1.5px solid ${
          theme === targetTheme ? activeBorderColor : 'gray'
        }`,
        ...styles,
      }}>
      <div style={{ paddingTop: 4, paddingLeft: 4 }}>
        <Icon name={iconName} size="large" color={'grey'} />
      </div>
      <Divider />
      <div>
        <Group>
          <ThemeCardPlaceHolder color={iconColor} width={50} />
          <ThemeCardPlaceHolder color={iconColor} width={50} />
        </Group>
        <ThemeCardPlaceHolder color={primaryColor} width={25} />
      </div>
      <Divider />
      <div style={{ marginTop: 10 }}>
        <Form.Radio
          disabled={theme === 'system'}
          checked={theme === targetTheme}
          label={t('appearance.label', '{{label}}', { label })}
          onClick={() => updateTheme(targetTheme)}
        />
      </div>
    </div>
  );
}
