import React from 'react';
import PropTypes from 'prop-types';
import bem from 'helpers/bem';

class PortalNavSidebar extends React.Component {
  getModifiers() {
    const { dark } = this.props;
    const { offscreen } = this.context;
    return [dark ? 'dark' : null, offscreen ? 'offscreen' : null];
  }

  render() {
    const Element = this.props.as;
    return (
      <Element className={this.getBlockClass()} style={this.props.style}>
        {this.props.children}
      </Element>
    );
  }
}

PortalNavSidebar.propTypes = {
  dark: PropTypes.bool,
  as: PropTypes.elementType,
};

PortalNavSidebar.defaultProps = {
  dark: false,
  as: 'div',
};

export default bem(PortalNavSidebar);
