import React, { ChangeEvent } from 'react';
import { Grid, Form, Table, Button, Header } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import SearchDropdown from 'components/SearchDropdown';
import { capitalize } from 'utils/formatting';
import { LocationBillingPolicyUsageReimbursement } from './types';
import { Account } from 'types/account';

import './EditLocationStyle.less';

const percentageSum = (list: any[] = []): number =>
  list.reduce((acc, { percentage }) => acc + parseInt(percentage), 0);

type EntitySearchDropdownProps = {
  value: any;
  name: any;
  accessPolicy: any;
  onChange: (e: ChangeEvent<Element> | null, val: any) => void;
};
const EntitySearchDropdown = ({
  value,
  name,
  accessPolicy,
  onChange,
}: EntitySearchDropdownProps) => {
  return (
    <SearchDropdown
      value={value?.id}
      name={name}
      style={{ width: '100%' }}
      options={
        value?.id
          ? [
              {
                ...value,
                name: `${capitalize(value.type)}: ${value.name}`,
              },
            ]
          : []
      }
      getOptionValue={(value) => value}
      valueGetter={({ options, value: id }) => {
        return options.find((option: any) => {
          if (option.type === 'user') {
            return option.id === id && option.type === 'user';
          }
          if (option.type === 'account') {
            return option.id === id && option.type === 'account';
          }
        });
      }}
      onDataNeeded={async (body: any) => {
        const { data } = await request({
          path: `/2/locations/cpo/billing-policy-members/search`,
          method: 'POST',
          body: {
            accessPolicy,
            name: body?.name,
          },
        });
        return {
          data: data.map((item: any) => ({
            ...item,
            name: `${capitalize(item.type)}: ${item.name}`,
          })),
        };
      }}
      onChange={(e, { name, value, valueObject }) => {
        const entity = value || valueObject;
        onChange(e, {
          name,
          value: {
            type: entity?.type,
            [`${entity?.type}Id`]: entity?.id,
          },
        });
      }}
    />
  );
};

type SplitReimbusementConfigrationProps = {
  onChange: (
    e: string | null,
    val: LocationBillingPolicyUsageReimbursement[]
  ) => void;
  account: Account;
  accessPolicy: any;
  value: LocationBillingPolicyUsageReimbursement[];
};

export const SplitReimbusementConfigration = ({
  onChange,
  accessPolicy,
  account,
  value = [
    {
      percentage: 50,
      type: 'credit',
      entity: {
        type: 'account',
        id: account?.id,
        accountId: account?.id,
        name: account?.name,
      },
    },
    { percentage: 50, type: 'credit' },
  ],
}: SplitReimbusementConfigrationProps) => {
  const { t } = useTranslation();

  const removeItem = (i: number) => {
    onChange('billingPolicy.usageReimbursement', [
      ...value.slice(0, i),
      ...value.slice(i + 1, value.length),
    ]);
  };

  const updateIndexValue = (i: number, name: any, fieldValue: any) => {
    onChange('billingPolicy.usageReimbursement', [
      ...value.slice(0, i),
      {
        ...value[i],
        [name]: fieldValue,
      },
      ...value.slice(i + 1, value.length),
    ]);
  };

  return (
    <Grid celled>
      <Grid.Row>
        <div style={{ padding: '1rem' }}>
          <Header as="h3">
            {t('editLocation.usageReimbursement', 'Split reimbursement')}
          </Header>
          <p>
            {t(
              'editLocation.splitBillingNote',
              'Specify how you want to split reimbursement between different users or accounts in percentages.'
            )}
          </p>
        </div>
      </Grid.Row>

      <Grid.Row style={{ padding: '1rem' }}>
        <Table>
          <Table.Header>
            <Table.HeaderCell>
              {t(
                'accessPolicyConfiguration.reimbursePercentageTitle',
                '% to reimburse'
              )}
              <span style={{ color: 'red' }}>*</span>
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t(
                'accessPolicyConfiguration.reimbursePercentageEntity',
                'User or Account to reimburse'
              )}
              <span style={{ color: 'red' }}>*</span>
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('accessPolicyConfiguration.action', 'Action')}
            </Table.HeaderCell>
          </Table.Header>

          {value?.map(({ percentage, entity }, i) => (
            <Table.Row key={i}>
              <Table.Cell>
                <Form.Input
                  required
                  type="number"
                  name="percentage"
                  onChange={(_, { name, value }) =>
                    updateIndexValue(i, name, value)
                  }
                  value={percentage}
                />
              </Table.Cell>
              <Table.Cell>
                <EntitySearchDropdown
                  value={entity}
                  name="entity"
                  accessPolicy={accessPolicy}
                  onChange={(_, { name, value }) =>
                    updateIndexValue(i, name, value)
                  }
                />
              </Table.Cell>
              <Table.Cell>
                {i >= 2 && (
                  <Button
                    basic
                    icon="trash"
                    title="Delete"
                    onClick={() => removeItem(i)}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>

        <div className={'usageReimbursementContainer'}>
          <button
            className={`usageReimbursement ${percentageSum(value) < 100 ? 'usageReimbursementActive' : ''}`}
            disabled={percentageSum(value) >= 100}
            onClick={() =>
              onChange('billingPolicy.usageReimbursement', [
                ...(value || []),
                {
                  percentage: 0,
                  type: 'credit',
                  entity: undefined,
                },
              ])
            }>
            {'+ '}
            {t(
              'accessPolicyConfiguration.addReimbursement',
              'Add user or account to reimburse'
            )}
          </button>
        </div>
      </Grid.Row>
      <Grid.Row>
        {percentageSum(value) !== 100 && (
          <div
            style={{
              background: '#fef2f4',
              padding: '1rem',
              width: '100%',
              border: 'solid 1px #fac2c9',
            }}>
            <p
              style={{
                flex: 1,
                fontWeight: 'bold',
              }}>
              <div>
                {t(
                  'editLocation.percentageNotEqual100',
                  'The sum of the percentage reimbursed must equal 100%, but was {{PERCENTAGE}}%',
                  {
                    PERCENTAGE: percentageSum(value),
                  }
                )}
              </div>
            </p>
          </div>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default SplitReimbusementConfigration;
