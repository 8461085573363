import { SpacingValue } from 'types/layout';

export const spacingMap = {
  xxs: '8px',
  xs: '16px',
  sm: '32px',
  md: '64px',
  lg: '128px',
  xl: '256px',
} as const;

export function resolveSpacingValue(
  spacing: SpacingValue,
  defaultValue: number
) {
  if (typeof spacing === 'number') {
    return spacing;
  } else {
    return spacingMap[spacing] || defaultValue;
  }
}
