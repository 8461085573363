import React from 'react';
import { BillingInfo } from 'components/BillingInfo/types';
import { useFormikContext } from 'formik';
import { Divider, FormGroup, Grid } from 'semantic';
import {
  BillingAddressFormPart,
  BillingAddressFormPartHeader,
} from 'components/BillingInfo/BillingAddress';
import {
  BillingOrganizationInfoFormPart,
  BillingOrganizationInfoFormPartHeader,
} from 'components/BillingInfo/OrganizationInfo';
import {
  BillingPaymentInfoFormPart,
  BillingPaymentInfoFormPartHeader,
} from 'components/BillingInfo/PaymentInfo';
import styles from './BillingInfo.module.less';

type BillingInfoFormikFormProps = {
  values?: BillingInfo;
  accountType: string;
  enableCountryCodeInput?: boolean;
  enableBicCollection?: boolean;
  objectPath?: string;
  showErrorLabels?: boolean;
};

export function BillingInfoFormikForm(props: BillingInfoFormikFormProps) {
  const { values } = useFormikContext<BillingInfo>();
  const billingObject: BillingInfo = props.objectPath
    ? values[props.objectPath]
    : values;

  billingObject.countryCode =
    billingObject?.countryCode || props.values?.countryCode;

  return (
    <Grid padded="horizontally">
      {props.accountType === 'organization' && (
        <>
          <Grid.Row>
            <Grid.Column>
              <BillingOrganizationInfoFormPartHeader />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <FormGroup styles={{ width: '100%' }} widths={'equal'}>
                <BillingOrganizationInfoFormPart
                  value={billingObject}
                  objectPath={props.objectPath}
                  showErrorLabels={props.showErrorLabels}
                />
              </FormGroup>
            </Grid.Column>
          </Grid.Row>
          <Divider style={{ marginBottom: 0 }} />
        </>
      )}
      <Grid.Row className={styles.billingDetails}>
        <Grid.Column style={{ paddingRight: '1rem' }} className={styles.column}>
          <BillingPaymentInfoFormPartHeader />
          <BillingPaymentInfoFormPart
            value={billingObject}
            accountType={props.accountType}
            objectPath={props.objectPath}
            showErrorLabels={props.showErrorLabels}
            enableBicCollection={props.enableBicCollection}
          />
        </Grid.Column>
        <Grid.Column
          className={styles.column}
          style={{ paddingLeft: '1rem', paddingBottom: '20px' }}>
          <BillingAddressFormPartHeader />
          <BillingAddressFormPart
            value={billingObject}
            objectPath={props.objectPath}
            showErrorLabels={props.showErrorLabels}
            enableCountryCodeInput={props.enableCountryCodeInput}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
