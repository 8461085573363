import React from 'react';
import { useUser } from '../contexts/user';
import { useTranslation } from 'react-i18next';

const eFluxPrivacy = {
  de: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Privacy-Policy_DE.pdf',
  en: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Privacy-Policy_EN.pdf',
  nl: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Privacy-Policy_NL.pdf',
  fr: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Privacy-Policy_FR.pdf',
};

//
const eFluxTerms = {
  en: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Terms-Conditions_June-2023_EN.pdf',
  nl: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Terms-Conditions-June-2023_NL.pdf',
  de: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Terms-Conditions_June-2023_DE.pdf',
  fr: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Terms-Conditions_June-2023_FR.pdf',
  it: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Terms-Conditions-June-2023_IT.pdf',
  es: 'https://www.e-flux.io/pdf/E-Flux-by-Road_Terms-Conditions-June-2023_ES.pdf',
};

const eFluxSepaTerms = {
  // TODO: update the key value pairs when other translations are available
  en: 'https://www.e-flux.io/direct-debit',
  nl: 'https://www.e-flux.io/nl/automatische-incasso',
  fr: 'https://www.e-flux.io/fr/prelevement-automatique',
};

function getUrl(type, language, provider) {
  if (provider.slug === 'e-flux' && type === 'privacy') {
    return eFluxPrivacy[language] || eFluxPrivacy.en;
  }
  if (provider.slug === 'e-flux' && type === 'tos') {
    return eFluxTerms[language] || eFluxTerms.en;
  }

  if (type === 'tos' && provider.tosUrl) return provider.tosUrl;
  if (type === 'privacy' && provider.privacyUrl) return provider.privacyUrl;
  if (type === 'sepa' && provider.sepaUrl) return provider.sepaUrl;

  if (type === 'privacy') return eFluxPrivacy[language] || eFluxPrivacy.en;
  if (type === 'sepa') return eFluxSepaTerms[language] || eFluxSepaTerms.en;

  return eFluxTerms[language] || eFluxTerms.en;
}

export default function LegalLink({ type, children }) {
  const { provider } = useUser();
  const { i18n } = useTranslation();

  const url = getUrl(type, i18n.language, provider);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}
