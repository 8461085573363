import React from 'react';
import { Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'contexts/account';
import { useUser } from 'contexts/user';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import PasswordAndAuthenticationForm from './PasswordAndAuthentication.form';
import OrganizationTwoFactorAuthentication from '../../organization/TwoFactorAuthentication/OrganizationTwoFactorAuthentication';
import { mfaIsRequiredForUser } from 'utils/security';
import FormHeader from '../../../components/atoms/FormHeader';

export default function PasswordAndAuthenticationLayout() {
  const { user } = useUser();
  const { t } = useTranslation();
  const { isAccountManagedBySso } = useAccount();

  if (isAccountManagedBySso) {
    return (
      <SettingsShell>
        <FormHeader
          size="large"
          pb={5}
          pt={5}
          text={t(
            'settings.personal.passwordAndAuthentication.title',
            'Password & Authentication'
          )}
        />
        <Message warning>
          {t(
            'access.cannotViewPasswordAndAuthentication',
            'Your account is managed by a single sign-on provider(SSO). You cannot view or change your password or authentication settings.'
          )}
        </Message>
      </SettingsShell>
    );
  }

  return (
    <SettingsShell>
      <PasswordAndAuthenticationForm />
      {mfaIsRequiredForUser(user) && <OrganizationTwoFactorAuthentication />}
    </SettingsShell>
  );
}
