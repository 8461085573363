import React from 'react';

import ExploreMap from 'components/maps/ExploreMap';
import Map from 'components/Map';
import { request } from 'utils/api';

import { useFeatures } from 'contexts/features';

export default function MapContainer() {
  const { hasFeature } = useFeatures();

  return hasFeature('map-v3') ? (
    <Map />
  ) : (
    <ExploreMap
      search
      fetchData={({ geoBox }) =>
        request({
          method: 'POST',
          path: '/2/locations/msp/search',
          body: {
            sort: { field: 'updatedAt', order: 'asc' },
            geoBox,
            limit: 500,
          },
        })
      }
    />
  );
}
