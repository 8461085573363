import React, { useRef, useState } from 'react';

import { request } from 'utils/api';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { Form, Message, Modal, Button } from 'semantic';

import { simpleOptions } from 'utils/form';
import { API_URL } from 'utils/env';

import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import CodeBlock from '../CodeBlock';
import PropTypes from 'prop-types';

function EditOcpiCredential({ initialValues, t, close, onSave }) {
  const [formValues, setFormValues] = useState({
    role: 'receiver', // default to receiver, is overwritten if set in initialValues
    ...initialValues,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const submitRef = useRef(null);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const isPatch = !!formValues.id;

    if (isUpdate && formValues.role !== 'sender' && !formValues.versionsUrl) {
      setFormValues((prevState) => ({ ...prevState, versionsUrl }));
    }

    try {
      await request({
        method: isPatch ? 'PATCH' : 'POST',
        path: isPatch
          ? `/1/ocpi-credentials/${formValues.id}`
          : '/1/ocpi-credentials',
        body: formValues,
      });
      close();
      onSave();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function setField(name, value) {
    setFormValues({ ...formValues, [name]: value });
  }

  const isUpdate = !!initialValues.id;

  const hasBeenReset = // Credential has been reset using /:OcpiCredential/reset
    !initialValues.activeVersion &&
    !initialValues.endpoints &&
    !initialValues.mostRecentSync &&
    !initialValues.roles &&
    !initialValues.tokenB &&
    !initialValues.tokenC &&
    !initialValues.versions &&
    !initialValues.versionsUrl;

  const versionsUrl = `${API_URL}/ocpi/${
    formValues.providerContext === 'msp' ? 'emsp' : 'cpo'
  }/versions`;

  return (
    <>
      <Modal.Header>
        {isUpdate
          ? t('editOcpiCredential.titleEdit', 'Edit OCPI Credential {{name}}', {
              name: initialValues.name,
            })
          : t('editOcpiCredential.titleNew', 'New OCPI Credential')}
      </Modal.Header>
      <Modal.Content>
        <Form error={Boolean(error)} onSubmit={() => onSubmit()}>
          {error && <Message error content={error.message} />}

          <Form.Select
            disabled={isUpdate}
            value={formValues.providerContext}
            options={simpleOptions(['msp', 'cpo'], { upperCase: true })}
            name="providerContext"
            label="Provider Context"
            required
            type="text"
            onChange={(_, { name, value }) => setField(name, value)}
          />
          <p>
            {formValues.providerContext &&
              (formValues.providerContext === 'msp'
                ? 'The remote party is a CPO that can use our charge cards.'
                : 'The remote party is an MSP that can use our charge stations')}
          </p>
          <Form.Input
            value={formValues.name}
            name="name"
            label={t('editOcpiCredential.name', 'Provider Name')}
            type="text"
            required
            onChange={(_, { name, value }) => setField(name, value)}
          />
          {(!isUpdate || hasBeenReset) && (
            <Form.Select
              value={formValues.role || 'receiver'}
              options={simpleOptions(['receiver', 'sender'])}
              name="role"
              label={t('editOcpiCredential.role', 'Role')}
              required
              type="text"
              onChange={(_, { name, value }) => setField(name, value)}
            />
          )}
          {isUpdate && !hasBeenReset && (
            <Form.Input
              value={formValues.role}
              name="role"
              label={t('editOcpiCredential.role', 'Role')}
              type="text"
              readOnly
            />
          )}

          <Form.Field>
            <label>Account</label>
            <SearchDropdown
              clearable
              value={formValues.accountId}
              objectMode={false}
              onDataNeeded={(body) =>
                request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                })
              }
              onChange={(_, { value }) => setField('accountId', value)}
            />
          </Form.Field>

          {formValues.role === 'sender' && (
            <>
              <Form.Input
                value={formValues.versionsUrl}
                name="versionsUrl"
                label={t('editOcpiCredential.versionsURL', 'Versions URL')}
                type="text"
                required={hasBeenReset}
                readOnly={!hasBeenReset}
                onChange={(_, { name, value }) => setField(name, value)}
              />
              <Form.Input
                value={formValues.tokenA}
                name="tokenA"
                label={t('editOcpiCredential.tokenA', 'Token A')}
                type="text"
                required={hasBeenReset}
                readOnly={!hasBeenReset}
                onChange={(_, { name, value }) => setField(name, value)}
              />
            </>
          )}
          {isUpdate && formValues.role !== 'sender' && (
            <Form.Input
              value={versionsUrl}
              name="versionsUrl"
              label={t('editOcpiCredential.versionURL', 'E-Flux Versions URL')}
              type="text"
              readOnly
            />
          )}
          {isUpdate && formValues.role !== 'sender' && (
            <Form.Input
              value={formValues.tokenA}
              name="tokenA"
              label={t('editOcpiCredential.tokenA', 'Token A')}
              type="text"
              readOnly
            />
          )}
          {isUpdate && (
            <>
              <Form.Input
                value={formValues.tokenB}
                name="tokenB"
                label={t('editOcpiCredential.tokenB', 'Token B')}
                type="text"
                readOnly
              />
              <Form.Input
                value={formValues.tokenC}
                name="tokenC"
                label={t('editOcpiCredential.tokenC', 'Token C')}
                type="text"
                readOnly
              />
              <Form.Input
                value={formValues.activeVersion}
                name="activeVersion"
                label={t('editOcpiCredential.activeVersion', 'Active Version')}
                type="text"
                readOnly
              />

              <Form.Select
                value={formValues.implementationVersion}
                options={[1, 2].map((v) => ({ key: v, value: v, text: v }))}
                label={t(
                  'editOcpiCredential.implementationVersion',
                  'Implementation Version'
                )}
                name="implementationVersion"
                type="number"
                onChange={(_, { name, value }) => setField(name, value)}
              />

              <Form.Checkbox
                label={t(
                  'editOcpiCredential.enableTokenSynchronization',
                  'Enable Token Synchronization?'
                )}
                name="tokensEndpointEnabled"
                checked={formValues.tokensEndpointEnabled || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />

              <Form.Checkbox
                label={t(
                  'editOcpiCredential.enablePulling',
                  'Enable pulling from endpoints?'
                )}
                name="enablePulling"
                checked={formValues.enablePulling || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />

              <Form.Checkbox
                label={t(
                  'editOcpiCredential.enableCDRSynchronization',
                  'Enable CDR Synchronization?'
                )}
                name="cdrsEndpointEnabled"
                checked={formValues.cdrsEndpointEnabled || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />

              {formValues.providerContext === 'msp' && (
                <Form.Checkbox
                  label={t(
                    'editOcpiCredential.cpoSupportsRemoteStartStop',
                    'Supports remote start/stop commands'
                  )}
                  name="cpoSupportsRemoteStartStop"
                  checked={formValues.cpoSupportsRemoteStartStop || false}
                  onChange={(_, { name, checked }) => setField(name, checked)}
                />
              )}

              {formValues.providerContext === 'msp' && (
                <Form.Checkbox
                  label={'Our MSP tokens have Real-Time Token Authorization'}
                  name="tokensRealtimeAuthentication"
                  checked={formValues.tokensRealtimeAuthentication || false}
                  onChange={(_, { name, checked }) => setField(name, checked)}
                />
              )}
              {formValues.providerContext === 'msp' &&
                formValues.tokensRealtimeAuthentication && (
                  <Form.Select
                    value={formValues.tokenWhitelistType}
                    options={[
                      {
                        key: 'ALWAYS',
                        value: 'ALWAYS',
                        text: 'ALWAYS. CPO should never verify our tokens',
                      },
                      {
                        key: 'ALLOWED',
                        value: 'ALLOWED',
                        text: 'ALLOWED. CPO can verify our token',
                      },
                      {
                        key: 'ALLOWED_OFFLINE',
                        value: 'ALLOWED_OFFLINE',
                        text: 'ALLOWED_OFFLINE. CPO will verify, but when we error allow',
                      },
                      {
                        key: 'NEVER',
                        value: 'NEVER',
                        text: 'NEVER. CPO should always verify our tokens',
                      },
                    ]}
                    label={'Token Whitelist Type'}
                    name="tokenWhitelistType"
                    type="text"
                    onChange={(_, { name, value }) => setField(name, value)}
                  />
                )}
              <Form.Checkbox
                label={t(
                  'editOcpiCredential.preventTrailingSlashes',
                  'Prevent Trailing Slashes in OCPI API calls'
                )}
                name="preventTrailingSlashes"
                checked={formValues.preventTrailingSlashes || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />

              <Form.Checkbox
                label="Use Token A during handshake"
                name="useTokenAInHandshake"
                checked={formValues.useTokenAInHandshake || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />

              <Form.Checkbox
                label="Use next-link for pagination"
                name="useNextLinkPagination"
                checked={formValues.useNextLinkPagination || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />
              <Form.Checkbox
                label="Use delta sync"
                name="supportsDeltaSync"
                checked={formValues.supportsDeltaSync || false}
                onChange={(_, { name, checked }) => setField(name, checked)}
              />

              {formValues.providerContext === 'cpo' && (
                <Form.Checkbox
                  label="Realtime verify external tokens at owning MSP"
                  name="externalTokenRealtimeAuthorizationEnabled"
                  checked={
                    formValues.externalTokenRealtimeAuthorizationEnabled ||
                    false
                  }
                  onChange={(_, { name, checked }) => setField(name, checked)}
                />
              )}

              {formValues.providerContext === 'cpo' &&
                formValues.role === 'receiver' && (
                  <Form.Checkbox
                    label="Send costs in Active Session updates"
                    name="activeSessionCostUpdatesEnabled"
                    checked={
                      formValues.activeSessionCostUpdatesEnabled || false
                    }
                    onChange={(_, { name, checked }) => setField(name, checked)}
                  />
                )}

              {formValues.providerContext === 'msp' && (
                <Form.Checkbox
                  name="sendConnectorIdInCommands"
                  label="Send connectorId in when doing REMOTE_START commands"
                  checked={formValues.sendConnectorIdInCommands || false}
                  onChange={(_, { name, checked }) =>
                    this.setField(name, checked)
                  }
                />
              )}

              {formValues.providerContext === 'msp' && (
                <Form.Checkbox
                  label="On-the-fly convert customId (auth_id/contract_id) to be Emi3 conformant"
                  name="useOnTheFlyAuthidEmi3Compat"
                  checked={formValues.useOnTheFlyAuthidEmi3Compat || false}
                  onChange={(_, { name, checked }) => setField(name, checked)}
                />
              )}

              <Form.Field>
                <label>{t('editOcpiCredential.endpoints', 'Endpoints')}</label>
                <CodeBlock
                  value={JSON.stringify(formValues.endpoints || [], null, 2)}
                  language="json"
                />
              </Form.Field>

              <Form.Field>
                <label>{t('editOcpiCredential.roles', 'Roles')}</label>
                <CodeBlock
                  value={JSON.stringify(formValues.roles || [], null, 2)}
                  language="json"
                />
              </Form.Field>
            </>
          )}
          <button ref={submitRef} type="submit" hidden />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          disabled={loading}
          primary
          content={isUpdate ? t('button.update') : t('button.create')}
          onClick={() => submitRef.current.click()} // Submit using hidden button to trigger native validation
        />
      </Modal.Actions>
    </>
  );
}

EditOcpiCredential.propTypes = {
  initialValues: PropTypes.shape({
    role: PropTypes.string,
  }),
  onSave: PropTypes.func,
  close: PropTypes.func,
  t: PropTypes.func.isRequired,
};

export default modal(withTranslation()(EditOcpiCredential));
