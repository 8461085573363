import React, { useEffect, useState } from 'react';
import { Container, Message } from 'semantic';
import { Breadcrumbs, ListHeader } from 'components';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { useUser } from 'contexts/user';
import Table from './Table';
import { Link } from 'react-router-dom';

export default function ProviderCurrencies({ prefixPath = '/finance' }) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    error: undefined,
    currencies: [],
  });

  const { provider } = useUser();

  const fetchCurrencies = async () => {
    try {
      const { data: currencies } = await request({
        method: 'GET',
        path: `/1/providers/${provider.id}/currencies`,
      });
      setState({
        currencies,
      });
    } catch (error) {
      setState({
        currencies: [],
        error,
      });
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, [provider?.id]);

  return (
    <>
      <Container>
        <Breadcrumbs
          path={[
            <Link key="finance" to={prefixPath}>
              {t('finance.title', 'Finance')}
            </Link>,
          ]}
          active={t('providerCurrencies.title', 'Currencies')}
        />
      </Container>

      {state.error && <Message error content={state.error.message} />}

      {!state.error && state.currencies?.length > 0 && (
        <>
          <ListHeader title={t('providerCurrencies.title', 'Currencies')} />

          <Table
            provider={provider}
            currencies={state.currencies}
            onSave={fetchCurrencies}
          />
        </>
      )}
    </>
  );
}
