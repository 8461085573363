import React, { CSSProperties } from 'react';
import { useField, FieldValidator } from 'formik';
import { Form, FormSelectProps, SemanticWIDTHS } from 'semantic';

export interface SelectFieldOption {
  key: string;
  value: string;
  text: string;
}

interface Props extends FormSelectProps {
  name: string;
  label?: string;
  disabled?: boolean;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  required?: boolean;
  validate?: FieldValidator;
  options: Array<SelectFieldOption>;
  clearable?: boolean;
  // set this to true if you do not want to show the error label below the input if the field is not valid. Note that
  // the field will still be styled as invalid
  hideErrorLabel?: boolean;
  width?: SemanticWIDTHS;
}

const SelectField: React.FC<Props> = ({
  name,
  label,
  disabled = false,
  required,
  validate,
  options = [],
  hideErrorLabel,
  clearable,
  width,
  style,
  wrapperStyle,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  return (
    // wrapperStyle is used to style the formik validation errors
    <div style={wrapperStyle}>
      <Form.Select
        width={width}
        value={field.value}
        options={options}
        disabled={disabled}
        name={name}
        style={style}
        aria-label={name}
        onBlur={() => helpers.setTouched(true)}
        error={
          meta.touched && meta.error
            ? hideErrorLabel
              ? true
              : meta.error
            : undefined
        }
        label={label}
        clearable={clearable}
        required={required}
        onChange={(e, { value }) => helpers.setValue(value)}
        {...rest}
      />
    </div>
  );
};

export default SelectField;
