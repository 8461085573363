import React from 'react';
import { Header, Container, Divider, Grid, Icon, Button } from 'semantic';

import PortalNav from 'components/PortalNav';
import { Layout } from 'components/Layout';
import { getThemedField } from 'utils/theming';
import { Link } from 'react-router-dom';

export default class Home extends React.Component {
  render() {
    const isDark = this.context.renderedTheme === 'dark';

    const backgroundStyle = {};

    const secondaryBackgroundColorHex = getThemedField(
      window.provider,
      'secondaryBackgroundColorHex',
      isDark
    );

    if (secondaryBackgroundColorHex) {
      backgroundStyle.background = secondaryBackgroundColorHex;
    }

    return (
      <>
        <PortalNav.Content>
          <PortalNav.MobileHead style={backgroundStyle}>
            <Layout horizontal center spread style={{ overflow: 'visible' }}>
              <div />
              <PortalNav.Trigger>
                <Icon name="bars" fitted />
              </PortalNav.Trigger>
            </Layout>
          </PortalNav.MobileHead>

          <Layout vertical>
            <Container>
              <Header as="h1">
                <Header.Content>
                  Documentation
                  <Header.Subheader>
                    Explore our guides and examples to integrate E-Flux.
                  </Header.Subheader>
                </Header.Content>
              </Header>
              <Divider hidden />
              <Divider hidden />
              <div className="block">
                <Header as="h2" content="Charge Stations" />
                <p>
                  Manage, operate and control EV Charge Stations and receive EV
                  charge transactions.
                </p>
                <a href="/docs/charge-stations">
                  <Button primary content="Get Started" />
                </a>
              </div>
              <Divider hidden />
              <Divider hidden />
              <Grid columns={2} divided>
                <Grid.Row>
                  <Grid.Column>
                    <div className="block">
                      <Header as="h2" content="Charge Cards" />
                      <p>
                        Sell and manage EV Charge Cards and download roaming
                        charge transactions.
                      </p>
                      <Button
                        as={Link}
                        to="/docs/cards"
                        basic
                        content="Learn More"
                      />
                      <p>
                        <h5>In this section</h5>
                      </p>
                      <ul>
                        <li>
                          <Link to="/docs/cards">Use my own RFID cards</Link>
                        </li>
                      </ul>
                    </div>
                  </Grid.Column>
                  <Grid.Column>
                    <div className="block">
                      <Header as="h2" content="Roaming" />
                      <p>
                        Exchange locations, tokens and charge transactions as a
                        roaming partner.
                      </p>
                      <Button
                        as={Link}
                        to="/docs/roaming"
                        basic
                        content="Learn More"
                      />
                      <p>
                        <h5>In this section</h5>
                      </p>
                      <ul>
                        <li>
                          <Link to="/docs/roaming">
                            Link to my own CPO platform
                          </Link>
                        </li>
                        <li>
                          <Link to="/docs/roaming">
                            Link to my own MSP platform
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Layout>
        </PortalNav.Content>
      </>
    );
  }
}
