import React from 'react';
import { Label, Loader, Message, Segment, Table, Button } from 'semantic';

import ViewSyncLog from 'components/modals/ViewSyncLog';
import Pagination from '../Pagination';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class SyncLogTable extends React.Component {
  render() {
    const { status, data: items, meta = {}, page } = this.props;
    return (
      <>
        <Table key="Table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Time</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Label
                      content={item.type}
                      color={item.type === 'error' ? 'red' : undefined}
                    />
                  </Table.Cell>
                  <Table.Cell>{item.message}</Table.Cell>
                  <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <ViewSyncLog
                      initialValues={item}
                      trigger={<Button basic icon="magnifying-glass" />}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        {status.success && meta.total > this.props.limit && (
          <Center key="Pagintation">
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }} key="Loader">
            <Loader active>Loading</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </>
    );
  }
}
