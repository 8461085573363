import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Divider, Form, Icon } from 'semantic';
import InputField from 'components/form-fields/formik/InputField';
import SelectField from 'components/form-fields/formik/SelectField';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import { useFormikContext } from 'formik';
import { PersonalAndAccountDetails } from 'screens/Auth/SignupV2/steps/PersonalDetails';

type IndividualAccountDetails = {
  accountType: 'individual';
};

type OrganizationAccountDetails = {
  accountType: 'organization';
  organizationName: string;
  jobTitle?: string;
  websiteUrl?: string;
  organizationSize?: string;
};

export type AccountDetails =
  | IndividualAccountDetails
  | OrganizationAccountDetails;

type AccountDetailsProps = {
  value: AccountDetails;
};

export const accountDetailsValidationSchema = Yup.object().shape({
  accountType: Yup.string().oneOf(['individual', 'organization']).required(),
  organizationName: Yup.string().when('accountType', {
    is: (accountType: string) => accountType === 'organization',
    then: (schema) => schema.required('Organization name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  jobTitle: Yup.string().when('accountType', {
    is: (accountType: string) => accountType === 'organization',
    then: (schema) => schema,
    otherwise: (schema) => schema.notRequired(),
  }),
  websiteUrl: Yup.string().when('accountType', {
    is: (accountType: string) => accountType === 'organization',
    then: (schema) => schema.url('Must be a valid URL'),
    otherwise: (schema) => schema.notRequired(),
  }),
  organizationSize: Yup.string().when('accountType', {
    is: (accountType: string) => accountType === 'organization',
    then: (schema) => schema,
    otherwise: (schema) => schema.notRequired(),
  }),
});

export default function AccountDetailsForm(props: AccountDetailsProps) {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<PersonalAndAccountDetails>();

  const [accountType, setAccountType] = useState<AccountDetails['accountType']>(
    props.value.accountType || 'individual'
  );

  // used to keep track of organization details already input even if account type changes
  const [organizationDetails, setOrganizationDetails] =
    useState<OrganizationAccountDetails>(
      props.value.accountType === 'organization'
        ? (props.value as OrganizationAccountDetails)
        : {
            accountType: 'organization',
            organizationName: '',
          }
    );

  // Update parent formik values when account type changes
  useEffect(() => {
    if (accountType === 'individual') {
      setValues((prevValues) => ({
        ...prevValues,
        accountType: accountType,
        organizationName: undefined,
        jobTitle: undefined,
        websiteUrl: undefined,
        organizationSize: undefined,
      }));
    } else if (accountType === 'organization') {
      setValues((prevValues) => ({
        ...prevValues,
        ...organizationDetails,
      }));
    }
  }, [accountType, organizationDetails]);

  const { productInstrumentation } = useProductInstrumentation();
  useEffect(() => {
    productInstrumentation?.trackJourneyStep({
      journey: 'sign-up',
      stage: 'started',
      step: 'account-type-selection',
      variant: '1.1.0',
    });
  }, []);

  return (
    <div>
      <div className="required field" style={{ marginTop: '1em' }}>
        <label>{t('signup.typeOfAccount', 'Type of Account')}</label>
      </div>
      <Form.Group widths={'equal'}>
        <AccountTypeButton
          label={t('signup.individualAccountType', 'Individual')}
          icon={'user'}
          accountType={'individual'}
          value={accountType}
          onChange={() => setAccountType('individual')}
        />
        <AccountTypeButton
          label={t('signup.organizationAccountType', 'Organization')}
          icon={'building'}
          accountType={'organization'}
          value={accountType}
          onChange={() => setAccountType('organization')}
        />
      </Form.Group>

      {accountType === 'organization' && (
        <>
          <Divider hidden />
          <OrganizationForm
            onChange={setOrganizationDetails}
            initialValues={organizationDetails}
          />
          <Divider hidden />
        </>
      )}
    </div>
  );
}

type OrganizationFormProps = {
  initialValues: OrganizationAccountDetails;
  objectPath?: string;
  onChange: (values: OrganizationAccountDetails) => void;
};

function OrganizationForm({
  initialValues,
  objectPath,
  onChange,
}: OrganizationFormProps) {
  const { t } = useTranslation();
  const prefix = objectPath ? `${objectPath}.` : '';
  const formatFieldName = (fieldName: string) => prefix + fieldName;

  useEffect(() => {
    onChange(initialValues);
  }, []);

  const handleChange = (
    field: keyof OrganizationAccountDetails,
    value: string
  ) => {
    onChange({
      ...initialValues,
      [field]: value,
    });
  };

  const organizationSizeOptions = useOrganizationSizeOptions();

  return (
    <div>
      <Form.Field>
        <InputField
          required
          name={formatFieldName('organizationName')}
          label={t('signup.organizationName', 'Organization name')}
          hideErrorLabel
          onChange={(e) => handleChange('organizationName', e.target.value)}
        />
      </Form.Field>

      <Form.Field>
        <InputField
          name={formatFieldName('jobTitle')}
          label={t('signup.jobTitleLabel', 'Job title')}
          hideErrorLabel
          onChange={(e) => handleChange('jobTitle', e.target.value)}
        />
      </Form.Field>

      <Form.Field>
        <InputField
          name={formatFieldName('websiteUrl')}
          label={t('signup.websiteUrl', 'Website URL')}
          hideErrorLabel
          onChange={(e) => handleChange('websiteUrl', e.target.value)}
        />
      </Form.Field>

      <Form.Field>
        <SelectField
          label={t('signup.organizationSize', 'Organization size')}
          name={formatFieldName('organizationSize')}
          options={organizationSizeOptions}
          hideErrorLabel
          onChange={(e, { value }) =>
            handleChange('organizationSize', value as string)
          }
        />
      </Form.Field>
    </div>
  );
}

export function AccountDetailsButton({ handleSubmit }) {
  const { t } = useTranslation();
  const { productInstrumentation } = useProductInstrumentation();
  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'account-creation',
      variant: '1.1.0',
    });
  }, []);
  return (
    <div>
      <Button
        as="button"
        type="submit"
        onClick={() => {
          sendClick('create-account');
          handleSubmit();
        }}
        primary
        size="large"
        content={t('signup.createAccount', 'Create Account')}
        fluid
      />
    </div>
  );
}

function AccountTypeButton({ label, icon, value, accountType, onChange }) {
  const isSelected = value === accountType;
  return (
    <Form.Button
      type="button"
      name={accountType}
      basic
      color={isSelected ? 'blue' : null}
      style={{
        padding: '10px',
        width: '100%',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={() => onChange(accountType)}>
      <div>
        <StyledIcon name={icon} />
      </div>
      <div
        style={{
          flex: '1 1 auto',
          fontWeight: 600,
          textAlign: 'left',
          paddingLeft: '0.5rem',
        }}>
        <span>{label}</span>
      </div>
      <div>
        {isSelected && <StyledIcon name={'circle-check'} />}
        {!isSelected && <StyledIcon name={'circle regular'} />}
      </div>
    </Form.Button>
  );
}

function StyledIcon({ name }) {
  return <Icon size="large" name={name} />;
}

function useOrganizationSizeOptions() {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        key: 'Self-employed',
        value: 'Self-employed',
        text: t('signup.organizationSizeOptionSelfEmployed', 'Self Employed'),
      },
      {
        key: '1-10',
        value: '1-10',
        text: t('signup.organizationSizeOption1-10', '1-10'),
      },
      {
        key: '11-50',
        value: '11-50',
        text: t('signup.organizationSizeOption11-50', '11-50'),
      },
      {
        key: '51-200',
        value: '51-200',
        text: t('signup.organizationSizeOption51-200', '51-200'),
      },
      {
        key: '201-500',
        value: '201-500',
        text: t('signup.organizationSizeOption201-500', '201-500'),
      },
      {
        key: '501-1000',
        value: '501-1000',
        text: t('signup.organizationSizeOption501-1000', '501-1000'),
      },
      {
        key: '1001-5000',
        value: '1001-5000',
        text: t('signup.organizationSizeOption1001-5000', '1001-5000'),
      },
      {
        key: '5001-10000',
        value: '5001-10000',
        text: t('signup.organizationSizeOption5001-10000', '5001-10000'),
      },
      {
        key: '10001+',
        value: '10001+',
        text: t('signup.organizationSizeOption10001+', '10001+'),
      },
    ],
    [t]
  );
}
