//https://flagicons.lipis.dev/

import React from 'react';

export const UKFlag = () => {
  return (
    <span className="flag uk">
      <svg
        height="10px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 480">
        <path fill="#012169" d="M0 0h640v480H0z" />
        <path
          fill="#FFF"
          d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"
        />
        <path
          fill="#C8102E"
          d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"
        />
        <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z" />
        <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z" />
      </svg>
    </span>
  );
};

export const DEFlag = () => {
  return (
    <span className="flag de">
      <svg
        height="10px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 480">
        <path fill="#ffce00" d="M0 320h640v160H0z" />
        <path fill="#000" d="M0 0h640v160H0z" />
        <path fill="#d00" d="M0 160h640v160H0z" />
      </svg>
    </span>
  );
};

export const NLFlag = () => {
  return (
    <span className="flag nl">
      <svg
        height="10px"
        xmlns="http://www.w3.org/2000/svg"
        id="flag-icons-nl"
        viewBox="0 0 640 480">
        <path fill="#21468b" d="M0 0h640v480H0z" />
        <path fill="#fff" d="M0 0h640v320H0z" />
        <path fill="#ae1c28" d="M0 0h640v160H0z" />
      </svg>
    </span>
  );
};

export const FRFlag = () => {
  return (
    <span className="flag nl">
      <svg
        height="10px"
        xmlns="http://www.w3.org/2000/svg"
        id="flag-icons-fr"
        viewBox="0 0 640 480">
        <g fillRule="evenodd" strokeWidth="1pt">
          <path fill="#fff" d="M0 0h640v480H0z" />
          <path fill="#002654" d="M0 0h213.3v480H0z" />
          <path fill="#ce1126" d="M426.7 0H640v480H426.7z" />
        </g>
      </svg>
    </span>
  );
};

export const ITFlag = () => {
  return (
    <span className="flag it">
      <svg
        height="10px"
        xmlns="http://www.w3.org/2000/svg"
        id="flag-icons-it"
        viewBox="0 0 640 480">
        <g fillRule="evenodd" strokeWidth="1pt">
          <path fill="#fff" d="M0 0h640v480H0z" />
          <path fill="#009246" d="M0 0h213.3v480H0z" />
          <path fill="#ce2b37" d="M426.7 0H640v480H426.7z" />
        </g>
      </svg>
    </span>
  );
};

export const ESFlag = () => {
  return (
    <span className="flag es">
      <svg
        height="10px"
        xmlns="http://www.w3.org/2000/svg"
        id="flag-icons-es"
        viewBox="0 0 640 480">
        <path fill="#AA151B" d="M0 0h640v480H0z" />
        <path fill="#F1BF00" d="M0 120h640v240H0z" />
      </svg>
    </span>
  );
};
