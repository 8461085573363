import React from 'react';
import { Header, Table, Button } from 'semantic';
import CopyValue from 'components/CopyValue';
import { numberWithDots } from 'utils/formatting';
import { formatPermissions } from 'utils/credentials';
import { formatDateTime } from 'utils/date';

export default class Overview extends React.Component {
  render() {
    const { credential } = this.props;
    return (
      <div>
        <Header as="h3">API Token</Header>
        <CopyValue value={credential.apiToken} label="API Token" />
        <CopyValue value={credential.providerId} label="Provider ID" />

        <Header as="h3">Getting Started</Header>

        <p>
          Comprehensive examples and reference documentation can be found at{' '}
          <Button primary as="a" href="/docs" content="/docs" />
        </p>

        <Header as="h3">Details</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>ID</Table.Cell>
              <Table.Cell>{credential.id}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{credential.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Account</Table.Cell>
              <Table.Cell>
                {credential.account ? credential.account.name : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Permissions</Table.Cell>
              <Table.Cell>
                {formatPermissions(credential.permissions, credential.context)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(credential.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(credential.updatedAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Request Count</Table.Cell>
              <Table.Cell>{numberWithDots(credential.requestCount)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
