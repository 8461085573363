import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { BillingAddress } from 'components/BillingInfo/BillingAddress';
import { BillingOrganizationInfo } from 'components/BillingInfo/OrganizationInfo';
import { BillingPaymentInfo } from 'components/BillingInfo/PaymentInfo';
import { BillingInfo } from 'components/BillingInfo/types';
import { Account } from 'types/account';
import { request } from 'utils/api';
import { ValidationErrorResponse } from 'types/api';

type UseSubmitBillingInfoMutationOptions = Omit<
  UseMutationOptions<
    BillingPaymentInfo & BillingAddress & BillingOrganizationInfo,
    ValidationErrorResponse,
    BillingPaymentInfo & BillingAddress & BillingOrganizationInfo
  >,
  'mutationFn'
> & {
  account: Account;
};

export function useSubmitBillingInfoMutation(
  options: UseSubmitBillingInfoMutationOptions
) {
  const { account, ...mutationOptions } = options;
  return useMutation({
    mutationFn: (values: BillingInfo) => {
      if (!account) {
        throw new Error('No account in context!');
      }
      return submitBillingInfo(account, values);
    },
    ...mutationOptions,
  });
}

export async function submitBillingInfo(
  account: Account,
  billingInfo: BillingInfo
) {
  let paymentInfo: Record<string, string | boolean> = {
    paymentMethod: billingInfo.paymentMethod,
  };
  if (billingInfo.paymentMethod === 'autopay') {
    paymentInfo = {
      paymentMethod: billingInfo.paymentMethod,
      accountHolderName: billingInfo.accountHolderName,
      ibanNo: billingInfo.ibanNumber,
      bicNo: billingInfo.bicNumber,
      sepaAccepted: billingInfo.sepaAccepted,
    };
  }
  let organizationInfo: Partial<Account> = {};
  if (account.type === 'organization') {
    organizationInfo = {
      vatNo: billingInfo.vatNumber,
      hasNoVatNo: billingInfo.vatNotApplicable,
      chamberOfCommerceNo: billingInfo.chamberOfCommerceNumber
        ? billingInfo.chamberOfCommerceNumber
        : undefined,
    };
  }
  return request({
    method: 'PATCH',
    path: `/1/accounts/self/billing`,
    body: {
      billing: {
        address: billingInfo.street,
        addressLine2: billingInfo.number,
        city: billingInfo.city,
        countryCode: billingInfo.countryCode,
        postalCode: billingInfo.postalCode,
        email: account.billing?.email || account.contact?.email,
        ...paymentInfo,
      },
      ...organizationInfo,
    },
  });
}
