import React from 'react';

import { request } from 'utils/api';
import Table from 'components/tables/Locations';
import { Container, Divider, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { ListHeader, SearchProvider } from 'components';
import EditFacilitatorAccountPermissions from 'components/modals/EditFacilitatorPermissions';

export default function AccountLocations({ account }) {
  const { t } = useTranslation();

  function handleDeleteItem(item) {
    return request({
      method: 'DELETE',
      path: `/2/locations/cpo/${item.id}`,
    });
  }

  function handleRequest(body) {
    return request({
      method: 'POST',
      path: '/2/locations/cpo/search',
      body: {
        ...body,
        accountId: account.id,
      },
    });
  }

  return (
    <Container>
      <SearchProvider limit={20} onDataNeeded={handleRequest}>
        <ListHeader title={t('accountLocations.title', 'Locations')}>
          <EditFacilitatorAccountPermissions
            accountId={account.id}
            trigger={<Button basic content={'Facilitators'} icon={'key'} />}
          />
        </ListHeader>
        <Divider hidden />
        <Table onDeleteItem={handleDeleteItem} />
        <Divider hidden />
      </SearchProvider>
    </Container>
  );
}
