import React, { createContext, useContext } from 'react';

interface ModalContextValue {
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

export const useModalContext = (): ModalContextValue => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  closeModal: () => void;
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({
  closeModal,
  children,
}) => {
  const value = { closeModal };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};
