import React from 'react';
import { Header, Divider } from 'semantic';
import { API_URL } from 'utils/env';
import RequestBlock from 'components/RequestBlock';

export default function Examples(props) {
  const asCurl = (method, url, token) =>
    `curl -X ${method} ${url} -H "Authorization: Token ${token}"`;

  const renderCurlCommand = (commands) =>
    commands.map((entry) => {
      const curlCommand = asCurl(entry.method, entry.url, entry.token);
      return (
        <div key={curlCommand}>
          <p>
            {entry.description} | using token: {entry.tokenType}
          </p>
          <RequestBlock
            authToken={entry.token}
            baseUrl={entry.url}
            request={{
              path: '',
              method: entry.method,
            }}
            bearerHeaderPrefix="Token"
          />
          <Divider hidden />
        </div>
      );
    });

  const ocpiCredential = props.ocpiCredential;
  const entries =
    ocpiCredential.providerContext === 'cpo'
      ? entriesAsCpo(ocpiCredential)
      : entriesAsMsp(ocpiCredential);

  return (
    <div>
      <Header as="h2">Examples</Header>

      <div>
        {Object.entries(entries).map(([key, commands]) => {
          return (
            <div key={key}>
              <h3 style={{ textTransform: 'capitalize' }}>{key}</h3>
              {renderCurlCommand(commands)}
              <Divider hidden />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getTokens(credential) {
  if (credential.role === 'receiver') {
    return {
      receiveTokenType: 'tokenC',
      receiveToken: credential.tokenC,
      callTokenType: 'tokenB',
      callToken: credential.tokenB,
    };
  } else if (credential.role === 'sender') {
    return {
      receiveTokenType: 'tokenB',
      receiveToken: credential.tokenB,
      callTokenType: 'tokenC',
      callToken: credential.tokenC,
    };
  }
}

// convert OCPI endpoints array to object for easy lookup
function convertEndpoints(endpoints) {
  const converted = {};
  endpoints.forEach((endpoint) => {
    converted[endpoint.identifier] = endpoint.url;
  });
  return converted;
}

function getLocalEndpoints(credential) {
  const ocpiApiLocalRoot = `${API_URL}/ocpi/${
    credential.providerContext === 'msp' ? 'emsp' : 'cpo'
  }/${credential.activeVersion}`;

  return {
    tokens: `${ocpiApiLocalRoot}/tokens`,
    locations: `${ocpiApiLocalRoot}/locations`,
    credentials: `${ocpiApiLocalRoot}/credentials`,
    cdrs: `${ocpiApiLocalRoot}/cdrs`,
    sessions: `${ocpiApiLocalRoot}/sessions`,
    tariffs: `${ocpiApiLocalRoot}/tariffs`,
  };
}

function entriesAsMsp(ocpiCredential) {
  const credential = ocpiCredential;

  const endpoints = convertEndpoints(credential.endpoints);
  const localEndpoints = getLocalEndpoints(credential);

  const { receiveTokenType, receiveToken, callTokenType, callToken } =
    getTokens(credential);

  const roleMsp211 = {
    tokens: [
      {
        description: 'Provide tokens',
        method: 'GET',
        url: `${localEndpoints.tokens}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'Update token',
        method: 'PUT',
        url: `${endpoints.tokens}/{country_code}/{party_id}/{token_uid}`,
        tokenType: callTokenType,
        token: callToken,
      },
      {
        description: 'Get token (client owned object)',
        method: 'GET',
        url: `${endpoints.tokens}/{country_code}/{party_id}/{token_uid}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
    cdrs: [
      {
        description: 'Get CDRS',
        method: 'GET',
        url: `${endpoints.cdrs}`,
        tokenType: callTokenType,
        token: callToken,
      },
      {
        description: 'Receive CDRS',
        method: 'POST',
        url: `${localEndpoints.cdrs}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'Get CDR (client owned object)',
        method: 'GET',
        url: `${localEndpoints.cdrs}/{cdrId}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
    sessions: [
      {
        description: 'Get sessions',
        method: 'GET',
        url: `${endpoints.sessions}`,
        tokenType: callTokenType,
        token: callToken,
      },
      {
        description: 'Get sessions',
        method: 'PUT',
        url: `${localEndpoints.sessions}/{country_code}/{party_id}/{session_id}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'Get session (client owned object)',
        method: 'PUT',
        url: `${localEndpoints.sessions}/{country_code}/{party_id}/{session_id}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
    ],
    locations: [
      {
        description: 'get locations',
        method: 'GET',
        url: `${endpoints.locations}`,
        tokenType: callTokenType,
        token: callToken,
      },
      {
        description: 'Get location (client owned object)',
        method: 'GET',
        url: `${localEndpoints.locations}/{country_code}/{party_id}/{session_id}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
    tariffs: [
      {
        description: 'get tariffs',
        method: 'GET',
        url: `${endpoints.tariffs}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
  };

  return roleMsp211;
}

function entriesAsCpo(ocpiCredential) {
  const credential = ocpiCredential;

  const endpoints = convertEndpoints(credential.endpoints);
  const localEndpoints = getLocalEndpoints(credential);

  const { receiveTokenType, receiveToken, callTokenType, callToken } =
    getTokens(credential);

  const roleCpo211 = {
    tokens: [
      {
        description: 'Retrieve tokens from MSP',
        method: 'GET',
        url: `${endpoints.tokens}`,
        tokenType: callTokenType,
        token: callToken,
      },
      {
        description: 'Retrieve token (client owned object)',
        method: 'GET',
        url: `${localEndpoints.tokens}/{country_code}/{party_id}/{token_uid}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'Push token',
        method: 'PUT',
        url: `${localEndpoints.tokens}/{country_code}/{party_id}/{token_uid}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
    ],
    cdrs: [
      {
        description: 'MSP retrieves CDRS',
        method: 'GET',
        url: `${localEndpoints.cdrs}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'send CDR',
        method: 'POST',
        url: `${localEndpoints.cdrs}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
    sessions: [
      {
        description: 'MSP retrieves sessions',
        method: 'GET',
        url: `${localEndpoints.sessions}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'Push session to MSP',
        method: 'PUT',
        url: `${endpoints.sessions}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
    locations: [
      {
        description: 'Get locations',
        method: 'GET',
        url: `${localEndpoints.locations}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
      {
        description: 'Push locations',
        method: 'PUT',
        url: `${endpoints.locations}/{country_code}/{party_id}/{location_id}`,
        tokenType: callTokenType,
        token: callToken,
      },
    ],
    tariffs: [
      {
        description: 'Get tariffs',
        method: 'GET',
        url: `${localEndpoints.tariffs}`,
        tokenType: receiveTokenType,
        token: receiveToken,
      },
    ],
  };

  return roleCpo211;
}
