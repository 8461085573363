import React from 'react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Message, Grid, Header } from 'semantic';
import Menu from './Menu';
import PageLoader from 'components/PageLoader';

import Overview from '../../MyCredential/Overview';
import { request } from 'utils/api';
import Breadcrumbs from 'components/Breadcrumbs';

import { withTranslation } from 'react-i18next';
import Logs from './Logs';

class CredentialDetails extends React.Component {
  state = {
    credentialId: this.props.match.params.id,
    credential: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    const { credentialId } = this.state;
    this.fetch(credentialId);
  }

  fetch = (credentialId) => {
    return request({
      method: 'GET',
      path: `/1/credentials/${credentialId}`,
    })
      .then(({ data }) => {
        this.setState({ credential: data, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    const { t, location } = this.props;
    const { credential, credentialId } = this.state;
    const isLogsPage = location.pathname.includes('/logs');

    return (
      <AppWrapper fluid={isLogsPage}>
        <Container fluid>
          <Breadcrumbs
            path={[
              <Link to="/credentials">
                {t('credentials.title', 'API Access')}
              </Link>,
            ]}
            active={credential?.name}
          />
        </Container>

        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Header as="h2" style={{ margin: '0' }}>
            {credential?.name}
          </Header>
        </Grid.Column>

        <Divider hidden />

        {credential && credential.deletedAt && (
          <Message error content="This credential has been deleted" />
        )}

        <Menu itemId={credentialId} />

        <Divider hidden />
        {!credential && <PageLoader />}
        {credential && (
          <Switch>
            <Route
              exact
              path="/credentials/:credentialId"
              render={(props) => (
                <Overview {...props} credential={credential} />
              )}
            />
            <Route
              exact
              path="/credentials/:credentialId/logs"
              render={(props) => <Logs {...props} credential={credential} />}
            />
          </Switch>
        )}
      </AppWrapper>
    );
  }
}

export default withTranslation()(withRouter(CredentialDetails));
