import React from 'react';
import { Route } from 'react-router-dom';

import Boot from 'components/Boot';

export default class Protected extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { component: Component, endpoint, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Boot endpoint={endpoint}>
              <Component {...this.props} {...props} />
            </Boot>
          );
        }}
      />
    );
  }
}
