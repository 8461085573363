import React from 'react';
import { set } from 'lodash-es';

import { Form, Message, Modal, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

export default function InviteEmployee({
  trigger,
  onSuccess = () => {},
  onClose = () => {},
  initialValues = {},
} = {}) {
  const [formValues, setFormValues] = React.useState({
    ...initialValues,
  });

  const [open, setOpen] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [status, setStatus] = React.useState(false);

  const { t } = useTranslation();

  function setField(name, value) {
    const newValues = { ...formValues };
    set(newValues, name, value);
    setFormValues(newValues);
  }

  async function handleSubmit() {
    setSubmitted(true);
    setStatus({
      request: true,
    });

    try {
      await request({
        method: 'PATCH',
        path: `/1/evse-invites/${formValues.id}`,
        body: {
          pricePerKwh: Number(formValues.pricePerKwh),
        },
      });
      setStatus({
        sucess: true,
      });
      setOpen(false);
      onSuccess({});
      onClose();
    } catch (error) {
      setStatus({
        error,
      });
    }
  }

  return (
    <Modal
      size="small"
      closeIcon
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('editEvsePrice.title', 'Update price settings')}
      </Modal.Header>
      <Modal.Content>
        <Form
          error={submitted && Boolean(status.error)}
          onSubmit={() => handleSubmit()}>
          {status.error && <Message error content={status.error.message} />}

          <Form.Input
            label={t('editEvsePrice.pricePerKwh')}
            required
            type="number"
            value={formValues.pricePerKwh}
            step={0.01}
            onChange={(e, { value }) => {
              setField('pricePerKwh', value);
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={status.request === true}
          disabled={status.request === true}
          primary
          content={t('button.update', 'Update')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}
