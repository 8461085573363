import React from 'react';
import { Dropdown } from 'semantic';
import InspectObject from 'components/modals/InspectObject';
import EnterpriseAccountMigrationModal from 'components/modals/EnterpriseAccountMigration';
import { useTranslation } from 'react-i18next';

export default function EnterpriseAccountMigrationActions({ item }) {
  const { t } = useTranslation();

  const migrationReadyForReview =
    item?.status === 'accepted' && item?.executions[0]?.numberOfDocuments === 0;

  const migrationInProgress =
    item?.status === 'accepted' &&
    item?.executions?.length > 0 &&
    !migrationReadyForReview;

  return (
    <>
      {item?.status === 'confirmationPending' && (
        <EnterpriseAccountMigrationModal
          startingStep="confirmation"
          intermediateData={{
            migrationPlanId: item?.id,
            accountName: item?.migrationProperties?.accountName,
            enterpriseAccountName: item?.migrationProperties?.newProviderName,
          }}
          trigger={
            <Dropdown.Item
              text={t('enterpriseAccountMigrationActions.confirm', 'Confirm')}
              icon="check"
            />
          }
        />
      )}
      {item?.status === 'accepted' && (
        <EnterpriseAccountMigrationModal
          startingStep="completion"
          trigger={
            <Dropdown.Item
              text={t('enterpriseAccountMigrationActions.confirm', 'Complete')}
              icon="flag"
              disabled={migrationInProgress}
            />
          }
          intermediateData={{
            migrationPlanId: item?.id,
          }}
        />
      )}

      <InspectObject
        name="Account Migration"
        data={item || {}}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />
    </>
  );
}
