import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { currentUserCanAccess } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { Segment, Divider, Popup, Icon } from 'semantic';
import { request } from 'utils/api';
import Table from './Table';
import {
  ListHeader,
  Breadcrumbs,
  Search,
  Layout,
  SearchFilters,
  FeatureFlag,
} from 'components';

export default function Tokens() {
  const searchRef = useRef();
  const { t } = useTranslation();

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: `/1/tokens/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: filters,
    });
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
      isUnassigned: {
        label: t('tokens.showUnassigned', 'Show Unassigned'),
        type: 'boolean',
      },
      hasMaintenanceAccess: {
        label: t('tokens.showMaintenanceTokens', 'Maintenance Tokens'),
        type: 'boolean',
      },
      isAssignedAndBlocked: {
        label: t('tokens.isAssignedAndBlocked', 'Show assigned and blocked'),
      },
      excludeEmptyCustomId: {
        label: t(
          'tokens.excludeEmptyCustomId',
          'Hide Tokens with empty custom IDs'
        ),
      },
      includeEmptyUid: {
        label: t('tokens.includeEmptyUid', 'Show tokens with empty UIDs'),
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
    };
  };

  const writeAccess = currentUserCanAccess('tokens', 'write');

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={() => getFilterMapping()}>
      <Breadcrumbs
        path={[
          <Link key="cards" to="/cards">
            {t('cards.title', 'Cards')}
          </Link>,
        ]}
        active={t('tokens.title', 'Tokens')}
      />

      <ListHeader title={t('tokens.title', 'Tokens')} />

      <Segment>
        <Layout horizontal spread stackable>
          <SearchFilters.Modal>
            <SearchFilters.Checkbox
              label={t('tokens.showUnassigned', 'Show Unassigned')}
              name="isUnassigned"
            />
            <SearchFilters.Checkbox
              label={t('tokens.showMaintenanceTokens', 'Maintenance Tokens')}
              name="hasMaintenanceAccess"
            />
            <div style={{ display: 'flex' }}>
              <SearchFilters.Checkbox
                label={t(
                  'tokens.isAssignedAndBlocked',
                  'Show assigned and blocked'
                )}
                name="isAssignedAndBlocked"
              />
              <Popup
                content={t(
                  'tokens.isAssignedAndBlockedTooltip',
                  'Only show tokens that have been assigned to an account and user but are blocked.'
                )}
                trigger={
                  <Icon
                    style={{ margin: '0.2rem 0 0 0.5rem' }}
                    color="grey"
                    name="circle-info"
                  />
                }
              />
            </div>
            <SearchFilters.Checkbox
              label={t(
                'tokens.excludeEmptyCustomId',
                'Hide Tokens with empty custom IDs'
              )}
              name="excludeEmptyCustomId"
            />
            <SearchFilters.Checkbox
              label={t('tokens.includeEmptyUid', 'Show Tokens with empty UIDs')}
              name="includeEmptyUid"
            />
            <Divider />
            <SearchFilters.DateRange
              label={t('common.createdAt')}
              name="createdAt"
            />
          </SearchFilters.Modal>

          <Layout horizontal stackable center right>
            <Search.Total />
            <SearchFilters.Search
              name="searchPhrase"
              placeholder={t('tokens.placeHolder', 'UID or Custom IDs')}
            />
          </Layout>
        </Layout>
      </Segment>

      <Search.Status noResults={t('common.noResults', 'No Results')} />

      <Table />

      <Divider hidden />
      <div
        style={{
          textAlign: 'center',
        }}>
        <Search.Pagination />
      </div>
    </Search.Provider>
  );
}
