import React from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import EditAccessGroup from 'components/modals/EditAccessGroup';
import ImportAccessGroups from 'components/modals/ImportAccessGroups';

import DataList from 'components/DataList';
import Search from 'components/SearchInput';

import { request } from 'utils/api';

import Filters from './Filters';
import Table from 'components/tables/AccessGroups';

import { Container, Header, Segment, Grid, Divider, Button } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { withTranslation } from 'react-i18next';

const itemLimit = 100;

class AccessGroups extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  handleDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/access-groups/${item.id}`,
    }).then(() => this.refresh());
  };

  handleRequest = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/access-groups/search',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  handleFilterChange = (filters) => {
    this.setState(
      {
        filters: filters.reduce((result, item) => {
          return {
            ...result,
            ...(item.apiFilter || {}),
          };
        }, {}),
      },
      () => this.refresh()
    );
  };

  handleOnSearch = (search) => {
    this.setState(
      {
        searchId: search,
      },
      () => this.refresh()
    );
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { t } = this.props;
    const writeAccess = currentUserCanAccessProviderEndpoint(
      'accessGroups',
      'write'
    );
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[<Link to="/charging-stations">Charging Stations</Link>]}
            active={t('accessGroups.title', 'Access Groups')}
          />
          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={10}
              textAlign="right"
              verticalAlign="middle">
              <ImportAccessGroups
                onClose={() => this.refresh()}
                trigger={
                  <Button
                    basic
                    content={t(
                      'users.importAccessGroups',
                      'Import Access Groups'
                    )}
                    icon="upload"
                    disabled={!writeAccess}
                  />
                }
              />
              <EditAccessGroup
                onClose={() => this.refresh()}
                trigger={
                  <Button
                    primary
                    disabled={!writeAccess}
                    content="New Access Group"
                    icon="plus"
                  />
                }
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('accessGroups.title', 'Access Groups')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={10}>
                  <Filters onChange={this.handleFilterChange}></Filters>
                </Grid.Column>
                <Grid.Column width={6}>
                  <Search
                    placeholder="Search by ID, Account, etc."
                    onChange={this.handleOnSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <DataList
            key={this.state.dataKey}
            limit={itemLimit}
            onRequest={this.handleRequest}>
            <Table
              onDeleteItem={this.handleDeleteItem}
              onRefresh={() => this.refresh()}
            />
          </DataList>
          <Divider hidden />
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(AccessGroups);
