import React from 'react';
import {
  Button,
  Divider,
  Grid,
  Label,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic';
import request from '../../../utils/api/request';
import { set, truncate } from 'lodash-es';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SearchProvider, Filters, Layout } from '../../../components';
import { formatDateTime } from '../../../utils/date';
import ViewApiLogEntry from '../../../components/modals/ViewApiLogEntry';
import ExpandableJson from '../../../components/ExpandableJson';
import ExpandJsonButton from '../../../components/ExpandJsonButton';
import styled from 'styled-components';

const StyledRow = styled(Grid.Row)`
  &&& {
    border-top: 1px solid #ccc;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    cursor: pointer;
    row-gap: 5px;
  }
  &:hover {
    background-color: #f5f5f5;
  }
  &&& > div.column {
    padding-inline: 5px;
  }
  & .break {
    overflow-wrap: break-word;
  }
`;

function getStateromQueryString(search) {
  if (!search) return;
  const params = new URLSearchParams(search);
  return { keyword: params.get('requestId') };
}

class CredentialLogs extends React.Component {
  requestUrlPrefix = this.props.location.pathname.includes('/my-credentials')
    ? '/1/credentials/mine'
    : '/1/credentials';
  state = {
    selectedItem: null,
    initialFilters: getStateromQueryString(this.props.location.search),
    collapsedLevel: 2,
  };

  onDataNeeded = async (body) => {
    const { credential } = this.props;

    this.setState({ selectedItem: null });

    if (body['response.status']) {
      set(
        body,
        'response.status'.split('.'),
        JSON.parse(body['response.status'])
      );
    }
    if (body['request.method']) {
      set(body, 'request.method'.split('.'), body['request.method']);
    }

    const resp = await request({
      method: 'POST',
      path: `${this.requestUrlPrefix}/${credential.id}/logs/search`,
      body,
    });

    this.setState({ selectedItem: resp.data[0] }, () =>
      this.updateQueryString()
    );

    return resp;
  };

  getColorForHttpStatus = (status) => (status > 204 ? 'orange' : 'green');

  updateQueryString() {
    const pathName = this.props.location.pathname;
    const queryString = this.state.selectedItem
      ? `?requestId=${this.state.selectedItem.requestId}`
      : '';
    this.props.history.replace(`${pathName}${queryString}`);
  }

  setActiveItem = (item) =>
    this.setState({ selectedItem: item }, () => this.updateQueryString());

  render() {
    const { credential } = this.props;
    const { selectedItem, initialFilters, collapsedLevel } = this.state;

    return (
      <SearchProvider
        filters={initialFilters}
        limit={15}
        onDataNeeded={this.onDataNeeded}>
        {({ items, loading, error }) => (
          <Grid>
            <Grid.Row style={{ rowGap: '15px' }}>
              <Grid.Column mobile={16} tablet={9} computer={9}>
                <Layout horizontal gap wrap>
                  <Filters.Search
                    style={{ width: '300px' }}
                    name="keyword"
                    placeholder="Filter by Path or Request Id"
                  />
                  <Filters.Dropdown
                    style={{ width: '120px' }}
                    label=""
                    name="response.status"
                    placeholder="Status"
                    options={[
                      {
                        value: JSON.stringify({
                          $gte: 200,
                          $lt: 300,
                        }),
                        text: 'Succeeded',
                      },
                      {
                        value: JSON.stringify({
                          $gt: 300,
                        }),
                        text: 'Failed',
                      },
                    ]}
                  />
                  <Filters.Dropdown
                    label=""
                    name="request.method"
                    placeholder="Method"
                    options={[
                      { value: 'GET', text: 'GET' },
                      { value: 'POST', text: 'POST' },
                      { value: 'PATCH', text: 'PATCH' },
                      { value: 'DELETE', text: 'DEL' },
                    ]}
                  />
                </Layout>
                <Divider hidden />
                {loading ? (
                  <Segment style={{ minHeight: '5em' }}>
                    <Loader active inline />
                  </Segment>
                ) : error ? (
                  <Message error content={error.message} />
                ) : items.length === 0 ? (
                  <Message>No Results</Message>
                ) : (
                  <>
                    <Grid padded="horizontally">
                      {items.map((item) => (
                        <StyledRow
                          key={item.id}
                          verticalAlign={'middle'}
                          style={{
                            ...(selectedItem?._id === item._id
                              ? { borderLeft: '4px solid #ccc' }
                              : { paddingLeft: '4px' }),
                          }}
                          onClick={() => this.setActiveItem(item)}>
                          <Grid.Column
                            mobile={10}
                            tablet={4}
                            computer={2}
                            largeScreen={2}>
                            <Label
                              color={this.getColorForHttpStatus(
                                item.response.status
                              )}>
                              {item.response.status}
                            </Label>
                          </Grid.Column>
                          <Grid.Column
                            mobile={6}
                            tablet={4}
                            computer={3}
                            largeScreen={2}>
                            <Label>{item.request.method.toUpperCase()}</Label>
                          </Grid.Column>
                          <Grid.Column
                            mobile={10}
                            tablet={5}
                            computer={6}
                            largeScreen={7}
                            className={'break'}>
                            {truncate(item.request.path, { length: 30 })}
                          </Grid.Column>
                          <Grid.Column
                            mobile={6}
                            tablet={3}
                            computer={5}
                            largeScreen={5}
                            className={'break'}>
                            {formatDateTime(item.createdAt)}
                          </Grid.Column>
                        </StyledRow>
                      ))}
                    </Grid>
                    <Divider hidden />
                    <SearchProvider.Pagination />
                  </>
                )}
              </Grid.Column>
              {selectedItem && (
                <Grid.Column mobile={16} tablet={7} computer={7}>
                  <h2
                    id="requestTitle"
                    style={{
                      marginTop: '10px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                    title={`${selectedItem.request.method} ${selectedItem.request.path}`}>
                    {selectedItem.request.method}{' '}
                    {truncate(selectedItem.request.path, { length: 28 })}
                    <ViewApiLogEntry
                      centered={false}
                      credential={credential}
                      request={selectedItem.request}
                      requestId={selectedItem.requestId}
                      trigger={
                        <Button
                          compact
                          floated="right"
                          icon="up-right-and-down-left-from-center"
                          basic
                        />
                      }
                    />
                  </h2>
                  <Table definition style={{ overflowWrap: 'anywhere' }}>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Request Id</Table.Cell>
                        <Table.Cell>
                          <code>{selectedItem.requestId}</code>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Path</Table.Cell>
                        <Table.Cell>
                          <code>{selectedItem.request.path}</code>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>IP Address</Table.Cell>
                        <Table.Cell>
                          <code>{selectedItem.request.ip}</code>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Time</Table.Cell>
                        <Table.Cell>
                          <code>{formatDateTime(selectedItem.createdAt)}</code>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  {selectedItem.response.body && (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <h3>Response Body</h3>
                        <ExpandJsonButton
                          collapsedLevel={collapsedLevel}
                          onClick={() =>
                            this.setState({
                              collapsedLevel: collapsedLevel ? false : 2,
                            })
                          }></ExpandJsonButton>
                      </div>
                      <ExpandableJson
                        dark
                        height={500}
                        collapsedLevel={collapsedLevel}
                        scrollable
                        name="Body"
                        object={selectedItem.response.body}
                      />
                    </>
                  )}
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        )}
      </SearchProvider>
    );
  }
}

export default withRouter(withTranslation()(CredentialLogs));
