import React, { useEffect, useState } from 'react';
import { Form, Dropdown } from 'semantic';

const amPmOptions = [
  { key: 'AM', text: 'AM', value: 'AM' },
  { key: 'PM', text: 'PM', value: 'PM' },
];

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {ClockTimeObj} [props.value]
 * @param {boolean} props.disabled
 * @param {function} props.onChange
 * @param {function} [props.validate]
 * @returns {React.JSX.Element}
 */
export default function TimeObjectInput({
  label,
  value = null,
  onChange,
  disabled,
  validate,
}) {
  const [formValue, setFormValue] = useState(timeObjectToString(value));
  const [amPm, setAmPm] = useState(timeObjectToAmPm(value));
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    setFormValue(timeObjectToString(value));
    setAmPm(timeObjectToAmPm(value));
  }, [value]);

  const doValidation = (nextTime) => {
    try {
      validate?.(nextTime);
      setValidationError(null);
      return true;
    } catch (e) {
      setValidationError({ content: e.message });
      return false;
    }
  };

  const validateFormValue = (nextFormValue) => {
    try {
      validateTimeInput(nextFormValue);
      return true;
    } catch (e) {
      setValidationError({ content: e.message });
      return false;
    }
  };

  useEffect(() => {
    doValidation(value);
  }, [validate]);

  const onTimeComponentChange = (timeString, nextAmPm) => {
    let nextTime;
    if (!validateFormValue(timeString)) {
      return;
    }
    setAmPm(nextAmPm);
    nextTime = stringToTimeObject(timeString, nextAmPm);
    doValidation(nextTime);
    onChange?.(nextTime);
  };

  return (
    <Form.Field>
      <label>{label}</label>
      <Form.Input
        action={
          <Dropdown
            button
            basic
            options={amPmOptions}
            value={amPm}
            onChange={(e, { value }) => onTimeComponentChange(formValue, value)}
          />
        }
        labelPosition={'right'}
        icon={'clock'}
        iconPosition={'left'}
        disabled={disabled}
        value={formValue}
        onChange={(e) => setFormValue(e.target.value)}
        onBlur={() => onTimeComponentChange(formValue, amPm)}
        error={validationError}
      />
    </Form.Field>
  );
}

const validTimePattern =
  /^(?<hour>\d{1,2})(?::?(?<minute>\d{2}))?(?<pm>p|Pm|M)?/;

function validateTimeInput(formValue) {
  if (!formValue.match(validTimePattern)) {
    throw new Error(`${formValue} is not a valid time`);
  }
}

/**
 *
 * @param {{hour: number, minute: number}} value
 * @returns {string}
 */
function timeObjectToString(value) {
  value = value || { hour: 0, minute: 0 };
  let hour = (value.hour || 0) % 12;
  if (hour === 0) {
    hour = 12;
  }
  return `${hour.toString().padStart(2, '0')}:${(value.minute || 0)
    .toString()
    .padStart(2, '0')}`;
}

function timeObjectToAmPm(value) {
  if (!value) {
    return 'AM';
  }
  if (value.hour < 12) {
    return 'AM';
  }
  return 'PM';
}

/**
 *
 * @param {string} formValue
 * @param {string} amPm
 * @returns {{hour: number, minute: number}}
 */
function stringToTimeObject(formValue, amPm) {
  const parsed = validTimePattern.exec(formValue)?.groups;
  const nextTime = {
    hour: Number(parsed?.hour || '0'),
    minute: Number(parsed?.minute || '0'),
  };
  if (amPm === 'PM') {
    nextTime.hour += 12;
  }
  if (nextTime.hour === 24 && nextTime.minute === 0) {
    return { hour: 0, minute: 0 };
  }
  return nextTime;
}
