import React, { useState, useEffect } from 'react';
import { Message, Loader, Segment, Form } from 'semantic';
import { request } from 'utils/api';
import { simpleOptions } from 'utils/form';

function optionsForContext(maxValue, permissionValues) {
  if (maxValue === 'read-write') {
    return simpleOptions(permissionValues);
  }
  if (maxValue === 'read') {
    return simpleOptions(['none', 'read']);
  }
  return simpleOptions(['none']);
}

function PermissionsSelector(props) {
  const [permissionsState, setPermissionsState] = useState({
    permissions: null,
    isLoading: true,
    error: false,
    value: { ...props.value },
  });

  useEffect(() => {
    fetchPermissions();
  }, []);

  async function fetchPermissions() {
    try {
      const { data } = await request({
        path: '/1/credentials/permissions',
        method: 'GET',
      });
      setPermissionsState((prevState) => ({
        ...prevState,
        permissions: data,
        isLoading: false,
      }));
      setDefaultPermissions(data);
    } catch (error) {
      setPermissionsState((prevState) => ({
        ...prevState,
        error,
        isLoading: false,
      }));
    }
  }

  function setField(endpoint, permissionLevel) {
    const { value } = permissionsState;
    value[endpoint] = permissionLevel;
    setPermissionsState((prevState) => ({
      ...prevState,
      value,
    }));
    props.onChange(value);
  }

  function setDefaultPermissions({ endpoints, permissionDefaultValue }) {
    const { value } = permissionsState;
    endpoints.forEach((endpoint) => {
      if (!value[endpoint]) {
        value[endpoint] = permissionDefaultValue;
      }
    });
    setPermissionsState((prevState) => ({
      ...prevState,
      value,
    }));
    props.onChange(value);
  }

  const { permissions, isLoading, error, value } = permissionsState;

  if (error) return <Message error content={error.message} />;
  if (isLoading) return <Loader active />;

  const { endpointDefinitions, permissionValues, permissionDefaultValue } =
    permissions;
  return (
    <Segment>
      {Object.keys(endpointDefinitions)
        .filter((endpoint) => {
          const { contexts } = endpointDefinitions[endpoint];
          const maxValue = contexts[props.context] || 'none';
          if (maxValue === 'none') return false;
          return true;
        })
        .map((endpoint) => {
          const { contexts } = endpointDefinitions[endpoint];
          const maxValue = contexts[props.context] || 'none';
          const options = optionsForContext(maxValue, permissionValues);
          return (
            <Form.Select
              key={endpoint}
              value={value[endpoint] || permissionDefaultValue}
              options={options}
              label={endpointDefinitions[endpoint].label.en}
              onChange={(e, { value }) => setField(endpoint, value)}
              type="text"
            />
          );
        })}
    </Segment>
  );
}

export default PermissionsSelector;
