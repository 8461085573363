import { HUBSPOT_PORTAL_ID } from './env';

export function trackCustomBehavioralEvent(
  eventName: string,
  properties: Record<string, any>
) {
  const _hsq = (window as any)._hsq || [];
  // Track the event with HubSpot
  _hsq.push([
    'trackCustomBehavioralEvent',
    {
      name: `pe${HUBSPOT_PORTAL_ID}_${eventName}`,
    },
  ]);
}

export function identifyUser(props: Record<string, any>) {
  const _hsq = (window as any)._hsq || [];
  const email = props.email;

  _hsq.push([
    'identify',
    {
      email,
    },
  ]);
  _hsq.push(['trackPageView']);
}
