import React from 'react';
import DataList from 'components/DataList';

import { request } from 'utils/api';

import Table from './Table';

const itemLimit = 20;

export default class EvseControllers extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  handleRequest = (filters = {}) => {
    const { location } = this.props;
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: `/2/locations/cpo/${location.id}/evse-controllers/search`,
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  handleOnSearch = (search) => {
    this.setState(
      {
        searchId: search,
      },
      () => this.refresh()
    );
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { location } = this.props;
    return (
      <DataList
        key={this.state.dataKey}
        limit={itemLimit}
        onRequest={this.handleRequest}>
        <Table
          location={location}
          onDeleteItem={this.handleDeleteItem}
          onRefresh={() => this.refresh()}
        />
      </DataList>
    );
  }
}
