import React, { useRef } from 'react';
import { Form, Header, Table } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { formatDateTime } from 'utils/date';
import { Confirm, FeatureFlag, SecretSpan } from 'components';
import RoamingActivity from 'components/roaming/RoamingActivity';
import styles from './TokenOverview.module.css';
import { request } from 'utils/api';
import { Icon } from 'semantic';

interface Props {
  token: any;
}

const TokenOverview: React.FC<Props> = ({ token }) => {
  const blockTimeout = useRef<NodeJS.Timeout>(null);
  const [showBlockSuccess, setShowBlockSuccess] = React.useState(false);
  const [blocked, setBlocked] = React.useState(!token?.valid);

  const [roamingMode, setRoamingMode] = React.useState(token?.roamingMode);
  const [showRoamingSuccess, setShowRoamingSuccess] = React.useState(false);
  const roamingModeTimeout = useRef<NodeJS.Timeout>(null);
  const { t } = useTranslation();

  const handleTokenBlock = async (block: boolean) => {
    try {
      await request({
        method: 'POST',
        path: `/1/tokens/${token.id}/block`,
        body: {
          isBlocked: block,
        },
      });
      setBlocked(block);
      setShowBlockSuccess(true);
      if (blockTimeout.current) {
        clearTimeout(blockTimeout.current);
      }
      blockTimeout.current = setTimeout(() => {
        setShowBlockSuccess(false);
      }, 3000);
    } catch (err) {}
  };

  const handleChangeRoamingMode = async (roamingMode: string) => {
    try {
      await request({
        method: 'POST',
        path: `/1/tokens/${token?.id}/roaming`,
        body: { roamingMode },
      });
      setRoamingMode(roamingMode);
      setShowRoamingSuccess(true);
      if (roamingModeTimeout.current) {
        clearTimeout(roamingModeTimeout.current);
      }
      roamingModeTimeout.current = setTimeout(() => {
        setShowRoamingSuccess(false);
      }, 3000);
    } catch (err) {}
  };

  const description = (roamingMode: string) => {
    switch (roamingMode) {
      case 'ALWAYS':
        return t(
          'tokenOverview.roamingMode.alwaysDesc',
          'The CPO should never attempt to authorize the token remotely by making a call to us. It should always use its locally cached value.'
        );
      case 'ALLOWED_OFFLINE':
        return t(
          'tokenOverview.roamingMode.allowedOfflineDesc',
          'The CPO should always first attempt to authorize the token remotely by making a call to us. If that call fails due to technical reasons, it can fall back to its locally cached value.'
        );
      case 'NEVER':
        return t(
          'tokenOverview.roamingMode.neverDesc',
          'The CPO must always authorize the token remotely by making a call to us. If this call fails, user should not be able to charge.'
        );
      default:
        return '';
    }
  };

  return (
    <>
      <Header as="h3">{t('tokenOverview.basic', 'Basic details')}</Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>ID</Table.Cell>
            <Table.Cell>{token?.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('tokenOverview.type', 'Type')}</Table.Cell>
            <Table.Cell>{token?.type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('tokenOverview.customId', 'Auth ID')}</Table.Cell>
            <Table.Cell>{token?.customId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('tokenOverview.visualNumber', 'Visual number')}
            </Table.Cell>
            <Table.Cell>{token?.visualNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>UID</Table.Cell>
            <Table.Cell>
              <SecretSpan value={token?.uid} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>{t('tokenOverview.status', 'Status')}</Table.Cell>
            <Table.Cell>
              <div className={styles.roamingModeFieldContainer}>
                <Confirm
                  header={t(
                    'tokenOverview.blockDialogHeader',
                    'Are you sure you want to {{action}} this token?',
                    { action: blocked ? 'unblock' : 'block' }
                  )}
                  content={
                    blocked
                      ? t(
                          'deleteDialog.unblockDialogDescription',
                          'Unblock this token and allow it to be used for charging? This action will broadcast the change to the entire roaming network.'
                        )
                      : t(
                          'deleteDialog.blockDialogDescription',
                          'Block this token and prevent it from being used for charging? This action will broadcast the change to the entire roaming network.'
                        )
                  }
                  trigger={
                    <Button size="mini" color={blocked ? 'olive' : 'red'}>
                      {!blocked
                        ? t('tokenOverview.blocked', 'Block')
                        : t('tokenOverview.unblocked', 'Unblock')}
                    </Button>
                  }
                  onConfirm={async () => {
                    handleTokenBlock(!blocked);
                  }}
                />
                {showBlockSuccess && (
                  <Icon name="circle-check" size="large" color="olive" />
                )}
              </div>
              <p className={styles.description}>
                {blocked
                  ? t(
                      'tokenOverview.blockedDescription',
                      'The token is currently blocked and the driver will not be able to charge on charging stations within the roaming network.'
                    )
                  : t(
                      'tokenOverview.unblockedDescription',
                      'The token is active and the driver should be able to charge on charging stations within the roaming network. Use the sync button in the roaming activity section below to push this token to all connected CPOs.'
                    )}
              </p>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('tokenOverview.createdAt', 'Created at')}
            </Table.Cell>
            <Table.Cell>{formatDateTime(token?.createdAt)}</Table.Cell>
          </Table.Row>
          <FeatureFlag feature="token_roaming_mode">
            <Table.Row>
              <Table.Cell>
                {t('tokenOverview.roamingModeTitle', 'Roaming mode')}
              </Table.Cell>
              <Table.Cell>
                <p className={styles.roamingModeFieldContainer}>
                  <Form.Select
                    name="roamingMode"
                    defaultValue={token?.roamingMode}
                    onChange={(e, { value }) => handleChangeRoamingMode(value)}
                    options={[
                      {
                        key: 'always',
                        text: t(
                          'tokenOverview.roamingMode.always',
                          'Skip remote authorization'
                        ),
                        value: 'ALWAYS',
                      },
                      {
                        key: 'allowedOffline',
                        text: t(
                          'tokenOverview.roamingMode.allowedOffline',
                          'Prefer remote authorization (Recommended)'
                        ),
                        value: 'ALLOWED_OFFLINE',
                      },
                      {
                        key: 'never',
                        text: t(
                          'tokenOverview.roamingMode.never',
                          'Require remote authorization'
                        ),
                        value: 'NEVER',
                      },
                    ]}
                  />
                  {showRoamingSuccess && (
                    <Icon name="circle-check" size="large" color="olive" />
                  )}
                </p>
                <p className={styles.description}>{description(roamingMode)}</p>
              </Table.Cell>
            </Table.Row>
          </FeatureFlag>
        </Table.Body>
      </Table>
      <FeatureFlag feature="roaming_activity">
        <RoamingActivity
          live={true}
          entities={[{ type: 'token', id: token?.uid }]}
        />
      </FeatureFlag>
    </>
  );
};

export default TokenOverview;
