import { AccountProvider } from 'contexts/account';
import React, { useRef, useState } from 'react';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WelcomeModal from 'screens/Home/Welcome/WelcomeModal';
import NewChargingStationModal from 'components/modals/NewChargingStation';
import InviteEmployeeModal from 'screens/MyLocations/InviteEmployee';
import InviteSingleUserModal from 'components/modals/InviteSingleUser';
import { RequestCardModal } from 'components/modals/RequestCard';

type User = {
  numCards: number;
  numEvseControllers: number;
  accountType: string;
  account?: {
    signup?: {
      journey?: string;
      billingPlanId?: string;
    };
  };
};

type ActiveModal =
  | 'none'
  | 'welcome'
  | 'new-charging-station'
  | 'new-charge-card'
  | 'invite-single-user'
  | 'invite-employee';

function useModalState() {
  const { user } = useUser();
  const { hasFeature } = useFeatures();
  const [activeModal, setActiveModal] = useState<ActiveModal>(
    !localStorage.getItem('dismissWelcomeModal') &&
      !user?.numCards &&
      !user?.numEvseControllers
      ? ((): ActiveModal => {
          if (!hasFeature('request_card_v2')) {
            return 'none';
          }
          if (user?.account?.signup?.journey === 'cpo') {
            return 'new-charging-station';
          }
          if (user?.account?.signup?.journey === 'msp') {
            return 'new-charge-card';
          }
          return 'welcome';
        })()
      : 'none'
  );
  return [activeModal, setActiveModal];
}

function closingWelcomeModal(setActiveModal) {
  setActiveModal('none');
  localStorage.setItem('dismissWelcomeModal', 'true');
}

export default function HomeModalController() {
  const { t } = useTranslation();
  const { user, fetchUser }: { user: User; fetchUser: () => void } = useUser();

  const [activeModal, setActiveModal] = useModalState();

  const myUsersLink = useRef<HTMLLinkElement>();
  const myCardsLink = useRef<HTMLLinkElement>();

  return (
    <AccountProvider>
      <WelcomeModal
        isOpen={activeModal === 'welcome'}
        onClose={() => closingWelcomeModal(setActiveModal)}
        onNewChargeCard={() => setActiveModal('new-charge-card')}
        onNewChargingStation={() => setActiveModal('new-charging-station')}
        onNewUser={() => setActiveModal('invite-single-user')}
        onNewEmployee={() => setActiveModal('invite-employee')}
      />

      {activeModal === 'new-charging-station' && (
        <NewChargingStationModal
          isOpen
          onClose={() => setActiveModal('welcome')}
          onSuccess={() => {
            fetchUser();
          }}
        />
      )}
      {activeModal === 'new-charge-card' && (
        <RequestCardModal
          title={t('home.welcome.request_card.title', 'Request Card or Tag')}
          isOpen
          duringSignup={true}
          onClose={() => setActiveModal('welcome')}
          onSuccess={() => {
            myCardsLink.current?.click();
            fetchUser();
          }}
          initialValues={{
            type: 'card',
            billingPlanId: user.account?.signup?.billingPlanId,
          }}
        />
      )}
      {activeModal === 'invite-single-user' && (
        <InviteSingleUserModal
          onboardAccountRoles
          open
          onClose={() => setActiveModal('welcome')}
          onSuccess={() => {
            myUsersLink.current?.click();
            fetchUser();
          }}
        />
      )}
      {activeModal === 'invite-employee' && (
        <InviteEmployeeModal
          isOpen
          onClose={() => setActiveModal('welcome')}
          onSuccess={() => {
            myCardsLink.current?.click();
            fetchUser();
          }}
        />
      )}

      <Link
        ref={myUsersLink}
        to="/my-account/users"
        style={{ display: 'none' }}
      />
      <Link ref={myCardsLink} to="/my-cards" style={{ display: 'none' }} />
    </AccountProvider>
  );
}
