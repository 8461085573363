import React from 'react';
import { Container, Divider, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Provider } from 'types/provider';
import { currentUserCanAccess } from 'utils/roles';

interface Props {
  provider?: Partial<Provider>;
}

const ProviderMenu: React.FC<Props> = ({ provider }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Menu pointing secondary stackable>
        {currentUserCanAccess('providers', 'read') && (
          <Menu.Item
            exact
            content={t('providerMenu.general', 'General')}
            to={`/providers/${provider?.id}`}
            as={NavLink}
          />
        )}
        {provider?.enableSocialLogin &&
          currentUserCanAccess(
            'providers-sensitive-configuration',
            'write'
          ) && (
            <Menu.Item
              exact
              content={t('providerMenu.social', 'OpenID configuration')}
              to={`/providers/${provider?.id}/social-login`}
              as={NavLink}
            />
          )}
      </Menu>
      <Divider hidden />
    </Container>
  );
};

export default ProviderMenu;
