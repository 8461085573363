import React from 'react';
import { NavLink } from 'react-router-dom';
import bem from 'helpers/bem';

class SidebarLayoutLink extends React.Component {
  render() {
    const style = {};
    const { activeColor, ...props } = this.props;
    if (activeColor) {
      style.borderLeft = '3px solid ' + activeColor;
      style.backgroundColor = `${activeColor}19`;
    }
    return (
      <NavLink
        className={this.getBlockClass()}
        activeStyle={style}
        {...props}
      />
    );
  }
}

export default bem(SidebarLayoutLink);
