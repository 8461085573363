import React, { useContext, useEffect, useState } from 'react';
import { AppWrapper, Confirm } from 'components';
import { request } from 'utils/api';
import { Container, Grid, Header, Button, Message, Loader } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts/user';
import { Breadcrumbs } from 'components';
import InspectObject from 'components/modals/InspectObject';
import CdrTable from 'components/CdrTable';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { CdrStatuses } from 'utils/constants';

export default function CdrDetail({ match }) {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { id: cdrId } = match.params;
  const [cdr, setCdr] = useState();
  const [loading, setLoading] = useState(true);
  const writeAccess = currentUserCanAccessProviderEndpoint('cdrs', 'write');

  async function fetchCdr() {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/cdrs/${cdrId}`,
      });
      setCdr(data);
    } finally {
      setLoading(false);
    }
  }

  const handleReject = () => {
    return request({
      method: 'POST',
      path: `/1/cdrs/${cdrId}/status`,
      body: { status: CdrStatuses.REJECTED },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchCdr();
    })();
  }, [cdrId]);

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link key="cards" to="/cards">
              {t('cards.title', 'Cards')}
            </Link>,
            <Link key="sessions" to="/cards/cdrs">
              {t('cdrs.title', 'CDRs')}
            </Link>,
          ]}
          active={cdr && cdr.id}
        />
        <Grid>
          <Grid.Column mobile={4} tablet={6} computer={8}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('cdrs.singleCdr', 'CDR')}
            </Header>
          </Grid.Column>
          <Grid.Column mobile={12} tablet={10} computer={8} textAlign={'right'}>
            <Confirm
              header={t(
                'cdrs.rejectOneHeader',
                'Are you sure you want to reject {{name}}?',
                { name: cdr?.cdrId }
              )}
              content={t(
                'cdrs.rejectOneContent',
                'The status will be changed to rejected.'
              )}
              trigger={
                <Button
                  basic
                  icon="eject"
                  content="Reject"
                  disabled={!writeAccess}
                />
              }
              button={t('cdrs.rejectButton', 'Reject')}
              onConfirm={async () => {
                await handleReject();
                await fetchCdr();
              }}
            />
            <InspectObject
              name="CDR"
              data={cdr}
              trigger={<Button basic content="Inspect" icon="code" />}
            />
          </Grid.Column>
        </Grid>
        {cdr && cdr.deletedAt && (
          <Message
            error
            content={t('cdrs.deleted', 'This cdr has been deleted.')}
          />
        )}
        {loading ? (
          <Loader active inline="centered" />
        ) : (
          <CdrTable cdr={cdr} user={user} />
        )}
      </Container>
    </AppWrapper>
  );
}
