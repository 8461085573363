import { ReactNode, createContext } from 'react';

export type ToastContextProps = {
  success: (content: ReactNode) => void;
  info: (content: ReactNode) => void;
  error: (content: ReactNode) => void;
};

export const ToastContext = createContext<ToastContextProps>({
  success: () => null,
  info: () => null,
  error: () => null,
});
