import React from 'react';
import { set, merge } from 'lodash-es';
import { request } from 'utils/api';

import { Form, Message, Modal, Radio, Button } from 'semantic';
import { withTranslation } from 'react-i18next';

class StopSession extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    type: 'soft',
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  handleSubmit = () => {
    const { initialValues, stopSessionForLocation } = this.props;
    this.setState({
      submitted: true,
      loading: true,
      error: null,
    });

    const path = stopSessionForLocation
      ? `/2/locations/cpo/${initialValues.locationId}/sessions/${initialValues.id}/cancel`
      : `/2/sessions/cpo/${initialValues.id}/cancel`;

    request({
      method: 'POST',
      path,
      body: {
        type: this.state.type,
      },
    })
      .then(() => {
        this.setState({ loading: false });
        if (this.props.onClose) this.props.onClose();
        this.setState({
          open: false,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    this.setState({
      submitted: false,
      formValues: merge({}, this.state.formValues, set({}, name, value)),
    });
  }
  handleChange = (e, { value }) => this.setState({ type: value });
  render() {
    const { trigger, t } = this.props;
    const { submitted, open, loading, error } = this.state;
    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        trigger={trigger}
        onClose={() => {
          if (this.props.onClose) this.props.onClose();
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false,
          });
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Stop Session</Modal.Header>
        <Modal.Content>
          <Form
            id="StopSession-form"
            error={submitted && Boolean(error)}
            onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}
            <Form.Field>
              <Radio
                label={t(
                  'stopSession.softStrop',
                  'Tell device to stop session. This will cleanly end the session and include it in billing.'
                )}
                name="type"
                value="soft"
                checked={this.state.type === 'soft'}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label={t(
                  'stopSession.hardStop',
                  'Force end transaction. This will end and exclude the session from billing. (Needed for ConcurrentTX)'
                )}
                name="type"
                value="hard"
                checked={this.state.type === 'hard'}
                onChange={this.handleChange}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={'Update'}
            form="StopSession-form"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(StopSession);
