import React from 'react';

import { useTranslation } from 'react-i18next';
import { Label, SemanticCOLORS } from 'semantic-ui-react';

import {
  EvseControllerBackgroundJobStatus,
  EvseControllerBackgroundJobWorkflowMetrics,
} from 'types/evse-controller-background-job-execution';

type Props = {
  status: EvseControllerBackgroundJobStatus;
};

export default function EvseControllerBackgroundJobExecutionStatus({
  status,
}: Props) {
  const { t } = useTranslation();

  return (
    <Label
      color={getEvseControllerBackgroundJobStatusColor(status)}
      content={getEvseControllerBackgroundJobStatusText(status, t)}
    />
  );
}

type CountsProps = {
  status: EvseControllerBackgroundJobStatus;
  workflowMetrics?: EvseControllerBackgroundJobWorkflowMetrics;
};

export function EvseControllerBackgroundJobExecutionStatusCountsList({
  status,
  workflowMetrics,
}: CountsProps) {
  if (!workflowMetrics || !workflowMetrics.statusesCount) return null;

  const { t } = useTranslation();
  const statusesCount = new Map(Object.entries(workflowMetrics.statusesCount));

  const statusesToCheck = [
    EvseControllerBackgroundJobStatus.Completed,
    EvseControllerBackgroundJobStatus.Failed,
    EvseControllerBackgroundJobStatus.Cancelled,
  ];

  const texts = statusesToCheck.flatMap((statusKey) => {
    const count = statusesCount.get(statusKey) || 0;
    return count > 0
      ? `${count} ${getEvseControllerBackgroundJobStatusText(statusKey, t)}`
      : [];
  });

  // Check if the current status is either InProgress or PartlyCompleted
  if (
    status === EvseControllerBackgroundJobStatus.InProgress ||
    status === EvseControllerBackgroundJobStatus.PartlyCompleted
  ) {
    return (
      <p>
        {texts.map((text, index) => (
          <span style={{ fontSize: '12px' }} key={index}>
            {text}
            {index < texts.length - 1 && ', '}
          </span>
        ))}
      </p>
    );
  }

  return null; // Return null if the status does not match
}

export function getEvseControllerBackgroundJobStatusColor(
  status: EvseControllerBackgroundJobStatus
): SemanticCOLORS {
  switch (status) {
    case EvseControllerBackgroundJobStatus.InProgress:
      return 'blue';
    case EvseControllerBackgroundJobStatus.Cancelling:
      return 'orange';
    case EvseControllerBackgroundJobStatus.Cancelled:
      return 'grey';
    case EvseControllerBackgroundJobStatus.PartlyCompleted:
      return 'orange';
    case EvseControllerBackgroundJobStatus.Completed:
      return 'olive';
    case EvseControllerBackgroundJobStatus.Failed:
      return 'red';
    default:
      return 'grey';
  }
}

export function getEvseControllerBackgroundJobStatusText(
  status: EvseControllerBackgroundJobStatus,
  t: (key: string, defaultValue: string) => string
) {
  switch (status) {
    case EvseControllerBackgroundJobStatus.InProgress:
      return t(
        'evseControllerBulkActionExecutionStatus.inProgress',
        'In Progress'
      );
    case EvseControllerBackgroundJobStatus.PartlyCompleted:
      return t(
        'evseControllerBulkActionExecutionStatus.partlyCompleted',
        'Partly Completed'
      );
    case EvseControllerBackgroundJobStatus.Completed:
      return t(
        'evseControllerBulkActionExecutionStatus.completed',
        'Completed'
      );
    case EvseControllerBackgroundJobStatus.Failed:
      return t('evseControllerBulkActionExecutionStatus.failed', 'Failed');
    case EvseControllerBackgroundJobStatus.Cancelling:
      return t(
        'evseControllerBulkActionExecutionStatus.canceling',
        'Canceling'
      );
    case EvseControllerBackgroundJobStatus.Cancelled:
      return t('evseControllerBulkActionExecutionStatus.canceled', 'Canceled');
    default:
      return t('evseControllerBulkActionExecutionStatus.unknown', 'Unknown');
  }
}
