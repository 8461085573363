import React, { useState, useContext } from 'react';
import { Search } from 'components';
import { Modal, Button, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useEnterpriseAccountMigration } from '../context';

export default function EnterpriseAccountMigrationCompletion({
  intermediateData,
  onClose,
}) {
  const { t } = useTranslation();
  const { completeMigration } = useEnterpriseAccountMigration();
  const { reload } = useContext(Search.Context);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function handleCompletion() {
    setLoading(true);
    try {
      const { migrationPlanId } = intermediateData;
      await completeMigration(migrationPlanId);
      setLoading(false);
      reload();
      onClose();
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal.Header>Account Migration - Step 3/3: Completion</Modal.Header>
      <Modal.Content>
        {t(
          'enterpriseAccountMigration.completion.warningContent',
          `The migration will complete automatically after 10 consecutive unsuccessful attempts when there is no more data left to migrate. Alternatively, you can manually complete the migration by clicking the button below.`
        )}
        <Message
          warning
          icon="circle-exclamation"
          header={t(
            'enterpriseAccountMigration.completion.warningHeader',
            'Note'
          )}
          content={t(
            'enterpriseAccountMigration.completion.content',
            `Please make sure that all data has been successfully transferred before marking the migration as complete.`
          )}
          style={{ marginTop: '1rem' }}
        />
        {error && (
          <Message
            error
            header={t(
              'enterpriseAccountMigration.completion.errorHeader',
              'Error'
            )}
            content={error.message}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <>
          <Button
            basic
            content={t('common.close', 'Close')}
            onClick={() => onClose()}
            disabled={loading}
          />
          <Button
            primary
            onClick={handleCompletion}
            loading={loading}
            content={t(
              'enterpriseAccountMigration.completion.complete',
              'Complete Migration'
            )}
          />
        </>
      </Modal.Actions>
    </>
  );
}
