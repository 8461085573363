import React from 'react';
import AutoConfigureEvseController from 'components/modals/AutoConfigureEvseController';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'semantic';

interface Props {
  evseController: Object;
  loading: boolean;
  onStart: () => void;
  nextStep: () => void;
  totalSteps: number;
}

export default function ConfigureChargingStationStep(props: Props) {
  const { t } = useTranslation();
  const [nextStepDisabled, setNextStepDisabled] = React.useState(true);

  return (
    <React.Fragment>
      <Modal.Header>
        {`${t('setupEvse.inviteCustomer', 'Setup Charge Station')} (1/${
          props.totalSteps
        })`}
      </Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'setupEvse.autoConfigureDescription',
            `Step 1: The system will automatically configure all needed settings.
            Press "Upload Configuration" to send configuration commands. Once completed hit
            "Next" at the bottom. The charge station will automatically reboot
            during this process.`
          )}
        </p>
        <AutoConfigureEvseController
          fixAllLabel={t(
            'setupEvse.autoConfigureFixAll',
            'Upload Configuration'
          )}
          inline
          start
          evseController={props.evseController}
          onStart={props.onStart}
          onDone={() => {
            setNextStepDisabled(false);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content={t('setupEvse.next', 'Next')}
          onClick={() => props.nextStep()}
          loading={props.loading}
          disabled={nextStepDisabled}
        />
      </Modal.Actions>
    </React.Fragment>
  );
}
