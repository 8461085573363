import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { ListHeader, Breadcrumbs } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Divider, Loader, Message, Segment } from 'semantic';

import Table from './Table';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_OPERATIONAL_STATUSES_FE_PATH,
  hasEvseCustomOperationalStatusesPermissions,
} from '../utils';
import {
  EvseOperationalStatus,
  EvseOperationalStatusType,
} from 'types/evse-operational-status';
import EditEvseOperationalStatus from 'components/modals/EditEvseOperationalStatus';
import useFetch from 'hooks/useFetch';
import { request } from 'utils/api';

export default function OperationalStatusesList() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [errorDeleting, setErrorDeleting] = useState<Error | null>(null);
  const [loadingDeleting, setLoadingDeleting] = useState(false);

  const {
    data: evseOperationalStatuses,
    error: errorFetchingEvseOperationalStatuses,
    loading: loadingEvseOperationalStatuses,
    refresh: refreshEvseOperationalStatuses,
  } = useFetch<EvseOperationalStatus[]>({
    path: '/1/evse-operational-statuses',
  });

  const hasCustomOperationalStatusWriteAccess =
    hasEvseCustomOperationalStatusesPermissions();

  const deleteEvseOperationalStatus = async (id: string) => {
    setLoadingDeleting(true);
    setErrorDeleting(null);

    try {
      await request({
        method: 'DELETE',
        path: `/1/evse-operational-statuses/${id}`,
      });
      refreshEvseOperationalStatuses();
    } catch (e) {
      setErrorDeleting(e as Error);
    } finally {
      setLoadingDeleting(false);
    }
  };

  const evseOperationalSystemStatuses =
    evseOperationalStatuses?.filter(
      (status) => status.type === EvseOperationalStatusType.System
    ) || [];

  const aggregatedError = errorFetchingEvseOperationalStatuses || errorDeleting;
  const aggregatedLoading = loadingEvseOperationalStatuses || loadingDeleting;
  const readyToRenderError = !aggregatedLoading && aggregatedError;
  const readyToRenderItems = !aggregatedLoading;

  return (
    <div>
      <Breadcrumbs
        path={[
          <Link to={CHARGING_STATIONS_FE_PATH}>
            {t('evseOperationalStatuses.breadcrumbs', 'Charging Stations')}
          </Link>,
          <Link to={EVSE_OPERATIONAL_STATUSES_FE_PATH}>
            {t('evseOperationalStatuses.header', 'Operational Statuses')}
          </Link>,
        ]}
        active={id}
      />
      <div style={{ marginTop: 0, marginBottom: 20 }}>
        <ListHeader
          title={t('evseOperationalStatuses.title', 'Operational Statuses')}>
          {hasCustomOperationalStatusWriteAccess && (
            <EditEvseOperationalStatus
              size="tiny"
              evseOperationalSystemStatuses={evseOperationalSystemStatuses}
              onSuccessfulSubmit={refreshEvseOperationalStatuses}
              trigger={
                <Button
                  primary
                  content={t(
                    'evseOperationalStatuses.createStatusButton',
                    'Create Status'
                  )}
                  icon="plus"
                />
              }
            />
          )}
        </ListHeader>
        <p style={{ marginTop: 5 }}>
          <Trans i18nKey="evseOperationalStatuses.description">
            Define your own operational states that you can then use for
            charging stations operational life cycle.
          </Trans>
        </p>
      </div>

      <Divider hidden />

      {aggregatedLoading && (
        <Segment style={{ height: '100px' }}>
          <Loader active />
        </Segment>
      )}
      {readyToRenderError && (
        <Message error content={aggregatedError.message} />
      )}
      {readyToRenderItems && (
        <Table
          evseOperationalStatuses={evseOperationalStatuses || []}
          writeAccess={hasCustomOperationalStatusWriteAccess}
          deleteEvseOperationalStatus={(id) => deleteEvseOperationalStatus(id)}
          onUpdatedEvseOperationalStatus={refreshEvseOperationalStatuses}
        />
      )}
    </div>
  );
}
