import React from 'react';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';
import { useTranslation } from 'react-i18next';

import { Confirm } from 'components';
import { request } from 'utils/api';

export default function AccountTierActions({
  item,
  writeAccess,
  onReload,
} = {}) {
  const { t } = useTranslation();

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/account-tiers/${item.id}`,
    });
  }

  return (
    <>
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.name }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item icon="trash" disabled={!writeAccess} text="Delete" />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />

      <InspectObject
        name="Account Tier"
        data={item}
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
      />
    </>
  );
}
