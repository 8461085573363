import React from 'react';
import { Button, Form, Popup } from 'semantic';

import SearchContext from './Context';

import { withTranslation } from 'react-i18next';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';

import PropTypes from 'prop-types';

/**
 * @component
 * @example
 *
 * async function getData(from, to) {
 *  // make data request here
 * }
 * return (
 *  <ExportMonthButton onRangeSubmit={getData} />
 * )
 */
class ExportMonthButton extends React.Component {
  static defaultProps = {
    rangeField: 'createdAt',
  };

  static contextType = SearchContext;

  state = {
    loading: false,
    error: null,
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  };

  handleSubmit = async () => {
    this.setState({ loading: true, error: null });

    try {
      await this.props.onRangeSubmit(
        startOfDay(this.state.from),
        endOfDay(this.state.to),
        this.context.filters
      );
      this.setState({
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { meta } = this.context;
    const { t } = this.props;

    return (
      <Popup
        content={
          <div style={{ width: '250px' }}>
            <Form onSubmit={this.handleSubmit} error={true}>
              <Form.Input
                label={t('export.From', 'From')}
                name="from"
                type="date"
                start
                value={this.state.from.toISOString().split('T')[0]}
                placeholder="No Start"
                onChange={(e, { name, value }) => {
                  const date = new Date(value);
                  if (date.toString() === 'Invalid Date') return;
                  this.setState({
                    [name]: date,
                  });
                }}
                fluid
                clearable
              />

              <Form.Input
                name={'to'}
                label={t('export.To', 'To')}
                fluid
                type="date"
                value={this.state.to.toISOString().split('T')[0]}
                placeholder="Present"
                onChange={(e, { name, value }) => {
                  const date = new Date(value);
                  if (date.toString() === 'Invalid Date') return;
                  this.setState({
                    [name]: date,
                  });
                }}
                clearable
              />

              <Button type="submit" fluid primary content="Submit" />
            </Form>
          </div>
        }
        on="click"
        trigger={
          // adding the div make it possible to correcly position the popup
          <div>
            <Button
              loading={loading}
              disabled={meta?.total === 0 || loading}
              negative={error}
              title={error?.message}
              basic
              icon={error || 'download'}
              content={this.props.content || 'Export'}
            />
          </div>
        }
      />
    );
  }
}

/**
 * propTypes for ExportMonthButton
 */
ExportMonthButton.propTypes = {
  /**
   * Callback once a date is submitted
   * @param {Date} from
   * @param {Date} to
   * @param {any} filters
   */
  onRangeSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(ExportMonthButton);
