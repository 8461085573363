import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Modal, Message } from 'semantic';

export default function AsyncModal(Component) {
  return function (props) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(!!props.getData);
    const [data, setData] = React.useState({});
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
      setData(props.data || {});
    }, [props.data]);

    async function getData() {
      try {
        const data = await props.getData();
        setData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    useEffect(() => {
      if (open && props.getData) {
        getData();
      }
    }, [open]);

    const close = () => {
      setOpen(false);
      props.onClose && props.onClose();
    };

    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        trigger={props.trigger}
        onClose={close}
        onOpen={() => setOpen(true)}
        open={open}
        size={props.size}>
        {loading && (
          <Loader
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
            active
            inline="centered">
            {t('loading.loading', 'Loading...')}
          </Loader>
        )}
        {!loading && error && (
          <Modal.Content>
            <Message negative>
              <Message.Header>
                {t('error.failedToGetContent', 'Failed to retrieve content')}
              </Message.Header>
              <p>{error.message}</p>
            </Message>
          </Modal.Content>
        )}
        {!loading && !error && (
          <Component {...props} data={data} open={open} close={close} />
        )}
      </Modal>
    );
  };
}
