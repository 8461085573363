import React, { useState } from 'react';
import { Form, FormTextAreaProps } from 'semantic';

type TextAreaWithMaxLengthProps = {
  maxLength?: number;
  value?: string;
  charactersRemainingDescription?: (charactersRemaining: number) => string;
} & FormTextAreaProps;

/*
 * A TextArea component that displays the number of characters remaining out of a maximum length.
 */
export function TextAreaWithMaxLength(props: TextAreaWithMaxLengthProps) {
  const { maxLength, charactersRemainingDescription, onChange, ...restProps } =
    props;

  const handleOnChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    data: FormTextAreaProps
  ) => {
    if (maxLength && event.target.value.length > maxLength) {
      return;
    }

    onChange?.(event, data);
  };

  return (
    <>
      <Form.TextArea {...restProps} onChange={handleOnChange} />
      {maxLength && charactersRemainingDescription && (
        <p
          style={{
            position: 'relative',
            top: '-0.5rem',
            fontSize: '0.9rem',
            opacity: 0.6,
          }}>
          {charactersRemainingDescription(
            maxLength - (restProps.value?.length || 0)
          )}
        </p>
      )}
    </>
  );
}
