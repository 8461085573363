import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Divider, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { request } from 'utils/api';
import ExploreMap from 'components/maps/ExploreMap';

import AppWrapper from 'components/AppWrapper';

import { useTranslation } from 'react-i18next';

export default function LocationsMap() {
  const { t } = useTranslation();

  return (
    <AppWrapper>
      <Breadcrumbs
        path={[<Link to="/facilitator/locations">Facilitator</Link>]}
        active={t(
          'dashboard.headerFacilitatorLocationsMap',
          'Facilitator Locations Map'
        )}
      />
      <Grid reversed="computer">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            {t(
              'dashboard.headerFacilitatorLocationsMap',
              'Facilitator Locations Map'
            )}
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <ExploreMap
        search
        fetchData={({ geoBox }) => {
          return request({
            method: 'POST',
            path: '/2/locations/cpo/facilitator/search',
            body: {
              limit: 2000,
            },
          });
        }}
        defaultZoom={8}
      />
    </AppWrapper>
  );
}
