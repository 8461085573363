import { Segment, Table } from 'semantic';
import { Layout, SearchFilters, Search } from 'components';
import React from 'react';
import {
  FilterMapping,
  OnDataNeededFilterParams,
} from 'components/search/Provider';
import { BatchMessage } from 'components/modals/TokenBatches/types';
import { useTranslation } from 'react-i18next';

type Props = { errors: BatchMessage[] };

export function ErrorSummary({ errors }: Props) {
  const { t } = useTranslation();

  const onDataNeeded = async (filters: OnDataNeededFilterParams) => {
    if (filters.errorMessage) {
      errors = errors?.filter(
        (error) => error.message === filters.errorMessage
      );
    }

    return {
      data: errors,
    };
  };

  const getFilterMapping = (): FilterMapping => {
    return {
      errorMessage: {
        name: 'Error message',
        type: 'dropdown',
      },
    };
  };

  const errorMessages = [
    'Token not part of Batch',
    'Visible ID already assigned RFID',
  ];

  return (
    <Search.Provider
      onDataNeeded={onDataNeeded}
      filterMapping={getFilterMapping()}>
      {(context) => {
        const { items } = context;
        return (
          <>
            <Segment>
              <Layout horizontal spread stackable>
                <SearchFilters.Modal>
                  <SearchFilters.Dropdown
                    name="errorMessage"
                    label={t('cards.errorMessage', 'Error message')}
                    options={errorMessages.map((errorMessage) => ({
                      key: errorMessage,
                      text: errorMessage,
                      value: errorMessage,
                    }))}
                  />
                </SearchFilters.Modal>
              </Layout>
            </Segment>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('tokenBatch.columnMessage', 'Message')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('tokenBatch.columnCount', 'Count')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.message}</Table.Cell>
                    <Table.Cell textAlign="right">{item.count}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        );
      }}
    </Search.Provider>
  );
}
