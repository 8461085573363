import React from 'react';
import { Divider, Header, Table, Button, Label } from 'semantic';
import { LoadButton, Search } from 'components';
import { useTranslation } from 'react-i18next';
import { request } from '../../utils/api';
import { formatDateTime } from '../../utils/date';
import EditRoamingOverride from '../../components/modals/EditRoamingOverride';
import { formatCurrency } from '../../utils/formatting';
import useMenuRoutes from 'hooks/useMenuRoutes';

const deleteRoamingOverride = (apiRoute, infraProviderId) => {
  return request({
    path: `${apiRoute}/${infraProviderId}`,
    method: 'DELETE',
  });
};

const getExternalProviders = (apiRoute) => {
  return request({
    path: apiRoute,
    method: 'GET',
  });
};

export default function ProviderRoamingOverride({ routesContext = 'legacy' }) {
  const [overrides, setOverrides] = React.useState([]);
  const { t } = useTranslation();
  const { financeRoamingOverridesRoutes } = useMenuRoutes(routesContext);
  const { apiRoute } = financeRoamingOverridesRoutes;

  return (
    <Search.Provider
      onDataNeeded={() =>
        getExternalProviders(apiRoute).then((x) => {
          setOverrides(x?.data);
          return x;
        })
      }>
      {({ reload }) => (
        <>
          <Header as="h3">
            {t('roamingCpoCostOverride.title', 'Roaming CPO cost overrides')}
          </Header>
          <p>
            {t(
              'roamingCpoCostOverride.description',
              'This section allows for configuration of cost overrides for roaming CPO that send CDRs with kWh but no associated cost - upon receiving such CDR the system will pick a record matching the roaming provider ID and apply given configured cost to the CDR'
            )}
          </p>
          <p>
            {t(
              'roamingCpoCostOverride.note',
              'Note, the original 0 cost is always saved on a CDR in a separate field allowing for recognition of overwritten cost.'
            )}
          </p>
          <Table celled size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('sessionsTable.columnInfraProvider', 'Infra provider')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('roamingCpoCostOverride.pricePerKwh', 'Price per kw/h')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t(
                    'roamingCpoCostOverride.pricePerSession',
                    'Price per session'
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('roamingCpoCostOverride.pricePerHour', 'Price per hour')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('roamingCpoCostOverride.validFrom', 'Valid from')}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t('roamingCpoCostOverride.actions', 'Actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {overrides.map((item, i) => (
                <Table.Row
                  key={`${item._id?.providerId}${item._id?.infraProviderId}`}>
                  <Table.Cell>
                    {item.validFrom && (
                      <span>
                        {item.infraProvider.length !== 0 && (
                          <Label content={item.infraProvider[0].name} />
                        )}
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      AC:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formatCurrency(item?.powerTypeAC?.pricePerKwh, {
                          currency: 'EUR',
                        })}
                      </span>
                    </Label>
                    <Label>
                      DC:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formatCurrency(item?.powerTypeDC?.pricePerKwh, {
                          currency: 'EUR',
                        })}
                      </span>
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      AC:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formatCurrency(item?.powerTypeAC?.pricePerSession, {
                          currency: 'EUR',
                        })}
                      </span>
                    </Label>
                    <Label>
                      DC:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formatCurrency(item?.powerTypeDC?.pricePerSession, {
                          currency: 'EUR',
                        })}
                      </span>
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      AC:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formatCurrency(item?.powerTypeAC?.pricePerHour, {
                          currency: 'EUR',
                        })}
                      </span>
                    </Label>
                    <Label>
                      DC:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {formatCurrency(item?.powerTypeDC?.pricePerHour, {
                          currency: 'EUR',
                        })}
                      </span>
                    </Label>
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(item?.validFrom)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <EditRoamingOverride
                      override={item}
                      onClose={() => reload()}
                      trigger={<Button basic icon="edit" />}
                      routesContext={routesContext}
                    />
                    <LoadButton
                      basic
                      icon="trash"
                      title="Delete"
                      onClick={() => {
                        deleteRoamingOverride(
                          apiRoute,
                          item?._id?.infraProviderId
                        ).then(() => {
                          const indexOfProvider = overrides.findIndex(
                            (override) =>
                              override._id?.infraProviderId ===
                              item?._id?.infraProviderId
                          );
                          setOverrides([
                            ...overrides.slice(0, indexOfProvider),
                            ...overrides.slice(
                              indexOfProvider + 1,
                              overrides.length
                            ),
                          ]);
                        });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <EditRoamingOverride
            onClose={() => reload()}
            excludedProviders={overrides.map((x) => x._id?.infraProviderId)}
            trigger={
              <Button
                primary
                content={t('roamingCpoCostOverride.add', 'Add override')}
                icon="plus"
              />
            }
          />
          <Divider hidden />
        </>
      )}
    </Search.Provider>
  );
}
