import { Form, Modal, Button, Label, Message } from 'semantic';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import InputField from 'components/form-fields/formik/InputField';
import { request } from 'utils/api';
import SelectField from 'components/form-fields/formik/SelectField';
import { SemanticCOLORS } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Status = ({ status }: { status: string }) => {
  let color: SemanticCOLORS;
  let statusTitle = status;

  switch (status) {
    case 'Accepted':
      color = 'olive';
      break;
    case 'Blocked':
      color = 'red';
      break;
    case 'Invalid':
      color = 'orange';
      break;
    case 'ConcurrentTx':
      statusTitle = 'Concurrent Transaction';
      color = 'red';
      break;
    default:
      color = 'black';
      break;
  }

  return <Label color={color}>{statusTitle}</Label>;
};

interface Props {
  evseControllerId: string;
  connectors: number[];
  trigger: React.ReactNode;
}

const TestTokenAuthorization: React.FC<Props> = ({
  evseControllerId,
  connectors = [],
  trigger,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = React.useState<{
    idTag: string;
    connectorId: number;
    status: string;
    type: string;
    session?: {
      id: string;
      externalId: string;
      externalUniqueId: string;
    };
    userCanStopSession?: boolean;
  } | null>(null);

  const verifyTokenAuthorization = async ({
    idTag,
    connectorId,
    forceRemote,
  }: any) => {
    setResponse(null);
    const { data } = await request({
      method: 'POST',
      path: `/1/evse-controllers/${evseControllerId}/test-token-authorization`,
      body: { idTag, forceRemote, connectorId: parseInt(connectorId, 10) },
    });

    setResponse({
      idTag,
      connectorId,
      status: data?.status,
      type: data?.authType,
      session: data?.session,
      userCanStopSession: data?.userCanStopSession,
    });
  };

  const sessionInfo = `${response?.session?.externalId} (${response?.session?.externalUniqueId})`;

  const authorizationContext = (authType: string) => {
    switch (authType) {
      case 'AUTH_REQUEST':
        return t(
          'testTokenAuthorizationModal.authRequest',
          'To check whether this token was allowed to charge, we made a remote call to the eMSP that issued it.'
        );
      case 'WHITELIST':
        return t(
          'testTokenAuthorizationModal.whitelist',
          'To check whether this token was allowed to charge, we use the local cache in our database (without making a remote call).'
        );
      default:
        return '';
    }
  };

  return (
    <Formik
      initialValues={{ idTag: '' }}
      onSubmit={(values) => verifyTokenAuthorization(values)}>
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          closeIcon
          closeOnDimmerClick={false}
          trigger={trigger}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}>
          <Modal.Header>
            {t('testTokenAuthorizationModal.title', 'Test token authorization')}
          </Modal.Header>
          <Modal.Content>
            <p>
              {t(
                'testTokenAuthorizationModal.help',
                'This form simulates an authorization request from a charging station. Use it to test whether a token would be authorized to charge on this charging station. No sessions will be started.'
              )}
            </p>
            <Form>
              <InputField
                name="idTag"
                label={t('testTokenAuthorization.idTag', 'ID Tag (UID)')}
              />
              <br />
              <SelectField
                name="connectorId"
                options={(connectors || []).map((connector) => ({
                  key: connector.toString(),
                  value: connector.toString(),
                  text: connector.toString(),
                }))}
                label="Connector"
              />
              <br />
            </Form>
            {response && (
              <div style={{ padding: '1rem 0' }}>
                <p>
                  <Trans i18nKey="testTokenAuthorizationModal.response">
                    Response for <strong>{response?.idTag}</strong> on connector{' '}
                    {response?.connectorId}:
                  </Trans>
                </p>
                <Status status={response.status} />
                {response.type && (
                  <p style={{ marginTop: '1rem' }}>
                    <strong>{response.type}</strong>-{' '}
                    {authorizationContext(response.type)}
                  </p>
                )}
                {response.session && (
                  <p>
                    <br />
                    {t(
                      'testTokenAuthorizationModal.concurrentSessionFound',
                      'A concurrent active session detected with this token.'
                    )}
                    <br />
                    {t(
                      'testTokenAuthorizationModal.externalSessionId',
                      'External Session ID:'
                    )}{' '}
                    <strong>
                      {response.userCanStopSession ? (
                        <Link
                          target="_blank"
                          to={`/charging-stations/sessions/${response.session.id}`}>
                          {sessionInfo}
                        </Link>
                      ) : (
                        sessionInfo
                      )}
                    </strong>
                    {response.userCanStopSession ? (
                      <Message info>
                        <Trans i18nKey="testTokenAuthorizationModal.userCanStopSession">
                          <strong>Do you want to stop this session?</strong>
                          <br />
                          Open the Session Details and select the "Stop this
                          session" action. Afterward, refresh the Session
                          Details page to ensure the session has stopped. Once
                          done, you can return to Test Token Authorization and
                          run the test again.
                        </Trans>
                      </Message>
                    ) : (
                      <Message warning>
                        <Trans i18nKey="testTokenAuthorizationModal.userCanNotStopSession">
                          Contact{' '}
                          <a
                            href="https://www.e-flux.io/support"
                            target="_blank"
                            rel="noopener noreferrer">
                            Customer Support
                          </a>{' '}
                          if concurrent transaction needs to be stopped.
                        </Trans>
                      </Message>
                    )}
                  </p>
                )}
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button loading={isSubmitting} onClick={handleSubmit} type="submit">
              {t('submit', 'Verify')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  );
};

export default TestTokenAuthorization;
