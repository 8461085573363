import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function MspMenu({ sessionId }) {
  const { t } = useTranslation();

  return (
    <Container style={{ marginBottom: '15px' }}>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content="Details"
          to={`/cards/sessions/${sessionId}`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('creditSessions.title', 'Credit Sessions')}
          to={`/cards/sessions/${sessionId}/credit-sessions`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
}
