import React, { useState } from 'react';
import { Header, Button, Divider, Message, Grid } from 'semantic';
import EditAccessGroupMember from 'components/modals/EditAccessGroupMember';
import { request } from 'utils/api';
import ImportAccessGroupMembers from 'components/modals/ImportAccessGroupMembers';
import RfidTable from './RfidTable';
import UsersTable from './UsersTable';
import { useTranslation } from 'react-i18next';
import { ExportAccessGroupMembers } from 'components/ExportAccessGroupMembers';

export default function Overview({ onRefresh, accessGroup }) {
  const [error, setError] = useState(null);
  const { members } = accessGroup;
  const { t } = useTranslation();

  const refresh = () => {
    onRefresh();
  };

  const onDelete = async (item) => {
    try {
      await request({
        method: 'DELETE',
        path: `/1/access-groups/${accessGroup?.id}/members/${item?.id}`,
      });
      return refresh();
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div>
      <Grid reversed="computer">
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={10}
          textAlign="right"
          verticalAlign="middle">
          {accessGroup.type === 'rfid' && (
            <>
              <ExportAccessGroupMembers accessGroup={accessGroup} />
              <ImportAccessGroupMembers
                accessGroup={accessGroup}
                onClose={() => refresh()}
                trigger={
                  <Button
                    primary
                    content={t('myAccessGroup.importMembers', 'Import Member')}
                    icon="upload"
                  />
                }
              />
            </>
          )}
          <EditAccessGroupMember
            accessGroup={accessGroup}
            refresh={() => refresh()}
            trigger={
              <Button
                primary
                content={t('myAccessGroup.addMember', 'Add Member')}
                icon="plus"
              />
            }
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={6}>
          <Header as="h2" style={{ margin: '0' }}>
            Members
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <div className="list">
        {!members.length && <Message>No members added yet</Message>}
        {members.length > 0 &&
          (accessGroup.type === 'rfid' ? (
            <RfidTable
              members={members}
              accessGroup={accessGroup}
              onRefresh={() => refresh()}
              onDelete={onDelete}
            />
          ) : (
            <UsersTable
              members={members}
              accessGroup={accessGroup}
              onRefresh={() => refresh()}
              onDelete={onDelete}
            />
          ))}
      </div>
    </div>
  );
}
