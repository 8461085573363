import React from 'react';

import AppWrapper from 'components/AppWrapper';
import { set } from 'lodash-es';

import {
  Header,
  Message,
  Form,
  Input,
  Segment,
  Icon,
  Button,
  Grid,
} from 'semantic';

import { withTranslation } from 'react-i18next';
import { EVSE_ID_COUNTRY_CODES } from 'utils/evse-ids';

import './activate.less';

import { request } from 'utils/api';

class Activate extends React.Component {
  constructor(props) {
    super(props);

    const suggestedCountryCode = props.i18n.language.toUpperCase();

    this.state = {
      formValues: {
        countryCode: EVSE_ID_COUNTRY_CODES.includes(suggestedCountryCode)
          ? suggestedCountryCode
          : 'NL',
      },
    };
  }

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
      success: false,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/cards/activate',
        body: this.state.formValues,
      });

      this.setState({
        loading: false,
        success: true,
      });
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  setField(name, value) {
    const { formValues } = this.state;
    set(formValues, name, value);
    this.setState({
      formValues,
    });
  }

  render() {
    const { t } = this.props;
    const { loading, error, success } = this.state;

    return (
      <AppWrapper>
        {success ? (
          <Segment placeholder>
            <Header icon>
              <Icon name="circle-check" />
              {t('activate.success', 'Your card is now activated! Enjoy!')}
            </Header>
          </Segment>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}>
            <Grid centered>
              <Segment compact padded="very">
                <Form error={Boolean(error)} onSubmit={() => this.onSubmit()}>
                  {error && <Message error content={error.message} />}
                  <Grid centered style={{ maxWidth: '598px' }}>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Header as="h3" style={{ fontWeight: 'normal' }}>
                          {t('activate.title', 'Activate Card')}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <p>
                          {t(
                            'activate.validateMessage',
                            'To verify that your card has been properly delivered by postal services, please enter the identifier on the front of your card.'
                          )}
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Grid
                          className="segment"
                          style={{
                            backgroundColor: '#FCFCFC',
                            padding: '10px',
                          }}>
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Field required>
                                <label>
                                  {t('activate.labelCardId', 'Card Identifier')}
                                </label>
                                <div
                                  className="activate-input"
                                  style={{
                                    paddingTop: '10px',
                                    marginBottom: '25px',
                                  }}>
                                  {' '}
                                  <Input
                                    autoFocus
                                    name="customId"
                                    type="text"
                                    required
                                    onChange={(e, { name, value }) =>
                                      this.setField(name, value)
                                    }
                                  />
                                </div>
                              </Form.Field>
                              <label
                                style={{
                                  fontStyle: 'italic',
                                  fontSize: '10pt',
                                  color: '#2A2A2A',
                                }}>
                                {t(
                                  'activate.labelExample',
                                  'Example: NL-EFL-000000000-0  or  C12345678'
                                )}
                              </label>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column textAlign="center">
                        <Button
                          loading={loading}
                          disabled={loading}
                          primary
                          content={t(
                            'activate.buttonActivate',
                            'Activate Card'
                          )}
                          onClick={this.onSubmit}
                          style={{ width: '200px', fontWeight: 'normal' }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </Segment>
            </Grid>
          </div>
        )}
      </AppWrapper>
    );
  }
}

export default withTranslation()(Activate);
