import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';
import List from './List';
import useMenuRoutes from 'hooks/useMenuRoutes';
import { MenuRoutesContext } from 'interfaces/interfaces';

export default function EnterpriseAccountMigrations({
  routesContext = 'legacy',
}: MenuRoutesContext) {
  const { migrationRoutes } = useMenuRoutes(routesContext);
  const { prefixUiRoute, uiRoute, apiRoute } = migrationRoutes;
  const listUiRoute = `${prefixUiRoute}${uiRoute}`;

  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route
            path={listUiRoute}
            component={(props: any) => <List {...props} apiRoute={apiRoute} />}
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
