import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import {
  Aggregate,
  AggregateTerms,
  ErrorBoundary,
  MultiSeriesChart,
} from 'react-tectonic';

import { changeFilterId, getColors } from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import MultiCardinalityDonutChart from 'components/analytics/MultiCardinalityDonutChart';
import TermsByTermsTable from 'components/analytics/TermsByTermsTable';
import { Link } from 'react-router-dom';
import { mergeFilters } from 'utils/visualizations';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';

export default function CpoEquipment({
  hasUsersReference = true,
  includeEvseIssues = true,
  baseFilter = {},
}) {
  const { provider } = useUser();
  const { t } = useTranslation();
  const locationFilter = changeFilterId(baseFilter, 'locationId');
  const colorHex = `#${provider.primaryColorHex}`;
  const colors = getColors(colorHex);

  return (
    <ErrorBoundary>
      <Divider hidden />
      <Divider hidden />

      <Statistic.Group
        widths={includeEvseIssues ? 4 : 3}
        style={{ justifyContent: 'space-between' }}>
        <CardinalityStatistic
          collection="evsecontrollers"
          label={t('analyticsCpoEquipment.manufacturers', 'Manufacturers')}
          field="bootInfo.chargePointVendor"
          filter={baseFilter}
        />
        <CardinalityStatistic
          collection="evsecontrollers"
          label={t('analyticsCpoEquipment.models', 'Models')}
          field="bootInfo.chargePointModel"
          filter={baseFilter}
        />

        <CardinalityStatistic
          collection="evsecontrollers"
          label={t(
            'analyticsCpoEquipment.firmwareVersions',
            'Firmware Versions'
          )}
          field="bootInfo.firmwareVersion"
          filter={baseFilter}
        />

        {includeEvseIssues && (
          <CardinalityStatistic
            collection="evseissues"
            label={t('analyticsCpoEquipment.resolvedIssues', 'Resolved Issues')}
            filter={mergeFilters(
              {
                terms: [{ isResolved: true }],
              },
              baseFilter
            )}
          />
        )}
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTerms
        collection="evsecontrollers"
        aggField="bootInfo.chargePointVendor"
        operation="count"
        timeRangeDateField="createdAt"
        filter={baseFilter}
        termsSize={6}>
        {({ data }) => {
          return (
            <Aggregate
              type="time-series"
              collection="evsecontrollers"
              dateField="createdAt"
              requests={data.map((term) => {
                return {
                  operation: 'count',
                  dateField: 'createdAt',
                  filter: mergeFilters(
                    {
                      terms: [{ 'bootInfo.chargePointVendor': term.key }],
                    },
                    baseFilter
                  ),
                };
              })}>
              <MultiSeriesChart
                label={t(
                  'analyticsCpoEquipment.installationsByManufacturers',
                  'Installations by Manufacturers'
                )}
                colors={colors}
                titleAlign="center"
                height={350}
                chartType="area"
                stacked
                valueField="count"
                labels={data.map((term) => term.key)}
              />
            </Aggregate>
          );
        }}
      </AggregateTerms>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="locations"
                aggField="evses.connectors.standard"
                operation="count"
                title={t(
                  'analyticsCpoEquipment.connectorStandards',
                  'Connector Standards'
                )}
                filter={locationFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="locations"
                aggField="evses.connectors.power_type"
                operation="count"
                filter={locationFilter}
                title={t('analyticsCpoEquipment.powerTypes', 'Power Types')}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment basic>
              <TermsByTermsTable
                colors={colors}
                collection="evsecontrollers"
                filter={baseFilter}
                aggField="bootInfo.chargePointVendor"
                aggField2="bootInfo.chargePointModel"
                operation="count"
                valueField="count"
                valueFieldLabel={t('common.evses', 'EVSEs')}
                title={t('analyticsCpoEquipment.evseHardware', 'EVSE Hardware')}
                label=""
                label2={t(
                  'analyticsCpoEquipment.topHardwareModels',
                  'Top Hardware Models'
                )}
                termsSize={10}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="locations"
                filter={locationFilter}
                aggField="evses.connectors.max_amperage"
                operation="count"
                valueField="count"
                valueFieldLabel={t('common.connectors', 'Connectors')}
                labelFormatter={(item) => `${item}A`}
                title={t('analyticsCpoEquipment.maxAmperage', 'Max Amperage')}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                colors={colors}
                collection="locations"
                filter={locationFilter}
                aggField="evses.connectors.max_voltage"
                labelFormatter={(item) => `${item}V`}
                operation="count"
                title={t('analyticsCpoEquipment.maxVoltagee', 'Max Voltage')}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {includeEvseIssues && (
        <React.Fragment>
          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  {hasUsersReference ? (
                    <TermsTable
                      filter={baseFilter}
                      collection="evseissues"
                      aggField="resolvedByUserId"
                      operation="count"
                      valueField="count"
                      valueFieldName={t(
                        'analyticsCpoEquipment.issues',
                        'Issues'
                      )}
                      title={t(
                        'analyticsCpoEquipment.topIssueResolvers',
                        'Top Issue Resolvers'
                      )}
                      fetchReference="users"
                      referenceLabelFormatter={(item) => {
                        return (
                          <Link to={`/accounts/users/${item.id}`}>
                            {item.contact.firstName}
                          </Link>
                        );
                      }}
                      termsSize={15}
                    />
                  ) : (
                    <TermsTable
                      filter={baseFilter}
                      collection="evseissues"
                      aggField="resolvedByUserName"
                      operation="count"
                      valueField="count"
                      valueFieldName="Issues"
                      title={t(
                        'analyticsCpoEquipment.topIssueResolvers',
                        'Top Issue Resolvers'
                      )}
                      termsSize={15}
                    />
                  )}
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsTable
                    filter={baseFilter}
                    title={t('analyticsCpoEquipment.issueTypes', 'Issue Types')}
                    collection="evseissues"
                    aggField="type"
                    operation="count"
                    valueField="count"
                    valueFieldName={t('analyticsCpoEquipment.issues', 'Issues')}
                    labelFormatter={(item) => `${item}`}
                    termsSize={15}
                    style={{ height: '100%' }}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider hidden />
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <TermsDonutChart
                    colors={colors}
                    filter={baseFilter}
                    collection="evseissues"
                    aggField="severity"
                    operation="count"
                    title={t(
                      'analyticsCpoEquipment.issuesSeverities',
                      'Issue Severities'
                    )}
                    termsSize={15}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment basic>
                  <MultiCardinalityDonutChart
                    colors={colors}
                    title={t(
                      'analyticsCpoEquipment.issueResolutionStatus',
                      'Issue Resolution Status'
                    )}
                    collection="evseissues"
                    items={[
                      {
                        label: t('analyticsCpoEquipment.resolved', 'Resolved'),
                        request: {
                          collection: 'evseissues',
                          fields: ['id'],
                          filter: mergeFilters(
                            {
                              terms: [{ isResolved: true }],
                            },
                            baseFilter
                          ),
                        },
                      },
                      {
                        label: t('analyticsCpoEquipment.open', 'Open'),
                        request: {
                          collection: 'evseissues',
                          fields: ['id'],
                          filter: mergeFilters(
                            {
                              terms: [{ isResolved: false }],
                            },
                            baseFilter
                          ),
                        },
                      },
                    ]}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}

      <Divider hidden />
      <Divider hidden />
    </ErrorBoundary>
  );
}
