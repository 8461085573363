export default {
  menuRoutesContext: {
    routesContext: 'legacy',
  },
  providerRoutes: {
    uiRoute: '/providers',
    apiRoute: '/1/providers',
  },
  financeRoamingRoutes: {
    prefixUiRoute: '/finance',
    uiRoute: '/roaming-overrides',
    cpoSessionsBreakdownApiEndpoint: '/2/sessions/cpo/external-provider',
    cpoSessionsBreakdownRoamingApiEndpoint: '/2/sessions/cpo/breakdown/roaming',
    providerSearchApiEndpoint: '/1/providers/search',
    mspSessionsBreakdownApiEndpoint: '/1/sessions/external-provider',
    mspSessionsBreakdownRoamingApiEndpoint: '/1/sessions/breakdown/roaming',
  },
  financeExchangeRatesRoutes: {
    prefixUiRoute: '/finance',
    apiRoute: '/1/exchange-rates',
  },
  financeRoamingOverridesRoutes: {
    prefixUiRoute: '/finance',
    apiRoute: '/1/roaming-overrides',
    apiExternalProvidersRoute: '/1/external-providers/search',
  },
  migrationRoutes: {
    prefixUiRoute: '/migrations',
    uiRoute: '/enterprise-accounts',
    apiRoute: '/1/migration/enterprise-account',
  },
};
