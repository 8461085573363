import React from 'react';
import { Message } from 'semantic';

import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation();
  //Login
  t('"email" is not allowed to be empty');
  t('"password" is not allowed to be empty');
  t('email password combination does not match');

  //Signup
  t('A user with that email already exists');
  t('Not a valid IBAN');

  //Forgot password
  t('No user with that email');

  t('The token been revoked');

  // i18next-extract-disable-next-line
  return <Message content={t(props.message)} {...props} />;
};
