import React, { useEffect } from 'react';
import { Sidebar, Segment, Message } from 'semantic';
import LocationDetails from './LocationDetails';
import ClusterMap from './ClusterMap';
import { request } from 'utils/api';

export default function LocationsMap({ style, ...mapProps }) {
  const [charger, setCharger] = React.useState();
  const [height, setHeight] = React.useState(0);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [config, setConfig] = React.useState();

  const ref = React.useRef(null);

  async function fetchConfig() {
    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/map/config',
      });
      setConfig(data);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  React.useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, [ref.current]);

  const onChargerSelect = (selectedCharger) => {
    if (selectedCharger?.id === charger?.id) return;
    setCharger(null); // Close sidebar
    setTimeout(() => {
      setCharger(selectedCharger); // Open sidebar with new charger
    }, 350);
  };

  return (
    <div ref={ref} style={{ minHeight: '80vh', overflowX: 'hidden' }}>
      {error && <Message error content={error.message} />}
      {loading && <Message content="Loading..." />}
      <Sidebar.Pushable style={{ ...style }}>
        <Sidebar
          as={Segment}
          animation="overlay"
          direction="left"
          icon="labeled"
          style={{ background: 'rgba(0,0,0,.85)' }}
          onHide={() => setCharger(null)}
          vertical
          visible={!!charger}
          width="wide">
          {charger && <LocationDetails location={charger} />}
        </Sidebar>
        <Sidebar.Pusher>
          {config && height && (
            <ClusterMap
              {...mapProps}
              config={config}
              height={height}
              onSelect={onChargerSelect}
              emitError={setError}
            />
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}
