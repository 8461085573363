import React from 'react';
import { Table, Header, Loader, Message, Button } from 'semantic';
import { formatEuro } from 'utils/formatting';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import HelpTip from 'components/HelpTip';
import { downloadCsv } from 'utils/csv';
import { sub, format } from 'date-fns';
import Actions from '../Actions';

class Breakdown extends React.Component {
  state = {
    loading: true,
    data: [],
    itemRefreshing: {},
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    try {
      let current = new Date();

      for (let i = 0; i < 12; i++) {
        current = sub(current, { months: 1 });
        const month = current.getMonth() + 1;
        const year = current.getFullYear();

        const { data } = await request({
          method: 'GET',
          path: '/1/sessions/breakdown/revenue',
          params: { month, year },
        });

        this.setState({
          data: this.state.data.concat(
            data.map(({ data, ageSeconds }) => {
              return { data, age: this.secondsAgo(ageSeconds) };
            })
          ),
        });
      }
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  }

  itemKey(id) {
    return `item:${id.year}-${id.month}`;
  }

  async refreshItem(id) {
    const { t } = this.props;
    if (this.state.itemRefreshing[this.itemKey(id)]) {
      // btn will change once the refresh is started
      // this resulted in accidental doubleclick
      return;
    }
    try {
      this.setState({
        itemRefreshing: {
          ...this.state.itemRefreshing,
          [this.itemKey(id)]: true,
        },
      });
      const {
        data: [item],
      } = await request({
        method: 'GET',
        path: '/1/sessions/breakdown/revenue',
        params: { year: id.year, month: id.month, useCache: false },
      });
      if (!item) {
        this.setState({
          error: t('mspSessionsFinance.itemNotFound', 'Item not found'),
        });
        this.setState({
          itemRefreshing: {
            ...this.state.itemRefreshing,
            [this.itemKey(id)]: false,
          },
        });
        return;
      }

      const data = [...this.state.data].map((el) => {
        if (el.data._id.year === id.year && el.data._id.month === id.month) {
          return {
            data: item.data,
            age: this.secondsAgo(item.ageSeconds),
          };
        }
        return el;
      });
      this.setState({ data });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({
        itemRefreshing: {
          ...this.state.itemRefreshing,
          [this.itemKey(id)]: false,
        },
      });
    }
  }

  secondsAgo(seconds) {
    return new Date(new Date().getTime() - seconds * 1000);
  }

  render() {
    const { loading, error, data } = this.state;
    const { t } = this.props;
    const refreshBtn = (id) => {
      if (this.state.itemRefreshing[this.itemKey(id)]) {
        return <Button icon="ban" basic />;
      }
      return (
        <Button
          onClick={() => this.refreshItem(id)}
          title={t('common.refreshData', 'Refresh Data')}
          icon="sync"
          basic
        />
      );
    };
    return (
      <div>
        <Header as="h3">
          {t(
            'mspSessionsFinance.revenueBreakdownTitle',
            'MSP Charge Sessions Revenue Breakdown'
          )}
        </Header>
        {loading && <Loader active inline="centered" />}
        {error && <Message error content={error.message} />}
        {data && (
          <React.Fragment>
            <p>
              Dates are determined by the end datetime of a charge session as
              reported by the (external) CPO provider.
            </p>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('mspSessionsFinance.columnMonth', 'Month')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('mspSessionsFinance.columnNumSessions', 'No Sessions')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('mspSessionsFinance.columnTotalKwh', 'Total kWh')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      'mspSessionsFinance.columnProviderOriginTotalExternalCalculatedPrice',
                      'Provider Origin Revenue'
                    )}
                    <HelpTip
                      title="Provider Origin Revenue"
                      text="This is the sum of all externalCalculatedPrice of sessions that originate from charge stations operated by this provider (via e-flux)"
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      'mspSessionsFinance.columnTotalRevenue',
                      'Total Revenue'
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      'mspSessionsFinance.columnBillingRevenue',
                      'Billing Revenue'
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      'mspSessionsFinance.columnCalculatedTime',
                      'Calculated at'
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('mspSessionsFinance.columnActions', 'Actions')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map(({ data: item, age }) => (
                  <Table.Row key={`${item._id.year}-${item._id.month}`}>
                    <Table.Cell>
                      {item._id.year} / {item._id.month}
                    </Table.Cell>
                    <Table.Cell textAlign="right">{item.total}</Table.Cell>
                    <Table.Cell textAlign="right">
                      {Math.round(item.totalKwh)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatEuro(item.providerOriginTotalRevenue, 2, true)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatEuro(item.totalRevenue, 2, true)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatEuro(item.billedRevenue, 2, true)}
                    </Table.Cell>
                    <Table.Cell>{age.toLocaleString()}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {refreshBtn(item._id)}
                      <Actions
                        month={item._id.month}
                        year={item._id.year}
                        providerContext={'msp'}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <div>
              <Button
                onClick={() =>
                  downloadCsv(
                    data.map(({ data: item }) => {
                      const { _id, ...rest } = item;
                      const { year, month } = _id;
                      return {
                        month: `${year} / ${month}`,
                        ...rest,
                      };
                    }),
                    null,
                    `msp-sessions-revenue-${format(
                      new Date(),
                      'yyyy-MM-dd'
                    )}.csv`
                  )
                }
                icon="download"
                content="Export CSV"
                basic
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(Breakdown);
