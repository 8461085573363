import React from 'react';
import CopyValue from 'components/CopyValue';
import { API_URL } from 'utils/env';
import { request } from 'utils/api';

export default class Statistics extends React.Component {
  state = {
    statisticsToken: undefined,
  };

  getMetrics() {
    return [
      {
        name: 'CPO Sessions total KWH',
        collectionName: 'cpo-sessions',
        queryName: 'sessionsTotalKwh',
      },
      {
        name: 'CPO Sessions total',
        collectionName: 'cpo-sessions',
        queryName: 'sessionsTotal',
      },
      {
        name: 'CPO Sessions total durationDays',
        collectionName: 'cpo-sessions',
        queryName: 'sessionsTotalDurationDays',
      },
      {
        name: 'Chargers per country',
        collectionName: 'evse-controllers',
        queryName: 'chargersPerCountry',
      },
      {
        name: 'Chargers per power type',
        collectionName: 'evse-controllers',
        queryName: 'chargersPerPowerType',
      },
      {
        name: 'Chargers per publishing mode',
        collectionName: 'evse-controllers',
        queryName: 'chargersPerPublishingMode',
      },
      {
        name: 'Chargers total',
        collectionName: 'evse-controllers',
        queryName: 'chargersTotal',
      },
      {
        name: 'Chargers with contract',
        collectionName: 'evse-controllers',
        queryName: 'chargersWithContract',
      },
      {
        name: 'Chargers per manufacturer',
        collectionName: 'evse-controllers',
        queryName: 'chargersPerManufacturer',
      },
      {
        name: 'Connectors total',
        collectionName: 'evse-controllers',
        queryName: 'connectorsTotal',
      },
      {
        name: 'Cards per contract',
        collectionName: 'cards',
        queryName: 'cardsPerContract',
      },
      {
        name: 'Cards per status',
        collectionName: 'cards',
        queryName: 'cardsPerStatus',
      },
      {
        name: 'Cards total',
        collectionName: 'cards',
        queryName: 'cardsTotal',
      },
      {
        name: 'Cards per type',
        collectionName: 'cards',
        queryName: 'cardsPerType',
      },
      {
        name: 'Cards per account type',
        collectionName: 'cards',
        queryName: 'cardsPerAccountType',
      },
    ];
  }

  componentDidMount() {
    this.getStatisticsToken();
  }

  async getStatisticsToken() {
    try {
      const response = await request({
        path: '/1/finance/exports/token',
        method: 'POST',
      });

      this.setState({
        statisticsToken: response.data.token,
      });
    } catch (error) {
      console.error(error);
    }
  }

  generateCollectionUrl(metricEndpoint) {
    return `${API_URL}/1/finance/exports/statistics/${metricEndpoint.collectionName}/${metricEndpoint.queryName}?format=csv&providerId=${window.provider.id}&token=${this.state.statisticsToken}`;
  }

  getCollectionButtons() {
    return this.getMetrics().map((metric) => (
      <CopyValue
        label={metric.name}
        key={metric.name}
        value={this.generateCollectionUrl(metric)}
      />
    ));
  }

  render() {
    return (
      <div>
        <h2>List of exportable statistics</h2>
        {this.getCollectionButtons()}
      </div>
    );
  }
}
