import React, { ReactNode, CSSProperties, Children } from 'react';
import { AlignItems, JustifyContent } from 'types/layout';

type StackAlignItems = Exclude<AlignItems, 'baseline' | 'stretch'>;
type StackJustifyContent = Extract<
  JustifyContent,
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
>;

interface StackProps {
  /**
   * Controls the alignment of the children along the cross axis.
   * @default 'center'
   */
  align?: StackAlignItems;
  /**
   * Controls the alignment of the children along the main axis.
   * @default 'center'
   */
  justify?: StackJustifyContent;
  /**
   * Controls the spacing between children.
   * @default 5
   * @example 10
   */
  spacing?: number;
  /**
   * The style to be applied to the center container.
   */
  style?: CSSProperties;
  /**
   * The children to be rendered.
   */
  children: ReactNode;
}

/**
 * A component that stacks its children vertically with customizable alignment and spacing.
 *
 * @component
 * @param {StackProps} props - The props for the Stack component.
 * @param {StackAlignItems} props.align - Controls the alignment of the children along the cross axis.
 * @param {StackJustifyContent} props.justify - Controls the alignment of the children along the main axis.
 * @param {number} props.spacing - Controls the spacing between children.
 * @returns {JSX.Element} The stacked container with vertically aligned children.
 * @example
 * // Basic usage
 * <Stack>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Stack>
 *
 * // Stacked container with custom alignment and spacing
 * <Stack align="flex-start" justify="space-between" spacing={10}>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Stack>
 */
const DEFAULT_SPACING_VALUE_IN_PX = 5;

export default function Stack({
  align = 'center',
  justify = 'center',
  spacing = DEFAULT_SPACING_VALUE_IN_PX,
  children,
  style,
}: StackProps) {
  const stackStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: align,
    justifyContent: justify,
    gap: spacing,
    ...style,
  };

  return (
    <div style={stackStyle}>
      {Children.map(children, (child) => (
        <div>{child}</div>
      ))}
    </div>
  );
}
