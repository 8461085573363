import React from 'react';

import { useTranslation } from 'react-i18next';

import { Label } from 'semantic';

export default ({ invoice }) => {
  const type = invoice.type;

  const { t } = useTranslation();

  function getLabel(type) {
    if (type === 'cpo-usage') {
      return t('invoiceType.cpo-usage', 'Charge station subscription');
    }
    if (type === 'cpo-credit') {
      return t('invoiceType.cpo-credit', 'Reimbursement invoice');
    }
    if (type === 'msp-usage-delayed-sessions') {
      return t(
        'invoiceType.msp-usage-delayed-sessions',
        'Charge card delayed sessions'
      );
    }
    if (type === 'cpo-credit-delayed-sessions') {
      return t(
        'invoiceType.cpo-credit-delayed-sessions',
        'Reimbursement delayed sessions'
      );
    }
    return t('invoiceType.msp-usage', 'Charge Card Subscription and Usage');
  }

  return <Label>{getLabel(type)}</Label>;
};
