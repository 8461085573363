import React, { ReactNode } from 'react';
import ProductAnalytics from '@road/analytics-sdk-browser';
import { MIXPANEL_TOKEN } from '../utils/env';
import { useUser } from './user';

type ProductInstrumentationContextType = {
  productInstrumentation?: ProductAnalytics;
};

type ProductInstrumentationProviderProps = {
  children: ReactNode;
};

const ProductInstrumentationContext =
  React.createContext<ProductInstrumentationContextType>(
    {} as ProductInstrumentationContextType
  );

export const ProductInstrumentationProvider = ({
  children,
}: ProductInstrumentationProviderProps) => {
  const [productInstrumentation, setProductInstrumentation] = React.useState<
    ProductAnalytics | undefined
  >(undefined);
  const { provider } = useUser();

  React.useEffect(() => {
    if (provider.slug === 'e-flux' && MIXPANEL_TOKEN) {
      const instrumentationInstance = new ProductAnalytics(MIXPANEL_TOKEN);
      setProductInstrumentation(instrumentationInstance);
    }
  }, [provider]);

  return (
    <ProductInstrumentationContext.Provider value={{ productInstrumentation }}>
      {children}
    </ProductInstrumentationContext.Provider>
  );
};

export const useProductInstrumentation = () => {
  const context = React.useContext(ProductInstrumentationContext);
  if (!context) {
    throw new Error(
      'useProductInstrumentation must be used within a ProductInstrumentationProvider'
    );
  }
  return context;
};
