import { Link } from 'react-router-dom';
import React from 'react';
import { Header, Label, Modal, Table } from 'semantic';
import * as PropTypes from 'prop-types';
import {
  formatPowerType,
  formatSessionExcludedReason,
  formatSessionProvider,
  formatSessionStatus,
  formatSyncStatusCell,
  roundUpTwoDigits,
  translateExcludedReason,
} from 'utils/formatting';
import SyncLogStatus from 'components/modals/SyncLogStatus';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatDuration } from 'utils/date';
import Currency from 'components/Currency';
import { CpoSession } from 'types/charging-session';
import { PaymentMethod } from 'components/cpo-payment/paymentMethod';
import AsyncModal from 'helpers/async-modal';
import { IdlePeriodInfo, SessionCostSettings } from 'components/SessionDetails';

SessionDetails.propTypes = {
  session: PropTypes.object.isRequired,
};

type SessionTableProps = {
  data: CpoSession;
};

export function SessionDetails({ data: session }: SessionTableProps) {
  const { t } = useTranslation();

  return (
    <>
      <Modal.Header>View Session {session.id}</Modal.Header>
      <Modal.Content>
        <div>
          <Header as="h3">Basic Details</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>ID</Table.Cell>
                <Table.Cell>{session.id}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>External ID</Table.Cell>
                <Table.Cell>
                  {session.externalId} ({session.externalUniqueId})
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Provider Context</Table.Cell>
                <Table.Cell>
                  <Label content={session.providerContext?.toUpperCase()} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h3">Usage</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>kWh</Table.Cell>
                <Table.Cell>
                  {session.status === 'ACTIVE' ? '-' : session.kwh}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Duration</Table.Cell>
                <Table.Cell>
                  {formatDuration(session.durationSeconds, t)}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Started At</Table.Cell>
                <Table.Cell>{formatDateTime(session.startedAt)}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Ended At</Table.Cell>
                <Table.Cell>
                  {session.endedAt ? formatDateTime(session.endedAt) : '-'}
                  &nbsp;&nbsp;
                  <IdlePeriodInfo session={session} />
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Power Type</Table.Cell>
                <Table.Cell>{formatPowerType(session.powerType)}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h3">Costs &amp; Billing</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Billing Status</Table.Cell>
                <Table.Cell>
                  {session.excluded ? (
                    <>
                      <Label
                        content="Excluded"
                        title={translateExcludedReason(
                          session.excludedReason,
                          t
                        )}
                        color="orange"
                      />
                      {session.excludedReason &&
                        formatSessionExcludedReason(session.excludedReason, t)}
                    </>
                  ) : (
                    <Label content="Included" />
                  )}
                </Table.Cell>
              </Table.Row>
              {typeof session.startCosts === 'number' &&
                session.startCosts >= 0 && (
                  <Table.Row>
                    <Table.Cell>Start Cost</Table.Cell>
                    <Table.Cell>
                      <Currency
                        value={roundUpTwoDigits(session.startCosts)}
                        currency={session.currency}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              {typeof session.timeCosts === 'number' &&
                session.timeCosts >= 0 && (
                  <Table.Row>
                    <Table.Cell width={4}>Time Cost</Table.Cell>
                    <Table.Cell>
                      <Currency
                        value={roundUpTwoDigits(session.timeCosts)}
                        currency={session.currency}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              {typeof session.idleCosts === 'number' &&
                session.idleCosts >= 0 && (
                  <Table.Row>
                    <Table.Cell>Idle Cost</Table.Cell>
                    <Table.Cell>
                      <Currency
                        value={roundUpTwoDigits(session.idleCosts)}
                        currency={session.currency}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}

              {typeof session.energyCosts === 'number' &&
                session.energyCosts >= 0 && (
                  <Table.Row>
                    <Table.Cell>Energy Cost</Table.Cell>
                    <Table.Cell>
                      <Currency
                        value={roundUpTwoDigits(session.energyCosts)}
                        currency={session.currency}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              {typeof session.energyCosts === 'number' &&
                session.energyCosts >= 0 && (
                  <Table.Row>
                    <Table.Cell>Total Cost</Table.Cell>
                    <Table.Cell>
                      <Currency
                        value={roundUpTwoDigits(
                          (session.timeCosts ?? 0) +
                            (session.startCosts ?? 0) +
                            session.energyCosts +
                            (session.idleCosts || 0)
                        )}
                        currency={session.currency}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              <SessionCostSettings session={session} />
            </Table.Body>
          </Table>

          <Header as="h3">Payments Transaction Information</Header>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Payment Method</Table.Cell>
                <Table.Cell>
                  <PaymentMethod
                    source={session.authorization?.payment?.productPaymentFlow}
                    t={t}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Header as="h3">User &amp; Authentication</Header>
          <Table definition>
            <Table.Body>
              {session.rawRecord && (
                <Table.Row>
                  <Table.Cell width={4}>Token Type</Table.Cell>
                  <Table.Cell>{session.rawRecord.tokenType}</Table.Cell>
                </Table.Row>
              )}
              {session.rawRecord && session.rawRecord.tokenVisualNumber && (
                <Table.Row>
                  <Table.Cell width={4}>Token Visual Number</Table.Cell>
                  <Table.Cell>{session.rawRecord.tokenVisualNumber}</Table.Cell>
                </Table.Row>
              )}
              {session.tokenUid && (
                <Table.Row>
                  <Table.Cell width={4}>Token UID</Table.Cell>
                  <Table.Cell>{session.tokenUid}</Table.Cell>
                </Table.Row>
              )}
              {session.rawRecord && (
                <Table.Row>
                  <Table.Cell width={4}>Provider</Table.Cell>
                  <Table.Cell>{formatSessionProvider(session)}</Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell width={4}>Location</Table.Cell>
                <Table.Cell>
                  {session.location ? (
                    <>
                      <Link
                        to={`/charging-stations/locations/${session.location.id}`}>
                        {session.location.name}
                      </Link>{' '}
                      {session.evseController && (
                        <p>
                          <small>
                            (
                            {session.evseController.id ? (
                              <span>
                                <Link
                                  to={`/charging-stations/${session.evseController.id}`}>
                                  {session.evseController.ocppIdentity}
                                </Link>{' '}
                                #{session.connectorId}
                              </span>
                            ) : (
                              `${session.evseController.ocppIdentity} #${session.connectorId}`
                            )}
                            )
                          </small>
                        </p>
                      )}
                    </>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>EVSE ID</Table.Cell>
                <Table.Cell>
                  {session.evseId || session.rawRecord?.evseId || '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Connector ID</Table.Cell>
                <Table.Cell>{session.connectorId || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Status</Table.Cell>
                <Table.Cell>{formatSessionStatus(session.status)}</Table.Cell>
              </Table.Row>
              {session.rawRecord && (
                <>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {t('sessionTable.columnSyncOCPI', 'Sync OCPI')}
                    </Table.Cell>
                    <Table.Cell>
                      {formatSyncStatusCell(
                        session,
                        'session',
                        'push-ocpi-session',
                        SyncLogStatus,
                        {
                          showExternalRefs: true,
                          externalRefFields: [
                            'credentialName',
                            'credentialIds',
                          ],
                        }
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={4}>
                      {t('sessionTable.columnSyncEclearing', 'Sync Eclearing')}
                    </Table.Cell>
                    <Table.Cell>
                      {formatSyncStatusCell(
                        session,
                        'session',
                        'push-eclearing-cpo-session',
                        SyncLogStatus
                      )}
                    </Table.Cell>
                  </Table.Row>
                </>
              )}
            </Table.Body>
          </Table>
        </div>
      </Modal.Content>
      <Modal.Actions />
    </>
  );
}

export default AsyncModal(SessionDetails);
