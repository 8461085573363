export default {
  'code[class*="language-"]': {
    color: '#222222',
    background: 'none',
    fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    fontSize: '1em',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: '#222222',
    background: '#f6f8fa',
    fontFamily: "Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace",
    fontSize: '1em',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    MozTabSize: '4',
    OTabSize: '4',
    tabSize: '4',
    WebkitHyphens: 'none',
    MozHyphens: 'none',
    msHyphens: 'none',
    hyphens: 'none',
    padding: '1em',
    margin: '.5em 0',
    overflow: 'auto',
    border: '1px solid rgba(34,36,38,.15)',
  },
  ':not(pre) > code[class*="language-"]': {
    background: '#f6f8fa',
    padding: '.1em',
    borderRadius: '.3em',
    whiteSpace: 'normal',
  },
  comment: {
    color: '#AAAAAA',
  },
  'block-comment': {
    color: '#AAAAAA',
  },
  prolog: {
    color: '#AAAAAA',
  },
  doctype: {
    color: '#AAAAAA',
  },
  cdata: {
    color: '#AAAAAA',
  },
  punctuation: {
    color: '#222222',
  },
  tag: {
    color: '#1E7A58',
  },
  'attr-name': {
    color: '#1E7A58',
  },
  namespace: {
    color: '#1E7A58',
  },
  deleted: {
    color: '#C13600',
  },
  'function-name': {
    color: '#217BA3',
  },
  boolean: {
    color: '#C13600',
  },
  number: {
    color: '#C13600',
  },
  function: {
    color: '#C13600',
  },
  property: {
    color: '#CC8E0B',
  },
  'class-name': {
    color: '#CC8E0B',
  },
  constant: {
    color: '#CC8E0B',
  },
  symbol: {
    color: '#CC8E0B',
  },
  selector: {
    color: '#8B388B',
  },
  important: {
    color: '#8B388B',
    fontWeight: 'bold',
  },
  atrule: {
    color: '#8B388B',
  },
  keyword: {
    color: '#8B388B',
  },
  builtin: {
    color: '#8B388B',
  },
  string: {
    color: '#CC6A00',
  },
  char: {
    color: '#CC6A00',
  },
  'attr-value': {
    color: '#CC6A00',
  },
  regex: {
    color: '#CC6A00',
  },
  variable: {
    color: '#CC6A00',
  },
  operator: {
    color: '#222222',
  },
  entity: {
    color: '#217BA3',
    cursor: 'help',
  },
  url: {
    color: '#217BA3',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  inserted: {
    color: '#197400',
  },
};
