import React, { useState, useEffect } from 'react';

import { Breadcrumbs, ErrorMessage } from 'components';
import { Divider, Header, Loader } from 'semantic';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { request } from 'utils/api';

import { DetailItem } from '../common';
import BasicDetailTable from './BasicDetailTable';
import SessionDetailTable from './SessionDetailTable';
import PaymentFeesDetailTable from './PaymentFeesDetailTable';

const myPaymentFeesListBreadcrumbPath = '/my-payment-fees';

async function loader(paymentId: string): Promise<DetailItem> {
  const { data } = await request({
    method: 'GET',
    path: `/1/cpo-payments/${paymentId}/fees`,
  });
  return data;
}

type Params = {
  paymentId: string;
};

export default function MyPaymentFeesDetail() {
  const { t } = useTranslation();
  const { paymentId } = useParams<Params>();
  const [item, setItem] = useState<DetailItem | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    loader(paymentId)
      .then((item) => {
        setItem(item);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [paymentId]);

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="finance" to={myPaymentFeesListBreadcrumbPath}>
            {t('my-payment-fees.breadcrumb.list', 'My Payment Fees')}
          </Link>,
        ]}
        active={paymentId || '...'}
      />
      <Header as="h2">{t('my-payment-fees.detail.header', 'Payment')}</Header>
      <Divider hidden />
      {loading && <Loader active />}
      {error && <ErrorMessage error={error} />}

      {item && (
        <>
          <BasicDetailTable item={item} />
          <Divider hidden />
          <SessionDetailTable item={item} />
          <Divider hidden />
          <PaymentFeesDetailTable item={item} />
        </>
      )}
    </>
  );
}
