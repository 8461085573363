import React from 'react';
import { request } from 'utils/api';
import AppWrapper from 'components/AppWrapper';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Segment,
} from '../../../semantic';
import Filters from './Filters';
import Search from 'components/SearchInput';
import DataList from 'components/DataList';
import Table from './EvseIdsTable';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../utils/date';

const itemLimit = 100;

export default class BatchEvseIds extends React.Component {
  state = {
    dateKey: Date.now(),
    batch: undefined,
  };

  getBatchId() {
    return this.props.match.params.id;
  }

  async componentDidMount() {
    const response = await this.getBatch();
    this.setState({
      batch: response,
    });
  }

  handleDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/evse-ids/${item.id}`,
    }).then(() => this.refresh());
  };

  handleRequest = (appliedFilters = {}) => {
    const { searchId, filters } = this.state;
    return request({
      method: 'POST',
      path: '/1/evse-ids/search',
      body: {
        ...appliedFilters,
        ...(searchId ? { searchId } : {}),
        ...filters,
        evseIdPrintBatchIds: [this.getBatchId()],
        limit: itemLimit,
      },
    });
  };

  getBatch = async () => {
    const response = await request({
      method: 'GET',
      path: `/1/evse-ids/print-batches/${this.getBatchId()}`,
    });

    return response.data;
  };

  handleFilterChange = (filters) => {
    this.setState(
      {
        filters: filters.reduce(
          (result, item) => ({
            ...result,
            ...(item.apiFilter || {}),
          }),
          {}
        ),
      },
      () => this.refresh()
    );
  };

  handleOnSearch = (searchId) => {
    this.setState({ searchId }, () => this.refresh());
  };

  refresh() {
    this.setState({ dataKey: Date.now() });
  }

  render() {
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="charging-stations" to="/charging-stations">
                Charging Stations
              </Link>,
              <Link key="evse-ids" to="/charging-stations/evse-ids">
                EVSE IDs
              </Link>,
              <Link
                key="evse-id-batches"
                to="/charging-stations/evse-ids/batches">
                Print Batches
              </Link>,
            ]}
            active={this.state.batch?.note}
          />

          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              textAlign="right"
              verticalAlign="middle">
              <Button
                primary
                content="Export"
                icon="download"
                onClick={() =>
                  this.handleRequest({
                    format: 'csv',
                    filename: `evse-ids-${new Date().toISOString()}`,
                  })
                }
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Header as="h2" style={{ margin: '0' }}>
                EVSE IDs
              </Header>
            </Grid.Column>
          </Grid>

          <Divider />

          <p>
            Batch created for: <strong>{this.state.batch?.note}</strong>
          </p>
          <p>
            Batch created at:{' '}
            <strong> {formatDateTime(this.state.batch?.createdAt)}</strong>
          </p>
          <p>
            Batch created by:
            <strong> {this.state.batch?.createdBy?.name}</strong>
          </p>
          <p>
            Number of subconnectors:
            <strong> {this.state.batch?.numSubConnectors}</strong>
          </p>

          <Divider />

          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={10}>
                  <Filters onChange={this.handleFilterChange} />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Search
                    placeholder="Search by ID, EVSE ID, Serial Number, IMSI, etc"
                    onChange={this.handleOnSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <p>
            These are reserved EVSE IDs that can be used to pair charge
            stations. Please note that some of these may have been sent out to
            field service providers or customers already.
          </p>

          <DataList
            key={this.state.dataKey}
            limit={itemLimit}
            onRequest={this.handleRequest}>
            <Table
              onDeleteItem={this.handleDeleteItem}
              onRefresh={() => this.refresh()}
            />
          </DataList>
          <Divider hidden />
        </Container>
      </AppWrapper>
    );
  }
}
