import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EvseControllerMigrationProps,
  SearchDropdownResult,
} from 'interfaces/interfaces';
import { Modal, Form, Button, Dropdown, Message } from 'semantic';
import { request } from 'utils/api';
import SearchDropDown from 'components/form-fields/SearchDropdown';

type RequestBody = {
  newProviderId: string;
};

export default function EvseControllerMigration({
  evseControllerId,
  trigger,
  onReload,
}: EvseControllerMigrationProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [formError, setFormError] = useState(false);

  const [providerId, setProviderId] = useState<string | null>(null);
  const [_, setProviderName] = useState<string | null>(null);

  async function handleSubmit(evseControllerId: string) {
    if (!providerId) {
      setFormError(true);
      return;
    }

    setLoading(true);
    setError(null);

    const body: RequestBody = {
      newProviderId: providerId,
    };

    try {
      await request({
        path: `/1/evse-controllers/${evseControllerId}/migrate`,
        method: 'POST',
        body: body,
      });
      setLoading(false);
      setOpen(false);
      onReload();
    } catch (error: any) {
      setError(error);
      setFormError(error);
      setLoading(false);
    }
  }

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}>
      <Modal.Header>
        {t('evseControllerMigrationModal.header', 'EVSE Controller Migration')}
      </Modal.Header>
      {!error && (
        <>
          <Modal.Content>
            <p>
              {t(
                'evseControllerMigrationModal.description',
                'This action will migrate the EVSE Controller to a new provider. Please select the provider to associate with this EVSE Controller.'
              )}
            </p>

            <Form id="evseControllerMigration-form" error={formError}>
              {formError && (
                <Message
                  error
                  content={t(
                    'evseControllerMigrationModal.validationError',
                    `Please select a provider.`
                  )}
                />
              )}

              <Form.Field required>
                <label>Select Provider</label>
                <SearchDropDown
                  clearable={false}
                  placeholder="Select/Search Provider"
                  value={providerId}
                  objectMode={false}
                  onDataNeeded={(body: RequestBody) => {
                    return request({
                      path: '/1/providers/search',
                      method: 'POST',
                      body: body,
                    });
                  }}
                  onChange={(
                    e: SyntheticEvent,
                    { value, options }: SearchDropdownResult
                  ) => {
                    setProviderId(value);
                    setProviderName(
                      options.find(
                        (option: { value: string }) => option.value === value
                      )?.text || ''
                    );
                  }}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              form="evseControllerMigration-form"
              type="submit"
              onClick={() => handleSubmit(evseControllerId)}>
              {t('evseControllerMigrationModal.submit', 'Submit')}
            </Button>
          </Modal.Actions>
        </>
      )}
      {error && (
        <Modal.Content>
          <Message
            error
            header={t(
              'evseControllerMigrationModal.errorHeader',
              'An error occurred'
            )}
            content={t(
              'evseControllerMigrationModal.errorContent',
              `${error?.message || 'Internal Server Error'}`
            )}
          />
        </Modal.Content>
      )}
    </Modal>
  );
}
