import React from 'react';

import { Grid } from 'semantic';

export const Paragraph = ({ children }) => {
  return (
    <p
      style={{
        paddingTop: '1rem',
        paddingBottom: 0,
        marginBottom: 0,
        fontSize: '1.1rem',
      }}>
      {children}
    </p>
  );
};

export const Counter = ({ value, label }) => {
  return (
    <Grid.Column
      style={{
        textAlign: 'center',
        minWidth: '200px',
      }}>
      <div
        style={{
          padding: '0.5rem',
          border: '1px solid #e2e2e2',
          borderRadius: '1rem',
        }}>
        <p
          style={{
            marginBottom: '0',
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}>
          {label}
        </p>
        <p style={{ fontWeight: 'bold', fontSize: '3rem' }}>{value || 0}</p>
      </div>
    </Grid.Column>
  );
};
