import React from 'react';
import {
  Button,
  Header,
  Table,
  Modal,
  Segment,
  Label,
  Message,
} from 'semantic';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import styled from 'styled-components';
import modal from 'helpers/modal';
import { useTheme } from 'contexts/theme';
import { formatDateTime } from 'utils/date';

const StyledLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

function Stacktrace({ item }) {
  const { renderedTheme } = useTheme();
  return (
    <Segment inverted={renderedTheme !== 'dark'}>
      <pre style={{ fontSize: '0.9em', whiteSpace: 'pre-wrap' }}>
        {item.stackTraces[0]}
      </pre>{' '}
    </Segment>
  );
}

class SyncLogDetailModal extends React.Component {
  state = {
    loadMap: {},
    errorMessage: null,
    showDocuments: false,
  };

  async requestResync(syncName, modelName) {
    const { item } = this.props;
    let { loadMap } = this.state;

    const key = syncName + modelName;
    loadMap[key] = true;
    this.setState({ loadMap });
    const ids = item.docs.map((doc) => doc.id);
    let response;
    try {
      response = await request({
        method: 'POST',
        path: '/1/external-sync/request-sync',
        body: {
          ids: ids,
          modelName,
          externalSyncName: syncName,
        },
      });
    } catch (err) {
      this.setState({ errorMessage: err.message });
    }
    loadMap = this.state.loadMap;
    loadMap[key] = false;
    this.setState({
      loadMap,
    });
    if (response) {
      this.setState({
        message: `Succesfully requested sync for ${response.data.length} documents`,
      });
    }
  }

  render() {
    const { item, t } = this.props;
    const { loadMap, errorMessage, message, showDocuments } = this.state;

    if (!item) return;
    const byModelName = item.docs.reduce((memo, val) => {
      memo[val.model] = memo[val.model] || [];
      memo[val.model].push(val);
      return memo;
    }, {});

    return (
      <>
        <Modal.Content>
          <Header as="h2">
            {t('synclogIssues.detailTitle', 'Issue details')}
          </Header>
          {!!item.syncJobs.length && (
            <>
              <Header as="h3">
                {t('synclogIssues.detailActions', 'Actions')}
              </Header>
              {errorMessage && <Message error content={errorMessage} />}
              {message && <Message info content={message} />}
              {item.syncJobs.map((job) => (
                <Button
                  basic
                  key={job.syncName + job.modelName}
                  label={`${job.modelName} - ${job.syncName}`}
                  size="small"
                  icon="arrows-rotate"
                  content="Request resync"
                  loading={!!loadMap[job.syncName + job.modelName]}
                  onClick={() =>
                    this.requestResync(job.syncName, job.modelName)
                  }
                />
              ))}
            </>
          )}

          <Table definition>
            <Table.Body style={{ fontSize: '0.9em' }}>
              <Table.Row>
                <Table.Cell width={2}>
                  {t('synclogIssues.detailMessage', 'Message')}
                </Table.Cell>
                <Table.Cell width={8}>
                  {item.message.replaceAll('","', '", "')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('synclogIssues.detailLastSeen', 'Last seen')}
                </Table.Cell>
                <Table.Cell>{formatDateTime(item.lastOccurence)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('synclogIssues.detailFirstSeen', 'First seen')}
                </Table.Cell>
                <Table.Cell>{formatDateTime(item.firstOccurence)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {t('synclogIssues.detailCount', 'Count')}
                </Table.Cell>
                <Table.Cell>
                  <Label circular>{item.count}</Label>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {!!item.stackTraces.length && (
            <>
              <Header as="h3">
                {t('synclogIssues.stackTrace', 'Stack trace')}
              </Header>
              <p>
                {t('synclogIssues.fromMostRecent', 'From most recent call')}
              </p>
              <Stacktrace item={item} />
            </>
          )}
          <Header
            as="h3"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}>
            <p style={{ margin: '0' }}>
              {t('synclogIssues.detailDocuments', 'Documents')}
            </p>
            <Button
              onClick={() => this.setState({ showDocuments: !showDocuments })}>
              {showDocuments ? 'hide' : 'show'} documents
            </Button>
          </Header>
          <Table definition style={{ display: !showDocuments && 'none' }}>
            <Table.Body>
              {Object.keys(byModelName).map((key) => (
                <Table.Row key={key}>
                  <Table.Cell width={2}>{key}</Table.Cell>
                  <Table.Cell width={8}>
                    {showDocuments &&
                      byModelName[key].map((instance, i) => (
                        <span key={key}>
                          <StyledLink
                            href={`/${key.toLowerCase()}s/${instance.id}`}>
                            {instance.id}
                          </StyledLink>
                          {i < byModelName[key].length - 1 ? `, ` : ''}
                        </span>
                      ))}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </>
    );
  }
}

export default withTranslation()(modal(SyncLogDetailModal));
