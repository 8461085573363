import React, { Suspense, useState } from 'react';
import { request } from 'utils/api';

export default ({ children }) => {
  const [providerId, setProviderId] = useState(null);

  // get providerID from query params
  const params = new URLSearchParams(window.location.search);

  const overriddenProviderId = params.get('providerId');
  if (overriddenProviderId) {
    sessionStorage.setItem('resourceProviderId', overriddenProviderId);
  }

  useState(() => {
    if (!overriddenProviderId) {
      return;
    }
    return request({
      method: 'GET',
      path: `/1/providers/public/${overriddenProviderId}`,
    }).then(({ data }) => {
      sessionStorage.setItem('sessionProviderName', data?.name);
      sessionStorage.setItem('resourceProviderId', overriddenProviderId);
      setProviderId(overriddenProviderId);
    });
  }, []);

  return <Suspense>{children}</Suspense>;
};
