import React, { JSX } from 'react';

import { capitalize } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Table,
  Item,
  Message,
  SemanticCOLORS,
  Label,
  Popup,
} from 'semantic';
import { Link } from 'react-router-dom';

import { hasConnectorInfo } from 'utils/evse-controllers';
import EvseConnector from 'components/EvseConnector';
import { formatDateTime } from 'utils/date';
import {
  EvseControllerConnectorEvseIds,
  EvseControllerEvseId,
} from 'components/EvseId';
import { useFeatures } from 'contexts/features';
import CircularIcon from 'components/CircularIcon';
import { EvseController } from 'types/evse-controller';
import { EvseOperationalStatusType } from 'types/evse-operational-status';
import { Card } from 'types/card';

type Props = {
  evseController: EvseController;
  maintainerMode: boolean;
};

const EvseControllerOverview = ({ evseController, maintainerMode }: Props) => {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const { bootInfo, connectors, accessGroups } = evseController;
  const hasOperationalStatusesPageFeature = hasFeature(
    'operational_statuses_page'
  );
  const hasOperationalStatus =
    hasOperationalStatusesPageFeature && evseController.evseOperationalStatus;

  const pairedChargeCardSyncStatus = (
    status: string,
    pairedChargeCardId: string
  ) => {
    let color: SemanticCOLORS;
    let content: string;

    switch (status) {
      case 'InProgress':
        color = 'blue';
        content = `Pending${pairedChargeCardId ? '' : ' Unpair'}`;
        break;
      case 'Completed':
        color = 'green';
        content = 'Paired';
        break;
      case 'Failed':
        color = 'red';
        content = 'Failed';
        break;
      default:
        return;
    }

    return <Label content={content} color={color} />;
  };

  const pairedChargeCardTokenInfo = (pairedChargeCard: Card): JSX.Element => {
    const { token } = pairedChargeCard;

    if (!token) {
      return <>{pairedChargeCard.id}&nbsp;&nbsp;</>;
    }

    return (
      <>
        {token.visualNumber || token.customId} - {token.uid}&nbsp;&nbsp;
      </>
    );
  };

  return (
    <div>
      <Header as="h3">Connectors</Header>
      {hasConnectorInfo(evseController) ? (
        <Item.Group divided>
          {connectors?.map((connector: any) => {
            const connectorStatus =
              evseController.connectorStatus?.[connector.connectorId]?.status;
            const evseId =
              connector.evseId ||
              connector.evseIdRef?.identifier ||
              evseController.evseId ||
              evseController.evseIdRef?.identifier;
            return (
              <EvseConnector
                connectorStandard={connector.standard}
                key={connector.connectorId}
                evseId={evseId}
                powerType={connector.powerType}
                connectorFormat={connector.format}
                status={connectorStatus}
                activeSession={null}
              />
            );
          })}
        </Item.Group>
      ) : (
        <Message content="No connectors configured yet" />
      )}

      <Header as="h3">Access Groups</Header>
      {accessGroups?.length ? (
        <Table definition collapsing>
          <Table.Body>
            {accessGroups.map((accessGroup) => (
              <Table.Row key={accessGroup.id}>
                <Table.Cell>{accessGroup.name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message content="No access groups configured yet" />
      )}

      <Header as="h3">Details</Header>
      <Table definition fixed>
        <Table.Body>
          {hasOperationalStatus && (
            <Table.Row>
              <Table.Cell>
                {t('evseOperationalStatus.title', 'Operational Status')}
              </Table.Cell>
              <Table.Cell>
                <Label
                  content={
                    evseController?.evseOperationalStatus?.type ===
                    EvseOperationalStatusType.System
                      ? t(
                          `evseOperationalStatuses.systemName.${evseController?.evseOperationalStatus?.name}`,
                          capitalize(
                            evseController?.evseOperationalStatus?.name
                          )
                        )
                      : evseController?.evseOperationalStatus?.name
                  }
                  color={evseController?.evseOperationalStatus?.badgeColor}
                />
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Location</Table.Cell>
            <Table.Cell>
              {evseController.location ? (
                maintainerMode ? (
                  evseController.location.name || 'No Name'
                ) : (
                  <Link
                    to={`/charging-stations/locations/${evseController.location.id}`}>
                    {evseController.location.name || 'No Name'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Account</Table.Cell>
            <Table.Cell>
              {evseController.account ? (
                maintainerMode ? (
                  evseController.account.name || 'No Name'
                ) : (
                  <Link to={`/accounts/${evseController.account.id}`}>
                    {evseController.account.name || 'No Name'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          {evseController.description && (
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{evseController.description}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Field Service Account</Table.Cell>
            <Table.Cell>
              {evseController.maintenanceAccount ? (
                maintainerMode ? (
                  evseController.maintenanceAccount.name || 'No Name'
                ) : (
                  <Link
                    to={`/accounts/${evseController.maintenanceAccount.id}`}>
                    {evseController.maintenanceAccount.name || 'No Name'}
                  </Link>
                )
              ) : (
                '-'
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Billing Plan</Table.Cell>
            <Table.Cell>
              {evseController.billingPlan &&
                evseController.billingPlan.details?.en.name}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>OCPP Identity</Table.Cell>
            <Table.Cell>{evseController.ocppIdentity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>EVSE ID</Table.Cell>
            <Table.Cell>
              <EvseControllerEvseId evseController={evseController} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>EVSE IDs (Connectors)</Table.Cell>
            <Table.Cell>
              <EvseControllerConnectorEvseIds evseController={evseController} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Serial Number</Table.Cell>
            <Table.Cell>
              {evseController.serialNumber ||
                evseController.bootInfo?.chargePointSerialNumber}
            </Table.Cell>
          </Table.Row>
          {(evseController.pairedChargeCardId ||
            (evseController.pairedChargeCardSync?.status &&
              evseController.pairedChargeCardSync?.status !== 'Completed')) && (
            <Table.Row>
              <Table.Cell>Paired Charge Card</Table.Cell>
              <Table.Cell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {evseController.pairedChargeCard &&
                    pairedChargeCardTokenInfo(evseController.pairedChargeCard)}
                  {pairedChargeCardSyncStatus(
                    evseController.pairedChargeCardSync?.status || '',
                    evseController.pairedChargeCardId || ''
                  )}
                  {evseController.pairedChargeCardSync?.statusDetails && (
                    <Popup
                      style={{ marginInlineStart: '-10px' }}
                      trigger={
                        <div style={{ marginInlineStart: '3px' }}>
                          <CircularIcon color="grey" icon="info" />
                        </div>
                      }
                      content={
                        evseController.pairedChargeCardSync?.statusDetails
                      }
                    />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Created At</Table.Cell>
            <Table.Cell>{formatDateTime(evseController.createdAt)}</Table.Cell>
          </Table.Row>
          {evseController.activatedAt && (
            <Table.Row>
              <Table.Cell>Activated At</Table.Cell>
              <Table.Cell>
                {formatDateTime(evseController.activatedAt)}
              </Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Updated At</Table.Cell>
            <Table.Cell>{formatDateTime(evseController.updatedAt)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Provider</Table.Cell>
            <Table.Cell>{evseController.provider?.name}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      {bootInfo && (
        <>
          <Header as="h3">Boot Info</Header>
          <Table definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Vendor</Table.Cell>
                <Table.Cell>{bootInfo.chargePointVendor || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Model</Table.Cell>
                <Table.Cell>{bootInfo.chargePointModel || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Serial Number</Table.Cell>
                <Table.Cell>
                  {bootInfo.chargePointSerialNumber || '-'}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Box Serial Number</Table.Cell>
                <Table.Cell>{bootInfo.chargeBoxSerialNumber || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Firmware Version</Table.Cell>
                <Table.Cell>{bootInfo.firmwareVersion || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>IMSI</Table.Cell>
                <Table.Cell>{bootInfo.imsi || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>ICCID</Table.Cell>
                <Table.Cell>{bootInfo.iccid || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Meter Type</Table.Cell>
                <Table.Cell>{bootInfo.meterType || '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Meter Serial Number</Table.Cell>
                <Table.Cell>{bootInfo.meterSerialNumber || '-'}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}

      {hasFeature('evsecontroller-labels') && (
        <>
          <Header as="h3">Other Details</Header>
          <Table definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Labels</Table.Cell>
                <Table.Cell>
                  {evseController.labels?.map((label: string) => (
                    <Label key={label}>{label}</Label>
                  ))}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </div>
  );
};

export default EvseControllerOverview;
