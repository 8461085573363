import { formatCurrency } from 'utils/formatting';
import React from 'react';

interface PriceTextProps {
  label: string;
  amount: number;
  currency: string;
  scalar?: number;
}

export function PriceText(props: PriceTextProps) {
  const { label, amount, currency, scalar = 1 } = props;
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        {formatCurrency(amount * scalar, { currency, exact: true })}
      </span>
      &nbsp;
      {label}
    </>
  );
}
