import React from 'react';
import { API_URL } from 'utils/env';
import { useSandbox } from 'contexts/sandbox';
import { IS_STAGING, IS_DEMO } from 'utils/env';

export const isNotProd = () =>
  API_URL === 'http://localhost:2300' || IS_STAGING || IS_DEMO;

export const environmentBannerHeight = '28px';

const Banner = ({ children, backgroundColor, color }) => (
  <div
    style={{
      backgroundColor,
      color,
      padding: '4px',
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: environmentBannerHeight,
      width: '100%',
      zIndex: '99999999',
    }}>
    <span>{children}</span>
  </div>
);

const environmentLabel = () => {
  if (IS_STAGING) {
    return 'STAGING';
  }
  if (IS_DEMO) {
    return 'DEMO';
  }
  return 'DEVELOPMENT';
};

export const EnvironmentBanner = ({ children }) => {
  const { sandboxMode } = useSandbox();
  return (
    <>
      {isNotProd() && (
        <Banner backgroundColor="#666" color="#ccc">
          {environmentLabel()} ENVIRONMENT
        </Banner>
      )}
      {sessionStorage.getItem('resourceProviderId') && (
        <Banner backgroundColor="#3d4db6" color="#ccc">
          VIEWING PROVIDER: {sessionStorage.getItem('sessionProviderName')}
        </Banner>
      )}
      {sandboxMode && (
        <Banner backgroundColor="#666" color="#ccc">
          SANDBOX MODE
        </Banner>
      )}
      {children}
    </>
  );
};
