import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useTheme } from 'contexts/theme';
import React, { useMemo, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useInvoiceEventLogs from 'screens/InvoiceEventLogs/data/api';
import {
  extractEventPayload,
  mapOperationType,
} from 'screens/InvoiceEventLogs/data/utils';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import { Form, Message } from 'semantic';

export default function InvoiceLogDiffWrapper() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);

  const { invoiceLogId }: { invoiceLogId: string } = useParams();

  return (
    <QueryClientProvider client={queryClient}>
      <InvoiceLogDetailDiff id={invoiceLogId} />
    </QueryClientProvider>
  );
}

function InvoiceLogDetailDiff({ id }: { id: string }) {
  const { data } = useInvoiceEventLogs(id);

  const { t } = useTranslation();
  const { theme } = useTheme();

  const [eventInputOne, setEventInputOne] = useState(null);
  const [eventInputTwo, setEventInputTwo] = useState(null);

  if (data && data.length === 0) {
    return (
      <Message
        info
        content={t(
          'invoiceLogs.diff.noData',
          'No events are available for comparison.'
        )}
      />
    );
  }

  const options =
    data &&
    data.map((event, index) => {
      const operationType = mapOperationType(event.Payload.operationType);
      return {
        key: event.CreatedAt,
        text: `Event ${index + 1} - ${operationType} `,
        value: event._id,
      };
    });

  const selectedEventOne = extractEventPayload(data, eventInputOne);
  const selectedEventTwo = extractEventPayload(data, eventInputTwo);

  return (
    <>
      <Form.Dropdown
        selection
        fluid
        name="eventOneInput"
        label={t('invoiceLogs.diff.eventSelector', 'Select Event')}
        clearable
        options={options}
        value={eventInputOne}
        onChange={(e, { value }) => {
          setEventInputOne(value);
        }}
        style={{ maxWidth: '400px' }}
      />

      <FormVerticalSpace />

      <Form.Dropdown
        selection
        fluid
        clearable
        name="event"
        label={t('invoiceLogs.diff.eventSelector', 'Select Event')}
        options={options}
        value={eventInputTwo}
        onChange={(e, { value }) => {
          setEventInputTwo(value);
        }}
        style={{ maxWidth: '400px' }}
      />

      {eventInputOne && eventInputTwo && (
        <>
          <FormVerticalSpace />
          <ReactDiffViewer
            oldValue={JSON.stringify(selectedEventOne, null, ' ')}
            newValue={JSON.stringify(selectedEventTwo, null, ' ')}
            splitView={false}
            hideLineNumbers={true}
            disableWordDiff={true}
            useDarkTheme={theme === 'dark'}
          />
        </>
      )}

      <FormVerticalSpace />
    </>
  );
}
