import React, { useEffect, useRef } from 'react';
import { Form } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'utils/use-debounce';
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from 'postcode-validator';

export default function PostalCode({
  value,
  name,
  label,
  countryCode,
  onChange,
  readOnly = false,
  ...rest
}) {
  const { t } = useTranslation();

  const [error, setError] = React.useState();
  const [intervalValue, setIntervalValue] = React.useState(value);
  const testValue = useDebounceValue(intervalValue, 500);
  const [isLoading, setIsLoading] = React.useState(false);

  const ref = useRef();

  useEffect(() => {
    if (ref.current && rest.required) {
      ref.current.setCustomValidity(error ? error : '');
    }
  }, [ref.current, error, rest]);

  function wrappedOnChange(e, props) {
    if (value === props.value) {
      return;
    }
    onChange(e, props);
  }

  useEffect(() => {
    const country = countryCode?.toUpperCase();

    // XXX should be moved to the serverside
    if (country && postcodeValidatorExistsForCountry(country)) {
      setError(
        postcodeValidator(testValue, country) || !testValue?.length
          ? null
          : t('error.notAPostalCode', 'Not a valid postal code')
      );
      wrappedOnChange(null, { name, value: testValue });
    } else {
      wrappedOnChange(null, { name, value: testValue });
    }

    setIsLoading(false);
  }, [testValue, countryCode, wrappedOnChange]);

  return (
    <Form.Input
      input={{ ref }}
      error={error}
      value={intervalValue}
      loading={isLoading}
      required={rest.required}
      name={name}
      label={label || t('formLabel.postCode', 'Postal Code')}
      type="text"
      onChange={(e, props) => {
        setIsLoading(true);
        setIntervalValue(props.value?.toUpperCase().trim());
      }}
      readOnly={readOnly}
    />
  );
}
