import React, { useState } from 'react';
import { Divider, Message } from 'semantic';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import SettingsShell from '../../../components/wrappers/SettingsShell';
import FormHeader from '../../../components/atoms/FormHeader';
import RemovePersonalBilling from './RemovePersonalBilling';

export default function PersonalBillingSettings() {
  // @ts-ignore until the user context is typed
  const { user } = useUser();
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <SettingsShell>
      <FormHeader
        pt={5}
        pb={5}
        text={t('setting.personal.billing.title', 'Personal Billing')}
      />

      <Divider />
      {errorMessage && <Message error content={errorMessage} />}
      {success && (
        <Message
          success
          content={t(
            'setting.personal.billing.removed',
            'Your personal billing info removed successfully!'
          )}
        />
      )}
      {user.billing ? (
        <RemovePersonalBilling
          setErrorMessage={setErrorMessage}
          setSuccess={setSuccess}
          t={t}
        />
      ) : (
        <Message>
          {t(
            'settings.personal.billing.noBilling',
            'You do not have personal billing information, to set billing information please check your account billing settings.'
          )}
        </Message>
      )}
    </SettingsShell>
  );
}
