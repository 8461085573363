import React from 'react';
import {
  Table,
  Header,
  Loader,
  Message,
  Button,
  Form,
  Segment,
} from 'semantic';
import { formatEuro } from 'utils/formatting';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { downloadCsv } from 'utils/csv';
import { format } from 'date-fns';
import { Layout } from 'components/Layout';
import Actions from '../Actions';

const date = new Date();

// we load 10 months at the time to make sure we response a bit faster, on production this takes roughly 55 secs to load
// cutting this down to 10 months means it loads ~25 secs which is still a lot
// this is specially important for some of the revenueType filters as that is even slower,
// loading 10 months bring those filters into a similar loading time of ~50 secs.
const numberOfMonths = 10;

// DATES are in UTC because the server aggration pipeline is using UTC, so we need to match the dates

function getDefaultState() {
  return {
    loading: true,
    firstLoad: true,
    from: new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth() - numberOfMonths,
        1,
        0,
        0,
        0,
        0
      )
    ),
    to: new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), 0, 23, 59, 59, 999)
    ),
    hasMoreData: false,
    data: [],
  };
}

class SessionsRevenueBreakdown extends React.Component {
  state = {
    revenueType: 'platform',
    ...getDefaultState(),
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch(append) {
    try {
      this.setState({
        loading: true,
      });
      const query = {
        revenueType: this.state.revenueType,
      };
      // because this are query params they are send as iso string
      if (this.state.from) {
        query.from = this.state.from.toISOString();
      }

      // on the first load we load also all the future months by not setting the to date
      if (this.state.to && !this.state.firstLoad) {
        query.to = this.state.to.toISOString();
      }

      const { data } = await request({
        method: 'GET',
        path: '/2/sessions/cpo/breakdown/revenue',
        params: query,
      });

      // make sure that if there is a gap of 10 months we load all the rest of the data
      if (!data.length && this.state.from) {
        this.setState(
          {
            from: undefined,
            to: this.state.to,
            firstLoad: false,
          },
          () => {
            this.fetch(append);
          }
        );
        return;
      }

      this.setState({
        hasMoreData: !!data.length,
        firstLoad: false,
        data: append ? [...this.state.data, ...data] : data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  async loadMore() {
    const { from, to } = this.state;
    // 10 months earlier
    const newFrom = new Date(
      Date.UTC(
        from.getFullYear(),
        from.getMonth() - numberOfMonths,
        1,
        0,
        0,
        0,
        0
      )
    );

    const newTo = new Date(
      Date.UTC(
        to.getFullYear(),
        to.getMonth() - numberOfMonths,
        0,
        23,
        59,
        59,
        999
      )
    );

    this.setState(
      {
        from: newFrom,
        to: newTo,
      },
      () => {
        this.fetch(true);
      }
    );
  }

  render() {
    const { loading, error, data } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Header as="h3">
          {t(
            'cpoSessionsFinance.revenueBreakdownTitle',
            'CPO Charge Sessions Revenue Breakdown'
          )}
        </Header>
        <Segment>
          <Layout spread horizontal>
            <div
              style={{
                paddingBottom: '0em',
                paddingTop: '0em',
                lineHeight: '1.5em',
              }}>
              <div>
                <b>
                  {t(
                    'cpoSessionsFinance.revenueBreakdownRevenueRoad',
                    'Revenue Road'
                  )}
                  :
                </b>{' '}
                {t(
                  'cpoSessionsFinance.revenueBreakdownRevenueRoadDescription',
                  'Revenue of charge sessions processed by Road'
                )}
              </div>
              <div>
                <b>
                  {t(
                    'cpoSessionsFinance.revenueBreakdownRevenueOther',
                    'Revenue Other'
                  )}
                  :
                </b>{' '}
                {t(
                  'cpoSessionsFinance.revenueBreakdownRevenueOtherDescription',
                  'Revenue of charge sessions processed by other Payment Service Providers'
                )}
              </div>
              <div>
                <b>
                  {t(
                    'cpoSessionsFinance.revenueBreakdownTotalRevenue',
                    'Total Revenue'
                  )}
                  :
                </b>{' '}
                {t(
                  'cpoSessionsFinance.revenueBreakdownTotalRevenueDescription',
                  'Total revenue of sessions (= Road + Other)'
                )}
              </div>
            </div>
            <Form style={{ marginTop: '1em', float: 'right' }}>
              <Form.Select
                width={4}
                value={this.state.revenueType}
                disabled={this.state.loading}
                onChange={(e, { value }) => {
                  this.setState(
                    { revenueType: value, ...getDefaultState() },
                    () => {
                      this.fetch();
                    }
                  );
                }}
                options={[
                  {
                    key: 'platform',
                    value: 'platform',
                    text: t(
                      'cpoSessionsFinance.revenueBreakdownRevenueRoad',
                      'Revenue Road'
                    ),
                  },
                  {
                    key: 'other',
                    value: 'other',
                    text: t(
                      'cpoSessionsFinance.revenueBreakdownRevenueOther',
                      'Revenue Other'
                    ),
                  },
                  {
                    key: 'total',
                    value: 'total',
                    text: t(
                      'cpoSessionsFinance.revenueBreakdownTotalRevenue',
                      'Total Revenue'
                    ),
                  },
                ]}
              />
            </Form>
          </Layout>
        </Segment>

        <p>
          {t(
            'cpoSessionsFinance.revenueBreakdownDescription',
            'Dates are determined by the end datetime of a charge session as reported by the charge station devices. Total Revenue DOES NOT include any roaming surcharges.'
          )}
        </p>

        {loading && data.length === 0 && (
          <Loader active centered inline="centered" />
        )}
        {error && <Message error content={error.message} />}
        {data && (
          <React.Fragment>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('cpoSessionsFinance.columnMonth', 'Month')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('cpoSessionsFinance.columnNumSessions', 'No Sessions')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('cpoSessionsFinance.columnTotalKwh', 'Total kWh')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      'cpoSessionsFinance.columnTotalRevenue',
                      'Total Revenue'
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('mspSessionsFinance.columnActions', 'Actions')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map((item) => {
                  const { year, month } = item._id;
                  return (
                    <Table.Row key={`${year}-${month}`}>
                      <Table.Cell>
                        {year} / {month}
                      </Table.Cell>
                      <Table.Cell textAlign="right">{item.total}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {Math.round(item.totalKwh)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatEuro(item.totalRevenue, 2, true)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Actions
                          month={item._id.month}
                          year={item._id.year}
                          providerContext="cpo"
                          filters={{ revenueType: this.state.revenueType }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                {this.state.hasMoreData && (
                  <Table.Row>
                    <Table.Cell colspan="5" textAlign="center">
                      <Button
                        loading={this.state.loading}
                        style={{
                          width: '300px',
                        }}
                        primary
                        onClick={() => {
                          this.loadMore();
                        }}
                        content={t('cpoSessionsFinance.loadMore', 'Load More')}
                        basic
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <div>
              <Button
                disabled={this.state.loading}
                onClick={() => {
                  downloadCsv(
                    data.map((item) => {
                      const { _id, ...rest } = item;
                      const { year, month } = _id;
                      return {
                        month: `${year} / ${month}`,
                        ...rest,
                      };
                    }),
                    null,
                    `cpo-sessions-revenue-breakdown-${format(
                      new Date(),
                      'yyyy-MM-dd'
                    )}.csv`
                  );
                }}
                icon="download"
                content="Export CSV"
                basic
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(SessionsRevenueBreakdown);
