import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    const _hsq = (window as any)._hsq || [];
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);
  }, [location]);

  return null;
}
