import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic';
import TypeButton from 'components/modals/RequestCard/v2/TypeButton';
import styles from './SelectCardHolderType.module.less';

type SelectCardHolderTypeProps = {
  selected: string;
  onSelect: (type: string) => void;
  isPrimaryUser?: boolean;
};

export function SelectCardHolderType(props: SelectCardHolderTypeProps) {
  const { t } = useTranslation();

  return (
    <Grid>
      <Grid.Row className={styles.row}>
        <Grid.Column className={styles.column}>
          <TypeButton
            icon="user-group"
            isSelected={props.selected === 'existing'}
            onClick={() => {
              props.onSelect('existing');
              localStorage.removeItem('dismissWelcomeModal');
            }}
            text={
              props.isPrimaryUser
                ? t('selectCardHolder.myself', 'Myself')
                : t('selectCardHolder.existingUser', 'Existing User')
            }
          />
        </Grid.Column>
        <Grid.Column className={styles.column}>
          <TypeButton
            icon="user-plus"
            isSelected={props.selected === 'new'}
            onClick={() => {
              props.onSelect('new');
              localStorage.setItem('dismissWelcomeModal', 'true');
            }}
            text={t('selectCardHolder.newUser', 'New User')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
