import React from 'react';
import Stats from './Stats';
import InvoicesBreakdown from './InvoicesBreakdown';
import BillingPlansBreakdown from './BillingPlansBreakdown';
import SessionsRevenueBreakdown from './SessionsRevenueBreakdown';
import { Divider } from 'semantic';

export default function Cpo({ cpoStatsApiRoute }) {
  return (
    <div>
      <Stats cpoStatsApiRoute={cpoStatsApiRoute} />
      <Divider hidden />
      <Divider hidden />
      <InvoicesBreakdown />
      <Divider hidden />
      <Divider hidden />
      <SessionsRevenueBreakdown />
      <Divider hidden />
      <Divider hidden />
      <BillingPlansBreakdown />
      <Divider hidden />
      <Divider hidden />
    </div>
  );
}
