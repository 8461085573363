import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import Panel from 'screens/Auth/SignupV2/Panel';
import { Divider, Form, Grid, Icon } from 'semantic';
import LoginPanel from 'screens/Auth/SignupV2/LoginPanel';
import { useTranslation } from 'react-i18next';

interface Props {
  isLoading: boolean;
  error?: boolean;
  errorMessage?: string;
  hideBelowContent?: boolean;
  headerText: string;
  subHeaderText: string;
  loginPanelText: string;
  content: ReactNode;
  onSubmit: () => void;
  postSignupRedirect: string;
  navigationDirection: 'none' | 'forward' | 'backward';
}

export function RedirectPanel(props: Props) {
  return (
    <Panel
      isLoading={props.isLoading}
      errorMessage={props.errorMessage}
      headerText={props.headerText}
      subHeaderText={props.subHeaderText}
      content={
        <Form>
          <Divider hidden />
          {props.content}

          {!props.error && (
            <>
              <Divider hidden />
              <Countdown onCountdownEnd={props.onSubmit} />
            </>
          )}
        </Form>
      }
      hideBelowContent={props.hideBelowContent}
      belowContent={
        <Grid.Column>
          <LoginPanel
            redirectTo={props.postSignupRedirect}
            textAlign="center"
            text={props.loginPanelText}
          />
        </Grid.Column>
      }
      hideBelowContentOnInit
      navigateDirection={props.navigationDirection}
    />
  );
}

interface CountdownProps {
  onCountdownEnd: () => void;
  seconds?: number;
}

function Countdown({ onCountdownEnd, seconds = 5 }: CountdownProps) {
  const { t } = useTranslation();
  const [countdownStyle, setCountdownStyle] = useState<CSSProperties>({
    display: 'flex',
    verticalAlign: 'middle',
  });

  const [countdownText, setCountdownText] = useState<string>(
    t(
      'signup.countdown.plural',
      '{{secondsRemaining}} seconds until login...',
      { secondsRemaining: Math.max(1, seconds) }
    )
  );

  // Implement countdown
  useEffect(() => {
    let secondsRemaining = Math.max(1, seconds);
    const interval = setInterval(() => {
      secondsRemaining -= 1;
      if (secondsRemaining !== 1) {
        setCountdownText(
          t(
            'signup.countdown.plural',
            '{{secondsRemaining}} seconds until login...',
            { secondsRemaining }
          )
        );
      }
      if (secondsRemaining === 1) {
        setCountdownText(
          t('signup.countdown.singular', '1 second until login...')
        );
      }
      if (secondsRemaining === 0) {
        onCountdownEnd();
        setCountdownStyle({ ...countdownStyle, opacity: 0 });
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={countdownStyle}>
      <Icon size="large" loading name="circle-notch" color="blue" />
      <div style={{ marginLeft: '0.5rem' }}>
        <strong>{countdownText}</strong>
      </div>
    </div>
  );
}
