import React, { Component } from 'react';
import ReactJson from './ReactJsonThemed';
import { Divider } from 'semantic';
import PropTypes from 'prop-types';
import { ThemeContext } from '../contexts/theme';

export default class ExpandableJson extends Component {
  static contextType = ThemeContext;

  static propTypes = {
    object: PropTypes.object.isRequired,
    name: PropTypes.string,
    collapsedLevel: PropTypes.number,
    height: PropTypes.number,
    scrollable: PropTypes.bool,
  };

  static defaultProps = {
    collapsedLevel: 2,
    dark: false,
    scrollable: false,
    height: 200,
  };
  state = { isExpandable: false, isExpanded: false };
  constructor(props) {
    super(props);
    this.expandableThreshold = props.height;
    this.scrollable = props.scrollable;
    this.jsonView = React.createRef();
  }

  toggleExpand = (e) => {
    e && e.preventDefault();
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  determineExpandable = () => {
    setTimeout(() => {
      const isExpandable =
        this.jsonView?.current?.clientHeight >= this.expandableThreshold;
      this.setState({ isExpandable });
      !isExpandable && this.setState({ isExpanded: false });
    }, 100);
  };

  componentDidMount() {
    this.determineExpandable();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Redetermine expandability after expanding/collapsing or when object is updated
    const { object, collapsedLevel } = this.props;
    if (
      prevProps.object !== object ||
      prevProps.collapsedLevel !== collapsedLevel
    ) {
      this.determineExpandable();
    }
  }

  render() {
    const { object, name, collapsedLevel } = this.props;
    const { isExpanded, isExpandable } = this.state;
    const { expandableThreshold, scrollable, jsonView } = this;

    return (
      <div>
        <div ref={jsonView} onClick={this.determineExpandable}>
          <ReactJson
            displayDataTypes={false}
            src={object}
            collapsed={collapsedLevel}
            name={name}
            style={Object.assign(
              isExpanded
                ? {}
                : {
                    maxHeight: `${expandableThreshold}px`,
                    overflow: scrollable ? 'auto' : 'hidden',
                  },
              { padding: '15px', wordBreak: 'break-word' }
            )}
          />
        </div>
        {isExpandable && (
          <Divider
            horizontal
            style={{
              textTransform: 'none',
              fontWeight: 'normal',
              letterSpacing: 'normal',
              marginBlock: '8px',
            }}>
            <a
              href="#"
              onClick={(e) => this.toggleExpand(e)}
              style={{ textAlign: 'center', width: '100%', display: 'block' }}>
              {`view ${isExpanded ? 'Less' : 'More'}`}
            </a>
          </Divider>
        )}
      </div>
    );
  }
}
