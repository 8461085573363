import React from 'react';
import { Button, Icon } from 'semantic';
import classNames from 'classnames';
import styles from 'components/modals/RequestCard/v2/typeButton.module.less';

type TypeButtonProps = {
  icon: string;
  text: string;
  isSelected: boolean;
  onClick?: () => void;
};

export default function TypeButton(props: TypeButtonProps) {
  return (
    <Button
      onClick={props.onClick}
      type="button"
      basic
      color={props.isSelected ? 'blue' : undefined}
      className={styles['type-button']}>
      <div className={styles['type-label']}>
        <Icon size="large" name={props.icon} /> <span>{props.text}</span>
      </div>
      <Icon
        size="large"
        className={classNames(styles['selection-icon'], {
          [styles['disabled']]: !props.isSelected,
        })}
        name={props.isSelected ? 'circle-check regular' : 'circle regular'}
      />
    </Button>
  );
}
