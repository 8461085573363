import { Label, SemanticCOLORS } from '../semantic';
import React from 'react';

type OperationalStatusProps = {
  status: string;
  color?: SemanticCOLORS;
};

export function OperationalStatus({
  status,
  color = 'grey',
}: OperationalStatusProps) {
  return (
    <>
      <Label title={status} content={status} color={color} />
    </>
  );
}
