import React, { useState } from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import SkeletonLoader from './SkeletonLoader';
import { useTranslation } from 'react-i18next';
import { useEnterpriseAccountMigration } from '../context';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import FeatureFlag from 'components/FeatureFlag';

export default function EnterpriseAccountMigrationInputs({ onDone }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(false);
  const {
    migrationType,
    setMigrationType,
    accountId,
    setAccountId,
    setAccountName,
    enterpriseAccountId,
    setEnterpriseAccountId,
    setEnterpriseAccountName,
  } = useEnterpriseAccountMigration();

  const handleSubmit = () => {
    if (!accountId || !enterpriseAccountId || !migrationType) {
      setFormError(true);
      return;
    }
    setFormError(false);
    onDone();
  };

  return (
    <>
      <Modal.Header>
        Account Migration - Step 1/2: Migration Inputs
      </Modal.Header>
      {loading && (
        <Modal.Content>
          <SkeletonLoader />
        </Modal.Content>
      )}
      {!loading && !error && (
        <>
          <Modal.Content>
            <Message
              warning
              icon="circle-exclamation"
              header={t('common.warning', 'Warning')}
              content="Migrating an existing account will migrate all the data associated
              with that account into a new provider."
            />
            <Message
              warning
              icon="circle-exclamation"
              header={t('common.warning', 'Warning')}
              content="Note that the original account will no longer exist in its current provider after migration and all data will be transferred to the selected destination provider!"
            />

            <Form id="EnterpriseAccountMigration-form" error={formError}>
              {formError && (
                <Message
                  error
                  content={t(
                    'enterpriseAccountMigration.inputs.validationError',
                    'Please fill all the required fields'
                  )}
                />
              )}

              <FeatureFlag feature="reseller_provider_migration">
                <Form.Field required>
                  <label>Select Migration Type</label>
                  <Form.Radio
                    style={{ marginLeft: '20px' }}
                    label="Enterprise Account"
                    name="migrationType"
                    value="enterpriseAccounts"
                    checked={migrationType === 'enterpriseAccounts'}
                    onChange={(e, { value }) => setMigrationType(value)}
                  />
                  <Form.Radio
                    style={{ marginLeft: '20px' }}
                    label="Reseller Provider"
                    name="migrationType"
                    value="resellerProviders"
                    checked={migrationType === 'resellerProviders'}
                    onChange={(e, { value }) => setMigrationType(value)}
                  />
                </Form.Field>
              </FeatureFlag>

              <Form.Field required>
                <label>Select Account</label>
                <SearchDropDown
                  clearable={false}
                  placeholder="Select/Search Account"
                  value={accountId}
                  objectMode={false}
                  onDataNeeded={(body) => {
                    return request({
                      path: '/1/accounts/search',
                      method: 'POST',
                      body: body,
                    });
                  }}
                  onChange={(e, { value, options }) => {
                    setAccountId(value);
                    setAccountName(
                      options.find((option) => option.value === value)?.text ||
                        ''
                    );
                  }}
                />
              </Form.Field>

              <Form.Field required>
                <label>
                  Select{' '}
                  {migrationType === 'enterpriseAccounts'
                    ? "Enterprise Account's Provider"
                    : 'Reseller Provider'}
                </label>
                <SearchDropDown
                  clearable={false}
                  placeholder="Select/Search Provider"
                  value={enterpriseAccountId}
                  objectMode={false}
                  onDataNeeded={(body) => {
                    return request({
                      path: '/1/providers/search',
                      method: 'POST',
                      body: body,
                    });
                  }}
                  onChange={(e, { value, options }) => {
                    setEnterpriseAccountId(value);
                    setEnterpriseAccountName(
                      options.find((option) => option.value === value)?.text ||
                        ''
                    );
                  }}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={loading}
              disabled={loading}
              primary
              content={t('common.next', 'Next')}
              form="EnterpriseAccountMigration-form"
              onClick={handleSubmit}
            />
          </Modal.Actions>
        </>
      )}
    </>
  );
}
