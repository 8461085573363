import React from 'react';
import { FieldValidator, useField } from 'formik';
import SearchDropdownField from 'components/form-fields/SearchDropdown';
import { Label } from 'semantic';

interface Props {
  name: string;
  label?: string;
  validate?: FieldValidator;
  onDataNeeded: (search: { name: string }) => Promise<{ data: string[] }>;
  clearable?: boolean;
  multiple?: boolean;
  objectMode?: boolean;
  required?: boolean;
  hideErrorLabel?: boolean;
  getOptionLabel?: (item: any) => string;
  description?: string;
  forceFetchOnFocus?: boolean;
  allowAdditions?: boolean;
  placeholder?: string;
}

const SearchDropdown: React.FC<Props> = ({
  name,
  label,
  validate,
  objectMode = false,
  multiple,
  onDataNeeded,
  clearable = true,
  required = false,
  hideErrorLabel = false,
  getOptionLabel,
  description,
  forceFetchOnFocus,
  allowAdditions = false,
  placeholder,
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  return (
    <>
      <SearchDropdownField
        clearable={clearable}
        value={field.value}
        objectMode={objectMode}
        onDataNeeded={onDataNeeded}
        placeholder={placeholder}
        label={label}
        allowAdditions={allowAdditions}
        multiple={multiple}
        description={description}
        required={required}
        onChange={(e: Event, { value }: { value: unknown }) => {
          helpers.setValue(value, true);
        }}
        getOptionLabel={getOptionLabel}
        forceFetchOnFocus={forceFetchOnFocus}
        error={
          meta.touched && meta.error
            ? hideErrorLabel
              ? undefined
              : meta.error
            : undefined
        }
      />

      {/* We had to add this label to show error message box below the dropdown list */}
      {meta.error && meta.touched && !hideErrorLabel && (
        <Label
          basic
          pointing={'above'}
          style={{
            color: '#9F3A38',
            marginTop: -15,
            border: '1px solid #E0B4B4',
          }}>
          {meta.error}
        </Label>
      )}
    </>
  );
};

export default SearchDropdown;
