import React, { useState } from 'react';
import { Accordion, Icon, Message, MessageContent } from 'semantic';
import { useTranslation } from 'react-i18next';

function RemoveMspDelayedBillingMessage() {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { t } = useTranslation();

  return (
    <Message info icon>
      <MessageContent>
        {t(
          'generateInvoiceDialog.removeMspDelayedBillingTitle',
          'This will include sessions outside of the "Year/Month" period'
        )}
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={() => setActiveIndex(activeIndex === 0 ? -1 : 0)}
            style={{ color: 'inherit', fontSize: '.8em' }}>
            <Icon name="dropdown" />
            {t(
              'generateInvoiceDialog.removeMspDelayedBillingInfo',
              'What does this mean?'
            )}
          </Accordion.Title>
          <Accordion.Content
            active={activeIndex === 0}
            style={{ fontSize: '.8em' }}>
            <p>
              {t(
                'generateInvoiceDialog.removeMspDelayedBilling12months',
                'Sessions from Dutch CPOs that are up to 12 months old'
              ) +
                '\n' +
                t(
                  'generateInvoiceDialog.removeMspDelayedBilling24months',
                  'Sessions from non-Dutch CPOs that are up to 24 months old'
                )}
            </p>
          </Accordion.Content>
        </Accordion>
      </MessageContent>
      <Icon name={'circle-info'} />
    </Message>
  );
}

export default RemoveMspDelayedBillingMessage;
