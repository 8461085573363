import React from 'react';
import { Segment, Item, Loader, Message, Divider } from 'semantic';
import EvseConnector from 'components/EvseConnector';
import { request } from 'utils/api';

export default class ConnectorsMsp extends React.Component {
  state = { loading: true };
  componentDidMount() {
    this.fetchActiveSessions();
  }

  async fetchActiveSessions() {
    try {
      const { data: activeSessions } = await request({
        method: 'POST',
        path: `/2/locations/msp/${this.props.location.id}/sessions/search`,
        body: {
          limit: 10,
          status: 'ACTIVE',
        },
      });
      this.setState({ activeSessions, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  getActiveSession(activeSessions = [], evseControllerId, connectorId) {
    return activeSessions.filter(
      (session) =>
        session.evseControllerId == evseControllerId &&
        session.connectorId == connectorId
    )[0];
  }

  render() {
    const { activeSessions, loading, error } = this.state;
    const { evses } = this.props.location;

    if (loading) {
      return <Loader />;
    }
    if (error) {
      return <Message error content={error.message} />;
    }

    return (
      <div>
        {evses.map((evse) => {
          return (
            <>
              <Segment key={evse.uid} basic>
                <Item.Group>
                  {evse.connectors.map((connector) => {
                    const activeSession = this.getActiveSession(
                      activeSessions,
                      evse.evseControllerId,
                      connector.id
                    );

                    return (
                      <EvseConnector
                        connectorStandard={connector.standard}
                        evseId={connector.evseId || evse.evse_id}
                        powerType={connector.power_type}
                        connectorFormat={connector.format}
                        activeSession={activeSession}
                        status={evse.status}
                      />
                    );
                  })}
                </Item.Group>
              </Segment>
              <Divider />
            </>
          );
        })}
      </div>
    );
  }
}
