import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

export default function InviteEmployee({
  trigger,
  onSuccess = () => {},
  onClose = () => {},
  initialValues = {},
} = {}) {
  const evseControllers = initialValues.evseControllers || [];

  const [pricePerKwh, setPricePerKwh] = React.useState(
    evseControllers.length ? evseControllers[0].costSettings[0].pricePerKwh : 0
  );

  const [open, setOpen] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [status, setStatus] = React.useState(false);

  const { t } = useTranslation();

  async function handleSubmit() {
    setSubmitted(true);
    setStatus({
      request: true,
    });

    try {
      await Promise.all(
        evseControllers.map((evseController) => {
          return request({
            method: 'POST',
            path: `/2/locations/cpo/${initialValues.id}/cost-settings`,
            body: {
              evseControllerId: evseController.id,
              costSettings: evseController.costSettings.map((costSettings) => {
                return {
                  ...costSettings,
                  pricePerKwh: pricePerKwh,
                  pricePerSession: 0,
                  pricePerHour: 0,
                };
              }),
            },
          });
        })
      );
      setStatus({
        sucess: true,
      });
      setOpen(false);
      onSuccess({});
      onClose();
    } catch (error) {
      setStatus({
        error,
      });
    }
  }

  return (
    <Modal
      size="small"
      closeIcon
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('editEvsePrice.title', 'Update price settings')}
      </Modal.Header>
      <Modal.Content>
        <Form
          error={submitted && Boolean(status.error)}
          onSubmit={() => handleSubmit()}>
          {status.error && <Message error content={status.error.message} />}

          <Form.Input
            label={t('editEvsePrice.pricePerKwh')}
            required
            type="number"
            value={pricePerKwh}
            onChange={(e, { value }) => {
              setPricePerKwh(value);
            }}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={status.request === true}
          disabled={status.request === true}
          primary
          content={t('button.update', 'Update')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}
