import { useMemo, useState } from 'react';
import { request } from '../../../../utils/api';

export function useLocationOptions(accountId: string) {
  const [locationOptions, setLocationOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useMemo(() => {
    const requestLocationData = async () => {
      const response = await request({
        path: '/2/locations/cpo/search',
        method: 'POST',
        body: {
          accountId,
        },
      });

      setLocationOptions(
        response?.data?.map((location: any) => ({
          value: location.id,
          label: location.name,
        }))
      );
    };

    if (!!accountId) {
      requestLocationData();
    }
  }, [accountId]);

  return locationOptions;
}
