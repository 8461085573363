import React from 'react';

import { Item, Header } from 'semantic';
import { Box } from 'components';

import appIcon from 'assets/public/app-logo.png';

import widgetstyles from '../widgetstyles.module.less';
import { useTranslation } from 'react-i18next';

export default function GetMobileApp() {
  const { t } = useTranslation();
  return (
    <Box title={t('dashboardHome.getMobileAppTitle', 'Get Mobile App')}>
      <Box.Content>
        <Item.Group unstackable className={widgetstyles.itemGroup}>
          <Item>
            <Item.Image>
              <img src={appIcon} style={{ width: '60px', height: '60px' }} />
            </Item.Image>
            <Item.Content verticalAlign="middle">
              <Header as="div">E-Flux App</Header>
              <div>
                <strong>
                  {t(
                    'dashboardHome.getMobileAppDescription',
                    'Find & Use EV Stations'
                  )}
                </strong>
              </div>
            </Item.Content>
          </Item>
        </Item.Group>
      </Box.Content>
      <Box.Actions>
        <Box.ExternalActionLink href="https://apps.apple.com/nl/app/e-flux-ev/id1488299113">
          App Store
        </Box.ExternalActionLink>
        <Box.ExternalActionLink href="https://play.google.com/store/apps/details?id=com.eflux.ev">
          Google Play
        </Box.ExternalActionLink>
      </Box.Actions>
    </Box>
  );
}
