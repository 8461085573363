import React from 'react';

import { Form, Message, Modal, Table, Header, Button } from 'semantic';

import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';

import { request } from 'utils/api';

class EditInvoice extends React.Component {
  state = {
    invoice: this.props.invoice || {},
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { invoice } = this.state;

    try {
      if (this.props.invoice?.id) {
        await request({
          method: 'POST',
          path: `/1/invoices/${invoice.id}`,
          body: invoice,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/invoices',
          body: {
            ...invoice,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      invoice: {
        ...this.state.invoice,
        [name]: value,
      },
    });
  }

  render() {
    const { t } = this.props;
    const { invoice = {}, error } = this.state;

    const isUpdate = !!this.props.invoice?.id;

    return (
      <>
        <Modal.Header>
          {isUpdate
            ? t('editInvoice.titleEdit', 'Edit Invoice {{name}}', {
                name: this.props.invoice.identifier,
              })
            : t('editInvoice.titleNew', 'New Invoice')}
        </Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)} onSubmit={() => this.onSubmit()}>
            {error && <Message error content={error.message} />}
            <Form.Input
              value={invoice.id}
              name="id"
              label={t('editInvoice.id', 'ID')}
              type="text"
              readOnly
            />
            <Form.Input
              value={invoice.year}
              name="year"
              label={t('editInvoice.year', 'Year')}
              type="text"
              readOnly
            />
            <Form.Input
              value={invoice.month}
              name="month"
              label={t('editInvoice.month', 'Month')}
              type="text"
              readOnly
            />
            <Form.Input
              value={invoice.identifier}
              name="identifier"
              label={t('editInvoice.identifier', 'Identifier')}
              type="text"
              readOnly
            />
            <Form.Input
              value={invoice.numericReferenceCode}
              name="numericReferenceCode"
              label={t(
                'editInvoice.numericReferenceCode',
                'Numeric Reference Code'
              )}
              type="text"
              readOnly
            />
            <Form.Checkbox
              label={t('editInvoice.isReady', 'Is Ready?')}
              name="isReady"
              checked={invoice.isReady || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
            <Form.Checkbox
              label={t('editInvoice.isPaid', 'Is Paid?')}
              name="isPaid"
              checked={invoice.isPaid || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
            <Form.Checkbox
              label={t('editInvoice.skipAccounting', 'Skip accounting system?')}
              name="skipAccountingSystem"
              checked={invoice.skipAccountingSystem || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
            <Form.Checkbox
              label={t('editInvoice.skipEmails', 'Skip emails?')}
              name="skipEmails"
              checked={invoice.skipEmails || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
            <Header as="h3">
              {t('editInvoice.exactReferences', 'Exact References')}
            </Header>
            {invoice.exactReferences && invoice.exactReferences.length ? (
              <>
                {invoice.exactReferences.map((reference) => {
                  return (
                    <Table key={reference.documentId} definition>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            {t('editInvoice.documentID', 'Document ID')}
                          </Table.Cell>
                          <Table.Cell>{reference.documentId}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            {t(
                              'editInvoice.documentAttachmentID',
                              'Document Attachment ID'
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {reference.documentAttachmentId}
                          </Table.Cell>
                        </Table.Row>
                        {reference.salesEntryResult && (
                          <>
                            <Table.Row>
                              <Table.Cell>
                                {t(
                                  'editInvoice.salesEntryID',
                                  'Sales Entry ID'
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.salesEntryResult.EntryID}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {t('editInvoice.journal', 'Journal')}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.salesEntryResult.Journal} (
                                {reference.salesEntryResult.JournalDescription})
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {t('editInvoice.amountFC', 'AmountFC')}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.salesEntryResult.AmountFC}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {t('editInvoice.vatAmountFC', 'VATAmountFC')}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.salesEntryResult.VATAmountFC}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        )}
                        {reference.purchaseEntryResult && (
                          <>
                            <Table.Row>
                              <Table.Cell>
                                {t(
                                  'editInvoice.purchaseEntryID',
                                  'Purchase Entry ID'
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.purchaseEntryResult.EntryID}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {t('editInvoice.journal', 'Journal')}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.purchaseEntryResult.Journal} (
                                {
                                  reference.purchaseEntryResult
                                    .JournalDescription
                                }
                                )
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {t('editInvoice.amountFC', 'AmountFC')}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.purchaseEntryResult.AmountFC}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>
                                {t('editInvoice.vatAmountFC', 'VATAmountFC')}
                              </Table.Cell>
                              <Table.Cell>
                                {reference.purchaseEntryResult.VATAmountFC}
                              </Table.Cell>
                            </Table.Row>
                          </>
                        )}
                      </Table.Body>
                    </Table>
                  );
                })}
              </>
            ) : (
              <Message
                content={t(
                  'editInvoice.noReferencesYet',
                  'No references to Exact exist yet'
                )}
              />
            )}
            {invoice.hasError && (
              <>
                <Header as="h3">{t('editInvoice.error', 'Error')}</Header>
                <Message negative content={invoice.error.message} />
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={this.state.loading}
            primary
            content={isUpdate ? t('button.update') : t('button.create')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditInvoice));
