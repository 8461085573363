import React from 'react';
import AccountVerified from 'screens/Auth/SignupV2/steps/Completed/AccountVerified';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';
import ChargeCardOrdered from 'screens/Auth/SignupV2/steps/Completed/ChargeCardOrdered';
import AccountCreated from 'screens/Auth/SignupV2/steps/Completed/AccountCreated';

export type Variation =
  | 'account-verified'
  | 'account-created'
  | 'charge-card-ordered';

interface Props {
  isLoading: boolean;
  error?: boolean;
  errorMessage?: string;
  hideBelowContent?: boolean;
  onSubmit: () => void;
  postSignupRedirect: string;
  loginPanelText: string;
  navigationDirection: 'none' | 'forward' | 'backward';
  variation: 'account-verified' | 'account-created' | 'charge-card-ordered';
}

export default function RedirectScreen(props: Props) {
  const provider = useProvider();
  const platformName = provider.platformName;

  return (
    <>
      {props.variation === 'account-verified' && (
        <AccountVerified {...props} platformName={platformName} />
      )}
      {props.variation === 'account-created' && (
        <AccountCreated {...props} platformName={platformName} />
      )}
      {props.variation === 'charge-card-ordered' && (
        <ChargeCardOrdered {...props} platformName={platformName} />
      )}
    </>
  );
}
