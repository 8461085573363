import i18next from 'i18next';

const getMonthNames = () => [
  i18next.t('months.january', 'January'),
  i18next.t('months.february', 'February'),
  i18next.t('months.march', 'March'),
  i18next.t('months.april', 'April'),
  i18next.t('months.may', 'May'),
  i18next.t('months.june', 'June'),
  i18next.t('months.July', 'July'),
  i18next.t('months.august', 'August'),
  i18next.t('months.september', 'September'),
  i18next.t('months.october', 'October'),
  i18next.t('months.november', 'November'),
  i18next.t('months.december', 'December'),
];

const getPreviousMonth = () => {
  const currentMonth = new Date().getMonth();
  return currentMonth === 0 ? 11 : currentMonth - 1;
};

const getPreviousMonthYear = () => {
  const now = new Date();
  const currentMonth = now.getMonth();
  return currentMonth === 0 ? now.getFullYear() - 1 : now.getFullYear();
};

export { getMonthNames, getPreviousMonth, getPreviousMonthYear };
