import React, { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { Form, Message, Modal, Button } from 'semantic';

import { omit } from 'lodash-es';
import DateField from '../form-fields/Date';

export default function EditExchangeRate({
  initialValues = { activeFrom: new Date() },
  trigger,
  formMeta = { currencies: [], countryCodeMap: {} },
  onClose,
  apiRoute = '1/exchange-rates/',
}) {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    const body = omit(formValues, [
      '_id',
      'createdAt',
      'updatedAt',
      '__v',
      'isActive',
    ]);

    const isUpdate = !!initialValues._id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate ? `${apiRoute}${initialValues._id}` : `${apiRoute}`;
    request({ method, path, body })
      .then(() => {
        setLoading(false);
        onClose?.();
        setFormValues(initialValues);
        setOpen(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const setField = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const isUpdate = !!initialValues._id;
  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        onClose?.();
        setOpen(false);
        setFormValues(initialValues);
        setSubmitted(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {isUpdate ? `Edit Exchange Rate` : 'New Exchange Rate'}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="exchange-rate-form"
          error={submitted && Boolean(error)}
          onSubmit={() => handleSubmit()}>
          {error && <Message error content={error.message} />}

          <Form.Dropdown
            required
            fluid
            selection
            search
            options={formMeta.currencies.map((code) => ({
              key: code,
              value: code,
              text: code,
            }))}
            value={formValues.sourceCurrency || ''}
            name="sourceCurrency"
            label="Source currency"
            onChange={(e, { name, value }) => {
              setField(name, value);
            }}
          />

          <Form.Dropdown
            required
            fluid
            selection
            search
            options={formMeta.currencies.map((code) => ({
              key: code,
              value: code,
              text: code,
            }))}
            value={formValues.targetCurrency || ''}
            name="targetCurrency"
            label="Target currency"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Input
            required
            fluid
            selection
            label="Rate"
            name="rate"
            type="number"
            step="0,01"
            value={formValues.rate || ''}
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <DateField
            required
            fluid
            selection
            value={formValues.activeFrom}
            label="Valid from"
            name="activeFrom"
            includeTime={false}
            onChange={(e, dateStr) => setField('activeFrom', dateStr.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          type="submit"
          loading={loading}
          primary
          content={isUpdate ? 'Update' : 'Create'}
          form="exchange-rate-form"
        />
      </Modal.Actions>
    </Modal>
  );
}
