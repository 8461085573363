import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Detail from './Detail';
import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function OcpiCredentials({
  ocpiCredentialsPath = '/credentials/ocpi',
}) {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path={ocpiCredentialsPath} component={List} exact />
          <Route path={`${ocpiCredentialsPath}/:id`} component={Detail} />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
