import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilters, Breadcrumbs, Search, Layout } from 'components';
import { Segment, Divider, Message } from 'semantic';
import { Link } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { userCanAccessProviderEndpoint } from '../../../utils/roles';
import Table from './Table';
import { request } from 'utils/api';
import { useFeatures } from '../../../contexts/features';

export type LabelItem = {
  label: string;
  usage: number;
};

export default function Integrations() {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const { hasFeature, isReady } = useFeatures();

  const labelFeatureEnabled = hasFeature('evsecontroller-labels');

  const writeAccess = userCanAccessProviderEndpoint(
    user!,
    provider,
    'evseControllers',
    'write'
  );

  if (!isReady || !labelFeatureEnabled || !writeAccess) {
    return (
      <Message warning>
        {t(
          'access.cannotAccess',
          'This page is not available for you. Please contact your administrator.'
        )}
      </Message>
    );
  }

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/1/evse-controllers/labels/search',
      body: {
        ...filters,
      },
    });
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
    };
  };

  const searchRef = useRef(null);

  return (
    <Search.Provider
      onDataNeeded={onDataNeeded}
      ref={searchRef}
      filterMapping={getFilterMapping()}>
      {() => (
        <>
          <Breadcrumbs
            path={[
              <Link to="/charging-stations">
                {t('chargingStations.header', 'Charging Stations')}
              </Link>,
            ]}
            active={t('manageLabels.title', 'Manage Labels')}
          />
          <h3>{t('manageLabels.title', 'Manage Labels')}</h3>
          <p>
            {t(
              'manageLabels.description',
              'Use label to group any objects within same type and easily filter them with the same labels later.'
            )}
          </p>

          <Divider hidden />
          <Segment>
            <Layout horizontal spread stackable>
              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t('manageLabels.search', 'Search label')}
                />
              </Layout>
            </Layout>
          </Segment>
          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table writeAccess={writeAccess} t={t} />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </>
      )}
    </Search.Provider>
  );
}
