import React from 'react';
import { request } from 'utils/api';
import { Loader, Table, Message, Label } from 'semantic';
import { fromNow } from 'utils/date';

function colorForDate(date) {
  const ts = Date.parse(date);
  const now = Date.now();
  const durationHours = (now - ts) / 1000 / 3600;
  if (durationHours < 1) {
    return 'olive';
  }
  if (durationHours > 5 && durationHours < 24) {
    return 'yellow';
  }
  return 'orange';
}

export default class EvseControllers extends React.Component {
  state = {
    error: null,
    loading: true,
    data: null,
  };
  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    request({
      method: 'GET',
      path: '/1/external-tokens/breakdown',
      params: {
        importSource: this.props.importSource,
      },
    })
      .then(({ data }) => {
        this.setState({ data, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    const { data, error, loading } = this.state;
    if (loading) return <Loader active />;
    if (error) return <Message error content={error.message} />;
    const { providers, lastUpdatedAt } = data;
    if (!lastUpdatedAt) {
      return <Message content="No tokens collected yet" />;
    }
    return (
      <div>
        <p>
          Most recent token update:&nbsp;
          <Label
            content={fromNow(lastUpdatedAt)}
            color={colorForDate(lastUpdatedAt)}
          />
        </p>
        <Table celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Party ID</Table.HeaderCell>
              <Table.HeaderCell>Total Tokens</Table.HeaderCell>
              {this.props.includeUpdatedAt && (
                <Table.HeaderCell>Most Recent Token</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {providers.map((provider) => {
              return (
                <Table.Row key={provider.id}>
                  <Table.Cell>{provider.id}</Table.Cell>
                  <Table.Cell textAlign="right">{provider.total}</Table.Cell>
                  {this.props.includeUpdatedAt && (
                    <Table.Cell>{fromNow(provider.updatedAt)}</Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
