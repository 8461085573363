import React from 'react';
import AccountSettingsMenuItem from '../atoms/SettingsMenuItem';
import {
  SettingsActiveItem,
  SettingsMenuItemProps,
} from 'types/account-settings';
import { Divider, Menu } from 'semantic';

interface AccountSettingsMenuProps extends SettingsActiveItem {
  header: 'Personal' | 'Account' | 'Organization';
  menuItems: SettingsMenuItemProps[];
}

export default function SettingsMenu({
  header,
  menuItems,
  activeItem,
}: AccountSettingsMenuProps) {
  return (
    <Menu vertical style={{ minWidth: '250px' }}>
      <Menu.Header
        as="h4"
        style={{
          marginLeft: 15,
          fontWeight: 'bold',
          marginBottom: 0,
          marginTop: 12,
        }}>
        {header}
      </Menu.Header>

      <Divider style={{ marginBottom: -1 }} />

      {menuItems.map(({ title, route, iconName }) => {
        return (
          <AccountSettingsMenuItem
            key={route}
            title={title}
            route={route}
            activeItem={activeItem}
            iconName={iconName}
          />
        );
      })}
    </Menu>
  );
}
