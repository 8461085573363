export const EVSE_ID_COUNTRY_CODES = [
  'AT',
  'BE',
  'CH',
  'DE',
  'DK',
  'ES',
  'FR',
  'GB',
  'IT',
  'LU',
  'NL',
  'PL',
  'PT',
  'SE',
];
