import React, { CSSProperties } from 'react';
import { useField, FieldValidator } from 'formik';
import { Form, SemanticWIDTHS } from 'semantic';

interface Props {
  name: string;
  label?: string;
  fluid?: boolean;
  required?: boolean;
  style?: CSSProperties;
  wrapperStyle?: CSSProperties;
  autoComplete?: string;
  placeholder?: string;
  validate?: FieldValidator;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  // set this to true if you do not want to show the error label below the input if the field is not valid. Note that
  // the field will still be styled as invalid
  hideErrorLabel?: boolean;
  // Set this to true if you want the valdiation occur immediately, instead of after touched;
  // i.e touched = input has been focused, or form submitted
  validateImmediately?: boolean;
  width?: SemanticWIDTHS;
  readOnly?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  rows?: number;
}

const TextArea: React.FC<Props> = ({
  name,
  label,
  required,
  placeholder,
  validate,
  validateImmediately,
  disabled,
  style,
  wrapperStyle,
  fluid,
  autoComplete,
  min,
  max,
  step,
  hideErrorLabel,
  width,
  readOnly = false,
  onFocus,
  rows,
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const hasTouched = validateImmediately ? true : meta.touched;

  return (
    // wrapperStyle is used to style the formik validation errors
    <div style={wrapperStyle}>
      <Form.TextArea
        width={width}
        value={field.value ?? ''}
        required={required}
        name={name}
        min={min}
        fluid={fluid}
        role="input"
        aria-label={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onBlur={() => helpers.setTouched(true)}
        label={label}
        style={style}
        onFocus={onFocus}
        error={
          hasTouched && meta.error
            ? hideErrorLabel
              ? true
              : meta.error
            : undefined
        }
        disabled={disabled}
        max={max}
        step={step}
        readOnly={readOnly}
        onChange={(e, { value }) => {
          helpers.setValue(value, true);
        }}
        rows={rows}
      />
    </div>
  );
};

export default TextArea;
