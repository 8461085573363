import React from 'react';
import { Statistic } from 'semantic';
import { numberWithCommas } from 'utils/formatting';
import { AggregateCardinality } from 'react-tectonic';

export default function CardinalityStatistic({
  collection,
  label,
  field = 'id',
  filter,
  timeRangeDateField = 'createdAt',
}) {
  return (
    <AggregateCardinality
      collection={collection}
      fields={[field]}
      timeRangeDateField={timeRangeDateField}
      filter={filter}>
      {({ status, data }) => (
        <Statistic>
          <Statistic.Value>
            {status.success ? numberWithCommas(data[field]) : '...'}
          </Statistic.Value>
          <Statistic.Label>{label}</Statistic.Label>
        </Statistic>
      )}
    </AggregateCardinality>
  );
}
