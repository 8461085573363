import React from 'react';
import './Segment.less';
import { omit } from 'lodash-es';
import { Segment, SegmentProps } from 'semantic-ui-react';

interface Props extends SegmentProps {
  offBackground?: boolean; // adds a subtle background color that is distinguishable from the main background
}

function ThemedSegment(props: Props) {
  function buildClasses() {
    let classesString = props.className || '';

    if (props.offBackground) {
      classesString += ' segment-off-background';
    }

    return classesString.trim();
  }

  return <Segment className={buildClasses()} {...omit(props, 'className')} />;
}

export default ThemedSegment;
