import React, { useContext, useEffect } from 'react';
import { request } from 'utils/api';

interface PlatformModule {
  key: string;
  name: string;
  description: string;
  features: {
    key: string;
    name: string;
    description: string;
  }[];
}

type PlatformModuleContextValue = {
  platformModules: PlatformModule[];
  hasPlatformModule: (providerModule: string) => boolean;
  load: () => void;
};

const PlatformModulesContext = React.createContext<PlatformModuleContextValue>({
  platformModules: [],
  hasPlatformModule: () => false,
  load: () => undefined,
});

interface Props {
  children: React.ReactNode;
}

export const PlatformModuleProvider: React.FC<Props> = ({ children }) => {
  const [platformModules, setPlatformModules] = React.useState<
    PlatformModule[]
  >([]);

  const hasPlatformModule = (platformModuleKey: string): boolean => {
    return !!platformModules.find((x) => x.key === platformModuleKey);
  };

  return (
    <PlatformModulesContext.Provider
      value={{
        platformModules,
        hasPlatformModule,
        load: () => {
          request({
            method: 'GET',
            path: '/1/platform-modules',
          })
            .then((response) => {
              setPlatformModules(response.data);
            })
            .catch((err) => {
              console.error(`Failed fetching provider modules ${err.message}`);
            });
        },
      }}>
      {children}
    </PlatformModulesContext.Provider>
  );
};

export const usePlatformModules = () => {
  return useContext(PlatformModulesContext);
};
