import React from 'react';
import { useFormikContext } from 'formik';
import { Form } from 'semantic';
import { useTranslation } from 'react-i18next';
import Divider from '../Sidebar/Divider';
import RadioButtonField from '../form-fields/formik/RadioButtonField';
import { get } from 'lodash';
import SearchDropdown from '../form-fields/formik/SearchDropdown';

const CONSTANTS = {
  True: 'true',
  False: 'false',
};

export default function EditEvseControllerRoamingSettings({
  objectPath,
}: {
  objectPath: string;
}) {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const enablePublicFreeCharging = get(
    values,
    `${objectPath}enablePublicFreeCharging`
  );
  const enablePublicCharging = get(values, `${objectPath}enablePublicCharging`);
  const disableRoamingChargeForProviders = get(
    values,
    `${objectPath}disableRoamingChargeForProviders`
  );

  return (
    <div>
      <Form.Group>
        <RadioButtonField
          style={{ marginTop: '10px' }}
          name={`${objectPath}enablePublicFreeCharging`}
          label={t(
            'editEvseControllers.chargingFee.free',
            'Allow any card to charge here for free (ignores all cost settings)'
          )}
          id="enablePublicFreeCharging"
          value={CONSTANTS.True}
        />
        <RadioButtonField
          style={{ marginTop: '10px' }}
          name={`${objectPath}enablePublicFreeCharging`}
          label={t(
            'editEvseControllers.chargingFee.tariffSettings',
            'Apply regular cost settings (default)'
          )}
          id="disablePublicFreeCharging"
          value={CONSTANTS.False}
        />
      </Form.Group>

      <Divider />

      <Form.Group
        {...(enablePublicFreeCharging !== CONSTANTS.False && {
          'data-tooltip': t(
            'editEvseControllers.roaming.tooltip.enablePublicCharging',
            'To configure roaming, please ensure first that the regular cost settings are applied above.'
          ),
        })}>
        <RadioButtonField
          disabled={enablePublicFreeCharging !== CONSTANTS.False}
          style={{ marginTop: '10px' }}
          name={`${objectPath}enablePublicCharging`}
          label={t(
            'editEvseControllers.roaming.allowed',
            'Allow charging from other networks (roaming)'
          )}
          id="enablePublicCharging"
          value={CONSTANTS.True}
        />
        <RadioButtonField
          disabled={enablePublicFreeCharging !== CONSTANTS.False}
          style={{ marginTop: '10px' }}
          name={`${objectPath}enablePublicCharging`}
          label={t(
            'editEvseControllers.roaming.disallowed',
            'Restrict charging from other networks (roaming)'
          )}
          id="disablePublicCharging"
          value={CONSTANTS.False}
        />
      </Form.Group>

      <Divider />

      <Form.Group
        {...(enablePublicCharging !== CONSTANTS.True && {
          'data-tooltip': t(
            'editEvseControllers.roaming.tooltip.disableRoamingChargeForProviders',
            'To configure roaming, please ensure first that the roaming is allowed above.'
          ),
        })}>
        <RadioButtonField
          disabled={enablePublicCharging !== CONSTANTS.True}
          style={{ marginTop: '10px' }}
          name={`${objectPath}disableRoamingChargeForProviders`}
          label={t(
            'editEvseControllers.roaming.enableSurcharge',
            'Enable roaming surcharge per kWh for all providers'
          )}
          id="disableRoamingChargeForProviders"
          value={CONSTANTS.False}
        />

        <RadioButtonField
          disabled={enablePublicCharging !== CONSTANTS.True}
          style={{ marginTop: '10px' }}
          name={`${objectPath}disableRoamingChargeForProviders`}
          label={t(
            'editEvseControllers.roaming.disableSurcharge',
            'Disable roaming surcharge per kWh for the specific party IDs'
          )}
          id="enableRoamingChargeForProviders"
          value={CONSTANTS.True}
        />
      </Form.Group>

      {enablePublicCharging === CONSTANTS.True &&
        disableRoamingChargeForProviders === CONSTANTS.True && (
          <Form.Group style={{ marginTop: '15px' }}>
            <SearchDropdown
              name={`${objectPath}disableRoamingChargePartyIds`}
              label={t(
                'editEvseControllers.disableSurchargePartyIds',
                'Party ID (e.g. NLEVB)'
              )}
              multiple
              allowAdditions={true}
              onDataNeeded={() =>
                new Promise((resolve) => {
                  resolve({
                    data: [],
                  });
                })
              }
              placeholder={t(
                'editEvseControllersModal.selectOption',
                'Select Option'
              )}
            />
          </Form.Group>
        )}
    </div>
  );
}
