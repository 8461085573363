import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #565c66;

  .nocturnal-theme & {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export default function SummaryLabel({ children }) {
  return <StyledLabel>{children}</StyledLabel>;
}
