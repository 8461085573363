import React, { useRef } from 'react';
import { currentUserCanAccess } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { Divider, Button } from 'semantic';
import { Breadcrumbs, Search, ListHeader, FeatureFlag } from 'components';
import { request } from 'utils/api';
import Table from './Table';
import EnterpriseAccountMigrationModal from 'components/modals/EnterpriseAccountMigration';

const onDataNeeded = async (filters, apiRoute) => {
  const res = await request({
    method: 'POST',
    path: `${apiRoute}/search`,
    body: {
      filters,
    },
  });

  return res;
};

export default function EnterpriseAccountMigrations({ apiRoute }) {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const writeAccess = currentUserCanAccess('providers', 'write');

  return (
    <FeatureFlag feature="enterprise-account-migrations" invert>
      <Search.Provider
        ref={searchRef}
        onDataNeeded={(filters) => onDataNeeded(filters, apiRoute)}>
        <>
          <Breadcrumbs active="Account Migrations" />
          <ListHeader title="Account Migrations Tooling">
            <EnterpriseAccountMigrationModal
              trigger={
                <Button
                  primary
                  icon="plus"
                  content={t(
                    'enterpriseAccountMigrations.requestMigration',
                    'Request Migration'
                  )}
                  disabled={!writeAccess}
                />
              }
              forceReset={true}
            />
          </ListHeader>

          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table />
          <Divider hidden />

          <Search.Pagination />
        </>
      </Search.Provider>
    </FeatureFlag>
  );
}
