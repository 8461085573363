import React from 'react';
import { request } from 'utils/api';
import Table from 'components/tables/Sessions';
import { Container, Header, Divider } from 'semantic';
import { withTranslation } from 'react-i18next';

import { Search, Layout } from 'components';

import { Context as SearchContext } from 'components/search';

import { safeFileName } from 'utils/formatting';

const itemLimit = 100;

class CpoSessions extends React.Component {
  static contextType = SearchContext;

  state = {
    dateKey: Date.now(),
  };

  onDataNeeded = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: `/2/sessions/cpo/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        accountId: this.props.account.id,
      },
    });
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Container>
        <Search.Provider
          sort={{
            field: 'endedAt',
            order: 'desc',
          }}
          ref={this.searchRef}
          onDataNeeded={this.onDataNeeded}
          limit={itemLimit}>
          <Header as="h2">
            <Layout horizontal spread>
              {t('accountCpoSessions.title', 'CPO Sessions')}
              <Search.ExportRange
                onRangeSubmit={async (from, to, filters) => {
                  const body = this.props.body || {};

                  await this.onDataNeeded({
                    format: 'csv',
                    limit: 100000,
                    endedAt: {
                      $gte: from,
                      $lte: to,
                    },
                    filename: safeFileName(
                      `cpo-sessions-${this.props.account.id}`
                    ),
                    ...filters,
                    ...body,
                  });
                }}
              />
            </Layout>
          </Header>
          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table includeResyncAction includeSyncStatus showInvoiceStatus />
          <Divider hidden />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </Search.Provider>
      </Container>
    );
  }
}

export default withTranslation()(CpoSessions);
