import React from 'react';
import { Divider, Grid, Segment, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import {
  AggregateTimeSeries,
  ErrorBoundary,
  SeriesChart,
} from 'react-tectonic';

import { changeFilterId, mergeFilters, getColors } from 'utils/visualizations';
import CardinalityStatistic from 'components/analytics/CardinalityStatistic';
import TermsDonutChart from 'components/analytics/TermsDonutChart';
import TermsTable from 'components/analytics/TermsTable';
import { useUser } from 'contexts/user';

export default function CpoLocations({ baseFilter = {} }) {
  const { provider } = useUser();
  const { t } = useTranslation();
  const locationFilter = mergeFilters(
    changeFilterId(baseFilter, 'locationId'),
    {
      terms: [{ deleted: false }],
    }
  );

  const colorHex = `#${provider.primaryColorHex}`;

  const colors = getColors(colorHex);

  return (
    <ErrorBoundary>
      <Divider hidden />
      <Divider hidden />

      <Statistic.Group widths="two" style={{ justifyContent: 'space-between' }}>
        <CardinalityStatistic
          collection="locations"
          label={t('common.locations', 'Locations')}
          filter={locationFilter}
        />
        <CardinalityStatistic
          collection="evsecontrollers"
          label={t('common.evses', 'EVSEs')}
          filter={baseFilter}
        />
      </Statistic.Group>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="locations"
        operation="count"
        dateField="createdAt"
        filter={locationFilter}>
        <SeriesChart
          title={t('analyticsSessions.newLocations', 'New Locations')}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueField="count"
          valueFieldLabel={t('analyticsSessions.newLocations', 'New Locations')}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <AggregateTimeSeries
        collection="evsecontrollers"
        operation="count"
        dateField="createdAt"
        filter={baseFilter}>
        <SeriesChart
          title={t('analyticsSessions.evsecontrollers', 'New EVSEs')}
          titleAlign="center"
          chartType="bar"
          height={350}
          valueField="count"
          valueFieldLabel={t('analyticsSessions.evsecontrollers', 'New EVSEs')}
          color={colorHex}
        />
      </AggregateTimeSeries>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                collection="locations"
                aggField="status"
                colors={colors}
                title="Location Status"
                filter={locationFilter}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                collection="locations"
                aggField="publishingMode"
                title={t(
                  'analyticsCpoLocations.locationPrivacy',
                  'Location Privacy'
                )}
                colors={colors}
                filter={locationFilter}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={16} mobile={16}>
            <Segment basic>
              <TermsTable
                height={156}
                collection="locations"
                aggField="publishingMode"
                title={t(
                  'analyticsCpoLocations.locationPrivacyAbsolute',
                  'Location Privacy Absolute'
                )}
                valueField="count"
                valueFieldName={t('common.amount', 'Amount')}
                filter={locationFilter}
                termsSize={2}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="locations"
                aggField="country"
                title={t('common.countries', 'Countries')}
                valueField="count"
                valueFieldName={t('common.locations', 'Locations')}
                labelFormatter={(key) => key.toUpperCase()}
                filter={locationFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="locations"
                aggField="city"
                title={t('common.cities', 'cities')}
                valueField="count"
                valueFieldName={t('common.locations', 'Locations')}
                filter={locationFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="locations"
                aggField="facilities"
                title={t('analyticsCpoLocations.facilities', 'Facilities')}
                valueField="count"
                valueFieldName={t('common.locations', 'Locations')}
                filter={locationFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsTable
                collection="locations"
                aggField="parking_type"
                title={t(
                  'analyticsCpoLocations.parkingTypes"',
                  'Parking Types'
                )}
                valueField="count"
                valueFieldName={t('common.locations', 'Locations')}
                filter={locationFilter}
                termsSize={15}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </ErrorBoundary>
  );
}
