import AppWrapper from 'components/AppWrapper';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Message } from 'semantic';
import { PlatformModule } from '../../components';
import PageCenter from '../../components/PageCenter';

import TokenBatches from './Batches';
import TokenBatchesIds from './Batches/BatchTokenIds';

export default function TokenBatchesScreen({
  tokenBatchesPath = '/cards/token-batches',
  tokenBatchesIdPath = '/cards/token-batches/:id',
}) {
  return (
    <AppWrapper>
      <Container>
        <PlatformModule moduleName="token-batches">
          <Switch>
            <Route path={tokenBatchesPath} component={TokenBatches} exact />
            <Route
              path={tokenBatchesIdPath}
              component={TokenBatchesIds}
              exact
            />
          </Switch>
        </PlatformModule>
        <PlatformModule moduleName="token-batches" invert>
          <PageCenter>
            <>
              <Message
                error
                header="Module Disabled"
                content="Whoops, looks like this module is disabled for the provider."
              />
            </>
          </PageCenter>
        </PlatformModule>
      </Container>
    </AppWrapper>
  );
}
