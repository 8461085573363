import { API_URL } from 'utils/env';

export * from './token';
export * from './errors';
export { default as request } from './request';
export { joiErrorDetailsToObject as joiErrorDetailsToObject } from './request';

export function getFilePath(path) {
  return `${API_URL.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;
}
