import React from 'react';
import SearchContext from '../old_search/Context';
import { Loader, Message, Segment, Dimmer } from 'semantic';
import { useTranslation } from 'react-i18next';

export default function SearchStatus({ noItems }) {
  const { loading, error, items } = React.useContext(SearchContext);
  const { t } = useTranslation();

  if (loading) {
    return (
      <Segment style={{ height: '100px' }}>
        <Loader active>Loading</Loader>
      </Segment>
    );
  }

  if (error) {
    return (
      <Segment>
        <Message error content={error.message} />
      </Segment>
    );
  }

  if (items.length === 0) {
    return (
      <Segment>
        <Message>
          {noItems || t('common.noItemsFound', 'No results found')}
        </Message>
      </Segment>
    );
  }

  return null;
}
