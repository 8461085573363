import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';
import TokenOverview from './TokenOverview';

export default function Tokens({ match }) {
  const { t } = useTranslation();
  const itemId = match.params.id;

  const [item, setItem] = React.useState();
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/tokens/${itemId}`,
      });
      setItem(data);
    } catch (e) {}
  }

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link key="cards" to="/cards">
              {t('providerBreadcrumbs.cardsList', 'Cards')}
            </Link>,
            <Link key="tokens" to="/cards/tokens">
              {t('providerBreadcrumbs.tokensList', 'Tokens')}
            </Link>,
          ]}
          active={item ? item.name : t('loading.loading')}
        />
        <Switch>
          <Route
            exact
            path="/cards/tokens/:id"
            item={item}
            component={(props) => <TokenOverview {...props} token={item} />}
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
