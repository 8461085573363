import React from 'react';
import { SimpleTariffCostSettings } from 'components/modals/EditTariffProfile/SimpleTariffCostSettings';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { TariffProfile } from 'types/tariffprofile';
import { AdvancedTariffCostSettings } from 'components/modals/EditTariffProfile/AdvancedTariffCostSettings';

type Props = {
  sameForAcDc: boolean;
  formValues: TariffProfile;
  setField: (field: string, value: any) => void;
  setActiveTariffSystem: (e: { ac: string; dc: string }) => void;
  hideHeader?: boolean;
};

export function TariffCostSettings({
  sameForAcDc,
  formValues,
  setField,
  setActiveTariffSystem,
  hideHeader,
}: Props) {
  const advancedTariffsFeatures = useAdvancedTariffFeatures();

  if (advancedTariffsFeatures.advancedTariffs) {
    return (
      <AdvancedTariffCostSettings
        sameForAcDc={sameForAcDc}
        formValues={formValues}
        setField={setField}
        setActiveTariffSystem={setActiveTariffSystem}
        hideHeader={hideHeader}
      />
    );
  }

  return (
    <SimpleTariffCostSettings
      sameForAcDc={sameForAcDc}
      formValues={formValues}
      setField={setField}
    />
  );
}
