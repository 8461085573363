import React from 'react';
import { Form, Popup } from 'semantic';
import { useTranslation } from 'react-i18next';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { EvseControllerModalTabViewProps } from 'components/modals/EditEvseController/types';

export default function SelectTariffProfile(
  props: EvseControllerModalTabViewProps
) {
  const { t } = useTranslation();
  const { formValues } = props;

  const tariffProfileDisabled =
    !props.currency ||
    !formValues.enablePublicCharging ||
    formValues.enablePublicFreeCharging;

  const tariffProfilesAccess = currentUserCanAccessProviderEndpoint(
    'tariffProfiles',
    'read',
    null
  );

  if (!tariffProfilesAccess) {
    return <></>;
  }
  return (
    <Popup
      disabled={!tariffProfileDisabled}
      content={t(
        'editEvseControllers.tariffProfileDisabled',
        'Tariff profile can only be selected once the the Location has been added.'
      )}
      position="bottom left"
      trigger={
        <Form.Field>
          <Form.Select
            clearable
            disabled={tariffProfileDisabled}
            value={formValues.tariffProfileId}
            options={props.tariffProfiles.map((i) => {
              return { value: i.id, text: i.name };
            })}
            name="tariffProfileId"
            label={t('editEvseControllers.tariffProfile', 'Tariff Profile')}
            type="text"
            onChange={(e, { name, value }) => {
              props.setField(name, value);
            }}
          />
        </Form.Field>
      }
    />
  );
}
