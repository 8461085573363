import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal, Button, Message } from 'semantic';
import { Formik } from 'formik';
import SelectLanguage from 'components/form-fields/formik/SelectLanguage';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import InputField from 'components/form-fields/formik/InputField';
import { request } from 'utils/api';
import * as yup from 'yup';

interface Props {
  evseControllerId: string;
  nextStep: () => void;
  totalSteps: number;
}

interface InviteCustomerPayload {
  customerEmail: string;
  customerFirstName: string;
  languageCode: string;
  evseControllerId: string;
}

export default function InviteCustomerStep(props: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  // @ts-ignore
  const providerName = window?.provider.name || 'Road';

  async function save(values: any, nextStep: () => void) {
    try {
      setLoading(true);
      const payload: InviteCustomerPayload = {
        customerEmail: values.customerEmail,
        customerFirstName: values.customerFirstName,
        languageCode: values.languageCode,
        evseControllerId: props.evseControllerId,
      };
      await request({
        method: 'POST',
        path: `/1/invites/user/setup/charging-station`,
        body: payload,
      });
      nextStep();
    } catch (error) {
      setError(error as Error);
    }
    setLoading(false);
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{}}
      onSubmit={(values) => save(values, props.nextStep)}
      enableReinitialize>
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <>
            <Modal.Header>
              {`${t('setupEvse.inviteCustomer', 'Invite customer')} (3/${
                props.totalSteps
              })`}
            </Modal.Header>
            <Modal.Content>
              <p>
                {t(
                  'setupEvse.inviteCustomerDescription',
                  `To invite your customer to start registering the charge station you’ve just configured, fill in their e-mail address so they will receive an invite to create a {{providerName}} account.`,
                  { providerName }
                )}
              </p>
              <Form
                onSubmit={handleSubmit}
                loading={isSubmitting}
                data-testid="invite-customer-for-station"
                id="invite-customer-for-station">
                <InputField
                  name="customerEmail"
                  label={t('setupEvse.customerEmail', 'Customer email')}
                  type="email"
                />

                <FormVerticalSpace />

                <InputField
                  name="customerFirstName"
                  label={t(
                    'setupEvse.customerFirstName',
                    'Customer first name'
                  )}
                  type="text"
                />

                <FormVerticalSpace />

                <SelectLanguage
                  name="languageCode"
                  label={t(
                    'setupEvse.languageCode',
                    'Language for the customer'
                  )}
                  data-testid="langCode"
                />

                {error && (
                  <Message error>
                    <p>
                      {t(
                        'setupEvse.inviteCustomerError',
                        `Something went wrong while sending the invitation. Please try again.`
                      )}
                    </p>
                  </Message>
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button basic onClick={props.nextStep} disabled={loading}>
                {t('setupEvse.skipCustomerInviteButton', 'Skip invite')}
              </Button>
              <Button
                type="submit"
                loading={loading}
                disabled={loading}
                style={{ marginLeft: 0 }}
                form="invite-customer-for-station">
                {t('setupEvse.sendCustomerInviteButton', 'Send email')}
              </Button>
            </Modal.Actions>
          </>
        );
      }}
    </Formik>
  );
}

const validationSchema = yup.object({
  customerEmail: yup.string().email().required(),
  customerFirstName: yup.string().required(),
  languageCode: yup.string().required(),
});
