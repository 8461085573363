import React from 'react';
import { Modal } from 'semantic';
import AsyncModal from 'helpers/async-modal';
import {
  AdvancedSessionTimeline,
  SessionTimelineTable,
} from 'components/Session/TimelineTable';

type ModalContentProps = {
  data: AdvancedSessionTimeline;
};

export function SessionTimelineModalContent(props: ModalContentProps) {
  return (
    <>
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <span>Session Timeline</span>
      </Modal.Header>
      <Modal.Content>
        <SessionTimelineTable timeline={props.data} />
      </Modal.Content>
      <Modal.Actions />
    </>
  );
}

export const SessionTimelineModal = AsyncModal(SessionTimelineModalContent);
