import React from 'react';
import { Link } from 'react-router-dom';

import EditAccountTier from 'components/modals/EditAccountTier';

import { currentUserCanAccess } from 'utils/roles';

import { Divider, Button } from 'semantic';

import { Breadcrumbs, Search, ListHeader } from 'components';

import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import Table from './Table';

class AccountTiers extends React.Component {
  onDataNeeded = async () => {
    return request({
      method: 'GET',
      path: '/1/account-tiers',
    });
  };

  render() {
    const { t } = this.props;
    const writeAccess = currentUserCanAccess('account-tiers', 'write');

    return (
      <Search.Provider ref={this.searchRef} onDataNeeded={this.onDataNeeded}>
        {({ reload }) => {
          return (
            <>
              <Breadcrumbs
                path={[
                  <Link key="finance" to="/finance">
                    {t('finance.title', 'Finance')}
                  </Link>,
                ]}
                active={t('accountTiers.title', 'Account Tiers')}
              />
              <ListHeader title={t('accountTiers.title', 'Account Tiers')}>
                <EditAccountTier
                  onSave={reload}
                  trigger={
                    <Button
                      primary
                      floated="right"
                      style={{ marginTop: '-5px' }}
                      content={t('accountTiers.newTier', 'New Account Tier')}
                      icon="plus"
                      disabled={!writeAccess}
                    />
                  }
                />
              </ListHeader>

              <Search.Status
                noItems={t(
                  'accountTiers.noTiersYet',
                  'No Account Tiers created yet'
                )}
              />
              <Table />
              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}></div>
            </>
          );
        }}
      </Search.Provider>
    );
  }
}

export default withTranslation()(AccountTiers);
