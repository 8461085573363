import ProviderForm from 'components/forms/ProviderForm/ProviderForm';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic';
import React from 'react';
import modal from 'helpers/modal';

interface Props {
  onSave: () => void;
  onSubmit: () => Promise<void>;
}

const CreateProviderModal: React.FC<Props> = ({ onSave, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal.Header>{t('editProvider.titleNew', 'New Provider')}</Modal.Header>
      <Modal.Content>
        <ProviderForm onSubmit={onSubmit} onSave={onSave} />
      </Modal.Content>
    </>
  );
};

export default modal(CreateProviderModal);
