import React from 'react';
import PropTypes from 'prop-types';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  atomDark,
  prism,
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useTheme } from '../contexts/theme';

function CodeBlock({ value, language = null, height }) {
  const { renderedTheme } = useTheme();

  return (
    <div
      style={{
        ...(height ? { maxHeight: `${height}px`, overflow: 'auto' } : {}),
      }}>
      <SyntaxHighlighter
        language={language}
        style={renderedTheme === 'dark' ? atomDark : prism}>
        {value}
      </SyntaxHighlighter>
    </div>
  );
}

CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string,
  height: PropTypes.number,
};

export default CodeBlock;
