import React from 'react';
import { Table } from 'semantic';
import { formatNumber } from '../utils/formatting';

export default ({ stats, ocpiCredential }) => {
  return (
    <Table definition celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Expected Statistic</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>CDRs</Table.Cell>
          <Table.Cell textAlign="right">
            <strong>{formatNumber(stats.cdrCount)}</strong>
          </Table.Cell>
          <Table.Cell>
            {ocpiCredential.providerContext === 'cpo' ? (
              <>
                This is the total number of CDRs created by E-Flux charge
                stations using {ocpiCredential.name} MSP Tokens.
              </>
            ) : (
              <>
                This is the total number of CDRs received by E-FLux from{' '}
                {ocpiCredential.name}
              </>
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Sessions</Table.Cell>
          <Table.Cell textAlign="right">
            <strong>{formatNumber(stats.activeSessionCount)}</strong>
          </Table.Cell>
          <Table.Cell>
            {ocpiCredential.providerContext === 'cpo' ? (
              <>
                This is the total number of currently active sessions created by
                E-Flux charge stations using {ocpiCredential.name} MSP Tokens.
              </>
            ) : (
              <>
                This is the currently active sessions known at E-Flux created by{' '}
                {ocpiCredential.name}
              </>
            )}
          </Table.Cell>
        </Table.Row>
        {ocpiCredential.providerContext === 'msp' && (
          <Table.Row>
            <Table.Cell>Tokens</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{formatNumber(stats.tokenCount)}</strong>
            </Table.Cell>
            <Table.Cell>
              This is the total number of E-Flux tokens in our system.
            </Table.Cell>
          </Table.Row>
        )}
        {ocpiCredential.providerContext === 'cpo' && (
          <Table.Row>
            <Table.Cell>Tokens</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{formatNumber(stats.externalTokenCount)}</strong>
            </Table.Cell>
            <Table.Cell>
              This is the total number of {ocpiCredential.name} tokens in our
              system.
            </Table.Cell>
          </Table.Row>
        )}

        {ocpiCredential.providerContext === 'cpo' && (
          <Table.Row>
            <Table.Cell>Locations</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{formatNumber(stats.locationCount)}</strong>
            </Table.Cell>
            <Table.Cell>
              This is the total number of published (public) E-Flux CPO
              locations in our system.
            </Table.Cell>
          </Table.Row>
        )}
        {ocpiCredential.providerContext === 'cpo' && (
          <Table.Row>
            <Table.Cell>Tariffs</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{formatNumber(stats.tariffs)}</strong>
            </Table.Cell>
            <Table.Cell>
              This is the total number of public (materialized) tariffs in our
              system.
            </Table.Cell>
          </Table.Row>
        )}
        {ocpiCredential.providerContext === 'msp' && (
          <Table.Row>
            <Table.Cell>Locations</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{formatNumber(stats.externalLocationsCount)}</strong>
            </Table.Cell>
            <Table.Cell>
              This is the total number of received locations from{' '}
              {ocpiCredential.name}
            </Table.Cell>
          </Table.Row>
        )}
        {ocpiCredential.providerContext === 'msp' && (
          <Table.Row>
            <Table.Cell>Tariffs</Table.Cell>
            <Table.Cell textAlign="right">
              <strong>{formatNumber(stats.externalTariffsCount)}</strong>
            </Table.Cell>
            <Table.Cell>
              This is the total number of received tariffs from{' '}
              {ocpiCredential.name}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
