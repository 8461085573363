import legacyMenuRoutes from './LegacyMenuRoutes';

// TODO: Add system endpoints when available
export default {
  ...legacyMenuRoutes,
  menuRoutesContext: {
    routesContext: 'system',
  },
  providerRoutes: {
    ...legacyMenuRoutes.providerRoutes,
    uiRoute: '/system/providers',
  },
  financeRoamingRoutes: {
    ...legacyMenuRoutes.financeRoamingRoutes,
    prefixUiRoute: '/system/finance',
  },

  financeExchangeRatesRoutes: {
    prefixUiRoute: '/system/finance',
    apiRoute: '/1/system/exchange-rates',
  },
  financeRoamingLimitsRoutes: {
    uiRoute: '/roaming-limits',
    prefixUiRoute: '/system/finance',
    apiRoute: '/1/system/roaming-limits',
  },
  financeRoamingOverridesRoutes: {
    ...legacyMenuRoutes.financeRoamingOverridesRoutes,
    prefixUiRoute: '/system/finance',
    apiRoute: '/1/system/roaming-overrides',
  },
  migrationRoutes: {
    ...legacyMenuRoutes.migrationRoutes,
    prefixUiRoute: '/system/migrations',
  },
};
