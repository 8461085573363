import React from 'react';
import { Segment, SegmentProps } from 'semantic';

export function StyledSegment(props: SegmentProps) {
  const { children, ...rest } = props;
  return (
    <Segment {...rest} style={{ paddingBottom: '2rem' }}>
      {children}
    </Segment>
  );
}
