import React, { useCallback, useEffect } from 'react';

import { Segment, Form, Header, Menu, Button, Divider } from 'semantic';
import { useTranslation } from 'react-i18next';
import { get, merge, set } from 'lodash-es';
import CountriesField from 'components/form-fields/Countries';
import VATInput from 'components/form-fields/VAT';
import PostalCode from 'components/form-fields/PostalCode';

import { useHistory } from 'react-router-dom';
import { DeliveryAddress, useSignup } from '../Context';
import { Account } from 'types/account';
import { useUser } from 'contexts/user';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import { trackCustomBehavioralEvent } from 'utils/hubspot';

const defaultFormValues: Account = {
  contact: {},
  name: '',
  vatNo: null,
  type: 'individual',
  billingCurrency: 'EUR',
  billing: {
    countryCode: 'nl',
  },
};

interface Props {
  withPlan?: boolean;
  withoutPlan?: boolean;
  directPayment?: boolean;
  fieldService?: boolean;
  journey?: string;
}

const SignupBilling: React.FC<Props> = ({
  withPlan,
  withoutPlan,
  directPayment,
  fieldService,
  journey,
}) => {
  const { t } = useTranslation();

  // @ts-ignore
  const { user } = useUser();
  const {
    setStep,
    currency,
    setAccount,
    setCurrency,
    delivery,
    setDelivery,
    billingPlan,
    account,
  } = useSignup();

  const history = useHistory();
  const [submitted, setSubmitted] = React.useState(false);
  const [sameAsBilling, setSameAsBilling] = React.useState(true);
  const [vatDisabled, setVatDisabled] = React.useState<boolean>(false);
  const [deliveryFields, setDeliveryFields] = React.useState(delivery);
  const [formValues, setFormValues] = React.useState<Account>(
    account
      ? account
      : {
          ...defaultFormValues,
          type: fieldService || withoutPlan ? 'organization' : 'individual',
          billing: {},
          name: '',
          contact: {},
        }
  );

  const { productInstrumentation } = useProductInstrumentation();
  const sendProductInstrumentation = useCallback(
    (stage: 'started' | 'failed' | 'completed' | 'errored') => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'billing-details',
        stage,
        variant: '1.0.0',
      });
    },
    []
  );

  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'billing-details',
      variant: '1.0.0',
    });
  }, []);

  useEffect(() => {
    sendProductInstrumentation('started');
  }, [sendProductInstrumentation]);

  useEffect(() => {
    setStep('billing');
  }, []);

  useEffect(() => {
    if (withoutPlan) {
      if (!user || !user.email) {
        history.replace('/cpo/signup');
      }
    } else if (directPayment) {
      if (!user || !user.email) {
        history.replace('/direct-payment/signup');
      }
    } else if (fieldService) {
      if (!user || !user.email) {
        history.replace('/field-service/signup');
      }
    } else {
      if (!user || !user.email || !billingPlan?.id) {
        history.replace('/signup');
      }
    }
  }, [user]);

  useEffect(() => {
    if (user && user.accountId) {
      history.replace('/');
    }
  }, [user]);

  useEffect(() => {
    // @ts-ignore
    const dataLayer = window.dataLayer || [];
    if (dataLayer) {
      dataLayer.push({
        event: 'sign_up_account_started',
      });
    }

    trackCustomBehavioralEvent(`${journey}_sign_up_account_started`, {});

    // @ts-ignore
    const fbq = window.fbq;
    if (fbq) fbq('track', 'sign_up_account_started');
  }, []);

  function setField<T>(name: string, value: T) {
    setSubmitted(false);
    setFormValues(merge({}, formValues, set({}, name, value)));
  }

  function setDeliveryField<T>(name: string, value: T) {
    setSubmitted(false);
    setDeliveryFields(merge({}, deliveryFields, set({}, name, value)));
  }

  function getDelivery(): DeliveryAddress | undefined {
    if (sameAsBilling) {
      return {
        firstName: user.contact.firstName,
        lastName: user.contact.lastName,
        address: formValues?.billing?.address,
        addressLine2: formValues?.billing?.addressLine2,
        city: formValues?.billing?.city,
        countryCode: formValues?.billing?.countryCode,
        postalCode: formValues?.billing?.postalCode,
      };
    } else {
      return deliveryFields;
    }
  }

  async function handleOnSubmit() {
    setSubmitted(true);

    const { type } = formValues;

    if (type === 'individual') {
      const { firstName, lastName } = user.contact;
      formValues.name = `${firstName} ${lastName}`;
      if (formValues.billing) {
        formValues.billing.email = user.email;
      }
    }

    if (vatDisabled) {
      formValues.vatNo = null;
    }

    const body = {
      ...formValues,
      billingCurrency: currency,
      contact: {
        ...user.contact,
        email: user.email,
      },
    };

    setAccount(body);
    setDelivery(getDelivery());

    const dataLayer = window.dataLayer || [];

    if (dataLayer) {
      dataLayer.push({
        event: 'billing_completed',
        accountBillingCountry: formValues?.billing?.countryCode?.toUpperCase(),
      });
    }

    trackCustomBehavioralEvent(`${journey}_billing_completed`, {});

    let url = '/signup/finalize';
    if (withoutPlan) {
      url = '/cpo/signup/finalize';
    } else if (directPayment) {
      url = '/direct-payment/signup/finalize';
    } else if (fieldService) {
      url = '/field-service/signup/finalize';
    }
    history.replace(url);
    sendProductInstrumentation('completed');
    sendClick('confirm');
  }

  // @ts-ignore
  return (
    <Form error={submitted} onSubmit={() => handleOnSubmit()}>
      <Segment>
        <Header as="h2">{t('signup.headerBilling', 'Billing Detail')}</Header>
        {!fieldService && (
          <>
            <Menu tabular>
              <Menu.Item
                content={t('formLabel.accountTypeIndividual', 'Individual')}
                active={formValues.type === 'individual'}
                onClick={() =>
                  setFormValues({
                    ...defaultFormValues,
                    type: 'individual',
                  })
                }
              />
              <Menu.Item
                content={t('formLabel.accountTypeOrganization', 'Organization')}
                active={formValues.type === 'organization'}
                onClick={() =>
                  setFormValues({
                    ...defaultFormValues,
                    type: 'organization',
                  })
                }
              />
            </Menu>
          </>
        )}

        <CountriesField
          required
          label={t('formLabel.country', 'Country')}
          name="country"
          value={get(formValues, 'billing.countryCode', '')}
          onChange={(value: string) => setField('billing.countryCode', value)}
        />

        {formValues.type !== 'individual' && (
          <>
            <Form.Input
              required
              value={get(formValues, 'name', '')}
              name="name"
              label={t('formLabel.buisnessName', 'Business Name')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
              fluid
            />

            <Form.Group widths="equal">
              <VATInput
                countryCode={formValues?.billing?.countryCode}
                required={!vatDisabled}
                disabled={vatDisabled}
                value={get(formValues, 'vatNo', '')}
                name="vatNo"
                onChange={(
                  e: React.ChangeEvent,
                  {
                    name,
                    value,
                  }: {
                    name: string;
                    value: string;
                  }
                ) => setField(name, value)}
                fluid
              />
              <Form.Input
                required
                value={get(formValues, 'chamberOfCommerceNo', '')}
                name="chamberOfCommerceNo"
                label={t('formLabel.kvkNumber', 'Chamber of Commerce Number')}
                type="text"
                onChange={(e, { name, value }) => setField(name, value)}
                fluid
              />
            </Form.Group>

            {!fieldService && (
              <Form.Checkbox
                onChange={(e, { checked }) => setVatDisabled(!!checked)}
                label={t(
                  'signup.vatNotApplicable',
                  'VAT Number not applicable'
                )}
              />
            )}
          </>
        )}

        <Divider horizontal style={{ margin: '2em 0' }}>
          {t('signup.headerBilling', 'Billing Address')}
        </Divider>

        <Form.Group widths="3">
          <Form.Input
            width={12}
            required
            value={get(formValues, 'billing.address', '')}
            name="billing.address"
            label={t('formLabel.address', 'Address')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Form.Input
            required
            value={get(formValues, 'billing.addressLine2', '')}
            name="billing.addressLine2"
            label={t('formLabel.addressLine2', 'No.')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <PostalCode
            required
            countryCode={get(formValues, 'billing.countryCode', '')}
            value={get(formValues, 'billing.postalCode', '')}
            name="billing.postalCode"
            label={t('formLabel.postCode', 'Postal Code')}
            type="text"
            onChange={(
              e: React.ChangeEvent,
              {
                name,
                value,
              }: {
                name: string;
                value: string;
              }
            ) => setField(name, value)}
            fluid
          />
          <Form.Input
            required
            value={get(formValues, 'billing.city', '')}
            name="billing.city"
            label={t('formLabel.city', 'City/Town')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
            fluid
          />
        </Form.Group>

        {formValues.type !== 'individual' && (
          <Form.Input
            required
            value={get(formValues, 'billing.email', '')}
            name="billing.email"
            label={t('formLabel.billingEmail', 'Billing Email')}
            type="email"
            onChange={(e, { name, value }) => setField(name, value)}
          />
        )}

        {withPlan && (
          <Form.Field>
            <label>
              {t('signupBilling.deliveryAddress', 'Card Delivery Address')}
            </label>
            <Form.Checkbox
              label={
                <label>{t('formLabel.sameAsBilling', 'Same as billing')}</label>
              }
              checked={sameAsBilling}
              onChange={(e, { checked }) => setSameAsBilling(!!checked)}
            />
          </Form.Field>
        )}

        {!sameAsBilling && (
          <>
            <Form.Group widths="equal">
              <Form.Input
                required
                label={t('formLabel.firstName', 'First Name')}
                value={get(deliveryFields, 'firstName', '')}
                name="firstName"
                type="text"
                onChange={(e, { name, value }) => setDeliveryField(name, value)}
              />
              <Form.Input
                required
                label={t('formLabel.lastName', 'Last Name')}
                value={get(deliveryFields, 'lastName', '')}
                name="lastName"
                type="text"
                onChange={(e, { name, value }) => setDeliveryField(name, value)}
              />
            </Form.Group>

            <Form.Group widths="3">
              <Form.Input
                width="12"
                required
                value={get(deliveryFields, 'address', '')}
                name="address"
                label={t('formLabel.address', 'Address')}
                type="text"
                onChange={(e, { name, value }) => setDeliveryField(name, value)}
              />
              <Form.Input
                required
                value={get(deliveryFields, 'addressLine2', '')}
                name="addressLine2"
                label={t('formLabel.addressLine2', 'No.')}
                type="text"
                onChange={(e, { name, value }) => setDeliveryField(name, value)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                required
                value={get(deliveryFields, 'postalCode', '')}
                name="postalCode"
                label={t('formLabel.postCode', 'Postal Code')}
                type="text"
                onChange={(e, { name, value }) => setDeliveryField(name, value)}
              />
              <Form.Input
                required
                value={get(deliveryFields, 'city', '')}
                name="city"
                label={t('formLabel.city', 'City/Town')}
                type="text"
                onChange={(e, { name, value }) => setDeliveryField(name, value)}
              />
            </Form.Group>

            <CountriesField
              required
              label={t('formLabel.country', 'Country')}
              name="country"
              value={get(deliveryFields, 'countryCode', 'nl')}
              onChange={(value: string) =>
                setDeliveryField('countryCode', value)
              }
            />
          </>
        )}
      </Segment>
      <div>
        <Button
          as="button"
          primary
          size="large"
          disabled={!formValues?.billing?.countryCode}
          fluid
          content={t('signup.buttonBillingConfirm', 'Confirm')}
        />
      </div>
    </Form>
  );
};

export default SignupBilling;
