import React from 'react';
import { usePlatformModules } from 'contexts/platformModules';

interface Props {
  children: React.ReactNode;
  moduleName: string;
  invert?: boolean;
}

const PlatformModule: React.FC<Props> = ({ children, moduleName, invert }) => {
  const { hasPlatformModule } = usePlatformModules();
  return (hasPlatformModule(moduleName) && !invert) ||
    (!hasPlatformModule(moduleName) && invert) ? (
    <>{children}</>
  ) : null;
};

export default PlatformModule;
