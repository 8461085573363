import React from 'react';
import { Link, NavLink, Route, Switch } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Container, Menu } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import Requests from './Requests';
import EvseIdPrintBatches from './Batches';
export default class EvseIdsIndex extends React.Component {
  render() {
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="charging-stations" to="/charging-stations">
                Charging Stations
              </Link>,
            ]}
            active="EVSE IDs"
          />

          <Menu pointing secondary stackable>
            <Menu.Item
              name="Assignment Requests"
              to={`/charging-stations/evse-ids/requests`}
              as={NavLink}
            />
            <Menu.Item
              name="Generated Print Batches"
              to={`/charging-stations/evse-ids/batches`}
              as={NavLink}
            />
          </Menu>

          <Switch>
            <Route
              path="/charging-stations/evse-ids/requests"
              component={Requests}
            />
            <Route
              path="/charging-stations/evse-ids/batches"
              component={EvseIdPrintBatches}
            />
          </Switch>
        </Container>
      </AppWrapper>
    );
  }
}
