import React from 'react';

import { Modal, Form, Message, Button } from 'semantic';
import { request } from 'utils/api';

import SearchDropdown from 'components/form-fields/SearchDropdown';

import modal from 'helpers/modal';

class CloneAccountToProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account || {},
    };
  }

  onSubmit = async () => {
    const { account } = this.props;
    this.setState({ error: null, loading: true });

    try {
      await request({
        method: 'POST',
        path: `/1/accounts/${account.id}/clone`,
        body: this.state.account,
      });

      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      account: {
        ...this.state.account,
        [name]: value,
      },
    });
  }

  render() {
    const { loading, account, error } = this.state;
    return (
      <>
        <Modal.Header>Clone Account to Provider</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <p>
            This operation creates a duplicate account and all of its users into
            one of the providers.
          </p>
          <Form
            id="CloneAccountToProvider-form"
            error={Boolean(error)}
            onSubmit={this.onSubmit}>
            <Form.Field>
              <label>Destination Provider</label>
              <SearchDropdown
                value={account.providerId}
                objectMode={false}
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/providers/search',
                    method: 'POST',
                    body,
                  });
                }}
                onChange={(e, { value }) => this.setField('providerId', value)}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={'Clone'}
            form="CloneAccountToProvider-form"
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(CloneAccountToProvider);
