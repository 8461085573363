import React from 'react';
import { Card, CardContent, Placeholder } from 'semantic';

export default function CurrentPlanLoader() {
  const headerLines = Array.from({ length: 2 });
  const paragraphLines = Array.from({ length: 4 });

  return (
    <Card style={{ minWidth: '480px' }}>
      <CardContent>
        <Placeholder>
          <Placeholder.Header>
            {headerLines.map((_, index) => (
              <Placeholder.Line key={index} />
            ))}
          </Placeholder.Header>
          <Placeholder.Paragraph>
            {paragraphLines.map((_, index) => (
              <Placeholder.Line key={index} />
            ))}
          </Placeholder.Paragraph>{' '}
        </Placeholder>
      </CardContent>
    </Card>
  );
}
