import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Dropdown, Button } from 'semantic';
import HelpTip from 'components/HelpTip';
import { Search } from 'components';
import Actions from '../Actions';
import { formatDateTime } from 'utils/date';
import { currentUserCanAccess } from 'utils/roles';
import FetchObject from 'components/FetchObject';

export default function ProvidersTable() {
  const { t } = useTranslation();
  const { items, reload, loading } = useContext(Search.Context);
  if (!items.length || loading) return null;
  const writeAccess = currentUserCanAccess('providers', 'write');
  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>
              {t('providers.columnName', 'Name')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('providers.columnHumanID', 'Human ID')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('providers.columnParentProvider', 'Parent Provider')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('providers.columnCreated', 'Created')}
              <HelpTip
                title={t('providers.columnCreated', 'Created')}
                text={t(
                  'providers.columnCreatedToolTip',
                  'This is the date and time the credential was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('providers.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  {item.name}
                  <p>
                    <small>{item.id}</small>
                  </p>
                </Table.Cell>
                <Table.Cell>{item.slug}</Table.Cell>
                <Table.Cell>
                  {item.parentProviderId ? (
                    <FetchObject
                      endpoint="providers"
                      id={item.parentProviderId}>
                      {(parentProvider) => <span>{parentProvider.name}</span>}
                    </FetchObject>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    basic
                    as="a"
                    href={`/providers/${item.id}`}
                    icon="pen-to-square"
                    title="Edit"
                    disabled={!writeAccess}
                  />
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions item={item} onReload={reload} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
