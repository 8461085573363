import React, { useState } from 'react';

import { Form, Message, Modal, Label, Button, Divider } from 'semantic';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash-es';

import modal from 'helpers/modal';
import { request } from '../../utils/api';
import SearchDropdown from '../SearchDropdown';
import Confirm from '../Confirm';
import useMenuRoutes from 'hooks/useMenuRoutes';

const ConfirmDifferentOverride = ({ t, button, onConfirm }) => {
  return (
    <Confirm
      header={t(
        'roamingCpoCostOverride.confirmHeader',
        'Use different values for AC/DC?'
      )}
      content={t(
        'roamingCpoCostOverride.confirmBody',
        'Are you sure you want to use different override values for AC and DC sessions?'
      )}
      trigger={button}
      onConfirm={async () => {
        await onConfirm();
      }}
    />
  );
};

const OverridesInput = ({
  formValues = { pricePerKwh: 0, pricePerSession: 0, pricePerHour: 0 },
  t,
  onChange,
}) => {
  return (
    <>
      <Form.Input
        type="number"
        name="pricePerKwh"
        label={t('roamingCpoCostOverride.pricePerKwh', 'Price per kWh')}
        onChange={(e, { name, value }) =>
          onChange({ ...formValues, [name]: value })
        }
        value={formValues.pricePerKwh}
      />
      <Form.Input
        type="number"
        name="pricePerSession"
        label={t('roamingCpoCostOverride.pricePerSession', 'Price per session')}
        onChange={(e, { name, value }) =>
          onChange({ ...formValues, [name]: value })
        }
        value={formValues.pricePerSession}
      />
      <Form.Input
        type="number"
        name="pricePerHour"
        label={t('roamingCpoCostOverride.pricePerHour', 'Price per hour')}
        onChange={(e, { name, value }) =>
          onChange({ ...formValues, [name]: value })
        }
        value={formValues.pricePerHour}
      />
    </>
  );
};

function EditRoamingOverride({
  member = {},
  override,
  close,
  excludedProviders = [],
  routesContext,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState(
    override
      ? {
          ...override,
          infraProviderId: override._id.infraProviderId,
        }
      : {}
  );

  const { financeRoamingOverridesRoutes } = useMenuRoutes(routesContext);
  const { apiRoute, apiExternalProvidersRoute } = financeRoamingOverridesRoutes;

  const onSubmit = async () => {
    const { infraProviderId, powerTypeAC, powerTypeDC } = formValues;
    setLoading(true);
    await request({
      path: apiRoute,
      method: 'POST',
      body: {
        infraProviderId,
        powerTypeAC,
        powerTypeDC,
      },
    });
    setLoading(false);
    close();
  };

  const setField = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const sameValuesForACDC = () => {
    return isEqual(formValues?.powerTypeAC, formValues?.powerTypeDC);
  };

  const isUpdate = !!override;
  return (
    <>
      <Modal.Header>
        {isUpdate
          ? t('roamingCpoCostOverride.edit', 'Update CPO Cost Override')
          : t('roamingCpoCostOverride.new', 'New CPO Cost Override')}
      </Modal.Header>
      <Modal.Content>
        <Form
          id="EditRoamingOverride-form"
          error={Boolean(error)}
          onSubmit={() => onSubmit()}>
          {error && <Message error content={error.message} />}

          {override ? (
            <span>
              <Label content={override.infraProvider[0].name} />
            </span>
          ) : (
            <Form.Field>
              <label>Infra provider</label>
              <SearchDropdown
                value={formValues?.infraProviderId}
                clearable
                objectMode={false}
                style={{ width: '100%' }}
                name="infraProviderId"
                keywordField="customId"
                label="Infrastructure Provider"
                getOptionLabel={(option) =>
                  `${option.name} - (${option.customId})`
                }
                getOptionValue={(option) => option.customId}
                onChange={(e, { name, value }) => setField(name, value)}
                onDataNeeded={async (body) => {
                  const result = await request({
                    path: apiExternalProvidersRoute,
                    method: 'POST',
                    body: {
                      limit: 500,
                      ...body,
                    },
                  });
                  return {
                    ...result,
                    data: result.data.filter(
                      (provider) =>
                        excludedProviders.indexOf(provider.customId) === -1
                    ),
                  };
                }}
              />
            </Form.Field>
          )}
          <Divider />
          <label>
            {t('roamingCpoCostOverride.acHeader', 'AC power override')}
          </label>
          <Divider />
          <OverridesInput
            t={t}
            formValues={formValues.powerTypeAC}
            onChange={(value) => setField('powerTypeAC', value)}
          />
          <Divider />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ flex: 1 }}>
              {t('roamingCpoCostOverride.dcHeader', 'DC power override')}
            </label>
            <Button
              type="button"
              basic
              onClick={(e) => setField('powerTypeDC', formValues.powerTypeAC)}
              icon="copy">
              Copy AC setting
            </Button>
          </div>
          <Divider />
          <OverridesInput
            t={t}
            formValues={formValues.powerTypeDC}
            onChange={(value) => setField('powerTypeDC', value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {override ? (
          sameValuesForACDC() ? (
            <Button
              primary
              content={t('roamingCpoCostOverride.save', 'Save')}
              icon="save"
              form="EditRoamingOverride-form"
            />
          ) : (
            <ConfirmDifferentOverride
              t={t}
              onConfirm={() => onSubmit()}
              loading={loading}
              button={
                <Button
                  type="button"
                  loading={loading}
                  primary
                  content={t('roamingCpoCostOverride.save', 'Save')}
                  icon="save"
                  form="EditRoamingOverride-form"
                />
              }
            />
          )
        ) : sameValuesForACDC() ? (
          <Button
            loading={loading}
            primary
            content={t('roamingCpoCostOverride.create', 'Create')}
            form="EditRoamingOverride-form"
          />
        ) : (
          <ConfirmDifferentOverride
            t={t}
            onConfirm={() => onSubmit()}
            loading={loading}
            button={
              <Button
                type="button"
                loading={loading}
                primary
                content={t('roamingCpoCostOverride.save', 'Save')}
                icon="save"
                form="EditRoamingOverride-form"
              />
            }
          />
        )}
      </Modal.Actions>
    </>
  );
}
export default modal(EditRoamingOverride);
