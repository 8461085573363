import isoCountries from 'i18n-iso-countries';

export function labelPrintUrlForCards(cards) {
  const cardLines = cards.map((card) => {
    return labelPrintUrlForCard(card, true);
  });

  return `${document.location.protocol}//${
    document.location.host
  }/cards/print-label?data=${encodeURIComponent(JSON.stringify(cardLines))}`;
}

export function getDeliveryCountryCode(card) {
  if (card.delivery?.countryCode) {
    return card.delivery.countryCode.toUpperCase();
  }
  if (card.account?.billing?.countryCode) {
    return card.account.billing.countryCode.toUpperCase();
  }
  if (card.user?.address?.countryCode) {
    return card.user.address.countryCode.toUpperCase();
  }
  return '';
}

function getCardCountry(card) {
  const countryCode = getDeliveryCountryCode(card);
  if (!countryCode) {
    return '';
  }
  const country = isoCountries.getName(countryCode, 'en') || '';
  if (!country) {
    return '';
  }
  return country.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

export function sortCardsByCountry(cards) {
  const cardCountByCountry = {};
  for (const card of cards) {
    const country = getCardCountry(card);
    cardCountByCountry[country] ||= 0;
    cardCountByCountry[country] += 1;
  }

  return [...cards].sort((a, b) => {
    const aCountry = getCardCountry(a);
    const bCountry = getCardCountry(b);
    if (aCountry === bCountry) {
      return 0;
    }
    if (!aCountry) {
      return 1;
    }
    if (!bCountry) {
      return -1;
    }
    if (cardCountByCountry[aCountry] > cardCountByCountry[bCountry]) {
      return -1;
    }
    return 1;
  });
}

export function labelPrintUrlForCard(card, json) {
  let { delivery } = card;
  if (!delivery && card.account && card.account.billing) {
    delivery = card.account.billing;
  }
  if (!delivery && card.user && card.user.address) {
    delivery = card.user.address;
  }
  if (!delivery) {
    delivery = {};
  }
  let recipient = '';
  if (delivery.lastName) {
    recipient = `${delivery.firstName} ${delivery.lastName}`;
  } else if (card.requestUserLastName) {
    recipient = `${card.requestUserFirstName} ${card.requestUserLastName}`;
  } else if (card.nameOnCard) {
    recipient = `${card.nameOnCard}`;
  } else if (card.account) {
    recipient = card.account.name;
  }

  let address =
    `${[delivery.address, delivery.addressLine2, delivery.addressFull]
      .filter(Boolean)
      .join(' ')}` || '-';

  const postalCity =
    `${delivery.postalCode || ''} ${delivery.city || ''}`.trim() || '-';
  const country = getCardCountry(card) || '-';

  const lines = [];

  if (card.account && card.account.type === 'organization') {
    lines.push(card.account.name);
    lines.push(recipient);
    lines.push(address);
    lines.push(postalCity);
    lines.push(country);
  } else {
    lines.push(recipient);
    lines.push(address);
    lines.push(postalCity);
    lines.push(country);
  }

  if (json) {
    return lines;
  }

  return `${document.location.protocol}//${
    document.location.host
  }/cards/print-label?data=${encodeURIComponent(JSON.stringify([lines]))}`;
}
