import { startCase } from 'lodash-es';
import i18next from 'i18next';

function titleize(str) {
  return str
    .toLowerCase()
    .replace('_', ' ')
    .split(' ')
    .map((word) => startCase(word))
    .join(' ');
}

export const labelPrintUrlForLocation = (location) => {
  const address = location.address;

  let postalCityCountry = `${location.postal_code} ${location.city}`;
  if (location.country && location.country !== 'NLD') {
    postalCityCountry += ` ${location.country.toUpperCase()}`;
  }

  const lines = [];

  lines.push(location?.user?.name || location?.account?.name);
  lines.push(address);
  lines.push(postalCityCountry);

  return `${document.location.protocol}//${
    document.location.host
  }/cards/print-label?data=${encodeURIComponent(JSON.stringify([lines]))}`;
};

export const colorForStatus = (status) => {
  if (status === 'CHARGING') return 'blue';
  if (status === 'AVAILABLE') return 'olive';
  if (status === 'INOPERATIVE') return 'red';
  if (status === 'UNAVAILABLE') return 'red';
  if (status === 'FAULTED') return 'red';
  if (status === 'SUSPENDEDEV') return 'yellow';
  if (status === 'UNKNOWN') return 'grey';
  return undefined;
};

export const colorForMinorStatus = (status, minorStatus) => {
  if (minorStatus === 'CHARGING') return 'blue';
  if (minorStatus === 'AVAILABLE') return 'olive';
  if (minorStatus === 'INOPERATIVE') return 'red';
  return undefined;
};

export const labelForStatus = (status) => {
  if (status === 'CHARGING') return 'Charging';
  if (status === 'AVAILABLE') return 'Available';
  if (status === 'INOPERATIVE') return 'Inoperative';
  if (status === 'UNAVAILABLE') return 'Not Available';
  if (status === 'SUSPENDEDEV') return 'Suspended EV';
  if (status === 'FAULTED') return 'Faulted';
  if (status === 'UNKNOWN') return 'Unknown';
  return status;
};

export const formatConnectorType = (value) => {
  if (value === 'IEC_62196_T1') return 'Type 1';
  if (value === 'IEC_62196_T1_COMBO') return 'Type 1 Combo';
  if (value === 'IEC_62196_T2') return 'Type 2';
  if (value === 'IEC_62196_T2_COMBO') return 'Type 2 Combo';
  if (value === 'IEC_62196_T3A') return 'Type 3A';
  if (value === 'IEC_62196_T3C') return 'Type 3C';
  if (value === 'CHADEMO') return 'CHAdeMO';
  if (value === 'DOMESTIC_F') return 'Domestic F';
  if (value === 'DOMESTIC_E') return 'Domestic E';
  if (value === 'DOMESTIC_B') return 'Domestic B';
  if (value === 'CATARC') return 'Catarc';
  return value;
};

export const connectorTypes = [
  'IEC_62196_T1',
  'IEC_62196_T1_COMBO',
  'IEC_62196_T2',
  'IEC_62196_T2_COMBO',
  'IEC_62196_T3A',
  'IEC_62196_T3C',
  'CHADEMO',
  'DOMESTIC_F',
  'DOMESTIC_E',
  'DOMESTIC_B',
  'CATARC',
];

export const connectorTypeOptions = connectorTypes.map((key) => {
  return {
    key,
    value: key,
    text: formatConnectorType(key),
  };
});

export const connectorPowerTypes = {
  AC_3_PHASE: 'AC x 3',
  AC_1_PHASE: 'AC x 1',
  DC: 'DC',
};

export const connectorPowerTypeOptions = Object.keys(connectorPowerTypes).map(
  (key) => {
    return {
      key,
      value: key,
      text: connectorPowerTypes[key],
    };
  }
);

export const connectorFormats = {
  SOCKET: 'Socket',
  CABLE: 'Fixed Cable',
};

export const connectorFormatOptions = Object.keys(connectorFormats).map(
  (key) => {
    return {
      key,
      value: key,
      text: connectorFormats[key],
    };
  }
);

export const formatKwh = (value) => {
  return Math.round(value * 100).toLocaleString('nl');
};

export function formatConnectorPowerType(powerType) {
  return connectorPowerTypes[powerType] || '';
}

export function formatConnectorFormat(format) {
  return connectorFormats[format] || '';
}

/**
 *
 * @param currency
 * @returns {string}
 */
export const formatCurrencySymbol = (currency) => {
  if (currency === 'EUR') {
    return '€';
  }
  return currency;
};

export const formatEnergyAmount = (component, taxRate) => {
  const { price } = component;
  const unit = 'kWh';
  return `${Math.ceil(price * taxRate * 100) / 100} per ${unit}`;
};

export const formatTariff = (tariff, countryCode) => {
  const { currency, elements } = tariff;
  if (!currency) return '';
  if (!elements) return '';
  const relevantPriceComponent = elements.find(
    (element) =>
      element.price_components[0] && element.price_components[0].price >= 0
  );
  if (!relevantPriceComponent) return '';
  const { price_components } = relevantPriceComponent;
  if (price_components[0].type !== 'ENERGY') return '';
  const taxRate = 1.09;
  return `${formatCurrencySymbol(currency)}${formatEnergyAmount(
    price_components[0],
    taxRate
  )} (incl VAT)`;
  // Todo use countryCode to determine more exact VAT
  /*return `${formatCurrencySymbol(currency)}${formatEnergyAmount(
    price_components[0]
  )} (excl VAT)`;*/
};

export const formatVoltage = (connector) => {
  let currentSpecifier = '';
  if (connector.power_type) {
    currentSpecifier = `${connector.power_type} `;
  }
  return `${currentSpecifier}${connector.voltage}V`;
};

export const formatLocationFacilities = (location) => {
  const tags = [];
  if (location.charging_when_closed) {
    tags.push(`After-hours Charging`);
  }
  const { opening_times } = location;
  if (opening_times && opening_times.twentyfourseven) {
    tags.push(`24/7`);
  }
  const { capabilities } = location;
  if (capabilities && capabilities.includes('RESERVABLE')) {
    tags.push('Reservable');
  }
  if (capabilities && capabilities.includes('RFID_READER')) {
    tags.push('RFID');
  }
  if (capabilities && capabilities.includes('CREDIT_CARD_PAYABLE')) {
    tags.push('Credit Card');
  }
  const { energy_mix } = location;
  if (energy_mix && energy_mix.is_green_energy) {
    tags.push('Green Energy');
  }
  const { parking_type } = location;
  if (parking_type) {
    tags.push(titleize(parking_type));
  }
  const { type } = location;
  if (type && type !== parking_type) {
    tags.push(titleize(type));
  }
  const { facilities } = location;
  if (facilities) {
    facilities.forEach((facility) => {
      tags.push(titleize(facility));
    });
  }
  return tags;
};

export const getParkingTypes = () => ({
  ALONG_MOTORWAY: {
    en: {
      name: i18next.t('parkingTypes.ALONG_MOTORWAY.name', 'Along motorway'),
      description: i18next.t(
        'parkingTypes.ALONG_MOTORWAY.description',
        'Location on a parking facility/rest area along a motorway, freeway, interstate, highway etc'
      ),
    },
    nl: {
      name: i18next.t('parkingTypes.ALONG_MOTORWAY.name', 'Naast snelweg'),
      description: i18next.t(
        'parkingTypes.ALONG_MOTORWAY.description',
        'Locatie en parkeerfaciliteit langs een snelweg'
      ),
    },
    fr: {
      name: i18next.t('parkingTypes.ALONG_MOTORWAY.name', 'Along motorway'),
      description: i18next.t(
        'parkingTypes.ALONG_MOTORWAY.description',
        'Location on a parking facility/rest area along a motorway, freeway, interstate, highway etc'
      ),
    },
    de: {
      name: i18next.t('parkingTypes.ALONG_MOTORWAY.name', 'Along motorway'),
      description: i18next.t(
        'parkingTypes.ALONG_MOTORWAY.description',
        'Location on a parking facility/rest area along a motorway, freeway, interstate, highway etc'
      ),
    },
  },
  PARKING_GARAGE: {
    en: {
      name: i18next.t('parkingTypes.PARKING_GARAGE.name', 'Parking Garage'),
      description: i18next.t(
        'parkingTypes.PARKING_GARAGE.description',
        'Multistory car park.'
      ),
    },
    nl: {
      name: i18next.t('parkingTypes.PARKING_GARAGE.name', 'Parkeergarage'),
      description: i18next.t(
        'parkingTypes.PARKING_GARAGE.description',
        'Parkeergarage met verdiepingen.'
      ),
    },
    fr: {
      name: i18next.t('parkingTypes.PARKING_GARAGE.name', 'Parking Garage'),
      description: i18next.t(
        'parkingTypes.PARKING_GARAGE.description',
        'Multistory car park.'
      ),
    },
    de: {
      name: i18next.t('parkingTypes.PARKING_GARAGE.name', 'Parking Garage'),
      description: i18next.t(
        'parkingTypes.PARKING_GARAGE.description',
        'Multistory car park.'
      ),
    },
  },
  PARKING_LOT: {
    en: {
      name: i18next.t('parkingTypes.PARKING_LOT.name', 'Parking Lot'),
      description: i18next.t(
        'parkingTypes.PARKING_LOT.description',
        'A cleared area that is intended for parking vehicles, i.e. at super markets, bars, etc'
      ),
    },
    nl: {
      name: i18next.t('parkingTypes.PARKING_LOT.name', 'Parkeerterrein'),
      description: i18next.t(
        'parkingTypes.PARKING_LOT.description',
        'Parkeerterrein bedoeld voor het parkeren van voertuigen bijv. bij supermarkt, restaurants, etc'
      ),
    },
    fr: {
      name: i18next.t('parkingTypes.PARKING_LOT.name', 'Parking Lot'),
      description: i18next.t(
        'parkingTypes.PARKING_LOT.description',
        'A cleared area that is intended for parking vehicles, i.e. at super markets, bars, etc'
      ),
    },
    de: {
      name: i18next.t('parkingTypes.PARKING_LOT.name', 'Parking Lot'),
      description: i18next.t(
        'parkingTypes.PARKING_LOT.description',
        'A cleared area that is intended for parking vehicles, i.e. at super markets, bars, etc'
      ),
    },
  },
  ON_DRIVEWAY: {
    en: {
      name: i18next.t('parkingTypes.ON_DRIVEWAY.name', 'On Driveway'),
      description: i18next.t(
        'parkingTypes.ON_DRIVEWAY.description',
        'Location is on the driveway of a house/building.'
      ),
    },
    nl: {
      name: i18next.t('parkingTypes.ON_DRIVEWAY.name', 'Aan oprit'),
      description: i18next.t(
        'parkingTypes.ON_DRIVEWAY.description',
        'Locatie is op de oprit van een huis of gebouw.'
      ),
    },
    fr: {
      name: i18next.t('parkingTypes.ON_DRIVEWAY.name', 'On Driveway'),
      description: i18next.t(
        'parkingTypes.ON_DRIVEWAY.description',
        'Location is on the driveway of a house/building.'
      ),
    },
    de: {
      name: i18next.t('parkingTypes.ON_DRIVEWAY.name', 'On Driveway'),
      description: i18next.t(
        'parkingTypes.ON_DRIVEWAY.description',
        'Location is on the driveway of a house/building.'
      ),
    },
  },
  ON_STREET: {
    en: {
      name: i18next.t('parkingTypes.ON_STREET.name', 'On Street'),
      description: i18next.t(
        'parkingTypes.ON_STREET.description',
        'Parking in public space along a street.'
      ),
    },
    nl: {
      name: i18next.t('parkingTypes.ON_STREET.name', 'Aan straat'),
      description: i18next.t(
        'parkingTypes.ON_STREET.description',
        'Parkeren in openbare ruimte langs straat'
      ),
    },
    fr: {
      name: i18next.t('parkingTypes.ON_STREET.name', 'On Street'),
      description: i18next.t(
        'parkingTypes.ON_STREET.description',
        'Parking in public space along a street.'
      ),
    },
    de: {
      name: i18next.t('parkingTypes.ON_STREET.name', 'On Street'),
      description: i18next.t(
        'parkingTypes.ON_STREET.description',
        'Parking in public space along a street.'
      ),
    },
  },
  UNDERGROUND_GARAGE: {
    en: {
      name: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.name',
        'Underground Garage'
      ),
      description: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.description',
        'Multistory car park, mainly underground.'
      ),
    },
    nl: {
      name: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.name',
        'Ondergrondse parkeergarage'
      ),
      description: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.description',
        'Parkeergarage met meerdere verdiepingen, voornamelijk ondergronds.'
      ),
    },
    fr: {
      name: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.name',
        'Underground Garage'
      ),
      description: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.description',
        'Multistory car park, mainly underground.'
      ),
    },
    de: {
      name: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.name',
        'Underground Garage'
      ),
      description: i18next.t(
        'parkingTypes.UNDERGROUND_GARAGE.description',
        'Multistory car park, mainly underground.'
      ),
    },
  },
  FUEL_STATION: {
    en: {
      name: i18next.t('parkingTypes.FUEL_STATION.name', 'Fuel Station'),
      description: i18next.t(
        'parkingTypes.FUEL_STATION.description',
        'Fuel station with or without EV charge points'
      ),
    },
    nl: {
      name: i18next.t('parkingTypes.FUEL_STATION.name', 'Tankstation'),
      description: i18next.t(
        'parkingTypes.FUEL_STATION.description',
        'Tankstation met of zonder EV laadpalen'
      ),
    },
    fr: {
      name: i18next.t('parkingTypes.FUEL_STATION.name', 'Fuel Station'),
      description: i18next.t(
        'parkingTypes.FUEL_STATION.description',
        'Fuel station with or without EV charge points'
      ),
    },
    de: {
      name: i18next.t('parkingTypes.FUEL_STATION.name', 'Fuel Station'),
      description: i18next.t(
        'parkingTypes.FUEL_STATION.description',
        'Fuel station with or without EV charge points'
      ),
    },
  },
});

export function getParkingTypeOptions() {
  const parkingTypes = getParkingTypes();
  const { language } = i18next;
  return Object.keys(parkingTypes).map((key) => {
    const parkingType = parkingTypes[key][language] || parkingTypes[key].en;

    return {
      key,
      value: key,
      text: `${parkingType.name} - ${parkingType.description}`,
    };
  });
}

export const getFacilities = () => ({
  HOTEL: {
    en: { name: i18next.t('facilities.HOTEL.name', 'A Hotel.') },
    nl: { name: i18next.t('facilities.HOTEL.name', 'Een hotel.') },
    fr: { name: i18next.t('facilities.HOTEL.name', 'A Hotel.') },
    de: { name: i18next.t('facilities.HOTEL.name', 'A Hotel.') },
  },
  RESTAURANT: {
    en: { name: i18next.t('facilities.RESTAURANT.name', 'A Restaurant.') },
    nl: { name: i18next.t('facilities.RESTAURANT.name', 'Een restaurant.') },
    fr: { name: i18next.t('facilities.RESTAURANT.name', 'A Restaurant.') },
    de: { name: i18next.t('facilities.RESTAURANT.name', 'A Restaurant.') },
  },
  CAFE: {
    en: { name: i18next.t('facilities.CAFE.name', 'A cafe.') },
    nl: { name: i18next.t('facilities.CAFE.name', 'Een cafe.') },
    fr: { name: i18next.t('facilities.CAFE.name', 'A cafe.') },
    de: { name: i18next.t('facilities.CAFE.name', 'A cafe.') },
  },
  MALL: {
    en: {
      name: i18next.t('facilities.MALL.name', 'A mall or shopping center.'),
    },
    nl: { name: i18next.t('facilities.MALL.name', 'Een winkelcentrum.') },
    fr: {
      name: i18next.t('facilities.MALL.name', 'A mall or shopping center.'),
    },
    de: {
      name: i18next.t('facilities.MALL.name', 'A mall or shopping center.'),
    },
  },
  SUPERMARKET: {
    en: { name: i18next.t('facilities.SUPERMARKET.name', 'A supermarket.') },
    nl: { name: i18next.t('facilities.SUPERMARKET.name', 'Een supermarkt.') },
    fr: { name: i18next.t('facilities.SUPERMARKET.name', 'A supermarket.') },
    de: { name: i18next.t('facilities.SUPERMARKET.name', 'A supermarket.') },
  },
  SPORT: {
    en: {
      name: i18next.t(
        'facilities.SPORT.name',
        'Sport facilities: gym, field etc.'
      ),
    },
    nl: {
      name: i18next.t(
        'facilities.SPORT.name',
        'Sport faciliteiten sportschool, sportveld etc.'
      ),
    },
    fr: {
      name: i18next.t(
        'facilities.SPORT.name',
        'Sport facilities: gym, field etc.'
      ),
    },
    de: {
      name: i18next.t(
        'facilities.SPORT.name',
        'Sport facilities: gym, field etc.'
      ),
    },
  },
  RECREATION_AREA: {
    en: {
      name: i18next.t('facilities.RECREATION_AREA.name', 'A recreation area.'),
    },
    nl: {
      name: i18next.t(
        'facilities.RECREATION_AREA.name',
        'Een recreatiegebied.'
      ),
    },
    fr: {
      name: i18next.t('facilities.RECREATION_AREA.name', 'A recreation area.'),
    },
    de: {
      name: i18next.t('facilities.RECREATION_AREA.name', 'A recreation area.'),
    },
  },
  NATURE: {
    en: {
      name: i18next.t(
        'facilities.NATURE.name',
        'Located in, or close to, a park, nature reserve etc.'
      ),
    },
    nl: {
      name: i18next.t(
        'facilities.NATURE.name',
        'Gelegen in of nabij een park, natuurgebied etc.'
      ),
    },
    fr: {
      name: i18next.t(
        'facilities.NATURE.name',
        'Located in, or close to, a park, nature reserve etc.'
      ),
    },
    de: {
      name: i18next.t(
        'facilities.NATURE.name',
        'Located in, or close to, a park, nature reserve etc.'
      ),
    },
  },
  MUSEUM: {
    en: { name: i18next.t('facilities.MUSEUM.name', 'A museum.') },
    nl: { name: i18next.t('facilities.MUSEUM.name', 'Een museum.') },
    fr: { name: i18next.t('facilities.MUSEUM.name', 'A museum.') },
    de: { name: i18next.t('facilities.MUSEUM.name', 'A museum.') },
  },
  BIKE_SHARING: {
    en: {
      name: i18next.t(
        'facilities.BIKE_SHARING.name',
        'A bike/e-bike/e-scooter sharing location.'
      ),
    },
    nl: {
      name: i18next.t(
        'facilities.BIKE_SHARING.name',
        'Een locatie voor het delen van een fiets / e-bike / e-scooter.'
      ),
    },
    fr: {
      name: i18next.t(
        'facilities.BIKE_SHARING.name',
        'A bike/e-bike/e-scooter sharing location.'
      ),
    },
    de: {
      name: i18next.t(
        'facilities.BIKE_SHARING.name',
        'A bike/e-bike/e-scooter sharing location.'
      ),
    },
  },
  BUS_STOP: {
    en: { name: i18next.t('facilities.BUS_STOP.name', 'A bus stop.') },
    nl: { name: i18next.t('facilities.BUS_STOP.name', 'Een bushalte.') },
    fr: { name: i18next.t('facilities.BUS_STOP.name', 'A bus stop.') },
    de: { name: i18next.t('facilities.BUS_STOP.name', 'A bus stop.') },
  },
  TAXI_STAND: {
    en: { name: i18next.t('facilities.TAXI_STAND.name', 'A taxi stand.') },
    nl: {
      name: i18next.t('facilities.TAXI_STAND.name', 'Een taxi standplaats.'),
    },
    fr: { name: i18next.t('facilities.TAXI_STAND.name', 'A taxi stand.') },
    de: { name: i18next.t('facilities.TAXI_STAND.name', 'A taxi stand.') },
  },
  TRAM_STOP: {
    en: {
      name: i18next.t('facilities.TRAM_STOP.name', 'A tram stop/station.'),
    },
    nl: { name: i18next.t('facilities.TRAM_STOP.name', 'Een tramhalte.') },
    fr: {
      name: i18next.t('facilities.TRAM_STOP.name', 'A tram stop/station.'),
    },
    de: {
      name: i18next.t('facilities.TRAM_STOP.name', 'A tram stop/station.'),
    },
  },
  METRO_STATION: {
    en: {
      name: i18next.t('facilities.METRO_STATION.name', 'A metro station.'),
    },
    nl: {
      name: i18next.t('facilities.METRO_STATION.name', 'Een metrostation.'),
    },
    fr: {
      name: i18next.t('facilities.METRO_STATION.name', 'A metro station.'),
    },
    de: {
      name: i18next.t('facilities.METRO_STATION.name', 'A metro station.'),
    },
  },
  TRAIN_STATION: {
    en: {
      name: i18next.t('facilities.TRAIN_STATION.name', 'A train station.'),
    },
    nl: {
      name: i18next.t('facilities.TRAIN_STATION.name', 'Een treinstation.'),
    },
    fr: {
      name: i18next.t('facilities.TRAIN_STATION.name', 'A train station.'),
    },
    de: {
      name: i18next.t('facilities.TRAIN_STATION.name', 'A train station.'),
    },
  },
  AIRPORT: {
    en: { name: i18next.t('facilities.AIRPORT.name', 'An airport.') },
    nl: { name: i18next.t('facilities.AIRPORT.name', 'Een vliegveld.') },
    fr: { name: i18next.t('facilities.AIRPORT.name', 'An airport.') },
    de: { name: i18next.t('facilities.AIRPORT.name', 'An airport.') },
  },
  PARKING_LOT: {
    en: { name: i18next.t('facilities.PARKING_LOT.name', 'A parking lot.') },
    nl: {
      name: i18next.t('facilities.PARKING_LOT.name', 'Een parkeerplaats.'),
    },
    fr: { name: i18next.t('facilities.PARKING_LOT.name', 'A parking lot.') },
    de: { name: i18next.t('facilities.PARKING_LOT.name', 'A parking lot.') },
  },
});

export function getFacilityOptions() {
  const facilities = getFacilities();
  const { language } = i18next;
  return Object.keys(facilities).map((key) => {
    const facility = facilities[key][language] || facilities[key].en;

    return {
      key,
      value: key,
      text: facility.name,
    };
  });
}

export const getAccessPolicies = () => ({
  businessReimburse: {
    en: i18next.t(
      'accessPolicies.businessReimburse',
      'Business gets reimbursed'
    ),
    nl: i18next.t(
      'accessPolicies.businessReimburse',
      'Vergoeding voor zakelijke locaties'
    ),
    fr: i18next.t(
      'accessPolicies.businessReimburse',
      'Business gets reimbursed'
    ),
    de: i18next.t(
      'accessPolicies.businessReimburse',
      'Business gets reimbursed'
    ),
  },
  employeeReimburse: {
    en: i18next.t(
      'accessPolicies.employeeReimburse',
      'Employee gets reimbursed by employer'
    ),
    nl: i18next.t(
      'accessPolicies.employeeReimburse',
      'Werknemer krijgt vergoeding van werkgever'
    ),
    fr: i18next.t(
      'accessPolicies.employeeReimburse',
      'Employee gets reimbursed by employer'
    ),
    de: i18next.t(
      'accessPolicies.employeeReimburse',
      'Employee gets reimbursed by employer'
    ),
  },
  communityReimburse: {
    en: i18next.t('accessPolicies.hoaPolicy', 'HOA Policy'),
    nl: i18next.t('accessPolicies.hoaPolicy', 'HOA Policy'),
    fr: i18next.t('accessPolicies.hoaPolicy', 'HOA Policy'),
    de: i18next.t('accessPolicies.hoaPolicy', 'HOA Policy'),
  },
  noReimburse: {
    en: i18next.t('accessPolicies.noReimburse', 'No reimbursement'),
    nl: i18next.t('accessPolicies.noReimburse', 'Geen vergoeding'),
    fr: i18next.t('accessPolicies.noReimburse', 'No reimbursement'),
    de: i18next.t('accessPolicies.noReimburse', 'No reimbursement'),
  },
  splitReimburse: {
    en: i18next.t('accessPolicies.splitReimburse', 'Split Reimbursement'),
    nl: i18next.t(
      'accessPolicies.splitReimburse',
      'Vergoeding voor zakelijke locaties'
    ),
    fr: i18next.t('accessPolicies.splitReimburse', 'Split Reimbursement'),
    de: i18next.t('accessPolicies.splitReimburse', 'Split Reimbursement'),
  },
});

export function getAccessPoliciesOptions(
  splitReimburseEnabled,
  communityReimburseEnabled
) {
  const accessPolicies = getAccessPolicies();
  const { language } = i18next;
  return Object.keys(accessPolicies)
    .filter((key) => key !== 'splitReimburse' || splitReimburseEnabled)
    .filter((key) => key !== 'communityReimburse' || communityReimburseEnabled)
    .map((key) => ({
      key,
      value: key,
      text: accessPolicies[key][language] || accessPolicies[key].en,
    }));
}
