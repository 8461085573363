import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Message, Label } from 'semantic';
import { simpleOptions } from 'utils/form';
import * as PropTypes from 'prop-types';
import { request } from 'utils/api';
import Code from 'components/form-fields/Code';
import { isEqual, merge, set } from 'lodash-es';
import modal from 'helpers/modal';

function SmartChargingModal({ initialValues, onSubmit }) {
  const [formValues, setFormValues] = React.useState({ ...initialValues });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (formValues?.smartCharging?.method === 'none') {
      formValues.smartCharging.pairingCode = null;
    }

    await request({
      method: 'PATCH',
      path: `/1/evse-controllers/smart-charging/pairing/${initialValues.id}`,
      body: formValues,
    })
      .then(({ data }) => setFormValues(data))
      .then(() => setTimeout(() => onSubmit(), 1000))
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  const setField = (name, value) =>
    value && setFormValues(merge({}, formValues, set({}, name, value)));

  useEffect(() => {
    if (formValues?.smartCharging?.method !== 'none') {
      document.querySelector('.form-field-code__item ')?.focus();
    }
  }, [formValues.smartCharging.method]);

  return (
    <>
      <Modal.Header
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          {t('smartCharging.title', 'Smart Charging')}{' '}
          {t('smartCharging.pairing', 'Pairing')}
          {': '}
          {initialValues.ocppIdentity}
        </span>
        <span>
          {t('smartCharging.status', 'Status')}
          {': '}
          <Label
            content={
              formValues.smartCharging.paired
                ? t('smartCharging.paired', 'Paired')
                : t('smartCharging.notPaired', 'Not Paired')
            }
            color={formValues?.smartCharging?.paired ? 'olive' : undefined}
          />
        </span>
      </Modal.Header>
      <Modal.Content>
        <Form id="SmartCharging-form" onSubmit={handleSubmit}>
          {!!error && <Message error content={error}></Message>}
          <Form.Field>
            <label>{t('smartCharging.method', 'Smart Charging Method')}</label>
            <Form.Dropdown
              value={formValues.smartCharging.method}
              selection
              options={simpleOptions(['none', 'stekker'])}
              name="smartCharging.method"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Form.Field>
          {formValues.smartCharging.method !== 'none' && (
            <Form.Field>
              <label style={{ textAlign: 'center', margin: '1em' }}>
                {t('smartCharging.pairingCode', 'Smart Charging Pairing Code')}
              </label>
              <Code
                value={formValues.smartCharging.pairingCode || ''}
                type="text"
                name="smartCharging.pairingCode"
                style={{ marginInline: 'auto' }}
                length={5}
                onChange={({ name, value }) => setField(name, value)}
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          loading={loading}
          disabled={isEqual(formValues, initialValues)}
          content={t('common.activate', 'Activate')}
          form="SmartCharging-form"
        />
      </Modal.Actions>
    </>
  );
}

SmartChargingModal.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default modal(SmartChargingModal);
