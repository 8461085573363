import React, { useEffect, useState } from 'react';
import {
  Form,
  Message,
  Modal,
  Button,
  Icon,
  DropdownItemProps,
  Popup,
} from 'semantic';
import { request } from 'utils/api';
import SearchDropDown from 'components/form-fields/formik/SearchDropdown';
import { getInvoiceTypeOptions, invoiceTypes } from 'utils/invoicing';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  convertLocalDateToUTCEquivalent,
  monthOptions,
  yearOptions,
} from 'utils/date';
import { getAccountingSystemOptions } from 'utils/accountingSystems';
import { FeatureFlags } from 'screens/Invoices/features';
import RemoveMspDelayedBillingMessage from './RemoveMspDelayedBillingMessage';
import DateField from 'components/form-fields/formik/DateField';
import { Formik, FormikValues, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useFeatures } from 'contexts/features';
import DropdownField from 'components/form-fields/formik/DropdownField';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import InputField from 'components/form-fields/formik/InputField';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import { format } from 'date-fns';
import { getDefaultInvoicePostingPeriod } from './utils';
import AsyncModal from '../../../helpers/async-modal';

const BulkCountMessage = () => {
  const { t } = useTranslation();
  const [bulkCountMessage, setBulkCountMessage] = useState<string | null>();
  const { values } = useFormikContext();

  async function getBulkCount(year?: string, month?: string, type?: string) {
    if (!year || !month || !type) {
      setBulkCountMessage(null);
    }

    const invoiceType = t(`invoiceType.{{type}}`, { type });

    try {
      const { data: bulkCount } = await request({
        method: 'POST',
        path: '/1/invoices/count',
        body: {
          year: Number(year),
          month: Number(month),
          type: type,
        },
      });

      if (bulkCount > 0) {
        setBulkCountMessage(
          `Already ${bulkCount} invoices of type ${invoiceType} generated for this period ${year}-${month}`
        );
      } else if (bulkCount !== undefined) {
        setBulkCountMessage(null);
      } else {
        setBulkCountMessage(
          `Error while checking for existing invoices: failed to retrieve bulk count`
        );
      }
    } catch (err) {
      if (err instanceof Error && err.message) {
        setBulkCountMessage(
          `Error while checking for existing invoices: ${err.message}`
        );
      }
    }
  }

  useEffect(() => {
    const { year, month, type } = values as {
      year: string;
      month: string;
      type: string;
    };
    getBulkCount(year, month, type);
  }, [values]);

  return bulkCountMessage ? (
    <Message warning icon="triangle-exclamation" content={bulkCountMessage} />
  ) : null;
};

const GenerateInvoice = ({
  handleClose,
  close,
  isBulk,
}: {
  handleClose: () => Promise<void>;
  close: () => void;
  isBulk: boolean;
}) => {
  const [error, setError] = useState<Error | null>();
  const [successMessage, setSuccessMessage] = useState<string | null>();

  const { t } = useTranslation();
  const { hasFeature } = useFeatures();

  const [accountingSystemOptions, setAccountingSystemOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [defaultAccountingSystem, setDefaultAccountingSystem] = useState<
    string[]
  >([]);
  const [invoiceTypeOptions, setInvoiceTypeOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [defaultInvoiceType, setDefaultInvoiceType] = useState<string | null>();

  const isInvoiceDateFeatureEnabled = hasFeature(FeatureFlags.InvoiceDate);
  const removeMspDelayedBilling = hasFeature(
    FeatureFlags.RemoveMspDelayedBilling
  );
  const isManualERPSyncEnabled = hasFeature(FeatureFlags.ManualERPSync);

  async function onSubmit(values: FormikValues) {
    const path = isBulk
      ? '/1/invoices/generate'
      : '/1/invoices/generate/account';

    const body = {
      type: values.type,
      year: Number(values.year),
      month: Number(values.month),
      accountingSystems: values.accountingSystems,
      ...(isBulk && { batchSize: Number(values.batchSize) }),
      ...(!isBulk && { accountId: values.accountId }),
      ...(isInvoiceDateFeatureEnabled && {
        invoiceDate: format(
          convertLocalDateToUTCEquivalent(values.invoiceDate),
          'yyyy-MM-dd'
        ),
      }),
      ...(isInvoiceDateFeatureEnabled && {
        invoicePostingPeriod: values.invoicePostingPeriod,
      }),
      ...(isManualERPSyncEnabled && {
        disableERPSubmission: values.disableERPSubmission,
      }),
      includeEnterpriseAccounts: values.includeEnterpriseAccounts,
    };

    try {
      const { data } = await request({
        method: 'POST',
        path,
        body,
      });

      if (data?.numCreated !== undefined) {
        setSuccessMessage(
          `${data.numCreated} invoice${
            data.numCreated !== 1 ? 's' : ''
          } created`
        );
      }

      if (data?.error) {
        setError(new Error(data?.error));
      }
    } catch (err) {
      if (err instanceof Error && err.message) {
        setError(error);
      }
    }
  }

  async function getAccountingSystems() {
    try {
      const accountingSystemOptions: DropdownItemProps[] =
        await getAccountingSystemOptions();
      setAccountingSystemOptions(accountingSystemOptions);

      if (accountingSystemOptions.find((v) => v.value === 'netsuite')) {
        setDefaultAccountingSystem(['netsuite']);
      }
    } catch (err) {
      if (err instanceof Error && err.message) {
        setError(error);
      }
    }
  }

  function getInvoiceTypes() {
    const options = getInvoiceTypeOptions(t, removeMspDelayedBilling);
    setInvoiceTypeOptions(options);

    if (options.find((v) => v.value === invoiceTypes.MspUsage)) {
      setDefaultInvoiceType(invoiceTypes.MspUsage);
    }
  }

  async function searchForAccount(body: object) {
    return await request({
      path: '/1/accounts/search',
      method: 'POST',
      body: {
        ...body,
        includeDeleted: true,
      },
    });
  }

  function setAccountDeleteLabel(item: { deleted: boolean; name: string }) {
    if (item.deleted) {
      return item.name + ' ❌ [deleted] ❌';
    }

    return item.name;
  }

  useEffect(() => {
    getInvoiceTypes();
    getAccountingSystems();
  }, []);

  const initialValues = {
    type: defaultInvoiceType,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    includeEnterpriseAccounts: false,
    accountingSystems: defaultAccountingSystem,
    ...(isBulk && { batchSize: 100 }),
    ...(!isBulk && { accountId: undefined }),
    ...(isInvoiceDateFeatureEnabled && { invoiceDate: undefined }),
    ...(isManualERPSyncEnabled && { disableERPSubmission: false }),
    ...(isInvoiceDateFeatureEnabled && {
      invoicePostingPeriod: getDefaultInvoicePostingPeriod(),
    }),
  };

  const validationSchema = Yup.object({
    type: Yup.string()
      .oneOf(invoiceTypeOptions.map((v) => v.value as string))
      .required(),
    year: Yup.number().required(),
    month: Yup.number().required(),
    accountingSystems: Yup.array().of(
      Yup.string().oneOf(accountingSystemOptions.map((v) => v.value as string))
    ),
    ...(isBulk && { batchSize: Yup.number().required().min(1) }),
    ...(!isBulk && { accountId: Yup.string().required() }),
    ...(isInvoiceDateFeatureEnabled && { invoiceDate: Yup.date().required() }),
    ...(isInvoiceDateFeatureEnabled && {
      invoicePostingPeriod: Yup.string(),
    }),
    ...(isManualERPSyncEnabled && {
      disableERPSubmission: Yup.boolean().required(),
    }),
  });

  function clearMessages() {
    setError(null);
    setSuccessMessage(null);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, isSubmitting, values }) => {
        return (
          <>
            <Modal.Header>
              {isBulk
                ? t('generateInvoicesDialog.header', 'Generate Invoices')
                : t(
                    'generateInvoiceDialog.header',
                    'Generate Invoice for Account'
                  )}
            </Modal.Header>

            {(!!error || !!successMessage) && (
              <>
                <Modal.Content>
                  {error && <Message error content={error.message} />}
                  {successMessage && <Message info content={successMessage} />}
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    onClick={async () => {
                      await handleClose();
                      close();
                    }}
                    content={t('generateInvoiceDialog.close', 'Close')}
                    floated="right"
                  />
                  {error && (
                    <Button
                      basic
                      onClick={clearMessages}
                      content={t('generateInvoiceDialog.back', 'Back')}
                      floated="right"
                    />
                  )}
                  <FormVerticalSpace size={30} />
                </Modal.Actions>
              </>
            )}

            {!error && !successMessage && (
              <Modal.Content>
                <Form onSubmit={handleSubmit}>
                  {isBulk && <BulkCountMessage />}

                  <DropdownField
                    name="type"
                    label="Type"
                    options={invoiceTypeOptions}
                    clearable={false}
                    fluid
                    selection
                    required
                  />
                  {removeMspDelayedBilling &&
                    values.type === invoiceTypes.MspUsage && (
                      <RemoveMspDelayedBillingMessage />
                    )}
                  <DropdownField
                    name="year"
                    label={t('generateInvoiceDialog.year', 'Year')}
                    options={yearOptions()}
                    clearable={false}
                    fluid
                    selection
                    required
                  />
                  <DropdownField
                    name="month"
                    label={t('generateInvoiceDialog.month', 'Month')}
                    options={monthOptions()}
                    clearable={false}
                    fluid
                    selection
                    required
                  />

                  {isInvoiceDateFeatureEnabled && (
                    <>
                      <div
                        className="inline field required"
                        style={{ width: '100%', marginBottom: '0' }}>
                        <label>
                          {t(
                            'generateInvoiceDialog.invoiceDate',
                            'Invoice Date'
                          )}
                        </label>
                        <Popup
                          content={t(
                            'generateInvoiceDialog.invoiceDateToolTip',
                            'This will be the date that appears as invoice date on the invoice.'
                          )}
                          trigger={<Icon size="small" name="circle-info" />}
                        />
                      </div>
                      <div>
                        <DateField
                          name="invoiceDate"
                          required
                          fullWidth={true}
                          hideErrorLabel={false}
                        />
                      </div>
                      <FormVerticalSpace size={5} />
                    </>
                  )}

                  {isInvoiceDateFeatureEnabled && (
                    <>
                      <div
                        className="inline field"
                        style={{ width: '100%', marginBottom: '0' }}>
                        <label>
                          {t(
                            'generateInvoiceDialog.invoicePostingPeriod',
                            'Invoice Posting Date'
                          )}
                        </label>
                        <Popup
                          content={t(
                            'generateInvoiceDialog.invoiceDateToolTip',
                            'This will be the date that appears as posting date on the invoice.'
                          )}
                          trigger={<Icon size="small" name="circle-info" />}
                        />
                      </div>
                      <div>
                        <InputField
                          name="invoicePostingPeriod"
                          hideErrorLabel={false}
                          type="month"
                        />
                      </div>
                      <FormVerticalSpace size={5} />
                    </>
                  )}

                  {!isBulk && (
                    <SearchDropDown
                      objectMode={false}
                      name="accountId"
                      label={t('generateInvoiceDialog.account', 'Account')}
                      onDataNeeded={searchForAccount}
                      getOptionLabel={setAccountDeleteLabel}
                      required
                    />
                  )}

                  {isBulk && (
                    <>
                      <InputField
                        name="batchSize"
                        type="number"
                        min={1}
                        label={t(
                          'generateInvoicesDialog.batchSizeMax',
                          'Batch Size (Max)'
                        )}
                      />
                      <FormVerticalSpace size={5} />
                    </>
                  )}

                  {isBulk && (
                    <CheckboxField
                      label={t(
                        'generateInvoiceDialog.includeEnterpriseAccounts',
                        'Generate invoices also for enterprise account providers'
                      )}
                      name="includeEnterpriseAccounts"
                    />
                  )}

                  {!!accountingSystemOptions.length && (
                    <>
                      <DropdownField
                        name="accountingSystems"
                        label={t(
                          'generateInvoiceDialog.accountingSystems',
                          'Accounting Systems'
                        )}
                        fluid
                        multiple
                        selection
                        clearable
                        options={accountingSystemOptions}
                        required
                      />
                      {isManualERPSyncEnabled && (
                        <CheckboxField
                          label={t(
                            'generateInvoiceDialog.disableERPSubmission',
                            'Do not immediately submit invoice to ERP system'
                          )}
                          name="disableERPSubmission"
                          required
                        />
                      )}
                    </>
                  )}

                  {isBulk && (
                    <Message
                      info
                      icon="hourglass-half"
                      content="Please note that for large batches, it can take up to 5 minutes for invoices to appear in the Invoices overview"
                    />
                  )}

                  <Button
                    primary
                    type="submit"
                    disabled={error || Boolean(successMessage) || isSubmitting}
                    loading={isSubmitting}
                    content={t('generateInvoiceDialog.generate', 'Generate')}
                    floated="right"
                  />
                  <FormVerticalSpace size={25} />
                </Form>
              </Modal.Content>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AsyncModal(withTranslation()(GenerateInvoice));
