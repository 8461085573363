import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'semantic';
import { Breadcrumbs, ListHeader, Search } from 'components';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import Table from 'screens/FinanceRoamingLimits/List/Table';
import { EditRoamingLimit } from 'components/modals/EditRoamingLimit';

const onDataNeeded = async (filters, apiRoute) => {
  return await request({
    method: 'POST',
    path: `${apiRoute}/search`,
    body: {
      filters,
    },
  });
};

export default function FinanceRoamingLimits({ prefixUiRoute, apiRoute }) {
  const { t } = useTranslation();
  const searchRef = useRef(null);

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={(filters) => onDataNeeded(filters, apiRoute)}>
      <>
        <Breadcrumbs
          path={[
            <Link key="finance" to={prefixUiRoute}>
              {t('finance.title', 'Finance')}
            </Link>,
          ]}
          active={t('roamingLimits.title', 'Roaming limits')}
        />

        <ListHeader title={t('roamingLimits.title', 'Roaming limits')}>
          <EditRoamingLimit
            trigger={
              <Button
                primary
                floated="right"
                style={{ marginTop: '-5px' }}
                content={t('roamingLimits.newLimit', 'New Limit')}
                icon="plus"
              />
            }
            onSave={() => {
              searchRef.current.reload();
            }}
          />
        </ListHeader>

        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table />
        <Divider hidden />

        <Search.Pagination />
      </>
    </Search.Provider>
  );
}
