import React from 'react';
import { AggregateTerms } from 'react-tectonic';
import { Table, Header } from 'semantic';

export default function TermsByTermsTable({
  collection,
  title = '',
  aggField,
  termsSize = 8,
  termsSize2 = 8,
  aggField2,
  operation,
  field,
  label = '',
  label2 = '',
  valueFieldLabel = 'Total',
  timeRangeDateField = 'createdAt',
}) {
  return (
    <AggregateTerms
      collection={collection}
      aggField={aggField}
      termsSize={termsSize}
      operation={operation}
      timeRangeDateField={timeRangeDateField}
      field={field}>
      {({ data }) => {
        return (
          <React.Fragment>
            <Header as="h3" textAlign="center">
              {title}
            </Header>
            <Table celled definition>
              <Table.Header>
                <Table.HeaderCell>{label}</Table.HeaderCell>
                <Table.HeaderCell>{valueFieldLabel}</Table.HeaderCell>
                <Table.HeaderCell>{label2}</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {data.map(({ key, count }) => {
                  return (
                    <Table.Row key={key}>
                      <Table.Cell>{key}</Table.Cell>
                      <Table.Cell>{count}</Table.Cell>
                      <Table.Cell>
                        <AggregateTerms
                          collection={collection}
                          aggField={aggField2}
                          termsSize={termsSize2}
                          operation={operation}
                          field={field}
                          timeRangeDateField={timeRangeDateField}
                          filter={{
                            terms: [{ [aggField]: key }],
                          }}>
                          {({ data }) => {
                            return (
                              <Table basic="very" celled>
                                <Table.Body>
                                  {data.map(({ key, count }, i) => {
                                    return (
                                      <Table.Row key={key}>
                                        <Table.Cell
                                          style={{
                                            borderTop:
                                              i > 0
                                                ? '1px solid rgba(34, 36, 38, 0.1)'
                                                : '',
                                            backgroundColor: 'transparent',
                                          }}
                                          width={10}>
                                          {key}
                                        </Table.Cell>
                                        <Table.Cell
                                          style={{
                                            borderTop:
                                              i > 0
                                                ? '1px solid rgba(34, 36, 38, 0.1)'
                                                : '',
                                          }}>
                                          {count}
                                        </Table.Cell>
                                      </Table.Row>
                                    );
                                  })}
                                </Table.Body>
                              </Table>
                            );
                          }}
                        </AggregateTerms>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </React.Fragment>
        );
      }}
    </AggregateTerms>
  );
}
