import React from 'react';
import { Search } from 'components';
import { request } from 'utils/api';
import Feed from './Feed';

import { Divider, Loader, Message, Dropdown } from 'semantic';
import { withTranslation } from 'react-i18next';

function defaultConnectorId(evseController) {
  if (evseController.connectors && evseController.connectors.length) {
    return evseController.connectors[0].connectorId;
  }
  return undefined;
}

const limit = 2000; //due to we have to aggrate data see the Feed component
class EvseControllerActivity extends React.Component {
  state = {
    connectorId: defaultConnectorId(this.props.evseController),
  };

  onDataNeeded = (filters = {}) => {
    const { evseController } = this.props;
    return request({
      method: 'POST',
      path: `/1/evse-controllers/${evseController.id}/commands/search`,
      body: {
        limit,
        hideMeterValues: true,
        connectorId: this.state.connectorId,
        ...filters,
      },
    });
  };

  render() {
    const { evseController, t } = this.props;
    const { connectorId } = this.state;
    if (!evseController) return <Loader active />;
    if (!evseController.connectors || !evseController.connectors.length) {
      return (
        <Message content="No connectors have been configured for this device yet" />
      );
    }

    return (
      <div>
        <Dropdown
          selection
          value={connectorId}
          onChange={(e, { value }) => {
            this.setState({ connectorId: value });
          }}
          options={evseController.connectors.map((connector) => {
            return {
              key: connector.connectorId,
              value: connector.connectorId,
              text: `Connector ${connector.connectorId}`,
            };
          })}
        />

        <Divider hidden />

        <Search.Provider
          limit={limit}
          key={connectorId}
          onDataNeeded={this.onDataNeeded}>
          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Feed />
          <Divider hidden />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </Search.Provider>
      </div>
    );
  }
}

export default withTranslation()(EvseControllerActivity);
