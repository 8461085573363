import { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { EvseController } from 'components/modals/EditEvseController/types';

export default function useBillingPlans(
  evseController: Partial<EvseController>,
  setField: (name: string, value: string) => void,
  onError?: (error: any) => void
) {
  const [billingPlans, setBillingPlans] = useState([]);

  useEffect(() => {
    if (!evseController.accountId) {
      setBillingPlans([]);
      return;
    }
    (async () => {
      const { data: account } = await request({
        method: 'GET',
        path: `/1/accounts/${evseController.accountId}`,
      });
      const currency = account?.billingCurrency;
      const payload: any = { currency, providerContext: 'cpo' };
      if (account.allowedBillingPlanIds?.length) {
        payload.ids = account.allowedBillingPlanIds;
      }
      const { data: billingPlans } = await request({
        path: '/1/billing-plans/search',
        method: 'POST',
        body: payload,
      });
      setBillingPlans(billingPlans);

      // check if chosen tariff profile is in the list
      if (!billingPlans.find((i) => i.id === evseController.billingPlanId)) {
        setField('billingPlanId', '');
      }
    })().catch((error) => onError?.(error));
  }, [evseController.accountId]);

  return billingPlans;
}
