import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import EditEvseController from 'components/modals/EditEvseController';
import InspectObject from 'components/modals/InspectObject';
import ExportButton from 'components/ExportButton';
import { Link } from 'react-router-dom';
import { getSetupProgress } from 'utils/evse-controllers';
import { truncate } from 'utils/formatting';

import { currentUserCanAccessProviderEndpoint } from 'utils/roles';

import {
  Table,
  Loader,
  Segment,
  Message,
  Modal,
  Button,
  Progress,
  Label,
  Divider,
} from 'semantic';
import { ConnectivityStatus } from '../ConnectivityStatus';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const { status, data: items, meta = {}, page } = this.props;

    const writeAccess = currentUserCanAccessProviderEndpoint(
      'evseControllers',
      'write'
    );
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No EVSE Controllers created yet</Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>OCPP ID</Table.HeaderCell>
                <Table.HeaderCell width={3}>Accounting</Table.HeaderCell>
                <Table.HeaderCell width={2}>Setup</Table.HeaderCell>
                <Table.HeaderCell width={2}>Connectivity</Table.HeaderCell>
                <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                const setupProgress = getSetupProgress(item);
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <Link
                        to={`/charging-stations/${item.id}`}
                        title={`Serial Number: ${
                          item.serialNumber ||
                          item.bootInfo?.chargePointSerialNumber
                        }`}>
                        {truncate(item.ocppIdentity, 20)}
                      </Link>
                      <p>
                        <small>Evse ID: {item.evseId || '-'}</small> <br />
                        <small>Serial number: {item.serialNumber || '-'}</small>
                      </p>
                    </Table.Cell>
                    <Table.Cell>
                      {item.account && (
                        <>
                          Account:{' '}
                          <Link to={`/accounts/${item.account.id}`}>
                            {item.account.name}
                          </Link>
                          <br />
                        </>
                      )}
                      {item.maintenanceAccount && (
                        <>
                          Field Service:{' '}
                          <Link to={`/accounts/${item.maintenanceAccount.id}`}>
                            {item.maintenanceAccount.name}
                          </Link>
                          <br />
                        </>
                      )}
                      {item.location && (
                        <>
                          Location:{' '}
                          <Link
                            to={`/charging-stations/locations/${item.location.id}`}>
                            {item.location.name}
                          </Link>
                          <br />
                        </>
                      )}
                      {item.billingPlan && (
                        <>Plan: {item.billingPlan.details.en.name}</>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {setupProgress.completed ? (
                        <Progress percent={100} label="Completed" size="tiny" />
                      ) : setupProgress.readyForActivation ? (
                        <Label
                          color="blue"
                          content="Activation Pending"
                          title="This EVSE is ready to be activated by the customer or linked by admin"
                        />
                      ) : (
                        <Progress
                          percent={setupProgress.percent}
                          label="Setup Progress"
                          size="tiny"
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <ConnectivityStatus item={item} />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <InspectObject
                        name="EVSE Controller"
                        data={item}
                        trigger={<Button basic icon="file-code" />}
                      />
                      <EditEvseController
                        data={item}
                        trigger={
                          <Button
                            basic
                            icon="pen-to-square"
                            title="Edit"
                            onClick={() => {
                              setResourceProviderIdInSessionStorage(
                                item?.providerId
                              );
                            }}
                            disabled={!writeAccess}
                          />
                        }
                        onClose={() => {
                          removeResourceProviderIdInSessionStorage();
                          this.props.onRefresh();
                        }}
                      />
                      <Modal
                        header={`Are you sure you want to delete?`}
                        content="All data will be permanently deleted"
                        //status={deleteStatus}
                        trigger={
                          <Button
                            basic
                            icon="trash"
                            title="Delete"
                            disabled={!writeAccess}
                          />
                        }
                        closeIcon
                        actions={[
                          {
                            key: 'delete',
                            primary: true,
                            content: 'Delete',
                            onClick: () => this.props.onDeleteItem(item),
                          },
                        ]}
                      />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Loader active>Loading</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
        {!this.props.hideExport && status.success && meta.total > 0 && (
          <>
            <div>
              <ExportButton
                content="Export All"
                path={'/1/evse-controllers/search'}
                filename="evse-controllers"
              />
            </div>
            <Divider hidden />
            <div>
              <ExportButton
                content="Export All Connectors"
                path={'/1/evse-controllers/search'}
                filename="connectors"
                body={{ exportConnectorsOnly: true }}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}
