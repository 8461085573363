export const invoiceTypes = {
  MspUsage: 'msp-usage',
  CpoUsage: 'cpo-usage',
  CpoCredit: 'cpo-credit',
  MspUsageDelayedSessions: 'msp-usage-delayed-sessions',
  CpoCreditDelayedSessions: 'cpo-credit-delayed-sessions',
};

function getTranslationKey(t, invoiceType) {
  const strings = {
    [invoiceTypes.MspUsage]: t('invoiceType.msp-usage'),
    [invoiceTypes.CpoUsage]: t('invoiceType.cpo-usage'),
    [invoiceTypes.CpoCredit]: t('invoiceType.cpo-credit'),
    [invoiceTypes.MspUsageDelayedSessions]: t(
      'invoiceType.msp-usage-delayed-sessions'
    ),
    [invoiceTypes.CpoCreditDelayedSessions]: t(
      'invoiceType.cpo-credit-delayed-sessions'
    ),
  };
  return strings[invoiceType];
}

export function getInvoiceTypeOptions(t, RemoveMspDelayedBilling = false) {
  return Object.values(invoiceTypes)
    .filter(
      (key) =>
        !(
          RemoveMspDelayedBilling &&
          key === invoiceTypes.MspUsageDelayedSessions
        )
    )
    .map((key) => ({
      key,
      value: key,
      text: getTranslationKey(t, key),
    }));
}
