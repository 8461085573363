import React, { useMemo } from 'react';
import SimpleCostSettings from 'components/tariffs/EvseTariff/Simple';
import AdvancedCostSettings from 'components/tariffs/EvseTariff/Advanced';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import {
  EvseControllerConnector,
  EvseControllerCostSettings,
} from 'components/modals/EditEvseController/types';

export type CostSettingsType = 'simple' | 'advanced';

export function useCostSettingsType(): CostSettingsType {
  const advancedTariffsFeatures = useAdvancedTariffFeatures();
  return useMemo(() => {
    if (advancedTariffsFeatures.advancedTariffs) {
      return 'advanced';
    }

    return 'simple';
  }, [advancedTariffsFeatures.advancedTariffs]);
}

type CostSettingsProps = {
  currency: string;
  disabled: boolean;
  value: EvseControllerCostSettings[];
  energyDeliveryArea: string;
  connectors: EvseControllerConnector[];
  evseConfiguration: any;
  evseConnectivityState: string;
  evseControllerId: string;
  numConnectors: number;
  label: string;
  refetchEvseConfiguration: () => void;
  setIsReady: (ready: boolean) => void;
  setRebootRequired: (reboot: boolean) => void;
  onChange: (value: any) => void;
};

export default function CostSettings(
  props: CostSettingsProps
): React.ReactElement {
  const costSettingsType = useCostSettingsType();

  if (costSettingsType === 'advanced') {
    return <AdvancedCostSettings {...props} />;
  }

  return <SimpleCostSettings {...props} />;
}
