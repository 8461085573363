import React from 'react';
import { Header, Table, Label } from 'semantic';
import { useTranslation } from 'react-i18next';

import { DetailItem, DetailItemSession, DetailItemLocation } from '../common';
import { Link } from 'react-router-dom';

type Props = {
  item: DetailItem;
};

function SessionID({ session }: { session?: DetailItemSession }) {
  if (!session) return <span>-</span>;
  return <span>{`${session.id}`}</span>;
}

function LocationLink({ location }: { location: DetailItemLocation }) {
  return (
    <Link to={`/charging-stations/locations/${location.id}`}>
      {location.name}
    </Link>
  );
}

export default function SessionDetailTable({ item }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h3">
        {t('my-payment-fees.detail.fees-details', 'Session Details')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>
              {t('my-payment-fees.detail.session-id', 'Session ID')}
            </Table.Cell>
            <Table.Cell>
              <SessionID session={item.session} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('my-payment-fees.detail.location', 'Location')}
            </Table.Cell>
            <Table.Cell>
              <LocationLink location={item.location} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('my-payment-fees.detail.currency-charged', 'Currency Charged')}
            </Table.Cell>
            <Table.Cell>
              <Label>{item.payment.currency}</Label>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
