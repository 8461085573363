import React, { useContext, useRef } from 'react';
import { formatDuration } from 'utils/date';
import {
  formatKwh,
  formatLocationAddress,
  safeFileName,
} from 'utils/formatting';
import InspectObject from 'components/modals/InspectObject';
import ViewSession from 'components/modals/ViewSession';
import Session from '../../../components/Session';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import { Header, Table, Label, Divider, Button } from 'semantic';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash-es';
import { request } from 'utils/api';
import { Search, Layout, Confirm } from 'components';
import { formatDateTime } from 'utils/date';
import { Context as SearchContext } from 'components/search';

const AccountSessions = ({ account }) => {
  const { provider } = useUser();
  const searchRef = useRef();
  const { t } = useTranslation();

  const onDelete = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/sessions/${item.id}`,
    });
  };

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: `/1/sessions/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        accountId: account.id,
        ...filters,
        sort: {
          order: 'desc',
          field: 'startedAt',
        },
      },
    });
  };

  const fetchSession = async (id) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/sessions/${id}`,
    });

    return data;
  };

  return (
    <>
      <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
        {({ items, reload }) => {
          return (
            <>
              <Header as="h2">
                <Layout horizontal spread>
                  {t('accountSessions.title', 'MSP Sessions')}
                  <Search.ExportRange
                    onRangeSubmit={async (from, to, filters) => {
                      await onDataNeeded({
                        format: 'csv',
                        limit: 100000,
                        endedAt: {
                          $gte: from,
                          $lte: to,
                        },
                        filename: safeFileName(`sessions-${account.id}`),
                        ...filters,
                      });
                    }}
                  />
                </Layout>
              </Header>
              <Search.Status
                noItems={t(
                  'accountSessions.noSessionYet',
                  'No charge sessions created yet'
                )}
              />
              {items.length > 0 && (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={2}>
                        {t('accountSessions.columnUserId', 'User / ID')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        {t('accountSessions.location', 'Location')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('accountSessions.columnDuration', 'Duration')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('accountSessions.columnTotalPrice', 'Total Price')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1}>
                        {t('accountSessions.columnKwh', 'kWh')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t('accountSessions.columnProvider', 'infraProviderId')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={3}>
                        {t('accountSessions.columnActions', 'Actions')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {items.map((item) => {
                      return (
                        <Table.Row key={item.id}>
                          <Table.Cell>
                            {item.user && item.user.name}
                            <p>
                              <Link to={`/cards/sessions/${item.id}`}>
                                <small title={item.externalUniqueId}>
                                  {item.externalUniqueId
                                    ? truncate(item.externalUniqueId, 20)
                                    : '-'}
                                </small>
                              </Link>
                            </p>
                          </Table.Cell>
                          <Table.Cell>
                            {formatLocationAddress(item.location)}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDuration(item.durationSeconds, t)}

                            <p>
                              <small>
                                {t('common.start', 'Start')}:{' '}
                                {formatDateTime(item.startedAt)}
                              </small>
                              <br />
                              <small>
                                {t('common.end', 'End')}:{' '}
                                {formatDateTime(item.endedAt)}
                              </small>
                            </p>
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <Session.Price withVAT session={item} />
                            <p>
                              <small>
                                {t('common.excl', 'Excl')}:{' '}
                                <Session.Price session={item} />
                              </small>
                              <br />
                              <small>
                                {item.invoiceId ||
                                item.debitInvoiceId ||
                                item?.debitInvoiceIds?.length
                                  ? '(invoiced)'
                                  : '(uninvoiced)'}
                              </small>
                            </p>
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatKwh(item.kwh)}
                          </Table.Cell>
                          <Table.Cell>
                            {item.externalProvider ? (
                              <span>
                                {item.externalProvider.name}{' '}
                                <Label
                                  content={item.infraProviderId}
                                  style={{ float: 'right' }}
                                />
                              </span>
                            ) : (
                              <Label content={item.infraProviderId} />
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <InspectObject
                              name="Session"
                              getData={() => fetchSession(item.id)}
                              trigger={<Button basic icon="file-code" />}
                            />

                            <ViewSession
                              getData={() => fetchSession(item.id)}
                              trigger={<Button basic icon="search" />}
                            />

                            <Confirm
                              header={t(
                                'deleteDialog.header',
                                'Are you sure you want to delete {{name}}?',
                                { name: item.id }
                              )}
                              content={t(
                                'deleteDialog.content',
                                'All data will be permanently deleted'
                              )}
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  title="Delete"
                                  disabled={!provider.enableMspSessionDeletion}
                                />
                              }
                              onConfirm={async () => {
                                await onDelete(item);
                                await reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Search.Pagination />
              </div>
            </>
          );
        }}
      </Search.Provider>
    </>
  );
};

export default AccountSessions;
