import React from 'react';

import { Statistic, Divider, Segment } from 'semantic';

import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';

import { ErrorMessage, Layout } from 'components';
import { formatNumber } from 'utils/formatting';

class MyMspUsageStats extends React.Component {
  state = {
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.fetchStats();
  }

  fetchStats = async () => {
    this.setState({ loading: true, error: null });
    try {
      const { data } = await request({
        method: 'GET',
        path: '/1/sessions/mine/stats',
      });
      this.setState({ loading: true, error: null, stats: data });
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  render() {
    const { t } = this.props;

    const { stats, loading, error } = this.state;
    if (error) return <ErrorMessage error={error} />;
    if (!loading || !stats?.total) return null;

    return (
      <>
        <Segment className="stats-container">
          {stats?.total > 0 && (
            <Layout
              style={{
                padding: '1em 4em',
              }}
              horizontal
              spread
              stackable
              padded>
              <Statistic
                label={t('stats.labelSession', 'Sessions')}
                value={formatNumber(stats.total)}
              />
              <Statistic
                label={t('stats.labelKwH', 'KwH')}
                value={formatNumber(Math.round(stats.totalKwh))}
              />
              <Statistic
                label={t('stats.labelHours', 'Hours')}
                value={formatNumber(
                  Math.round(stats.totalDurationSeconds / 3600)
                )}
              />
            </Layout>
          )}
        </Segment>

        <Divider />
      </>
    );
  }
}

export default withTranslation()(MyMspUsageStats);
