import React from 'react';
import { Form } from 'semantic';
import SearchDropdown from '../SearchDropdown';
import { omit } from 'lodash-es';

export default class FormSearchDropdown extends React.Component {
  render() {
    const { label, required, description, ...props } = omit(
      this.props,
      Form.Field.propTypes
    );
    return (
      <Form.Field required={required}>
        <label>{label}</label>
        {description && (
          <div style={{ paddingBottom: 10, fontSize: 12, color: '#696969' }}>
            <em>{description}</em>
          </div>
        )}
        <SearchDropdown fluid {...props} />
      </Form.Field>
    );
  }
}
