import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Dropdown, Label, Popup } from 'semantic';
import HelpTip from 'components/HelpTip';
import { Search } from 'components';
import Actions from './Actions';
import { formatDateTime } from 'utils/date';

export default function EnterpriseAccountMigrationsTable() {
  const { t } = useTranslation();
  const { items, loading } = useContext(Search.Context);

  if (!items.length || loading) return null;

  function resolveTooltipMessage(item) {
    switch (item.status) {
      case 'confirmationPending':
        return t(
          'enterpriseAccountMigrations.tooltip.status.confirmationPending',
          'This migration has not started yet. It is awaiting confirmation by a super admin.'
        );
      case 'completed':
        return t(
          'enterpriseAccountMigrations.tooltip.status.completed',
          'This migration has been completed. This is the final state in a migration lifecycle.'
        );
      case 'rejected':
        return t(
          'enterpriseAccountMigrations.tooltip.status.rejected',
          'This migration has been rejected. No further actions are possible. Request a new migration to attempt to migrate this account again.'
        );
      case 'accepted':
        if (item.executions[0]?.numberOfDocuments === 0) {
          return t(
            'enterpriseAccountMigrations.tooltip.status.readyForReview',
            "This migration has been executed and is ready for review. The migration status will be automatically updated to 'completed' once all the entities are migrated."
          );
        }
        if (item.executions.length > 0) {
          return t(
            'enterpriseAccountMigrations.tooltip.status.inProgress',
            "This migration has been accepted and is in progress. The migration status will be automatically updated as 'completed' once all entities have been migrated."
          );
        }
        return t(
          'enterpriseAccountMigrations.tooltip.status.accepted',
          'This migration has been accepted and was added to the migrations queue.'
        );
      default:
        return t(
          'enterpriseAccountMigrations.tooltip.status.unknown',
          'This migration state is unknown.'
        );
    }
  }

  function mapMigrationContext(context) {
    switch (context) {
      case 'enterpriseAccounts':
        return 'Enterprise Account';
      case 'resellerProviders':
        return 'Reseller Provider';
      default:
        return context;
    }
  }

  function mapStatus(item) {
    switch (item.status) {
      case 'confirmationPending':
        return {
          text: t(
            'enterpriseAccountMigrations.status.confirmationPending',
            'Confirmation Pending'
          ),
          color: 'yellow',
        };
      case 'completed':
        return {
          text: t('enterpriseAccountMigrations.status.completed', 'Completed'),
          color: 'green',
        };
      case 'rejected':
        return {
          text: t('enterpriseAccountMigrations.status.rejected', 'Rejected'),
          color: 'red',
        };

      case 'accepted':
        if (item.executions[0]?.numberOfDocuments === 0) {
          return {
            text: t(
              'enterpriseAccountMigrations.status.readyForReview',
              'Ready for Review'
            ),
            color: 'orange',
          };
        }

        if (item.executions.length > 0) {
          return {
            text: t(
              'enterpriseAccountMigrations.status.inProgress',
              'In Progress'
            ),
            color: 'yellow',
          };
        }

        return {
          text: t('enterpriseAccountMigrations.status.accepted', 'Accepted'),
          color: 'blue',
        };

      default:
        return {
          text: t('enterpriseAccountMigrations.status.unknown', 'Unknown'),
          color: 'grey',
        };
    }
  }

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>
              {t(
                'enterpriseAccountMigrations.columnHumanID',
                'Account to Migrate'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('enterpriseAccountMigrations.columnName', 'Migrate To')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>Migration Type</Table.HeaderCell>
            <Table.HeaderCell>
              {t('enterpriseAccountMigrations.columnCreated', 'Created')}
              <HelpTip
                title={t(
                  'enterpriseAccountMigrations.columnCreated',
                  'Created'
                )}
                text={t(
                  'enterpriseAccountMigrations.columnCreatedToolTip',
                  'This is the date and time the Account Migration was created.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('enterpriseAccountMigrations.Status', 'Status')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('enterpriseAccountMigrations.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const { text, color } = mapStatus(item);
            const tooltipMessage = resolveTooltipMessage(item);

            return (
              <Table.Row key={item.id}>
                <Table.Cell>{item.migrationProperties.accountName}</Table.Cell>
                <Table.Cell>
                  {item.migrationProperties.newProviderName}
                  <p>
                    <small>{item.migrationProperties.newProviderId}</small>
                  </p>
                </Table.Cell>
                <Table.Cell>{mapMigrationContext(item.context)}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                <Table.Cell>
                  <Popup
                    position="bottom center"
                    content={tooltipMessage}
                    trigger={<Label color={color} content={text} />}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions item={item} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
