import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useSuboperatorValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    suboperatorSettings: Yup.object().shape({
      name: Yup.string()
        .required(
          t(
            'validations.suboperator.error',
            'Please enter your suboperator name'
          )
        )
        .min(
          2,
          t(
            'validations.suboperator.name',
            'Name must have at least 2 characters'
          )
        ),
    }),
  });
};
