import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, Divider, Icon, Button, Message } from 'semantic';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'contexts/theme';
import SettingsShell from '../../components/wrappers/SettingsShell';
import ThemeSwitchCard from '../../components/molecules/ThemeSwitchCard';
import { Group } from 'components/Layout/helpers';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
} from '@tanstack/react-query';

export default function Appearance() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);
  useEffect(() => () => queryClient.clear(), []);

  return (
    <QueryClientProvider client={queryClient}>
      <AppearanceLayout />
    </QueryClientProvider>
  );
}

function AppearanceLayout() {
  const { t } = useTranslation();
  const { theme: currentTheme, setTheme } = useTheme();
  const [localTheme, setLocalTheme] = useState(currentTheme);
  const [success, setSuccess] = useState(false);
  const ref = useRef(currentTheme);

  /*
   * This will discard the unsaved local theme changes
   * when the component unmounts
   */
  useEffect(() => {
    return () => setTheme(ref.current);
  }, []);

  const unsavedNewTheme = (newTheme: any) => {
    setSuccess(false);
    setTheme(newTheme);
    setLocalTheme(newTheme);
  };

  const onSaveMutation = useMutation(
    async () => {
      await request({
        method: 'PATCH',
        path: `/1/users/me`,
        body: {
          theme: localTheme,
        },
      });
    },
    {
      onSuccess: () => {
        // Persist saved theme into local storage
        setTheme(localTheme, true);
        ref.current = localTheme;
        setSuccess(true);
      },
    }
  );

  return (
    <SettingsShell>
      <Form onSubmit={() => onSaveMutation.mutate()}>
        <FormHeader
          size="large"
          pb={5}
          pt={5}
          text={t('appearance.title', 'Appearance')}
        />

        <Divider />
        {onSaveMutation.isError && (
          <Message
            error
            content={t('messages.error.appearance', '{{error}}', {
              error: (onSaveMutation.error as any)?.message,
            })}
          />
        )}
        {success && onSaveMutation.isSuccess && (
          <Message
            success
            content={t('messages.updated', 'Successfully updated!')}
          />
        )}

        <p style={{ maxWidth: 500, marginBottom: 0 }}>
          {t(
            'settings.personal.appearance.description',
            'Choose how the dashboard looks to you. Select a light or dark mode, or sync with your system and automatically switch between light and dark mode.'
          )}
        </p>

        <FormVerticalSpace size={10} />
        <Group>
          <Form.Checkbox
            checked={localTheme === 'system'}
            onChange={(e, { checked }) =>
              unsavedNewTheme(checked ? 'system' : 'light')
            }
            toggle
          />
          <Group>
            <Icon name="sun" size="large" />
            <p style={{ fontWeight: 'bold' }}>
              {t('appearance.sync', 'Sync Mode With Operating System')}
            </p>
          </Group>
        </Group>
        <p>
          {t(
            'settings.personal.appearance.sync.subheader',
            'Automatically switch between light and dark themes when your system does.'
          )}
        </p>

        <Divider hidden />
        <FormHeader
          size="small"
          text={t('settings.personal.appearance.buttonsTitle', 'Select Theme')}
          pb={10}
        />
        <Group>
          <div className="diurnal-theme">
            <ThemeSwitchCard
              staticThemeClassName={'diurnal-theme'}
              theme={localTheme}
              targetTheme="light"
              updateTheme={unsavedNewTheme}
              iconName="regular sun"
              label={t('settings.appearance.light', 'Light Mode')}
            />
          </div>
          <div className="nocturnal-theme">
            <ThemeSwitchCard
              staticThemeClassName="nocturnal-theme"
              theme={localTheme}
              targetTheme="dark"
              updateTheme={unsavedNewTheme}
              iconName="moon"
              label={t('settings.appearance.dark', 'Dark Mode')}
            />
          </div>
        </Group>

        <Divider hidden />
        <div>
          <Button
            primary
            content={t('settings.themeSwitch.update', 'Update Appearance')}
            loading={onSaveMutation.isLoading}
            disabled={onSaveMutation.isLoading}
          />
        </div>
      </Form>
    </SettingsShell>
  );
}
