import React, { useState, useEffect } from 'react';
import { request } from 'utils/api';
import SyncLogTable from 'components/tables/SyncLogTable';
import DataList from 'components/DataList';
import { Grid, Segment } from 'semantic';
import Filters from './SyncLogFilters';

const itemLimit = 1000;

export default function Logs(props) {
  const [itemId, setItemId] = useState(props.match.params.id);
  const [dateKey, setDateKey] = useState(Date.now());
  const [tags, setTags] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchTags();
    // TODO: Add a clean up function to avoid memory leaks
  }, []);

  const refresh = () => {
    setDateKey(Date.now());
  };

  const fetchTags = async () => {
    const { lockOcpiCredential } = props;
    const { data } = await request({
      method: 'GET',
      path: lockOcpiCredential
        ? `/1/ocpi-credentials/mine/${itemId}/sync-logs/search`
        : '/1/sync-log/tags',
    });
    setTags(data.tags);
  };

  const handleRequest = (filters = {}) => {
    const { lockOcpiCredential } = props;
    const searchId = itemId;
    return request({
      method: 'POST',
      path: lockOcpiCredential
        ? `/1/ocpi-credentials/mine/${itemId}/sync-logs/search`
        : '/1/sync-log/search',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...filters,
        limit: itemLimit,
        ...(lockOcpiCredential ? {} : { ocpiCredentialId: itemId }),
      },
    });
  };

  const handleFilterChange = (filters) => {
    setFilters(filters);
    refresh();
  };

  return (
    <>
      <Segment>
        <Grid>
          <Grid.Row width={16}>
            <Grid.Column width={10}>
              <Filters onChange={handleFilterChange} tags={tags}></Filters>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <DataList key={dateKey} limit={itemLimit} onRequest={handleRequest}>
        <SyncLogTable onRefresh={refresh} />
      </DataList>
    </>
  );
}
