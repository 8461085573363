import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, Route, Switch, useParams } from 'react-router-dom';
import { Container, Header, Menu } from 'semantic';
import InvoiceLogDetailDiff from './Diff';
import InvoiceLogDetaiEvents from './Events';
import Breadcrumbs from 'components/Breadcrumbs';

export default function InvoiceLogDetail() {
  const { t } = useTranslation();
  const { invoiceLogId } = useParams();

  return (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="finance" to={'/finance'}>
            {t('finance.title', 'Finance')}
          </Link>,
        ]}
        active={t('invoiceLogs.page.title', 'Invoice Event Log')}
      />
      <Header size="large">
        {t('invoiceLogs.header', 'Invoice Event Log')}
      </Header>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('invoiceLogs.menu.events', 'Events Summary')}
          to={`/finance/invoices-event-logs/${invoiceLogId}/events`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('invoiceLogs.menu.diff', 'Diff Comparison')}
          to={`/finance/invoices-event-logs/${invoiceLogId}/diff`}
          as={NavLink}
        />
      </Menu>

      <Switch>
        <Route
          path={`/finance/invoices-event-logs/:invoiceLogId/events`}
          exact
          component={() => <InvoiceLogDetaiEvents />}
        />
        <Route
          path={`/finance/invoices-event-logs/:invoiceLogId/diff`}
          exact
          component={() => <InvoiceLogDetailDiff />}
        />
      </Switch>
    </Container>
  );
}
