import React from 'react';
import { Breadcrumb, Grid, Header } from 'semantic';
import { request } from 'utils/api';
import ExploreMap from 'components/maps/ExploreMap';

import AppWrapper from 'components/AppWrapper';

import { useTranslation } from 'react-i18next';
import Map from 'components/Map';

import { useFeatures } from 'contexts/features';

export default function LocationsMap() {
  const { t } = useTranslation();

  const { hasFeature } = useFeatures();

  return (
    <AppWrapper fluid>
      <div style={{ marginBottom: '20px' }}>
        <Breadcrumb size="mini">
          <Breadcrumb.Section active>
            {t('menu.home', 'Home')}
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <Grid reversed="computer">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            {t(
              'dashboard.headerLocations',
              'Check out where you can use your card'
            )}
          </Header>
        </Grid.Column>
      </Grid>
      <div style={{ marginTop: '10px' }} />
      {hasFeature('map-v3') ? (
        <Map />
      ) : (
        <ExploreMap
          search
          fetchData={({ geoBox }) =>
            request({
              method: 'POST',
              path: '/2/locations/msp/search',
              body: {
                sort: { field: 'updatedAt', order: 'asc' },
                geoBox,
                limit: 500,
              },
            })
          }
        />
      )}
    </AppWrapper>
  );
}
