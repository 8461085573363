import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { request } from 'utils/api';
import { Form } from 'semantic';

const PublicLocations = (props) => {
  const { valueField, textField, clearable, fetchData, ...restProps } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (props.evseId) {
      // Locations search is fuzzy... perform client side filtering to narrow down results
      const filterEvse = (evse) =>
        evse?.normalizedEvseId?.toLowerCase() === props.evseId.toLowerCase();
      const filterConnector = (connector) =>
        !props.connectorId || connector?.id === props.connectorId;
      findLocations(props.evseId, filterEvse, filterConnector).then(
        (newOptions) => {
          // If there is exactly one match, select it
          if (newOptions.length === 1) {
            props.onChange(null, { value: newOptions[0].value });
          }
          // If there are multiple matches, focus the input to prompt the user to select
          if (newOptions.length > 1) {
            setFocus(true);
          }
        }
      );
    }
  }, [props.evseId]);

  const findLocations = useCallback(
    (searchPhrase, filterEvse = () => true, filterConnector = () => true) => {
      setLoading(true);
      setError(false);

      return request({
        path: '/1/remote-sessions/locations/search/fast',
        method: 'POST',
        body: { searchPhrase: searchPhrase.toLowerCase() },
      })
        .then(({ data }) => {
          const newOptions = [];
          data.forEach((item) => {
            item.evses.forEach((evse) => {
              if (!filterEvse(evse)) {
                return;
              }
              evse.connectors.forEach((connector) => {
                if (!filterConnector(connector)) {
                  return;
                }
                const key = `${item.id}:${evse.evse_id}:${evse.evseControllerId}:${connector.id}`;
                newOptions.push({
                  text: `${evse.evse_id} - Connector ${connector.id} - ${item.name}`,
                  value: key,
                  key,
                  evse,
                  connector,
                });
              });
            });
          });
          setOptions(newOptions);
          setLoading(false);
          return newOptions;
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    },
    []
  );

  const findLocationsDebounced = useCallback(debounce(findLocations, 1000), [
    findLocations,
  ]);

  const onHandleSearchChange = useCallback(
    (e, { searchQuery }) => {
      if (searchQuery.length) {
        findLocationsDebounced(searchQuery);
      } else {
        setOptions([]);
      }
    },
    [findLocationsDebounced]
  );

  return (
    <Form.Dropdown
      key={`public-locations-${focus}`}
      searchInput={{ autoFocus: focus }}
      error={error}
      required={true}
      name="load"
      search={() => options}
      selection
      clearable={clearable}
      loading={loading}
      options={options}
      onSearchChange={onHandleSearchChange}
      {...restProps}
    />
  );
};

PublicLocations.defaultProps = {
  valueField: 'id',
  textField: 'name',
};

export default PublicLocations;
