import React from 'react';
import PropTypes from 'prop-types';
import bem from 'helpers/bem';

class PortalNavMobileSidebar extends React.Component {
  getModifiers() {
    const { dark } = this.props;
    const { open } = this.context;
    return [dark ? 'dark' : null, open ? 'open' : null];
  }

  render() {
    const Element = this.props.as;
    return (
      <Element className={this.getBlockClass()} style={this.props.style}>
        {this.props.children}
      </Element>
    );
  }
}

PortalNavMobileSidebar.propTypes = {
  dark: PropTypes.bool,
  as: PropTypes.elementType,
};

PortalNavMobileSidebar.defaultProps = {
  dark: false,
  as: 'nav',
};

export default bem(PortalNavMobileSidebar);
