import React, { useEffect, useState } from 'react';
import AppWrapper from 'components/AppWrapper';
import { request } from '../../utils/api';
import { Divider, Container, Menu } from 'semantic';
import { Switch, Route, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isSandboxMode } from '../../utils/sandbox';
import { SANDBOX_API_URL } from '../../utils/env';
import SandboxMain from './Main';
import SandboxCpo from './CPO';
import SandboxMsp from './MSP';

const SandboxDashboard = () => {
  const sandboxMode = isSandboxMode();
  if (!sandboxMode) {
    return null;
  }

  const activeApiUrl = SANDBOX_API_URL;
  const [credentials, setCredentials] = useState([]);

  useEffect(() => {
    request({
      method: 'POST',
      path: '/1/credentials/search',
    }).then(({ data }) => setCredentials(data));
  }, []);

  const cpoSessionCredential = credentials.find(
    (credential) => credential.permissions.cpoSessions.substr(0, 4) === 'read'
  );

  const { t } = useTranslation();
  return (
    <AppWrapper>
      <Container>
        <Menu stackable secondary pointing>
          <Menu.Item
            exact
            as={NavLink}
            name={t('sandbox.sandboxDashboard', 'Sandbox')}
            to={'/sandbox'}
          />
          <Menu.Item
            exact
            as={NavLink}
            name={t('sandbox.menuCpo', 'CPO')}
            to={'/sandbox/cpo'}
          />
          <Menu.Item
            exact
            as={NavLink}
            name={t('sandbox.menuMsp', 'MSP')}
            to={'/sandbox/msp'}
          />
        </Menu>
        <Divider hidden />
        <Switch>
          <Route
            path="/sandbox"
            item={t('sandbox.sandboxDashboard', 'Sandbox')}
            render={() => (
              <SandboxMain
                url={activeApiUrl}
                credentials={cpoSessionCredential}
              />
            )}
            exact
          />
          <Route
            path="/sandbox/cpo"
            item={t('sandbox.menuCpo', 'CPO')}
            render={() => (
              <SandboxCpo
                url={activeApiUrl}
                credentials={cpoSessionCredential}
              />
            )}
            exact
          />
          <Route
            path="/sandbox/msp"
            item={t('sandbox.menuMsp', 'MSP')}
            render={() => (
              <SandboxMsp
                url={activeApiUrl}
                credentials={cpoSessionCredential}
              />
            )}
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
};

export default SandboxDashboard;
