import React from 'react';
import { Header, Table, Loader, Message, Divider } from 'semantic';
import { request } from 'utils/api';
import OcpiStats from 'components/OcpiStats';
import { formatDateTime } from 'utils/date';

export default class Overview extends React.Component {
  state = {};
  loadStats = async () => {
    this.setState({
      loading: true,
      error: null,
    });

    try {
      const { data: stats } = await request({
        method: 'GET',
        path: `/1/ocpi-credentials/mine/${this.props.ocpiCredential.id}/stats`,
      });
      this.setState({
        loading: false,
        stats,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
      });
    }
  };

  componentDidMount = () => {
    this.loadStats();
  };

  renderStatistics() {
    const { error, loading, stats } = this.state;
    const { ocpiCredential } = this.props;
    if (error) return <Message error content={error.message} />;
    if (loading || !stats) return <Loader active inline />;
    return <OcpiStats stats={stats} ocpiCredential={ocpiCredential} />;
  }

  render() {
    const { ocpiCredential } = this.props;
    return (
      <div>
        <Header as="h3">Statistics</Header>
        {this.renderStatistics()}
        <Divider hidden />
        <Header as="h3">Details</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>ID</Table.Cell>
              <Table.Cell>{ocpiCredential.id}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{ocpiCredential.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Context</Table.Cell>
              <Table.Cell>{ocpiCredential.providerContext}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{ocpiCredential.type}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Account</Table.Cell>
              <Table.Cell>
                {ocpiCredential.account ? ocpiCredential.account.name : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Versions URL</Table.Cell>
              <Table.Cell>{ocpiCredential.versionsUrl}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>
                {formatDateTime(ocpiCredential.createdAt)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>
                {formatDateTime(ocpiCredential.updatedAt)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }
}
