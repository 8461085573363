import React from 'react';
import Favicon from 'react-favicon';
import { useUser } from 'contexts/user';

export default () => {
  const { provider } = useUser();
  if (provider && provider.name) {
    document.title = provider.name;
  }
  if (provider && provider.faviconUrl) {
    return <Favicon url={provider.faviconUrl} />;
  }
  return <span />;
};
