import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';

import { Table, Loader, Segment, Dimmer, Message, Button } from 'semantic';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;
import { withTranslation } from 'react-i18next';

class DataTable extends React.Component {
  toggleAll(items, checked) {
    items.forEach((item) => {
      this.props.onToggleItem(item.id, checked);
    });
  }
  render() {
    const { status, data: items, meta = {}, page, t } = this.props;

    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>
            {t('importBatches.noBatchesYet', 'No import batches yet')}
          </Message>
        )}

        {status.success && items.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t('importBatches.columnImportBatchId', 'Import Batch ID')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('importBatches.numberOfSessions', 'Number of Sessions')}
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  {t('importBatches.columnActions', 'Actions')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.id}</Table.Cell>
                    <Table.Cell textAlign="right">{item.total}</Table.Cell>
                    <Table.Cell textAlign="center"></Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Loader active>{t('loading.loading')}</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}

export default withTranslation()(DataTable);
