import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import List from './ui/Search';
import { AppWrapper } from 'components';
import { Container, Header } from 'semantic';
import InvoiceLogDetail from './ui/Detail';
import { useFeatures } from 'contexts/features';
import { useTranslation } from 'react-i18next';

export default function InvoicesEventLog({
  prefixPath = '/finance',
  invoicesPath = '/finance/invoices-event-logs',
  detailPath = '/finance/invoices-event-logs/:invoiceLogId',
}) {
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const isBillingAndSettlementInvoiceEventLogsEnabled = hasFeature(
    'billing_and_settlement_invoice_event_logs'
  );

  if (!isBillingAndSettlementInvoiceEventLogsEnabled) {
    return (
      <AppWrapper>
        <Header as="h2">{t('errorPage.header', 'Oops!')}</Header>
        <p>
          {t(
            'errorPage.body',
            "The page you're looking for can't be found. Please check the URL or "
          )}
          <Link to="/">{t('errorPage.link', 'head back to our homepage')}</Link>
        </p>
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route
            path={`${detailPath}/*`}
            component={() => <InvoiceLogDetail />}
          />
          <Route
            path={`${detailPath}`}
            exact
            component={() => <InvoiceLogDetail />}
          />
          <Route
            path={invoicesPath}
            render={(props) => {
              return <List {...props} prefixPath={prefixPath} />;
            }}
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
