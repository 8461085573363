import React from 'react';

import { Checkbox, Form, Grid, Icon, Modal, Button } from 'semantic';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { startOfMonth, endOfMonth } from 'date-fns';

function getLevelOptions() {
  return [
    {
      key: 0,
      text: 'All levels',
      value: 'all',
    },
    {
      key: 1,
      text: 'Error',
      value: 'error',
    },
    {
      key: 2,
      text: 'Warning',
      value: 'warning',
    },
    {
      key: 3,
      text: 'Info',
      value: 'info',
    },
  ];
}

function getTagOptions(tags) {
  return [
    {
      key: 0,
      text: 'All tags',
      value: 'all',
    },
    ...tags.map((tagName, i) => ({
      key: i + 1,
      text: tagName,
      value: tagName,
    })),
  ];
}

class Filters extends React.Component {
  state = {
    open: false,
    appliedFilters: [],
    level: '',
    selectedTag: '',
    useAllTime: true,
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  };

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  handleReset = () => {
    this.props.onChange([]);
    this.setState({
      appliedFilters: [],
    });
    this.close();
  };

  handleApply = () => {
    const filters = {};
    if (this.state.level !== 'all' && this.state.level !== '') {
      filters['type'] = this.state.level;
    }
    if (this.state.selectedTag !== 'all' && this.state.selectedTag !== '') {
      filters['tag'] = this.state.selectedTag;
    }
    if (!this.state.useAllTime) {
      filters['from'] = this.state.from;
      filters['to'] = this.state.to;
    }
    this.props.onChange(filters);
    this.close();
  };

  render() {
    const { from, to } = this.state;
    const { t } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        <Modal
          size="tiny"
          open={this.state.open}
          onOpen={this.open}
          onClose={this.close}
          trigger={
            <Button basic primary={true} floated="left">
              <Icon name="filter" /> Filters
            </Button>
          }
          closeIcon>
          <Modal.Header>Filters</Modal.Header>
          <Modal.Content>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>
                      <Checkbox
                        onChange={(e, { checked }) => {
                          this.setState({
                            useAllTime: checked,
                          });
                        }}
                        toggle
                        checked={this.state.useAllTime}
                        label="All Time"
                      />
                    </Form.Field>
                    <div
                      onClick={() =>
                        this.setState({
                          useAllTime: false,
                        })
                      }>
                      <Form.Field disabled={this.state.useAllTime}>
                        <label>From</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={from}
                          onChange={(date) => this.setState({ from: date })}
                          selectsStart
                          startDate={from}
                          endDate={to}
                        />
                      </Form.Field>
                      <Form.Field disabled={this.state.useAllTime}>
                        <label>To</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={to || new Date()}
                          onChange={(date) => this.setState({ to: date })}
                          selectsEnd
                          startDate={from}
                          endDate={to}
                          minDate={from}
                        />
                      </Form.Field>
                    </div>

                    <Form.Select
                      value={this.state.level}
                      options={getLevelOptions()}
                      label={t('synclogIssues.level', 'Level')}
                      onChange={(_, { value }) =>
                        this.setState({ level: value })
                      }
                      style={{ width: 'auto', marginRight: '1em' }}
                    />

                    <Form.Select
                      value={this.state.selectedTag}
                      options={getTagOptions(this.props.tags)}
                      label={t('synclogIssues.tags', 'Tags')}
                      type="text"
                      style={{ width: 'auto' }}
                      onChange={(_, { value }) =>
                        this.setState({ selectedTag: value })
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>

          <Modal.Actions>
            <Button basic content="Reset" onClick={this.handleReset} />
            <Button
              primary
              icon="check"
              content="Apply"
              onClick={this.handleApply}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(Filters);
