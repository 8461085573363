import {
  SettingsActiveItem,
  SettingsMenuItemProps,
} from 'types/account-settings';
import SettingsMenu from '../molecules/SettingsMenu';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'contexts/account';

export default function AccountSettingsMenu(
  { activeItem }: SettingsActiveItem,
  canViewBilling: boolean
) {
  const { t } = useTranslation();
  const { account } = useAccount();

  const showCurrentPlanUnderBilling = Boolean(
    account?.accountTierBillingPlanId
  );

  const accountSettings: SettingsMenuItemProps[] = [
    {
      title: showCurrentPlanUnderBilling
        ? t('settings.sidebar.personal.billingAndPlans', 'Billing & Plans')
        : t('settings.sidebar.personal.billing', 'Billing'),
      route: '/settings/account/individual/billing',
      iconName: 'regular credit-card',
    },
  ];

  return SettingsMenu({
    header: t('settings.sidebar.individual.title', 'Account'),
    menuItems: accountSettings,
    activeItem,
  });
}
