import React from 'react';
import { formatDateTime } from 'utils/date';

import { Link } from 'react-router-dom';

import { currentUserCanAccess } from 'utils/roles';

import { Table, Label } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Search } from 'components';
import Currency from 'components/Currency';

import { DetailItem, paymentMethodText } from '../common';

export default function MyPaymentFeesTable() {
  const { items, loading, setSort, getSorted } = React.useContext(
    Search.Context
  );
  const { t } = useTranslation();

  if (!items.length || loading) return null;

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('my-payment-fees.columnPaymentId', 'Payment ID')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSorted('amountCaptured')}
              onClick={() => setSort('amountCaptured')}>
              {t('my-payment-fees.columnPaymentsAmount', 'Amount')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('my-payment-fees.columnPaymentMethod', 'Payment Method')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSorted('feeExclVat')}
              onClick={() => setSort('feeExclVat')}>
              {t('my-payment-fees.columnFeeExclVAT', 'Fee (Excl VAT)')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSorted('feeInclVat')}
              onClick={() => setSort('feeInclVat')}>
              {t('my-payment-fees.columnFeeInclVat', 'Fee (Incl VAT)')}
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSorted('createdAt')}
              onClick={() => setSort('createdAt')}>
              {t('my-payment-fees.createdDate', 'Created')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item: DetailItem) => (
            <Table.Row key={item.payment.paymentId}>
              <Table.Cell>
                <Link to={`/my-payment-fees/${item.payment.paymentId}`}>
                  {item.payment.paymentId}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Currency
                  currency={item.payment.currency}
                  value={item.payment.capturedAmount}
                />
              </Table.Cell>
              <Table.Cell>
                {<Label>{paymentMethodText(item.payment.paymentMethod)}</Label>}
              </Table.Cell>
              <Table.Cell>
                <Currency
                  currency={item.totalFee.currency}
                  value={item.totalFee.amountExclVat}
                />
              </Table.Cell>
              <Table.Cell>
                <Currency
                  currency={item.totalFee.currency}
                  value={item.totalFee.amountInclVat}
                />
              </Table.Cell>
              <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
