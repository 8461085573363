import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 8px;
  position: absolute;
  z-index: 100;

  input {
    width: 280px;
  }

  .ui.input.input.input.input input {
    border: 1px solid rgba(34, 36, 38, 0.15);
    background: #fff;
    color: rgba(0, 0, 0, 0.78);

    &:focus {
      border-color: #85b7d9;
      background: #fff;
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

export default class SearchBox extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setup();
  }

  setup() {
    const { map, mapApi } = this.props;
    if (!mapApi?.places?.SearchBox) {
      return setTimeout(() => this.setup(), 1000);
    }
    this.searchBox = new mapApi.places.SearchBox(this.searchInput);
    this.searchBox.addListener('places_changed', this.onPlacesChanged);
    this.searchBox.bindTo('bounds', map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  onPlacesChanged = ({ map, addplace } = this.props) => {
    const selected = this.searchBox.getPlaces();
    const [place] = selected || [];
    if (!place?.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }
    addplace(selected);
    this.searchInput.blur();
  };

  clearSearchBox = () => {
    this.searchInput.value = '';
  };

  render() {
    return (
      <Wrapper>
        <div className="ui active visible magnifying-glass">
          <div className="ui icon input wide">
            <input
              ref={(ref) => {
                this.searchInput = ref;
              }}
              type="text"
              onFocus={this.clearSearchBox}
              placeholder="Enter a location"
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}
