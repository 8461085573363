import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FeatureFlag from 'components/FeatureFlag';

import { currentUserCanAccessAccountEndpoint } from 'utils/roles';
import { useUser } from 'contexts/user';
import { isSuperAdmin } from 'utils/roles';
import {
  hasPaymentTerminalPermissions,
  hasPaymentTerminalPlatformFeature,
} from './PaymentTerminals/utils';

export default function LocationsMenu({ itemId }) {
  const { t } = useTranslation();
  const { hasPlatformFeature, user, provider } = useUser();
  const hasPaymentTerminalFeature = hasPaymentTerminalPlatformFeature();

  const hasAnalytics =
    hasPlatformFeature('analytics:account') ||
    isSuperAdmin(user) ||
    currentUserCanAccessAccountEndpoint('evseControllersMaintenance', 'read') ||
    currentUserCanAccessAccountEndpoint('locationsFacilitator', 'read');

  const hasPaymentTerminals =
    hasPaymentTerminalFeature &&
    hasPaymentTerminalPermissions(user, 'read', provider);

  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('locationsMenu.overview', 'Overview')}
          to={`/charging-stations/locations/${itemId}`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.connectors', 'Connectors')}
          to={`/charging-stations/locations/${itemId}/connectors`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.configuration', 'Configuration')}
          to={`/charging-stations/locations/${itemId}/config`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.sessions', 'Sessions')}
          to={`/charging-stations/locations/${itemId}/sessions`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.controllers', 'EVSE Controllers')}
          to={`/charging-stations/locations/${itemId}/evse-controllers`}
          as={NavLink}
        />

        {hasPaymentTerminals && (
          <Menu.Item
            exact
            content={t('locationsMenu.paymentTerminals', 'Payment Terminals')}
            to={`/charging-stations/locations/${itemId}/payment-terminals`}
            as={NavLink}
          />
        )}

        {hasAnalytics && (
          <Menu.Item
            exact
            content={t('locationsMenu.analytics', 'Analytics')}
            to={`/charging-stations/locations/${itemId}/analytics`}
            as={NavLink}
          />
        )}

        {/* TODO(ggordan): this feature flag should not be inverted  */}
        <FeatureFlag feature="roaming_activity">
          <Menu.Item
            exact
            content={t('locationsMenu.roamingActivity', 'Roaming activity')}
            to={`/charging-stations/locations/${itemId}/roaming`}
            as={NavLink}
          />
        </FeatureFlag>
      </Menu>
    </Container>
  );
}
