type AuthenticatedMessage = 'authorized' | 'sso-token-expired';

type AuthenticatedSubscriber = (authorized: AuthenticatedMessage) => void;

const subscribers: AuthenticatedSubscriber[] = [];

export function emitAuthenticationBusMessage(msg: AuthenticatedMessage) {
  subscribers.forEach((sub) => sub(msg));
}

export function subscribeToAuthenticationBus(
  subscriber: AuthenticatedSubscriber
) {
  subscribers.push(subscriber);
}
