import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic';
import { request } from 'utils/api';

export default function ConnectorSelector(props = {}) {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch();
  }, [props.evseControllerId]);

  const fetch = () => {
    const { evseControllerId } = props;
    setLoading(true);

    request({
      method: 'GET',
      path: `/1/evse-controllers/${evseControllerId}`,
    })
      .then(({ data }) => {
        setResult(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  let options = [];
  if (result) {
    options = result.connectors.map(({ connectorId, evseId }) => {
      let text = `Connector ${connectorId}`;
      if (evseId) {
        text += ` (${evseId})`;
      }

      const connectorStatus = Object.values(result.connectorStatus)?.find(
        (cs) => cs.connectorId?.toString() === connectorId.toString()
      );

      return {
        value: {
          ocppConnectorId: connectorStatus?.ocppConnectorId?.toString(),
          ocppEvseId: connectorStatus?.ocppEvseId,
        },
        text,
        key: connectorStatus?.ocppEvseId
          ? `${connectorStatus.ocppEvseId}-${connectorId.toString()}`
          : connectorId.toString(),
      };
    });
  }

  return (
    <Dropdown
      error={error}
      selection
      clearable={props.clearable}
      loading={loading}
      options={options}
      {...props}
    />
  );
}
