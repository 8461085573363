import React from 'react';
import { Divider, Button } from 'semantic';
import { withTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Search,
  ListHeader,
  Layout,
  SearchFilters,
} from 'components';
import Table from './Table';
import { request } from 'utils/api';
import EditTariffNote from 'components/modals/EditTariffNote';
import { Segment } from '../../../semantic';

class TariffNotes extends React.Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/1/tariff-notes/search',
      body: filters,
    });
  };

  getFilterMapping() {
    const { t } = this.props;
    return {
      keywords: {
        type: 'search',
      },
      enabled: {
        label: t('tariffNotes.enabled', 'Enabled'),
        type: 'boolean',
      },
      createdAt: {
        label: t('common.createdAt'),
        type: 'date',
        range: true,
      },
    };
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Search.Provider
          ref={this.searchRef}
          onDataNeeded={this.onDataNeeded}
          filterMapping={this.getFilterMapping()}>
          {({ reload, onFilterChange, filters }) => (
            <>
              <Breadcrumbs active={t('tariffNotes.title', 'Tariff Notes')} />

              <ListHeader title={t('tariffNotes.title', 'Tariff Notes')}>
                <EditTariffNote
                  onSave={reload}
                  initialValues={{}}
                  trigger={
                    <Button
                      primary
                      content={t(
                        'tariffNotes.newTariffNote',
                        'New Tariff Note'
                      )}
                      icon="plus"
                    />
                  }
                />
              </ListHeader>

              <Segment>
                <Layout horizontal spread stackable>
                  <SearchFilters.Modal>
                    <SearchFilters.Checkbox
                      label={t('tariffNotes.filterEnabled', 'Enabled')}
                      name="enabled"
                    />
                    <Divider />
                    <SearchFilters.DateRange
                      label={t('tariffNotes.columnCreatedAt', 'Created At')}
                      name="createdAt"
                    />
                  </SearchFilters.Modal>

                  <Layout horizontal stackable center right>
                    <Search.Total />
                    <SearchFilters.Search
                      name="keywords"
                      placeholder={t(
                        'tariffNotes.filterKeywords',
                        'PartyId, CountryCodes, CustomNote or ID'
                      )}
                    />
                  </Layout>
                </Layout>
              </Segment>

              <p>
                {t(
                  'tariffNotes.description',
                  'These are custom tariff notes applicable to specific operators displayed in the locations map. You can use these to add additional information about the tariffs displayed in the map. For example, you can add a note that the charge location might have idle fees.'
                )}
              </p>

              <Search.Status
                noItems={t(
                  'tariffNotes.noTariffNotesYet',
                  'No tariff notes created yet'
                )}
              />
              <Table />
              <Divider hidden />
              <div style={{ textAlign: 'center' }}>
                <Search.Pagination />
              </div>
            </>
          )}
        </Search.Provider>
      </>
    );
  }
}

export default withTranslation()(TariffNotes);
