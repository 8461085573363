import React from 'react';
import { Form, Divider } from 'semantic';
import { format } from 'date-fns';

const createJobSelectionPage = (props) => {
  const {
    t,
    error,
    formValues,
    loading,
    setField,
    dateFilterTypes,
    mappedDropdownCollectionFields,
    collectionNames,
    onHandleSearchChange,
  } = props;

  return (
    <div>
      <Form.Dropdown
        error={error}
        required={true}
        name="Collection"
        label={t('financeExports.createJob.collection', 'Collection')}
        value={formValues.collectionName}
        search={() => collectionNames}
        selection
        loading={loading}
        options={collectionNames}
        onSearchChange={onHandleSearchChange}
        onChange={(e, { value }) => {
          setField('collectionName', value);
        }}
      />
      <Form.Dropdown
        error={error}
        required={true}
        name="Fields"
        label={t('financeExports.createJob.collectionFields', 'Fields')}
        search={() => mappedDropdownCollectionFields}
        value={formValues.collectionFields}
        selection
        multiple
        loading={loading}
        options={mappedDropdownCollectionFields}
        onSearchChange={onHandleSearchChange}
        onChange={(e, { value }) => {
          setField('collectionFields', value);
        }}
      />
      <Divider />
      <Form.Dropdown
        error={error}
        required={true}
        name="Fields"
        label={t('financeExports.createJob.dateFilterType', 'Date filter type')}
        search={() => dateFilterTypes}
        value={formValues.dateFilterTypes}
        selection
        multiple
        loading={loading}
        options={dateFilterTypes}
        onSearchChange={onHandleSearchChange}
        onChange={(e, { value }) => {
          setField('dateFilterTypes', value);
        }}
      />
      <Form.Input
        type="date"
        name="form"
        value={format(formValues.dateFrom, 'yyyy-MM-dd')}
        label={t('financeExports.createJob.dateFrom', 'From')}
        onChange={(e, { value }) => {
          setField('dateFrom', new Date(value));
        }}
      />
      <Form.Input
        type="date"
        label={t('financeExports.createJob.dateTo', 'To')}
        name="to"
        value={format(formValues.dateTo, 'yyyy-MM-dd')}
        onChange={(e, { value }) => setField('dateTo', new Date(value))}
      />
    </div>
  );
};

export default createJobSelectionPage;
