import React, { useEffect } from 'react';
import { Button, Message, Form } from 'semantic';
import { ProviderSocialLoginIssuer } from 'types/provider';
import { FieldArray, FieldValidator, Formik, useField } from 'formik';
import InputField from 'components/form-fields/formik/InputField';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import styles from './SocialLogin.module.css';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import SelectField from 'components/form-fields/formik/SelectField';
import { joiErrorDetailsToObject, request } from 'utils/api';
import { OID_REDIRECT_BASE_API_URL } from 'utils/env';
import { useParams } from 'react-router-dom';
import { useUser } from 'contexts/user';

const SocialProviderField: React.FC<{
  name: string;
  validate?: FieldValidator;
}> = ({ name, validate }) => {
  const { t } = useTranslation();
  const { provider } = useUser();
  const [field, meta, helpers] = useField({ name, validate });
  return (
    <div className={styles.container}>
      <Message info>
        <Message.Content>
          {t(
            'editProvider.issuerDescription',
            "Issuer is a unique identifier for the social login provider. The issuer discover URL is the URL that the OpenID Connect provider publishes to allow clients to automatically discover information about the provider. When the URL contains '.well-known', only that document is loaded, otherwise .well-known/openid-configuration will be appended to the URL."
          )}
        </Message.Content>
      </Message>
      <div className={styles.field}>
        <InputField
          name={`${name}.issuer`}
          label="Issuer (unique identifier)"
          required
          validate={validate}
        />
        <InputField
          name={`${name}.issuerDiscoverUrl`}
          required
          label={'Issuer discover URL'}
          validate={validate}
        />
      </div>

      <Message warning header="Redirect URI">
        <Message.Content>
          <ul>
            <li>
              When configuring your OpenID application, use{' '}
              <code>
                {new URL(
                  `/1/auth/${provider.slug}/${meta.value.issuer}/exchange`,
                  OID_REDIRECT_BASE_API_URL
                ).toString()}
              </code>{' '}
              as the redirect URI.
            </li>
          </ul>
        </Message.Content>
      </Message>

      <InputField
        name={`${name}.clientId`}
        label="Client ID"
        required
        validate={validate}
      />
      <InputField
        name={`${name}.clientSecret`}
        label="Client secret"
        required
        validate={validate}
      />
      <SelectField
        name={`${name}.scopes`}
        label="Scopes"
        required
        options={[
          {
            key: 'openid',
            text: 'OpenID',
            value: 'openid',
          },
          {
            key: 'profile',
            text: 'Profile',
            value: 'profile',
          },
          {
            key: 'email',
            text: 'Email',
            value: 'email',
          },
        ]}
        multiple
      />
      <SelectField
        name={`${name}.responseType`}
        label="Response type"
        required
        options={[
          {
            key: 'code',
            text: 'Code',
            value: 'code',
          },
        ]}
      />
      <SelectField
        name={`${name}.prompt`}
        label="Prompt (optional)"
        options={[
          {
            key: 'none',
            text: '',
            value: '',
          },
          {
            key: 'select_account',
            text: 'select_account',
            value: 'select_account',
          },
        ]}
      />
    </div>
  );
};

const ProviderSocialLogin: React.FC<never> = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [intialValue, setInitialValue] = React.useState<
    ProviderSocialLoginIssuer[]
  >([]);

  const name = 'socialLoginIssuers';

  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const { data } = await request({
          path: `/1/providers/${id}/configuration`,
          method: 'GET',
        });
        setInitialValue(data.socialLoginIssuers);
      } catch (e) {
        console.error('provider fetch console error', id, e);
      }
    };
    fetchInitialValue();
  }, [id]);

  const handleSubmit = async (
    formData: { socialLoginIssuers: any[] },
    formikBag: any
  ) => {
    try {
      await request({
        method: 'PATCH',
        path: `/1/providers/${id}/configuration`,
        body: formData,
      });
      setHasSubmitted(true);
    } catch (error: any) {
      setHasSubmitted(false);
      if (Array.isArray(error?.details)) {
        formikBag.setErrors(joiErrorDetailsToObject(error));
      } else {
        formikBag.setStatus(error?.message);
      }
    }
  };
  return (
    <div>
      <br />
      <Message info>
        <Message.Content>
          {t(
            'editProvider.socialLogin',
            'The form below allows you to configure the social login issues that are compatible with OpenID for this provider'
          )}
        </Message.Content>
      </Message>
      <Formik
        enableReinitialize
        initialValues={{
          socialLoginIssuers: intialValue,
        }}
        onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, status, isValid, dirty }) => (
          <Form>
            <FieldArray
              name={name}
              render={(helpers) => {
                const values = get(helpers.form.values, name);
                return (
                  <>
                    {values?.map(
                      (value: ProviderSocialLoginIssuer, i: number) => {
                        return (
                          <SocialProviderField key={i} name={`${name}.${i}`} />
                        );
                      }
                    )}
                    <div className={styles.add}>
                      <Button
                        as="button"
                        icon="plus"
                        label="Add social provider"
                        onClick={() => {
                          helpers.push({
                            scopes: ['openid'],
                            issuer: '',
                            issuerDiscoverUrl: '',
                            clientId: '',
                            clientSecret: '',
                            responseType: 'code',
                          });
                        }}
                      />
                    </div>
                  </>
                );
              }}
            />
            {status && (
              <Message error>
                <p>{status}</p>
              </Message>
            )}
            {hasSubmitted && (
              <Message success>
                <p>
                  {t(
                    'editProvider.socialLoginSuccess',
                    'Social login configuration updated successfully'
                  )}
                </p>
              </Message>
            )}
            <br />
            <Button
              onClick={handleSubmit}
              disabled={isSubmitting || !isValid || !dirty}>
              {t('editProvider.socialLoginSave', 'Update')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProviderSocialLogin;
