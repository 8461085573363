import React, { useEffect, useState } from 'react';
import { Form, Header, Message } from 'semantic';
import PriceInput from 'components/form-fields/Price';
import { useTranslation } from 'react-i18next';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { CostSettingsFormProps } from 'components/tariffs/AdvancedTariff/CostSettings/Form';
import PricePerIdleMinute from 'components/tariffs/AdvancedTariff/CostSettings/PricePerIdleMinute';
import CurrentMarketPrices from 'components/MarketEnergyTariffs/Current';
import { formatCurrency } from 'utils/formatting';

export default function CostSettingsDynamicForm({
  value: costSettings = {},
  onChange = () => undefined,
  currency,
}: CostSettingsFormProps) {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const [pricePerKwh, setPricePerKwh] = useState(costSettings?.pricePerKwh);
  const [pricePerSession, setPricePerSession] = useState(
    costSettings?.pricePerSession
  );
  const [pricePerHour, setPricePerHour] = useState(costSettings?.pricePerHour);
  const [pricePerIdleMinute, setPricePerIdleMinute] = useState<number>(
    costSettings?.pricePerIdleMinute
  );
  const [idleGracePeriodMinutes, setIdleGracePeriodMinutes] = useState<number>(
    costSettings?.idleGracePeriodMinutes || 30
  );

  const disablePricePerSession =
    !advancedTariffFeatures.sessionFee && !pricePerSession;
  const disablePricePerHour = !advancedTariffFeatures.timeFee && !pricePerHour;

  const [surchargePerKwh, setSurchargePerKwh] = useState(
    costSettings?.surchargePerKwh || 0
  );

  const [enableIdleFee, setEnableIdleFee] = useState(
    !!costSettings?.pricePerIdleMinute
  );

  useEffect(() => {
    onChange?.({
      pricePerKwh,
      pricePerSession,
      pricePerHour,
      pricePerIdleMinute: enableIdleFee ? pricePerIdleMinute : 0,
      idleGracePeriodMinutes,
      surchargePerKwh,
      _uiKey: costSettings._uiKey,
    });
  }, [
    pricePerKwh,
    pricePerSession,
    pricePerHour,
    pricePerIdleMinute,
    idleGracePeriodMinutes,
    enableIdleFee,
    surchargePerKwh,
  ]);

  return (
    <>
      <CurrentMarketPrices />

      <Form.Group widths={'equal'}>
        <Form.Field>
          <label>
            {t(
              'advancedTariff.dynamicPriceSurchargePerKwh',
              'Surcharge per kWh'
            )}
          </label>
          <PriceInput
            value={surchargePerKwh}
            onChange={setSurchargePerKwh}
            currency={currency}
          />
        </Form.Field>

        <Form.Field>
          <label>
            {t('advancedTariff.pricePerSession', 'Price per Session')}
          </label>
          <PriceInput
            value={pricePerSession}
            onChange={setPricePerSession}
            currency={currency}
            disabled={disablePricePerSession}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('advancedTariff.pricePerHour', 'Price per Hour')}</label>
          <PriceInput
            value={pricePerHour}
            onChange={setPricePerHour}
            currency={currency}
            disabled={disablePricePerHour}
          />
          <i style={{ fontSize: 12, float: 'left', marginTop: 6 }}>
            {t(
              'advancedTariff.timeCapMessage',
              '*Maximum cost may be applied per session according to roaming contracts'
            )}
          </i>
        </Form.Field>
      </Form.Group>

      {advancedTariffFeatures.idleFee && (
        <>
          <PricePerIdleMinute
            enableIdleFee={enableIdleFee}
            setEnableIdleFee={setEnableIdleFee}
            pricePerIdleMinute={pricePerIdleMinute}
            setPricePerIdleMinute={setPricePerIdleMinute}
            idleGracePeriodMinutes={idleGracePeriodMinutes}
            setIdleGracePeriodMinutes={setIdleGracePeriodMinutes}
            currency={currency}
          />
        </>
      )}

      <br />
      <Header as={'h4'} style={{ marginTop: 0 }}>
        {t('advancedTariff.backupTariff', 'Backup Tariff')}
      </Header>
      <p>
        {t(
          'advancedTariff.backupTariffDescription',
          'Set your backup price in case the current energy market price cannot be retrieved. Note that the surcharge price from the dynamic tariff will still be applied.'
        )}
      </p>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <label>{t('advancedTariff.pricePerKwh', 'Price per kWh')}</label>
          <PriceInput
            value={pricePerKwh}
            onChange={setPricePerKwh}
            currency={currency}
          />
          <i style={{ float: 'left', marginTop: 6 }}>
            {formatCurrency(pricePerKwh + surchargePerKwh, {
              currency,
              exact: true,
            })}{' '}
            {t(
              'advancedTariff.pricePerKwhIncludingSurcharge',
              'per kWh including surcharge'
            )}
          </i>
        </Form.Field>
        <Form.Field />
        <Form.Field />
      </Form.Group>

      <Message
        info
        content={t(
          'advancedTariff.accessPolicyMessage',
          'Note the hour tariff and session tariff can only be used for public locations, where the reimbursement is paid out to a business entity.'
        )}
      />
    </>
  );
}
