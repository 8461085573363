import React, { ReactNode, CSSProperties, Children } from 'react';
import { AlignItems, SpacingValue } from 'types/layout';
import { resolveSpacingValue } from './utils';

type GroupPosition = 'left' | 'right' | 'center' | 'apart';
type GroupAlignItems = Extract<
  AlignItems,
  'baseline' | 'center' | 'flex-start' | 'flex-end'
>;

interface GroupProps {
  /**
   * Controls the alignment of the children along the cross axis.
   * @default 'center'
   */
  align?: GroupAlignItems;
  /**
   * Determines if the children should grow to fill available space.
   * @default false
   */
  grow?: boolean;
  /**
   * Prevents wrapping of children to multiple lines.
   * @default false
   */
  noWrap?: boolean;
  /**
   * Controls the position of the children within the group.
   * @default 'left'
   */
  position?: GroupPosition;
  /**
   * Controls the spacing between children.
   * @default 5
   */
  spacing?: SpacingValue;
  /**
   * The style to be applied to the center container.
   */
  style?: CSSProperties;
  /**
   * The children to be rendered.
   */
  children: ReactNode;
}

const DEFAULT_SPACING_VALUE_IN_PX = 5;
const GROUP_POSITIONS = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
  apart: 'space-between',
} as const;

/**
 * A flexible container that groups and arranges its children with various options.
 *
 * @component
 * @param {GroupProps} props - The props for the Group component.
 * @param {GroupAlignItems} props.align - Controls the alignment of the children along the cross axis.
 * @param {boolean} props.grow - Determines if the children should grow to fill available space.
 * @param {boolean} props.noWrap - Prevents wrapping of children to multiple lines.
 * @param {GroupPosition} props.position - Controls the position of the children within the group.
 * @param {SpacingValue} props.spacing - Controls the spacing between children.
 * @returns {JSX.Element} The group container with arranged children.
 * @example
 * // Basic usage
 * <Group>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Group>
 *
 * // Group with centered and spaced items
 * <Group align="center" spacing={10}>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Group>
 *
 * // Group with growing items
 * <Group grow>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Group>
 */
export default function Group({
  align = 'flex-start',
  grow = false,
  noWrap = false,
  position = 'left',
  spacing = DEFAULT_SPACING_VALUE_IN_PX,
  children,
  style,
}: GroupProps) {
  const groupStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: align,
    justifyContent: GROUP_POSITIONS[position],
    flexWrap: noWrap ? 'nowrap' : 'wrap',
    gap: resolveSpacingValue(spacing, DEFAULT_SPACING_VALUE_IN_PX),
    ...style,
  };

  const childStyle: CSSProperties = {
    flexGrow: grow ? 1 : 0,
  };

  return (
    <div style={groupStyle}>
      {Children.map(children, (child) => (
        <div style={childStyle}>{child}</div>
      ))}
    </div>
  );
}
