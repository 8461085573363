import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';

import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function ProviderCurrencies({
  prefixPath = '/finance',
  providerCurrenciesPath = '/finance/currencies',
}) {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route
            path={providerCurrenciesPath}
            component={() => <List prefixPath={prefixPath} />}
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
