import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default class MyPaymentFees extends React.Component {
  render() {
    return (
      <AppWrapper>
        <Container>
          <Switch>
            <Route path="/my-payment-fees" component={List} exact />
            <Route
              path="/my-payment-fees/:paymentId"
              component={Detail}
              exact
            />
            <Route
              path=""
              component={() => <Redirect to="/my-payment-fees" />}
            />
          </Switch>
        </Container>
      </AppWrapper>
    );
  }
}
