import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic';
import { ValidationError, ValidationErrorResponse } from '../../../types/api';
import { FormikProps } from 'formik';
import { InviteEmployeeFormValues } from './formData';

export class InviteEmployeeError extends Error {
  constructor(
    public type: 'billing' | 'invite',
    public innerError: ValidationErrorResponse
  ) {
    super(innerError.message || 'Invite employee failed!');
  }

  static Billing(innerError: ValidationErrorResponse) {
    return new InviteEmployeeError('billing', innerError);
  }

  static Inviting(innerError: ValidationErrorResponse) {
    return new InviteEmployeeError('invite', innerError);
  }
}

type InviteEmployeeErrorMessageProps = {
  error: InviteEmployeeError | null;
  onDismiss?: () => void;
};

export function InviteEmployeeErrorMessage(
  props: InviteEmployeeErrorMessageProps
) {
  const { t } = useTranslation();
  if (!props.error) {
    return null;
  }
  return (
    <Message error onDismiss={props.onDismiss}>
      {props.error.type === 'billing' &&
        t(
          'inviteEmployee.billingError',
          'There was a problem updating your billing info'
        )}
      {props.error.type === 'invite' &&
        t(
          'inviteEmployee.inviteError',
          'There was a problem inviting the employee.'
        ) +
          ' ' +
          (props.error?.innerError?.message || '')}
    </Message>
  );
}

export function useInviteEmployeeErrorFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (
      error: ValidationError,
      formikProps: FormikProps<InviteEmployeeFormValues> | null
    ) => {
      if (!formikProps) {
        return;
      }
      switch (error.code) {
        case 'SIGNUP_INVALID_IBAN':
          formikProps.setFieldError(
            'billing.ibanNumber',
            t('updateBillingInfo.errors.invalidIban', 'Invalid IBAN number')
          );
          break;
        case 'SIGNUP_INVALID_POSTAL_CODE_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.postalCode',
            t(
              'updateBillingInfo.errors.invalidPostalCode',
              'Invalid postal code'
            )
          );
          break;
        case 'SIGNUP_INVALID_COUNTRY':
          formikProps.setFieldError(
            'billing.countryCode',
            t(
              'updateBillingInfo.errors.invalidCountryCode',
              'Please select a supported country'
            )
          );
          break;
        case 'SIGNUP_VAT_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.vatNumber',
            t(
              'updateBillingInfo.errors.invalidVatNumber',
              'Invalid for the selected country'
            )
          );
          break;
        case 'SIGNUP_COC_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.chamberOfCommerceNumber',
            t(
              'updateBillingInfo.errors.invalidVatNumber',
              'Invalid for the selected country'
            )
          );
          break;
        default:
          console.error('Unhandled request card error', error);
      }
    },
    [t]
  );
}
