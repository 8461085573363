import React from 'react';
import { omit } from 'lodash-es';
import { Message } from 'semantic';

export default class DataList extends React.PureComponent {
  state = {
    data: [],
    page: 1,
    status: {
      request: true,
    },
  };

  static defaultProps = {
    onRequest: () => {
      throw Error('DataList.onRequest needs to be implemented');
    },
    processData: (data) => data,
  };

  componentDidUpdate(prevProps) {
    const changed = Object.keys(this.getOptions()).some((key) => {
      return prevProps[key] !== this.props[key];
    });

    if (changed) {
      this.fetchItems();
    }
  }

  getOptions() {
    return omit(this.props, ['children']);
  }

  componentDidMount() {
    this.fetchItems();
    if (this.props.live) {
      this.interval = setInterval(() => {
        this.updateItems();
      }, this.props.liveIntervalMs || 5000);
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  setSort() {
    console.warn('SORT TODO');
    /*
    const { chargeSessions } = this.props;
    const sort = chargeSessions.sort;
    if (sort.field !== field) {
      chargeSessions.setSort({
        field: field,
        order: 'asc'
      });
    } else {
      chargeSessions.setSort({
        field: field,
        order: sort.order === 'asc' ? 'desc' : 'asc'
      });
    }
    chargeSessions.fetchItems();
    */
  }

  setPage = (page) => {
    return new Promise((resolve) => {
      this.setState(
        {
          page,
        },
        () => {
          resolve(this.fetchItems());
        }
      );
    });
  };

  fetchItems = () => {
    const limit = this.props.limit;
    this.setState({
      status: { request: true },
    });

    const promise = this.props.onRequest({
      limit,
      skip: (this.state.page - 1) * limit,
    });

    if (!promise || !promise.then) {
      throw Error('DataList.onRequest needs to return a promise');
    }

    return promise
      .then(({ data, meta }) => {
        this.setState({
          data: this.props.processData(data),
          meta: meta,
          status: { success: true },
        });
        return { data, meta };
      })
      .catch((error) => {
        this.setState({
          status: { success: false, error },
        });
      });
  };

  updateItems = () => {
    const limit = this.props.limit;
    const promise = this.props.onRequest({
      limit,
      skip: (this.state.page - 1) * limit,
    });

    if (!promise || !promise.then) {
      throw Error('DataList.onRequest needs to return a promise');
    }

    return promise
      .then(({ data, meta }) => {
        this.setState({
          data: this.props.processData(data),
          meta: meta,
          status: { success: true },
        });
        return { data, meta };
      })
      .catch((error) => {
        this.setState({
          status: { success: false, error },
        });
      });
  };

  render() {
    const { status } = this.state;
    if (status && status.error) {
      return <Message error content={status.error.message} />;
    }
    const children = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, {
        ...this.state,
        fetchItems: this.fetchItems,
        deleteItem: this.deleteItem,
        setPage: this.setPage,
        ...this.getOptions(),
      });
    });
    return children;
  }
}
