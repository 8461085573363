import { Input, Modal, Form, Select, Button, Message } from 'semantic';
import React from 'react';

import modal from 'helpers/modal';
import { request } from 'utils/api';

const options = [
  {
    key: 'excluded',
    text: 'Create a non-billable test CPO session',
    value: 'excluded',
  },
  {
    key: 'ocpi-credential-linked',
    text: 'Create a billable CPO session attached to OCPI credential',
    value: 'ocpi-credential-linked',
  },
];

class CreateSessionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenUid: this.props.tokenUid,
      kwh: this.props.kwh,
      sessionType: this.props.sessionType,
    };
  }

  handleSubmit = () => {
    this.props.onChange();
  };

  handleSubmit = async () => {
    const { sessionType, tokenUid, kwh } = this.state;

    this.setState({ loading: true });

    try {
      const { error } = await request({
        path: '/2/sessions/cpo/simulate',
        method: 'POST',
        body: {
          sessionType: sessionType,
          tokenUid: tokenUid,
          kwh: kwh,
        },
      });

      // very nonstandard error handling due to api
      if (error) {
        throw new Error(error.message);
      }

      this.props.close();
      this.props.onSave({
        sessionType,
        tokenUid,
        kwh,
      });
    } catch (error) {
      this.setState({ error: error, loading: false });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <>
        <Modal.Header>Create Charging Session</Modal.Header>
        <Modal.Content>
          <Form>
            {error && <Message error content={error.message} />}
            <Form.Field
              control={Select}
              label="Session type"
              options={options}
              value={this.state.sessionType}
              onChange={(e, { value }) => {
                this.setState({ sessionType: value });
              }}
            />
            <Form.Field
              control={Input}
              label={
                this.state.sessionType === 'excluded'
                  ? 'Token UID (any string)'
                  : `Token UID (OCPI provider owned token)`
              }
              value={this.state.tokenUid}
              onChange={(e, { value }) => {
                this.setState({ tokenUid: value });
              }}
            />
            <Form.Field
              control={Input}
              label={'kWh'}
              value={this.state.kwh}
              onChange={(e, { value }) => {
                this.setState({ kwh: value });
              }}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Create" onClick={this.handleSubmit} />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(CreateSessionModal);
