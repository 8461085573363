import React from 'react';
import { Input } from 'semantic';

import styled from 'styled-components';

const StyledDiv = styled('div')`
  margin-bottom: 0.8em;
  .ui.label {
    min-width: 150px;
  }
  &:hover {
    filter: brightness(90%);
  }
  .ui.button {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

export default class CopyValue extends React.Component {
  state = {
    loading: false,
    showTip: false,
  };

  onClick = async () => {
    this.setState({
      loading: true,
    });
    await this.props.onClick();
    if (this.mounted) {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async copy(text) {
    await navigator.clipboard.writeText(text);
  }

  handleOpen = () => {
    this.setState({ showTip: true });

    this.timeout = setTimeout(() => this.setState({ showTip: false }), 2000);
  };

  handleClose = () => {
    this.setState({ showTip: false });
    clearTimeout(this.timeout);
  };

  render() {
    const { showTip } = this.state;
    const { label, value, ...props } = this.props;
    return (
      <StyledDiv>
        <Input
          fluid
          readOnly
          value={value}
          label={label}
          action={{
            labelPosition: 'right',
            icon: showTip ? 'check' : 'copy',
            content: 'Copy',
            onClick: async () => {
              await this.copy(value);
              this.handleOpen();
            },
          }}
        />
      </StyledDiv>
    );
  }
}
