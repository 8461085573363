import { useState, useEffect } from 'react';
import { request } from 'utils/api';

type Params<T> = {
  // path is the API endpoint to fetch data from
  path: string;
  // method is the HTTP method to use
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';
  // body is the body to send to the API
  body?: any;
  // queryParams is an object to use for query parameters
  queryParams?: any;
  // onCompleted is a callback that will be called when the request is completed
  onCompleted?: (params: Result<T>) => void;
  // disabled is a boolean indicating if the request should be made
  disabled?: boolean;
};

type Result<T> = {
  // data is the data returned by the API
  data: T | null;
  // loading is a boolean indicating if the request is loading
  loading: boolean;
  // error is an error returned by the API
  error: Error | null;
  // refresh is a function that can be called to refresh the data
  refresh: () => void;
};

const useFetch = <T>(params: Params<T>): Result<T> => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [refreshIncrement, setRefreshIncrement] = useState(0);
  const refresh = () => setRefreshIncrement((prev) => prev + 1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await request({
          path: params.path,
          method: params.method ?? 'GET',
          body: params.body,
          params: params.queryParams,
        });

        setData(data);
        setLoading(false);
        setError(null);
        if (params.onCompleted) {
          params.onCompleted({ data, loading, error, refresh });
        }
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };
    if (!params.disabled) {
      fetchData();
    }
  }, [params.path, params.method, params.disabled, refreshIncrement]);
  return { data, loading, error, refresh };
};

export default useFetch;
