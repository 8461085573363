import React from 'react';
import { get } from 'lodash-es';
import { Form, Modal, Button, Header, Segment } from 'semantic';
import ErrorMessage from 'components/ErrorMessage';

import { withTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';

import { request } from 'utils/api';

import modal from 'helpers/modal';

class EditAccountTier extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    const { accountTier } = props;
    const state = {
      accountTier: props.accountTier || {},
      availableModules: [],
      loading: false,
      error: null,
      selectedFeatures: [],
    };

    if (accountTier) {
      state.selectedFeatures = accountTier.platformFeatures.map((f) => f.key);
    }

    this.state = state;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const availableModules = await this.fetchModulesWithFeatures();
    this.setState({ availableModules, loading: false });
  }

  isUpdate() {
    return !!this.props.accountTier?.id;
  }

  fetchModulesWithFeatures = async () => {
    const { data } = await request({
      method: 'GET',
      path: '/1/platform-modules',
    });
    return data;
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    const { accountTier } = this.state;

    if (accountTier) {
      accountTier.platformFeatures = this.state.selectedFeatures;
    }

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/account-tiers/${accountTier.id}`,
          body: { ...accountTier },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/account-tiers',
          body: { ...accountTier },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setName(value) {
    const { accountTier } = this.state;
    accountTier.name = value;
    this.setState({
      accountTier: accountTier,
    });
  }

  toggleFeature(feature, checked) {
    if (checked) {
      this.setState({
        selectedFeatures: [...this.state.selectedFeatures, feature],
      });
    } else {
      this.setState({
        selectedFeatures: this.state.selectedFeatures.filter(
          (f) => f != feature
        ),
      });
    }
  }

  render() {
    const { t } = this.props;
    const { accountTier, loading, error, selectedFeatures } = this.state;

    return (
      <>
        <Modal.Header>
          {this.isUpdate()
            ? t('editAccountTier.titleEdit', 'Edit {{name}}', {
                name: accountTier.name,
              })
            : t('editAccountTier.titleNew', 'New Account Tier')}
        </Modal.Header>
        <Modal.Content>
          <Form
            autoComplete="disabled"
            error={Boolean(error)}
            onSubmit={this.onSubmit}
            id="edit-account-form">
            <ErrorMessage error={error} />

            <Form.Input
              value={get(accountTier, 'name', '')}
              name="name"
              label={t('editAccountTier.name', 'Name')}
              autoComplete="disabled"
              type="text"
              required={true}
              onChange={(e, { name, value }) => this.setName(value)}
            />

            {this.state.availableModules
              .filter((m) => m.features?.length)
              .map((module) => (
                <Segment key={module.key}>
                  <Header size={'small'}>{module.name}</Header>
                  {module.features.map((feature) => (
                    <Form.Checkbox
                      toggle
                      key={feature.key}
                      label={`${feature.name} - ${feature.description}`}
                      checked={selectedFeatures.includes(feature.key)}
                      name={feature.key}
                      onChange={(e, { name, checked }) => {
                        this.toggleFeature(name, checked);
                      }}
                    />
                  ))}
                </Segment>
              ))}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={this.isUpdate() ? t('button.update') : t('button.create')}
            form="edit-account-form"
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditAccountTier));
