import React, { useContext, useMemo } from 'react';
import { request } from 'utils/api';
import { useQuery } from '@tanstack/react-query';

type PlatformFeature = {
  description: string;
  key: string;
  name: string;
  source: string;
};

type AccountPlatformFeatureContextType = {
  initialized: boolean;
  accountId?: string;
  accountHasPlatformModule: (module: string) => boolean;
  accountHasPlatformFeature: (feature: string) => boolean;
};

const AccountPlatformFeatureContext =
  React.createContext<AccountPlatformFeatureContextType>({
    initialized: false,
    accountHasPlatformModule: () => {
      console.warn('PlatformFeatureContext not initialized');
      return false;
    },
    accountHasPlatformFeature: () => {
      console.warn('PlatformFeatureContext not initialized');
      return false;
    },
  });

type AccountPlatformFeatureProviderProps = {
  children: React.ReactNode;
  accountId?: string;
};

export const AccountPlatformFeatureProvider = (
  props: AccountPlatformFeatureProviderProps
) => {
  const { data: activePlatformFeatures } = useQuery<
    PlatformFeature[],
    unknown,
    PlatformFeature[]
  >(
    ['/1/platform-modules/account/', props.accountId],
    async () => {
      if (!props.accountId) {
        return [];
      }
      const { data } = await request({
        path: `/1/platform-modules/account/${props.accountId}`,
        method: 'GET',
      });
      return data;
    },
    {
      placeholderData: [],
      staleTime: 5 * 60 * 1000,
    }
  );

  const context = useMemo(
    () => ({
      initialized: true,
      accountId: props.accountId,
      accountHasPlatformModule: (module: string) => {
        if (!activePlatformFeatures) {
          return false;
        }
        return !!activePlatformFeatures.find((f) =>
          f?.key?.startsWith?.(module + ':')
        );
      },
      accountHasPlatformFeature: (feature: string) => {
        if (!activePlatformFeatures) {
          return false;
        }
        return !!activePlatformFeatures.find((f) => f?.key === feature);
      },
    }),
    [activePlatformFeatures]
  );

  return (
    <AccountPlatformFeatureContext.Provider value={context}>
      {props.children}
    </AccountPlatformFeatureContext.Provider>
  );
};

export function useAccountPlatformFeatures(): AccountPlatformFeatureContextType {
  return useContext(AccountPlatformFeatureContext);
}
