import React, { useMemo, useState } from 'react';
import { Button, Form, Icon, Modal, Progress } from 'semantic';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { processFile } from 'utils/csv';
import { tokenBatchImportMapping } from 'utils/constants';
import { BatchItem, Mapping } from 'components/modals/ImportTokenBatch/types';

type Props = {
  onUploadSuccess: (
    items: BatchItem[],
    mapping: Mapping,
    numColumnsMatched: number
  ) => void;
  onUploadFailed: (error: Error) => void;
  onGoToPreviousStep: () => void;
};

export function UploadScreen({
  onUploadSuccess,
  onUploadFailed,
  onGoToPreviousStep,
}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const drop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    setLoading(true);

    if (rejectedFiles.length) {
      setLoading(false);
      onUploadFailed(
        new Error(
          t(
            'tokenBatch.fileCriteriaError',
            'File did not meet criteria: {{name}}',
            {
              name:
                rejectedFiles[0].errors?.[0]?.message ||
                rejectedFiles[0]?.file?.name,
            }
          )
        )
      );
      return;
    }

    if (acceptedFiles.length > 1) {
      setLoading(false);
      onUploadFailed(
        new Error(
          t(
            'tokenBatch.fileLimitError',
            'Oops, you can only upload 1 file at a time'
          )
        )
      );
      return;
    }

    try {
      const result = await processFile(
        tokenBatchImportMapping,
        acceptedFiles[0]
      );

      onUploadSuccess(result.items, result.mapping, result.numColumnsMatched);
    } catch (error: any) {
      onUploadFailed(error);
    }

    setLoading(false);

    return;
  };

  return (
    <>
      <Modal.Content>
        {loading && (
          <Progress
            label={t('tokenBatch.analyzingData', 'Analyzing Data')}
            percent={100}
            indicating
          />
        )}

        {!loading && (
          <Form>
            <Dropzone
              maxSize={25 * 1024 * 1024}
              onDrop={(acceptedFiles, rejectedFiles) =>
                drop(acceptedFiles, rejectedFiles)
              }>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps()}
                  className={
                    isDragActive
                      ? 'ui icon blue message upload-dropzone-active'
                      : 'ui icon message upload-dropzone'
                  }
                  style={{ cursor: 'pointer', outline: 0 }}>
                  <Icon name="file regular" />
                  <input {...getInputProps()} />
                  <div className="content">
                    {isDragActive ? (
                      <p>{t('tokenBatch.dropfiles', 'Drop files here...')}</p>
                    ) : (
                      <p>
                        {t(
                          'tokenBatch.dropfilesCSV',
                          'Drop a CSV file here, or click to select one for upload.'
                        )}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          // @ts-ignore
          content={t('tokenBatches.previousStep', 'Previous')}
          icon="arrow-rotate-right"
          onClick={onGoToPreviousStep}
        />
      </Modal.Actions>
    </>
  );
}
