import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createContext } from 'react';

type BaseCostSettingsContextType = {
  isBaseCostSettings: (costSetting) => boolean;
  isMarketTrackingEnabled: () => boolean;
};

const BaseCostSettingsContext =
  createContext<BaseCostSettingsContextType | null>(null);

export function BaseCostSettingsProvider({
  value: baseCostSettings,
  children,
}) {
  const context = useMemo(
    () => ({
      isBaseCostSettings: (costSettings) =>
        baseCostSettings?._uiKey === costSettings._uiKey,
      isMarketTrackingEnabled: () =>
        baseCostSettings.enableEnergyMarketTracking,
    }),
    [baseCostSettings]
  );

  return (
    <BaseCostSettingsContext.Provider value={context}>
      {children}
    </BaseCostSettingsContext.Provider>
  );
}

export function useIsBaseCostSettings(costSettings) {
  const context = useContext(BaseCostSettingsContext);
  return context?.isBaseCostSettings(costSettings);
}

export function useEnableEnergyMarketTrackingState(
  costSettings
): [boolean, (next: boolean) => void] {
  const context = useContext(BaseCostSettingsContext);
  const isBaseCostSetting = context?.isBaseCostSettings(costSettings);
  const isMarketTrackingEnabled = !!context?.isMarketTrackingEnabled();
  // Local state is only used for the base tariff
  const [state, setState] = useState(isMarketTrackingEnabled);
  useEffect(() => setState(isMarketTrackingEnabled), [isMarketTrackingEnabled]);

  if (isBaseCostSetting) {
    // If this is the base tariff, allow the caller to control the state
    return [state, setState];
  }
  // If this is not the base tariff, the state is controlled by the base tariff
  return [isMarketTrackingEnabled, () => undefined];
}
