import React from 'react';
import { Button, Message, Divider } from 'semantic';
import { withTranslation } from 'react-i18next';
import { safeFileName } from 'utils/formatting';

import SearchContext from '../old_search/Context';

class ExportButton extends React.Component {
  static contextType = SearchContext;

  state = {
    loading: false,
    error: null,
  };

  handleSubmit = async () => {
    this.setState({ loading: true, error: null });
    const body = this.props.body || {};

    try {
      await this.context.onDataNeeded({
        format: 'csv',
        limit: 1000000,
        filename: this.props.filename
          ? `${safeFileName(this.props.filename)}.csv`
          : 'export.csv',
        ...this.context.filters,
        ...body,
      });
      this.setState({
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error: err,
      });
    }
  };

  render() {
    const { loading, error } = this.state;
    const { t } = this.props;

    const context = this.context;
    if (context.loading || context.error || context.items.length === 0) {
      return null;
    }

    return (
      <div>
        <Divider hidden />
        {error && <Message error content={error.message} />}
        <div>
          <Button
            loading={loading}
            disabled={loading}
            primary
            icon="download"
            content={t('exportBtn.content', 'Export All')}
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ExportButton);
