import React from 'react';

import { useTranslation } from 'react-i18next';
import { Table, Checkbox } from 'semantic';
import HelpTip from 'components/HelpTip';
import { currentUserCanAccess } from 'utils/roles';
import { request } from 'utils/api';
import { useUser } from '../../../contexts/user';

export default function ProviderCurrenciesTable({
  provider,
  currencies,
  onSave,
}) {
  const { fetchProvider } = useUser();
  const { t } = useTranslation();
  const writeAccess = currentUserCanAccess('providers-currencies', 'write');

  const toggleActivation = async (_, { checked: active, name: currencyId }) => {
    await request({
      method: 'PATCH',
      path: `/1/providers/${provider.id}/currencies/${currencyId}`,
      body: { active },
    });
    fetchProvider(provider.id);
    onSave();
  };

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('providerCurrencies.columnCurrency', 'Currency')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t(
                'providerCurrencies.columnTotalCPOBillingPlans',
                'Number of Charge Station billing plans'
              )}
              <HelpTip
                title={t(
                  'providerCurrencies.columnTotalCPOBillingPlans',
                  'Number of Charge Station billing plans'
                )}
                text={t(
                  'providerCurrencies.columnTotalCPOBillingPlansTooltip',
                  'This is the total number of Charge Stations billing plans that are currently configured to use this currency.'
                )}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t(
                'providerCurrencies.columnTotalMSPBillingPlans',
                'Number of Consumer Card billing plans'
              )}
              <HelpTip
                title={t(
                  'providerCurrencies.columnTotalMSPBillingPlans',
                  'Number of Consumer Card billing plans'
                )}
                text={t(
                  'providerCurrencies.columnTotalMSPBillingPlansTooltip',
                  'This is the total number of Consumer Card billing plans that are currently configured to use this currency.'
                )}
              />
            </Table.HeaderCell>
            {writeAccess && (
              <Table.HeaderCell>
                {t('providerCurrencies.columnActivation', 'Active')}
                <HelpTip
                  title={t('providerCurrencies.activate', 'Activate currency')}
                  text={
                    <>
                      <p>
                        {t(
                          'providerCurrencies.activateTooltip',
                          'Accounts can be configured to be billed in any currencies that are marked as active. ' +
                            'Deactivating a currency prevents further accounts being created with that billing  ' +
                            'currency configured, but it will not impact any existing usage. Note that at least ' +
                            'one currency must be active at all times.'
                        )}
                      </p>
                      <p>
                        {t(
                          'providerCurrencies.activateTooltipExtra',
                          'A currency can only be made active once at least one Billing Plan has been configured ' +
                            'with a price in that currency.'
                        )}
                      </p>
                    </>
                  }
                />
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {currencies.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>{item.currency}</Table.Cell>
                <Table.Cell>{item.cpoBillingPlans}</Table.Cell>
                <Table.Cell>{item.mspBillingPlans}</Table.Cell>
                {writeAccess && (
                  <Table.Cell>
                    <Checkbox
                      checked={item.active}
                      name={item.id}
                      disabled={
                        (item.active && !item.canDeactivate) ||
                        (!item.active && !item.canActivate)
                      }
                      onChange={toggleActivation}
                      toggle
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
