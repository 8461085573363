import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function SyncLog({ prefixPath = '/sync-log' }) {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path={prefixPath} component={List} exact />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
