import React from 'react';

import {
  isSuperAdmin,
  currentUserCanAccessAccountEndpoint,
  currentUserMatchesProviderRole,
} from 'utils/roles';

import { useUser } from 'contexts/user';
import { AppWrapper } from 'components';
import { Redirect } from 'react-router-dom';

import { DashboardProvider } from './context';
import Home from './Home';
import { useFeatures } from 'contexts/features';
import { Loader } from 'semantic';
import { useTranslation } from 'react-i18next';
import PageCenter from 'components/PageCenter';
import { createStorage } from 'utils/storage';

function shouldRedirect(user) {
  const isProviderContext = isSuperAdmin(user) || user.providerRoles.length > 0;

  if (isProviderContext) {
    if (currentUserMatchesProviderRole(/helpdesk/i)) {
      return '/charging-stations';
    } else {
      return '/accounts';
    }
  } else {
    if (currentUserCanAccessAccountEndpoint('ocpiCredentials', 'read')) {
      return '/my-credentials';
    } else if (
      currentUserCanAccessAccountEndpoint('evseControllersMaintenance', 'read')
    ) {
      return '/maintenance';
    } else if (
      currentUserCanAccessAccountEndpoint('locationsFacilitator', 'read')
    ) {
      return '/facilitator/locations';
    }
  }
}

export default function Dashboard() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { isReady } = useFeatures();
  const storage = createStorage('sessionStorage');

  // should be removed once feature flag is removed
  if (!isReady) {
    return (
      <div style={{ height: '80vh' }}>
        <PageCenter>
          <Loader active>{t('loading.loading')}</Loader>
        </PageCenter>
      </div>
    );
  }

  const redirectPath = shouldRedirect(user);

  if (redirectPath && !storage.getItem('homeRedirect')) {
    storage.setItem('homeRedirect', true);
    return <Redirect to={redirectPath} />;
  }

  return (
    <AppWrapper>
      <DashboardProvider>
        <Home />
      </DashboardProvider>
    </AppWrapper>
  );
}
