import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, ListHeader } from 'components';
import { Input, Table } from 'semantic';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';

export default function InvoicesEventLog({ prefixPath = '/finance' }) {
  const { t } = useTranslation();
  const [invoiceLogId, setInvoiceLogId] = useState<string | null>(null);
  const [data, setData] = useState<any | null>(null);

  const fetchInvoiceLog = async (invoiceLogId: string) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/invoices-event-logs/${invoiceLogId || ''}`,
    });

    setData(data);
  };

  useEffect(() => {
    if (invoiceLogId === '') {
      setData(null);
    }
    invoiceLogId && fetchInvoiceLog(invoiceLogId);
  }, [invoiceLogId]);

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="finance" to={prefixPath}>
            {t('finance.title', 'Finance')}
          </Link>,
        ]}
        active={t('invoicelogs.title', 'Invoice Logs')}
      />

      <ListHeader title={t('invoicelogs.title', 'Invoice Logs')} />

      <FormVerticalSpace size={50} />

      <Input
        icon="search"
        placeholder="Enter an Invoice ID"
        onChange={(e) => setInvoiceLogId(e.target.value)}
        onClear={() => setInvoiceLogId(null)}
        style={{ width: '50%' }}
      />

      {data && (
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('invoices.columnInvoiceId', 'Invoice ID')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('invoices.columnIdentifier', 'Identifier')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('invoices.columnDate', 'Date')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('invoices.columnDeleted', 'Deleted')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {[data].map((item) => {
              const invoiceLogPath = `${prefixPath}/invoices-event-logs/${item.invoiceId}/events`;

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link to={invoiceLogPath}>{item.invoiceId}</Link>
                  </Table.Cell>
                  <Table.Cell>
                    <p
                      style={{
                        textDecoration: item.deleted ? 'line-through' : 'none',
                      }}>
                      {item?.invoiceIdentifier}
                    </p>
                  </Table.Cell>
                  <Table.Cell>{item.date}</Table.Cell>
                  <Table.Cell>{item.deleted || 'False'}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
}
