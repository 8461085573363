import React from 'react';

import { withTranslation } from 'react-i18next';

import { Button, Label, Popup, Table } from 'semantic';

import HelpTip from 'components/HelpTip';
import { formatRoles } from 'utils/formatting';

import { Search } from 'components';
import EditMyUser from 'components/modals/EditMyUser';

import { formatDateTime } from 'utils/date';

class MyUsersTable extends React.Component {
  static contextType = Search.Context;

  render() {
    const { t, writeAccess } = this.props;
    const { items, reload, loading } = this.context;

    if (!items.length || loading) return null;

    const canEdit = (item) =>
      writeAccess && item.id !== this.props.user.id && item.type === 'user';

    const formatStatus = (item) => {
      if (item.type === 'user') {
        return (
          <Label content={t('myUsers.statusUser', 'User')} color="yellow" />
        );
      } else if (item.type === 'invite') {
        return (
          <Popup
            content={formatDateTime(item.createdAt)}
            trigger={
              <Label
                content={t('myUsers.statusInvited', 'Invited')}
                color="teal"
              />
            }
          />
        );
      }
    };

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>
                {t('myUsers.columnName', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('myUsers.columnEmail', 'E-mail')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('myUsers.columnRoles', 'Roles')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('myUsers.columnStatus', 'Status')}
                <HelpTip
                  title={t('myUsers.columnStatus', 'Status')}
                  text={t(
                    'myUsers.columnStatusToolTip',
                    'Indicates if this is an invited or created user.'
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('myUsers.columnJoined', 'Joined')}
                <HelpTip
                  title={t('myUsers.columnJoined', 'Joined')}
                  text={t(
                    'myUsers.columnJoinedToolTip',
                    'This is the date and time the user was created.'
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('myUsers.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              const name =
                (item.contact &&
                  [item.contact.firstName, item.contact.lastName]
                    .filter(Boolean)
                    .join(' ')) ||
                t('myUsers.UnknownName', 'Unknown Name');

              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  <Table.Cell>
                    {formatRoles(
                      item.accountRoles,
                      item.providerRoles,
                      item.globalRoles,
                      item.systemRoles
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {formatStatus(item)}
                  </Table.Cell>
                  <Table.Cell>
                    {item.type == 'user' && formatDateTime(item.createdAt)}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <EditMyUser
                      initialValues={item}
                      trigger={
                        <Button
                          disabled={!canEdit(item)}
                          basic
                          icon="pen-to-square"
                          title="Edit"
                        />
                      }
                      onSave={() => reload()}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(MyUsersTable);
