import React from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';
import SearchDropdown from '../../form-fields/formik/SearchDropdown';
import { request } from '../../../utils/api';

export default function EditEvseControllerAccessGroups({
  objectPath,
  accountId,
}: {
  objectPath: string;
  accountId?: string;
}) {
  const { t } = useTranslation();

  if (!accountId) {
    return (
      <Message
        icon="triangle-exclamation"
        size="small"
        error
        header={t(
          'editEvseControllerAccessGroups.error.header',
          'Account is required'
        )}
        content={t(
          'editEvseControllerAccessGroups.error.content',
          'Please select a charging station that is already assigned to an account.'
        )}
      />
    );
  }

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <SearchDropdown
          name={`${objectPath}assignAccessGroups`}
          label={t(
            'evseControllers.accessGroups.assignAccessGroups',
            'Assign Access Groups'
          )}
          multiple
          allowAdditions={false}
          onDataNeeded={() =>
            request({
              path: '/1/access-groups/search',
              method: 'POST',
              body: {
                accountId,
              },
            }).then((res) => {
              return {
                data: res.data?.map((accessGroup: any) => ({
                  id: accessGroup?.id,
                  name: accessGroup?.name,
                })),
              };
            })
          }
          placeholder={t(
            'editEvseControllersModal.selectOption',
            'Select Option'
          )}
        />
      </div>
      <div>
        <SearchDropdown
          name={`${objectPath}removeAccessGroups`}
          label={t(
            'evseControllers.accessGroups.removeAccessGroups',
            'Remove Access Groups'
          )}
          multiple
          allowAdditions={false}
          onDataNeeded={() =>
            request({
              path: '/1/access-groups/search',
              method: 'POST',
              body: {
                accountId,
              },
            }).then((res) => {
              return {
                data: res.data?.map((accessGroup: any) => ({
                  id: accessGroup?.id,
                  name: accessGroup?.name,
                })),
              };
            })
          }
          placeholder={t(
            'editEvseControllersModal.selectOption',
            'Select Option'
          )}
        />
      </div>
    </>
  );
}
