import React from 'react';

import List from './List';
import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';

export default function Integrations() {
  return (
    <AppWrapper>
      <Container>
        <List />
      </Container>
    </AppWrapper>
  );
}
