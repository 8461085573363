import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';

import PermissionsSelector from 'components/form-fields/PermissionsSelector';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import modal from 'helpers/modal';

class EditCredential extends React.Component {
  static defaultProps = {
    initialValues: {
      disabled: false,
    },
  };

  state = {
    formValues: { ...this.props.initialValues },
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
    });
    const { formValues } = this.state;

    try {
      if (this.props.initialValues?.id) {
        await request({
          method: 'PATCH',
          path: `/1/credentials/${formValues.id}`,
          body: formValues,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/credentials',
          body: formValues,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value) {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  getContentOptions() {
    const { t } = this.props;
    return [
      {
        text: t('editRole.optionAccount', 'Account'),
        value: 'account',
      },
      {
        text: t('editRole.optionProvider', 'Provider'),
        value: 'provider',
      },
    ];
  }

  render() {
    const { initialValues, t } = this.props;
    const { formValues = {}, error } = this.state;
    const isUpdate = !!initialValues.id;

    return (
      <>
        <Modal.Header>
          {isUpdate
            ? t('editCredential.titleEdit', 'Edit Credential {{name}}', {
                name: initialValues.name,
              })
            : t('editCredential.titleNew', 'New Credential')}
        </Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)} onSubmit={() => this.onSubmit()}>
            {error && <Message error content={error.message} />}
            <Form.Input
              value={formValues.name || ''}
              required
              name="name"
              label={t('editCredential.name', 'Name')}
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Select
              readOnly={isUpdate}
              disabled={isUpdate}
              value={formValues.context}
              options={this.getContentOptions()}
              name="context"
              label={t('editRole.context', 'Context')}
              required
              type="text"
              onChange={(e, { name, value }) => this.setField(name, value)}
            />

            <Form.Field>
              <label>Account</label>
              <SearchDropDown
                clearable
                value={formValues.accountId}
                objectMode={false}
                onDataNeeded={(body) => {
                  return request({
                    path: '/1/accounts/search',
                    method: 'POST',
                    body: body,
                  });
                }}
                onChange={(e, { value }) => this.setField('accountId', value)}
              />
            </Form.Field>

            <h4>{t('editCredential.permissions', 'Permissions')}</h4>
            <PermissionsSelector
              context="provider"
              value={formValues.permissions || {}}
              onChange={(permissions) =>
                this.setField('permissions', permissions)
              }
            />

            <Form.Checkbox
              label={t('editCredential.disabled', 'Is Disabled?')}
              name="disabled"
              checked={formValues.disabled || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />

            <Form.Checkbox
              label={t(
                'editCredential.allowAllMspLocations',
                'Allow access to all providers for MSP Locations API?'
              )}
              name="allowAllMspLocations"
              checked={formValues.allowAllMspLocations || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={this.state.loading}
            primary
            content={isUpdate ? t('button.update') : t('button.create')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditCredential));
