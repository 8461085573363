import React, { useEffect, useState } from 'react';
import { AppWrapper } from 'components';
import { Container, Header } from 'semantic';
import { Route, Switch, useParams } from 'react-router-dom';
import { Provider } from 'types/provider';
import { request } from 'utils/api';
import Menu from './Menu';
import Detail from './Detail';
import SocialLogin from './SocialLogin';

const ProviderDetail: React.FC<never> = () => {
  const [provider, setProvider] = useState<Partial<Provider>>();
  //@ts-ignore
  const { id } = useParams();

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        const { data } = await request({
          path: `/1/providers/public/${id}`,
          method: 'GET',
        });
        setProvider(data);
      } catch (e) {
        console.error('provider fetch console error', id, e);
      }
    };
    fetchProvider();
  }, [id]);

  return (
    <AppWrapper>
      <Container>
        <Header as="h3">
          {provider?.id ? `Edit ${provider.name}` : 'Editing...'}
        </Header>
        <Menu provider={provider} />
        <Switch>
          <Route
            exact
            path="/providers/:id"
            component={(props) => <Detail {...props} />}
          />
          <Route
            exact
            path="/providers/:id/social-login"
            component={(props: any) => <SocialLogin {...props} />}
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
};

export default ProviderDetail;
