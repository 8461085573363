import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useAccountBillingValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    billing: Yup.object().shape({
      address: Yup.string().required(
        t(
          'settings.personal.accountBilling.validations.address',
          'Please enter your address'
        )
      ),
      addressLine2: Yup.string().required(
        t(
          'settings.personal.accountBilling.validations.addressLine2',
          'Please enter your street number'
        )
      ),
      postalCode: Yup.string().required(
        t(
          'settings.personal.accountBilling.validations.postalCode',
          'Please enter your postal code'
        )
      ),
      city: Yup.string().required(
        t(
          'settings.personal.accountBilling.validations.city',
          'Please enter your city'
        )
      ),
      countryCode: Yup.string().required(
        t(
          'settings.personal.accountBilling.validations.countryCode',
          'Please enter your country code'
        )
      ),
      paymentMethod: Yup.string().required(
        t(
          'settings.personal.accountBilling.validations.paymentMethod',
          'Please select your payment method'
        )
      ),
      email: Yup.string().email(
        t(
          'settings.personal.accountBilling.validations.email',
          'Please enter a valid email'
        )
      ),
    }),
  });
};
