import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditLocation from 'components/modals/EditLocation';
import ImportLocations from 'components/modals/ImportLocations';
import { request } from 'utils/api';
import Table from 'components/tables/Locations';
import { Container, Header, Segment, Grid, Divider, Button } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { SearchProvider, AppWrapper, Filters, Layout } from 'components';

export default function Locations() {
  const { t } = useTranslation();

  function handleDeleteItem(item) {
    return request({
      method: 'DELETE',
      path: `/2/locations/cpo/${item.id}`,
    });
  }

  function handleRecomputeLocation(item) {
    return request({
      method: 'POST',
      path: `/1/ocpp/webhooks/update-location`,
      body: {
        locationId: item.id,
      },
    });
  }

  const onDataNeeded = useCallback((filters) => {
    return request({
      method: 'POST',
      path: `/2/locations/cpo/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        ...filters,
        searchPhrase: filters.searchId,
      },
    });
  }, []);

  const writeAccess = currentUserCanAccessProviderEndpoint(
    'cpoLocations',
    'write'
  );

  const searchRef = React.useRef(null);

  const labels = {
    hasErrors: t('common.hasErrors', 'Has Errors'),
    createdAt: t('common.createdAt', 'Created At'),
    useFastSearch: t('fastSearch.title', 'Fast Search'),
  };

  return (
    <AppWrapper>
      <Container>
        <SearchProvider ref={searchRef} limit={20} onDataNeeded={onDataNeeded}>
          <Breadcrumbs
            path={[
              <Link key="charging-stations" to="/charging-stations">
                Charging Stations
              </Link>,
            ]}
            active={t('locations.title', 'Locations')}
          />
          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={10}
              textAlign="right"
              verticalAlign="middle">
              <ImportLocations
                onClose={() => searchRef.current.reload()}
                trigger={
                  <Button
                    basic
                    content={t('users.importLocations', 'Import Locations')}
                    icon="upload"
                    disabled={!writeAccess}
                  />
                }
              />
              <EditLocation
                onClose={() => searchRef.current?.reload()}
                trigger={
                  <Button
                    primary
                    disabled={!writeAccess}
                    content={t('locations.newLocation', 'New Location')}
                    icon="plus"
                  />
                }
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={6}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('locations.title', 'Locations')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={10}>
                  <Filters.Modal>
                    <Filters.Checkbox
                      name="hasErrors"
                      label={labels.hasErrors}
                    />
                    <Filters.DateRange
                      label={labels.createdAt}
                      name="createdAt"
                    />
                  </Filters.Modal>
                  <Filters.Overview labels={labels} />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Layout horizontal stackable center right>
                    <Filters.Search
                      fluid
                      placeholder={t(
                        'locations.searchPlaceholder',
                        'Search by ID, EVSE ID, etc.'
                      )}
                      name="searchId"
                    />
                  </Layout>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Table
            onRecompute={handleRecomputeLocation}
            onDeleteItem={handleDeleteItem}
            exportUrl="/2/locations/cpo/search"
          />

          <Divider hidden />
        </SearchProvider>
      </Container>
    </AppWrapper>
  );
}
