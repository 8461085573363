import React, { useState, useEffect } from 'react';
import { Form, Loader, Message, Divider, Button } from 'semantic';
import InputField from 'components/form-fields/formik/InputField';
import { Formik } from 'formik';
import FormVerticalSpace from 'screens/Settings/components/atoms/FormVerticalSpace';
import SelectLanguage from 'components/form-fields/formik/SelectLanguage';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import { Group } from 'components/Layout/helpers';
import PhoneCountry from 'components/form-fields/formik/PhoneCountry';
import FormHeader from 'screens/Settings/components/atoms/FormHeader';
import { useAccount } from 'contexts/account';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { useInfoAndContactValidation } from './info-and-contact.validation';
import { isDirty } from 'components/form-fields/formik/utils/forms';

type InfoAndContactFormValues = {
  name: string;
  chamberOfCommerceNo: string;
  vatNo: string;
  hasNoVatNo?: boolean;
  fieldService: {
    email: string;
  };
  defaultLangCode: string;
  contact: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNo: string;
    phoneCountryCode: string;
  };
};

export default function InfoAndContactForm() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const validationSchema = useInfoAndContactValidation();

  const { account } = useAccount();
  const [disabledVatInput, setDisabledVadInput] = useState<boolean | undefined>(
    account?.hasNoVatNo
  );

  const initialFormValues = {
    name: account?.name || '',
    chamberOfCommerceNo: account?.chamberOfCommerceNo || '',
    vatNo: account?.vatNo || '',
    hasNoVatNo: account?.hasNoVatNo,
    fieldService: {
      email: account?.fieldService?.email || '',
    },
    defaultLangCode: account?.defaultLangCode || 'nl',
    contact: {
      firstName: account?.contact?.firstName || '',
      lastName: account?.contact?.lastName || '',
      email: account?.contact?.email || '',
      phoneNo: account?.contact?.phoneNo || '',
      phoneCountryCode: account?.contact?.phoneCountryCode || 'NL',
      jobTitle: account?.contact?.jobTitle || '',
    },
  };

  useEffect(() => {
    setDisabledVadInput(account?.hasNoVatNo);
  }, [account]);

  const handleSubmit = async (values: InfoAndContactFormValues) => {
    setError('');
    setLoading(true);
    try {
      await request({
        method: 'PATCH',
        path: '/1/accounts/self',
        body: values,
      });
      setSuccess(true);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {account && (
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {({ handleSubmit, values }) => {
            const dirty = isDirty(initialFormValues, values);

            return (
              <Form onSubmit={handleSubmit}>
                <FormHeader
                  size="large"
                  pb={5}
                  pt={5}
                  text={t(
                    'settings.organization.infoAndContact.title',
                    'Account Info & Contact'
                  )}
                />
                <Divider />
                {loading && <Loader />}
                {error && <Message error content={error} />}
                {success && (
                  <Message
                    success
                    content={t('messages.updated', 'Successful updated!')}
                  />
                )}
                <p>
                  {t(
                    'settings.organization.infoAndContact.description',
                    `Manage your organizations details and contact information.`
                  )}
                </p>

                <FormHeader
                  size="medium"
                  mt={20}
                  mb={15}
                  text={t(
                    'settings.organization.infoAndContact.infoHeader',
                    'Organization Info'
                  )}
                />

                <InputField
                  name="name"
                  required
                  label={t(
                    'settings.organization.infoAndContact.name',
                    'Organization Name'
                  )}
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <FormVerticalSpace />

                <InputField
                  name="chamberOfCommerceNo"
                  label={t(
                    'settings.organization.infoAndContact.chamberOfCommerceNo',
                    'Chamber of Commerce Number'
                  )}
                  required
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <FormVerticalSpace />

                <InputField
                  name="vatNo"
                  disabled={disabledVatInput}
                  label={t('settings.organization.billing.vatNo', 'VAT Number')}
                  required
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <FormVerticalSpace />

                <CheckboxField
                  name={'hasNoVatNo'}
                  label={t(
                    'settings.organization.infoAndContact.vatNotApplicable',
                    'VAT Number not applicable'
                  )}
                  onChangeFn={() => setDisabledVadInput(!disabledVatInput)}
                />

                <InputField
                  name="fieldService.email"
                  required
                  label={t(
                    'settings.organization.infoAndContact.fieldServiceEmail',
                    'Email for EVSE issues'
                  )}
                  wrapperStyle={{ maxWidth: 285, paddingTop: 5 }}
                />

                <FormVerticalSpace />

                <SelectLanguage
                  name="defaultLangCode"
                  label={t('formLabel.language', 'Language')}
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <p style={{ fontStyle: 'italic' }}>
                  {t(
                    'settings.organization.account-info-and-contact.languageNote',
                    'Note that organization specific emails & invoices will be sent in selected language.'
                  )}
                </p>

                <FormHeader
                  size="medium"
                  mt={20}
                  mb={15}
                  text={t(
                    'settings.organization.infoAndContact.companyContact',
                    'Company Contact'
                  )}
                />

                <Group align="flex-start" spacing={'xs'}>
                  <InputField
                    name="contact.firstName"
                    label={t(
                      'settings.organization.infoAndContact.firstName',
                      'First Name'
                    )}
                    required
                    wrapperStyle={{ maxWidth: 188 }}
                  />
                  <InputField
                    name="contact.lastName"
                    label={t(
                      'settings.organization.infoAndContact.lastName',
                      'Last Name'
                    )}
                    required
                    wrapperStyle={{ maxWidth: 188 }}
                  />
                </Group>

                <FormVerticalSpace />

                <InputField
                  name="contact.email"
                  label={t('settings.personal.profile.email', 'Email')}
                  required
                  type="email"
                  wrapperStyle={{ maxWidth: 285 }}
                />

                <FormVerticalSpace />

                <Group spacing="xs">
                  <PhoneCountry
                    name="contact.phoneCountryCode"
                    label={t(
                      'settings.organization.infoAndContact.countryCode',
                      'Country Code'
                    )}
                    required
                    style={{ minWidth: 133 }}
                    wrapperStyle={{ maxWidth: 133 }}
                  />

                  <InputField
                    type="tel"
                    name="contact.phoneNo"
                    fluid
                    required
                    style={{ minWidth: 142 }}
                    wrapperStyle={{ maxWidth: 142 }}
                    label={t(
                      'settings.organization.infoAndContact.phoneNumber',
                      'Phone Number'
                    )}
                  />
                </Group>

                <FormVerticalSpace size={50} />

                <Button
                  type="submit"
                  disabled={!dirty}
                  style={{ marginLeft: 0 }}>
                  {t(
                    'settings.organization.infoAndContact.update',
                    'Update Account Info'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
