import React from 'react';
import { request } from 'utils/api';
import { Segment, Message } from 'semantic';

import PageCenter from 'components/PageCenter';
import Logo from 'components/LogoTitle';

import { Link, useHistory } from 'react-router-dom';
import { useUser } from 'contexts/user';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function LoginSSO(props) {
  const params = new URLSearchParams(window.location.search);
  const exchangeToken = window.location.hash.substring(1);
  const { provider, setToken } = useUser();

  const [state, setState] = useState({
    error: null,
    loading: false,
    email: '',
    ssoEnabled: provider.enableSingleSignOn,
  });

  const history = useHistory();

  const { t } = useTranslation();

  async function handleOnLoad() {
    setState({
      ...state,
      error: null,
      loading: true,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/auth/login/sso/exchange',
        body: {
          exchangeToken,
        },
      });

      if (!data.ssoEnabled) {
        setState({ ssoEnabled: false, error: null, loading: false });
        return;
      }

      if (!data.token) {
        setState({
          ssoEnabled: true,
          error: 'Token not found',
          loading: false,
        });
        return;
      }

      setToken(data.token);
      history.push(params.get('redirect') || '/');
    } catch (error) {
      setState({
        ...state,
        error,
        loading: false,
      });
    }
  }

  useEffect(() => {
    handleOnLoad();
  }, []);

  if (!state.ssoEnabled) {
    return (
      <PageCenter>
        <Logo title="Login" />
        <Segment.Group>
          <Segment padded>
            <Message
              error
              content={t(
                'error.ssoNotEnabled',
                'Single Sign-On is not enabled for your service provider.'
              )}
            />
            <Link to={'/login'}>
              {t('link.loginWithPassword', 'Log in with email and password')}
            </Link>
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }

  return (
    <PageCenter>
      <Logo />
      {state.error && (
        <Segment.Group>
          <Segment padded>
            <Message error content={state.error.message} />
          </Segment>
        </Segment.Group>
      )}
    </PageCenter>
  );
}
