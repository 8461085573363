import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { Header, Divider, Form, Grid } from 'semantic';

import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

import { SearchProvider, Filters } from 'components';
import Breadcrumbs from 'components/Breadcrumbs';

import Table from './Table';

const sort = {
  order: 'desc',
  field: 'createdAt',
};

export default function MyInvoices() {
  const { t } = useTranslation();

  async function handleDataNeeded(body) {
    const result = await request({
      method: 'POST',
      path: '/1/invoices/mine',
      body,
    });
    return result;
  }

  return (
    <AppWrapper>
      <SearchProvider onDataNeeded={handleDataNeeded} sort={sort}>
        <Breadcrumbs active={t('myInvoices.title', 'My Invoices')} />
        <Grid reversed="computer">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('myInvoices.title', 'My Invoices')}
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Form>
          <Form.Group>
            <Filters.Dropdown
              name="type"
              label={t('invoices.filterByType', 'Filter By Type')}
              placeholder={t('common.all', 'All')}
              options={[
                {
                  text: t('invoices.mspUsage', 'Card subscription'),
                  value: 'msp-usage',
                },
                {
                  text: t('invoices.cpoUsage', 'Charge station subscription'),
                  value: 'cpo-usage',
                },
              ]}
            />
          </Form.Group>
        </Form>
        <Table />
      </SearchProvider>
    </AppWrapper>
  );
}
