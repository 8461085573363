import { useMemo } from 'react';
import { getParameterByName } from 'utils/url';
import useQueryParams from 'hooks/useQueryParams';

export const PersonalDetailsStep = 'personalDetails';
export const AccountDetailsStep = 'accountDetails';
export const VerifyEmailStep = 'verifyEmail';
export const AccountVerifiedStep = 'accountVerified';
export const BillingInfoStep = 'billingInfo';
export type SignupStep =
  | typeof PersonalDetailsStep
  | typeof AccountDetailsStep
  | typeof VerifyEmailStep
  | typeof AccountVerifiedStep
  | typeof BillingInfoStep;

type SignupFlowNavigation = { next?: SignupStep; prev?: SignupStep };

const defaultSignupFlow = new Map<SignupStep, SignupFlowNavigation>([
  // TODO next: VerifyEmailStep when email verification is enabled
  [PersonalDetailsStep, { next: AccountVerifiedStep }],
  // TODO prev: VerifyEmailStep when email verification is enabled
  [AccountVerifiedStep, { prev: PersonalDetailsStep }],
]);

const remoteSessionsSignupFlow = new Map<SignupStep, SignupFlowNavigation>([
  [PersonalDetailsStep, { next: BillingInfoStep }],
  // TODO next: VerifyEmailStep when email verification is enabled
  [BillingInfoStep, { next: AccountVerifiedStep, prev: PersonalDetailsStep }],
  // TODO prev: VerifyEmailStep when email verification is enabled
  [AccountVerifiedStep, { prev: BillingInfoStep }],
]);

const cpoSignupFlow = new Map<SignupStep, SignupFlowNavigation>([
  // TODO next: VerifyEmailStep when email verification is enabled
  [PersonalDetailsStep, { next: AccountVerifiedStep }],
  // TODO prev: VerifyEmailStep when email verification is enabled
  [AccountVerifiedStep, { prev: PersonalDetailsStep }],
]);

const mspSignupFlow = new Map<SignupStep, SignupFlowNavigation>([
  [PersonalDetailsStep, { next: BillingInfoStep }],
  // TODO next: VerifyEmailStep when email verification is enabled
  [BillingInfoStep, { next: AccountVerifiedStep, prev: PersonalDetailsStep }],
  // TODO prev: VerifyEmailStep when email verification is enabled
  [AccountVerifiedStep, { prev: BillingInfoStep }],
]);

export function useSignupJourney() {
  const query = useQueryParams();
  const { userJourney, postSignupRedirect, flowMap } = useMemo(() => {
    const paramRedirectURL = query.get('redirect');
    const nextUserJourney = resolveUserJourney();

    switch (nextUserJourney.journey) {
      case 'cpo':
        return {
          userJourney: nextUserJourney,
          postSignupRedirect: `/`,
          flowMap: cpoSignupFlow,
        };
      case 'msp':
        return {
          userJourney: nextUserJourney,
          postSignupRedirect: '/my-cards',
          flowMap: mspSignupFlow,
        };
      case 'remote-sessions':
        return {
          userJourney: nextUserJourney,
          postSignupRedirect: `/my-map/remote-sessions?evseId=${nextUserJourney.evseId}`,
          flowMap: remoteSessionsSignupFlow,
        };
      case 'fst-invite-customer':
        return {
          userJourney: nextUserJourney,
          postSignupRedirect: `/my-locations?station-serial-number=${nextUserJourney.stationSerialNumber}`,
          flowMap: defaultSignupFlow,
        };
    }
    if (paramRedirectURL) {
      return {
        userJourney: nextUserJourney,
        postSignupRedirect: paramRedirectURL,
        flowMap: defaultSignupFlow,
      };
    }
    return {
      userJourney: nextUserJourney,
      postSignupRedirect: `/`,
      flowMap: defaultSignupFlow,
    };
  }, [query]);

  return {
    userJourney,
    postSignupRedirect,
    nextStep: (step: SignupStep) => flowMap.get(step)?.next,
    prevStep: (step: SignupStep) => flowMap.get(step)?.prev,
  };
}

export type SignupJourney = ReturnType<typeof useSignupJourney>;
export type Journey =
  | 'cpo'
  | 'msp'
  | 'remote-sessions'
  | 'fst-invite-customer'
  | 'generic';

export interface UserJourney {
  journey?: Journey;
  billingPlanId?: string;
  evseId?: string;
  stationSerialNumber?: string;
  referral?: string;
  platform?: 'web';
}

function resolveUserJourney(): UserJourney {
  const urlParams = new URLSearchParams(window.location.search);

  const referral = urlParams.get('referral') || undefined;
  const journeyParam = urlParams.get('journey');

  if (journeyParam === 'cpo') {
    return { journey: 'cpo', referral, platform: 'web' } as const;
  }

  if (journeyParam === 'msp') {
    return {
      journey: 'msp',
      billingPlanId: getParameterByName('billingPlanId'),
      referral,
      platform: 'web',
    } as const;
  }

  if (journeyParam === 'remote-sessions') {
    return {
      journey: 'remote-sessions',
      evseId: getParameterByName('evseId'),
      referral,
      platform: 'web',
    } as const;
  }

  if (journeyParam === 'fst-invite-customer') {
    return {
      journey: 'fst-invite-customer',
      stationSerialNumber: getParameterByName('stationSerialNumber'),
      referral,
      platform: 'web',
    } as const;
  }

  return { journey: 'generic', referral, platform: 'web' } as const;
}
