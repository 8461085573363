import React from 'react';
import Dropzone from 'react-dropzone';

import { Modal, Icon, Progress, Table, Message, Button, Form } from 'semantic';

import { processFile } from 'utils/csv';
import {
  accessGroupRFIDImportMapping,
  accessGroupVisualNumberImportMapping,
} from 'utils/constants';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import modal from 'helpers/modal';

import PlatformFeature from 'components/PlatformFeature';

const defaultState = {
  step: 1,
  loading: false,
  tokenType: 'rfid',
  items: null,
  mapping: null,
  progressPercent: 0,
};

async function batchCreate(type, objects, percentFn, accessGroup) {
  const errors = [];
  percentFn(1);
  let i = 0;

  for (const object of objects) {
    const member =
      type === 'rfid'
        ? {
            name: object.name,
            uid: object.uid,
            type: 'rfid',
            priceType: object.pricePerKwh ? 'custom' : 'free',
          }
        : {
            name: object.name,
            type: 'visualNumber',
            visualNumber: object.visualNumber,
            priceType: object.pricePerKwh ? 'custom' : 'free',
          };

    if (object.pricePerKwh) {
      member.pricePerKwh = object.pricePerKwh;
    }

    try {
      await request({
        method: 'POST',
        path: `/1/access-groups/${accessGroup.id}/member`,
        body: member,
      });
    } catch (e) {
      errors.push(e);
    }

    percentFn((i / objects.length) * 100);
    i += 1;
  }
  percentFn(100);
  return errors;
}

class ImportAccessGroupMembers extends React.Component {
  state = {
    ...defaultState,
  };

  drop(acceptedFiles, rejectedFiles) {
    const { t } = this.props;
    this.setState({ loading: true, error: null });
    const loading = false;
    let error = null;
    if (rejectedFiles.length) {
      error = new Error(
        t(
          'importTokens.fileCriteriaError',
          'File did not meet criteria: {{name}}',
          {
            name: rejectedFiles[0].name,
          }
        )
      );

      return this.setState({ error, loading });
    }
    if (acceptedFiles.length > 1) {
      error = new Error(
        t(
          'importTokens.fileLimitError',
          'Oops, you can only upload 1 file at a time'
        )
      );
      return this.setState({ error, loading });
    }

    this.setState({ step: 2, loading: true });
    processFile(
      this.state.tokenType == 'rfid'
        ? accessGroupRFIDImportMapping
        : accessGroupVisualNumberImportMapping,
      acceptedFiles[0]
    )
      .then((result) => this.setState({ loading: false, ...result }))
      .catch((error) => this.setState({ loading: false, error }));
  }

  commit() {
    const { step, items } = this.state;
    this.setState({ step: step + 1, loading: true });
    batchCreate(
      this.state.tokenType,
      items,
      (progressPercent) => this.setState({ progressPercent }),
      this.props.accessGroup
    )
      .then((errors) => this.setState({ loading: false, errors }))
      .catch((error) => this.setState({ loading: false, error }));
  }

  renderErrorSummary(errors) {
    const { t } = this.props;
    const errorsByCount = {};
    errors.forEach((error) => {
      if (!errorsByCount[error.message]) {
        errorsByCount[error.message] = 0;
      }
      errorsByCount[error.message] += 1;
    });

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('importTokens.columnError', 'Error')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('importTokens.columnOccurrences', 'Occurrences')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(errorsByCount).map((message, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{message}</Table.Cell>
                <Table.Cell textAlign="right">
                  {errorsByCount[message]}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  renderCommit() {
    const { t } = this.props;
    const { loading, progressPercent, error, errors, items } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading ? (
          <Progress
            label={t('importTokens.importingData', 'Importing Data')}
            percent={progressPercent}
            indicating
          />
        ) : errors && errors.length ? (
          <div>
            <p>
              {t(
                'importTokens.importDataError',
                'Received {{errorCount}} errors while importing {{itemsCount}} records:',
                {
                  errorCount: errors.length,
                  itemsCount: items.length,
                }
              )}
            </p>
            {this.renderErrorSummary(errors)}
          </div>
        ) : (
          <p>
            {t(
              'importTokens.importDataSuccess',
              'Imported {{itemsCount}} records successfully!',
              {
                itemsCount: items.length,
              }
            )}
          </p>
        )}
      </div>
    );
  }

  renderPreview() {
    const { t } = this.props;
    const { error, loading, items, mapping, numColumnsMatched } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading && (
          <Progress
            label={t('importTokens.analyzingData', 'Analyzing Data')}
            percent={100}
            indicating
          />
        )}
        {items && (
          <div>
            <p>
              {t(
                'importSessions.analyzingDataSuccess',
                'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
                {
                  numColumnsMatched,
                  itemsCount: items.length,
                }
              )}
            </p>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  {Object.keys(mapping).map((key) => {
                    return <Table.HeaderCell key={key}>{key}</Table.HeaderCell>;
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.slice(0, 5).map((item, i) => {
                  return (
                    <Table.Row key={i}>
                      {Object.keys(mapping).map((key) => {
                        return <Table.Cell key={key}>{item[key]}</Table.Cell>;
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    );
  }

  renderUploadForm() {
    const { t } = this.props;

    return (
      <>
        <Form>
          <PlatformFeature feature="access-groups:import-visualnumber">
            <Form.Select
              options={[
                {
                  key: 'rfid',
                  value: 'rfid',
                  text: 'RFID (UID)',
                },
                {
                  key: 'visualNumber',
                  value: 'visualNumber',
                  text: 'Visual Number',
                },
              ]}
              value={this.state.tokenType}
              onChange={(e, { value }) => {
                this.setState({ tokenType: value });
              }}
              label="Token Type"
            />
          </PlatformFeature>
        </Form>

        <Dropzone
          maxSize={10 * 1024 * 1024}
          onDrop={(acceptedFiles, rejectedFiles) =>
            this.drop(acceptedFiles, rejectedFiles)
          }>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={
                  isDragActive
                    ? 'ui icon blue message upload-dropzone-active'
                    : 'ui icon message upload-dropzone'
                }
                style={{ cursor: 'pointer', outline: 0 }}>
                <Icon name="file regular" />
                <input {...getInputProps()} />

                <div className="content">
                  {isDragActive ? (
                    <p>
                      {t('importTokens.dropfiles', 'Drop files here...')}
                      Drop files here...
                    </p>
                  ) : (
                    <p>
                      {t(
                        'importTokens.dropfilesCSV',
                        'Drop a CSV file here, or click to select one for upload.'
                      )}
                    </p>
                  )}
                  {this.state.tokenType == 'rfid' &&
                    t(
                      'importAccessGroupMembers.rfidHelp',
                      "First column 'Name' (description charge card) and second column 'Token UID' (RFID number)"
                    )}

                  {this.state.tokenType == 'visualNumber' &&
                    t(
                      'importAccessGroupMembers.visualHelp',
                      "First column 'Name' (description charge card) and second column 'Visual number' (the number that can be found on the RFID card/tag)"
                    )}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </>
    );
  }

  render() {
    const { t } = this.props;
    const { step, loading } = this.state;
    return (
      <>
        <Modal.Header>
          {t('importAccessGroupMembers.header', 'Import Members')}
        </Modal.Header>
        <Modal.Content>
          {step === 1 && this.renderUploadForm()}
          {step === 2 && this.renderPreview()}
          {step === 3 && this.renderCommit()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('importTokens.reset', 'Reset')}
            secondary
            icon="arrow-rotate-right"
            disabled={step === 1 || step > 2}
            onClick={() => {
              this.setState({
                ...defaultState,
              });
            }}
          />
          {step === 2 && (
            <Button
              content={t('importTokens.import', 'Import')}
              icon="check"
              primary
              disabled={loading}
              onClick={() => {
                this.commit();
              }}
            />
          )}
          {step === 3 && (
            <Button
              content={t('importTokens.done', 'Done')}
              primary
              disabled={loading}
              loading={loading}
              onClick={() => {
                this.props.close();
              }}
            />
          )}
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(ImportAccessGroupMembers));
