import { BillingAndRequestCardFormValues } from 'components/BillingInfo/types';
import { useFormikContext } from 'formik';
import React from 'react';
import CheckboxField from 'components/form-fields/formik/CheckboxField';
import InputField from 'components/form-fields/formik/InputField';
import { useTranslation } from 'react-i18next';
import { FormField, Header } from 'semantic';
import { useBillingInfoFormCountryCode } from 'components/BillingInfo/useCountryCode';
import styles from './BillingInfo.module.less';

export type BillingOrganizationInfo = {
  vatNumber?: string;
  vatNotApplicable?: boolean;
  chamberOfCommerceNumber?: string;
};

type BillingOrganizationInfoFormPartProps = {
  value: BillingOrganizationInfo;
  objectPath?: string;
  showErrorLabels?: boolean;
  innerPadding?: string;
};

export function BillingOrganizationInfoFormPartHeader() {
  const { t } = useTranslation();
  return (
    <Header as={'h3'} style={{ paddingLeft: 0, fontWeight: 600 }}>
      {t('billingInfo.organizationInfoHeader', 'Organization Info')}
    </Header>
  );
}

export function BillingOrganizationInfoFormPart(
  props: BillingOrganizationInfoFormPartProps
) {
  const { t } = useTranslation();
  const { values, setValues } =
    useFormikContext<BillingAndRequestCardFormValues>();
  const prefix = props.objectPath ? `${props.objectPath}.` : '';
  const vatFieldName = prefix + 'vatNumber';
  const vatNotApplicableFieldName = prefix + 'vatNotApplicable';
  const chamberOfCommerceNumberFieldName = prefix + 'chamberOfCommerceNumber';
  const countryCode = useBillingInfoFormCountryCode(props.objectPath);
  switch (countryCode?.toUpperCase()) {
    case 'NL': // Netherlands
      return (
        <>
          <FormField className={styles.formField}>
            <InputField
              name={chamberOfCommerceNumberFieldName}
              label={t(
                'formLabel.chamberOfCommerceNumber',
                'Chamber of Commerce Nº'
              )}
              hideErrorLabel={!props.showErrorLabels}
              required
              validateImmediately
              onChange={(e) => {
                setValues({
                  ...values,
                  billing: {
                    ...values.billing,
                    chamberOfCommerceNumber: e.target.value,
                  },
                });
              }}
            />
          </FormField>
          <FormField style={{ paddingLeft: props.innerPadding }}>
            <InputField
              name={vatFieldName}
              disabled={props.value.vatNotApplicable}
              label={t('formLabel.vatNumber', 'VAT Number')}
              style={{ paddingBottom: '1em' }}
              required={!props.value.vatNotApplicable}
              validateImmediately={!props.value.vatNotApplicable}
              hideErrorLabel={!props.showErrorLabels}
              onChange={(e) => {
                setValues({
                  ...values,
                  billing: {
                    ...values.billing,
                    vatNumber: e.target.value,
                  },
                });
              }}
            />
            <CheckboxField
              name={vatNotApplicableFieldName}
              label={t(
                'formLabel.vatNotApplicable',
                'VAT Number not applicable'
              )}
              onChangeFn={() => {
                setValues({
                  ...values,
                  billing: {
                    ...values.billing,
                    vatNotApplicable: !values.billing.vatNotApplicable,
                  },
                });
              }}
            />
          </FormField>
        </>
      );
    case 'FR': // France
      return (
        <>
          <FormField style={{ paddingLeft: props.innerPadding }}>
            <InputField
              name={chamberOfCommerceNumberFieldName}
              label={t('formLabel.siretNumber', 'SIRET number')}
              hideErrorLabel={!props.showErrorLabels}
              required
              validateImmediately
            />
          </FormField>
          <FormField
            className={styles.formField}
            style={{
              paddingRight: props.innerPadding,
            }}>
            <InputField
              name={vatFieldName}
              disabled={props.value.vatNotApplicable}
              style={{ paddingBottom: '1em' }}
              label={t('formLabel.vatNumber', 'VAT Number')}
              required={!props.value.vatNotApplicable}
              validateImmediately={!props.value.vatNotApplicable}
              hideErrorLabel={!props.showErrorLabels}
            />
            <CheckboxField
              name={vatNotApplicableFieldName}
              label={t(
                'formLabel.vatNotApplicable',
                'VAT Number not applicable'
              )}
            />
          </FormField>
        </>
      );
    case 'DE': // Germany
    case 'BE': // Belgium
    case 'AT': // Austria
    case 'IT': // Italy
      return (
        <>
          <FormField className={styles.formField}>
            <InputField
              name={vatFieldName}
              disabled={props.value.vatNotApplicable}
              style={{ paddingBottom: '1em' }}
              label={t('formLabel.vatNumber', 'VAT Number')}
              required={!props.value.vatNotApplicable}
              validateImmediately={!props.value.vatNotApplicable}
              hideErrorLabel={!props.showErrorLabels}
            />
            <CheckboxField
              name={vatNotApplicableFieldName}
              label={t(
                'formLabel.vatNotApplicable',
                'VAT Number not applicable'
              )}
            />
          </FormField>
        </>
      );
    case 'CH': // Switzerland
    case 'ES': // Spain
      return (
        <>
          <FormField className={styles.formField}>
            <InputField
              name={vatFieldName}
              disabled={props.value.vatNotApplicable}
              style={{ paddingBottom: '1em' }}
              label={t(
                'formLabel.taxIdentificationNumber',
                'Tax Identification Number'
              )}
              required={!props.value.vatNotApplicable}
              validateImmediately={!props.value.vatNotApplicable}
              hideErrorLabel={!props.showErrorLabels}
            />
            <CheckboxField
              name={vatNotApplicableFieldName}
              label={t(
                'formLabel.taxIdentificationNumberNotApplicable',
                'Tax Identification Number not applicable'
              )}
            />
          </FormField>
        </>
      );
    case 'GB': // United Kingdom
      return (
        <>
          <FormField style={{ paddingLeft: props.innerPadding }}>
            <InputField
              name={chamberOfCommerceNumberFieldName}
              label={t(
                'formLabel.companyRegistrationNumber',
                'Company Registration Nº'
              )}
              hideErrorLabel={!props.showErrorLabels}
              required
              validateImmediately
            />
          </FormField>
          <FormField
            className={styles.formField}
            style={{
              paddingRight: props.innerPadding,
            }}>
            <InputField
              name={vatFieldName}
              disabled={props.value.vatNotApplicable}
              label={t('formLabel.vatNumber', 'VAT Number')}
              style={{ paddingBottom: '1em' }}
              required={!props.value.vatNotApplicable}
              validateImmediately={!props.value.vatNotApplicable}
              hideErrorLabel={!props.showErrorLabels}
            />
            <CheckboxField
              name={vatNotApplicableFieldName}
              label={t(
                'formLabel.vatNotApplicable',
                'VAT Number not applicable'
              )}
            />
          </FormField>
        </>
      );
    default:
      return (
        <>
          <FormField className={styles.formField}>
            <InputField
              name={vatFieldName}
              disabled={props.value.vatNotApplicable}
              style={{ paddingBottom: '1em' }}
              label={t('formLabel.taxNumber', 'Tax Number')}
              required={!props.value.vatNotApplicable}
              validateImmediately={!props.value.vatNotApplicable}
              hideErrorLabel={!props.showErrorLabels}
            />
            <CheckboxField
              name={vatNotApplicableFieldName}
              label={t(
                'formLabel.taxNumberNotApplicable',
                'Tax Number not applicable'
              )}
            />
          </FormField>
        </>
      );
  }
}
