import React from 'react';
import { AccountProvider } from 'contexts/account';
import AccountBilling from './BillingAndPlans.layout';

export default function AccountBillingContainer() {
  //TODO: Add error boundary layer
  return (
    <AccountProvider>
      <AccountBilling />
    </AccountProvider>
  );
}
