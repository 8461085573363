import React from 'react';

import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'utils/formatting';

export default function ConnectorTariff({
  tariff,
  unreliableTariffs,
  customNote,
}) {
  const { t } = useTranslation();

  if (!tariff) return null;

  const elements = tariff.elements || [];
  const currency = tariff.currency || '';

  const unreliableTariffsNote = t(
    'tariffPriceComponent.unreliableTariff',
    "This operator may not be sending accurate tariffs. Please check tariff information at the physical location or the operator's support desk"
  );
  const inaccurateTariffsNote = t(
    'tariffPriceComponent.inaccurateTariff',
    'Tariffs shown here have been sent to E-Flux by roaming partner. Accuracy may vary depending on provider'
  );

  function translatePriceComponent(priceComponent, currency) {
    switch (priceComponent.type) {
      case 'FLAT':
        return t('tariffPriceComponent.flat', 'Start Fee {{total}} incl. VAT', {
          total: formatCurrency(priceComponent.price * 1.21, {
            currency,
          }),
        });
      case 'TIME':
        return t(
          'tariffPriceComponent.time',
          'Hourly rate {{total}} incl. VAT per hour',
          {
            total: formatCurrency(priceComponent.price * 1.21, {
              currency,
            }),
          }
        );
      /* we have not implemented PARKING_TIME yet
      case 'PARKING_TIME':
        return t(
          'tariffPriceComponent.parkingTime',
          'Hourly rate (not charging) {{total}} incl. VAT per hour',
          { total: formatEuro(priceComponent.price * 1.21) }
        );
      */
      case 'ENERGY':
        return t(
          'tariffPriceComponent.energy',
          'Energy cost {{total}} incl. VAT per KWh',
          {
            total: formatCurrency(priceComponent.price * 1.21, {
              currency,
            }),
          }
        );
      default:
        return null;
    }
  }

  const hasPriceComponents = elements.some(
    (element) => element.price_components && element.price_components.length > 0
  );

  return (
    <div style={{ fontSize: '12px' }}>
      {hasPriceComponents ? (
        <div style={{ marginBottom: '1em', fontStyle: 'italic' }}>
          {unreliableTariffs
            ? customNote || unreliableTariffsNote
            : inaccurateTariffsNote}
        </div>
      ) : (
        <div>{t('tariffPriceComponent.noTariff', 'No tariff available')}</div>
      )}
      {elements.map((element) =>
        (element.price_components || []).map((component) => (
          <div key={component.type}>
            {translatePriceComponent(component, currency)}
          </div>
        ))
      )}
    </div>
  );
}
