export async function batchExecute(fn: any, objects: any, percentFn: any) {
  const errors = [];
  percentFn(1);
  let i = 0;
  for (const object of objects) {
    try {
      await fn(object, false);
    } catch (error) {
      errors.push(error);
    }
    percentFn((i / objects.length) * 100);
    i += 1;
  }
  percentFn(100);
  return errors;
}
