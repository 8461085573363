import React from 'react';
import { request } from 'utils/api';
import { Form, Message, Modal, Segment, Button, Icon } from 'semantic';
import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import Countries from 'components/form-fields/Countries';

class EditTariffNote extends React.Component {
  state = {
    formValues: { ...this.props.initialValues },
  };

  onSubmit = async () => {
    this.setState({ loading: true, error: null });
    const { formValues } = this.state;

    try {
      if (formValues?.id) {
        await request({
          method: 'PATCH',
          path: `/1/tariff-notes/${formValues.id}`,
          body: formValues,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/tariff-notes',
          body: formValues,
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  checkLocations = async () => {
    const { partyId, countryCodes } = this.state.formValues;

    if (!partyId || partyId.length !== 3 || !countryCodes?.length) {
      this.setState({ hasLocations: null, locationsCount: null });
      return;
    }

    this.setState({ loading: true, error: null });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/tariff-notes/check-locations',
        body: { partyId, countryCodes },
      });

      this.setState({ hasLocations: data.result, locationsCount: data.count });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  setField(name, value) {
    this.setState(
      {
        submitted: false,
        formValues: { ...this.state.formValues, [name]: value },
      },
      async () =>
        (name === 'partyId' || name === 'countryCodes') &&
        (await this.checkLocations())
    );
  }

  async componentDidMount() {
    const { formValues } = this.state;
    if (formValues?.id) await this.checkLocations();
  }

  render() {
    const { formValues = {}, error, loading } = this.state;
    const { hasLocations, locationsCount } = this.state;
    const { t, initialValues } = this.props;
    const isUpdate = !!initialValues.id;
    const { partyId, countryCodes, enabled, customNote } = formValues;
    const joinedCountryCodes = countryCodes?.join(', ');

    return (
      <>
        <Modal.Header>
          {isUpdate
            ? t(
                'editTariffNote.titleEdit',
                'Edit Tariff Note For {{operatorName}}',
                {
                  operatorName: `Operator with Party ID: '${partyId}'`,
                }
              )
            : t('editTariffNote.titleNew', 'New Tariff Note')}
        </Modal.Header>
        <Modal.Content>
          <Form error={Boolean(error)} onSubmit={() => this.onSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Input
              value={partyId}
              name="partyId"
              label={t('editTariffNote.partyId', 'Party ID')}
              title={
                hasLocations === true
                  ? t(
                      'editTariffNote.LocationsFound',
                      'Found {{count}} locations with partyId: {{partyId}} & countryCodes: {{countryCodes}}',
                      {
                        count: locationsCount,
                        partyId,
                        countryCodes: joinedCountryCodes,
                      }
                    )
                  : hasLocations === false
                    ? t(
                        'editTariffNote.NoLocationsFound',
                        'No locations found with partyId: {{partyId}} & countryCodes: {{countryCodes}}',
                        { partyId, countryCodes: joinedCountryCodes }
                      )
                    : undefined
              }
              icon={
                hasLocations === true ? (
                  <Icon name="check" color="green" />
                ) : hasLocations === false ? (
                  <Icon name="close" color="red" />
                ) : undefined
              }
              type="text"
              required
              onChange={(e, { name, value }) =>
                value.length < 4 && this.setField(name, value.toUpperCase())
              }
            />
            <Countries
              required
              value={countryCodes}
              name={'countryCodes'}
              label={t('editTariffNote.countryCodes', 'Country Codes')}
              lowerCase={false}
              countryNameLabels={false}
              multiple
              onChange={(value) => this.setField('countryCodes', value)}
            />
            <Segment>
              <Form.Field>
                <label>{t('editTariffNote.enabled', 'Enabled')}</label>
                <Form.Checkbox
                  label={t(
                    'editTariffNote.showUnreliableTariffsNote',
                    'Show note about unreliable tariffs in locations map?'
                  )}
                  name="enabled"
                  checked={enabled || false}
                  onChange={(e, { name, checked }) =>
                    this.setField(name, checked)
                  }
                />
              </Form.Field>
              {enabled && (
                <>
                  <Form.Field>
                    <label>
                      {t(
                        'editTariffNote.defaultUnreliableTariffsNote',
                        'The default note for unreliable tariffs is: '
                      )}
                      <span style={{ fontWeight: 'normal' }}>
                        "
                        {t(
                          'tariffPriceComponent.unreliableTariff',
                          "This operator may not be sending accurate tariffs. Please check tariff information at the physical location or the operator's support desk"
                        )}
                        "
                      </span>
                    </label>
                  </Form.Field>
                  <Form.Field>
                    <label>
                      {t(
                        'editTariffNote.unreliableTariffsCustomNote',
                        'Custom note for unreliable tariffs'
                      )}
                    </label>
                    <Form.Input
                      type="text"
                      value={customNote}
                      name="customNote"
                      icon={
                        !!customNote && {
                          name: 'close',
                          link: true,
                          onClick: () => this.setField('customNote', ''),
                        }
                      }
                      onChange={(e, { name, value }) =>
                        this.setField(name, value)
                      }
                    />
                  </Form.Field>
                </>
              )}
            </Segment>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            disabled={loading}
            primary
            content={isUpdate ? t('button.update') : t('button.create')}
            onClick={this.onSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditTariffNote));
