export enum TaxResidencyCountryCode {
  NLD = 'NLD',
  DEU = 'DEU',
  FRA = 'FRA',
}

export type TaxResidencyCountry =
  (typeof TaxResidencyCountryCode)[keyof typeof TaxResidencyCountryCode];

export interface BillingEntity {
  id: string;
  name: string;
  slug?: string;
  taxResidencyCountryCode: TaxResidencyCountry;
  address: {
    streetAddress: string;
    locality: string; // City or Town
    postalCode: string; // Postal or Zip Code
    countryCode: string; // ISO 3166-1 alpha-3 country code (e.g. NLD, DEU, FRA)
    region?: string; // State or Province
    additionalInfo?: string;
  };
  billingDetails: {
    iban?: string;
    bic?: string;
    vatNumber: string;
    chamberOfCommerceNumber: string;
  };
  deleted: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
