import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Header, Container, Divider, Message } from 'semantic';
import InputField from 'components/form-fields/formik/InputField';
import SearchDropdown from 'components/form-fields/formik/SearchDropdown';
import DateField from 'components/form-fields/formik/DateField';
import { request } from 'utils/api';
import PublicNav from 'PublicNav';
import { useTranslation } from 'react-i18next';
import { Translate } from 'types/translation';
import { startOfDay } from 'date-fns';
import isoCountries from 'i18n-iso-countries';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';
import useFetch from 'hooks/useFetch';
import SelectField from 'components/form-fields/formik/SelectField';
import { formatDecimalNumber } from 'utils/formatting';

interface GetReceiptResponse {
  formattedReceiptString: string;
  receiptName: string;
}

enum GetReceiptExceptionMessage {
  NO_RECEIPT_FOUND = 'NO_RECEIPT_FOUND',
  NO_ELIGIBLE_RECEIPT_FOUND = 'NO_ELIGIBLE_RECEIPT_FOUND',
  NO_SESSIONS_FOUND = 'NO_SESSIONS_FOUND',
}

type CountriesResponse = {
  countries: string[];
  default: string;
};

export default function GetReceipt() {
  const [receiptData, setReceiptData] = useState<null | GetReceiptResponse>(
    null
  );
  const [submitError, setSubmitError] = useState<null | Error>(null);
  const { t, i18n } = useTranslation();
  const provider = useProvider();

  const { data: countriesData, error: countriesError } =
    useFetch<CountriesResponse>({
      path: '/1/receipt/countries',
      method: 'GET',
    });

  const countriesOptions =
    countriesData?.countries.map((country) => ({
      text: isoCountries.getName(country, i18n.language),
      value: country,
      key: country,
    })) || [];

  async function onSubmit(values: {
    transactionDate: Date;
    location?: string;
    amount?: number;
    country?: string;
  }) {
    setReceiptData(null);
    setSubmitError(null);

    try {
      const response: { data: GetReceiptResponse } = await request({
        path: '/1/receipt/find',
        method: 'POST',
        body: {
          locationId: values.location,
          transactionDate: values.transactionDate,
          amount: values.amount,
        },
      });

      if (response?.data?.formattedReceiptString) {
        setReceiptData(response.data);
      }
    } catch (error) {
      setSubmitError(error as Error);
    } finally {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  async function fetchLocations(
    searchParams: { name: string },
    country?: string
  ) {
    if (!searchParams || searchParams?.name.length <= 0) {
      return [];
    }

    const response = await request({
      path: '/1/receipt/locations',
      method: 'GET',
      params: {
        searchPhrase: searchParams?.name || '',
        countryCode: country,
      },
    });

    return response;
  }

  function removeScrollFromNumberInput(e: React.FocusEvent<HTMLInputElement>) {
    e.target.addEventListener(
      'wheel',
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
  }

  return (
    <>
      <PublicNav />
      <style>
        {/*
          A solution that targets only a single input to remove the number input arrows would be betterbut I couldn't find a way to do it
          Reference: https://www.geeksforgeeks.org/how-to-disable-arrows-from-number-input/
        */}
        {`
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }

          input[type=number]{
              -moz-appearance: textfield;
          }
        `}
      </style>
      <Container style={{ width: '40rem', marginTop: '2rem' }}>
        <h2>{t('receipt.title', 'Find payment receipts')}</h2>
        <div style={{ marginBottom: 20 }}>
          <Description
            text={t(
              'receipt.description',
              'Find receipts for charging sessions paid at charging stations operated by {{platformName}}. If you started the session by charge card or paid directly in mobile app, please use dashboard or app to find your receipt.',
              { platformName: provider?.platformName }
            )}
          />
        </div>

        <div style={{ paddingBottom: 20 }}>
          <Formik
            enableReinitialize
            validationSchema={Yup.object({
              location: Yup.string().required(),
              transactionDate: Yup.date().required(),
              amount: Yup.number().required().positive(),
            })}
            initialValues={{
              location: undefined,
              country: countriesData?.default,
              transactionDate: startOfDay(new Date()),
            }}
            onSubmit={onSubmit}>
            {({ isSubmitting, handleSubmit, values, setValues }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div style={{ marginBottom: 30 }}>
                    <SelectField
                      label={t('receipt.countryCode', 'Country')}
                      name="country"
                      options={countriesOptions}
                      required
                      onChange={(e, { value }) => {
                        setValues({
                          ...values,
                          location: '',
                          country: value as string,
                        });
                      }}
                      error={
                        countriesError
                          ? t(
                              'receipt.countryError',
                              'Failed to load the countries. Reload the page to try again.'
                            )
                          : undefined
                      }
                    />
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <SearchDropdown
                      label={t('receipt.locationName', 'Location')}
                      name="location"
                      description={t(
                        'receipt.locationDescription',
                        'Search for location by name or address.'
                      )}
                      onDataNeeded={(searchPhrase) =>
                        fetchLocations(searchPhrase, values.country)
                      }
                      getOptionLabel={(item) =>
                        `${item.name} - ${item.address} - ${item.city}`
                      }
                      objectMode={false}
                      forceFetchOnFocus
                      clearable
                      required
                    />
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <DateField
                      required
                      name="transactionDate"
                      description={t(
                        'receipt.transactionDateDescription',
                        'Select either start or end date of charging session.'
                      )}
                      label={t(
                        'receipt.transactionDate',
                        'Date of transaction'
                      )}
                    />
                  </div>
                  <div style={{ marginBottom: 30 }}>
                    <Form.Field required>
                      <label htmlFor="amount">
                        {t('receipt.amount', 'Paid amount')}
                      </label>
                      <Description
                        text={t(
                          'receipt.amountDescription',
                          'Exact amount paid including VAT.'
                        )}
                      />
                      <InputField
                        required
                        type="number"
                        name="amount"
                        placeholder={formatDecimalNumber(0, i18n.language)}
                        onFocus={removeScrollFromNumberInput}
                        step={0.01}
                      />
                    </Form.Field>
                  </div>
                  <Button
                    style={{ marginLeft: 0, marginTop: 30, marginBottom: 30 }}
                    type="submit"
                    fluid
                    primary
                    size="large"
                    content={t('receipt.submit', 'Find receipt')}
                    loading={isSubmitting}
                    as="button"
                  />
                </Form>
              );
            }}
          </Formik>

          {receiptData?.formattedReceiptString && (
            <>
              <Divider />
              <Header as="h3">{t('receipt.receipt', 'Receipt')}</Header>
              <a
                href={`data:text/plain;charset=utf-8,${encodeURIComponent(
                  receiptData?.formattedReceiptString
                )}`}
                download={`${receiptData?.receiptName}.txt`}>
                {t('receipt.download', 'Download receipt')}
              </a>
              <Container textAlign="center">
                <pre>{receiptData?.formattedReceiptString}</pre>
              </Container>
            </>
          )}
          {submitError && <FormattedErrorMessage error={submitError} t={t} />}
        </div>
      </Container>
    </>
  );
}

function Description({ text }: { text: string }) {
  return (
    <div
      style={{
        paddingBottom: 10,
        fontSize: 12,
        color: '#696969',
      }}>
      <em>{text}</em>
    </div>
  );
}

function FormattedErrorMessage({ error, t }: { error: Error; t: Translate }) {
  let fullMessage = t('receipt.unknownError', 'An unknown error occured');

  if (error?.message?.includes('Too many requests')) {
    fullMessage = t(
      'receipt.errorTooManyRequests',
      'Too many requests. Please try again in a few seconds.'
    );
  }

  switch (error?.message) {
    case GetReceiptExceptionMessage.NO_SESSIONS_FOUND:
      fullMessage = t(
        'receipt.noCpoSessionsFound',
        'No charging sessions found for the given location, date and amount.'
      );
      break;
    case GetReceiptExceptionMessage.NO_RECEIPT_FOUND:
      fullMessage = t('receipt.noReceiptFound', 'No receipt found');
      break;
    case GetReceiptExceptionMessage.NO_ELIGIBLE_RECEIPT_FOUND:
      fullMessage = t(
        'receipt.noEligibleReceiptFound',
        'No eligible receipt found. Please contact our customer service.'
      );
      break;
  }

  return (
    <Message error>
      <p>{fullMessage}</p>
    </Message>
  );
}
