import React, { useState } from 'react';

import { Message, Modal, Button } from 'semantic';
import { useTranslation } from 'react-i18next';

import { EvseController } from 'types/evse-controller';
import {
  isEvseControllersFromSameVendor,
  anyEvseControllersPendingFirstConnection,
} from 'utils/evse-controllers';

type Props = {
  evseControllers: EvseController[];
  trigger: React.ReactNode;
  onDone: () => void;
};

export default function ConfirmWithWarningFirmwareUpdateBulk({
  evseControllers,
  onDone,
  trigger,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const evseControllersWithSameVendor =
    isEvseControllersFromSameVendor(evseControllers);

  const hasEvseControllersPendingFirstConnection =
    anyEvseControllersPendingFirstConnection(evseControllers);

  const canProceed = !hasEvseControllersPendingFirstConnection;

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('confirmWithWarningFirmwareUpdateBulk.title', 'Firmware Update')}
      </Modal.Header>
      <Modal.Content>
        {hasEvseControllersPendingFirstConnection && (
          <Message
            error
            icon="triangle-exclamation"
            header={t(
              'confirmWithWarningFirmwareUpdateBulk.warningTitle',
              'Charging Stations pending first connection detected'
            )}
            content={t(
              'confirmWithWarningFirmwareUpdateBulk.warningContent',
              'Some of the selected charging stations have not yet been connected to the backend. Please unselect them.'
            )}
          />
        )}
        {!evseControllersWithSameVendor && (
          <Message
            warning
            icon="triangle-exclamation"
            header={t(
              'confirmWithWarningFirmwareUpdateBulk.messageTitle',
              'Different vendors for charging stations detected'
            )}
            content={t(
              'confirmWithWarningFirmwareUpdateBulk.messageContent',
              'Some of the selected charging stations are from different vendors. Please review your selection to avoid unexpected results.'
            )}
          />
        )}
        {canProceed && (
          <p>
            {t(
              'confirmWithWarningFirmwareUpdateBulk.confirmWithWarning',
              'Would you like to proceed anyway?'
            )}
          </p>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          onClick={() => setOpen(false)}
          content={t('confirmWithWarningFirmwareUpdateBulk.back', 'Back')}
        />
        {canProceed && (
          <Button
            primary
            onClick={() => onDone()}
            content={t(
              'confirmWithWarningFirmwareUpdateBulk.confirm',
              'Confirm'
            )}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}
