const defaultCurrency = 'EUR';

export const newPrice = (currency = defaultCurrency, amount = 0) => ({
  amount,
  currency,
});

const newCostSettings = (simpleCostSetting = {}) => ({
  pricePerKwh: simpleCostSetting.pricePerKwh || 0,
  pricePerSession: simpleCostSetting.pricePerSession || 0,
  pricePerHour: simpleCostSetting.pricePerHour || 0,
  pricePerIdleMinute: 0,
  idleGracePeriodMinutes: 0,
});

export function getNewAdvancedTariffComponent({ isNew = false } = {}) {
  return {
    restrictions: {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      daysOfWeek: null,
    },
    costSettings: newCostSettings(),
    _uiIsNew: isNew,
  };
}
