import { useFormikContext } from 'formik';

export function useBillingInfoFormCountryCode(objectPath?: string) {
  const { values } = useFormikContext();
  if (!objectPath) {
    return values.countryCode;
  }
  const pathParts = objectPath.split('.');
  let value = values;
  for (const pathPart of pathParts) {
    value = value?.[pathPart];
  }
  return value?.countryCode;
}
