import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, Header, Grid } from 'semantic';
import AppWrapper from 'components/AppWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import Menu from './Menu';
import Msp from './Msp';
import Cpo from './Cpo';

export default function Finance({
  mspPath = '/finance',
  cpoPath = '/finance/cpo',
  cpoStatsApiRoute = '/1/evse-controllers/stats',
  mspStatsApiRoute = '/1/cards/stats',
}) {
  const { t } = useTranslation();

  return (
    <AppWrapper>
      <Breadcrumbs active={t('finance.title', 'Finance')} />
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            {t('finance.title', 'Finance')}
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <Menu mspPath={mspPath} cpoPath={cpoPath} />
      <Divider hidden />
      <Switch>
        <Route
          exact
          path={cpoPath}
          component={(props) => (
            <Cpo {...props} cpoStatsApiRoute={cpoStatsApiRoute} />
          )}
        />
        <Route
          exact
          path={mspPath}
          component={(props) => (
            <Msp {...props} mspStatsApiRoute={mspStatsApiRoute} />
          )}
        />
      </Switch>
    </AppWrapper>
  );
}
