import React from 'react';
import { Table } from 'semantic';
import { formatTokenStatus } from 'utils/formatting';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import SecretSpan from 'components/SecretSpan';
import { StyledTable } from 'components/modals/SyncLogStatus/StyledComponents';

function TokenStatusTable({ token, t }) {
  return (
    <Table definition>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={2}>{t('editCard.token', 'Token')}</Table.Cell>
          <StyledTable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ display: 'none' }} />
                <Table.HeaderCell width={8}>
                  {t('tokens.columnUID', 'UID')}
                </Table.HeaderCell>
                <Table.HeaderCell width={8}>
                  {t('common.status', 'Status')}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <SecretSpan value={token.uid} inline />
                </Table.Cell>
                <Table.Cell>
                  {formatTokenStatus(token.isActive, token.isBlocked, t)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </StyledTable>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

TokenStatusTable.propTypes = {
  token: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isBlocked: PropTypes.bool.isRequired,
  }).isRequired,
};

export default withTranslation()(TokenStatusTable);
