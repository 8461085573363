import React from 'react';
import Dropzone from 'react-dropzone';

import { Modal, Icon, Progress, Table, Message, Button } from 'semantic';
import { processFile } from 'utils/csv';
import { usersImportMapping } from 'utils/constants';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash-es';
import { request } from 'utils/api';

async function batchCreate(request, objects, percentFn) {
  const errors = [];
  percentFn(1);
  let i = 0;
  for (const object of objects) {
    try {
      await request(object);
    } catch (e) {
      errors.push(e);
    }
    percentFn((i / objects.length) * 100);
    i += 1;
  }
  percentFn(100);
  return errors;
}

const defaultState = {
  step: 1,
  loading: false,
  items: null,
  mapping: null,
  progressPercent: 0,
};

class ImportUsers extends React.Component {
  state = {
    open: false,
    ...defaultState,
  };

  drop(acceptedFiles, rejectedFiles) {
    const { t } = this.props;
    this.setState({ loading: true, error: null });
    const loading = false;
    let error = null;
    if (rejectedFiles.length) {
      error = new Error(
        t(
          'importUsers.fileCriteriaError',
          'File did not meet criteria: {{name}}',
          {
            name: rejectedFiles[0].name,
          }
        )
      );

      return this.setState({ error, loading });
    }
    if (acceptedFiles.length > 1) {
      error = new Error(
        t(
          'importUsers.fileLimitError',
          'Oops, you can only upload 1 file at a time'
        )
      );
      return this.setState({ error, loading });
    }

    this.setState({ step: 2, loading: true });
    processFile(usersImportMapping, acceptedFiles[0])
      .then((result) => this.setState({ loading: false, ...result }))
      .catch((error) => this.setState({ loading: false, error }));
  }

  commit() {
    const { step, items } = this.state;
    this.setState({ step: step + 1, loading: true });
    batchCreate(this.create, items, (progressPercent) =>
      this.setState({ progressPercent })
    )
      .then((errors) => this.setState({ loading: false, errors }))
      .catch((error) => this.setState({ loading: false, error }));
  }

  create = (body) => {
    return request({
      method: 'POST',
      path: '/1/users',
      body,
    });
  };

  renderErrorSummary(errors) {
    const { t } = this.props;
    const errorsByCount = {};
    errors.forEach((error) => {
      if (!errorsByCount[error.message]) {
        errorsByCount[error.message] = 0;
      }
      errorsByCount[error.message] += 1;
    });

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t('importUsers.columnError', 'Error')}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t('importUsers.columnOccurrences', 'Occurrences')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(errorsByCount).map((message, i) => {
            return (
              <Table.Row key={i}>
                <Table.Cell>{message}</Table.Cell>
                <Table.Cell textAlign="right">
                  {errorsByCount[message]}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  renderCommit() {
    const { t } = this.props;
    const { loading, progressPercent, error, errors, items } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading ? (
          <Progress
            label={t('importUsers.importingData', 'Importing Data')}
            percent={progressPercent}
            indicating
          />
        ) : errors && errors.length ? (
          <div>
            <p>
              {t(
                'importUsers.importDataError',
                'Received {{errorCount}} errors while importing {{itemsCount}} records:',
                {
                  errorCount: errors.length,
                  itemsCount: items.length,
                }
              )}
            </p>
            {this.renderErrorSummary(errors)}
          </div>
        ) : (
          <p>
            {t(
              'importUsers.importDataSuccess',
              'Imported {{itemsCount}} records successfully!',
              {
                itemsCount: items.length,
              }
            )}
          </p>
        )}
      </div>
    );
  }

  renderPreview() {
    const { t } = this.props;
    const { error, loading, items, mapping, numColumnsMatched } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading && (
          <Progress
            label={t('importUsers.analyzingData', 'Analyzing Data')}
            percent={100}
            indicating
          />
        )}
        {items && (
          <div>
            <p>
              {t(
                'importSessions.analyzingDataSuccess',
                'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
                {
                  numColumnsMatched,
                  itemsCount: items.length,
                }
              )}
            </p>
            <div style={{ overflowX: 'auto' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    {Object.keys(mapping).map((key) => {
                      return (
                        <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                      );
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.slice(0, 5).map((item, i) => {
                    return (
                      <Table.Row key={i}>
                        {Object.keys(mapping).map((key) => {
                          return (
                            <Table.Cell key={key}>{get(item, key)}</Table.Cell>
                          );
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderUploadForm() {
    const { t } = this.props;
    return (
      <Dropzone
        maxSize={10 * 1024 * 1024}
        onDrop={(acceptedFiles, rejectedFiles) =>
          this.drop(acceptedFiles, rejectedFiles)
        }>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={
                isDragActive
                  ? 'ui icon blue message upload-dropzone-active'
                  : 'ui icon message upload-dropzone'
              }
              style={{ cursor: 'pointer', outline: 0 }}>
              <Icon name="file regular" />
              <input {...getInputProps()} />
              <div className="content">
                {isDragActive ? (
                  <p>
                    {t('importUsers.dropfiles', 'Drop files here...')}
                    Drop files here...
                  </p>
                ) : (
                  <p>
                    {t(
                      'importUsers.dropfilesCSV',
                      'Drop a CSV file here, or click to select one for upload.'
                    )}
                  </p>
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }

  render() {
    const { trigger, t } = this.props;
    const { open, step, loading } = this.state;
    return (
      <Modal
        closeOnDimmerClick={false}
        closeIcon
        trigger={trigger}
        onClose={() => {
          this.setState({
            open: false,
            touched: false,
            ...defaultState,
          });
          this.props.onClose();
        }}
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>{t('importUsers.header', 'Import Users')}</Modal.Header>
        <Modal.Content>
          {step === 1 && this.renderUploadForm()}
          {step === 2 && this.renderPreview()}
          {step === 3 && this.renderCommit()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('importUsers.reset', 'Reset')}
            secondary
            icon="arrow-rotate-right"
            disabled={step === 1 || step > 2}
            onClick={() => {
              this.setState({
                ...defaultState,
              });
            }}
          />
          {step === 2 && (
            <Button
              content={t('importUsers.import', 'Import')}
              icon="check"
              primary
              disabled={loading}
              onClick={() => {
                this.commit();
              }}
            />
          )}
          {step === 3 && (
            <Button
              content={t('importUsers.done', 'Done')}
              primary
              disabled={loading}
              loading={loading}
              onClick={() => {
                this.setState({
                  open: false,
                  touched: false,
                  ...defaultState,
                });
                this.props.onClose();
              }}
            />
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withTranslation()(ImportUsers);
