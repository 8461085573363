import React from 'react';

import Dropzone from 'react-dropzone';
import { get } from 'lodash-es';
import { request } from 'utils/api';
import { formatDuration } from 'utils/date';

import {
  Form,
  Modal,
  Icon,
  Progress,
  Table,
  Message,
  Button,
  Segment,
} from 'semantic';

import {
  checkDupeField,
  checkUniqueField,
  processFileContent,
  readFile,
} from 'utils/csv';
import { sessionsImportMapping } from 'utils/constants';
import { withTranslation } from 'react-i18next';
import SearchDropDown from 'components/form-fields/SearchDropdown';
import modal from 'helpers/modal';
import sleep from 'utils/sleep';

import { Layout, Search, SearchFilters } from 'components';

function extractBatchErrors(batchResults) {
  return batchResults.filter((item) => item.error).map((item) => item.error);
}

async function storeBatchWithRetry(batch, importBatchId, attempt = 1) {
  try {
    const { batchResults } = await request({
      method: 'POST',
      path: '/1/sessions/batch',
      body: { batch: batch.map((body) => ({ ...body, importBatchId })) },
    });
    if (attempt > 3) {
      return extractBatchErrors(batchResults);
    }
    return extractBatchErrors(batchResults);
  } catch (error) {
    if (error.message.match(/failed to fetch/i)) {
      await sleep(1000);
      return await storeBatchWithRetry(batch, importBatchId, attempt + 1);
    } else if (error.name.match(/ApiError/i)) {
      return error.details.map((detail) => ({
        message: error.name,
        details: detail.message,
      }));
    }

    return [error];
  }
}

function chunk(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

async function batchCreate(objects, percentFn) {
  let errors = [];
  percentFn({
    total: objects.length,
    percent: 1,
    current: 0,
  });
  let i = 0;
  const importBatchId = `Import of ${objects.length} sessions by ${
    window.user ? window.user.name : 'Unknown'
  } on ${new Date().toLocaleString()}`;
  const batchSize = 100;
  const batches = chunk(objects, batchSize);
  for (const batch of batches) {
    const batchErrors = await storeBatchWithRetry(batch, importBatchId);
    errors = errors.concat(batchErrors);
    percentFn({
      percent: (i / objects.length) * 100,
      total: objects.length,
      current: i,
    });
    i += batchSize;
    await sleep(20);
  }
  percentFn({
    percent: 100,
    total: objects.length,
    current: objects.length,
  });
  return errors;
}

const defaultState = {
  step: 1,
  loading: false,
  items: null,
  mapping: null,
  progressPercent: 0,
  externalProviderId: null,
  error: null,
  progress: { percent: 0, total: 0, current: 0 },
};

class ImportSessions extends React.Component {
  state = {
    ...defaultState,
  };

  drop(acceptedFiles, rejectedFiles) {
    const { t } = this.props;
    this.setState({ loading: true, error: null });
    const loading = false;
    let error = null;
    if (rejectedFiles.length) {
      error = new Error(
        t(
          'importSessions.fileCriteriaError',
          'File did not meet criteria: {{name}}',
          {
            name:
              rejectedFiles[0].errors?.[0]?.message ||
              rejectedFiles[0]?.file?.name,
          }
        )
      );
      return this.setState({ error, loading });
    }
    if (acceptedFiles.length > 1) {
      error = new Error(
        t(
          'importSessions.fileLimitError',
          'Oops, you can only upload 1 file at a time'
        )
      );
      return this.setState({ error, loading });
    }

    readFile(acceptedFiles[0]).then((fileContent) => {
      this.setState({ step: 2, loading: true, rawCsv: fileContent });
      processFileContent(sessionsImportMapping, fileContent)
        .then((result) => {
          const { externalProviderId } = this.state; // If externalProviderId is set, set it on all items
          if (externalProviderId && externalProviderId.length) {
            result.items.forEach(
              (item) => (item.infraProviderId = externalProviderId)
            );
          }
          checkDupeField(result, 'externalId', t);
          checkUniqueField(result, 'infraProviderId', t);
          const infraProviderId = result.items[0].infraProviderId;
          this.setState({ loading: false, ...result, infraProviderId });
        })
        .catch((error) => this.setState({ loading: false, error }));
    });
  }

  sendRawCsvToServer() {
    const { rawCsv, infraProviderId } = this.state;
    return request({
      method: 'POST',
      path: '/1/sessions/rawCsv',
      body: { rawCsv, infraProviderId },
    });
  }

  commit() {
    const { step, items, progress } = this.state;
    this.setState({
      step: step + 1,
      loading: true,
      startTs: Date.now(),
      progress: { ...progress, total: items.length },
    });
    this.sendRawCsvToServer()
      .then(() => {
        batchCreate(items, (progress) => this.setState({ progress }))
          .then((errors) => this.setState({ loading: false, errors }))
          .catch((error) => this.setState({ loading: false, error }));
      })
      .catch((error) => this.setState({ loading: false, error }));
  }

  getFilterMapping() {
    const { t } = this.props;
    return {
      errorMessage: {
        type: 'dropdown',
      },
    };
  }

  onDataNeeded = async (filters) => {
    let errors = this.state.errors;

    if (filters.errorMessage) {
      errors = this.state.errors.filter(
        (error) => error.message === filters.errorMessage
      );
    }

    return {
      data: errors,
    };
  };

  renderErrorSummary(errors) {
    const { t } = this.props;

    const errorMessages = [
      'Missing value',
      'CDR already exists',
      'Invalid data',
      'Value out of bounds',
      'Unknown',
    ];

    return (
      <Search.Provider
        onDataNeeded={this.onDataNeeded}
        filterMapping={this.getFilterMapping()}>
        {(context) => {
          const { items } = context;
          return (
            <>
              <Segment>
                <Layout horizontal spread stackable>
                  <SearchFilters.Modal>
                    <SearchFilters.Dropdown
                      name="errorMessage"
                      label={t('cards.errorMessage', 'Error message')}
                      options={errorMessages.map((errorMessage) => ({
                        text: errorMessage,
                        value: errorMessage,
                      }))}
                    />
                  </SearchFilters.Modal>
                </Layout>
              </Segment>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {t('importSessions.columnMessage', 'Message')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('importSessions.columnDetails', 'Details')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.length ? (
                    items.map((item, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{item.message}</Table.Cell>
                        <Table.Cell textAlign="right">
                          {item.details}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>
                      <Table.Cell colSpan="2">
                        {t(
                          'importSessions.noErrors',
                          'No errors found for this filter.'
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </>
          );
        }}
      </Search.Provider>
    );
  }

  renderCommit() {
    const { t } = this.props;
    const { loading, progress, error, errors, items, startTs } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {!loading && startTs > 0 && (
          <p>
            Import took{' '}
            {formatDuration(Math.round((Date.now() - startTs) / 1000), t)}.
          </p>
        )}
        {loading ? (
          <Progress
            label={`${t('importSessions.importingData', 'Importing Data')} (${
              progress.current
            }/${progress.total})`}
            percent={progress.percent}
            indicating
          />
        ) : errors && errors.length ? (
          <div>
            <p>
              {t(
                'importSessions.importDataError',
                'Received {{errorCount}} errors while importing {{itemsCount}} records:',
                {
                  errorCount: errors.length,
                  itemsCount: items.length,
                }
              )}
            </p>
            {this.renderErrorSummary(errors)}
          </div>
        ) : (
          <p>
            {t(
              'importSessions.importDataSuccess',
              'Imported {{itemsCount}} records successfully!',
              {
                itemsCount: items.length,
              }
            )}
          </p>
        )}
      </div>
    );
  }

  renderPreview() {
    const { t } = this.props;
    const { error, loading, items, mapping, numColumnsMatched } = this.state;
    return (
      <div>
        {error && <Message error content={error.message} />}
        {loading && (
          <Progress
            label={t('importSessions.analyzingData', 'Analyzing Data')}
            percent={100}
            indicating
          />
        )}
        {items && (
          <div>
            <p>
              {t(
                'importSessions.analyzingDataSuccess',
                'Matched up {{numColumnsMatched}} columns over {{itemsCount}} records. Preview:',
                {
                  numColumnsMatched,
                  itemsCount: items.length,
                }
              )}
            </p>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    {Object.keys(mapping).map((key) => (
                      <Table.HeaderCell key={key}>{key}</Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.slice(0, 5).map((item, i) => (
                    <Table.Row key={i}>
                      {Object.keys(mapping).map((key) => (
                        <Table.Cell key={key}>{get(item, key)}</Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderUploadForm() {
    const { t } = this.props;
    const { externalProviderId, error } = this.state;

    return (
      <div>
        <Form>
          <Form.Field>
            <label>Override Infra Provider ID (Optional)</label>
            {error && <Message error content={error.message} />}
            <SearchDropDown
              value={externalProviderId}
              clearable
              objectMode={false}
              getOptionLabel={(option) =>
                `${option.name} - (${option.customId})`
              }
              getOptionValue={(option) => option.customId}
              onDataNeeded={(body) =>
                request({
                  path: '/1/external-providers/search',
                  method: 'POST',
                  body: { ...body },
                })
              }
              onChange={(e, { value }) =>
                this.setState({ externalProviderId: value })
              }
            />
          </Form.Field>
          <Dropzone
            maxSize={25 * 1024 * 1024}
            onDrop={(acceptedFiles, rejectedFiles) =>
              this.drop(acceptedFiles, rejectedFiles)
            }>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={
                  isDragActive
                    ? 'ui icon blue message upload-dropzone-active'
                    : 'ui icon message upload-dropzone'
                }
                style={{ cursor: 'pointer', outline: 0 }}>
                <Icon name="file regular" />
                <input {...getInputProps()} />
                <div className="content">
                  {isDragActive ? (
                    <p>{t('importSessions.dropfiles', 'Drop files here...')}</p>
                  ) : (
                    <p>
                      {t(
                        'importSessions.dropfilesCSV',
                        'Drop a CSV file here, or click to select one for upload.'
                      )}
                    </p>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </Form>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { step, loading, error } = this.state;
    return (
      <>
        <Modal.Header>
          {t('importSessions.header', 'Import Charge Sessions')}
        </Modal.Header>
        <Modal.Content>
          {step === 1 && this.renderUploadForm()}
          {step === 2 && this.renderPreview()}
          {step === 3 && this.renderCommit()}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('importSessions.reset', 'Reset')}
            icon="arrow-rotate-right"
            disabled={step === 1 || step > 2}
            onClick={() => this.setState({ ...defaultState })}
          />
          {step === 2 && (
            <Button
              content={t('importSessions.import', 'Import')}
              icon="check"
              primary
              disabled={loading || error}
              onClick={() => this.commit()}
            />
          )}
          {step === 3 && (
            <Button
              content={t('importSessions.done', 'Done')}
              primary
              disabled={loading}
              loading={loading}
              onClick={() =>
                this.setState(
                  {
                    open: false,
                    touched: false,
                    ...defaultState,
                  },
                  () => this.props.close()
                )
              }
            />
          )}
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(ImportSessions));
