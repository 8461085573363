import React from 'react';
import { Table } from 'semantic';

import { useTranslation } from 'react-i18next';

export type AddressParams = {
  firstName: string;
  lastName: string;
  address: string;
  addressLine2: string;
  city: string;
  postalCode: string;
};
export default ({
  firstName,
  lastName,
  address,
  addressLine2,
  city,
  postalCode,
}: AddressParams) => {
  const { t } = useTranslation();

  return (
    <Table definition columns="2">
      <Table.Body>
        <Table.Row>
          <Table.Cell>{t('address.firstName', 'First Name')}</Table.Cell>
          <Table.Cell>{firstName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('address.lastName', 'Last Name')}</Table.Cell>
          <Table.Cell>{lastName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('address.street', 'Street')}</Table.Cell>
          <Table.Cell>
            {[address, addressLine2].filter(Boolean).join(' ')}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('address.city', 'City')}</Table.Cell>
          <Table.Cell>{city}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>{t('address.postalCode', 'Postal code')}</Table.Cell>
          <Table.Cell>{postalCode}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};
