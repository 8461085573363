import React from 'react';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_KEY } from 'utils/env';

export default function PointerMap({
  address,
  value,
  defaultPosition = {
    latitude: 52.25,
    longitude: 4.666667,
  },
  onChange = () => {},
}) {
  const mapRef = React.useRef();
  const mapApiRef = React.useRef();
  const markerRef = React.useRef();

  const [mapApi, setMapApi] = React.useState();
  const [position, setPosition] = React.useState(
    value
      ? {
          lng: value.longitude,
          lat: value.latitude,
        }
      : undefined
  );

  const map = mapRef.current;

  React.useEffect(() => {
    if (value) {
      setPosition({
        lng: value.longitude,
        lat: value.latitude,
      });
    }
  }, [value]);

  React.useEffect(() => {
    if (position) {
      if (
        !value ||
        value.latitude !== position.lat ||
        value.longitude !== position.lng
      ) {
        onChange({
          latitude: position.lat,
          longitude: position.lng,
        });
      }
    }
  }, [position, value]);

  React.useEffect(() => {
    if (mapApi && position) {
      if (!markerRef.current) {
        const marker = new mapApi.Marker({
          position,
          map,
          draggable: true,
        });

        markerRef.current = marker;
        marker.addListener('dragend', (event) => {
          setPosition(event.latLng.toJSON());
        });
        map.setZoom(20);
      } else {
        markerRef.current.setPosition(position);
      }
      map.panTo(position);
    }
  }, [position, markerRef.current, mapApi, map]);

  React.useEffect(() => {
    if (mapApi && address && map) {
      const geocoder = new mapApi.Geocoder();
      geocoder.geocode({ address }, function (results, status) {
        if (status == 'OK') {
          const location = results[0].geometry.location;
          map.setCenter(location);
          setPosition({
            lat: location.lat(),
            lng: location.lng(),
          });
          map.setZoom(20);
        }
      });
    } else if (mapApi && map && !value) {
      map.panTo({
        lat: defaultPosition.latitude,
        lng: defaultPosition.longitude,
      });
      map.setZoom(7);
    }
  }, [mapApi, address, map]);

  return (
    <GoogleMapReact
      draggable={true}
      bootstrapURLKeys={{
        key: GOOGLE_KEY,
      }}
      center={{ lat: defaultPosition.latitude, lng: defaultPosition.longitude }}
      defaultZoom={7}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        mapRef.current = map;
        mapApiRef.current = maps;
        setMapApi(maps);
      }}
    />
  );
}
