import React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'semantic';

interface Props {
  value?: string | null;
  onChange: (value: string | null) => void;
  placeholder: string;
}

export default function Search({ value = '', onChange, placeholder }: Props) {
  const [search, setSearch] = useState<string | null>(value);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  return (
    <Form onSubmit={() => onChange(search)}>
      <Form.Input
        value={search}
        name="search"
        placeholder={placeholder}
        icon={{
          name: 'magnifying-glass',
          link: true,
          onClick: () => {
            onChange(search);
          },
        }}
        type="text"
        onChange={(e, { value }) => setSearch(value.length ? value : null)}
      />
    </Form>
  );
}
