import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic';
import CardStatusTable from 'components/modals/SyncLogStatus/Audit/CardStatusTable';
import TokenStatusTable from 'components/modals/SyncLogStatus/Audit/TokenStatusTable';
import { withTranslation } from 'react-i18next';

function CurrentStatus({ token, cards, t }) {
  const [open, setOpen] = useState(true);

  return (
    <Accordion>
      <Accordion.Title active={open} onClick={() => setOpen(!open)}>
        <h1 style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
          <Icon name="dropdown" />
          Current Status:
        </h1>
      </Accordion.Title>
      <Accordion.Content active={open}>
        {!cards.length ? (
          <p>
            {t(
              'tokens.noCardFound',
              'No card found associated with this token'
            )}
          </p>
        ) : (
          <CardStatusTable cards={cards} />
        )}
        <TokenStatusTable token={token} />
      </Accordion.Content>
    </Accordion>
  );
}

export default withTranslation()(CurrentStatus);
