import React from 'react';
import { Form, Icon, Input } from '../../semantic';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  &&& {
    width: 38px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    opacity: 0.8 !important;
  }
`;

export default function ObjectIdInput({
  value,
  onChange,
  placeholder,
  label,
  clearable,
}) {
  const [inputValue, setInputValue] = React.useState(value);

  const isValidObjectId = (value) => value?.length === 24;

  const onValueChange = (evt, { value }) => {
    setInputValue(value);
    if (!value || isValidObjectId(value)) {
      onChange(evt, { value });
    }
  };

  return (
    <Form.Field>
      {label && <label>{label}</label>}
      <Input
        value={inputValue}
        placeholder={placeholder}
        onChange={onValueChange}
        icon={
          clearable && (
            <StyledIcon
              name="close"
              color="grey"
              style={{ visibility: !!inputValue ? 'visible' : 'hidden' }}
              onClick={() => onValueChange(null, { value: '' })}
              link
            />
          )
        }
      />
    </Form.Field>
  );
}

ObjectIdInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  clearable: PropTypes.bool,
};
