import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic';

const StyledGridRowHeader = styled(Grid.Row)`
  padding-bottom: 0 !important;
`;

export function SummaryGridRow({ row }) {
  return (
    <>
      <StyledGridRowHeader columns={row?.length}>
        {row.map?.((col) => col.Header)}
      </StyledGridRowHeader>
      <Grid.Row columns={row?.length}>
        {row.map?.((col) => col.Content)}
      </Grid.Row>
    </>
  );
}
