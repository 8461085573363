import React, { useEffect, useMemo, useState } from 'react';

import { Formik } from 'formik';
import {
  Segment,
  Form,
  Divider,
  Message,
  Progress,
  Label,
  Button,
  SemanticCOLORS,
} from 'semantic';

import { Configuration, ConfigurationInfo201 } from 'types/config';
import useFetch from 'hooks/useFetch';
import {
  determineCoordinatorStatus,
  getEvseProtocol,
} from 'utils/evse-controllers';
import { request } from 'utils/api';
import ConfigField from 'components/config/ConfigField';
import { waitForResult } from 'utils/evse-commands';
import useInterval from 'hooks/useInternal';
import { useTranslation } from 'react-i18next';
import { generateRequestId } from 'helpers/ocpp';

const INTERVAL_TIME_UNTIL_DOES_NOT_FIND_NEW_CONFIGURATION = 3_000;
const INTERVAL_TIME_WITH_LATEST_CONFIGURATION = 15_000;
const PROTOCOL_ENDPOINT_PATH = '/1/ocpp/2.0.1/protocol';
const COMMANDS_ENDPOINT_PATH = (id: string) =>
  `/1/evse-controllers/${id}/commands`;
const EVSE_CONTROLLER_ENDPOINT_PATH = (id: string) =>
  `/1/evse-controllers/${id}`;

type ProtocolResponse = {
  CONFIGURATION: ConfigurationInfo201[];
};

type Params = {
  evseController: {
    id: string;
    configuration: Configuration[];
    configurationRequestId?: number;
  };
  maintainerMode: boolean;
};

type ResultSetVariables = {
  attributeStatus: 'Accepted' | 'Rejected';
  component: {
    name: string;
  };
  variable: {
    name: string;
  };
};

const buildConfigurationKey = (component: string, variable: string) => {
  return [component, variable].join('.');
};

const transformKey = (key: string) => {
  return key.replaceAll('.', '_');
};

export default function NewConfiguration(params: Params): JSX.Element {
  const { t } = useTranslation();

  const [intervalFetchEvseController, setIntervalFetchEvseController] =
    useState(INTERVAL_TIME_UNTIL_DOES_NOT_FIND_NEW_CONFIGURATION);
  const [evseControllerLatest, setEvseControllerLatest] = useState(
    params.evseController
  );
  const [evseControllerLatestLoading, setEvseControllerLatestLoading] =
    useState(true);
  const [evseFetchError, setEvseFetchError] = useState(null);
  const {
    data: protocol,
    loading: protocolLoading,
    error: protocolFetchError,
  } = useFetch<ProtocolResponse>({ path: PROTOCOL_ENDPOINT_PATH });
  const [getBaseReportLoading, setGetBaseReportLoading] = useState(false);
  const [getBaseReportFetchError, setGetBaseReportFetchError] = useState(null);
  const [latestRequestId, setLatestRequestId] = useState(
    evseControllerLatest.configurationRequestId
  );
  const [resultSetVariables, setResultSetVariables] = useState<
    ResultSetVariables[] | null
  >(null);

  useInterval(() => {
    request({ path: EVSE_CONTROLLER_ENDPOINT_PATH(evseControllerLatest.id) })
      .then(({ data }) => {
        if (data?.configurationRequestId === latestRequestId) {
          setEvseControllerLatestLoading(false);
          setEvseControllerLatest(data);
          // If you update a State Hook to the same value as the current state,
          // React will bail out without rendering the children or firing effects
          setIntervalFetchEvseController(
            INTERVAL_TIME_WITH_LATEST_CONFIGURATION
          );
        }
      })
      .catch((error) => {
        setEvseFetchError(error);
        setEvseControllerLatestLoading(false);
      });
  }, intervalFetchEvseController);

  useEffect(() => {
    getBaseReport();
  }, []);

  function getBaseReport() {
    const requestId = generateRequestId();
    setLatestRequestId(requestId);

    setIntervalFetchEvseController(
      INTERVAL_TIME_UNTIL_DOES_NOT_FIND_NEW_CONFIGURATION
    );

    setGetBaseReportLoading(true);
    request({
      method: 'POST',
      path: COMMANDS_ENDPOINT_PATH(evseControllerLatest.id),
      body: {
        method: 'GetBaseReport',
        params: {
          requestId,
          reportBase: 'FullInventory',
        },
      },
    })
      .then(() => {
        setGetBaseReportLoading(false);
      })
      .catch((error) => {
        setGetBaseReportFetchError(error);
        setGetBaseReportLoading(false);
      });
  }

  const configurationKeys =
    useMemo(
      () =>
        protocol?.CONFIGURATION?.reduce<Record<string, ConfigurationInfo201>>(
          (acc, val) => {
            const key = buildConfigurationKey(val.component, val.variableName);
            acc[key] = val;
            return acc;
          },
          {}
        ),
      [protocol]
    ) || {};

  const initialValues =
    useMemo(
      () =>
        evseControllerLatest.configuration?.reduce<
          Record<string, string | number | boolean>
        >((acc, val) => {
          const key = transformKey(val.key);
          acc[key] = val.value;
          return acc;
        }, {}),
      [evseControllerLatest?.configurationRequestId]
    ) || {};

  const evseProtocol = getEvseProtocol(evseControllerLatest);
  const status = determineCoordinatorStatus(evseControllerLatest);
  const loading =
    protocolLoading || getBaseReportLoading || evseControllerLatestLoading;
  const error = protocolFetchError || getBaseReportFetchError || evseFetchError;

  if (loading) {
    return <Progress percent={80} label="Loading configuration" active />;
  }

  // TODO: add validation
  const validate = (values: Record<string, string | number | boolean>) => {
    return {};
  };

  const submit = async (values: Record<string, string | number | boolean>) => {
    // We first send the SetVariables request, wait for that command to be accepted.
    // After that we request the BaseReport again.
    // The behaviour is similar to 1.6, except that GetBaseReport is a bit more costly
    // as it uses multiple requests to notify the reports.

    setResultSetVariables(null);
    setEvseControllerLatestLoading(true);

    const postCommandResult = await request({
      method: 'POST',
      path: COMMANDS_ENDPOINT_PATH(evseControllerLatest.id),
      body: {
        method: 'SetVariables',
        params: {
          setVariableData: getModifiedVariables(
            evseControllerLatest.configuration,
            values
          ),
        },
      },
    });
    const commandId = postCommandResult.data.id;
    const setVariablesResponse = await waitForResult(
      evseControllerLatest.id,
      commandId
    );
    setResultSetVariables(setVariablesResponse.result.setVariableResult);

    window.scrollTo(0, 0);

    getBaseReport();
  };

  const acceptedVariables = (resultSetVariables || []).filter(
    (config) => config.attributeStatus === 'Accepted'
  );
  const rejectedVariables = (resultSetVariables || []).filter(
    (config) => config.attributeStatus === 'Rejected'
  );
  const hasAcceptedVariables = acceptedVariables.length > 0;
  const hasRejectedVariables = rejectedVariables.length > 0;

  return (
    <div>
      <p style={{ float: 'left' }}>
        Protocol: <Label content={evseProtocol} />
      </p>
      <p style={{ float: 'right' }}>
        Connection Status:{' '}
        <Label
          color={status.color as SemanticCOLORS}
          content={status.content}
        />
      </p>
      <Divider hidden />
      <Segment style={{ clear: 'both' }}>
        {hasAcceptedVariables && (
          <Message info>
            <>
              <p>
                {t(
                  'configuration.variablesUpdated',
                  'The following variables have been updated:'
                )}
              </p>
              <ul>
                {acceptedVariables.map((variable) => (
                  <li
                    key={`${variable.component.name}-${variable.variable.name}`}>
                    ({variable.component.name}) {variable.variable.name}
                  </li>
                ))}
              </ul>
            </>
          </Message>
        )}
        {hasRejectedVariables && (
          <Message error>
            <>
              <p>
                {t(
                  'configuration.variablesFailedToBeUpdated',
                  'The following variables failed to be updated:'
                )}
              </p>
              <ul>
                {rejectedVariables.map((variable) => (
                  <li
                    key={`${variable.component.name}-${variable.variable.name}`}>
                    ({variable.component.name}) {variable.variable.name}
                  </li>
                ))}
              </ul>
            </>
          </Message>
        )}
        {error && <Message error content={error.message} />}
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={validate}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            submit(values);
            setSubmitting(false);
          }}>
          {({
            values,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              {evseControllerLatest.configuration?.map((config) => {
                const infoKey = buildConfigurationKey(
                  config.component?.name,
                  config.variable?.name
                );
                const info = configurationKeys?.[infoKey];
                const key = transformKey(config.key);
                const value = values?.[key];

                return (
                  <ConfigField
                    key={key}
                    configKey={key}
                    handleChange={handleChange}
                    value={value}
                    config={config}
                    configInfo={info}
                    setFieldValue={setFieldValue}
                  />
                );
              })}
              <Button
                as="button"
                type="submit"
                primary
                content="Save"
                disabled={isSubmitting}
                style={{ float: 'left' }}
              />
              <div style={{ clear: 'both' }} />
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  );
}

function getModifiedVariables(
  configuration: Configuration[],
  values: Record<string, string | number | boolean>
) {
  return configuration
    .filter((configVar) => {
      const key = transformKey(configVar.key);
      return values[key] !== configVar.value;
    })
    .map((configVar) => {
      const key = transformKey(configVar.key);
      configVar.value = values[key];
      return {
        attributeType: 'Actual',
        attributeValue: configVar.value,
        component: configVar.component,
        variable: configVar.variable,
      };
    });
}
