import React from 'react';
import { FormButton } from 'semantic-ui-react';
import { omit } from 'lodash-es';

import styled from 'styled-components';

const StyledFormButton = styled(FormButton)`
  .ui.button {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

export default function ThemedButton({ as, ...props }) {
  const FormButtonComponent = props.disableStyled
    ? FormButton
    : StyledFormButton;

  const style = Object.assign({}, props.style || {});

  return (
    <FormButtonComponent
      forwardedAs={as}
      style={style}
      {...omit(props, 'style', 'disableStyled', 'forwardedAs')}
    />
  );
}
