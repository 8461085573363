import React from 'react';

import { useTranslation } from 'react-i18next';

type Props = {
  numResults: number;
};

export default function ResultsFound({ numResults }: Props) {
  const { t } = useTranslation();

  return (
    <p style={{ color: '#6C727F', marginRight: '1em' }}>
      {t('resultsFound.text', '{{numResults}} result(s)', { numResults })}
    </p>
  );
}
