import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Segment, Divider, Button } from 'semantic';

import { request } from 'utils/api';

import { Layout, ListHeader, Search, SearchFilters } from 'components';

import { withTranslation } from 'react-i18next';
import Table from './BatchesTable';
import GenerateEvseIdPrintBatch from '../../../components/modals/GenerateEvseIdPrintBatch';

const staticFilterMapping = {
  keywords: {
    type: 'search',
  },
  createdAt: {
    label: 'Created at',
    type: 'date',
    range: true,
  },
};

function EvseIdPrintBatches({ t }) {
  const searchRef = useRef();
  const selectedBatchIds = useMemo(() => new Set(), []);
  const [disableExportButton, setDisableExportButton] = useState(true);

  const exportEvseIdsFromBatches = useCallback(async () => {
    return request({
      method: 'POST',
      path: '/1/evse-ids/search',
      body: {
        evseIdPrintBatchIds: [...selectedBatchIds],
        filename: `evse-ids-${new Date().toISOString()}`,
        format: 'csv',
      },
    });
  }, []);

  const onDataNeeded = useCallback(async (filters) => {
    return request({
      method: 'POST',
      path: '/1/evse-ids/print-batches/search',
      body: filters,
    });
  }, []);

  const toggleBatchForBulkActions = useCallback(
    (batchId) => {
      if (selectedBatchIds.has(batchId)) {
        selectedBatchIds.delete(batchId);
      } else {
        selectedBatchIds.add(batchId);
      }
      setDisableExportButton(selectedBatchIds.size === 0);
      return selectedBatchIds.has(batchId);
    },
    [selectedBatchIds]
  );

  const isBatchSelected = useCallback(
    (batchId) => {
      return selectedBatchIds.has(batchId);
    },
    [selectedBatchIds]
  );

  const translations = useMemo(
    () => ({
      title: t('evseIdPrintBatches.title', 'EVSE ID print batches'),
      hasErrors: t('evseIdPrintBatches.showErrors', 'Show Errors'),
      createdAt: t('evseIdPrintBatches.createdAt', 'Created at'),
      searchPlaceholder: t('evseIdPrintBatches.filterPlaceHolder', 'Search'),
      generateBatchBtn: t(
        'evseIdPrintBatches.generateBatchBtn',
        'Generate new batch'
      ),
    }),
    [t]
  );

  return (
    <Search.Provider
      ref={searchRef}
      onDataNeeded={onDataNeeded}
      filterMapping={staticFilterMapping}>
      {() => (
        <>
          <ListHeader title={translations.title}>
            <Button
              primary
              disabled={disableExportButton}
              content="Export"
              icon="download"
              onClick={exportEvseIdsFromBatches}
            />

            <GenerateEvseIdPrintBatch
              onSave={() => searchRef.current?.reload()}
              trigger={
                <Button
                  primary
                  style={{ marginTop: '1px' }}
                  content={translations.generateBatchBtn}
                  icon="plus"
                />
              }
            />
          </ListHeader>

          <Segment>
            <Layout horizontal spread stackable>
              <SearchFilters.Modal>
                <Divider />
                <SearchFilters.DateRange
                  label={translations.createdAt}
                  name="createdAt"
                />
              </SearchFilters.Modal>

              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="keywords"
                  placeholder={translations.searchPlaceholder}
                />
              </Layout>
            </Layout>
          </Segment>

          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table
            toggleBatchForBulkActions={toggleBatchForBulkActions}
            isBatchSelected={isBatchSelected}
          />
          <Divider hidden />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </>
      )}
    </Search.Provider>
  );
}

export default withTranslation()(EvseIdPrintBatches);
