import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

export default ({ itemId }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('accontMenu.overview', 'Overview')}
          to={`/accounts/${itemId}`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.user', 'Users')}
          to={`/accounts/${itemId}/users`}
          as={NavLink}
        />

        <Menu.Item
          exact
          content={t('accontMenu.invites', 'Invites')}
          to={`/accounts/${itemId}/invites`}
          as={NavLink}
        />

        <Menu.Item
          exact
          content={t('accontMenu.cards', 'Cards')}
          to={`/accounts/${itemId}/cards`}
          as={NavLink}
        />

        <Menu.Item
          exact
          content={t('accontMenu.chargeSessions', 'MSP Sessions')}
          to={`/accounts/${itemId}/sessions`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.tokens', 'Tokens')}
          to={`/accounts/${itemId}/tokens`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.Invoices', 'Invoices')}
          to={`/accounts/${itemId}/invoices`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.locations', 'Locations')}
          to={`/accounts/${itemId}/locations`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.evses', 'EVSE')}
          to={`/accounts/${itemId}/evse-controllers`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.cpoSessions', 'CPO Sessions')}
          to={`/accounts/${itemId}/sessions/cpo`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('accontMenu.accessGroups', 'Access Groups')}
          to={`/accounts/${itemId}/access-groups`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
};
