import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Label } from 'semantic';
import HelpTip from './HelpTip';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../utils/date';
import { Button, Message } from 'semantic';
import { Formik } from 'formik';
import { request } from 'utils/api';
import { useFeatures } from 'contexts/features';
import { FeatureFlags } from 'screens/Invoices/features';

const SyncERPButton = ({ invoiceId, handleClose }) => {
  const [error, setError] = useState();
  const onSubmit = async () => {
    try {
      await request({
        method: 'POST',
        path: `/1/invoices/${invoiceId}/erp/sync`,
      });
      if (typeof handleClose === 'function') {
        handleClose();
      }
    } catch (e) {
      setError(e?.message);
    }
  };
  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <>
          {error && (
            <Message size="small" error>
              <p style={{ textAlign: 'left' }}>{error}</p>
            </Message>
          )}
          <Button
            icon="warning"
            disabled={error}
            loading={isSubmitting}
            onClick={handleSubmit}>
            Sync to NetSuite
          </Button>
        </>
      )}
    </Formik>
  );
};

const NetSuiteSyncStatus = ({ status, invoiceId }) => {
  const { hasFeature } = useFeatures();
  const { t } = useTranslation();

  const lastSubmission = status?.netSuiteSubmissions?.at(-1);

  const canSyncERP = hasFeature(FeatureFlags.ManualERPSync);

  switch (status?.state) {
    default:
      return <>-</>;
    case 'submitted':
      return (
        <Label
          style={{ cursor: 'default' }}
          color="yellow"
          icon="database"
          title={`Submitted to NetSuite at ${formatDateTime(
            lastSubmission?.submittedToNetSuiteAt
          )}`}
          content="NS"
        />
      );
    case 'error':
      return (
        <Label
          style={{ cursor: 'default' }}
          color="red"
          icon="database"
          title={`NetSuite submission failed ${formatDateTime(
            lastSubmission?.submittedToNetSuiteAt
          )}`}
          content="NS"
        />
      );
    case 'skipped':
      return (
        <HelpTip
          title={t(
            'netSuiteSyncStatus.skippedFromNetSuite',
            'Invoice not submitted to NetSuite'
          )}
          actions={
            status?.noSyncCause === 'dry-run' && canSyncERP
              ? [<SyncERPButton invoiceId={invoiceId} />]
              : []
          }
          text={(() => {
            switch (status?.noSyncCause) {
              default:
                return t(
                  'netSuiteSyncStatus.unknownError',
                  'The invoice was skipped from NetSuite for an unknown reason. Please contact support.'
                );
              case 'dry-run':
                return t(
                  'netSuiteSyncStatus.dryRun',
                  'The invoice was skipped from NetSuite because it was created with a dry-run flag. Use the sync button below to submit the invoice. This is an asynchronous process, so it may take a few minutes for the invoices to be updated.'
                );
              case 'zero-invoice':
                return t(
                  'netSuiteSyncStatus.zeroInvoice',
                  'The amount on the invoice was zero.'
                );
              case 'erp-sync-error':
                return t(
                  'netSuiteSyncStatus.erpSyncError',
                  'The invoice was skipped from NetSuite because of an error in the ERP.'
                );
              case 'skip-accounting-system-was-true':
                return t(
                  'netSuiteSyncStatus.skipAccountingSystemWasTrue',
                  'The invoice was skipped from NetSuite because the skipAccountingSystem flag was true.'
                );
              case 'no-erp-selected-for-syncing':
                return t(
                  'netSuiteSyncStatus.noErpSelectedForSyncing',
                  'The invoice was skipped from NetSuite because no ERP was selected for syncing.'
                );
              case 'invalid-access-policy':
                return t(
                  'netSuiteSyncStatus.invalidAccessPolicy',
                  'The invoice was skipped from NetSuite because of an invalid access policy.'
                );
              case 'missing-netsuite-account-id':
                return t(
                  'netSuiteSyncStatus.missingNetSuiteAccountId',
                  'The invoice was skipped from NetSuite because the NetSuite account ID was missing.'
                );
            }
          })()}>
          <Label
            style={{ cursor: 'pointer' }}
            color={status?.noSyncCause === 'dry-run' ? 'gray' : 'grey'}
            icon="database"
            title="Skipped from NetSuite. Click for more details."
            content="NS"
          />
        </HelpTip>
      );
    case 'submissionAcknowledged':
      return (
        <Label
          as="a"
          color="olive"
          icon="database"
          title="Open in NetSuite"
          content="NS"
          rel="noopener"
          target="_blank"
          href={status?.linkToNetSuiteTransaction}
        />
      );
  }
};

NetSuiteSyncStatus.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  status: PropTypes.shape({
    state: PropTypes.string,
    noSyncCause: PropTypes.string,
    netSuiteSubmissions: PropTypes.arrayOf(
      PropTypes.shape({
        submittedToNetSuiteAt: PropTypes.string,
      })
    ),
  }),
};

export default NetSuiteSyncStatus;
