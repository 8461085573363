import React, { useState } from 'react';

import useFetch from 'hooks/useFetch';
import { Link, useParams } from 'react-router-dom';
import { ListHeader, Breadcrumbs, Search } from 'components';
import { useTranslation } from 'react-i18next';
import { Header, Table, Divider, Loader, Message, Button } from 'semantic';

import {
  EvseBulkActionWorkflow,
  EvseControllerBackgroundJobExecution,
  EvseControllerBackgroundJobStatus,
} from 'types/evse-controller-background-job-execution';
import { formatDateTime } from 'utils/date';
import EvseControllerBackgroundJobExecutionStatus from '../EvseControllerBackgroundJobExecutionStatus';
import InspectObject from 'components/modals/InspectObject';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_BACKGROUND_JOBS_BE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  USERS_FE_PATH,
} from '../utils';
import WorkflowsTable from 'screens/EvseControllersBackgroundJobs/Detail/WorkflowsTable';
import WorkflowsFilterHeader, {
  WorkflowFilterFields,
  workflowFilterMapping,
} from 'screens/EvseControllersBackgroundJobs/Detail/WorkflowsFilterHeader';
import { decodeStringForFilter } from 'utils/filters-header';
import { ClientCSVExporter } from 'components/search';

type WorkflowExportRow = {
  jobId: string;
  jobType?: string;
  ocppIdentity?: string;
  serialNumber?: string;
  account?: string;
  maintenanceAccount?: string;
  location?: string;
  billingPlan?: string;
  firmwareVersion?: string;
  ocppVersion?: string;
  status: string;
  statusDetails?: string;
  createdBy?: string;
  createdAt?: string;
  note?: string;
};

const workflowExportHeaders: WorkflowExportRow = {
  jobId: 'Job ID',
  jobType: 'Job Type',
  ocppIdentity: 'OCPP ID',
  serialNumber: 'Serial Number',
  account: 'Account',
  maintenanceAccount: 'Field Service Account',
  location: 'Location',
  billingPlan: 'Plan',
  firmwareVersion: 'Firmware Version',
  ocppVersion: 'OCPP Version',
  status: 'Status',
  statusDetails: 'Status Detail',
  createdBy: 'Created By',
  createdAt: 'Created At',
  note: 'Note',
};

export default function EvseControllersBackgroundJobsDetail() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [exportData, setExportData] = useState([] as WorkflowExportRow[]);

  const {
    data: evseControllerBulkActionExecutionData,
    loading: evseControllerBulkActionExecutionLoading,
    error: evseControllerBulkActionExecutionError,
    refresh,
  } = useFetch<EvseControllerBackgroundJobExecution>({
    path: `${EVSE_BACKGROUND_JOBS_BE_PATH}/${id}`,
  });

  const showDetails =
    !evseControllerBulkActionExecutionLoading &&
    evseControllerBulkActionExecutionData;
  const showError =
    !evseControllerBulkActionExecutionLoading &&
    evseControllerBulkActionExecutionError;

  const workflowStatuses =
    evseControllerBulkActionExecutionData?.workflows?.reduce(
      (acc, w) => {
        switch (w.status) {
          case EvseControllerBackgroundJobStatus.Completed:
            acc.completed++;
            break;
          case EvseControllerBackgroundJobStatus.Failed:
            acc.failed++;
            break;
          case EvseControllerBackgroundJobStatus.Cancelled:
            acc.cancelled++;
            break;
        }
        return acc;
      },
      { completed: 0, failed: 0, cancelled: 0 }
    );

  const numWorkflowsCompleted = workflowStatuses?.completed || 0;
  const numWorkflowsFailed = workflowStatuses?.failed || 0;
  const numWorkflowsCancelled = workflowStatuses?.cancelled || 0;

  function generateStatusMessage(
    status: EvseControllerBackgroundJobStatus,
    numCompleted: number,
    numFailed: number,
    numCancelled: number,
    total: number
  ): string {
    const completedText =
      numCompleted > 0
        ? t(
            'evseControllersBackgroundJobsDetail.statusStationsNumCompleted',
            '{{numCompleted}} completed',
            { numCompleted }
          )
        : '';
    const failedText =
      numFailed > 0
        ? t(
            'evseControllersBackgroundJobsDetail.statusStationsNumFailed',
            '{{numFailed}} failed',
            { numFailed }
          )
        : '';
    const cancelledText =
      numCancelled > 0
        ? t(
            'evseControllersBackgroundJobsDetail.statusStationsNumCancelled',
            '{{numCancelled}} canceled',
            { numCancelled }
          )
        : '';

    const statusMessages = [completedText, failedText, cancelledText]
      .filter(Boolean)
      .join(', ');

    if (
      status === EvseControllerBackgroundJobStatus.InProgress ||
      status === EvseControllerBackgroundJobStatus.PartlyCompleted
    ) {
      return t(
        'evseControllersBackgroundJobsDetail.statusStationsColumn',
        `{{statusMessages}} out of {{total}} stations`,
        { statusMessages, total }
      );
    }

    return '';
  }

  function updateExportData(workflow: EvseBulkActionWorkflow[]) {
    const creatorUserContact =
      evseControllerBulkActionExecutionData?.creatorUser.contact;

    setExportData(
      workflow.map((w) => ({
        jobId: id,
        jobType: evseControllerBulkActionExecutionData?.actionType,
        ocppIdentity: w.evseController?.ocppIdentity,
        serialNumber: w.evseController?.serialNumber,
        account: w.evseController?.account?.name,
        maintenanceAccount: w.evseController?.maintenanceAccount?.name,
        location: w.evseController?.location?.name,
        billingPlan: w.evseController?.billingPlan?.name,
        firmwareVersion: w.evseController?.bootInfo?.firmwareVersion,
        ocppVersion: w.evseController?.ocppProtocolVersion,
        status: w.status,
        statusDetails: w.statusDetails,
        createdBy:
          creatorUserContact &&
          `${creatorUserContact.firstName} ${creatorUserContact.lastName}`,
        createdAt: evseControllerBulkActionExecutionData?.createdAt?.toString(),
        note: evseControllerBulkActionExecutionData?.note,
      }))
    );
  }

  return (
    <div>
      <Breadcrumbs
        path={[
          <Link
            key={'breadcrumbs_chargingstation'}
            to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobs.breadcrumbs',
              'Charging Stations'
            )}
          </Link>,
          <Link
            key={'breadcrumbs_backgroundjobs'}
            to={EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}>
            {t('evseControllersBackgroundJobs.header', 'Background Jobs')}
          </Link>,
        ]}
        active={id}
      />
      {evseControllerBulkActionExecutionLoading && (
        <div style={{ paddingTop: 200 }}>
          <Loader active />
        </div>
      )}
      {showError && (
        <div>
          <Message error>
            {t(
              'evseControllersBackgroundJobsDetail.failedToLoad',
              'Failed to load job details.'
            )}
          </Message>
        </div>
      )}
      {showDetails && (
        <>
          <div style={{ marginTop: 0, marginBottom: 20 }}>
            <ListHeader
              title={t(
                'evseControllersBackgroundJobsDetail.title',
                'Job Details'
              )}>
              <ClientCSVExporter
                data={exportData}
                headers={workflowExportHeaders}
                fileName="background-job-details"
              />
            </ListHeader>
          </div>
          <div>
            <Header as="h3">
              {t(
                'evseControllersBackgroundJobsDetail.overviewTitle',
                'Overview'
              )}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.jobIdColumn',
                      'Job ID'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {evseControllerBulkActionExecutionData.id}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.jobTypeColumn',
                      'Job Type'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <span style={{ paddingRight: 20 }}>
                      {evseControllerBulkActionExecutionData.actionType}
                    </span>
                    <InspectObject
                      name={t(
                        'evseControllersBackgroundJobsDetail.parametersModalTitle',
                        'Parameters for Job {{jobId}}',
                        { jobId: evseControllerBulkActionExecutionData.id }
                      )}
                      data={evseControllerBulkActionExecutionData.parameters}
                      trigger={
                        <Button
                          basic
                          content={t(
                            'evseControllersBackgroundJobsDetail.showParams',
                            'Show Params'
                          )}
                          icon="code"
                        />
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {evseControllerBulkActionExecutionData.creatorUser && (
                  <Table.Row>
                    <Table.Cell>
                      {t(
                        'evseControllersBackgroundJobsDetail.createdByColumn',
                        'Created By'
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {evseControllerBulkActionExecutionData.creatorUser && (
                        <Link
                          to={`${USERS_FE_PATH}/${evseControllerBulkActionExecutionData.creatorUser.id}`}
                          target="_blank">
                          {
                            evseControllerBulkActionExecutionData.creatorUser
                              .contact?.firstName
                          }{' '}
                          {
                            evseControllerBulkActionExecutionData.creatorUser
                              .contact?.lastName
                          }
                        </Link>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.noteColumn',
                      'Note'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {evseControllerBulkActionExecutionData.note}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.createdAtColumn',
                      'Created At'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(
                      evseControllerBulkActionExecutionData.createdAt
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    {t(
                      'evseControllersBackgroundJobsDetail.statusColumn',
                      'Status'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <EvseControllerBackgroundJobExecutionStatus
                      status={evseControllerBulkActionExecutionData.status}
                    />
                    <br />
                    <small>
                      {generateStatusMessage(
                        evseControllerBulkActionExecutionData.status,
                        numWorkflowsCompleted,
                        numWorkflowsFailed,
                        numWorkflowsCancelled,
                        evseControllerBulkActionExecutionData.workflowMetrics
                          ?.executionsCount ||
                          evseControllerBulkActionExecutionData.workflowExecutionsNumber
                      )}
                    </small>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
            <Search.Provider
              onDataNeeded={async (body) => {
                const data = applyFilters(
                  evseControllerBulkActionExecutionData?.workflows || [],
                  body as Record<WorkflowFilterFields, any>
                );
                updateExportData(data);

                return { data };
              }}
              filterMapping={workflowFilterMapping}>
              <WorkflowsFilterHeader
                data={evseControllerBulkActionExecutionData?.workflows}
              />
              <Search.Status />
              <WorkflowsTable
                backgroundJobId={evseControllerBulkActionExecutionData.id}
                onRefresh={refresh}
              />
            </Search.Provider>
          </div>
        </>
      )}
    </div>
  );
}

const applyFilters = (
  data: EvseBulkActionWorkflow[],
  filters: Record<WorkflowFilterFields, any>
) => {
  let workflows = data;
  if (filters?.statuses) {
    workflows = workflows.filter((workflow) =>
      filters.statuses.includes(workflow.status)
    );
  }
  if (filters?.accountIds) {
    workflows = workflows.filter((workflow) =>
      filters.accountIds.includes(workflow.evseController?.accountId)
    );
  }
  if (filters?.locationIds) {
    workflows = workflows.filter((workflow) =>
      filters.locationIds.includes(workflow.evseController?.locationId)
    );
  }
  if (filters?.maintenanceAccountIds) {
    workflows = workflows.filter((workflow) =>
      filters.maintenanceAccountIds.includes(
        workflow.evseController?.maintenanceAccountId
      )
    );
  }
  if (filters?.vendors) {
    const vendors = filters.vendors.map((vendor: string) =>
      decodeStringForFilter(vendor)
    );
    workflows = workflows.filter((workflow) =>
      vendors.includes(workflow.evseController?.bootInfo?.chargePointVendor)
    );
  }
  if (filters?.firmwareVersions) {
    const firmwareVersions = filters.firmwareVersions.map(
      (firmwareVersion: string) => decodeStringForFilter(firmwareVersion)
    );
    workflows = workflows.filter((workflow) =>
      firmwareVersions.includes(
        workflow.evseController?.bootInfo?.firmwareVersion
      )
    );
  }
  if (filters?.powerTypes) {
    const powerTypes = filters.powerTypes.map((powerType: string) =>
      decodeStringForFilter(powerType)
    );
    workflows = workflows.filter((workflow) =>
      workflow.evseController?.connectors?.some((connector) =>
        powerTypes.includes(connector.powerType)
      )
    );
  }
  if (filters?.ocppVersions) {
    workflows = workflows.filter((workflow) =>
      filters.ocppVersions.includes(
        workflow.evseController?.ocppProtocolVersion
      )
    );
  }
  return workflows;
};
