import React, { CSSProperties, ReactNode } from 'react';
import { Header } from 'semantic';

interface FormHeaderProps {
  size?: 'small' | 'medium' | 'large' | 'huge';
  fontWeight?: number;
  text?: ReactNode | string;
  disabled?: boolean;
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  style?: CSSProperties;
}

export default function FormHeader({
  size = 'large',
  fontWeight = 600,
  text,
  disabled = false,
  pt = 5,
  pb = 5,
  mt = 0,
  mb = 0,
  style,
}: FormHeaderProps) {
  return (
    <Header
      size={size}
      disabled={disabled}
      style={{
        ...{
          fontWeight: fontWeight,
          paddingTop: pt,
          paddingBottm: pb,
          marginTop: mt,
          marginBottom: mb,
        },
        ...style,
      }}>
      {text}
    </Header>
  );
}
