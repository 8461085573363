import React from 'react';
import { Form, Button } from 'semantic';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [touched, setTouched] = React.useState(false);

  return (
    <Form
      error={touched}
      size="large"
      onSubmit={() => {
        setTouched(true);
        props.onSubmit({
          email,
        });
      }}>
      <Form.Input
        name="email"
        icon="envelope"
        iconPosition="left"
        placeholder={t('formPlaceHolder.email', 'E-mail Address')}
        type="text"
        autoComplete="email"
        onChange={(e, { value }) => setEmail(value.trim())}
      />
      <Form.Input>
        <Button
          fluid
          primary
          size="large"
          content={t('button.requestPassword', 'Request password')}
          loading={props.loading}
        />
      </Form.Input>
    </Form>
  );
};
