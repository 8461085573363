import React, { useContext } from 'react';
import { Table, Button } from 'semantic';
import { Search } from 'components';
import { useTranslation } from 'react-i18next';
import ResourceLink from 'components/Link/ResourceLink';
import { formatDateTime } from 'utils/date';

export default function FacilitatorLocationsTable() {
  const { items, reload } = useContext(Search.Context);
  const { t } = useTranslation();

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell width={3}>Account</Table.HeaderCell>
            <Table.HeaderCell width={3}>Created</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            return (
              <Table.Row key={item.id}>
                <Table.Cell>
                  <ResourceLink
                    route={`/charging-stations/locations/${item?.id}`}
                    searchItemProviderId={item?.providerId}
                    linkName={item?.name || 'No Name'}
                  />
                </Table.Cell>
                <Table.Cell>{item?.account?.name}</Table.Cell>
                <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}
