import React from 'react';

import { request } from 'utils/api';

import Table from 'components/tables/Sessions';
import { Search, ListHeader } from 'components';
import { Divider } from 'semantic';
import { withTranslation } from 'react-i18next';
import { safeFileName } from 'utils/formatting';

const itemLimit = 20;

class Sessions extends React.Component {
  onDataNeeded = (filters = {}) => {
    const { evseController } = this.props;
    const { limit } = filters;

    return request({
      method: 'POST',
      path: `/1/evse-controllers/${evseController.id}/sessions/search`,
      body: {
        ...filters,
        limit: limit || itemLimit,
      },
    });
  };

  render() {
    const { t } = this.props;
    return (
      <Search.Provider limit={itemLimit} onDataNeeded={this.onDataNeeded}>
        <ListHeader>
          <Search.ExportRange
            onRangeSubmit={async (from, to, filters) => {
              await this.onDataNeeded({
                format: 'csv',
                limit: 100000,
                endedAt: {
                  $gte: from,
                  $lte: to,
                },
                filename: safeFileName(`sessions`),
                ...filters,
              });
            }}
          />
        </ListHeader>
        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table includeStopAction includeSyncStatus />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </Search.Provider>
    );
  }
}

export default withTranslation()(Sessions);
