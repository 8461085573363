import React, { useEffect } from 'react';
import { request } from 'utils/api';

import { AppWrapper } from 'components';
import Search from './Search';

import { Container, Header, Table, Loader, Message, Button } from 'semantic';
import Wizard from './Wizard';
import Confetti from 'components/Confetti';
import { useTranslation } from 'react-i18next';

interface Data {
  ocppIdentity: string;
  serialNumber: string;
  bootInfo?: {
    chargePointVendor: string;
    chargePointModel: string;
  };
  id: string;
}

export default function SetupEvse() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [data, setData] = React.useState<Data | null>(null);
  const [hideWizardCloseIcon, setHideWizardCloseIcon] = React.useState(false);
  const [query, setQuery] = React.useState<string | null>(null);
  const [message, setMessage] = React.useState<string | null>(null);

  const { t } = useTranslation();

  async function search(query: string | null) {
    try {
      setLoading(true);
      setError(null);
      setQuery(query);
      const { data } = await request({
        method: 'POST',
        path: '/1/evse-controllers/lookup-unlinked',
        body: {
          serialNumber: query,
        },
      });
      setData(data);
    } catch (error) {
      setError(error as Error);
    }
    setLoading(false);
  }

  function closeListener(e: any) {
    e.preventDefault();
    return (e.returnValue =
      'Are you sure you want to close? Charge station setup is not completed yet');
  }

  function addAlertListener() {
    window.addEventListener('beforeunload', closeListener);
  }

  function removeAlertListener() {
    window.removeEventListener('beforeunload', closeListener);
  }

  useEffect(() => {
    return removeAlertListener;
  }, []);

  return (
    <AppWrapper>
      <Container style={{ position: 'relative' }}>
        <Header as="h2">{t('setupEvse.title', 'Setup New EVSE')}</Header>
        {message && (
          <div>
            <Message info content={message} />
            {message.match(/configured/) && <Confetti />}
          </div>
        )}
        <p>
          {t(
            'setupEvse.searchYourChargeStation',
            "Search the charge station you'd like to setup"
          )}
        </p>
        <Search
          value={query}
          placeholder={t(
            'setupEvse.searchPlaceholder',
            'Search by OCPP Identity or Serial Number'
          )}
          onChange={search}
        />
        {renderResult()}
      </Container>
    </AppWrapper>
  );

  function renderResult() {
    if (loading) return <Loader active />;
    if (error) return <Message error content={error.message} />;
    if (query && !data) {
      return (
        <Message
          content={t(
            'setupEvse.noRegistrationsFound',
            'No charge station registrations found with this OCPP Identity or Serial Number'
          )}
        />
      );
    }
    if (!data) return '';
    return (
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {t('setupEvse.ocppIdentity', 'OCPP Identity')}
            </Table.Cell>
            <Table.Cell>{data.ocppIdentity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('setupEvse.serialNumber', 'Serial Number')}
            </Table.Cell>
            <Table.Cell>{data.serialNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('setupEvse.vendorAndModel', 'Vendor / Model')}
            </Table.Cell>
            <Table.Cell>
              {data.bootInfo?.chargePointVendor} /{' '}
              {data.bootInfo?.chargePointModel}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={2}>
              <Wizard
                t={t}
                onDone={() => {
                  setMessage(
                    `Charge station ${data.ocppIdentity} has been configured`
                  );
                  setData(null);
                  setQuery('');
                  removeAlertListener();
                  setHideWizardCloseIcon(false);
                }}
                onClose={removeAlertListener}
                evseController={data}
                trigger={
                  <Button
                    onClick={addAlertListener}
                    primary
                    content={t('setupEvse.setupDevice', 'Setup Device')}
                    as="button"
                    fluid
                  />
                }
                closeIcon={!hideWizardCloseIcon}
                closeOnEscape={false}
                nextStep={(step: number) =>
                  step === 2 && setHideWizardCloseIcon(true)
                }
                onStart={() => setHideWizardCloseIcon(true)}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
