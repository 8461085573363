import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic';
import Lookup from './Steps/Lookup';
import Plans from './Steps/Plans';
import AccountBilling from './Steps/account-billing';
import Location from './Steps/Location';
import Details from './Steps/Details';
import DetailsInvite from './Steps/DetailsInvite';
import { WizardProvider } from './context';
import { useHistory } from 'react-router-dom';
import { useAccount } from 'contexts/account';
import { useFeatures } from 'contexts/features';

export default ({
  onClose = undefined,
  onSuccess = undefined,
  trigger = undefined,
  evseInvite = undefined,
  newStationSerialFromURL = undefined,
  isOpen = false,
}) => {
  const [open, setOpen] = React.useState(isOpen);
  const [step, setStep] = React.useState('lookup');
  const history = useHistory();
  const { isBillingUpdateRequired } = useAccount();
  const showBillingStepRef = React.useRef(false);

  const { t } = useTranslation();
  const { hasFeature, isReady } = useFeatures();

  // Order of steps is important
  const steps = ['lookup', 'plans', 'location', 'details'];

  const showAccountBillingForm =
    isReady &&
    hasFeature('account_billing_new_charge_station') &&
    (isBillingUpdateRequired || showBillingStepRef.current);

  if (showAccountBillingForm) {
    showBillingStepRef.current = true;
    steps.splice(2, 0, 'billing');
  }

  useEffect(() => {
    if (newStationSerialFromURL) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  function onBack() {
    const currentStep = steps.indexOf(step);
    setStep(steps[currentStep - 1]);
  }

  const setNextStep = () => {
    if (!steps.includes(step) || steps.indexOf(step) === -1) {
      setStep('lookup');
      return;
    }

    const currentStepIndex = steps.indexOf(step);
    const nextStep = steps[currentStepIndex + 1];
    setStep(nextStep);
  };

  const stepHeader =
    t('newChargingStation.header', 'New Charging Station - Step') +
    ' ' +
    (steps.indexOf(step) + 1) +
    '/' +
    steps.length +
    ': ';

  function renderDetails() {
    if (evseInvite) {
      return (
        <DetailsInvite
          stepHeader={stepHeader}
          evseInvite={evseInvite}
          onBack={onBack}
          onDone={(location) => {
            onSuccess?.();
            showBillingStepRef.current = isBillingUpdateRequired;
            history.push(`/locations/${location.id}`);
          }}
        />
      );
    }

    return (
      <Details
        stepHeader={stepHeader}
        onBack={onBack}
        onDone={(location) => {
          onSuccess?.();
          showBillingStepRef.current = isBillingUpdateRequired;
          history.push(`/locations/${location.id}`);
        }}
      />
    );
  }

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <WizardProvider>
        {step === 'lookup' && (
          <Lookup
            stepHeader={stepHeader}
            onDone={() => setNextStep()}
            defaultSerialNumber={newStationSerialFromURL}
          />
        )}
        {step === 'plans' && (
          <Plans
            stepHeader={stepHeader}
            onBack={onBack}
            onDone={() => setNextStep()}
          />
        )}
        {step === 'billing' && (
          <AccountBilling
            stepHeader={stepHeader}
            onBack={onBack}
            onDone={() => setNextStep()}
          />
        )}
        {step === 'location' && (
          <Location
            stepHeader={stepHeader}
            evseInvite={evseInvite}
            onBack={onBack}
            onDone={() => setNextStep()}
          />
        )}

        {step === 'details' && renderDetails()}
      </WizardProvider>
    </Modal>
  );
};
