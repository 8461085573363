import React from 'react';
import {
  Card,
  Divider,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Message,
  Button,
} from 'semantic';

import ExpandableJson from 'components/ExpandableJson';
import ResultCard from './ResultCard';
import { formatDateTime, fromNow } from 'utils/date';

export default class DataList extends React.Component {
  render() {
    const items = this.props.items.reverse();

    return (
      <div className="list">
        {!items.length && <Message>No tests created yet</Message>}
        {items.map((item) => {
          return (
            <Card key={item.id} fluid>
              <Card.Content>
                <Card.Header>
                  <Button
                    icon="trash"
                    floated="right"
                    basic
                    onClick={() => this.props.onDelete(item)}
                  />
                  {formatDateTime(item.createdAt)}
                </Card.Header>
                <Card.Description>
                  <p>{item.comment}</p>
                  <Grid columns={3}>
                    <GridRow>
                      <GridColumn>
                        <Header sub>Start at</Header>
                        {item.startAt}
                      </GridColumn>
                      <GridColumn>
                        <Header sub>OCPP url</Header>
                        {item.ocppUrl}
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn>
                        <Header sub>Chargepoints per node</Header>
                        {item.numChargepoints}
                      </GridColumn>
                      <GridColumn>
                        <Header sub>Messages per Chargepoint per node</Header>
                        {item.messagesPerChargepoint}
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn>
                        <Header sub>Timeout between commands</Header>
                        {item.timeoutBetweenCommands}
                      </GridColumn>
                      <GridColumn>
                        <Header sub>Timeout after connection</Header>
                        {item.timeoutAfterConnection}
                      </GridColumn>
                    </GridRow>
                  </Grid>

                  {item.results.map((result) => (
                    <ResultCard key={result.hostname} result={result} />
                  ))}

                  <ExpandableJson object={item} />
                </Card.Description>
                <Card.Meta>{fromNow(item.createdAt)}</Card.Meta>
                <Divider />
              </Card.Content>
            </Card>
          );
        })}
      </div>
    );
  }
}
