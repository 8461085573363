import React, { useEffect } from 'react';

import EditRole from 'components/modals/EditRole';
import { currentUserCanAccess } from 'utils/roles';

import { Divider, Button } from 'semantic';

import { Breadcrumbs, Search, ListHeader } from 'components';

import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import Table from './Table';

class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  onDataNeeded = async (params) => {
    const [list, { data: providers }] = await Promise.all([
      request({
        method: 'POST',
        path: '/1/roles/search',
        body: { all: true, ...params },
      }),
      request({
        method: 'POST',
        path: '/1/providers/search',
      }),
    ]);

    list.data.map((role) => {
      if (role.providerId) {
        role.provider = providers.find(
          (provider) => provider.id === role.providerId
        );
      }
      return role;
    });

    return list;
  };

  render() {
    const { t } = this.props;
    const writeAccess = currentUserCanAccess('roles', 'write');

    return (
      <Search.Provider ref={this.searchRef} onDataNeeded={this.onDataNeeded}>
        <>
          <Breadcrumbs active={t('roles.title', 'Roles')} />

          <ListHeader title={t('roles.title', 'Roles')}>
            <EditRole
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '-5px' }}
                  content={t('roles.newRole', 'New Role')}
                  icon="plus"
                  disabled={!writeAccess}
                />
              }
              onSave={() => this.searchRef.current.reload()}
            />
          </ListHeader>

          <Divider hidden />
          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table />
          <Divider hidden />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </>
      </Search.Provider>
    );
  }
}

export default withTranslation()(Roles);
