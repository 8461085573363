import React from 'react';
import styles from './SocialLoginButton.module.css';
import { OID_REDIRECT_BASE_API_URL } from 'utils/env';

interface Props {
  slug: string;
  issuer: string;
}

const SocialLoginButton = ({ issuer, slug }: Props) => {
  return (
    <a
      className={styles.socialLoginButton}
      href={new URL(
        `/1/auth/${slug}/${issuer}/login`,
        OID_REDIRECT_BASE_API_URL
      ).toString()}
      key={issuer}>
      Sign in with {issuer}
    </a>
  );
};

export default SocialLoginButton;
