import { request } from 'utils/api';

export const getAccountingSystems = async () => {
  return await request({
    method: 'GET',
    path: '/1/invoices/accounting-systems',
  });
};

export const getAccountingSystemOptions = async () => {
  const accountingSystems = await getAccountingSystems();
  return accountingSystems.map((system) => ({
    key: system.name,
    text: system.title,
    value: system.name,
  }));
};
