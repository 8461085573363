import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal, ModalProps } from 'semantic-ui-react';
import { ModalProvider } from 'components/modal/ModalContext';

interface WrappedModalProps extends ModalProps {
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * API Design is borrowed from
 * MUI:        https://mui.com/material-ui/react-modal/
 * chakra-ui: https://v2.chakra-ui.com/docs/components/modal/usage
 *
 * Keeping our code in line with other design system.
 * For usage example please check the story in this dir
 */
const WrappedModal = (props: WrappedModalProps) => {
  const { open = false, onClose, onOpen, isOpen, setIsOpen, ...rest } = props;

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) onClose();
  };

  const handleOpen = () => {
    setIsOpen(true);
    if (onOpen) onOpen();
  };

  const trapNativeEvents = (evt: React.MouseEvent | React.KeyboardEvent) => {
    evt.stopPropagation();
  };

  return (
    <ModalProvider closeModal={handleClose}>
      <Modal
        onClick={trapNativeEvents}
        onFocus={trapNativeEvents}
        onBlur={trapNativeEvents}
        onMouseDown={trapNativeEvents}
        onMouseUp={trapNativeEvents}
        onKeyDown={trapNativeEvents}
        closeOnDimmerClick={false}
        closeIcon
        onOpen={handleOpen}
        onClose={handleClose}
        open={isOpen}
        {...rest}>
        {props.children}
      </Modal>
    </ModalProvider>
  );
};

export default WrappedModal;
