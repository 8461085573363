import React, { useState, useContext, useEffect } from 'react';
import {
  Header,
  Message,
  Button,
  Divider,
  Container,
  Table,
  Loader,
} from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { UserContext } from 'contexts/user';
import { ENV_NAME } from 'utils/env';
import { request } from '../../utils/api';
import { startCase } from 'lodash-es';
import { parseToken } from '../../utils/token';
import { getToken } from '../../utils/api';
import { Trans, useTranslation } from 'react-i18next';

export function determineOcppBaseUrl() {
  const parsed = parseToken(getToken());
  let sandboxMode = !!parsed?.sandboxMode;
  switch (ENV_NAME) {
    case 'development':
      return 'ws://localhost:2600';
    case 'production':
      return sandboxMode ? 'wss://sandbox-ocpp.road.io' : 'wss://ocpp.road.io';
    case 'staging':
      return sandboxMode
        ? 'wss://sandbox-ocpp.road.dev'
        : 'wss://ocpp.public.road.dev';
  }
}

function determineOcppIdentity(user) {
  const providerPrefix = startCase(window.provider.name).replace(/\s/g, '');
  if (!user || !user.accountName) return `${providerPrefix}ChargeStationSim`;
  return (
    providerPrefix + startCase(user.accountName).replace(/\s/g, '') + 'Sim'
  );
}

const ChargeStationSimulator = () => {
  const { provider, user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tokens, setTokens] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchMaintenanceTokens();
  }, []);

  const fetchMaintenanceTokens = async () => {
    try {
      setLoading(true);

      const { data: tokens } = await request({
        method: 'POST',
        path: '/1/tokens/search/fast',
        body: {
          hasMaintenanceAccess: true,
        },
      });
      setTokens(tokens);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const ocppBaseUrl = determineOcppBaseUrl() + '/1.6/' + provider.slug;
  const ocppIdentity = determineOcppIdentity(user);

  const renderMaintenanceTokens = ({ provider, ocppIdentity, ocppBaseUrl }) => {
    if (loading || !tokens) return <Loader active inline />;
    if (error) {
      return <Message error content={error.message} />;
    }
    if (!tokens.length) {
      return <Message content="No maintenance tokens created yet." />;
    }
    return (
      <div>
        <Table collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>OCPP Identity</Table.HeaderCell>
              <Table.HeaderCell>Provider</Table.HeaderCell>
              <Table.HeaderCell>Token Holder</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tokens.slice(0, 5).map((token) => {
              return (
                <Table.Row key={token.id}>
                  <Table.Cell>{ocppIdentity}</Table.Cell>
                  <Table.Cell>{provider.name}</Table.Cell>
                  <Table.Cell>{token.user?.name}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button
                      as="a"
                      primary
                      content="Launch Simulator"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://chargestation.one?ocppBaseUrl=${ocppBaseUrl}&Identity=${ocppIdentity}&uid=${token.uid}`}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  };

  return (
    <>
      <AppWrapper>
        <Container>
          <Breadcrumbs
            active={t(
              'chargeStationSimulator.title',
              'Charge Station Simulator'
            )}
          />
        </Container>

        <Divider hidden />
        <Header as="h2">
          {t('chargeStationSimulator.title', 'Charge Station Simulator')}
        </Header>
        <p>
          <Trans i18nKey="chargeStationSimulator.simulatorDescription">
            You can use our{' '}
            <a
              href="https://github.com/e-flux-platform/chargestation"
              target="_blank"
              rel="noopener noreferrer">
              open source
            </a>{' '}
            charge station simulator to emulate a physical charge station. Using
            Chargestation.one you can create sessions, simulate failures or
            other usage patterns.
          </Trans>
        </p>

        <Table collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t('chargeStationSimulator.ocppIdentity', 'OCPP Identity')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('chargeStationSimulator.provider', 'Provider')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('chargeStationSimulator.action', 'Action')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{ocppIdentity}</Table.Cell>
              <Table.Cell>{provider.name}</Table.Cell>
              <Table.Cell textAlign="center">
                <Button
                  as="a"
                  primary
                  content={t(
                    'chargeStationSimulator.launchSimulator',
                    'Launch Simulator'
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://chargestation.one?ocppBaseUrl=${ocppBaseUrl}&Identity=${ocppIdentity}`}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <p>
          <Trans i18nKey="chargeStationSimulator.connectedSimulator">
            Once your simulator is connected, your station's OCPP Identity will
            show up in the{' '}
            <Link to="/charging-stations">Charging Stations List</Link>.
          </Trans>
        </p>

        <Header as="h4">
          {t('chargeStationSimulator.maintenanceTokens', 'Maintenance Tokens')}
        </Header>

        <p>
          {t(
            'chargeStationSimulator.openSimulatorSession',
            'Alternatively, you can open a simulator session for one of these maintenance tokens:'
          )}
        </p>

        {renderMaintenanceTokens({ provider, ocppIdentity, ocppBaseUrl })}
      </AppWrapper>
    </>
  );
};

export default ChargeStationSimulator;
