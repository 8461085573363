import React from 'react';
import { Segment, Divider, Button } from 'semantic';
import CreateJob from './CreateJob';
import Table from './Table';

import { request } from 'utils/api';

import { Layout, Search, ListHeader } from 'components';

import { withTranslation } from 'react-i18next';

class FinanceExportsList extends React.Component {
  constructor(props) {
    super(props);
    this.searchRef = React.createRef();
  }

  onDataNeeded = async () => {
    return request({
      method: 'POST',
      path: '/1/finance/exports/jobs/search',
    });
  };

  render() {
    const { t } = this.props;

    const searchRef = this.searchRef;

    return (
      <Search.Provider ref={this.searchRef} onDataNeeded={this.onDataNeeded}>
        {({ items: jobs }) => {
          return (
            <React.Fragment>
              <ListHeader title={t('financeExports.exportJobs', 'Export Jobs')}>
                <CreateJob
                  trigger={
                    <Button primary icon="download" content={'Create Job'} />
                  }
                  onSave={() => searchRef.current?.reload()}
                />
              </ListHeader>

              <Segment>
                <Layout horizontal spread stackable>
                  <Layout horizontal stackable center right>
                    <Search.Total />
                  </Layout>
                </Layout>
              </Segment>

              <Search.Status noItems={t('common.noResults', 'No Results')} />
              {jobs.length !== 0 && <Table onDelete={this.onDelete} />}
              <Divider hidden />
              <div
                style={{
                  textAlign: 'center',
                }}>
                <Search.Pagination />
              </div>
            </React.Fragment>
          );
        }}
      </Search.Provider>
    );
  }
}

export default withTranslation()(FinanceExportsList);
