import React, { useState, useEffect } from 'react';
import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import SearchDropdown from 'components/form-fields/SearchDropdown';

type ResetEvseProps = {
  locationId?: string;
  trigger?: any;
  onDone?: (data: any) => void;
  initialValues: {
    evseControllerId?: string;
  };
};
const ResetEvse = (props: ResetEvseProps) => {
  const { locationId, initialValues, trigger, onDone } = props;

  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.initialValues !== initialValues) {
      setLoading(false);
      setFormValues({ ...initialValues });
    }
  }, [props.initialValues, initialValues]);

  const handleSubmit = async () => {
    const { evseControllerId } = initialValues;
    const path = evseControllerId
      ? `/1/evse-controllers/${evseControllerId}/action`
      : `/2/locations/cpo/${locationId}/action`;

    setLoading(true);

    try {
      const data = await request({
        method: 'POST',
        path,
        body: {
          method: 'Reset',
          evseControllerId: formValues.evseControllerId,
        },
      });

      if (onDone) {
        onDone(data);
      }
      setFormValues(initialValues);
      setOpen(false);
    } catch (e) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const setField = (name: string, value: any) => {
    setLoading(false);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        setOpen(false);
        setFormValues(initialValues);
        setLoading(false);
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>Reset</Modal.Header>
      <Modal.Content>
        <p>
          By executing this command, the charging station controller will
          perform a hard reset. Charging operations may be disrupted during this
          operation.
        </p>
        <Form error={loading && Boolean(error)} onSubmit={handleSubmit}>
          {error && <Message error content={error.message} />}

          {!initialValues.evseControllerId && (
            <Form.Field>
              <label>EVSE Controller</label>
              <SearchDropdown
                value={formValues.evseControllerId}
                getOptionLabel={(option: any) => option.ocppIdentity}
                objectMode={false}
                onDataNeeded={(body: any) => {
                  return request({
                    path: `/2/locations/cpo/${locationId}/evse-controllers/search`,
                    method: 'POST',
                    body: body,
                  });
                }}
                onChange={(e: any, { value }: { value?: any }) =>
                  setField('evseControllerId', value)
                }
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content="Execute"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

ResetEvse.defaultProps = {
  initialValues: {},
};

export default ResetEvse;
