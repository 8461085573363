import React from 'react';
import { Form } from 'semantic';
import { FieldValidator, useField } from 'formik';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  validate?: FieldValidator;
  validateImmediately?: boolean;
  disabled?: boolean;
  hideErrorLabel?: boolean;
}

export default function PhoneNumber({
  name,
  validate,
  validateImmediately,
  hideErrorLabel,
  ...rest
}: Props) {
  const [field, meta, helpers] = useField({ name, validate });
  const hasTouched = validateImmediately ? true : meta.touched;

  return (
    <Form.Input
      style={{ flex: 1 }}
      value={field.value}
      error={
        hasTouched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
      name={name}
      type="text"
      fluid
      onChange={(e, { value }) => {
        helpers.setValue(value, true);
      }}
      onBlur={() => helpers.setTouched(true)}
      {...rest}
    />
  );
}
