import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LocationsMenu({ itemId }) {
  const { t } = useTranslation();
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('locationsMenu.overview', 'Overview')}
          to={`/cards/locations/${itemId}`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.connectors', 'Connectors')}
          to={`/cards/locations/${itemId}/connectors`}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
}
