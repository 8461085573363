import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppWrapper,
  Confirm,
  Filters,
  ListHeader,
  SearchProvider,
} from 'components';
import {
  formatCdrSourceCell,
  formatCdrStatusCell,
  formatKwh,
} from 'utils/formatting';
import { simpleOptions } from 'utils/form';
import { request } from 'utils/api';
import { Container, Divider, Grid, Segment, Table, Button } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatDuration } from 'utils/date';
import { uniq } from 'lodash-es';
import { CdrSources, CdrStatuses } from 'utils/constants';
import PricingTool from 'components/modals/PricingTool';
import Currency from 'components/Currency';

const itemLimit = 100;

export default function Cdrs() {
  const { t } = useTranslation();
  const writeAccess = currentUserCanAccessProviderEndpoint('cdrs', 'write');
  const [selectedIds, setSelectedIds] = useState([]);

  async function handleRejectToggledItems(ids) {
    const promises = ids.map((itemId) => ({
      itemId,
      operation: async () =>
        await request({
          method: 'POST',
          path: `/1/cdrs/${itemId}/status`,
          body: { status: CdrStatuses.REJECTED },
        }),
    }));

    const errors = [];
    for (const { operation, itemId } of promises) {
      try {
        await operation();
      } catch (error) {
        errors.push({ itemId, error });
      }
    }

    if (errors.length) {
      const messages = errors
        .map((e) => `${e.itemId}: ${e.error.message}`)
        .join('\n');
      throw new Error(
        `Failed to reject ${errors.length} out of ${ids.length} CDRS:\n ${messages}`
      );
    }
  }

  async function handleOnDataNeeded(filters) {
    return request({
      method: 'POST',
      path: `/1/cdrs/search`,
      body: { ...filters },
    });
  }

  const labels = {
    rejectionReason: t('cdrs.rejectionReason', 'Rejection Reason'),
    onlyPending: t('cdrs.onlyPending', 'Only Pending'),
    onlyAccepted: t('cdrs.onlyAccepted', 'Only Accepted'),
    onlyRejected: t('cdrs.onlyRejected', 'Only Rejected'),
    onlyBilled: t('cdrs.onlyBilled', 'Only Billed'),
    status: t('cdrs.status', 'Status'),
    source: t('cdrs.source', 'Source'),
    endTime: t('cdrs.endTime', 'End Time'),
  };

  return (
    <AppWrapper>
      <Container>
        <SearchProvider
          limit={itemLimit}
          fast
          onDataNeeded={handleOnDataNeeded}>
          {({ items, reload, loading }) => (
            <>
              <Breadcrumbs
                path={[
                  <Link key="cards" to="/cards">
                    {t('cards.title', 'Cards')}
                  </Link>,
                ]}
                active={t('cdrs.title', 'CDRS')}
              />
              <ListHeader title={t('cdrs.title', 'CDRS')}>
                <PricingTool
                  onClose={reload}
                  trigger={
                    <Button
                      primary
                      content={t('cdrs.pricingTool', 'Pricing Tool')}
                      icon="upload"
                      disabled={!writeAccess}
                    />
                  }
                />
              </ListHeader>

              <Divider hidden />
              <Segment>
                <Grid>
                  <Grid.Row width={16}>
                    <Grid.Column width={12}>
                      <Filters.Modal>
                        <Filters.Dropdown
                          name="status"
                          label={labels.status}
                          options={simpleOptions([
                            CdrStatuses.PENDING,
                            CdrStatuses.ACCEPTED,
                            CdrStatuses.REJECTED,
                            CdrStatuses.BILLED,
                          ])}
                        />

                        <Filters.Dropdown
                          name="rejectionReason"
                          label={labels.rejectionReason}
                          options={simpleOptions([
                            CdrStatuses.PENDING,
                            CdrStatuses.ACCEPTED,
                            CdrStatuses.REJECTED,
                            CdrStatuses.BILLED,
                          ])}
                        />

                        <Filters.Dropdown
                          name="source"
                          options={simpleOptions(
                            Object.keys(CdrSources).map(
                              (key) => CdrSources[key]
                            )
                          )}
                          label={labels.source}
                        />

                        {/*<Filters.NumberRange label={labels.kwh} name="kwh" />*/}
                        {/*<Filters.NumberRange*/}
                        {/*  label={labels.externalCalculatedPrice}*/}
                        {/*  name="externalCalculatedPrice"*/}
                        {/*/>*/}
                        <Filters.DateRange
                          label={labels.endTime}
                          name="endTime"
                        />
                      </Filters.Modal>
                      <Filters.Overview labels={labels} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Filters.Search
                        fluid
                        placeholder={t(
                          'cdrs.searchPlaceholder',
                          'Search by cdrId, externalCdrId or contractId'
                        )}
                        name="searchPhrase"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              <SearchProvider.Status
                noItems={t('common.noResults', 'No Results')}
              />

              <div>
                {!loading && items.length > 0 && (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {t('cdrsTable.externalCdrId', 'CDR ID')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('cdrsTable.totalKwh', 'Total Energy (kWh)')}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          {t('cdrsTable.totalKwh', 'Cost')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('cdrsTable.duration', 'Duration')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t('cdrsTable.status', 'Status')}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {items.map((item) => {
                        const startDate = new Date(item.startTime);
                        const endDate = new Date(item.endTime);
                        const durationSeconds = (endDate - startDate) / 1000;
                        return (
                          <Table.Row key={item.id}>
                            <Table.Cell>
                              <Link to={`/cards/cdrs/${item.id}`}>
                                <small>{item.externalCdrId}</small>
                              </Link>
                            </Table.Cell>
                            <Table.Cell>
                              {formatKwh(parseFloat(item.totalKwh))}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              <Currency
                                value={item?.cost?.totalCost}
                                currency={item?.cost?.currency}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {formatDuration(durationSeconds, t)}
                              <p>
                                <small>
                                  <b>{t('common.start', 'Start')}</b>:{' '}
                                  {formatDateTime(item.startTime)}
                                </small>
                                <br />
                                <small>
                                  <b>{t('common.end', 'End')}</b>:{' '}
                                  {formatDateTime(item.endTime)}
                                </small>
                              </p>
                            </Table.Cell>

                            <Table.Cell>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}>
                                {formatCdrStatusCell(item.status)}
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}

                <SearchProvider.Pagination />

                <Divider hidden />
                <div>
                  {selectedIds.length > 0 && (
                    <Confirm
                      header={t(
                        'cdrs.rejectMultipleHeader',
                        'Are you sure you want to reject {{count}} CDRS?',
                        { count: selectedIds.length }
                      )}
                      content={t(
                        'cdrs.rejectMultipleContent',
                        'All the statuses will be changed to rejected.'
                      )}
                      trigger={
                        <Button
                          content={t(
                            'cdrs.rejectMultipleTrigger',
                            `Reject all {{count}} items`,
                            { count: selectedIds.length }
                          )}
                          color="red"
                          icon="trash"
                          title="Reject"
                        />
                      }
                      onConfirm={async () => {
                        await handleRejectToggledItems(selectedIds);
                        await reload();
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </SearchProvider>
      </Container>
    </AppWrapper>
  );
}
