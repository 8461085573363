import React from 'react';
import PortalWrapper from 'components/PortalWrapper';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import ChargeStations from './ChargeStations';
import Cards from './Cards';
import Roaming from './Roaming';
import LocationData from './LocationData';
import Reference from './Reference';
import MapSDK from './MapSDK';

export default class Docs extends React.Component {
  render() {
    return (
      <PortalWrapper>
        <Switch>
          <Route
            exact
            path="/docs"
            component={(props) => <Home {...props} />}
          />
          <Route
            exact
            path="/docs/charge-stations/:id?"
            component={(props) => <ChargeStations {...props} />}
          />
          <Route
            exact
            path="/docs/cards"
            component={(props) => <Cards {...props} />}
          />
          <Route
            exact
            path="/docs/roaming"
            component={(props) => <Roaming {...props} />}
          />
          <Route
            exact
            path="/docs/location-data"
            component={(props) => <LocationData {...props} />}
          />
          <Route
            exact
            path="/docs/location-map"
            component={(props) => <MapSDK {...props} />}
          />
          <Route
            exact
            path="/docs/reference/:id?"
            component={(props) => <Reference {...props} />}
          />
        </Switch>
      </PortalWrapper>
    );
  }
}
