import React, { useEffect } from 'react';
import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SearchDropdown from 'components/form-fields/SearchDropdown';

export default function AssignTariffProfile(
  props = { chargingStationIds: [], initialValues: {} }
) {
  const [open, setOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    ...(props.initialValues || {}),
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setSubmitted(false);
    setLoading(false);
    setFormValues({ ...(props.initialValues || {}) });
  }, [props.initialValues]);

  const handleSubmit = async () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);
    try {
      await request({
        method: 'POST',
        path: '/1/evse-controllers/bulk/assign-tariff-profile',
        body: {
          tariffProfileId: formValues.tariffProfileId,
          evseControllerIds: props.chargingStationIds,
        },
      });
      props.onDone();
      setFormValues({});
      setOpen(false);
    } catch (error) {
      setError(error);
    } finally {
      setSubmitted(false);
      setLoading(false);
    }
  };

  function setField(name, value) {
    setSubmitted(false);
    setFormValues((prevFormValues) => {
      let updatedFormValues;
      updatedFormValues = {
        ...prevFormValues,
        [name]: value,
      };
      return updatedFormValues;
    });
  }

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={props.trigger}
      onClose={() => {
        setOpen(false);
        setFormValues(props.initialValues || {});
        setSubmitted(false);
      }}
      onOpen={() => {
        setOpen(true);
        setError(null);
      }}
      open={open}>
      <Modal.Header>Assign Tariff Profile</Modal.Header>
      <Modal.Content>
        <p>
          {t(
            'assignTariffProfileModal.doYouWant',
            'Do you want to assign the tariff profile to the selected charging stations?'
          )}
        </p>
        <Message
          compact
          content={
            <span>
              {t('assignTariffProfileModal.selected', 'Selected: ')}
              <b>
                {props.chargingStationIds.length}
                {t(
                  'assignTariffProfileModal.chargingStations',
                  ' charging stations'
                )}
              </b>
            </span>
          }
        />
        <Form
          id="confirm"
          error={submitted && Boolean(error)}
          onSubmit={() => handleSubmit()}>
          {error && <Message error content={error.message} />}
          <Form.Field required>
            <label>
              {t('assignTariffProfileModal.tariffProfile', 'Tariff Profile')}
            </label>
            <SearchDropdown
              keywordField="searchPhrase"
              objectMode={false}
              getOptionLabel={(item) => item.name}
              onDataNeeded={(body) => {
                return request({
                  method: 'POST',
                  path: '/1/tariff-profiles/search',
                  body: {
                    ...body,
                    limit: 50,
                    sort: {
                      field: 'name',
                      order: 'asc',
                    },
                  },
                });
              }}
              onChange={(e, { value }) => setField('tariffProfileId', value)}
            />
          </Form.Field>
        </Form>
        <Message
          warning
          content={t(
            'assignTariffProfileModal.note',
            'Note: All previously assigned tariffs and tariff profiles will be overwritten for selected charging stations'
          )}
        />
      </Modal.Content>
      <Modal.Actions>
        <Link
          to="/charging-stations/tariff-profiles"
          target="_blank"
          title="Open Tariff Profiles"
          style={{
            marginRight: '24em',
            color: 'var(--primary-color)',
          }}>
          Open Tariff Profiles
        </Link>
        <Button
          basic
          content={t('assignTariffProfileModal.cancel', 'Cancel')}
          onClick={() => setOpen(false)}
        />
        <Button
          loading={loading || submitted}
          primary
          content={t('assignTariffProfileModal.confirm', 'Confirm')}
          form="confirm"
        />
      </Modal.Actions>
    </Modal>
  );
}
