import React from 'react';

import { Button, Form, TextArea, Modal, Message } from 'semantic';
import { emailRegexp } from 'utils/validate';
import { request } from 'utils/api';
import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';
import { currentUserIsSuperAdmin } from 'utils/roles';

const defaultState = () => {
  return {
    open: false,
    validEmails: [],
    invalidEmails: [],
    touched: false,
    error: null,
  };
};

class InviteUser extends React.Component {
  state = {
    validEmails: [],
    invalidEmails: [],
    accountRoles: null,
    loading: false,
  };

  componentDidMount() {
    this.fetch();
  }

  handleTextAreaChange = (e, { value }) => {
    const values = value
      .toLowerCase()
      .split(/[\s,;\t\n]+/)
      .map((str) => str.trim())
      .filter(Boolean);

    const validEmails = [];
    const invalidEmails = [];
    values.forEach((text) => {
      if (text.match(emailRegexp)) {
        validEmails.push(text);
      } else {
        invalidEmails.push(text);
      }
    });

    this.setState({
      validEmails,
      invalidEmails,
    });
  };

  async fetch() {
    try {
      const { data: accountRoles } = await request({
        method: 'POST',
        path: `/1/roles/search`,
        body: {
          context: 'account',
        },
      });
      const { data: providerRoles } = await request({
        method: 'POST',
        path: `/1/roles/search`,
        body: {
          context: 'provider',
        },
      });
      this.setState({ accountRoles, providerRoles, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  handleSubmit = async () => {
    this.setState({
      touched: true,
    });

    try {
      const { accountRole, providerRole, validEmails } = this.state;
      await request({
        method: 'POST',
        path: `/1/invites`,
        body: {
          accountRoleId: accountRole,
          providerRoleId: providerRole,
          emails: validEmails,
          accountId: this.props.account.id,
        },
      });
      this.setState(defaultState());
      document.location.reload();
    } catch (error) {
      this.setState({ error, loading: false });
    }
  };

  render() {
    const { accountRoles, providerRoles, validEmails, error, loading } =
      this.state;

    return (
      <>
        <Modal.Header>Invite User</Modal.Header>
        <Modal.Content>
          {error && <Message error content={error.message} />}
          <Form onSubmit={this.handleSubmit} className={this.props.className}>
            {accountRoles && (
              <Form.Dropdown
                required
                selection
                name="accountRole"
                label="Account Role"
                options={accountRoles.map((accountRole) => {
                  return {
                    key: accountRole.id,
                    text: accountRole.name,
                    value: accountRole.id,
                  };
                })}
                onChange={(e, { value }) => {
                  this.setState({ accountRole: value });
                }}
              />
            )}
            {currentUserIsSuperAdmin() && providerRoles && (
              <Form.Dropdown
                selection
                name="providerRole"
                label="Provider Role"
                options={providerRoles.map((providerRole) => {
                  return {
                    key: providerRole.id,
                    text: providerRole.name,
                    value: providerRole.id,
                  };
                })}
                onChange={(e, { value }) => {
                  this.setState({ providerRole: value });
                }}
              />
            )}

            <Form.Field>
              <label>Enter email address of the participant to invite</label>
              <Form.TextArea
                required
                style={{ minHeight: '70px' }}
                name="emails"
                onChange={this.handleTextAreaChange}
                placeholder="Email address seperate by comma or newline .e.g first@gmail.com, second@gmail.com"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.props.onClose}>
            Close
          </Button>
          <Button
            onClick={this.handleSubmit}
            primary
            disabled={validEmails.length === 0}
            loading={loading}
            type="submit">
            Invite Members {validEmails.length ? `(${validEmails.length})` : ''}
          </Button>
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(InviteUser));
