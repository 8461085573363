import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';

import { withTranslation } from 'react-i18next';
import modal from 'helpers/modal';

class EditProviderCertificate extends React.Component {
  static defaultProps = {
    initialValues: {},
  };

  state = {
    open: false,
    formValues: {},
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId) {
      this.setState({
        submitted: false,
        formValues: {},
      });
      this.fetch();
    }
  }

  fetch() {
    return request({
      method: 'GET',
      path: `/1/providers/${this.props.providerId}/certificate`,
    })
      .then(({ data }) => {
        this.setState({ formValues: { ...data }, loading: false, error: null });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  handleSubmit = () => {
    this.setState({
      loading: true,
    });

    return request({
      method: 'PATCH',
      path: `/1/providers/${this.props.providerId}/certificate`,
      body: {
        ...this.state.formValues,
      },
    })
      .then(() => {
        this.setState({
          loading: false,
          error: null,
        });
        this.props.close();
        this.props.onSave();
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    this.setState({
      submitted: false,
      formValues: {
        ...this.state.formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { t } = this.props;
    const { formValues = {}, loading, error } = this.state;

    return (
      <>
        <Modal.Header>
          {t('editProviderCertificate.header', 'Configure Certificate')}
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.TextArea
              value={formValues.privateKey}
              name="privateKey"
              label={t('editProviderCertificate.privateKey', 'Private Key')}
              type="text"
              placeholder={`-----BEGIN PRIVATE KEY-----\n...\n-----END PRIVATE KEY-----`}
              required
              onChange={(e, { name, value }) => {
                this.setField(name, value);
              }}
            />

            <Form.TextArea
              value={formValues.certificate}
              name="certificate"
              label={t(
                'editProviderCertificate.certificatePublic',
                'Certificate (Public)'
              )}
              type="text"
              placeholder={`-----BEGIN CERTIFICATE-----\n...\n-----END CERTIFICATE-----`}
              required
              onChange={(e, { name, value }) => {
                this.setField(name, value);
              }}
            />

            <Form.TextArea
              value={formValues.certificateChain}
              name="certificateChain"
              label={t(
                'editProviderCertificate.certificateChain',
                'Certificate Chain (CA Bundle)'
              )}
              type="text"
              placeholder={`-----BEGIN CERTIFICATE REQUEST-----\n...\n-----END CERTIFICATE REQUEST-----`}
              required
              onChange={(e, { name, value }) => {
                this.setField(name, value);
              }}
            />

            <Form.Checkbox
              label={t('editProviderCertificate.isDisabled', 'Is Disabled?')}
              name="disabled"
              checked={formValues.disabled || false}
              onChange={(e, { name, checked }) => this.setField(name, checked)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={t('editProviderCertificate.save', 'Save')}
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </>
    );
  }
}

export default modal(withTranslation()(EditProviderCertificate));
