import React, { CSSProperties } from 'react';
import { SpacingValue } from 'types/layout';
import { resolveSpacingValue } from './utils';

interface SpaceProps {
  /**
   * Controls the size of the space.
   * @default 5
   */
  size?: SpacingValue;
  /**
   * Controls the direction of the space.
   * @default 'horizontal'
   */
  direction?: 'horizontal' | 'vertical';
}

const DEFAULT_SPACE_VALUE_IN_PX = 5;

/**
 * A component that adds empty space with a specified size and direction.
 *
 * @component
 * @param {SpaceProps} props - The props for the Space component.
 * @param {SpacingValue} props.size - Controls the size of the space.
 * @param {'horizontal' | 'vertical'} props.direction - Controls the direction of the space.
 * @returns {JSX.Element} An empty space element.
 * @example
 * // Basic usage
 * <Space />
 *
 * // Horizontal space with larger size
 * <Space size={20} />
 *
 * // Vertical space
 * <Space direction="vertical" size={10} />
 */
export default function Space({
  direction = 'horizontal',
  size = DEFAULT_SPACE_VALUE_IN_PX,
}: SpaceProps) {
  const spaceStyle: CSSProperties = {
    width:
      direction === 'horizontal'
        ? resolveSpacingValue(size, DEFAULT_SPACE_VALUE_IN_PX)
        : 'auto',
    height:
      direction === 'vertical'
        ? resolveSpacingValue(size, DEFAULT_SPACE_VALUE_IN_PX)
        : 'auto',
    display: 'inline-block',
  };
  return <div style={spaceStyle} />;
}
