import React, { useRef, useState } from 'react';
import CreateProviderModal from 'components/modals/CreateProviderModal';
import { currentUserCanAccess } from 'utils/roles';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Segment } from 'semantic';
import {
  Breadcrumbs,
  Layout,
  Search,
  SearchFilters,
  ListHeader,
} from 'components';

import { request } from 'utils/api';
import Table from './Table';

const onDataNeeded = async (filters, apiRoute) => {
  return request({
    method: 'POST',
    path: `${apiRoute}/search`,
    body: filters,
  });
};

export default function Providers({ apiRoute }) {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const writeAccess = currentUserCanAccess('providers', 'write');

  const labels = {
    hasParentProvider: t('providers.hasParentProvider', 'Has Parent Provider'),
    parentProvider: t('providers.parentProvider', 'Parent Provider'),
  };

  const getFilterMapping = () => {
    return {
      keyword: {
        type: 'search',
      },
      hasParentProvider: {
        label: labels.hasParentProvider,
        type: 'boolean',
      },
      parentProviderId: {
        label: labels.parentProvider,
        type: 'string',
      },
    };
  };

  return (
    <Search.Provider
      ref={searchRef}
      // filterMapping={getFilterMapping()} disabling mapping for now the it causes a reload issue
      onDataNeeded={(filters) => onDataNeeded(filters, apiRoute)}>
      <>
        <Breadcrumbs active={t('providers.title', 'Providers')} />
        <ListHeader title={t('providers.title', 'Providers')}>
          <CreateProviderModal
            onSave={() => searchRef.current.reload()}
            onSubmit={(formValues) =>
              request({
                method: 'POST',
                path: `/1/providers`,
                body: formValues,
              })
            }
            trigger={
              <Button
                primary
                floated="right"
                style={{ marginTop: '-5px' }}
                content={t('providers.newProvider', 'New Provider')}
                icon="plus"
                disabled={!writeAccess}
              />
            }
          />
        </ListHeader>
        <Segment>
          <Layout horizontal spread stackable>
            <SearchFilters.Modal>
              <SearchFilters.Checkbox
                label={labels.hasParentProvider}
                name="hasParentProvider"
              />

              <SearchFilters.Dropdown
                search
                clearable
                label={labels.parentProvider}
                name="parentProviderId"
                onDataNeeded={(props) => {
                  const body = {
                    hasParentProvider: false,
                  };
                  if (props?.name) {
                    body.keywords = props.name;
                  }

                  return request({
                    path: '/1/providers/search',
                    method: 'POST',
                    body,
                  });
                }}
              />
            </SearchFilters.Modal>

            <Layout horizontal stackable center right>
              <Search.Total />
              <SearchFilters.Search
                name="keywords"
                placeholder={t(
                  'provider.filterPlaceHolder',
                  'Name, Human Id or Id'
                )}
              />
            </Layout>
          </Layout>
        </Segment>
        <Table />
        <Divider hidden />
        <Search.Status noResults={t('common.noResults', 'No Results')} />

        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </>
    </Search.Provider>
  );
}
