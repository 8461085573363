import React, { useState, useEffect } from 'react';
import { request } from 'utils/api';

import {
  Checkbox,
  Divider,
  Form,
  Message,
  Modal,
  Header,
  Grid,
  Button,
  Tab,
} from 'semantic';

import { useTranslation } from 'react-i18next';
import { useWizardContext } from '../context';
import { useUser } from 'contexts/user';

import stickerExampleImage from '../../../../assets/public/sticker-example.png';

export default ({ onDone, stepHeader, defaultSerialNumber = '' }) => {
  const { setStation, station, setStickerCode, wantSticker, setWantSticker } =
    useWizardContext();

  const { provider } = useUser();
  const { t } = useTranslation();

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalid, setInvalid] = useState(true);

  const [stickerCodeClashes, setStickerCodeClashes] = useState(false);

  const [serialNumberError, setSerialNumberError] = useState();
  const [serialNumber, setSerialNumber] = useState(defaultSerialNumber);
  const [isSerialNumberValid, setIsSerialNumberValid] = useState(false);

  const [stickerCodeError, setStickerCodeError] = useState('');
  const [stickerCodeValue, setStickerCodeValue] = useState('');
  const [isStickerCodeValid, setIsStickerCodeValid] = useState(false);

  async function validateSerialNumber() {
    setSubmitted(true);
    setLoading(true);
    setSerialNumberError(null);
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/evse-controllers/lookup-unlinked',
        body: {
          serialNumber,
        },
      });

      if (!data.connectors.length) {
        setSerialNumberError(
          new Error(
            t(
              'newChargingStationLookup.noConnectorsError',
              'This charging station cannot be registered due to missing configuration settings. Please contact E-Flux to complete the configuration.'
            )
          )
        );
      } else {
        setIsSerialNumberValid(true);
        setStation(data);
      }
    } catch (e) {
      if (e.message.match(/no unclaimed EV stations/i)) {
        setSerialNumberError(
          new Error(
            t(
              'newChargingStationLookup.noUnclaimedEvStations',
              'We have not found any charging station with this serial number. Please check the serial number or contact our support team.'
            )
          )
        );
        setIsSerialNumberValid(false);
        setLoading(false);
        return;
      }
      setSerialNumberError(e);
      setIsSerialNumberValid(false);
    }
    setLoading(false);
  }

  async function validateStickerCode() {
    setSubmitted(true);
    setLoading(true);
    setStickerCodeError(null);
    setStickerCodeClashes(false);
    try {
      if (!stickerCodeValue) {
        throw { message: 'Sticker code is required' };
      }
      await request({
        method: 'POST',
        path: '/1/evse-controllers/validate-sticker-code',
        body: {
          stickerCode: stickerCodeValue,
          evseControllerId: station.id,
        },
      });
      setIsStickerCodeValid(true);
      setStickerCode(stickerCodeValue);
    } catch (e) {
      // TODO use translations for sticker code errors
      if (e.message.match(/Duplicate Evse ID|EVSE ID is already in use/)) {
        // TODO use error codes instead of messages for check
        setIsStickerCodeValid(true);
        setStickerCodeClashes(true);
        setWantSticker(true);
        setLoading(false);
        return;
      }
      setStickerCodeError(e);
      setIsStickerCodeValid(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!isSerialNumberValid) {
      setInvalid(true);
      return;
    }

    const hasSticker = !wantSticker;
    setInvalid(hasSticker && !isStickerCodeValid);
  }, [isStickerCodeValid, isSerialNumberValid, wantSticker]);

  const handleTabChange = (e, { activeIndex }) => {
    // Set wantSticker to true if the second tab (I don't have a sticker -> index 1) is selected, false otherwise (index 0)
    setWantSticker(activeIndex === 1);
  };

  const panes = [
    {
      menuItem: t('newChargingStationLookup.iHaveSticker', 'I have a sticker'),
      render: () => (
        <Tab.Pane>
          {stickerCodeClashes && (
            <p>
              {t(
                'newChargingStationLookup.stickerCodeClash',
                "We are sorry but there is a problem with your sticker, don't worry, you can continue activating your station today and we will send you a new sticker in due course."
              )}
            </p>
          )}
          {!stickerCodeClashes && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form
                    id="EditMyChargingStationFormLookup"
                    error={
                      submitted &&
                      Boolean(stickerCodeError || serialNumberError)
                    }>
                    {stickerCodeError && (
                      <Message error content={stickerCodeError.message} />
                    )}

                    <Form.Input
                      error={!!stickerCodeError}
                      label={t(
                        'newChargingStationLookup.enterStickerCode',
                        'Enter Your Sticker Code'
                      )}
                      value={stickerCodeValue}
                      placeholder={t(
                        'newChargingStationLookup.enterStickerCodePlaceHolder',
                        'Enter Your Sticker Code'
                      )}
                      fluid
                      type="text"
                      onChange={(e, { value }) =>
                        setStickerCodeValue(value.toUpperCase().trim())
                      }
                      onBlur={() => validateStickerCode()}
                      disabled={isSerialNumberValid === false}
                      required={true}
                    />
                  </Form>
                </Grid.Column>

                <Grid.Column width={8}>
                  <Header>
                    {t(
                      'newChargingStationLookup.stickerCodeHelpHeader',
                      'Where can I find the sticker code'
                    )}
                  </Header>
                  {provider.slug === 'e-flux' && (
                    <img
                      src={stickerExampleImage}
                      width="100"
                      style={{ float: 'right', margin: '0 0 1rem 1rem' }}
                    />
                  )}
                  <p>
                    {t(
                      'newChargingStationLookup.stickerCodeHelpText',
                      'You will find the EVSE ID on the sticker provided along with your charger.'
                    )}
                  </p>
                  <p>
                    <strong>
                      {t(
                        'newChargingStationLookup.stickerCodeHelpNoStickerHeader',
                        'No sticker?'
                      )}
                    </strong>
                    <br />
                    {t(
                      'newChargingStationLookup.stickerCodeHelpNoStickerText',
                      'Please select "I don’t have a sticker" tab.'
                    )}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: t(
        'newChargingStationLookup.iDontHaveSticker',
        `I don't have a sticker`
      ),
      render: () => (
        <Tab.Pane>
          <p>
            <strong>
              {t(
                'newChargingStationLookup.noStickerTitle',
                'No worries. We will send a sticker to your address.'
              )}
            </strong>
          </p>
          <p>
            {t(
              'newChargingStationLookup.noStickerText1',
              'You are still able to finish the registration and your charger will have a digital EVSE ID.'
            )}
          </p>
          <p>
            {t(
              'newChargingStationLookup.noStickerText2',
              'However, if your charging station is accessible for public charging at any time, you must place a physical sticker on your charge point so drivers can pay anonymously with Scan to Pay & get support.'
            )}
          </p>
          <p>
            {t(
              'newChargingStationLookup.noStickerText3',
              'If the station is not publicly accessible, you still need this sticker to access the self service tool or contact support.'
            )}
          </p>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Modal.Header>
        {stepHeader}
        {t('newChargingStationLookup.title', 'Request')}
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Form
                id="EditMyChargingStationFormLookup"
                error={
                  submitted && Boolean(stickerCodeError || serialNumberError)
                }>
                {serialNumberError && (
                  <Message error content={serialNumberError.message} />
                )}

                <Form.Input
                  label={t(
                    'newChargingStationLookup.enterSerialNumber',
                    'Enter Serial Number Or Chargebox ID'
                  )}
                  value={serialNumber}
                  error={!!serialNumberError}
                  placeholder={t(
                    'newChargingStationLookup.enterSerialNumberPlaceholder',
                    'Enter Your Charging station Serial Number Or Chargebox ID'
                  )}
                  fluid
                  required
                  type="text"
                  onChange={(e, { value }) => setSerialNumber(value.trim())}
                  onBlur={() => validateSerialNumber()}
                />
              </Form>
            </Grid.Column>

            <Grid.Column width={8}>
              <Header>
                {t(
                  'newChargingStationLookup.serialHelpHeader',
                  'How to locate your EV station serial number'
                )}
              </Header>
              <p>
                {t(
                  'newChargingStationLookup.serialHelpText',
                  'The serial number is located on the charging station.'
                )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={16}>
              <Tab
                onTabChange={handleTabChange}
                menu={{ widths: panes.length, attached: true, _tabular: true }}
                panes={panes}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          disabled={loading || invalid}
          primary
          content={t('common.next', 'Next')}
          form="EditMyChargingStationFormLookup"
          onClick={() => onDone()}
        />
      </Modal.Actions>
    </>
  );
};
