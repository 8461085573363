import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Form, Message, Image, Icon, Label, Card, Segment } from 'semantic';
import { request } from 'utils/api';
import { urlForUpload } from 'utils/uploads';

const commonStyles = {
  cursor: 'pointer',
  outline: 0,
};

const bigBoxStyles = {
  textAlign: 'center',
  height: '10em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const validationErrorStyles = {
  border: '1px dashed red',
};

export default class Uploads extends React.Component {
  constructor(props) {
    super(props);

    // Set default values directly
    this.maxFiles = props.maxFiles || 100; // Default max files
    this.bigBox = props.bigBox || false; // Default big box

    this.state = {
      loading: false,
      error: null,
      validationError: null,
      uploads: props.value ? props.value.slice() : [],
    };
    props.onChange(this.state.uploads);
  }

  delete(upload) {
    const { uploads } = this.state;
    const newUploads = uploads.filter((u) => u.id != upload.id);
    this.setState({ uploads: newUploads });
    this.props.onChange(newUploads);
  }

  onDropAccepted = async (acceptedFiles, rejectedFiles) => {
    this.setState({
      loading: true,
      error: null,
      validationError: null,
    });
    try {
      if (rejectedFiles.length) {
        throw new Error(
          `File did not meet criteria: ${rejectedFiles[0].file.name}`
        );
      }
      const { data } = await request({
        method: 'POST',
        path: '/1/uploads',
        files: acceptedFiles,
      });
      const uploaded = Array.isArray(data) ? data : [data];
      const uploads = [...this.state.uploads, ...uploaded];
      this.setState({
        uploads,
        loading: false,
      });
      this.props.onChange(uploads);
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
      this.props.onError(error);
    }
  };

  render() {
    const { required, label, type, placeholder } = this.props;
    const { error, loading, uploads } = this.state;
    const isDisabled = uploads.length >= this.maxFiles;
    const bigBox = this.bigBox;

    // Combine styles conditionally
    let combinedStyles = bigBox
      ? { ...commonStyles, ...bigBoxStyles }
      : commonStyles;

    if (this.props.validationError) {
      combinedStyles = { ...combinedStyles, ...validationErrorStyles };
    }

    return (
      <Form.Field required={required}>
        {label && <label>{label}</label>}
        {error && <Message error content={error.message} />}
        {uploads.length > 0 &&
          (type === 'image' ? (
            <Card.Group itemsPerRow={4}>
              {uploads.map((upload) => (
                <Card key={upload.id}>
                  <Image key={upload.id} src={urlForUpload(upload, true)} />
                  <Icon
                    name="delete"
                    color="blue"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: '5px',
                      top: '5px',
                      zIndex: 1,
                    }}
                    onClick={() => this.delete(upload)}
                  />
                  <Card.Content>{upload.filename}</Card.Content>
                </Card>
              ))}
            </Card.Group>
          ) : (
            <div>
              {bigBox ? (
                <Segment>
                  {uploads.map((upload) => (
                    <Label key={upload.id} style={{ padding: '1em' }}>
                      <Icon name="file" />
                      {upload.filename}
                      <Icon
                        name="delete"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.delete(upload)}
                      />
                    </Label>
                  ))}
                </Segment>
              ) : (
                <Label.Group color="blue">
                  {uploads.map((upload) => (
                    <Label key={upload.id}>
                      <Icon name="file" />
                      {upload.filename}
                      <Icon
                        name="delete"
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.delete(upload)}
                      />
                    </Label>
                  ))}
                </Label.Group>
              )}
            </div>
          ))}
        {!isDisabled && (
          <Dropzone
            disabled={isDisabled}
            maxSize={4096 * 1024 * 1024}
            onDropAccepted={this.onDropAccepted}
            maxFiles={this.maxFiles}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={
                    isDragActive
                      ? 'ui icon blue message upload-dropzone-active'
                      : 'ui icon message upload-dropzone'
                  }
                  style={combinedStyles}>
                  {!bigBox &&
                    (loading ? (
                      <Icon name="rotate" loading />
                    ) : (
                      <Icon name="file" />
                    ))}
                  <input {...getInputProps()} />
                  <div className="content">
                    {bigBox &&
                      (loading ? (
                        <Icon name="rotate" loading />
                      ) : (
                        <Icon name="upload" />
                      ))}
                    {loading ? (
                      <span>Uploading...</span>
                    ) : isDragActive ? (
                      <span>Drop files here...</span>
                    ) : (
                      <span>
                        {placeholder
                          ? placeholder
                          : 'Try dropping some files here, or click to select files to upload.'}
                      </span>
                    )}
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {this.props.validationError && (
          <p style={{ color: 'red', fontSize: '0.9em', lineHeight: '2em' }}>
            This field is required
          </p>
        )}
      </Form.Field>
    );
  }
}

Uploads.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
};

Uploads.defaultProps = {
  type: 'image',
  onError: () => {},
};
