export const defaultColors = [
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab',
];

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

function rgbToHex([r, g, b]) {
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
}

function calculateDistance(color1, color2) {
  const [r1, g1, b1] = color1;
  const [r2, g2, b2] = color2;
  return Math.sqrt((r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2);
}

function normalizeColor(color, factor) {
  return color.map((channel) => Math.min(255, Math.max(0, channel * factor)));
}

export function getColors(target, threshold = 100, colors = defaultColors) {
  const targetRgb = hexToRgb(target);
  const filteredColors = colors
    .map(hexToRgb)
    .filter((color) => calculateDistance(color, targetRgb) >= threshold);

  // Adjust colors to the same range (optional: normalize to similar brightness)
  const maxChannelValue = Math.max(...filteredColors.flat());
  const adjustmentFactor = 255 / maxChannelValue; // Adjusts colors to max brightness of 255

  const adjustedColors = filteredColors.map((color) =>
    rgbToHex(normalizeColor(color, adjustmentFactor))
  );

  return [target, ...adjustedColors];
}

export const sessionsExclusionFilter = {
  ranges: [
    {
      durationSeconds: { gte: 0, lte: 7 * 24 * 3600 },
    },
    {
      kwh: { lte: 10000, gt: 0 },
    },
  ],
};

export function mergeFilters(one, two) {
  const baseFilter = {};
  Object.keys(one).forEach((key) => {
    baseFilter[key] = one[key];
  });
  Object.keys(two).forEach((key) => {
    if (baseFilter[key]) {
      baseFilter[key] = baseFilter[key].concat(two[key]);
    } else {
      baseFilter[key] = two[key];
    }
  });
  return baseFilter;
}

export function changeFilterId(filter, field) {
  let newFilter = {};
  filter?.terms?.forEach((key) => {
    if (!newFilter?.terms) {
      newFilter = { terms: [] };
    }
    Object.keys(key)[0] === field
      ? newFilter.terms.push({ id: Object.values(key)[0] })
      : newFilter.terms.push(key);
  });
  return newFilter;
}
