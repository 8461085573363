import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic';
import TypeButton from 'components/modals/RequestCard/v2/TypeButton';
import styles from './styles.module.less';

type SelectTypeProps = {
  selected: string;
  onSelect: (type: string) => void;
};

export default function SelectType(props: SelectTypeProps) {
  const { t } = useTranslation();

  return (
    <Grid>
      <Grid.Row className={styles.row}>
        <Grid.Column className={styles.column}>
          <TypeButton
            icon="credit-card regular"
            isSelected={props.selected === 'card'}
            onClick={() => props.onSelect('card')}
            text={t('addCardModal.card', 'Card')}
          />
        </Grid.Column>
        <Grid.Column className={styles.column}>
          <TypeButton
            icon="tag"
            isSelected={props.selected === 'tag'}
            onClick={() => props.onSelect('tag')}
            text={t('addCardModal.tag', 'Tag')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
