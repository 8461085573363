import React from 'react';

import { Route } from 'react-router-dom';
import Boot from 'components/Boot';
import BootPublic from 'components/BootPublic';

import { getToken } from 'utils/api';

export default class AuthSwitchRoute extends React.Component {
  render() {
    const {
      loggedIn: LoggedInComponent,
      loggedOut: LoggedOutComponent,
      to,
      exact,
    } = this.props;

    return (
      <Route
        to={to}
        exact={exact}
        render={(props) => {
          return getToken() ? (
            <Boot endpoint="me">
              <LoggedInComponent {...props} />
            </Boot>
          ) : (
            <BootPublic>
              <LoggedOutComponent {...props} />
            </BootPublic>
          );
        }}
      />
    );
  }
}
