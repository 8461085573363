import React, { useMemo } from 'react';
import { AccountProvider } from 'contexts/account';
import { useTranslation } from 'react-i18next';
import { currentUserCanAccess } from 'utils/roles';
import SettingsShell from 'screens/Settings/components/wrappers/SettingsShell';
import BillingAndPlansForm from 'screens/Settings/screens/personal/BillingAndPlans/BillingAndPlans.form';
import { useAccount } from 'contexts/account';
import { Divider, Header, Message } from 'semantic';
import { request } from 'utils/api';
import { QueryClient } from '@tanstack/react-query';

// Note: This account billing page is for personal/individual accounts and is different (in the business context) from organization account billing.
// This page needs to exist separately in the settings sidebar under the "account" menu for individual accounts.
export default function BillingAndPlansContainer() {
  //TODO: Add error boundary layer
  const { t } = useTranslation();
  const cannotReadBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'read'
  );

  if (cannotReadBillingInformation) {
    return (
      <>
        <Message warning>
          {t(
            'access.cannotViewBillingInformation',
            'You do not have sufficient permissions to view billing information.'
          )}
        </Message>
      </>
    );
  }

  return (
    <SettingsShell>
      <BillingAndPlansForm />
    </SettingsShell>
  );
}
