import { APP_NAME } from 'utils/env';
import { snakeCase } from 'lodash-es';

export const JWT_KEY = `${snakeCase(APP_NAME)}_jwt`;
export const SANDBOX_JWT_KEY = `${snakeCase(APP_NAME)}_sandbox_jwt`;

export function hasToken() {
  return !!getToken();
}

export function getToken(key = JWT_KEY) {
  const token = sessionStorage.getItem(key) || localStorage.getItem(key);
  // we need a little fallback because the APP_NAME was not set
  // can be removed in a few months
  if (!token) {
    return sessionStorage.getItem('_jwt') || localStorage.getItem('_jwt');
  }
  return token;
}

export function setToken(token) {
  if (token) {
    localStorage.setItem(JWT_KEY, token);
  } else {
    localStorage.removeItem(JWT_KEY);
  }
}

export function sandboxSwitch(enabled, token) {
  enabled ? enterSandbox(token) : leaveSandbox();
}

function enterSandbox(token) {
  // Save the current token to local storage so that we can switch back
  // to it when the user decides to leave the sandbox environment
  localStorage.setItem(SANDBOX_JWT_KEY, getToken());
  localStorage.setItem(JWT_KEY, token);
}

// Export required for sandbox server error handling
export function leaveSandbox() {
  localStorage.setItem(JWT_KEY, getToken(SANDBOX_JWT_KEY));
  localStorage.removeItem(SANDBOX_JWT_KEY);
}
