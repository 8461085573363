import React, { useState } from 'react';
import { Breadcrumbs } from 'components';
import { Space } from 'components/Layout/helpers';
import {
  PersonalSettingsMenu,
  OrganizationSettingsMenu,
  AccountSettingsMenu,
} from '..';
import { useUser } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAccount } from 'contexts/account';
import { currentUserCanAccess } from 'utils/roles';
import { Header } from 'semantic';
import useAccountHasFeature from 'hooks/useAccountHasFeature';

// Note: The SettingsSidebar component renders personal(user), account, or organization menu items.
// The user menu is always visible and is applicable to both "individual"/"organization" account types.

// If the account type is "individual", but the user has billing write permissions, the account menu is visible.
// This is to allow for billing on account level for individual accounts.

// Lastly, if the account type is "organization", the organization menu is visible.

export default function SettingsSidebar() {
  // @ts-ignore until the user context is typed
  const { hasPlatformFeature, user } = useUser();
  const { account } = useAccount();
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const [activeItem] = useState(pathname);

  const canEditBillingInformation = currentUserCanAccess(
    'accounts-billing',
    'write'
  );

  const canViewAccountsBilling = currentUserCanAccess(
    'accounts-billing',
    'read'
  );

  const canViewAccounts = currentUserCanAccess('accounts', 'read');

  const canEditIndividualAccountBillingInformation =
    canEditBillingInformation &&
    (account?.type === 'individual' || user.accountType === 'individual');

  const canViewOrganizationSettings =
    canViewAccounts &&
    (account?.type === 'organization' || user?.accountType === 'organization');

  const ssoModuleEnabled = hasPlatformFeature('single-sign-on:saml');

  return (
    <div>
      <Breadcrumbs active={t('settings.account', 'Settings')} />

      <Header
        size="medium"
        style={{ marginTop: '15px', marginBottom: '15px', fontWeight: 600 }}>
        {t('settings.sidebar.title', 'Settings')}
      </Header>

      <PersonalSettingsMenu activeItem={activeItem} />

      <Space direction="vertical" size={20} />

      {canEditIndividualAccountBillingInformation && (
        <AccountSettingsMenu activeItem={activeItem} />
      )}

      {canViewOrganizationSettings && (
        <OrganizationSettingsMenu
          activeItem={activeItem}
          canViewBillingInformation={canViewAccountsBilling}
          subOperatorEnabled={hasPlatformFeature('roaming:suboperator')}
          ssoModuleEnabled={ssoModuleEnabled}
        />
      )}
    </div>
  );
}
