import React, { createRef } from 'react';
import {
  Header,
  Container,
  Menu,
  Message,
  Divider,
  Icon,
  Dropdown,
  Button,
} from 'semantic';

import { getThemedField } from 'utils/theming';

import { Layout } from 'components/Layout';
import PageLoader from 'components/PageLoader';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import StandardPage from './StandardPage';
import Breadcrumbs from 'components/Breadcrumbs';
import { request } from 'utils/api';
import PortalNav from 'components/PortalNav';

import CONFIGURE_OCPP_MD from 'docs/charge-stations/CONFIGURE_OCPP.md';
import AUTHENTICATION_MD from 'docs/charge-stations/AUTHENTICATION.md';
import CREATING_LOCATIONS_MD from 'docs/charge-stations/CREATING_LOCATIONS.md';
import RECEIVING_SESSIONS_MD from 'docs/charge-stations/RECEIVING_SESSIONS.md';
import EVSE_CONTROL_MD from 'docs/charge-stations/EVSE_CONTROL.md';
import EVSE_QR_CODE from 'docs/charge-stations/EVSE_QR_CODE.md';
import EVSE_ACTIVATION_MD from 'docs/charge-stations/EVSE_ACTIVATION.md';
import EVSE_ACCESS_CONTROL from 'docs/charge-stations/EVSE_ACCESS_CONTROL.md';
import { withTheme } from 'contexts/theme';

const pages = [
  {
    id: 'configure-ocpp',
    name: 'Configure OCPP',
    markdown: CONFIGURE_OCPP_MD,
  },
  {
    id: 'authentication',
    name: 'Authentication',
    markdown: AUTHENTICATION_MD,
  },
  {
    id: 'creating-locations',
    name: 'Creating Locations',
    markdown: CREATING_LOCATIONS_MD,
  },
  {
    id: 'evse-activation',
    name: 'EVSE Activation',
    markdown: EVSE_ACTIVATION_MD,
  },
  {
    id: 'access-control',
    name: 'EVSE Access Control',
    markdown: EVSE_ACCESS_CONTROL,
  },
  {
    id: 'receiving-sessions',
    name: 'Receiving Sessions',
    markdown: RECEIVING_SESSIONS_MD,
  },
  {
    id: 'evse-control',
    name: 'EVSE Control',
    markdown: EVSE_CONTROL_MD,
  },
  {
    id: 'evse-qr-code',
    name: 'QR Code',
    markdown: EVSE_QR_CODE,
  },
];

function stateForParams(params) {
  const { id } = params;
  return {
    pageId: id,
    page: id ? pages.find((p) => p.id === id) : pages[0],
  };
}

class ChargeStations extends React.Component {
  contextRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      openApi: null,
      loading: true,
      error: null,
      ...stateForParams(this.props.match.params),
    };
  }

  state = {
    loading: true,
    error: null,
  };

  async componentDidMount() {
    try {
      const openApi = await request({
        method: 'GET',
        path: '/openapi.lite.json',
      });
      this.setState({
        loading: false,
        openApi,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        ...stateForParams(this.props.match.params),
      });
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { loading, openApi, error, pageId, page } = this.state;

    const backgroundStyle = {};

    const isDark = this.context.renderedTheme === 'dark';
    const secondaryBackgroundColorHex = getThemedField(
      window.provider,
      'secondaryBackgroundColorHex',
      isDark
    );

    if (secondaryBackgroundColorHex) {
      backgroundStyle.background = secondaryBackgroundColorHex;
    }

    if (loading) {
      return <PageLoader />;
    }

    return (
      <>
        <PortalNav.Sidebar style={backgroundStyle}>
          <Layout vertical style={{ height: '100%' }}>
            <Layout.Group grow overflow>
              <Layout vertical style={{ minHeight: '100%' }}>
                <Layout.Group grow>{this.renderMenu()}</Layout.Group>
              </Layout>
            </Layout.Group>
          </Layout>
        </PortalNav.Sidebar>
        <PortalNav.Content>
          <PortalNav.MobileHead style={backgroundStyle}>
            <Layout horizontal center spread style={{ overflow: 'visible' }}>
              <Menu fluid>{this.renderMobileMenu()}</Menu>
              <PortalNav.Trigger>
                <Icon name="bars" fitted />
              </PortalNav.Trigger>
            </Layout>
          </PortalNav.MobileHead>

          <Layout vertical>
            <Container>
              {!pageId && (
                <div>
                  <Header as="h1">
                    <Header.Content>Charge Stations</Header.Content>
                    <Header.Subheader>
                      Manage, operate and control EV Charge Stations and receive
                      EV charge transactions.
                    </Header.Subheader>
                  </Header>
                  <Divider hidden />

                  <Message>
                    Not ready for a full integration?{' '}
                    <a
                      href="https://www.e-flux.io/support/#contact"
                      target="_blank"
                      rel="noopener noreferrer">
                      Contact us for turn-key solutions
                    </a>
                  </Message>
                  <Divider hidden />

                  <div className="block">
                    <Header as="h2" content="Configure OCPP backend" />
                    <p>
                      Configure E-Flux as your OCPP backend to operate your
                      fleet of charge stations.
                    </p>
                    <a href="/docs/charge-stations/configure-ocpp">
                      <Button primary content="Get Started" />
                    </a>
                  </div>
                </div>
              )}

              {pageId && page && (
                <React.Fragment>
                  <Breadcrumbs
                    homePath="/docs"
                    homeName="Docs"
                    path={[
                      <Link key="developer" to="/docs/charge-stations">
                        Charge Stations
                      </Link>,
                    ]}
                    active={page.name}
                  />
                  {/* <Divider hidden /> */}
                </React.Fragment>
              )}

              <Switch>
                {pages.map((page) => {
                  return (
                    <Route
                      key={page.id}
                      exact
                      path={`/docs/charge-stations/${page.id}`}
                      component={(props) => (
                        <StandardPage
                          {...props}
                          openApi={openApi}
                          page={page}
                        />
                      )}
                    />
                  );
                })}
              </Switch>
              <Divider hidden />
              <Divider hidden />
            </Container>
          </Layout>
        </PortalNav.Content>
      </>
    );
  }

  renderMenu() {
    const { pageId } = this.state;
    return (
      <>
        {pages.map(({ id, name }) => {
          return (
            <PortalNav.Link
              key={id}
              exact
              active={pageId === id}
              to={`/docs/charge-stations/${id}`}>
              {name}
            </PortalNav.Link>
          );
        })}
      </>
    );
  }

  renderMobileMenu() {
    const { pageId } = this.state;
    return (
      <Dropdown text="Jump to chapter" className="link item" fluid>
        <Dropdown.Menu>
          {pages.map(({ id, name }) => {
            return (
              <Menu.Item
                key={id}
                exact
                active={pageId === id}
                to={`/docs/charge-stations/${id}`}
                as={NavLink}>
                {name}
              </Menu.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default withTheme(ChargeStations);
