import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useOrganizationBillingValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    billing: Yup.object().shape({
      address: Yup.string().required(
        t(
          'settings.organization.billing.validations.address',
          'Please enter your address'
        )
      ),
      addressLine2: Yup.string().required(
        t(
          'settings.organization.billing.validations.addressLine2',
          'Please enter your street number'
        )
      ),
      postalCode: Yup.string().required(
        t(
          'settings.organization.billing.validations.postalCode',
          'Please enter your postal code'
        )
      ),
      city: Yup.string().required(
        t(
          'settings.organization.billing.validations.city',
          'Please enter your city'
        )
      ),
      countryCode: Yup.string().required(
        t(
          'settings.organization.billing.validations.countryCode',
          'Please enter your country code'
        )
      ),
      paymentMethod: Yup.string().required(
        t(
          'settings.organization.billing.validations.paymentMethod',
          'Please select your payment method'
        )
      ),
      email: Yup.string().email(
        t(
          'settings.organization.billing.validations.email',
          'Please enter a valid email'
        )
      ),
      cpoReferenceText: Yup.string(),
      mspReferenceText: Yup.string(),
    }),
  });
};
