import React from 'react';
import { Divider, Grid, Segment } from 'semantic';

import TermsDonutChart from 'components/analytics/TermsDonutChart';
import { useUser } from 'contexts/user';
import { getColors } from 'utils/visualizations';
import { useTranslation } from 'react-i18next';

export default function Equipment({ baseFilter = {} }) {
  const { t } = useTranslation();
  const { provider } = useUser();
  const colorHex = `#${provider.primaryColorHex}`;

  const colors = getColors(colorHex);

  return (
    <div>
      <Divider hidden />
      <Divider hidden />

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                collection="sessions"
                aggField="connectorType"
                operation="count"
                title={t(
                  'analyticsCpoEquipment.connectorTypes',
                  'Connector Types'
                )}
                filter={baseFilter}
                termsSize={15}
                colors={colors}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Segment basic>
              <TermsDonutChart
                collection="sessions"
                aggField="ocpiCredentialsName"
                operation="count"
                title={t('analyticsCpoEquipment.ocpiSource', 'OCPI Source')}
                filter={baseFilter}
                termsSize={15}
                colors={colors}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />
    </div>
  );
}
