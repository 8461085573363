import React from 'react';

import { UserContext } from 'contexts/user';

export default class Logout extends React.Component {
  static contextType = UserContext;

  componentDidMount() {
    this.context.logout();
    window.location.href = '/';
  }
  render() {
    return <div />;
  }
}
