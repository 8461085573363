import React from 'react';
import { SettingsMenuItemProps } from 'types/account-settings';
import { Menu, Icon } from 'semantic';
import { Link } from 'react-router-dom';
import styles from './SettingsMenuItem.module.css';

export default function AccountSettingsMenuItem({
  title,
  route,
  iconName,
  activeItem,
}: SettingsMenuItemProps) {
  return (
    <Link to={route} className={`${styles.menuItem}`}>
      <Menu.Item
        active={activeItem === route}
        className={
          activeItem === route
            ? `active item ${styles.activeMenuItem}`
            : styles.menuItem
        }>
        <Icon name={iconName} />
        <div style={{ fontWeight: 500 }}>{title}</div>
      </Menu.Item>
    </Link>
  );
}
