import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import DropdownMenu from 'components/DropdownMenu';
import {
  Segment,
  SegmentGroup,
  Dropdown,
  LabelDetail,
  ActionButton,
} from 'semantic';
import SelectionReviewModal, { DataColumn } from './SelectionReviewModal';
import { Trans, useTranslation } from 'react-i18next';

type Action<T> = {
  name: string;
  icon?: string;
  quickAccess?: boolean;
  onExecute: (selectedItems: T[]) => Promise<void>;
};

type BulkActionsIslandProps<T> = {
  otherActionsCaption?: string;
  actions: Action<T>[];
  selectedItems: T[];
  onSetSelectedItems: Dispatch<SetStateAction<T[]>>;
  selectionReviewColumns?: DataColumn<T>[];
  equal?: (a: T, b: T) => boolean;
};

type StyledStickyProps = {
  visible: boolean;
};

const HidableSticky = styled.div<StyledStickyProps>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
  color: #fff;
`;

const StyledSegmentGroup = styled(SegmentGroup)`
  pointer-events: auto;

  &::after {
    display: none;
  }
`;

const Container = styled('div')`
  display: flex;
  align-items: baseline;
`;

const StyledActionButton = styled(ActionButton)`
  &:hover {
    opacity: 0.8;
  }
`;

export default function BulkActionsIsland<T>({
  actions,
  selectedItems,
  onSetSelectedItems,
  otherActionsCaption,
  selectionReviewColumns,
  equal,
}: BulkActionsIslandProps<T>) {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const { t } = useTranslation();
  const quickActions = actions.filter((action) => action.quickAccess);
  const otherActions = actions.filter((action) => !action.quickAccess);
  const visible = selectedItems?.length > 0;
  const selectedItemsCount = selectedItems?.length;

  return (
    <HidableSticky className="nocturnal-theme" visible={visible}>
      <StyledSegmentGroup stacked raised compact horizontal>
        <Segment>
          <Container size="large">
            <StyledActionButton
              name="delete"
              icon="delete"
              onClick={() => onSetSelectedItems([])}
            />
            <p style={{ margin: 0, marginRight: '1rem' }}>
              {selectedItemsCount === 1 && (
                <Trans i18nKey="bulkActionIsland.selectedItemsSingular">
                  1 item selected
                </Trans>
              )}
              {selectedItemsCount > 1 && (
                <Trans
                  i18nKey="bulkActionIsland.selectedItemsPlural"
                  values={{ selectedItemsCount }}>
                  {{ selectedItemsCount }} items selected
                </Trans>
              )}
            </p>
            {
              /* Selection review modal */ selectionReviewColumns && equal && (
                <LabelDetail>
                  <SelectionReviewModal
                    isOpen={isReviewModalOpen}
                    onSetIsOpen={setIsReviewModalOpen}
                    selectedItems={selectedItems}
                    onSetSelectedItems={onSetSelectedItems}
                    selectionReviewColumns={selectionReviewColumns}
                    equal={equal}
                  />
                  <StyledActionButton
                    onClick={() => setIsReviewModalOpen(true)}
                    content={t(
                      'bulkActionIsland.reviewSelection',
                      'Review selection'
                    )}
                  />
                </LabelDetail>
              )
            }
          </Container>
        </Segment>
        {
          /* Quick actions */ quickActions.length > 0 && (
            <Segment>
              {quickActions.map((action) => (
                <StyledActionButton
                  key={action.name}
                  icon={action.icon}
                  content={action.name}
                  size="medium"
                  basic
                  onClick={() => action.onExecute(selectedItems)}
                />
              ))}
            </Segment>
          )
        }
        {
          /* Other actions */ otherActions.length > 0 && (
            <Segment>
              <DropdownMenu
                text={
                  otherActionsCaption ??
                  t('bulkActionIsland.actionsMenu', 'Actions')
                }>
                {otherActions.map((action) => (
                  <Dropdown.Item
                    key={action.name}
                    icon={action.icon}
                    text={action.name}
                    basic
                    onClick={() => action.onExecute(selectedItems)}
                  />
                ))}
              </DropdownMenu>
            </Segment>
          )
        }
      </StyledSegmentGroup>
    </HidableSticky>
  );
}
