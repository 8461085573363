import React from 'react';
import { useTranslation } from 'react-i18next';

import { sandboxSwitch, request } from 'utils/api';
import { useSandbox } from 'contexts/sandbox';
import { Checkbox, Loader } from 'semantic';

const SandboxToggle = (props) => {
  const { t } = useTranslation();
  const [switchPending, setSwitchPending] = React.useState(false);
  const { sandboxMode } = useSandbox();

  const toggleSandbox = async () => {
    // if we are not currently in the sandbox mode, we need to get
    // a new access token. However, if we've already in the sandbox
    // mode the nextToken is optional as it is already stored in the
    // local storage
    let nextToken;
    if (!sandboxMode) {
      const res = await request({
        method: 'POST',
        path: '/1/auth/sandbox',
      });
      nextToken = res.data.token;
    }

    const nextSandboxMode = !sandboxMode;
    sandboxSwitch(nextSandboxMode, nextToken);
    setSwitchPending(true);
    setTimeout(() => {
      if (nextSandboxMode) {
        window.location.assign('/sandbox');
      } else {
        window.location.assign('/accounts');
      }
    }, 1000);
  };

  return (
    <>
      {switchPending ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Loader size="small" inline active />
          <p style={{ marginLeft: '1rem' }}>
            {t('sandbox.loadingMessage', 'Switching environment...')}
          </p>
        </div>
      ) : (
        <Checkbox
          checked={sandboxMode}
          onChange={() => toggleSandbox()}
          toggle
          label={t('sandbox.sandboxMode', 'Sandbox mode')}
        />
      )}
    </>
  );
};

export default SandboxToggle;
