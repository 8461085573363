import styled from 'styled-components';
import { Table } from 'semantic';

export const Text = styled.div`
  font-size: 0.9em;
`;

export const LogType = styled.span`
  text-transform: uppercase;
  color: #888;
  margin-right: 4px;
`;

export const SmallText = styled.div`
  font-size: 0.72em;
  line-height: 1.2;
  display: inline-block;
`;

export const StyledTable = styled(Table)`
  &&& {
    margin-top: 0;
  }
  &&& td,
  &&& th {
    padding: 0.5rem;
  }
`;

export const NarrowTable = styled(Table)`
  &&& td {
    padding: 0.5rem;
  }
`;
