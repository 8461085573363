import React, { useState } from 'react';
import { formatDateTime } from 'utils/date';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalHeader,
  Table,
} from 'semantic-ui-react';
import ExpandableJson from 'components/ExpandableJson';
import styles from './RoamingActivity.module.css';

export interface Activity {
  label: string;
  correlationId: string;
  traceId: string;
  owner: {
    connectionId: string;
    connectionName?: string;
  };
  occurredAt: Date;
  entityId: string;
  entityType: string;
  childActivities: Activity[];
  body: {
    cpoCommand?: {
      command?: string;
      commandId?: string;
      initialRequestBody?: string;
      cpoProxiedRequestBody?: string;
      cpoProxiedResponseBody?: string;
      cpoProxiedStatusCode?: number;
      mspProxiedRequestBody?: string;
      mspProxiedResponseBody?: string;
      mspProxiedStatusCode?: number;
      error: string;
    };
  };
}

const CPOCommandModal: React.FC<{
  item?: Activity;
  onClose(): void;
}> = ({ item, onClose }) => {
  const { t } = useTranslation();
  if (!item?.body?.cpoCommand) {
    return null;
  }

  const tokenAuthorize = item?.body?.tokenAuthorize;

  var initialRequestJSON = {};
  var cpoProxiedRequestJSON = {};
  var cpoProxiedResponseJSON = {};
  var mspProxiedRequestJSON = {};
  var mspProxiedResponseJSON = {};

  try {
    initialRequestJSON = JSON.parse(item?.body?.cpoCommand?.initialRequestBody);
    cpoProxiedRequestJSON = JSON.parse(
      item?.body?.cpoCommand?.cpoProxiedRequestBody
    );
    cpoProxiedResponseJSON = JSON.parse(
      item?.body?.cpoCommand?.cpoProxiedResponseBody
    );
    mspProxiedRequestJSON = JSON.parse(
      item?.body?.cpoCommand?.mspProxiedRequestBody
    );
    mspProxiedResponseJSON = JSON.parse(
      item?.body?.cpoCommand?.mspProxiedResponseBody
    );
  } catch (e) {
    console.warn(`Error parsing initial request JSON: ${e}`);
  }

  return (
    <Modal closeIcon onClose={() => onClose()} open={!!open}>
      <ModalHeader>
        {t('roamingActivity.requestDetails', 'Request details')}
      </ModalHeader>
      <ModalContent>
        <Table>
          <Table.Body>
            {tokenAuthorize?.error && (
              <Table.Row>
                <Table.Cell>
                  {t('roamingActivity.requestDetailsError', 'Error')}
                </Table.Cell>
                <Table.Cell>{tokenAuthorize?.error}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>
                {t('roamingActivity.requestDetailsoccurredAt', 'Occurred at')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(item?.occurredAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t(
                  'roamingActivity.cpoRequestStatus',
                  'CPO Proxy request status'
                )}
              </Table.Cell>
              <Table.Cell>
                {item?.body?.cpoCommand?.cpoProxiedStatusCode}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t(
                  'roamingActivity.mspRequestStatus',
                  'MSP Proxy request status'
                )}
              </Table.Cell>
              <Table.Cell>
                {item?.body?.cpoCommand?.mspProxiedStatusCode}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <h2>{t('roamingActivity.initialRequest', 'Initial request')}</h2>
        <ExpandableJson object={initialRequestJSON || {}} />

        <h2>{t('roamingActivity.cpoRequest', 'CPO request')}</h2>
        <div className={styles.requestResponse}>
          <div className={styles.requestResponseInner}>
            <ExpandableJson object={cpoProxiedRequestJSON || {}} />
          </div>
          <div className={styles.requestResponseInner}>
            <ExpandableJson object={cpoProxiedResponseJSON || {}} />
          </div>
        </div>
        <h2>{t('roamingActivity.mspRequest', 'MSP request')}</h2>
        <div className={styles.requestResponse}>
          <div className={styles.requestResponseInner}>
            <ExpandableJson object={mspProxiedRequestJSON || {}} />
          </div>
          <div className={styles.requestResponseInner}>
            <ExpandableJson object={mspProxiedResponseJSON || {}} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

const Command: React.FC<{ command: string }> = ({ command }) => {
  const { t } = useTranslation();

  const label = (() => {
    switch (command) {
      case 'START_SESSION':
        return t('roamingActivity.startSession', 'START_SESSION');
      case 'STOP_SESSION':
        return t('roamingActivity.stopSession', 'STOP_SESSION');
    }
  })();

  return <Label size="tiny">cmd: {label}</Label>;
};

const RoamingActivityCPOCommand: React.FC<{
  item: Activity;
}> = ({ item }) => {
  const [selectedItem, toggleModal] = useState<Activity | undefined>(undefined);
  const { t } = useTranslation();

  return (
    <div className={styles.activityItem}>
      <CPOCommandModal
        item={selectedItem}
        onClose={() => toggleModal(undefined)}
      />
      <div className={styles.activityItemHeader}>
        <div className={styles.activityHeaderName}>
          <Command command={item?.body?.cpoCommand?.command} />{' '}
          <p>
            {t('roamingActivity.cpoCommand', 'Remote command received', {})}
          </p>
        </div>
        <div className={styles.activityTime}>
          {formatDateTime(item.occurredAt)}
        </div>
        <Button
          className={styles.button}
          basic
          size="mini"
          onClick={() => toggleModal(item)}>
          {t('roamingActivity.request', 'View request data')}
        </Button>
      </div>
    </div>
  );
};

export default RoamingActivityCPOCommand;
