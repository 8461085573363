import { omit } from 'lodash-es';

export const getRoleIds = (relationships) => {
  return relationships.map((r) => r.role.id);
};

export const isAccountAdmin = (user) => {
  return !!user?.accountRoles.find(
    (roleItem) => roleItem.role.name === 'Account Admin'
  );
};

export const createProviderRoles = (providerId, roleIds = []) => {
  return roleIds.map((roleId) => {
    return { roleId, providerId };
  });
};

export const createAccountRoles = (accountId, roleIds = []) => {
  return roleIds.map((roleId) => {
    return { roleId, accountId };
  });
};

export const createGlobalRoles = (globalRoleIds = []) => {
  return globalRoleIds.map((roleId) => {
    return { roleId };
  });
};

export const createSystemRoles = (systemRoleIds = []) => {
  return systemRoleIds.map((roleId) => {
    return { roleId };
  });
};

export const setRolesInBody = (body) => {
  const {
    providerId,
    providerRoleIds,
    accountId,
    accountRoleIds,
    globalRoleIds,
    systemRoleIds,
  } = body;
  return {
    ...omit(body, 'providerRoleIds', 'accountRoleIds'),
    accountRoles: createAccountRoles(accountId, accountRoleIds),
    providerRoles: createProviderRoles(providerId, providerRoleIds),
    globalRoles: createGlobalRoles(globalRoleIds),
    systemRoles: createSystemRoles(systemRoleIds),
  };
};

export const isSuperAdmin = (user) => {
  return (user?.roles || []).includes('super_admin');
};

/**
 *
 * @param {string} permissionValue
 * @param {string} level
 * @returns {boolean}
 */
function meetsLevel(permissionValue, level) {
  if (permissionValue === 'none') return false;
  if (permissionValue === 'read-write' && level === 'write') return true;
  if (permissionValue === 'read-write' && level === 'read') return true;
  if (permissionValue === 'read-write' && level === 'read-write') return true;
  if (permissionValue === 'read' && level === 'read') return true;
  return false;
}

/**
 *
 * @param roles
 * @param endpoint
 * @param requiredLevel
 * @returns {boolean}
 */
export function checkPermissionAccess(roles, endpoint, requiredLevel) {
  if (!roles) return false;
  return !!roles.find((role) => {
    const { permissions } = role;
    const permissionValue = (permissions && permissions[endpoint]) || 'none';
    return meetsLevel(permissionValue, requiredLevel);
  });
}

export const canAccessGlobalEndpoint = (user, endpoint, requiredLevel) => {
  if (endpoint === 'me' && user) return true;
  if (isSuperAdmin(user)) return true;
  const relevantRoles = (user.globalRoles || []).map((r) => r.role);
  return checkPermissionAccess(relevantRoles, endpoint, requiredLevel);
};

export const canAccessSystemEndpoint = (user, endpoint, requiredLevel) => {
  if (endpoint === 'me' && user) return true;
  if (isSuperAdmin(user)) return true;
  const relevantRoles = (user.systemRoles || []).map((r) => r.role);
  return checkPermissionAccess(relevantRoles, endpoint, requiredLevel);
};

/**
 *
 * @param user
 * @param providerId
 * @param endpoint
 * @param requiredLevel
 * @returns {boolean}
 */
export const canAccessProviderEndpoint = (
  user,
  providerId,
  endpoint,
  requiredLevel
) => {
  if (endpoint === 'me' && user) return true;
  if (isSuperAdmin(user)) return true;
  const relevantRoles = (user?.providerRoles || [])
    .filter((r) => r.providerId === providerId)
    .concat(user.globalRoles || [])
    .map((r) => r.role);
  return checkPermissionAccess(relevantRoles, endpoint, requiredLevel);
};

export const canAccessAccountEndpoint = (
  user,
  accountId,
  endpoint,
  requiredLevel
) => {
  if (endpoint === 'me' && user) return true;
  if (isSuperAdmin(user)) return true;
  const relevantRoles = (user.accountRoles || [])
    .filter((r) => r.accountId === accountId)
    .map((r) => r.role);
  return checkPermissionAccess(relevantRoles, endpoint, requiredLevel);
};

export const canAccess = (
  user,
  providerId,
  accountId,
  endpoint,
  requiredLevel
) => {
  if (canAccessSystemEndpoint(user, endpoint, requiredLevel)) {
    return true;
  }

  if (canAccessGlobalEndpoint(user, endpoint, requiredLevel)) {
    return true;
  }

  if (canAccessProviderEndpoint(user, providerId, endpoint, requiredLevel)) {
    return true;
  }

  if (canAccessAccountEndpoint(user, accountId, endpoint, requiredLevel)) {
    return true;
  }

  return false;
};

export const hasProviderRoles = (user) => {
  return isSuperAdmin(user) || user?.providerRoles?.length > 0;
};

export const hasGlobalRoles = (user) => {
  return isSuperAdmin(user) || (user?.globalRoles || []).length > 0;
};

export const hasSystemRoles = (user) => {
  return isSuperAdmin(user) || (user?.systemRoles || []).length > 0;
};

/**
 * @deprecated this relies on window.user and window.provider, which is not a good pattern, please use canAccess directly instead.
 * you can use useUser() to get the user and provider.
 *
 *
 * @param endpoint
 * @param requiredLevel
 * @return {boolean}
 */
export const currentUserCanAccess = (endpoint, requiredLevel) => {
  const { user, provider } = window;
  return canAccess(user, provider.id, user.accountId, endpoint, requiredLevel);
};

export const currentUserHasGlobalRoles = () => {
  const { user, provider } = window;
  return hasGlobalRoles(user);
};

export const currentUserHasMaintenanceAccess = (object) => {
  const { user } = window;
  return (
    user &&
    object &&
    object.maintenanceAccountId &&
    object.maintenanceAccountId === user.accountId
  );
};

export const currentUserMatchesProviderRole = (keywords) => {
  const { user } = window;
  const roleNames = user?.providerRoles?.map((role) => {
    return role.role.name;
  });
  for (const roleName of roleNames) {
    if (roleName.match(keywords)) return true;
  }
  return false;
};

/**
 *
 * @param endpoint
 * @param requiredLevel
 * @param providerId
 * @returns {boolean|boolean|*}
 *
 * @deprecated this relies on window.user and window.provider, which is not a good pattern, please use userCanAccessProviderEndpoint instead, useUser() can get you the user and provider.
 */
export const currentUserCanAccessProviderEndpoint = (
  endpoint,
  requiredLevel,
  providerId
) => {
  const { user, provider } = window;
  if (!user) {
    return false;
  }
  return canAccessProviderEndpoint(
    user,
    providerId || provider.id,
    endpoint,
    requiredLevel
  );
};

/**
 *
 * @param user
 * @param provider
 * @param endpoint
 * @param requiredLevel
 * @returns {boolean}
 */
export const userCanAccessProviderEndpoint = (
  user,
  provider,
  endpoint,
  requiredLevel
) => {
  if (!user) {
    return false;
  }
  return canAccessProviderEndpoint(user, provider.id, endpoint, requiredLevel);
};

export const currentUserCanAccessAccountEndpoint = (
  endpoint,
  requiredLevel
) => {
  const { user } = window;
  return canAccessAccountEndpoint(
    user,
    user.accountId,
    endpoint,
    requiredLevel
  );
};

export const currentUserCanAccessSystemEndpoint = (endpoint, requiredLevel) => {
  const { user } = window;
  return canAccessSystemEndpoint(user, endpoint, requiredLevel);
};

export const currentUserIsSuperAdmin = () => {
  const { user } = window;
  return isSuperAdmin(user);
};

/**
 *
 * @param permissions
 * @param {string} endpoint
 * @param {string} requiredLevel
 * @return {boolean}
 */
export function hasFacilitatorPermissionsAccess(
  permissions,
  endpoint,
  requiredLevel
) {
  const permissionValue = (permissions && permissions[endpoint]) || 'none';
  return meetsLevel(permissionValue, requiredLevel);
}
