import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';

export default class NewSwarmTest extends React.Component {
  constructor(props) {
    super(props);
    let ocppUrl = 'ws://localhost:2600/1.6/e-flux';
    if (window.location.hostname === 'dashboard.e-flux.dev') {
      ocppUrl = 'ws://ocpp-ingress.e-flux.dev/1.6/e-flux';
    }

    this.state = {
      open: false,
      saving: false,
      error: null,
      ocppUrl: ocppUrl,
      numChargepoints: 10,
      timeoutBetweenInit: 1,
      messagesPerChargepoint: 10,
      timeoutBetweenCommands: 1000,
      timeoutAfterConnection: 0,
      comment: '',
      startIn: 20_000, // on close delay the start time by 1 minute
    };
  }

  updateStateFromEvent = (key, event) => {
    this.setState({ [key]: event.target.value });
  };

  handleSubmit = async () => {
    this.setState({ saving: true });
    try {
      await this.props.onChange(this.state);
    } catch (error) {
      this.setState({ error: error, saving: false });
      return;
    }
    this.setState({ saving: false, open: false, error: null });
  };

  render() {
    const { trigger } = this.props;

    return (
      <Modal
        closeIcon
        trigger={trigger}
        onClose={() => this.setState({ open: false })}
        onOpen={() => this.setState({ open: true })}
        open={this.state.open}>
        <Modal.Header>Add new OCPP multi swarm load test</Modal.Header>
        <Modal.Content>
          {this.state.error && (
            <Message negative>
              <p>{this.state.error.toString()}</p>
            </Message>
          )}
          <Form>
            <Form.Input
              onChange={(event) => this.updateStateFromEvent('ocppUrl', event)}
              label="OCPP URL"
              value={this.state.ocppUrl}
            />
            <Form.Input
              onChange={(event) =>
                this.updateStateFromEvent('numChargepoints', event)
              }
              type="number"
              label="Number of chargepoints per node"
              value={this.state.numChargepoints}
            />
            <Form.Input
              onChange={(event) =>
                this.updateStateFromEvent('timeoutBetweenInit', event)
              }
              type="number"
              label="Timeout between init"
              value={this.state.timeoutBetweenInit}
            />
            <Form.Input
              onChange={(event) =>
                this.updateStateFromEvent('messagesPerChargepoint', event)
              }
              type="number"
              label="Messages per chargepoint per node"
              value={this.state.messagesPerChargepoint}
            />
            <Form.Input
              onChange={(event) =>
                this.updateStateFromEvent('timeoutBetweenCommands', event)
              }
              type="number"
              label="Timeout between Commands"
              value={this.state.timeoutBetweenCommands}
            />
            <Form.Input
              onChange={(event) =>
                this.updateStateFromEvent('timeoutAfterConnection', event)
              }
              type="number"
              label="Timeout after connection"
              value={this.state.timeoutAfterConnection}
            />
            <Form.Input
              onChange={(event) => this.updateStateFromEvent('startIn', event)}
              type="number"
              label="Schedule start delay (should be higher than node poll time)"
              value={this.state.startIn}
            />
            <Form.TextArea
              onChange={(event) => this.updateStateFromEvent('comment', event)}
              label="Comment"
              value={this.state.comment}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content="Start"
            onClick={this.handleSubmit}
            loading={this.state.saving}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
