import React from 'react';
import { Header, Table, Divider } from 'semantic';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { formatPhone } from 'utils/formatting';
import { formatDateTime } from 'utils/date';

class AccountOverview extends React.Component {
  render() {
    const { user, t } = this.props;
    const { contact, account } = user;

    return (
      <div>
        {contact && (
          <div>
            <Header as="h3">
              {t('user.contactDetails', 'Contact Details')}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{t('user.firstName', 'First Name')}</Table.Cell>
                  <Table.Cell>{contact.firstName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('user.lastName', 'Last Name')}</Table.Cell>
                  <Table.Cell>{contact.lastName}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('user.email', 'E-mail')}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('user.phone', 'Phone No')}</Table.Cell>
                  <Table.Cell>
                    {formatPhone(contact.phoneNo, contact.phoneCountryCode)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('user.account', 'Account')}</Table.Cell>

                  <Table.Cell>
                    <Link to={`/accounts/${user.accountId}`}>
                      {t('user.showAccount', 'Show Account')}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
          </div>
        )}

        {account && (
          <div>
            <Header as="h3">
              {t('user.accountDetails', 'Account Details')}
            </Header>
            <Table definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {t('user.organizationName', 'Organization Name')}
                  </Table.Cell>
                  <Table.Cell>
                    {account.organization && account.organization.name}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>{t('user.accountType', 'Type')}</Table.Cell>
                  <Table.Cell>{account.type}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider hidden />
          </div>
        )}

        <Header as="h3">{t('user.otherDetails', 'Other Details')}</Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {t('user.defaultLangCode', 'Default Lang Code')}
              </Table.Cell>
              <Table.Cell>{user.defaultLangCode}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t('user.mfaMethod', 'MFA Method')}</Table.Cell>
              <Table.Cell>{user.mfaMethod}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t('user.createdAt', 'Created At')}</Table.Cell>
              <Table.Cell>{formatDateTime(user.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{t('user.updatedAt', 'Updated At')}</Table.Cell>
              <Table.Cell>{formatDateTime(user.updatedAt)}</Table.Cell>
            </Table.Row>
            {user?.deletedAt && (
              <Table.Row>
                <Table.Cell>{t('user.deletedAt', 'Deleted At')}</Table.Cell>
                <Table.Cell>{formatDateTime(user?.deletedAt)}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(AccountOverview);
