import React, { Component } from 'react';
import { Form } from 'semantic';
import { set } from 'lodash-es';
import ListField from 'components/form-fields/List';

function checkRefType(field, definitions) {
  if (!field['$ref']) {
    return field;
  }

  const defKey = field['$ref']?.split('/').pop();
  return definitions[defKey];
}

export default class JSONSchemaInputs extends Component {
  constructor(props) {
    super(props);
    this.state = { params: props.params || {} };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.params !== prevState.params) {
      return {
        params: nextProps.params,
      };
    }
    return null;
  }

  setParamsField(path, value) {
    this.setState((prevState) => {
      const newParams = { ...prevState.params };
      set(newParams, path, value);
      this.props.onChange(newParams);
      return { params: newParams };
    });
  }

  render() {
    const { params } = this.state;
    const { schema } = this.props;
    const { properties, required, definitions } = schema;

    return (
      <>
        {Object.keys(properties).map((key) => {
          let label = key;
          let field = properties[key];

          // check if field is ref type
          field = checkRefType(field, definitions);

          let { type, items, maxLength, $ref } = field;

          const enumValue = field.enum;
          const value = params[key] || '';
          let Element = Form.Input;

          if (type === 'object' || $ref) {
            Element = Form.TextArea;
            label += ' (JSON)';
          }
          if (type === 'array') {
            if (items && items.type === 'string') {
              Element = ListField;
            } else {
              Element = Form.TextArea;
              label += ' (JSON)';
            }
          }
          if (type === 'string' && maxLength > 100) {
            label += ` (max ${maxLength} characters)`;
            Element = Form.TextArea;
          }
          let options = undefined;
          if (enumValue) {
            Element = Form.Select;
            options = enumValue.map((key) => {
              return {
                key,
                value: key,
                text: key,
              };
            });
          }
          return (
            <Element
              required={(required || []).includes(key)}
              key={key}
              label={label}
              name={key}
              value={value}
              onChange={(e, { name, value }) => {
                this.setParamsField(name, value);
              }}
              options={options}
            />
          );
        })}
      </>
    );
  }
}

JSONSchemaInputs.normalize = function (schema, params) {
  const { properties, definitions } = schema;
  if (!properties) return params;
  const newParams = {};

  Object.keys(params).forEach((key) => {
    if (!properties[key]) {
      newParams[key] = params[key];
    } else {
      const field = checkRefType(properties[key], definitions);

      const { type, items, $ref } = field;
      if (type === 'integer') {
        newParams[key] = parseInt(params[key], 10);
      } else if (type === 'object' || $ref) {
        newParams[key] = JSON.parse(params[key]);
      } else if (type === 'array') {
        if (items && items.type === 'string') {
          newParams[key] = params[key];
        } else {
          newParams[key] = JSON.parse(params[key]);
        }
      } else {
        newParams[key] = params[key];
      }
    }
  });
  return newParams;
};
