import React from 'react';
import { Header, Container, Divider, Icon } from 'semantic';
import MAP_SDK_MD from 'docs/location-map/OVERVIEW.md';
import StandardPage from './StandardPage';
import { request } from 'utils/api';
import PortalNav from 'components/PortalNav';
import { Layout } from 'components/Layout';
import { getThemedField } from 'utils/theming';
import PageLoader from 'components/PageLoader';

export default class LocationMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openApi: null,
      loading: true,
      error: null,
    };
  }

  state = {
    loading: true,
    error: null,
  };

  async componentDidMount() {
    try {
      const openApi = await request({
        method: 'GET',
        path: '/openapi.lite.json',
      });
      this.setState({
        loading: false,
        openApi,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, openApi, error } = this.state;

    const isDark = this.context.renderedTheme === 'dark';

    const backgroundStyle = {};

    const secondaryBackgroundColorHex = getThemedField(
      window.provider,
      'secondaryBackgroundColorHex',
      isDark
    );

    if (secondaryBackgroundColorHex) {
      backgroundStyle.background = secondaryBackgroundColorHex;
    }

    if (loading) {
      return <PageLoader />;
    }

    return (
      <>
        <PortalNav.Content>
          <PortalNav.MobileHead style={backgroundStyle}>
            <Layout horizontal center spread style={{ overflow: 'visible' }}>
              <div />
              <PortalNav.Trigger>
                <Icon name="bars" fitted />
              </PortalNav.Trigger>
            </Layout>
          </PortalNav.MobileHead>
          <Layout vertical>
            <Container>
              <Header as="h1">
                <Header.Content>Locations Map</Header.Content>
              </Header>
              <StandardPage
                openApi={openApi}
                page={{
                  markdown: MAP_SDK_MD,
                }}
              />
              <Divider hidden />
              <Divider hidden />
            </Container>
          </Layout>
        </PortalNav.Content>
      </>
    );
  }
}
