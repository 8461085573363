import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Issues from './List/Issues';
import Detail from './Detail';
import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function EvseControllerIndex() {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path="/charging-stations/issues" component={Issues} exact />
          <Route path="/charging-stations/:id" component={Detail} />
          <Route path="/charging-stations/:id/*" component={Detail} />
          <Route path="/charging-stations" component={List} exact />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
