import React, { useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Button } from 'semantic';
import { useTranslation } from 'react-i18next';

import { Breadcrumbs, Search, ListHeader } from 'components';
import { UserContext } from 'contexts/user';
import { request } from 'utils/api';
import Table from './Table';
import InviteSingleUser from 'components/modals/InviteSingleUser';
import { useFeatures } from 'contexts/features';

const MyUsers = () => {
  const { t } = useTranslation();
  const searchRef = useRef(null);
  const userContext = useContext(UserContext);
  const { hasFeature } = useFeatures();

  const writeAccess = userContext.canAccess('users', 'write');

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/1/invites/user/search',
      body: {
        ...filters,
      },
    });
  };

  return (
    <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
      {() => {
        return (
          <>
            {/* TODO: after adopting to reorganized settings, false condition to be removed */}
            {hasFeature('account_settings_reorganization') ? (
              <Breadcrumbs active={t('myUsers.title', 'Users')} />
            ) : (
              <Breadcrumbs
                path={[
                  <Link key="my-account" to="/my-account">
                    {t('settings.settingsAccount', 'Billing Settings')}
                  </Link>,
                ]}
                active={t('myUsers.title', 'Users')}
              />
            )}

            <ListHeader title={t('myUsers.title', 'Users')}>
              {writeAccess && (
                <InviteSingleUser
                  onboardAccountRoles={true}
                  onClose={() => {
                    searchRef.current?.reload();
                  }}
                  trigger={
                    <Button
                      primary
                      style={{ marginTop: '1px' }}
                      disabled={!writeAccess}
                      content={t(
                        'accountInvites.inviteSingleUser',
                        'Invite User'
                      )}
                      icon="plus"
                    />
                  }
                />
              )}
            </ListHeader>

            <Search.Status
              noItems={t('myUsers.noUsersYet', 'No users added yet')}
            />
            <Table user={userContext.user} writeAccess={writeAccess} />
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
};

export default MyUsers;
