import React, { ReactNode, useMemo } from 'react';
import {
  Dimmer,
  Divider,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from 'semantic';
import panelStyles from './panel.module.less';

type PanelProps = {
  headerText: string;
  subHeaderText: string;
  content: ReactNode;
  belowContent: ReactNode;
  navigateDirection: 'forward' | 'backward' | 'none';
  hideBelowContentOnInit?: boolean;
  hideBelowContent?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  step?: string;
};

function useAnimationStyle(navigateDirection: 'forward' | 'backward' | 'none') {
  return useMemo(() => {
    if (navigateDirection === 'forward') {
      return panelStyles.slideInFromRight;
    }
    if (navigateDirection === 'backward') {
      return panelStyles.slideInFromLeft;
    }
    return '';
  }, []);
}

export default function Panel(props: PanelProps) {
  const animationStyle = useAnimationStyle(props.navigateDirection);

  return (
    <>
      <Grid.Row centered>
        <Grid.Column>
          <Segment className={animationStyle} style={{ padding: 0 }}>
            <Dimmer inverted active={props.isLoading}>
              <Loader />
            </Dimmer>
            <div className={panelStyles.panelText}>
              <PanelHeader
                headerText={props.headerText}
                subHeaderText={props.subHeaderText}
              />
              {props.content}
              {props.errorMessage && (
                <Message error>{props.errorMessage}</Message>
              )}
            </div>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <PanelFooter navigateDirection={props.navigateDirection}>
        {!props.hideBelowContent ? props.belowContent : null}
      </PanelFooter>
    </>
  );
}

type PanelFooterProps = {
  children: ReactNode;
  navigateDirection: 'forward' | 'backward' | 'none';
};

function PanelFooter({ children, navigateDirection }: PanelFooterProps) {
  const animationStyle = useAnimationStyle(navigateDirection);
  return (
    <Grid.Row className={animationStyle} centered>
      {children}
    </Grid.Row>
  );
}

type PanelHeaderProps = {
  headerText: string;
  subHeaderText: string;
};

export function PanelHeader(props: PanelHeaderProps) {
  return (
    <>
      <Header as="h2" style={{ fontWeight: 600 }}>
        {props.headerText}
      </Header>
      <p style={{ fontWeight: 500 }}>{props.subHeaderText}</p>
      <Divider />
    </>
  );
}
