import { startOfDay, endOfDay, subDays } from 'date-fns';

export function getMaxRange(numberOfDays, to = new Date()) {
  const from = subDays(to, numberOfDays);

  return {
    $gte: startOfDay(from),
    $lte: endOfDay(to),
  };
}
