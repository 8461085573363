import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';

export default function Users() {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path="/accounts/users" component={List} exact />
          <Route path="/accounts/users/:id" component={Detail} />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
