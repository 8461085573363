import React, { useEffect, useState } from 'react';

import { Loader, Message } from 'semantic';

import { request } from 'utils/api';

type EvseLinkProps = {
  match: {
    params: {
      id: string;
    };
  };
};

export default function EvseLink(props: EvseLinkProps) {
  const [state, setState] = useState<{ error: { message: string } }>();

  useEffect(() => {
    const evseId = props.match.params.id;

    const redirectBasedOnEvse = async function () {
      try {
        const { data } = await request({
          method: 'GET',
          path: `/1/evse-ids/url/${evseId}`,
          params: {
            language: navigator.language,
          },
        });

        document.location = data;
      } catch (error: any) {
        setState({ error });
        return;
      }
    };

    redirectBasedOnEvse();
  }, []);

  return (
    <div>
      {state?.error && <Message error content={state.error.message} />}
      <Loader active content="Redirecting.." />
    </div>
  );
}
