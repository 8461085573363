import CopySnippet from 'components/CopySnippet';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container, Grid, Header, List } from 'semantic';
import { request } from '../../utils/api';
import { determineOcppBaseUrl } from '../ChargeStationSimulator';
import { Counter, Paragraph } from './Components';

class SandboxMain extends React.Component {
  state = {
    cpo: {
      sessions: 0,
      locations: 0,
      evseControllers: 0,
      externalTokens: 0,
    },
    msp: {
      sessions: 0,
      users: 0,
      cards: 0,
      tokens: 0,
    },
  };

  async componentDidMount() {
    const { data: stats } = await request({
      method: 'GET',
      path: '/1/sandbox/entities',
    });

    this.setState({
      cpo: {
        sessions: stats?.cpo?.sessions || 0,
        locations: stats?.cpo?.locations || 0,
        evseControllers: stats?.cpo?.evseControllers || 0,
        externalTokens: stats?.cpo?.externalTokens || 0,
      },
      msp: {
        sessions: stats?.msp?.sessions || 0,
        users: stats?.msp?.users || 0,
        cards: stats?.msp?.cards || 0,
        tokens: stats?.msp?.tokens || 0,
      },
    });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Container>
          <Grid reversed="computer">
            <Grid.Column>
              <Header as="h1" style={{ margin: '0' }}>
                {t('sandbox.dashboard', 'Sandbox overview')}
              </Header>
            </Grid.Column>
          </Grid>
          <Paragraph>
            {t(
              'sandbox.welcome',
              'Welcome to the road.io sandbox environment!'
            )}
          </Paragraph>
          <Paragraph>
            {t(
              'sandbox.context',
              'Here you can test your integrations, experiment with different configurations and explore our API in a safe and controlled environment.'
            )}
          </Paragraph>
          <Paragraph>
            {t(
              'sandbox.createdEntities',
              'We have also gone ahead and generated some data for you to experiment with:'
            )}
          </Paragraph>
          <Grid stackable columns={4} style={{ padding: '1.2rem' }}>
            <Grid.Row>
              <Counter value={this.state.cpo.sessions} label="CPO Sessions" />
              <Counter value={this.state.cpo.locations} label="Locations" />
              <Counter
                value={this.state.cpo.evseControllers}
                label="EVSE controllers"
              />
              <Counter value={this.state.cpo.externalTokens} label="Tokens" />
            </Grid.Row>
          </Grid>
          <Grid stackable columns={4} style={{ padding: '1.2rem' }}>
            <Grid.Row>
              <Counter value={this.state.msp.sessions} label="MSP Sessions" />
              <Counter value={this.state.msp.users} label="Users" />
              <Counter value={this.state.msp.cards} label="Cards" />
              <Counter value={this.state.msp.tokens} label="Tokens" />
            </Grid.Row>
          </Grid>
          <Paragraph>
            {t(
              'sandbox.credentialIntro',
              'You can now use the following credentials to test your integration:'
            )}
          </Paragraph>
          <CopySnippet
            language="bash"
            value={this.props.credentials?.apiToken || ''}
          />
          <Header as="h2" style={{ marginTop: '1rem', marginBottom: '0.5rem' }}>
            Examples
          </Header>
          <Paragraph>
            {t(
              'sandbox.quickExamplesHelp',
              'Below you will find a few examples '
            )}
          </Paragraph>
          <List>
            <List.Item style={{ marginBottom: '1rem' }}>
              <List.Icon name="code" size="large" verticalAlign="middle" />
              <List.Content>
                <Link to={'/sandbox/cpo'}>
                  <List.Header>CPO</List.Header>
                  <List.Description>API calls for CPO data</List.Description>
                </Link>
              </List.Content>
            </List.Item>
            <List.Item style={{ marginBottom: '1rem' }}>
              <List.Icon name="code" size="large" verticalAlign="middle" />
              <List.Content>
                <Link to={'/sandbox/msp'}>
                  <List.Header>MSP</List.Header>
                  <List.Description>API calls for MSP data</List.Description>
                </Link>
              </List.Content>
            </List.Item>
          </List>
          <Header as="h2" style={{ marginTop: '3rem', marginBottom: '0.5rem' }}>
            Useful links
          </Header>
          <List>
            <List.Item style={{ marginBottom: '1rem' }}>
              <List.Icon name="code" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header as="a" href="/docs">
                  Documentation
                </List.Header>
                <List.Description as="a">
                  Get detailed information on our API
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item style={{ marginBottom: '1rem' }}>
              <List.Icon name="code" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header
                  as="a"
                  href={`https://chargestation.one?ocppBaseUrl=${determineOcppBaseUrl()}`}>
                  Charge station simlator
                </List.Header>
                <List.Description as="a">
                  Our open source simulator for testing your integration
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </>
    );
  }
}

export default withTranslation()(SandboxMain);
