import React from 'react';
import Pagination from 'components/Pagination';

import styled from 'styled-components';
import QRCode from 'components/QRcode';
import { Link } from 'react-router-dom';

import {
  Table,
  Loader,
  Segment,
  Message,
  Modal,
  Button,
  Divider,
} from 'semantic';
import { formatEvseId } from 'utils/formatting';
import { currentUserCanAccessProviderEndpoint } from 'utils/roles';
import { formatDateTime } from 'utils/date';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

export default class DataTable extends React.Component {
  render() {
    const { status, data: items, meta = {}, page } = this.props;
    const writeAccess = currentUserCanAccessProviderEndpoint(
      'evseIds',
      'write'
    );
    return (
      <div className="list">
        {status.success && !items.length && (
          <Message>No EVSE ID generated yet</Message>
        )}

        {status.success && items.length > 0 && (
          <div>
            <p>Total number of EVSE IDs: {meta.total}</p>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Identifier</Table.HeaderCell>
                  <Table.HeaderCell width={4}>EVSE Controller</Table.HeaderCell>
                  <Table.HeaderCell width={2} textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {items.map((item) => {
                  return (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        <code>{formatEvseId(item.identifier)}</code>
                      </Table.Cell>
                      <Table.Cell>
                        {item.evseControllerId ? (
                          <Link
                            to={`/charging-stations/${item.evseControllerId}`}>
                            {item.evseControllerId}
                          </Link>
                        ) : (
                          '-'
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Modal
                          header={`Are you sure you want to delete?`}
                          content="All data will be permanently deleted"
                          trigger={
                            <Button
                              disabled={!writeAccess}
                              basic
                              icon="trash"
                              title="Delete"
                            />
                          }
                          closeIcon
                          actions={[
                            {
                              key: 'delete',
                              primary: true,
                              content: 'Delete',
                              onClick: () => this.props.onDeleteItem(item),
                            },
                          ]}
                        />
                        <Modal
                          trigger={<Button basic icon="link" />}
                          closeIcon
                          size="small">
                          <Modal.Header>EVSE Link</Modal.Header>
                          <Modal.Content>
                            <p>
                              This URL can be used for support and payments in
                              the future:
                            </p>
                            <p>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer">
                                {item.url}
                              </a>
                            </p>
                            <p>QR Code:</p>
                            <QRCode data={item.url} />
                          </Modal.Content>
                        </Modal>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
        <Divider hidden />
        {status.success && meta.total > this.props.limit && (
          <Center>
            <Pagination
              limit={this.props.limit}
              page={page}
              total={meta.total}
              onPageChange={(e, { activePage }) => {
                this.props.setPage(activePage).then(() => {
                  window.scrollTo(0, 0);
                });
              }}
            />
          </Center>
        )}
        {status.request && (
          <Segment style={{ height: '100px' }}>
            <Loader active>Loading</Loader>
          </Segment>
        )}
        {status.error && <Message error content={status.error.message} />}
      </div>
    );
  }
}
