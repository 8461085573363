import React, { CSSProperties } from 'react';
import { FieldValidator, useField } from 'formik';
import { Label, Form } from 'semantic';
import { DropdownItemProps } from 'semantic-ui-react';

interface Props {
  name: string;
  label?: string;
  validate?: FieldValidator;
  options: DropdownItemProps[];
  clearable?: boolean;
  objectMode?: boolean;
  search?: boolean;
  required?: boolean;
  multiple?: boolean;
  selection?: boolean;
  fluid?: boolean;
  hideErrorLabel?: boolean;
  wrapperStyle?: CSSProperties;
  disabled?: boolean;
  hideInternalComponentError?: boolean;
  postChange?: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  allowAdditions?: boolean;
  className?: string;
}
const DropdownField: React.FC<Props> = ({
  name,
  label,
  validate,
  options,
  search = false,
  multiple = false,
  selection = false,
  objectMode = false,
  clearable = true,
  required = false,
  hideErrorLabel = false,
  hideInternalComponentError = false,
  fluid = false,
  wrapperStyle,
  disabled = false,
  postChange,
  placeholder,
  style,
  allowAdditions = false,
  className,
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  return (
    <Form.Field>
      <div style={wrapperStyle}>
        <Form.Dropdown
          className={className}
          search={search}
          clearable={clearable}
          allowAdditions={allowAdditions}
          fluid={fluid}
          value={field.value}
          objectMode={objectMode}
          label={label}
          style={style}
          required={required}
          multiple={multiple}
          selection={selection}
          options={options}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e, { value }) => {
            helpers.setValue(value, true);
            if (postChange) {
              postChange(value as string);
            }
          }}
          error={
            meta.touched && meta.error
              ? hideErrorLabel || hideInternalComponentError
                ? undefined
                : meta.error
              : undefined
          }
        />

        {/* We had to add this label to show error message box below the dropdown list */}
        {meta.error && meta.touched && !hideErrorLabel && (
          <Label
            basic
            pointing={'above'}
            style={{
              color: '#9F3A38',
              marginTop: -15,
              border: '1px solid #E0B4B4',
            }}>
            {meta.error}
          </Label>
        )}
      </div>
    </Form.Field>
  );
};

export default DropdownField;
