import React from 'react';
import EditLocation from 'components/modals/EditLocation';
import InspectObject from 'components/modals/InspectObject';
import { labelPrintUrlForLocation } from 'utils/locations';
import { Dropdown } from 'semantic';
import { Table, Modal, Button } from 'semantic';
import SearchContext from 'components/old_search/Context';
import { SearchProvider } from 'components';
import { useTranslation } from 'react-i18next';
import SyncLogStatus from 'components/modals/SyncLogStatus';
import ResourceLink from 'components/Link/ResourceLink';
import { formatDateTime } from 'utils/date';
import { currentUserIsSuperAdmin } from 'utils/roles';
import { request } from 'utils/api';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';

export default function LocationTables({
  exportUrl,
  hideInspect,
  onRecompute,
  onDeleteItem,
}) {
  const { items, loading, reload } = React.useContext(SearchContext);
  const { t } = useTranslation();

  async function getCpoLocation(id) {
    const result = await request({
      method: 'GET',
      path: `/2/locations/cpo/${id}`,
    });
    return result.data;
  }

  return (
    <div className="list">
      <SearchProvider.Status noItems={t('common.noResults', 'No Results')} />

      {!loading && items.length !== 0 && (
        <>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell width={3}>Account</Table.HeaderCell>
                <Table.HeaderCell width={2}>Policy</Table.HeaderCell>
                <Table.HeaderCell width={2}>Created</Table.HeaderCell>
                <Table.HeaderCell width={3}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items.map((item) => {
                const showSyncStatus =
                  onRecompute && item.publishingMode !== 'private';

                // Search result provider id
                const externalLinkItemProviderId = item?.providerId;

                // External Link Routes
                const externalLinkLocationsRoute = `/charging-stations/locations/${item?.id}`;
                const externalLinkAccountRoute = `/accounts/${item?.accountId}`;

                // Link names
                const externalLinkLocationName = item?.name || 'No Name';
                const externalLinkAccountName = item?.account?.name;

                return (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      <ResourceLink
                        route={externalLinkLocationsRoute}
                        searchItemProviderId={externalLinkItemProviderId}
                        linkName={externalLinkLocationName}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {item.account && (
                        <ResourceLink
                          route={externalLinkAccountRoute}
                          searchItemProviderId={externalLinkItemProviderId}
                          linkName={externalLinkAccountName}
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>{item.accessPolicy}</Table.Cell>
                    <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <EditLocation
                        getData={() => getCpoLocation(item.id)}
                        trigger={
                          <Button
                            basic
                            icon="pen-to-square"
                            title="Edit"
                            onClick={() =>
                              setResourceProviderIdInSessionStorage(
                                item?.providerId
                              )
                            }
                          />
                        }
                        onClose={() => {
                          removeResourceProviderIdInSessionStorage();
                          reload();
                        }}
                      />
                      <Dropdown button basic text={t('common.more', 'More')}>
                        <Dropdown.Menu direction="left">
                          {onRecompute && (
                            <Dropdown.Item
                              icon="arrow-rotate-right"
                              content="Recompute Location"
                              onClick={async () => {
                                await onRecompute(item);
                                await reload();
                              }}
                            />
                          )}
                          {!hideInspect && (
                            <InspectObject
                              name="Location"
                              getData={() =>
                                getCpoLocation(item._id || item.id)
                              }
                              trigger={
                                <Dropdown.Item
                                  content="Inspect"
                                  icon="file-code"
                                />
                              }
                            />
                          )}
                          {!hideInspect && (
                            <Dropdown.Item
                              icon="print"
                              as="a"
                              rel="noopener"
                              target="_blank"
                              content="Print Location"
                              href={labelPrintUrlForLocation(item)}
                            />
                          )}
                          {currentUserIsSuperAdmin() && (
                            <SyncLogStatus
                              getData={() =>
                                getCpoLocation(item._id || item.id)
                              }
                              modelName="location"
                              size="fullscreen"
                              syncStatusName="Location Push"
                              trigger={
                                <Dropdown.Item
                                  disabled={!showSyncStatus}
                                  text="Sync status"
                                  icon="wand-magic-sparkles"
                                />
                              }
                            />
                          )}

                          <Modal
                            header={`Are you sure you want to delete?`}
                            content="All data will be permanently deleted"
                            trigger={
                              <Dropdown.Item icon="trash" content="Delete" />
                            }
                            closeIcon
                            actions={[
                              {
                                key: 'delete',
                                primary: true,
                                content: 'Delete',
                                onClick: async () => {
                                  await onDeleteItem(item);
                                  await reload();
                                },
                              },
                            ]}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <SearchProvider.Pagination />

          {exportUrl && (
            <SearchProvider.ExportButton
              simple={true}
              content="Export All"
              path={exportUrl}
              filename="locations"
            />
          )}
        </>
      )}
    </div>
  );
}
