import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Table, Button, Label, Progress, Dropdown, Checkbox } from 'semantic';
import { useHistory } from 'react-router';
import { currentUserCanAccess } from 'utils/roles';
import { Search, FeatureFlag } from 'components';
import { capitalize } from 'lodash-es';
import {
  getSetupProgress,
  isEvseControllersFromSameVendor,
  anyEvseControllersPendingFirstConnection,
  getEvseProtocols,
} from 'utils/evse-controllers';
import { ConnectivityStatus } from 'components/ConnectivityStatus';
import EditEvseController from 'components/modals/EditEvseController';
import AssignTariffProfile from 'components/modals/AssignTariffProfile';
import { useToast } from 'components/Toast';
import Actions from '../Actions';
import { EvseControllerEvseId } from '../../../components/EvseId';
import { request } from 'utils/api';
import ResourceLink from 'components/Link/ResourceLink';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import {
  BulkActionsIsland,
  toggle,
  toggleAll,
} from 'components/BulkActionsIsland';
import { useFeatures } from 'contexts/features';
import ConfirmWithWarningFirmwareUpdateBulk from 'components/modals/ConfirmWithWarningFirmwareUpdateBulk';
import {
  EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH,
  hasEvseControllersBackgroundJobsPermissions,
} from 'screens/EvseControllersBackgroundJobs/utils';
import ConfirmWithWarningChangeConfigurationBulk from 'components/modals/ConfirmWithWarningChangeConfigurationBulk';
import ConfirmWithWarningExecuteCommandBulk from 'components/modals/ConfirmWithWarningExecuteCommandBulk';
import EditEvseControllers from 'components/modals/EditEvseControllers';
import { EvseOperationalStatusType } from 'types/evse-operational-status';

const itemsEqual = (a, b) => a.id === b.id;

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item) => <Table.Cell>{item.ocppIdentity}</Table.Cell>,
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item) => <Table.Cell>{item.account?.name || '-'}</Table.Cell>,
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item) => <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>,
  },
  {
    title: <Table.HeaderCell>Firmware Version</Table.HeaderCell>,
    data: (item) => (
      <Table.Cell textAlign="center">
        {item.bootInfo?.firmwareVersion || '-'}
      </Table.Cell>
    ),
  },
];

export default function EvseControllersTable() {
  const { items, reload, loading } = useContext(Search.Context);
  const [selectedItems, setSelectedItems] = useState([]);
  const assignTariffProfileRef = React.useRef(null);
  const editEvseControllersRef = React.useRef(null);
  const confirmWithWarningFirmwareUpdateBulkRef = React.useRef(null);
  const confirmWithWarningChangeConfigurationBulkRef = React.useRef(null);
  const confirmWithWarningExecuteCommandBulkRef = React.useRef(null);
  const { t } = useTranslation();
  const { hasFeature } = useFeatures();
  const toast = useToast();
  const history = useHistory();

  if (!items.length || loading) return null;

  const getEvseController = async (id) => {
    const result = await request({
      method: 'GET',
      path: `/1/evse-controllers/${id}`,
    });
    return result.data;
  };

  const writeAccess = currentUserCanAccess('evseControllers', 'write');
  const hasAccessToBulkOperations =
    hasEvseControllersBackgroundJobsPermissions('write') ||
    hasFeature('bulk-tariff-profile-assignment');
  const hasOperationalStatusesPageFeature = hasFeature(
    'operational_statuses_page'
  );

  const computeActions = () => {
    const a = [];

    if (hasFeature('bulk_action_update_station_settings')) {
      a.push({
        name: t(
          'evseControllerBulkActions.updateStationSettings',
          'Update Station Settings'
        ),
        icon: 'charging-station',
        onExecute: () => editEvseControllersRef.current.click(),
      });
    }

    if (hasFeature('bulk-tariff-profile-assignment')) {
      a.push({
        name: t(
          'evseControllerBulkActions.assignTariffProfile',
          'Assign Tariff Profile'
        ),
        icon: 'dollar-sign',
        onExecute: () => assignTariffProfileRef.current.click(),
      });
    }

    if (hasEvseControllersBackgroundJobsPermissions('write')) {
      a.push({
        name: t('evseControllerBulkActions.firmwareUpdate', 'Update Firmware'),
        icon: 'file-code',
        onExecute: () => {
          const evseControllersWithSameVendor =
            isEvseControllersFromSameVendor(selectedItems);
          const hasEvseControllersPendingFirstConnection =
            anyEvseControllersPendingFirstConnection(selectedItems);

          if (
            evseControllersWithSameVendor &&
            !hasEvseControllersPendingFirstConnection
          ) {
            history.push(
              `${EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH}`,
              { evseControllers: selectedItems }
            );
          } else {
            confirmWithWarningFirmwareUpdateBulkRef.current.click();
          }
        },
      });

      a.push({
        name: t(
          'evseControllerBulkActions.changeConfiguration',
          'Change Configuration'
        ),
        icon: 'pen-to-square',
        onExecute: () => {
          const sameProtocol = getEvseProtocols(selectedItems).length === 1;
          const hasEvseControllersPendingFirstConnection =
            anyEvseControllersPendingFirstConnection(selectedItems);

          if (sameProtocol && !hasEvseControllersPendingFirstConnection) {
            history.push(
              `${EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH}`,
              { evseControllers: selectedItems }
            );
          } else {
            confirmWithWarningChangeConfigurationBulkRef.current.click();
          }
        },
      });

      a.push({
        name: t(
          'evseControllerBulkActions.executeCommands',
          'Execute Commands'
        ),
        icon: 'terminal',
        onExecute: () => {
          const sameProtocol = getEvseProtocols(selectedItems).length === 1;

          if (sameProtocol) {
            history.push(
              `${EVSE_CONTROLLERS_BACKGROUND_JOBS_EXECUTE_COMMAND_FE_PATH}`,
              { evseControllers: selectedItems }
            );
          } else {
            confirmWithWarningExecuteCommandBulkRef.current.click();
          }
        },
      });
    }

    return a;
  };

  return (
    <>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            {hasAccessToBulkOperations && (
              <Table.HeaderCell style={{ width: '1%' }} textAlign="center">
                <Checkbox
                  indeterminate={
                    selectedItems.length > 0 &&
                    items.length !== selectedItems.length
                  }
                  onChange={(e, { checked }) => {
                    toggleAll(
                      setSelectedItems,
                      selectedItems,
                      items,
                      itemsEqual,
                      checked
                    );
                  }}
                />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell width={2}>
              {t('evseControllers.columnOCPP_ID', 'OCPP ID')}
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>
              {t('evseControllers.columnAccounting', 'Accounting')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {hasOperationalStatusesPageFeature
                ? t('evseOperationalStatus.title', 'Operational Status')
                : t('evseControllers.columnSetup', 'Setup')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllers.columnConnectivity', 'Connectivity')}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>
              {t('evseControllers.columnActions', 'Actions')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((item) => {
            const isSelected = selectedItems.some((s) => itemsEqual(s, item));
            const setupProgress = getSetupProgress(item);

            const hasOperationalStatus =
              hasOperationalStatusesPageFeature && item.evseOperationalStatus;

            // Search Result Provider Id
            const externalLinkSearchItemProviderId = item?.providerId;

            // External Link Routes
            const externalLinkEvseControllerRoute = `/charging-stations/${item.id}`;
            const externalLinkAccountRoute = `/accounts/${
              item?.account?.id || item?.accountId
            }`;
            const externalLinkFieldServiceRoute = `/accounts/${
              item?.maintenanceAccount?.id || item?.maintenanceAccountId
            }`;
            const externalLinkLocationRoute = `/charging-stations/locations/${
              item?.location?.id || item?.locationId
            }`;

            // External Link Names
            const externalLinkLinkName = item?.ocppIdentity;
            const externalLinkAccountName = item?.account?.name;
            const externalLinkMaintenanceAccountName =
              item?.maintenanceAccount?.name;
            const externalLinkLocationName = item?.location?.name;

            return (
              <Table.Row key={item.id} active={isSelected}>
                {hasAccessToBulkOperations && (
                  <Table.Cell style={{ width: '1%' }} textAlign="center">
                    <Checkbox
                      checked={isSelected}
                      onChange={(e, { checked }) => {
                        toggle(
                          setSelectedItems,
                          selectedItems,
                          item,
                          itemsEqual,
                          checked
                        );
                      }}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>
                  <ResourceLink
                    route={externalLinkEvseControllerRoute}
                    searchItemProviderId={externalLinkSearchItemProviderId}
                    linkName={externalLinkLinkName}
                  />
                  <p>
                    <small>
                      Evse ID: <EvseControllerEvseId evseController={item} />{' '}
                    </small>{' '}
                    <br />
                    <small>
                      {t('evseController.serialNumber', 'Serial Number: ')}
                      {item.serialNumber || '-'}
                    </small>
                  </p>
                </Table.Cell>
                <Table.Cell>
                  {item.account?.name &&
                    (item.account?.id || item.accountId) && (
                      <>
                        {t('evseControllers.account', 'Account: ')}
                        <ResourceLink
                          route={externalLinkAccountRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkAccountName}
                        />
                        <br />
                      </>
                    )}
                  {item.maintenanceAccount?.name &&
                    (item.maintenanceAccount?.id ||
                      item.maintenanceAccountId) && (
                      <>
                        {t('evseController.fieldService', 'Field Service: ')}
                        <ResourceLink
                          route={externalLinkFieldServiceRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkMaintenanceAccountName}
                        />
                        <br />
                      </>
                    )}
                  {item.location?.name &&
                    (item.location?.id || item.locationId) && (
                      <>
                        {t('evseController.location', 'Location: ')}
                        <ResourceLink
                          route={externalLinkLocationRoute}
                          searchItemProviderId={
                            externalLinkSearchItemProviderId
                          }
                          linkName={externalLinkLocationName}
                        />
                        <br />
                      </>
                    )}
                  {item.billingPlan?.name && <>Plan: {item.billingPlan.name}</>}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {hasOperationalStatus ? (
                    <Label
                      content={
                        item.evseOperationalStatus?.type ===
                        EvseOperationalStatusType.System
                          ? t(
                              `evseOperationalStatuses.systemName.${item.evseOperationalStatus?.name}`,
                              capitalize(item.evseOperationalStatus?.name)
                            )
                          : item.evseOperationalStatus?.name
                      }
                      color={item.evseOperationalStatus?.badgeColor}
                    />
                  ) : setupProgress.completed ? (
                    <Progress percent={100} label="Completed" size="tiny" />
                  ) : setupProgress.readyForActivation ? (
                    <Label
                      color="blue"
                      content="Activation Pending"
                      title="This EVSE is ready to be activated by the customer or linked by admin"
                    />
                  ) : (
                    <Progress
                      percent={setupProgress.percent}
                      label="Setup Progress"
                      size="tiny"
                    />
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <ConnectivityStatus item={item} />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <EditEvseController
                    getData={() => getEvseController(item.id)}
                    trigger={
                      <Button
                        basic
                        icon="pen-to-square"
                        title="Edit"
                        disabled={!writeAccess}
                        onClick={() =>
                          setResourceProviderIdInSessionStorage(
                            item?.providerId
                          )
                        }
                      />
                    }
                    onClose={() => {
                      removeResourceProviderIdInSessionStorage();
                      reload();
                    }}
                  />
                  <Dropdown button basic text={t('common.more', 'More')}>
                    <Dropdown.Menu direction="left">
                      <Actions
                        item={item}
                        onReload={reload}
                        view="table"
                        getEvseController={getEvseController}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <EditEvseControllers
        onDone={() => {
          setSelectedItems([]);
          toast.success(
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <Trans
                i18nKey="EvseControllersTable.updateStationSettingsSuccess.p1"
                defaults="<p><strong>Update Station Settings for {{count}} charging stations</strong> is successfully completed.<br/>The dashboard may take a few minutes to refresh - please reload the page to see the changes.</p>"
                values={{ count: selectedItems.length }}
              />
              <Button
                style={{
                  margin: '0px 2em',
                  display: 'flex',
                  alignItems: 'center',
                  maxHeight: '3em',
                }}
                basic
                icon="arrow-rotate-right"
                loading={loading}
                content={t(
                  'EvseControllersTable.updateStationSettingsSuccess.refresh',
                  'Refresh'
                )}
                onClick={() => reload()}
              />
            </div>
          );
          reload();
        }}
        evseControllerIds={selectedItems.map((item) => item.id)}
        trigger={<div ref={editEvseControllersRef} />}
      />
      <AssignTariffProfile
        onDone={() => {
          setSelectedItems([]);
          toast.success(
            <Trans
              i18nKey="EvseControllersTable.assignSuccess"
              defaults="Update <strong>Assing Tariff Profile to {{count}} charging stations</strong> is successfully completed."
              values={{ count: selectedItems.length }}
            />
          );
          reload();
        }}
        chargingStationIds={selectedItems.map((item) => item.id)}
        trigger={<div ref={assignTariffProfileRef} />}
      />
      <ConfirmWithWarningFirmwareUpdateBulk
        onDone={() =>
          history.push(
            `${EVSE_CONTROLLERS_BACKGROUND_JOBS_FIRMWARE_UPDATE_FE_PATH}`,
            { evseControllers: selectedItems }
          )
        }
        evseControllers={selectedItems}
        trigger={<div ref={confirmWithWarningFirmwareUpdateBulkRef} />}
      />
      <ConfirmWithWarningChangeConfigurationBulk
        onDone={() =>
          history.push(
            `${EVSE_CONTROLLERS_BACKGROUND_JOBS_CHANGE_CONFIGURATION_FE_PATH}`,
            { evseControllers: selectedItems }
          )
        }
        evseControllers={selectedItems}
        trigger={<div ref={confirmWithWarningChangeConfigurationBulkRef} />}
      />
      <ConfirmWithWarningExecuteCommandBulk
        evseControllers={selectedItems}
        trigger={<div ref={confirmWithWarningExecuteCommandBulkRef} />}
      />
      <BulkActionsIsland
        items={items}
        selectedItems={selectedItems}
        onSetSelectedItems={setSelectedItems}
        selectionReviewColumns={selectionReviewColumns}
        equal={itemsEqual}
        actions={computeActions()}
      />
    </>
  );
}
