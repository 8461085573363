import React, { useMemo } from 'react';

import { request } from 'utils/api';
import { Link } from 'react-router-dom';
import { labelForStatus, colorForStatus } from 'utils/locations';
import { Trans, useTranslation } from 'react-i18next';
import NewChargingStation from 'components/modals/NewChargingStation';
import { AccountProvider } from 'contexts/account';
import { canAccess } from 'utils/roles';
import {
  Header,
  Label,
  Message,
  Segment,
  Icon,
  Button,
  Divider,
  Table,
} from 'semantic';
import { useUser } from 'contexts/user';
import InviteEmployee from './InviteEmployee';
import styled from 'styled-components';
import { Search, ListHeader, SearchFilters } from 'components';
import { formatDateTime } from 'utils/date';
import EditFacilitatorAccountPermissions from 'components/modals/EditFacilitatorPermissions';
import useQueryParams from 'hooks/useQueryParams';

const DoubleDigit = styled.span`
  // display: inline-block;
  // text-align: right;
  // width: 1.25rem;
  // margin-right: 0.5rem;
`;

const sort = {
  order: 'asc',
  field: 'createdAt',
};

export default function MyLocations() {
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const [evseInvites, setEvseInvites] = React.useState([]);
  const [displaySuccess, toggleSuccessMessage] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(Date.now());

  const query = useQueryParams();
  const newStationSerialFromURL = query.get('station-serial-number');

  async function fetchEvents() {
    const { data } = await request({
      method: 'GET',
      path: '/1/evse-invites/mine',
    });
    setEvseInvites(data);
  }

  React.useEffect(() => {
    fetchEvents();
  }, []);

  const canInvite =
    provider.enableChargeAtHome &&
    user.accountType === 'organization' &&
    canAccess(user, provider.id, user.accountId, 'users', 'write');

  const canCreate = canAccess(
    user,
    provider.id,
    user.accountId,
    'cpoLocations',
    'write'
  );

  async function onClose() {
    query.set('station-serial-number', '');
    setRefreshKey(Date.now());
  }

  async function getLocations(params) {
    return await request({
      method: 'POST',
      path: '/2/locations/cpo/mine/fast',
      body: {
        ...params,
        locationsForUserId: user.id,
        searchPhrase: params.searchId,
      },
    });
  }

  const filterMapping = useMemo(() => {
    return {
      searchId: {
        type: 'search',
      },
    };
  }, []);

  return (
    <>
      <AccountProvider>
        <ListHeader title={t('myLocations.header', 'My Locations')}>
          <EditFacilitatorAccountPermissions
            accountId={user.accountId}
            trigger={<Button basic content={'Facilitators'} icon={'key'} />}
          />
          {canInvite && (
            <InviteEmployee
              key={`invite-${refreshKey}`}
              trigger={
                <Button
                  primary
                  content={t('myLocation.inviteEmployee', 'Invite Employee')}
                  icon="plus"
                />
              }
              onClose={() => setRefreshKey(Date.now())}
              onSuccess={() => {
                toggleSuccessMessage(true);
                setRefreshKey(Date.now());
              }}
            />
          )}
          {canCreate && (
            <NewChargingStation
              key={`new-${refreshKey}`}
              onClose={onClose}
              newStationSerialFromURL={newStationSerialFromURL}
              trigger={
                <Button
                  primary
                  content={t('myLocations.newStation', 'New Charging Station')}
                  icon="wand-magic-sparkles"
                />
              }
            />
          )}
        </ListHeader>
        <Divider hidden />
        {displaySuccess && <Message>{t('myLocations.successInvited')}</Message>}

        {evseInvites.length > 0 && (
          <Segment placeholder>
            <Header icon>
              <Icon name="envelope" />

              <Trans i18nKey="myLocations.invitesDescription">
                You've been invited to register your charging station. <br />{' '}
                The costs for charging at home will be paid by your employer.
              </Trans>
            </Header>

            <NewChargingStation
              key={`invitation-${refreshKey}`}
              onClose={() => setRefreshKey(Date.now())}
              evseInvite={evseInvites[0]}
              trigger={
                <Button
                  primary
                  floated="right"
                  style={{ marginTop: '-5px' }}
                  content={t('myLocations.invitesButton', 'Start registration')}
                  icon="wand-magic-sparkles"
                />
              }
            />
          </Segment>
        )}
      </AccountProvider>
      <Search.Provider
        filterMapping={filterMapping}
        key={`list-${refreshKey}`}
        sort={sort}
        onDataNeeded={getLocations}>
        {({ items }) => (
          <>
            <SearchFilters.Search
              name="searchId"
              placeholder={t(
                'myLocations.filterPlaceHolder',
                'Name or address'
              )}
            />

            <Search.Status
              noResults={t(
                'myLocations.noChargeLocationsYet',
                'No charge stations activated yet'
              )}
            />

            {items.length > 0 && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>
                      {t('myLocations.locationNameHeader', 'Location name')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      {t('myLocations.statusHeader', 'Status')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      {t('myLocations.addressHeader', 'Address')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}>
                      {t('myLocations.connectorsHeader', 'Connectors')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={3}>
                      {t('myLocations.createdAtHeader', 'Created at')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.map((item) => {
                    return (
                      <Table.Row key={item.id}>
                        <Table.Cell>
                          <Link to={`/charging-stations/locations/${item.id}`}>
                            <strong>
                              {item.name || t('myLocations.noName', 'No Name')}
                            </strong>
                          </Link>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <Label
                            icon="plug"
                            title={t(
                              'myLocations.locationStatus',
                              'Location Status'
                            )}
                            color={colorForStatus(item.status)}
                            content={labelForStatus(item.status)}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {[
                            item.address,
                            item.postal_code,
                            item.city,
                            item.country,
                          ]
                            .filter(Boolean)
                            .join(', ')}
                        </Table.Cell>
                        <Table.Cell textAlign="right">
                          <DoubleDigit>{item.numConnectors}</DoubleDigit>
                        </Table.Cell>
                        <Table.Cell>
                          {formatDateTime(item.createdAt)}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}

            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        )}
      </Search.Provider>
    </>
  );
}
