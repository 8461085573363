import React from 'react';
import { FieldArray } from 'formik';
import { get } from 'lodash-es';
import { Form, List, Icon } from 'semantic';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
}

const TagsField: React.FC<Props> = ({ name, label, required }) => {
  const [currentValue, setCurrentValue] = React.useState('');

  const { t } = useTranslation();

  return (
    <FieldArray
      name={name}
      render={({ form, remove, push }) => {
        const meta = form.getFieldMeta(name);

        return (
          <Form.Field required={required}>
            {meta.touched && meta.error && (
              <span>
                {t('common.error')} {meta.error}
              </span>
            )}
            {label && <label>{label}</label>}

            {get(form.values, name)?.length > 0 && (
              <List
                divided
                role="listbox"
                relaxed
                verticalAlign="middle"
                aria-label={`${name}-list`}
                size="large">
                {get(form.values, name)?.map((text: string, i: number) => (
                  <List.Item
                    key={i}
                    aria-label={text}
                    style={{ position: 'relative' }}>
                    <List.Content floated="right">
                      <Icon
                        name="delete"
                        color="blue"
                        role="button"
                        aria-label={`${name}-delete-${i}`}
                        style={{
                          float: 'right',
                          cursor: 'pointer',
                        }}
                        onClick={() => remove(i)}
                      />
                    </List.Content>
                    <List.Content>{text}</List.Content>
                  </List.Item>
                ))}
              </List>
            )}
            <Form.Input
              type="text"
              aria-label={`${name}-input`}
              role="input"
              action={{
                icon: 'plus',
                primary: true,
                role: 'button',
                'aria-label': `${name}-add`,
                onClick: (e: React.MouseEvent) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (currentValue !== '') {
                    push(currentValue);
                    setCurrentValue('');
                  }
                },
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.keyCode !== 13) {
                  return;
                }
                e.preventDefault();
                if (currentValue !== '') {
                  push(currentValue);
                  setCurrentValue('');
                }
              }}
              value={currentValue}
              onChange={(e, { value }) => setCurrentValue(value)}
            />
          </Form.Field>
        );
      }}
    />
  );
};

export default TagsField;
