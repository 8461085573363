import React, { useMemo } from 'react';
import { Checkbox, Form } from 'semantic';

export const DayOfWeekField = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

export default function DaysOfWeekInput({ value, onChange, disabled }) {
  const valueAsSet = useMemo(() => {
    return new Set(value);
  }, [value]);

  const toggleDay = (day) => {
    const next = new Set(valueAsSet);
    if (next.has(day)) {
      next.delete(day);
    } else {
      next.add(day);
    }
    onChange?.([...next]);
  };

  return (
    <Form.Field disabled={disabled}>
      <Form.Group style={{ marginBottom: '10px' }}>
        <DayOfWeekCheckbox
          value={DayOfWeekField.MONDAY}
          label={'Monday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
        <DayOfWeekCheckbox
          value={DayOfWeekField.TUESDAY}
          label={'Tuesday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
        <DayOfWeekCheckbox
          value={DayOfWeekField.WEDNESDAY}
          label={'Wednesday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
        <DayOfWeekCheckbox
          value={DayOfWeekField.THURSDAY}
          label={'Thursday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
        <DayOfWeekCheckbox
          value={DayOfWeekField.FRIDAY}
          label={'Friday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
      </Form.Group>
      <Form.Group disabled={disabled}>
        <DayOfWeekCheckbox
          value={DayOfWeekField.SATURDAY}
          label={'Saturday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
        <DayOfWeekCheckbox
          value={DayOfWeekField.SUNDAY}
          label={'Sunday'}
          checkedSet={valueAsSet}
          onClick={toggleDay}
        />
      </Form.Group>
    </Form.Field>
  );
}

function DayOfWeekCheckbox({ label, disabled, value, checkedSet, onClick }) {
  return (
    <Form.Button
      basic
      disabled={disabled}
      key={`day-of-week-${value}`}
      style={{ minWidth: '145px' }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(value);
      }}>
      <Checkbox checked={checkedSet.has(value)} label={label} />
    </Form.Button>
  );
}
