import { usePlatformModules } from 'contexts/platformModules';
import { canAccess } from 'utils/roles';
import { useUser } from 'contexts/user';
import { isSuperAdmin } from 'utils/roles';

const REQUIRED_FEATURES = [
  'smart-charging:kia',
  'smart-charging:coneva',
  'smart-charging:pluq',
  'smart-charging:scoptvision',
];
const REQUIRED_PLATFORM_MODULES = 'smart-charging';
const REQUIRED_PERMISSION = 'evseControllersIntegrations';

const useUserHasAccessToEvseControllersIntegrations = (
  permission: 'read' | 'read-write' | 'none'
): boolean => {
  const { user, hasPlatformFeature } = useUser();
  const { hasPlatformModule } = usePlatformModules();

  // super admins have access to everything
  if (isSuperAdmin(user)) {
    return true;
  }

  // needs to have at least one of the required features
  const hasRequiredFeatures = REQUIRED_FEATURES.some((feature) =>
    hasPlatformFeature(feature)
  );

  const canAccessEvseControllersIntegrations =
    permission === 'none' ||
    canAccess(
      user,
      user.providerId,
      user.accountId,
      REQUIRED_PERMISSION,
      permission
    );

  return (
    hasPlatformModule(REQUIRED_PLATFORM_MODULES) &&
    hasRequiredFeatures &&
    canAccessEvseControllersIntegrations
  );
};

export default useUserHasAccessToEvseControllersIntegrations;
