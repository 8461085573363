import React, { useState, PropsWithChildren, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { ToastContext } from './ToastContext';
import { Toast } from './Toast';
import styles from './styles.module.less';

type ToastData = {
  id: string;
  content: ReactNode;
  success?: boolean;
  info?: boolean;
  error?: boolean;
};

export const ToastProvider = (props: PropsWithChildren<unknown>) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);

  const generateID = () => {
    return new Date().toISOString();
  };

  const success = (content: ReactNode) =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateID(), content, success: true },
    ]);

  const info = (content: ReactNode) =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateID(), content, info: true },
    ]);

  const error = (content: ReactNode) =>
    setToasts((currentToasts) => [
      ...currentToasts,
      { id: generateID(), content, error: true },
    ]);

  const close = (id: string) =>
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );

  const contextValue = { success, info, error };

  return (
    <ToastContext.Provider value={contextValue}>
      {props.children}
      {createPortal(
        <div className={styles.toastsWrapper}>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              close={() => close(toast.id)}
              success={toast.success}
              info={toast.info}
              error={toast.error}>
              {toast.content}
            </Toast>
          ))}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};
