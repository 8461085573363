import React from 'react';
import { Modal, Form, Header, Grid, Message, Divider, Button } from 'semantic';

import { request } from 'utils/api';

import { useTranslation } from 'react-i18next';
import { useWizardContext } from '../context';
import { formatEuro } from 'utils/formatting';
import { Trans } from 'react-i18next';
import { useUser } from 'contexts/user';

import CountriesField from 'components/form-fields/Countries';
import PostalCode from 'components/form-fields/PostalCode';

export default ({ onDone, onBack, evseInvite, stepHeader }) => {
  const { billingPlan, location, station, stickerCode, wantSticker } =
    useWizardContext();

  const { user } = useUser();
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [creditBilling, setCreditBilling] = React.useState({
    countryCode: 'nl',
    ...user?.creditBilling,
  });

  // this is a temporary way to handle required fields as we urgently needed a quickfix to ensure all fields are filled in
  // we should soon refactor all of this to use proper forms / validation
  const requiredFields = [
    'countryCode',
    'accountHolderName',
    'ibanNo',
    'address',
    'addressLine2',
    'postalCode',
    'city',
  ];

  async function handleSubmit() {
    for (const field of requiredFields) {
      if (!creditBilling[field] || creditBilling[field] === '') {
        setError(new Error('Please fill in all required fields'));
        return false;
      }
    }

    setError(false);
    setLoading(true);

    const locationObj = {
      ...location,
      address: `${location.address} ${location.addressLine2}`,
    };
    delete locationObj.addressLine2;

    try {
      await request({
        method: 'PATCH',
        path: '/1/users/me',
        body: {
          creditBilling,
        },
      });

      let body = {
        evseControllerId: station.id,
        billingPlanId: billingPlan.id,
        enablePublicCharging: true,
        enablePublicFreeCharging: false,
        location: {
          ...locationObj,
          accessPolicy: 'employeeReimburse',
          publishingMode: 'private',
        },
        evseInviteId: evseInvite.id,
        costSettings: station.connectors.map((c) => {
          return {
            connectorId: c.connectorId,
            pricePerKwh: Number(evseInvite.pricePerKwh),
          };
        }),
      };

      if (stickerCode) {
        body.evseId = stickerCode;
      } else {
        body.assignNewEvseId = 'true';
      }

      const { data } = await request({
        method: 'POST',
        path: '/1/evse-controllers/link',
        body,
      });
      onDone(data);
    } catch (e) {
      setError(e);
      setLoading(false);
      return;
    }

    if (wantSticker) {
      try {
        await request({
          method: 'POST',
          path: `/1/evse-controllers/${station.id}/evse-id-assignment-request`,
          body: {
            source: 'registration',
          },
        });
      } catch (e) {
        setError(e);
      }
    }

    setLoading(false);
  }

  return (
    <>
      <Modal.Header>
        {stepHeader}
        {t('newChargingStationDetails.header', 'Finalize')}
      </Modal.Header>

      <Modal.Content>
        <Form
          id="newChargingStationDetailsInvite"
          error={!!error}
          style={{ minHeight: '350px' }}
          onSubmit={handleSubmit}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Trans i18nKey="newChargingStationDetailsInvite.description">
                  <p>
                    You have been invited by your employer to register your
                    charge station. The costs of the billing plan and charge
                    costs of charging the car at home will be paid by your
                    employer.
                  </p>
                  <p>
                    All charge sessions will be automatically reimbursed and
                    paid by your employer. On every 1st day of the month a self
                    billing invoice will be created, and the amount will be
                    transferred to your bank account.
                  </p>
                  <p>
                    Please enter the bank account number (IBAN) for receiving
                    the reimbursement amount
                  </p>
                </Trans>
                {error && <Message error>{error.message}</Message>}
              </Grid.Column>

              <Grid.Column width={8}>
                <div>
                  <Header as="h3">
                    {t('newChargingStationDetails.energyCost', 'Energy Costs')}
                  </Header>
                  <p>
                    {t(
                      'newChargingStationDetailsInvite.costPricePerKwh',
                      'This cost is set by your company'
                    )}
                  </p>
                  <p>{formatEuro(evseInvite.pricePerKwh)}</p>
                </div>

                <Divider />
                <p>
                  {t(
                    'newChargingStationDetails.creditBillingText',
                    'Please fill in your reimbursement details.'
                  )}
                </p>

                <Form.Input
                  required={requiredFields.includes('accountHolderName')}
                  value={creditBilling.accountHolderName}
                  name="accountHolderName"
                  label={t(
                    'creditBilling.accountHolderName',
                    'Account Holder Name'
                  )}
                  type="text"
                  onChange={(e, { name, value }) =>
                    setCreditBilling({ ...creditBilling, [name]: value })
                  }
                />

                <Form.Input
                  required={requiredFields.includes('ibanNo')}
                  value={creditBilling?.ibanNo || ''}
                  name="ibanNo"
                  label={t('common.iban', 'IBAN Number')}
                  type="text"
                  onChange={(e, { value, name }) =>
                    setCreditBilling({ ...creditBilling, [name]: value })
                  }
                />

                <Form.Group widths="3">
                  <Form.Input
                    required={requiredFields.includes('address')}
                    width="12"
                    value={creditBilling.address || ''}
                    name="address"
                    label={t('formLabel.address', 'Address')}
                    type="text"
                    onChange={(e, { name, value }) =>
                      setCreditBilling({ ...creditBilling, [name]: value })
                    }
                  />

                  <Form.Input
                    required={requiredFields.includes('addressLine2')}
                    value={creditBilling.addressLine2 || ''}
                    name="addressLine2"
                    label={t('formLabel.addressLine2', 'No.')}
                    autoComplete="disabled"
                    type="text"
                    onChange={(e, { name, value }) =>
                      setCreditBilling({ ...creditBilling, [name]: value })
                    }
                  />
                </Form.Group>

                <CountriesField
                  required={requiredFields.includes('countryCode')}
                  name="countryCode"
                  label={t('formLabel.country', 'Country')}
                  value={creditBilling.countryCode || 'nl'}
                  onChange={(value) =>
                    setCreditBilling({ ...creditBilling, countryCode: value })
                  }
                />

                <Form.Group widths="equal">
                  <PostalCode
                    required={requiredFields.includes('postalCode')}
                    countryCode={creditBilling.countryCode || 'NL'}
                    value={creditBilling.postalCode || ''}
                    name="postalCode"
                    label={t('formLabel.postCode', 'Postal Code')}
                    type="text"
                    onChange={(e, { name, value }) =>
                      setCreditBilling({ ...creditBilling, [name]: value })
                    }
                  />

                  <Form.Input
                    required={requiredFields.includes('city')}
                    value={creditBilling.city || ''}
                    name="city"
                    label={t('formLabel.city', 'City/Town')}
                    type="text"
                    onChange={(e, { name, value }) =>
                      setCreditBilling({ ...creditBilling, [name]: value })
                    }
                  />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onBack()}>{t('common.back', 'Back')}</Button>
        <Button
          loading={loading}
          disabled={loading}
          primary
          content={t('common.create', 'Create')}
          form="newChargingStationDetailsInvite"
        />
      </Modal.Actions>
    </>
  );
};
