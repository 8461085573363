import React from 'react';
import { TectonicProvider, TimeRangePicker } from 'react-tectonic';
import { withTranslation } from 'react-i18next';
import AnalyticsToken from 'components/AnalyticsToken';
import CpoTransactions from 'components/analytics-screens/cpo/Transactions';
import { Header, Button, Divider } from 'semantic';
import {
  AggregateTimeSeries,
  SeriesChart,
  ErrorBoundary,
} from 'react-tectonic';

import { defaultColors, sessionsExclusionFilter } from 'utils/visualizations';

import { TECTONIC_URL } from 'utils/env';
import { getTimeRangeFromLocations } from '../../../utils/analytics';

class LocationAnalytics extends React.Component {
  render() {
    const { t, location } = this.props;

    const timeRange = getTimeRangeFromLocations([location]);

    return (
      <AnalyticsToken path={`/2/locations/cpo/${location.id}/analytics-token`}>
        <TectonicProvider
          debug
          baseUrl={TECTONIC_URL}
          primaryColor="#ff3d00"
          collection="sessions"
          dateField="endedAt"
          timeRange={timeRange}>
          <React.Fragment>
            <div style={{ float: 'right' }}>
              <TimeRangePicker
                align="right"
                allowedTimeRange={timeRange}
                renderButton={(label, handleOnClick) => (
                  <Button
                    primary
                    icon="clock"
                    content={label}
                    onClick={handleOnClick}
                  />
                )}
              />
            </div>
            <Header as="h2">{t('analytics.title', 'Analytics')}</Header>
            <div style={{ position: 'relative' }}>
              <ErrorBoundary>
                <AggregateTimeSeries
                  collection="sessions"
                  operation="count"
                  filter={sessionsExclusionFilter}>
                  <SeriesChart
                    title={t('analyticsSessions.sessions', 'Sessions')}
                    titleAlign="center"
                    chartType="bar"
                    height={350}
                    valueField="count"
                    valueFieldLabel={t(
                      'analyticsSessions.sessions',
                      'Sessions'
                    )}
                    color={defaultColors[0]}
                  />
                </AggregateTimeSeries>

                <Divider hidden />
                <Divider hidden />

                <CpoTransactions />
              </ErrorBoundary>
            </div>
          </React.Fragment>
        </TectonicProvider>
      </AnalyticsToken>
    );
  }
}

export default withTranslation()(LocationAnalytics);
