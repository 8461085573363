import { getToken } from './api';
import { parseToken } from './token';

export const SANDBOX_ERROR_TITLE = 'The Sandbox environment is not available';
export const SANDBOX_ERROR_MESSAGE =
  'Please switch to the standard environment. If the problem persists, please contact support.';

// isSandboxMode returns true if the current token is used for the sandbox API
export const isSandboxMode = () => {
  const token = getToken();
  const parsedToken = parseToken(token);
  return !!parsedToken?.sandboxMode;
};

export const inSandboxMode = isSandboxMode();
