import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useInfoAndContactValidation = () => {
  const { t } = useTranslation();

  return Yup.object().shape({
    name: Yup.string().required(
      t(
        'settings.organization.infoAndContact.validations.name',
        'Please enter your organization name'
      )
    ),
    chamberOfCommerceNo: Yup.string()
      .min(4)
      .required(
        t(
          'settings.organization.infoAndContact.validations.chamberOfCommerceNo',
          'Please enter your Chamber of Commerce number'
        )
      ),
    vatNo: Yup.string(),
    hasNoVatNo: Yup.boolean(),
    fieldService: Yup.object().shape({
      email: Yup.string()
        .email(
          t(
            'settings.organization.infoAndContact.validations.fieldServiceEmailValid',
            'Please enter your email for EVSE issues'
          )
        )
        .required(
          t(
            'settings.organization.infoAndContact.validations.fieldServiceEmailRequired',
            'Email is required'
          )
        ),
    }),
    defaultLangCode: Yup.string().required(
      t(
        'settings.organization.infoAndContact.validations.defaultLangCode',
        'Please select a default language'
      )
    ),
    contact: Yup.object().shape({
      firstName: Yup.string().required(
        t(
          'settings.organization.infoAndContact.validations.firstName',
          'Please enter your first name'
        )
      ),
      lastName: Yup.string().required(
        t(
          'settings.organization.infoAndContact.validations.lastName',
          'Please enter your last name'
        )
      ),
      phoneCountryCode: Yup.string().required(
        t(
          'settings.organization.infoAndContact.validations.countryCode',
          'Please enter your country code'
        )
      ),
      phoneNo: Yup.string().required(
        t(
          'settings.organization.infoAndContact.validations.phoneNumber',
          'Please enter your phone number'
        )
      ),
      email: Yup.string()
        .email(
          t(
            'settings.organization.infoAndContact.validations.emailValid',
            'Please provide a valid email'
          )
        )
        .required(
          t(
            'settings.organization.infoAndContact.validations.emailRequired',
            'Email is required'
          )
        ),
    }),
  });
};
