import React from 'react';
import { Link } from 'react-router-dom';

export const accountReferenceFormatter = (item) => {
  const { reference } = item;
  if (!reference) {
    return item.key;
  }
  return <Link to={`/accounts/${reference.id}`}>{reference.name}</Link>;
};

export const getTimeRangeFromLocations = (locations) => {
  if (!locations || !locations.length) {
    return null;
  }

  let from = new Date();
  locations.forEach((location) => {
    if (Date.parse(location.createdAt) < from) {
      from = new Date(Date.parse(location.createdAt) - 24 * 3600 * 1000);
    }
  });

  return {
    from,
    to: new Date(),
  };
};
