import React from 'react';
import { Loader, Message } from 'semantic';
import { Aggregate, DonutChart } from 'react-tectonic';
import { startCase } from 'lodash-es';
import { defaultColors } from 'utils/visualizations';

export default function MultiCardinalityDonutChart({
  collection,
  items,
  title,
  labelFormatter,
  colors,
  timeRangeDateField = 'createdAt',
}) {
  return (
    <Aggregate
      collection={collection}
      type="cardinality"
      timeRangeDateField={timeRangeDateField}
      requests={items.map((item) => item.request)}>
      {({ data, status, error }) => {
        if (status.loading) return <Loader active />;
        if (error) return <Message error content={error.message} />;
        if (!data.length) return <Message content="No data" />;
        const pieData = items.map((item, i) => {
          return {
            key: item.label || '',
            count: data[i][item.request.fields[0]],
          };
        });
        return (
          <DonutChart
            data={pieData}
            colors={colors || defaultColors}
            limit={8}
            percent
            title={title}
            titleAlign="center"
            labelFormatter={
              labelFormatter || ((key) => startCase((key || '').toLowerCase()))
            }
          />
        );
      }}
    </Aggregate>
  );
}
