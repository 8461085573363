import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { set, size } from 'lodash-es';
import { LabelDetail, Message, Segment } from 'semantic';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Redirect, useHistory, Link } from 'react-router-dom';
import {
  Container,
  Form,
  Table,
  Header,
  Divider,
  HeaderSubheader,
  Label,
  Button,
  Icon,
  ActionButton,
} from 'semantic';

import { Breadcrumbs, ErrorMessage, Layout } from 'components';
import { request } from 'utils/api';
import SelectionReviewModal from 'components/BulkActionsIsland/SelectionReviewModal';
import {
  CHARGING_STATIONS_FE_PATH,
  EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH,
  EVSE_BACKGROUND_JOBS_BE_CHANGE_CONFIGURATION_PATH,
} from '../utils';
import { useToast, ToastButtonLayout } from 'components/Toast';
import { getEvseProtocols } from 'utils/evse-controllers';
import useFetch from 'hooks/useFetch';
import {
  configurationKeysFromProtocolResponses,
  ProtocolResponse16,
  ProtocolResponse201,
  StandardConfigurationKeys,
} from './protocol';
import ConfigurationInput, {
  Value,
  ConfigurationInputType,
} from './ConfigurationInput';
import { shortCircuitEvent } from 'utils/events';
import { EvseController, OCPPProtocolVersion } from 'types/evse-controller';

const selectionReviewEqual = (a: any, b: any) => a.id === b.id;

const isConfigValid = (
  config: BulkChangeConfigurationItem,
  ocppProtocols: string[] = []
) => {
  const onlyOcpp201 = ocppProtocols.every(
    (p) => p === OCPPProtocolVersion.OCPP201
  );
  if (onlyOcpp201) {
    return config?.name && config?.component?.name && config?.value;
  }
  return config?.name && config?.value;
};

const buildChangeConfigurationItem = (
  id: string,
  input: ConfigurationInputType = ConfigurationInputType.STANDARD
): BulkChangeConfigurationItem => ({
  id,
  name: '',
  value: '',
  ocppProtocolVersion: '',
  label: '',
  input,
  edit: true,
});

function mapConfigurationValue(
  id: string,
  value: Value,
  standardConfigurationKeys: StandardConfigurationKeys
): BulkChangeConfigurationItem {
  const standardConfiguration = value.standardKeyId
    ? standardConfigurationKeys[value.standardKeyId]
    : null;

  return {
    id,
    label: standardConfiguration?.label || value.name,
    name: value.name,
    value: value.value,
    ...(value.component && {
      component: {
        name: value.component,
      },
    }),
    ocppProtocolVersion: value.ocppProtocol || '',
    input: value.input,
    standardKeyId: value.standardKeyId,
    edit: true,
  };
}

const selectionReviewColumns = [
  {
    title: <Table.HeaderCell>OCPP ID</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppIdentity}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>Account</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.account?.name || '-'}</Table.Cell>
    ),
  },
  {
    title: <Table.HeaderCell>OCPP Version</Table.HeaderCell>,
    data: (item: EvseController) => (
      <Table.Cell>{item.ocppProtocolVersion || '-'}</Table.Cell>
    ),
  },
];

type LocationState = {
  evseControllers?: any[];
};

type ViewState = {
  loading: boolean;
  apiError: Error | null;
  formError: Error | null;
};

type BulkChangeConfigurationForm = {
  chargingStationIds: string[];
  configurationItems: BulkChangeConfigurationItem[];
  note: string;
};

type BulkChangeConfigurationItem = {
  id: string;
  name: string;
  value: string;
  component?: {
    name: string;
  };
  ocppProtocolVersion: string;
  label: string;
  input: ConfigurationInputType;
  standardKeyId?: string;
  edit: boolean;
};

export default function EvseControllersBackgroundJobsChangeConfiguration() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [viewState, setViewState] = useState<ViewState>({
    loading: false,
    apiError: null,
    formError: null,
  });

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const { evseControllers } = (location.state || {}) as LocationState;

  const [selectedItems, setSelectedItems] = useState(evseControllers || []);

  const { data: protocol16 } = useFetch<ProtocolResponse16>({
    path: '1/ocpp/1.6/protocol',
  });

  const { data: protocol201 } = useFetch<ProtocolResponse201>({
    path: '1/ocpp/2.0.1/protocol',
  });

  const [ocppProtocols, setOcppProtocols] = useState<string[]>(
    getEvseProtocols(selectedItems)
  );

  const [idCounter, setIdCounter] = useState(1);

  const standardConfigurationKeys = useMemo<StandardConfigurationKeys>(() => {
    return configurationKeysFromProtocolResponses(
      ocppProtocols,
      protocol16,
      protocol201
    );
  }, [ocppProtocols, protocol16, protocol201]);

  const [bulkChangeConfiguration, setBulkChangeConfiguration] =
    useState<BulkChangeConfigurationForm>({
      note: '',
      configurationItems: [buildChangeConfigurationItem(`${idCounter}`)],
      chargingStationIds: selectedItems.map(
        (item) => item.ocppChargingStationId
      ),
    });

  const setField = (name: string, value: any, nullable = false) => {
    const parsedValue = value === '' && nullable ? null : value;
    setBulkChangeConfiguration(
      set({ ...bulkChangeConfiguration }, name, parsedValue)
    );
  };

  const updatedSelectedItems = (items: EvseController[]) => {
    setSelectedItems(items);
    setField(
      'chargingStationIds',
      items.map((item) => item.ocppChargingStationId)
    );
    setOcppProtocols(getEvseProtocols(items));
  };

  const configurationItemLabel = (item: BulkChangeConfigurationItem) =>
    item.input === ConfigurationInputType.STANDARD ? (
      <Label color="blue">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.addedStandardVariableKeyLabel',
          'Standard Variable'
        )}
        <LabelDetail
          content={`OCPP ${item.ocppProtocolVersion === 'ocpp1.6' ? '1.6' : '2.0.1'}`}
        />
      </Label>
    ) : (
      <Label color="black">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.addedCustomVariableKeyLabel',
          'Custom Variable'
        )}
      </Label>
    );

  const submitForm = useCallback(async () => {
    setViewState({ loading: true, apiError: null, formError: null });

    if (!bulkChangeConfiguration.chargingStationIds.length) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('At least one charging station is required'),
      });
      return;
    }
    if (!bulkChangeConfiguration.configurationItems.length) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('At least one configuration item is required'),
      });
      return;
    }

    const allConfigurationFieldsValid =
      bulkChangeConfiguration.configurationItems.every((cfgItem) =>
        isConfigValid(cfgItem, ocppProtocols)
      );
    if (!allConfigurationFieldsValid) {
      setViewState({
        loading: false,
        apiError: null,
        formError: new Error('All configuration fields must be filled'),
      });
      return;
    }

    try {
      const { data } = await request({
        method: 'POST',
        path: EVSE_BACKGROUND_JOBS_BE_CHANGE_CONFIGURATION_PATH,
        body: {
          ...bulkChangeConfiguration,
          // Remove non-API fields
          configurationItems: bulkChangeConfiguration.configurationItems.map(
            (item) => ({
              ...item,
              id: undefined,
              label: undefined,
              input: undefined,
              standardKeyId: undefined,
              edit: undefined,
            })
          ),
        },
      });

      toast.info(
        <ToastButtonLayout
          buttonTo={`${EVSE_CONTROLLERS_BACKGROUND_JOBS_FE_PATH}/${data.id}`}
          buttonTitle={t(
            'evseControllersBackgroundJobs.successToastView',
            'View'
          )}>
          <Trans
            i18nKey="evseControllersBackgroundJobsChangeConfiguration.toastSuccess"
            defaults="Job {{jobId}} <strong>Change Configuration</strong> is in progress. Go to Background Jobs page for details."
            values={{ jobId: data.id }}
          />
        </ToastButtonLayout>
      );
      setViewState({ loading: false, apiError: null, formError: null });
      history.push(CHARGING_STATIONS_FE_PATH);
    } catch (e: any) {
      setViewState({ loading: false, apiError: e, formError: null });
    }
  }, [bulkChangeConfiguration]);

  const canDelete = size(bulkChangeConfiguration.configurationItems) > 1;

  return selectedItems.length === 0 ? (
    <Redirect to={CHARGING_STATIONS_FE_PATH} />
  ) : (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="backgroundjobs" to={CHARGING_STATIONS_FE_PATH}>
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.breadcrumbsChargingStations',
              'Charging Stations'
            )}
          </Link>,
        ]}
        active={t(
          'evseControllersBackgroundJobsChangeConfiguration.title',
          'Change OCPP Configuration'
        )}
      />
      <Header as="h2">
        {t(
          'evseControllersBackgroundJobsChangeConfiguration.title',
          'Change OCPP Configuration'
        )}
      </Header>
      <Divider hidden />

      <Form error={Boolean(viewState.formError)} onSubmit={submitForm}>
        <div>
          <SelectionReviewModal
            isOpen={isReviewModalOpen}
            onSetIsOpen={setIsReviewModalOpen}
            selectedItems={selectedItems}
            onSetSelectedItems={updatedSelectedItems}
            selectionReviewColumns={selectionReviewColumns}
            equal={selectionReviewEqual}
          />
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.reviewChargingStationTitle',
              '1. Review your selected charging station'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.reviewChargingStationSubTitle',
                'Once saved, the changes cannot be cancelled or undone.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Label
              size="large"
              content={`${selectedItems?.length} ${t(
                'evseControllersBackgroundJobsChangeConfiguration.chargingStations',
                'charging stations'
              )}`}
            />
            <Button
              basic
              content={t(
                'evseControllersBackgroundJobsChangeConfiguration.showSelectedChargingStations',
                'Show Selected'
              )}
              onClick={() => setIsReviewModalOpen(true)}
              type="button"
              icon="list"
            />
          </Segment>

          {ocppProtocols.length > 1 && (
            <Message>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.multiProtocolWarning',
                'Note: Different OCPP versions for charging stations detected. Only the variables (keys) for the relevant OCPP version will be updated.'
              )}
            </Message>
          )}
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.defineConfigurationTitle',
              '2. Define OCPP Configuration'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.defineConfigurationSubTitle',
                'Choose an OCPP Configuration variable you want to change, or create a new one. You can add and create multiple variables (keys).'
              )}
            </HeaderSubheader>
          </Header>

          <Table padded basic>
            <Table.Body>
              {bulkChangeConfiguration.configurationItems?.map((item) => {
                const idx =
                  bulkChangeConfiguration.configurationItems.findIndex(
                    (i) => i.id === item.id
                  );
                const value: Value = {
                  name: item.name,
                  value: item.value,
                  component: item.component?.name,
                  ocppProtocol: item.ocppProtocolVersion,
                  input: item.input,
                  standardKeyId: item.standardKeyId,
                };

                return (
                  <Table.Row key={item.id}>
                    {item.edit && (
                      <Table.Cell colspan="4">
                        <div style={{ display: 'flex' }}>
                          <div style={{ flexGrow: 1 }}>
                            <ConfigurationInput
                              withBox={false}
                              standardConfigurationKeys={
                                standardConfigurationKeys
                              }
                              ocppProtocols={ocppProtocols}
                              value={value}
                              onChange={(v: Value) => {
                                const configurationItems = [
                                  ...bulkChangeConfiguration.configurationItems,
                                ];
                                configurationItems[idx] = mapConfigurationValue(
                                  item.id,
                                  v,
                                  standardConfigurationKeys
                                );

                                setField(
                                  'configurationItems',
                                  configurationItems
                                );
                              }}
                            />
                          </div>
                          <div>
                            <ActionButton
                              style={{ paddingLeft: 0 }}
                              disabled={!isConfigValid(item, ocppProtocols)}
                              primary
                              compact
                              onClick={(
                                e: React.SyntheticEvent<HTMLElement>
                              ) => {
                                shortCircuitEvent(e);
                                const configurationItems = [
                                  ...bulkChangeConfiguration.configurationItems,
                                ];
                                configurationItems[idx] = {
                                  ...configurationItems[idx],
                                  edit: false,
                                };
                                setField(
                                  'configurationItems',
                                  configurationItems
                                );
                              }}>
                              <>
                                <Icon name="compress" />
                                {t(
                                  'evseControllersBackgroundJobsChangeConfiguration.minimizeConfiguration',
                                  'Minimize'
                                )}
                              </>
                            </ActionButton>
                            <Button
                              basic
                              icon="trash"
                              title="Delete"
                              disabled={!canDelete}
                              onClick={(
                                e: React.SyntheticEvent<HTMLElement>
                              ) => {
                                shortCircuitEvent(e);

                                const newConfigurationItems =
                                  bulkChangeConfiguration.configurationItems.filter(
                                    (c) => c.id !== item.id
                                  );

                                setField(
                                  'configurationItems',
                                  newConfigurationItems
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Table.Cell>
                    )}
                    {!item.edit && (
                      <>
                        <Table.Cell collapsing>
                          {configurationItemLabel(item)}
                        </Table.Cell>
                        <Table.Cell collapsing>
                          {t(
                            'evseControllersBackgroundJobsChangeConfiguration.configurationItemKey',
                            'Key'
                          ) + ': '}
                          <strong>{item.label}</strong>
                        </Table.Cell>
                        <Table.Cell>
                          {t(
                            'evseControllersBackgroundJobsChangeConfiguration.configurationItemValue',
                            'Value'
                          ) + ': '}
                          <strong>{item.value}</strong>
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <Button
                            basic
                            icon="pen-to-square"
                            title="Edit"
                            onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                              shortCircuitEvent(e);
                              const configurationItems = [
                                ...bulkChangeConfiguration.configurationItems,
                              ];
                              configurationItems[idx] = {
                                ...configurationItems[idx],
                                edit: true,
                              };
                              setField(
                                'configurationItems',
                                configurationItems
                              );
                            }}
                          />
                          <Button
                            basic
                            icon="trash"
                            disabled={!canDelete}
                            title="Delete"
                            onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                              shortCircuitEvent(e);
                              const configurationItems = [
                                ...bulkChangeConfiguration.configurationItems,
                              ];
                              configurationItems.splice(idx, 1);
                              setField(
                                'configurationItems',
                                configurationItems
                              );
                            }}
                          />
                        </Table.Cell>
                      </>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>

          <AddConfigurationActionButton
            icon="plus"
            text={t(
              'evseControllersBackgroundJobsChangeConfiguration.addConfigurationItem',
              'Add configuration item'
            )}
            onClick={() => {
              const newCounter = idCounter + 1;
              const configurationItems =
                bulkChangeConfiguration.configurationItems.map((item) => {
                  if (isConfigValid(item, ocppProtocols)) {
                    return { ...item, edit: false };
                  }
                  return item;
                });

              setIdCounter(newCounter);
              setField('configurationItems', [
                ...configurationItems,
                buildChangeConfigurationItem(`${newCounter}`),
              ]);
            }}
          />
        </div>

        <Divider hidden />

        <div>
          <Header as="h4">
            {t(
              'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteTitle',
              '3. Add custom note'
            )}
            <HeaderSubheader style={{ marginTop: '0.8em' }}>
              {t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteSubTitle',
                'Write a note to show together with the details of this command at the Background Jobs page.'
              )}
            </HeaderSubheader>
          </Header>
          <Segment>
            <Form.Input
              name="note"
              placeholder={t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNotePlaceholder',
                'Type here...'
              )}
              label={t(
                'evseControllersBackgroundJobsChangeConfiguration.addCustomNoteInputLabel',
                'Note'
              )}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Segment>
        </div>

        <Divider />

        <ErrorMessage error={viewState.apiError} />
        <ErrorMessage error={viewState.formError} />

        <Button
          loading={viewState.loading}
          disabled={viewState.loading}
          content={t(
            'evseControllersBackgroundJobsChangeConfiguration.executeChangeConfiguration',
            'Change Configuration'
          )}
          type="submit"
        />

        <Link to={viewState.loading ? '#' : '/charging-stations'}>
          <Button
            disabled={viewState.loading}
            content={t(
              'evseControllersBackgroundJobsChangeConfiguration.cancelUpdate',
              'Cancel'
            )}
            basic
            type="button"
          />
        </Link>
      </Form>
    </Container>
  );
}

function AddConfigurationActionButton({
  text,
  icon,
  onClick,
  disabled = false,
}: {
  text: string;
  icon: string;
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <ActionButton
      style={{ paddingLeft: 0 }}
      disabled={disabled}
      primary
      compact
      onClick={(e: React.SyntheticEvent<HTMLElement>) => {
        shortCircuitEvent(e);
        onClick?.();
      }}>
      <>
        <Icon name={icon} />
        {text}
      </>
    </ActionButton>
  );
}
