import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic';

import SearchContext from '../Context';

/**
 * @deprecated Use InputV2, it is a modern functional component, and use hooks.
 */
export default class Input extends React.Component {
  static contextType = SearchContext;

  onChange = (evt, { name, value }) => {
    this.context.onFilterChange({
      name,
      value,
    });
  };

  render() {
    const { name, ...rest } = this.props;
    const value = this.context.filters[name];

    return (
      <Form.Input
        name={name}
        icon={this.renderIcon()}
        value={value || ''}
        onChange={this.onChange}
        {...rest}
      />
    );
  }

  renderIcon() {
    const { name } = this.props;
    const value = this.context.filters[name];

    return {
      name: value ? 'close' : '',
      link: true,
      onClick: (evt) => {
        if (value) {
          this.context.onFilterChange({ name, value: '' });
        }
        evt.target.closest('.input').querySelector('input').focus();
      },
    };
  }
}

Input.propTypes = {
  ...Form.Input.propTypes,
  name: PropTypes.string.isRequired,
};
