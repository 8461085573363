import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Button } from 'semantic';
import { Search } from 'components';
import { formatDateTime } from 'utils/date';
import InspectObject from 'components/modals/InspectObject';

import { formatFinanceExportsJobStatus } from 'utils/formatting';
import { fromNow } from 'utils/date';

class FinanceExportsTable extends React.Component {
  static contextType = Search.Context;

  getDownloadExpirationText(now, downloadExpiration) {
    if (!downloadExpiration) {
      return this.props.t(
        'financeExports.noDownloadExpirationDate',
        'No download available (yet)'
      );
    }

    if (downloadExpiration > now) {
      return fromNow(downloadExpiration);
    } else {
      return this.props.t('financeExport.downloadExpired', 'Download expired');
    }
  }

  getDownloadButtonDisabled(now, downloadExpiration, downloadUrl) {
    const isExpired = now > downloadExpiration;
    return isExpired || !downloadUrl;
  }

  render() {
    const { t } = this.props;
    const { items } = this.context;
    const now = new Date();

    return (
      <>
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>
                {t('financeExports.collectionName', 'Collection')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('financeExports.createdAt', 'Job created at')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('financeExports.dateFrom', 'Date from')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('financeExports.dateTo', 'Date To')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('financeExports.status', 'Status')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('financeExports.actions', 'Actions')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t(
                  'financeExports.downloadExpirationDate',
                  'Download expires in'
                )}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('financeExports.createdByUser', 'Created by user')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              const {
                collectionName,
                downloadExpirationDate,
                dateTo,
                dateFrom,
                downloadUrl,
                createdBy,
              } = item;

              const downloadExpiration =
                downloadExpirationDate && new Date(downloadExpirationDate);

              return (
                <Table.Row key={item._id}>
                  <Table.Cell>
                    {collectionName ||
                      t('financeExports.noCollectionName', 'No Name')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(item.createdAt) ||
                      t('financeExports.noCreationDate', 'No creation date')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(dateFrom) ||
                      t('financeExports.noDateFrom', 'No "date from"')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateTime(dateTo) ||
                      t('financeExports.noDateTo', 'No "date to"')}
                  </Table.Cell>
                  <Table.Cell>
                    {formatFinanceExportsJobStatus(item.status) ||
                      t('financeExports.noStatus', 'No status')}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Button
                      basic
                      icon="download"
                      title="Download CSV"
                      href={downloadUrl}
                      disabled={this.getDownloadButtonDisabled(
                        now,
                        downloadExpiration,
                        downloadUrl
                      )}
                    />
                    <InspectObject
                      name="Account"
                      data={item}
                      trigger={<Button basic icon="code" title="Inspect" />}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {this.getDownloadExpirationText(now, downloadExpiration)}
                  </Table.Cell>
                  <Table.Cell>{createdBy?.name}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(FinanceExportsTable);
