import React from 'react';
import useUpdateDetector from 'hooks/useUpdateDetector';
import { environmentBannerHeight } from 'components/environmentBanner';
import { ActionButton } from 'semantic';
import { useTranslation } from 'react-i18next';

export default function AppUpdateBanner() {
  const { t } = useTranslation();
  const updateAvailable = useUpdateDetector();

  const hardRefresh = () => {
    location.reload();
  };

  return (
    <>
      {updateAvailable && (
        <div
          style={{
            backgroundColor: '#00a5ff',
            color: '#fff',
            padding: '24px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: environmentBannerHeight,
            width: '100%',
            zIndex: '99999999',
          }}>
          <span>
            <ActionButton
              style={{
                fontSize: '18px',
              }}
              onClick={hardRefresh}>
              {t(
                'appUpdate.message',
                'A dashboard update is available. Click here to update.'
              )}
            </ActionButton>
          </span>
        </div>
      )}
    </>
  );
}
