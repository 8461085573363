import {
  CPO_SESSION_EXCLUDED_REASON,
  MSP_SESSION_EXCLUDED_REASON,
} from 'types/sessions';

export const SESSION_EXCLUDED_REASON_OPTIONS = [
  {
    key: CPO_SESSION_EXCLUDED_REASON.HISTORICAL_END,
    value: CPO_SESSION_EXCLUDED_REASON.HISTORICAL_END,
    text: 'Session was back-dated',
  },
  {
    key: MSP_SESSION_EXCLUDED_REASON.TOO_OLD,
    value: MSP_SESSION_EXCLUDED_REASON.TOO_OLD,
    text: 'Session was too old',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.MAINTENANCE_TOKEN,
    value: CPO_SESSION_EXCLUDED_REASON.MAINTENANCE_TOKEN,
    text: 'Session started with maintenance token',
  },
  {
    key: MSP_SESSION_EXCLUDED_REASON.NON_EXISTENT_TOKEN,
    value: MSP_SESSION_EXCLUDED_REASON.NON_EXISTENT_TOKEN,
    text: 'Session started with a non-existent token',
  },
  {
    key: MSP_SESSION_EXCLUDED_REASON.NON_ACTIVE_TOKEN,
    value: MSP_SESSION_EXCLUDED_REASON.NON_ACTIVE_TOKEN,
    text: 'Session started with a non-active token',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.HIGH_ENERGY_USAGE,
    value: CPO_SESSION_EXCLUDED_REASON.HIGH_ENERGY_USAGE,
    text: 'Session had a higher energy usage than normal',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.LOW_ENERGY_USAGE,
    value: CPO_SESSION_EXCLUDED_REASON.LOW_ENERGY_USAGE,
    text: 'Session had a lower energy usage than normal',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.LOW_DURATION,
    value: CPO_SESSION_EXCLUDED_REASON.LOW_DURATION,
    text: 'Session had a lower duration session than normal',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.IMPROBABLE_ENERGY_USAGE,
    value: CPO_SESSION_EXCLUDED_REASON.IMPROBABLE_ENERGY_USAGE,
    text: 'Session had a improbable energy usage',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.NO_COST,
    value: CPO_SESSION_EXCLUDED_REASON.NO_COST,
    text: 'Session had no cost',
  },
  {
    key: MSP_SESSION_EXCLUDED_REASON.HIGH_COST,
    value: MSP_SESSION_EXCLUDED_REASON.HIGH_COST,
    text: 'Session had higher cost than normal',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.EXTERNAL_PAYMENT_SOLUTION,
    value: CPO_SESSION_EXCLUDED_REASON.EXTERNAL_PAYMENT_SOLUTION,
    text: 'Session used an external payment solution',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.NON_ACCEPTED_AUTH,
    value: CPO_SESSION_EXCLUDED_REASON.NON_ACCEPTED_AUTH,
    text: 'Session was started with non authorized token',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.MANUAL_EXCLUSION,
    value: CPO_SESSION_EXCLUDED_REASON.MANUAL_EXCLUSION,
    text: 'Session was manually excluded',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.MANUAL_CANCELLATION,
    value: CPO_SESSION_EXCLUDED_REASON.MANUAL_CANCELLATION,
    text: 'Session was manually cancelled',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.INVALID_START_TIME,
    value: CPO_SESSION_EXCLUDED_REASON.INVALID_START_TIME,
    text: 'Session had an invalid start time',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.INVALID_END_TIME,
    value: CPO_SESSION_EXCLUDED_REASON.INVALID_END_TIME,
    text: 'Session had an invalid end time',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.UNKNOWN_METER_VALUES,
    value: CPO_SESSION_EXCLUDED_REASON.UNKNOWN_METER_VALUES,
    text: 'Session meter values are unknown',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.TEMPORARILY_EXCLUDED_WAITING_FOR_MISSING_DATA,
    value:
      CPO_SESSION_EXCLUDED_REASON.TEMPORARILY_EXCLUDED_WAITING_FOR_MISSING_DATA,
    text: 'Session is temporarily excluded waiting for missing data',
  },
  {
    key: MSP_SESSION_EXCLUDED_REASON.PSP_PAYMENT,
    value: MSP_SESSION_EXCLUDED_REASON.PSP_PAYMENT,
    text: 'Session was paid with a Payment Service Provider',
  },
  {
    key: MSP_SESSION_EXCLUDED_REASON.NO_SESSION,
    value: MSP_SESSION_EXCLUDED_REASON.NO_SESSION,
    text: 'Session had no cost and no energy usage',
  },
  {
    key: CPO_SESSION_EXCLUDED_REASON.NO_AUTO_REIMBURSEMENT,
    value: CPO_SESSION_EXCLUDED_REASON.NO_AUTO_REIMBURSEMENT,
    text: 'Session had no auto reimbursed',
  },
];
