import React from 'react';
import { request } from '../../../../../utils/api';
import { Button } from 'semantic';
import { useUser } from 'contexts/user';
import { useAccount } from 'contexts/account';
import { TFunction } from 'react-i18next';
import PersonalBillingInfoBox from './PersonalBillingInfoBox';
import PersonalBillingPreview from './PersonalBillingPreview';

export default function RemovePersonalBilling({
  setErrorMessage,
  setSuccess,
  t,
}: {
  setErrorMessage: (message: string) => void;
  setSuccess: (success: boolean) => void;
  t: TFunction<'translation', undefined>;
}) {
  // @ts-ignore until the user context is typed
  const { user, fetchUser } = useUser();
  const { isBillingUpdateRequired } = useAccount();

  const handleDelete = async () => {
    setErrorMessage('');
    try {
      await request({
        method: 'PATCH',
        path: '/1/users/me',
        body: {
          billing: {
            removeBilling: true,
          },
        },
      });

      fetchUser();
      setSuccess(true);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const sideBarMenuName =
    user.accountType === 'organization'
      ? t('settings.sidebar.organization.title', 'Organization')
      : t('settings.sidebar.individual.title', 'Account');

  return (
    <>
      <PersonalBillingInfoBox
        isBillingUpdateRequired={!!isBillingUpdateRequired}
        sideBarMenuName={sideBarMenuName}
        t={t}
      />

      <PersonalBillingPreview />

      <Button
        style={{ marginLeft: 0 }}
        content={t(
          'settings.personal.billing.deleteBilling',
          'Delete Your Personal Billing Information'
        )}
        onClick={handleDelete}
        disabled={isBillingUpdateRequired}
      />
    </>
  );
}
