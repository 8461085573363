import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfMonth, endOfMonth } from 'date-fns';

import { Modal, Form, Label, Icon, Grid, Checkbox, Button } from 'semantic';

function getDefaultState(/*props*/) {
  return {
    open: false,
  };
}

export default class Filters extends React.Component {
  state = {
    ...getDefaultState(this.props),
    appliedFilters: [],
    useAllTime: true,
    open: false,
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  };

  open = () => this.setState({ open: true });
  close = () => {
    this.setState(getDefaultState(this.props));
  };

  computeFilters() {
    const { from, to, useAllTime } = this.state;

    const filters = [];
    if (!useAllTime) {
      filters.push({
        field: 'dateRange',
        value: {
          from,
          to,
        },
        apiFilter: {
          from,
          to,
        },
        label: `Date Range ${from.toLocaleDateString()}-${to.toLocaleDateString()}`,
      });
    }
    return filters;
  }

  handleApply = () => {
    const appliedFilters = this.computeFilters();
    this.props.onChange(appliedFilters);
    this.setState({
      appliedFilters: appliedFilters,
    });
    this.close();
  };

  handleReset = () => {
    this.props.onChange([]);
    this.setState({
      appliedFilters: [],
    });
    this.close();
  };

  handleRemoveFilter = (filter) => {
    this.setState(
      {
        appliedFilters: this.state.appliedFilters.filter(
          (c) => c.field !== filter.field
        ),
      },
      () => {
        this.props.onChange(this.state.appliedFilters);
      }
    );
    this.close();
  };

  render() {
    const { from, to } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        <Modal
          size="tiny"
          open={this.state.open}
          onOpen={this.open}
          onClose={this.close}
          trigger={
            <Button basic primary={true} floated="left">
              <Icon name="filter" /> Filters
            </Button>
          }
          closeIcon>
          <Modal.Header>Filters</Modal.Header>
          <Modal.Content>
            <Form>
              <Grid divided>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form.Field>
                      <Checkbox
                        onChange={(e, { checked }) => {
                          this.setState({
                            useAllTime: checked,
                          });
                        }}
                        toggle
                        checked={this.state.useAllTime}
                        label="All Time"
                      />
                    </Form.Field>
                    <div
                      onClick={() =>
                        this.setState({
                          useAllTime: false,
                        })
                      }>
                      <Form.Field disabled={this.state.useAllTime}>
                        <label>From</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={from}
                          onChange={(date) => this.setState({ from: date })}
                          selectsStart
                          startDate={from}
                          endDate={to}
                        />
                      </Form.Field>
                      <Form.Field disabled={this.state.useAllTime}>
                        <label>To</label>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          selected={to || new Date()}
                          onChange={(date) => this.setState({ to: date })}
                          selectsEnd
                          startDate={from}
                          endDate={to}
                          minDate={from}
                        />
                      </Form.Field>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8}></Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button basic content="Reset" onClick={this.handleReset} />
            <Button
              primary
              icon="check"
              content="Apply"
              onClick={this.handleApply}
            />
          </Modal.Actions>
        </Modal>

        <div style={{ marginLeft: '10px' }}>
          {this.state.appliedFilters.map((filter) => (
            <Label
              key={filter.field}
              size="large"
              style={{ marginLeft: '10px', lineHeight: '20px' }}>
              {filter.label}
              <Icon
                name="delete"
                onClick={() => this.handleRemoveFilter(filter)}
              />
            </Label>
          ))}
        </div>
      </div>
    );
  }
}
