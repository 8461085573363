import React from 'react';

import { Dropdown } from 'semantic';

import InspectObject from 'components/modals/InspectObject';
import EditInvoice from 'components/modals/EditInvoice';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Confirm } from 'components';
import { request } from 'utils/api';
import { currentUserIsSuperAdmin } from '../../utils/roles';
import {
  removeResourceProviderIdInSessionStorage,
  setResourceProviderIdInSessionStorage,
} from 'utils/providers';
import RegenerateInvoice from 'components/modals/invoices/RegenerateInvoice';

export default function InvoiceActions({
  item,
  onReload,
  writeAccess,
  prefixPath = '/finance',
} = {}) {
  const { t } = useTranslation();

  const fetchInvoice = async (id) => {
    const { data } = await request({
      method: 'GET',
      path: `/1/invoices/${id}`,
    });

    return data;
  };

  const hasBeenProcessed = item.isReady || item.hasError;

  return (
    <>
      <RegenerateInvoice
        trigger={
          <Dropdown.Item
            icon="arrow-rotate-right"
            text="Regenerate"
            disabled={!writeAccess || !hasBeenProcessed}
          />
        }
        invoiceId={item.id}
        handleClose={onReload}
      />
      <Dropdown.Item
        onClick={() => {
          request({
            path: `/1/invoices/${item.id}/preview`,
            method: 'POST',
          });
        }}
        icon="at"
        text="Preview"
        disabled={!writeAccess}
      />
      <EditInvoice
        invoice={item}
        onClose={removeResourceProviderIdInSessionStorage}
        trigger={
          <Dropdown.Item
            icon="pen-to-square"
            text="Edit"
            disabled={!writeAccess}
            onClick={() =>
              setResourceProviderIdInSessionStorage(item?.providerId)
            }
          />
        }
        onSave={() => {
          return onReload();
        }}
      />
      <Dropdown.Item
        onClick={() => {
          request({
            path: `/1/invoices/${item.id}/sessions/download`,
            method: 'GET',
            params: {
              filename: `${item.identifier}-sessions.csv`,
            },
          });
        }}
        icon="download"
        text="Download Sessions"
        disabled={!writeAccess}
      />
      <Dropdown.Item
        onClick={() => {
          request({
            path: `/1/invoices/${item.id}/billable-items/export`,
            method: 'GET',
          });
        }}
        icon="file-csv"
        text={t(
          'invoices.tableColumn.actions.downloadBillableItems',
          'Download Invoice Billable Items'
        )}
        disabled={!writeAccess}
      />
      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: 'Invoice' }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item
            icon="trash"
            text="Delete"
            disabled={!writeAccess || !hasBeenProcessed}
          />
        }
        button={t('deleteDialog.button', 'Delete')}
        onConfirm={async () => {
          await request({
            method: 'DELETE',
            path: `/1/invoices/${item.id}`,
          });
          await onReload();
        }}
      />

      {item?.billingEngineVersion === 2 && currentUserIsSuperAdmin() && (
        <Dropdown.Item
          as={Link}
          to={`${prefixPath}/invoices/${item.id}/summary`}
          text="Summary"
          icon="star"
        />
      )}

      <InspectObject
        name="Invoice"
        trigger={<Dropdown.Item text="Inspect" icon="code" />}
        getData={() => fetchInvoice(item.id)}
      />
    </>
  );
}
