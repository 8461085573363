import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilters, Breadcrumbs, Search, Layout } from 'components';
import { Segment, Divider, Message } from 'semantic';
import { Link } from 'react-router-dom';

import Table from './Table';
import { request } from 'utils/api';
import useUserHasAccessToEvseControllersIntegrations from '../useUserHasAccessToEvseControllersIntegrations';

export default function Integrations() {
  const { t } = useTranslation();

  const hasAccess = useUserHasAccessToEvseControllersIntegrations('none');
  if (!hasAccess) {
    return (
      <>
        <Message warning>
          {t(
            'access.cannotAccess',
            'This page is not available for you. Please contact your administrator.'
          )}
        </Message>
      </>
    );
  }

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/1/evse-controllers/mine',
      body: {
        includeIntegrations: true,
        ignoreOtherUserLocations: true,
        ...filters,
      },
    });
  };

  const getFilterMapping = () => {
    return {
      searchPhrase: {
        type: 'search',
      },
    };
  };

  const searchRef = useRef(null);

  return (
    <Search.Provider
      onDataNeeded={onDataNeeded}
      ref={searchRef}
      filterMapping={getFilterMapping()}>
      {() => (
        <>
          <Breadcrumbs
            path={[
              <Link to="/my-locations">
                {t('myLocations.header', 'My Locations')}
              </Link>,
            ]}
            active={t('integrations.title', 'Integrations')}
          />
          <Message info>
            {t(
              'integrations.description',
              'Integrations allow you to connect your charging station to a third party. It can be a smart charging provider or an app that unlocks additional functionality for your charging station.'
            )}
          </Message>

          <Divider hidden />
          <Segment>
            <Layout horizontal spread stackable>
              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchPhrase"
                  placeholder={t(
                    'integrations.filterPlaceHolder',
                    'Location or ID'
                  )}
                />
              </Layout>
            </Layout>
          </Segment>
          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table />
          <div
            style={{
              textAlign: 'center',
            }}>
            <Search.Pagination />
          </div>
        </>
      )}
    </Search.Provider>
  );
}
