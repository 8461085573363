import React, { useRef, useEffect } from 'react';
import { Form } from 'semantic';
import { useTranslation } from 'react-i18next';

import { useDebounceValue } from 'utils/use-debounce';

import { checkVAT, countries } from 'jsvat-next';

export default function VatInput({
  value,
  name,
  label = '',
  countryCode,
  onChange,
  ...rest
}) {
  const { t } = useTranslation();

  const [error, setError] = React.useState();
  const [intervalValue, setIntervalValue] = React.useState(value);
  const testValue = useDebounceValue(intervalValue, 500);
  const [isLoading, setIsLoading] = React.useState(false);

  const ref = useRef();

  useEffect(() => {
    if (ref.current && rest.required) {
      ref.current.setCustomValidity(error ? error : '');
    }
  }, [ref.current, error, rest.required]);

  function wrappedOnChange(e, props) {
    if (value === props.value) {
      return;
    }
    onChange(e, props);
  }

  useEffect(() => {
    // XXX should be moved serverside
    const { isValid, country } = checkVAT(testValue, countries);
    if (
      testValue?.length &&
      (!isValid ||
        (countryCode && country?.isoCode.short !== countryCode.toUpperCase()))
    ) {
      setError(t('error.notAValidVATNumber', 'Not a valid VAT Number'));
    } else {
      setError(null);
    }
    wrappedOnChange(null, { name, value: testValue });
    setIsLoading(false);
  }, [testValue, wrappedOnChange]);

  return (
    <Form.Input
      input={{ ref }}
      error={error}
      loading={isLoading}
      label={label || t('formLabel.vatNumber', 'Vat Number')}
      type="text"
      value={intervalValue}
      onChange={(e, props) => {
        setIsLoading(true);
        setIntervalValue(props.value.trim());
      }}
      {...rest}
    />
  );
}
