import React from 'react';
import { Segment, Message } from 'semantic';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import ApiMessage from 'components/ApiMessage';
import { Link } from 'react-router-dom';

import Form from './Form';

import { parseToken } from 'utils/token';
import { withTranslation, Trans } from 'react-i18next';
import { UserContext } from 'contexts/user';

import { request } from 'utils/api';

class ResetPassword extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    const token = localStorage.getItem('resetPasswordToken');
    this.state = {
      token,
      jwt: parseToken(token),
    };
  }

  onSubmit = async (body) => {
    this.setState({
      loading: true,
      error: null,
    });
    try {
      await request({
        method: 'POST',
        path: '/1/auth/set-password',
        body: {
          ...body,
          token: this.state.token,
        },
      });
      this.setState({
        loading: false,
        success: true,
      });

      setTimeout(() => {
        window.location = '/';
      }, 1000);
    } catch (e) {
      this.setState({
        loading: false,
        error: e,
      });
    }
  };

  render() {
    const { token, jwt, loading, error, success } = this.state;

    const { t } = this.props;

    return (
      <PageCenter>
        <LogoTitle title={t('title.resetPassword', 'Reset Password')} />
        <Segment.Group>
          <Segment padded>
            {(!token || !jwt) && (
              <Message error>
                <Trans i18nKey="message.notValidResetPasswordToken">
                  <Message.Header>No valid token found</Message.Header>
                  <Message.Content>
                    Please ensure you either click the email link in the email
                    or copy paste the link in full.
                  </Message.Content>
                </Trans>
              </Message>
            )}
            {success && (
              <Message info>
                <Trans i18nKey="message.passwordChanged">
                  <Message.Header>
                    Your password has been changed!
                  </Message.Header>
                </Trans>
              </Message>
            )}
            {error && error.status !== 400 && (
              <Message error>
                <Trans i18nKey="message.tokenExpired">
                  We were unable to confirm your identity. Either the link you
                  followed is invalid, or it has expired.
                </Trans>
                <br />
                <br />
                <Link to="/password/index">
                  {t('link.tryAgain', 'Try Again')}
                </Link>
              </Message>
            )}
            {error && error.status === 400 && (
              <ApiMessage error message={error.message} />
            )}
            {!success && token && jwt && (
              <Form onSubmit={this.onSubmit} loading={loading} />
            )}
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}

export default withTranslation()(ResetPassword);
