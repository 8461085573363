import React from 'react';
import AppWrapper from 'components/AppWrapper';

import DataList from 'components/DataList';

import { request } from 'utils/api';

import Table from './Table';

import { Link } from 'react-router-dom';
import { Container, Breadcrumb, Divider } from 'semantic';
import { Breadcrumbs } from 'components';

import { withTranslation } from 'react-i18next';

const itemLimit = 100;

class ImportBatches extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  handleRequest = () => {
    return request({
      method: 'GET',
      path: '/1/sessions/import-batches',
    });
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { t } = this.props;
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="cards" to="/cards">
                {t('cards.title', 'Cards')}
              </Link>,
              <Link key="cards" to="/cards/sessions">
                {t('importBatches.breadcrumbSessions', 'Sessions')}
              </Link>,
            ]}
            active={t('importBatches.[title]', 'Previous Imports')}
          />

          <Divider hidden />

          <DataList
            key={this.state.dataKey}
            limit={itemLimit}
            onRequest={this.handleRequest}>
            <Table filters={this.state.filters} />
          </DataList>
          <Divider hidden />
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(ImportBatches);
