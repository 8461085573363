import React, { useEffect } from 'react';
import { getParameterByName } from 'utils/url';

export interface DeliveryAddress {
  firstName: string;
  lastName: string;
  address?: string;
  addressLine2?: string;
  city?: string;
  countryCode?: string;
  postalCode?: string;
}

export type CardType = 'card' | 'tag';

type SignupStep = 'plans' | 'billing' | 'payment' | 'account';

interface SignupContext {
  userId?: string;
  setUserId(userId?: string): void;
  cardType: CardType;
  setCardType(cardType: CardType): void;
  currency?: string;
  setCurrency(currency: string): void;
  referral: string;
  billingPlan?: BillingPlan;
  setBillingPlan(billingPlan?: BillingPlan): void;
  account?: Account;
  setAccount(account: Account): void;
  delivery?: DeliveryAddress;
  setDelivery(delivery?: DeliveryAddress): void;
  cleanup(): void;
  step?: SignupStep;
  setStep(step: SignupStep): void;
}

const SignupContext = React.createContext<SignupContext>({
  setUserId: () => void {},
  cardType: 'card',
  setCardType: () => void {},
  currency: 'EUR',
  referral: '',
  setCurrency: () => void {},
  setBillingPlan: () => void {},
  setDelivery: () => void {},
  setAccount: () => void {},
  cleanup: () => void {},
  setStep: () => void {},
});

import { storage } from 'utils/storage';
import { BillingPlan } from 'types/billingplan';
import { Account } from 'types/account';
import { useUser } from 'contexts/user';

const getData = () => {
  return storage.getItem('signupFields') || {};
};

interface Props {
  children: React.ReactNode;
}

const SignupProvider: React.FC<Props> = ({ children }) => {
  const signupFields = getData();
  const { provider } = useUser();

  const [billingPlan, setBillingPlan] = React.useState<BillingPlan | undefined>(
    signupFields.billingPlan
  );

  const [cardType, setCardType] = React.useState<CardType>(
    signupFields.cardType || 'card'
  );

  const [step, setStep] = React.useState<SignupStep>('plans');
  const [currency, setCurrency] = React.useState<string>();

  useEffect(() => {
    // TODO: we need decide how we want to pay for this service, or whether we want to pay for it at all. in
    // fetch(
    //   `https://api.ip2location.io/?key=XXX&format=json`
    // )
    //   .then((res) => res.json())
    //   .then((data) => {
    //     const currency = countries.getCurrencyByAlpha2(data.country_code);
    //     if (currency && provider.billingCurrencies.includes(currency)) {
    //       setCurrency(currency);
    //     } else {
    //       setCurrency(provider.billingCurrencies[0]);
    //     }
    //   })
    //   .catch(() => setCurrency(provider.billingCurrencies[0]));
    setTimeout(() => setCurrency(provider.billingCurrencies[0]), 250);
  }, []);

  // ensure that if the user updates a billing plan that their selected currency
  // is also available for the new plan
  useEffect(() => {
    const availableCurrency = billingPlan?.prices.find(
      (price) => price.currency === currency
    );
    if (!availableCurrency) {
      setBillingPlan(undefined);
    }
  }, [currency]);

  const [referral, setReferral] = React.useState(
    getParameterByName('referral') || signupFields.referral
  );
  const [userId, setUserId] = React.useState(signupFields.userId);
  const [account, setAccount] = React.useState(signupFields.account);
  const [delivery, setDelivery] = React.useState(signupFields.delivery);

  function cleanup() {
    storage.removeItem('signupFields');
  }

  const values: SignupContext = React.useMemo(() => {
    return {
      userId,
      setUserId,
      cardType,
      setCardType,
      referral,
      setReferral,
      billingPlan,
      setBillingPlan,
      setAccount,
      account,
      setDelivery,
      delivery,
      currency,
      setCurrency,
      cleanup,
      step,
      setStep,
    };
  }, [billingPlan, cardType, referral, currency, userId, account, step]);

  storage.setItem('signupFields', values, 1000 * 60 * 60 * 24);

  return (
    <SignupContext.Provider value={values}>{children}</SignupContext.Provider>
  );
};

const useSignup = () => {
  const context = React.useContext(SignupContext);
  if (context === undefined) {
    throw new Error('useSignup must be used within a SignupProvider Context');
  }
  return context;
};

export { SignupProvider, useSignup };
