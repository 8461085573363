import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Menu } from 'semantic';
import { useTranslation } from 'react-i18next';
import { currentUserIsSuperAdmin } from 'utils/roles';

export default () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('externalTokensMenu.summary', 'Summary')}
          to={`/charging-stations/tokens`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('externalTokensMenu.search', 'Search')}
          to={`/charging-stations/tokens/search`}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('externalTokensMenu.analytics', 'Analytics')}
          to={`/charging-stations/tokens/analytics`}
          as={NavLink}
        />
        {currentUserIsSuperAdmin() && (
          <Menu.Item
            exact
            content={t('externalTokensMenu.remoteSearch', 'Remote Search')}
            to={`/charging-stations/tokens/remote-search`}
            as={NavLink}
          />
        )}
      </Menu>
    </Container>
  );
};
