import React, { useCallback, useEffect } from 'react';
import { Segment, Form, Message, Header, Divider, Button } from 'semantic';
import { useTranslation, Trans } from 'react-i18next';
import ApiMessage from 'components/ApiMessage';
import { get, merge, set } from 'lodash-es';
import Password from 'components/form-fields/Password';
import { request } from 'utils/api';

import PhoneCountry from 'components/form-fields/PhoneCountry';
import PhoneNumber from 'components/form-fields/PhoneNumber';
import LegalLink from 'components/LegalLink';
import { Layout } from 'components/Layout';

import { useHistory } from 'react-router-dom';

import { useSignup } from '../Context';
import { useUser } from 'contexts/user';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import { trackCustomBehavioralEvent } from 'utils/hubspot';

interface Props {
  withPlan?: boolean;
  withoutPlan?: boolean;
  directPayment?: boolean;
  fieldService?: boolean;
  journey?: string;
}

const SignupAccount: React.FC<Props> = ({
  withPlan,
  withoutPlan,
  fieldService,
  directPayment,
  journey,
}) => {
  //@ts-ignore
  const { provider, setToken, token, user, fetchUser } = useUser();
  const { t, i18n } = useTranslation();
  const { referral, billingPlan, setStep } = useSignup();

  const history = useHistory();

  const [error, setError] = React.useState<string | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const { productInstrumentation } = useProductInstrumentation();
  const sendProductInstrumentation = useCallback(
    (stage: 'started' | 'failed' | 'completed' | 'errored') => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'account-creation',
        stage,
        variant: '1.0.0',
      });
    },
    []
  );

  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'account-creation',
      variant: '1.0.0',
    });
  }, []);

  const isLoggedIn = !!token;

  useEffect(() => {
    sendProductInstrumentation('started');
  }, [sendProductInstrumentation]);

  useEffect(() => {
    setStep('account');
  }, []);

  useEffect(() => {
    if (!user && token) {
      setLoading(true);

      fetchUser({ token }).then(() => {
        setLoading(false);
      });
    }
  }, []);

  const [formValues, setFormValues] = React.useState<{
    email?: string;
    password?: string;
    newsletter?: boolean;
    acceptedTerms?: boolean;
    acceptedPrivacy?: boolean;
    defaultLangCode?: string;
    timeZone?: string;
    contact?: {
      firstName?: string;
      lastName?: string;
      phoneCountryCode?: string;
      phoneNo?: string;
    };
  }>({
    contact: {
      phoneCountryCode: 'NL',
    },
    defaultLangCode: i18n.language,
    timeZone:
      (window.Intl &&
        Intl.DateTimeFormat() &&
        Intl.DateTimeFormat().resolvedOptions().timeZone) ||
      'Europe/Amsterdam',
  });

  if (!billingPlan?.id && withPlan) {
    history.replace('/signup');
  }

  React.useEffect(() => {
    if (user) {
      setFormValues({
        contact: user.contact,
        email: user.email,
        newsletter: user.newsletter,
        acceptedTerms: true,
        acceptedPrivacy: true,
      });
    }
  }, [user]);

  function setField<T>(name: string, value: T) {
    setSubmitted(false);
    setFormValues(merge({}, formValues, set({}, name, value)));
  }

  async function handleOnSubmit() {
    setLoading(true);
    setSubmitted(true);

    if (!formValues.acceptedTerms || !formValues.acceptedPrivacy) {
      setLoading(false);
      return;
    }

    const body = {
      ...formValues,
      referral: referral,
      contact: {
        ...(formValues.contact || {}),
      },
    };

    try {
      const { data } = await request(
        isLoggedIn
          ? {
              method: 'PATCH',
              path: '/1/users/me',
              body,
            }
          : {
              method: 'POST',
              path: '/1/auth/register',
              body: body,
            }
      );

      if (!isLoggedIn) {
        // adding tracking here, to avoid double tracking, when user is already logged in
        const dataLayer = window.dataLayer || [];
        if (dataLayer) {
          dataLayer.push({
            event: 'account_completed',
          });
        }

        trackCustomBehavioralEvent(`${journey}_account_completed`, {});
        setToken(data.token);
      }

      let url = '/signup/billing';
      if (withoutPlan) {
        url = '/cpo/signup/billing';
      } else if (directPayment) {
        url = '/direct-payment/signup/billing';
      } else if (fieldService) {
        url = '/field-service/signup/billing';
      }

      sendProductInstrumentation('completed');
      sendClick('continue');
      history.push(url);
    } catch (e: any) {
      sendProductInstrumentation('errored');
      setError(e?.message);
    }
    setLoading(false);
  }

  const platformName = provider?.platformName;

  return (
    <Form error={submitted} loading={loading} onSubmit={handleOnSubmit}>
      <Segment>
        <Header as="h2">
          {t('signup.accountHeader', 'Create an account')}
        </Header>

        {directPayment && (
          <p>
            {t(
              'signupAccount.directPaymentIntroWithPlatformName',
              'Please register for an account to start a remote session on an {{platformName}} charge station. At the end of the month you will be invoiced for the session.',
              {
                platformName: platformName,
              }
            )}
          </p>
        )}
        <Divider />

        {error && <ApiMessage error message={error} />}

        <Form.Group widths="equal">
          <Form.Input
            value={get(formValues, 'contact.firstName', '')}
            required
            name="contact.firstName"
            label={t('formLabel.firstName', 'First Name')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
            fluid
          />

          <Form.Input
            value={get(formValues, 'contact.lastName', '')}
            required
            name="contact.lastName"
            label={t('formLabel.lastName', 'Last Name')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
            fluid
          />
        </Form.Group>

        <Form.Field required>
          <label>{t('formLabel.phoneNumber', 'PhoneNumber')}</label>
          <Layout horizontal spread style={{ overflow: 'visible' }}>
            <PhoneCountry
              value={get(formValues, 'contact.phoneCountryCode')}
              name="contact.phoneCountryCode"
              autoComplete="disabled"
              onChange={(
                e: React.ChangeEvent,
                {
                  name,
                  value,
                }: {
                  name: string;
                  value: string;
                }
              ) => setField(name, value)}
            />

            <PhoneNumber
              required
              disabled={!get(formValues, 'contact.phoneCountryCode')}
              countryCode={get(formValues, 'contact.phoneCountryCode', '')}
              value={get(formValues, 'contact.phoneNo', '')}
              type="tel"
              name="contact.phoneNo"
              autoComplete="disabled"
              onChange={(
                e: React.ChangeEvent,
                {
                  name,
                  value,
                }: {
                  name: string;
                  value: string;
                }
              ) => setField(name, value)}
            />
          </Layout>
        </Form.Field>

        <Form.Input
          value={formValues.email || ''}
          required
          disabled={isLoggedIn}
          name="email"
          label={t('formLabel.email', 'E-mail')}
          type="email"
          onChange={(e, { name, value }) => setField(name, value)}
        />

        <Password
          disabled={isLoggedIn}
          name="password"
          label={t('formLabel.password', 'Password')}
          required
          value={formValues.password || ''}
          onChange={(
            e: React.ChangeEvent,
            {
              name,
              value,
            }: {
              name: string;
              value: string;
            }
          ) => setField(name, value)}
        />

        <Form.Checkbox
          error={submitted && !formValues.acceptedTerms}
          required
          disabled={isLoggedIn}
          label={
            <label>
              <Trans i18nKey="signupAccount.acceptTermsOfServices">
                I accept the <LegalLink type="tos">Terms of Service</LegalLink>.
              </Trans>
            </label>
          }
          checked={formValues.acceptedTerms}
          onChange={(e, { checked }) => setField('acceptedTerms', checked)}
        />

        <Form.Checkbox
          error={submitted && !formValues.acceptedPrivacy}
          required
          disabled={isLoggedIn}
          label={
            <label style={{ maxWidth: '370px', boxSizing: 'initial' }}>
              <Trans
                i18nKey="signupAccount.acceptPrivacy"
                platformName={
                  provider.privacyUrl ? provider.platformName : 'E-flux'
                }>
                I understand that {{ platformName }} will process my personal
                data in accordance with the{' '}
                <LegalLink type="privacy">Privacy Statement</LegalLink>.
              </Trans>
            </label>
          }
          checked={formValues.acceptedPrivacy}
          onChange={(e, { checked }) => setField('acceptedPrivacy', checked)}
        />

        <Form.Checkbox
          label={
            <label>
              <Trans i18nKey="signupAccount.acceptNewsletter">
                I want to receive newsletters about my charge card and/or
                subscription, and I hereby consent to the processing of my
                personal data for this, as described in the{' '}
                <LegalLink type="privacy">Privacy Statement</LegalLink>.
              </Trans>
            </label>
          }
          checked={formValues.newsletter}
          onChange={(e, { checked }) => setField('newsletter', checked)}
        />

        {!isLoggedIn &&
          submitted &&
          !(formValues.acceptedTerms || formValues.acceptedPrivacy) && (
            <Message
              error
              content={t(
                'signupAccount.pleaseAcceptTosAndPrivacy',
                'Please accept both terms of service and privacy statement.'
              )}
            />
          )}
      </Segment>
      <div>
        <Button
          as="button"
          primary
          size="large"
          content={t('signup.continueToBilling', 'Continue to billing')}
          loading={loading}
          disabled={
            loading || !formValues.acceptedTerms || !formValues.acceptedPrivacy
          }
          fluid
        />
      </div>
    </Form>
  );
};

export default SignupAccount;
