import React, { useMemo } from 'react';

import { request } from 'utils/api';

import DownloadInvoices from 'components/modals/DownloadInvoices';
import GenerateInvoice from 'components/modals/invoices/GenerateInvoice';

import { getInvoiceTypeOptions } from 'utils/invoicing';
import { currentUserCanAccess } from 'utils/roles';

import { Segment, Button, Divider, Dropdown } from 'semantic';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import {
  Search,
  Breadcrumbs,
  Layout,
  ListHeader,
  SearchFilters,
} from 'components';

import Table from './Table';
import SyncERPBulkButton from 'components/SyncERPBulkButton';

const onDataNeeded = ({ keywords, month, ...body }) => {
  const parsedMonth = {};
  if (month) {
    const result = month.split('-');
    parsedMonth.year = Number(result[0]);
    parsedMonth.month = Number(result[1]);
  }
  const isCsvFormat = body.format === 'csv';

  const path = isCsvFormat ? '/1/invoices/search' : '/1/invoices/search/fast';

  return request({
    method: 'POST',
    path,
    body: {
      ...body,
      ...parsedMonth,
      searchPhrase: keywords,
    },
  });
};

export default function Invoices({ prefixPath = '/finance' }) {
  const { t, i18n } = useTranslation();

  const writeAccess = currentUserCanAccess('invoices', 'write');

  const searchRef = React.useRef(null);

  const { labels, filterMapping } = useMemo(() => {
    const labels = {
      status: t('invoices.filterByStatus', 'Status'),
      type: t('invoices.filterByType', 'Type'),
      month: t('invoices.filterMonth', 'Month'),
      multipleExactReferences: t(
        'invoices.filterMultiExactReferences',
        'ERP Issues'
      ),
      excludeZero: t('invoices.filterExcludeZero', 'Exclude 0'),
      minAmount: t('invoices.filterMinAmount', 'Min Amount (vat excl.)'),
      accounts: t('invoices.filterAccounts', 'Filter By Accounts'),
    };

    const filterMapping = {
      keywords: {
        type: 'search',
      },
      status: {
        label: labels.status,
        type: 'string',
      },
      minAmount: {
        label: labels.minAmount,
        type: 'number',
      },
      type: {
        label: labels.type,
        type: 'string',
      },
      month: {
        label: labels.month,
        type: 'string',
      },
      multipleExactReferences: {
        label: labels.multipleExactReferences,
        type: 'boolean',
      },
      excludeZero: {
        label: labels.excludeZero,
        type: 'boolean',
      },
      accounts: {
        label: labels.accounts,
        type: 'string',
        multiple: true,
        getDisplayValue: async (ids) => {
          const { data } = await request({
            path: '/1/accounts/search',
            method: 'POST',
            body: {
              ids,
            },
          });

          return data.map((c) => c.name);
        },
      },
    };
    return {
      labels,
      filterMapping,
    };
  }, [i18n.language]);

  return (
    <Search.Provider
      ref={searchRef}
      limit={100}
      filterMapping={filterMapping}
      onDataNeeded={onDataNeeded}>
      <Breadcrumbs
        path={[
          <Link key="finance" to={prefixPath}>
            {t('finance.title', 'Finance')}
          </Link>,
        ]}
        active={t('invoices.title', 'Invoices')}
      />

      <ListHeader title={t('invoices.title', 'Invoices')}>
        <Search.Export filename="Invoices" />
        <SyncERPBulkButton />

        <DownloadInvoices
          onDone={() => searchRef.current.reload()}
          trigger={
            <Button
              basic
              content={t('invoices.downloadInvoices', 'Download Bulk')}
              icon="download"
            />
          }
        />
        <Dropdown
          button
          primary
          text={t('invoices.generateInvoicesAction', 'Generate invoices')}
          direction="left">
          <Dropdown.Menu>
            <GenerateInvoice
              handleClose={() => searchRef.current.reload()}
              trigger={
                <Dropdown.Item
                  basic
                  content={t(
                    'invoices.generateSingleInvoiceAction',
                    'Single Invoice'
                  )}
                  icon="file"
                  disabled={!writeAccess}
                />
              }
              isBulk={false}
            />
            <GenerateInvoice
              handleClose={() => searchRef.current.reload()}
              trigger={
                <Dropdown.Item
                  primary
                  content={t(
                    'invoices.generateBulkInvoicesAction',
                    'Bulk Invoices'
                  )}
                  icon="box-archive"
                  disabled={!writeAccess}
                />
              }
              isBulk={true}
            />
          </Dropdown.Menu>
        </Dropdown>
      </ListHeader>

      <Segment>
        <Layout horizontal spread stackable>
          <SearchFilters.Modal size="tiny">
            <SearchFilters.Dropdown
              name="status"
              label={labels.status}
              options={[
                {
                  text: t('invoices.filterError', 'Error'),
                  value: 'error',
                },
                {
                  text: t('invoices.filterReady', 'Ready'),
                  value: 'ready',
                },
                {
                  text: t('invoices.filterPending', 'Pending'),
                  value: 'pending',
                },
                {
                  text: t('invoices.filterPaid', 'Paid'),
                  value: 'paid',
                },
              ]}
            />

            <SearchFilters.Dropdown
              label={labels.type}
              name="type"
              fluid
              options={getInvoiceTypeOptions(t)}
            />

            <SearchFilters.Input
              label={labels.month}
              fluid
              name="month"
              type="month"
              width={6}
            />

            <SearchFilters.Input
              label={labels.minAmount}
              fluid
              name="minAmount"
              type="number"
            />

            <SearchFilters.Dropdown
              clearable
              objectMode={false}
              multiple
              label={labels.accounts}
              name="accounts"
              search
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body,
                });
              }}
            />

            <Divider />
            <Layout horizontal>
              <SearchFilters.Checkbox
                label={labels.multipleExactReferences}
                name="multipleExactReferences"
              />
              <SearchFilters.Checkbox
                label={labels.excludeZero}
                name="excludeZero"
                style={{ marginLeft: '1em' }}
              />
            </Layout>
          </SearchFilters.Modal>

          <Layout horizontal stackable center right>
            <Search.Total />
            <SearchFilters.Search
              name="keywords"
              placeholder={t('acconts.filterPlaceHolder', 'Name or ID')}
            />
          </Layout>
        </Layout>
      </Segment>
      <Search.Status noResults={t('common.noResults', 'No Results')} />
      <Table prefixPath={prefixPath} />
      <div
        style={{
          textAlign: 'center',
        }}>
        <Search.Pagination />
      </div>
    </Search.Provider>
  );
}
