import { useUser } from '../contexts/user';

// PlatformFeature is a component that will render its children if the current user has
// the specified feature enabled.
//
// Note: This component is NOT a feature flag checker, rather it checks whether the current
// user has a platform capability. To read more about the differences, check out:
// See: https://e-flux.atlassian.net/wiki/spaces/TECH/pages/239435861/Feature+management
export default function PlatformFeature({ children, feature }) {
  const { hasPlatformFeature } = useUser();
  if (hasPlatformFeature(feature)) {
    return children;
  }
  return null;
}
