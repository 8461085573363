import React from 'react';
import * as Search from 'components/search';
import { Divider } from 'semantic';
import TokenAuditTable from './TokenAuditTable';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';

function Audit({ t, doc, cards }) {
  const onDataNeeded = async (filters) => {
    const objectId = cards?.length
      ? [...cards.map(({ id }) => id), doc.id]
      : doc.id;
    return request({
      method: 'POST',
      path: `/1/audit-entries/search`,
      body: { ...filters, objectId, objectType: ['Token', 'Card'] },
    });
  };

  return (
    <>
      <Search.Provider
        key={doc.id + cards?.length} // Reload when cards change (fetch new audit entries)
        onDataNeeded={onDataNeeded}
        limit={30}>
        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <TokenAuditTable />
        <Divider hidden />
        <div style={{ textAlign: 'center' }}>
          <Search.Pagination />
        </div>
      </Search.Provider>
    </>
  );
}

export default withTranslation()(Audit);
