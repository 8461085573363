import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Divider, Header } from 'semantic';
import { RequestCardFormValues } from 'components/modals/RequestCard/v2/formData';
import SelectType from 'components/modals/RequestCard/v2/TypeAndPlan/SelectType';
import SelectBillingPlan from 'components/modals/RequestCard/v2/TypeAndPlan/SelectBillingPlan';
import { BillingPlan } from 'types/billingplan';

type TypeAndPlanProps = {
  vatPercentage?: number;
  billingPlans?: BillingPlan[];
};

export default function TypeAndPlan(props: TypeAndPlanProps) {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<RequestCardFormValues>();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <Header textAlign="center" as="h3">
        {t('addCardModal.selectType', 'Select Type')}
      </Header>
      <SelectType
        selected={values.card.type}
        onSelect={(nextType) =>
          setValues({
            ...values,
            card: { ...values.card, type: nextType },
          })
        }
      />
      <Divider hidden />
      <Header textAlign="center" as="h3">
        {props.billingPlans?.length === 1
          ? t('addCardModal.yourPlan', 'Your Plan')
          : t('addCardModal.choosePlan', 'Choose Plan')}
      </Header>
      <SelectBillingPlan
        billingPlans={props.billingPlans}
        billingPlanId={values.card.billingPlanId}
        onBillingPlanIdChange={(nextBillingPlanId) =>
          setValues({
            ...values,
            card: { ...values.card, billingPlanId: nextBillingPlanId },
          })
        }
        vatPercentage={props.vatPercentage}
        billingCountryCode={values?.billing?.countryCode}
      />
    </>
  );
}
