import React from 'react';
import styles from './styles.module.less';
import { useUser } from 'contexts/user';

export default function ChargeCard({ type, children }) {
  const { provider } = useUser();

  return (
    <div
      className={[styles.card, type, styles[provider.slug]]
        .filter(Boolean)
        .join(' ')}>
      <div className={[styles.innerCard].join(' ')}>
        <div className={styles.logo} />
        {/* <img src={provider.logoUrl || cardLogo} className={styles.logo} />*/}
        <div className={styles.labels}>{children}</div>
      </div>
    </div>
  );
}

ChargeCard.Group = function ChargeCardGroup({ children }) {
  return <div className={styles.groupcontainer}>{children}</div>;
};

ChargeCard.Title = function ChargeCardTitle({ children }) {
  return <div className={styles.title}>{children}</div>;
};

ChargeCard.Subtitle = function ChargeCardSubtitle({ children }) {
  return <div className={styles.subtitle}>{children}</div>;
};

ChargeCard.Number = function ChargeCardNumber({ children }) {
  return <div className={styles.number}>{children}</div>;
};
