import CopySnippet from 'components/CopySnippet';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Header } from 'semantic';
import { Paragraph } from './Components';

class SandboxCpo extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <>
        <Header as="h2" style={{ marginTop: '1rem' }}>
          CPO examples
        </Header>
        <Paragraph>
          {t('sandbox.retrieveCpoSessionsHelp', 'Retrieve CPO sessions')}
        </Paragraph>
        <CopySnippet
          language="bash"
          value={`curl -X POST ${this.props.url}/2/sessions/cpo/search \\
  -H "Provider: ${this.props.credentials?.providerId}" \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer ${
    this.props.credentials?.apiToken || '<API TOKEN>'
  }" \\
  --data '{ "limit": 10 }'`}
        />
      </>
    );
  }
}

export default withTranslation()(SandboxCpo);
