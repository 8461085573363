import React, { useCallback } from 'react';
import {
  CardDeliveryInfo,
  RequestCardFormValues,
} from 'components/modals/RequestCard/v2/formData';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, FormField, Grid } from 'semantic';
import Countries from 'components/form-fields/formik/Countries';
import InputField from 'components/form-fields/formik/InputField';
import styles from './styles.module.less';

type DeliveryAddressFormPartProps = {
  objectPath?: string;
  showErrorLabels?: boolean;
  disabled?: boolean;
};

export function DeliveryAddressFormPart(props: DeliveryAddressFormPartProps) {
  const { t } = useTranslation();
  const prefix = props.objectPath ? `${props.objectPath}.` : '';
  const formatFieldName = (fieldName: string) => prefix + fieldName;

  return (
    <Form>
      <Grid padded="horizontally" className={styles.formContainer}>
        <Grid.Row className={styles.rowResetPaddingBottom}>
          <Grid.Column className={styles.deliveryAddressColumnPaddingRight}>
            <Form.Group>
              <FormField
                disabled={props.disabled}
                width={8}
                className={`${styles.deliveryAddressColumnPaddingRight} ${styles.deliveryAddressColumnMobilePaddingRight}`}>
                <InputField
                  required
                  validateImmediately
                  name={formatFieldName('firstName')}
                  label={t('deliveryAddress.firstName', 'First Name')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
              <FormField
                disabled={props.disabled}
                width={8}
                className={`${styles.fieldTopPaddingForMobile} ${styles.deliveryAddressLastName}`}>
                <InputField
                  required
                  validateImmediately
                  name={formatFieldName('lastName')}
                  label={t('deliveryAddress.lastName', 'Last Name')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
            </Form.Group>
            <FormField
              disabled={props.disabled}
              required
              validateImmediately
              className={styles.fieldTopPaddingForMobile}>
              <Countries
                lowerCase={false}
                name={formatFieldName('countryCode')}
                label={t('formLabel.country', 'Country')}
                hideErrorLabel={!props.showErrorLabels}
              />
            </FormField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className={styles.deliveryAddressColumnPaddingRight}>
            <Form.Group>
              <FormField disabled={props.disabled} width={12}>
                <InputField
                  required
                  validateImmediately
                  name={formatFieldName('street')}
                  label={t('productDelivery.street', 'Street')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
              <FormField
                disabled={props.disabled}
                width={4}
                className={styles.fieldTopPaddingForMobile}>
                <InputField
                  required
                  validateImmediately
                  name={formatFieldName('number')}
                  label={t('productDelivery.number', 'No.')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
            </Form.Group>
            <Form.Group>
              <FormField
                disabled={props.disabled}
                width={6}
                className={styles.fieldTopPaddingForMobile}>
                <InputField
                  required
                  validateImmediately
                  name={formatFieldName('postalCode')}
                  label={t('productDelivery.postalCode', 'Postal Code')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
              <FormField
                disabled={props.disabled}
                width={10}
                className={styles.fieldTopPaddingForMobile}>
                <InputField
                  required
                  validateImmediately
                  name={formatFieldName('city')}
                  label={t('productDelivery.city', 'City/Town')}
                  hideErrorLabel={!props.showErrorLabels}
                />
              </FormField>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}

interface DeliveryAddressProps {
  hiddenForm?: boolean;
}

export function DeliveryAddressSameAsBilling(props: DeliveryAddressProps) {
  const { values } = useFormikContext<RequestCardFormValues>();

  const Component = useCallback(
    () => (
      <Formik<CardDeliveryInfo>
        initialValues={{
          useSameAsBilling: false,
          firstName:
            values.cardHolder.type === 'existing'
              ? values.cardHolder.user?.contact?.firstName || ''
              : values.cardHolder.firstName,
          lastName:
            values.cardHolder.type === 'existing'
              ? values.cardHolder.user?.contact?.lastName || ''
              : values.cardHolder.lastName,
          street: values.billing.street,
          number: values.billing.number,
          postalCode: values.billing.postalCode,
          city: values.billing.city,
          countryCode: values.billing.countryCode?.toUpperCase(),
        }}
        onSubmit={() => undefined}>
        {!props.hiddenForm && <DeliveryAddressFormPart disabled />}
      </Formik>
    ),
    [
      values.billing.street,
      values.billing.number,
      values.billing.postalCode,
      values.billing.city,
      values.billing.countryCode,
      ...(values.cardHolder.type === 'existing'
        ? [values.cardHolder.user, undefined]
        : [values.cardHolder.firstName, values.cardHolder.lastName]),
    ]
  );
  return <Component />;
}

export function useDeliveryAddressFormikValidationSchema() {
  const { t } = useTranslation();
  const conditionalString = (msg: string) =>
    Yup.string().when('useSameAsBilling', {
      is: false,
      then: (schema) => schema.required(msg),
    });

  return {
    firstName: conditionalString(
      t('requestCardModel.firstNameRequired', 'First Name is required')
    ),
    lastName: conditionalString(
      t('requestCardModel.lastNameRequired', 'Last Name is required')
    ),
    street: conditionalString(
      t('requestCardModel.streetRequired', 'Street is required')
    ),
    number: conditionalString(
      t('requestCardModel.numberRequired', 'Number is required')
    ),
    postalCode: conditionalString(
      t('requestCardModel.postalCodeRequired', 'Postal Code is required')
    ),
    city: conditionalString(
      t('requestCardModel.cityRequired', 'City is required')
    ),
    countryCode: conditionalString(
      t('requestCardModel.countryCodeRequired', 'Country is required')
    ),
  };
}
