import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic';
import modal from 'helpers/modal';
import { request } from 'utils/api';
import { Dropdown, DropdownMenu, DropdownItem } from 'semantic';
import { currentUserCanAccess, currentUserHasGlobalRoles } from 'utils/roles';
import { useUser } from 'contexts/user';

interface Props {
  onSelect: (item: any) => void;
  defaultValue: string;
}

type ProviderOnList = {
  id: string;
  name: string;
  parentId: string;
  children: ProviderOnList[];
};

const ProviderSelector: React.FC<Props> = ({ onSelect, defaultValue }) => {
  const canListUnfiltered =
    currentUserCanAccess('providers', 'read') || currentUserHasGlobalRoles();

  const user = useUser();
  const [state, setState] = useState<{
    data: null | undefined | ProviderOnList[];
    loading: boolean;
    error: any;
  }>({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await request({
          method: 'GET',
          path: `/1/providers/list`,
        });
        setState({ data, error: null, loading: false });
      } catch (error) {
        setState({ error, loading: false, data: null });
      }
    };
    fetch();
  }, []);

  const mapProviderToDropdownData = (item: ProviderOnList, level: number) => {
    return {
      key: item.id,
      value: item.id,
      label: level ? (
        <span style={{ display: 'inline-block', width: `${level}em` }} />
      ) : null,
      text: item.name,
    };
  };

  const displayProviders = (state.data || []).filter((provider) => {
    // a provider should be included in the list if they are either a top level provider or do not
    // exist in another provider children list, this can happen if the user is a member of a sub provider
    // without access to parent provider
    if (!provider.parentId) {
      return true;
    }

    const onChildrenList = (state.data || []).some((parentProvider) =>
      parentProvider.children.some(
        (childProvider) => childProvider.id == provider.id
      )
    );

    return !onChildrenList;
  });

  const options = [];

  const buildOptions = (providers: ProviderOnList[], level: number) => {
    for (const provider of providers) {
      options.push(mapProviderToDropdownData(provider, level));
      buildOptions(provider.children, level + 1);
    }
  };

  buildOptions(displayProviders, 0);

  return (
    <React.Fragment>
      <Modal.Header>Select Provider</Modal.Header>
      <Modal.Content>
        <Dropdown
          selection
          fluid
          search
          loading={state.loading}
          options={options}
          error={state.error}
          onChange={(e, { value }) => {
            const item = state?.data?.filter((c) => c.id === value)[0];
            onSelect(item);
            window.location.reload();
          }}
          defaultValue={defaultValue}
        />
      </Modal.Content>
    </React.Fragment>
  );
};

export default modal(ProviderSelector);
