import React from 'react';
import { useField, FieldValidator } from 'formik';
import Countries from '../Countries'; // Import your original Countries component

interface SelectCountryProps {
  name: string;
  label?: string;
  disabled?: boolean;
  wrapperStyle?: React.CSSProperties;
  validate?: FieldValidator;
  required?: boolean;
  readOnly?: boolean;
  lowerCase?: boolean;
}

export default function SelectCountry({
  name,
  required = false,
  label,
  disabled = false,
  wrapperStyle,
  validate,
  lowerCase = true,
  readOnly = false,
}: SelectCountryProps) {
  const [field, meta, helpers] = useField({ name, validate });

  return (
    <div style={wrapperStyle}>
      <Countries
        required={required}
        label={label}
        lowerCase={lowerCase}
        name={name}
        value={field.value}
        disabled={disabled}
        readOnly={readOnly}
        onChange={(value: string) => {
          helpers.setValue(value);
        }}
      />
    </div>
  );
}
