import { hasProviderRoles, canAccessAccountEndpoint } from 'utils/roles';
import { ENV_NAME } from './env';

function isAllowedEmailDomain(email) {
  const domain = email.split('@')[1];
  return ['road.io', 'e-flux.nl', 'e-flux.io'].includes(domain);
}

export function mfaIsRequiredForUser(user) {
  if (user.accountType !== 'organization') {
    return false;
  }
  if (user?.mfaMethod === 'disabled') {
    return false;
  }
  if (ENV_NAME === 'development') return false;

  if (hasProviderRoles(user) || isAllowedEmailDomain(user.email)) return true;
  if (
    canAccessAccountEndpoint(user, user.accountId, 'ocpiCredentials', 'read')
  ) {
    return true;
  }
  return false;
}
