import React, { useMemo } from 'react';
import { Icon, Popup } from '../semantic';

function resolveEvseId(evseId, evseIdRef) {
  let evseIdDisplayValue = '-';
  let fromRef = false;
  if (evseId) {
    evseIdDisplayValue = evseId;
  } else if (evseIdRef?.identifier) {
    evseIdDisplayValue = evseIdRef.identifier;
    fromRef = true;
  }
  return {
    evseId: evseIdDisplayValue,
    fromRef,
  };
}

export function EvseControllerEvseId({ evseController = {}, connector = {} }) {
  return useMemo(() => {
    const { evseId, fromRef } = resolveEvseId(
      connector?.evseId || evseController.evseId,
      evseController.evseIdRef
    );
    return (
      <>
        {evseId}
        {fromRef && (
          <>
            &nbsp;
            <Popup
              content={
                'Full EVSE ID will be assigned once the EVSE Controller has a valid Location (with country)'
              }
              trigger={<Icon name={'circle-question'} color={'grey'} />}
            />
          </>
        )}
      </>
    );
  }, [evseController]);
}

export function EvseControllerConnectorEvseIds({ evseController }) {
  return useMemo(() => {
    if (!evseController.connectors.length) {
      return '';
    }
    if (evseController.evseId) {
      return evseController.connectors.map((c) => c.evseId).join(', ');
    }
    if (evseController.evseIdRef) {
      const s = evseController.connectors.length === 1 ? '' : 's';
      return (
        <>
          <i>
            {evseController.connectors.length} Connector{s} (EVSE ID{s} Pending)
          </i>
          &nbsp;
          <Popup
            content={`Connector EVSE ID${s} will be assigned once the EVSE Controller has a valid Location (with country)`}
            trigger={<Icon name={'circle-question'} color={'grey'} />}
          />
        </>
      );
    }
    return '';
  }, [evseController.evseId, evseController.evseIdRef]);
}
