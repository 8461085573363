import { isSandboxMode } from 'utils/sandbox';
import { SANDBOX_API_URL, API_URL } from 'utils/env';

export function isEfluxDomain() {
  const { hostname } = document.location;
  if (hostname.match(/localhost/)) return true;
  if (hostname.match(/^e-flux.local$/)) return true;
  if (hostname.match(/^e-flux.dev$/)) return true;
  if (hostname.match(/^www.e-flux.dev$/)) return true;
  if (hostname.match(/^dashboard.e-flux.dev$/)) return true;
  if (hostname.match(/^e-flux.nl$/)) return true;
  if (hostname.match(/^www.e-flux.nl$/)) return true;
  if (hostname.match(/^dashboard.e-flux.nl$/)) return true;
  if (hostname.match(/^dashboard\.e-flux\.io$/)) return true;
  if (hostname.match(/^dashboard\.road\.io$/)) return true;
  if (hostname.match(/^beta-dashboard.e-flux.nl$/)) return true;
  if (hostname.match(/\.demo\.road\.dev$/)) return true;
  if (hostname.match(/\.demo\.public\.road\.dev$/)) return true;
  if (hostname.match(/^dashboard\.public\.road\.dev$/)) return true;
  if (hostname.match(/^dashboard\.road\.dev$/)) return true;
  if (hostname.match(/.*\.local\.road\.dev$/)) return true;
  if (hostname.match(/^local\.road\.dev$/)) return true;
  return false;
}

export function getRoadApiUrl() {
  if (isSandboxMode()) {
    return SANDBOX_API_URL;
  }
  const { hostname } = document.location;
  if (hostname.match(/local/)) return `http://road.local:2300`;
  if (hostname.match(/.dev/)) return `https://api.e-flux.dev`;
  return `https://api.road.io`;
}

export function getOcppDomain({ road = false, staging = false }) {
  if (road) {
    return staging ? 'public.road.dev' : 'road.io';
  } else {
    return staging ? 'e-flux.dev' : 'e-flux.nl';
  }
}
