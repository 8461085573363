import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactJson from './ReactJsonThemed';

class JSONBlock extends PureComponent {
  static propTypes = {
    value: PropTypes.object.isRequired,
    name: PropTypes.string,
    collapsed: PropTypes.number,
  };

  static defaultProps = {
    language: null,
  };

  render() {
    const { name, value, collapsed, ...props } = this.props;
    return (
      <div style={{ marginBottom: '15px' }}>
        <ReactJson
          {...props}
          sortKeys={false}
          displayDataTypes={false}
          style={{ padding: '15px', wordBreak: 'break-word' }}
          src={value}
          name={name}
          collapsed={collapsed}
          groupArraysAfterLength={collapsed ? 100 : 0}
        />
      </div>
    );
  }
}

export default JSONBlock;
