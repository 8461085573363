import React from 'react';

import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import { simpleOptions } from 'utils/form';

export default class UnlockConnector extends React.Component {
  static defaultProps = {
    initialValues: {
      source: 'eclearing',
    },
  };

  state = {
    open: false,
    formValues: { ...this.props.initialValues },
  };

  componentDidUpdate(prevProps) {
    if (this.props.initialValues !== prevProps.initialValues) {
      this.setState({
        submitted: false,
        formValues: { ...this.props.initialValues },
      });
    }
  }

  componentDidMount() {}

  handleSubmit = () => {
    const { sessionId, initialValues } = this.props;
    const { formValues } = this.state;
    this.setState({
      submitted: true,
      loading: true,
    });
    request({
      method: 'POST',
      path: `/2/sessions/cpo/${sessionId}/resync`,
      body: {
        source: formValues.source,
      },
    })
      .then(({ data }) => {
        this.props.onDone(data);
        this.setState({
          formValues: initialValues,
          open: false,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  setField(name, value) {
    const { formValues } = this.state;
    this.setState({
      submitted: false,
      formValues: {
        ...formValues,
        [name]: value,
      },
    });
  }

  render() {
    const { sessionId } = this.props;
    const { trigger } = this.props;
    const { error, loading, formValues = {}, submitted, open } = this.state;

    return (
      <Modal
        closeIcon
        closeOnDimmerClick={false}
        trigger={trigger}
        onClose={() =>
          this.setState({
            open: false,
            formValues: this.props.initialValues,
            submitted: false,
          })
        }
        onOpen={() => this.setState({ open: true })}
        open={open}>
        <Modal.Header>Resync Session {sessionId}</Modal.Header>
        <Modal.Content>
          <p>This will resync the session to roaming partners.</p>
          <Form
            error={submitted && Boolean(error)}
            onSubmit={() => this.handleSubmit()}>
            {error && <Message error content={error.message} />}

            <Form.Select
              value={formValues.source}
              options={simpleOptions(['eclearing', 'ocpi'])}
              name="source"
              label="Source"
              type="text"
              required
              onChange={(e, { name, value }) => this.setField(name, value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading || submitted}
            primary
            content="Execute"
            onClick={this.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
