import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SecretSpan extends Component {
  state = { visible: false };

  hide = (e) => {
    e.stopPropagation();
    this.setState({ visible: false });
  };

  show = (e) => {
    e.stopPropagation();
    this.setState({ visible: true });
  };

  render() {
    const { visible } = this.state;
    const { value = '', percentageToShow = 0.7, inline = false } = this.props;
    const obfuscationPoint = Math.floor(value.length * percentageToShow);
    const obfuscatedValue = value.replace(
      value.slice(0, obfuscationPoint),
      '*******'
    );
    return (
      <span>
        {visible ? value : obfuscatedValue}
        {inline ? ' ' : <br />}
        {visible ? (
          <a style={{ cursor: 'pointer' }} onClick={(e) => this.hide(e)}>
            Hide
          </a>
        ) : (
          <a style={{ cursor: 'pointer' }} onClick={(e) => this.show(e)}>
            Show
          </a>
        )}
      </span>
    );
  }
}

SecretSpan.propTypes = {
  value: PropTypes.string,
  percentageToShow: PropTypes.number,
  inline: PropTypes.bool,
};
