import { Table, Label, Dropdown, Confirm } from 'semantic';
import React, { useState } from 'react';
import { formatKwh, truncate } from 'utils/formatting';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date';
import Currency from 'components/Currency';
import { useTranslation } from 'react-i18next';

export default function CreditSessionsTable({
  creditSessions,
  onCreditSessionDelete,
}) {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const { t } = useTranslation();

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>Account / ID</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Total Amount</Table.HeaderCell>
          <Table.HeaderCell>Session kWh</Table.HeaderCell>
          <Table.HeaderCell>External Unique ID</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {creditSessions.map((creditSession) => (
          <Table.Row key={creditSession.id}>
            <Table.Cell>
              <Link to={`/accounts/${creditSession.accountId}`}>
                {creditSession.account.name}
              </Link>
              <p>
                <Link to={`/cards/sessions/${creditSession.sessionId}`}>
                  <small title={creditSession.externalUniqueId}>
                    {creditSession.externalUniqueId
                      ? truncate(creditSession.externalUniqueId, 20)
                      : '-'}
                  </small>
                </Link>
              </p>
            </Table.Cell>
            <Table.Cell>{formatDate(creditSession.createdAt)}</Table.Cell>
            <Table.Cell>
              <Label>{creditSession.correctionType}</Label>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <Currency
                currency={creditSession.currency}
                value={creditSession.totalAmount}
              />
            </Table.Cell>
            <Table.Cell textAlign="right">
              {formatKwh(creditSession.sessionKwh)}
            </Table.Cell>
            <Table.Cell>
              <code>{creditSession.externalUniqueId}</code>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Dropdown button basic text={t('common.more', 'More')}>
                <Dropdown.Menu direction="left">
                  <Confirm
                    size="mini"
                    open={showRemoveConfirm}
                    content="Are you sure you want to remove this credit session?"
                    onConfirm={() => onCreditSessionDelete(creditSession.id)}
                    trigger={
                      <Dropdown.Item
                        onClick={() => setShowRemoveConfirm(true)}
                        text="Delete"
                        icon="trash"
                      />
                    }
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
