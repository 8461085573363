import React, { useEffect, useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';

import { Container, Divider, Message, Loader, Header, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';

export default function AccessGroup(props) {
  const [itemId, setItemId] = useState(props.match.params.id);
  const [item, setItem] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    fetch(itemId);
  }, []);

  function fetch(itemId) {
    return request({
      method: 'GET',
      path: `/1/access-groups/${itemId}`,
    }).then(({ data }) => {
      setItem(data);
    });
  }

  if (!item) return <Loader active />;

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link to="/my-locations">
              {t('myLocations.header', 'My Locations')}
            </Link>,
            <Link to="/my-locations/access-groups">
              {t('myAccessGroups.title', 'My Access Groups')}
            </Link>,
          ]}
          active={item ? item.name : 'Loading...'}
        />
        <Grid reversed="computer">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              {item ? item.name : 'Loading...'}
            </Header>
          </Grid.Column>
        </Grid>
        <Divider hidden />
      </Container>
      {item && item.deletedAt && (
        <Message error content="This access group has been deleted" />
      )}
      {!item && <PageLoader />}
      {item && (
        <Switch>
          <Route
            exact
            path="/my-locations/access-groups/:id"
            item={item}
            component={(props) => (
              <Overview
                {...props}
                accessGroup={item}
                onRefresh={() => fetch(itemId)}
              />
            )}
          />
        </Switch>
      )}
    </AppWrapper>
  );
}
