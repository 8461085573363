import React from 'react';
import { Form, Button } from 'semantic';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation();
  const { loading } = props;
  const [password, setPassword] = React.useState('');
  const [repeat, setRepeat] = React.useState('');
  const [touched, setTouched] = React.useState(false);

  return (
    <Form
      error={touched}
      size="large"
      onSubmit={() => {
        setTouched(true);

        if (repeat !== password) return;

        props.onSubmit({
          password,
        });
      }}>
      <Form.Input
        name="password"
        icon="lock"
        iconPosition="left"
        placeholder={t('formPlaceHolder.newPassword', 'New Password')}
        autoComplete="new-password"
        type="password"
        value={password}
        onChange={(e, { value }) => setPassword(value)}
      />

      <Form.Input
        error={touched && repeat !== password}
        name="password"
        icon="lock"
        iconPosition="left"
        placeholder={t('formPlaceHolder.repeatPassword', 'Repeat Password')}
        autoComplete="new-password"
        type="password"
        value={repeat}
        onChange={(e, { value }) => setRepeat(value)}
      />
      <Form.Input>
        <Button
          fluid
          primary
          size="large"
          content={t('button.resetPassword', 'Reset Password')}
          loading={loading}
          disabled={loading}
        />
      </Form.Input>
    </Form>
  );
};
