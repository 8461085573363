import React from 'react';
import RequestBlock from 'components/RequestBlock';
import Markdown from 'components/Markdown';
import Code from 'components/Markdown/Code';
import Heading from './Heading';
import 'github-markdown-css';
import { enrichMarkdown, executeOpenApiMacros } from 'utils/markdown';
import { ThemeContext } from 'contexts/theme';

import './table.less';
import { API_URL } from 'utils/env';
import { getRoadApiUrl, getOcppDomain } from '../../utils/domains';

export default class StandardPage extends React.Component {
  state = {
    application: undefined,
  };

  static contextType = ThemeContext;

  componentDidMount() {
    this.scrollIntoView();
  }

  componentDidUpdate() {
    this.scrollIntoView();
  }

  scrollIntoView() {
    const hash = this?.props?.location?.hash?.replace('#', '');
    if (!hash) {
      return;
    }
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView();
    }
  }

  renderCodeBlock = (props) => {
    const { className = '', children } = props;
    if (className.includes('request')) {
      const value = Array.isArray(children) ? children[0] : children;
      return (
        <RequestBlock
          authToken={'<token>'}
          providerId={'<provider-id>'}
          apiKey={this.context.application?.apiKey}
          request={JSON.parse(value)}
          baseUrl={API_URL}
        />
      );
    }

    return (
      <Code renderedTheme={this.context.renderedTheme} {...props} allowCopy />
    );
  };

  render() {
    const { credentials, page, openApi } = this.props;
    const { provider } = window;
    const markdownOptions = {
      OCPP_DOMAIN_PRODUCTION: getOcppDomain({ road: true, staging: false }),
      OCPP_DOMAIN_STAGING: getOcppDomain({ road: true, staging: true }),
      OCPP_DEFAULT_PROVIDER_SLUG: '<provider-slug>',
    };
    if (provider.slug !== 'e-flux') {
      markdownOptions.APP_NAME = 'Road.io';
      markdownOptions.API_URL = getRoadApiUrl();
      markdownOptions.OCPP_DOMAIN_PRODUCTION = getOcppDomain({
        road: true,
        staging: false,
      });
      markdownOptions.OCPP_DOMAIN_STAGING = getOcppDomain({
        road: true,
        staging: true,
      });
      markdownOptions.OCPP_DEFAULT_PROVIDER_SLUG = '<provider-slug>';
    }
    let markdown = enrichMarkdown(page.markdown, credentials, markdownOptions);
    markdown = executeOpenApiMacros(openApi, markdown);

    return (
      <div className="docs markdown-body">
        <Markdown
          source={markdown}
          components={{
            code: this.renderCodeBlock,
            heading: Heading,
          }}
        />
      </div>
    );
  }
}
