import React from 'react';
import { FieldValidator, useField } from 'formik';
import { Form } from 'semantic';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  validate?: FieldValidator;
  id: string;
  // Set this to true if you want the valdiation occur immediately, instead of after touched;
  // i.e touched = input has been focused, or form submitted
  validateImmediately?: boolean;
  // set this to true if you do not want to show the error label below the input if the field is not valid. Note that
  // the field will still be styled as invalid
  hideErrorLabel?: boolean;
  style?: React.CSSProperties;
  classNames?: string[];
  disabled?: boolean;
  value?: string;
}

const RadioButtonField: React.FC<Props> = ({
  name,
  label,
  required,
  validate,
  hideErrorLabel,
  validateImmediately,
  id,
  style,
  classNames,
  disabled = false,
  value,
}) => {
  const [field, meta, helpers] = useField({ name, validate });
  const hasTouched = validateImmediately ? true : meta.touched;
  if (style || classNames) {
    return (
      <div className={classNames?.join(' ')} style={style}>
        <Form.Radio
          checked={field.value === (value !== undefined ? value : id)}
          required={required}
          label={label}
          name={name}
          id={id}
          disabled={disabled}
          error={
            hasTouched && meta.error
              ? hideErrorLabel
                ? true
                : meta.error
              : undefined
          }
          onChange={(e, { id }) =>
            helpers.setValue(value !== undefined ? value : id)
          }
        />
      </div>
    );
  }

  return (
    <Form.Radio
      checked={field.value === (value !== undefined ? value : id)}
      required={required}
      label={label}
      name={name}
      id={id}
      error={
        hasTouched && meta.error
          ? hideErrorLabel
            ? true
            : meta.error
          : undefined
      }
      onChange={(e, { id }) =>
        helpers.setValue(value !== undefined ? value : id)
      }
    />
  );
};

export default RadioButtonField;
