import React, { CSSProperties } from 'react';
import { useField, FieldValidator } from 'formik';
import { Form, Input } from 'semantic';
import { currencyToSymbol } from 'utils/formatting';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  validate?: FieldValidator;
  validateImmediately?: boolean;
  disabled?: boolean;
  hideErrorLabel?: boolean;
  wrapperStyle?: CSSProperties;
  min?: number;
  max?: number;
  currency?: string;
}

const PriceField: React.FC<Props> = ({
  name,
  label,
  currency = 'EUR',
  required,
  validate,
  validateImmediately,
  disabled,
  hideErrorLabel,
  wrapperStyle,
  min,
  max,
}) => {
  const [field, meta, helpers] = useField({ name, validate });

  const hasTouched = validateImmediately ? true : meta.touched;

  return (
    <div style={wrapperStyle}>
      <Form.Input
        label={label}
        error={
          hasTouched && meta.error
            ? hideErrorLabel
              ? true
              : meta.error
            : undefined
        }>
        <Input
          min={min}
          max={max}
          name={name}
          label={currencyToSymbol(currency)}
          role="input"
          aria-label={name}
          required={required}
          disabled={disabled}
          value={field.value}
          type="number"
          fluid
          onChange={(e, { value }) => {
            helpers.setValue(value, true);
          }}
          onBlur={() => helpers.setTouched(true)}
        />
      </Form.Input>
    </div>
  );
};

export default PriceField;
