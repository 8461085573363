import React, { useMemo } from 'react';
import { Grid, List } from 'semantic';
import SummaryLabel from 'components/tariffs/AdvancedTariff/SummaryLabel';
import { formatDate } from 'utils/date';
import { useTranslation } from 'react-i18next';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { SummaryGridRow } from 'components/tariffs/AdvancedTariff/SummaryRow';

export default function RestrictionsSummary({ value }) {
  const row = useRestrictionsSummaryRow(value);
  return <SummaryGridRow row={row.columns} />;
}

/**
 *
 * @param value
 * @return {{columns: {Header: React.ReactElement, Content: React.ReactElement}[], uiKey: number}}
 */
export function useRestrictionsSummaryRow(value = {}) {
  const uiKey = useMemo(() => Math.random(), []);
  const timeOfDayColumn = useTimeOfDayColumn(value);
  const dayOfWeekColumn = useDayOfWeekColumn(value);
  const dateRangeColumn = useDateRangeColumn(value);

  const advancedTariffFeatures = useAdvancedTariffFeatures();

  const columns = [timeOfDayColumn];

  if (advancedTariffFeatures.daysOfWeek) {
    columns.push(dayOfWeekColumn);
  }
  if (advancedTariffFeatures.dateRange) {
    columns.push(dateRangeColumn);
  }

  return { uiKey, columns };
}

function useTimeOfDayColumn(value) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);
  return {
    Header: (
      <Grid.Column key={`time-of-day-column-header-${uiKey}`}>
        <SummaryLabel>
          {t('advancedTariff.timeOfDay', 'Time of Day')}
        </SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`time-of-day-column-content-${uiKey}`}>
        <List>
          {value.startTime && (
            <List.Item>
              <TimeObjectText
                value={value?.startTime}
                label={t('advancedTariff.startTime', 'Start Time')}
              />
            </List.Item>
          )}
          {value.endTime && (
            <List.Item>
              <TimeObjectText
                value={value?.endTime}
                label={t('advancedTariff.endTime', 'End Time')}
              />
            </List.Item>
          )}
          {!value.startTime && !value.endTime && (
            <List.Item>
              <strong>{t('advancedTariff.allDay', 'All Day')}</strong>
            </List.Item>
          )}
        </List>
      </Grid.Column>
    ),
  };
}

/**
 *
 * @param {Object} value
 * @param {string[]} value.daysOfWeek
 * @return {{Header: React.ReactElement, Content: React.ReactElement}}
 */
function useDayOfWeekColumn(value) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);
  return {
    Header: (
      <Grid.Column key={`day-of-week-column-header-${uiKey}`}>
        <SummaryLabel>
          {t('advancedTariff.dayOfWeek', 'Day of Week')}
        </SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`day-of-week-column-content-${uiKey}`}>
        <List>
          <List.Item>
            <DaysOfWeekText value={value.daysOfWeek} />
          </List.Item>
        </List>
      </Grid.Column>
    ),
  };
}

/**
 *
 * @param {Object} value
 * @param {Object} value.startDate
 * @param {number} value.startDate.year
 * @param {number} value.startDate.month
 * @param {number} value.startDate.day
 * @param {Object} value.endDate
 * @param {number} value.endDate.year
 * @param {number} value.endDate.month
 * @param {number} value.endDate.day
 *
 * @return {{Header: React.JSX.Element, Content: React.JSX.Element}}
 */
function useDateRangeColumn(value) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);
  return {
    Header: (
      <Grid.Column key={`date-range-column-header-${uiKey}`}>
        <SummaryLabel>
          {t('advancedTariff.dateRange', 'Date Range')}
        </SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`date-range-column-content-${uiKey}`}>
        <List>
          {(value.startDate || value.endDate) && (
            <>
              <List.Item>
                <DateText
                  value={value?.startDate}
                  label={t('advancedTariff.startDate', 'Start Date')}
                />
              </List.Item>
              <List.Item>
                <DateText
                  value={value?.endDate}
                  label={t('advancedTariff.endDate', 'End Date')}
                />
              </List.Item>
            </>
          )}
          {!value.startDate && !value.endDate && (
            <List.Item>
              <strong>{t('advancedTariff.always', 'Always')}</strong>
            </List.Item>
          )}
        </List>
      </Grid.Column>
    ),
  };
}

function TimeObjectText({ label, value }) {
  const hour = value.hour || 0;
  const minute = value.minute || 0;
  const amPm = hour < 12 ? 'AM' : 'PM';
  let displayHour = hour % 12;
  if (displayHour === 0) displayHour = 12;
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        {displayHour.toString().padStart(2, '0')}:
        {minute.toString().padStart(2, '0')} {amPm}
      </span>
      &nbsp;
      {label}
    </>
  );
}

/**
 *
 * @param {string} label
 * @param {Object} value
 * @param {number} value.year
 * @param {number} value.month
 * @param {number} value.day
 * @return {React.ReactElement}
 * @constructor
 */
function DateText({ label, value }) {
  return (
    <>
      <span style={{ fontWeight: 'bold' }}>
        {dateObjToStringOmitYearIfCurrent(value)}
      </span>
      &nbsp;
      {label}
    </>
  );
}

/**
 *
 * @param {Object} dateObj
 * @param {number} dateObj.year
 * @param {number} dateObj.month
 * @param {number} dateObj.day
 * @return {*}
 */
function dateObjToStringOmitYearIfCurrent(dateObj) {
  const date = new Date(dateObj.year, dateObj.month, dateObj.day);
  const now = new Date();
  let formatted = formatDate(date);
  if (now.getFullYear() === date.getFullYear()) {
    formatted = formatted.replace(/[0-9]{4}$/, '');
  }
  return formatted;
}

const dayOrder = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

function dayOfWeekComparator(day1, day2) {
  day1 = day1.toLowerCase();
  day2 = day2.toLowerCase();
  if (dayOrder[day1] < dayOrder[day2]) {
    return -1;
  }
  if (dayOrder[day1] > dayOrder[day2]) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param value
 * @return {React.JSX.Element}
 * @constructor
 */
function DaysOfWeekText({ value }) {
  const { t } = useTranslation();
  const text = useMemo(() => {
    const daySet = new Set(value || []);
    if (!daySet?.size || daySet?.size === 7) {
      return t('advancedTariffs.everyDay', 'Every day');
    }
    if (daySet.size === 5 && !daySet.has('saturday') && !daySet.has('sunday')) {
      return t('advancedTariffs.weekdays', 'Weekdays');
    }
    if (daySet.size === 2 && daySet.has('saturday') && daySet.has('sunday')) {
      return t('advancedTariffs.weekends', 'Weekends');
    }
    const dayTranslator = (day) => t(`advancedTariff.{{day}}`, day, { day });
    let dayFormatter = (day) => `${day.charAt(0).toUpperCase()}${day.slice(1)}`;
    if (daySet.size > 2) {
      dayFormatter = (day) =>
        `${day.charAt(0).toUpperCase()}${day.slice(1, 3)}`;
    }
    return value
      .sort(dayOfWeekComparator)
      .map((day) => {
        return dayFormatter(dayTranslator(day));
      })
      .join(', ');
  }, [value, t]);
  return <strong>{text}</strong>;
}
