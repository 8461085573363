import React from 'react';
import { get, set, merge } from 'lodash-es';
import { Form, Modal, Button, Divider } from 'semantic';
import ErrorMessage from 'components/ErrorMessage';

import { withTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';

import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';

import modal from 'helpers/modal';

class GenerateEvseIdPrintBatch extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      evseIdPrintBatch: {
        numSubConnectors: 0,
      },
    };
  }

  onSubmit = async () => {
    this.setState({
      loading: true,
      error: null,
    });

    const { evseIdPrintBatch } = this.state;

    try {
      await request({
        method: 'POST',
        path: '/1/evse-ids/print-batches/generate',
        body: evseIdPrintBatch,
      });

      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  setField(name, value, nullable = false) {
    const parsedValue = value === '' && nullable ? null : value;
    this.setState({
      evseIdPrintBatch: merge(
        {},
        this.state.evseIdPrintBatch,
        set({}, name, parsedValue)
      ),
    });
  }

  toggleSetSubConnectors = (checked) => {
    const newState = { setSubConnectors: checked };

    if (!checked) {
      newState.evseIdPrintBatch = {
        ...this.state.evseIdPrintBatch,
        numSubConnectors: 0,
      };
    }

    this.setState(newState);
  };

  render() {
    const { t } = this.props;
    const { evseIdPrintBatch, loading, error } = this.state;

    return (
      <>
        <Modal.Header>
          {t('evseIdPrintBatch.title', 'Generate new batch')}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              autoComplete="disabled"
              error={Boolean(error)}
              onSubmit={this.onSubmit}
              id="generate-evse-id-print-batch-form">
              <ErrorMessage error={error} />

              <Form.Input
                required
                value={get(evseIdPrintBatch, 'size')}
                name="size"
                label={t(
                  'evseIdPrintBatch.size',
                  'Amount of EVSE IDs to be created'
                )}
                type="number"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
              <Divider />
              <Form.Checkbox
                checked={this.state.setSubConnectors}
                onChange={(e, { checked }) =>
                  this.toggleSetSubConnectors(checked)
                }
                label={t(
                  'evseIdPrintBatch.setSubConnectors',
                  'Create extra stickers for subconnectors'
                )}
              />
              {this.state.setSubConnectors && (
                <Form.Input
                  required
                  value={get(evseIdPrintBatch, 'numSubConnectors')}
                  name="numSubConnectors"
                  label={t(
                    'evseIdPrintBatch.numSubConnectors',
                    'Number of subconnectors per EVSE'
                  )}
                  type="number"
                  onChange={(e, { name, value }) => this.setField(name, value)}
                />
              )}
              <Divider />
              <Form.Input
                required
                value={get(evseIdPrintBatch, 'note')}
                name="note"
                label={t(
                  'evseIdPrintBatch.note',
                  "A note that describes the batch's purpose"
                )}
                type="text"
                onChange={(e, { name, value }) => this.setField(name, value)}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={loading}
            primary
            content={t('button.create', 'Generate')}
            form="generate-evse-id-print-batch-form"
          />
        </Modal.Actions>
      </>
    );
  }
}

export default withTranslation()(modal(GenerateEvseIdPrintBatch));
