import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';
import List from './List';

export default function EvseControllersLabels() {
  return (
    <AppWrapper>
      <Container>
        <List />
      </Container>
    </AppWrapper>
  );
}
