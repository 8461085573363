import React, { useState } from 'react';
import AppWrapper from 'components/AppWrapper';
import { Grid, Header, Form, Dropdown } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import Table from './DataTable';
import { useTranslation } from 'react-i18next';
import DataList from 'components/DataList';
import { request } from 'utils/api';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import DateField from 'components/form-fields/Date';
import styled from 'styled-components';
import ObjectIdInput from 'components/form-fields/ObjectIdInput';
import { omit } from 'lodash-es';
import { startOfDay, endOfDay } from 'date-fns';
import { useFeatures } from '../../contexts/features';

const StyledFormGroup = styled(Form.Group)`
  &&& {
    flex-direction: row !important;
    align-items: center !important;
    margin-bottom: 0;

    @media only screen and (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
    }

    .divider {
      display: block !important;
      flex: 0 1 auto;
    }

    .field,
    .layout-group {
      flex: 1 0 auto;
    }

    .ui.input.input.input {
      input {
        width: 100px; // arbitrary small value seems to fix sizing issues
      }
    }
  }
`;

const StyledForm = styled(Form)`
  &&& {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(min(310px, 100%), 1fr));

    .field {
      margin-bottom: 0 !important;
    }
  }
`;

const itemLimit = 100;

const requestMethods = ['POST', 'PATCH', 'DELETE'];

export default function AuditTrailWrapper() {
  const { t } = useTranslation();
  const [dataKey, setDataKey] = useState(Date.now());
  const [filter, setFilter] = useState({});

  const setFilterValue = (key, value) => {
    setDataKey(Date.now());
    setFilter(value ? { ...filter, [key]: value } : { ...omit(filter, key) });
  };

  const handleRequest = (filters = {}) => {
    return request({
      method: 'POST',
      path: `/1/audit-entries/search`,
      body: { ...filters, limit: itemLimit, ...filter },
    });
  };

  return (
    <AppWrapper>
      <Breadcrumbs active={t('auditTrail.title', 'Audit Trail')} />
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              {t('auditTrail.title', 'Audit Trail')}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <StyledForm>
              <Form.Field className="date-range">
                <label>Created At</label>
                <StyledFormGroup>
                  <DateField
                    name="from"
                    value={filter.dateFrom || ''}
                    placeholder="No Start"
                    onChange={(evt, { value }) =>
                      setFilterValue('dateFrom', value && startOfDay(value))
                    }
                    clearable
                  />
                  <span className="divider">&ndash;</span>
                  <DateField
                    name="to"
                    value={filter.dateTo || ''}
                    placeholder="No End"
                    onChange={(evt, { value }) =>
                      setFilterValue('dateTo', value && endOfDay(value))
                    }
                    clearable
                  />
                </StyledFormGroup>
              </Form.Field>
              <SearchDropdown
                keywordField="searchPhrase"
                value={filter.user || ''}
                placeholder="Filter by user"
                label="User"
                clearable
                objectMode={false}
                getOptionLabel={(item) => `${item.name}`}
                onDataNeeded={(body) =>
                  request({
                    path: '/1/users/search/fast',
                    method: 'POST',
                    body,
                  })
                }
                onChange={(e, { value }) => setFilterValue('user', value)}
              />
              <SearchDropdown
                value={filter.account || ''}
                placeholder="Filter by account"
                label="Account"
                clearable
                objectMode={false}
                getOptionLabel={(item) => `${item.name}`}
                onDataNeeded={(body) =>
                  request({ path: '/1/accounts/search', method: 'POST', body })
                }
                onChange={(e, { value }) => setFilterValue('account', value)}
              />
              <SearchDropdown
                value={filter.provider || ''}
                placeholder="Filter by provider"
                label="Provider"
                clearable
                objectMode={false}
                getOptionLabel={(item) => `${item.name}`}
                onDataNeeded={(body) =>
                  request({ path: '/1/providers/search', method: 'POST', body })
                }
                onChange={(e, { value }) => setFilterValue('provider', value)}
              />
              <SearchDropdown
                value={filter.objectType || ''}
                placeholder="Filter by object type"
                label="Object Type"
                clearable
                objectMode={false}
                getOptionLabel={(item) => `${item.id}`}
                onDataNeeded={(body) =>
                  request({
                    path: '/1/audit-entries/objectTypes',
                    method: 'GET',
                    body,
                  })
                }
                onChange={(e, { value }) => setFilterValue('objectType', value)}
              />
              <ObjectIdInput
                value={filter.objectId || ''}
                label="Object Id"
                placeholder="Filter by object id"
                onChange={(e, { value }) => setFilterValue('objectId', value)}
                clearable
              />
              <Form.Field>
                <label>Request Method</label>
                <Dropdown
                  value={filter.requestMethod || ''}
                  placeholder="Filter by request method"
                  options={requestMethods.map((method) => ({
                    key: method,
                    text: method,
                    value: method,
                  }))}
                  clearable
                  selection
                  onChange={(e, { value }) =>
                    setFilterValue('requestMethod', value)
                  }
                />
              </Form.Field>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <DataList key={dataKey} limit={itemLimit} onRequest={handleRequest} live>
        <Table />
      </DataList>
    </AppWrapper>
  );
}
