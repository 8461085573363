import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Detail from './Detail';
import { AppWrapper } from 'components';
import { Container } from 'semantic';

export default function Accounts() {
  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route path="/accounts" component={List} exact />
          <Route path="/accounts/:id" component={Detail} />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
