import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PaymentMethods from './PaymentMethods';
import {
  ProfileSettings,
  PersonalBilling,
  AccountBilling,
  PersonalReimbursement,
  ThemeSwitch,
  Notifications as PersonalNotifications,
  PasswordAndAuthentication,
} from './screens/personal';
import {
  InfoAndContact,
  Billing,
  OrganizationReimbursement,
  SuboperatorSettings,
  SingleSignOnSAMLForm,
} from './screens/organization';
import TwoFactorAuthenticator from './screens/organization/TwoFactorAuthentication/TwoFactorAuthenticator';
import TwoFactorSms from './screens/organization/TwoFactorAuthentication/TwoFactorSms';

export default function Settings() {
  return (
    <Switch>
      <Route path="/settings" exact component={ProfileSettings} />
      <Route
        exact
        path="/settings/personal/profile"
        component={ProfileSettings}
      />
      {/* TODO: to remove this route after migrating user billing info to account billing */}
      <Route
        exact
        path="/settings/personal/billing"
        component={PersonalBilling}
      />
      <Route
        exact
        path="/settings/personal/appearance"
        component={ThemeSwitch}
      />
      <Route
        exact
        path="/settings/personal/notifications"
        component={PersonalNotifications}
      />
      <Route
        exact
        path="/settings/personal/reimbursement"
        component={PersonalReimbursement}
      />
      <Route
        exact
        path="/settings/personal/password-and-authentication"
        component={PasswordAndAuthentication}
      />
      <Route
        exact
        path="/settings/account/individual/billing"
        component={AccountBilling}
      />
      <Route
        exact
        path="/settings/account/organization/info-and-contact"
        component={InfoAndContact}
      />
      <Route
        exact
        path="/settings/account/organization/billing"
        component={Billing}
      />
      <Route
        exact
        path="/settings/account/organization/reimbursement"
        component={OrganizationReimbursement}
      />
      <Route
        exact
        path="/settings/account/organization/suboperator"
        component={SuboperatorSettings}
      />
      <Route
        exact
        path="/settings/account/organization/mfa-sms"
        component={TwoFactorSms}
      />
      <Route
        exact
        path="/settings/account/organization/mfa-authenticator"
        component={TwoFactorAuthenticator}
      />
      <Route
        exact
        path="/settings/account/organization/sso/saml"
        component={SingleSignOnSAMLForm}
      />
      <Route exact path="/settings/*" component={ProfileSettings} />
    </Switch>
  );
}
