export const extractEventPayload = (data, targetEventId) => {
  return data?.find((event) => event._id === targetEventId)?.Payload?.document;
};

export const mapOperationType = (operationType: string) => {
  switch (operationType) {
    case 'OPERATION_TYPE_INSERT':
      return 'Insert';
    case 'OPERATION_TYPE_UPDATE':
      return 'Update';
    case 'OPERATION_TYPE_DELETE':
      return 'Delete';
    case 'OPERATION_TYPE_SEED':
      return 'Seed';
    case 'OPERATION_TYPE_REPLACE':
      return 'Replace';
    case 'OPERATION_TYPE_UNSPECIFIED':
      return 'Unspecified';
    default:
      return 'Unkown';
  }
};
