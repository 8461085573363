import React from 'react';
import { request } from 'utils/api';
import HelpTip from 'components/HelpTip';
import {
  Segment,
  Form,
  Divider,
  Message,
  Loader,
  Progress,
  Input,
  Label,
  Dropdown,
  Button,
} from 'semantic';
import { isString } from 'lodash';

import {
  sendGetConfigurationCommandAndWaitForResult,
  saveConfigurationAndWait,
  configurationListToHash,
  getBooleanOptionsForValue,
  isValidOcppBoolean,
  forbiddenConfigurationKeysForMaintenance,
} from 'utils/evse-commands';

import { determineCoordinatorStatus } from 'utils/evse-controllers';

function formatAccessibility(accessibility) {
  if (accessibility === 'read') {
    return 'Read Only';
  }
  if (accessibility === 'write') {
    return 'Write Only';
  }
  if (accessibility === 'read-write') {
    return 'Read/Write';
  }
  return 'Unknown';
}

export default class Commands extends React.Component {
  state = {
    configurationLoading: true,
    configurationLoadingProgress: 0,
    configurationLoadingMessage: '',
    configuration: [],
    formValues: {},
    error: null,
  };

  componentDidMount() {
    this.fetch(this.props.evseController.id);
    const interval = 15_000; // 15 seconds
    this.interval = setInterval(() => {
      this.update(this.props.evseController.id);
    }, interval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetch = (itemId) => {
    request({
      method: 'GET',
      path: `/1/evse-controllers/${itemId}`,
    })
      .then(({ data }) => {
        const status = determineCoordinatorStatus(data);

        this.setState({
          status,
          loading: false,
        });

        this.fetchProtocol();
        this.fetchConfiguration(data);
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  fetchProtocol() {
    request({
      method: 'GET',
      path: `/1/ocpp/1.6/protocol`,
    })
      .then(({ data }) => {
        this.setState({
          protocol: data,
        });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  }

  update = (itemId) => {
    request({
      method: 'GET',
      path: `/1/evse-controllers/${itemId}`,
    })
      .then(({ data }) => {
        const status = determineCoordinatorStatus(data);
        if (JSON.stringify(this.state.status) !== JSON.stringify(status)) {
          this.setState({
            status,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  fetchConfiguration(evseController) {
    const status = determineCoordinatorStatus(evseController);
    this.setState({
      configuration: [],
      configurationLoading: true,
      configurationLoadingMessage: 'Loading configuration from device',
      configurationLoadingProgress: 40,
      error: null,
    });
    if (status.state === 'disconnected') {
      this.setState({
        configurationLoading: false,
      });
      return;
    }

    sendGetConfigurationCommandAndWaitForResult(
      evseController.id,
      (configurationLoadingProgress) => {
        this.setState({ configurationLoadingProgress });
      }
    )
      .then((configuration) => {
        const formValues = configurationListToHash(configuration);
        this.setState({
          formValues,
          configuration,
          configurationLoading: false,
          configurationLoadingProgress: 90,
        });
      })
      .catch((error) => {
        this.setState({
          configurationLoading: false,
          error,
        });
      });
  }

  save() {
    const { evseController } = this.props;
    const { formValues, configuration, protocol } = this.state;

    const validationError = this.validateForm(formValues, protocol);
    if (validationError) {
      this.setState({
        configurationLoading: false,
        error: validationError,
      });
      return;
    }

    this.setState({
      configurationLoading: true,
      configurationLoadingMessage: 'Saving configuration to device',
      configurationLoadingProgress: 20,
      error: null,
    });
    const readOnlyKeys = configuration
      .filter((field) => field.readonly)
      .map((field) => field.key);
    saveConfigurationAndWait(
      evseController.id,
      configuration,
      formValues,
      readOnlyKeys
    )
      .then(() => {
        this.fetchConfiguration(evseController);
      })
      .catch((error) => {
        this.setState({
          configurationLoading: false,
          error,
        });
      });
  }

  validateForm(formValues, protocol) {
    const { CONFIGURATION_KEYS } = protocol;
    const keys = Object.keys(formValues);
    for (const key of keys) {
      const info = CONFIGURATION_KEYS[key];
      if (!info) continue;
      const { type, accessibility } = info;
      if (accessibility === 'read') continue;
      const value = formValues[key];

      if (
        type === 'Number' &&
        value &&
        isString(value) &&
        !value.match(/^[\d\.]+$/)
      ) {
        return new Error(
          `Invalid value for configuration ${key}, expected a number`
        );
      }

      if (type === 'Boolean' && !isValidOcppBoolean(value)) {
        return new Error(
          `Invalid value for configuration ${key}, expected a boolean (True or False)`
        );
      }
      /*
      // This gives some issues for Phoenix chargers
      if (type === 'List' && value && value.match(/,\s+/)) {
        return new Error(
          `Invalid value for configuration ${key}, expected no whitespace after commas`
        );
      }
      */
    }
    return undefined;
  }

  setField(key, value) {
    const { formValues } = this.state;
    formValues[key] = value;
    this.setState({ formValues });
  }

  render() {
    const { status, protocol } = this.state;
    if (!status || !protocol) return <Loader active />;
    const { error } = this.state;
    return (
      <div>
        <p style={{ float: 'right' }}>
          Connection Status: <Label {...status} />
        </p>
        <Divider hidden />
        <Segment style={{ clear: 'both' }}>
          {error && <Message error content={error.message} />}
          {this.renderConfiguration()}
        </Segment>
      </div>
    );
  }

  renderConfiguration() {
    const {
      configuration,
      configurationLoading,
      configurationLoadingMessage,
      configurationLoadingProgress,
    } = this.state;
    if (configurationLoading)
      return (
        <Progress
          percent={configurationLoadingProgress}
          label={configurationLoadingMessage}
          active
        />
      );
    return configuration.length ? (
      this.renderForm(configuration)
    ) : (
      <Message content="No configuration fetched yet" />
    );
  }

  addField() {
    const key = prompt("What's the key for this field?");
    const { configuration, formValues } = this.state;
    configuration.push({
      key,
      value: '',
    });
    formValues[key] = '';
    this.setState({ configuration, formValues });
  }

  renderField(field) {
    const { protocol, formValues } = this.state;
    const { CONFIGURATION_KEYS } = protocol;
    const info = CONFIGURATION_KEYS[field.key];
    let readOnly = field.readonly;
    if (
      this.props.maintainerMode &&
      forbiddenConfigurationKeysForMaintenance.includes(field.key)
    ) {
      readOnly = true;
    }

    return (
      <Form.Field>
        <label>
          {field.key}{' '}
          {info && (
            <HelpTip
              title={`${field.key} Information`}
              text={
                <>
                  <p>{info.description}</p>
                  <p>Profile: {info.profile}</p>
                  <p>Type: {info.type}</p>
                  {info.unit && <p>Unit: {info.unit}</p>}
                  <p>
                    Chargepoint Accessibility:{' '}
                    {formatAccessibility(info.accessibility)}
                  </p>
                  <p>
                    Chargepoint Implementation:{' '}
                    {info.required ? 'Required' : 'Optional'}
                  </p>
                </>
              }
            />
          )}
        </label>
        {this.renderInput(field, formValues[field.key], readOnly, info)}
      </Form.Field>
    );
  }

  renderInput(field, value, readOnly, info) {
    if (info && info.type === 'Boolean') {
      const options = getBooleanOptionsForValue(value || '').map((key) => {
        return {
          value: key,
          key,
          text: key,
        };
      });

      return (
        <Dropdown
          selection
          readOnly={readOnly}
          name={field.key}
          value={value}
          options={options}
          onChange={(e, { name, value }) => {
            this.setField(name, value);
          }}
        />
      );
    }
    return (
      <Input
        key={field.key}
        type="text"
        name={field.key}
        value={value}
        readOnly={readOnly}
        onChange={(e, { name, value }) => {
          this.setField(name, value);
        }}
        labelPosition={info && info.unit ? 'right' : undefined}>
        <input
          style={{
            backgroundColor: readOnly ? 'rgba(192,192,192,.25)' : undefined,
          }}
        />
        {info && info.unit && <Label basic>{info.unit}</Label>}
      </Input>
    );
  }

  renderForm(configuration) {
    const { status } = this.state;
    return (
      <Form onSubmit={() => this.save()}>
        {configuration.map((field) => {
          return this.renderField(field);
        })}
        <Button
          primary
          content="Save"
          submit
          disabled={status.state === 'disconnected'}
          style={{ float: 'left' }}
        />
        <a
          onClick={() => this.addField()}
          style={{
            float: 'left',
            marginTop: '8px',
            marginLeft: '8px',
            cursor: 'pointer',
          }}>
          Add Field
        </a>

        <div style={{ clear: 'both' }} />
      </Form>
    );
  }
}
