import React, { useRef } from 'react';
import { request } from 'utils/api';
import { Container, Header, Divider } from 'semantic';
import { useTranslation } from 'react-i18next';
import Table from 'screens/Invoices/List/Table';
import { Search } from 'components';
import { useFeatures } from 'contexts/features';

const AccountInvoices = ({ account }) => {
  const { t } = useTranslation();
  const searchRef = useRef();
  const { hasFeature } = useFeatures();
  const isFastSearch = hasFeature('fast_search_invoices');

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: `/1/invoices/search${isFastSearch ? '/fast' : ''}`,
      body: {
        accountId: account.id,
        ...filters,
      },
    });
  };

  return (
    <Container>
      <Header as="h2">{t('accountInvoices.title', 'Invoices')}</Header>

      <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </Search.Provider>

      <Divider hidden />
    </Container>
  );
};

export default AccountInvoices;
