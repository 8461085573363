import React from 'react';

import { Table, Label } from 'semantic';
import { Link } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InvoiceStatusLabel from 'components/InvoiceStatusLabel';
import InvoiceTypeLabel from 'components/InvoiceTypeLabel';
import NetSuiteSyncStatus from '../../../components/NetSuiteSyncStatus';
import Currency from 'components/Currency';

InvoiceDetailTable.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default function InvoiceDetailTable({ invoice }) {
  const { t } = useTranslation();

  const totalAmount =
    invoice.billingEngineVersion === 2
      ? invoice.type === 'cpo-credit'
        ? invoice.bookableCreditAmountWithVat
        : invoice.bookableAmountWithVat
      : invoice.type === 'cpo-credit'
        ? invoice.totalCreditAmountWithVat
        : invoice.totalAmountWithVat;

  return (
    <Table definition>
      <Table.Body>
        <Table.Row>
          <Table.Cell>ID</Table.Cell>
          <Table.Cell>{invoice.identifier}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Account</Table.Cell>
          <Table.Cell>
            {invoice.account ? (
              <>
                <Link to={`/accounts/${invoice.account.id}`}>
                  {invoice.account.name}
                </Link>
                {invoice.user && (
                  <p>
                    <small>
                      (
                      <Link to={`/accounts/users/${invoice.user.id}`}>
                        {invoice.user.name}
                      </Link>
                      )
                    </small>
                  </p>
                )}
              </>
            ) : (
              <span>{t('invoices.noAccount', 'No Account')}</span>
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Date</Table.Cell>
          <Table.Cell>
            {invoice.year} / {invoice.month}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Type</Table.Cell>
          <Table.Cell>
            <InvoiceTypeLabel invoice={invoice} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Price</Table.Cell>
          <Table.Cell>
            <Currency currency={invoice.currency} value={totalAmount} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Status</Table.Cell>
          <Table.Cell>
            <InvoiceStatusLabel invoice={invoice} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>ERP</Table.Cell>
          <Table.Cell>
            {invoice.exactReferences && !!invoice.exactReferences.length && (
              <Label
                as={'a'}
                color="olive"
                title="Exact"
                icon="database"
                content={`${invoice.exactReferences.length}`}
                rel="noopener"
                target="_blank"
                href={`https://start.exactonline.nl/docs/DocEdit.aspx?FilterDocumentTypes=1&_Division_=1926660&ID=${
                  invoice.exactReferences[invoice.exactReferences.length - 1]
                    .documentId
                }`}
              />
            )}
            <NetSuiteSyncStatus
              invoiceId={invoice?.id}
              status={invoice?.netSuiteStatus}
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
