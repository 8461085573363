import React from 'react';
import { Button, Segment, Header, Form, Message } from 'semantic';
import { request } from 'utils/api';

import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import { withRouter } from 'react-router-dom';

import { UserContext } from 'contexts/user';
import { withTranslation } from 'react-i18next';

class Finalize extends React.Component {
  static contextType = UserContext;

  state = {
    loading: false,
    error: null,
    savedCodes: false,
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
      touched: true,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/mfa/enable',
        body: this.props.requestBody,
      });
      await this.context.fetchUser();
      this.props.history.push(this.props.successfulHistoryPushUrl);
    } catch (error) {
      if (error.status == 403) {
        this.props.history.push('/confirm-access?to=/settings/mfa-sms');
        return;
      }

      this.setState({
        error,
        loading: false,
      });
    }
  };

  getDownloadLink(text) {
    const data = new Blob([text], { type: 'text/plain' });
    return window.URL.createObjectURL(data);
  }

  render() {
    const { loading, error } = this.state;
    const { t } = this.props;

    const codesStr = this.props.codes.reduce((str, current, index) => {
      return str + current + (index % 2 ? '\n' : '     ');
    }, '');

    return (
      <PageCenter>
        <LogoTitle title="Save your recovery codes" />

        <Segment.Group>
          <Segment>
            <Header size="small">
              {t(
                'settingsSecurityFinalize.header',
                'Two-factor recovery codes'
              )}
            </Header>
            <p>
              {t(
                'settingsSecurityFinalize.body',
                'Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor authentication codes.'
              )}
            </p>
          </Segment>

          {error && <Message error content={error.message} />}

          <Segment>
            <Button
              as={'a'}
              download="backup-codes.txt"
              href={this.getDownloadLink(codesStr)}
              basic>
              {t('common.download', 'Download')}
            </Button>
            <Button
              basic
              onClick={() => navigator.clipboard.writeText(codesStr)}>
              Copy
            </Button>
            <div
              style={{
                padding: '0.5em',
                marginTop: '0.5em',
              }}>
              <pre
                style={{
                  fontSize: '18px',
                  borderRadius: '3px',
                  padding: '1em',
                  textAlign: 'center',
                }}>
                {codesStr}
              </pre>
            </div>
          </Segment>
          <Segment>
            <Form>
              <Form.Checkbox
                checked={this.state.savedCodes}
                label={t(
                  'settingsSecurityFinalize.labelSavedBackup',
                  'I saved my backup codes.'
                )}
                onChange={(e, { checked }) =>
                  this.setState({ savedCodes: checked })
                }
              />
              {this.props.method !== 'sms' && (
                <Message warning>
                  {t(
                    'settingsSecurityFinalize.iUnderstand',
                    'I understand that {{providerName}} cannot give me access to my account if I lose my backup codes and access to my authentication methods.',
                    {
                      providerName: this.context.provider.name,
                    }
                  )}
                </Message>
              )}
              {this.props.method === 'sms' && (
                <Message warning>
                  {t(
                    'settingsSecurityFinalize.keepSave',
                    'Put these in a safe spot. If you lose your device and don’t have the recovery codes you will lose access to your account.'
                  )}
                </Message>
              )}
            </Form>
          </Segment>

          <Segment>
            <Button
              onClick={this.onSubmit}
              primary
              loading={loading}
              disabled={!this.state.savedCodes || loading}
              content={t(
                'settingsSecurityFinalize.enable',
                'Enable two-factor authentication'
              )}
            />
          </Segment>
        </Segment.Group>
      </PageCenter>
    );
  }
}

export default withRouter(withTranslation()(Finalize));
