import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { Divider, Header, Grid } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

import Table from './Table';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default () => {
  const { t } = useTranslation();
  return (
    <AppWrapper>
      <Breadcrumbs
        path={[
          <Link key="charging-stations" to="/charging-stations">
            {t('menu.cpoChargeStations', 'Charging stations')}
          </Link>,
        ]}
        active={t('evseIssues.title', 'EVSE Controllers Issues')}
      />
      <Grid>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            {t('evseIssues.title', 'EVSE Controllers Issues')}
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      {/** <Menu /> **/}
      <Divider hidden />
      <Table />
    </AppWrapper>
  );
};
