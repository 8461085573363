import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Segment, Divider, Button, Icon } from 'semantic';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { request } from 'utils/api';
import { Layout, ListHeader, Search, SearchFilters } from 'components';
import { CreateTokenBatch } from '../../../components/modals/CreateTokenBatch';
import { Breadcrumbs } from '../../../components';

import { currentUserCanAccessProviderEndpoint } from '../../../utils/roles';
import Table from './BatchesTable';
import ImportTokenBatch from 'components/modals/ImportTokenBatch';
import { useUser } from 'contexts/user';

const staticFilterMapping = {
  keywords: {
    type: 'search',
  },
  createdAt: {
    label: 'Created at',
    type: 'date',
    range: true,
  },
};

export default function TokenBatches() {
  const { t } = useTranslation();
  const searchRef = useRef();
  const user = useUser();

  const onDataNeeded = useCallback(async (filters) => {
    return request({
      method: 'POST',
      path: '/1/tokens/batch/search',
      body: filters,
    });
  }, []);

  const translations = useMemo(
    () => ({
      title: t('cards.token-batches.title', 'Token Batches'),
      hasErrors: t('cards.token-batches.showErrors', 'Show Errors'),
      createdAt: t('cards.token-batches.createdAt', 'Created at'),
      searchPlaceholder: t('cards.token-batches.filterPlaceHolder', 'Search'),
    }),
    [t]
  );

  const disableCreateBatchButton = !currentUserCanAccessProviderEndpoint(
    'tokenBatches',
    'read-write'
  );

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="cards" to="/cards">
            {t('cards.title', 'Cards')}
          </Link>,
        ]}
        active={t('cards.token-batches.title', 'Token Batches')}
      />
      <Search.Provider
        ref={searchRef}
        onDataNeeded={onDataNeeded}
        filterMapping={staticFilterMapping}>
        {() => (
          <>
            <ListHeader title={translations.title}>
              <CreateTokenBatch
                trigger={
                  <Button
                    primary
                    disabled={disableCreateBatchButton}
                    style={{ marginTop: '1px' }}
                    content={t('tokenBatches.new', 'Create New Batch')}
                    icon="plus"
                    as="button"
                  />
                }
                onSave={searchRef.current?.reload}
              />
              {user.isSuperAdmin() && (
                <ImportTokenBatch
                  onClose={searchRef.current?.reload}
                  trigger={
                    <Button
                      primary
                      content={t(
                        'cards.token-batches.import-batch',
                        'Import Batch'
                      )}
                      as="button"
                    />
                  }
                />
              )}
            </ListHeader>

            <Segment>
              <Layout horizontal spread stackable>
                <SearchFilters.Modal>
                  <Divider />
                  <SearchFilters.DateRange
                    label={translations.createdAt}
                    name="createdAt"
                  />
                </SearchFilters.Modal>

                <Layout horizontal stackable center right>
                  <Search.Total />
                  <SearchFilters.Search
                    name="keywords"
                    placeholder={translations.searchPlaceholder}
                  />
                </Layout>
              </Layout>
            </Segment>

            <Search.Status noResults={t('common.noResults', 'No Results')} />
            <Table />
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        )}
      </Search.Provider>
    </>
  );
}
