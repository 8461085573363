import React from 'react';
import { useField, FieldValidator, FieldArray } from 'formik';
import { Button, Form, Popup, Table } from 'semantic';
import { get } from 'lodash-es';
import SelectField, {
  SelectFieldOption,
} from 'components/form-fields/formik/SelectField';
import { useTranslation } from 'react-i18next';
import InputField from 'components/form-fields/formik/InputField';
import { useProvider } from 'screens/Auth/SignupV2/useProvider';

interface Props {
  name: string;
  label?: string;
  validate?: FieldValidator;
  localAttributes: SelectFieldOption[];
}

const SAMLAttributeMappingField: React.FC<Props> = ({
  name,
  validate,
  label,
  localAttributes,
}) => {
  const { t } = useTranslation();
  const provider = useProvider();
  const [field, meta, helpers] = useField({ name, validate });
  return (
    <Form.Field>
      <br />
      <p>
        {t(
          'samlattributemappingfield.description',
          `Configure how attributes from your SAML provider will be mapped to attributes within your ${provider.name} user.`
        )}
      </p>
      {label && <label>{label}</label>}
      <FieldArray
        name={name}
        render={(helpers) => {
          const values = get(helpers.form.values, name);
          return (
            <>
              <Table style={{ tableLayout: 'fixed' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>
                      {t(
                        'samlattributemappingfield.localAttributeColumnName',
                        'User attribute'
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      {t(
                        'samlattributemappingfield.remoteAttributeColumnName',
                        'SAML provider attribute'
                      )}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {values?.map((price: any, i: number) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <SelectField
                          options={localAttributes}
                          placeholder={t(
                            'samlattributemappingfield.selectPlaceholder',
                            'Select attribute'
                          )}
                          name={`${name}.${i}.localAttribute`}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <InputField
                          name={`${name}.${i}.remoteAttribute`}
                          type="text"
                          min={0}
                          hideErrorLabel
                        />
                      </Table.Cell>
                      <Table.HeaderCell>
                        <Popup
                          content={t(
                            'samlattributemappingfield.removeAttribute',
                            'Remove attribute'
                          )}
                          trigger={
                            <Button
                              icon="trash"
                              role="button"
                              onClick={() => helpers.remove(i)}
                            />
                          }
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div style={{ float: 'right' }}>
                <Button
                  as="button"
                  icon="plus"
                  role="button"
                  label={t('samlattributemappingfield.add', 'Add attribute')}
                  onClick={() =>
                    helpers.push({
                      localAttribute: '',
                      remoteAttribute: '',
                    })
                  }
                />
              </div>
            </>
          );
        }}
      />
    </Form.Field>
  );
};

export default SAMLAttributeMappingField;
