export function getNotifyEvseRecipientOptions(t) {
  return [
    {
      value: 'evseOwner',
      text: t('settingsNotifications.evseOwner', 'Charge station owner'),
    },
    {
      value: 'fieldServiceTechnician',
      text: t(
        'settingsNotifications.fieldServiceTechnician',
        'Field Service Technician'
      ),
    },
  ];
}
