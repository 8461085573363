import React from 'react';
import { FormikProps } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Message } from 'semantic';
import { ValidationError, ValidationErrorResponse } from 'types/api';
import { AccountBillingValues } from './formData';

export class AccountBillingError extends Error {
  constructor(
    public type: 'billing',
    public innerError: ValidationErrorResponse
  ) {
    super('Account billing request failed!');
  }

  static Billing(innerError: ValidationErrorResponse) {
    return new AccountBillingError('billing', innerError);
  }
}

type AccountBillingErrorMessageProps = {
  error: AccountBillingError | null;
  onDismiss?: () => void;
};

export function AccountBillingErrorMessage(
  props: AccountBillingErrorMessageProps
) {
  const { t } = useTranslation();
  if (!props.error) {
    return null;
  }
  return (
    <Message
      error
      onDismiss={props.onDismiss}
      content={t(
        'addAccountBillingModal.billingError',
        'There was a problem updating your billing info'
      )}
    />
  );
}

export function useAccountBillingErrorFormatter() {
  const { t } = useTranslation();

  return useCallback(
    (
      error: ValidationError,
      formikProps: FormikProps<AccountBillingValues> | null
    ) => {
      if (!formikProps) {
        return;
      }
      switch (error.code) {
        case 'SIGNUP_INVALID_IBAN':
          formikProps.setFieldError(
            'billing.ibanNumber',
            t('updateBillingInfo.errors.invalidIban', 'Invalid IBAN number')
          );
          break;
        case 'SIGNUP_INVALID_BIC':
          formikProps.setFieldError(
            'billing.bicNumber',
            t('updateBillingInfo.errors.invalidBic', 'Invalid BIC number')
          );
          break;
        case 'SIGNUP_INVALID_POSTAL_CODE_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.postalCode',
            t(
              'updateBillingInfo.errors.invalidPostalCode',
              'Invalid postal code'
            )
          );
          break;
        case 'SIGNUP_INVALID_COUNTRY':
          formikProps.setFieldError(
            'billing.countryCode',
            t(
              'updateBillingInfo.errors.invalidCountryCode',
              'Please select a supported country'
            )
          );
          break;
        case 'SIGNUP_VAT_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.vatNumber',
            t(
              'updateBillingInfo.errors.invalidVatNumber',
              'Invalid for the selected country'
            )
          );
          break;
        case 'SIGNUP_COC_INVALID_FOR_COUNTRY':
          formikProps.setFieldError(
            'billing.chamberOfCommerceNumber',
            t(
              'updateBillingInfo.errors.invalidVatNumber',
              'Invalid for the selected country'
            )
          );
          break;
        default:
          console.error('Unhandled request error', error);
      }
    },
    [t]
  );
}
