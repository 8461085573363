import React, { useEffect, useMemo, useState } from 'react';
import { Form, Header, Loader, Message, Segment, Button } from 'semantic';
import { request } from 'utils/api';
import QRCode from 'components/QRcode';
import { Layout } from 'components/Layout';
import PageCenter from 'components/PageCenter';
import { Link, useHistory } from 'react-router-dom';
import LogoTitle from 'components/LogoTitle';
import Code from 'components/form-fields/Code';
import Finalize from './Finalize';
import { Trans, useTranslation } from 'react-i18next';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

export default function Authenticator() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);
  useEffect(() => () => queryClient.clear(), []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticatorContainer />
    </QueryClientProvider>
  );
}

function AuthenticatorContainer() {
  const { t } = useTranslation();
  const history = useHistory();

  const [insertedCode, setInsertedCode] = useState('');

  const secretQuery = useQuery(
    ['/1/mfa/setup'],
    async () => {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/setup',
        body: {
          method: 'otp',
        },
      });

      return data;
    },
    {
      onError: (error: any) => {
        if (error.status == 403) {
          history.push(
            '/confirm-access?to=/settings/account/organization/mfa-authenticator'
          );
          return;
        }
      },
      staleTime: Infinity,
    }
  );

  const secret = secretQuery.data?.secret;
  const secretUri = secretQuery.data?.uri;

  const getBackupCodesQuery = useQuery(
    ['/1/mfa/generate-backup-codes'],
    async () => {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/generate-backup-codes',
      });

      return data;
    },
    {
      // Set enabled to false to prevent the query from running on mount
      enabled: false,
      staleTime: Infinity,
    }
  );

  const onSubmitMutation = useMutation(
    async (code: string) => {
      await request({
        method: 'POST',
        path: '/1/mfa/check-code',
        body: {
          code,
          secret,
          method: 'otp',
        },
      });
    },
    {
      onSuccess: () => {
        getBackupCodesQuery.refetch();
      },
    }
  );

  const submitCode = async (code: string) => {
    try {
      await onSubmitMutation.mutateAsync(code);
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async () => {
    await submitCode(insertedCode);
  };

  const verified =
    onSubmitMutation.isSuccess &&
    getBackupCodesQuery.isSuccess &&
    getBackupCodesQuery.data;

  if (verified) {
    return (
      <Finalize
        codes={getBackupCodesQuery.data}
        successfulHistoryPushUrl="/settings/personal/password-and-authentication"
        requestBody={{
          secret,
          method: 'otp',
          backupCodes: getBackupCodesQuery.data,
        }}
      />
    );
  }

  const isLoading = onSubmitMutation.isLoading || secretQuery.isLoading;
  const isError = onSubmitMutation.isError || secretQuery.isError;
  const errorMessage =
    (onSubmitMutation.error as any)?.message || secretQuery.error?.message;
  return (
    <PageCenter>
      <LogoTitle
        title={t('settingsSecurityApp.header', 'Set up app authentication')}
      />

      <Segment.Group>
        <Segment>
          <Header size="small">
            {t('settingsSecurityApp.step1', '1. Download an authenticator app')}
          </Header>
          <p>
            <Trans i18nKey="settingsSecurityApp.step1body">
              We recommend{' '}
              <a
                rel="noopener"
                target="_blank"
                href="https://support.google.com/accounts/answer/1066447">
                Google Authenticator
              </a>
              ,{' '}
              <a
                rel="noopener"
                target="_blank"
                href="https://support.1password.com/one-time-passwords/">
                1Password
              </a>
              , or{' '}
              <a rel="noopener" target="_blank" href="https://authy.com/">
                Authy.
              </a>
            </Trans>
          </p>
        </Segment>
        <Segment style={{ minHeight: '276px' }}>
          <Header size="small">
            {t(
              'settingsSecurityApp.step2',
              '2. Scan this barcode using your authenticator app'
            )}
          </Header>
          <Layout center>
            {!secretUri && <Loader active />}

            {secretUri && (
              <QRCode
                style={{ display: 'block', marginTop: '1em' }}
                data={secretUri}
              />
            )}
          </Layout>
        </Segment>
        <Form onSubmit={onSubmit}>
          <Segment>
            <Header size="small">
              {t(
                'settingsSecurityApp.step3',
                '3. Enter the security code generated by your app'
              )}
            </Header>
            {isError && errorMessage && (
              <Message error content={errorMessage} />
            )}
            <Layout center>
              <Code
                type="number"
                length={6}
                autoFocus
                onChange={(value) => setInsertedCode(value)}
                onComplete={(value) => submitCode(value)}
              />
            </Layout>
          </Segment>
          <Segment>
            <Button
              type="submit"
              primary
              loading={isLoading}
              disabled={isLoading || insertedCode.length !== 6}
              content={t('settingsSecurityApp.verifyCode', 'Verify')}
            />
            <Button
              as={Link}
              to="/settings/personal/password-and-authentication"
              basic
              floated="right"
              secondary
              content={t('common.cancel', 'Cancel')}
            />
          </Segment>
        </Form>
      </Segment.Group>
    </PageCenter>
  );
}
