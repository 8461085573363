import React from 'react';
import { Container, Header, Divider } from 'semantic';
import Stats from './Stats';

export default () => {
  return (
    <Container>
      <Header as="h2">External Tokens</Header>
      <Divider hidden />
      <p>
        This view shows you how many tokens were collected from external
        systems. These tokens allow other operators to authenticate on our CPO
        platform.
      </p>
      <Divider hidden />
      <Header as="h3">OCPI</Header>
      <Stats importSource="ocpi" includeUpdatedAt />
      <Divider hidden />
      <Header as="h3">Eclearing</Header>
      <Stats importSource="eclearing" />
      <Divider hidden />
    </Container>
  );
};
