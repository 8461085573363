import React, { useEffect, useState } from 'react';
import { Loader, Message, Statistic } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

export default function MspStats({ mspStatsApiRoute }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchStats(mspStatsApiRoute);
  }, [mspStatsApiRoute]);

  const fetchStats = async (mspStatsApiRoute) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: mspStatsApiRoute,
      });
      setData(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader active inline="centered" />}
      {error && <Message error content={error.message} />}
      {data && (
        <React.Fragment>
          <Statistic.Group widths={5}>
            <Statistic
              value={data.active}
              label={t('mspStatLabels.activeCards', 'active cards')}
            />
            <Statistic
              value={data.pending}
              label={t('mspStatLabels.pendingCards', 'pending cards')}
            />
            <Statistic
              value={data.sent}
              label={t('mspStatLabels.sentCards', 'sent cards')}
            />
            <Statistic
              value={data.disabled}
              label={t('mspStatLabels.disabledCards', 'disabled cards')}
            />
            <Statistic
              value={data.total}
              label={t('mspStatLabels.totalCards', 'total cards')}
            />
          </Statistic.Group>
        </React.Fragment>
      )}
    </div>
  );
}
