import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Header, Icon, Message } from 'semantic';
import { Flex, Group, Space, Stack } from 'components/Layout/helpers';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';

import { request } from 'utils/api';
import CurrentPlanSkeleton from 'screens/Settings/components/organisms/CurrentPlan/Loader';

const currencyMap = {
  EUR: '€',
  GBP: '£',
  USD: '$',
};

interface CurrentPlanProps {
  currentPlanId: string;
  currentLanguage: 'en' | 'fr' | 'nl' | 'de' | 'it' | 'es';
  accountBillingCurrency: 'string';
}

export default function CurrentPlanWrapper(props: CurrentPlanProps) {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CurrentPlan {...props} />
    </QueryClientProvider>
  );
}

function CurrentPlan({
  currentPlanId,
  currentLanguage,
  accountBillingCurrency,
}: CurrentPlanProps) {
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    error,
    data: currentPlan,
  } = useQuery({
    enabled: !!currentPlanId,
    queryKey: ['accountTierBillingPlan', currentPlanId],
    queryFn: () =>
      request({ path: `/1/billing-plans/${currentPlanId}`, method: 'GET' }),
  });

  const {
    name: currentPlanName,
    price: currentPlanPrice,
    features: currentPlanFeatures,
  } = extractPlanDetails({
    currentPlan,
    currentLanguage,
    accountBillingCurrency,
  });

  const currentPlanCard = (
    <Card style={{ minWidth: '480px' }}>
      <>
        <CardContent>
          {/* Current plan title */}
          <Group grow position="apart" align="center">
            <Group align="center">
              <Icon name="regular building" size="large" />
              <Header
                as="h4"
                style={{
                  fontWeight: '700',
                  marginTop: 0,
                }}>
                {currentPlanName}
              </Header>
            </Group>

            {/* Current plan price label */}
            <p
              style={{
                paddingLeft: 60,
                paddingRight: 0,
                fontWeight: '700',
              }}>
              {currentPlanPrice > 0
                ? t('currentPlan.price.label', '{{currency}} {{price}}/month', {
                    price: currentPlanPrice,
                    currency: currencyMap?.[accountBillingCurrency],
                  })
                : t('currentPlan.price.free', 'Free')}
            </p>
          </Group>

          <Space size={10} />

          {/* Current Plan Features */}
          <Stack align="flex-start" spacing={10}>
            {currentPlanFeatures.map((feature: string, index: number) => {
              return (
                <Group key={index}>
                  <Flex style={{ maxWidth: '300px' }} wrap="nowrap">
                    <Icon name="regular circle" size="small" />
                    <div>{feature}</div>
                  </Flex>
                </Group>
              );
            })}
          </Stack>
        </CardContent>
      </>
    </Card>
  );

  return (
    <>
      <Header as="h3">{t('currentPlan.header', 'Current Plan')}</Header>
      {isLoading && <CurrentPlanSkeleton />}
      {isError && <Message error content={error?.message} />}
      {currentPlan && currentPlanCard}
    </>
  );
}

const extractPlanDetails = ({
  currentPlan,
  currentLanguage,
  accountBillingCurrency,
}) => {
  if (!currentPlan) {
    return {
      name: '',
      features: [],
      price: 0,
    };
  }
  // Extract the name in the right locale for the billing plan. If we are unable to find details in the users selected
  // langauge, we will default to the first available name.
  const currentPlanDetails = currentPlan?.data?.details[currentLanguage]?.name
    ? currentPlan?.data?.details[currentLanguage]
    : Object.values(currentPlan?.data?.details).find(
        (detail) => !!detail?.name
      );

  const price =
    currentPlan?.data?.prices
      .filter((price) => price.currency === accountBillingCurrency)[0]
      ?.perPeriod?.toFixed(2) || 0;

  return {
    name: currentPlanDetails?.name || '',
    features: currentPlanDetails?.features || [],
    price,
  };
};
