import React from 'react';

import { withTranslation } from 'react-i18next';
import { Table, Button, Label, Header, Modal } from 'semantic';
import { currentUserCanAccess } from 'utils/roles';
import { Search } from 'components';

import { formatDateTime } from 'utils/date';
import EditCredential from 'components/modals/EditCredential';

import CopyValue from 'components/CopyValue';
import { Confirm } from 'components';
import { request } from 'utils/api';
import { formatPermissions } from 'utils/credentials';

class CredentialTable extends React.Component {
  static contextType = Search.Context;

  render() {
    const { t } = this.props;
    const { items, reload, loading } = this.context;
    const writeAccess = currentUserCanAccess('credentials', 'write');

    if (!items.length || loading) return null;

    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>
                {t('credentials.columnName', 'Name')}
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>
                {t('credentials.columnToken', 'API Token')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('roles.columnContext', 'Context')}
              </Table.HeaderCell>
              <Table.HeaderCell width={4}>
                {t('credentials.columnPermissions', 'Permissions')}
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                {t('credentials.columnActive', 'Active?')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('credentials.columnCreated', 'Created')}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t('credentials.columnActions', 'Actions')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <Modal
                      closeIcon
                      trigger={
                        <Button basic size="small" content="View" icon="list" />
                      }>
                      <Modal.Content>
                        <Header as="h2">
                          {t('credentialsEditDialog.title', 'API Token')}
                        </Header>
                        <CopyValue value={item.apiToken} label="API Token" />
                        <CopyValue
                          value={item.providerId}
                          label="Provider ID"
                        />
                        <p>
                          <a
                            rel="noopener"
                            target="_blank"
                            href="/docs"
                            rel="noreferrer">
                            {t(
                              'credentialsEditDialog.viewDocs',
                              'View API Docs'
                            )}
                          </a>
                        </p>
                      </Modal.Content>
                    </Modal>
                  </Table.Cell>
                  <Table.Cell>
                    {item.context === 'provider' ? (
                      <Label
                        color="teal"
                        content={t('roles.labelProvider', 'Provider')}
                      />
                    ) : (
                      <Label content={t('roles.labelAccount', 'Account')} />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatPermissions(item.permissions, item.context)}
                  </Table.Cell>
                  <Table.Cell>{item.inactive ? 'No' : 'Yes'}</Table.Cell>
                  <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                  <Table.Cell textAlign="center">
                    <EditCredential
                      initialValues={item}
                      onSave={reload}
                      trigger={
                        <Button
                          basic
                          icon="pen-to-square"
                          title="Edit"
                          disabled={!writeAccess}
                        />
                      }
                    />
                    <Confirm
                      header={t(
                        'deleteDialog.header',
                        'Are you sure you want to delete {{name}}?',
                        { name: item.name }
                      )}
                      content={t(
                        'deleteDialog.content',
                        'All data will be permanently deleted'
                      )}
                      trigger={<Button basic icon="trash" text="Delete" />}
                      onConfirm={async () => {
                        await request({
                          method: 'DELETE',
                          path: `/1/credentials/${item.id}`,
                        });
                        await reload();
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default withTranslation()(CredentialTable);
