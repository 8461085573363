import React, { useEffect } from 'react';
import { request } from 'utils/api';

import { Form, Message, Modal, Header, Segment, Button } from 'semantic';
import CountriesField from 'components/form-fields/Countries';
import PointerMap from 'components/maps/Pointer';
import { getParkingTypeOptions, getFacilityOptions } from 'utils/locations';

import { useTranslation } from 'react-i18next';

import { useUser } from 'contexts/user';
import modal from 'helpers/modal';
import { PlatformFeature } from 'components';
import { get, set } from 'lodash-es';
import {
  canAccessAccountEndpoint,
  hasFacilitatorPermissionsAccess,
} from 'utils/roles';

function EditMyLocation({
  initialValues = {
    country: 'NLD',
  },
  onClose,
  close,
}) {
  const { t } = useTranslation();

  const [formValues, setFormValues] = React.useState(initialValues);
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [creditBilling, setCreditBilling] = React.useState({});
  const [account, setAccount] = React.useState({});

  const { user, facilitatorPermissions } = useUser();

  const isLocationOwnerAccount = formValues.accountId === user.accountId;
  const hasWriteAccess =
    isLocationOwnerAccount ||
    canAccessAccountEndpoint(
      user,
      formValues.accountId,
      'cpoLocations',
      'write'
    ) ||
    hasFacilitatorPermissionsAccess(
      facilitatorPermissions,
      'cpoLocations',
      'write'
    );

  const isUpdate = !!initialValues.id;

  const title = isUpdate
    ? t('location.editTitle', 'Edit Location')
    : t('location.newTitle', 'New Location');

  const readonlyMessage = !hasWriteAccess
    ? t(
        'location.readonlyMessage',
        'The location owner has not granted you permission to edit this location.'
      )
    : '';

  useEffect(() => {
    // converting to promose to avoid async in useEffect
    getAccount()
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setCreditBillingEntity();
  }, [user, formValues, submitted]);

  const handleSubmit = async () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    const body = formValues;
    const isUpdate = !!initialValues.id;
    const method = isUpdate ? 'PATCH' : 'POST';
    const path = isUpdate
      ? `/2/locations/cpo/mine/${initialValues.id}`
      : '/2/locations/cpo/mine';

    if (isLocationOwnerAccount) {
      try {
        await request({
          method: 'PATCH',
          path:
            formValues.accessPolicy === 'employeeReimburse'
              ? '/1/users/me'
              : '/1/accounts/self',
          body: { creditBilling },
        });
      } catch (error) {
        setError(error);
        setLoading(false);
        return;
      }
    }

    request({
      method,
      path,
      body,
    })
      .then(() => {
        if (onClose) onClose();
        if (close) close();
        setFormValues(initialValues);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues(set({ ...formValues }, name, value));
  };

  const setCreditBillingEntity = () => {
    if (formValues.accessPolicy === 'employeeReimburse') {
      setCreditBilling(user.creditBilling);
    } else {
      setCreditBilling(account.creditBilling);
    }
  };

  const getMapProps = () => {
    const address = [
      formValues.address,
      formValues.postal_code,
      formValues.city,
      formValues.country,
    ];
    const { coordinates } = formValues.geoLocation || {};
    const hasCompleteAddress = address.every((c) => !!c);

    const isValidLocation = coordinates[0] !== 135 && coordinates[1] !== 90;

    const geoPosition = isValidLocation &&
      coordinates && {
        longitude: coordinates[0],
        latitude: coordinates[1],
      };

    if (geoPosition) {
      return {
        value: geoPosition,
      };
    }
    return { address: hasCompleteAddress ? address.join(', ') : undefined };
  };

  const getAccount = async () => {
    const { data } = await request({
      method: 'GET',
      path: `/1/accounts/self`,
    });
    setAccount(data);
  };

  if (loading) {
    return <></>;
  }

  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form
          id="EditLocation-form"
          error={submitted && Boolean(error)}
          onSubmit={() => handleSubmit()}>
          {readonlyMessage && <Message content={readonlyMessage} />}
          {error && <Message error content={error.message} />}

          <Form.Input
            readOnly={!hasWriteAccess}
            value={formValues.name || ''}
            name="name"
            required
            label={t('common.name', 'Name')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />

          <Header as="h3">{t('common.address')}</Header>
          <Segment>
            <Form.Input
              readOnly={!hasWriteAccess}
              value={formValues.address || ''}
              name="address"
              required
              label={t('common.address', 'address')}
              type="text"
              autoComplete="disabled"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Input
              readOnly={!hasWriteAccess}
              value={formValues.postal_code || ''}
              name="postal_code"
              required
              label={t('common.postCode', 'Postal Code / Zip')}
              type="text"
              autoComplete="disabled"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <Form.Input
              readOnly={!hasWriteAccess}
              value={formValues.city || ''}
              name="city"
              required
              label={t('common.city', 'city')}
              type="text"
              autoComplete="disabled"
              onChange={(e, { name, value }) => setField(name, value)}
            />
            <CountriesField
              readOnly={!hasWriteAccess}
              label={t('common.country', 'country')}
              name="country"
              required
              standard="alpha-3"
              lowerCase={false}
              autoComplete="disabled"
              value={formValues.country}
              onChange={(value) => setField('country', value)}
            />
          </Segment>

          <Header as="h3">
            {t('location.publicationSetting', 'Publication Settings')}
          </Header>
          <Segment>
            {formValues.accessPolicy === 'noReimburse' &&
              formValues.publishingMode === 'public' && (
                <Message
                  error
                  content={t(
                    'editLocation.noReimburseError',
                    "You can't publish a charge station without reimbursement"
                  )}
                />
              )}

            <p>
              {t(
                'location.publicationNote',
                'Public charging stations will be shared other providers, so that it can be found on maps.'
              )}
            </p>

            <Form.Checkbox
              readOnly={!hasWriteAccess}
              checked={formValues.publishingMode === 'public'}
              name="publishingMode"
              label={t(
                'location.publishChargeStation',
                'Publish Charge Station Location'
              )}
              onChange={(e, { name, checked }) =>
                setField(name, checked ? 'public' : 'private')
              }
            />

            {formValues.publishingMode === 'public' && (
              <>
                <PlatformFeature feature={'roaming:suboperator-locations'}>
                  <Form.Input
                    readOnly={!hasWriteAccess}
                    value={get(formValues, 'suboperator.name', '')}
                    name="suboperator.name"
                    label={t('suboperator.name', 'Suboperator Name')}
                    type="text"
                    onChange={(e, { name, value }) => setField(name, value)}
                  />
                </PlatformFeature>
                <Form.Select
                  readOnly={!hasWriteAccess}
                  value={formValues.parking_type}
                  options={getParkingTypeOptions()}
                  name="parking_type"
                  label={t('location.parkingType', 'Parking Type')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
                <Form.Select
                  readOnly={!hasWriteAccess}
                  multiple
                  value={formValues.facilities}
                  options={getFacilityOptions()}
                  name="facilities"
                  label={t('location.facilities', 'Available Facilities')}
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
                <div style={{ height: '300px', marginBottom: '5px' }}>
                  <PointerMap
                    onChange={(position) => {
                      setField('geoLocation', {
                        type: 'Point',
                        coordinates: [position.longitude, position.latitude],
                      });
                    }}
                    {...getMapProps()}
                  />
                </div>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => setField('geoLocation', undefined)}>
                  {t('location.resetPosition', 'Set Position to Address')}
                </a>
              </>
            )}
          </Segment>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {hasWriteAccess && (
          <Button
            loading={loading}
            primary
            content={isUpdate ? t('common.update') : t('common.create')}
            form="EditLocation-form"
          />
        )}
      </Modal.Actions>
    </>
  );
}

export default modal(EditMyLocation);
