import React from 'react';
import { TariffSystem } from 'components/tariffs/AdvancedTariff/state';
import { Button, Grid, Header, Icon } from 'semantic';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { useTranslation } from 'react-i18next';

type SelectTariffSystemProps = {
  tariffSystem: TariffSystem;
  setTariffSystem: (tariffSystem: TariffSystem) => void;
};

export default function SelectTariffSystem({
  tariffSystem,
  setTariffSystem,
}: SelectTariffSystemProps) {
  const advancedTariffsFeatures = useAdvancedTariffFeatures();
  const { t } = useTranslation();

  return (
    <Grid style={{ marginBottom: 6 }}>
      <Grid.Row columns={1} style={{ paddingBottom: 0 }}>
        <Grid.Column>
          <Header as={'h4'} style={{ marginTop: 0 }}>
            {t('advancedTariff.selectTariffSystem', 'Select Tariff System')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3} style={{ paddingBottom: 0 }}>
        <Grid.Column>
          <SelectTariffSystemButton
            text={t('advancedTariff.baseTariffTitle', 'Base Tariff')}
            selected={tariffSystem === 'basic'}
            onClick={() => setTariffSystem('basic')}
          />
        </Grid.Column>
        {advancedTariffsFeatures.scheduled && (
          <Grid.Column>
            <SelectTariffSystemButton
              text={t(
                'advancedTariff.scheduledTariffTitle',
                'Scheduled Tariff'
              )}
              selected={tariffSystem === 'scheduled'}
              onClick={() => setTariffSystem('scheduled')}
            />
          </Grid.Column>
        )}
        {advancedTariffsFeatures.dynamicPricing && (
          <Grid.Column>
            <SelectTariffSystemButton
              text={t('advancedTariff.dynamicTariffTitle', 'Dynamic Tariff')}
              selected={tariffSystem === 'dynamic'}
              onClick={() => setTariffSystem('dynamic')}
            />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
}

function SelectTariffSystemButton({ text, selected, onClick }) {
  const handleButtonClick = (e) => {
    e.preventDefault();
    onClick?.(true);
  };
  return (
    <Button
      basic
      color={selected ? 'blue' : null}
      style={{ width: '100%' }}
      onClick={handleButtonClick}>
      <div style={{ float: 'left', fontWeight: 'bold' }}>{text}</div>
      <div style={{ float: 'right' }}>
        {selected && <Icon name={'circle-check'} />}
        {!selected && <Icon name={'circle regular'} />}
      </div>
    </Button>
  );
}
