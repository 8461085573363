import React from 'react';
import { Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import EditMyAccessGroup from 'components/modals/EditMyAccessGroup';
import DataList from 'components/DataList';
import { request } from 'utils/api';
import Table from './Table';
import { Container, Header, Divider, Grid, Button } from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';

const itemLimit = 100;

import { withTranslation } from 'react-i18next';

class AccessGroups extends React.Component {
  state = {
    dateKey: Date.now(),
  };

  handleDeleteItem = (item) => {
    return request({
      method: 'DELETE',
      path: `/1/access-groups/${item.id}`,
    }).then(() => this.refresh());
  };

  deleteItems = async (itemIds) => {
    for (const itemId of itemIds) {
      await request({
        method: 'DELETE',
        path: `/1/access-groups/${itemId}`,
      });
    }
  };

  handleRequest = (filters = {}) => {
    const { searchId } = this.state;
    return request({
      method: 'POST',
      path: '/1/access-groups/mine',
      body: {
        ...filters,
        ...(searchId ? { searchId } : {}),
        ...this.state.filters,
        limit: itemLimit,
      },
    });
  };

  handleFilterChange = (filters) => {
    this.setState(
      {
        filters: filters.reduce((result, item) => {
          return {
            ...result,
            ...(item.apiFilter || {}),
          };
        }, {}),
      },
      () => this.refresh()
    );
  };

  handleOnSearch = (search) => {
    this.setState(
      {
        searchId: search,
      },
      () => this.refresh()
    );
  };

  refresh() {
    this.setState({
      dataKey: Date.now(),
    });
  }

  render() {
    const { t } = this.props;
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link to="/my-locations">
                {t('myLocations.header', 'My Locations')}
              </Link>,
            ]}
            active={t('myAccessGroups.title', 'My Access Groups')}
          />
          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={6}
              textAlign="right"
              verticalAlign="middle">
              <EditMyAccessGroup
                onClose={() => this.refresh()}
                trigger={
                  <Button
                    primary
                    content={t(
                      'myAccessGroups.newAccessGroup',
                      'New Access Group'
                    )}
                    icon="plus"
                  />
                }
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={10}>
              <Header as="h2" style={{ margin: '0' }}>
                {t('myAccessGroups.title', 'My Access Groups')}
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />

          <p>
            {t(
              'myAccessGroups.description',
              'Access Groups allow you to create lists of RFID tags or people that can charge for free.'
            )}
          </p>

          <DataList
            key={this.state.dataKey}
            limit={itemLimit}
            onRequest={this.handleRequest}>
            <Table
              onDeleteItem={this.handleDeleteItem}
              onRefresh={() => this.refresh()}
            />
          </DataList>
          <Divider hidden />
        </Container>
      </AppWrapper>
    );
  }
}

export default withTranslation()(AccessGroups);
