import React from 'react';
import { Icon, Table, Loader, Message, Button, Label, Form } from 'semantic';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';
import SyncLogDetailModal from './DetailModal';
import SparkChart from 'components/SparkChart';
import { formatDateTime, fromNow } from 'utils/date';
import { sub, format } from 'date-fns';

class SyncLogIssues extends React.Component {
  state = {
    issues: null,
    tags: [],
    loading: true,
    from: null,
    maxCount: null,
    levels: ['error'],
    sortBy: 'count',
    selectedTags: 'all',
    intervalOptions: [],
  };

  async componentDidMount() {
    const intervalOptions = this.getIntervalOptions();
    this.setState({
      intervalOptions,
    });
    this.setState(
      {
        // default to week
        from: intervalOptions[1].value,
      },
      () => this.fetch()
    );

    const { data } = await request({
      method: 'GET',
      path: '/1/sync-log/tags',
    });

    this.setState({ tags: [...new Set(this.state.tags.concat(data.tags))] });
  }

  async fetch() {
    const { from, levels, sortBy, selectedTags } = this.state;
    this.setState({
      loading: true,
      data: null,
      error: null,
    });

    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/sync-log/search-issues',
        body: {
          from,
          groupSort: sortBy,
          ...(levels !== 'all' && { levels }),
          ...(selectedTags !== 'all' && { tags: selectedTags }),
        },
      });

      this.setState({
        issues: data.issues,
        tags: [...new Set(this.state.tags.concat(data.tags))],
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  getIntervalOptions() {
    const now = new Date();

    return [
      {
        key: 0,
        text: 'Last day',
        value: format(
          sub(now, {
            days: 1,
          }),
          'yyyy-MM-dd'
        ),
      },
      {
        key: 1,
        text: 'Last 7 days',
        value: format(
          sub(now, {
            days: 7,
          }),
          'yyyy-MM-dd'
        ),
      },
      {
        key: 2,
        text: 'Last 30 days',
        value: format(
          sub(now, {
            days: 30,
          }),
          'yyyy-MM-dd'
        ),
      },
      {
        key: 3,
        text: 'Last 60 days',
        value: sub(now, {
          days: 60,
        })
          .toISOString()
          .split('T')[0],
      },
    ];
  }

  getLevelOptions() {
    const { levels } = this.state;

    const levelOptions = [
      {
        key: 1,
        text: 'Error',
        value: 'error',
      },
      {
        key: 2,
        text: 'Warning',
        value: 'warning',
      },
      {
        key: 3,
        text: 'Info',
        value: 'info',
      },
    ];

    if (levels.includes('all')) {
      levelOptions.unshift({
        key: 0,
        text: 'All levels',
        value: 'all',
      });
    }

    return levelOptions;
  }

  getSortOptions() {
    return [
      {
        key: 0,
        text: 'Occurrences',
        value: 'count',
      },
      {
        key: 1,
        text: 'Affected documents',
        value: 'docCount',
      },
    ];
  }

  getTagOptions() {
    const { tags, selectedTags } = this.state;

    const tagOptions = [
      ...tags.map((tagName, i) => ({
        key: i + 1,
        text: tagName,
        value: tagName,
      })),
    ];

    if (selectedTags.includes('all')) {
      tagOptions.unshift({
        key: tagOptions.length + 1,
        text: 'All tags',
        value: 'all',
      });
    }

    // if no retrieved tags, add a "no tags" option
    if (!tags.length) {
      tagOptions.unshift({
        key: 0,
        text: 'Only tags from current items are shown',
        value: 'no tags',
        disabled: true,
      });
    }

    // Add tags option for previously selected tags if they don't exist anymore
    if (Array.isArray(selectedTags)) {
      selectedTags?.forEach((tag) => {
        if (!tags.includes(tag)) {
          tagOptions.unshift({
            key: tagOptions.length + 1,
            text: tag,
            value: tag,
          });
        }
      });
    }
    return tagOptions;
  }

  render() {
    const {
      loading,
      error,
      issues,
      from,
      intervalOptions,
      levels,
      sortBy,
      selectedTags,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        {/* Do we need this header? */}
        {/* <Header as="h3">{t('synclogIssues.title', 'Sync Issues')}</Header> */}
        <div>
          <Form style={{ display: 'flex' }}>
            <Form.Select
              value={from}
              options={intervalOptions}
              label={t('synclogIssues.from', 'From')}
              onChange={(_, { value }) =>
                this.setState({ from: value }, () => this.fetch())
              }
              style={{ width: 'auto', marginRight: '1em' }}
            />
            <Form.Select
              value={levels}
              multiple
              clearable
              options={this.getLevelOptions()}
              label={t('synclogIssues.level', 'Level')}
              onChange={(_, { value }) =>
                this.setState({ levels: value.length ? value : 'all' }, () =>
                  this.fetch()
                )
              }
              style={{ width: 'auto', marginRight: '1em' }}
            />
            <Form.Select
              value={sortBy}
              options={this.getSortOptions()}
              label={t('synclogIssues.sortBy', 'Sort by')}
              type="text"
              style={{ width: 'auto', marginRight: '1em' }}
              onChange={(_, { value }) =>
                this.setState({ sortBy: value }, () => this.fetch())
              }
            />
            <Form.Select
              value={selectedTags}
              multiple
              clearable
              options={this.getTagOptions()}
              label={t('synclogIssues.tags', 'Tags')}
              type="text"
              style={{ width: 'auto' }}
              onChange={(_, { value }) =>
                this.setState(
                  { selectedTags: value.length ? value : 'all' },
                  () => this.fetch()
                )
              }
            />
          </Form>
        </div>
        {loading && <Loader active inline style={{ marginTop: '1em' }} />}
        {error && <Message error content={error.message} />}
        {issues && (
          <React.Fragment>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnMessage', 'Message')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnGraph', 'Graph')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnTotal', 'Total')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnDocs', 'Docs')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnLastSeen', 'Last seen')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnFirstSeen', 'First seen')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('synclogIssues.columnTags', 'Tags')}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    {t('synclogIssues.columnDetails', 'Details')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {issues.map((item) => (
                  <Table.Row key={item._id}>
                    <Table.Cell>
                      <div>
                        <p style={{ fontSize: '0.9em', marginBottom: '.5em' }}>
                          {item.message.replaceAll('","', '", "')}
                        </p>
                      </div>

                      <div>
                        {item.levels.map((level) => (
                          <Label size="small" key={level}>
                            {level}
                          </Label>
                        ))}
                        {item.syncNames.map((name) => (
                          <Label size="small" key={name}>
                            {name}
                          </Label>
                        ))}
                        <Label size="small" key="lastSeen">
                          <Icon name="clock" />
                          {fromNow(item.lastOccurence)}
                          {` | `}
                          {fromNow(item.firstOccurence).replace('ago', '')} old
                        </Label>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <SparkChart timeSeries={item.timeSeries} />
                    </Table.Cell>
                    <Table.Cell text-align="center">
                      <Label circular>{item.count}</Label>
                    </Table.Cell>
                    <Table.Cell>
                      <Label circular>{item.docs.length}</Label>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{ fontSize: '0.9em' }}>
                        {formatDateTime(item.lastOccurence)}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{ fontSize: '0.9em' }}>
                        {formatDateTime(item.firstOccurence)}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {item.tags.map((tag) => (
                        <Label size="tiny" key={tag}>
                          {tag}
                        </Label>
                      ))}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <SyncLogDetailModal
                        item={item}
                        trigger={<Button basic size="small" icon="list" />}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
                {issues.length === 0 && (
                  <Table.Row>
                    <Table.Cell colSpan="8">
                      <Message
                        icon="info"
                        content={t(
                          'synclogIssues.noIssues',
                          'No issues found, refine your search criteria using the filters above!'
                        )}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation()(SyncLogIssues);
