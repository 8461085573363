export function parseQueryString(location) {
  const url = location.search;
  const query = url.substr(1);
  const result = {};
  query.split('&').forEach(function (part) {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function getParameterByName(name, url = undefined) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return undefined;
  if (!results[2]) return undefined;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function utf8ToBase64(str) {
  const encoder = new TextEncoder();
  const uint8Array = encoder.encode(str);
  let binary = '';
  uint8Array.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });
  return btoa(binary);
}

export function base64ToUtf8(base64) {
  const binary = atob(base64);
  const uint8Array = new Uint8Array(
    [...binary].map((char) => char.charCodeAt(0))
  );
  const decoder = new TextDecoder();
  return decoder.decode(uint8Array);
}
