import React from 'react';
import { useFilterContext } from 'components/search/Context';
import SearchDropdown from 'components/SearchDropdown';
import { Form } from 'semantic';

type MultiSelectDropdownFilterProps = {
  name: string;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  onDataNeeded: (filter: {
    name?: string;
  }) => Promise<MultiselectDropdownFilterData>;
  populateOnLoad?: boolean;

  placeholder?: string;

  multiple?: boolean;
  /**
   * For fast feedback on a field change with no re-rendering.
   * @param value
   */
  onChange?: (value: any) => void;
  /**
   * by default filterContext doesn't reload on changes
   * @see {@link services/web/src/components/search/Provider.js|reload()}
   * To be aligned with previous behaviour, this is set to false.
   * In case you need fast feedback set this flag to true.
   */
  reloadOnChange?: boolean;
};

export type MultiselectDropdownFilterData = {
  data: { name: string; id: string }[];
};

/**
 * DropdownSearchFilterV2 is a multi-select dropdown filter that relies on Network fetches to populate the dropdown.
 *
 * @note be mindful about the memory usage, this component cache the data fetched from the network so it can show
 * selected items when the search replaces old data with new which makes it so the component can't find the text to
 * show in the Options list
 *
 * @param name
 * @param label
 * @param disabled
 * @param error
 * @param onDataNeeded
 * @param populateOnLoad
 * @param multiple
 * @param onChange
 * @param reloadOnChange
 * @param placeHolder
 * @constructor
 */
export const DropdownSearchFilterV2 = ({
  name,
  label,
  disabled,
  error,
  onDataNeeded,
  placeholder,
  populateOnLoad,
  multiple = false,
  onChange,
  reloadOnChange = false,
}: MultiSelectDropdownFilterProps) => {
  const filterContext = useFilterContext();

  const currentFilterValue = filterContext.filters[name];

  let currentFilter;
  if (multiple) {
    if (!currentFilterValue) {
      currentFilter = [];
    } else if (Array.isArray(currentFilterValue)) {
      currentFilter = currentFilterValue;
    } else {
      currentFilter = [currentFilterValue];
    }
  } else {
    currentFilter = currentFilterValue;
  }

  // Caching already fetched data so can show the data when search is not submitted yet.
  // This can be garbage collected when the component is unmounted.
  // So, hopefully it won't cause any memory issues.
  // TODO: Make this cache only the select items
  const cacheData = new Set<any>();

  return (
    <Form.Field disabled={disabled} error={error}>
      <label>{label}</label>
      <SearchDropdown
        objectMode={false}
        multiple={multiple ?? false}
        disabled={disabled}
        placeholder={placeholder}
        value={currentFilter}
        onDataNeeded={async (filter) => {
          const response = await onDataNeeded(filter);

          response.data.forEach((e) => {
            cacheData.add(e);
          });

          return { data: Array.from(cacheData) };
        }}
        populateOnLoad={populateOnLoad}
        onChange={(e, data) => {
          filterContext.onFilterChange({
            value: data.value,
            name: name,
          });
          onChange?.(data.value);
          if (reloadOnChange) {
            filterContext.reload();
          }
        }}
      />
    </Form.Field>
  );
};
