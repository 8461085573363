import React from 'react';

// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter';
// @ts-ignore
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Modal, Header, Table, Container, Divider, Button } from 'semantic';
import { useTranslation } from 'react-i18next';

import AsyncModal from 'helpers/async-modal';
import { EVSEPaymentTerminalSession } from 'types/evse-payment-terminal-session';
import InspectObject from 'components/modals/InspectObject';
import ExpandJsonButton from 'components/ExpandJsonButton';

type Props = {
  data: EVSEPaymentTerminalSession;
};

function EvsePaymentTerminalSession({ data }: Props) {
  const { t } = useTranslation();

  // @ts-ignore - _id is legacy and it can be removed as soon as the backend is deployed
  const id = data.id || data._id;
  return (
    <>
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {t('evsePaymentTerminalSession.view', 'View payment details')}
        <InspectObject
          name="Payment Terminal Session"
          data={data}
          trigger={<Button basic content={'Inspect'} icon="code" />}
        />
      </Modal.Header>
      <Modal.Content>
        <Header as="h3">
          {t('evsePaymentTerminalSession.basicDetails', 'Basic details')}
        </Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={4}>
                {t('evsePaymentTerminalSession.id', 'Id')}
              </Table.Cell>
              <Table.Cell>{id}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t(
                  'evsePaymentTerminalSession.evseControllerId',
                  'EVSE Controller ID'
                )}
              </Table.Cell>
              <Table.Cell>{data.evseControllerId}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t('evsePaymentTerminalSession.connectorId', 'Connector ID')}
              </Table.Cell>
              <Table.Cell>{data.connectorId}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h3">
          {t('evsePaymentTerminalSession.paymentTerminal', 'Payment terminal')}
        </Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={4}>
                {t('evsePaymentTerminalSession.provider', 'Provider')}
              </Table.Cell>
              <Table.Cell>{data.terminalProvider}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Header as="h3">
          {t('evsePaymentTerminalSession.paymentSession', 'Payment session')}
        </Header>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={4}>
                {t('evsePaymentTerminalSession.uid', 'Unique ID')}
              </Table.Cell>
              <Table.Cell>{data.uid}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t('evsePaymentTerminalSession.status', 'Status')}
              </Table.Cell>
              <Table.Cell>{data.status}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                {t(
                  'evsePaymentTerminalSession.externalPaymentId',
                  'External Payment ID'
                )}
              </Table.Cell>
              <Table.Cell>{data.externalPaymentId}</Table.Cell>
            </Table.Row>
            {data.cardIdUsedForPayment && (
              <Table.Row>
                <Table.Cell>
                  {t(
                    'evsePaymentTerminalSession.cardUsedForPayment',
                    'Card ID used for payment'
                  )}
                </Table.Cell>
                <Table.Cell>{data.cardIdUsedForPayment}</Table.Cell>
              </Table.Row>
            )}
            {data.cardType && (
              <Table.Row>
                <Table.Cell>
                  {t('evsePaymentTerminalSession.paymentType', 'Payment Type')}
                </Table.Cell>
                <Table.Cell>
                  {data.cardType === 'CREDIT_OR_DEBIT_CARD' ? 'Card' : 'RFID'}
                </Table.Cell>
              </Table.Row>
            )}
            {data.vatInfo && (
              <Table.Row>
                <Table.Cell>
                  {t('evsePaymentTerminalSession.vat', 'VAT')}
                </Table.Cell>
                <Table.Cell>{data.vatInfo.vatPercentage}%</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {data.externalRecord && (
          <>
            <Header as="h3">
              {t(
                'evsePaymentTerminalSession.externalRecord',
                'External record'
              )}
            </Header>
            <SyntaxHighlighter language="json" style={atomDark}>
              {JSON.stringify(data.externalRecord || {}, null, 2)}
            </SyntaxHighlighter>
          </>
        )}
        {data.formattedReceiptString && (
          <>
            <Header as="h3">
              {t('evsePaymentTerminalSession.receipt', 'Receipt')}
            </Header>
            <Container textAlign="center">
              <pre>{data.formattedReceiptString}</pre>
            </Container>
          </>
        )}
      </Modal.Content>
    </>
  );
}

export default AsyncModal(EvsePaymentTerminalSession);
