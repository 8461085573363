import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './App';

import 'react-tectonic/lib/styles.css';
import 'react-day-picker/dist/style.css';

import { BrowserRouter } from 'react-router-dom';
import PageTracker from 'components/PageTracker';

import { UserProvider, useUser } from 'contexts/user';
import { FeatureProvider } from 'contexts/features';
import { PlatformModuleProvider } from 'contexts/platformModules';
import { ProductInstrumentationProvider } from 'contexts/productInstrumentation';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from 'contexts/theme';
import { SandboxProvider } from 'contexts/sandbox';
import ProviderIDInjector from 'components/ProviderIDInjector';

import './i18n';
import { ENV_NAME } from 'utils/env';

import { isEfluxDomain } from 'utils/domains';

if (ENV_NAME !== 'development' && isEfluxDomain()) {
  Sentry.init({
    dsn: 'https://24d6de28fd6f42f2ad4b97b95aff5708@o298529.ingest.sentry.io/1552825',
    environment: ENV_NAME,
    release: process.env.RELEASE_VERSION,
  });
}

// import generatedTheme from './theme/theme.generated.json';

// Icons

import { Icon } from 'semantic';
import solidIcons from 'semantic/assets/icons/solid.svg';
import brandIcons from 'semantic/assets/icons/brands.svg';
import regularIcons from 'semantic/assets/icons/regular.svg';
import { EnvironmentBanner } from './components/environmentBanner';
import AppUpdateBanner from './components/AppUpdateBanner';
import { useTheme } from './contexts/theme';
import { getThemedField } from 'utils/theming';
import { ToastProvider } from 'components/Toast';

Icon.useSet(solidIcons);
Icon.useSet(brandIcons, 'brand');
Icon.useSet(regularIcons, 'regular');

const Theme = ({ children }) => {
  const { provider } = useUser();
  const { renderedTheme } = useTheme();

  const [theme, setTheme] = React.useState();

  React.useEffect(() => {
    if (provider) {
      const isDark = provider.primaryColorDarkHex && renderedTheme === 'dark';

      const primaryColor =
        (isDark && provider.primaryColorDarkHex) || provider.primaryColorHex;

      const secondaryBackgroundColor = getThemedField(
        provider,
        'secondaryBackgroundColorHex',
        isDark
      );

      document.documentElement.style.setProperty(
        '--primary-color',
        provider.primaryColorHex ? `#${primaryColor}` : '#F7931E'
      );

      if (primaryColor) {
        // 5% transparency
        document.documentElement.style.setProperty(
          '--primary-color-hover',
          provider.primaryColorHex ? `#${primaryColor}0D` : '#F7931E0D'
        );
      }

      document.documentElement.style.setProperty(
        '--secondary-background-color',
        secondaryBackgroundColor ? `${secondaryBackgroundColor}` : '#FFF3E5'
      );

      setTheme({
        ...(primaryColor
          ? { primaryColor: `#${primaryColor}` }
          : { primaryColor: '#F7931E' }),
      });
    }
  }, [provider]);

  if (!theme) {
    return <span></span>;
  }

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};

const Wrapper = () => (
  <Suspense fallback={<div></div>}>
    <ProviderIDInjector>
      <PlatformModuleProvider>
        <UserProvider>
          <FeatureProvider>
            <SandboxProvider>
              <ThemeProvider>
                <Theme>
                  <BrowserRouter>
                    <ProductInstrumentationProvider>
                      <EnvironmentBanner>
                        <PageTracker />
                        <AppUpdateBanner />
                        <ToastProvider>
                          <App />
                        </ToastProvider>
                      </EnvironmentBanner>
                    </ProductInstrumentationProvider>
                  </BrowserRouter>
                </Theme>
              </ThemeProvider>
            </SandboxProvider>
          </FeatureProvider>
        </UserProvider>
      </PlatformModuleProvider>
    </ProviderIDInjector>
  </Suspense>
);

const root = createRoot(document.getElementById('root'));
root.render(<Wrapper />);
