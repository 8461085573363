export const timeOptions = {
  today: {
    type: 'fixed',
    label: 'Today',
    to: 'now',
    from: 'now-1h/d',
  },
  yesterday: {
    type: 'fixed',
    label: 'Yesterday',
    to: 'now-1h/d',
    from: 'now-1d/d',
  },
  ThisWeek: {
    type: 'fixed',
    label: 'This Week',
    to: 'now',
    from: 'now/w',
  },
  ThisMonth: {
    type: 'fixed',
    label: 'This Month',
    to: 'now',
    from: 'now/M',
  },
  ThisYear: {
    type: 'fixed',
    label: 'This Year',
    to: 'now',
    from: 'now/y',
  },
  hoursInput: {
    type: 'input',
    unit: 'hours',
    default: 24,
  },
  daysInput: {
    type: 'input',
    unit: 'days',
    default: 7,
  },
  monthsInput: {
    type: 'input',
    unit: 'months',
    default: 3,
  },
  selectYear: {
    type: 'select',
    unit: 'years',
    label: 'Year',
    default: new Date().getFullYear() - 1,
  },
  selectMonths: {
    type: 'select',
    unit: 'months',
    label: 'Month',
    default: new Date().getMonth(),
  },
};
