import React from 'react';

import { request } from 'utils/api';
import { useTranslation } from 'react-i18next';
import { formatEuro, truncate } from 'utils/formatting';
import { Container, Header, Table, Button, Grid, Divider } from 'semantic';
import EditLocationPrice from './EditLocationPrice';

import { SearchProvider } from 'components';
import { formatDateTime } from 'utils/date';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';

export default function MyLocations() {
  const { t } = useTranslation();
  const advancedTariffFeatures = useAdvancedTariffFeatures();

  async function onDataNeeded(params) {
    const result = await request({
      method: 'POST',
      path: '/2/locations/cpo/mine/fast',
      body: {
        ...params,
        accessPolicies: ['employeeReimburse'],
      },
    });

    //XXX yes this not the best way to do this, but it works for now
    await Promise.all(
      result.data.map(async (location) => {
        const { data } = await request({
          method: 'POST',
          path: `/2/locations/cpo/${location.id}/evse-controllers/search`,
        });
        location.evseControllers = data;
      })
    );

    return result;
  }

  return (
    <Container style={{ position: 'relative' }}>
      <Grid reversed="computer">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            {t('myLocations.employeesheader', 'Employees Locations')}
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />

      <SearchProvider onDataNeeded={onDataNeeded}>
        {({ items, reload, loading }) => (
          <div className="list">
            <SearchProvider.Status
              noItems={t(
                'myLocations.noChargeLocationsYet',
                'No charge stations activated yet'
              )}
            />

            {!loading && items.length !== 0 && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>
                      {t('common.name', 'Name')}
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>Chargebox ID</Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      {t('common.user', 'User')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('common.email', 'Email')}
                    </Table.HeaderCell>
                    {!advancedTariffFeatures.advancedTariffs && (
                      <Table.HeaderCell width={2}>
                        {t('evseInviteModal.pricePerKwh')}
                      </Table.HeaderCell>
                    )}
                    <Table.HeaderCell width={3}>
                      {t('common.createdAt')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t('common.actions', 'Actions')}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>
                        {truncate(
                          item?.evseControllers[0]?.ocppIdentity || ' - ',
                          20
                        )}
                      </Table.Cell>
                      <Table.Cell>{item.user?.name}</Table.Cell>
                      <Table.Cell>{item.user?.email}</Table.Cell>
                      {!advancedTariffFeatures.advancedTariffs && (
                        <Table.Cell textAlign="right">
                          {formatEuro(
                            item?.evseControllers[0]?.costSettings[0]
                              ?.pricePerKwh
                          )}
                        </Table.Cell>
                      )}
                      <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {item?.evseControllers[0]?.costSettings?.length > 0 && (
                          <EditLocationPrice
                            initialValues={item}
                            trigger={
                              <Button basic icon="pen-to-square" title="Edit" />
                            }
                            onClose={() => reload()}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
            <SearchProvider.Pagination />
          </div>
        )}
      </SearchProvider>
    </Container>
  );
}
