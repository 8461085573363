import React, { useEffect, useState } from 'react';
import { request } from 'utils/api';
import { Table, Loader, Message } from 'semantic';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, ListHeader, HelpTip } from 'components';
import { translateExcludedReason, truncate } from 'utils/formatting';
import { formatDate } from 'utils/date';
import Currency from 'components/Currency';

const excludedStyle = (excluded) =>
  excluded
    ? {
        textDecoration: 'line-through',
        opacity: 0.3,
      }
    : {};

const EntityRender = ({ entity }) => {
  const { t } = useTranslation();
  switch (entity?.entityType) {
    default:
      return <p>Unable to render {entity?.entityType}</p>;
    case 'EvseController':
      return (
        <>
          <p>
            Charge station
            <br />
            <Link to={`/charging-stations/${entity.id}`}>
              <small title={entity.evseId}>{entity.evseId}</small>
            </Link>
          </p>
        </>
      );
    case 'Card':
      return (
        <>
          <p>
            Card
            <br />
            <small title={entity?.nameOnCard}>{entity.nameOnCard}</small>
          </p>
        </>
      );
    case 'Invoice':
      return (
        <>
          <p>
            Invoice
            <br />
            <small>
              {t('invoice.items.manualInvoiceFee', 'Manual processing fee')}
            </small>
          </p>
        </>
      );
    case 'Token':
      return (
        <>
          <p>
            Token
            <br />
            <small title={entity.customId}>{entity.customId}</small>
          </p>
        </>
      );
    case 'Cdr':
      return (
        <>
          <p>
            Cdr
            <br />
            <Link to={`/cards/cdrs/${entity.id}`}>
              <small title={entity.cdrId}>
                {entity.cdrId ? truncate(entity.cdrId, 20) : '-'}
              </small>
            </Link>{' '}
            <small>
              {formatDate(entity.endTime)} <br />
            </small>
          </p>
        </>
      );
    case 'Session':
      return (
        <>
          <p>
            Session
            <br />
            <Link to={`/cards/sessions/${entity.id}`}>
              <small title={entity.externalUniqueId}>
                {entity.externalUniqueId
                  ? truncate(entity.externalUniqueId, 20)
                  : '-'}
              </small>
            </Link>{' '}
            <small>
              {formatDate(entity.endedAt)} <br />
            </small>
          </p>
        </>
      );
    case 'SubscriptionCharge':
      return (
        <>
          <p>
            Subscrition Charge
            <br />
            <small>{entity.accountTierName}</small>
            <br />
            <small>
              {formatDate(entity.startDate)} - {formatDate(entity.endDate)}{' '}
            </small>
          </p>
        </>
      );
  }
};

export default function Summary(props) {
  const [invoice, setInvoice] = useState();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const { t } = useTranslation();

  const invoiceId = props.match.params.id;

  async function loadInvoiceData() {
    const { data } = await request({
      method: 'GET',
      path: `/1/invoices/${invoiceId}`,
    });
    setInvoice(data);
  }

  useEffect(() => {
    loadInvoiceData();
  }, []);

  async function loadResults() {
    const results = await request({
      method: 'GET',
      path: `/1/invoices/${invoiceId}/summary`,
    });
    setLoading(false);
    setResults(
      results.data?.sort((a, b) => (a.excluded === b.excluded ? 1 : -1))
    );
  }

  useEffect(() => {
    loadResults();
  }, []);

  return (
    <>
      <Breadcrumbs
        path={[
          <Link key="finance" to="/finance">
            {t('finance.title', 'Finance')}
          </Link>,
          <Link key="invoices" to="/finance/invoices">
            {t('invoices.title', 'Invoices')}
          </Link>,
          <Link key="invoices" to={`/finance/invoices/${invoice?.id}`}>
            {`#${invoice?.identifier}`}
          </Link>,
        ]}
        active={'Summary'}
      />
      <ListHeader
        title={t('invoiceSummary.header', 'Invoice #{{name}} summary', {
          name: invoice?.identifier,
          date: invoice ? `${invoice?.year} / ${invoice?.month}` : '',
        })}></ListHeader>
      {loading && <Loader active inline="centered" />}
      {results.length === 0 && !loading && (
        <Message>
          {t(
            'invoice.items.emptyInvoice',
            'This invoice does not contain any billable items.'
          )}
        </Message>
      )}
      {results.length !== 0 && (
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                {t('invoice.summary.columnEntity', 'Entity')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('invoice.summary.columnUnit', 'Unit')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('invoice.summary.columnType', 'Type')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('invoice.summary.columnTotal', 'Total amount')}
              </Table.HeaderCell>
              <Table.HeaderCell width={3}>
                {t('invoice.summary.columnBookable', 'Bookable amount')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {results.map(
              ({
                id,
                entity,
                unit,
                type,
                totalAmount,
                totalAmountWithVat,
                bookableAmount,
                currency = 'EUR',
                bookableAmountWithVat,
                excluded,
                excludedReason,
              }) => (
                <>
                  <Table.Row key={id}>
                    <Table.Cell>
                      {excluded ? (
                        <HelpTip
                          title={t(
                            'invoiceSummary.excludedTitle',
                            'Excluded from billing'
                          )}
                          text={translateExcludedReason(excludedReason, t)}
                        />
                      ) : null}
                    </Table.Cell>
                    <Table.Cell style={excludedStyle(excluded)}>
                      <EntityRender entity={entity} />
                    </Table.Cell>
                    <Table.Cell style={excludedStyle(excluded)}>
                      {unit?.quantity} {unit?.label}
                    </Table.Cell>
                    <Table.Cell style={excludedStyle(excluded)}>
                      {type}
                    </Table.Cell>
                    <Table.Cell style={excludedStyle(excluded)}>
                      <Currency currency={currency} value={totalAmount} />{' '}
                      <small>
                        (with VAT{' '}
                        <Currency
                          currency={currency}
                          value={totalAmountWithVat}
                        />
                      </small>
                      )
                    </Table.Cell>
                    <Table.Cell style={excludedStyle(excluded)}>
                      <Currency currency={currency} value={bookableAmount} />{' '}
                      <small>
                        (with VAT{' '}
                        <Currency
                          currency={currency}
                          value={bookableAmountWithVat}
                        />
                      </small>
                      )
                    </Table.Cell>
                  </Table.Row>
                </>
              )
            )}
          </Table.Body>
        </Table>
      )}
    </>
  );
}
