import React, { useState } from 'react';
import { Form, Message, Modal, Button } from 'semantic';
import { request } from 'utils/api';
import PublicLocationsMsp from 'components/form-fields/PublicLocationsMsp';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import { withTranslation } from 'react-i18next';

const StartRemoteSessionMsp = ({ trigger, t, onSubmit, onClose }) => {
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = () => {
    setSubmitted(true);
    setError(null);

    const { location, paymentInstrumentId, tokenId } = formValues;
    if (!location) {
      setError(new Error('No location selected'));
      return;
    }
    request({
      method: 'POST',
      path: '/1/remote-sessions/msp',
      body: {
        locationId: location.locationId,
        evseUid: location.evseUid,
        connectorId: location.connectorId,
        paymentType: paymentInstrumentId ? 'PSP_PREAUTH' : 'TOKEN',
        tokenId,
        paymentInstrumentId,
        preauthAmount: 50,
        currency: 'EUR',
      },
    })
      .then(() => {
        handleClose();
        onSubmit();
      })
      .catch((error) => {
        setSubmitted(false);
        setError(error);
      });
  };

  const handleClose = () => {
    setFormValues({});
    setSubmitted(false);
    setError(null);
    setOpen(false);
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };

  return (
    <Modal
      size="small"
      closeIcon
      closeOnDimmerClick={false}
      trigger={trigger}
      onClose={() => {
        handleClose();
        onClose();
      }}
      onOpen={() => setOpen(true)}
      open={open}>
      <Modal.Header>
        {t('startRemoteSessionModal.header', 'Start Remote Session')}
      </Modal.Header>
      <Modal.Content>
        <p>
          Step 1: Make sure your cable is plugged into both your vehicle and the
          charge station connector.
        </p>
        <p>
          Step 2: Find the correct ID number and connector before starting a
          charge session.
        </p>
        <Form onSubmit={handleSubmit}>
          {!!error && <Message error content={error.message} />}

          <Form.Field required>
            <label>{t('editCard.card', 'Card')}</label>
            <SearchDropdown
              value={formValues.tokenId}
              getOptionLabel={(option) =>
                `${option.nameOnCard} - ${option.token?.customId}`
              }
              getOptionValue={(option) => option.token?.id}
              objectMode={false}
              keywordField="keywords"
              onDataNeeded={() =>
                request({ path: '/1/cards/mine', method: 'GET' })
              }
              onChange={(_, { value }) => setField('tokenId', value)}
            />
          </Form.Field>

          <Form.Field>
            <label>Find Public Location</label>
            <PublicLocationsMsp
              value={formValues.location}
              onChange={(_, { value }) => setField('location', value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={submitted}
          primary
          content={t('startRemoteSessionModal.start', 'Start Session')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default withTranslation()(StartRemoteSessionMsp);
