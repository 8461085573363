import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function FinanceMenu({
  mspPath = '/finance',
  cpoPath = '/finance/cpo',
}) {
  const { t } = useTranslation();
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('financeMenu.msp', 'MSP')}
          to={mspPath}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('financeMenu.cpo', 'CPO')}
          to={cpoPath}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
}
