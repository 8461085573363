import React from 'react';

import { request } from 'utils/api';
import Table from './Table';
import { Container, Divider, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { SearchProvider, ListHeader } from 'components';
import InviteUser from 'components/modals/InviteUser';
import { currentUserCanAccess } from 'utils/roles';

export default function AccountInvites({ account }) {
  const { t } = useTranslation();

  function handleDeleteItem(item) {
    return request({
      method: 'DELETE',
      path: `/1/invites/${item.id}`,
    });
  }

  function handleRequest(body) {
    return request({
      method: 'POST',
      path: '/1/invites/search',
      body: {
        ...body,
        accountId: account.id,
      },
    });
  }

  const searchRef = React.useRef(null);
  const writeAccess = currentUserCanAccess('accounts', 'write');

  return (
    <Container>
      <SearchProvider limit={100} onDataNeeded={handleRequest}>
        <ListHeader
          ref={searchRef}
          title={t('accountInvites.title', 'Invites')}>
          <InviteUser
            account={account}
            onClose={() => {
              searchRef.current?.reload();
            }}
            trigger={
              <Button
                primary
                style={{ marginTop: '1px' }}
                disabled={!writeAccess}
                content={t('accountInvites.inviteUser', 'Create Invite')}
                icon="plus"
              />
            }
          />
        </ListHeader>
        <Divider hidden />
        <Table onDeleteItem={handleDeleteItem} />
        <Divider hidden />
      </SearchProvider>
    </Container>
  );
}
