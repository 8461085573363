import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Icon, List } from 'semantic';
import SummaryLabel from 'components/tariffs/AdvancedTariff/SummaryLabel';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { SummaryGridRow } from 'components/tariffs/AdvancedTariff/SummaryRow';
import { useEnableEnergyMarketTrackingState } from 'components/tariffs/AdvancedTariff/BaseCostSettings';
import { useEnergyMarketPriceContext } from 'components/MarketEnergyTariffs/Context';
import { PriceText } from './PriceText';

/**
 * @typedef UIRow
 * @property {Element} Header
 * @property {Element} Content
 */

/**
 *
 * @param {Object} props
 * @param {string} props.currency
 * @param {Object} props.value
 * @param {number?} props.value.pricePerKwh
 * @param {number?} props.value.pricePerSession
 * @param {number?} props.value.pricePerHour
 * @returns {React.ReactElement}
 */
export default function CostSettingsSummary(props) {
  const { value, currency } = props;
  const row = useCostSettingsSummaryRow(value, currency);
  return <SummaryGridRow row={row.columns} />;
}

// TODO: Refactor this to createCostSettingsSummaryRow. Using use*** is confusing.
/**
 *
 * @param {Object} value
 * @param {number?} value.pricePerKwh
 * @param {number?} value.pricePerSession
 * @param {number?} value.pricePerHour
 * @param {string} currency
 * @return {{columns: UIRow[], uiKey: number}}
 */
export function useCostSettingsSummaryRow(value, currency) {
  const uiKey = useMemo(() => Math.random(), []);
  const staticPriceColumn = useStaticPriceColumn(value, currency);
  const dynamicPriceColumn = useDynamicPriceColumn(value, currency);
  const backupPriceColumn = useBackupPriceColumn(value, currency);
  const idleFeeColumn = useIdleFeeColumn(value, currency);

  const advancedTariffsFeatures = useAdvancedTariffFeatures();
  const [isMarketTrackingEnabled] = useEnableEnergyMarketTrackingState(value);

  const columns = [];
  if (isMarketTrackingEnabled) {
    columns.push(dynamicPriceColumn);
    columns.push(backupPriceColumn);
  } else {
    columns.push(staticPriceColumn);
  }

  if (advancedTariffsFeatures.idleFee) {
    columns.push(idleFeeColumn);
  }

  return { uiKey, columns };
}

/**
 *
 * @param {Object} value
 * @param {number?} value.pricePerKwh
 * @param {number?} value.pricePerSession
 * @param {number?} value.pricePerHour
 * @param {string} currency
 * @return {UIRow}
 */
function useStaticPriceColumn(value, currency) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);

  return {
    Header: (
      <Grid.Column key={`price-column-header-${uiKey}`}>
        <SummaryLabel>{t('advancedTariff.price', 'Price')}</SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`price-column-content-${uiKey}`}>
        <List>
          <List.Item>
            <PriceText
              amount={value?.pricePerKwh}
              currency={currency}
              label={t('advancedTariff.perKwh', 'per kWh')}
            />
          </List.Item>
          <List.Item>
            <PriceText
              amount={value?.pricePerSession}
              currency={currency}
              label={t('advancedTariff.perSession', 'per session')}
            />
          </List.Item>
          <List.Item>
            <PriceText
              amount={value?.pricePerHour}
              currency={currency}
              label={t('advancedTariff.perHour', 'per hour')}
            />
          </List.Item>
        </List>
      </Grid.Column>
    ),
  };
}

// TODO: Refactor this to createDynamicPriceColumn. Using use*** is confusing.
/**
 *
 * @param {Object} value
 * @param {number?} value.pricePerKwh
 * @param {number?} value.pricePerSession
 * @param {number?} value.pricePerHour
 * @param {string} currency
 * @return {UIRow}
 */
function useDynamicPriceColumn(value, currency) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);
  const energyTariffContext = useEnergyMarketPriceContext();

  return {
    Header: (
      <Grid.Column key={`price-column-header-${uiKey}`}>
        <SummaryLabel>{t('advancedTariff.price', 'Price')}</SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`price-column-content-${uiKey}`}>
        <List>
          <>
            <List.Item>
              <PriceText
                amount={
                  energyTariffContext.activeTariff?.costSettings.pricePerKwh
                }
                currency={
                  energyTariffContext.activeTariff?.costSettings.currency
                }
                label={t('advancedTariff.perKwh', 'per kWh')}
              />{' '}
              <span>
                <Icon name={'chart-line'} />
              </span>
            </List.Item>
            <List.Item>
              <PriceText
                amount={value.surchargePerKwh}
                currency={currency}
                label={t('advancedTariff.surchargePerKwh', 'surcharge per kWh')}
              />
            </List.Item>
          </>

          <List.Item>
            <PriceText
              amount={value?.pricePerSession}
              currency={currency}
              label={t('advancedTariff.perSession', 'per session')}
            />
          </List.Item>
          <List.Item>
            <PriceText
              amount={value?.pricePerHour}
              currency={currency}
              label={t('advancedTariff.perHour', 'per hour')}
            />
          </List.Item>
        </List>
      </Grid.Column>
    ),
  };
}

// TODO: Refactor this to createBackupPriceColumn. Using use*** is confusing.
/**
 *
 * @param {Object} value
 * @param {number?} value.pricePerKwh
 * @param {number?} value.pricePerSession
 * @param {number?} value.pricePerHour
 * @param {string} currency
 * @return {UIRow}
 */
function useBackupPriceColumn(value, currency) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);
  return {
    Header: (
      <Grid.Column key={`idle-fee-column-header-${uiKey}`}>
        <SummaryLabel>
          {t('advancedTariff.backupPrices', 'Backup Price')}
        </SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`idle-fee-column-content-${uiKey}`}>
        <List>
          <List.Item>
            <PriceText
              amount={value?.pricePerKwh}
              currency={currency}
              label={t('advancedTariff.backupPricePerKwh', 'per kWh')}
            />
          </List.Item>
        </List>
      </Grid.Column>
    ),
  };
}

// TODO: Refactor this to createIdleFeeColumn. Using use*** is confusing.
/**
 *
 * @param {Object} value
 * @param {number?} value.pricePerKwh
 * @param {number?} value.pricePerSession
 * @param {number?} value.pricePerHour
 * @param {string} currency
 * @return {UIRow}
 */
function useIdleFeeColumn(value, currency) {
  const { t } = useTranslation();
  const uiKey = useMemo(() => Math.random(), []);
  return {
    Header: (
      <Grid.Column key={`idle-fee-column-header-${uiKey}`}>
        <SummaryLabel>{t('advancedTariff.idleFee', 'Idle Fee')}</SummaryLabel>
      </Grid.Column>
    ),
    Content: (
      <Grid.Column key={`idle-fee-column-content-${uiKey}`}>
        <List>
          {value?.pricePerIdleMinute > 0 && (
            <>
              <List.Item>
                <PriceText
                  amount={value.pricePerIdleMinute}
                  currency={currency}
                  label={t('advancedTariff.perMinute', 'per minute')}
                />
              </List.Item>
              <List.Item>
                <span style={{ fontWeight: 'bold' }}>
                  {value.idleGracePeriodMinutes ?? 0} min
                </span>{' '}
                <span style={{ textTransform: 'lowercase' }}>
                  {t(
                    'advancedTariff.idleFeeWaitTime',
                    'wait time before idle fee'
                  )}
                </span>
              </List.Item>
            </>
          )}
          {!value?.pricePerIdleMinute && (
            <List.Item>
              <i>{t('advancedTariff.none', 'None')}</i>
            </List.Item>
          )}
        </List>
      </Grid.Column>
    ),
  };
}
