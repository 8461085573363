import { uniq } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

export function toggle<T>(
  setSelectedItems: Dispatch<SetStateAction<T[]>>,
  selectedItems: T[],
  item: T,
  equal: (a: T, b: T) => boolean,
  checked: boolean
) {
  checked
    ? setSelectedItems(uniq([...selectedItems, item]))
    : setSelectedItems(selectedItems.filter((s) => !equal(s, item)));
}

export function toggleAll<T>(
  setSelectedItems: Dispatch<SetStateAction<T[]>>,
  selectedItems: T[],
  items: T[] = [],
  equal: (a: T, b: T) => boolean,
  checked: boolean
) {
  setSelectedItems(
    checked
      ? uniq([...selectedItems, ...items])
      : selectedItems.filter((s) => !items.some((i) => equal(s, i)))
  );
}
