import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import EditBillingPlanModal from 'components/modals/EditBillingPlanModal';
import { currentUserCanAccess } from 'utils/roles';
import { Divider, Button, Segment } from 'semantic';
import {
  Breadcrumbs,
  Search,
  ListHeader,
  SearchFilters,
  Layout,
} from 'components';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import Table from './Table';

const onDataNeeded = async (filters) => {
  return request({
    method: 'POST',
    path: '/1/billing-plans/search',
    body: {
      ...filters,
      includeCount: true,
    },
  });
};

export default function BillingPlans({ prefixPath = '/finance' }) {
  const searchRef = useRef();
  const { t } = useTranslation();

  const writeAccess = currentUserCanAccess('billingPlans', 'write');

  return (
    <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
      {({ reload }) => {
        return (
          <>
            <Breadcrumbs
              path={[
                <Link key="finance" to={prefixPath}>
                  {t('finance.title', 'Finance')}
                </Link>,
              ]}
              active={t('billingPlans.title', 'Billing Plans')}
            />
            <ListHeader title={t('billingPlans.title', 'Billing Plans')}>
              <EditBillingPlanModal
                onSave={reload}
                onSubmit={(body) =>
                  request({
                    method: 'POST',
                    path: '/1/billing-plans',
                    body,
                  })
                }
                trigger={
                  <Button
                    primary
                    floated="right"
                    style={{ marginTop: '-5px' }}
                    content={t('billingPlans.newPlan', 'New Billing Plan')}
                    icon="plus"
                    disabled={!writeAccess}
                  />
                }
              />
            </ListHeader>

            <Segment>
              <Layout horizontal spread stackable>
                <Layout horizontal stackable center right>
                  <Search.Total />
                  <SearchFilters.Search
                    name="name"
                    placeholder={t('billingPlans.searchPlaceHolder', 'Name')}
                  />
                </Layout>
              </Layout>
            </Segment>

            <Search.Status
              noItems={t(
                'billingPlans.noPlansYet',
                'No BillingPlans created yet'
              )}
            />
            <Table />
            <Divider hidden />
            <div
              style={{
                textAlign: 'center',
              }}>
              <Search.Pagination />
            </div>
          </>
        );
      }}
    </Search.Provider>
  );
}
