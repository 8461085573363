import { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const currentVersion = process.env.RELEASE_VERSION;

const fetchVersion = async () => {
  try {
    const res = await fetch('/__version', {
      method: 'GET',
    });
    const { version } = await res.json();
    return version;
  } catch (e) {
    console.warn('unable to fetch current version', e);
    return null;
  }
};

export default function useUpdateDetector(): boolean {
  const interval = useRef<number>(-1);
  const nextVersion = useRef<string>();
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const checkForUpdate = async () => {
    const fetchedVersion = await fetchVersion();
    if (!fetchedVersion || fetchedVersion === 'Unknown') {
      // no op
      return;
    }
    if (fetchedVersion === currentVersion) {
      // no update
      return;
    }
    // we have a new version, check if we've seen it before
    if (nextVersion.current !== fetchedVersion) {
      // first time seeing this version -> don't notify yet, wait for the next time
      // this is to mitigate the case where a rolling an update is in progress, and we see multiple versions
      nextVersion.current = fetchedVersion;
      return;
    }
    // second time seeing this version -> notify
    setUpdateAvailable(true);
    // clear interval to stop checking
    clearInterval(interval.current);
  };

  useEffect(() => {
    (async () => {
      // check every 30 seconds
      interval.current = setInterval(checkForUpdate, 1000 * 30);
      // check immediately
      await checkForUpdate();
    })().catch(console.error);
    // clear interval on unmount
    return () => clearInterval(interval.current);
  }, []);

  return updateAvailable;
}
