import React from 'react';
import { Form, Message, Divider, Button, Loader } from 'semantic';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { currentUserCanAccess } from 'utils/roles';
import { Formik } from 'formik';
import FormVerticalSpace from '../../../components/atoms/FormVerticalSpace';
import FormHeader from '../../../components/atoms/FormHeader';
import InputField from '../../../../../components/form-fields/formik/InputField';
import SelectCountry from '../../../../../components/form-fields/formik/SelectCountry';
import Group from '../../../../../components/Layout/helpers/Group';
import { useAccount } from 'contexts/account';
import { useMutation } from '@tanstack/react-query';
import { useReimbursementValidation } from './reimbursement.validation';
import { useFeatures } from 'contexts/features';

interface OrganizationReimbursementFormValues {
  accountHolderName: string;
  ibanNo: string;
  bicNo: string;
  referenceText: string;
  email: string;
  countryCode: string;
  address: string;
  addressLine2: string;
  postalCode: string;
  city: string;
}

export default function OrganizationReimbursementForm() {
  const { t } = useTranslation();
  const { hasFeature, isReady: isFeatureReady } = useFeatures();
  const { account } = useAccount();
  const creditBilling = account?.creditBilling;

  const isBicCollectionEnabled = hasFeature('billing_bic_collection');

  const onSubmitMutation = useMutation(
    async (values: OrganizationReimbursementFormValues) => {
      await request({
        method: 'PATCH',
        path: '/1/accounts/self',
        body: {
          creditBilling: values,
        },
      });
    }
  );

  const validationSchema = useReimbursementValidation();

  const cannotEditBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'write'
  );

  const cannotReadBillingInformation = !currentUserCanAccess(
    'accounts-billing',
    'read'
  );

  if (cannotReadBillingInformation) {
    return (
      <>
        <Message warning>
          {t(
            'access.cannotViewBillingInformation',
            'You do not have sufficient permissions to view billing information.'
          )}
        </Message>
      </>
    );
  }
  if (!isFeatureReady) {
    return (
      <Loader active={true}>{t('query.states.loading', 'Loading')}</Loader>
    );
  }
  return (
    <Formik
      initialValues={{
        accountHolderName: creditBilling?.accountHolderName || '',
        ibanNo: creditBilling?.ibanNo || '',
        bicNo: creditBilling?.bicNo || '',
        referenceText: creditBilling?.referenceText || '',
        email: creditBilling?.email || '',
        countryCode: creditBilling?.countryCode || '',
        address: creditBilling?.address || '',
        addressLine2: creditBilling?.addressLine2 || '',
        postalCode: creditBilling?.postalCode || '',
        city: creditBilling?.city || '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmitMutation.mutate}
      enableReinitialize>
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Form
          error={onSubmitMutation.isError}
          onSubmit={handleSubmit}
          loading={onSubmitMutation.isLoading}>
          <FormHeader
            pt={5}
            pb={5}
            text={t(
              'settings.organization.reimbursement.title',
              'Organization Reimbursement'
            )}
          />
          <Divider />
          {onSubmitMutation.isError && (
            <Message error content={(onSubmitMutation.error as any)?.message} />
          )}
          {onSubmitMutation.isSuccess && (
            <Message
              success
              content={t('messages.updated', 'Successfully updated!')}
            />
          )}
          <p style={{ maxWidth: 600 }}>
            {t(
              'settings.organization.reimbursement.description',
              "        Fill in the organization's bank account information in order to receive automatic reimbursements for charging sessions on your business locations."
            )}
          </p>
          <p>
            {t(
              'settings.organization.reimbursement.description2',
              'You can find your invoices that will be reimbursed under '
            )}
            <a href="/payout-invoices">
              {t(
                'settings.organization.reimbursement.invoices',
                'My Self-billing invoices'
              )}
            </a>
            .
          </p>
          {cannotEditBillingInformation && (
            <>
              <FormVerticalSpace size={5} />
              <Message info>
                {t(
                  'access.cannotEditBillingInformation',
                  'You do not have sufficient permissions to edit billing information.'
                )}
              </Message>
            </>
          )}
          <FormHeader
            size="medium"
            mt={20}
            mb={15}
            text={t(
              'settings.organization.reimbursement.headers.reimbursementInfo',
              'Reimbursement Information'
            )}
          />
          <InputField
            required
            name="accountHolderName"
            label={t(
              'settings.organization.reimbursement.accountHolderName',
              'Account Holder Name'
            )}
            wrapperStyle={{ maxWidth: 335 }}
            readOnly={cannotEditBillingInformation}
          />
          <FormVerticalSpace />
          <InputField
            required
            name="ibanNo"
            label={t('settings.organization.reimbursement.iban', 'IBAN Number')}
            wrapperStyle={{ maxWidth: 335 }}
            readOnly={cannotEditBillingInformation}
          />
          <FormVerticalSpace />
          {isBicCollectionEnabled && (
            <>
              <InputField
                required
                name="bicNo"
                label={t(
                  'settings.organization.reimbursement.bic',
                  'BIC Number'
                )}
                wrapperStyle={{ maxWidth: 335 }}
                readOnly={cannotEditBillingInformation}
              />
              <FormVerticalSpace />
            </>
          )}
          <InputField
            name="referenceText"
            label={t(
              'settings.organization.reimbursement.referenceText',
              'Invoice Reference (Reimbursement)'
            )}
            placeholder="Reimbursement from Road"
            wrapperStyle={{ maxWidth: 335 }}
            readOnly={cannotEditBillingInformation}
          />
          <FormVerticalSpace />
          <FormHeader
            size="medium"
            mt={20}
            mb={15}
            text={t(
              'settings.organization.reimbursement.headers.addressInfo',
              'Address'
            )}
          />
          <InputField
            required
            name="email"
            label={t(
              'settings.organization.reimbursement.email',
              'Reimbursement Billing E-mail'
            )}
            wrapperStyle={{ maxWidth: 335 }}
            readOnly={cannotEditBillingInformation}
          />
          <FormVerticalSpace />
          <SelectCountry
            required
            name="countryCode"
            label={t('settings.organization.reimbursement.country', 'Country')}
            wrapperStyle={{ maxWidth: 335 }}
            lowerCase={false}
            readOnly={cannotEditBillingInformation}
          />
          <FormVerticalSpace />
          <Group align="flex-start" spacing={'xs'} style={{ maxWidth: 335 }}>
            <InputField
              required
              name="address"
              label={t('settings.organization.reimbursement.address', 'Street')}
              wrapperStyle={{ width: 239 }}
              readOnly={cannotEditBillingInformation}
            />

            <InputField
              required
              name="addressLine2"
              label={t(
                'settings.organization.reimbursement.addressLine2',
                'No.'
              )}
              autoComplete="disabled"
              wrapperStyle={{ maxWidth: 80 }}
              readOnly={cannotEditBillingInformation}
            />
          </Group>
          <FormVerticalSpace />
          <Group align="flex-start" spacing={'xs'} style={{ maxWidth: 335 }}>
            <InputField
              name="postalCode"
              label={t(
                'settings.organization.reimbursement.postalCode',
                'Post Code'
              )}
              wrapperStyle={{ maxWidth: 134 }}
              required
              readOnly={cannotEditBillingInformation}
            />

            <InputField
              required
              name="city"
              label={t('settings.organization.reimbursement.city', 'City/Town')}
              wrapperStyle={{ maxWidth: 185 }}
              readOnly={cannotEditBillingInformation}
            />
          </Group>
          <FormVerticalSpace size={50} />
          <Button
            primary
            type="submit"
            disabled={cannotEditBillingInformation || !dirty}
            style={{ marginLeft: 0 }}>
            {t(
              'settings.organization.reimbursement.updateButton',
              'Update Payment Information'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
