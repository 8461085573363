import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Table } from 'semantic';

import { Link } from 'react-router-dom';
import { Search } from 'components';

import { formatDateTime } from 'utils/date';

function BatchesTable() {
  const { t } = useTranslation();
  const { items, loading } = useContext(Search.Context);

  if (!items.length || loading) {
    return null;
  }

  return (
    <Table celled sortable>
      <Table.Header>
        <Header t={t} />
      </Table.Header>
      <Table.Body>
        {items.map((item, i) => (
          <Row key={i} item={item} />
        ))}
      </Table.Body>
    </Table>
  );
}

function Header({ t }) {
  const translations = useMemo(
    () => ({
      note: t('cards.token-batches.note', 'Note'),
      size: t('cards.token-batches.size', 'Size'),
      type: t('cards.token-batches.type', 'Type'),
      createdAt: t('cards.token-batches.createdAt', 'Created at'),
      createdBy: t('cards.token-batches.createdBy', 'Created by'),
    }),
    [t]
  );

  return (
    <Table.Row>
      <Table.HeaderCell>{translations.note}</Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.size}</Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.type}</Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.createdAt}</Table.HeaderCell>
      <Table.HeaderCell width={3}>{translations.createdBy}</Table.HeaderCell>
    </Table.Row>
  );
}

function Row({ item }) {
  return (
    <Table.Row key={item._id}>
      <Table.Cell>
        <Link to={`/cards/token-batches/${item.id.toString()}`}>
          {item.note}
        </Link>
      </Table.Cell>
      <Table.Cell>{item.size}</Table.Cell>
      <Table.Cell>{item.type}</Table.Cell>
      <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
      <Table.Cell>
        {item.createdBy && item.createdBy.id && item.createdBy.name ? (
          <Link to={`/accounts/users/${item.createdBy.id}`}>
            {item.createdBy.name}
          </Link>
        ) : (
          'No Name'
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default React.memo(BatchesTable);
