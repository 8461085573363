import React from 'react';
import { Form } from 'semantic';

import SearchContext from '../../old_search/Context';

export default function Search({ name, placeholder }) {
  const { getFilterValue, onFilterChange, loading } =
    React.useContext(SearchContext);

  const propsValue = getFilterValue(name);
  const [value, setValue] = React.useState(propsValue);

  React.useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  return (
    <Form onSubmit={() => onFilterChange({}, { value, name })}>
      <Form.Input
        value={value || ''}
        name="search"
        placeholder={placeholder}
        disabled={loading}
        icon={{
          name: value ? 'close' : 'magnifying-glass',
          link: true,
          onClick: (evt) => {
            if (value) {
              onFilterChange({}, { value: undefined, name });
              setValue('');
              evt.currentTarget.parentNode.querySelector('input').focus();
            }
          },
        }}
        type="text"
        onChange={(e, { value }) => setValue(value.length ? value : undefined)}
      />
    </Form>
  );
}
