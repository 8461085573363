import React, { useEffect, useState } from 'react';
import { Accordion, Button, Form, Icon, Message, Modal, Table } from 'semantic';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import Address from 'components/Address';
import { useTranslation } from 'react-i18next';
import AsyncModal from 'helpers/async-modal';
import { request } from 'utils/api';
import { Popup } from '../../semantic';
import { FeatureFlag, SecretSpan } from 'components';
import { useFeatures } from 'contexts/features';
import CardStatusTable from 'components/modals/SyncLogStatus/Audit/CardStatusTable';
import TokenStatusTable from 'components/modals/SyncLogStatus/Audit/TokenStatusTable';
import { formatPhone } from 'utils/formatting';

function EditCard(props) {
  const [open, setOpen] = useState(true);
  const [card, setCard] = useState({ ...props.data });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [account, setAccount] = useState(null);
  const [billingPlans, setBillingPlans] = useState([]);
  const { hasFeature } = useFeatures();

  const { t } = useTranslation();

  useEffect(() => {
    card.accountId && fetchAccount(card.accountId);
  }, []);

  const fetchAccount = async (accountId) => {
    try {
      const { data: account } = await request({
        method: 'GET',
        path: `/1/accounts/${accountId}`,
      });
      setAccount(account);
      fetchBillingPlans(account?.billingCurrency);
    } catch (error) {
      setError(error);
    }
  };

  const fetchBillingPlans = async (currency) => {
    try {
      const { data: billingPlans } = await request({
        path: '/1/billing-plans/search',
        method: 'POST',
        body: { currency, providerContext: 'msp' },
      });
      setBillingPlans(billingPlans);

      // check if chosen tariff profile is in the list
      if (!billingPlans.find((i) => i.id === card.billingPlanId)) {
        setField('billingPlanId', '');
      }
    } catch (error) {
      setError(error);
    }
  };

  const getStatusOptions = () => {
    return [
      {
        text: t('editCard.statusPending', 'Pending'),
        value: 'pending',
      },
      {
        text: t('editCard.statusSent', 'Sent'),
        value: 'sent',
      },
      {
        text: t('editCard.statusActive', 'Active'),
        value: 'active',
      },
      {
        text: t('editCard.statusDisabled', 'Disabled'),
        value: 'disabled',
      },
    ];
  };

  const getTagOptions = () => {
    return [
      {
        text: t('editCard.cardTypeTag', 'Tag'),
        value: 'tag',
      },
      {
        text: t('editCard.cardTypeCard', 'Card'),
        value: 'card',
      },
    ];
  };

  const onSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      await request(
        card.id
          ? {
              method: 'PATCH',
              path: `/1/cards/${card.id}`,
              body: card,
            }
          : {
              method: 'POST',
              path: '/1/cards',
              body: card,
            }
      );
      props.close();
      props.onSave();
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  const setField = (name, value) => {
    setCard((prevCard) => ({
      ...prevCard,
      [name]: value,
    }));
  };

  const isUpdate = !!card.id;

  return (
    <>
      <Modal.Header>
        {isUpdate
          ? t('editCard.titleEdit', 'Edit Card {{name}}', {
              name: card.nameOnCard,
            })
          : t('editCard.titleNew', 'New Card')}
      </Modal.Header>
      <Modal.Content>
        <Form error={Boolean(error)} onSubmit={() => onSubmit()}>
          {error && <Message error content={error.message} />}
          {isUpdate && (
            <Form.Select
              value={card.status}
              options={getStatusOptions()}
              name="status"
              label={t('editCard.status', 'Status')}
              required
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          )}
          <Form.Select
            required
            value={card.type}
            options={getTagOptions()}
            name="type"
            label={t('editCard.type', 'Type')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          {isUpdate && (
            <Form.Input
              value={card.nameOnCard}
              name="nameOnCard"
              label={t('editCard.nameOnCard', 'Name On Card')}
              type="text"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          )}

          <Form.Field required>
            <label>{t('editCard.account', 'Account')}</label>
            <SearchDropdown
              value={card.accountId}
              objectMode={false}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/accounts/search',
                  method: 'POST',
                  body: body,
                });
              }}
              onChange={(e, { value }) => {
                setField('accountId', value);
                if (value) {
                  fetchAccount(value);
                } else {
                  setAccount(null);
                  setField('billingPlanId', '');
                }
              }}
            />
          </Form.Field>

          <Form.Field required>
            <label>{t('editCard.user', 'User')}</label>
            <SearchDropdown
              keywordField="searchPhrase"
              key={card.accountId}
              value={card.userId}
              objectMode={false}
              getOptionLabel={(item) => `${item.name} (${item.email})`}
              onDataNeeded={(body) => {
                return request({
                  path: '/1/users/search/fast',
                  method: 'POST',
                  body: {
                    ...body,
                    accountId: card.accountId,
                  },
                });
              }}
              onChange={(e, { value }) => setField('userId', value)}
            />
          </Form.Field>

          <Popup
            disabled={!!card.accountId}
            content={t(
              'editCard.billingPlanDisabled',
              'Billing Plan is available only if the account is set.'
            )}
            position="bottom left"
            trigger={
              <Form.Field>
                <Form.Select
                  required
                  clearable
                  disabled={!card.accountId}
                  value={card.billingPlanId}
                  options={billingPlans.map((i) => {
                    return { value: i.id, text: i.name };
                  })}
                  name="billingPlanId"
                  label="Billing Plan"
                  type="text"
                  onChange={(e, { name, value }) => setField(name, value)}
                />
              </Form.Field>
            }
          />

          {card.delivery && (
            <Form.Field>
              <label>
                {t('editCard.customAddress', 'Custom Delivery Address')}
              </label>
              <Address {...card.delivery} />
            </Form.Field>
          )}

          {!card.delivery && card.account && card.account.billing && (
            <Form.Field>
              <label>
                {t(
                  'editCard.billingDeliveryAddress',
                  'Billing Delivery Address'
                )}
              </label>
              <Address
                firstName={card.requestUserFirstName}
                lastName={card.requestUserLastName}
                {...card.account.billing}
              />
            </Form.Field>
          )}

          {card.token && (
            <Form.Field>
              <label>{t('cards.columnToken', 'Token')}</label>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={6}>
                      {t(
                        'editToken.visualNumber',
                        'Visual Number (overrides Custom ID)'
                      )}
                    </Table.Cell>
                    <Table.Cell>{card.token.visualNumber}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      {t('editToken.customID', 'Custom ID')}
                    </Table.Cell>
                    <Table.Cell>{card.token.customId || ''}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>{t('tokens.columnUID', 'UID')}</Table.Cell>
                    <Table.Cell>
                      <SecretSpan value={card.token.uid || ''} />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form.Field>
          )}

          {!isUpdate && (
            <Form.Field required>
              <Form.Checkbox
                label={t('editCard.sendEmail', 'Send email to user')}
                name="sendEmail"
                checked={card.sendEmail || false}
                onChange={(e, { name, checked }) =>
                  setField('sendEmail', checked)
                }
              />
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          disabled={loading}
          primary
          content={isUpdate ? t('button.update') : t('button.create')}
          onClick={onSubmit}
        />
      </Modal.Actions>
    </>
  );
}

export default AsyncModal(EditCard);
