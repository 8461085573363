import React from 'react';
import { Placeholder } from 'semantic';

export default function SkeletonLoader() {
  const headerLines = Array.from({ length: 2 });
  const paragraphLines1 = Array.from({ length: 4 });
  const paragraphLines2 = Array.from({ length: 8 });

  return (
    <Placeholder>
      <Placeholder.Header>
        {headerLines.map((_, index) => (
          <Placeholder.Line key={index} />
        ))}
      </Placeholder.Header>
      <Placeholder.Paragraph>
        {paragraphLines1.map((_, index) => (
          <Placeholder.Line key={index} />
        ))}
      </Placeholder.Paragraph>
      <Placeholder.Paragraph>
        {paragraphLines2.map((_, index) => (
          <Placeholder.Line key={index} />
        ))}
      </Placeholder.Paragraph>
    </Placeholder>
  );
}
