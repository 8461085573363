import React from 'react';
import { request } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';
import { canAccess } from '../../utils/roles';

const INVOICE_TYPE = {
  subscriptionBilling: 'cpo-usage',
  usageReimbursement: 'cpo-credit',
  usageReimbursementDelayedSessions: 'cpo-credit-delayed-sessions',
  usageBilling: 'msp-usage',
  usageBillingDelayedSessions: 'msp-usage-delayed-sessions',
} as const;

type InvoiceType = (typeof INVOICE_TYPE)[keyof typeof INVOICE_TYPE];

interface Invoice {
  id: string;
  identifier: string;
  type: InvoiceType;
}

export default function Actions(props: { invoice: Invoice }) {
  const { invoice } = props;
  const { t } = useTranslation();
  const { user, provider } = useUser();
  const { isReady, hasFeature } = useFeatures();

  const invoiceReadAccess = canAccess(
    user,
    provider.id,
    user.accountId,
    'invoices',
    'read'
  );

  const showDownloadBillableItems =
    isReady && hasFeature('export_billable_items') && invoiceReadAccess;

  return (
    <>
      <Dropdown.Item
        onClick={() => {
          request({
            path: `/1/invoices/mine/${invoice.id}/sessions/download`,
            method: 'GET',
            params: {
              filename: `${invoice.identifier}-sessions.csv`,
            },
          });
        }}
        icon="file-csv"
        text={t(
          'invoices.tableColumn.actions.downloadSessions',
          'Download Sessions'
        )}
        disabled={invoice.type === INVOICE_TYPE.subscriptionBilling}
      />
      {showDownloadBillableItems && (
        <Dropdown.Item
          onClick={() => {
            request({
              path: `/1/invoices/${invoice.id}/billable-items/export`,
              method: 'GET',
            });
          }}
          icon="file-csv"
          text={t(
            'invoices.tableColumn.actions.downloadBillableItems',
            'Download Invoice Billable Items'
          )}
        />
      )}
    </>
  );
}
