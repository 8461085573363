import {
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  AreaChart,
} from 'recharts';
import React from 'react';

export default function GrowthChart({ timeSeries }) {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <AreaChart data={timeSeries}>
        <XAxis dataKey="day" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Area
          type="monotone"
          dot
          dataKey="count"
          stroke="#f89522"
          fill="#f89522"
        />
        <Area
          type="monotone"
          dataKey="amount"
          stackId="1"
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
