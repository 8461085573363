import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { Container, Divider, Grid, Segment } from '../../../semantic';
import { Button, Icon } from 'semantic';
import { ErrorMessage, ListHeader } from 'components';
import Filters from './Filters';
import Search from 'components/SearchInput';
import DataList from 'components/DataList';
import Table from './BatchTokenIdsTable';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../../utils/date';
import ImportBatchTokens from '../../../components/modals/ImportBatchTokens';
import { currentUserCanAccessProviderEndpoint } from '../../../utils/roles';

const itemLimit = 100;

export default function BatchTokenIds(props) {
  const { t } = useTranslation();

  const [exportingOrImportingBatch, setExportingOrImportingBatch] =
    useState(false);
  const [exportOrImportError, setExportOrImportError] = useState();
  const [dateKey, setDateKey] = useState(Date.now());
  const [batch, setBatch] = useState();
  const [batchError, setBatchError] = useState();
  const [searchId, setSearchId] = useState('');
  const [filters, setFilters] = useState({});

  const disableImportButton = !currentUserCanAccessProviderEndpoint(
    'tokenBatches',
    'read-write'
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await getBatch();
      setBatch(response);
    };
    fetchData();
  }, []);

  const getBatchId = () => {
    return props.match.params.id;
  };

  const getBatch = async () => {
    try {
      const response = await request({
        method: 'GET',
        path: `/1/tokens/batch/${getBatchId()}`,
      });
      return response.data;
    } catch (error) {
      setBatchError(error);
    }
  };

  const exportBatch = async () => {
    setExportingOrImportingBatch(true);
    setExportOrImportError(undefined);
    try {
      await request({
        method: 'GET',
        path: `/1/tokens/batch/${getBatchId()}/export`,
      });
    } catch (error) {
      setExportOrImportError({
        message: `Error exporting batch: ${error.message}`,
      });
    } finally {
      setExportingOrImportingBatch(false);
    }
  };

  const handleRequest = (appliedFilters = {}) => {
    return request({
      method: 'POST',
      path: '/1/tokens/search',
      body: {
        ...appliedFilters,
        ...(searchId ? { keywords: searchId } : {}),
        ...filters,
        batchId: getBatchId(),
        limit: itemLimit,
        excludeEmptyCustomId: false,
        includeEmptyUid: true,
      },
    });
  };

  const handleFilterChange = (filters) => {
    setFilters(
      filters.reduce(
        (result, item) => ({
          ...result,
          ...(item.apiFilter || {}),
        }),
        {}
      )
    );
    refresh();
  };

  const handleOnSearch = (searchId) => {
    setSearchId(searchId);
    refresh();
  };

  const refresh = () => {
    setDateKey(Date.now());
  };

  return (
    <Container>
      <Breadcrumbs
        path={[
          <Link key="cards" to="/cards">
            {t('cards.title', 'Cards')}
          </Link>,
          <Link key="token-batches" to="/cards/token-batches">
            {t('cards.token-batches.title', 'Token Batches')}
          </Link>,
        ]}
        active={batch?.note}
      />

      {batch && (
        <>
          <Grid reversed="computer">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              textAlign="right"
              verticalAlign="middle"></Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <ListHeader
                as="h2"
                title={t('cards.token-batches.single-title', 'Token Batch')}>
                <ImportBatchTokens
                  batchId={getBatchId()}
                  trigger={
                    <Button
                      primary
                      content={t(
                        'cards.token-batches.import-batch',
                        'Import Tokens'
                      )}
                      icon={
                        exportingOrImportingBatch ? (
                          <Icon loading name="spinner" />
                        ) : (
                          'upload'
                        )
                      }
                      disabled={
                        disableImportButton || exportingOrImportingBatch
                      }
                      as="button"
                    />
                  }
                />

                <Button
                  primary
                  content="Export"
                  icon={
                    exportingOrImportingBatch ? (
                      <Icon loading name="spinner" />
                    ) : (
                      'download'
                    )
                  }
                  disabled={exportingOrImportingBatch}
                  onClick={exportBatch}
                  as="button"
                />
              </ListHeader>
            </Grid.Column>
          </Grid>

          <Divider />

          {exportOrImportError && <ErrorMessage error={exportOrImportError} />}

          <p>
            Token Batch Note: <strong>{batch?.note}</strong>
          </p>
          <p>
            Batch created at:{' '}
            <strong> {formatDateTime(batch?.createdAt)}</strong>
          </p>
          <p>
            Batch created by:
            <strong>
              {' '}
              {batch.createdBy && batch.createdBy.id && batch.createdBy.name ? (
                <Link to={`/accounts/users/${batch.createdBy.id}`}>
                  {batch.createdBy.name}
                </Link>
              ) : (
                'No Name'
              )}
            </strong>
          </p>
          <p>
            Requested Size of Batch:
            <strong> {batch?.size}</strong>
          </p>

          <Divider />

          <Segment>
            <Grid>
              <Grid.Row width={16}>
                <Grid.Column width={10}>
                  <Filters onChange={handleFilterChange} />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Search
                    placeholder="Search by emiContract "
                    onChange={handleOnSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <p>
            These are generated Contract Ids. Contract Ids that have not been
            assigned are shown with an `*` in place of the Country Code, Party
            Id and Checksum Digit.
          </p>

          <DataList key={dateKey} limit={itemLimit} onRequest={handleRequest}>
            <Table onRefresh={refresh} />
          </DataList>
          <Divider hidden />
        </>
      )}

      {batchError && <ErrorMessage error={batchError} />}
    </Container>
  );
}
