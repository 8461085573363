import React from 'react';
import { useTranslation } from 'react-i18next';
import { RedirectPanel } from 'screens/Auth/SignupV2/steps/Completed/RedirectPanel';

interface Props {
  isLoading: boolean;
  error?: boolean;
  errorMessage?: string;
  hideBelowContent?: boolean;
  onSubmit: () => void;
  postSignupRedirect: string;
  loginPanelText: string;
  navigationDirection: 'none' | 'forward' | 'backward';
  platformName: string;
}

export default function ChargeCardOrdered(props: Props) {
  const { t } = useTranslation();

  const platformName = props.platformName;

  return (
    <RedirectPanel
      {...props}
      headerText={t(
        'signup.chargeCardOrderCompletedHeader',
        'Charge card order completed'
      )}
      subHeaderText={t(
        'signup.chargeCardOrderCompletedSubheader',
        `Your account has been created`
      )}
      content={
        <>
          <p>
            {t(
              'signup.chargeCardOrderedText',
              'We will now log you in to the {{platformName}} Dashboard',
              { platformName }
            )}
          </p>
        </>
      }
    />
  );
}
