import React from 'react';
import * as Search from 'components/search';
import { Divider } from 'semantic';
import Table from './Table';
import { withTranslation } from 'react-i18next';
import { request } from 'utils/api';

class SyncLogs extends React.Component {
  onDataNeeded = async (filters) => {
    const { doc } = this.props;

    return request({
      method: 'POST',
      path: '/1/sync-log/search',
      body: {
        ...filters,
        subjectId: doc.id,
      },
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Search.Provider onDataNeeded={this.onDataNeeded} limit={30}>
        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table />
        <Divider hidden />
        <div
          style={{
            textAlign: 'center',
          }}>
          <Search.Pagination />
        </div>
      </Search.Provider>
    );
  }
}

export default withTranslation()(SyncLogs);
