import React, { useEffect, useMemo, useState } from 'react';
import { Form, Message, Segment, Header, Divider, Button } from 'semantic';
import { request } from 'utils/api';
import allCountries from 'utils/countries';
import Finalize from './Finalize';
import PageCenter from 'components/PageCenter';
import LogoTitle from 'components/LogoTitle';
import { Link, useHistory } from 'react-router-dom';
import Code from 'components/form-fields/Code';
import { Layout } from 'components/Layout';
import { useTranslation } from 'react-i18next';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

export default function Sms() {
  const queryClient = useMemo(() => {
    return new QueryClient();
  }, []);
  useEffect(() => () => queryClient.clear(), []);

  return (
    <QueryClientProvider client={queryClient}>
      <SmsContainer />
    </QueryClientProvider>
  );
}

function SmsContainer() {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [countryCallingCode, setCountryCallingCode] = useState<number>();
  const [code, setCode] = useState('');
  const [secret, setSecret] = useState('');
  const [isComplete, setIsComplete] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const selectedCountry = allCountries.find((c) => c.nameEn === country);
    if (!selectedCountry || !selectedCountry.callingCode) {
      return;
    }

    setCountryCallingCode(selectedCountry.callingCode);
  }, [country]);

  useEffect(() => {
    if (isComplete) {
      onVerify();
    }
  }, [isComplete]);

  const triggerSmsMutation = useMutation(
    async () => {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/setup',
        body: {
          method: 'sms',
          phoneNumber: `+${countryCallingCode}${phoneNumber}`,
        },
      });

      return data;
    },
    {
      onSuccess: (data) => {
        setSecret(data.secret);
      },
      onError: (error: any) => {
        if (error.status == 403) {
          history.push(
            '/confirm-access?to=/settings/account/organization/mfa-sms'
          );
          return;
        }
      },
    }
  );

  const triggerSms = async () => {
    triggerSmsMutation.mutate();
  };

  const onVerifyMutation = useMutation(
    async () => {
      await request({
        method: 'POST',
        path: '/1/mfa/check-code',
        body: {
          code,
          secret,
          method: 'sms',
        },
      });
    },
    {
      onSuccess: () => {
        getBackupCodesQuery.refetch();
      },
      onError: (error: any) => {
        if (error.status == 403) {
          history.push(
            '/confirm-access?to=/settings/account/organization/mfa-sms'
          );
          return;
        }
      },
    }
  );

  const getBackupCodesQuery = useQuery(
    ['/1/mfa/generate-backup-codes'],
    async () => {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/generate-backup-codes',
      });

      return data;
    },
    {
      // Set enabled to false to prevent the query from running on mount
      enabled: false,
    }
  );

  const onVerify = async () => {
    onVerifyMutation.mutate();
  };

  const verified =
    secret &&
    onVerifyMutation.isSuccess &&
    getBackupCodesQuery.isSuccess &&
    getBackupCodesQuery.data;

  if (verified) {
    return (
      <Finalize
        method="sms"
        successfulHistoryPushUrl="/settings/personal/password-and-authentication"
        requestBody={{
          secret,
          method: 'sms',
          phoneNumber: `+${countryCallingCode}${phoneNumber}`,
          backupCodes: getBackupCodesQuery.data,
        }}
        codes={getBackupCodesQuery.data}
      />
    );
  }

  const countryCallingCodes = allCountries.map(({ nameEn, callingCode }) => ({
    value: nameEn,
    text: `${nameEn} (+${callingCode})`,
    key: `${nameEn}-${callingCode}`,
  }));

  return (
    <PageCenter>
      <LogoTitle
        title={t('settingsSecuritySms.header', 'Set up SMS authentication')}
      />
      <Segment.Group>
        <Segment>
          <Header size="small">
            {t(
              'settingsSecuritySms.step1',
              '1. What’s your mobile phone number?'
            )}
          </Header>
          <p>
            {t(
              'settingsSecuritySms.step1body',
              'Authentication codes will be sent to it.'
            )}
          </p>
          <Form
            onSubmit={triggerSms}
            error={onVerifyMutation.isError && onVerifyMutation.error?.message}>
            {triggerSmsMutation.isError &&
              triggerSmsMutation.error?.message && (
                <Message error content={triggerSmsMutation.error?.message} />
              )}
            <Form.Select
              options={countryCallingCodes}
              search
              value={country}
              label={t('common.countryCode', 'Country Code')}
              required
              type="text"
              autoComplete="tel-country-code"
              onChange={(e, { value }) => setCountry(value as string)}
            />
            <Form.Input
              value={phoneNumber}
              label={t('common.phoneNumber', 'Phone number')}
              required
              type="tel"
              autoComplete="tel-local"
              onChange={(e, { value }) =>
                setPhoneNumber(value.replace(/ /g, ''))
              }
            />
            <Layout horizontal center>
              <Button
                disabled={!country || !phoneNumber}
                type="submit"
                basic
                loading={triggerSmsMutation.isLoading}>
                {t('settingsSecuritySms.sendCode', 'Send authentication Code')}
              </Button>
              {triggerSmsMutation.isSuccess && (
                <div style={{ height: '28px', paddingLeft: '10px' }}>
                  {t(
                    'settingsSecuritySms.sendCodeNote',
                    'It may take a minute to arrive.'
                  )}
                </div>
              )}
            </Layout>
          </Form>
        </Segment>
        <Segment disabled={!triggerSmsMutation.isSuccess}>
          <Header size="small">
            {t(
              'settingsSecuritySms.step2',
              '2. Enter the security code sent to your device'
            )}
          </Header>
          <p>
            {t(
              'settingsSecuritySms.step2body',
              'It may take a minute to arrive.'
            )}
          </p>
          {onVerifyMutation.isError ||
            (getBackupCodesQuery.isError && (
              <Message
                error
                content={
                  (onVerifyMutation.error as any)?.message ||
                  (getBackupCodesQuery.error as any)?.message
                }
              />
            ))}
          <Divider hidden />
          <Layout center>
            <Code
              disabled={!triggerSmsMutation.isSuccess}
              type="number"
              length={6}
              onChange={(value) => setCode(value)}
              onComplete={() => {
                setIsComplete(true);
              }}
            />
          </Layout>
          <Divider hidden />
        </Segment>
        <Segment>
          <Button
            form="authenticator-form"
            primary
            loading={onVerifyMutation.isLoading}
            disabled={
              !triggerSmsMutation.isSuccess ||
              onVerifyMutation.isLoading ||
              code.length !== 6
            }
            onClick={onVerify}
            content={t('settingsSecuritySms.verifyCode', 'Verify')}
          />
          <Button
            as={Link}
            to="/settings/personal/password-and-authentication"
            basic
            floated="right"
            secondary
            content={t('common.cancel', 'Cancel')}
          />
        </Segment>
      </Segment.Group>
    </PageCenter>
  );
}
