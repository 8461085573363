import React from 'react';
import EditOcpiCredential from 'components/modals/EditOcpiCredential';
import { Menu, Divider, Button } from 'semantic';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Search, ListHeader } from 'components';
import Table from './Table';
import { request } from 'utils/api';

const defaultFilter = { providerContext: 'cpo' };

export default function OcpiCredentials() {
  const { t } = useTranslation();

  const filterMapping = { providerContext: { type: 'string' } };

  const onDataNeeded = async (searchFilters) => {
    return request({
      method: 'POST',
      path: '/1/ocpi-credentials/search',
      body: { ...defaultFilter, ...searchFilters },
    });
  };

  return (
    <>
      <Search.Provider
        filterMapping={filterMapping}
        onDataNeeded={onDataNeeded}>
        {({ reload, setFilters, filters = defaultFilter }) => {
          if (!filters.providerContext) {
            setFilters({ ...defaultFilter, ...filters });
          }
          return (
            <>
              <Breadcrumbs
                active={t('ocpiCredentials.title', 'OCPI Credentials')}
              />

              <ListHeader
                title={t('ocpiCredentials.title', 'OCPI Credentials')}>
                <EditOcpiCredential
                  onSave={reload}
                  initialValues={{ providerContext: filters.providerContext }}
                  trigger={
                    <Button
                      primary
                      content={t(
                        'ocpiCredentials.newCredential',
                        'New Credential'
                      )}
                      icon="plus"
                    />
                  }
                />
              </ListHeader>

              <Menu secondary pointing stackable>
                <Menu.Item
                  content="CPO"
                  active={filters.providerContext === 'cpo'}
                  onClick={() => setFilters({ providerContext: 'cpo' })}
                />
                <Menu.Item
                  content="MSP"
                  active={filters.providerContext === 'msp'}
                  onClick={() => setFilters({ providerContext: 'msp' })}
                />
              </Menu>
              <Divider hidden />
              <p>
                {filters.providerContext === 'cpo'
                  ? 'These are CPO platform credentials. These are remote MSPs that interface with our charge stations'
                  : 'These are MSP platform credentials. These are remote CPO providers that can use our charge cards.'}
              </p>

              <Search.Status
                noItems={t(
                  'ocpiCredentials.noCredentialsYet',
                  'No OCPI Credentials created yet'
                )}
              />
              <Table />
              <Divider hidden />
              <div style={{ textAlign: 'center' }}>
                <Search.Pagination />
              </div>
            </>
          );
        }}
      </Search.Provider>
    </>
  );
}
