import React from 'react';

import {
  Button,
  FormField,
  Input,
  Label,
  Message,
  Segment,
  Select,
} from 'semantic';
import { request } from 'utils/api';

import beautify from 'xml-beautifier';
import JSONBlock from './JSONBlock';

export const ECLEARING = 'eclearing';
export const OCPI = 'ocpi';

const protocolOptions = [
  { value: ECLEARING, text: 'E-Clearing' },
  { value: OCPI, text: 'OCPI' },
];

export default class RemoteTokenSearch extends React.Component {
  constructor(props) {
    super(props);

    const allowedProtocols = props.allowedProtocols || [OCPI, ECLEARING];
    const filteredProtocols = this.getFilteredProtocols(allowedProtocols);

    this.state = {
      protocol: filteredProtocols[0].value,
      allowedProtocols: allowedProtocols,
      tokenUid: props.tokenUid || '',
      request: false,
      tokenResultType: null,
      tokenResult: null,
      error: null,
      selectedOcpiCredential: null,
      ocpiCredentialOptions: [],
    };
  }
  componentDidMount() {
    this.fetchOcpiCredentialOptions();
  }

  changeProtocol(protocol) {
    this.setState({
      protocol: protocol,
      tokenResult: null,
      tokenResultType: null,
      error: null,
    });
  }

  async checkToken() {
    const protocol = this.state.protocol;
    //
    if (protocol === ECLEARING) {
      return this.checkEclearing();
    } else if (protocol === OCPI) {
      return this.checkOcpi();
    }
  }

  async checkEclearing() {
    this.setState({
      error: null,
      request: true,
      tokenResult: null,
    });
    try {
      const { data, error } = await request({
        method: 'POST',
        path: `/1/tokens/${this.state.tokenUid}/remotesearch/${this.state.protocol}`,
      });

      if (error) {
        this.setState({
          error,
          request: false,
          tokenResultType: ECLEARING,
          tokenResult: error,
        });
        return;
      }
      this.setState({
        tokenResultType: ECLEARING,
        tokenResult: data,
        request: false,
      });
    } catch (e) {
      this.setState({ error: e, request: false });
      return;
    }
  }

  async checkOcpi() {
    this.setState({
      error: null,
      request: true,
      tokenResult: null,
    });
    try {
      const { data, error } = await request({
        method: 'POST',
        path: `/1/tokens/${this.state.tokenUid}/remotesearch/${this.state.protocol}/${this.state.selectedOcpiCredential}`,
      });

      if (error) {
        this.setState({
          error,
          request: false,
          tokenResultType: OCPI,
          tokenResult: error,
        });
        return;
      }
      this.setState({
        tokenResultType: OCPI,
        tokenResult: data,
        request: false,
      });
    } catch (e) {
      this.setState({ error: e, request: false });
    }
  }

  async fetchOcpiCredentialOptions() {
    const credentials = await request({
      method: 'POST',
      path: '/1/ocpi-credentials/search',
      body: {
        providerContext: 'msp',
      },
    });

    const options = credentials.data.map((cred) => {
      return { value: cred.id, text: cred.name };
    });

    this.setState({
      ocpiCredentialOptions: options,
      selectedOcpiCredential: options[0].value,
    });
  }

  getFilteredProtocols(allowedProtocols) {
    const filteredProtocols = protocolOptions.filter((option) => {
      return allowedProtocols.includes(option.value);
    });
    return filteredProtocols;
  }

  render() {
    return (
      <>
        {this.state.error && (
          <Message error content={this.state.error.message} />
        )}
        <FormField>
          <Label>Token UID:</Label>
          <Input
            name="tokenUid"
            value={this.state.tokenUid}
            onChange={(e, { name, value }) =>
              this.setState({ tokenUid: value })
            }></Input>
          <Select
            value={this.state.protocol}
            options={this.getFilteredProtocols(this.state.allowedProtocols)}
            name="protocol"
            onChange={(e, { value }) => this.changeProtocol(value)}></Select>
          {this.state.protocol === OCPI && (
            <Select
              name="selectedOcpiCredential"
              value={this.state.selectedOcpiCredential}
              options={this.state.ocpiCredentialOptions}
              onChange={(e, { value }) => {
                this.setState({ selectedOcpiCredential: value });
              }}
            />
          )}
          <Button
            loading={this.state.request === true}
            disabled={this.state.request === true}
            onClick={() => {
              this.checkToken();
            }}>
            Check
          </Button>
        </FormField>
        {this.state.tokenResult?.token &&
          Boolean(Object.keys(this.state.tokenResult.token).length) && (
            <>
              <h2>Token data:</h2>
              <JSONBlock value={this.state.tokenResult.token} />
            </>
          )}
        {this.state.protocol === ECLEARING && this.state.tokenResult && (
          <>
            <h2>Response Raw XML from E-Clearing</h2>
            <Segment inverted>
              <pre>{beautify(this.state.tokenResult.responseRawXml)}</pre>
            </Segment>
            <h2>Request raw XML to E-Clearing</h2>
            <Segment inverted>
              <pre>{beautify(this.state.tokenResult.requestRawXml)}</pre>
            </Segment>
          </>
        )}
      </>
    );
  }
}
