import React from 'react';
import TableView from './components/TableView';
import { AppWrapper } from '../../components';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import { Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function BillingEntitiesScreen() {
  return (
    <AppWrapper>
      <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
        <TableView />
      </ErrorBoundary>
    </AppWrapper>
  );
}

function ErrorBoundaryFallback() {
  const { t } = useTranslation();
  return (
    <Message error>
      <Message.Header>
        {t('billingEntities.errorBoundaryMessage', 'Something went wrong!')}
      </Message.Header>
      <Message.Content>
        <p>
          {t(
            'billingEntities.errorBoundaryAction',
            'Try refreshing the page or contact support'
          )}
        </p>
      </Message.Content>
    </Message>
  );
}
