import { Segment } from 'semantic';
import { Layout, SearchFilters } from 'components';
import React from 'react';
import { EvseBulkActionWorkflow } from 'types/evse-controller-background-job-execution';
import { useTranslation } from 'react-i18next';
import { FilterMapping } from 'components/search/Provider';
import { Account } from 'types/account';
import { Location } from 'types/location';
import { useFilterContext } from 'components/search/Context';
import { flatten, sortBy, uniqBy } from 'lodash-es';
import useFetch from 'hooks/useFetch';
import {
  decodeStringForFilter,
  encodeStringForFilter,
} from 'utils/filters-header';
import { getEvseControllerBackgroundJobStatusText } from 'screens/EvseControllersBackgroundJobs/EvseControllerBackgroundJobExecutionStatus';

export type WorkflowFilterFields =
  | 'statuses'
  | 'accountIds'
  | 'locationIds'
  | 'maintenanceAccountIds'
  | 'vendors'
  | 'powerTypes'
  | 'firmwareVersions'
  | 'ocppVersions';

export const workflowFilterMapping: FilterMapping<WorkflowFilterFields> = {
  statuses: {
    name: 'statuses',
    type: 'string',
    multiple: true,
  },
  accountIds: {
    name: 'accountIds',
    type: 'string',
    multiple: true,
  },
  locationIds: {
    name: 'locationIds',
    type: 'string',
    multiple: true,
  },
  maintenanceAccountIds: {
    name: 'maintenanceAccountIds',
    type: 'string',
    multiple: true,
  },
  vendors: {
    name: 'vendors',
    type: 'string',
    multiple: true,
  },
  powerTypes: {
    name: 'powerTypes',
    type: 'string',
    multiple: true,
  },
  firmwareVersions: {
    name: 'firmwareVersions',
    type: 'string',
    multiple: true,
  },
  ocppVersions: {
    name: 'ocppVersions',
    type: 'string',
    multiple: true,
  },
} as const;

export default function WorkflowsFilterHeader({
  data,
}: {
  data: EvseBulkActionWorkflow[] | undefined;
}) {
  const { t } = useTranslation();
  const filterContext = useFilterContext<WorkflowFilterFields>();

  const { data: currentFilterAccounts } = useFetch<Account[]>({
    method: 'POST',
    path: '1/accounts/search',
    body: {
      ids: filterContext?.filters?.accountIds,
    },
    disabled: !filterContext?.filters?.accountIds,
  });

  const { data: currentFilterLocations = [] } = useFetch<Location[]>({
    method: 'POST',
    path: '2/locations/cpo/search',
    body: {
      ids: filterContext?.filters?.locationIds,
    },
    disabled: !filterContext?.filters?.locationIds,
  });

  const { data: currentFilterMaintenanceAccounts = [] } = useFetch<Account[]>({
    method: 'POST',
    path: '1/accounts/search',
    body: {
      ids: filterContext?.filters?.maintenanceAccountIds,
    },
    disabled: !filterContext?.filters?.maintenanceAccountIds,
  });

  const currentFilterVendors = filterContext?.filters?.vendors
    ? filterContext.filters.vendors?.map((id: string) => ({
        id,
        name: decodeStringForFilter(id),
      }))
    : [];

  const currentFilterFirmwareVersions = filterContext?.filters?.firmwareVersions
    ? filterContext.filters.firmwareVersions?.map((id: string) => ({
        id,
        name: decodeStringForFilter(id),
      }))
    : [];

  return (
    <Segment>
      <Layout horizontal spread stackable center>
        <SearchFilters.ModalFilterV2 useAutoFocus={false}>
          <SearchFilters.DropdownFilterV2
            label={t('workflowsFilter.status', 'Status')}
            name={workflowFilterMapping.statuses.name}
            multiple={workflowFilterMapping.statuses.multiple ?? false}
            options={sortBy(
              uniqBy(
                data?.map((workflow) => ({
                  key: workflow.status.toString(),
                  value: workflow.status.toString(),
                  text: getEvseControllerBackgroundJobStatusText(
                    workflow.status,
                    t
                  ),
                })),
                'key'
              ),
              'text'
            )}
          />
          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t('workflowsFilter.account', 'Account')}
            name={workflowFilterMapping.accountIds.name}
            populateOnLoad={true}
            multiple={workflowFilterMapping.accountIds.multiple ?? false}
            onDataNeeded={async (filter) => {
              if (!filter?.name) {
                return { data: currentFilterAccounts || [] };
              }

              const name = filter?.name?.toLowerCase();

              return {
                data: sortBy(
                  uniqBy(
                    data
                      ?.filter((workflow) =>
                        workflow.evseController?.account?.name
                          ?.toLowerCase()
                          ?.includes(name)
                      )
                      .map((workflow) => workflow.evseController?.account),
                    'id'
                  ),
                  'name'
                ) as Account[],
              };
            }}
          />
          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t('workflowsFilter.location', 'Location')}
            name={workflowFilterMapping.locationIds.name}
            populateOnLoad={true}
            multiple={workflowFilterMapping.locationIds.multiple ?? false}
            onDataNeeded={async (filter) => {
              if (!filter?.name) {
                return { data: currentFilterLocations || [] };
              }

              const name = filter?.name?.toLowerCase();

              return {
                data: sortBy(
                  uniqBy(
                    data
                      ?.filter((workflow) =>
                        workflow.evseController?.location?.name
                          ?.toLowerCase()
                          .includes(name)
                      )
                      .map((workflow) => workflow.evseController?.location),
                    'id'
                  ),
                  'name'
                ) as Location[],
              };
            }}
          />
          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t(
              'workflowsFilter.fieldServiceAccount',
              'Field Service Account'
            )}
            name={workflowFilterMapping.maintenanceAccountIds.name}
            populateOnLoad={true}
            multiple={
              workflowFilterMapping.maintenanceAccountIds.multiple ?? false
            }
            onDataNeeded={async (filter) => {
              if (!filter?.name) {
                return { data: currentFilterMaintenanceAccounts || [] };
              }

              const name = filter?.name?.toLowerCase();

              return {
                data: sortBy(
                  uniqBy(
                    data
                      ?.filter((workflow) =>
                        workflow.evseController?.maintenanceAccount?.name
                          ?.toLowerCase()
                          ?.includes(name)
                      )
                      .map(
                        (workflow) =>
                          workflow.evseController?.maintenanceAccount
                      ),
                    'id'
                  ),
                  'name'
                ) as Account[],
              };
            }}
          />
          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t('workflowsFilter.vendors', 'Vendor')}
            name={workflowFilterMapping.vendors.name}
            populateOnLoad={true}
            multiple={workflowFilterMapping.vendors.multiple ?? false}
            onDataNeeded={async (filter) => {
              if (!filter?.name) {
                return { data: currentFilterVendors };
              }

              const name = filter?.name?.toLowerCase();

              return {
                data: sortBy(
                  uniqBy(
                    data
                      ?.filter((workflow) =>
                        workflow.evseController?.bootInfo?.chargePointVendor
                          ?.toLowerCase()
                          ?.includes(name)
                      )
                      .map((workflow) => ({
                        id: encodeStringForFilter(
                          workflow.evseController?.bootInfo
                            ?.chargePointVendor as string
                        ),
                        name: workflow.evseController?.bootInfo
                          ?.chargePointVendor,
                      })),

                    'id'
                  ),
                  'name'
                ) as { id: string; name: string }[],
              };
            }}
          />
          <SearchFilters.DropdownFilterV2
            label={t('workflowsFilter.powerTypes', 'Power Type')}
            name={workflowFilterMapping.powerTypes.name}
            multiple={workflowFilterMapping.powerTypes.multiple ?? false}
            options={sortBy(
              uniqBy(
                flatten(
                  data?.map((workflow) => workflow.evseController?.connectors)
                )
                  .filter((connector) => connector?.powerType)
                  .map((connector) => ({
                    key: connector?.powerType as string,
                    value: encodeStringForFilter(
                      connector?.powerType as string
                    ),
                    text: connector?.powerType as string,
                  })),
                'key'
              ),
              'text'
            )}
          />
          <SearchFilters.DropdownSearchFilterV2
            placeholder={t('common.search', 'Search...')}
            label={t('workflowsFilter.firmwareVersions', 'Firmware Version')}
            name={workflowFilterMapping.firmwareVersions.name}
            populateOnLoad={true}
            multiple={workflowFilterMapping.firmwareVersions.multiple ?? false}
            onDataNeeded={async (filter) => {
              if (!filter?.name) {
                return { data: currentFilterFirmwareVersions };
              }

              const name = filter?.name?.toLowerCase();

              return {
                data: sortBy(
                  uniqBy(
                    data
                      ?.filter((workflow) =>
                        workflow.evseController?.bootInfo?.firmwareVersion
                          ?.toLowerCase()
                          ?.includes(name)
                      )
                      .map((workflow) => ({
                        id: encodeStringForFilter(
                          workflow.evseController?.bootInfo
                            ?.firmwareVersion as string
                        ),
                        name: workflow.evseController?.bootInfo
                          ?.firmwareVersion,
                      })),

                    'id'
                  ),
                  'name'
                ) as { id: string; name: string }[],
              };
            }}
          />
          <SearchFilters.DropdownFilterV2
            label={t('workflowsFilter.ocppVersion', 'OCPP Version')}
            name={workflowFilterMapping.ocppVersions.name}
            multiple={workflowFilterMapping.ocppVersions.multiple ?? false}
            options={sortBy(
              uniqBy(
                data
                  ?.filter((w) => w.evseController?.ocppProtocolVersion)
                  .map((w) => ({
                    key: w.evseController?.ocppProtocolVersion || '',
                    value: w.evseController?.ocppProtocolVersion || '',
                    text:
                      w.evseController?.ocppProtocolVersion?.toUpperCase() ||
                      '',
                  })),
                'key'
              ),
              'text'
            )}
          />
        </SearchFilters.ModalFilterV2>
      </Layout>
    </Segment>
  );
}
