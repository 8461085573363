import { object, string, ref } from 'yup';
import { useTranslation } from 'react-i18next';

export const usePasswordAndAuthValidaton = () => {
  const { t } = useTranslation();

  return object({
    currentPassword: string().required('Please enter your current password'),
    newPassword: string()
      .required(
        t(
          'settings.personal.passwordAndAuthentication.validation.newPassword',
          'Please enter a password'
        )
      )
      .min(
        8,
        t(
          'settings.personal.passwordAndAuthentication.validation.newPasswordMinLength',
          'Password must have at least 8 characters'
        )
      ),
    confirmPassword: string()
      .required(
        t(
          'settings.personal.passwordAndAuthentication.validation.confirmPassword',
          'Please confirm your password'
        )
      )
      .oneOf(
        [ref('newPassword')],
        t(
          'settings.personal.passwordAndAuthentication.validation.confirmPasswordMatch',
          'Passwords must match'
        )
      ),
  });
};
