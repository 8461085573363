import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Layout,
  ListHeader,
  Search,
  SearchFilters,
} from 'components';
import { Divider, Message, Segment } from 'semantic';
import Table from './Table';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';

export default function Pairing() {
  const { t } = useTranslation();
  const [messageViz, setMessageViz] = React.useState(true);

  const searchRef = React.useRef(null);

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: '/1/evse-controllers/mine',
      body: {
        ...filters,
      },
    });
  };

  return (
    <Search.Provider ref={searchRef} onDataNeeded={onDataNeeded}>
      {() => (
        <>
          <Breadcrumbs
            path={[
              <Link key="smart-charging" to="/smart-charging">
                {t('smartCharging.title', 'Smart Charging')}
              </Link>,
            ]}
            active={t('smartCharging.pairing', 'Pairing')}
          />

          <ListHeader title={t('smartCharging.pairing', 'Pairing')} />

          <Message
            icon="info"
            info
            header={t('smartCharging.title', 'Smart Charging')}
            hidden={!messageViz}
            onDismiss={() => setMessageViz(false)}
            content={t(
              'smartCharging.pairingMessage',
              'Choose the charging station to pair with the smart charging provider (E.g. Stekker.app) and fill in the pairing code.'
            )}></Message>

          <Segment>
            <Layout horizontal spread stackable>
              <Layout horizontal stackable center right>
                <Search.Total />
                <SearchFilters.Search
                  name="searchId"
                  placeholder={t('acconts.filterPlaceHolder', 'Name or ID')}
                />
              </Layout>
            </Layout>
          </Segment>

          <Search.Status noResults={t('common.noResults', 'No Results')} />
          <Table />
          <Divider hidden />
          <div style={{ textAlign: 'center' }}>
            <Search.Pagination />
          </div>
        </>
      )}
    </Search.Provider>
  );
}
