import React from 'react';
import { Grid, Header } from 'semantic';

type ListHeaderProps = {
  title: string;
  children?: React.ReactNode;
  topPadded?: boolean;
  mobileChildAlignment?: 'top' | 'bottom';
};

export default function ListHeader({
  title,
  children,
  topPadded = false,
  mobileChildAlignment = 'top',
}: ListHeaderProps) {
  const gridStyle = { marginTop: topPadded ? 0 : undefined };
  if (mobileChildAlignment === 'bottom') {
    return (
      <Grid style={gridStyle}>
        <HeaderColumn title={title} />
        <ChildrenColumn>{children}</ChildrenColumn>
      </Grid>
    );
  }
  return (
    <Grid reversed="computer" style={gridStyle}>
      <ChildrenColumn>{children}</ChildrenColumn>
      <HeaderColumn title={title} />
    </Grid>
  );
}

function HeaderColumn({ title }) {
  return (
    <Grid.Column mobile={16} tablet={16} computer={6}>
      <Header as="h2" style={{ margin: '0' }}>
        {title}
      </Header>
    </Grid.Column>
  );
}

function ChildrenColumn({ children }) {
  return (
    <Grid.Column
      mobile={16}
      tablet={16}
      computer={10}
      textAlign="right"
      verticalAlign="middle">
      {children}
    </Grid.Column>
  );
}
