import { Button, Form, Message, Modal } from 'semantic';
import React, { useMemo } from 'react';
import SelectField from 'components/form-fields/formik/SelectField';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getRequiredFieldMessage } from 'utils/validate';
import InputField from 'components/form-fields/formik/InputField';

type Props = {
  initialValues: BatchDetails;
  onSubmit: (values: BatchDetails) => void;
};

export type BatchDetails = {
  note: string;
  tokenType: string;
};

const TOKEN_TYPES = ['card', 'tag'] as const;
type TokenTypes = (typeof TOKEN_TYPES)[number];

export function BatchDetailsScreen({ initialValues, onSubmit }: Props) {
  const { t } = useTranslation();

  const LABELS = {
    note: t('tokenBatches.note', "A note that describes the batch's purpose"),
    tokenType: t('tokenBatches.tokenType', 'Token Type'),
  };

  const TOKEN_TYPES_LABELS: { [key in TokenTypes]: string } = {
    card: t('tokenBatches.card', 'Card'),
    tag: t('tokenBatches.tag', 'Tag'),
  };

  return (
    <>
      <Modal.Content>
        <Message warning>
          <p>
            {t(
              'tokenBatches.importMethodWarning',
              "The preferred way to import a batch of tokens is to create the batch first, then open the batch in the dashboard and click the import button there. Only use the current method of imports if you want to upload a completely new batch with uid's already assigned."
            )}
          </p>
        </Message>

        <Formik
          validationSchema={Yup.object({
            note: Yup.string().required(
              getRequiredFieldMessage(t, LABELS.note)
            ),
            tokenType: Yup.string().required(
              getRequiredFieldMessage(t, LABELS.tokenType)
            ),
          })}
          initialValues={initialValues}
          onSubmit={onSubmit}>
          {({ handleSubmit, status }) => {
            return (
              <>
                <Form onSubmit={handleSubmit} id="batch-details-form">
                  <Form.Field>
                    <InputField
                      required
                      name="note"
                      label={LABELS.note}
                      type="text"
                    />
                  </Form.Field>
                  <Form.Field>
                    <SelectField
                      required
                      options={TOKEN_TYPES.map((tokenType) => ({
                        key: tokenType,
                        value: tokenType,
                        text: TOKEN_TYPES_LABELS[tokenType],
                      }))}
                      name="tokenType"
                      label={LABELS.tokenType}
                    />
                  </Form.Field>
                  {status && <Message error content={status} />}
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
      <Modal.Actions>
        <Button
          // @ts-ignore
          primary
          type="submit"
          form="batch-details-form"
          content={t('tokenBatches.submit', 'Submit')}
        />
      </Modal.Actions>
    </>
  );
}
