import React from 'react';
import RemoteTokenSearch from '../../components/RemoteTokenSearch';
import { Container, Divider, Header } from 'semantic';

export default class RemoteTokenSearchPanel extends React.Component {
  render() {
    return (
      <Container>
        <Header as="h2">Search Remote Tokens</Header>
        <Divider hidden />
        <p>Find out what other parties have stored</p>
        <RemoteTokenSearch />
      </Container>
    );
  }
}
