import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from 'utils/language';

import PhraseInContextEditorPostProcessor from 'i18next-phrase-in-context-editor-post-processor';
import { I18N_URL } from 'utils/env';
import HttpApi from 'i18next-http-backend';

(() => {
  const params = new URLSearchParams(window.location.search);
  const enablePostProcessor = !!params.get('i18n');

  i18next.use(initReactI18next).use(HttpApi);

  if (enablePostProcessor) {
    window.PHRASEAPP_CONFIG = {
      autoLowercase: false,
    };

    i18next.use(
      new PhraseInContextEditorPostProcessor({
        phraseEnabled: true,
        projectId: '941c5a1fdc010f9499aba0e4f0eb6dc7',
      })
    );
  }

  const language = getLanguage();
  document.documentElement.setAttribute('lang', language);

  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    language: language,
  });

  i18next.init({
    lng: getLanguage(),
    fallbackLng: 'en',
    referenceLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    returnEmptyString: false,
    keySeparator: '.',
    debug: false,
    postProcess: enablePostProcessor ? ['phraseInContextEditor'] : undefined,

    backend: {
      crossDomain: true,
      requestOptions: {
        // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
      // set to /locales/{{lng}} if you want to use local files.
      loadPath: I18N_URL,
    },
  });
})();

export const supportedLanguages = [
  { key: 'nl', text: 'Nederlands', value: 'nl' },
  { key: 'en', text: 'English', value: 'en' },
  { key: 'de', text: 'Deutsch', value: 'de' },
  { key: 'fr', text: 'Français', value: 'fr' },
  { key: 'it', text: 'Italiano', value: 'it' },
  { key: 'es', text: 'Español', value: 'es' },
];

export default i18next;
