import React from 'react';
import AppWrapper from 'components/AppWrapper';
import { request } from 'utils/api';
import { Link } from 'react-router-dom';

import {
  Container,
  Header,
  Segment,
  Statistic,
  Message,
  Loader,
  Table,
  Divider,
  Grid,
} from 'semantic';
import Breadcrumbs from 'components/Breadcrumbs';
import HelpTip from 'components/HelpTip';
import { fromNow } from 'utils/date';

export default class EvseControllersStatus extends React.Component {
  state = {
    data: null,
    error: null,
    loading: true,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    request({
      method: 'POST',
      path: '/1/evse-controllers/status',
      body: {},
    })
      .then(({ data }) => {
        this.setState({ data, loading: false });
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  render() {
    return (
      <AppWrapper>
        <Container>
          <Breadcrumbs
            path={[
              <Link key="charging-stations" to="/charging-stations">
                Charging Stations
              </Link>,
            ]}
            active="EVSE Connections"
          />
          <Grid reversed="computer">
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header as="h2" style={{ margin: '0' }}>
                EVSE Connections
              </Header>
            </Grid.Column>
          </Grid>
          <Divider hidden />
          {this.renderStatus()}
        </Container>
      </AppWrapper>
    );
  }

  renderStatus() {
    const { data, error, loading } = this.state;
    if (error) return <Message content={error.message} error />;
    if (loading) {
      return (
        <Segment style={{ height: '100px' }}>
          <Loader active />
        </Segment>
      );
    }
    const { nodes, inactiveEvseControllers } = data;
    let numEvseControllers = 0;
    nodes.forEach((node) => {
      numEvseControllers += node.evseControllers.length;
    });

    return (
      <div>
        <Segment>
          <Statistic.Group widths="two">
            <Statistic>
              <Statistic.Value>{nodes.length}</Statistic.Value>
              <Statistic.Label>Coordinators</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{numEvseControllers}</Statistic.Value>
              <Statistic.Label>Active Connections</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        <Header as="h3">Coordinator Distribution</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Coordinator</Table.HeaderCell>
              <Table.HeaderCell width={2}>Count</Table.HeaderCell>
              <Table.HeaderCell>
                Active EVSE Controllers{' '}
                <HelpTip
                  title={'Active EVSE Controllers'}
                  text={
                    'These are all controllers that have received an OCPP heartbeat in the past 1000 seconds'
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {nodes.map((node) => {
              return (
                <Table.Row key={node.id}>
                  <Table.Cell width={3}>{node.id}</Table.Cell>
                  <Table.Cell width={2} textAlign="right">
                    {node.evseControllers.length}
                  </Table.Cell>
                  <Table.Cell width={11}>
                    {node.evseControllers.map((item) => {
                      return (
                        <Link
                          to={`/charging-stations/${item.id}`}
                          key={item.id}
                          style={{
                            marginRight: '8px',
                            textDecoration: 'underline',
                          }}>
                          {item.ocppIdentity}
                        </Link>
                      );
                    })}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Header as="h3">Inactive EVSE Controllers</Header>
        <p>
          These controllers are linked to accounts but have not received a
          heartbeat within 3 days.
        </p>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>OCPP Identity</Table.HeaderCell>
              <Table.HeaderCell>Serial Number</Table.HeaderCell>
              <Table.HeaderCell>Last Token Authorize</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {inactiveEvseControllers.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link to={`/charging-stations/${item.id}`} key={item.id}>
                      {item.ocppIdentity}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{item.serialNumber}</Table.Cell>
                  <Table.Cell>
                    {item.tokenAuthorizeReceivedAt
                      ? fromNow(item.tokenAuthorizeReceivedAt)
                      : 'Never'}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
