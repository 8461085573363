import React, { useEffect, useState } from 'react';
import { Switch, Route, Link, useParams } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Container, Divider, Message, Loader, Grid, Header } from 'semantic';
import Menu from './Menu';
import PageLoader from 'components/PageLoader';
import Overview from './Overview';
import { request } from 'utils/api';
import Breadcrumbs from 'components/Breadcrumbs';

export default function AccessGroup() {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItem(id);
  }, [id]);

  const fetchItem = async (itemId) => {
    try {
      const { data } = await request({
        method: 'GET',
        path: `/1/access-groups/${itemId}`,
      });
      setItem(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const refresh = () => {
    fetchItem(id);
  };

  if (loading) {
    return <Loader active />;
  }

  return (
    <AppWrapper>
      <Container>
        <Breadcrumbs
          path={[
            <Link to="/charging-stations">Charging Stations</Link>,
            <Link to="/charging-stations/access-groups">Access Groups</Link>,
          ]}
          active={item ? item.name : 'Loading...'}
        />

        <Grid reversed="computer">
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Header as="h2" style={{ margin: '0' }}>
              {item ? item.name : 'Loading...'}
            </Header>
          </Grid.Column>
        </Grid>
      </Container>
      <Divider hidden />
      {item && item.deletedAt && (
        <Message error content="This access group has been deleted" />
      )}
      <Menu itemId={id} />
      <Divider hidden />
      {!item && <PageLoader />}
      {item && (
        <Switch>
          <Route
            exact
            path="/charging-stations/access-groups/:id"
            item={item}
            component={(props) => (
              <Overview
                {...props}
                accessGroup={item}
                onRefresh={() => refresh()}
              />
            )}
          />
        </Switch>
      )}
      {error && <Message error content={error.message} />}
    </AppWrapper>
  );
}
