import React, { createContext, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

const EnterpriseAccountMigrationContext = createContext({});

export const EnterpriseAccountMigrationProvider = ({ children }) => {
  const [migrationType, setMigrationType] = useState('enterpriseAccounts');
  const [accountId, setAccountId] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [enterpriseAccountId, setEnterpriseAccountId] = useState(null);
  const [enterpriseAccountName, setEnterpriseAccountName] = useState(null);
  const [migrationData, setMigrationData] = useState(null);

  async function requestMigration(
    migrationType,
    accountId,
    enterpriseAccountId
  ) {
    const resp = await request({
      method: 'POST',
      path: '/1/migration/enterprise-account/plan',
      body: {
        migrationType,
        accountId,
        newProviderId: enterpriseAccountId,
      },
    });
    return resp;
  }

  async function acceptMigration(migrationPlanId) {
    const res = await request({
      method: 'PATCH',
      path: `/1/migration/enterprise-account/${migrationPlanId}/accept`,
    });
    return res;
  }

  async function rejectMigration(migrationPlanId) {
    const res = await request({
      method: 'PATCH',
      path: `/1/migration/enterprise-account/${migrationPlanId}/reject`,
    });

    return res;
  }

  async function completeMigration(migrationPlanId) {
    const res = await request({
      method: 'PATCH',
      path: `/1/migration/enterprise-account/${migrationPlanId}/complete`,
    });
    return res;
  }

  const values = useMemo(() => {
    return {
      migrationType,
      setMigrationType,
      accountId,
      setAccountId,
      accountName,
      setAccountName,
      enterpriseAccountId,
      setEnterpriseAccountId,
      enterpriseAccountName,
      setEnterpriseAccountName,
      requestMigration,
      acceptMigration,
      rejectMigration,
      completeMigration,
      migrationData,
      setMigrationData,
    };
  }, [
    migrationType,
    setMigrationType,
    accountId,
    setAccountId,
    accountName,
    setAccountName,
    enterpriseAccountId,
    setEnterpriseAccountId,
    enterpriseAccountName,
    setEnterpriseAccountName,
    requestMigration,
    acceptMigration,
    rejectMigration,
    completeMigration,
    migrationData,
    setMigrationData,
  ]);

  return (
    <EnterpriseAccountMigrationContext.Provider value={values}>
      {children}
    </EnterpriseAccountMigrationContext.Provider>
  );
};

export const useEnterpriseAccountMigration = () => {
  const { t } = useTranslation();
  const context = useContext(EnterpriseAccountMigrationContext);
  if (context === undefined) {
    throw new Error(
      t(
        'enterpriseAccountMigration.context.error',
        'useEnterpriseAccountMigration must be used within a EnterpriseAccountMigrationProvider'
      )
    );
  }
  return context;
};
