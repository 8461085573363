import React from 'react';
import { Grid } from 'semantic-ui-react';

export default (props) => (
  <div style={{ height: '100%', flex: '1 0 auto' }}>
    <Grid style={{ minHeight: '100%' }} centered verticalAlign="middle">
      <Grid.Column
        style={{ maxWidth: props.maxWidth || '700px', margin: '40px 0' }}>
        {props.children}
      </Grid.Column>
    </Grid>
  </div>
);
