import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Divider, Header, Grid } from 'semantic';
import Menu from './Menu';
import List from './List';
import Map from './Map';
import Sources from './Sources';
import Analytics from './Analytics';
import Breadcrumbs from 'components/Breadcrumbs';

export default function Locations({
  prefixPath = '/cards',
  locationsPath = '/cards/locations',
  sourcesPath = '/cards/locations/sources',
  mapPath = '/cards/locations/map',
  analyticsPath = '/cards/locations/analytics',
}) {
  return (
    <AppWrapper>
      <Breadcrumbs
        path={[
          <Link key="cards" to={prefixPath}>
            Cards
          </Link>,
        ]}
        active="MSP Locations"
      />
      <Grid reversed="computer">
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Header as="h2" style={{ margin: '0' }}>
            MSP Locations
          </Header>
        </Grid.Column>
      </Grid>
      <Divider hidden />
      <Menu
        locationsPath={locationsPath}
        sourcesPath={sourcesPath}
        mapPath={mapPath}
        analyticsPath={analyticsPath}
      />
      <Divider hidden />
      <Switch>
        <Route
          exact
          path={locationsPath}
          component={() => <List locationsPath={locationsPath} />}
        />
        <Route
          exact
          path={sourcesPath}
          component={() => <Sources sourcesPath={sourcesPath} />}
        />
        <Route
          exact
          path={mapPath}
          component={() => <Map mapPath={mapPath} />}
        />
        <Route
          exact
          path={analyticsPath}
          component={() => <Analytics analyticsPath={analyticsPath} />}
        />
      </Switch>
    </AppWrapper>
  );
}
