import React, { useMemo, useRef, useState } from 'react';
import {
  ActionButton,
  Grid,
  Header,
  Icon,
  Segment,
  Breadcrumb,
  Divider,
} from 'semantic';
import { useValueCostSettings } from 'components/tariffs/EvseTariff/useValueCostSettings';
import { shortCircuitEvent } from 'utils/events';
import CostSettingsSummary from 'components/tariffs/AdvancedTariff/CostSettings/Summary';
import { useTranslation } from 'react-i18next';
import AdvancedTariffForm, {
  useSummaryRows,
} from 'components/tariffs/AdvancedTariff';
import { SummaryGridRow } from 'components/tariffs/AdvancedTariff/SummaryRow';
import { BaseCostSettingsProvider } from 'components/tariffs/AdvancedTariff/BaseCostSettings';
import { useAdvancedTariffState } from 'components/tariffs/AdvancedTariff/state';
import AdvancedTariffsFeatureAlert from 'components/tariffs/AdvancedTariff/FeatureAlert';

export default function AdvancedCostSettings({
  value,
  connectors = undefined,
  numConnectors = 1,
  onChange,
  disabled,
  currency,
}) {
  const costSettingsList: any[] = useValueCostSettings({
    connectors,
    numConnectors,
    value,
  });
  const onAdvancedCostSettingsChange = (advancedCostSettings, index) => {
    costSettingsList[index] = advancedCostSettings;
    onChange(costSettingsList);
  };

  const [selectedTariffIndex, setSelectedTariffIndex] = useState(-1);

  return (
    <>
      {selectedTariffIndex < 0 && (
        <ConnectorCostSettingsList
          costSettingsList={costSettingsList}
          currency={currency}
          setSelectedTariffIndex={setSelectedTariffIndex}
          disabled={disabled}
        />
      )}
      {selectedTariffIndex >= 0 && (
        <SelectedConnectorCostSetting
          value={costSettingsList[selectedTariffIndex]}
          selectedTariffIndex={selectedTariffIndex}
          onAdvancedCostSettingsChange={onAdvancedCostSettingsChange}
          goBack={() => setSelectedTariffIndex(-1)}
          currency={currency}
        />
      )}
    </>
  );
}

function SelectedConnectorCostSetting({
  value,
  selectedTariffIndex,
  onAdvancedCostSettingsChange,
  goBack,
  currency,
}) {
  const { t } = useTranslation();
  const initialValue = useRef(value);
  const onClickGoBack = (e) => {
    e.preventDefault();
    goBack();
  };

  const handleChange = (nextCostSettings) => {
    onAdvancedCostSettingsChange(nextCostSettings, selectedTariffIndex);
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    onAdvancedCostSettingsChange(initialValue.current, selectedTariffIndex);
    goBack();
  };

  return (
    <Grid columns={1}>
      <Grid.Column style={{ paddingBottom: 0 }}>
        <Breadcrumb>
          <Breadcrumb.Section onClick={onClickGoBack}>
            <ActionButton
              onClick={(e) => onClickGoBack(e)}
              primary
              style={{ padding: 0, textTransform: 'uppercase' }}>
              {t('advancedTariff.connectorTariffs', 'Connector Tariffs')}
            </ActionButton>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>
            {t('advancedTariff.connectorLabel', 'Connector {{connectorNum}}', {
              connectorNum: selectedTariffIndex + 1,
            })}
          </Breadcrumb.Section>
        </Breadcrumb>
        <ActionButton
          onClick={(e) => onClickCancel(e)}
          primary
          style={{ float: 'right', paddingBottom: 0 }}>
          {t('advancedTariff.cancelChanges', 'Cancel Changes')}
        </ActionButton>
      </Grid.Column>
      <Grid.Column>
        <AdvancedTariffForm
          value={value}
          onChange={(nextCostSettings) => handleChange(nextCostSettings)}
          currency={currency}
        />
      </Grid.Column>
    </Grid>
  );
}

function ConnectorCostSettingsList({
  costSettingsList,
  currency,
  disabled,
  setSelectedTariffIndex,
}) {
  const { t } = useTranslation();
  return (
    <Grid columns={1}>
      <Grid.Column style={{ paddingBottom: 0 }}>
        <Breadcrumb>
          <Breadcrumb.Section active>
            {t('advancedTariff.connectorTariffs', 'Connector Tariffs')}
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>
      <Grid.Column>
        {costSettingsList?.map?.((costSettings, index) => (
          <TariffListItem
            key={`tariff-list-item-${index}`}
            costSettings={costSettings}
            index={index}
            currency={currency}
            disabled={disabled}
            setSelectedTariffIndex={setSelectedTariffIndex}
          />
        ))}
      </Grid.Column>
    </Grid>
  );
}

function TariffListItem({
  costSettings,
  index,
  currency,
  disabled,
  setSelectedTariffIndex,
}) {
  const { t } = useTranslation();
  const [showScheduledTariffs, setShowScheduledTariffs] = useState(false);

  const { tariffSystem } = useAdvancedTariffState(costSettings);
  const tariffSystemLabel = useMemo(() => {
    switch (tariffSystem) {
      case 'basic':
        return `- ${t('advancedTariff.baseTariffTitle', 'Base Tariff')}`;
      case 'scheduled':
        return `- ${t(
          'advancedTariff.scheduledTariffTitle',
          'Scheduled Tariff'
        )}`;
      case 'dynamic':
        return `- ${t('advancedTariff.dynamicTariffTitle', 'Dynamic Tariff')}`;
    }
  }, [t, tariffSystem]);

  return (
    <Segment key={`advanced-cost-settings-${costSettings.connectorId}`}>
      <Grid>
        <Grid.Row columns={2} style={{ paddingBottom: 0 }}>
          <Grid.Column>
            <Header as={'h4'}>
              Connector {costSettings.connectorId} {tariffSystemLabel}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign={'right'}>
            <ActionButton
              primary
              compact
              disabled={disabled}
              onClick={(e) => {
                shortCircuitEvent(e);
                setSelectedTariffIndex(index);
              }}>
              <Icon name={'edit'} /> {t('advancedTariff.edit', 'Edit')}
            </ActionButton>
          </Grid.Column>
        </Grid.Row>
        <BaseCostSettingsProvider value={costSettings}>
          <AdvancedTariffsFeatureAlert
            style={{ marginLeft: 12 }}
            tariffSystem={tariffSystem}
          />
          <CostSettingsSummary currency={currency} value={costSettings} />
          {showScheduledTariffs && (
            <ScheduledTariffSummaryList
              value={costSettings.components}
              currency={currency}
            />
          )}
          {!!costSettings.components?.length && (
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column>
                <ActionButton
                  primary
                  compact
                  style={{ paddingLeft: 0 }}
                  onClick={(e) => {
                    shortCircuitEvent(e);
                    setShowScheduledTariffs(!showScheduledTariffs);
                    e.target.blur?.();
                  }}>
                  {showScheduledTariffs && (
                    <>
                      <Icon name={'caret-up'} />
                    </>
                  )}
                  {!showScheduledTariffs && (
                    <>
                      <Icon name={'caret-down'} />
                    </>
                  )}
                  {costSettings.components?.length}{' '}
                  {costSettings.components?.length === 1 &&
                    t('advancedTariff.scheduledTariff', 'Scheduled Tariff')}
                  {costSettings.components?.length > 1 &&
                    t('advancedTariff.scheduledTariffs', 'Scheduled Tariffs')}
                </ActionButton>
              </Grid.Column>
            </Grid.Row>
          )}
        </BaseCostSettingsProvider>
      </Grid>
    </Segment>
  );
}

function ScheduledTariffSummaryList({ value = [], currency }) {
  const valueWithUiKeys = useMemo(() => {
    return value.map((component) => ({
      uiKey: Math.random(),
      component,
    }));
  }, [value]);
  return (
    <>
      {valueWithUiKeys.map(({ uiKey, component }) => (
        <React.Fragment key={`scheduled-tariff-summary-${uiKey}`}>
          <Divider />
          <ScheduledTariffSummary value={component} currency={currency} />
        </React.Fragment>
      ))}
    </>
  );
}

function ScheduledTariffSummary({ value, currency }) {
  const rows: any[] = useSummaryRows(value, currency, false);
  return (
    <>
      {rows.map((row) => (
        <SummaryGridRow key={row.uiKey} row={row.columns} />
      ))}
    </>
  );
}
