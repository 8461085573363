import React from 'react';
import { Form } from 'semantic';
import { useFilterContext } from 'components/search/Context';

export type DropdownOption = {
  key: string;
  value: string;
  text: string;
};

type DropdownFilterProps = {
  name: string;
  label?: string;
  options: DropdownOption[];
  multiple?: boolean;
  onChange?: (value: any) => void;
  reloadOnChange?: boolean;
};

export const DropdownFilterV2 = ({
  name,
  label,
  options,
  multiple = false,
  onChange,
  reloadOnChange = false,
}: DropdownFilterProps) => {
  const filterContext = useFilterContext();

  let currentValue;
  if (multiple) {
    currentValue = filterContext.filters[name] ?? [];
  } else {
    currentValue = filterContext.filters[name] ?? '';
  }

  return (
    <Form.Field>
      <label>{label}</label>
      <Form.Dropdown
        value={currentValue}
        clearable
        fluid
        selection
        multiple={multiple}
        options={options}
        name={name}
        onChange={(_, { value, name }) => {
          filterContext.onFilterChange({ name, value });
          onChange?.(value);
          if (reloadOnChange) {
            filterContext.reload();
          }
        }}
      />
    </Form.Field>
  );
};

export default DropdownFilterV2;
