import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState, useEffect, useCallback } from 'react';

import { Segment, Header, Divider, Radio, Icon, Button } from 'semantic';
import { useTranslation } from 'react-i18next';

import { CardType, useSignup } from '../Context';
import { useUser } from 'contexts/user';
import PlanSelector from 'components/PlanSelector';
import { getParameterByName } from 'utils/url';
// @ts-ignore
import Tag from 'assets/tag.svg';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useProductInstrumentation } from 'contexts/productInstrumentation';
import { trackCustomBehavioralEvent } from 'utils/hubspot';

const CardContainer = styled.div<{ center: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  width: 60%;
  margin: auto;

  .image {
    height: 60px;
    width: 90px;
    border: 1px solid black;
  }

  .item {
    margin-top: 10px;
    cursor: pointer;
  }

  .radio {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;

const queryClient = new QueryClient();

interface Props {
  journey?: string;
}

const SignupPlans: React.FC = ({ journey }: Props) => {
  const { t } = useTranslation();
  const {
    setStep,
    cardType,
    setCardType,
    setBillingPlan,
    billingPlan,
    currency,
  } = useSignup();
  //@ts-ignore
  const { provider } = useUser();
  const [enabledChargeProducts, setEnabledChargeProducts] = useState<
    CardType[]
  >([]);
  const { productInstrumentation } = useProductInstrumentation();
  const sendProductInstrumentation = useCallback(
    (stage: 'started' | 'failed' | 'completed' | 'errored') => {
      productInstrumentation?.trackJourneyStep({
        journey: 'sign-up',
        step: 'plan-selection',
        stage,
        variant: '1.0.0',
      });
    },
    []
  );

  const sendClick = useCallback((target: string) => {
    productInstrumentation?.trackClick({
      target,
      journey: 'sign-up',
      step: 'plan-selection',
      variant: '1.0.0',
    });
  }, []);

  useEffect(() => {
    productInstrumentation?.trackJourney({
      journey: 'sign-up',
      stage: 'started',
      variant: '1.0.0',
    });
    sendProductInstrumentation('started');
  }, [sendProductInstrumentation]);

  useEffect(() => {
    setEnabledChargeProducts(
      (provider.enabledChargeProducts || []).filter((x: CardType) => {
        return x === 'card' || x === 'tag';
      })
    );
  }, [provider.enabledChargeCards]);

  useEffect(() => {
    if (
      enabledChargeProducts.length > 0 &&
      !enabledChargeProducts.includes(cardType)
    ) {
      setCardType(enabledChargeProducts[0]);
    }
  }, [enabledChargeProducts]);

  useEffect(() => {
    setStep('plans');
  }, []);

  const history = useHistory();
  const restrictBillingPlanId = getParameterByName('billingPlanId');

  return (
    <QueryClientProvider client={queryClient}>
      <Segment>
        <>
          <Header>{t('signupPlans.planHeader', 'You will receive a:')}</Header>
          <CardContainer center={enabledChargeProducts.length === 1}>
            {enabledChargeProducts.includes('card') && (
              <div className="item" onClick={() => setCardType('card')}>
                <Icon size="huge" name="credit-card" />
                <Radio
                  label={t('signupPlans.cardTypeCard')}
                  name="radioGroup"
                  checked={cardType === 'card'}
                  onChange={() => setCardType('card')}
                />
              </div>
            )}
            {enabledChargeProducts.includes('tag') && (
              <div className="item" onClick={() => setCardType('tag')}>
                <img src={Tag} style={{ height: '45px' }} />
                <Radio
                  label={t('signupPlans.cardTypeTag')}
                  name="radioGroup"
                  checked={
                    cardType === 'tag' ||
                    !provider?.enabledChargeProducts?.includes('card')
                  }
                  onChange={() => setCardType('tag')}
                />
              </div>
            )}
          </CardContainer>
          <Divider />
        </>
        <PlanSelector
          onlyPrimary={true}
          restrictBillingPlanId={restrictBillingPlanId}
          billingPlanId={billingPlan?.id}
          currency={currency}
          onSelect={(plan) => {
            setBillingPlan(plan);
          }}
        />
      </Segment>
      <div>
        <Button
          as="button"
          fluid
          primary
          disabled={!billingPlan}
          onClick={() => {
            const dataLayer = (window as any).dataLayer || [];
            if (dataLayer) {
              dataLayer.push({
                event: 'plan_completed',
                billingPlanId: billingPlan?.id,
                billingPlanName: billingPlan?.name,
              });
            }
            trackCustomBehavioralEvent(`${journey}_plan_completed`, {});

            sendProductInstrumentation('completed');
            sendClick('continue');
            history.push('/signup/account');
          }}>
          {t('signupPlans.continue', 'continue')}
        </Button>
      </div>
    </QueryClientProvider>
  );
};

export default SignupPlans;
