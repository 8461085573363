import React, { useState } from 'react';
import { Button, Form, Message, Modal, Table } from 'semantic';
import Address from 'components/Address';
import SearchDropdown from 'components/form-fields/SearchDropdown';
import modal from 'helpers/modal';
import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';
import { labelPrintUrlForCard } from 'utils/cards';
import { useFeatures } from 'contexts/features';

const countryOptions = [
  {
    key: 'NL',
    value: 'NL',
    text: `Netherlands (NL)`,
  },
];

const AssignTokenToCard = ({ initialValues, close }) => {
  const [formValues, setFormValues] = useState({
    cardId: initialValues.id,
    countryCode: 'NL',
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    setSubmitted(true);
    setLoading(true);
    setError(null);

    try {
      await request({
        method: 'POST',
        path: '/1/cards/assign-token',
        body: formValues,
      });
      close();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  const setField = (name, value) => {
    setSubmitted(false);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <>
      <Modal.Header>
        {t('assignTokenToCard.title', 'Assign Token to Card')}
      </Modal.Header>
      <Modal.Content>
        <Form error={submitted && Boolean(error)} onSubmit={handleSubmit}>
          {error && <Message error content={error.message} />}
          {initialValues.delivery && (
            <Form.Field>
              <label>
                {t(
                  'assignTokenToCard.customDeliveryAddress',
                  'Custom Delivery Address'
                )}
              </label>
              <Address {...initialValues.delivery} />
              <Button
                basic
                icon="print"
                as="a"
                rel="noopener"
                target="_blank"
                href={labelPrintUrlForCard(initialValues)}
                content={t('assignTokenToCard.printLabel', 'Print Label')}
              />
            </Form.Field>
          )}
          <Table definition columns="2">
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {t('assignTokenToCard.cardtype', 'Card type')}
                </Table.Cell>
                <Table.Cell>{initialValues.type}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

          <Form.Input
            required
            value={formValues.tokenId}
            name="tokenUid"
            label={t('assignTokenToCard.tokenUid', 'Token UID')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
          {/*
          Hiding country field on Assign token to card until we support multiple countries - SAAS-1562
          <Form.Field required>
            <label>{t('assignTokenToCard.countryCode', 'Country')}</label>
            <Form.Dropdown
              button
              basic
              value={formValues.countryCode}
              options={countryOptions}
              name="countryCode"
              onChange={(e, { name, value }) => setField(name, value)}
            />
          </Form.Field>
          */}
          <Form.Input
            readOnly
            value={formValues.cardId}
            name="cardId"
            label={t('assignTokenToCard.cardID', 'Card ID')}
            type="text"
            onChange={(e, { name, value }) => setField(name, value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={t('assignTokenToCard.assign', 'Assign')}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </>
  );
};

export default modal(AssignTokenToCard);
