import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppWrapper from 'components/AppWrapper';
import { Container } from 'semantic';
import List from './List';
import useMenuRoutes from 'hooks/useMenuRoutes';

export default function FinanceRoamingLimits() {
  const { financeRoamingLimitsRoutes } = useMenuRoutes('system');
  const { prefixUiRoute, uiRoute, apiRoute } = financeRoamingLimitsRoutes;
  const listUiRoute = `${prefixUiRoute}${uiRoute}`;

  return (
    <AppWrapper>
      <Container>
        <Switch>
          <Route
            path={listUiRoute}
            component={(props) => (
              <List
                {...props}
                prefixUiRoute={prefixUiRoute}
                apiRoute={apiRoute}
              />
            )}
            exact
          />
        </Switch>
      </Container>
    </AppWrapper>
  );
}
