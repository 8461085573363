import React from 'react';
import { Button, Message, Modal } from 'semantic';
import { TFunction } from 'react-i18next';
import { request } from 'utils/api';
import { getCancelUrl } from '../utils';
import {
  EvseBulkActionWorkflow,
  EvseControllerBackgroundJobStatus,
} from 'types/evse-controller-background-job-execution';

interface WorkflowActionsProps {
  backgroundJobId: string;
  workflow: EvseBulkActionWorkflow;
  t: TFunction;
  onRefresh: () => void;
}

export default function WorkflowActions({
  backgroundJobId,
  workflow,
  t,
  onRefresh,
}: WorkflowActionsProps) {
  const handleCancel = (
    backgroundJobId: string,
    workflow: EvseBulkActionWorkflow
  ) => {
    return request({
      method: 'POST',
      path: getCancelUrl(backgroundJobId, workflow.executionId),
      body: {},
    }).then(() => {
      onRefresh();
    });
  };

  const canCancel =
    workflow.status === EvseControllerBackgroundJobStatus.InProgress;

  return (
    <>
      {canCancel && (
        <CancelWorkflow
          disabled={!canCancel}
          onSubmitHandler={handleCancel}
          backgroundJobId={backgroundJobId}
          workflow={workflow}
          t={t}
        />
      )}
    </>
  );
}

interface ActionModalProps {
  disabled: boolean;
  onSubmitHandler: (
    backgroundJobId: string,
    workflow: EvseBulkActionWorkflow
  ) => Promise<void>;
  backgroundJobId: string;
  workflow: EvseBulkActionWorkflow;
  t: TFunction;
}

function CancelWorkflow({
  disabled,
  onSubmitHandler,
  backgroundJobId,
  workflow,
  t,
}: ActionModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Modal
      closeIcon
      open={isModalOpen}
      size="small"
      onOpen={() => setIsModalOpen(true)}
      onClose={() => setIsModalOpen(false)}
      trigger={
        <Button
          data-tooltip={t(
            'evseControllersBackgroundJobsDetail.cancelButton.tooltip',
            'Cancel the job for this station'
          )}
          data-position="top right"
          disabled={disabled}
          basic
          icon="close"
        />
      }>
      <Modal.Header>
        {t(
          'evseControllersBackgroundJobsDetail.cancelModal.title',
          'Are you sure you want to cancel the job for this station?'
        )}
      </Modal.Header>
      <Modal.Content>
        <Message warning>
          {t(
            'evseControllersBackgroundJobsDetail.cancelModal.warning.important',
            'This action cannot be undone.'
          )}
        </Message>
        <p>
          {t(
            'evseControllersBackgroundJobsDetail.cancelModal.warning.description',
            'Canceling the job will stop further actions, but any process already started by the charging station cannot be reversed.'
          )}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content={t('common.close', 'Close')}
          onClick={() => setIsModalOpen(false)}
        />
        <Button
          primary
          disabled={disabled}
          content={t('common.confirm', 'Confirm')}
          color="red"
          onClick={() => {
            onSubmitHandler(backgroundJobId, workflow);
            setIsModalOpen(false);
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
