import React from 'react';

import { Dropdown } from 'semantic';

import EditOcpiCredential from 'components/modals/EditOcpiCredential';
import InspectObject from 'components/modals/InspectObject';
import { useTranslation } from 'react-i18next';

import { request } from 'utils/api';

import { Confirm } from 'components';

export default function OcpiCredentialsActions({ item, onReload } = {}) {
  const { t } = useTranslation();

  function onDelete() {
    return request({
      method: 'DELETE',
      path: `/1/ocpi-credentials/${item.id}`,
    });
  }

  function onReset() {
    return request({
      method: 'POST',
      path: `/1/ocpi-credentials/${item.id}/reset`,
    });
  }

  function onHandshake() {
    return request({
      method: 'POST',
      path: `/1/ocpi-credentials/${item.id}/handshake`,
    });
  }

  function onHandshakeUpdate() {
    return request({
      method: 'POST',
      path: `/1/ocpi-credentials/${item.id}/update`,
    });
  }

  function onCallDelete() {
    return request({
      method: 'POST',
      path: `/1/ocpi-credentials/${item.id}/delete`,
    });
  }

  return (
    <>
      <EditOcpiCredential
        initialValues={item}
        trigger={
          <Dropdown.Item
            content={t('ocpiCredential.configure', 'Configure')}
            icon="gears"
          />
        }
        onClose={onReload}
      />
      <InspectObject
        name="EVSE"
        data={item}
        trigger={<Dropdown.Item icon="file-code" content="Inspect Object" />}
      />
      {!item.activeVersion && (
        <Confirm
          header={t(
            'ocpiCredentialsHandshake.header',
            'Are you sure you want to initiate handshake {{name}}?',
            { name: item.name }
          )}
          content={t(
            'ocpiCredentialsHandshake.content',
            'A bidirectional token exchange will be performed'
          )}
          trigger={<Dropdown.Item icon="handshake" content="Handshake" />}
          onConfirm={async () => {
            await onHandshake(item);
            await onReload();
          }}
        />
      )}
      {item.activeVersion && (
        <Confirm
          header={t(
            'ocpiCredentialsPush.header',
            'Are you sure you want to push new credentials to {{name}}?',
            { name: item.name }
          )}
          content={t(
            'ocpiCredentialsPush.content',
            'A PUT credentials exchange will be performed'
          )}
          trigger={
            <Dropdown.Item icon="cloud-arrow-up" content="Handshake Update" />
          }
          onConfirm={async () => {
            await onHandshakeUpdate(item);
            await onReload();
          }}
        />
      )}

      <Confirm
        header={t(
          'remoteCallDeleteDialog.header',
          'Are you sure you want to call DELETE for {{name}}?',
          { name: item.name }
        )}
        content={t(
          'remoteCallDeleteDialog.content',
          'This will do a DELETE call to the OCPI endpoint, making it inactive'
        )}
        trigger={
          <Dropdown.Item
            icon="trash-arrow-up"
            text={t('remoteCallDeleteDialog.button', 'Call DELETE')}
          />
        }
        onConfirm={async () => {
          await onCallDelete(item);
          await onReload();
        }}
      />

      <Confirm
        header={t(
          'resetCredentialDialog.header',
          'Are you sure you want to Reset {{name}}?',
          { name: item.name }
        )}
        content={t(
          'resetCredentialDialog.content',
          'This will reset the current credential, making it inert'
        )}
        trigger={
          <Dropdown.Item
            icon="explosion"
            text={t('resetCredentialDialog.button', 'Call Reset')}
          />
        }
        onConfirm={async () => {
          await onReset(item);
          await onReload();
        }}
      />

      <Confirm
        header={t(
          'deleteDialog.header',
          'Are you sure you want to delete {{name}}?',
          { name: item.name }
        )}
        content={t(
          'deleteDialog.content',
          'All data will be permanently deleted'
        )}
        trigger={
          <Dropdown.Item
            icon="trash"
            text={t('deleteDialog.button', 'Delete')}
          />
        }
        onConfirm={async () => {
          await onDelete(item);
          await onReload();
        }}
      />
    </>
  );
}
