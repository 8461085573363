import React from 'react';
import { Header, Table, Label, SemanticCOLORS } from 'semantic';
import { useTranslation } from 'react-i18next';

import { formatDateTime } from 'utils/date';

import { paymentMethodText, DetailItem, DetailItemInvoice } from '../common';

type Props = {
  item: DetailItem;
};

function InvoiceText({ invoice }: { invoice?: DetailItemInvoice }) {
  if (!invoice) return <span>-</span>;
  return `${invoice.year}/${invoice.month} (#${invoice.identifier})`;
}

function FeeStatusLabel({ status }: { status: 'invoiced' | 'uninvoiced' }) {
  const { t } = useTranslation();
  const labels = {
    invoiced: {
      text: t('my-payment-fees.detail.invoice-status.invoiced', 'Invoiced'),
      color: 'green' as SemanticCOLORS,
    },
    uninvoiced: {
      text: t('my-payment-fees.detail.invoice-status.uninvoiced', 'Uninvoiced'),
      color: 'black' as SemanticCOLORS,
    },
  };

  return <Label color={labels[status].color}>{labels[status].text}</Label>;
}

export default function BasicDetailTable({ item }: Props) {
  const { t } = useTranslation();
  return (
    <>
      <Header as="h3">
        {t('my-payment-fees.detail.basic-details.header', 'Basic Details')}
      </Header>
      <Table definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>
              {t(
                'my-payment-fees.detail.basic-details.payment-id',
                'Payment ID'
              )}
            </Table.Cell>
            <Table.Cell>{item.payment.paymentId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {t(
                'my-payment-fees.detail.basic-details.payment-method',
                'Payment Method'
              )}
            </Table.Cell>
            <Table.Cell>
              <Label>{paymentMethodText(item.payment.paymentMethod)}</Label>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {t(
                'my-payment-fees.detail.basic-details.invoice-status',
                'Payment Fee Status'
              )}
            </Table.Cell>
            <Table.Cell>
              <FeeStatusLabel
                status={item.invoice ? 'invoiced' : 'uninvoiced'}
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell width={4}>
              {t('my-payment-fees.detail.basic-details.invoice', 'Invoice')}
            </Table.Cell>
            <Table.Cell>
              <InvoiceText invoice={item.invoice} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Row>
          <Table.Cell width={4}>
            {t('my-payment-fees.detail.basic-details.created-at', 'Created At')}
          </Table.Cell>
          <Table.Cell>{formatDateTime(item.createdAt)}</Table.Cell>
        </Table.Row>
      </Table>
    </>
  );
}
