import React from 'react';

import { useTranslation } from 'react-i18next';
import { request } from 'utils/api';

import { Divider } from 'semantic';

import { Search } from 'components';

import Table from 'screens/Users/List/Table';
import { useFeatures } from '../../../contexts/features';

const AccountUsers = ({ account }) => {
  const { t } = useTranslation();

  const onDataNeeded = async (filters) => {
    return request({
      method: 'POST',
      path: `/1/users/search${filters.format !== 'csv' ? '/fast' : ''}`,
      body: {
        accountId: account.id,
        ...filters,
      },
    });
  };

  const onDelete = async (item) => {
    await request({
      method: 'DELETE',
      path: `/1/users/${item.id}`,
    });
  };

  return (
    <Search.Provider onDataNeeded={onDataNeeded}>
      <>
        <Search.Status noResults={t('common.noResults', 'No Results')} />
        <Table displayContext="user" onDelete={onDelete} />
        <Divider hidden />
        <div style={{ textAlign: 'center' }}>
          <Search.Pagination />
        </div>
      </>
    </Search.Provider>
  );
};

export default AccountUsers;
