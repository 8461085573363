import React from 'react';
import { Label } from '../../semantic';
import startCase from 'lodash-es/startCase';
import { TFunction } from 'react-i18next';

interface PaymentMethodProps {
  source?: 'tap-to-pay' | 'scan-to-pay';
  t: TFunction;
}

export function PaymentMethod({ source, t }: PaymentMethodProps) {
  switch (source?.toUpperCase()) {
    case 'tap-to-pay':
      return (
        <Label content={t('payment.paymentMethod.tapToPay', 'Tap to pay')} />
      );
    case 'scan-to-pay':
      return (
        <Label content={t('payment.paymentMethod.scanToPay', 'Scan to pay')} />
      );
    default:
      return (
        <Label
          content={startCase(
            source?.toLowerCase() ||
              t('payment.paymentMethod.chargeCard', 'Charge Card')
          )}
        />
      );
  }
}
