import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useInvoiceEventLogs from 'screens/InvoiceEventLogs/data/api';
import { mapOperationType } from 'screens/InvoiceEventLogs/data/utils';
import { Message } from 'semantic';
import { Table } from 'semantic';

export default function InvoiceLogEventsSummary() {
  const { t } = useTranslation();

  const { invoiceLogId: id } = useParams();
  const { data, isLoading, isError } = useInvoiceEventLogs(id);

  if (!isLoading && !data) {
    return (
      <Message
        info
        content={t(
          'invoiceLogs.diff.noData',
          'No events are available for the given invoice.'
        )}
      />
    );
  }

  return (
    <>
      {!isLoading && !isError && data && (
        <Table sortable={false} celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>
                {t('invoiceEventsLog.operationType', 'Operation Type')}
              </Table.HeaderCell>
              <Table.HeaderCell width={6}>
                {t('invoiceEventsLog.timestamp', 'Timestamp')}
              </Table.HeaderCell>
              <Table.HeaderCell widht={3}>Document Id</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((event) => {
              const operationType = mapOperationType(
                event.Payload.operationType
              );
              const timestamp = event.CreatedAt;
              const documentId = event.Payload.document._id;

              return (
                <>
                  <Table.Row key={timestamp}>
                    <Table.Cell>{operationType}</Table.Cell>
                    <Table.Cell>{timestamp}</Table.Cell>
                    <Table.Cell>{documentId}</Table.Cell>
                  </Table.Row>
                </>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </>
  );
}
