import React from 'react';
import { FormCheckbox } from 'semantic-ui-react';
import { omit } from 'lodash-es';

import styled from 'styled-components';

const StyledFormCheckbox = styled(FormCheckbox)`
  &.ui.toggle.checkbox {
    input:checked ~ label:before {
      background-color: ${(props) => props.theme.primaryColor} !important;
    }
  }

  .ui.checked.toggle.checkbox {
    input:checked ~ label:before {
      background-color: ${(props) => props.theme.primaryColor} !important;
    }
  }
`;

export default function ThemedButton({ as, ...props }) {
  const FormCheckboxComponent = props.disableStyled
    ? FormCheckbox
    : StyledFormCheckbox;

  const style = Object.assign({}, props.style || {});

  return (
    <FormCheckboxComponent
      forwardedAs={as}
      style={style}
      {...omit(props, 'style', 'disableStyled', 'forwardedAs')}
    />
  );
}
