import React, { useEffect } from 'react';
import CodeBlock from 'components/CodeBlock';
import { Button, Icon } from 'semantic';
import styled from 'styled-components';

const StyledDiv = styled('div')`
  position: relative;
  .ui.button {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    position: absolute;
    top: 1em;
    right: 1em;
  }
`;

export default function CopySnippet({
  label,
  language = 'html',
  value,
  onCopy,
  ...props
}) {
  const [showTip, setShowTip] = React.useState(false);
  let timeout = null;

  async function copy(text) {
    await navigator.clipboard.writeText(text);
  }

  const handleTip = () => {
    setShowTip(true);
    timeout = setTimeout(() => setShowTip(false), 2000);
  };

  useEffect(() => () => clearTimeout(timeout), []);

  return (
    <StyledDiv>
      <CodeBlock language={language} value={value} />
      <StyledButton
        icon
        labelPosition="right"
        onClick={async () => {
          await copy(value);
          handleTip();
          onCopy && (await onCopy());
        }}>
        {label || 'Copy'}
        <Icon name={showTip ? 'check' : 'copy'} />
      </StyledButton>
    </StyledDiv>
  );
}
