import React, { useState } from 'react';
import { formatDateTime } from 'utils/date';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Modal,
  ModalContent,
  ModalHeader,
  Table,
} from 'semantic-ui-react';
import ExpandableJson from 'components/ExpandableJson';
import styles from './RoamingActivity.module.css';

export interface Activity {
  label: string;
  correlationId: string;
  traceId: string;
  owner: {
    connectionId: string;
    connectionName?: string;
  };
  occurredAt: Date;
  entityId: string;
  entityType: string;
  childActivities: Activity[];
  body: {
    tokenAuthorize?: {
      requestBody: string;
      responseBody: string;
      error: string;
    };
  };
}

const TokenAuthorizeRequestModal: React.FC<{
  item?: Activity;
  onClose(): void;
}> = ({ item, onClose }) => {
  const { t } = useTranslation();
  if (!item?.body?.tokenAuthorize) {
    return null;
  }

  const tokenAuthorize = item?.body?.tokenAuthorize;

  let requestJSON = {};
  let responseJSON = {};
  try {
    requestJSON = JSON.parse(tokenAuthorize?.requestBody);
  } catch (e) {
    console.warn(`Error parsing request JSON: ${e}`);
  }

  try {
    responseJSON = JSON.parse(tokenAuthorize?.responseBody);
  } catch (e) {
    console.warn(`Error parsing response JSON: ${e}`);
  }

  return (
    <Modal closeIcon onClose={() => onClose()} open={!!open}>
      <ModalHeader>
        {t('roamingActivity.requestDetails', 'Request details')}
      </ModalHeader>
      <ModalContent>
        <Table>
          <Table.Body>
            {tokenAuthorize?.error && (
              <Table.Row>
                <Table.Cell>
                  {t('roamingActivity.requestDetailsError', 'Error')}
                </Table.Cell>
                <Table.Cell>{tokenAuthorize?.error}</Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell>
                {t('roamingActivity.requestDetailsoccurredAt', 'Occurred at')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(item?.occurredAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <h2>{t('roamingActivity.request', 'Request')}</h2>
        <ExpandableJson object={requestJSON || {}} />
        <h2>{t('roamingActivity.response', 'Response')}</h2>
        <ExpandableJson object={responseJSON || {}} />
      </ModalContent>
    </Modal>
  );
};

const RoamingActivityTokenAuthorize: React.FC<{
  item: Activity;
}> = ({ item }) => {
  const [selectedItem, toggleModal] = useState<Activity | undefined>(undefined);
  const { t } = useTranslation();

  return (
    <div className={styles.activityItem}>
      <TokenAuthorizeRequestModal
        item={selectedItem}
        onClose={() => toggleModal(undefined)}
      />
      <div className={styles.activityItemHeader}>
        <div className={styles.activityHeaderName}>
          {t(
            'roamingActivity.tokenAuthorize',
            'Token authorization request made'
          )}
        </div>
        <div className={styles.activityTime}>
          {formatDateTime(item.occurredAt)}
        </div>
        <Button
          className={styles.button}
          basic
          size="mini"
          onClick={() => toggleModal(item)}>
          {t('roamingActivity.request', 'View request data')}
        </Button>
      </div>
    </div>
  );
};

export default RoamingActivityTokenAuthorize;
