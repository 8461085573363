import React, { ReactNode } from 'react';
import RequestCardV1 from 'components/modals/RequestCard/v1';
import RequestCardV2 from 'components/modals/RequestCard/v2';
import { useFeatures } from 'contexts/features';
import { useUser } from 'contexts/user';

type RequestCardModalProps = {
  title?: string;
  initialValues?: {
    type: 'card' | 'tag';
  };
  trigger?: ReactNode;
  isOpen?: boolean;
  onSuccess?: () => void;
  onClose?: () => void;
  duringSignup?: boolean;
};

export function RequestCardModal(props: RequestCardModalProps) {
  const { hasFeature } = useFeatures();
  const v2Enabled = hasFeature('request_card_v2');

  return (
    <>
      {v2Enabled && <RequestCardV2 {...props} title={undefined} />}
      {!v2Enabled && <RequestCardV1 {...props} />}
    </>
  );
}
