import React from 'react';
import { Container, Menu } from 'semantic';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default ({
  locationsPath = '/cards/locations',
  sourcesPath = '/cards/locations/sources',
  mapPath = '/cards/locations/map',
  analyticsPath = '/cards/locations/analytics',
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Menu pointing secondary stackable>
        <Menu.Item
          exact
          content={t('locationsMenu.list', 'List')}
          to={locationsPath}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.map', 'Map')}
          to={mapPath}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.sources', 'Sources')}
          to={sourcesPath}
          as={NavLink}
        />
        <Menu.Item
          exact
          content={t('locationsMenu.analytics', 'Analytics')}
          to={analyticsPath}
          as={NavLink}
        />
      </Menu>
    </Container>
  );
};
