import React from 'react';
import { Button, Modal, Table, Label } from 'semantic';
import { formatEuro } from 'utils/formatting';
import EditAccessGroupMember from 'components/modals/EditAccessGroupMember';

export default ({ accessGroup, members, onRefresh, onDelete }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Name</Table.HeaderCell>
          <Table.HeaderCell width={2}>Energy Costs</Table.HeaderCell>
          <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {members.map((item) => {
          return (
            <Table.Row key={item.id}>
              <Table.Cell>{item.user && item.user.name}</Table.Cell>
              <Table.Cell>
                {item.priceType === 'free' ? (
                  <Label content="Free" />
                ) : (
                  <span>{formatEuro(item.pricePerKwh)} per kWh</span>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <EditAccessGroupMember
                  accessGroup={accessGroup}
                  refresh={() => onRefresh()}
                  member={item}
                  trigger={<Button basic icon="pen-to-square" title="Edit" />}
                  onClose={() => onRefresh()}
                />
                <Modal
                  header={`Are you sure you want to delete?`}
                  content="All data will be permanently deleted"
                  trigger={<Button basic icon="trash" title="Delete" />}
                  closeIcon
                  actions={[
                    {
                      key: 'delete',
                      primary: true,
                      content: 'Delete',
                      onClick: () => onDelete(item),
                    },
                  ]}
                />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
