import {
  SettingsActiveItem,
  SettingsMenuItemProps,
} from 'types/account-settings';
import AccountSettingsMenu from '../molecules/SettingsMenu';
import { useTranslation } from 'react-i18next';
import { useUser } from 'contexts/user';
import { isAccountAdmin } from 'utils/roles';
import { useAccount } from 'contexts/account';

export default function PersonalAccountSettingsMenu({
  activeItem,
}: SettingsActiveItem) {
  const { t } = useTranslation();
  // @ts-ignore until the user context is typed
  const { user, provider } = useUser();
  const { account, isAccountManagedBySso } = useAccount();

  const showNotificationsSettings =
    provider?.isNotificationSettingsFeatureEnabled || isAccountAdmin(user);

  const showPersonalBillingMenuItem = Boolean(user?.billing);
  const showCurrentPlanUnderBilling = Boolean(
    account?.accountTierBillingPlanId
  );

  const individualAccountSettings: SettingsMenuItemProps[] = [
    {
      title: t('settings.sidebar.personal.profile', 'Profile'),
      route: '/settings/personal/profile',
      iconName: 'regular user',
    },
    ...(showPersonalBillingMenuItem
      ? [
          {
            title: showCurrentPlanUnderBilling
              ? t(
                  'settings.sidebar.personal.billingAndPlans',
                  'Billing & Plans'
                )
              : t('settings.sidebar.personal.billing', 'Billing'),

            route: '/settings/personal/billing',
            iconName: 'regular credit-card',
          },
        ]
      : []),
    {
      title: t('settings.sidebar.personal.reimbursement', 'Reimbursement'),
      route: '/settings/personal/reimbursement',
      iconName: 'regular file-lines',
    },
    {
      title: t('settings.sidebar.personal.appearance', 'Appearance'),
      route: '/settings/personal/appearance',
      iconName: 'regular sun',
    },
    ...(showNotificationsSettings
      ? [
          {
            title: t(
              'settings.sidebar.personal.notifications',
              'Notifications'
            ),
            route: '/settings/personal/notifications',
            iconName: 'regular envelope',
          },
        ]
      : []),
    ...(isAccountManagedBySso
      ? []
      : [
          {
            title: t(
              'settings.sidebar.personal.passwordAndAuth',
              'Password & Authentication'
            ),
            route: '/settings/personal/password-and-authentication',
            iconName: 'shield-halved',
          },
        ]),
  ];

  return AccountSettingsMenu({
    header: t('settings.sidebar.personal.title', 'Personal'),
    menuItems: individualAccountSettings,
    activeItem,
  });
}
