import React from 'react';
import { TariffSystem } from 'components/tariffs/AdvancedTariff/state';
import { useAdvancedTariffFeatures } from 'components/tariffs/features';
import { Icon, Message } from 'semantic';
import { useTranslation } from 'react-i18next';

type AdvancedTariffsFeatureAlertProps = {
  tariffSystem: TariffSystem;
  style?: React.CSSProperties;
};

export default function AdvancedTariffsFeatureAlert(
  props: AdvancedTariffsFeatureAlertProps
) {
  const { t } = useTranslation();
  const features = useAdvancedTariffFeatures();
  const { tariffSystem } = props;
  if (tariffSystem === 'dynamic' && !features.dynamicPricing) {
    return (
      <Message style={props.style} error>
        <Icon name={'triangle-exclamation'} />
        {t(
          'advancedTariff.dynamicTariffsNotEnabled',
          'Dynamic Tariffs are not enabled for your account. To enable this feature, please contact support.'
        )}
      </Message>
    );
  }
  if (tariffSystem === 'scheduled' && !features.scheduled) {
    return (
      <Message style={props.style} error>
        <Icon name={'triangle-exclamation'} />
        {t(
          'advancedTariff.scheduledTariffsNotEnabled',
          'Scheduled Tariffs are not enabled for your account. To enable this feature, please contact support.'
        )}
      </Message>
    );
  }
  return <></>;
}
